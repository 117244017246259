import fuzzysearch from 'fuzzysearch';
import pluralize from 'pluralize';
import {
  ACCOUNTS_SEARCH_GROUP_ID,
  ACCOUNTS_SEARCH_FIELD_ID,
  SPACES_TASKS_KEY_PATH,
  ACCOUNT_TASK_DETAILS_KEY_PATH,
  UNTITLED_COLUMN_LABEL,
} from 'Modules/Spaces/show/constants';
import { WORKSPACE_TYPES } from 'Modules/Spaces/shared/constants';
import moment from 'moment';
import { createSelector } from 'reselect';
import { Map, OrderedMap, Set } from 'immutable';
import {
  firestoreWatcherCategoryToReducerKey,
  generateSingleSelectOptionId,
} from 'Modules/Spaces/show/utils';
import Task from 'Modules/Spaces/show/records/Task';
import { currentUser as getCurrentUser } from 'Modules/Shared/selectors/users';
import { isNull } from 'lodash-es';

const accountsTablePath = ['spacesShow', 'accountsTable'];

export const getAccountsTable = state => state.getIn(accountsTablePath);
export const accountsTableSelected = state =>
  state.getIn([...accountsTablePath, 'selectedAccounts']);
export const accountsTableSelectedColumns = state =>
  state.getIn([...accountsTablePath, 'selectedColumns']);
export const getWorkspaceName = state => state.getIn(['spacesShow', 'name']);
export const getWorkspaceType = state =>
  state.getIn(['spacesShow', 'workspaceType']);
export const getAutoSyncId = state => state.getIn(['spacesShow', 'autoSyncId']);
export const getReportName = state => state.getIn(['spacesShow', 'reportName']);
export const getWorkspaceId = state => state.getIn(['spacesShow', 'id']);
export const getWorkspaceDescription = state =>
  state.getIn(['spacesShow', 'description'], '');
export const getAllWorkspaces = state =>
  state.getIn(['spacesShow', 'workspaces']);
export const getIsStarredWorkspace = state =>
  state.getIn(['spacesShow', 'isStarredAccountsWorkspace']);
export const getWorkspaceCreatorId = state =>
  state.getIn(['spacesShow', 'creatorId']);
export const getSharedUsers = state =>
  state.getIn(['spacesShow', 'sharedUsers']);
export const getAllSObjectsAreLoaded = state =>
  state.getIn([...accountsTablePath, 'allSObjectsAreLoaded']);
export const accountsTableSortData = state => ({
  orderBy: state.getIn([...accountsTablePath, 'orderBy']),
  orderDirection: state.getIn([
    'spacesShow',
    'accountsTable',
    'orderDirection',
  ]),
});
export const accountsTableRowOrder = state =>
  state.getIn([...accountsTablePath, 'rowOrder']);
export const removingAccounts = state =>
  state.getIn(['spacesShow', 'removingAccounts']);
export const getFilterQuery = state =>
  state.getIn(
    [
      'form',
      ACCOUNTS_SEARCH_GROUP_ID,
      'fields',
      ACCOUNTS_SEARCH_FIELD_ID,
      'value',
    ],
    ''
  );
export const accountsTableRows = state =>
  state.getIn([...accountsTablePath, 'standardRows']);

export const getStandardColumns = state =>
  state.getIn([...accountsTablePath, 'standardColumns']);

export const getCellField = (state, columnId) =>
  state.getIn([...accountsTablePath, 'standardColumns', columnId], new Map());

export const getCellFieldMetadata = (state, columnId) =>
  state.getIn(
    [...accountsTablePath, 'standardColumns', columnId, 'fieldMetadata'],
    new Map()
  );

export const getStandardColumnData = columnId =>
  createSelector(getStandardColumns, standardColumns =>
    standardColumns.get(columnId)
  );

export const getSalesforceColumnNames = state =>
  state.getIn([...accountsTablePath, 'salesforceColumnNames']);

export const getColumnOrderEditingUserId = state =>
  state.getIn([...accountsTablePath, 'columnOrderEditingUserId'], null);
export const isExternalUserEditingColumnOrder = createSelector(
  getColumnOrderEditingUserId,
  getCurrentUser,
  (editingUserId, currentUser) =>
    !isNull(editingUserId) && editingUserId !== currentUser.get('id')
);
export const isCurrentUserEditingColumnOrder = createSelector(
  getColumnOrderEditingUserId,
  getCurrentUser,
  (editingUserId, currentUser) =>
    !isNull(editingUserId) && editingUserId === currentUser.get('id')
);

export const getStandardRowData = (state, accountId) =>
  state.getIn([...accountsTablePath, 'standardRows', accountId]);

export const getCellValue = (state, accountId, columnId) =>
  state.getIn([
    ...accountsTablePath,
    'standardRows',
    accountId,
    'rowValues',
    columnId,
    'data',
  ]);

export const getIsCellUpdating = (state, accountId, columnId) =>
  state.getIn([
    ...accountsTablePath,
    'standardRows',
    accountId,
    'rowValues',
    columnId,
    'isUpdating',
  ]);

// spaces tasks selectors
/**
 * TODO @jennyjkim95 going to move task selectors, action & reducers out into it's own files
 */
export const getSObjectTaskCount = (state, workspaceSObjectId) =>
  state.getIn(
    [...ACCOUNT_TASK_DETAILS_KEY_PATH, workspaceSObjectId, 'totalTasksCount'],
    0
  );
export const getAssignedIncompleteTaskCount = (state, workspaceSObjectId) =>
  state.getIn(
    [
      ...ACCOUNT_TASK_DETAILS_KEY_PATH,
      workspaceSObjectId,
      'assignedIncompleteTasksCount',
    ],
    0
  );
export const getAssignedCompleteTaskCount = (state, workspaceSObjectId) =>
  state.getIn(
    [
      ...ACCOUNT_TASK_DETAILS_KEY_PATH,
      workspaceSObjectId,
      'assignedCompleteTasksCount',
    ],
    0
  );
export const getAccountTaskDetails = state =>
  state.getIn(ACCOUNT_TASK_DETAILS_KEY_PATH);
export const getEditingTask = state =>
  state.getIn([...SPACES_TASKS_KEY_PATH, 'taskToEdit'], new Task());
const getFlowActions = state =>
  state.getIn([...SPACES_TASKS_KEY_PATH, 'flowActions'], new OrderedMap());
export const getWorkspaceAccountId = state =>
  state.getIn([...SPACES_TASKS_KEY_PATH, 'workspaceAccountId']);
export const getAccountName = state =>
  state.getIn([...SPACES_TASKS_KEY_PATH, 'accountName']);
export const getSortedFlowActions = createSelector(
  getFlowActions,
  flowActions => flowActions.sortBy(flowAction => flowAction.daysUntilDue)
);

export const getAllTasks = state =>
  state.getIn([...SPACES_TASKS_KEY_PATH, 'taskList']);
export const getIsTaskExecuting = (state, taskId) =>
  state.getIn([...SPACES_TASKS_KEY_PATH, 'isTaskExecuting', taskId]);
export const getIsFetchingTasks = state =>
  state.getIn([...SPACES_TASKS_KEY_PATH, 'isFetchingTasks']);
export const getIsExecutingTaskForm = state =>
  state.getIn([...SPACES_TASKS_KEY_PATH, 'isExecutingTaskForm']);
export const getTaskLists = createSelector(getAllTasks, tasks => {
  const tasksToProcess = tasks || [];
  let completedTasks = [];
  let incompleteTasks = [];
  tasksToProcess.forEach(task => {
    if (task.completedAt) {
      completedTasks.push([task.id, task]);
    } else {
      incompleteTasks.push([task.id, task]);
    }
  });

  completedTasks = OrderedMap(completedTasks);
  incompleteTasks = OrderedMap(incompleteTasks);

  const sortedIncompleteTasks = incompleteTasks.sort(
    (firstTask, secondTask) =>
      moment(firstTask.dueAt) - moment(secondTask.dueAt)
  );
  const sortedCompleteTasks = completedTasks.sort(
    (firstTask, secondTask) =>
      moment(secondTask.completedAt) - moment(firstTask.completedAt)
  );

  return new Map({
    sortedCompleteTasks,
    sortedIncompleteTasks,
  });
});

export const makeGetUserByRole = ({ role, taskAction }) =>
  createSelector(getSharedUsers, sharedUsers => {
    const userId = taskAction ? taskAction.get(role) : null;
    return sharedUsers.get(parseInt(userId, 10), new Map());
  });

// custom column selectors
export const getLastUpdatedByCustomColumnId = (state, id) =>
  state.getIn([...accountsTablePath, 'customColumns', id, 'editingUserId']) ||
  null;
export const getEditingColumnUserImgSrc = columnId =>
  createSelector(
    getSharedUsers,
    state => getLastUpdatedByCustomColumnId(state, columnId),
    (sharedUsers, editingUserId) => {
      if (editingUserId) {
        const user = sharedUsers.find(user => user.get('id') === editingUserId);
        return user.get('profileImageUrl');
      }

      return '';
    }
  );
export const getCustomColumnName = (state, columnId) => {
  const column = state.getIn([
    'spacesShow',
    'accountsTable',
    'customColumns',
    columnId,
  ]);
  return (column && column.get('name')) || '';
};
export const getCustomColumns = state =>
  state.getIn(['spacesShow', 'accountsTable', 'customColumns']);

export const getCustomColumnsLoaded = state =>
  state.getIn(['spacesShow', 'accountsTable', 'customColumnsLoaded']);

export const getColumnOrder = state =>
  state.getIn(['spacesShow', 'accountsTable', 'columnOrder']);

export const getFirestoreRowData = state =>
  state.getIn(['spacesShow', 'accountsTable', 'firestoreRowData']);

export const makeGetFirestoreRowDataById = rowId =>
  createSelector(getFirestoreRowData, firestoreRowData => {
    return firestoreRowData.get(rowId) || new Map();
  });

export const makeGetCustomColumnRowValue = (columnId, rowId) =>
  createSelector(makeGetFirestoreRowDataById(rowId), firestoreRow => {
    return firestoreRow.get(columnId) || '';
  });

export const getSpaceCellEditingId = (state, rowId, columnId) =>
  state.getIn(
    [
      'spacesShow',
      'accountsTable',
      'firestoreRowData',
      rowId,
      columnId,
      'editingUserId',
    ],
    null
  );

export const makeGetEditingUserForAccountCustomField = (accountId, columnId) =>
  createSelector(getFirestoreRowData, firestoreRowData => {
    const columnData = firestoreRowData.getIn([accountId, columnId], null);
    return (columnData && columnData.get('editingUserId')) || null;
  });

export const makeGetUserImageSrc = userId =>
  createSelector(getSharedUsers, sharedUsers => {
    if (userId) {
      const user = sharedUsers.find(user => user.get('id') === userId);
      return user.get('profileImageUrl');
    }

    return '';
  });

export const getSingleSelectOptionsByColumnId = (state, id) =>
  state.getIn(['spacesShow', 'accountsTable', 'customColumns', id, 'options']);

export const generateNewSingleSelectOptionUId = (state, columnId) => {
  const options =
    getSingleSelectOptionsByColumnId(state, columnId) || new Map();
  const modifier = options.size + 1;
  return generateSingleSelectOptionId(modifier);
};

export const getEditingUserForSingleSelectOption = (
  state,
  columnId,
  optionId
) =>
  state.getIn([
    ...accountsTablePath,
    'customColumns',
    columnId,
    'options',
    optionId,
    'editingUserId',
  ]);

export const makeGetAccountCustomFieldValue = (accountId, columnId) =>
  createSelector(getFirestoreRowData, firestoreRowData =>
    firestoreRowData.getIn([accountId, columnId, 'value'], null)
  );

export const getVisibleColumns = createSelector(
  getColumnOrder,
  getStandardColumns,
  getCustomColumns,
  (columnOrder, standardColumns, customColumns) =>
    columnOrder.filter(
      column =>
        column.get('isVisible') &&
        (standardColumns.has(column.get('id')) ||
          customColumns.has(column.get('id')))
    )
);

export const makeGetAccountIds = () =>
  createSelector(
    accountsTableRows,
    accountsTableRowOrder,
    getFilterQuery,
    getWorkspaceType,
    (standardRows, rowOrder, filter, workspaceType) => {
      const lowerCaseFilter = filter.toLowerCase();
      if (workspaceType === WORKSPACE_TYPES.OPPORTUNITY) {
        return rowOrder.filter(
          oppId =>
            standardRows.has(oppId) &&
            fuzzysearch(
              lowerCaseFilter,
              (
                standardRows.getIn([oppId, 'rowValues', 'name', 'name'], '') ||
                ''
              ).toLowerCase()
            )
        );
      }
      return rowOrder.filter(
        accountId =>
          standardRows.has(accountId) &&
          fuzzysearch(
            lowerCaseFilter,
            (
              standardRows.getIn(
                [accountId, 'rowValues', 'accountName', 'name'],
                ''
              ) || ''
            ).toLowerCase()
          )
      );
    }
  );

export const getFirestoreUpstartStatus = (state, category) =>
  state.getIn([
    'spacesShow',
    'firestoreUpstartStatuses',
    firestoreWatcherCategoryToReducerKey(category),
  ]);

/**
 * Adds the appropriate label into the column data
 * and sorts the list based on that label
 *
 * @return {Immutable.List}
 */
export const getColumnsForVisibilitySelector = createSelector(
  getColumnOrder,
  getStandardColumns,
  getCustomColumns,
  (columnOrder, standardColumns, customColumns) => {
    return columnOrder
      .map(column => {
        const id = column.get('id');
        const label = standardColumns.getIn(
          [id, 'label'],
          customColumns.getIn([id, 'name'])
        );

        // In the case of a newly created custom column, the user may not have entered a label yet
        return column.set('label', label || UNTITLED_COLUMN_LABEL);
      })
      .sort((columnOne, columnTwo) => {
        const labelOne = columnOne.get('label');
        const labelTwo = columnTwo.get('label');

        // If there are multiple custom columns with
        // labels of UNTITLED_COLUMN_LABEL we sort by the id.
        // This will at least preserve their order in the list.
        if (labelOne === labelTwo) {
          const idOne = columnOne.get('id');
          const idTwo = columnTwo.get('id');

          return idOne > idTwo ? 1 : -1;
        }

        return labelOne > labelTwo ? 1 : -1;
      });
  }
);

export const getVisibleColumnsIds = createSelector(
  getVisibleColumns,
  visibleColumns => new Set(visibleColumns.map(entry => entry.get('id')))
);

export const getActiveAccountId = state =>
  state.getIn(['spacesShow', 'activeAccountId']);

export const getActiveTab = state => state.getIn(['spacesShow', 'activeTab']);

export const getActiveAccountSfdcId = createSelector(
  accountsTableRows,
  getActiveAccountId,
  (accounts, id) =>
    accounts && id ? accounts.getIn([id, 'rowValues', 'Id', 'data']) : new Map()
);

export const getTotalSObjectCount = createSelector(
  accountsTableRowOrder,
  accounts => accounts.size
);

export const getWorkspaceObjectType = ({ plural }) =>
  createSelector(getWorkspaceType, workspaceType => {
    let objectType;
    if (workspaceType === WORKSPACE_TYPES.ACCOUNT) {
      objectType = 'Account';
    } else {
      objectType = 'Opportunity';
    }
    return plural ? pluralize(objectType) : objectType;
  });
