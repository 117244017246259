import cx from 'classnames';
import { FC, HTMLProps } from 'react';

export interface LinkButtonProps extends HTMLProps<HTMLAnchorElement> {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

// The reason why this is called LinkButton is because linter complains when it's called a Link
const LinkButton: FC<LinkButtonProps> = ({
  children,
  className,
  disabled,
  onClick,
  ...rest
}) => {
  const handleClick = (): void => {
    if (!disabled) onClick?.();
  };
  return (
    <a className="no-underline" {...rest}>
      <div
        className={cx(
          className,
          'font-groove cursor-pointer font-semibold',
          disabled
            ? 'text-neutral/400 hover:text-neutral/400 cursor-not-allowed'
            : 'text-clari-blue/600 hover:text-clari-blue/700',
        )}
        role="button"
        onClick={handleClick}
        tabIndex={0}
        onKeyDown={event => event.key === 'Enter' && handleClick()}
      >
        {children}
      </div>
    </a>
  );
};

export default LinkButton;
