import { StandardDropDownItem } from './Components/DropDownList';

export const timeList: StandardDropDownItem[] = [
  {
    key: '12:00',
    value: '12:00',
    text: '12:00',
  },
  {
    key: '12:30',
    value: '12:30',
    text: '12:30',
  },
  {
    key: '01:00',
    value: '01:00',
    text: '01:00',
  },
  {
    key: '01:30',
    value: '01:30',
    text: '01:30',
  },
  {
    key: '02:00',
    value: '02:00',
    text: '02:00',
  },
  {
    key: '02:30',
    value: '02:30',
    text: '02:30',
  },
  {
    key: '03:00',
    value: '03:00',
    text: '03:00',
  },
  {
    key: '03:30',
    value: '03:30',
    text: '03:30',
  },
  {
    key: '04:00',
    value: '04:00',
    text: '04:00',
  },
  {
    key: '04:30',
    value: '04:30',
    text: '04:30',
  },
  {
    key: '05:00',
    value: '05:00',
    text: '05:00',
  },
  {
    key: '05:30',
    value: '05:30',
    text: '05:30',
  },
  {
    key: '06:00',
    value: '06:00',
    text: '06:00',
  },
  {
    key: '06:30',
    value: '06:30',
    text: '06:30',
  },
  {
    key: '07:00',
    value: '07:00',
    text: '07:00',
  },
  {
    key: '07:30',
    value: '07:30',
    text: '07:30',
  },
  {
    key: '08:00',
    value: '08:00',
    text: '08:00',
  },
  {
    key: '08:30',
    value: '08:30',
    text: '08:30',
  },
  {
    key: '09:00',
    value: '09:00',
    text: '09:00',
  },
  {
    key: '09:30',
    value: '09:30',
    text: '09:30',
  },
  {
    key: '10:00',
    value: '10:00',
    text: '10:00',
  },
  {
    key: '10:30',
    value: '10:30',
    text: '10:30',
  },
  {
    key: '11:00',
    value: '11:00',
    text: '11:00',
  },
  {
    key: '11:30',
    value: '11:30',
    text: '11:30',
  },
];

export const amPm: StandardDropDownItem[] = [
  {
    key: 'AM',
    value: 'AM',
    text: 'AM',
  },
  {
    key: 'PM',
    value: 'PM',
    text: 'PM',
  },
];

export type presetUnits = 'hour' | 'day' | 'week' | 'month';

export type Presets = {
  text: string;
  number: number;
  unit: presetUnits;
};

export const presets: Presets[] = [
  {
    text: 'In 1 hour',
    number: 1,
    unit: 'hour',
  },
  {
    text: 'In 4 hours',
    number: 4,
    unit: 'hour',
  },
  {
    text: 'Tomorrow morning',
    number: 1,
    unit: 'day',
  },
  {
    text: 'In 2 work days',
    number: 2,
    unit: 'day',
  },
  {
    text: 'In 1 work week',
    number: 1,
    unit: 'week',
  },
];
