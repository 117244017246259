import {
  createActions,
  standardActions,
  keyMirror,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  PEOPLE_IMPORT_DIALOG: {
    CSV_LOOKUP: {
      UPLOAD_CSV: standardActions(),
      ...keyMirror([
        'SEARCH_BY_CSV',
        'UPDATE_CSV_LOOKUP_STATE',
        'RESET',
        'SET_CSV_ERROR_MESSAGE',
        'OPEN_AUTO_CREATION_MODAL',
        'CLOSE_AUTO_CREATION_MODAL',
        'UPDATE_ASSIGN_BASED_ON_USER_COLUMN',
      ]),
    },
  },
}).PEOPLE_IMPORT_DIALOG.CSV_LOOKUP;

export function updateAssignBasedOnUserColumn(value) {
  return {
    type: actionTypes.UPDATE_ASSIGN_BASED_ON_USER_COLUMN,
    payload: value,
  };
}

export function uploadCsvFile(file, groupId) {
  return {
    type: actionTypes.UPLOAD_CSV.BEGIN,
    payload: { file, groupId },
  };
}

export function searchByCsv(payload = {}) {
  return {
    type: actionTypes.SEARCH_BY_CSV,
    payload,
  };
}

export function updateCsvLookupState(payload) {
  return {
    type: actionTypes.UPDATE_CSV_LOOKUP_STATE,
    payload,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function setCsvErrorMessage(message) {
  return {
    type: actionTypes.SET_CSV_ERROR_MESSAGE,
    payload: message,
  };
}

export function openAutoCreationModal() {
  return {
    type: actionTypes.OPEN_AUTO_CREATION_MODAL,
  };
}

export function closeAutoCreationModal() {
  return {
    type: actionTypes.CLOSE_AUTO_CREATION_MODAL,
  };
}
