import {
  createActions,
  keyMirror as keyMirror2,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SHARED: {
    SAVED_SEARCHES: {
      CREATE_OR_UPDATE: standardActions(),
      FETCH: standardActions(),
      ...keyMirror2(['SET_SAVED_SEARCHES', 'REMOVE']),
    },
  },
}).SHARED.SAVED_SEARCHES;

export function setSavedSearches(data) {
  return {
    type: actionTypes.SET_SAVED_SEARCHES,
    payload: data,
  };
}

export function createOrUpdateSavedSearch(payload) {
  return {
    type: actionTypes.REMOVE_SAVED_SEARCH,
    payload,
  };
}

export function removeSavedSearch(payload) {
  return {
    type: actionTypes.REMOVE_SAVED_SEARCH,
    payload,
  };
}

export function successfullyFetchedSavedSearch(savedSearch) {
  return {
    type: actionTypes.FETCH.SUCCESS,
    payload: savedSearch,
  };
}

export function failedFetchingSavedSearch() {
  return {
    type: actionTypes.FETCH.FAILURE,
  };
}
