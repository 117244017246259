import keyMirror from 'Utils/key-mirror';

export const DRAWER_WIDTH = 350;
export const CONTENT_WIDTH = `calc(100% - ${DRAWER_WIDTH}px)`;

export const TABS = keyMirror({
  search: null,
  advancedSearch: null,
  salesforceListView: null,
  salesforceReport: null,
  csvLookup: null,
  concierge: null,
});

export const PEOPLE_IMPORT_ROOT_UI_KEY_PATH = ['peopleImportDialog'];
export const SPECIAL_CASE_SEARCH = ['account', 'opportunity'];
export const UNSUPPORTED_FIELD_TYPES = ['id', 'reference', 'address'];

export const ADD_PEOPLE_JOB_ID_KEYPATH = [
  'PeopleImportDialog',
  'root',
  'addPeopleJobId',
];
export const DATE_LITERALS = [
  'YESTERDAY',
  'TODAY',
  'LAST_WEEK',
  'THIS_WEEK',
  'NEXT_WEEK',
  'LAST_MONTH',
  'THIS_MONTH',
  'NEXT_MONTH',
  'LAST_90_DAYS',
  'NEXT_90_DAYS',
  'THIS_QUARTER',
  'LAST_QUARTER',
  'NEXT_QUARTER',
  'THIS_YEAR',
  'LAST_YEAR',
  'NEXT_YEAR',
];

export const CUSTOM_MERGE_FIELD_PREFIX = 'flow:';
export const CUSTOM_MERGE_FIELD_REGEXP = /^flow[:.](\w*)$/;

export const MODULE_ROOT_HEADER_HEIGHT = 112;
