import { createSelector } from 'reselect';
import { isEmpty } from 'lodash-es';
import { Map } from 'immutable';
import { selectors } from '@groove-labs/groove-ui';

import { FILTER_SEARCH_GROUP_ID } from 'Modules/Actions/constants/filters';
import {
  IS_FILTER_MENU_OPEN_UI_KEY_PATH,
  FILTER_MENU_GROUP_ID,
  FILTER_SOURCE_FIELD_ID,
  FILTER_DUE_FIELD_ID,
  FILTER_TYPE_FIELD_ID,
  FILTER_TIMEZONE_FIELD_ID,
} from 'Modules/Actions/constants/filterMenu';
import { isMultiselectFilterApplied } from 'Modules/Actions/utils';
import { currentUser } from 'Modules/Shared/selectors/users';

const { getGroupFields } = selectors.form;
const { getProperty } = selectors.ui;

const getAllTabData = state => state.getIn(['actions', 'tabData']);

export const getTotalTableWidth = state =>
  state.getIn(['actions', 'table', 'totalWidth']);

export const getActiveTab = state =>
  state.getIn(['actions', 'table', 'activeTab']);

export const getStatusFilter = state =>
  state.getIn(['actions', 'filters', 'status']);

export const getInitialLoadComplete = state =>
  state.getIn(['actions', 'initialLoadComplete']);

export const getIsFetchingInitialLoadData = state =>
  state.getIn(['actions', 'isFetchingInitialLoadData']);

export const getIsFetchingAdditionalData = state =>
  state.getIn(['actions', 'isFetchingAdditionalData']);

export const getIsFetchingActionCounts = state =>
  state.getIn(['actions', 'isFetchingActionCounts']);

export const getIsFetchingTimezoneFilters = state =>
  state.getIn(['actions', 'isFetchingTimezoneFilters']);

export const getSortDetails = state => state.getIn(['actions', 'sort']);

export const getChangedFilterFieldIds = state =>
  state.getIn(['actions', 'filters', 'changedFilterFieldIds']);

export const getIsComposePaneReady = state =>
  state.getIn(['actions', 'composePane', 'isReady']);

export const getTotalActionsCountByTab = (state, tab) =>
  state.getIn(['actions', 'tabData', tab, 'totalCount']);

export const getTimezoneFilters = state =>
  state.getIn(['actions', 'filters', 'timezoneFilters']);

export const getCurrentTabData = createSelector(
  getAllTabData,
  getActiveTab,
  (allTabData, activeTab) => allTabData.get(activeTab)
);

export const getCurrentTabCount = createSelector(getCurrentTabData, tabData =>
  tabData.get('totalCount')
);

export const getActiveRows = createSelector(getCurrentTabData, tabData =>
  tabData.get('rows')
);

export const getActiveOrderedRowIds = createSelector(
  getCurrentTabData,
  tabData => tabData.get('orderedRowIds')
);

export const getPageInfo = createSelector(getCurrentTabData, tabData =>
  tabData.get('pageInfo')
);

export const makeGetRowData = index =>
  createSelector(getActiveRows, getActiveOrderedRowIds, (rows, rowIds) =>
    rows.get(rowIds.get(index))
  );

export const getActiveId = state => state.getIn(['actions', 'activeId']);

export const getActiveAction = createSelector(
  getActiveId,
  getActiveRows,
  (activeId, rows) => rows.get(activeId)
);

export const getActiveActionType = () =>
  createSelector(getActiveId, getActiveRows, (actionId, rows) => {
    return rows.get(actionId)?.get('type');
  });

export const getActiveActionSource = () =>
  createSelector(getActiveId, getActiveRows, (actionId, rows) => {
    return rows.get(actionId)?.get('source');
  });

export const getRowCount = createSelector(getActiveOrderedRowIds, rowIds =>
  rowIds.count()
);

export const getHasFiltersApplied = createSelector(
  state => getGroupFields(state, { groupId: FILTER_MENU_GROUP_ID }),
  state => getGroupFields(state, { groupId: FILTER_SEARCH_GROUP_ID }),
  (filterData, searchData) => {
    const searchValue = !isEmpty(searchData.getIn(['searchInput', 'value']));
    const dueFilterApplied = isMultiselectFilterApplied(
      filterData,
      FILTER_DUE_FIELD_ID
    );
    const typeFilterApplied = isMultiselectFilterApplied(
      filterData,
      FILTER_TYPE_FIELD_ID
    );
    const sourceFilterApplied = isMultiselectFilterApplied(
      filterData,
      FILTER_SOURCE_FIELD_ID
    );
    const timezoneFilterApplied = isMultiselectFilterApplied(
      filterData,
      FILTER_TIMEZONE_FIELD_ID
    );
    return (
      timezoneFilterApplied ||
      sourceFilterApplied ||
      searchValue ||
      dueFilterApplied ||
      typeFilterApplied
    );
  }
);

export const getHasNoFilteredResults = createSelector(
  getHasFiltersApplied,
  getRowCount,
  (hasFiltersApplied, rowCount) => rowCount === 0 && hasFiltersApplied
);

export const getShouldDisplayNoActionsZeroState = () =>
  createSelector(
    getRowCount,
    getHasFiltersApplied,
    getIsFetchingInitialLoadData,
    state => getProperty(state, IS_FILTER_MENU_OPEN_UI_KEY_PATH),
    (
      rowCount,
      hasFiltersApplied,
      isFetchingInitialLoadData,
      isFilterMenuOpen
    ) => {
      const hasNoActions = rowCount === 0;

      return (
        !isFilterMenuOpen &&
        hasNoActions &&
        !isFetchingInitialLoadData &&
        !hasFiltersApplied
      );
    }
  );

export const getStickyFilters = state =>
  state.getIn(['actions', 'actionFilters']);

export const getActionFilters = createSelector(
  getStickyFilters,
  currentUser,
  (savedFilters, user) => savedFilters || user?.get('stickyFilters') || Map()
);

export const getFormatedActionFilters = createSelector(
  getActionFilters,
  savedFilters => {
    const { dueAt, type, source, priority, timezone } = savedFilters.toJS();
    return {
      type,
      source,
      priority,
      timezone,
      dueAt: dueAt?.map(operator => ({
        operator,
        dateTime: new Date().toISOString(),
      })),
    };
  }
);

export const getActiveTabRows = state =>
  state.getIn(['actions', 'tabData', 'ACTIVE', 'rows']);

export const getActionRow = actionId =>
  createSelector(getActiveTabRows, rows => {
    return rows.get(actionId);
  });
