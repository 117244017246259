import { keyMirror } from '@groove-labs/action-utils';
import { SPACES_IMPORT_DIALOG_UI_KEY_PATH } from 'Modules/Spaces/shared/constants';

export const TABS = keyMirror([
  'search',
  'ownershipSearch',
  'opportunitySearch',
  'salesforceReport',
]);

export const SPACES_IMPORT_DIALOG_IS_OPEN_UI_KEY_PATH = [
  ...SPACES_IMPORT_DIALOG_UI_KEY_PATH,
  'isOpen',
];
export const SPACES_IMPORT_DIALOG_ACTIVE_TAB_UI_KEY_PATH = [
  ...SPACES_IMPORT_DIALOG_UI_KEY_PATH,
  'activeTab',
];
export const SPACES_IMPORT_DIALOG_IS_DRAWER_OPEN_UI_KEY_PATH = [
  ...SPACES_IMPORT_DIALOG_UI_KEY_PATH,
  'isDrawerOpen',
];
export const SPACES_IMPORT_DIALOG_PAGE_UI_KEY_PATH = [
  ...SPACES_IMPORT_DIALOG_UI_KEY_PATH,
  'page',
];
export const SPACES_IMPORT_DIALOG_SORT_UI_KEY_PATH = [
  ...SPACES_IMPORT_DIALOG_UI_KEY_PATH,
  'sort',
];
export const SPACES_IMPORT_DIALOG_ACTIVE_TAB_IS_LOADING_UI_KEY_PATH = [
  ...SPACES_IMPORT_DIALOG_UI_KEY_PATH,
  'activeTabIsLoading',
];

export const PER_PAGE = 100;

export const ACCOUNTS_I_OWN_GROUP_ID = 'accountsIOwn';
export const ACCOUNTS_I_OWN_USERS_FIELD_VALUE = 'usersField';
export const ACCOUNTS_I_OWN_SALESFORCE_FIELD_FIELD_VALUE =
  'associatedSalesforceField';
