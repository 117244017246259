import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const ContactIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, 'fill-current')}
  >
    <path d="M11.5 8A1.5 1.5 0 0 1 13 9.5v.5c0 1.971-1.86 4-5 4-3.14 0-5-2.029-5-4v-.5A1.5 1.5 0 0 1 4.5 8h7zm0 1h-7a.5.5 0 0 0-.5.5v.5c0 1.438 1.432 3 4 3s4-1.562 4-3v-.5a.5.5 0 0 0-.5-.5zM8 1.5A2.75 2.75 0 1 1 8 7a2.75 2.75 0 0 1 0-5.5zm0 1A1.75 1.75 0 1 0 8 6a1.75 1.75 0 0 0 0-3.5z" />
  </svg>
);
