import { FC } from 'react';
import { WysiwygEditor } from '@groove/wysiwyg-editor';
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';
import useUserMergeFields from '@groove/api/hooks/useUserMergeFields';
import tinymce from 'tinymce';
import { useDebouncedCallback } from 'use-debounce';
import { fireAllOutsideCLickHandlers } from '@groove/ui/helpers/useHandleOutsideClicks';

import useStore, { PaneValues } from '../store/useStore';
import { ACTION_ORIGIN } from '../constants';

type WEditorProps = {
  onChange: (body: string) => void;
  initialValue: string;
  id: string | number;
  showScheduler?: boolean;
};

const WEditor: FC<WEditorProps> = ({
  onChange,
  initialValue,
  id,
  showScheduler,
}) => {
  const wysiwygDemoEnabled = useVariation('wysiwyg-editor-demo');

  const wysiwygEdited = useStore(store => store.otherValues.wysiwygEdited);
  const invokingApp = useStore(store => store.otherValues.invokingApp);

  const mergeFields = useUserMergeFields();

  const fontFamily =
    invokingApp === ACTION_ORIGIN.ADDIN
      ? 'Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif'
      : 'arial,helvetica,sans-serif';

  const otherBodyChange = (body: string): void => {
    useStore.getState().setOtherValues({
      wysiwygSelection: tinymce.activeEditor?.selection?.getSel(),
    });
    useStore.getState().updateAction({ body });
  };

  const [debounceBodyChange] = useDebouncedCallback(otherBodyChange, 250);

  const onBodyChange = (body: string): void => {
    useStore.getState().setOtherValues({ wysiwygEdited: true });
    debounceBodyChange(body);
  };

  const paneValues: PaneValues = {
    selectionStart: 0,
    selectionEnd: 0,
  };

  return (
    <div className="relative flex-1">
      <WysiwygEditor
        // for some reason, the initial value is being accepted
        // multiple times, causing a reset of the cursor position
        // to the beginning of the editor. This is a workaround
        // to prevent that from happening.
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(!wysiwygEdited && { initialValue })}
        mergeFieldProps={{
          mergeFields,
        }}
        onChange={onBodyChange}
        config={{
          height: '100%',
          content_style: `
            body {
              font-family: ${fontFamily};
              font-size: ${
                invokingApp === ACTION_ORIGIN.ADDIN ? '11pt' : 'small'
              };
              margin: 8px;
            }
            `,
          fontsize_formats: [
            'small',
            '8pt',
            '10pt',
            '11pt',
            '12pt',
            '14pt',
            '18pt',
            '24pt',
            '36pt',
          ].join(' '),
        }}
        inconsistentFormattingFeatureFlag={!!wysiwygDemoEnabled}
        onTemplateClick={() => useStore.getState().toggleTemplate(paneValues)}
        onSchedulerClick={
          showScheduler
            ? () => useStore.getState().toggleScheduler(paneValues)
            : undefined
        }
        onEditorClick={() => {
          fireAllOutsideCLickHandlers();
        }}
      />
    </div>
  );
};

export default WEditor;
