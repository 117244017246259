import { List, Map } from 'immutable';
import { getActivePersonId } from 'Modules/WorkStep/selectors';
import { createSelector } from 'reselect';
import { getTemplateEditorHasBeenModified } from './templateAddEdit';

export const getTemplateFolders = state =>
  state.getIn(['ui', 'templateFolderStructure'], List());

const getStepEditorWysiwygData = state =>
  state.getIn(['ui', 'wysiwygData'], new Map());

export const getWysiwygFocusedValue = state =>
  state.getIn(['ui', 'wysiwyg', 'focused'], false);

/**
 * @description no dirtiness check, only check if anyone else visited
 * workstep creates body key when it is focused in stepEditor
 */
const getMultipleWorkStepPeopleHaveBeenVisited = createSelector(
  getStepEditorWysiwygData,
  getActivePersonId,
  (workStepPeopleEditorValues, activePersonId) =>
    !!workStepPeopleEditorValues.find(
      (value, key) => value.has('body') && `${activePersonId}` !== `${key}`
    )
);

export const getEditorHasBeenModified = createSelector(
  getTemplateEditorHasBeenModified,
  getMultipleWorkStepPeopleHaveBeenVisited,
  (editorModified, multipleWorkStepPeopleVisited) =>
    editorModified || multipleWorkStepPeopleVisited
);
