// GrooveEngine transforms the payload in a strange way
// when the data is empty an empty, so we need to check for that
const transformGrooveEngineResult = <T>(results: T | { data: T }): T => {
  if ((results as { data: T }).data) {
    return (results as { data: T }).data;
  }

  return results as T;
};

export default transformGrooveEngineResult;
