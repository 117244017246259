import * as React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@groove-labs/groove-ui';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { ModifiedTheme } from 'Utils/theme/ClariTheme';
import { ERROR_DIALOG_UI_KEY_PATH } from 'Modules/Shared/constants';
import Error from 'Modules/Shared/components/ErrorStates/Error';
import ConfusedMan from 'Modules/Shared/components/Images/ConfusedMan';

const { setProperty } = actions.ui;

const useStyles = makeStyles<ModifiedTheme>(theme => ({
  root: {
    width: 400,
    height: 390,
    display: 'flex',
    borderRadius: 6,
  },
  paper: {
    paddingTop: 18,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.text.neutralColor,
    color: theme.palette.text.primary,
  },
  closeButtonContainer: {
    position: 'absolute',
    right: 12,
  },
}));

const ErrorDialog: React.FC<ErrorDialogPropTypes> = ({
  showRecoveryButton,
  showSubmitFeedbackButton,
  onRecovery,
  primaryText,
  secondaryText,
  icon,
}) => {
  const { closeButtonContainer, root, paper } = useStyles();
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);

    dispatch(
      setProperty({
        uiKeyPath: ERROR_DIALOG_UI_KEY_PATH,
        data: false,
      })
    );
  };

  return (
    <Dialog classes={{ paper: root }} open={open} onBackdropClick={handleClose}>
      <IconButton
        onClick={handleClose}
        classes={{ root: closeButtonContainer }}
      >
        <CloseIcon />
      </IconButton>
      <div className={paper}>
        <Error
          icon={icon}
          showRecoveryButton={showRecoveryButton}
          showSubmitFeedbackButton={showSubmitFeedbackButton}
          onRecovery={onRecovery}
          primaryText={primaryText}
          secondaryText={secondaryText}
        />
      </div>
    </Dialog>
  );
};

type ErrorDialogPropTypes = {
  showRecoveryButton?: boolean;
  showSubmitFeedbackButton?: boolean;
  primaryText?: string;
  onRecovery?: () => void;
  secondaryText?: string;
  recoveryButtonText?: string;
  previousPath?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
};

ErrorDialog.defaultProps = {
  showRecoveryButton: false,
  showSubmitFeedbackButton: false,
  primaryText: 'Something went wrong',
  onRecovery: null,
  secondaryText: 'We logged the error and are looking into it',
  recoveryButtonText: 'Reload Page',
  previousPath: '',
  icon: <ConfusedMan />,
};

export default ErrorDialog;
