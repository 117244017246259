import { takeEvery } from 'redux-saga/effects';
import {
  createSafeActionHandler,
  ANALYTICS_BASE_PROPERTIES,
} from 'Modules/Shared/sagas/analytics';
import { actionTypes } from 'Modules/SharingDialog/actions';
import { trackEvent } from 'Utils/segment';

function* entitySharingUpdated() {
  yield takeEvery(
    actionTypes.UPDATE_SUCCESSFUL,
    createSafeActionHandler(({ payload }) => {
      const { entityType } = payload;

      trackEvent('Sharing Updated', {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory: entityType,
        entityType,
      });
    })
  );
}

export default entitySharingUpdated;
