import { ActionType, SourceType } from '@groove/api/gateway/v1/actions';
import ActivityIcon from '@groove/omnibar/icons/ActivityIcon';
import NotesIcon from '@groove/omnibar/icons/NotesIcon';
import SalesforceIcon from '@groove/omnibar/icons/SalesforceIcon';
import GrooveIcon from '@groove/omnibar/icons/GrooveIcon';
import LinkedInIcon from '@groove/omnibar/icons/LinkedInIcon';

// TODO: extract to a more general purpose constants file

// Older icons from: apps/web-app/src/js/modules/Actions/constants/index.js
import {
  Email as EmailIcon,
  Call as CallIcon,
  ContactMail as DirectMailIcon,
  ChatBubble as SmsIcon,
  Business as InPersonVisitIcon,
} from '@material-ui/icons';

// import LinkedInIcon from 'Modules/Shared/icons/linkedIn';
// import OtherIcon from 'Modules/ActionCompose/components/Pin';
import PinIcon from 'Modules/Actions/components/PinIcon';
import SendosoIcon from 'Modules/Shared/icons/sendoso';

type ActionTypes = {
  [key in ActionType]: string;
};

export const TYPES = {
  EMAIL: 'TEMPLATE',
  CALL: 'CALL',
  IN_MAIL: 'STEP_SUBTYPE_LINKEDIN_INMAIL',
  LINKEDIN_CONNECT: 'STEP_SUBTYPE_LINKEDIN_CONNECT',
  SENDOSO: 'STEP_SUBTYPE_SENDOSO',
  SMS: 'STEP_SUBTYPE_SMS',
  // Maybe set
  GENERAL: 'GENERAL',
  OTHER: 'OTHER',
  // Are these still a thing?
  IN_PERSON_VISIT: 'STEP_SUBTYPE_IN_PERSON_VISIT',
  DIRECT_MAIL: 'STEP_SUBTYPE_DIRECT_MAIL',
  WORKSPACE: 'WORKSPACE_ACCOUNT_TASK',
  SALESFORCE: 'UPDATE_SALESFORCE',
} as ActionTypes;

// Note: Reduced this to only types
export const READABLE_NAME_LOOK_UP = Object.freeze({
  [TYPES.EMAIL]: 'Email',
  [TYPES.CALL]: 'Call',
  [TYPES.IN_MAIL]: 'LinkedIn InMail',
  [TYPES.LINKEDIN_CONNECT]: 'LinkedIn Connect',
  [TYPES.SENDOSO]: 'Sendoso',
  [TYPES.SMS]: 'SMS',
  [TYPES.IN_PERSON_VISIT]: 'In Person Visit',
  [TYPES.DIRECT_MAIL]: 'Direct Mail',
  [TYPES.OTHER]: 'Other',
  [TYPES.GENERAL]: 'Task',
});

// Modified the PinIcon
export const ACTION_TYPE_ICONS = {
  [TYPES.EMAIL]: EmailIcon,
  [TYPES.CALL]: CallIcon,
  [TYPES.OTHER]: () => <PinIcon fill="white" />,
  [TYPES.IN_PERSON_VISIT]: InPersonVisitIcon,
  [TYPES.SMS]: SmsIcon,
  [TYPES.DIRECT_MAIL]: DirectMailIcon,
  [TYPES.LINKEDIN_CONNECT]: LinkedInIcon,
  [TYPES.IN_MAIL]: LinkedInIcon,
  [TYPES.SENDOSO]: () => <SendosoIcon />,
  [TYPES.GENERAL]: () => <PinIcon fill="white" />,
};

// Are these used?
export const OTHER_ICONS = [
  ActivityIcon,
  NotesIcon,
  SalesforceIcon,
  GrooveIcon,
];

type SourcesType = {
  [key in SourceType]: SourceType;
};

export const SOURCES = {
  FLOW: 'FLOW',
  WORKSPACE: 'WORKSPACE',
  ONE_OFF: 'ONE_OFF',
  PLAY: 'PLAY',
} as SourcesType;
