import { CLARI_COLORS } from 'theme-constants';

export const PrimaryButtonStyle = {
  backgroundColor: `${CLARI_COLORS['clari-blue/600']} !important`,
  color: CLARI_COLORS.white,
  fontWeight: 600,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: `${CLARI_COLORS['clari-blue/700']} !important`,
    color: CLARI_COLORS.white,
    boxShadow: 'none',
  },
  '&:active': {
    padding: '0.5625rem 0.8125rem',
    background: CLARI_COLORS['clari-blue/800'],
    boxSizing: 'border-box',
    border: `1px solid ${CLARI_COLORS['clari-blue/800']}`,
    color: CLARI_COLORS.white,
    boxShadow: 'none',
  },
  '&:disabled': {
    color: `${CLARI_COLORS['neutral/400']} !important`,
    backgroundColor: `${CLARI_COLORS['neutral/75']} !important`,
  },
};

export const SecondaryButtonStyle = {
  color: `${CLARI_COLORS['neutral/900']} !important`,
  backgroundColor: CLARI_COLORS.white,
  border: `1px solid ${CLARI_COLORS['neutral/200']}`,
  fontWeight: 600,
  boxShadow: 'none',
  '&:hover': {
    border: `1px solid ${CLARI_COLORS['neutral/900']}`,
    backgroundColor: CLARI_COLORS.white,
    boxShadow: 'none',
  },
  '&:active': {
    border: `1px solid ${CLARI_COLORS['clari-blue/900']}`,
    backgroundColor: CLARI_COLORS.white,
    boxShadow: 'none',
  },
  '&:disabled': {
    border: `1px solid ${CLARI_COLORS['neutral/75']}`,
    background: `${CLARI_COLORS['neutral/75']} !important`,
    color: `${CLARI_COLORS['neutral/400']} !important`,
  },
};
