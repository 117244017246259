import { FC } from 'react';

const SendosoPane: FC = () => {
  return (
    <div className="flex-1" data-testid="sendoso-pane">
      <iframe
        className="h-full w-full ml-[16px]"
        title="sendoso-action"
        src="https://app.sendoso.com/v2/plugin/sends"
      />
    </div>
  );
};

export default SendosoPane;
