import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';
import { SagaIterator } from 'redux-saga';
import { FSA } from 'flux-standard-action';
import { Map } from 'immutable';

import { fetchBegin } from 'Modules/Settings/actions/automatedActions';
import {
  actionTypes as automatedActionsActionTypes,
  saveSuccess as automatedActionSaveSuccess,
} from 'Modules/FlowsShow/actions/automatedActions';
import { location as locationSelector } from 'Modules/Shared/selectors/location';
import { getFlow } from 'Modules/FlowsShow/selectors';
import {
  create as createOrgAutomatedAction,
  destroy as destroyOrgAutomatedAction,
  update as updateOrgAutomatedAction,
} from 'GrooveHTTPClient/admin/automatedActions';
import {
  create as createFlowAutomatedAction,
  destroy as destroyFlowAutomatedAction,
  update as updateFlowAutomatedAction,
} from 'GrooveHTTPClient/flows/automatedActions';
import {
  actionTypes,
  SavePayload,
} from 'Modules/Shared/actions/automatedActions';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';

function* handleSave({
  payload: {
    automatedActionsToCreate,
    automatedActionsToUpdate,
    automatedActionIdsToDestroy,
  },
}: FSA<string, SavePayload>): SagaIterator {
  const location = yield select(locationSelector);

  if (location.get('routeName') === 'flowsShow') {
    const flow: Map<string, unknown> = yield select(getFlow);
    const flowId = flow.get('id');

    const saveResponses = yield all([
      ...automatedActionsToCreate.toJSON().map(automatedAction =>
        call(createFlowAutomatedAction, {
          flowId,
          automatedAction: decamelizeKeys(automatedAction.toJS()),
        })
      ),
      ...automatedActionsToUpdate.toJSON().map(automatedAction =>
        call(updateFlowAutomatedAction, {
          flowId,
          automatedAction: decamelizeKeys(automatedAction.toJS()),
        })
      ),
    ]);
    yield all(
      saveResponses.map((response: { data: unknown }) =>
        put(automatedActionSaveSuccess(null, response.data))
      )
    );

    yield all(
      automatedActionIdsToDestroy.toJSON().map(automatedActionId =>
        call(destroyFlowAutomatedAction, {
          flowId,
          automatedAction: { id: automatedActionId },
        })
      )
    );

    yield all(
      automatedActionIdsToDestroy.toJSON().map(id =>
        put({
          type: automatedActionsActionTypes.DESTROY.SUCCESS,
          payload: { id },
        })
      )
    );
  } else {
    yield all([
      ...automatedActionsToCreate
        .toJSON()
        .map(automatedAction =>
          call(createOrgAutomatedAction, automatedAction)
        ),
      ...automatedActionsToUpdate
        .toJSON()
        .map(automatedAction =>
          call(updateOrgAutomatedAction, automatedAction)
        ),
      ...automatedActionIdsToDestroy
        .toJSON()
        .map(automatedActionId =>
          call(destroyOrgAutomatedAction, automatedActionId)
        ),
    ]);

    yield put(fetchBegin());
  }

  if (!automatedActionsToUpdate.isEmpty()) {
    yield put(pushSnackbarMessage({ message: 'Automated Action Updated' }));
  } else if (!automatedActionsToCreate.isEmpty()) {
    yield put(pushSnackbarMessage({ message: 'Automated Action Created' }));
  } else if (!automatedActionIdsToDestroy.isEmpty()) {
    yield put(pushSnackbarMessage({ message: 'Automated Action Deleted' }));
  }
}

function* watchSave(): SagaIterator {
  yield takeEvery<FSA<string, SavePayload>>(actionTypes.SAVE.BEGIN, handleSave);
}

export default function* root(): SagaIterator {
  yield all([fork(watchSave)]);
}
