import { Record } from 'immutable';

const defaults = {
  personFlowId: null,
  day: null,
  flowName: null,
  flowId: null,
  stepId: null,
};

export default class FlowMetadata extends Record(defaults) {
  static from({ personFlowId, day, flowName, flowId, stepId }) {
    return new FlowMetadata({
      personFlowId,
      day,
      flowName,
      flowId,
      stepId,
    });
  }
}
