import {
  isAnalyticsEnabled,
  mixpanelSafeTrack,
} from '@groove/api/analytics/hooks/useMixpanel';
import {
  FullAction,
  NEW_ACTION_ID,
} from '@groove/api/gateway/v1/actionCompose';

import {
  ACTION_SOURCE,
  ACTION_STATE,
  ACTION_EVENT,
  ACTION_ORIGIN,
  ACTION_TYPE,
  ACTION_VERSION,
} from '../constants';
// To be used for MixPanel tracking

export const baseActionEventsProperties = {
  app: 'Actions',
};

export function getSource(action: FullAction): string {
  const {
    playNodeRunId,
    personStepId,
    workspaceAccountTaskId,
    workspaceSObjectTaskId,
  } = action;
  if (playNodeRunId) {
    return ACTION_SOURCE.PLAY;
  }
  if (personStepId) {
    return ACTION_SOURCE.FLOW;
  }
  if (workspaceAccountTaskId || workspaceSObjectTaskId) {
    return ACTION_SOURCE.WORKSPACE;
  }
  return ACTION_SOURCE.ONE_OFF;
}

function getActionState(actionState: string, id: string): string {
  if (actionState === ACTION_STATE.ERROR) {
    return ACTION_STATE.ERROR;
  }
  if (!id || id === NEW_ACTION_ID) {
    return ACTION_STATE.NEW;
  }
  return ACTION_STATE.EXISTING;
}

function createEventString(actionState: string, eventName?: string): string {
  if (eventName === ACTION_EVENT.CLOSED) {
    return `${eventName} Action`;
  }
  if (
    actionState === ACTION_STATE.EXISTING ||
    (actionState === ACTION_STATE.NEW && eventName)
  ) {
    return `${actionState} Action ${eventName}`;
  }
  return actionState;
}

export function getOrigin(platform?: string): ACTION_ORIGIN {
  if (platform === 'OUTLOOK' || window.location.href.includes('grooveapp')) {
    return ACTION_ORIGIN.ADDIN;
  }
  if (window.location.href.includes('mail.google.com')) {
    return ACTION_ORIGIN.GMAIL;
  }
  if (
    window.location.href.includes('lightning.force.com') ||
    window.location.href.includes('salesforce.com')
  ) {
    return ACTION_ORIGIN.SALESFORCE;
  }
  return ACTION_ORIGIN.WEBAPP;
}

type ActionEventProps = {
  eventName: string;
  action: FullAction;
  actionVersion: ACTION_VERSION;
  errorCode?: string;
};

export function trackActionEvents(props: ActionEventProps): void {
  const { eventName, action, actionVersion, errorCode } = props;
  if (!isAnalyticsEnabled()) return;

  const { type, id } = action;

  // if the action is a flow, set the origin to the flow, otherwise use getOrigin
  const actionOrigin = action.personStepId ? ACTION_ORIGIN.FLOWS : getOrigin();
  const actionState = errorCode
    ? ACTION_STATE.ERROR
    : getActionState(eventName, id);

  const actionOpenedFrom =
    actionState === ACTION_STATE.EXISTING ? actionOrigin : undefined;
  const actionCreationFrom =
    actionState === ACTION_STATE.NEW ? actionOrigin : undefined;

  const application = actionOrigin || ACTION_ORIGIN.WEBAPP;

  const eventString = `${application}: Actions: ${createEventString(
    actionState,
    eventName,
  )}`;

  mixpanelSafeTrack(eventString, {
    ...baseActionEventsProperties,
    action_type: ACTION_TYPE[type],
    action_source: getSource(action),
    action_version: actionVersion,
    action_id: id,
    action_creation_from: actionCreationFrom, // figure out how to send one or the other.
    action_open_from: actionOpenedFrom, // rename it to match the name in the ticket
    error_code: errorCode,
    user_id: action.assignee?.id || action.creatorId,
  });
}
export { ACTION_EVENT, ACTION_VERSION, ACTION_ORIGIN };
