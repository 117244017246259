import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    textAlign: 'center',
    height: '100vh',
    flexDirection: 'column',
    position: 'relative',
    left: '-65px',
    width: 'calc(100% + 65px)',
    backgroundColor: theme.palette.text.backgroundColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    marginTop: 32,
  },
});

const RefreshSalesforceMeta = ({ classes }) => {
  return (
    <div className={classes.root}>
      <CircularProgress size={50} />
      <Typography variant="subtitle1" className={classes.description}>
        Gathering your Salesforce configuration…
      </Typography>
    </div>
  );
};

RefreshSalesforceMeta.displayName = 'RefreshSalesforceMeta';
RefreshSalesforceMeta.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(RefreshSalesforceMeta);
