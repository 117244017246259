import theme from '@groove/ui/theme';
import {
  IPersonaProps,
  NormalPeoplePicker,
  Separator,
  Text,
  Icon,
} from '@fluentui/react';
import grooveSearch from '@groove/api/visualforce/grooveSearch';
import RecordMenuItem from '@groove/ui/Components/RecordMenuItem';
import { FC, Fragment, useEffect } from 'react';
import useGrooveMessageEvent from '@groove/api/hooks/useGrooveMessageEvent';
import {
  isContactOrLead,
  isLead,
} from '@groove/api/helpers/sfdcObjectPrefixes';
import * as Sentry from '@sentry/browser';

import transformGrooveEngineResult from '../transformGrooveEngineResults';
import transformSearchResult, {
  DisplayableSearchResult,
} from '../transformSearchResult';

import useStore, { SelectedItem } from './useStore';
import SmallCheckbox from './SmallCheckbox';

const pickerStyles = {
  root: 'm-2',
  text: 'border-neutral/200 hover:border-neutral/900 rounded after:border-clari-blue/600 after:rounded',
};

type RelatedToProps = {
  disableMultiple: boolean;
  onOpenFullSearchAndSelect?: (searchQuery: string) => void;
  onSelectedItemChange?: (isSelected: boolean, item: SelectedItem) => void;
  handleOmnibarContextChange?: (id: string) => void;
  onSearch?: () => void;
};

const RelatedTo: FC<RelatedToProps> = ({
  disableMultiple,
  onOpenFullSearchAndSelect,
  onSelectedItemChange,
  handleOmnibarContextChange,
  onSearch,
}) => {
  const searchQuery = useStore(store => store.relatedToSearchQuery);
  const setSearchQuery = useStore(store => store.setRelatedToSearchQuery);

  const handleResolveSuggestions = async (
    filter: string,
  ): Promise<IPersonaProps[]> => {
    if (filter.length < 3) {
      return [];
    }
    Sentry.setTag('component', 'search-and-select-mini');
    const data = await grooveSearch(filter);

    const items = transformGrooveEngineResult(data)
      .flat?.()
      .map(transformSearchResult)
      .filter(result => !isContactOrLead(result.id));

    return [{ key: 'ADVANCED_SEARCH' }, ...items] as IPersonaProps[];
  };

  const { data: grooveMessageEvent } = useGrooveMessageEvent({
    cacheTime: 0,
    staleTime: 0,
  });

  const selectedNames = useStore(store => store.selectedNames);
  const suggestedRelatedTos = useStore(store => store.suggestedRelatedTos);
  const selectedRelatedTos = useStore(store => store.selectedRelatedTos);
  const setSelectedRelatedTos = useStore(store => store.setSelectedRelatedTos);
  const setSelectedTab = useStore(store => store.setSelectedTab);
  const recentlySearchedRelatedTos = useStore(
    store => store.recentlySearchedRelatedTos,
  );
  const setRecentlySearchedRelatedTos = useStore(
    store => store.setRecentlySearchedRelatedTos,
  );

  const shouldDisableMultiple =
    (grooveMessageEvent?.bestMatchEmailLogging &&
      grooveMessageEvent.bestMatchAllowMultiSelect) ||
    !grooveMessageEvent?.bestMatchEmailLogging;
  const canSelectMultiple = !disableMultiple && shouldDisableMultiple;

  useEffect(() => {
    if (selectedNames.some(name => isLead(name.id))) {
      setSelectedTab('name');
    }
  }, [selectedNames, setSelectedTab]);

  return (
    <div className="flex flex-col flex-grow">
      <NormalPeoplePicker
        inputProps={{
          placeholder: 'Type at least 3 characters to search…',
          'aria-label': 'Search Related To',
          type: 'search',
          value: searchQuery,
        }}
        onInputChange={input => {
          setSearchQuery(input);
          if (onSearch) {
            onSearch();
          }
          return input;
        }}
        pickerSuggestionsProps={{
          suggestionsContainerAriaLabel: 'Search Results',
        }}
        styles={pickerStyles}
        onResolveSuggestions={handleResolveSuggestions}
        resolveDelay={300}
        onRenderItem={props => <Fragment key={props.item.id} />}
        selectedItems={selectedRelatedTos}
        onItemSelected={item => {
          if (item) {
            if (item.key === 'ADVANCED_SEARCH') {
              onOpenFullSearchAndSelect?.(searchQuery);
              return null;
            }
            if (canSelectMultiple) {
              setSelectedRelatedTos([
                ...selectedRelatedTos,
                item as SelectedItem,
              ]);
            } else {
              setSelectedRelatedTos([item as SelectedItem]);
            }
            setRecentlySearchedRelatedTos([
              ...(recentlySearchedRelatedTos || []),
              item as SelectedItem,
            ]);
          }

          return item || null;
        }}
        onRenderSuggestionsItem={props =>
          props.key === 'ADVANCED_SEARCH' ? (
            <div className="flex items-center w-full h-8 px-3">
              <Icon iconName="Search" className="mr-4" />
              <Text
                className="font-semibold whitespace-pre-wrapA"
                block
                variant="medium"
              >
                Open &quot;{searchQuery}&quot; in advanced search
              </Text>
            </div>
          ) : (
            <RecordMenuItem
              id={props.id as string}
              key={props.id}
              text={props.text}
              secondaryText={(props as DisplayableSearchResult).secondaryText}
              secondaryTextLabel={
                (props as DisplayableSearchResult).secondaryTextLabel
              }
              tertiaryText={(props as DisplayableSearchResult).tertiaryText}
              tertiaryTextLabel={
                (props as DisplayableSearchResult).tertiaryTextLabel
              }
              quarteraryText={(props as DisplayableSearchResult).quarteraryText}
              quarteraryTextLabel={
                (props as DisplayableSearchResult).quarteraryTextLabel
              }
              quinaryText={(props as DisplayableSearchResult).quinaryText}
              quinaryTextLabel={
                (props as DisplayableSearchResult).quinaryTextLabel
              }
              handleOmnibarContextChange={handleOmnibarContextChange}
            />
          )
        }
      />
      <div className="flex-grow h-1 overflow-y-auto">
        {recentlySearchedRelatedTos.length > 0 && (
          <div className="p-2">
            <Text block className="text-sm text-base font-semibold">
              Recently Searched
            </Text>
            <Separator className="h-1 py-0 before:bg-gray4" />

            {recentlySearchedRelatedTos.map(item => (
              <div key={item.id} className="flex items-center pl-1">
                {!canSelectMultiple && (
                  <input
                    type="radio"
                    aria-label={`Recently Searched ${item.text}`}
                    className="checked:bg-gray-200"
                    style={{ accentColor: theme.secondary }}
                    checked={selectedRelatedTos?.some(
                      selectedRelatedTo => selectedRelatedTo.id === item.id,
                    )}
                    onChange={() => {
                      setSelectedRelatedTos([item]);
                      onSelectedItemChange?.(true, item);
                    }}
                  />
                )}
                {canSelectMultiple && (
                  <SmallCheckbox
                    ariaLabel={`Recently Searched ${item.text}`}
                    checked={selectedRelatedTos?.some(
                      selectedRelatedTo => selectedRelatedTo.id === item.id,
                    )}
                    onChange={(_e, checked) => {
                      if (checked) {
                        if (canSelectMultiple) {
                          setSelectedRelatedTos([...selectedRelatedTos, item]);
                        } else {
                          setSelectedRelatedTos([item]);
                        }
                      } else {
                        setSelectedRelatedTos(
                          selectedRelatedTos.filter(
                            selectedRelatedTo =>
                              selectedRelatedTo.id !== item.id,
                          ),
                        );
                      }
                      onSelectedItemChange?.(!!checked, item);
                    }}
                  />
                )}
                <RecordMenuItem
                  fullWidth
                  showLinkToSalesforce
                  id={item.id as string}
                  text={item.text || ''}
                  secondaryText={item.secondaryText}
                  secondaryTextLabel={item.secondaryTextLabel}
                  tertiaryText={item.tertiaryText}
                  tertiaryTextLabel={item.tertiaryTextLabel}
                  quarteraryText={item.quarteraryText}
                  quarteraryTextLabel={item.quarteraryTextLabel}
                  quinaryText={item.quinaryText}
                  quinaryTextLabel={item.quinaryTextLabel}
                  handleOmnibarContextChange={handleOmnibarContextChange}
                />
              </div>
            ))}
          </div>
        )}
        {suggestedRelatedTos.length > 0 && (
          <div className="p-2">
            <Text block className="text-sm text-base font-semibold">
              Suggested
            </Text>

            <Separator className="h-1 py-0 before:bg-gray4" />
            {suggestedRelatedTos?.map(item => (
              <div key={item.id} className="flex items-center pl-1">
                {!canSelectMultiple && (
                  <input
                    type="radio"
                    aria-label={`Suggested ${item.text}`}
                    className="checked:bg-gray-200"
                    style={{ accentColor: theme.secondary }}
                    checked={selectedRelatedTos?.some(
                      selectedRelatedTo => selectedRelatedTo.id === item.id,
                    )}
                    onChange={() => {
                      setSelectedRelatedTos([item]);
                      onSelectedItemChange?.(true, item);
                    }}
                  />
                )}
                {canSelectMultiple && (
                  <SmallCheckbox
                    ariaLabel={`Suggested ${item.text}`}
                    checked={selectedRelatedTos?.some(
                      selectedRelatedTo => selectedRelatedTo.id === item.id,
                    )}
                    onChange={(_e, checked) => {
                      if (checked) {
                        if (canSelectMultiple) {
                          setSelectedRelatedTos([...selectedRelatedTos, item]);
                        } else {
                          setSelectedRelatedTos([item]);
                        }
                      } else {
                        setSelectedRelatedTos(
                          selectedRelatedTos.filter(
                            selectedRelatedTo =>
                              selectedRelatedTo.id !== item.id,
                          ),
                        );
                      }
                      onSelectedItemChange?.(!!checked, item);
                    }}
                  />
                )}
                <RecordMenuItem
                  fullWidth
                  showLinkToSalesforce
                  id={item.id as string}
                  text={item.text || ''}
                  secondaryText={item.secondaryText}
                  secondaryTextLabel={item.secondaryTextLabel}
                  tertiaryText={item.tertiaryText}
                  tertiaryTextLabel={item.tertiaryTextLabel}
                  quarteraryText={item.quarteraryText}
                  quarteraryTextLabel={item.quarteraryTextLabel}
                  quinaryText={item.quinaryText}
                  quinaryTextLabel={item.quinaryTextLabel}
                  handleOmnibarContextChange={handleOmnibarContextChange}
                />
              </div>
            ))}
          </div>
        )}
        <div className="h-[50px]" />
      </div>
    </div>
  );
};

export default RelatedTo;
