import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';

import FiltersRow from 'Modules/ActionsRewrite/components/filtersRow/FiltersRow';
import PausedFlowsWarning from 'Modules/Shared/containers/PausedFlowsWarning';
import ChipsRow from 'Modules/ActionsRewrite/components/ChipsRow';
import TableTabs from 'Modules/ActionsRewrite/components/tableTabs/TableTabs';
import HeaderSection from 'Modules/ActionsRewrite/components/HeaderSection';
import ActionsTable from 'Modules/ActionsRewrite/components/ActionsTable';
import { loadAction } from '@groove/action-compose/utils/actionComposeHelpers';
import { NEW_ACTION_ID } from '@groove/api/gateway/v1/actionCompose';
import AddIcon from '@material-ui/icons/Add';
import { TableProvider } from '@clari/iris-table';

type ActionsTableEntryParams = {
  inLoadingState?: boolean;
};

const useStyles = makeStyles(_theme => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 24,
    justifyContent: 'flex-start',
  },
  pausedFlowContainer: {
    position: 'absolute',
    right: 22,
    top: 55,
  },
  fadeContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  newActionButton: {
    width: 100,
  },
}));

const ActionsTableEntry: FC<ActionsTableEntryParams> = ({
  inLoadingState = false,
}) => {
  const classes = useStyles();

  // Below are temporary
  const arePausedFlows = true;

  const handleCreateNewAction = () => {
    loadAction({ id: NEW_ACTION_ID });
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <HeaderSection />
        <ChipsRow />
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            onClick={handleCreateNewAction}
            color="primary"
            variant="contained"
            className={classes.newActionButton}
          >
            <AddIcon />
            New
          </Button>
        </Box>
      </div>
      <div className={classes.fadeContainer}>
        {/* {arePausedFlows && (
            <div className={classes.pausedFlowContainer}>
              <PausedFlowsWarning />
            </div>
          )} */}
        <FiltersRow />
        <TableTabs />
        <TableProvider>
          <ActionsTable />
        </TableProvider>
      </div>
    </>
  );
};

export default ActionsTableEntry;
