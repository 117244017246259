import { QueryClient, useQuery, UseQueryResult } from 'react-query';

import { findOrCreatePerson, FindPerson } from '../gateway/v1/people';

export type FetchFindOrCreatePersonParams = {
  client: QueryClient;
  sfdcId: string;
};

export const useFindOrCreatePerson = (
  sfdcId: string,
): UseQueryResult<FindPerson, unknown> => {
  return useQuery(
    ['findOrCreatePerson', sfdcId],
    () => findOrCreatePerson(sfdcId),
    {
      cacheTime: 1000 * 2,
      staleTime: 1000 * 2,
      enabled: sfdcId.length > 0,
    },
  );
};

export const fetchFindOrCreatePerson = ({
  client,
  sfdcId,
}: FetchFindOrCreatePersonParams): Promise<FindPerson> =>
  client.fetchQuery(
    ['findOrCreatePerson', sfdcId],
    () => findOrCreatePerson(sfdcId),
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
    },
  );
