import { List, Map } from 'immutable';
import { keyBy } from 'lodash-es';
import { createSelector } from 'reselect';
import { getAssignableUsersData } from 'Modules/PeopleImportDialog/selectors';
import { getLdClient } from 'Utils/ldClient';

const ROOT_KEY_PATH = ['PeopleImportDialog', 'csvLookup'];

export const getUploading = state =>
  state.getIn([...ROOT_KEY_PATH, 'uploading']);

export const getCsvRows = state => state.getIn([...ROOT_KEY_PATH, 'csvRows']);

export const getFoundValidRow = state =>
  state.getIn([...ROOT_KEY_PATH, 'foundValidRow']);
export const getErrorMessage = state =>
  state.getIn([...ROOT_KEY_PATH, 'error']);
export const getCsvHeader = state =>
  state.getIn([...ROOT_KEY_PATH, 'csvHeader']);
export const getKeyPrefixes = state =>
  state.getIn([...ROOT_KEY_PATH, 'keyPrefixes']);
export const getGroupId = state => state.getIn([...ROOT_KEY_PATH, 'groupId']);
export const getFetching = state => state.getIn([...ROOT_KEY_PATH, 'fetching']);
export const getInitialLookupByColumnIdx = state =>
  state.getIn([...ROOT_KEY_PATH, 'initialLookupByColumnIdx']);
export const getInitialSelectedObject = state =>
  state.getIn([...ROOT_KEY_PATH, 'initialSelectedObject']);
export const getAvailableObjectFields = state =>
  state.getIn([...ROOT_KEY_PATH, 'availableObjectFields']);
export const getMandatoryObjectFields = state =>
  state.getIn([...ROOT_KEY_PATH, 'mandatoryObjectFields']);
export const getSelectedObjectField = state =>
  state.getIn([...ROOT_KEY_PATH, 'selectedObjectField']);
export const getSelectedObjectFieldIdx = state =>
  state.getIn([...ROOT_KEY_PATH, 'selectedObjectFieldIdx']);
export const getRelationshipName = state =>
  state.getIn([...ROOT_KEY_PATH, 'relationshipName']);
export const getUploadErrorMessage = state =>
  state.getIn([...ROOT_KEY_PATH, 'uploadErrorMessage']);

export const getAssignBasedOnUserColumnIdx = state =>
  state.getIn([...ROOT_KEY_PATH, 'assignBasedOnUserColumnIdx']);

export const getPersonFlowOwnerIds = createSelector(
  [
    getCsvRows,
    getAssignableUsersData,
    getSelectedObjectField,
    getSelectedObjectFieldIdx,
    getAssignBasedOnUserColumnIdx,
  ],
  (
    csvRows,
    assignableUsers,
    getSelectedObjectField,
    selectedObjectFieldIdx,
    assignBasedOnUserColumnIdx
  ) => {
    const csvLookupAssignOwners = getLdClient()?.variation(
      'csv-lookup-assign-owners'
    );

    if (!csvLookupAssignOwners || assignBasedOnUserColumnIdx === undefined) {
      return null;
    }

    const selectedLookupColumnKey = getSelectedObjectField.toLowerCase();

    const assignableUsersBySfdcId = keyBy(assignableUsers, 'sfdcUserId');

    return csvRows.reduce((personFlowOwnerIds, row) => {
      if (
        selectedLookupColumnKey !== 'email' &&
        selectedLookupColumnKey !== 'id'
      ) {
        throw new Error('Invalid CSV: Selected lookup column is not supported');
      }

      const rowKeyValue = row.get(selectedObjectFieldIdx);

      if (!rowKeyValue)
        throw new Error(
          'Invalid CSV: Blank value in the selected Lookup Column'
        );

      const userSfdcId = row.get(assignBasedOnUserColumnIdx);

      if (!userSfdcId)
        throw new Error('Invalid CSV: Blank value in the Assign User Column');

      const userGrooveId = assignableUsersBySfdcId[userSfdcId]?.id;
      const userName = assignableUsersBySfdcId[userSfdcId]?.name;

      return personFlowOwnerIds.set(
        rowKeyValue.toLowerCase(),
        new Map({
          name: userName,
          sfdcId: userSfdcId,
          grooveId: userGrooveId,
        })
      );
    }, new Map());
  }
);

export const getLookupColumnOptions = createSelector(
  getCsvHeader,
  csvHeader => {
    if (!csvHeader) return null;
    return csvHeader.reduce((options, label, idx) => {
      return options.push({
        value: idx,
        label,
      });
    }, new List());
  }
);

export const getLookupColumnName = createSelector(
  [getCsvHeader, getSelectedObjectFieldIdx],
  (csvHeader, index) => {
    const name = csvHeader?.get(index);

    return name;
  }
);

export const getAssignBasedOnUserColumn = state =>
  state.getIn([...ROOT_KEY_PATH, 'assignBasedOnUserColumn']);
export const getAssignBasedOnUserColumnCheckboxIsActive = state => {
  const userColumnIdxFound = getAssignBasedOnUserColumnIdx(state);

  return userColumnIdxFound;
};

export const getIsAssignBasedOnUserColumnFound = state => {
  const userColumnIdx = getAssignBasedOnUserColumnIdx(state);

  return userColumnIdx !== undefined;
};
