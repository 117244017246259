import cx from 'classnames';
import { Spinner } from '@fluentui/react';

import Text from '../Text';

export const spinner = (
  <div className="flex h-[32px] justify-center">
    <Spinner />
  </div>
);

export const picklistMessageItem = (
  emptyText: string | JSX.Element,
  smallBold = false,
): JSX.Element => (
  <div className="flex text-left w-full bg-white items-center overflow-hidden p-0 relative min-h-[32px]">
    <Text
      className={cx(
        'p-[8px] my-auto',
        smallBold && 'text-neutral/600 text-metadata-sm font-semibold',
        !smallBold && 'text-metadata text-neutral/400',
      )}
    >
      {emptyText}
    </Text>
  </div>
);
