import { useInfiniteQuery } from 'react-query';

import { ColumnDefinitionKey } from 'Modules/ActionsRewrite/components/columnDefs/keysToColumnDefinitions';
import {
  getActions,
  ActionsFilter,
  OrderType,
  processDueAtFilter,
  DueAtOperator,
} from '@groove/api/gateway/v1/actions';
import { TabName } from 'Modules/ActionsRewrite/store/useStore';

const SORT_FIELD_MAP = {
  type: 'TYPE',
  summary: 'TITLE',
  source: 'SOURCE',
  priority: 'PRIORITY',
  due_at: 'DUE_AT',
  'assignee.name': 'MANAGED_BY',
  'who.company': 'COMPANY',
  'who.name': 'WHO_NAME',
  'who.tzid': 'TIMEZONE',
  smart_priority: 'SMART_PRIORITY',
};

export default function useGetActionsInfinitely(
  sortedColumnKeys: ColumnDefinitionKey[],
  activeTab: TabName,
  order?: OrderType,
  filter?: Partial<ActionsFilter>
) {
  return useInfiniteQuery(
    ['getActionsInfinitely', activeTab, ...sortedColumnKeys, order, filter],
    ({ pageParam = 1 }) =>
      getActions({
        page: pageParam,
        per_page: 30,
        tab: activeTab,
        filter: {
          ...filter,
          due_at: processDueAtFilter(
            filter?.due_at as unknown as DueAtOperator[]
          ),
        },
        order: order
          ? { ...order, field: SORT_FIELD_MAP[order.field] }
          : undefined,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.length > 0) {
          return allPages.length + 1;
        }
        return undefined; // TODO: block the fetch when next param is undefined
      },
      onError: (err: unknown) => {
        console.error(err);
      },
    }
  );
}
