import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const EmailRecipient = ({ recipient }) => {
  return <Typography variant="caption">{recipient}</Typography>;
};

EmailRecipient.displayName = 'EmailRecipient';
EmailRecipient.propTypes = {
  recipient: PropTypes.string.isRequired,
};

export default EmailRecipient;
