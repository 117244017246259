import { actions, selectors } from '@groove-labs/groove-ui';
import * as peopleImportHTTPClient from 'GrooveHTTPClient/peopleImport';
import { handleInvalidSalesforceConnectionHTTPRequest } from 'GrooveHTTPClient/sagas';
import { List, OrderedMap } from 'immutable';
import { getFlowId } from 'Modules/FlowsShow/selectors';
import {
  actionTypes as peopleImportActionTypes,
  requestProcessSearchResults,
  setNoResultsFound,
  setSalesforceObject,
  setSearching,
} from 'Modules/PeopleImportDialog/actions';
import {
  salesforceObject as getSalesforceObject,
  relationshipName as getRelationshipName,
} from 'Modules/PeopleImportDialog/selectors';
import { actionTypes } from 'Modules/PeopleImportDialog/submodules/listView/actions';
import { loadData } from 'Modules/PeopleImportDialog/submodules/peopleTable/actions';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import {
  all,
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

const { actionTypes: formActionTypes, setToInitialFieldState } = actions.form;
const { makeGetIsGroupRegistered, makeGetIsFieldRegistered } = selectors.form;

// -------------- Handlers --------------

function* clearListViews() {
  const listViewsSidebarRegistered = yield select(
    makeGetIsGroupRegistered('listViewsSidebar')
  );
  const listViewRegistered = yield select(
    makeGetIsFieldRegistered('listViewsSidebar', 'listView')
  );
  if (listViewsSidebarRegistered && listViewRegistered) {
    yield put(
      setToInitialFieldState({
        groupId: 'listViewsSidebar',
        fieldId: 'listViews',
      })
    );
  }
}

function* fetchListViews({ payload }) {
  yield put(setSalesforceObject(payload));
  yield* clearListViews();

  yield put({ type: actionTypes.FETCH_LIST_VIEWS.BEGIN });

  yield put({ type: actionTypes.FETCH_LIST_VIEWS.PROGRESS });
  try {
    const response = yield* handleInvalidSalesforceConnectionHTTPRequest(
      peopleImportHTTPClient.listViews,
      payload
    );
    yield put({
      type: actionTypes.FETCH_LIST_VIEWS.SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield put({ type: actionTypes.FETCH_LIST_VIEWS.FAILURE });
  }
}

function* fetchListView({ payload }) {
  yield put(
    loadData({
      rows: new List(),
      columns: new List(),
      selectedRows: new OrderedMap(),
    })
  );

  yield put(setNoResultsFound(false));
  yield put(setSearching(true));

  let salesforceObject = yield select(getSalesforceObject);
  salesforceObject = `${salesforceObject
    .charAt(0)
    .toUpperCase()}${salesforceObject.slice(1)}`;
  const relationshipName = yield select(getRelationshipName);
  yield put({ type: actionTypes.FETCH_LIST_VIEW.BEGIN });

  yield put({ type: actionTypes.FETCH_LIST_VIEW.PROGRESS });
  try {
    // Fetch a list view and parse results
    // and cancel the action when a tab is switched
    const flowId = yield select(getFlowId);
    const { response, cancel } = yield race({
      response: call(
        handleInvalidSalesforceConnectionHTTPRequest,
        peopleImportHTTPClient.listView,
        {
          sObjectType: salesforceObject,
          listViewId: payload.selectedData.id,
          relationshipName,
          flowId,
        }
      ),
      cancel: take(peopleImportActionTypes.REQUEST_SET_ACTIVE_TAB),
    });

    if (cancel) {
      yield put(setSearching(false));
      return;
    }

    yield put({ type: actionTypes.FETCH_LIST_VIEW.SUCCESS });
    yield put(requestProcessSearchResults(response));
  } catch (e) {
    yield put({ type: actionTypes.FETCH_LIST_VIEW.FAILURE });
    yield put(
      pushSnackbarMessage({
        message: 'Something went wrong searching for people',
      })
    );
    yield put(setSearching(false));
    yield cancel();
  }
}

// -------------- Watchers --------------

function* watchRequestSetSalesforceObject() {
  yield takeEvery(
    peopleImportActionTypes.REQUEST_SET_SALESFORCE_OBJECT,
    fetchListViews
  );
}

function* watchSelectListView() {
  yield takeEvery(
    action =>
      action.type === formActionTypes.UPDATE_FIELD_VALUE &&
      action.payload.fieldId === 'listViews' &&
      action.payload.selectedData,
    fetchListView
  );
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([
    fork(watchRequestSetSalesforceObject),
    fork(watchSelectListView),
    fork(watchRequestSetSalesforceObject),
  ]);
}
