import create from 'zustand';

export type SelectedItem = {
  id: string;
  type: string | null;
  text: string;
  secondaryText?: string;
  secondaryTextLabel?: string;
  tertiaryText?: string;
  tertiaryTextLabel?: string;
  quarteraryText?: string;
  quarteraryTextLabel?: string;
  quinaryText?: string;
  quinaryTextLabel?: string;
};

type Tab = 'name' | 'relatedTo' | 'activityFields' | 'review';

export const dedupeSelectedItemFilter = (
  selectedItem: SelectedItem,
  index: number,
  selectedItems: SelectedItem[],
): boolean =>
  selectedItems.findIndex(name => name.id === selectedItem.id) === index;

export type Store = {
  context: string;
  setContent: (content: string) => void;
  selectedTab: Tab;
  setSelectedTab: (tab: Tab) => void;
  additionalFieldsType: 'email' | 'calendar' | null;
  setAdditionalFieldsType: (type: 'email' | 'calendar') => void;
  nameSearchQuery: string;
  setNameSearchQuery: (searchQuery: string) => void;
  relatedToSearchQuery: string;
  setRelatedToSearchQuery: (searchQuery: string) => void;
  additionalFields: {
    [key: string]: string | number | boolean | null;
  };
  setAdditionalField: (
    key: string,
    value: string | number | boolean | null,
  ) => void;
  setAdditionalFields: (additionalFields: {
    [key: string]: string | number | boolean | null;
  }) => void;
  selectedNames: SelectedItem[];
  setSelectedNames: (selectedNames: SelectedItem[]) => void;
  selectedRelatedTos: SelectedItem[];
  setSelectedRelatedTos: (selectedRelatedTos: SelectedItem[]) => void;

  suggestedNames: SelectedItem[];
  suggestedRelatedTos: SelectedItem[];

  setSuggestedNames: (suggestedNames: SelectedItem[]) => void;
  setSuggestedRelatedTos: (suggestedRelatedTos: SelectedItem[]) => void;

  recentlySearchedNames: SelectedItem[];
  recentlySearchedRelatedTos: SelectedItem[];

  setRecentlySearchedNames: (recentlySearchedNames: SelectedItem[]) => void;
  setRecentlySearchedRelatedTos: (
    recentlySearchedRelatedTos: SelectedItem[],
  ) => void;
  reset: () => void;
  hasPerformedASearch: boolean;
  populatingSearchAndSelectMini: boolean;
  setPopulatingSearchAndSelectMini: (isPopulating: boolean) => void;
};

const defaultStoreValues: Partial<Store> = {
  context: '',
  selectedTab: 'name',
  additionalFieldsType: 'email',
  nameSearchQuery: '',
  relatedToSearchQuery: '',
  additionalFields: {},
  selectedNames: [],
  selectedRelatedTos: [],
  suggestedNames: [],
  suggestedRelatedTos: [],
  recentlySearchedNames: [],
  recentlySearchedRelatedTos: [],
  hasPerformedASearch: false,
  populatingSearchAndSelectMini: false,
};

const useStore = create<Store>((set, get) => ({
  ...(defaultStoreValues as Store),
  setContent: (context: string) => set({ context }),
  setSelectedTab: selectedTab => set({ selectedTab }),
  setAdditionalFieldsType: (additionalFieldsType: 'email' | 'calendar') =>
    set({ additionalFieldsType }),
  setNameSearchQuery: (searchQuery: string) => {
    set({ nameSearchQuery: searchQuery });
  },
  setRelatedToSearchQuery: (searchQuery: string) => {
    set({ relatedToSearchQuery: searchQuery });
  },
  setPopulatingSearchAndSelectMini: (isPopulating: boolean) => {
    set({ populatingSearchAndSelectMini: isPopulating });
  },
  setAdditionalField: (
    key: string,
    value: string | number | boolean | null,
  ) => {
    const { additionalFields } = get();
    if (value === null) {
      delete additionalFields[key];
      set({ additionalFields });
    } else {
      set({
        additionalFields: {
          ...additionalFields,
          [key]: value,
        },
      });
    }
  },
  setAdditionalFields: (additionalFields: {
    [key: string]: string | number | boolean | null;
  }) => {
    set({
      additionalFields,
    });
  },
  setSelectedNames: (selectedNames: SelectedItem[]) => {
    const dedupedSelectedNames = selectedNames.filter((selectedName, index) =>
      dedupeSelectedItemFilter(selectedName, index, selectedNames),
    );
    set({ selectedNames: dedupedSelectedNames, hasPerformedASearch: true });
  },
  setSelectedRelatedTos: (selectedRelatedTos: SelectedItem[]) => {
    const dedupedSelectedRelatedTos = selectedRelatedTos.filter(
      (selectedRelatedTo, index) =>
        dedupeSelectedItemFilter(selectedRelatedTo, index, selectedRelatedTos),
    );
    set({
      selectedRelatedTos: dedupedSelectedRelatedTos,
      hasPerformedASearch: true,
    });
  },
  setRecentlySearchedNames: (recentlySearchedNames: SelectedItem[]) => {
    const dedupedRecentlySearchedNames = recentlySearchedNames.filter(
      (recentlySearchedName, index) =>
        dedupeSelectedItemFilter(
          recentlySearchedName,
          index,
          recentlySearchedNames,
        ),
    );
    set({
      recentlySearchedNames: dedupedRecentlySearchedNames.slice(-5),
      hasPerformedASearch: true,
    });
  },
  setRecentlySearchedRelatedTos: (
    recentlySearchedRelatedTos: SelectedItem[],
  ) => {
    const dedupedRecentlySearchedRelatedTos = recentlySearchedRelatedTos.filter(
      (recentlySearchedRelatedTo, index) =>
        dedupeSelectedItemFilter(
          recentlySearchedRelatedTo,
          index,
          recentlySearchedRelatedTos,
        ),
    );
    set({
      // only keep the last 5 recently searched related tos
      recentlySearchedRelatedTos: dedupedRecentlySearchedRelatedTos.slice(-5),
      hasPerformedASearch: true,
    });
  },
  setSuggestedNames: (suggestedNames: SelectedItem[]) => {
    const dedupedSuggestedNames = suggestedNames.filter(
      (suggestedName, index) =>
        dedupeSelectedItemFilter(suggestedName, index, suggestedNames),
    );
    set({ suggestedNames: dedupedSuggestedNames, hasPerformedASearch: true });
  },
  setSuggestedRelatedTos: (suggestedRelatedTos: SelectedItem[]) => {
    const dedupedSuggestedRelatedTos = suggestedRelatedTos.filter(
      (suggestedRelatedTo, index) =>
        dedupeSelectedItemFilter(
          suggestedRelatedTo,
          index,
          suggestedRelatedTos,
        ),
    );
    set({
      suggestedRelatedTos: dedupedSuggestedRelatedTos,
      hasPerformedASearch: true,
    });
  },
  reset: () => {
    set(defaultStoreValues as Store);
  },
}));

export default useStore;
