import cx from 'classnames';
import { IconButton, Modal, Text } from '@fluentui/react';
import { convert } from 'html-to-text';
import { FC } from 'react';
import Scheduler from '@groove/scheduler';
import { getOrganizationBrand } from '@groove/api/gateway/v1/organization_brand';
import generateHtml, {
  OrganizationBrandData,
} from '@groove/scheduler/utils/generateHtml';
import generatePlainText from '@groove/scheduler/utils/generatePlainText';

import useStore from '../store/useStore';

const modalLayerProps = {
  eventBubblingEnabled: true,
  styles: { content: 'groove' },
};

const AddSpecificTimesModal: FC = () => {
  const isOpen = useStore(store => store.externalModalValues.isSchedulerOpen);
  const scheduleForId = useStore(store => store.otherValues.scheduleForId);
  const meetingTypeId = useStore(store => store.otherValues.meetingTypeId);
  const type = useStore(store => store.action.type);
  const schedulerAttendees = useStore(
    store => store.otherValues.schedulerAttendees,
  );

  const onClose = (): void =>
    useStore.getState().setExternalModalValues({ isSchedulerOpen: false });

  return (
    <div>
      <Modal
        styles={{
          scrollableContent: cx(
            'flex flex-col p-8',
            'min-w-[80vw] min-h-[80vh] max-w-[100vw] max-h-[100vh] box-border',
            outerHeight > 700 && 'w-[80vw] h-[80vh]',
          ),
          main: 'overflow-hidden max-w-[100vw] max-h-[100vh]',
        }}
        isOpen={isOpen}
        ignoreExternalFocusing
        layerProps={modalLayerProps}
        isBlocking
        onDismiss={() => onClose()}
      >
        <div className="flex items-center justify-between w-full">
          <Text variant="xLarge">Groove Scheduler</Text>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            onClick={() => onClose()}
          />
        </div>
        <Scheduler
          storeScheduleForId={scheduleForId}
          storeMeetingTypeId={meetingTypeId}
          storeSchedulerAttendees={schedulerAttendees}
          onDismiss={() => {
            onClose();
          }}
          onSubmit={async data => {
            const organizationBrandData: { data: OrganizationBrandData } =
              await getOrganizationBrand();

            const isPlainSteps =
              data.plain_text_enabled ||
              type === 'STEP_SUBTYPE_SMS' ||
              type === 'STEP_SUBTYPE_LINKEDIN_INMAIL' ||
              type === 'STEP_SUBTYPE_LINKEDIN_CONNECT';

            const generator = isPlainSteps ? generatePlainText : generateHtml;

            let html = generator(
              data.time_slots,
              data.time_zone,
              data.schedule_for,
              organizationBrandData.data,
              data.schedule_for_meeting_link,
            );

            if (isPlainSteps) html = convert(html, { wordwrap: false });

            useStore.getState().insertAtCursorAndUpdateAction({ body: html });

            onClose();
          }}
        />
      </Modal>
    </div>
  );
};

export default AddSpecificTimesModal;
