import { createSelector } from 'reselect';
import { contactChildren } from 'Modules/Shared/selectors/salesforceMeta';

const ROOT_KEY_PATH = ['PeopleImportDialog', 'peopleTable'];

export const rows = state => state.getIn([...ROOT_KEY_PATH, 'rows']);

export const columns = state => state.getIn([...ROOT_KEY_PATH, 'columns']);

export const selectedRows = state =>
  state.getIn([...ROOT_KEY_PATH, 'selectedRows']);

export const accounts = state => state.getIn([...ROOT_KEY_PATH, 'accounts']);

export const campaigns = state => state.getIn([...ROOT_KEY_PATH, 'campaigns']);

export const opportunities = state =>
  state.getIn([...ROOT_KEY_PATH, 'opportunities']);

export const customObjects = state =>
  state.getIn([...ROOT_KEY_PATH, 'customObjects']);

export const activeSalesforceObject = state =>
  state.getIn([...ROOT_KEY_PATH, 'activeSalesforceObject']);

export const page = state =>
  state.getIn([...ROOT_KEY_PATH, 'pagination', 'page']);

export const perPage = state =>
  state.getIn([...ROOT_KEY_PATH, 'pagination', 'perPage']);

export const orderBy = state =>
  state.getIn([...ROOT_KEY_PATH, 'sorting', 'orderBy']);

export const orderDirection = state =>
  state.getIn([...ROOT_KEY_PATH, 'sorting', 'orderDirection']);

export const hideDuplicatesFromSearchResults = state =>
  state.getIn([...ROOT_KEY_PATH, 'hideDuplicatesFromSearchResults']);

export const selectAllWithoutWarnings = state =>
  state.getIn([...ROOT_KEY_PATH, 'selectAllWithoutWarnings']);

export const createGetActiveObjectLabel = () => {
  return createSelector(
    activeSalesforceObject,
    contactChildren,
    (activeSalesforceObject, contactChildren) => {
      const contactChild = contactChildren.find(
        object => object.get('objectQueryKey') === activeSalesforceObject
      );

      if (contactChild) {
        return contactChild.get('objectLabel');
      }

      return activeSalesforceObject;
    }
  );
};

export const getEngagedFlows = state =>
  state.getIn([...ROOT_KEY_PATH, 'engagedFlows']);

export const getEngagedFlowsStatus = state =>
  state.getIn([...ROOT_KEY_PATH, 'engagedFlowsStatus']);

export const makeGetEngagedFlows = personSfdcId =>
  createSelector(
    getEngagedFlows,
    engagedFlows => engagedFlows && engagedFlows.get(personSfdcId)
  );

export const makeGetEngagedFlowsStatus = personSfdcId =>
  createSelector(getEngagedFlowsStatus, engagedFlowsStatus =>
    engagedFlowsStatus.get(personSfdcId)
  );
export const getDuplicatePeopleCount = state =>
  state.getIn([...ROOT_KEY_PATH, 'duplicatePeopleCount']);

export const getDuplicatePeopleRows = state =>
  state.getIn([...ROOT_KEY_PATH, 'duplicatePeopleRows']);

export const getImportableRows = state =>
  state.getIn([...ROOT_KEY_PATH, 'importableRows']);

export const hasWarningPresentInRows = state =>
  state.getIn([...ROOT_KEY_PATH, 'hasWarningPresentInRows']);
