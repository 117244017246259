import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  FLOWS_SHOW: {
    ...keyMirror([
      'UPSTART',
      'LOAD_DATA',
      'UPDATE_STEP_INTRA_DAY_ORDER',
      'SAVE_STEP_ORDER',
      'SELECT_STEP',
      'SELECT_STAT_FILTER',
      'SELECT_PEOPLE',
      'UNSELECT_PEOPLE',
      'CLEAR_SELECTED_PEOPLE',
      'REQUEST_OPEN_PEOPLE_IMPORT_DIALOG',
      'REMOVE_PEOPLE_FROM_FLOW',
      'MOVE_PEOPLE_TO_FLOW',
      'CANCEL_SCHEDULED_SEND',
      'REMOVE_ON_MOVE_OPTION',
      'RESET_REMOVE_ON_MOVE_OPTION',
      'FETCH_ALL_STEPS_STATS',
      'FETCH_FLOW_PEOPLE',
      'RESET_REDUCER',
      'CHANGE_STATS_SCOPE',
      'SET_STATS_SCOPE',
      'SET_PEOPLE_FOR_CSV',
      'CHANGE_PEOPLE_ORDER_BEGIN',
      'CHANGE_PEOPLE_ORDER_SUCCESS',
      'CUSTOM_MERGE_FIELDS_UPSTART',
      'MERGE_FIELDS_TABLE_LOADING',
      'LOAD_CUSTOM_MERGE_FIELD_DATA',
      'UPDATE_MERGE_TABLE_ORDER',
      'UPDATE_MERGE_TABLE_PAGE',
      'MERGE_FIELDS_TABLE_SEARCH',
      'OPEN_FLOW_PREVIEW',
      'REFRESH_STEPS_DATA',
      'ADD_LABEL_INSTANCES',
      'REMOVE_LABEL_INSTANCES',
      'SET_EMAIL_ALIASES',
      'SET_FLOW_ALIAS',
      'SET_GLOBAL_ALIAS',
      'UPDATE_FLOW_ALIAS',
      'ASSOCIATE_CAMPAIGN',
      'REMOVE_CAMPAIGN',
      'FETCH_NEXT_PAGE',
      'FETCH_PREVIOUS_PAGE',
      'RELOAD_FLOW',
    ]),
    NEW_AUTOMATED_ACTION: keyMirror(['BEGIN', 'UPDATE', 'CONFIRM', 'CANCEL']),
    EDIT_AUTOMATED_ACTION: keyMirror(['BEGIN', 'UPDATE', 'CONFIRM', 'CANCEL']),
    CREATE_AUTOMATED_ACTION: standardActions(),
    UPDATE_AUTOMATED_ACTION: standardActions(),
    DESTROY_AUTOMATED_ACTION: standardActions(),
    EDIT_FLOW: standardActions(),
    COPY_FLOW: standardActions(),
    DELETE_FLOW: standardActions(),
    ARCHIVE_FLOW: standardActions(),
    ACTIVATE_FLOW: standardActions(),
    UPDATE_AUTO_IMPORT: standardActions(),
    UPDATE_PRIORITIZE_AUTO_IMPORT: standardActions(),
    FETCH_REPORT_INFO: standardActions(),
    DELETE_STEP: standardActions(),
    BATCH_MOVE_PEOPLE_TO_STEP: standardActions(),
    FETCH_STEP_STATS: standardActions(),
    FETCH_STEP_PEOPLE: standardActions(),
    REMOVE_PEOPLE_FROM_FLOW_REQUEST: standardActions(),
    UPDATE_PERSON_FLOW_DATA: standardActions(),
    RESUME_PERSON: standardActions(),
    DOWNLOAD_STATS_CSV: standardActions(),
    UPDATE_DEFAULT_ADDRESS_FIELD: standardActions(),
  },
}).FLOWS_SHOW;

export const associateCampaign = () => ({
  type: actionTypes.ASSOCIATE_CAMPAIGN,
});

export const removeCampaign = () => ({
  type: actionTypes.REMOVE_CAMPAIGN,
});

export function loadData(data) {
  return {
    type: actionTypes.LOAD_DATA,
    payload: data,
  };
}

export function openPeopleImportDialog() {
  return {
    type: actionTypes.REQUEST_OPEN_PEOPLE_IMPORT_DIALOG,
  };
}

export function saveStepOrder(dayNumber) {
  return {
    type: actionTypes.SAVE_STEP_ORDER,
    payload: { dayNumber },
  };
}

export function deleteStep(stepId) {
  return {
    type: actionTypes.DELETE_STEP.BEGIN,
    payload: stepId,
  };
}

export function deleteStepSuccess(stepId) {
  return {
    type: actionTypes.DELETE_STEP.SUCCESS,
    payload: stepId,
  };
}

export function updateStepIntraDayOrder({ stepId, dayNumber, intraDayOrder }) {
  return {
    type: actionTypes.UPDATE_STEP_INTRA_DAY_ORDER,
    payload: { stepId, intraDayOrder, dayNumber },
  };
}

export function updateAutoImport({ autoImportId }) {
  return {
    type: actionTypes.UPDATE_AUTO_IMPORT.BEGIN,
    payload: { autoImportId },
  };
}

export function updatePrioritizeAutoImportSuccess(prioritizeAutoImport) {
  return {
    type: actionTypes.UPDATE_PRIORITIZE_AUTO_IMPORT.SUCCESS,
    payload: prioritizeAutoImport,
  };
}

export function successfullyUpdatedAutoImport({ importType, id }) {
  return {
    type: actionTypes.UPDATE_AUTO_IMPORT.SUCCESS,
    payload: { importType, id },
  };
}

export function reloadFlow(flowId, reloadAutomatedActions = false) {
  return {
    type: actionTypes.RELOAD_FLOW,
    payload: { flowId, reloadAutomatedActions },
  };
}

export function fetchReportInfo(reportId) {
  return {
    type: actionTypes.FETCH_REPORT_INFO.BEGIN,
    payload: { reportId },
  };
}

export function copyFlow({ flowId, copyFlowData }) {
  return {
    type: actionTypes.COPY_FLOW.BEGIN,
    payload: { flowId, copyFlowData },
  };
}

export function deleteFlow({ flowId }) {
  return {
    type: actionTypes.DELETE_FLOW.BEGIN,
    payload: { flowId },
  };
}

export function archiveFlow({ flowId }) {
  return {
    type: actionTypes.ARCHIVE_FLOW.BEGIN,
    payload: { flowId },
  };
}

export function activateFlow({ flowId }) {
  return {
    type: actionTypes.ACTIVATE_FLOW.BEGIN,
    payload: { flowId },
  };
}

export function selectStep(stepId) {
  return {
    type: actionTypes.SELECT_STEP,
    payload: { stepId },
  };
}

export function selectStatFilter(statName) {
  return {
    type: actionTypes.SELECT_STAT_FILTER,
    payload: { statName },
  };
}

export function fetchStepStatsBegin(stepId) {
  return {
    type: actionTypes.FETCH_STEP_STATS.BEGIN,
    payload: { stepId },
  };
}

export function fetchStepStatsSuccess(stepId, data) {
  const stats = data.getIn(['summary', 'primary']);

  return {
    type: actionTypes.FETCH_STEP_STATS.SUCCESS,
    payload: { stepId, stats },
  };
}

export function fetchAllStepStatsSuccess(stats) {
  return {
    type: actionTypes.FETCH_ALL_STEPS_STATS,
    payload: { stats },
  };
}

export function fetchFlowPeopleSuccess(
  filter,
  peopleById,
  peopleWithSteps,
  meta
) {
  return {
    type: actionTypes.FETCH_FLOW_PEOPLE,
    payload: { filter, peopleById, peopleWithSteps, meta },
  };
}

export function fetchStepPeopleBegin() {
  return {
    type: actionTypes.FETCH_STEP_PEOPLE.BEGIN,
  };
}

export function fetchStepPeopleSuccess(stepId, filter, people, meta) {
  return {
    type: actionTypes.FETCH_STEP_PEOPLE.SUCCESS,
    payload: { stepId, filter, people, meta },
  };
}

export function fetchStepPeopleFailure() {
  return {
    type: actionTypes.FETCH_STEP_PEOPLE.FAILURE,
  };
}

export function fetchNextPage() {
  return {
    type: actionTypes.FETCH_NEXT_PAGE,
  };
}

export function fetchPreviousPage() {
  return {
    type: actionTypes.FETCH_PREVIOUS_PAGE,
  };
}

export function setPeopleForCSVSuccess({ people }) {
  return {
    type: actionTypes.SET_PEOPLE_FOR_CSV,
    payload: { people },
  };
}

export function selectPeople(people) {
  return {
    type: actionTypes.SELECT_PEOPLE,
    payload: { people },
  };
}

export function unselectPeople(people) {
  return {
    type: actionTypes.UNSELECT_PEOPLE,
    payload: { people },
  };
}

export function clearSelectedPeople() {
  return {
    type: actionTypes.CLEAR_SELECTED_PEOPLE,
  };
}

export function beginMovingPeopleToStep(dayNumber) {
  return {
    type: actionTypes.BATCH_MOVE_PEOPLE_TO_STEP.BEGIN,
    payload: dayNumber,
  };
}

export function successfullyMovedPeopleToStep() {
  return {
    type: actionTypes.BATCH_MOVE_PEOPLE_TO_STEP.SUCCESS,
  };
}

export function failedMovingPeopleToStep() {
  return {
    type: actionTypes.BATCH_MOVE_PEOPLE_TO_STEP.FAILURE,
  };
}

export function movePeopleToFlow(flowId, selectedPeople) {
  return {
    type: actionTypes.MOVE_PEOPLE_TO_FLOW,
    payload: { flowId, selectedPeople },
  };
}

export function removePeopleFromFlow() {
  return {
    type: actionTypes.REMOVE_PEOPLE_FROM_FLOW,
  };
}

export function cancelScheduledSend() {
  return {
    type: actionTypes.CANCEL_SCHEDULED_SEND,
  };
}

export function toggleRemoveOnMoveOption(reset = false) {
  if (reset) {
    // reset the reducer to reflect default state of false
    return {
      type: actionTypes.RESET_REMOVE_ON_MOVE_OPTION,
    };
  }
  return {
    type: actionTypes.REMOVE_ON_MOVE_OPTION,
  };
}

export function beginRequestRemovePeopleFromFlow() {
  return {
    type: actionTypes.REMOVE_PEOPLE_FROM_FLOW_REQUEST.BEGIN,
  };
}

export function requestRemovePeopleFromFlowProgress() {
  return {
    type: actionTypes.REMOVE_PEOPLE_FROM_FLOW_REQUEST.PROGRESS,
  };
}

export function requestRemovePeopleFromFlowSucccess() {
  return {
    type: actionTypes.REMOVE_PEOPLE_FROM_FLOW_REQUEST.SUCCESS,
  };
}

export function editFlowBegin(groupId, flowId, flowData = null) {
  return {
    type: actionTypes.EDIT_FLOW.BEGIN,
    payload: { groupId, flowId, flowData },
  };
}

export function editFlowProgress() {
  return {
    type: actionTypes.EDIT_FLOW.PROGRESS,
  };
}

export function editFlowSuccess(flow) {
  return {
    type: actionTypes.EDIT_FLOW.SUCCESS,
    payload: { flow },
  };
}

export function changeStatsScope(scope) {
  return {
    type: actionTypes.CHANGE_STATS_SCOPE,
    payload: { scope },
  };
}

export function setStatsScope(scope) {
  return {
    type: actionTypes.SET_STATS_SCOPE,
    payload: { scope },
  };
}

export function changePeopleOrderBegin(columnName) {
  return {
    type: actionTypes.CHANGE_PEOPLE_ORDER_BEGIN,
    payload: { columnName },
  };
}

export function changePeopleOrderSuccess(orderBy, orderDirection) {
  return {
    type: actionTypes.CHANGE_PEOPLE_ORDER_SUCCESS,
    payload: { orderBy, orderDirection },
  };
}

export function customMergeFieldsUpstart() {
  return {
    type: actionTypes.CUSTOM_MERGE_FIELDS_UPSTART,
  };
}

export function loadCustomMergeFieldData({ columns, people, total }) {
  return {
    type: actionTypes.LOAD_CUSTOM_MERGE_FIELD_DATA,
    payload: { columns, people, total },
  };
}

export function setMergeFieldsTableLoading(payload) {
  return {
    type: actionTypes.MERGE_FIELDS_TABLE_LOADING,
    payload,
  };
}

export function updatePersonFlowData(payload) {
  return {
    type: actionTypes.UPDATE_PERSON_FLOW_DATA.BEGIN,
    payload,
  };
}

export function updatePersonFlowDataSuccess(payload) {
  return {
    type: actionTypes.UPDATE_PERSON_FLOW_DATA.SUCCESS,
    payload,
  };
}

export function updateCustomMergeTableOrder({ orderBy, orderDirection }) {
  return {
    type: actionTypes.UPDATE_MERGE_TABLE_ORDER,
    payload: { orderBy, orderDirection },
  };
}

export function updateCustomMergeTablePage(page, perPage) {
  return {
    type: actionTypes.UPDATE_MERGE_TABLE_PAGE,
    payload: { page, perPage },
  };
}

export function customMergeFieldsSearch() {
  return {
    type: actionTypes.MERGE_FIELDS_TABLE_SEARCH,
  };
}

export function openFlowPreview(flowId) {
  return {
    type: actionTypes.OPEN_FLOW_PREVIEW,
    payload: { flowId },
  };
}

export function refreshStepsData(flowId) {
  return {
    type: actionTypes.REFRESH_STEPS_DATA,
    payload: { flowId },
  };
}

export function resumePerson({ personId, flowId }) {
  return {
    type: actionTypes.RESUME_PERSON.BEGIN,
    payload: { personId, flowId },
  };
}

export function addLabelInstances({ labelInstances }) {
  return {
    type: actionTypes.ADD_LABEL_INSTANCES,
    payload: { labelInstances },
  };
}

export function removeLabelInstances({ ids }) {
  return {
    type: actionTypes.REMOVE_LABEL_INSTANCES,
    payload: { ids },
  };
}

export function downloadStatsCsvBegin() {
  return {
    type: actionTypes.DOWNLOAD_STATS_CSV.BEGIN,
  };
}

export function setEmailAliases(emailAliases) {
  return {
    type: actionTypes.SET_EMAIL_ALIASES,
    payload: emailAliases,
  };
}

export function setFlowAlias(flowAlias) {
  return {
    type: actionTypes.SET_FLOW_ALIAS,
    payload: flowAlias,
  };
}

export function setGlobalAlias(globalAlias) {
  return {
    type: actionTypes.SET_GLOBAL_ALIAS,
    payload: globalAlias,
  };
}

export function updateFlowAlias(flowAlias) {
  return {
    type: actionTypes.UPDATE_FLOW_ALIAS,
    payload: flowAlias,
  };
}

export function updateDefaultAddressField(
  defaultAddressFieldKey,
  newDefaultFieldName
) {
  return {
    type: actionTypes.UPDATE_DEFAULT_ADDRESS_FIELD.BEGIN,
    payload: {
      defaultAddressFieldKey,
      newDefaultFieldName,
    },
  };
}
