import { FC, useLayoutEffect } from 'react';
import { LocalDialIcon } from '@groove/ui/Components/BoogieIcon';
import useDialerStore from '@groove/dialer-components/hooks/useStore';
import { useBestLocalNumber } from '@groove/api/hooks/useCommonDialer';
import {
  LOCAL_DIAL_PARSE_ERROR,
  LOCAL_DIAL_PROVISION_ERROR,
} from '@groove/api/gateway/v1/commonDialer';
import { useGetCurrentUser } from '@groove/api/hooks/useUsers';

import { dialerErrorValidation } from '../../utils/dialer/localDialHelpers';
import useStore from '../../store/useStore';

import DialerBarButton from './DialerBarButton';

const LocalDialButton: FC = () => {
  const toRecipients = useStore(store => store.action.toRecipients);
  const callState = useDialerStore(store => store.callState);
  const isLocalDialEnabled = useDialerStore(store => store.isLocalDialEnabled);
  const setLocalDialEnabled = useDialerStore(
    store => store.setLocalDialEnabled,
  );

  const { data: userData } = useGetCurrentUser();
  const { data: localPhoneData } = useBestLocalNumber(
    { toPhoneNumber: toRecipients?.[0]?.phone || '' },
    !!toRecipients?.[0]?.phone && !!userData?.phone_number,
  );

  const stringifiedLocalPhoneData = JSON.stringify(localPhoneData);
  useLayoutEffect(() => {
    dialerErrorValidation(localPhoneData, isLocalDialEnabled);
    return () => {
      useStore.getState().removeMessage(LOCAL_DIAL_PARSE_ERROR);
      useStore.getState().removeMessage(LOCAL_DIAL_PROVISION_ERROR);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedLocalPhoneData, isLocalDialEnabled]);

  const isLocalDialDisabled =
    callState !== 'not-connected' || !toRecipients?.[0]?.phone;
  let localDialTooltip =
    'Click to make a phone call using a phone number local to the recipient';

  const onClick = (): void => {
    setLocalDialEnabled(!isLocalDialEnabled || false);
  };

  if (callState !== 'not-connected') {
    if (isLocalDialEnabled) {
      localDialTooltip = 'Call is in progress, cannot turn off local dial';
    } else {
      localDialTooltip = 'Call is in progress, cannot turn on local dial';
    }
  }
  if (!toRecipients?.[0]?.phone) {
    localDialTooltip = 'Please select a valid phone number';
  }

  return (
    <DialerBarButton
      label="Local"
      onClick={onClick}
      selected={isLocalDialEnabled}
      tooltip={localDialTooltip}
      disabled={isLocalDialDisabled}
    >
      <LocalDialIcon className="w-[20px]" />
    </DialerBarButton>
  );
};

export default LocalDialButton;
