import { DynamicForm, FormField } from '@groove/api/gateway/v1/actionCompose';
import { FC } from 'react';

import CustomField from './CustomField';

export type CustomFormProps = {
  formName: 'loggingForm';
  formData: DynamicForm;
};

const CustomForm: FC<CustomFormProps> = ({ formName, formData }) => {
  return (
    <div>
      {formData?.fields?.map((field: FormField) => (
        <CustomField key={field.name} formName={formName} fieldData={field} />
      ))}
    </div>
  );
};

export default CustomForm;
