import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { columnHeaderChange } from 'Modules/Spaces/show/actions/firestore';
import CustomHeaderInput from 'Modules/Spaces/show/containers/CustomHeaderInput';
import ColumnCellDropdown from 'Modules/Spaces/show/containers/AccountsTableWrapper/Shared/ColumnCellDropdown';

const muiClasses = {
  inputRoot: {
    width: 'calc(100% - 60px)',
  },
  svgIconRoot: {
    marginRight: 10,
  },
};

const htmlClasses = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 10,
    flexGrow: 1,
  },
};

const styles = {
  ...muiClasses,
  ...htmlClasses,
};

@withStyles(styles)
@connect(
  () => {
    return {};
  },
  { columnHeaderChange }
)
export default class CustomColumnHeaderCell extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string,
      inputRoot: PropTypes.string,
      svgIconRoot: PropTypes.string,
    }).isRequired,
    // Redux State
    columnId: PropTypes.string.isRequired,
    // Redux Actions
  };

  render() {
    const { classes, columnId } = this.props;
    return (
      <div className={classes.root}>
        <CustomHeaderInput columnId={columnId} />
        <ColumnCellDropdown id={columnId} />
      </div>
    );
  }
}
