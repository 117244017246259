import { createSelector } from 'reselect';

const getValues = (state, ...pathParts) =>
  state.getIn(['accountPreview', ...pathParts]);

const getAddingPeopleToFlow = state => getValues(state, 'addingPeopleToFlow');
const getAllPeople = state => getValues(state, 'people', 'all');
const getPeopleCount = state => getValues(state, 'people', 'total');
const getQuery = state => getValues(state, 'query');
const getRemovingPeopleFromAllFlows = state =>
  getValues(state, 'removingPeopleFromAllFlows');
const getSelectedUnassociatedContacts = state =>
  getValues(state, 'people', 'selected', 'unassociatedContacts');
const getSelectedActiveFlowPeople = state =>
  getValues(state, 'people', 'selected', 'activeFlow');
const isLoading = state => getValues(state, 'isLoading');
const getActivePersonId = state => getValues(state, 'activePersonId');
const getUnassociatedContacts = state =>
  getValues(state, 'people', 'unassociatedContacts');
const getFetchingUnassociatedContacts = state =>
  getValues(state, 'fetchingUnassociatedContacts');
const displayUnassociatedContacts = state =>
  getValues(state, 'displayUnassociatedContacts');
const getActiveAccountId = state => getValues(state, 'activeAccountId');
const isUnassociatedContactSelected = (state, contactId) => {
  const selectedUnassociatedContacts = getSelectedUnassociatedContacts(state);
  return selectedUnassociatedContacts.has(contactId);
};

const getActivePerson = createSelector(
  getAllPeople,
  getActivePersonId,
  (allPeople, activePersonId) =>
    allPeople.find(person => person.get('id') === activePersonId)
);

const getActiveUnassociatedContact = createSelector(
  getUnassociatedContacts,
  getSelectedUnassociatedContacts,
  (allUnassociatedContacts, selectedUnassociatedContactId) =>
    allUnassociatedContacts.find(
      contact => contact.get('sfdcId') === selectedUnassociatedContactId.last()
    )
);

const getPaneVisibility = state =>
  state.getIn(['accountPreview', 'paneVisibility']);

const getIsAccountLoading = state =>
  state.getIn(['accountPreview', 'isLoadingAccount']);

const getOneFlowRestrictionData = state =>
  getValues(state, 'people', 'selected', 'oneFlowRestrictionData');
const getIsLoadingModal = state =>
  getValues(state, 'people', 'selected', 'loadingModal');
const getSelectedPeopleAvailableToJoinFlow = createSelector(
  getSelectedActiveFlowPeople,
  getOneFlowRestrictionData,
  (selectedPeople, oneFlowRestrictionData) =>
    selectedPeople.filterNot(person => oneFlowRestrictionData.get(`${person}`))
);

export {
  getPaneVisibility,
  getActivePerson,
  getActiveUnassociatedContact,
  getAddingPeopleToFlow,
  getAllPeople,
  getPeopleCount,
  getQuery,
  getRemovingPeopleFromAllFlows,
  isLoading,
  getActivePersonId,
  getUnassociatedContacts,
  getSelectedActiveFlowPeople,
  getSelectedUnassociatedContacts,
  getFetchingUnassociatedContacts,
  displayUnassociatedContacts,
  getActiveAccountId,
  getIsAccountLoading,
  isUnassociatedContactSelected,
  getIsLoadingModal,
  getSelectedPeopleAvailableToJoinFlow,
  getOneFlowRestrictionData,
};
