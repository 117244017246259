import * as querystring from 'querystring';

import cx from 'classnames';
import { ChangeEvent, FC, useMemo, useEffect, useRef, useState } from 'react';
import {
  useGetCurrentUser,
  useGetOrgSettings,
} from '@groove/api/hooks/useUsers';
import { useGetLinkedinToken } from '@groove/api/hooks/useLinkedin';
import WysiwygLite from '@groove/ui/Components/WysiwygLite';
import Button from '@groove/ui/Components/Button';
import { CheckmarkCircle } from '@groove/ui/Components/BoogieIcon';
import { MessageBar, MessageBarType, MessageBarButton } from '@fluentui/react';
import Input from '@groove/ui/Components/Input';
import Text from '@groove/ui/Components/Text';

import useStore, { PaneValues } from '../store/useStore';
import RecipientSelector from '../component/RecipientSelector';
import { LINKEDIN_ACTIONS_URL } from '../constants';
import openMergeModal from '../component/MergeModal';

const LinkedInPane: FC = () => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [copied, setCopied] = useState(true);
  const [copiedSubject, setCopiedSubject] = useState(true);
  const [tokenError, setTokenError] = useState(true);

  const toRecipients = useStore(store => store.action.toRecipients);
  const type = useStore(store => store.action.type);
  const who = useStore(store => store.action.who);
  const subject = useStore(store => store.action.subject);
  const body = useStore(store => store.action.body);
  const isTaskPaneOpen = useStore(store => store.otherValues.isTaskPaneOpen);
  const isLinkedInFallback = useStore(
    store => store.modalValues.isLinkedInFallback,
  );
  const linkedInFallbackText = useStore(
    store => store.modalValues.linkedInFallbackText,
  );

  const toRecipient = toRecipients ? toRecipients[0] : null;

  const { data } = useGetCurrentUser();
  const { data: orgSettings } = useGetOrgSettings();
  const { data: token, refetch: refetchToken } = useGetLinkedinToken({
    onError: () => setTokenError(true),
  });

  useEffect(() => {
    if (token) setTokenError(false);
  }, [token]);

  useEffect(() => {
    setCopied(false);
  }, [body]);

  useEffect(() => {
    setCopiedSubject(false);
  }, [subject]);

  const onSubjectChange = (event: ChangeEvent<HTMLInputElement>): void =>
    useStore.getState().updateAction({ subject: event.target.value });

  let qs = '';

  qs = `?${querystring.stringify({
    version: '2.1',
    sat: token?.token || '',
    modules: type === 'STEP_SUBTYPE_LINKEDIN_INMAIL' ? 'Messaging' : 'Connect',
    apiKey: orgSettings?.org_linkedin_client_id || '',
    isInlineMode: 'true',
    companyName: who?.company || '',
    crmRecordId: who?.sfdcId,
    crmSourceType: 'GROOVE',
    crmOrgId: data?.sfdc_org_id || '',
    firstName: who?.firstName || toRecipient?.name,
    lastName: who?.lastName || '',
    email: toRecipient?.email || who?.email,
  })}`;

  const linkedInUrl = `${LINKEDIN_ACTIONS_URL}${qs}`;

  const paneValues = (): PaneValues => ({
    getCurrentInputRef: () => textAreaRef,
    selectionStart: textAreaRef.current?.selectionStart || 0,
    selectionEnd: textAreaRef.current?.selectionEnd || 0,
  });

  const onChange = (body: string): void =>
    useStore.getState().updateAction({ body });

  return (
    <div
      data-testid="linkedin-pane"
      className={cx(
        'flex flex-1 pr-[12px] pt-[12px] pl-[20px] min-w-0',
        isTaskPaneOpen ? 'flex-col' : 'flex-row',
      )}
    >
      <div
        className={cx(
          'flex flex-col',
          isTaskPaneOpen ? 'h-[215px] pb-[8px]' : 'flex-1 pr-[4px]',
        )}
      >
        <div className="flex flex-row pb-[8px]">
          <RecipientSelector
            className="flex-1 max-w-[210px]"
            placeholder="Recipient"
          />
        </div>
        <div className="flex flex-row pb-[8px]">
          <Input
            className="flex-1 h-[30px] mr-1"
            onChange={onSubjectChange}
            value={subject || ''}
            placeholder="Subject"
          />
          <Button
            variant="secondary"
            size="small"
            toggledOn={copiedSubject}
            disabled={!subject || subject.length < 1}
            onClick={() => {
              navigator.clipboard.writeText(subject || '');
              setCopiedSubject(true);
            }}
          >
            {copiedSubject ? (
              <>
                <CheckmarkCircle className="h-[19px] mr-[6px]" /> Copied
              </>
            ) : (
              'Copy'
            )}
          </Button>
        </div>
        <WysiwygLite
          onTemplateClick={() =>
            useStore.getState().toggleTemplate(paneValues())
          }
          onMergeFieldClick={() => {
            useStore.getState().setOtherValues(paneValues());
            openMergeModal();
          }}
          value={body || ''}
          onChange={onChange}
          ref={textAreaRef}
          onMouseUp={() => useStore.getState().setOtherValues(paneValues())}
        />
        <div className="flex flex-row-reverse pt-[8px]">
          <Button
            variant="secondary"
            size="small"
            toggledOn={copied}
            disabled={!body || body.length < 1}
            onClick={() => {
              navigator.clipboard.writeText(body || '');
              setCopied(true);
            }}
          >
            {copied ? (
              <>
                <CheckmarkCircle className="h-[16px] mr-[6px]" /> Copied
              </>
            ) : (
              'Copy'
            )}
          </Button>
        </div>
      </div>

      <div
        className={cx(
          'flex-1 flex flex-col relative',
          !isTaskPaneOpen && 'pl-[4px]',
        )}
      >
        {/* This useMemo is to prevent rerendering of the iframe and losing the data */}
        {useMemo(
          () => (
            <>
              {isLinkedInFallback && (
                <div className="flex flex-col items-center text-subject-title">
                  <Text className="p-2 text-center">
                    Click below to open LinkedIn Sales Navigator
                  </Text>
                  <Button onClick={() => window.open(linkedInUrl, '_blank')}>
                    {toRecipients?.[0]?.name || 'Click here'}
                  </Button>
                  {linkedInFallbackText && (
                    <Text className="p-2 text-center text-subject-title">
                      {linkedInFallbackText}
                    </Text>
                  )}
                </div>
              )}
              {!isLinkedInFallback && tokenError && (
                <div>
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline
                    onDismiss={() => {
                      setTokenError(false);
                    }}
                    actions={
                      <MessageBarButton
                        onClick={() => {
                          setTokenError(false);
                          refetchToken();
                        }}
                        ariaLabel="fix-linkedin"
                      >
                        Refresh
                      </MessageBarButton>
                    }
                    dismissButtonAriaLabel="Close"
                  >
                    After connecting to LinkedIn Sales Navigator in the Omnibar,
                    click Refresh to continue
                  </MessageBar>
                </div>
              )}
              {!isLinkedInFallback && (
                <iframe
                  title="linkedin-action"
                  className="h-full w-full overflow-auto"
                  src={linkedInUrl}
                />
              )}
            </>
          ),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [tokenError],
        )}
      </div>
    </div>
  );
};

export default LinkedInPane;
