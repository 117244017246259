import { actions } from '@groove-labs/groove-ui';
import { removePeopleBulkFromAllActiveFlows } from 'GrooveHTTPClient/flows';
import HttpStatusCodes from 'http-status-codes';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { addJob } from 'Modules/Shared/actions/batchJobs';
import { location } from 'Modules/Shared/selectors/location';
import { getFlowId, getPeople } from 'Modules/WorkStep/selectors';
import { removePersonFromFlow } from 'Modules/WorkStep/actions';
import { watchJob } from 'Modules/Shared/sagas/batchJobs';
import { actionTypes as appActionTypes } from 'Modules/App/actions';
import { REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH } from 'Modules/App/constants';
import { all, call, put, select } from 'redux-saga/effects';

const { setProperty } = actions.ui;

function* handleRemovePersonFromAllFlows({ payload: person }) {
  try {
    yield put({ type: appActionTypes.REMOVE_PERSON_FROM_ALL_FLOWS.PROGRESS });
    const response = yield call(removePeopleBulkFromAllActiveFlows, [
      person.id,
    ]);

    if (response.meta.status === HttpStatusCodes.ACCEPTED) {
      const jobId = response.data.batchId;

      // Begin polling job for completion
      yield put(
        addJob({
          id: jobId,
          name: 'Bulk remove people from flow - GlobalSearch',
        })
      );

      // Wait until this job is finished
      yield* watchJob(jobId);

      yield all([
        put({
          type: appActionTypes.REMOVE_PERSON_FROM_ALL_FLOWS.SUCCESS,
          payload: { personId: person.id },
        }),
        put(
          setProperty({
            uiKeyPath: REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH,
            data: false,
          })
        ),
        put(
          pushSnackbarMessage({
            message: `${person.name} removed from all flows`,
          })
        ),
      ]);

      // After Success Check page we are on
      const currentLocation = yield select(location);
      const workStepPeople = yield select(getPeople);

      if (
        currentLocation.get('routeName') === 'workStep' &&
        workStepPeople.keySeq().includes(person.id.toString())
      ) {
        // we are on workStep page hence we also call remove person from flow
        // get the current flow that the user has selected and specifically call
        // remove on him to remove them from the flow display
        const flowId = yield select(getFlowId);
        yield put(removePersonFromFlow({ flowId, id: person.id }));
      }
    } else {
      yield all([
        put({
          type: appActionTypes.REMOVE_PERSON_FROM_ALL_FLOWS.FAILURE,
          error: response,
        }),
        put(
          setProperty({
            uiKeyPath: REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH,
            data: false,
          })
        ),
        put(
          pushSnackbarMessage({
            message: `Failed to remove ${person.name} from flows`,
          })
        ),
      ]);
    }
  } catch (e) {
    yield all([
      put({
        type: appActionTypes.REMOVE_PERSON_FROM_ALL_FLOWS.FAILURE,
        error: e.message,
      }),
      put(
        setProperty({
          uiKeyPath: REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH,
          data: false,
        })
      ),
      put(
        pushSnackbarMessage({
          message: `Failed to remove ${person.name} from flows`,
        })
      ),
    ]);
  }
}

export default handleRemovePersonFromAllFlows;
