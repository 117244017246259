import * as React from 'react';
import { Tooltip } from '@groove-labs/groove-ui';
import { Button, makeStyles } from '@material-ui/core';
import { Help } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  tooltip: {
    color: 'inherit',
  },
  tooltipText: {
    lineHeight: '12px',
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
  },
  helpButtonStyles: {
    display: 'flex',
    cursor: 'pointer',
    height: '30px',
    width: '30px',
    maxHeight: '30px',
    maxWidth: '30px',
    margin: '0 8px',
  },
  openedMenuButton: {
    border: '1px solid black',
    borderRadius: '4px',
  },
  questionIcon: {
    height: '24px',
    width: '24px',
    color: theme.palette.primary.light,
  },
}));

type HelpIconButtonProps = {
  open: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};
const HelpIconButton: React.FC<HelpIconButtonProps> = ({ onClick, open }) => {
  const {
    tooltip,
    tooltipText,
    helpButtonStyles,
    openedMenuButton,
    questionIcon,
  } = useStyles({});
  const buttonClasses = `${helpButtonStyles} ${open ? openedMenuButton : ''}`;

  return (
    <Tooltip
      title={'Help'}
      placement="bottom"
      className={tooltip}
      classes={{ tooltip: tooltipText }}
    >
      <Button
        id="help-dropdown-button"
        className={buttonClasses}
        color="primary"
        onClick={onClick}
      >
        <Help className={questionIcon} />
      </Button>
    </Tooltip>
  );
};

export default HelpIconButton;
