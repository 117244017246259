import React from 'react';
import PropTypes from 'prop-types';
import EmailRecipientsRecord from 'Modules/Emails/data/EmailRecipients';
import EmailRecipient from 'Modules/Emails/components/EmailRecipient';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    paddingBottom: theme.spacing.unit,
  },
});

const EmailRecipients = ({ recipients, classes }) => {
  return (
    <div className={classes.root}>
      <div>
        {recipients.to?.map(recipient => (
          <EmailRecipient key={recipient} recipient={recipient} />
        ))}
      </div>
      <div>
        {recipients.cc?.map(recipient => (
          <EmailRecipient key={recipient} recipient={recipient} />
        ))}
      </div>
    </div>
  );
};

EmailRecipients.displayName = 'EmailRecipients';
EmailRecipients.propTypes = {
  recipients: PropTypes.instanceOf(EmailRecipientsRecord).isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(EmailRecipients);
