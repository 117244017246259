import { call } from 'redux-saga/effects';
import rootSagaErrorHandler from 'ErrorHandling/rootSagaErrorHandler';

const buildDefaultTeardownSaga = saga => {
  return function* teardownSaga() {
    if (saga) {
      yield call(saga);
    } else if (process.env.NODE_ENV !== 'production') {
      console.info(
        'Teardown saga not implemented. You can configure a teardown saga in src/js/routes'
      ); // eslint-disable-line no-console
    }
  };
};

export default function createRunRootSaga(saga, teardownSaga = null) {
  return function* doRunRootSaga(...args) {
    try {
      yield call(saga, ...args);
      return { success: true };
    } catch (error) {
      yield* rootSagaErrorHandler(error);
      return { success: false, error };
    } finally {
      yield call(buildDefaultTeardownSaga(teardownSaga));
    }
  };
}
