import { FC, useRef, useState } from 'react';
import { VMDropIcon } from '@groove/ui/Components/BoogieIcon';
import useDialerStore from '@groove/dialer-components/hooks/useStore';
import { useGetVoicemails } from '@groove/dialer-components/hooks/api/useGetVoicemails';
import Item, {
  DropDownItem,
  StandardDropDownItem,
} from '@groove/ui/Components/DropDownItem';
import DropDownFrame from '@groove/ui/Components/DropDownFrame';
import Button from '@groove/ui/Components/Button';
import {
  picklistMessageItem,
  spinner,
} from '@groove/ui/Components/Picklist/picklistUtilComponents';
import useHandleOutsideClicks from '@groove/ui/helpers/useHandleOutsideClicks';
import { useGetOrgSettings } from '@groove/api/hooks/useUsers';
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';
import { voicemailDrop } from '@groove/api/gateway/v1/commonDialer';

import useStore from '../../store/useStore';
import { VOICEMAIL_SETTINGS } from '../../constants';

import DialerButton from './DialerBarButton';

const VMDropButton: FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toRecipients = useStore(store => store.action.toRecipients);
  const addMessage = useStore(store => store.addMessage);
  const removeMessage = useStore(store => store.removeMessage);
  const participantCallSid = useDialerStore(store => store.activeBridgeCallSid);
  const conferenceFriendlyName = useDialerStore(
    store => store.conferenceFriendlyName,
  );
  const callState = useDialerStore(store => store.callState);
  const isBridgeCallingEnabled = useDialerStore(
    store => store.isBridgeCallingEnabled,
  );
  const isConstantBridgeEnabled = useDialerStore(
    store => store.isConstantBridgeEnabled,
  );

  const voicemailDropFlag = useVariation('voicemail-drop');
  const { data: voicemails, isLoading } = useGetVoicemails();
  const { data: orgSettings } = useGetOrgSettings();

  const items: DropDownItem[] =
    voicemails?.data?.map((item, index) => ({
      key: index,
      value: index,
      text: item.name,
    })) || [];

  useHandleOutsideClicks(ref, () => {
    setIsMenuOpen(false);
  });

  const isVMDropDisabled =
    !(isBridgeCallingEnabled || isConstantBridgeEnabled) ||
    callState === 'not-connected' ||
    orgSettings?.voicemail_drop_disabled ||
    !voicemailDropFlag;

  let vmDropTooltip: string | JSX.Element = 'Please start the call.';

  if (callState === 'connecting')
    vmDropTooltip = 'Call is starting please wait.';

  if (!voicemailDropFlag)
    vmDropTooltip =
      'Voicemail drop has not been enabled by your organization. Please contact your Admin.';

  if (orgSettings?.voicemail_drop_disabled)
    vmDropTooltip = 'Voicemail drop has been disabled by your Admin.';

  if (!isBridgeCallingEnabled && !isConstantBridgeEnabled)
    vmDropTooltip = 'Bridge dial is required in order to use voicemail drop.';

  if (!isVMDropDisabled) vmDropTooltip = 'Click to open a list of voicemails.';

  const onVMClick = async (item: StandardDropDownItem): Promise<void> => {
    const id = 'voicemail-drop-loading';
    addMessage({
      level: 'info',
      id,
      message: 'Leaving Voicemail: Standard Voicemail',
      noCloseButton: true,
    });
    try {
      await voicemailDrop({
        conferenceFriendlyName,
        voicemailId: item.value as number,
        phoneNumber: toRecipients?.[0]?.phone || '',
        shouldEndUserCall: !isConstantBridgeEnabled,
        ...(isConstantBridgeEnabled ? { participantCallSid } : {}),
      });
      addMessage({
        level: 'success',
        id,
        message: 'Voicemail Sent',
        noCloseButton: true,
      });
      useStore.getState().updateAction({
        ...useStore.getState().action,
        twilioSid: useDialerStore.getState().getActiveConnectionSid() || '',
      });
      useDialerStore.getState().endCall(false);
      setIsMenuOpen(false);
    } catch (err) {
      console.error('Issue with dropping voicemail', err);
      addMessage({
        level: 'error',
        id,
        message: 'Could not drop Voicemail, try again.',
        noCloseButton: true,
      });
    }
    setTimeout(() => removeMessage(id), 10000);
  };

  let renderMessage = null;

  if (items.length < 1)
    renderMessage = picklistMessageItem('No voicemail has been setup');
  else if (isLoading) renderMessage = spinner;

  return (
    <div className="relative" ref={ref}>
      <DropDownFrame
        itemList={items}
        lineHeight={32}
        showDropDown={isMenuOpen}
        renderedItem={Item}
        width={180}
        direction="topRight"
        itemData={{
          items,
          onClick: onVMClick,
        }}
        childrenLocation="below"
      >
        {renderMessage}
        <div className="flex flex-row p-[6px] border-0 !border-t border-solid border-neutral/100 justify-center">
          <a href={VOICEMAIL_SETTINGS} target="_blank" rel="noreferrer">
            <Button variant="secondary" className="text-body-sm">
              Manage Voicemail
            </Button>
          </a>
        </div>
      </DropDownFrame>
      <DialerButton
        label="VM Drop"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        tooltip={vmDropTooltip}
        disabled={isVMDropDisabled}
      >
        <VMDropIcon className="w-[20px]" />
      </DialerButton>
    </div>
  );
};

export default VMDropButton;
