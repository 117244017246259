import ROLES from 'Modules/Shared/constants/roles';

const VALID_ROLES = Object.freeze([ROLES.ADMIN, ROLES.TEAM_LEAD]);
const COACHABLE_ROLES = Object.freeze([ROLES.AGENT, ROLES.ANALYST]);
const GROOVE_ENGINE_CALL_COACHING_CHECK_INTERVAL = 90000;
const ANALYST_ROLE = ROLES.ANALYST;

export {
  VALID_ROLES,
  COACHABLE_ROLES,
  GROOVE_ENGINE_CALL_COACHING_CHECK_INTERVAL,
  ANALYST_ROLE,
};
