import { call, all, takeEvery, select } from 'redux-saga/effects';
import {
  createSafeActionHandler,
  ANALYTICS_BASE_PROPERTIES,
} from 'Modules/Shared/sagas/analytics';
import { trackEvent } from 'Utils/segment';
import { actionTypes as peopleImportDialogActionTypes } from 'Modules/PeopleImportDialog/actions';
import { actionTypes as peopleActionTypes } from 'Modules/People/actions';
import { actionTypes as flowsShowActionTypes } from 'Modules/FlowsShow/actions';
import { ROUTE_NAMES } from 'Routing/constants';
import { actionTypes as accountPreviewActionTypes } from 'Modules/Shared/actions/accountPreview';
import { actionTypes as appActionTypes } from 'Modules/App/actions';
import { actionTypes as workStepActionTypes } from 'Modules/WorkStep/actions';
import { location as getLocation } from 'Modules/Shared/selectors/location';

const peopleAddedActions = [
  {
    source: null,
    action: peopleImportDialogActionTypes.SUBMIT.SUCCESS,
  },
  {
    source: 'People Page',
    action: peopleActionTypes.ADD_PEOPLE_TO_FLOW.SUCCESS,
  },
  {
    source: 'Account Modal',
    action: accountPreviewActionTypes.ADD_PEOPLE_TO_FLOW.SUCCESS,
  },
];

const peopleRemovedActions = [
  {
    source: 'Workstep',
    action: workStepActionTypes.REMOVE_PERSON_FROM_FLOW.SUCCESS,
  },
  {
    source: 'Flows - People Tab',
    action: flowsShowActionTypes.REMOVE_PEOPLE_FROM_FLOW_REQUEST.SUCCESS,
  },
  {
    source: 'People Page',
    action: peopleActionTypes.REMOVE_PEOPLE_FROM_ALL_FLOWS.SUCCESS,
  },
  {
    source: 'Account Modal',
    action: accountPreviewActionTypes.REMOVE_PEOPLE_FROM_ALL_FLOWS.SUCCESS,
  },
  {
    source: 'Global Search - Single Flow',
    action: appActionTypes.REMOVE_PERSON_FROM_FLOW.SUCCESS,
  },
  {
    source: 'Global Search - All Flows',
    action: appActionTypes.REMOVE_PERSON_FROM_ALL_FLOWS.SUCCESS,
  },
];

function* trackPeopleModifiedEvent({ source, action, event }) {
  yield takeEvery(
    action,
    createSafeActionHandler(function* track() {
      let sourceName = source;
      if (action === peopleImportDialogActionTypes.SUBMIT.SUCCESS) {
        const location = yield select(getLocation);
        const routeName = location.get('routeName');
        sourceName =
          routeName === ROUTE_NAMES.FLOWS_WIZARD ? 'Flow Wizard' : 'Flow Show';
      }
      trackEvent(event, {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory: ROUTE_NAMES.PEOPLE,
        source: sourceName,
      });
    })
  );
}

export function* peopleAdded() {
  yield all(
    peopleAddedActions.map(({ action, source }) =>
      call(trackPeopleModifiedEvent, {
        event: 'People Added',
        action,
        source,
      })
    )
  );
}

export function* peopleRemoved() {
  yield all(
    peopleRemovedActions.map(({ action, source }) =>
      call(trackPeopleModifiedEvent, {
        event: 'People Removed',
        action,
        source,
      })
    )
  );
}
