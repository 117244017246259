import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

export default function getOrganizationBasePath(
  organizationId: string
): string {
  const basePath = `organizations/${organizationId}`;
  if (grooveEngineRootUrl.includes('engine-pr')) {
    return `environments/${
      new URL(grooveEngineRootUrl).host.split('.')[0]
    }/${basePath}`;
  }

  if (['staging', 'production'].includes(process.env.FIRESTORE_ENVIRONMENT)) {
    return basePath;
  }

  return `environments/${process.env.FIRESTORE_ENVIRONMENT}/${basePath}`;
}
