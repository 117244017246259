import React, { Component } from 'react';
import { Link } from 'Utils/history';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Map } from 'immutable';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import styles from './styles';

@withStyles(styles)
export default class PersonSearchFlowsItem extends Component {
  static displayName = 'PersonSearchFlowsItem';
  static propTypes = {
    classes: PropTypes.shape({
      removeFromFlowContainer: PropTypes.string.isRequired,
      removeFromFlowText: PropTypes.string.isRequired,
      flowDescription: PropTypes.string.isRequired,
      flowLinkContainer: PropTypes.string.isRequired,
      flowLinkText: PropTypes.string.isRequired,
      flowListItem: PropTypes.string.isRequired,
    }).isRequired,
    flow: PropTypes.instanceOf(Map).isRequired,
    currentUserId: PropTypes.number.isRequired,
    onRemoveFromFlowClick: PropTypes.func.isRequired,
  };

  buildFlowDescription = flow => {
    const { stepIndex, finishedAt, disabledAt, pausedUntil, stepCount } = flow;

    const dateFormat = 'MMM Do YYYY';

    if (stepIndex && !finishedAt && !disabledAt) {
      return `Step ${stepIndex} of ${stepCount}`;
    } else if (finishedAt && !disabledAt) {
      return `Completed on ${moment(finishedAt).format(dateFormat)}`;
    } else if (pausedUntil && !disabledAt) {
      return `Paused until ${moment(pausedUntil).format(dateFormat)}`;
    } else if (disabledAt) {
      return `Removed on ${moment(disabledAt).format(dateFormat)}`;
    }
    return '';
  };

  handleRemoveClick = () => {
    const { flow, onRemoveFromFlowClick } = this.props;
    onRemoveFromFlowClick(flow);
  };

  render() {
    const { classes, flow, currentUserId } = this.props;
    return (
      <div className={classes.flowListItem} key={flow.id}>
        <div className={classes.flowLinkContainer}>
          <Link to={`/flows/${flow.id}`} key={flow.id}>
            <Typography
              variant="caption"
              noWrap
              className={classes.flowLinkText}
            >
              {flow.name}
              {flow.flowOwnerName ? ` ( by ${flow.flowOwnerName})` : ''}
            </Typography>
          </Link>
          <Typography
            variant="caption"
            noWrap
            className={classes.flowDescription}
          >
            {this.buildFlowDescription(flow)}
          </Typography>
        </div>
        {currentUserId === flow.userId &&
          !flow.disabledAt &&
          !flow.finishedAt && (
            <div
              role="link"
              tabIndex="0"
              className={classes.removeFromFlowContainer}
              onClick={this.handleRemoveClick}
            >
              <a className={classes.removeFromFlowText}>Remove</a>
            </div>
          )}
      </div>
    );
  }
}
