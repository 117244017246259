import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const PhoneIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <path d="M1.55101 1.44274C2.40494 0.96496 3.49406 0.852733 4.53544 1.11654C5.04218 1.24492 5.47512 1.57346 5.73513 2.02696L6.6329 3.59282C7.29786 4.75261 6.9327 6.23029 5.80407 6.94685L5.01425 7.44831C4.68733 7.65587 4.56745 7.97242 4.64244 8.22403C4.90993 9.12147 5.50308 10.1182 6.15518 10.8002C6.34925 11.0032 6.7045 11.0519 7.05289 10.8487L7.4899 10.5938C8.69739 9.8894 10.2478 10.312 10.9308 11.5317L11.7345 12.9669C11.9975 13.4365 12.0415 13.9979 11.8548 14.5027C11.4838 15.5057 10.7694 16.3143 9.89035 16.7193C8.9973 17.1309 7.95159 17.1142 7.0056 16.4831C5.50831 15.4843 3.70298 13.8045 2.06815 11.002C0.412072 8.16304 -0.00544014 5.74551 0.0415579 3.94416C0.0716644 2.79025 0.68184 1.92904 1.55101 1.44274ZM10.7428 1.06289C10.5014 0.928786 10.197 1.01576 10.0629 1.25715C9.92879 1.49854 10.0158 1.80294 10.2572 1.93705L10.6411 2.15037C13.0761 3.50315 14.6938 5.96319 14.971 8.73492L15.0025 9.04972C15.0299 9.3245 15.275 9.52497 15.5497 9.49749C15.8245 9.47001 16.025 9.22499 15.9975 8.95022L15.966 8.63542C15.6562 5.5376 13.8483 2.78815 11.1268 1.27621L10.7428 1.06289ZM9.57128 3.74274C9.71335 3.50595 10.0205 3.42917 10.2573 3.57124L10.3814 3.64571C12.0144 4.62554 13.1356 6.27228 13.4487 8.15082L13.4932 8.41779C13.5386 8.69017 13.3546 8.94779 13.0822 8.99318C12.8098 9.03858 12.5522 8.85457 12.5068 8.58219L12.4623 8.31522C12.1974 6.72568 11.2487 5.33229 9.86689 4.5032L9.74278 4.42873C9.50599 4.28666 9.4292 3.97953 9.57128 3.74274Z" />
  </svg>
);
