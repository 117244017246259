import { useQuery, UseQueryResult } from 'react-query';

import { getVoicemails, VoicemailFetchResult } from '../../api/voicemail';

export const useGetVoicemails = (): UseQueryResult<
  VoicemailFetchResult,
  unknown
> =>
  useQuery('voicemails', () => getVoicemails(), {
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });
