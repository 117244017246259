import React, { FC } from 'react';

interface UploadedFileProps {
  uploadLocation: string;
  fileName: string;
  id?: string;
  onDelete?: (fileName: string) => void;
}
// This component is injected directly into the
// editor and as such, needs to be completely inline-styled
const UploadedFile: FC<UploadedFileProps> = ({
  uploadLocation,
  fileName,
  id,
  onDelete,
}) => {
  const handleOnDelete = () => {
    if (onDelete && id) {
      onDelete(id);
    }
  };
  return (
    <div
      style={{
        display: 'inline-block',
        marginTop: 15,
        padding: 8,
        border: '1px solid #ccc',
        borderRadius: 2,
        backgroundColor: 'rgba(250,250,250,1)',
        paddingRight: 20,
      }}
    >
      📎
      <a
        // This classname is used in the extension and in GE for attachment tracking
        className="fr-file"
        href={uploadLocation}
        id={id}
        style={{ marginLeft: 10 }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {fileName}
      </a>
      <span
        style={{ cursor: 'pointer', color: '#7a6f6f', paddingLeft: 5 }}
        onClick={handleOnDelete}
        onKeyDown={handleOnDelete}
        role="button"
        tabIndex={0}
      >
        x
      </span>
    </div>
  );
};

UploadedFile.defaultProps = {
  id: '',
};

export default UploadedFile;
