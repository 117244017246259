import create from 'zustand';

import { isAnalyticsEnabled, mixpanelSafeTrack } from './hooks/useMixpanel';

export type Properties = { [key: string]: string | number | boolean | null };

export type Store = {
  properties: Properties;
  updateProperties: (properties: Properties) => void;
  setProperties: (properties: Properties) => void;
  customTrackEvent?: (eventName: string, eventProperties?: Properties) => void;
  setCustomTrackEvent: (
    customTrackEvent: (eventName: string, eventProperties?: Properties) => void,
  ) => void;
};

export const useStore = create<Store>((set, get) => ({
  customTrackEvent: undefined,
  properties: {},
  updateProperties: props => {
    const { properties } = get();
    set({ properties: { ...properties, ...props } });
  },
  setProperties: properties => {
    set({ properties });
  },
  setCustomTrackEvent: (
    customTrackEvent: (eventName: string, eventProperties?: Properties) => void,
  ) => {
    set({ customTrackEvent });
  },
}));

export const trackEvent = (
  eventName: string,
  eventProperties?: Properties,
): void => {
  if (isAnalyticsEnabled()) {
    const { customTrackEvent, properties: globalProperties } =
      useStore.getState();
    if (customTrackEvent) {
      customTrackEvent(eventName, { ...globalProperties, ...eventProperties });
    } else {
      const { userId, ...otherProperties } = globalProperties;
      mixpanelSafeTrack(eventName, {
        userId: userId as string,
        properties: {
          ...otherProperties,
          ...eventProperties,
        },
      });
    }
  }
};

export default trackEvent;
