import { HTTPError } from 'ky';
import {
  FullAction,
  NEW_ACTION_ID,
  getActionCompose,
} from '@groove/api/gateway/v1/actionCompose';
import { SearchUser, getEmailAliases } from '@groove/api/gateway/v1/users';
import useSSMiniStore from '@groove/search-and-select/SearchAndSelectMini/useStore';
import { QueryClient } from 'react-query';
import {
  fetchAdditionalLoggingFields,
  fetchCurrentUser,
  fetchOrgSettings,
} from '@groove/api/hooks/useUsers';
import { fetchFindOrCreatePerson } from '@groove/api/hooks/useFindOrCreatePerson';
import useLaunchDarklyStore from '@groove/api/hooks/launchdarkly/useStore';

import { LoadAction } from '../types';
import useStore from '../store/useStore';
import {
  ACTION_ORIGIN,
  ActionComposeEnum,
  emptyAction,
  ErrorType,
  OUTGOING_MESSAGE_TYPES,
} from '../constants';
import { fetchGrooveMeta } from '../hooks/useGrooveMeta';

import { setUpLoggingTo } from './setupLoggingTo';
import { syncAllWhoData } from './syncWhoData';

import {
  buildNewActionWhoFromPerson,
  didActionChange,
  getErrorMessage,
} from '.';

export type ActionHandlerParams = {
  action: LoadAction;
  client: QueryClient;
  invokingApp?: ACTION_ORIGIN;
};

export type ErrorHandlerParams =
  | {
      message: string;
    }
  | string;

const errorHandler = (error: ErrorHandlerParams): void => {
  if (error instanceof HTTPError) {
    const { status } = error.response;
    const message = getErrorMessage(ErrorType.GetActionError, status);
    useStore
      .getState()
      .setRequestErrorMessage(ErrorType.GetActionError, message);
  } else {
    useStore
      .getState()
      .setRequestErrorMessage(
        ErrorType.GetActionError,
        typeof error === 'object' ? error.message : error.toString(),
      );
  }
  console.error('loadActionHandler has errored:', error);
  useStore.getState().setOtherValues({
    isFetching: false,
    wysiwygEdited: false,
  });
};

const loadAction = async ({
  action,
  client,
  invokingApp,
}: ActionHandlerParams): Promise<void> => {
  let newAction: FullAction;
  const { id } = action;
  useStore.getState().cleanUpActionCompose();
  useStore.setState({ actionComposeStatus: ActionComposeEnum.MAXIMIZED });
  useStore.getState().updateAction(action);
  useStore.getState().setOtherValues({ isFetching: true });
  useStore.getState().setModalValues({ isOpen: false });
  const { ldClient } = useLaunchDarklyStore.getState();
  const dialerInActionEnabled = ldClient?.variation('dialer-in-action');

  const isNewAction = !id || id === NEW_ACTION_ID;

  if (isNewAction) {
    newAction = await newActionHandler({ action, client });
  } else {
    newAction = await existingActionHandler({ action, client });
  }

  const { type } = newAction;
  const { dialer_enabled, sms_enabled, phone_number } =
    (await fetchCurrentUser(client)) || {};
  if (
    id &&
    id !== NEW_ACTION_ID &&
    type === 'CALL' &&
    dialer_enabled &&
    !dialerInActionEnabled
  ) {
    useStore.getState().setOtherValues({ taskPaneInitialClose: true });
    useStore.setState({ dialerEnabled: true });
  }

  // Save the action
  useStore.getState().updateAction(newAction);

  // set the original action
  useStore.getState().updateOriginalAction(newAction);

  setUpLoggingTo(newAction.who);

  const {
    disable_email_opt_out_safeguard,
    contact_opt_out_field,
    contact_call_opt_out_field,
    contact_sms_opt_out_field,
    activity_result_displayed_for_calls,
    activity_result_is_mandatory_for_calls,
    lead_opt_out_field,
    lead_call_opt_out_field,
    lead_sms_opt_out_field,
  } = (await fetchOrgSettings(client)) || {};
  // Set up other values
  useStore.getState().setOtherValues({
    isFetching: false,
    wysiwygEdited: false,
    invokingApp,
    isEmailOptOutSafeguardDisabled: disable_email_opt_out_safeguard,
    contactEmailOptOutField: contact_opt_out_field,
    contactCallOptOutField: contact_call_opt_out_field,
    contactSmsOptOutField: contact_sms_opt_out_field,
    isCallResultDisplayed: activity_result_displayed_for_calls,
    isCallResultRequired: activity_result_is_mandatory_for_calls,
    leadEmailOptOutField: lead_opt_out_field,
    leadCallOptOutField: lead_call_opt_out_field,
    leadSmsOptOutField: lead_sms_opt_out_field,
    smsEnabled: sms_enabled,
    phoneNumber: phone_number,
  });
  useSSMiniStore.setState({
    selectedNames: [],
    selectedRelatedTos: [],
  });

  // We set emailAlias afterwards because it's a slow call
  setUpEmailAliases();

  // Also setup syncing Who Data
  syncWhoData(client, newAction);

  if (isNewAction) {
    // Do link tracking after because it can be slow
    // check for trackClicks and trackOpens in dynamicData
    setLinkTracking(client);

    // Do Fetching of Additional call fields separately since it is slow also
    setAdditionalFields(client);
  }
};

/**
 * Main function for loading an Action into Action Compose
 * @param param0 type of ActionHandlerParams
 * @returns
 */
const loadActionHandler = async ({
  action,
  client,
  invokingApp,
}: ActionHandlerParams): Promise<void> => {
  try {
    const { id, forceLoad } = action;

    // We don't want people accidentally reloading open actions
    if (
      useStore.getState().action.id === id &&
      useStore.getState().actionComposeStatus !== ActionComposeEnum.CLOSED &&
      !forceLoad
    )
      return;

    const { originalAction, action: currentAction } = useStore.getState();
    // if the action compose window is open and we are trying to load a different action
    // check if the action has changed and prompt the user to save or exit with unsaved changes
    if (
      useStore.getState().actionComposeStatus !== ActionComposeEnum.CLOSED &&
      didActionChange(originalAction, currentAction)
    ) {
      useStore.getState().setNewModal({
        isOpen: true,
        onConfirm: () => {
          loadAction({ action, client, invokingApp }).catch(errorHandler);
        },
        onCancel: () => {
          window.postMessage(
            {
              type: OUTGOING_MESSAGE_TYPES.SWITCH_ACTION_CANCELED_BY_USER,
              payload: {
                currentActionId: originalAction.id,
              },
            },
            window.location.origin,
          );
        },
        contentText:
          'Are you sure you want to move away from this action without saving?',
        title: 'Done editing?',
        confirmText: 'Don’t save',
      });
    } else {
      await loadAction({ action, client, invokingApp });
    }
  } catch (error) {
    errorHandler(error as ErrorHandlerParams);
  }
};

/**
 * Handles the new action pathway
 */
const newActionHandler = async ({
  client,
  action: incomingAction,
}: ActionHandlerParams): Promise<FullAction> => {
  const { type, personSfdcId } = incomingAction || {};

  const newAction: FullAction = {
    ...emptyAction,
    ...incomingAction,
    assignee: await getCurrentUserAsAssignee(client),
    dueAt: new Date().toISOString(),
    type: type || 'GENERAL',
  };

  const firstSfdcId =
    Array.isArray(newAction.toRecipients) &&
    newAction.toRecipients.find(recipient => !!recipient.sfdcId)?.sfdcId;
  const sfdcId = personSfdcId || firstSfdcId;
  if (sfdcId) {
    const person = await fetchFindOrCreatePerson({ client, sfdcId });

    newAction.toRecipients =
      Array.isArray(newAction.toRecipients) && newAction.toRecipients.length > 0
        ? newAction.toRecipients
        : [
            {
              id: person.id.toString(),
              phone: incomingAction.toPhone || person.phone,
              email: incomingAction.toEmail || person.email,
              sfdcId: person.sfdcId,
            },
          ];
    newAction.personId = person.id;
    newAction.who = buildNewActionWhoFromPerson(person);
  }

  if (Array.isArray(newAction.toRecipients)) {
    newAction.toRecipients.forEach(recipient => {
      if (!recipient.sfdcId)
        recipient.attributes = {
          ...(recipient.attributes || {}),
          type: 'Unknown',
        };
    });
  }

  return newAction;
};

/**
 * Handles the existing action pathway
 */
const existingActionHandler = async ({
  action: incomingAction,
}: ActionHandlerParams): Promise<FullAction> => {
  const rawAction = (await getActionCompose(incomingAction.id)).data;
  let action = { ...rawAction, ...incomingAction };

  if (!action.body && !action.subject && action.template) {
    action = {
      ...action,
      body: action.template.body || '',
      subject: action.template.subject,
    };
  }
  if (action.who && !action.toRecipients?.length) {
    const { who } = action;
    const toRecipients = [
      {
        id: who.id.toString(),
        email: who.email,
        name: who.name,
        phone: '',
        phoneField: '',
        attributes: {
          type: who.sfdcType,
        },
        sfdcId: who.sfdcId,
      },
    ];
    action.toRecipients = toRecipients;
  }
  return action;
};

// We separate them out to make sure they can run async
const setUpEmailAliases = async (): Promise<void> => {
  const { data: emailAliasList } = await getEmailAliases();
  const { flowAlias, userAlias } = useStore.getState().action;

  // fromEmail should be a flowAlias if present, then user setting alias, then groove user account email. Never the gmail default.
  const fromEmail = flowAlias || userAlias || emailAliasList?.[0].email || '';

  useStore.getState().updateAction({
    fromEmail,
  });

  useStore.getState().updateOriginalAction({ fromEmail });
};

// We separate them out to make sure they can run async
const syncWhoData = async (
  client: QueryClient,
  action: FullAction,
): Promise<void> => {
  const grooveMeta = await fetchGrooveMeta({ client });
  if (grooveMeta) {
    await syncAllWhoData(action, grooveMeta);
    setUpLoggingTo(useStore.getState().action.who, action.loggingTo?.who);
  }
};

const setLinkTracking = async (client: QueryClient): Promise<void> => {
  const { track_link_clicks_by_default, track_emails_by_default } =
    (await fetchCurrentUser(client)) || {};

  const { dynamicData } = useStore.getState().action;
  useStore.getState().updateAction({
    dynamicData: {
      ...dynamicData,
      trackOpens: track_emails_by_default,
      trackClicks: track_link_clicks_by_default,
    },
  });
};

const setAdditionalFields = async (client: QueryClient): Promise<void> => {
  const { data: additionalFields } = await fetchAdditionalLoggingFields(client);
  useStore.getState().updateAction({
    dynamicData: {
      loggingForm: {
        title: null,
        subtitle: null,
        fields: additionalFields,
      },
    },
  });
};

export const getCurrentUserAsAssignee = async (
  client: QueryClient,
): Promise<SearchUser> => {
  const { id, email, name, sfdc_org_id, sfdc_user_id, meeting_custom_link } =
    (await fetchCurrentUser(client)) || {};
  return { id, email, name, sfdc_org_id, sfdc_user_id, meeting_custom_link };
};

export default loadActionHandler;
