import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const LightbulbIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="-2 0 16 16"
    className={cx(className, 'fill-current')}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 0C9.31371 0 12 2.59693 12 5.80041C12 7.47737 11.2546 9.0164 9.7961 10.3942C9.73242 10.4544 9.68313 10.5269 9.65119 10.6065L9.62514 10.6883L8.68908 14.6051C8.50477 15.3763 7.82356 15.935 7.01814 15.9947L6.8748 16H5.12546C4.30655 16 3.59 15.4839 3.34866 14.7385L3.31108 14.6047L2.37626 10.6886C2.34955 10.5766 2.29016 10.4745 2.20516 10.3942C0.815301 9.08195 0.0726475 7.62354 0.00507021 6.03903L0 5.80041L0.00320721 5.60894C0.107703 2.49409 2.75257 0 6 0ZM8.045 13H3.955L4.27386 14.3344L4.30004 14.4305C4.39695 14.7298 4.67583 14.9517 5.0116 14.993L5.12546 15L6.83787 15.0007L6.94423 14.9974C7.28647 14.9721 7.57256 14.7609 7.68542 14.4718L7.71647 14.3727L8.045 13ZM6 1C3.36782 1 1.21188 2.95301 1.0151 5.41357L1.00307 5.62569L0.999774 5.77916L1.00416 5.99642C1.05977 7.30026 1.67758 8.52078 2.89167 9.6671C3.07995 9.84486 3.22191 10.0647 3.30572 10.3078L3.34894 10.4564L3.716 12H8.285L8.67223 10.3851L8.72305 10.2343C8.80908 10.0198 8.94094 9.82647 9.10939 9.66733C10.3825 8.46465 11 7.18054 11 5.80041C11 3.15693 8.76885 1 6 1Z" />
  </svg>
);
