import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { LINKED_IN_SAVE_KEY } from 'Modules/Shared/constants';
import { remove, fetch } from 'GrooveLocalStorage';

export default function* handleLinkedInSATFailure(): SagaIterator {
  const storedToken = yield call(fetch, LINKED_IN_SAVE_KEY);
  if (storedToken) {
    yield call(remove, LINKED_IN_SAVE_KEY);
  }
}
