import React from 'react';

export default function LowPriorityIcon({
  className,
  style,
}: {
  className: string;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="6" fill="#969696" />
      <path
        opacity=".8"
        d="M8.89 4.13a.335.335 0 0 0-.53 0L6 6.927 3.64 4.13a.335.335 0 0 0-.53 0 .505.505 0 0 0 0 .629l2.625 3.11a.335.335 0 0 0 .53 0L8.89 4.76a.505.505 0 0 0 0-.629z"
        fill="#fff"
      />
    </svg>
  );
}
