import { createActions, standardActions } from '@groove-labs/action-utils';
import { FSA } from 'flux-standard-action';
import { Set, List } from 'immutable';

import AutomatedAction from 'Modules/Shared/records/AutomatedAction';

const types = {
  AUTOMATED_ACTIONS: {
    SAVE: standardActions(),
  },
};

export const actionTypes = createActions<typeof types>(types).AUTOMATED_ACTIONS;

export type SavePayload = {
  automatedActionsToCreate: List<AutomatedAction>;
  automatedActionsToUpdate: List<AutomatedAction>;
  automatedActionIdsToDestroy: Set<number>;
};

export const save = ({
  automatedActionsToCreate,
  automatedActionsToUpdate,
  automatedActionIdsToDestroy,
}: SavePayload): FSA<string, SavePayload> => ({
  type: actionTypes.SAVE.BEGIN,
  payload: {
    automatedActionsToCreate,
    automatedActionsToUpdate,
    automatedActionIdsToDestroy,
  },
});
