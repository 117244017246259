import { createSelector } from 'reselect';
import { List, Map, OrderedMap } from 'immutable';
import { isUndefined, isEmpty } from 'lodash-es';
import {
  TEMPLATE_FOLDERS_KEY_PATH,
  TEMPLATES_KEY_PATH,
  ACTIVE_FOLDER_ID_KEY_PATH,
  ACTIVE_TEMPLATE_ID_KEY_PATH,
  SHARING_DIALOG_OPEN_KEY_PATH,
  FOLDER_RENAME_DIALOG_OPEN_KEY_PATH,
  FLOW_USAGE_DIALOG_OPEN_KEY_PATH,
  FOLDER_DELETE_DIALOG_OPEN_KEY_PATH,
  NEW_FOLDER_DIALOG_OPEN_KEY_PATH,
  TEMPLATE_COPY_DIALOG_OPEN_KEY_PATH,
  TEMPLATE_EDIT_DIALOG_OPEN_KEY_PATH,
  TEMPLATE_CREATE_DIALOG_OPEN_KEY_PATH,
  TEMPLATES_LOADING_KEY_PATH,
  FOLDER_TAB_VALUES,
  TARGET_FOLDER_ID_KEY_PATH,
  ORDER_BY_KEY_PATH,
  ACTIVE_TEMPLATE_FLOW_USAGE_KEY_PATH,
  SEARCH_QUERY_KEY_PATH,
  TEMPLATE_CREATE_TYPE_KEY_PATH,
  TEMPLATE_FILTER_OPTION,
  FOLDER_SEARCH_QUERY,
} from 'Modules/Templates/constants';
import {
  folderBelongsToTab,
  orderByMapIsValid,
  getDefaultOrderByForContext,
} from 'Modules/Templates/utils';
import { currentUser } from 'Modules/Shared/selectors/users';
import { accessControls } from 'Modules/Shared/selectors/accessControls';
import { getCheckedLabelIds } from 'Modules/Shared/selectors/labels';
import { LABEL_IDS_TO_FILTER_UI_KEY_PATH } from 'Modules/Shared/constants/labels';

export const templateFolders = state => state.getIn(TEMPLATE_FOLDERS_KEY_PATH);
export const templates = state => state.getIn(TEMPLATES_KEY_PATH);
export const orderBy = state => state.getIn(ORDER_BY_KEY_PATH);
export const activeFolderId = state => state.getIn(ACTIVE_FOLDER_ID_KEY_PATH);
export const activeTemplateId = state =>
  state.getIn(ACTIVE_TEMPLATE_ID_KEY_PATH);
export const searchQuery = state => state.getIn(SEARCH_QUERY_KEY_PATH);
export const isLoading = state => state.getIn(TEMPLATES_LOADING_KEY_PATH);
export const sharingDialogOpen = state =>
  state.getIn(['ui', ...SHARING_DIALOG_OPEN_KEY_PATH]);
export const folderRenameDialogOpen = state =>
  state.getIn(['ui', ...FOLDER_RENAME_DIALOG_OPEN_KEY_PATH]);
export const folderDeleteDialogOpen = state =>
  state.getIn(['ui', ...FOLDER_DELETE_DIALOG_OPEN_KEY_PATH]);
export const newFolderDialogOpen = state =>
  state.getIn(['ui', ...NEW_FOLDER_DIALOG_OPEN_KEY_PATH]);
export const templateCopyDialogOpen = state =>
  state.getIn(['ui', ...TEMPLATE_COPY_DIALOG_OPEN_KEY_PATH]);
export const templateEditDialogOpen = state =>
  state.getIn(['ui', ...TEMPLATE_EDIT_DIALOG_OPEN_KEY_PATH]);
export const templateCreateDialogOpen = state =>
  state.getIn(['ui', ...TEMPLATE_CREATE_DIALOG_OPEN_KEY_PATH]);
export const flowUsageDialogOpen = state =>
  state.getIn(['ui', ...FLOW_USAGE_DIALOG_OPEN_KEY_PATH]);
export const targetFolderId = state =>
  state.getIn(['ui', ...TARGET_FOLDER_ID_KEY_PATH]);
export const activeTemplateFlowUsage = state =>
  state.getIn(['ui', ...ACTIVE_TEMPLATE_FLOW_USAGE_KEY_PATH]);
export const templateCreateTemplateType = state =>
  state.getIn(['ui', ...TEMPLATE_CREATE_TYPE_KEY_PATH]);
export const templatesByFolderId = state =>
  state.getIn(['templates', 'templatesByFolderId']);
export const getTemplatesSearchResults = state =>
  state.getIn(['templates', 'searchResults', 'templates']);
export const getTemplateFoldersSearchResults = state =>
  state.getIn(['templates', 'searchResults', 'templateFolders']);
export const templatesFetchInProgress = state =>
  state.getIn(['templates', 'templatesFetchInProgress']);
export const templateFilterOption = state =>
  state.getIn(TEMPLATE_FILTER_OPTION);
export const folderSearchQuery = state => state.getIn(FOLDER_SEARCH_QUERY);
/**
 * @return {function}
 */
export const makeGetActiveFolder = () => {
  return createSelector(
    [activeFolderId, templateFolders],
    (activeFolderId, templateFolders) => {
      if (!activeFolderId) {
        return new Map();
      }

      return templateFolders.get(activeFolderId);
    }
  );
};

/**
 * @return {function}
 */
export const getInSearchView = () => {
  return createSelector(
    [
      searchQuery,
      state => getCheckedLabelIds(state, LABEL_IDS_TO_FILTER_UI_KEY_PATH),
    ],
    (searchQuery, checkedLabelIds) => {
      return !isEmpty(searchQuery) || !checkedLabelIds.isEmpty();
    }
  );
};

/**
 * @return {function}
 */
export const makeGetDefaultFolder = () => {
  return createSelector(templateFolders, templateFolders => {
    const defaultFolder = templateFolders.find(folder => {
      return folder.get('isDefault');
    });

    return defaultFolder || templateFolders.first() || new Map();
  });
};

/**
 * @return {function}
 */
export const getActiveTemplate = () =>
  createSelector(
    [
      activeFolderId,
      activeTemplateId,
      templatesByFolderId,
      getTemplatesSearchResults,
      getInSearchView(),
    ],
    (
      activeFolderId,
      activeTemplateId,
      templatesByFolderId,
      templatesSearchResults,
      inSearchView
    ) => {
      if (inSearchView) {
        return templatesSearchResults.get(activeTemplateId) || new Map();
      }

      if (!activeTemplateId || !activeFolderId) {
        return new Map();
      }

      return templatesByFolderId.getIn(
        [activeFolderId, activeTemplateId],
        new Map()
      );
    }
  );

/**
 * @return func
 */
export const makeGetActiveFolderTab = () => {
  return createSelector(templateFilterOption, templateFilterOption => {
    if (isEmpty(templateFilterOption)) {
      return FOLDER_TAB_VALUES[0];
    }

    return templateFilterOption;
  });
};

/**
 * Select the folder currently being updated.
 *
 * @return function
 */
export const makeGetTargetFolder = () => {
  return createSelector(
    [targetFolderId, templateFolders],
    (targetFolderId, templateFolders) => {
      if (isUndefined(targetFolderId)) {
        return new Map();
      }

      const targetFolder = templateFolders.get(targetFolderId);
      if (isUndefined(targetFolder)) {
        return new Map();
      }

      return targetFolder;
    }
  );
};

/**
 * Sorting is dependent on whether there's an active folder or not.
 *
 * @return function
 */
export const makeGetOrderBy = () => {
  return createSelector(
    [orderBy, activeFolderId],
    (orderByMap, activeFolderId) => {
      const context = isUndefined(activeFolderId) ? 'folder' : 'template';

      if (orderByMapIsValid(orderByMap, context)) {
        return orderByMap;
      }

      // other wise return the default order by for that context.
      return getDefaultOrderByForContext(context);
    }
  );
};

export const getTemplatesForFolder = createSelector(
  [templatesByFolderId, activeFolderId],
  (templates, activeFolderId) => templates.get(activeFolderId, new OrderedMap())
);

export const getFolderById = folderId =>
  createSelector(templateFolders, allTemplateFolders => {
    const folder = allTemplateFolders.find(f => f.get('id') === folderId);
    return folder || new Map();
  });

export const makeGetCurrentTemplatesList = () =>
  createSelector(
    [
      getTemplatesForFolder,
      getTemplatesSearchResults,
      searchQuery,
      getInSearchView(),
    ],
    (
      templatesFromFolder,
      templatesSearchResults,
      searchQuery,
      inSearchView
    ) => {
      if (!inSearchView) {
        return templatesFromFolder.valueSeq();
      }

      return templatesSearchResults.valueSeq();
    }
  );

/**
 * Select current folder list displayed based on the active tab, active folder and query filter.
 *
 * 1. If a query filter is present, select using the query.
 * 2. If an active folder is present, an empty list is selected.
 * 3. If an active tab is present, filter the folder list as per the current tab.
 *
 * @return {function}
 */
export const makeGetCurrentFoldersList = () => {
  return createSelector(
    [
      templateFolders,
      getTemplateFoldersSearchResults,
      activeFolderId,
      getInSearchView(),
      currentUser,
      templateFilterOption,
    ],
    (
      allTemplateFolders,
      templateFolderSearchResults,
      activeFolderId,
      inSearchView,
      currentUser,
      templateFilterOption
    ) => {
      if (inSearchView) {
        return templateFolderSearchResults;
      }

      if (activeFolderId) {
        return new List();
      }

      return allTemplateFolders.valueSeq().reduce((list, templateFolder) => {
        const currentUserId = currentUser.get('id');
        const folderUserId = templateFolder.get('userId');
        if (
          folderBelongsToTab(templateFilterOption, folderUserId, currentUserId)
        ) {
          return list.push(templateFolder);
        }

        return list;
      }, new List());
    }
  );
};

/**
 * @return func
 */
export const makeGetActiveTemplateFlowUsage = () => {
  return createSelector([activeTemplateFlowUsage], activeTemplateFlowUsage => {
    if (isEmpty(activeTemplateFlowUsage)) {
      return new List();
    }

    return activeTemplateFlowUsage;
  });
};

/**
 * @return func
 */
export const makeGetOwnedFolderList = () => {
  return createSelector(
    [templateFolders, currentUser],
    (templateFolders, currentUser) => {
      if (isEmpty(templateFolders)) {
        return new List();
      }

      return templateFolders.toList().filter(templateFolder => {
        if (templateFolder.get('user')) {
          return templateFolder.getIn(['user', 'id']) === currentUser.get('id');
        }

        return false;
      });
    }
  );
};

/**
 * @return func
 */
export const makeGetSharedFolderList = () => {
  return createSelector(
    [templateFolders, currentUser, accessControls],
    (templateFolders, currentUser, accessControls) => {
      if (isEmpty(templateFolders)) {
        return new List();
      }

      // filter down access controls to deal with template folders with edit access, will be a list of the folderId that you have write
      // access to

      const editableFolderId = accessControls.reduce(
        (allowedIdList, accessObj) => {
          if (
            accessObj.get('entityType') === 'template_folder' &&
            (accessObj.get('scopes').includes('write') ||
              accessObj.get('scopes').includes('owner'))
          ) {
            return allowedIdList.push(accessObj.get('entityId'));
          }
          return allowedIdList;
        },
        new List()
      );

      // reduce the list of templateFolders to only those shared with you and have edit access to
      return templateFolders.toList().filter(templateFolder => {
        if (templateFolder.get('user')) {
          return (
            templateFolder.getIn(['user', 'id']) !== currentUser.get('id') &&
            editableFolderId.includes(templateFolder.get('id'))
          );
        }

        return false;
      });
    }
  );
};

/**
 * Select a list of the folders that you have edit access to. This includes the owned folders list.
 *
 * @return func
 */
export const makeGetEditableFoldersList = () => {
  return createSelector(
    [makeGetOwnedFolderList(), makeGetSharedFolderList()],
    (ownedFolderList, sharedFolderList) => {
      return ownedFolderList.concat(sharedFolderList);
    }
  );
};
