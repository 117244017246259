import cx from 'classnames';
import { HTMLProps, forwardRef, useMemo, useState } from 'react';
import { motion } from 'framer-motion';

import { RequireAtLeastOne } from '../type';

import Text from './Text';
import { DownChevron } from './BoogieIcon';
import { DropDownItem, StandardDropDownItem } from './DropDownItem';
import DropDownList, { PicklistRef } from './DropDownList';

// export all the types from DropDown Item
export * from './DropDownItem';

export type PicklistProps = {
  items?: DropDownItem[];
  // These are the items that will always be render first and will not be filtered
  nonFilteredItems?: DropDownItem[];
  disabled?: boolean;
  onChange?: (value: StandardDropDownItem | null) => void;
  onSearchChange?: (value: string) => void;
  onClear?: () => void;
  selectedValues?: StandardDropDownItem[] | null;
  placeholder?: string;
  className?: HTMLProps<HTMLElement>['className'];
  maxHeight?: number;
  hasSearch?: true;
  borderNone?: true;
};

const PicklistNew = forwardRef<
  PicklistRef,
  RequireAtLeastOne<PicklistProps, 'items' | 'nonFilteredItems'>
>(
  (
    {
      placeholder,
      items,
      nonFilteredItems,
      disabled,
      onChange,
      onSearchChange,
      onClear,
      selectedValues,
      className,
      maxHeight = 200,
      hasSearch,
      borderNone,
    },
    forwardedRef,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearchValue] = useState('');
    const [inputFocused, setInputFocused] = useState(false);

    const filteredItems = useMemo(
      () =>
        (nonFilteredItems || []).concat(
          (items || []).filter(item => {
            if (
              item.type === 'header' ||
              item.type === 'divider' ||
              item.type === 'clear'
            ) {
              return true;
            }

            return (
              item.value
                ?.toString()
                ?.toLowerCase()
                .includes(search.trim().toLowerCase()) ||
              item.text.toLowerCase().includes(search.trim().toLowerCase()) ||
              item.secondaryText?.some(text =>
                text?.toLowerCase().includes(search.trim().toLowerCase()),
              )
            );
          }),
        ),
      [nonFilteredItems, items, search],
    );

    const handleSearch = (value: string): void => {
      setSearchValue(value);
      onSearchChange?.(value);
    };

    const shouldShowDropdown = isOpen && filteredItems.length > 0;

    const internalChild = (
      <div className="flex flex-1 px-[7px] min-w-0">
        <motion.div
          className="relative flex-1 h-full items-center flex truncate min-w-0"
          initial={{ opacity: 1 }}
          animate={{
            opacity: inputFocused && hasSearch ? 0 : 1,
          }}
          transition={{
            duration: 0.15,
            ease: 'easeInOut',
            delay: inputFocused ? 0 : 0.1,
          }}
        >
          <Text
            variant="body-sm"
            className={cx(
              'whitespace-pre pointer-events-none truncate',
              disabled
                ? 'text-neutral/600'
                : placeholder &&
                    !selectedValues &&
                    !selectedValues?.[0] &&
                    'text-neutral/400',
            )}
          >
            {(selectedValues && selectedValues[0]?.text) || placeholder}
          </Text>
        </motion.div>
        <div className="relative w-[12px] pl-[6px] box-content">
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: shouldShowDropdown ? -180 : 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
          >
            <DownChevron
              className={cx(
                'm-auto w-[12px]',
                disabled ? 'text-neutral/00' : 'text-clari-blue/600',
              )}
            />
          </motion.div>
        </div>
      </div>
    );

    return (
      <DropDownList
        items={filteredItems}
        internalChild={internalChild}
        onSearchChange={handleSearch}
        onChange={onChange}
        onClear={onClear}
        onInputFocusChange={setInputFocused}
        className={className}
        maxHeight={maxHeight}
        hasSearch={hasSearch}
        onOpenChange={setIsOpen}
        disabled={disabled}
        borderNone={borderNone}
        ref={forwardedRef}
      />
    );
  },
);

export default PicklistNew;
