import { FC, useEffect, useRef, useState, CSSProperties } from 'react';
import cx from 'classnames';

import calcTextWidth from '../helpers/calcTextWidth';
import useHandleOutsideClicks from '../helpers/useHandleOutsideClicks';

import Text from './Text';
import ButtonArraySingle from './ButtonArraySingle';
import DropDownFrame from './DropDownFrame';
import Tooltip from './Tooltip';

const LINE_HEIGHT = 40;

type Button = {
  label: string;
  value: string;
};
type SelectedButton = Button & {
  selected?: boolean;
};

type ButtonList = Button[];

type ButtonArrayProps = {
  buttonList: ButtonList;
  selectedValue: string;
  onChange: (value: string) => void;
  dropDownWidth?: string;
  disabled?: boolean;
  tooltip?: string;
};

/**
 * This component automatically fills the given area with the buttons in the order of the given array.
 * A selected button will always be the last item of the visible portion of the array if it's part of the overflow.
 * Please make sure that the button text fits the visible area
 * Also if a value is given that isn't part of the button list, then the first button is selected
 *
 * @param param ButtonArrayProps
 * @returns Button Array Component
 */
const ButtonArray: FC<ButtonArrayProps> = ({
  buttonList,
  selectedValue,
  onChange,
  dropDownWidth = '100%',
  disabled,
  tooltip,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [initialized, setInitialized] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [menuButtons, setMenuButtons] = useState<SelectedButton[]>([]);
  const [shownButtons, setShownButtons] = useState<SelectedButton[]>([]);

  const onOverFlowClick = (): void => {
    if (!disabled) setShowDropDown(!showDropDown);
  };
  const handleClickOutside = (): void => setShowDropDown(false);

  useHandleOutsideClicks(containerRef, handleClickOutside);

  const calcButtonLayout = (): void => {
    // We just need to round down th make sure we don't accidentally add a pixel
    const maxWidth = (containerRef.current?.clientWidth || 0) - 1;

    // Always select the first button in array selected when value doesn't match any buttons
    let selectedIndex = buttonList.findIndex(
      button => button.value === selectedValue,
    );

    if (selectedIndex < 0) selectedIndex = 0;

    const calcButtonWidth = (label: string): number =>
      calcTextWidth(label, containerRef, 'button-array-canvas', 22);

    const ellipsisWidth = calcButtonWidth(`&#8943;`);
    let arrayWidth = calcButtonWidth(buttonList[selectedIndex].label);
    let lessThanMaxWith = true;
    const shownButtons: SelectedButton[] = [];
    const menuButtons: SelectedButton[] = [];

    const isLessThanMaxWidth = (
      buttonWidth: number,
      isLast = false,
    ): boolean => {
      if (isLast) return arrayWidth + buttonWidth < maxWidth;
      return arrayWidth + buttonWidth + ellipsisWidth < maxWidth;
    };

    buttonList.forEach((button, index) => {
      if (index === selectedIndex) {
        shownButtons.push({ ...button, selected: true });
        return;
      }
      const isLast = shownButtons.length === buttonList.length - 1;

      const buttonWidth = calcButtonWidth(buttonList[index].label);
      if (!isLessThanMaxWidth(buttonWidth, isLast)) lessThanMaxWith = false;
      if (lessThanMaxWith) {
        arrayWidth += buttonWidth;
        shownButtons.push(button);
      } else {
        menuButtons.push(button);
      }
    });

    setMenuButtons(menuButtons);
    setShownButtons(shownButtons);
  };

  useEffect(() => {
    if (!initialized) return;

    const element = containerRef?.current;

    if (!element) return;

    const observer = new ResizeObserver(calcButtonLayout);
    observer.observe(element);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);

  useEffect(() => {
    calcButtonLayout();
    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, JSON.stringify(buttonList)]);

  const onItemClick = (value: string): void => {
    onChange(value);
    setShowDropDown(false);
  };

  const Item: FC<{ index: number; style: CSSProperties }> = ({
    index,
    style,
  }) => {
    const item = menuButtons[index];
    return (
      <div
        aria-label={item.label}
        onClick={() => !disabled && onItemClick(item.value)}
        className={cx(
          `cursor-pointer bg-white border-0  h-[${LINE_HEIGHT}px] flex hover:bg-neutral/50`,
        )}
        key={item.label}
        tabIndex={!disabled ? 0 : 1}
        aria-disabled={disabled}
        onKeyUp={event =>
          !disabled && event.key === 'Enter' && onItemClick(item.value)
        }
        role="button"
        style={style}
      >
        <div className="px-1.5 py-1 flex-row flex items-center flex-1">
          <div className="ml-3">
            <Text>{item.label}</Text>
          </div>
        </div>
      </div>
    );
  };

  const buttonsRender = (
    <div className="rounded border border-solid border-neutral/200 overflow-hidden h-[30px] inline-block">
      {shownButtons.map((button, index) => (
        <ButtonArraySingle
          onClick={() => !disabled && onItemClick(button.value)}
          isLast={index === buttonList.length - 1}
          isActive={button.selected}
          key={button.label}
          disabled={disabled}
        >
          {button.label}
        </ButtonArraySingle>
      ))}
      {menuButtons && menuButtons.length > 0 && (
        <ButtonArraySingle
          onClick={onOverFlowClick}
          isLast
          key="overflow"
          disabled={disabled}
        >
          &#8943;
        </ButtonArraySingle>
      )}
    </div>
  );

  return (
    <div className="text-body-sm box-border font-groove" ref={containerRef}>
      <div className="inline-block relative">
        {tooltip && <Tooltip content={tooltip}>{buttonsRender}</Tooltip>}
        {!tooltip && buttonsRender}
        <DropDownFrame
          renderedItem={Item}
          itemList={menuButtons}
          showDropDown={showDropDown}
          lineHeight={LINE_HEIGHT}
          width={dropDownWidth}
          direction="bottomRight"
        />
      </div>
    </div>
  );
};

export default ButtonArray;
