import { Record, Map } from 'immutable';
import Person from 'Modules/Actions/records/Person';
import FlowMetadata from 'Modules/Actions/records/FlowMetadata';
import {
  ACTION_PERSON_FLOW_ID_KEY_PATH,
  ACTION_CHILD_ID_KEY_PATH,
  ACTION_FLOW_ID_KEY_PATH,
} from 'Modules/ActionCompose/constants/graphql';
import { getTemplateName } from 'Modules/ActionCompose/utils';
import sharedDefaults from './defaults';
import flowDefaults from './flowDefaults';

const defaults = {
  ...sharedDefaults,
  ...flowDefaults,
  currentValues: new Map({
    sfdcType: null,
    body: null,
    subject: null,
  }),
};

export default class DirectMailAction extends Record(defaults) {
  static fromGraphQL(action) {
    const executionContext = action.get('executionContext');
    const sfdcType = executionContext?.get('sfdcType');

    const mergedTemplate = executionContext?.get('mergedTemplate');
    const body = mergedTemplate?.get('body') || executionContext?.get('body');
    const subject =
      mergedTemplate?.get('subject') || executionContext?.get('subject');
    const templateName = getTemplateName(action, executionContext);

    return new DirectMailAction({
      id: action.get('id'),
      source: action.get('source'),
      type: action.get('type'),
      summary: action.get('summary'),
      priority: action.get('priority'),
      failedData: action.get('failedData'),
      error: action.get('error'),
      sourceMetadata: FlowMetadata.from(
        action.get('sourceMetadata')?.toJS() || {}
      ),
      templateName,
      personStepId: action.getIn(ACTION_CHILD_ID_KEY_PATH),
      personFlowId: action.getIn(ACTION_PERSON_FLOW_ID_KEY_PATH),
      flowId: action.getIn(ACTION_FLOW_ID_KEY_PATH),
      stepId: action.getIn(ACTION_FLOW_ID_KEY_PATH),
      ...(action.has('who') && { recipient: Person.from(action.get('who')) }),
      sfdcType,
      body,
      subject,
      currentValues: new Map({
        sfdcType,
        body,
        subject,
      }),
    });
  }
}
