import moment from 'moment-timezone';
import { useGetCurrentUser } from '@groove/api/hooks/useUsers';
import { SOURCES } from 'Modules/ActionsRewrite/components/customCells/ActionType/ActionTypeConstants';
import { Action } from '@groove/api/gateway/v1/actions';
import { TableText } from '@clari/iris-table';

const DueAt = ({ action }: { action: Action }) => {
  const { data: userData } = useGetCurrentUser();
  const currentUserTimeZone = userData?.time_zone || '';

  const inferredTimeZone = currentUserTimeZone || moment.tz.guess();

  const { due_at: dueAt, source } = action;
  if (!dueAt) return null;

  const dateMoment = moment(dueAt);
  const actionDayCreated = dateMoment.tz(inferredTimeZone).startOf('day');
  const today = moment().tz(inferredTimeZone).startOf('day');
  const isOverdue = actionDayCreated.diff(today, 'days') < 0;

  // One-Off actions are the only type where the time is useful information to the user
  const dateFormat =
    source === SOURCES.ONE_OFF || source === SOURCES.PLAY
      ? 'MM/DD/YYYY hh:mm A'
      : 'MM/DD/YYYY';

  return (
    <TableText>
      <span className={isOverdue ? 'text-error/600' : ''}>
        {dateMoment.tz(inferredTimeZone).format(dateFormat)}
      </span>
    </TableText>
  );
};

export default DueAt;
