import { trackEvent } from 'Utils/segment';
import { takeEvery } from 'redux-saga/effects';
import {
  createSafeActionHandler,
  ANALYTICS_BASE_PROPERTIES,
} from 'Modules/Shared/sagas/analytics';
import { actionTypes } from 'Modules/FlowsShow/actions';

const featureCategory = 'flows';

function* autoImportConfigured() {
  yield takeEvery(
    actionTypes.UPDATE_AUTO_IMPORT.SUCCESS,
    createSafeActionHandler(({ payload }) => {
      const { importType, id } = payload;
      trackEvent('Auto Import Configured', {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory,
        importType,
        id,
      });
    })
  );
}

export default autoImportConfigured;
