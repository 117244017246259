import { Dialog, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

import HighspotContainer from './HighspotContainer';

const useStyles = makeStyles({
  dialog: {
    overflow: 'hidden',
    maxHeight: 'unset',
  },
});

interface HighspotDialogProps {
  onClose: () => void;
  isOpen: boolean;
  recipientEmail?: string;
}
const HighspotDialog: FC<HighspotDialogProps> = ({
  isOpen,
  onClose,
  recipientEmail,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ paper: classes.dialog }}
      fullWidth
    >
      <HighspotContainer onClose={onClose} recipientEmail={recipientEmail} />
    </Dialog>
  );
};

export default HighspotDialog;
