import { call, all, takeEvery } from 'redux-saga/effects';
import {
  createSafeActionHandler,
  ANALYTICS_BASE_PROPERTIES,
} from 'Modules/Shared/sagas/analytics';
import { trackEvent } from 'Utils/segment';
import { actionTypes as peopleActionTypes } from 'Modules/People/actions';
import { actionTypes as accountActionTypes } from 'Modules/Accounts/actions';
import { actionTypes as workstepActionTypes } from 'Modules/WorkStep/actions';
import { ROUTE_NAMES } from 'Routing/constants';
import { tableActionTypes as spacesActionTypes } from 'Modules/Spaces/show/actions';

const eventName = 'Column Sorted';

const sortingActions = [
  {
    featureCategory: ROUTE_NAMES.SPACES_SHOW,
    action: spacesActionTypes.SET_NEXT_SORTED_ORDER,
  },
  {
    featureCategory: ROUTE_NAMES.PEOPLE,
    action: peopleActionTypes.UPDATE_QUERY_AND_REFETCH,
  },
  {
    featureCategory: ROUTE_NAMES.ACCOUNTS,
    action: accountActionTypes.UPDATE_QUERY_AND_REFETCH,
  },
  {
    featureCategory: ROUTE_NAMES.WORKSTEP,
    action: workstepActionTypes.ORDER_PEOPLE,
  },
];

function* trackColumnSorted({ featureCategory, action }) {
  yield takeEvery(
    action,
    createSafeActionHandler(({ payload }) => {
      const { orderBy, orderDir, orderDirection } = payload;
      trackEvent(eventName, {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory,
        orderBy,
        orderDirection: orderDir || orderDirection,
      });
    })
  );
}

function* columnSorted() {
  yield all(
    sortingActions.map(({ featureCategory, action }) => {
      return call(trackColumnSorted, {
        featureCategory,
        action,
      });
    })
  );
}

export default columnSorted;
