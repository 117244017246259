import { all, call, takeEvery, select } from 'redux-saga/effects';
import startCase from 'lodash-es/startCase';
import {
  createSafeActionHandler,
  ANALYTICS_BASE_PROPERTIES,
} from 'Modules/Shared/sagas/analytics';
import { trackEvent } from 'Utils/segment';
import { actionTypes as analyticsActionTypes } from 'Modules/Analytics/actions';
import { actionTypes as flowsShowActionTypes } from 'Modules/FlowsShow/actions';
import { makeGetActiveTab } from 'Modules/Analytics/selectors';
import { ROUTE_NAMES } from 'Routing/constants';

const eventName = 'CSV Exported';

const exportActions = [
  {
    action: analyticsActionTypes.EXPORT_AS_CSV.SUCCESS,
    featureCategory: ROUTE_NAMES.ANALYTICS,
    source: null,
  },
  {
    action: flowsShowActionTypes.DOWNLOAD_STATS_CSV.SUCCESS,
    featureCategory: ROUTE_NAMES.FLOWS_SHOW,
    source: 'Flows - People Tab',
  },
];

function trackCSVExported({ source, featureCategory }) {
  trackEvent(eventName, {
    ...ANALYTICS_BASE_PROPERTIES,
    featureCategory,
    source,
  });
}

function* handleAnalyticsExported({ featureCategory }) {
  const activeTab = yield select(makeGetActiveTab());
  const source = `Analytics - ${startCase(activeTab)}`;
  trackCSVExported({ source, featureCategory });
}

function* csvExported() {
  yield all(
    exportActions.map(function* track({ featureCategory, action, source }) {
      return yield takeEvery(
        action,
        createSafeActionHandler(function* track() {
          if (action === analyticsActionTypes.EXPORT_AS_CSV.SUCCESS) {
            yield call(handleAnalyticsExported, { featureCategory });
            return;
          }
          trackCSVExported({ featureCategory, source });
        })
      );
    })
  );
}

export default csvExported;
