import { SagaIterator } from 'redux-saga';
import { decamelizeKeys } from 'humps';

import { spacesTransformResponse } from 'GrooveHTTPClient/salesforceAccountSearch';

import makeHTTPRequest from '../makeHTTPRequest';

const BASE_URL = '/api/v2/salesforce_opportunities_search';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformResponse = (data: any) =>
  spacesTransformResponse(JSON.parse(data).data);

type SearchByNamePayload = {
  name: string;
  id: number;
  userId: number;
};

export const searchByName = ({
  name,
  id,
  userId,
}: SearchByNamePayload): SagaIterator =>
  makeHTTPRequest({
    path: `/api/v2/workspaces/${id}/opportunities_search/`,
    queryParameters: decamelizeKeys({
      name,
      userId,
    }),
    transformResponse,
  });

type SearchByMatchingFieldPayload = {
  sfdcFieldName: string;
  sfdcFieldValue: string;
};

export const searchByMatchingField = ({
  sfdcFieldName,
  sfdcFieldValue,
}: SearchByMatchingFieldPayload): SagaIterator =>
  makeHTTPRequest({
    path: `${BASE_URL}/search_by_matching_field`,
    queryParameters: decamelizeKeys({
      sfdcFieldName,
      sfdcFieldValue,
    }),
    transformResponse,
  });

type SearchByReportPayload = {
  sfdcReportId: string;
};

export const searchByReport = ({
  sfdcReportId,
}: SearchByReportPayload): SagaIterator =>
  makeHTTPRequest({
    path: `${BASE_URL}/search_by_report`,
    queryParameters: decamelizeKeys({
      sfdcReportId,
    }),
    transformResponse,
  });
