import { fromJS } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/activitySettings';

export const initialState = fromJS({});

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.FETCH.SUCCESS: {
      return fromJS(action.payload);
    }

    default:
      return state;
  }
}
