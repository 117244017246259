import shortId from 'shortid';

export const CHECKED_TYPES = Object.freeze({
  FULLY_CHECKED: 'FULLY_CHECKED',
  PARTIALLY_CHECKED: 'PARTIALLY_CHECKED',
  UNCHECKED: 'UNCHECKED',
});

export function identifyCheckedState(checkedItems, allItems) {
  const sizeDifference = allItems.size - checkedItems.size;
  if (sizeDifference === 0) {
    return CHECKED_TYPES.FULLY_CHECKED;
  } else if (sizeDifference > 0 && checkedItems.size !== 0) {
    return CHECKED_TYPES.PARTIALLY_CHECKED;
  }

  return CHECKED_TYPES.UNCHECKED;
}

export function generateSingleSelectOptionId(modifier) {
  return `${new Date().getTime()}-${modifier}-${shortId.generate()}`;
}

export const firestoreWatcherCategoryToReducerKey = category =>
  `${category}FetchComplete`;
