import { OrderedSet, Set } from 'immutable';

import { createSelector } from 'reselect';

import { ANALYST_ROLE, VALID_ROLES } from 'Modules/Coaching/constants';
import { currentUser as getCurrentUser } from 'Modules/Shared/selectors/users';
import { getLdClient } from 'Utils/ldClient';

const getTeamMembers = state => state.getIn(['coaching', 'teamMembers']);
const getTeams = state =>
  OrderedSet(state.getIn(['coaching', 'teams']).valueSeq());
const getSelectedTeamIds = state =>
  state.getIn(['coaching', 'selectedTeamIds']);
const hasSelectedTeams = state => !getSelectedTeamIds(state).isEmpty();
const getSelectedTeamsCount = state => getSelectedTeamIds(state).size;
const getSelectedUserIds = createSelector(
  getTeamMembers,
  getSelectedTeamIds,
  (teamMembers, selectedTeamIds) => {
    const selectedTeamMembers = teamMembers.filter(teamMember =>
      selectedTeamIds.has(teamMember.team.id)
    );
    const selectedUserIds = selectedTeamMembers.map(
      teamMember => teamMember.user.id
    );
    return Set(selectedUserIds);
  }
);
const getTeamUserIds = (state, teamId) => {
  const teamMembers = getTeamMembers(state);
  const userIds = teamMembers
    .filter(teamMember => teamMember.team.id === teamId)
    .map(teamMember => teamMember.user.id);
  return Set(userIds);
};

const shouldEnableCallCoaching = createSelector(getCurrentUser, currentUser => {
  const isAnalystCoachingEnabled = getLdClient().variation(
    'analyst-call-coaching'
  );
  return (
    currentUser &&
    (VALID_ROLES.includes(currentUser.get('role')) ||
      (isAnalystCoachingEnabled && currentUser.get('role') === ANALYST_ROLE))
  );
});

export {
  getSelectedTeamIds,
  getSelectedTeamsCount,
  getSelectedUserIds,
  getTeamMembers,
  getTeams,
  getTeamUserIds,
  hasSelectedTeams,
  shouldEnableCallCoaching,
};
