import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as shortId from 'shortid';

import { greyContent, pulse, shimmer } from 'Modules/Shared/styles/shimmerBar';
import { ModifiedTheme } from 'Utils/theme/ClariTheme';

const useStyles = makeStyles((theme: ModifiedTheme) => ({
  statContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 12,
    marginBottom: 12,
  },
  statCard: {
    height: '112px',
    marginRight: 24,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  cardEdge: {
    width: 12,
    backgroundColor: theme.palette.text.accentGray,
  },
  cardContent: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  data: {
    ...greyContent,
    height: '40px',
    width: '75%',
    margin: '0px 30px 7px 10px',
  },
  category: {
    ...greyContent,
    height: '15px',
    width: '75%',
    margin: '0px 30px 0px 10px',
  },
  loading: {
    ...pulse,
  },
  ...shimmer,
}));

type LoaderCardPropTypes = {
  cardCount?: number;
  failed: boolean;
  removeContainer?: boolean;
};

const LoaderCard: React.FC<LoaderCardPropTypes> = ({
  failed,
  cardCount = 5,
  removeContainer = false,
}) => {
  const classes = useStyles({});
  const cards = [...Array(cardCount)].fill(null).map(shortId.generate);

  const cardElements = cards.map(value => (
    <Card key={value} className={classes.statCard}>
      <div className={classes.cardEdge} />
      <CardContent className={classes.cardContent}>
        <div className={`${classes.data} ${failed ? null : classes.loading}`} />
        <div
          className={`${classes.category} ${failed ? null : classes.loading}`}
        />
      </CardContent>
    </Card>
  ));

  return removeContainer ? (
    <React.Fragment>{cardElements}</React.Fragment>
  ) : (
    <div className={classes.statContainer}>{cardElements}</div>
  );
};

export default LoaderCard;
