import { Record } from 'immutable';
import listToOrderedMap from 'Utils/list-to-ordered-map';

const LastTouch = new Record({
  type: null,
  createdAt: null,
});

const LastEngagement = new Record({
  type: null,
  createdAt: null,
});

const PersonRecord = new Record({
  id: null,
  sfdcId: null,
  name: null,
  email: null,
  emails: null,
  phones: null,
  flows: null,
  calls: null,
  phone: null,
  mobilePhone: null,
  hotlisted: null,
  opens: null,
  clicks: null,
  replies: null,
  lastTouch: null,
  lastTouchedAt: null,
  lastEngagement: null,
  lastEngagedAt: null,
  engagement: null,
  companyName: null,
  accountId: null,
  title: null,
  personRollups: [],
});

export const Query = new Record({
  filters: [],
  orderBy: null,
  orderDir: null,
  offset: null,
  limit: null,
  scope: null,
});

export const PersonRollup = new Record({
  id: null,
  personId: null,
  week: null,
  engagement: null,
  effort: null,
  createdAt: null,
  updatedAt: null,
  outboundDials: null,
  outboundCalls: null,
  emailsSentViaFlowSend: null,
  emailsSentViaFlowSendAll: null,
  emailsSentViaConnect: null,
  inboundCalls: null,
  emailOpens: null,
  emailLinkClicks: null,
  emailReplies: null,
  emailUnsubscribeReplies: null,
});

export class Person extends PersonRecord {
  constructor(props = {}) {
    super({
      ...props,
      flows: listToOrderedMap(props.flows, flow => flow.id),
      personRollups: props.personRollups.map(
        personRollup => new PersonRollup(personRollup)
      ),
      lastTouch: new LastTouch(props.lastTouch),
      lastEngagement: new LastEngagement(props.lastEngagement),
    });
  }
}

export class SearchRule extends Record(
  {
    field: null,
    operator: null,
    value: null,
    formGroupId: null,
  },
  'SearchRule'
) {}
