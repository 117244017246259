import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { IDropdownOption } from '@fluentui/react';
import { getActionsCreators } from '@groove/api/gateway/v1/actions';

export default function useGetCreatorsOptions(): IDropdownOption[] {
  const [creators, setCreators] = useState<IDropdownOption[]>([]);
  const { data: rawCreators } = useQuery('creators', getActionsCreators);

  useEffect(() => {
    setCreators(
      rawCreators?.data.map(user => ({
        key: user.creator_id.toString(),
        ariaLabel: user.name,
        text: user.name,
      })) || [],
    );
  }, [rawCreators]);

  return creators;
}
