import React from 'react';

import Typography from '@material-ui/core/Typography';
import FlowLimitCounter from 'Modules/Shared/containers/FlowLimitCounter';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const HeaderSection: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3">Actions</Typography>
      <FlowLimitCounter />
    </div>
  );
};

export default HeaderSection;
