import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { trackBasicEvent } from 'Modules/Shared/sagas/analytics';
import { actionTypes } from 'Modules/PeopleImportDialog/actions';

const FEATURE_CATEGORY = 'flows';

function* trackFlowAssigneeUpdated(): SagaIterator {
  yield call(trackBasicEvent, {
    featureCategory: FEATURE_CATEGORY,
    eventName: 'Flow Assignee Updated',
    action: actionTypes.TRACK_UPDATE_FLOW_ASSIGNEE,
  });
}

export default trackFlowAssigneeUpdated;
