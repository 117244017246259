import { createActions, standardActions } from '@groove-labs/action-utils';

export const actionTypes = createActions({
  PEOPLE_IMPORT_DIALOG: {
    REPORTS: {
      FETCH_REPORT_FOLDERS: standardActions(),
      FETCH_REPORTS: standardActions(),
      FETCH_REPORT: standardActions(),
      FETCH_PEOPLE: standardActions(),
    },
  },
}).PEOPLE_IMPORT_DIALOG.REPORTS;

export const fetchReportFolders = () => {
  return {
    type: actionTypes.FETCH_REPORT_FOLDERS.BEGIN,
  };
};

export const requestFetchReports = (payload = {}) => {
  return {
    type: actionTypes.FETCH_REPORTS.BEGIN,
    payload,
  };
};

export const requestFetchReport = () => {
  return {
    type: actionTypes.FETCH_REPORT.BEGIN,
  };
};

export const requestFetchPeople = () => {
  return {
    type: actionTypes.FETCH_PEOPLE.BEGIN,
  };
};
