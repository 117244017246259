/**
 * creates a string representing the toolbar. The string is broken up into sections
 * separated by the pipe character. This function abstracts that away into a simple
 * array interface
 * @param  {...Array<string>} sections
 */
const createToolbar = (...sections: string[][]) =>
  sections.map(section => section.join(' ')).join(' | ');

export default (): string =>
  // Be sure to add the new button or menu item into createToolbar
  createToolbar(
    [
      'mobileKeyboard',
      'variants',
      'template',
      'scheduler',
      'gen-ai',
      'trackEmails',
    ],
    ['fontselect', 'fontsizeselect'],
    ['bold', 'italic', 'underline'],
    ['backcolor', 'forecolor'],
    ['align', 'ltr', 'rtl', 'numlist', 'bullist'],
    ['merge-fields', 'highspot', 'vidyard', 'seismic'],
    ['image', 'file', 'link'],
    ['removeformat', 'code'],
  );
