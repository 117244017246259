import { decamelizeKeys } from 'humps';

import getClient from './getClient';

export type ApiSalesforceGetAllRelevantFields = {
  data: {
    [key: string]: Field[];
  };
};

export type SalesforceFieldType =
  | 'address'
  | 'base64'
  | 'boolean'
  | 'byte'
  | 'combobox'
  | 'currency'
  | 'date'
  | 'datetime'
  | 'double'
  | 'email'
  | 'id'
  | 'int'
  | 'integer'
  | 'long'
  | 'multipicklist'
  | 'name'
  | 'percent'
  | 'phone'
  | 'picklist'
  | 'reference'
  | 'string'
  | 'textarea'
  | 'time'
  | 'url';

export interface Field {
  label: string;
  type: SalesforceFieldType;
  updateable: boolean;
  nillable: boolean;
  name: string;
  filterable: boolean;
  relationshipName?: string;
  defaultedOnCreate: boolean;
  dependentPicklist: boolean;
  controllerName: unknown;
  length?: number;
  referenceTo?: string;
  referenceToFull?: string[];
  picklist?: Picklist[];
  maxlength?: number;
  scale?: number;
}

export interface Picklist {
  value: string;
  label: string;
  validFor: unknown;
}

export const CONTACT_PREFIX = '003';
export const LEAD_PREFIX = '00Q';
export const OPPORTUNITY_PREFIX = '006';
export const ACCOUNT_PREFIX = '001';
export const CONSULTANT_PREFIX = '001';
export const CAMPAIGN_PREFIX = '701';

export type SalesforceFindParam = {
  accountIds?: string[];
  contactIds?: string[];
  leadIds?: string[];
  opportunityIds?: string[];
};

export type ApiSalesforceFind = {
  accounts: {
    Id: string;
    Name: string;
  }[];
  contacts: {
    Account: {
      Id: string;
      Name: string;
    };
    Email: string;
    Id: string;
    Name: string;
    Owner: {
      Id: string;
      Name: string;
    };
    Phone: string;
    Title: string;
  }[];
  leads: {
    Company: string;
    Email: string;
    Id: string;
    Name: string;
    Owner: {
      Id: string;
      Name: string;
    };
    Phone: string;
  }[];
  opportunities: {
    Account: {
      Id: string;
      Name: string;
    };
    CloseDate: string;
    Id: string;
    IsClosed: boolean;
    Name: string;
    Owner: {
      Id: string;
      Name: string;
    };
    StageName: string;
    Type: string;
  }[];
};

export const salesforceGetAllRelevantFields = async (
  sfdcObject: string,
): Promise<ApiSalesforceGetAllRelevantFields> => {
  return getClient()
    .post('salesforce/relevant_fields', {
      json: {
        variables: { sfdc_object: sfdcObject },
      },
    })
    .json();
};

export const salesforceFind = async (
  params: SalesforceFindParam,
): Promise<ApiSalesforceFind> => {
  return getClient()
    .post('salesforce/find', {
      json: decamelizeKeys(params || {}),
    })
    .json();
};

export type ApiSalesforceMeta = {
  data: SalesforceMeta;
};
export type SalesforceMetaFields = {
  [key: string]: {
    label: string;
    name: string;
    type: string;
  };
};

export type SalesforceMeta = {
  [key: string]: {
    fields: SalesforceMetaFields;
  };
};

export const salesforceMeta = (): Promise<ApiSalesforceMeta> => {
  return getClient().get('salesforce/meta').json();
};
