import { Dialog, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

import VidyardContainer from './VidyardContainer';

const useStyles = makeStyles({
  dialog: {
    width: 890,
    overflow: 'hidden',
    maxHeight: 'unset',
  },
});

interface VidyardDialogProps {
  onClose: () => void;
  isOpen: boolean;
  recipientEmail?: string;
  showVidyardLink?: boolean;
}
const VidyardDialog: FC<VidyardDialogProps> = ({
  isOpen,
  onClose,
  recipientEmail,
  showVidyardLink,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} onClose={onClose} classes={{ paper: classes.dialog }}>
      <VidyardContainer
        onClose={onClose}
        recipientEmail={recipientEmail}
        showVidyardLink={showVidyardLink}
      />
    </Dialog>
  );
};

export default VidyardDialog;
