import { IconButton, Separator, Text } from '@fluentui/react';
import { FC, Fragment } from 'react';

import useStore from './useStore';
import deriveActivities from './utils/deriveActivities';

type ReviewProps = {
  onBack: () => void;
};

const Review: FC<ReviewProps> = ({ onBack }) => {
  const selectedNames = useStore(store => store.selectedNames);
  const setSelectedNames = useStore(store => store.setSelectedNames);
  const selectedRelatedTos = useStore(store => store.selectedRelatedTos);
  const setSelectedRelatedTos = useStore(store => store.setSelectedRelatedTos);

  const activities = deriveActivities(selectedNames, selectedRelatedTos);

  return (
    <div className="p-2">
      <div className="flex">
        <IconButton iconProps={{ iconName: 'ChevronLeft' }} onClick={onBack} />
        <Text className="pl-2 text-lg font-semibold">
          Review activities ({selectedRelatedTos.length || selectedNames.length}
          )
        </Text>
      </div>
      <div className="flex flex-col flex-grow pt-4 overflow-y-auto">
        {activities.map(({ names, relatedTo }) => (
          <Fragment key={relatedTo?.id || names[0]?.id}>
            <div className="flex items-center justify-between py-2">
              <div className="flex flex-col">
                <Text block className="pl-2 font-semibold">
                  Name:
                </Text>
                {names.map(name => (
                  <Text key={name.id} block className="pl-2">
                    {name.text} ({name.type})
                  </Text>
                ))}

                {relatedTo && (
                  <>
                    <Text block className="pl-2 font-semibold">
                      Related To:
                    </Text>
                    <Text block className="pl-2">
                      {relatedTo.text} ({relatedTo.type})
                    </Text>
                  </>
                )}
              </div>
              <IconButton
                iconProps={{ iconName: 'Delete' }}
                ariaLabel={`Remove ${(relatedTo || names[0]).text}`}
                onClick={() => {
                  if (relatedTo) {
                    const newSelectedRelatedTos = selectedRelatedTos.filter(
                      selectedRelatedTo =>
                        selectedRelatedTo.id !== relatedTo.id,
                    );
                    setSelectedRelatedTos(newSelectedRelatedTos);
                    if (newSelectedRelatedTos.length === 0) {
                      setSelectedNames([]);
                      onBack();
                    }
                  } else {
                    const newSelectedNames = selectedNames.filter(
                      selectedName =>
                        !names.some(name => name.id === selectedName.id),
                    );
                    setSelectedNames(newSelectedNames);
                    if (newSelectedNames.length === 0) {
                      onBack();
                    }
                  }
                }}
              />
            </div>
            <Separator className="before:bg-gray2" />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Review;
