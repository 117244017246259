import sendPostMessage from 'Modules/Shared/utilities/sendPostMessage';
import {
  INCOMING_POST_MESSAGE_TARGET as ACTION_COMPOSE_INCOMING_POST_MESSAGE_TARGET,
  IFRAME_NAME,
} from 'Modules/ActionCompose/constants';
import { OUTGOING_POST_SOURCE_SOURCE } from 'Modules/Actions/constants';

function sendMessageToActionCompose({ type, payload = {} }) {
  sendPostMessage({
    windowContext: window.frames[IFRAME_NAME],
    type,
    payload,
    target: ACTION_COMPOSE_INCOMING_POST_MESSAGE_TARGET,
    source: OUTGOING_POST_SOURCE_SOURCE,
  });
}

export default sendMessageToActionCompose;
