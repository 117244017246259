import { selectors } from '@groove-labs/groove-ui';
import { createSelector } from 'reselect';
import { isEmpty, isUndefined, toNumber, isString } from 'lodash-es';
import pluralize from 'pluralize';
import { List, Set, Map } from 'immutable';
import { activeTab, location } from 'Modules/Shared/selectors/location';
import {
  DEFAULTS,
  CALLS_TAB_DIALOG_KEYPATH,
  ANALYTICS_GROUP_ID,
  ANALYTICS_SEARCH_FIELD_ID,
  TEMPLATE_FILTER_KEYS,
  ANALYTICS_SCOPE_STORAGE_KEY,
} from 'Modules/Analytics/constants';
import { convertScopeParam } from 'Modules/Analytics/utils';
import React from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import TagFacesIcon from '@material-ui/icons/TagFaces';
import {
  currentUser,
  getFilterTeamLeadFromAnalytics,
  getCurrentUserRole,
} from 'Modules/Shared/selectors/users';
import sanitizer from 'Modules/Analytics/utils/sanitizer';
import roles from 'Modules/Shared/constants/roles';
import ClariTheme from 'Utils/theme/ClariTheme';
import * as GrooveLocalStorage from 'GrooveLocalStorage';

const { getProperty } = selectors.ui;
const { makeGetFieldValue } = selectors.form;

export const teams = state => state.getIn(['analytics', 'teams']);
export const users = state => state.getIn(['analytics', 'users']);
export const getIsTeamAnalyticsVisible = state =>
  state.getIn(['analytics', 'teamAnalyticsVisible']);

export const csvExportInProgress = state =>
  state.getIn(['analytics', 'csvExportInProgress']);

export const exportModalIsOpen = state =>
  state.getIn(['analytics', 'export', 'modalIsOpen']);
export const exportSuccessModalIsOpen = state =>
  state.getIn(['analytics', 'export', 'successModalIsOpen']);
export const exportFailureModalIsOpen = state =>
  state.getIn(['analytics', 'export', 'failedModalIsOpen']);
export const exportErrorMessage = state =>
  state.getIn(['analytics', 'export', 'errorMessage']);
export const exportIsProcessing = state =>
  state.getIn(['analytics', 'export', 'processing']);
export const activityOutcomes = state =>
  state.getIn(['analytics', 'calls', 'analyticsData', 'activityOutcomes']);
export const activityResults = state =>
  state.getIn(['analytics', 'calls', 'analyticsData', 'activityResults']);

export const getShowLoggedActivities = state =>
  state.getIn(['analytics', 'activities', 'showLoggedActivities']);

export const getShowFlowsActivities = state =>
  state.getIn(['analytics', 'activities', 'flowsFilter']);

export const activityPeriodUnit = state =>
  state.getIn(['analytics', 'activities', 'periodUnit']);
export const activityTypeFilter = state =>
  state.getIn(['analytics', 'activities', 'typeFilter']);

export const activitiesTabUserData = state =>
  state.getIn(['analytics', 'activities', 'userSummaryTable', 'data']);
export const activitiesTabCurrentlySelected = state =>
  state.getIn([
    'analytics',
    'activities',
    'userSummaryTable',
    'selectedRowIds',
  ]);

export const processTabUserData = state =>
  state.getIn(['analytics', 'process', 'userSummaryTable', 'data']);
export const processTabCurrentlySelected = state =>
  state.getIn(['analytics', 'process', 'userSummaryTable', 'selectedRowIds']);
export const processTabUserRenderData = state =>
  state.getIn(['analytics', 'process', 'userSummaryTable', 'renderData']);

export const emailsTabUserData = state =>
  state.getIn(['analytics', 'emails', 'userBreakdownSummaryTable', 'data']);
export const emailsTabUserRenderData = state =>
  state.getIn([
    'analytics',
    'emails',
    'userBreakdownSummaryTable',
    'renderData',
  ]);

export const emailsTabUserEmailRenderData = state =>
  state.getIn(['analytics', 'emails', 'userEmailSummaryTable', 'renderData']);
export const emailsTabUserEmailOrderBy = state =>
  state.getIn(['analytics', 'emails', 'userEmailSummaryTable', 'orderBy']);
export const emailsTabUserEmailOrderDirection = state =>
  state.getIn([
    'analytics',
    'emails',
    'userEmailSummaryTable',
    'orderDirection',
  ]);

export const csvExportLogsInProgress = state =>
  state.getIn(['analytics', 'csvExportLogsInProgress']);

export const emailsTabUserEmailsId = state =>
  state.getIn(['analytics', 'emails', 'userEmailSummaryTable', 'userId']);

export const emailsUserSelectedRowIds = state =>
  state.getIn(['analytics', 'emails', 'selected']);

export const emailsTabCurrentUser = state =>
  state.getIn(['users', 'currentUser', 'name']);
export const emailsTabCurrentAgentUserName = state => {
  const currUserId = state.getIn([
    'analytics',
    'emails',
    'userEmailSummaryTable',
    'userId',
  ]);
  let userName = state
    .getIn(['analytics', 'emails', 'userBreakdownSummaryTable', 'renderData'])
    .find(user => user.getIn(['rowValues', 'userId']) === currUserId);
  if (userName) {
    userName = userName.getIn(['rowValues', 'userName']);
  }
  return userName;
};

export const searchValue = state =>
  makeGetFieldValue(ANALYTICS_SEARCH_FIELD_ID)(state, {
    groupId: ANALYTICS_GROUP_ID,
  });

export const flowsTabUserDataRaw = state =>
  state.getIn(['analytics', 'flows', 'userSummaryTable', 'data']);
export const flowsTabPage = state =>
  state.getIn(['analytics', 'flows', 'page']);
export const flowsTabPerPage = state =>
  state.getIn(['analytics', 'flows', 'perPage']);

export const flowsTabCurrentlySelected = state =>
  state.getIn(['analytics', 'flows', 'userSummaryTable', 'selectedRowIds']);

export const flowsTabUserRenderDataRaw = state =>
  state.getIn(['analytics', 'flows', 'userSummaryTable', 'renderData']);

export const templatesTabUserDataRaw = state =>
  state.getIn(['analytics', 'templates', 'userSummaryTable', 'data']);
export const templatesTabPage = state =>
  state.getIn(['analytics', 'templates', 'page']);
export const templatesTabPerPage = state =>
  state.getIn(['analytics', 'templates', 'perPage']);

const templateFilter = (flow, searchValue) => {
  return (
    searchValue === '' ||
    TEMPLATE_FILTER_KEYS.some(key =>
      flow
        .getIn(['rowValues', key])
        .toLowerCase()
        .includes(searchValue.trim().toLowerCase())
    )
  );
};

export const makeTemplatesTabUserData = () =>
  createSelector(
    templatesTabUserDataRaw,
    searchValue,
    (templatesTabUserDataRaw, searchValue = '') =>
      templatesTabUserDataRaw.filter(flow => templateFilter(flow, searchValue))
  );

export const templatesTabCurrentlySelected = state =>
  state.getIn(['analytics', 'templates', 'userSummaryTable', 'selectedRowIds']);

export const templatesTabUserRenderDataRaw = state =>
  state.getIn(['analytics', 'templates', 'userSummaryTable', 'renderData']);

export const makeTemplatesTabUserRenderData = () =>
  createSelector(
    templatesTabUserRenderDataRaw,
    searchValue,
    templatesTabPage,
    templatesTabPerPage,
    (
      templatesTabUserRenderDataRaw,
      searchValue = '',
      templatesTabPage,
      templatesTabPerPage
    ) =>
      templatesTabUserRenderDataRaw
        .filter(flow => templateFilter(flow, searchValue))
        .slice(
          (templatesTabPage - 1) * templatesTabPerPage,
          (templatesTabPage - 1) * templatesTabPerPage + templatesTabPerPage
        )
  );

export const getTemplatesRowDataByTemplateId = targetTemplateId =>
  createSelector(
    templatesTabUserRenderDataRaw,
    searchValue,
    templatesTabPerPage,
    (templatesTabUserRenderDataRaw, searchValue = '', templatesTabPerPage) => {
      const targetTemplateRawIndex = templatesTabUserRenderDataRaw
        .filter(flow => templateFilter(flow, searchValue))
        .findIndex(templateRow => templateRow.get('id') === targetTemplateId);

      return Math.floor(targetTemplateRawIndex / templatesTabPerPage) + 1;
    }
  );

/**
 * @return {func}
 */
export const makeGetTeamScope = () => {
  return createSelector([location], location => {
    const teamScope = location.getIn(['searchParams', 'teamScope']);

    if (isUndefined(teamScope)) {
      return DEFAULTS.teamScope;
    }

    if (List.isList(teamScope)) {
      return teamScope.toJS().map(scope => convertScopeParam(scope));
    }

    return [convertScopeParam(teamScope)];
  });
};

export const getUserIdsNotInAnyTeams = createSelector(
  teams,
  users,
  (teams, users = new List()) => {
    const usersInTeams = new Set(
      teams
        .map(team => team.get('users').map(nameEntry => nameEntry.get('name')))
        .flatten()
    );
    const filteredUsers = users.filter(
      userEntry => !usersInTeams.has(userEntry.get('name'))
    );
    return filteredUsers.map(user => user.get('id'));
  }
);

/**
 * @return {func}
 */
/**
 * reads off of query params
 */
export const makeGetUserScope = () => {
  return createSelector(
    [location, currentUser, makeGetTeamScope(), getUserIdsNotInAnyTeams],
    (location, currentUser, teamScope, userIdsNotInAnyTeams) => {
      // where upstart sets the parameters from url

      const userScope = location.getIn(['searchParams', 'userScope']);

      const shouldForceMyPerformanceToBeChecked =
        teamScope.length < 1 &&
        isUndefined(userScope) &&
        userIdsNotInAnyTeams.length < 1;
      // My performance as the default
      if (shouldForceMyPerformanceToBeChecked) {
        return [currentUser.get('id')];
      }

      if (List.isList(userScope)) {
        return userScope.toJS().map(scope => convertScopeParam(scope));
      }

      if (isUndefined(userScope)) {
        return [];
      }

      return [convertScopeParam(userScope)];
    }
  );
};

export const makeGetUsersNotInATeamScope = () => {
  return createSelector([location], location => {
    const usersNotInATeamScope = location.getIn([
      'searchParams',
      'usersNotInATeamScope',
    ]);

    if (List.isList(usersNotInATeamScope)) {
      return usersNotInATeamScope.toJS().map(scope => convertScopeParam(scope));
    }

    if (isUndefined(usersNotInATeamScope) || isString(usersNotInATeamScope)) {
      return [];
    }
    return usersNotInATeamScope;
  });
};

/**
 * Build a string descriptor based on user and team scopes.
 * @return {func}
 */
export const makeGetScopeSelectorLabel = () => {
  return createSelector(
    [
      makeGetTeamScope(),
      makeGetUserScope(),
      makeGetUsersNotInATeamScope(),
      teams,
      users,
      currentUser,
    ],
    (teamScope, userScope, usersNotInATeamScope, teams, users, currentUser) => {
      const currentUserId = currentUser.get('id');
      const usersSelectedCount = userScope.length;
      const teamSelectedCount = teamScope.length;

      const singleUserSelectedAndNoTeams =
        teamSelectedCount === 0 && usersSelectedCount === 1;
      const singleTeamSelectedAndNoUsers =
        teamSelectedCount === 1 && usersSelectedCount === 0;
      const currentUserSelected = userScope
        .map(userId => toNumber(userId))
        .includes(currentUserId);
      const usersNotInATeamSelected = usersNotInATeamScope.length > 0;

      const onlyHasMyPerformanceSelected =
        singleUserSelectedAndNoTeams &&
        currentUserSelected &&
        !usersNotInATeamSelected;
      const getNameForSingleSelectedLabel = (mapOfData, scope) => {
        const userEntry = mapOfData.find(
          mapEntry => mapEntry.get('id') === toNumber(scope[0])
        );
        if (userEntry) {
          return userEntry.get('name');
        }
        return '';
      };

      let scopeSelectorLabel;
      if (onlyHasMyPerformanceSelected) {
        scopeSelectorLabel = 'My Performance';
      } else if (singleUserSelectedAndNoTeams && !usersNotInATeamSelected) {
        scopeSelectorLabel = getNameForSingleSelectedLabel(users, userScope);
      } else if (singleTeamSelectedAndNoUsers && !usersNotInATeamSelected) {
        scopeSelectorLabel = getNameForSingleSelectedLabel(teams, teamScope);
      } else {
        const createNameEntry = (memberCount, title) =>
          `${memberCount} ${pluralize(title, memberCount)}`;
        const individualTextEntries = [];

        if (teamSelectedCount > 0) {
          individualTextEntries.push(
            createNameEntry(teamSelectedCount, 'Team')
          );
        }
        if (usersSelectedCount > 0) {
          individualTextEntries.push(
            createNameEntry(usersSelectedCount, 'User')
          );
        }
        if (usersNotInATeamSelected) {
          individualTextEntries.push('Users not in a team');
        }

        scopeSelectorLabel = individualTextEntries.join(', ');
      }

      return scopeSelectorLabel;
    }
  );
};

/**
 * Return true iff the current user/team scope is the current user.
 *
 * @return {func}
 */
export const getScopeIsCurrentUser = createSelector(
  currentUser,
  makeGetTeamScope(),
  makeGetUserScope(),
  (currentUser, teamScope, userScope) => {
    return (
      teamScope.length === 0 &&
      userScope.length === 1 &&
      toNumber(userScope[0]) === currentUser.get('id')
    );
  }
);

/**
 * Get the unique list users who are currently selected, according to the team
 * and user scopes.
 *
 * @return {func}
 */
export const getScopeSelectedUsers = createSelector(
  makeGetTeamScope(),
  makeGetUserScope(),
  makeGetUsersNotInATeamScope(),
  teams,
  users,
  currentUser,
  (teamScope, userScope, usersNotInATeamScope, teams, users, currentUser) => {
    const userList = [];
    // used to maintain list of user ids already added, for faster lookup
    const usersMap = {};
    // the users list does not include the current user. If he/she's included in the scope,
    // add them to the final list manually.
    const combinedUserScope = [...userScope, ...usersNotInATeamScope];
    if (
      combinedUserScope.map(i => toNumber(i)).includes(currentUser.get('id'))
    ) {
      userList.push({
        id: currentUser.get('id'),
        name: currentUser.get('name'),
      });
      usersMap[currentUser.get('id')] = true;
    }

    teams.forEach(team => {
      if (teamScope.includes(team.get('id')) && team.get('users')) {
        team.get('users').forEach(user => {
          // only add the user if not included already
          if (!usersMap[user.get('id')]) {
            userList.push(user.toJS());
            usersMap[user.get('id')] = true;
          }
        });
      }
    });

    users.forEach(user => {
      if (
        combinedUserScope.includes(user.get('id')) &&
        !usersMap[user.get('id')]
      ) {
        userList.push(user.toJS());
        usersMap[user.get('id')] = true;
      }
    });

    return userList;
  }
);

/**
 * Get the unique list users who are currently selected, according to the team
 * and user scopes.
 *
 * @return {func}
 */
export const getUserHierarchyScopeSelectedUsers = userIds =>
  createSelector(users, currentUser, (users, currentUser) => {
    const userList = [];
    // used to maintain list of user ids already added, for faster lookup
    const usersMap = {};
    // the users list does not include the current user. If he/she's included in the scope,
    // add them to the final list manually.
    const combinedUserScope = userIds;
    if (
      combinedUserScope.map(i => toNumber(i)).includes(currentUser.get('id'))
    ) {
      userList.push({
        id: currentUser.get('id'),
        name: currentUser.get('name'),
      });
      usersMap[currentUser.get('id')] = true;
    }

    users.forEach(user => {
      if (
        combinedUserScope.includes(user.get('id')) &&
        !usersMap[user.get('id')]
      ) {
        userList.push(user.toJS());
        usersMap[user.get('id')] = true;
      }
    });

    return userList;
  });

/**
 * @return {func}
 */
export const makeGetStartDate = () => {
  return createSelector(location, location => {
    const startDate =
      location.getIn(['searchParams', 'startDate']) ||
      GrooveLocalStorage.fetch(ANALYTICS_SCOPE_STORAGE_KEY).startDate;

    if (isEmpty(startDate)) {
      return DEFAULTS.startDate;
    }

    return startDate;
  });
};

/**
 * @return {func}
 */
export const makeGetEndDate = () => {
  return createSelector(location, location => {
    const endDate =
      location.getIn(['searchParams', 'endDate']) ||
      GrooveLocalStorage.fetch(ANALYTICS_SCOPE_STORAGE_KEY).endDate;

    if (isEmpty(endDate)) {
      return DEFAULTS.endDate;
    }

    return endDate;
  });
};

/**
 * @return {func}
 */
export const makeGetActiveTab = () => {
  return createSelector(activeTab, activeTab => {
    if (isEmpty(activeTab)) {
      return DEFAULTS.tab.value;
    }

    return activeTab;
  });
};

/**
 * @return {func}
 */
export const makeGetActivityOutcomesById = () => {
  return createSelector(activityOutcomes, activityOutcomes => {
    return activityOutcomes.reduce((accumulator, value) => {
      accumulator[value.get('id')] = value.toJS();
      return accumulator;
    }, {});
  });
};

/**
 * @return {func}
 */
export const makeGetActivityResultsById = () => {
  return createSelector(activityResults, activityResults => {
    return activityResults.reduce((accumulator, value) => {
      accumulator[value.get('id')] = value.toJS();
      return accumulator;
    }, {});
  });
};

const callsTabDataKeyPath = ['analytics', 'calls', 'data'];

export const callsTabSummaryCards = state =>
  state.getIn([...callsTabDataKeyPath, 'summaryCards']);
export const callsTabCallStatus = state =>
  state.getIn([...callsTabDataKeyPath, 'callStatus']);
export const callsTabConversationOutcomes = state =>
  state.getIn([...callsTabDataKeyPath, 'conversationOutcomes']);
export const callsTabCallLog = state =>
  state.getIn(['analytics', 'calls', 'callLog']);
export const callsTabUserBreakdown = state =>
  state.getIn(['analytics', 'calls', 'userBreakdown']);
export const callsTabUserBreakdownSelectedRowIds = state =>
  state.getIn(['analytics', 'calls', 'userBreakdown', 'selectedRowIds']);
export const callsTabEmailLogDialogIsOpen = state =>
  getProperty(state, CALLS_TAB_DIALOG_KEYPATH);

export const callsTabWeekDayToTimeMatrix = state =>
  state.getIn([...callsTabDataKeyPath, 'weekdayToTimeMatrix']);
export const callsTabConversationsWeekdayToTimeMatrix = state =>
  state.getIn([...callsTabDataKeyPath, 'conversationsWeekdayToTimeMatrix']);

export const callsTabUserBreakdownIsFetching = state =>
  state.getIn(['analytics', 'calls', 'userBreakdown', 'isFetching']);
export const callsTabCallLogIsFetching = state =>
  state.getIn(['analytics', 'calls', 'callLog', 'isFetching']);

export const callsTabCallLogInitialLoadComplete = state =>
  state.getIn(['analytics', 'calls', 'callLog', 'initialLoadComplete']);
export const callsTabUserBreakdownInitialLoadComplete = state =>
  state.getIn(['analytics', 'calls', 'userBreakdown', 'initialLoadComplete']);
export const callsTabInitialTableLoadComplete = state => {
  const userBreakdownInitialLoadComplete =
    callsTabUserBreakdownInitialLoadComplete(state);
  const callLogInitialLoadComplete = callsTabCallLogInitialLoadComplete(state);

  return userBreakdownInitialLoadComplete || callLogInitialLoadComplete;
};

export const callsTabShouldShowLoader = createSelector(
  callsTabInitialTableLoadComplete,
  callsTabUserBreakdownIsFetching,
  callsTabCallLogIsFetching,
  currentUser,
  (
    tableLoadComplete,
    userBreakdownIsFetching,
    callLogIsFetching,
    currentUser
  ) => {
    const userIsAgent = currentUser.get('role') === 'agent';

    let showLoader;
    if (tableLoadComplete) {
      showLoader = false; // eslint-disable-line no-unused-expressions
    } else if (userIsAgent && callLogIsFetching) {
      showLoader = true; // eslint-disable-line no-unused-expressions
    } else if (!userIsAgent && userBreakdownIsFetching) {
      showLoader = true; // eslint-disable-line no-unused-expressions
    } else {
      showLoader = false; // eslint-disable-line no-unused-expressions
    }

    return showLoader;
  }
);

export const callsTabCallLogFetchFailed = state =>
  state.getIn(['analytics', 'calls', 'callLog', 'fetchFailed']);
export const callsTabUserBreakdownFetchFailed = state =>
  state.getIn(['analytics', 'calls', 'userBreakdown', 'fetchFailed']);

export const callsTabShouldShowTableFetchFailedErrorMessage = createSelector(
  callsTabInitialTableLoadComplete,
  callsTabCallLogFetchFailed,
  callsTabUserBreakdownFetchFailed,
  (tableLoadComplete, callLogFetchFailed, userBreakdownFetchFailed) => {
    return (
      callsTabInitialTableLoadComplete &&
      (callLogFetchFailed || userBreakdownFetchFailed)
    );
  }
);

export const callsTabUserBreakdownData = state => {
  const userBreakdownKeyPath = ['analytics', 'calls', 'userBreakdown'];
  const userBreakdown = state.getIn([...userBreakdownKeyPath, 'data']);
  const userBreakdownRender = state.getIn([
    ...userBreakdownKeyPath,
    'dataRender',
  ]);
  return { userBreakdown, userBreakdownRender };
};

export const callsTabCallLogData = state => {
  const summaryCards = state.getIn([
    'analytics',
    'calls',
    'data',
    'summaryCards',
  ]);

  const data = Map({
    rowValues: Map({
      userName: state.getIn(['users', 'currentUser', 'name']),
      totalCalls: summaryCards.getIn(['calls', 'count']),
      conversationCount: summaryCards.getIn(['conversationRate', 'count']),
      positiveConnections:
        summaryCards
          .getIn(['conversationRate', 'percentageOfTotal'])
          ?.toFixed(2) || null,
      positiveConversations:
        summaryCards
          .getIn(['positiveConversationRate', 'percentageOfTotal'])
          ?.toFixed(2) || null,
    }),
  });

  return {
    userBreakdownRender: List([data]),
  };
};

export const callsTabCallLogSorting = state => {
  const callsTabCallLogKeyPath = ['analytics', 'calls', 'callLog'];
  const data = state.getIn([...callsTabCallLogKeyPath, 'data']);
  const orderBy = state.getIn([...callsTabCallLogKeyPath, 'orderBy']);
  const orderDirection = state.getIn([
    ...callsTabCallLogKeyPath,
    'orderDirection',
  ]);
  return { data, orderBy, orderDirection };
};

export const callsTabUserBreakdownSortingData = state => {
  const keyPath = ['analytics', 'calls', 'userBreakdown'];
  const orderBy = state.getIn([...keyPath, 'orderBy']);
  const orderDirection = state.getIn([...keyPath, 'orderDirection']);
  const dataRender = state.getIn([...keyPath, 'dataRender']);
  return { dataRender, orderBy, orderDirection };
};
export const callsTabCallLogOrderBy = state =>
  state.getIn(['analytics', 'calls', 'callLog', 'orderBy']);
export const callsTabCallLogOrderDirection = state =>
  state.getIn(['analytics', 'calls', 'callLog', 'orderDirection']);
export const callsTabCallLogPage = state =>
  state.getIn(['analytics', 'calls', 'callLog', 'page']);
export const callsTabCallLogPerPage = state =>
  state.getIn(['analytics', 'calls', 'callLog', 'perPage']);
export const callsTabCallLogUserId = state =>
  state.getIn(['analytics', 'calls', 'callLog', 'userId']);

export const callsTabSummaryCardsProps = createSelector(
  callsTabSummaryCards,
  summaryCards => {
    const assembleObject = (label, value, icon, isPercentage, tooltipText) => {
      return {
        label,
        value: sanitizer(value),
        icon,
        color: ClariTheme.palette.success.main,
        isPercentage,
        tooltipText,
      };
    };

    const callsStat = summaryCards.getIn(['calls', 'count']);
    const conversationRateStat = summaryCards.getIn([
      'conversationRate',
      'percentageOfTotal',
    ]);
    const positiveConversationRateStat = summaryCards.getIn([
      'positiveConversationRate',
      'percentageOfTotal',
    ]);

    const posConvoStat = `${sanitizer(
      positiveConversationRateStat
    )}% (${summaryCards.getIn([
      'positiveConversationRate',
      'count',
    ])}/${callsStat})`;
    const posConvoTooltip = `${posConvoStat} of your coversations were positive`;

    const statistics = new List([
      assembleObject('Calls', callsStat, <PhoneIcon />, false),
      assembleObject(
        'Conversation Rate',
        conversationRateStat,
        <PhoneInTalkIcon />,
        true
      ),
      assembleObject(
        'Positive Conversation Rate',
        positiveConversationRateStat,
        <TagFacesIcon />,
        true,
        posConvoTooltip
      ),
    ]);

    return {
      title: 'Call Analytics',
      statistics,
    };
  }
);

export const getFormFieldsByGroupId = groupId => state =>
  state.getIn(['form', groupId]);

export const makeGetFormSelectedIds = groupId =>
  createSelector(getFormFieldsByGroupId(groupId), formSection => {
    const formEntryExists = formSection && formSection.has('fields');
    const getSelectedElementsFromForm = formSection => {
      const formFieldData = formSection.get('fields');
      return formFieldData
        .filter(field => {
          const selectedStatus = field.get('value');
          return selectedStatus;
        })
        .keySeq()
        .toJS();
    };
    return formEntryExists ? getSelectedElementsFromForm(formSection) : [];
  });

export const getFilterTeamLead = createSelector(
  currentUser,
  getCurrentUserRole,
  getFilterTeamLeadFromAnalytics,
  makeGetUserScope(),
  (user, currentUserRole, filterTeamLeadFromAnalytics, userScope) =>
    currentUserRole === roles.TEAM_LEAD &&
    filterTeamLeadFromAnalytics &&
    !userScope.includes(user.get('id'))
);

export const exportStartDate = state => {
  const startDate = state.getIn(['analytics', 'export', 'startDate']);

  if (isEmpty(startDate)) {
    return DEFAULTS.startDate;
  }

  return startDate;
};

export const exportEndDate = state => {
  const endDate = state.getIn(['analytics', 'export', 'endDate']);

  if (isEmpty(endDate)) {
    return DEFAULTS.endDate;
  }

  return endDate;
};
