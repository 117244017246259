import moment from 'moment';
import HttpStatusCodes from 'http-status-codes';

import * as GrooveLocalStorage from 'GrooveLocalStorage';
import { logError } from 'Modules/Shared/actions/errors';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { currentUser as getCurrentUser } from 'Modules/Shared/selectors/users';
import { getLoginAsUsers } from 'Modules/App/selectors';
import {
  index as getUsersByGrooveUser,
  loginAs as loginAsByGrooveUser,
} from 'GrooveHTTPClient/grooveUser/users';
import {
  index as getUsersByAdmin,
  loginAs as loginAsByAdmin,
} from 'GrooveHTTPClient/admin/users';

import {
  actionTypes as appActionTypes,
  loginAsSearchUsersSuccess,
  loginAsSearchUsersFailure,
  loginAsUserSuccess,
} from 'Modules/App/actions';
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { LOGIN_AS_USER_LIST_LIMIT } from 'Modules/App/constants';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

// -------------- Helpers --------------
function* handleSearchUsersBegin({
  payload: { page = 1, searchQuery, skipDelay },
}) {
  // Debounce 700 ms
  if (!skipDelay) {
    yield delay(700);
  }

  const currentUser = yield select(getCurrentUser);

  // requires at least 3 chars but support all single digit number
  if (!searchQuery || (searchQuery.length < 3 && !/^\d+$/.test(searchQuery))) {
    yield put(
      loginAsSearchUsersFailure({
        errorMessage: 'Type at least 3 characters',
      })
    );
    return;
  }

  try {
    const getUsersEndpoint = currentUser.get('isGrooveUser')
      ? getUsersByGrooveUser
      : getUsersByAdmin;

    const response = yield call(getUsersEndpoint, {
      searchQuery,
      limit: LOGIN_AS_USER_LIST_LIMIT,
      offset: (page - 1) * LOGIN_AS_USER_LIST_LIMIT,
    });

    const users = response.data;
    const { total } = response.meta;

    yield put(
      loginAsSearchUsersSuccess({
        page,
        searchQuery,
        total,
        users,
      })
    );
  } catch (e) {
    if (e.response.status === HttpStatusCodes.GATEWAY_TIMEOUT) {
      yield put(
        loginAsSearchUsersFailure({
          errorMessage:
            'Search process takes too long. Please try again or type more specific phrase.',
        })
      );
      return;
    }

    yield put(
      pushSnackbarMessage({
        message: 'Something went wrong',
      })
    );
    yield put(
      loginAsSearchUsersFailure({
        errorMessage: 'Something went wrong. Try again or contact the support',
      })
    );
    yield put(
      logError({
        error: e,
        isUiError: false,
        logSilently: true,
      })
    );
  }
}

function* handleLoginAsBegin({ payload: { userId } }) {
  try {
    const currentUser = yield select(getCurrentUser);
    const loginAsUserEndpoint = currentUser.get('isGrooveUser')
      ? loginAsByGrooveUser
      : loginAsByAdmin;

    yield call(loginAsUserEndpoint, { userId });

    const users = yield select(getLoginAsUsers);

    const loggedUser = users.find(user => user.get('id') === userId);

    yield put(loginAsUserSuccess(loggedUser));

    yield put(
      pushSnackbarMessage({
        message: `Logging in as ${loggedUser.get('name')}`,
      })
    );

    yield delay(1500);

    const csrfToken = yield call(GrooveLocalStorage.fetch, 'csrfToken');
    yield call(GrooveLocalStorage.replicateStore);

    // we use the same csrf token in the login as state
    yield call(GrooveLocalStorage.save, 'csrfToken', csrfToken);
    yield call(GrooveLocalStorage.save, 'loginAsActive', true);
    yield call(GrooveLocalStorage.save, 'loginAsTime', moment().unix());

    window.location = grooveEngineRootUrl;
  } catch (e) {
    yield put(
      pushSnackbarMessage({
        message: 'Something went wrong',
      })
    );

    yield put(
      logError({
        error: e,
        isUiError: false,
      })
    );
  }
}

// -------------- Watchers --------------
function* watchLoginAsSearchUsersBegin() {
  yield takeLatest(
    appActionTypes.LOGIN_AS_SEARCH_USERS.BEGIN,
    handleSearchUsersBegin
  );
}

function* watchLoginAsBegin() {
  yield takeLatest(appActionTypes.LOGIN_AS_USER.BEGIN, handleLoginAsBegin);
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([fork(watchLoginAsSearchUsersBegin), fork(watchLoginAsBegin)]);
}
