import { FieldValue, FormField } from '@groove/api/gateway/v1/actionCompose';
import Text from '@groove/ui/Components/Text';
import { FC } from 'react';
import DependentPicklist from '@groove/ui//Components/DependentPicklist';
import { useAvailablePicklistValues } from '@groove/api/hooks/useAvailablePicklistValues';

import useStore from '../store/useStore';
import { PICKLIST_TYPE } from '../constants';

export type CustomFieldProps = {
  formName: 'loggingForm';
  fieldData: FormField;
};

const CustomField: FC<CustomFieldProps> = ({ formName, fieldData }) => {
  const updateDynamicData = useStore(state => state.updateDynamicData);
  const allFields = useStore(
    state => state.action.dynamicData[formName]?.fields,
  );
  const parentField = allFields?.find(
    item => item.name === fieldData.controllerName,
  );
  const availableValues =
    useAvailablePicklistValues(PICKLIST_TYPE)?.data?.[fieldData.name]?.values ||
    [];

  const onChange = (newValue: FieldValue): void => {
    updateDynamicData(formName, { ...fieldData, value: newValue });
  };

  return (
    <div className="mb-3">
      <Text variant="body-sm" className="mb-[4px] mt-[4px] font-semibold">
        {fieldData.label || fieldData.name}{' '}
        {fieldData.required && <span className="text-error/600">*</span>}
      </Text>

      {(fieldData.type === 'picklist' ||
        fieldData.type === 'multipicklist') && (
        <DependentPicklist
          field={fieldData}
          parentField={parentField}
          onChange={onChange}
          availableValues={availableValues}
        />
      )}
    </div>
  );
};

export default CustomField;
