import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SeismicButtonProps } from './Seismic';

const useStyles = makeStyles({
  commonStyle: {
    textAlign: 'center',
    width: '120px',
    height: '34px',
    borderRadius: '20px',
  },
  normalStyle: {
    backgroundColor: '#E95517',
    '&:hover': {
      backgroundColor: '#d24d14',
    },
  },
  inverseStyle: {
    backgroundColor: 'white',
    color: '#E95517',
    border: '2px solid #E95517',
    '&:hover': {
      backgroundColor: 'hsl(0, 0%, 98%)',
      color: '#d24d14',
      borderColor: '#d24d14',
    },
  },
});

const SeismicButton: FC<SeismicButtonProps> = ({
  children,
  disabled,
  inverse,
  onClick,
}) => {
  const { commonStyle, inverseStyle, normalStyle } = useStyles();
  return (
    <Button
      className={`${inverse ? inverseStyle : normalStyle} ${commonStyle}`}
      variant="contained"
      color="primary"
      onClick={onClick}
      aria-label="Seismic"
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default SeismicButton;
