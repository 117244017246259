import { isEmpty, isString, includes, keys } from 'lodash-es';
import {
  DESC,
  ASC,
  COLUMN_TYPES,
} from 'Modules/Shared/components/Table/constants';
import recursiveToJs from 'Utils/recursive-to-js';

const noramlizeObject = object => {
  return recursiveToJs(object);
};

const getValue = (item, property, columnType) => {
  const normalizedItem = noramlizeObject(item);
  const value = normalizedItem[property];

  switch (columnType) {
    case COLUMN_TYPES.string: {
      if (!isString(value)) return value;
      return value.toLowerCase();
    }

    case COLUMN_TYPES.integer: {
      return parseFloat(value);
    }

    case COLUMN_TYPES.dateTime: {
      return new Date(value).getTime();
    }

    case COLUMN_TYPES.date: {
      return new Date(value).getTime();
    }

    default: {
      console.warn(
        `Unexpected column type: '${columnType}', falling back to strict equality comparison. Valid types are: [${keys(
          COLUMN_TYPES
        ).join(', ')}]. Use COLUMN_TYPES constant defined in Table module`
      ); // eslint-disable-line no-console, max-len
      return value;
    }
  }
};

/**
 * @param {Array.<object> | Immutable.List} columns
 * @param {object} options
 * @param {string} options.orderBy
 * @param {string} options.orderDirection
 *
 * @return {function} Function to use with Array/List sort function
 * */
export default function createSortComparator(
  columns,
  { orderBy = null, orderDirection = DESC }
) {
  // eslint-disable-line max-len
  if (!includes([DESC, ASC], orderDirection)) {
    throw new Error(
      `orderDirection must be '${DESC}' or '${ASC}'. Use the DESC and ASC exports from Table/constants`
    );
  }

  return (row1, row2) => {
    if (isEmpty(orderBy)) return 0;

    const normalizedColumns = noramlizeObject(columns);
    const column = normalizedColumns.find(column => column.id === orderBy);

    const val1 = column ? getValue(row1, orderBy, column.type) : null;
    const val2 = column ? getValue(row2, orderBy, column.type) : null;

    let sortVal;
    if (!isEmpty(val1) && isEmpty(val2)) {
      sortVal = -1;
    } else if (isEmpty(val1) && !isEmpty(val2)) {
      sortVal = 1;
    } else if (val1 === val2) {
      sortVal = 0;
    } else {
      const inverter = orderDirection === DESC ? 1 : -1;
      sortVal = inverter * (val1 > val2 ? -1 : 1);
    }

    return sortVal;
  };
}
