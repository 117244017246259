import capitalize from 'lodash-es/capitalize';
import { call, put } from 'redux-saga/effects';
import HTTPError from 'GrooveHTTPClient/HTTPError';
import HttpStatusCodes from 'http-status-codes';
import pluralize from 'pluralize';
import sagaErrorHandler from 'ErrorHandling/sagaErrorHandler';
import { SERVER_ERROR_TYPES } from 'ErrorHandling/constants';
import redirectToPathWithSnackbarMessage from 'ErrorHandling/redirectToPathWithSnackbarMessage';
import { logError } from 'Modules/Shared/actions/errors';
import { ERROR_TYPES } from 'Modules/Shared/constants';

import logout from './logout';

function* defaultSagaErrorHandler({ error, location }) {
  if (error instanceof HTTPError) {
    switch (error.response.status) {
      case HttpStatusCodes.NOT_FOUND: {
        const rootPath = location.get('rootPath');
        if (error.path.endsWith(`${rootPath}/[:id]`)) {
          return yield* redirectToPathWithSnackbarMessage({
            path: rootPath,
            message: `The requested ${capitalize(
              pluralize.singular(rootPath)
            )} was not found.`,
          });
        }
        break;
      }
      case HttpStatusCodes.FORBIDDEN: {
        if (
          error.response.data &&
          error.response.data.type === SERVER_ERROR_TYPES.INACCESSIBLE_ENTITY
        ) {
          const rootPath = location.get('rootPath');
          return yield* redirectToPathWithSnackbarMessage({
            path: rootPath,
            message: `The requested ${capitalize(
              pluralize.singular(rootPath)
            )} has not been shared with you.`,
          });
        }
        break;
      }
      case HttpStatusCodes.GATEWAY_TIMEOUT: {
        return yield put(
          logError({ error, errorType: ERROR_TYPES.REQUEST_TIMEOUT })
        );
      }
      case HttpStatusCodes.UNAUTHORIZED: {
        if (error.response.data.type === SERVER_ERROR_TYPES.NOT_AUTHENTICATED) {
          return yield call(logout, error);
        }
        break;
      }
      case HttpStatusCodes.TOO_MANY_REQUESTS: {
        if (
          error.response.data.type ===
          SERVER_ERROR_TYPES.SFDC_REQUEST_LIMIT_EXCEEDED
        ) {
          return yield put(
            logError({
              error,
              errorType: ERROR_TYPES.SFDC_REQUEST_LIMIT_EXCEEDED,
            })
          );
        }
        break;
      }
      case null: {
        return yield put(
          logError({ error, errorType: ERROR_TYPES.NETWORK_ERROR })
        );
      }
      default: {
        return yield put(
          logError({ error, errorType: ERROR_TYPES.UNHANDLED_HTTP_ERROR })
        );
      }
    }
  } else if (error.name === 'InvalidSalesforceMetaError') {
    return yield put(
      logError({ error, errorType: ERROR_TYPES.INVALID_SALESFORCE_META })
    );
  }

  return yield put(logError({ error, errorType: ERROR_TYPES.GENERIC }));
}

function* upstartSagaErrorHandler(error) {
  yield* sagaErrorHandler({
    error,
    routeSagaErrorHandlerName: 'upstartSagaErrorHandler',
    defaultSagaErrorHandler,
  });
}

export default upstartSagaErrorHandler;
