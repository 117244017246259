import { GetState, SetState } from 'zustand';
import produce from 'immer';
import { GeneratedContent } from '@groove/api/gateway/v1/generateContents';
import { CopilotCall } from '@groove/api/gateway/v1/copilotCalls';

import useStore, { Store } from './useStore';

export type Intent = 'COLD_EMAIL' | 'FOLLOWUP_EMAIL';
export type Tone = 'FORMAL' | 'CASUAL' | 'NEUTRAL';
export type DefaultEmailGeneration = {
  copilotCall: CopilotCall | null;
  emailIntent: Intent | null;
  shouldBeInvoked: boolean;
};

export const INIT_DEFAULT_COPILOT_CALL: DefaultEmailGeneration = {
  copilotCall: null,
  emailIntent: null,
  shouldBeInvoked: false,
};

export const EMAIL_GENERATION_ERROR_ID = 'generateContentError';

export type EmailGenerationStore = {
  generatedContent: { [key: string]: GeneratedContent | null };
  defaultEmailGeneration: DefaultEmailGeneration;
  setDefaultEmailGeneration: (
    defaultEmailGeneration: DefaultEmailGeneration,
  ) => void;
  closeGenerationPane: () => void;
  getCurrentGeneratedContent: (icaluid: string) => GeneratedContent | null;
  updateCurrentGeneratedContent: (
    icaluid: string,
    generatedContent?: GeneratedContent,
  ) => void;
};

export const emailGenerationStore = (
  set: SetState<Store>,
  get: GetState<Store>,
): EmailGenerationStore => ({
  generatedContent: {},
  defaultEmailGeneration: INIT_DEFAULT_COPILOT_CALL,
  closeGenerationPane: () => {
    set(
      produce((draft: Store) => {
        useStore.getState().removeMessage(EMAIL_GENERATION_ERROR_ID);
        draft.otherValues.taskPaneType = 'TASK';
        draft.otherValues.isExtendedTaskPaneOpen = false;
        draft.defaultEmailGeneration = INIT_DEFAULT_COPILOT_CALL;
      }),
    );
  },
  updateCurrentGeneratedContent: (
    icaluid: string,
    newGeneratedContent?: GeneratedContent,
  ) => {
    const actionId = get().action.id;
    const email = get().action.toRecipients?.[0]?.email;
    const currentGenerateContentKey = `${actionId}:${email}:${icaluid}`;

    set(
      produce((draft: Store) => {
        draft.generatedContent[currentGenerateContentKey] =
          newGeneratedContent || null;
      }),
    );
  },
  getCurrentGeneratedContent: (icaluid: string) => {
    const actionId = get().action.id;
    const email = get().action.toRecipients?.[0]?.email;
    const currentGenerateContentKey = `${actionId}:${email}:${icaluid}`;
    return get().generatedContent[currentGenerateContentKey];
  },
  setDefaultEmailGeneration: (values: DefaultEmailGeneration) =>
    set({
      defaultEmailGeneration: {
        ...values,
      },
    }),
});
