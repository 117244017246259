import { FC } from 'react';
import cx from 'classnames';

type ButtonArraySingleProps = {
  isActive?: boolean;
  onClick: () => void;
  isLast?: boolean;
  disabled?: boolean;
};

const ButtonArraySingle: FC<ButtonArraySingleProps> = ({
  isActive,
  children,
  onClick,
  isLast,
  disabled,
}) => (
  <button
    type="button"
    onClick={() => !isActive && onClick()}
    className={cx(
      'border-0 border-solid border-neutral/200 bg-none h-full px-3 font-semibold cursor-pointer inline-block',
      disabled &&
        (isActive
          ? '!cursor-not-allowed bg-neutral/100 text-neutral/25 hover:text-wavyNavy/600 active:text-wavyNavy/600 hover:bg-neutral/100'
          : '!cursor-not-allowed bg-neutral/25 text-neutral/400 hover:bg-neutral/25'),
      !disabled && isActive
        ? 'bg-clari-blue/600 hover:bg-clari-blue/700 text-white !cursor-default'
        : 'bg-white hover:bg-neutral/75 active:bg-neutral/100 text-clari-blue/600',
      !isLast && 'border-r',
    )}
    aria-pressed={isActive}
    disabled={disabled}
  >
    {children}
  </button>
);

export default ButtonArraySingle;
