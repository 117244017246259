import {
  DropDownItem,
  HeaderDropDownItem,
} from '@groove/ui/Components/DropDownItem';

export const callResultSerializer = (
  statusData: DropDownItem[],
): DropDownItem[] => {
  const outcomeHeader: HeaderDropDownItem = {
    type: 'header',
    key: 'HeaderOutcome',
    value: 'Conversation',
  };

  const resultHeader: HeaderDropDownItem = {
    type: 'header',
    key: 'HeaderResult',
    value: 'No Conversation',
  };

  const outcomeItems = statusData.filter((item: DropDownItem) =>
    item.value.startsWith('activity_outcome'),
  );

  const resultItems = statusData.filter((item: DropDownItem) =>
    item.value.startsWith('activity_result'),
  );

  const sortedData = [
    outcomeHeader,
    ...outcomeItems,
    resultHeader,
    ...resultItems,
  ];

  return sortedData;
};
