import { FilterDropdownOptions } from '@groove/api/services/v1/actions';

// Feature flags
export const SMART_ACTION_FORRESTER_FEATURE_FLAG = 'smart-priorities';
export const PLAYS_FEATURE_FLAG = 'plays';

// Filters
// Re-defining some of the filters here to replace the ones in omnibar
// in a separate PR
export const PRIORITY_FILTER: FilterDropdownOptions = {
  label: 'priority',
  text: 'Priority',
  data: [
    { key: 'high', ariaLabel: 'High', text: 'High' },
    { key: 'normal', ariaLabel: 'Medium', text: 'Medium' },
    { key: 'low', ariaLabel: 'Low', text: 'Low' },
    { key: 'none', ariaLabel: 'None', text: 'None' },
  ],
};
export const SMART_PRIORITY_FILTER: FilterDropdownOptions = {
  label: 'smart_priority',
  text: 'Smart Priority',
  data: [
    { key: 'high', ariaLabel: 'High', text: 'High' },
    { key: 'medium', ariaLabel: 'Medium', text: 'Medium' },
    { key: 'low', ariaLabel: 'Low', text: 'Low' },
    { key: 'none', ariaLabel: 'None', text: 'None' },
  ],
};

export const SOURCE_FILTER: FilterDropdownOptions = {
  label: 'source',
  text: 'Source',
  data: [
    { key: 'FLOW', ariaLabel: 'Flow', text: 'Flow' },
    { key: 'WORKSPACE', ariaLabel: 'Space', text: 'Space' },
    { key: 'ONE_OFF', ariaLabel: 'One off', text: 'One off' },
    // { key: 'PLAY', text: 'Play' }, // commented out for having play actions separate from the rest of actions
  ],
};

// The following source filter should be used when
// PLAYS_FEATURE_FLAG is enabled
export const SOURCE_FILTER_WITH_PLAYS: FilterDropdownOptions = {
  label: 'source',
  text: 'Source',
  data: [
    { key: 'FLOW', ariaLabel: 'Flow', text: 'Flow' },
    { key: 'WORKSPACE', ariaLabel: 'Space', text: 'Space' },
    { key: 'ONE_OFF', ariaLabel: 'One off', text: 'One off' },
    { key: 'PLAY', text: 'Play' },
  ],
};

export const TYPE_FILTER: FilterDropdownOptions = {
  label: 'type',
  text: 'Type',
  data: [
    { key: 'TEMPLATE', ariaLabel: 'Email', text: 'Email' },
    { key: 'CALL', ariaLabel: 'Call', text: 'Call' },
    { key: 'GENERAL', text: 'Task' },
    // following linkedIn Inmail to in person visit are part of Other
    { key: 'STEP_SUBTYPE_LINKEDIN_INMAIL', text: 'Inmail' },
    { key: 'STEP_SUBTYPE_LINKEDIN_CONNECT', text: 'Linkedin connect' },
    { key: 'STEP_SUBTYPE_SMS', text: 'SMS' },
    { key: 'STEP_SUBTYPE_SENDOSO', text: 'Sendoso' },
    { key: 'STEP_SUBTYPE_DIRECT_MAIL', text: 'Direct mail' },
    { key: 'STEP_SUBTYPE_IN_PERSON_VISIT', text: 'In person visit' },
    { key: 'OTHER', ariaLabel: 'Other', text: 'Other' },
  ],
};

export const DUE_FILTER: FilterDropdownOptions = {
  label: 'due_at',
  text: 'Due',
  data: [
    { key: 'BEFORE', ariaLabel: 'Past Due', text: 'Past Due' },
    { key: 'ON', ariaLabel: 'Today', text: 'Today' },
    { key: 'AFTER', ariaLabel: 'Later', text: 'Later' },
  ],
};
