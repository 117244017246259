import { all, call, put } from 'redux-saga/effects';
import { actions } from '@groove-labs/groove-ui';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { removePeopleFromFlow } from 'GrooveHTTPClient/flows';
import { actionTypes as appActionTypes } from 'Modules/App/actions';
import { REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH } from 'Modules/App/constants';

const { setProperty } = actions.ui;

function* handleRemovePersonFromFlow({ payload: { personId, flowId } }) {
  yield put({ type: appActionTypes.REMOVE_PERSON_FROM_FLOW.PROGRESS });

  try {
    yield call(removePeopleFromFlow, flowId, personId);
  } catch (e) {
    yield all([
      put({ type: appActionTypes.REMOVE_PERSON_FROM_FLOW.FAILURE, error: e }),
      put(
        setProperty({
          uiKeyPath: REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH,
          data: false,
        })
      ),
      put(
        pushSnackbarMessage({ message: 'Failed to remove person from flow' })
      ),
    ]);
    return null;
  }

  yield put({
    type: appActionTypes.REMOVE_PERSON_FROM_FLOW.SUCCESS,
    payload: { flowId, personId },
  });
  yield all([
    put(
      pushSnackbarMessage({ message: 'Successfully removed person from flow' })
    ),
    put(
      setProperty({
        uiKeyPath: REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH,
        data: false,
      })
    ),
  ]);
  return null;
}

export default handleRemovePersonFromFlow;
