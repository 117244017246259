import { Stack, Text } from '@fluentui/react';
import { TemplateFolder } from '@groove/api/services/v1/templateFolders';
import { FC, useMemo } from 'react';
import TruncatedTextWithTooltip from '@groove/ui/Components/TruncatedTextWithTooltip';

import SharedFolderIcon from './icons/shared_folder_icon.svg';
import TemplateFolderIcon from './icons/folder_icon.svg';
import useStore from './store/useStore';
import TemplateFolderStarrer from './TemplateFolderStarrer';

type TemplateFoldersListItemProps = {
  folder: TemplateFolder;
};

const TemplateFolderListItem: FC<TemplateFoldersListItemProps> = ({
  folder,
}) => {
  const setSelectedFolder = useStore(store => store.setSelectedFolder);
  const setSelectedFolderId = useStore(store => store.setSelectedFolderId);
  const favorites = useStore(store => store.favoriteTemplateFolders);
  const isFavorite = favorites?.some(f => f.id === folder.id);
  const isOutlook = useStore(store => store.isOutlook);

  const sharingDescription = useMemo(() => {
    if (folder.is_shared) {
      if (folder.is_owned_by_current_user) {
        return 'Shared by You';
      }

      if (!folder.user) {
        return 'Shared by a deactivated user';
      }

      return `Shared by ${folder.user.name}`;
    }

    return null;
  }, [folder]);

  return (
    <Stack
      key={folder.id}
      onClick={() => {
        setSelectedFolder(folder);
        setSelectedFolderId(folder.id);
      }}
      className="flex flex-row cursor-pointer p-4 hover:bg-neutral/50 max-h-16 border-solid border-0 border-neutral/100 border-b"
    >
      <div className="flex">
        {folder.is_shared ? (
          <img src={SharedFolderIcon} alt="shared-folder-icon" width={32} />
        ) : (
          <img src={TemplateFolderIcon} alt="template-folder-icon" width={32} />
        )}
      </div>
      <div className="flex flex-grow flex-col pl-4 justify-center">
        <TruncatedTextWithTooltip
          text={folder.name}
          textClasses="text-sm font-semibold"
        />
        <Text className="font-groove text-neutral/600">
          {sharingDescription}
        </Text>
      </div>
      {!isOutlook && (
        <div className="flex flex-grow justify-end">
          <div className="flex items-end">
            <TemplateFolderStarrer
              isFavorite={isFavorite}
              templateFolderId={folder.id}
            />
          </div>
        </div>
      )}
    </Stack>
  );
};

export default TemplateFolderListItem;
