import { FC } from 'react';
import cx from 'classnames';
import { Stack } from '@fluentui/react';
import TruncatedTextWithTooltip from '@groove/ui/Components/TruncatedTextWithTooltip';

import useStore from './store/useStore';
import MailIcon from './icons/mail_icon.svg';
import ViewsIcon from './icons/mail_read_icon.svg';
import ClicksIcon from './icons/link_clicked_icon.svg';
import RepliesIcon from './icons/replied_icon.svg';
import TemplateIcon from './icons/template_icon.svg';
import TemplateStarrer from './TemplateStarrer';
import {
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  trackTemplatesEvent,
} from './analytics/templatesEvents';
import TemplateFolderIcon from './icons/cleared_folder_icon.svg';

type TemplatesListItemProps = {
  template: {
    id: number;
    folder_id: number;
    name: string;
    subject: string;
    body?: string;
    sent_count: number;
    link_click_count: number;
    open_count: number;
    reply_count: number;
    updated_at: string;
    template_folder_name?: string;
  };
  isSearch?: boolean;
};

const formatPercentage = (numerator: number, denominator: number): string => {
  const percentage = Math.ceil((numerator / denominator) * 100 || 0);
  return `${isFinite(percentage) ? percentage : 0}%`;
};

const TemplatesListItem: FC<TemplatesListItemProps> = ({
  template,
  isSearch,
}) => {
  const selectedTemplate = useStore(store => store.selectedTemplate);
  const setSelectedTemplate = useStore(store => store.setSelectedTemplate);
  const favorites = useStore(store => store.favoriteTemplates);
  const isFavorite = favorites?.some(favorite => favorite.id === template.id);

  const isOutlook = useStore(store => store.isOutlook);
  return (
    <div className="border-solid border-0 border-b border-neutral/100">
      <Stack
        data-testid={`template-${template.id}`}
        key={template.id}
        onClick={() => {
          setSelectedTemplate(template);
          trackTemplatesEvent({
            eventName: TEMPLATES_EVENT_TYPE.PREVIEW_OPENED,
            templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
          });
        }}
        className={cx(
          `flex text-sm cursor-pointer overflow-x-hidden px-4 py-2 hover:bg-neutral/50`,
          template.id === selectedTemplate?.id && 'bg-neutral/50',
        )}
      >
        <Stack className="flex flex-row">
          <img src={TemplateIcon} alt="template-icon" width={32} />
          <div className="pl-4 w-full">
            {isSearch && template.template_folder_name && (
              <div className="flex align-middle">
                <img
                  src={TemplateFolderIcon}
                  alt="template-folder-icon"
                  width={14}
                  className="pr-1"
                />
                <TruncatedTextWithTooltip
                  text={template.template_folder_name}
                  textClasses="text-xs text-neutral/600"
                  lineClamp={1}
                />
              </div>
            )}
            <TruncatedTextWithTooltip
              text={template.name}
              textClasses="font-semibold text-sm text-black"
              lineClamp={2}
            />
            <TruncatedTextWithTooltip
              text={template.subject}
              textClasses="text-xs text-neutral/600"
              lineClamp={1}
            />
            <div className="flex items-center text-xs h-5">
              <div className="flex items-center pr-2">
                <img src={MailIcon} alt="mail-icon" width={16} />
                <div className="pl-1 font-groove text-sm text-neutral/900">
                  {template.sent_count}
                </div>
              </div>
              <div className="flex items-center pr-2">
                <img src={ViewsIcon} alt="views-icon" width={16} />
                <div className="pl-1 font-groove text-sm text-neutral/900">
                  {formatPercentage(template.open_count, template.sent_count)}
                </div>
              </div>
              <div className="flex items-center pr-2">
                <img src={ClicksIcon} alt="clicks-icon" width={16} />
                <div className="pl-1 font-groove text-sm text-neutral/900">
                  {formatPercentage(
                    template.link_click_count,
                    template.sent_count,
                  )}
                </div>
              </div>
              <div className="flex items-center pr-2">
                <img src={RepliesIcon} alt="replies-icon" width={16} />
                <div className="pl-1 font-groove text-sm text-neutral/900">
                  {formatPercentage(template.reply_count, template.sent_count)}
                </div>
              </div>
              {!isOutlook && (
                <div className="flex flex-grow justify-end">
                  <TemplateStarrer
                    isFavorite={isFavorite}
                    templateId={template.id}
                    isInModalView
                    size="medium"
                  />
                </div>
              )}
            </div>
          </div>
        </Stack>
      </Stack>
    </div>
  );
};

export default TemplatesListItem;
