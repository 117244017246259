import { OrderedMap } from 'immutable';
import { isUndefined } from 'lodash-es';
import { logErrorToSentry } from 'Modules/Shared/sagas/errors';
import Action from 'Modules/Actions/records/Action';

export function formatActions(actions) {
  const actionsWithNode = actions.filter(action => !!action.get('node'));

  return new OrderedMap(
    actionsWithNode.map(action => [
      action.getIn(['node', 'id']),
      Action.from(action.get('node')),
    ])
  );
}

export function isActionTypeValid(acceptableActionTypes, actionType) {
  const [namespace, type, standardActionStatus] = actionType.split('/');

  const acceptableNamespaces = Object.keys(acceptableActionTypes);

  if (!acceptableNamespaces.includes(namespace)) return false;

  if (isUndefined(acceptableActionTypes[namespace][type])) return false;

  if (isUndefined(standardActionStatus)) return true;

  return !!acceptableActionTypes[namespace][type][standardActionStatus];
}

export const isMultiselectFilterApplied = (fieldData, type) => {
  const initialValue = fieldData.getIn([type, 'initialValue']);
  const value = fieldData.getIn([type, 'value']);

  return value ? value.size !== initialValue.size : false;
};

export function constructSummaryMetadata(summary) {
  const metaData = {
    day: '',
    flowName: '',
  };
  try {
    const summaryArray = summary?.match(/(.*) - (Day \d+)/);

    if (summaryArray) {
      metaData.day = summaryArray[2];
      metaData.flowName = summaryArray[1];
      return metaData;
    }
    // some summaries are reversed this handles them but eventually this code can be removed.
    // todo: set reminder for June 2020 to review actions and delete if possible. - Sonia
    const reversedSummaryArray = summary?.match(/(Day \d+) - (.*)/);

    if (!summaryArray && reversedSummaryArray) {
      metaData.day = reversedSummaryArray[1];
      metaData.flowName = reversedSummaryArray[2];
    }
    return metaData;
  } catch (e) {
    logErrorToSentry(e);
    return metaData;
  }
}
