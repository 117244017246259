import { FC, useState, useEffect } from 'react';
import { useGetLinkedinToken } from '@groove/api/hooks/useLinkedin';

import useStore from '../store/useStore';

import TaskPaneContainer from './TaskPaneContainer';
import SendosoPane from './SendosoPane';
import EmailPane from './EmailPane';
import CallPane from './CallPane';
import GeneralTaskPane from './GeneralTaskPane';
import SmsPane from './SmsPane';
import LinkedInPane from './LinkedInPane';

const MainContainer: FC = () => {
  const type = useStore(store => store.action?.type);

  // useGetLinkedinToken will get called every time this component gets rerendered when the response is an error
  // to get around this, we only want to fetch for the toke on component mount
  // since we cannot call hooks inside a useEffect, we want a local state to see of the component has full rendered
  // if the component has full rendered, that means the useGetLinkedinToken was already called once. There disable
  // the endpoint on subsequent rerenders.

  const [componentFullyLoaded, setComponentFullyLoaded] = useState(false);
  useGetLinkedinToken({
    enabled: !componentFullyLoaded,
  });

  useEffect(() => {
    setComponentFullyLoaded(true);
  }, []);

  let mainPane = <GeneralTaskPane />;

  if (type === 'TEMPLATE') {
    mainPane = <EmailPane />;
  } else if (type === 'CALL') {
    mainPane = <CallPane />;
  } else if (type === 'STEP_SUBTYPE_SMS') {
    mainPane = <SmsPane />;
  } else if (type === 'STEP_SUBTYPE_SENDOSO') {
    mainPane = <SendosoPane />;
  } else if (
    type === 'STEP_SUBTYPE_LINKEDIN_INMAIL' ||
    type === 'STEP_SUBTYPE_LINKEDIN_CONNECT'
  ) {
    mainPane = <LinkedInPane />;
  }

  return (
    // We need min height = 0 to make overflow work
    <div className="relative flex flex-row flex-1 w-full min-h-0 bg-white">
      <TaskPaneContainer />
      {mainPane}
    </div>
  );
};

export default MainContainer;
