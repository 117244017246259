import { Text } from '@fluentui/react';

import TemplateIcon from './icons/zero_state_template_preview.svg';

const NoSelectedTemplate: React.FC = () => {
  return (
    <div className="flex flex-col w-full justify-center items-center">
      <img width={80} src={TemplateIcon} alt="No selected Template Icon" />
      <Text className="text-2xl text-gray2 font-groove">
        Select a template to view or edit
      </Text>
    </div>
  );
};

export default NoSelectedTemplate;
