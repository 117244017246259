import { ASC } from 'Modules/Shared/components/SortDirectionButton';
import { Map } from 'immutable';

export const SHARING_DIALOG_OPEN_KEY_PATH = ['sharingDialogOpen'];
export const FOLDER_RENAME_DIALOG_OPEN_KEY_PATH = ['renameDialogOpen'];
export const FOLDER_DELETE_DIALOG_OPEN_KEY_PATH = ['deleteFolderDialogOpen'];
export const TEMPLATE_COPY_DIALOG_OPEN_KEY_PATH = ['copyTemplateDialogOpen'];
export const TEMPLATE_EDIT_DIALOG_OPEN_KEY_PATH = ['editTemplateDialogOpen'];
export const TEMPLATE_CREATE_DIALOG_OPEN_KEY_PATH = [
  'createTemplateDialogOpen',
];
export const TEMPLATES_LOADING_KEY_PATH = ['templates', 'loading'];
export const FLOW_USAGE_DIALOG_OPEN_KEY_PATH = ['flowUsageDialogOpen'];
export const NEW_FOLDER_DIALOG_OPEN_KEY_PATH = ['newFolderDialogOpen'];
export const TEMPLATES_KEY_PATH = ['templates', 'templates'];
export const TEMPLATE_FOLDERS_KEY_PATH = ['templates', 'templateFolders'];
export const ORDER_BY_KEY_PATH = ['templates', 'orderBy'];
export const ACTIVE_FOLDER_ID_KEY_PATH = ['templates', 'activeFolderId'];
export const ACTIVE_TEMPLATE_ID_KEY_PATH = ['templates', 'activeTemplateId'];
export const SEARCH_QUERY_KEY_PATH = ['templates', 'searchQuery'];
export const TEMPLATE_FILTER_OPTION = ['templates', 'templateFilterOption'];
export const FOLDER_SEARCH_QUERY = ['templates', 'folderSearchQuery'];

// Holds Id for the folder currently being edited.
export const TARGET_FOLDER_ID_KEY_PATH = ['targetFolderId'];

export const ACTIVE_TEMPLATE_FLOW_USAGE_KEY_PATH = ['activeTemplateFlowUsage'];

export const TEMPLATE_CREATE_TYPE_KEY_PATH = ['templateCreateTemplateType'];

export const FOLDER_TAB_VALUES = ['all', 'mine', 'shared'];
export const FOLDER_TAB_LABELS = {
  all: 'All Folders',
  mine: 'My Templates',
  shared: 'Shared With Me',
};

export const FOLDER_UPDATE_OPTIONS = [
  { value: 'share', label: 'Share folder' },
  { value: 'rename', label: 'Rename' },
  { value: 'delete', label: 'Delete' },
];

export const FOLDER_ORDER_BY_OPTIONS = [
  { value: 'name', label: 'Name' },
  { value: 'created_at', label: 'Created At' },
];

export const FOLDER_ORDER_BY_OPTION_VALUES = FOLDER_ORDER_BY_OPTIONS.map(
  option => option.value
);

export const TEMPLATE_ORDER_BY_OPTIONS = [
  { value: 'sentCount', label: 'Most Used' },
  { value: 'name', label: 'Name' },
  { value: 'created_at', label: 'Created At' },
  { value: 'openedCount', label: 'Open Rate' },
  { value: 'linkClickCount', label: 'Click Rate' },
  { value: 'repliedCount', label: 'Reply Rate' },
];

export const DEFAULT_SORT_VALUE = FOLDER_ORDER_BY_OPTIONS[0].value;

export const DEFAULT_FOLDER_ORDER_OPTIONS = new Map({
  direction: ASC,
  value: DEFAULT_SORT_VALUE,
});

export const TEMPLATE_FILTER_OPTIONS = [
  { label: 'All templates', value: 'all' },
  { label: 'My templates', value: 'mine' },
  { label: 'Shared with me', value: 'shared' },
];

export const SEARCH_QUERY_DELAY = 500;
