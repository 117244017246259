// This is in JS since we are still using immutable.
import { fromJS, Map } from 'immutable';

import { actionTypes, CachedAction } from 'Modules/Shared/actions/cached.ts';

const initialState = fromJS({
  templateData: {},
  copiedTemplate: false,
});

export type CachedState = typeof initialState;

export default function cached(
  state = initialState,
  action: CachedAction = { type: null }
): Map<string, unknown> {
  switch (action.type) {
    case actionTypes.STORE_TEMPLATE_DATA: {
      const { cachedTemplateData } = action.payload;
      return state.set('templateData', cachedTemplateData);
    }

    case actionTypes.CLEAR_TEMPLATE_DATA: {
      return state.set('templateData', {});
    }

    case actionTypes.COPYING_TEMPLATE: {
      return state.set('copiedTemplate', true);
    }
    case actionTypes.NOT_COPYING_TEMPLATE: {
      return state.set('copiedTemplate', false);
    }

    default:
      return state;
  }
}
