import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { FSA } from 'flux-standard-action';

import {
  actionTypes,
  setCampaigns,
  failedToFetchCampaigns,
} from 'Modules/Shared/actions/campaignAssociation';
import * as query from 'Modules/Shared/gql/GetCampaigns.query.gql';
import executeGraphQLRequest from 'GrooveHTTPClient/executeGraphQLRequest';
import { logErrorToSentry } from 'Modules/Shared/sagas/errors';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';

export function* handleFetchCampaigns(): SagaIterator {
  try {
    const response = yield call(executeGraphQLRequest, {
      query,
    });

    const campaigns = response.getIn(['data', 'viewer', 'campaigns']);
    return yield put(setCampaigns(campaigns));
  } catch (error) {
    logErrorToSentry(error);
    yield put(
      pushSnackbarMessage({
        message: 'Error fetching campaigns',
      })
    );
    return yield put(failedToFetchCampaigns());
  }
}

function* watchHandleFetchCampaigns(): SagaIterator {
  yield takeEvery<FSA>(actionTypes.FETCH_CAMPAIGNS.BEGIN, handleFetchCampaigns);
}

export default function* root(): SagaIterator {
  yield all([fork(watchHandleFetchCampaigns)]);
}
