import { DropDownItem, StandardDropDownItem } from '../Components/DropDownItem';

// These two helper functions are needed because dividers and headers are part of a DropDown
export const getNextSelectableIndex = (
  items: DropDownItem[],
  index: number,
): number => {
  if (items.length < 1) return -1;
  if (!items[index].type && !(items[index] as StandardDropDownItem).disabled)
    return index;

  for (let i = index; i < items.length; i += 1) {
    if (!items[i].type && !(items[i] as StandardDropDownItem).disabled)
      return i;
  }

  return -1;
};

export const getPrevSelectableIndex = (
  items: DropDownItem[],
  index: number,
): number => {
  if (items.length < 1) return -1;
  if (!items[index].type && !(items[index] as StandardDropDownItem).disabled)
    return index;

  for (let i = index; i >= 0; i -= 1) {
    if (!items[i].type && !(items[i] as StandardDropDownItem).disabled)
      return i;
  }

  return items.length - 1;
};
