import { SALESFORCE_OBJECTS } from 'Modules/Shared/constants';
import { isString, mapKeys, mapValues, isNil, isBoolean } from 'lodash-es';

const baseSavedSearchMap = {
  name: null,
  object_type: null,
  definition: {
    objectType: null,
    scope: null,
    conditions: [],
  },
};

const baseConditionMap = {
  fieldName: null,
  fieldType: null,
  isContactCondition: false,
  operator: null,
  value: null,
};

const savedSearchScopes = ['everything', 'mine'];

export default class SavedSearchRequestBuilder {
  /**
   * @param {String} name
   * @param {String} salesforceObject
   * @param {Array} filters
   * @param {String} scope
   */
  constructor(
    name = '',
    salesforceObject = '',
    scope = '',
    filters = [],
    relationshipName = ''
  ) {
    this._name = name;
    this._objectType = salesforceObject;
    this._scope = scope;
    this._filters = filters;
    this._relationshipName = relationshipName;
  }

  /**
   * For standard salesforce objects, the objecType could be undercase or camelcase and needs to be mapped
   * to one of the SALESFORCE_OBJECTS in order to maintain uniformity
   * in the requests and saved searches.
   *
   * For contact related object types, use as-is, without transforming.
   *
   * @return {String}
   */
  getSalesforceObjectType() {
    if (!isString(this._objectType)) {
      throw new Error(
        `The salesforce object is required. Recieved: ${this._objectType}`
      );
    }

    const lowerSalesforceObjects = mapKeys(SALESFORCE_OBJECTS, (_, key) =>
      key.toLowerCase()
    );
    if (lowerSalesforceObjects[this._objectType.toLowerCase()]) {
      return lowerSalesforceObjects[this._objectType.toLowerCase()];
    }

    return this._objectType;
  }

  static transformValue(value) {
    if (isBoolean(value)) {
      return value.toString();
    }

    return value;
  }

  getDefinition() {
    const definition = {};
    definition.objectType = this.getSalesforceObjectType();
    definition.scope = this._scope;
    definition.conditions = this._filters.map(filter => {
      const conditionMap = Object.assign({}, baseConditionMap);
      return mapValues(conditionMap, (v, k) =>
        !isNil(filter[k]) ? this.constructor.transformValue(filter[k]) : v
      );
    });

    if (this._relationshipName)
      definition.relationshipName = this._relationshipName;

    return definition;
  }

  build() {
    if (!Array.isArray(this._filters)) {
      throw new Error(
        `Unexpected filter type. Expected Array recieved ${this._filters}`
      );
    }

    if (!isString(this._scope) || !savedSearchScopes.includes(this._scope)) {
      throw new Error(
        `Scope needs to be one of: ${savedSearchScopes}. Received: ${this._scope}`
      );
    }

    const requestMap = Object.assign({}, baseSavedSearchMap);

    const objectType = this.getSalesforceObjectType();
    requestMap.object_type = objectType;
    requestMap.definition = this.getDefinition();

    requestMap.name = this._name;

    return requestMap;
  }
}
