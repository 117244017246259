import { makeStyles } from '@material-ui/core';
import * as React from 'react';

import pulse from 'Modules/Shared/components/WorkStepRouteLoader/pulse';
import ClariTheme, { ModifiedTheme } from 'Utils/theme/ClariTheme';

const greyContent = {
  background: ClariTheme.palette.text.backgroundColor,
  borderRadius: 2,
  height: 21,
};

const useStyles = makeStyles((theme: ModifiedTheme) => ({
  root: {
    height: 56,
    borderBottom: `1px solid ${theme.palette.text.borders}`,
    alignItems: 'center',
    display: 'flex',
  },
  loadingType: {
    ...greyContent,
    ...pulse,
    width: 40,
    display: 'flex',
    margin: 16,
  },
  loadingContent: {
    ...greyContent,
    ...pulse,
    flex: 1,
    margin: '0px 30px 0px 16px',
  },
  '@keyframes pulse': {
    '0%': {
      backgroundColor: theme.palette.text.accentGray,
    },
    '50%': {
      backgroundColor: theme.palette.text.lightGray,
    },
    '100%': {
      backgroundColor: theme.palette.text.accentGray,
    },
  },
  playButtonContainer: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: theme.palette.text.primary,
  },
}));

const LoaderRow: React.FC = () => {
  const classes = useStyles({});
  return (
    <div className={classes.root}>
      <div className={classes.loadingType} />
      <div className={classes.loadingContent} />
    </div>
  );
};

LoaderRow.displayName = 'LoaderRow';

export default LoaderRow;
