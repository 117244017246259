import makeHTTPRequest from '../makeHTTPRequest';
import { POST } from '../HTTPMethod';

const BASE_URL = '/api/v2/accounts';
/**
 * @param {Object} body
 *
 * @return {Promise}
 * */
export const index = body =>
  makeHTTPRequest({
    path: BASE_URL,
    method: POST,
    body,
  });

/**
 * @param {Account} account
 *
 * @return {Promise}
 * */
export const toggleStar = ({ id, starred }) => {
  if (!starred) {
    return makeHTTPRequest({
      path: `${BASE_URL}/${id}/unstar`,
      method: POST,
    });
  }

  return makeHTTPRequest({
    path: `${BASE_URL}/${id}/star`,
    method: POST,
  });
};

export const batchCreate = sfdcAccounts => {
  return makeHTTPRequest({
    path: `${BASE_URL}/batch_create`,
    method: 'PATCH',
    body: { sfdc_accounts: sfdcAccounts },
  });
};

/**
 * @param {number} id
 *
 * @return {Promise}
 * */
export const accountIdSearch = id =>
  makeHTTPRequest({ path: `${BASE_URL}/${id}` });

export const unassociatedContacts = ({ id }) =>
  makeHTTPRequest({
    path: `${BASE_URL}/${id}/unassociated_salesforce_contacts`,
  });
