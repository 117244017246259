import useTemplateFolderStar from '@groove/api/hooks/useTemplateFolderStar';
import useTemplateFolderUnstar from '@groove/api/hooks/useTemplateFolderUnstar';
import { IconButton, TooltipHost, Text } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';

import EmptyStar from './icons/EmptyStar';
import Star from './icons/Star';
import {
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  TEMPLATES_VIEW,
  TEMPLATE_STAR_STATE,
  trackTemplatesEvent,
} from './analytics/templatesEvents';
import useStore from './store/useStore';

type TemplateFolderStarrerProps = {
  isFavorite: boolean;
  templateFolderId: number;
};

const styles = {
  root: 'h-5 w-5 p-0 text-neutral/400 hover:text-neutral/900',
};

const TemplateFolderStarrer: FC<TemplateFolderStarrerProps> = ({
  isFavorite,
  templateFolderId,
}) => {
  const { mutate: starTemplateFolder } = useTemplateFolderStar();
  const { mutate: unstarTemplateFolder } = useTemplateFolderUnstar();

  const selectedTemplatePanelTab = useStore(
    store => store.selectedTemplatePanelTab,
  );

  const [isStarred, setIsStarred] = useState(isFavorite);

  useEffect(() => {
    setIsStarred(isFavorite);
  }, [isFavorite]);

  const handleToggleFavorite = (
    e: React.MouseEvent<HTMLAnchorElement>,
  ): void => {
    e.stopPropagation();
    if (isStarred) {
      unstarTemplateFolder({ templateFolderId });
    } else {
      starTemplateFolder({ templateFolderId });
    }
    setIsStarred(prev => !prev);
    trackTemplatesEvent({
      eventName: TEMPLATES_EVENT_TYPE.STAR_CLICKED,
      templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.PANEL,
      templateTab: selectedTemplatePanelTab,
      templateView: TEMPLATES_VIEW.LIST,
      starState: isStarred
        ? TEMPLATE_STAR_STATE.UNCLICKED
        : TEMPLATE_STAR_STATE.CLICKED,
    });
  };

  const starTooltipText = isStarred
    ? 'Remove from favorites'
    : 'Add to favorites';

  return (
    <TooltipHost content={<Text>{starTooltipText}</Text>}>
      <IconButton
        aria-label="favorite-folder-button"
        onClick={handleToggleFavorite}
        styles={styles}
      >
        {isStarred ? <Star /> : <EmptyStar />}
      </IconButton>
    </TooltipHost>
  );
};

export default TemplateFolderStarrer;
