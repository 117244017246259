type GetAttachmentsReturn = {
  name: string;
  uploadLocation: string;
  size: number;
  id: string;
}[];

export const getAttachmentsFormBody = (
  body: string | null,
): GetAttachmentsReturn => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(body || '', 'text/html');
  const footer = htmlDoc.getElementsByClassName('attachmentFooter');
  const arr = Array.from(footer);
  if (arr.length) {
    const footerDoc = parser.parseFromString(arr[0].innerHTML, 'text/html');
    const links = footerDoc.getElementsByClassName('fr-file');
    const linksArr = Array.from(links);

    return linksArr.map(link => {
      const id = link?.getAttribute('id') || '';
      const idParts = id.split('*');
      const size = idParts.length > 1 ? Number(idParts[1]) : 0;
      return {
        name: link?.textContent || '',
        uploadLocation: link?.getAttribute('href') || '',
        size,
        id,
      };
    });
  }
  return [];
};
