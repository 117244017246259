import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { templateStar } from '../gateway/v1/template';

export type UseTemplateStarParams = {
  templateId: number;
};

const useTemplateStar = (): UseMutationResult<
  unknown,
  unknown,
  UseTemplateStarParams,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ templateId }) => templateStar(templateId), {
    onSuccess: () => {
      queryClient.invalidateQueries('favoriteTemplatesGet');
    },
  });
};

export default useTemplateStar;
