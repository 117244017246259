import React from 'react';
import { Tooltip } from '@groove-labs/groove-ui';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import LabeledIcon from 'Modules/ActionsRewrite/components/customCells/ActionType/LabeledIcon';
import ClariTheme from 'Utils/theme/ClariTheme';
import { Action } from '@groove/api/gateway/v1/actions';
import { useGetCurrentUser } from '@groove/api/hooks/useUsers';
import { getLdClient } from 'Utils/ldClient';
import useStore from 'Modules/ActionsRewrite/store/useStore';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import { loadAction } from '@groove/action-compose/utils/actionComposeHelpers';

export const useStyles = makeStyles(theme => ({
  openInNewIcon: {
    width: 20,
    height: 20,
    padding: '0 0 0 25px',
    cursor: 'pointer',
  },
}));

/**
 * TODO:
 * HALP! Lots of questions
 * isComposePaneReady
 * these are all passed in somehow.
 *
 * Ripping from:
 * apps/web-app/src/js/modules/Actions/containers/Table/Cell/Type/index.js
 */

const Type: React.FC<{ action: Action }> = ({ action }) => {
  // Feature Flags
  const deleteOrRemoveFromActionTableFF = getLdClient()?.variation(
    'instant-remove-people-and-delete-actions'
  );
  const actionComposeRewriteFF = getLdClient()?.variation(
    'action-compose-rewrite'
  );

  const isComposePaneReady = true; // TODO: WHAT IS THIS
  const { activeTab } = useStore();
  const isHistoryTab = activeTab === 'Executed Actions';

  const playButtonDisabled = !isComposePaneReady && !actionComposeRewriteFF;
  const iconStyle = {
    ...(playButtonDisabled && {
      backgroundColor: ClariTheme.palette.text.secondary,
    }),
  };

  const classes = useStyles();

  const { data: userData } = useGetCurrentUser();
  const { sfdc_instance_url: sfdcInstanceUrl } = userData;
  const { sfdc_id: sfdcId } = action?.who || {};

  const onPlayCircleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    loadAction({ id: action.id.toString() });
  };

  // TODO: Track to MixPanel and Rum (Splunk) - WFA-120
  const trackSalesforceEvent = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  return (
    <div>
      {isHistoryTab ? (
        <div className="flex row items-center">
          <div className="max-w-4">
            <LabeledIcon
              type={action.type}
              priority={action.priority}
              iconStyle={iconStyle}
            />
          </div>
          <Tooltip title="View In Salesforce" key={`${sfdcId}`}>
            <a
              target="_blank"
              href={`${sfdcInstanceUrl}/${sfdcId}`}
              onClick={trackSalesforceEvent}
            >
              <OpenInNewIcon className={classes.openInNewIcon} />
            </a>
          </Tooltip>
        </div>
      ) : (
        <IconButton
          color="default"
          onClick={onPlayCircleClick}
          disabled={playButtonDisabled}
        >
          <LabeledIcon
            type={action.type}
            priority={action.priority}
            iconStyle={iconStyle}
          />
        </IconButton>
      )}
    </div>
  );
};

export default Type;
