import Tab from 'Modules/ActionsRewrite/components/tableTabs/Tab';
import { getLdClient } from 'Utils/ldClient';
import usePostActionCounts from 'Modules/ActionsRewrite/hooks/usePostActionCounts';
import useStore, { TabName } from 'Modules/ActionsRewrite/store/useStore';
import useActionFilterStore from '@groove/ui/Components/ActionFilter/store/useStore';

const allTabs = [
  'Active',
  'Failed',
  'Executed Actions',
  'Assigned to Others',
] as TabName[];
const tabsWithoutExecuted = [
  'Active',
  'Failed',
  'Assigned to Others',
] as TabName[];

export default function TableTabs() {
  const { activeTab } = useStore();
  const selectedFilters = useActionFilterStore(store => store.selectedFilters);
  // TODO: fix typing
  const { data: actionCountsResponse, isFetching } =
    usePostActionCounts(selectedFilters);
  const omniActionEnabled = getLdClient()?.variation('omniaction');

  const actionCounts = actionCountsResponse?.data || {};
  const tabNames = omniActionEnabled ? allTabs : tabsWithoutExecuted;

  return (
    <div className="flex">
      {(tabNames as TabName[]).map(tabName => (
        <Tab
          key={tabName}
          name={tabName}
          isActive={tabName === activeTab}
          totalCount={actionCounts[tabName] || 0}
          isFetchingAdditionalData={false}
          isFetchingCounts={isFetching}
        />
      ))}
    </div>
  );
}
