import produce from 'immer';
import create from 'zustand';

// FINDME:
// TODO: Double check with omnibar
export type TabName =
  | 'Active'
  | 'Failed'
  | 'Executed Actions'
  | 'Assigned to Others';

export type Store = {
  activeTab: TabName;
  setActiveTab: (tabName: TabName) => void;
};

// Goal is to consolidate the
// apps/web-app/src/js/modules/Actions/selectors/index.js
// apps/web-app/src/js/modules/Actions/reducer.js
const useStore = create<Store>((set, get) => ({
  activeTab: 'Active',
  setActiveTab: (tabName: TabName) => set({ activeTab: tabName }),
}));

export default useStore;
