import { FC, HTMLProps, useRef } from 'react';
import useTemplatesStore from '@groove/templates/store/useStore';
import TemplatePreviewPane from '@groove/templates/newTemplateComponents/TemplatePreviewPane';
import useHandleOutsideClicks from '@groove/ui/helpers/useHandleOutsideClicks';
import useRecentTemplatesSet from '@groove/api/hooks/useRecentTemplatesSet';
import { FullAction } from '@groove/api/gateway/v1/actionCompose';

import useStore from '../store/useStore';

export type ExtendedTaskPaneParams = {
  className?: HTMLProps<HTMLElement>['className'];
};

const ExtendedTaskPane: FC<ExtendedTaskPaneParams> = ({ className }) => {
  const ssRef = useRef<HTMLDivElement | null>(null);

  const isExtendedTaskPaneOpen = useStore(
    store => store.otherValues.isExtendedTaskPaneOpen,
  );
  const taskPaneType = useStore(store => store.otherValues.taskPaneType);
  const templateId = useStore(store => store.action.templateId);
  const selectedTemplate = useTemplatesStore(store => store.selectedTemplate);

  const { mutate: setRecentTemplate } = useRecentTemplatesSet();

  const onTemplateInsert = (): void => {
    if (!selectedTemplate) return;

    const { body, subject } = useStore.getState().action;
    const partialAction: Partial<FullAction> = {
      body: selectedTemplate.body || '',
    };

    if (!subject) partialAction.subject = selectedTemplate.subject;
    if (!body && !subject)
      partialAction.crmTaskType = selectedTemplate.template_type_value;
    // Add the template id to the action if there is not already a template id for insert
    if (!templateId) partialAction.templateId = selectedTemplate.id;

    useStore
      .getState()
      .setOtherValues({ taskPaneType: 'TASK', wysiwygEdited: false });
    useStore.getState().insertAtCursorAndUpdateAction(partialAction);
    setRecentTemplate({ templateId: selectedTemplate.id });
    useTemplatesStore.getState().setSelectedTemplate(undefined);
  };

  const onTemplateReplace = (): void => {
    const { body, subject } = useStore.getState().action;
    if ((!subject || subject.length < 1) && (!body || body.length < 1)) return;

    useStore.getState().updateAction({
      body: selectedTemplate?.body,
      subject: selectedTemplate?.subject,
      crmTaskType: selectedTemplate?.template_type_value,
      templateId: selectedTemplate?.id,
    });
    useStore
      .getState()
      .setOtherValues({ taskPaneType: 'TASK', wysiwygEdited: false });

    useTemplatesStore.getState().setSelectedTemplate(undefined);
  };

  const closeSS = (): void =>
    useStore.getState().setOtherValues({
      isExtendedTaskPaneOpen: false,
    });

  useHandleOutsideClicks(ssRef, closeSS);

  if (!isExtendedTaskPaneOpen && !selectedTemplate) return null;

  const width = 'w-[450px]';

  return (
    <div className={`absolute right-0 top-0 h-full w-0 z-50 ${className}`}>
      <div
        className={`absolute h-full overflow-scroll flex flex-col bg-white ${width} border-0 !border-l border-solid border-neutral/200`}
        style={{
          boxShadow:
            '2.8px 0px 1.8px -1.8px rgba(0, 0, 0, 0.1), 7.2px 3.2px 7.2px -7.2px rgba(0, 0, 0, 0.13)',
        }}
      >
        {taskPaneType === 'TEMPLATE' && selectedTemplate && (
          <TemplatePreviewPane
            template={selectedTemplate}
            onInsert={onTemplateInsert}
            onDismiss={() =>
              useTemplatesStore.getState().setSelectedTemplate(undefined)
            }
            onReplace={onTemplateReplace}
            setIsEditorOpen={() =>
              useStore.getState().setOtherValues({ isTemplateEditorOpen: true })
            }
          />
        )}
      </div>
    </div>
  );
};

export default ExtendedTaskPane;
