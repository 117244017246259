import FilterButton from 'Modules/ActionsRewrite/components/filtersRow/FilterButton';
import Search from 'Modules/ActionsRewrite/components/filtersRow/Search';

export default function FilterRow() {
  return (
    <div className="flex bg-white box-border">
      <Search />
      <FilterButton />
    </div>
  );
}
