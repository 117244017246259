import { CancelToken } from 'axios';
import { call, cancelled, select } from 'redux-saga/effects';
import HTTPStatusCodes from 'http-status-codes';
import logout from 'ErrorHandling/logout';
import { location as getLocation } from 'Modules/Shared/selectors/location';

import { UNAUTHORIZED_ERROR_HANDLING_EXEMPT_PATHS } from 'Modules/App/constants';
import trackHttpError from 'Modules/Shared/sagas/analytics/trackHttpError';
import { currentUser } from 'Modules/Shared/selectors/users';

import executeHTTPRequest from './executeHTTPRequest';
import HTTPResponse from './HTTPResponse';
import HTTPError from './HTTPError';
import { getPathName } from 'Utils/history';

const BLANK_SFDC_CREDENTIALS_EXCEPTION =
  'ExceptionUtility::BlankSfdcCredentialsClientException';

function* makeHTTPRequest(options) {
  const source = CancelToken.source();

  try {
    const requestData = yield call(executeHTTPRequest, {
      ...options,
      cancelToken: source.token,
    });
    const { result } = requestData;

    if (result instanceof HTTPError) {
      const errorStatus = result?.response?.status;
      const isUnauthorizedResponse =
        errorStatus === HTTPStatusCodes.UNAUTHORIZED;
      const currentLocation = yield select(getLocation);
      const user = yield select(currentUser);

      trackHttpError({
        currentUser: user,
        statusCode: errorStatus,
        routeName: currentLocation?.get('routeName'),
      });

      const locationPathname = getPathName();
      const isExemptPath =
        UNAUTHORIZED_ERROR_HANDLING_EXEMPT_PATHS.includes(locationPathname);
      if (isUnauthorizedResponse) {
        // if we are in a path where we dont want the 401 handled, rethrow the error
        if (isExemptPath) {
          throw result;
        }

        const exceptionType = result.response.message?.getIn(['data', 'type']);

        // if they have blank sfdc credentials, this will be caught in handleHealthChecks
        // and users will be prompted to resolve it there
        if (exceptionType !== BLANK_SFDC_CREDENTIALS_EXCEPTION) {
          yield call(logout, result);
        }

        // we want this saga to effectively never end
        // until it gets cancelled by the route change.
        // This prevents any race conditions with the
        // global error handlers.
        yield call(() => new Promise(() => {}));
      } else {
        throw result;
      }
    }

    if (result instanceof HTTPResponse) {
      return result;
    }

    throw new Error(`Unexpected response: ${result}`);
  } finally {
    const shouldCancel = yield cancelled();
    if (shouldCancel) {
      source.cancel('cancelled by saga');
    }
  }
}

export default makeHTTPRequest;
