import { FC, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import {
  templateFoldersFavorites,
  templateFoldersSearch,
} from '@groove/api/services/v1/templateFolders';
import useUserGet from '@groove/api/hooks/useUserGet';
import { Icon, IconButton, Text } from '@fluentui/react';
import { favoriteTemplatesGet } from '@groove/api/services/v1/favoriteTemplates';
import * as Sentry from '@sentry/browser';
import { Transaction } from '@sentry/types';
import { Template, TemplateType } from '@groove/api/gateway/v1/template';
import { useIdentifyMixpanelUser } from '@groove/api/analytics/hooks/useMixpanel';

import useStore from './store/useStore';
import { TemplateLoadingShimmers } from './TemplateLoadingShimmers';
import TemplateSearchHeader from './TemplateSearchHeader';
import CreateTemplateButton from './CreateTemplateButton';
import initializeGrooveWysiwygEditorConfiguration from './initializeGrooveWysiwygEditorConfiguration';
import TemplatesNavigator from './TemplatesNavigator';
import useSentry from './sentry/initializeSentry';
import { REACT_QUERY_CACHE_STALE_TIME } from './constants';
import {
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  TEMPLATE_EDITOR_CLOSED_VIA,
  trackTemplatesEvent,
} from './analytics/templatesEvents';
import OpenNewTabIcon from './icons/open-new-tab.svg';

const iconBtnStyles = { root: 'text-neutral/900' };

type TemplatesProps = {
  initialSearchTerm?: string;
  onSubmit: (data: Template | undefined) => void;
  onReplace?: (data: Template | undefined) => void;
  onClose?: () => void;
  templateType?: TemplateType;
  templateSubType?: string;
};

const Templates: FC<TemplatesProps> = ({
  initialSearchTerm,
  onSubmit,
  onReplace,
  onClose,
  templateType = 'email',
  templateSubType = '',
}) => {
  useSentry();
  const setTemplateFolders = useStore(store => store.setTemplateFolders);
  const selectedFolderId = useStore(store => store.selectedFolderId);
  const setSelectedFolder = useStore(store => store.setSelectedFolder);
  const setFavoriteTemplates = useStore(store => store.setFavoriteTemplates);
  const setFavoriteTemplateFolders = useStore(
    store => store.setFavoriteTemplateFolders,
  );
  const isOutlook = useStore(store => store.isOutlook);
  const setIsOutlook = useStore(store => store.setIsOutlook);
  const { data: user } = useUserGet();
  const transactionRef = useRef<Transaction | null>(null);

  // For reasons unknown, Outlook clips 1 pixel off the right side of its dialog?
  // Hence, -1 width for our Templates app. Check monorepo/pull/1551 comments for pic
  const templatesAppWidth = isOutlook ? 'w-[calc(100%-1px)]' : 'w-full';

  const { data: favoriteTemplatesData } = useQuery(
    ['favoriteTemplatesGet', templateType],
    () => favoriteTemplatesGet(templateType, templateSubType),
    REACT_QUERY_CACHE_STALE_TIME,
  );

  const { data: favoriteTemplateFoldersData } = useQuery(
    ['favoriteTemplateFoldersGet', templateType],
    () => templateFoldersFavorites(templateType, templateSubType),
    REACT_QUERY_CACHE_STALE_TIME,
  );

  const {
    data: templateFolders,
    isFetching: isFetchingTemplateFolders,
    refetch: refetchTemplateFolders,
  } = useQuery(
    ['templateFoldersSearch', 'initialFoldersQuery'],
    () => {
      return templateFoldersSearch({
        search_term: '',
        template_type: templateType,
        template_subtype: templateSubType,
      });
    },
    REACT_QUERY_CACHE_STALE_TIME,
  );

  const templatesModalHeight = isOutlook ? 'h-[92vh]' : 'h-full';

  useIdentifyMixpanelUser();

  useEffect(() => {
    useStore.setState({ templateType });
  }, [templateType]);

  useEffect(() => {
    if (user?.data.provider) {
      setIsOutlook(user.data.provider === 'microsoft_graph_auth');
    }
  }, [setIsOutlook, user]);

  useEffect(() => {
    if (templateFolders) {
      setTemplateFolders(templateFolders);
    }
  }, [setTemplateFolders, templateFolders]);

  useEffect(() => {
    if (favoriteTemplatesData?.data) {
      setFavoriteTemplates(favoriteTemplatesData.data);
    }
  }, [favoriteTemplatesData, setFavoriteTemplates]);

  useEffect(() => {
    if (favoriteTemplateFoldersData?.data) {
      setFavoriteTemplateFolders(favoriteTemplateFoldersData.data);
    }
  }, [favoriteTemplateFoldersData, setFavoriteTemplateFolders]);

  useEffect(() => {
    if (
      !isFetchingTemplateFolders &&
      selectedFolderId &&
      templateFolders?.data?.length
    ) {
      const selected = templateFolders.data.find(folder => {
        return folder.id === selectedFolderId;
      });
      setSelectedFolder(selected);
    }
  }, [
    isFetchingTemplateFolders,
    selectedFolderId,
    setSelectedFolder,
    templateFolders?.data,
  ]);

  useEffect(() => {
    if (isFetchingTemplateFolders) {
      transactionRef.current = Sentry.startTransaction({
        name: 'Template Data Fetch',
      });
    } else {
      transactionRef.current?.finish();
    }
  }, [isFetchingTemplateFolders]);

  initializeGrooveWysiwygEditorConfiguration();

  return (
    <div className={`flex flex-col h-full font-groove ${templatesAppWidth}`}>
      <div className="flex h-[52px] bg-neutral/25 border-solid border-0 border-b border-neutral/200">
        <div className="flex w-full items-center p-3">
          <a
            aria-label="webapp-templates-link"
            target="_blank"
            className="hover:no-underlined no-underlined text-neutral/900"
            rel="noopener noreferrer"
            href={`${process.env.NX_FEBES_URL}/templates`}
          >
            <Text className="font-bold font-groove" variant="xLarge">
              Templates
            </Text>
            <img
              src={OpenNewTabIcon}
              className="pl-2 pr-4"
              alt="open-new-tab"
            />
          </a>
          <TemplateSearchHeader initialSearchTerm={initialSearchTerm} />
          <CreateTemplateButton
            selectedFolderId={selectedFolderId}
            refetchOnSuccess={refetchTemplateFolders}
          />
          {onClose && (
            <IconButton
              aria-label="close-templates-modal"
              iconProps={{
                iconName: 'Cancel',
                styles: iconBtnStyles,
              }}
              onClick={() => {
                trackTemplatesEvent({
                  eventName: TEMPLATES_EVENT_TYPE.CLOSED,
                  templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
                  closedVia: TEMPLATE_EDITOR_CLOSED_VIA.X_BUTTON,
                });
                onClose();
              }}
            />
          )}
        </div>
      </div>
      <div className={`overflow-y-hidden ${templatesModalHeight}`}>
        {isFetchingTemplateFolders && <TemplateLoadingShimmers />}
        {!isFetchingTemplateFolders && (
          <TemplatesNavigator
            templateFolders={templateFolders}
            onSubmit={onSubmit}
            onReplace={onReplace}
            refetchTemplateFolders={refetchTemplateFolders}
          />
        )}
      </div>
    </div>
  );
};

export default Templates;
