type RelaxingLadyParams = {
  width: number;
  height: number;
};

export default function RelaxingLady({ width, height }: RelaxingLadyParams) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 304 207"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-933 -511) translate(933 511)"
      >
        <path
          fill="#E4EFF7"
          fillRule="nonzero"
          d="M150.462 206.593c81.837 0 148.182-.837 148.182-1.869s-66.345-1.869-148.182-1.869c-81.838 0-148.182.837-148.182 1.87 0 1.031 66.344 1.868 148.182 1.868z"
        />
        <path
          stroke="#475056"
          strokeWidth="1.5"
          d="M14.538 152.641v51.82M68.838 152.641v51.82"
        />
        <path
          fill="#016776"
          fillRule="nonzero"
          d="M80.676 101.176169L2.84217094e-14 101.176169 2.84217094e-14 181.852169 80.676 181.852169z"
        />
        <path
          fill="#016776"
          fillRule="nonzero"
          d="M303.2733 73.1771686L80.676 73.1771686 80.676 181.855169 303.2733 181.855169z"
        />
        <path
          stroke="#FFF"
          strokeLinecap="square"
          strokeWidth="0.9"
          d="M27.645 101.176v57.786M13.09 118.837a4.8 4.8 0 100-9.6 4.8 4.8 0 000 9.6z"
        />
        <path
          fill="#24272D"
          fillRule="nonzero"
          d="M35.121 128.71s-1.941-18.675 17.631-18.675 33.507 18.675 33.507 18.675M91.356 118.258l-8.286-2.133 4.221-4.989a.637.637 0 011.077.162l2.988 6.96z"
        />
        <path
          fill="#24272D"
          fillRule="nonzero"
          d="M78.76 111.166l4.2 4.96-8.287 2.132 2.973-6.924a.658.658 0 01.51-.393.665.665 0 01.603.225z"
        />
        <path
          fill="#24272D"
          fillRule="nonzero"
          d="M83.154 131.515c5.658 0 10.245-3.747 10.245-8.37 0-4.623-4.587-8.37-10.245-8.37-5.658 0-10.245 3.747-10.245 8.37 0 4.623 4.587 8.37 10.245 8.37z"
        />
        <path
          fill="#016776"
          fillRule="nonzero"
          d="M96.3243 128.545169L73.9383 128.545169 73.9383 135.976169 96.3243 135.976169z"
        />
        <path
          stroke="#FFF"
          strokeLinecap="square"
          strokeWidth="0.9"
          d="M154.044 128.71H27.645"
        />
        <path
          fillRule="nonzero"
          stroke="#FFF"
          strokeLinecap="round"
          strokeWidth="0.5"
          d="M79 121.314c0-.726.78-1.314 1.743-1.314.963 0 1.74.6 1.74 1.314M88 121.314c0-.726.78-1.314 1.743-1.314.963 0 1.743.6 1.743 1.314"
        />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.5"
          d="M83.238 124.678s2.12-.24 2.054-2.083c-.065-1.844-.21 2.307 2.184 2.083"
        />
        <path
          fill="#EA6F57"
          fillRule="nonzero"
          d="M84.268 121h1.98a.297.297 0 01.249.194.299.299 0 01-.063.31l-.99.846a.301.301 0 01-.372 0l-.99-.846a.298.298 0 01.034-.442.297.297 0 01.152-.062z"
        />
        <path
          fill="#24272D"
          fillRule="nonzero"
          d="M55.956 139.705c-2.877-3.342-9.813 3.48-14.154.714-6.822-4.347 1.397-15.181-2.875-17.983-2.643-1.728-5.543 3.502-4.364 13.432.729 6.135 6 10.575 12.573 10.128
          3.903-.267 10.644-4.173 8.82-6.29z"
        />
        <path
          stroke="#475056"
          strokeWidth="1.5"
          d="M233.838 152.641v51.82M288.138 152.641v51.82"
        />
        <path
          fill="#C0E8EE"
          fillRule="nonzero"
          d="M248.073 78.436l-25.038-3.456-3.567 16.227 26.676 9.484c9.15 3.177
          18.528-4.552 17.346-14.167-1.182-9.615-2.445-19.125-2.973-20.364-1.107-2.586-4.989-13.476-28.434-14.463-23.445-.987-34.77 20.556-34.77 20.556l48.462 43.323"
        />
        <path
          fill="#24272D"
          fillRule="nonzero"
          d="M235.36 23.738c1.655-10.383-4.762-20.04-14.338-21.568-9.579-1.529-18.684 5.649-20.343 16.032-1.656 10.383 4.764 20.039 14.34 21.568s18.681-5.65 20.34-16.032z"
        />
        <path
          fill="#F19F4D"
          fillRule="nonzero"
          d="M226.428 60.34a7.241 7.241 0 01-4.152-1.97 7.235 7.235 0 01-2.124-4.075l-3.86-24.32a7.214 7.214 0 011.223-5.468 7.252 7.252 0 012.082-1.955 7.23 7.23 0
          0110.968 5.17l3.87 24.3a7.232 7.232 0 01-6 8.271 7.313 7.313 0 01-2.007.047z"
        />
        <path
          fill="#F19F4D"
          fillRule="nonzero"
          d="M221.415 48.367l-1.476.225a11.824 11.824 0 01-13.596-12.09l.3-8.6a11.81 11.81 0 0110.161-11.298 11.815 11.815 0 0113.356 10.05l1.182 8.375a11.816
          11.816 0 01-2.223 8.71 11.813 11.813 0 01-7.704 4.628z"
        />
        <path
          stroke="#25282D"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="0.9"
          d="M212.2653 29.5661686L209.3493 36.8231686 212.3013 37.2341686"
        />
        <path
          fill="#24272D"
          fillRule="nonzero"
          d="M215.358 32.317a1.146 1.146 0 100-2.292 1.146 1.146 0 000 2.292zM208.455 31.495a1.146 1.146 0 100-2.292 1.146 1.146 0 000 2.292z"
        />
        <path
          stroke="#25282D"
          strokeLinecap="round"
          strokeWidth="0.9"
          d="M217.686 39.103s-1.126 1.157-2.7 1.332"
        />
        <path
          fill="#24272D"
          fillRule="nonzero"
          d="M262.581 47.986s2.016 15.804-11.24 15.645c-21.946-.267-25.468-14.682-23.947-23.01.6-3.318 2.655-5.775
          4.938-8.127-11.835-2.595-11.028-10.34-11.028-10.34S199.3 32.58 200.577 18.687C201.855 4.795 214.857-.698 223.794.07c20.772
          1.782 21.384 15.015 26.958 16.92 31.596 10.812 11.83 30.996 11.83 30.996z"
        />
        <path
          fill="#F19F4D"
          fillRule="nonzero"
          d="M235.092 32.118a5.325 5.325 0 10-9.255-5.27l-2.394 4.205a5.325 5.325 0 109.255 5.27l2.394-4.205z"
        />
        <path
          stroke="#25282D"
          strokeLinecap="round"
          strokeWidth="0.9"
          d="M231.492 28.04l-3.399 5.966"
        />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeWidth="0.9"
          d="M214.986 72.507l33.336 5.332M241.692 76.561l-1.974-8.673"
        />
        <path
          fill="#F19F4D"
          fillRule="nonzero"
          d="M197.718 71.458s10.524-8.685 19.338 2.253c8.814 10.938-6.6 9.048-6.6 9.048"
        />
        <path
          fill="#A6A1A1"
          fillRule="nonzero"
          d="M216.11 84.677l-42.281 4.031-29.127-21.225 47.814-.768 24.056 16.136a1 1 0 01-.463 1.826z"
        />
        <path
          fill="#24272D"
          fillRule="nonzero"
          d="M121.6233 204.724169L154.9413 204.724169 159.1413 204.679169 155.9283 191.992169 140.8563 193.135169z"
        />
        <path
          fill="#24272D"
          fillRule="nonzero"
          d="M94.2903 142.966169L124.5543 161.761169 128.3973 164.086169 135.8313 142.744169 119.8113 140.899169z"
        />
        <path
          fill="#EA6F57"
          fillRule="nonzero"
          d="M178.149 97.756l57.21 32.38h-73.281v62.885h-31.119v-76.38c0-10.43 9-18.885 20.082-18.885h27.108z"
        />
        <path
          fill="#EA6F57"
          fillRule="nonzero"
          d="M177.054 105.166l30.19 22.335c10.991 8.13 26.28 4.5 32.843-7.824l8.724-16.1-61.455-32.905c-9.36-5.007-20.796-2.316-27.219 6.408l-45.078 61.227 23.4 19.263 38.595-52.404z"
        />
        <path
          fill="#016776"
          fillRule="nonzero"
          d="M303.2733 101.176169L222.5973 101.176169 222.5973 181.852169 303.2733 181.852169z"
        />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeWidth="0.9"
          d="M144.256 99.227c6.134-2.855 20.405-1.977 22.82-1.47 1.61.337 15 10.252 40.167 29.744 0 0 5.332 4.4 14.893 4.4"
        />
        <path stroke="#FFF" strokeWidth="0.9" d="M131.385 116.134v36.36" />
        <path
          fill="#A6A1A1"
          fillRule="nonzero"
          d="M192.546 30.085h-46.11a2.607 2.607 0 00-2.607 2.607v32.871a2.607 2.607 0 002.607 2.607h46.11c1.44 0 2.607-.015 2.607-1.455V32.692a2.607 2.607 0 00-2.607-2.607z"
        />
        <path
          stroke="#FFF"
          strokeLinecap="square"
          strokeWidth="0.9"
          d="M249.552 102.076v57.786M236.208 118.837a4.8 4.8 0 004.8-4.8 4.8 4.8 0 00-4.8-4.8 4.8 4.8 0 00-4.8 4.8 4.8 4.8 0 004.8 4.8zM241.008 101.224h62.268"
        />
      </g>
    </svg>
  );
}
