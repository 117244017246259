import {
  createClient,
  // dedupExchange,
  cacheExchange,
  fetchExchange,
} from 'urql';
import { pipe, tap } from 'wonka';
import HttpStatusCodes from 'http-status-codes';
import * as Sentry from '@sentry/browser';
import { fetch as getLocalStorageValue } from 'GrooveLocalStorage';
import { store } from 'Src/index';
import logout from 'ErrorHandling/logout';
import { UNAUTHORIZED_ERROR_HANDLING_EXEMPT_PATHS } from 'Modules/App/constants';
import { getPathName } from 'Utils/history';
import moboStore from 'Modules/App/Store/useStore';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';
import { POST_MESSAGE_OUTLOOK_SOURCE } from 'Modules/ActionCompose/constants';

export const GRAPHQL_ERROR_TYPES = {
  NETWORK: 'Network',
  GRAPHQL: 'GraphQL',
};

const errorExchange =
  ({ forward }) =>
  ops$ => {
    return pipe(
      forward(ops$),
      tap(result => {
        if (result.error) {
          const { error, operation } = result;

          const errorType = error.networkError
            ? GRAPHQL_ERROR_TYPES.NETWORK
            : GRAPHQL_ERROR_TYPES.GRAPHQL;
          const queryName = operation.query.definitions[0].name.value;
          const status = error.response ? error.response.status : 0;

          const locationPathname = getPathName();

          const isNotExemptPath =
            !UNAUTHORIZED_ERROR_HANDLING_EXEMPT_PATHS.includes(
              locationPathname
            );
          const handleUnauthorizedError =
            status === HttpStatusCodes.UNAUTHORIZED && isNotExemptPath;
          if (handleUnauthorizedError) {
            store.runSaga(logout);
            return;
          }

          let errorTitle = '';
          if (errorType === GRAPHQL_ERROR_TYPES.NETWORK) {
            errorTitle = `${queryName} - ${error.message}`;
          } else {
            errorTitle = queryName;
          }

          const errorName = `GQL - ${errorTitle} - ${errorType} ${status}`;
          const formattedGraphQLErrors = error.graphQLErrors.map(
            graphqlError => ({
              extensionCode: graphqlError.extensions.code,
              message: graphqlError.message,
              name: graphqlError.name,
              path: graphqlError.path,
            })
          );

          const isClientIdleError =
            errorType === GRAPHQL_ERROR_TYPES.NETWORK && status === 0;
          if (isClientIdleError) return;

          Sentry.withScope(scope => {
            scope.setTags({
              type: 'Groove GraphQL API Error',
              'http.error.status.code': status,
              queryName,
              errorTitle,
            });
            scope.setExtras({
              graphQLErrors: formattedGraphQLErrors,
              operation,
              networkError: error.networkError,
              response: error.response || null,
            });
            Sentry.captureMessage(errorName);
          });
        }
      })
    );
  };

const getGraphQLClient = () => {
  let authHeaders = { 'X-CSRF-Token': getLocalStorageValue('csrfToken') };

  const febesPage = getPathName()?.split('/')[1];
  const moboUser = moboStore.getState().moboUser;
  const moboHeader = {
    'X-Mobo-User': moboUser ? moboUser.id : '',
    'X-Febes-Page': febesPage,
  };

  const params = new URLSearchParams(window.location.search);
  if (params.get('source') === POST_MESSAGE_OUTLOOK_SOURCE) {
    authHeaders = {
      'X-Microsoft-Exchange-Identity-Token':
        window.localStorage.getItem('microsoftToken'),
    };
  }
  return createClient({
    url: `${grooveEngineRootUrl}/api/graphql`,
    fetchOptions: {
      headers: {
        ...authHeaders,
        ...moboHeader,
      },
      credentials: 'include',
    },

    exchanges: [
      // dedupExchange,
      cacheExchange,
      errorExchange,
      fetchExchange,
    ],
  });
};

export default getGraphQLClient;
