import { call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { OrderedMap } from 'immutable';

import executeGraphQLRequest from 'GrooveHTTPClient/executeGraphQLRequest';
import * as query from 'Modules/Spaces/import/gql/mutations/CreateOpportunities.mutation.gql';

type CreateWorkspacePayload = {
  sfdcOpportunities: OrderedMap<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export default function* createOpportunities({
  sfdcOpportunities,
}: CreateWorkspacePayload): SagaIterator {
  const response = yield call(executeGraphQLRequest, {
    query,
    variables: {
      sfdcOpportunities: sfdcOpportunities.valueSeq().toJS(),
    },
  });

  const error = response.get('error');
  if (error) {
    throw error;
  }

  const opportunities = response.getIn([
    'data',
    'createOpportunities',
    'opportunities',
  ]);

  return opportunities;
}
