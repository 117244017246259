import getClient from './getClient';

export interface Opportunity {
  id: number;
  name: string;
  sfdc_id: string;
  account_id: number;
  person_id: number;
  amount: number;
  is_closed: boolean;
  is_won: boolean;
  stage_name: string;
  probability: number;
  close_date: string;
  owner_name: number;
  person_flow_id: number;
  sfdc_data: { [key: string]: unknown };
  created_at: string;
  updated_at: string;
}

export interface Attributes {
  type: string;
  url: string;
}

export const findOrCreateAccount = (sfdcId: string): Promise<Opportunity> =>
  getClient()
    .post(`related_records/find_or_create_account`, {
      json: { sfdc_id: sfdcId },
    })
    .json();

export const findOrCreateOpportunity = (sfdcId: string): Promise<Opportunity> =>
  getClient()
    .post(`related_records/find_or_create_opportunity`, {
      json: { sfdc_id: sfdcId },
    })
    .json();
