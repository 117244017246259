import queryString from 'querystring';
import { eventChannel } from 'redux-saga';
import {
  actionTypes as appActionTypes,
  triggerAppUpstart,
  ready,
  topNavTabClicked,
} from 'Modules/App/actions';
import {
  finishChange,
  setActiveTab,
  setSearchParams,
} from 'Modules/Shared/actions/location';
import { createRunRootSaga } from 'Modules/Shared/sagas';
import { isEmpty, concat, flatten } from 'lodash-es';
import {
  join,
  spawn,
  take,
  put,
  select,
  race,
  call,
  fork,
  cancel,
} from 'redux-saga/effects';
import { isLoggedIn as isLoggedInSelector } from 'Modules/Shared/selectors/users';
import { history, getPathName } from 'Utils/history';
import { join as pathJoin } from 'path';
import upstartSagaErrorHandler from 'ErrorHandling/upstartSagaErrorHandler';
import { LoadingChunkError } from 'Routing/errors';
import { logError } from 'Modules/Shared/actions/errors';
import { ERROR_TYPES } from 'Modules/Shared/constants';
import { fromJS } from 'immutable';
import { routeMatcher } from 'Routing/routesSaga';
import Loader from 'Modules/Shared/components/RouteLoader';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

import { store } from '../../index';
import { isFEBESReviewApp } from 'Utils/reviewAppUtils';

export default class Route {
  constructor(options) {
    const {
      pattern,
      loader,
      moduleRoot,
      upstartSaga,
      teardownSaga = null,
      rootSaga,
      upstartSagaErrorHandler = null,
      rootSagaErrorHandler = null,
      routeName,
      requireAuth = true,
      hideLayout = false,
      cascade = true,
      tabs = [],
      children = [],
      parent = null,
      environments = ['development', 'production'],
      actionPrefix = null,
      loadingComponent = Loader,
      disableModuleRootTransition = false,
    } = options;

    if (!pattern) {
      throw new Error('Route must specify a pattern');
    }

    if (!routeName) {
      throw new Error('Route must specify a routeName');
    }

    this.pattern = pattern;
    if (loader) {
      this.loader = async () => {
        this.loading = true;
        let module;
        try {
          module = await loader();
        } catch (e) {
          console.error(e);
          throw new LoadingChunkError(e.message);
        }
        this.loading = false;
        return module;
      };
    }
    this.moduleRoot = moduleRoot;
    this.upstartSaga = upstartSaga;
    this.teardownSaga = teardownSaga;
    this.upstartSagaErrorHandler = upstartSagaErrorHandler;
    this.rootSagaErrorHandler = rootSagaErrorHandler;
    this.rootSaga = rootSaga;
    this.routeName = routeName;
    this.requireAuth = requireAuth;
    this.hideLayout = hideLayout;
    this.cascade = cascade;
    this.tabs = tabs;
    this.children = children;
    this.parent = parent;
    this.environments = environments;
    this.actionPrefix = actionPrefix;
    this.loadingComponent = loadingComponent;
    this.disableModuleRootTransition = disableModuleRootTransition;

    this.children.forEach(child => child.setParent(this));
  }

  static _appReady = false;

  static _currentRootSaga = null;

  static listenForPopState = function* listenForPopState() {
    const channel = eventChannel(emitter => {
      const listener = location => {
        emitter(location);
      };
      window.addEventListener('popstate', listener);

      return () => window.removeEventListener('popstate', listener);
    });

    while (true) {
      yield take(channel);
      const searchParams = queryString.parse(
        new URLSearchParams(window.location.search).toString()
      );
      yield put(setSearchParams({ searchParams: fromJS(searchParams) }));

      const { activeTab } = searchParams;

      yield put(setActiveTab({ activeTab, skipSettingQueryParameter: true }));
      yield put(topNavTabClicked(getPathName(), activeTab));
    }
  };

  static waitForAppReady = function* waitForAppReady() {
    if (!Route._appReady) {
      yield put(triggerAppUpstart());

      const { upstartSagaCompleted } = yield race({
        upstartSagaCompleted: take(appActionTypes.UPSTART.SUCCESS),
        upstartSagaAborted: take(appActionTypes.UPSTART.FAILURE),
      });

      if (upstartSagaCompleted) {
        Route._appReady = true;
      }

      yield spawn(Route.listenForPopState);
    }
  };

  // Build the in-order flat list of routes for redux-saga-router. We respect the tree structure
  // top-to-bottom when building the composite upstart saga.
  static buildRouteSubtree = (route, basePath = '') => {
    route.prependPath(basePath);

    let routes = [route];

    if (!isEmpty(route.children)) {
      route.children.forEach(childRoute => {
        routes = concat(
          routes,
          Route.buildRouteSubtree(childRoute, route.pattern)
        );
      });
    }

    return routes;
  };

  static assembleRoutes = routeConfiguration => {
    // filter out routes that should be not be available in the current environment
    // and build the route subtree for routes we want to be available.
    const env = process.env.NODE_ENV;
    const environmentalizedRoutes = routeConfiguration.reduce(
      (array, route) => {
        if (!route.environments.includes(env)) {
          return array;
        }

        array.push(Route.buildRouteSubtree(route));
        return array;
      },
      []
    );

    // Flatten the route configuration into the canonical route list
    return flatten(environmentalizedRoutes);
  };

  static hydrateRouteWithModule = async route => {
    if (route.loader) {
      const module = await route.loader();

      route.moduleRoot = module.ModuleRoot;
      route.upstartSaga = module.sagas.upstart;
      route.teardownSaga = module.sagas.teardown;
      route.upstartSagaErrorHandler = module.sagas.upstartErrorHandler;
      route.rootSagaErrorHandler = module.sagas.rootErrorHandler;
      route.rootSaga = module.sagas.default;

      if (module.reducer) {
        store.addAsyncReducer(route.routeName, module.reducer);
      }
    }
  };

  /**
   * Return the root node. Returns self if it is the root node.
   *
   * @return {Route}
   * */
  getRootNode() {
    if (!this.parent) return this;
    let node = this;
    do {
      node = node.parent;
    } while (node.parent);
    return node;
  }

  /**
   * Return true if the route subtree changed between two routes.
   *
   * @param {Route} route1
   * @param {Route} route2
   *
   * @return {boolean}
   * */
  static moduleChanged = (route1, route2) => {
    const rootNode1 = route1.getRootNode();
    const rootNode2 = route2.getRootNode();
    return rootNode1 !== rootNode2;
  };

  setParent(parent) {
    this.parent = parent;
  }

  prependPath(path) {
    this.pattern = pathJoin(path, this.pattern);
  }

  *runFaultTolerantRootSaga() {
    const runRootSaga = createRunRootSaga(this.rootSaga, this.teardownSaga);
    const { success } = yield call(runRootSaga);
    if (!success) {
      if (Route._currentRootSaga && Route._currentRootSaga.isRunning()) {
        // this sometimes prints a weird 'Generator is already running' message to the console
        // it doesn't throw, so it doesn't really affect us. https://github.com/redux-saga/redux-saga/issues/703
        Route._currentRootSaga.cancel();
      }
      Route._currentRootSaga = yield fork(
        this.runFaultTolerantRootSaga.bind(this)
      );
    }
  }

  // Recursively builds the handler saga if nested route and parents are configured to cascade
  // to children
  buildRouteHandler() {
    const self = this;

    return function* routeHandler() {
      // If a root saga was fun for the current module, we want to cancel it.
      // This prevents us from running root sagas multiple times and creating
      // too many action watchers.
      if (Route._currentRootSaga && Route._currentRootSaga.isRunning()) {
        yield cancel(Route._currentRootSaga);
        Route._currentRootSaga = null;
      }

      // Yield the root saga, which handles everything that upstart does not.
      if (self.rootSaga) {
        Route._currentRootSaga = yield fork(
          self.runFaultTolerantRootSaga.bind(self)
        );
      }

      // Notify that route resolution has completed, which means that all upstart sagas have finished
      // execution. This allows the mounting of the next ModuleRoot component per the matched Route
      // in App.ModuleRoot#renderActiveModule
      yield put(finishChange());
    };
  }

  buildBeforeRouteChangeHandler(previousRoute, nextRoute) {
    const routeRequiresAuth = this.requireAuth;
    const self = this;

    return function* beforeRouteChangeHandler() {
      if (nextRoute.pattern === '/setup') {
        yield call(
          window.location.assign.bind(window.location),
          `${grooveEngineRootUrl}/setup`
        );
      }

      let isLoggedIn;

      if (
        ![
          '/error',
          '/login/callback',
          '/refresh_salesforce_meta',
          '/redirect',
        ].includes(nextRoute.pattern)
      ) {
        // Don't perform initial route change until the application is ready.
        yield call(Route.waitForAppReady);

        isLoggedIn = yield select(isLoggedInSelector);

        if (isLoggedIn && !Route._appReady) {
          return;
        }
      }

      // if a route change happened in the app upstart, early exit here so
      //  we don't run the current route's upstart
      if (routeMatcher.find(getPathName()).pattern !== nextRoute.pattern) {
        return;
      }

      if (routeRequiresAuth && !isLoggedIn) {
        let queryString = '';
        if (isFEBESReviewApp()) {
          queryString = `?febes_url=${process.env.PUBLIC_URL}`;
        }

        yield call(
          window.location.assign.bind(window.location),
          `${grooveEngineRootUrl}${queryString}`
        );
        yield put(ready());
        return;
      } else if (!routeRequiresAuth) {
        yield put(ready());
      }

      try {
        yield call(Route.hydrateRouteWithModule, self);
      } catch (error) {
        if (error instanceof LoadingChunkError) {
          yield put(logError({ error, errorType: ERROR_TYPES.NETWORK_ERROR }));
        } else {
          yield put(logError({ error, errorType: ERROR_TYPES.GENERIC }));
        }
        return;
      }

      const channel = eventChannel(emitter =>
        history.listen((location, action) => {
          if (action === 'POP' && nextRoute.pattern === location.pathname)
            return;

          emitter(location);
        })
      );

      const callUpstartSaga = function* doCallUpstartSaga() {
        try {
          if (self.upstartSaga) {
            yield call(self.upstartSaga);
          }
        } catch (e) {
          yield* upstartSagaErrorHandler(e);
        }
      };

      const task = yield fork(callUpstartSaga);

      const { routeChange } = yield race({
        _: join(task),
        routeChange: take(channel),
      });

      if (routeChange) {
        yield cancel(task);
      }

      channel.close();
    };
  }
}
