import React from 'react';
import positiveEffectArrow from 'Modules/ActionsRewrite/components/customCells/SmartPriority/HoverCard/displayTopFactors/positiveEffectArrow.svg';
import negativeEffectArrow from 'Modules/ActionsRewrite/components/customCells/SmartPriority/HoverCard/displayTopFactors/negativeEffectArrow.svg';
import { FACTOR_EFFECTS } from 'Modules/ActionsRewrite/components/customCells/SmartPriority/constants';
import { FactorEffect } from 'Modules/ActionsRewrite/components/customCells/SmartPriority/HoverCard/useGetFactors';

const FactorArrow = ({ effect }: { effect: FactorEffect }) => {
  if (FACTOR_EFFECTS.POSITIVE.test(effect))
    return <img src={positiveEffectArrow} alt="positive effect" />;
  if (FACTOR_EFFECTS.NEGATIVE.test(effect))
    return <img src={negativeEffectArrow} alt="negative effect" />;

  return null;
};

export default FactorArrow;
