import cx from 'classnames';
import { Icon, IconButton, TooltipHost } from '@fluentui/react';
import { Template, templateGet } from '@groove/api/gateway/v1/template';
import StyledSeparator from '@groove/ui/Components/StyledSeparator';
import TruncatedTextWithTooltip from '@groove/ui/Components/TruncatedTextWithTooltip';
import Button from '@groove/ui/Components/Button';
import { HTMLProps, useEffect } from 'react';
import { useQuery } from 'react-query';
import sanitizeHtml from 'sanitize-html';

import TemplateStarrer from '../TemplateStarrer';
import useTemplatesStore from '../store/useStore';
import { ReactComponent as EditTemplateIcon } from '../icons/edit_template.svg';
import { SingleTemplateShimmer } from '../TemplateLoadingShimmers';
import { REACT_QUERY_CACHE_STALE_TIME } from '../constants';
import {
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  TEMPLATES_VIEW,
  TEMPLATE_EDITOR_CLOSED_VIA,
  trackTemplatesEvent,
} from '../analytics/templatesEvents';

type TemplatePreviewPaneProps = {
  onEdit?: () => void;
  template: Template;
  onInsert: () => void;
  onReplace?: () => void;
  onDismiss: () => void;
  className?: HTMLProps<HTMLElement>['className'];
  setIsEditorOpen?: (open: boolean) => void;
};

const TemplatePreviewPane: React.FC<TemplatePreviewPaneProps> = ({
  onEdit,
  template: templateProp,
  onInsert,
  onReplace,
  onDismiss,
  className,
  setIsEditorOpen,
}) => {
  // The template passed from the parent component does not include its body anymore. Hence, we need to fetch this individual template.
  const { data: templateData, isFetching } = useQuery(
    ['templateGet', templateProp?.id],
    () => templateGet(templateProp.id),
    REACT_QUERY_CACHE_STALE_TIME,
  );

  const selectedTemplatePanelTab = useTemplatesStore(
    store => store.selectedTemplatePanelTab,
  );

  const template = templateData?.data || templateProp;
  const favorites = useTemplatesStore(store => store.favoriteTemplates);
  const isFavorite = favorites?.some(favorite => favorite.id === template.id);

  const allTemplateFolders = useTemplatesStore(store => store.templateFolders);
  const currentTemplatesFolder = allTemplateFolders?.data?.find(
    folder => folder.id === template.folder_id,
  );
  const isEditable = currentTemplatesFolder?.is_editable;
  const setSelectedTemplate = useTemplatesStore(
    store => store.setSelectedTemplate,
  );

  const bodyContainer = document.createElement('div');
  bodyContainer.innerHTML = template.body || '';
  const sanitizeHtmlBody = sanitizeHtml(bodyContainer.innerHTML);
  useEffect(() => {
    if (templateData?.data) {
      setSelectedTemplate(templateData.data);
    }
  }, [templateData, setSelectedTemplate]);

  return (
    <div
      className={cx(
        'flex flex-col border-solid border-0 border-l-[1px] border-gray6 flex-1 min-h-0',
        className,
      )}
    >
      {isFetching ? (
        <SingleTemplateShimmer />
      ) : (
        <>
          <div
            id="templates-preview-title"
            className="flex items-center justify-between px-3 py-2"
          >
            <TruncatedTextWithTooltip
              text={template.name}
              textClasses="font-semibold text-[13px]"
            />
            <Icon
              onClick={() => {
                trackTemplatesEvent({
                  eventName: TEMPLATES_EVENT_TYPE.PREVIEW_CLOSED,
                  templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.PANEL,
                  closedVia: TEMPLATE_EDITOR_CLOSED_VIA.X_BUTTON,
                });
                onDismiss();
              }}
              iconName="Cancel"
              className="font-semibold text-sm cursor-pointer"
            />
          </div>
          <StyledSeparator xMargin={12} />
          <div
            id="templates-preview-subject"
            className="flex items-center justify-between px-3 py-2"
          >
            <TruncatedTextWithTooltip
              text={template.subject}
              textClasses="text-[13px]"
            />
            <div className="flex items-center">
              <TemplateStarrer
                isFavorite={isFavorite}
                templateId={template.id}
                isPreviewView
                size="medium"
              />
              <TooltipHost
                content={
                  isEditable
                    ? 'Edit Template'
                    : "You don't have permissions to edit this template"
                }
              >
                <IconButton
                  ariaLabel="edit-template-preview"
                  onClick={() => {
                    setIsEditorOpen?.(true);
                    onEdit?.();
                    trackTemplatesEvent({
                      eventName: TEMPLATES_EVENT_TYPE.EDIT_MODAL_OPENED,
                      templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.PANEL,
                      templateTab: selectedTemplatePanelTab,
                    });
                  }}
                  className="disabled:bg-white"
                  disabled={!isEditable}
                >
                  <EditTemplateIcon
                    className={
                      isEditable ? 'text-clari-blue/600' : 'text-gray3'
                    }
                  />
                </IconButton>
              </TooltipHost>
            </div>
          </div>
          <StyledSeparator xMargin={12} />
          <div
            id="templates-preview-body"
            className="flex flex-col flex-grow p-3 overflow-auto font-groove text-body-sm"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: sanitizeHtmlBody || '' }}
          />
          <div
            id="templates-preview-footer"
            className="flex justify-end border-0 border-solid border-t-[1px] border-gray6"
          >
            <Button
              onClick={() => {
                trackTemplatesEvent({
                  eventName: TEMPLATES_EVENT_TYPE.PREVIEW_CLOSED,
                  templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.PANEL,
                  closedVia: TEMPLATE_EDITOR_CLOSED_VIA.CLOSE_BUTTON,
                });
                onDismiss();
              }}
              className="my-2 mr-2"
              variant="secondary"
            >
              Close
            </Button>
            {onReplace && (
              <Button
                onClick={onReplace}
                className="my-2 mr-2"
                variant="secondary"
              >
                Replace
              </Button>
            )}
            <Button
              onClick={() => {
                onInsert();
                trackTemplatesEvent({
                  eventName: TEMPLATES_EVENT_TYPE.INSERTED,
                  templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.PANEL,
                  templateView: TEMPLATES_VIEW.PREVIEW,
                });
              }}
              className="my-2 mr-2"
              variant="secondary"
            >
              Insert
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default TemplatePreviewPane;
