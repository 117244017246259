import { FC, useMemo } from 'react';
import MessageBar from '@groove/ui/Components/MessageBar';

import useStore from '../store/useStore';

/**
 * Moved out MessageBar as an optimization to prevent reloading of the whole react stack for changing messages
 * @returns MessageBar
 */

const MessageBarContainer: FC = () => {
  const messages = useStore(store => store.messages);
  // This is cheaper than using JSON.stringify
  const messageId = messages.reduce((acc, message) => acc + message.id, '');
  const messageText = messages.reduce(
    (acc, message) => acc + message.message,
    '',
  );

  return useMemo(
    () => <MessageBar messages={messages} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageText, messageId],
  );
};

export default MessageBarContainer;
