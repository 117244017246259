import { decamelizeKeys } from 'humps';

import { User } from '../../services/v1/user';
import { Label } from '../../services/v1/labels';

import getClient from './getClient';

export type SharedType = 'SharedWithMe' | 'AllFlows' | 'MyFlows';

export type FlowSortType =
  | 'name.asc'
  | 'created_at.asc'
  | 'name.desc'
  | 'created_at.desc'
  | 'person_flows_created_at.desc';

export type FlowsFetchParams = {
  peopleIds?: number[];
  flowAvailableByPeopleIds?: number[];
  userId?: number | string;
  searchTerm?: string;
  shared?: SharedType;
  includeSteps?: boolean;
  includePersonFlows?: boolean;
  isFlowActive?: boolean;
  notCompleted?: boolean;
  sortBy?: string;
  labelIds?: string[];
};

export type PersonStep = {
  replied: boolean;
  is_due: boolean;
  is_current: boolean;
};

export type Step = {
  id: string;
  name: string;
  step_type: string;
  day_number: number;
  step_index: number;
  person_steps: PersonStep[];
};

export type PersonFlow = {
  id: string;
  current_day: number;
  finished_at: string | null;
  disabled_at: string | null;
  created_at: string;
  paused_until: string | null;
  user: User;
  user_owns_person_flow: boolean;
};

export type Flow = {
  id: number;
  name: string;
  is_master_flow: boolean;
  description: string | null;
  created_at: string;
  archived_at: string | null;
  deleted_at: string | null;
  user: User;
  step_count: number;
  steps: Step[];
  person_flows: PersonFlow[];
  labels?: Label[];
  flow_collection_id: number | null;
};

export type PauseUntilParams = {
  flowId: number;
  pausedDate: string;
  peopleIds: number[];
};

export type removeFromFlowParams = {
  flowId: number;
  peopleIds: number[];
};

export type SkipPersonStepParams = {
  actionId: string | number;
  skip_type?: 'THIS_STEP' | 'ALL_CALL_STEPS';
};

export type AddPeopleToFlowsParams = {
  flowId: string | number;
  /** Need either peopleId or sfdcIds */
  peopleIds?: (string | number)[];
  /** Need either peopleId or sfdcIds */
  sfdcIds?: string[];
};

export type ListFlowsParams = {
  page: number;
  per_page: number;
  'order[field]'?: string;
  'order[direction]'?: 'ASC' | 'DESC';
  'filter[status][]'?: string;
  'filter[search[]][query]'?: string;
  'filter[search[]][field]'?: 'NAME' | 'DESCRIPTION';
  access_scope?: 'write';
};

export const flowsFetch = (
  filter: FlowsFetchParams,
  page = 0,
  perPage = 100,
): Promise<Flow[]> => {
  let notSharedByUser = null;
  let sharedByUser = null;
  const search = [
    {
      field: 'NAME',
      query: filter.searchTerm,
    },
  ];
  const order = {
    field: filter.sortBy?.split('.')[0],
    direction: filter.sortBy?.split('.')[1],
  };

  if (filter.shared === 'MyFlows') sharedByUser = filter.userId;
  else if (filter.shared === 'SharedWithMe') notSharedByUser = filter.userId;

  return getClient()
    .post('flows/fetch', {
      json: {
        variables: {
          filter: {
            people_ids: filter.peopleIds,
            flow_available_by_people_ids: filter.flowAvailableByPeopleIds,
            not_shared_by_user: notSharedByUser,
            shared_by_user: sharedByUser,
            search,
            is_flow_active: filter.isFlowActive,
            not_completed: filter.notCompleted,
            labels: filter.labelIds,
          },
          include_steps: filter.includeSteps,
          include_person_flows: filter.includePersonFlows,
          page,
          per_page: perPage,
          order,
        },
      },
    })
    .json();
};

export const flowsPauseUntil = (params: PauseUntilParams): Promise<void> =>
  getClient()
    .post('flows/pause_until', {
      json: decamelizeKeys(params),
    })
    .json();

export const removeFromFlow = (params: removeFromFlowParams): Promise<void> =>
  getClient()
    .post('flows/remove_people', {
      json: decamelizeKeys(params),
    })
    .json();

export const skipPersonStep = (params: SkipPersonStepParams): Promise<void> =>
  getClient()
    .post('flows/skip_person_step', {
      json: decamelizeKeys(params),
    })
    .json();

export const addPeopleToFlow = (
  params: AddPeopleToFlowsParams,
): Promise<void> =>
  getClient()
    .post('flows/import_people', {
      json: decamelizeKeys(params),
    })
    .json();

export const listFlows = (params: ListFlowsParams): Promise<Flow[]> =>
  getClient().get('flows', { searchParams: params }).json();
