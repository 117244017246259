import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
    padding: 50,
    width: 400,
  },
  textContainer: {
    textAlign: 'center',
  },
  subFont: {
    color: '#757575',
    fontSize: '0.875rem',
  },
});

const WaitingForUser: FC = () => {
  const { container, textContainer, subFont } = useStyles();
  return (
    <div className={container}>
      <div className={textContainer}>
        <Typography>Please sign in to Seismic from the new window.</Typography>
        <Typography classes={{ root: subFont }}>
          You will be routed back to this screen once complete.
        </Typography>
      </div>
    </div>
  );
};

export default WaitingForUser;
