import makeHTTPRequest from 'GrooveHTTPClient/makeHTTPRequest';
import { POST } from 'GrooveHTTPClient/HTTPMethod';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

const BASE_PATH = '/api/v2/session';

export const disconnectSalesforce = () =>
  makeHTTPRequest({
    path: `${BASE_PATH}/disconnect_salesforce`,
    method: POST,
  });

export const createFirebaseJwtToken = () =>
  makeHTTPRequest({
    path: `${BASE_PATH}/firebase_jwt_token`,
    method: POST,
  });

// This ensures we reset a user's session
// The "redirect: manual" and "credentials: include" properties were added to
// 1. Ensure that redirects are not followed for /signout
// 2. Ensure that the session cookie is passed to the /signout endpoint
export const signout = () =>
  fetch(`${grooveEngineRootUrl}/signout`, {
    redirect: 'manual',
    credentials: 'include',
  });
