import { QueryClient } from 'react-query';

import { UpdateExistingAction } from '../types';
import { fetchGrooveMeta } from '../hooks/useGrooveMeta';

import { syncUnknownWhoData, syncWhoData } from './syncWhoData';

export type ActionHandlerParams = {
  action: UpdateExistingAction;
  client: QueryClient;
};

const updateExistingActionHandler = async ({
  action,
  client,
}: ActionHandlerParams): Promise<void> => {
  try {
    if (action.personSfdcId && action.message === 'RECORD_UPDATED') {
      const grooveMeta = await fetchGrooveMeta({ client });
      syncWhoData([action.personSfdcId], grooveMeta);
    } else if (
      action.personSfdcId &&
      action.personEmail &&
      action.message === 'RECORD_CREATED'
    ) {
      const grooveMeta = await fetchGrooveMeta({ client });
      syncUnknownWhoData(action.personEmail, action.personSfdcId, grooveMeta);
    }
  } catch (error) {
    // TODO: make error handler
    console.error('updateExistingActionHandler has errored:', error);
  }
};

export default updateExistingActionHandler;
