import { createActions } from '@groove-labs/action-utils';
import { FSA } from 'flux-standard-action';

const types = {
  SHARED: {
    PRODUCT_USAGE: {
      SET_PRODUCT_USAGE: 'SET_PRODUCT_USAGE',
    },
  },
};

export const actionTypes =
  createActions<typeof types>(types).SHARED.PRODUCT_USAGE;
interface Usage {
  quota: number;
  used: number;
  resetDate?: string;
  resetInterval?: string;
}

interface DialerProductUsage {
  minutes: Usage;
}
interface FlowProductUsage {
  actions: Usage;
}
interface ProductUsage {
  dialer: DialerProductUsage;
  flow: FlowProductUsage;
}

export function setProductUsage(data: ProductUsage): FSA<string, ProductUsage> {
  return {
    type: actionTypes.SET_PRODUCT_USAGE,
    payload: data,
  };
}
