import React from 'react';

import PropTypes from 'prop-types';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import { withStyles } from '@material-ui/core/styles';
import { greyContent, pulse, shimmer } from 'Modules/Shared/styles/shimmerBar';

const styles = theme => ({
  root: {
    height: 56,
    borderBottom: `1px solid ${theme.palette.text.borders}`,
    alignItems: 'center',
    display: 'flex',
  },
  loadingType: {
    ...greyContent,
    ...pulse,
    width: 40,
    display: 'flex',
    margin: 16,
  },
  loadingContent: {
    ...greyContent,
    ...pulse,
    flex: 1,
    margin: '0px 30px 0px 16px',
  },
  ...shimmer,
  playButtonContainer: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: theme.palette.text.secondary,
  },
});

const LoaderRow = ({ classes }) => {
  return (
    <div className={classes.root}>
      <div className={classes.loadingType} />
      <div className={classes.playButtonContainer}>
        <PlayCircleFilled className={classes.icon} />
      </div>
      <div className={classes.loadingContent} />
    </div>
  );
};

LoaderRow.displayName = 'LoaderRow';
LoaderRow.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(LoaderRow);
