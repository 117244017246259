import { FC, useEffect } from 'react';
import { SearchBox } from '@fluentui/react';
import { useDebouncedCallback } from 'use-debounce';

import useStore from './store/useStore';
import {
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  trackTemplatesEvent,
} from './analytics/templatesEvents';

type TemplateSearchHeaderProps = {
  initialSearchTerm?: string;
};

const TemplateSearchHeader: FC<TemplateSearchHeaderProps> = ({
  initialSearchTerm,
}) => {
  const setSearchTerm = useStore(store => store.setSearchTerm);
  const setInitialSearchTerm = useStore(
    store => store.templatesApp.setInitialSearchTerm,
  );
  const resetTemplatesStore = useStore(store => store.resetTemplatesStore);

  const [debouncedSearch] = useDebouncedCallback(value => {
    resetTemplatesStore();
    setSearchTerm(value as string);
    trackTemplatesEvent({
      eventName: TEMPLATES_EVENT_TYPE.SEARCHED,
      templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
    });
  }, 500);

  const handleSearch = (searchValue: string): void => {
    if (searchValue?.length === 0 || searchValue.length > 2) {
      debouncedSearch(searchValue);
    }
  };

  useEffect(() => {
    if (initialSearchTerm) {
      setSearchTerm(initialSearchTerm);
    }
  }, [initialSearchTerm, setSearchTerm]);

  return (
    <SearchBox
      defaultValue={initialSearchTerm}
      ariaLabel="Search Template Folders"
      className="flex-grow rounded after:border-clari-blue/600 after:rounded border-neutral/200 font-groove"
      placeholder="Search for template by name or subject"
      onChange={(_, value) => {
        handleSearch(value as string);
      }}
      onClear={() => {
        setInitialSearchTerm('');
        handleSearch('');
      }}
      clearButtonProps={{ ariaLabel: 'Clear Search' }}
    />
  );
};

export default TemplateSearchHeader;
