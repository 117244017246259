import { trackEvent } from 'Utils/segment';
import { ANALYTICS_BASE_PROPERTIES } from 'Modules/Shared/sagas/analytics';
import { FEATURE_CATEGORY } from 'Modules/FlowsShow/sagas/analytics';

export function logSkeletonStepsToggled({ toggle }) {
  trackEvent('Skeleton Steps Toggled', {
    ...ANALYTICS_BASE_PROPERTIES,
    featureCategory: FEATURE_CATEGORY,
    actionType: toggle,
  });
}
