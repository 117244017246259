import { useState, useEffect } from 'react';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';

import useStore from './useStore';

/**
 * subscribe to the ldClient change event for a specific key
 * update the flag value when it changes
 *
 * because this is a subscription to launch darkly,
 * the component will automatically re-render when the
 * variation changes on their end.
 */
const useVariation = (key: string): boolean => {
  const [flag, setFlag] = useState(false);
  const ldClient = useStore(state => state.ldClient);

  const handleChange = (newValue: LDFlagValue): void => setFlag(newValue);

  useEffect(() => {
    if (ldClient) {
      setFlag(ldClient.variation(key));
      ldClient.on(`change:${key}`, handleChange);
    }
    return (): void => {
      if (ldClient) {
        ldClient.off('change', handleChange);
      }
    };
  }, [key, ldClient]);

  return flag;
};

export default useVariation;
