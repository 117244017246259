import trackEvent, { Properties } from './trackEvent';

export enum CopilotEventType {
  ACTION = 'Actions',
  OMNIBAR = 'Omnibar',
}

// duplicated from libs/omnibar/src/lib/Activity/Activity.tsx
// to avoid circular import
export type ActivityType =
  | 'Event'
  | 'Call'
  | 'Email'
  | 'Meeting'
  | 'SMS'
  | null;

export enum CopilotOmnibarEvent {
  RECORDING_OPENED = 'Recording Opened',
  ACTION_ITEM_CREATED = 'Action Item Created',
  MORE_DROPDOWN_OPENED = 'More Dropdown Opened',
  MEETING_TAB_SWITCHED = 'Meeting Tab Switched',
  FOLLOW_UP_GENERATED = 'Follow-up Generated',
  COPY_BUTTON_CLICKED = 'Copy Button Clicked',
}

export enum CopilotActionEvent {
  GENERATE_AND_REPLACE_CLICKED = 'Generate and Replace Clicked',
  EVENT_DROPDOWN_OPENED = 'Event Dropdown Opened',
  NEW_MEETING_SELECTED = 'New Meeting Selected',
  TONE_SWITCHED = 'Tone Switched',
}

export type TrackCopilotProps = (
  | {
      type: CopilotEventType.OMNIBAR;
      event: CopilotOmnibarEvent;
    }
  | {
      type: CopilotEventType.ACTION;
      event: CopilotActionEvent;
    }
) & {
  activityType: ActivityType;
  props?: Properties;
};

const getRecordingSource = (activityType: ActivityType): string | null => {
  switch (activityType) {
    case 'Event':
      return 'calendar_event';
    case 'Call':
      return 'dialer';
    default:
      return activityType;
  }
};

export const trackCopilot = (args: TrackCopilotProps): void => {
  const { type, event, activityType, props } = args;
  trackEvent(`${type}: Copilot: ${event}`, {
    ...props,
    app: 'omnibar',
    app_feature: 'copilot',
    transaction_type: 'user',
    'recording-source': getRecordingSource(activityType),
  });
};
