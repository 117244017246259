import humps from 'humps';
import makeHTTPRequest from '../makeHTTPRequest';
import { POST, GET } from '../HTTPMethod';

const BASE_PATH = '/api/v2/people';

export const index = query =>
  makeHTTPRequest({
    path: BASE_PATH,
    method: POST,
    body: query,
  });

export const globalPeopleSearch = ({
  filters,
  orderBy,
  orderDir,
  offset,
  limit,
  scope,
  omitTotal,
}) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/global_search`,
    method: GET,
    queryParameters: humps.decamelizeKeys({
      filters,
      orderBy,
      orderDir,
      offset,
      limit,
      scope,
      omitTotal,
    }),
  });

export const peopleInAccount = queryParameters =>
  makeHTTPRequest({
    path: `${BASE_PATH}/people_in_account`,
    queryParameters,
  });

export const peopleSearch = query =>
  makeHTTPRequest({
    path: `${BASE_PATH}/people_search`,
    method: POST,
    body: query,
  });

export const saveSfdc = (personId, payload) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${personId}/save_sfdc`,
    method: POST,
    body: payload,
  });

export const hotlist = ({ hotlisted, email }) => {
  const pathPrefix = (hotlisted && 'un') || '';
  const path = `${BASE_PATH}/${pathPrefix}hotlist`;
  return makeHTTPRequest({
    path,
    method: POST,
    body: {
      email,
    },
  });
};

export const permanentlyErase = peopleIds =>
  makeHTTPRequest({
    path: `${BASE_PATH}/permanently_erase`,
    method: POST,
    body: {
      people_ids: peopleIds,
    },
  });

export const fetchInAnotherFlowData = peopleIds =>
  makeHTTPRequest({
    path: `${BASE_PATH}/in_another_flow`,
    method: GET,
    queryParameters: humps.decamelizeKeys({
      peopleIds,
    }),
  });
