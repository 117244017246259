import { trackEvent } from 'Utils/segment';
import { takeEvery } from 'redux-saga/effects';
import {
  createSafeActionHandler,
  ANALYTICS_BASE_PROPERTIES,
} from 'Modules/Shared/sagas/analytics';
import { actionTypes } from 'Modules/FlowsShow/actions/variants';

const featureCategory = 'A/B Testing';

const abActionTypes = {
  variantCreated: 'A/B Variant Added',
  variantDeleted: 'A/B Variant Deleted',
};

function* variantActionTaken() {
  yield takeEvery(
    actionTypes.TRACK_VARIANT_ACTION,
    createSafeActionHandler(({ payload }) => {
      const { actionName } = payload;
      trackEvent(abActionTypes[actionName], {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory,
      });
    })
  );
}

export default variantActionTaken;
