import { buffers, eventChannel, END } from 'redux-saga';
import * as GrooveLocalStorage from 'GrooveLocalStorage';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

export default (endpoint, file) => {
  return eventChannel(emitter => {
    const xhr = new XMLHttpRequest();
    const onProgress = e => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };
    const onFailure = e => {
      emitter({
        err: new Error('Upload failed', e),
        response: JSON.parse(xhr.responseText),
      });
      emitter(END);
    };
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.withCredentials = true;
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === 4) {
        if (status >= 200 && status < 300) {
          emitter({
            response: JSON.parse(xhr.responseText),
            success: true,
          });
          emitter(END);
        } else {
          onFailure(null);
        }
      }
    };
    const grooveEngineRoot = grooveEngineRootUrl || '';
    const fullPath = `${grooveEngineRoot}${endpoint}`;
    xhr.open('POST', fullPath, true);
    const csrfToken = GrooveLocalStorage.fetch('csrfToken');
    xhr.setRequestHeader('X-CSRF-Token', csrfToken);
    const formData = new FormData();
    formData.append('file', file);
    xhr.send(formData);

    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
};
