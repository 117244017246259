// TODO move get18DigitId from 'Modules/PeopleImportDialog/utils';

export const get15DigitId = id => {
  if (!id) return null;
  if (typeof id !== 'string') throw new Error("The given id isn't a string");
  if (id.length === 15) return id;
  if (id.length !== 18)
    throw new Error(`${id} is not a valid 18 characters long SFDC id`);

  return id.substring(0, 15);
};

/**
 * Converts short SFDC ID to long SFDC ID.
 * */
export const shortSfdcIdToLong = id => {
  if (id.length !== 15) return id;

  let suffix = '';
  for (let i = 0; i < 3; i += 1) {
    let position = 0;

    for (let j = 0; j < 5; j += 1) {
      const c = id.charAt(i * 5 + j);
      // eslint-disable-next-line no-bitwise
      if (c >= 'A' && c <= 'Z') position += 1 << j;
    }

    if (position <= 25) {
      suffix += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(position);
    } else {
      suffix += '012345'.charAt(position - 26);
    }
  }

  return id + suffix;
};
