import { useQuery } from 'react-query';
import client from 'GrooveHTTPClient/client';

const version = '/api/v2';

/**
 * Takes in an actionId, and makes a reactQuery request via
 * @param {number} actionId
 * @returns a useQuery response
 */
const useGetFactors = (actionId: number) =>
  useQuery(['priorityScores', actionId], () => getFactors(actionId));

export default useGetFactors;

const getFactors = (actionId: number) =>
  client
    .get(`${version}/actions/priorities/${actionId}/factors`)
    .then(response => response?.data as FactorsResponse);

type FactorsResponse = {
  actionsPriorityScoreLastSyncedAt: string;
  factors: Factor[];
};

export type Factor = {
  order?: number;
  effect: FactorEffect;
  text: string;
};

export type FactorEffect = 'positive' | 'negative';
