import { Map, OrderedMap, fromJS } from 'immutable';
import { isUndefined } from 'lodash-es';
import { actionTypes as peopleImportActionTypes } from 'Modules/PeopleImportDialog/actions';
import { DESC } from 'Modules/Shared/components/Table';

import { actionTypes } from './actions';

const initialState = fromJS({
  selectedRows: new OrderedMap(),
  accounts: new OrderedMap(),
  campaigns: new OrderedMap(),
  opportunities: new OrderedMap(),
  customObjects: new OrderedMap(),
  columns: [],
  rows: [],
  hideDuplicatesFromSearchResults: true,
  selectAllWithoutWarnings: true,
  sorting: {
    orderBy: null,
    orderDirection: DESC,
  },
  pagination: {
    page: 1,
    perPage: 100,
  },
  activeSalesforceObject: null,
  engagedFlows: new Map(), // flows engaged with people emails - by emails
  engagedFlowsStatus: new Map(), // status of fetching flows engaged with people emails - by emails
  duplicatePeopleCount: 0,
  duplicateRows: [],
  duplicateRowIds: [],
  importableRows: [],
  hasWarningPresentInRows: false,
});

export default function peopleTable(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.LOAD_DATA: {
      return state.merge(action.payload);
    }

    case peopleImportActionTypes.RESET: {
      return initialState;
    }

    case actionTypes.RESET_TABLE: {
      return initialState;
    }

    case actionTypes.ADD_SELECTED_ROWS: {
      const selectedRowIds = action.payload;
      const newRowsMapSlice = state
        .get('rows')
        .filter(row => selectedRowIds.includes(row.get('id')))
        .reduce((acc, row) => {
          return acc.merge({ [row.get('id')]: row });
        }, new Map());

      return state.mergeIn(['selectedRows'], newRowsMapSlice);
    }

    case actionTypes.SELECT_ALL_ROWS_WITHOUT_WARNING: {
      return state.setIn(
        ['selectedRows'],
        state
          .get('rows')
          .filter(row => row.get('warnings').every(value => value === false))
          .reduce((acc, row) => {
            return acc.merge({ [row.get('id')]: row });
          }, new Map())
      );
    }

    case actionTypes.UNSELECT_ALL_ROWS_WITH_WARNING: {
      const currentSelectedRows = state.get('selectedRows');

      return state.setIn(
        ['selectedRows'],
        currentSelectedRows.filter(row =>
          row.get('warnings').every(value => value === false)
        )
      );
    }

    case actionTypes.UNSELECT_ALL_ROWS: {
      return state
        .setIn(['selectedRows'], new Map())
        .set('selectAllWithoutWarnings', false);
    }

    case actionTypes.REMOVE_SELECTED_ROWS: {
      const unselectedRowIds = action.payload;
      const currentSelectedRows = state.get('selectedRows');

      return state.setIn(
        ['selectedRows'],
        currentSelectedRows.filterNot((_row, id) =>
          unselectedRowIds.includes(id)
        )
      );
    }

    case actionTypes.UPDATE_PAGINATION: {
      const { page, perPage } = action.payload;

      const updates = {};
      if (!isUndefined(page)) updates.page = page;
      if (!isUndefined(perPage)) updates.perPage = perPage;

      return state.mergeIn(['pagination'], updates);
    }

    case actionTypes.UPDATE_SORTING: {
      const { orderBy, orderDirection } = action.payload;

      const updates = {};
      if (!isUndefined(orderBy)) updates.orderBy = orderBy;
      if (!isUndefined(orderDirection)) updates.orderDirection = orderDirection;

      return state.mergeIn(['sorting'], updates);
    }

    case actionTypes.TOGGLE_HIDE_DUPLICATE_SEARCH_RESULTS: {
      const newValue = !state.get('hideDuplicatesFromSearchResults');
      const duplicateRowIds = state.get('duplicateRowIds');
      const rows = state.get('rows');
      let importableRows = rows;
      if (newValue) {
        importableRows = rows.filter(
          row => !duplicateRowIds.includes(row.get('id'))
        );
      }
      return state
        .set('hideDuplicatesFromSearchResults', newValue)
        .set('importableRows', importableRows);
    }

    case actionTypes.TOGGLE_SELECT_ALL_WITHOUT_WARNINGS: {
      const newValue = !state.get('selectAllWithoutWarnings');
      return state.set('selectAllWithoutWarnings', newValue);
    }

    case actionTypes.FETCH_ENGAGED_FLOWS.SUCCESS: {
      const { personSfdcId, flows } = action.payload;
      return state
        .setIn(['engagedFlows', personSfdcId], fromJS(flows))
        .setIn(['engagedFlowsStatus', personSfdcId], {
          isFetching: false,
          success: true,
        });
    }

    case actionTypes.FETCH_ENGAGED_FLOWS.PROGRESS: {
      const { personSfdcId } = action.payload;

      return state.setIn(['engagedFlowsStatus', personSfdcId], {
        isFetching: true,
        success: false,
      });
    }

    case actionTypes.FETCH_ENGAGED_FLOWS.FAILURE: {
      const { personSfdcId, message } = action.payload;

      return state.setIn(['engagedFlowsStatus', personSfdcId], {
        isFetching: false,
        success: false,
        message,
      });
    }

    default:
      return state;
  }
}
