import { FC, useEffect, useRef, useState } from 'react';
import Text from '@groove/ui/Components/Text';
import { useQuery } from 'react-query';
import { debounce } from 'lodash-es';
import MultiSelect, { MultiSelectRef } from '@groove/ui/Components/MultiSelect';
import grooveSearch, {
  grooveContactLeadSearch,
} from '@groove/api/visualforce/grooveSearch';
import { StandardDropDownItem } from '@groove/ui/Components/DropDownItem';
import {
  spinner,
  picklistMessageItem,
} from '@groove/ui/Components/Picklist/picklistUtilComponents';
import transformGrooveEngineResult from '@groove/search-and-select/transformGrooveEngineResults';
import { isContactOrLead } from '@groove/api/helpers/sfdcObjectPrefixes';
import { crmObject } from '@groove/api/gateway/v1/actionCompose';
import useGrooveMessageEvent from '@groove/api/hooks/useGrooveMessageEvent';
import { Dialog } from '@fluentui/react';
import { SearchIcon } from '@groove/ui/Components/BoogieIcon';
import Tooltip from '@groove/ui/Components/Tooltip';
import useSearchAndSelectStore from '@groove/search-and-select/useStore';
import SearchAndSelect from '@groove/search-and-select';
import { deriveNamesAndRelatedTosFromSearchAndSelectSelectedItems } from '@groove/search-and-select/SearchAndSelectMini/utils/deriveActivities';
import { CONTACT_PREFIX, LEAD_PREFIX } from '@groove/api/gateway/v1/salesforce';

import useGrooveMeta from '../hooks/useGrooveMeta';
import useStore from '../store/useStore';
import transformGrooveSearch, {
  getSfdcTypeIcon,
} from '../utils/transformGrooveSearch';
import {
  setSalesforceRelatedTo,
  setAdvanceSearchAndSelect,
  handleWhoSelect,
  handleWhoRemove,
} from '../utils/loggingToMethods';

const modalProps = {
  isBlocking: true,
  styles: {
    root: 'groove',
  },
};
const dialogStyles = {
  main: 'h-[70vh] w-[60vw] max-w-none max-h-none',
};
const dialogContentProps = {
  styles: {
    content: 'h-auto flex flex-col',
    inner: 'flex-grow',
    innerContent: 'h-full',
  },
};

const LoggingTo: FC = () => {
  const whoRef = useRef<MultiSelectRef | null>(null);
  const whatRef = useRef<MultiSelectRef | null>(null);

  const [whoSearch, setWhoSearch] = useState('');
  const [whatSearch, setWhatSearch] = useState('');
  const type = useStore(store => store.action.type);
  const loggingTo = useStore(store => store.action.loggingTo);
  const id = useStore(store => store.action.id);
  const who = useStore(store => store.action.who);
  const personStepId = useStore(store => store.action.personStepId);
  const usedAdvanceSS = useStore(store => store.action.usedAdvanceSS);
  const relatedToRelatedResults = useStore(
    store => store.otherValues.relatedToRelatedResults,
  );
  const loggingToRelatedResults = useStore(
    store => store.otherValues.loggingToRelatedResults,
  );
  const [whoItems, setWhoItems] = useState<StandardDropDownItem[]>([]);
  const [whatItems, setWhatItems] = useState<StandardDropDownItem[]>([]);
  const [isWhoLoading, setIsWhoLoading] = useState(false);
  const [isWhatLoading, setIsWhatLoading] = useState(false);
  const [isAdvanceSearchSelectOpen, setIsAdvanceSearchSelectOpen] =
    useState(false);
  const updateOriginalLoggingTo = useStore(
    store => store.updateOriginalLoggingTo,
  );
  const { data: grooveMeta } = useGrooveMeta();

  let shouldShowAdvanceSearch = usedAdvanceSS;
  let hasContact = false;
  let hasLead = false;

  const selectedWhos: StandardDropDownItem[] =
    loggingTo.who?.map(item => {
      if (item.id.startsWith(LEAD_PREFIX)) hasLead = true;
      if (item.id.startsWith(CONTACT_PREFIX)) hasContact = true;
      return {
        key: item.id,
        text: item.text,
        value: item.id,
        metaData: {
          type: item.type,
          email: item.email || '',
        },
        icon: getSfdcTypeIcon(item.type),
      };
    }) || [];

  const selectedWhats: StandardDropDownItem[] =
    loggingTo.what?.map(item => ({
      key: item.id,
      text: item.text || item?.attributes?.name || '',
      value: item.id,
      metaData: {
        type: item.type,
        email: item.email || '',
      },
      icon: getSfdcTypeIcon(item.type),
    })) || [];

  if ((hasLead && hasContact) || (hasLead && selectedWhats.length < 0))
    shouldShowAdvanceSearch = true;

  useQuery(
    ['action-compose-who-search', whoSearch],
    () => {
      setIsWhoLoading(true);
      return grooveContactLeadSearch(whoSearch);
    },
    {
      enabled: !!whoSearch && whoSearch.length > 2,
      onSettled: () => setIsWhoLoading(false),
      onSuccess: data => {
        const items = transformGrooveEngineResult(data || [])
          .flat?.()
          .map(transformGrooveSearch);
        setWhoItems(items);
      },
      cacheTime: 5 * 60 * 1000, // 5 minutes because we don't want a rerender due to a search that is no longer needed
      staleTime: 5 * 60 * 1000,
    },
  );

  useQuery(
    ['action-compose-what-search', whatSearch],
    () => {
      setIsWhatLoading(true);
      return grooveSearch(whatSearch);
    },
    {
      enabled: !!whatSearch && whatSearch.length > 2,
      onSettled: () => setIsWhatLoading(false),
      onSuccess: data => {
        const items = transformGrooveEngineResult(data || [])
          .flat?.()
          .map(transformGrooveSearch)
          .filter(result => !isContactOrLead(result.value as string));
        setWhatItems(items);
      },
      cacheTime: 5 * 60 * 1000, // 5 minutes because we don't want a rerender due to a search that is no longer needed
      staleTime: 5 * 60 * 1000,
    },
  );

  const externalMetadataRecords = useStore(
    store => store.externalMetadataRecords,
  );

  useEffect(() => {
    if (!externalMetadataRecords?.who) {
      return;
    }
    setWhoItems(
      externalMetadataRecords.who.map(
        item =>
          ({
            key: item.id,
            text: item.text,
            value: item.id,
          } as StandardDropDownItem),
      ),
    );
    if (!externalMetadataRecords?.what) {
      return;
    }
    setWhatItems(
      externalMetadataRecords.what.map(
        item =>
          ({
            key: item.id,
            text: item.text,
            value: item.id,
          } as StandardDropDownItem),
      ),
    );

    const { who, what } = externalMetadataRecords;

    updateOriginalLoggingTo({
      who: who.length > 0 ? [who[0]] : [],
      what: what.length > 0 ? [what[0]] : [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalMetadataRecords]);

  useEffect(() => {
    if (relatedToRelatedResults) {
      setWhatItems(relatedToRelatedResults);
    }
    if (loggingToRelatedResults) {
      setWhoItems(loggingToRelatedResults);
    }
  }, [relatedToRelatedResults, loggingToRelatedResults]);

  const { data: grooveMessageEvent } = useGrooveMessageEvent({
    cacheTime: 1 * 60 * 1000, // 1 minute because the user's settings from salesforce isn't going to change often
    staleTime: 1 * 60 * 1000,
  });

  // This is for flow Actions when we don't have logging to initially
  useEffect(() => {
    const { loggingTo } = useStore.getState().action;
    if (!loggingTo.what && !loggingTo.who && who) {
      setSalesforceRelatedTo([who.email], who.sfdcId, true, true);
      useStore.getState().updateCurrentLoggingTo({
        who: [
          {
            id: who.sfdcId,
            text: who.name,
            type: who.sfdcType,
            autoCreated: true,
          },
        ],
      });
      updateOriginalLoggingTo({
        who: [{ id: who.sfdcId, text: who.name, type: who.sfdcType }],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  useEffect(() => {
    useStore.getState().setOtherValues({ grooveMessageEvent });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(grooveMessageEvent)]);

  const isMultipleRelatedTo =
    (grooveMessageEvent?.bestMatchEmailLogging &&
      grooveMessageEvent.bestMatchAllowMultiSelect) ||
    !grooveMessageEvent?.bestMatchEmailLogging;

  const handleWhoSearch = (term: string): void => {
    setWhoSearch(term);
  };

  const handleWhatSearch = (term: string): void => {
    setWhatSearch(term);
  };

  const handleWhatSelect = async (
    item: StandardDropDownItem | null,
  ): Promise<void> => {
    if (!item) return;
    const { loggingTo } = useStore.getState().action;
    const what: crmObject[] = isMultipleRelatedTo
      ? [...(loggingTo.what || [])]
      : [];
    what.push({
      id: item.value as string,
      text: item.text,
      type: item.metaData?.type || '',
    });
    useStore.getState().updateCurrentLoggingTo({ what });
  };

  const openAdvanceSearchSelect = async (
    searchQuery: string,
    event?: React.MouseEvent | React.KeyboardEvent,
  ): Promise<void> => {
    event?.stopPropagation();
    setAdvanceSearchAndSelect();

    useSearchAndSelectStore.setState({ searchQuery });
    whoRef.current?.closePane();
    whatRef.current?.closePane();
    setIsAdvanceSearchSelectOpen(true);
  };

  // Components to be rendered in Multiselect list header
  let whoHeader: JSX.Element | undefined;
  let whatHeader: JSX.Element | undefined;

  const openAdvanceSearchButton = (searchTerm = ''): JSX.Element => (
    <div
      aria-label="Open Advanced Search"
      role="button"
      className="flex flex-row text-left w-full bg-white items-center overflow-hidden p-0 relative h-[32px] border-0 !border-b border-solid border-neutral/75 cursor-pointer"
      onClick={event => openAdvanceSearchSelect(searchTerm, event)}
      onKeyDown={event =>
        event.key === 'Enter' && openAdvanceSearchSelect(searchTerm, event)
      }
      tabIndex={0}
    >
      <div className="ml-[8px] flex w-[16px] h-full">
        <SearchIcon />
      </div>
      <Text className="px-[8px] my-auto text-metadata whitespace-nowrap !font-semibold">
        {searchTerm.length < 3 && `Open advanced search`}
        {searchTerm.length >= 3 && `Open "${searchTerm}" in advanced search`}
      </Text>
    </div>
  );

  if (isWhoLoading && whoSearch.length > 2) {
    whoHeader = spinner;
  } else if (whoItems.length < 1) {
    if (whoSearch.length < 3) {
      whoHeader = (
        <>
          {openAdvanceSearchButton(whoSearch)}
          {picklistMessageItem('Please type to search')}
        </>
      );
    } else {
      whoHeader = picklistMessageItem('No Contacts or Leads found');
    }
  } else if (loggingTo.who && loggingTo.who?.[0]?.id?.startsWith(LEAD_PREFIX)) {
    whoHeader = openAdvanceSearchButton(whoSearch);
  } else {
    whoHeader = (
      <>
        {openAdvanceSearchButton(whoSearch)}
        {picklistMessageItem(
          <>
            Leads cannot be added to activities that are logging to contacts.{' '}
            <a
              href="https://clari.my.site.com/customer/s/topiccatalog" // TODO: UPDATE LINKS -- old link: https://help.groove.co/en/articles/8501106-faq-for-logging-in-actions
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </>,
          true,
        )}
      </>
    );
  }

  if (isWhatLoading && whatSearch.length > 2) {
    whatHeader = spinner;
  } else if (whatItems.length < 1) {
    if (whatSearch.length < 3) {
      whatHeader = (
        <>
          {openAdvanceSearchButton(whatSearch)}
          {picklistMessageItem('Please type to search')}
        </>
      );
    } else {
      whatHeader = picklistMessageItem('No Accounts or Opportunities found');
    }
  } else if (isMultipleRelatedTo) {
    whatHeader = openAdvanceSearchButton(whatSearch);
  } else {
    whatHeader = (
      <>
        {openAdvanceSearchButton(whatSearch)}
        {picklistMessageItem(
          'Only 1 related object can be selected at a time',
          true,
        )}
      </>
    );
  }

  let relatedToTooltip =
    'Related record cannot be attached to activities with leads';
  if (!(loggingTo.who && loggingTo.who[0]?.id?.startsWith(LEAD_PREFIX))) {
    relatedToTooltip = 'Related records cannot be changed for flow actions';
  }

  return (
    <>
      <Text variant="body-sm" className="mb-[4px] mt-[4px] font-semibold">
        Logging to
      </Text>
      <Tooltip
        content="Name records cannot be changed for flow actions"
        hide={!personStepId}
      >
        <MultiSelect
          className="w-full mb-[4px]"
          onSearchChange={debounce(handleWhoSearch, 200)}
          selectedValues={selectedWhos}
          placeholder="Name"
          items={isWhoLoading ? [] : whoItems}
          onSelect={item => {
            handleWhoSelect(item, grooveMeta);
            setWhoItems([]);
          }}
          onRemove={handleWhoRemove}
          listHeader={whoHeader}
          onOpen={() => {
            useStore.getState().setOtherValues({ relatedToLastOpen: 'who' });
            if (shouldShowAdvanceSearch) {
              whoRef.current?.closePane();
              openAdvanceSearchSelect(whoSearch);
            }
          }}
          ref={whoRef}
          disabled={!!personStepId && !shouldShowAdvanceSearch}
        />
      </Tooltip>
      <Tooltip
        content={relatedToTooltip}
        hide={
          !(loggingTo.who && loggingTo.who[0]?.id?.startsWith(LEAD_PREFIX)) &&
          !personStepId
        }
      >
        <MultiSelect
          className="w-full mb-[4px]"
          onSearchChange={debounce(handleWhatSearch, 200)}
          selectedValues={selectedWhats}
          placeholder="Related to"
          items={isWhatLoading ? [] : whatItems}
          listHeader={whatHeader}
          onSelect={item => {
            handleWhatSelect(item);
            setWhatItems([]);
          }}
          onRemove={index => {
            const { loggingTo } = useStore.getState().action;

            if (!loggingTo.what) return;
            const { what, removedWhat: oldRemovedWhat = {} } = loggingTo;
            const removedWhat = { ...oldRemovedWhat };

            if (what[index] && what[index].autoCreated)
              removedWhat[what[index].id] = what[index];

            useStore.getState().updateCurrentLoggingTo({
              what: [...what.slice(0, index), ...what.slice(index + 1)],
              removedWhat,
            });
          }}
          onOpen={() => {
            useStore.getState().setOtherValues({ relatedToLastOpen: 'what' });
            if (shouldShowAdvanceSearch) {
              whatRef.current?.closePane();
              openAdvanceSearchSelect(whatSearch);
            }
          }}
          ref={whatRef}
          disabled={
            !!(
              (loggingTo.who &&
                loggingTo.who[0]?.id?.startsWith(LEAD_PREFIX)) ||
              personStepId
            ) && !shouldShowAdvanceSearch
          }
        />
      </Tooltip>
      <Dialog
        modalProps={modalProps}
        hidden={!isAdvanceSearchSelectOpen}
        onDismiss={() => setIsAdvanceSearchSelectOpen(false)}
        styles={dialogStyles}
        dialogContentProps={dialogContentProps}
      >
        <SearchAndSelect
          actionBtnText="Update records"
          additionalFieldsType="calendar"
          onDismiss={() => setIsAdvanceSearchSelectOpen(false)}
          onSubmit={selectedItems => {
            const { names, relatedTos } =
              deriveNamesAndRelatedTosFromSearchAndSelectSelectedItems(
                Object.values(selectedItems),
              );

            if (relatedTos.length > 0 || names.length > 0) {
              useStore.getState().updateAction({ usedAdvanceSS: true });
            } else {
              useStore.getState().updateAction({ usedAdvanceSS: false });
            }

            useStore.getState().updateCurrentLoggingTo({
              what: relatedTos.map(item => ({
                id: item.id,
                text: item.text,
                type: item.type || '',
              })),
              who: names.map(item => ({
                id: item.id,
                text: item.text,
                type: item.type || '',
                autoCreated: true,
              })),
            });
            setIsAdvanceSearchSelectOpen(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default LoggingTo;
