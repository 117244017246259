import { fromJS } from 'immutable';
import { actionTypes } from 'Modules/SharingDialog/actions';

const initialState = fromJS({
  loading: true,
  scopes: [],
  currentActors: [],
  availableActors: [],
});

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.LOAD_DATA: {
      return state.merge(action.payload);
    }

    case actionTypes.SET_LOADING: {
      return state.set('loading', action.payload);
    }

    case actionTypes.TEARDOWN: {
      return initialState;
    }

    default:
      return state;
  }
}
