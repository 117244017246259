const convertHexToRGB = (hexString: string): string => {
  const hex = hexString?.slice(1);
  if (!hex || hex.length !== 6) {
    return '';
  }

  const hexSplit = hex.match(/.{1,2}/g) || [];
  const rgb = [
    parseInt(hexSplit[0], 16),
    parseInt(hexSplit[1], 16),
    parseInt(hexSplit[2], 16),
  ];
  return rgb.join();
};

export default convertHexToRGB;
