import ClariTheme from 'Utils/theme/ClariTheme';

export const greyContent = {
  background: ClariTheme.palette.text.lightGray,
  borderRadius: 2,
  height: 21,
};

export const pulse = {
  backgroundColor: ClariTheme.palette.text.lightGray,
  animationName: '$pulse',
  animationDuration: '1s',
  animationIterationCount: 'infinite',
};

export const shimmer = {
  '@keyframes pulse': {
    '0%': {
      backgroundColor: ClariTheme.palette.text.lightGray,
    },
    '50%': {
      backgroundColor: ClariTheme.palette.text.accentGray,
    },
    '100%': {
      backgroundColor: ClariTheme.palette.text.lightGray,
    },
  },
};
