import { decamelize, decamelizeKeys } from 'humps';
import makeHTTPRequest from 'GrooveHTTPClient/makeHTTPRequest';
import { DELETE, PUT, POST } from 'GrooveHTTPClient/HTTPMethod';
import { getAdminContentVisibilityEnabled } from 'GrooveHTTPClient/flows';

export const BASE_PATH = '/api/v2/steps';

export const index = ({ flowId, includeTemplate, includeVariants = false }) =>
  makeHTTPRequest({
    path: BASE_PATH,
    queryParameters: decamelizeKeys({
      flowId,
      includeTemplate,
      includeVariants,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    }),
  });

export const reorderSubSteps = ({ stepIds, flowId }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/reorder_substeps`,
    method: PUT,
    body: decamelizeKeys({
      stepIds,
      flowId,
    }),
  });

export const show = ({ stepId }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${stepId}`,
    queryParameters: {
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const create = (flowId, { step, template, add_people }) =>
  makeHTTPRequest({
    path: BASE_PATH,
    method: POST,
    body: {
      flow_id: flowId,
      step,
      template,
      add_people: add_people || false, // eslint-disable-line camelcase
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const createAndBatchAddPeople = (flowId, { step, template }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/create_and_add_people`,
    method: POST,
    body: {
      flow_id: flowId,
      step,
      template,
    },
  });

export const update = (stepId, { step, template }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${stepId}`,
    method: PUT,
    body: {
      step,
      template,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const destroy = stepId =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${stepId}`,
    method: DELETE,
  });

export const summary = (stepId, queryParameters) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${stepId}/summary`,
    queryParameters: {
      ...decamelizeKeys(queryParameters),
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const people = (stepId, queryParameters) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${stepId}/people_v2`,
    queryParameters: decamelizeKeys({
      ...queryParameters,
      filter: decamelize(queryParameters.filter),
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    }),
  });
