import React, { FC } from 'react';

const SeismicLogo: FC = () => (
  <svg
    width="160"
    height="30"
    viewBox="0 60 160 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.9816 78.9374H37.8159V82.1325C37.8159 83.73 37.8159 85.3533 40.1718 85.3533C42.5521 85.3533 42.5521 83.7043 42.5521 81.2564C42.5521 79.2981 42.184 78.4736 40.908 77.5718L36.2945 74.3251C33.8405 72.573 33.1534 70.6405 33.1534 67.8319C33.1534 65.6675 33.3497 63.6061 35.0429 62.1116C36.0245 61.2613 37.7914 60.4626 40.3435 60.4626C43.1656 60.4626 47.0429 61.6736 47.0429 66.4147V69.146H42.2331V67.1619C42.2331 65.8221 42.2331 64.3533 40.0981 64.3533C38.7975 64.3533 37.865 64.8687 37.865 67.0846C37.865 69.4037 38.3558 69.8932 39.3865 70.6405L44.1472 74.0417C45.0797 74.7374 46.0859 75.7165 46.6503 76.7472C47.3619 78.0098 47.3619 78.8601 47.3619 81.1018C47.3619 83.5754 47.3619 85.0957 46.5031 86.5386C45.0552 89.0122 42.0368 89.2699 40.2454 89.2699C36.0981 89.2699 32.9325 87.6208 32.9325 82.9828V78.9374H32.9816Z"
      fill="#233D43"
    />
    <path
      d="M52.7117 61.0037H65.4479V65.2295H57.546V71.7227H64.9816V75.9227H57.546V84.4H65.7914V88.6773H52.7117V61.0037Z"
      fill="#233D43"
    />
    <path
      d="M75.8282 61.0294H71.0184V88.6515H75.8282V61.0294Z"
      fill="#233D43"
    />
    <path
      d="M80.9816 78.9374H85.8159V82.1325C85.8159 83.73 85.8159 85.3533 88.1718 85.3533C90.5521 85.3533 90.5521 83.7043 90.5521 81.2564C90.5521 79.2981 90.184 78.4736 88.908 77.5718L84.2945 74.3251C81.8405 72.573 81.1534 70.6405 81.1534 67.8319C81.1534 65.6675 81.3497 63.6061 83.0429 62.1116C84.0245 61.2613 85.8159 60.4626 88.3435 60.4626C91.1902 60.4626 95.0429 61.6736 95.0429 66.4147V69.146H90.2331V67.1619C90.2331 65.8221 90.2331 64.3533 88.0981 64.3533C86.7975 64.3533 85.865 64.8687 85.865 67.0846C85.865 69.4037 86.3558 69.8932 87.3865 70.6405L92.1472 74.0417C93.0797 74.7374 94.0859 75.7165 94.6503 76.7472C95.3619 78.0098 95.3619 78.8601 95.3619 81.1018C95.3619 83.5754 95.3619 85.0957 94.503 86.5386C93.0552 89.0122 90.0368 89.2699 88.2454 89.2699C84.0981 89.2699 80.9325 87.6208 80.9325 82.9828V78.9374H80.9816Z"
      fill="#233D43"
    />
    <path
      d="M100.712 61.0294H108.589L111.706 80.3804L114.798 61.0294H122.601V88.6773H117.791L118.233 64.7141L113.988 88.6773H109.448L105.104 64.7141L105.497 88.6773H100.712V61.0294Z"
      fill="#233D43"
    />
    <path
      d="M132.638 61.0294H127.828V88.6515H132.638V61.0294Z"
      fill="#233D43"
    />
    <path
      d="M147.386 71.13V67.6C147.386 65.5386 147.043 64.3533 144.932 64.3533C142.822 64.3533 142.699 66.1055 142.699 67.3681V82.184C142.699 83.8331 142.969 85.3018 145.129 85.3018C147.411 85.3018 147.411 83.5754 147.411 81.9006V78.2932H152.221V82.2098C152.221 84.4515 152.221 89.2441 145.055 89.2441C143.681 89.2441 141.472 88.9865 139.951 87.93C137.865 86.4871 137.865 84.3227 137.865 82.8024V67.7803C137.865 65.5386 137.988 64.0184 139.166 62.6527C141.055 60.4883 144.049 60.4626 145.055 60.4626C152.221 60.4626 152.221 64.9202 152.221 66.8785V71.1558H147.386V71.13Z"
      fill="#233D43"
    />
    <path
      d="M13.4233 69.7902H21.9141C21.9141 64.8945 18.1104 60.9264 13.4233 60.9264C8.73617 60.9264 4.95703 64.8945 4.95703 69.7902C4.95703 74.6859 8.76071 78.654 13.4233 78.654V69.7902Z"
      fill="#233D43"
    />
    <path
      d="M15.0675 80.4319H6.57666C6.57666 85.3276 10.3803 89.2957 15.0675 89.2957C19.7546 89.2957 23.5337 85.3276 23.5337 80.4319C23.5337 75.5362 19.73 71.5681 15.0675 71.5681V80.4319Z"
      fill="#E95517"
    />
  </svg>
);

export default SeismicLogo;
