import cx from 'classnames';
import { FC } from 'react';

import Text from './Text';

export type SourceType = 'PLAY' | 'ONE OFF' | 'FLOW' | 'SPACES';
const SOURCE_LABELS = {
  PLAY: 'Play',
  'ONE OFF': 'One Off',
  FLOW: 'Flow',
  SPACES: 'Spaces',
};

type ActionSourceProps = {
  source: SourceType;
};

const ActionSource: FC<ActionSourceProps> = ({ source }) => {
  let sourceClassName = 'text-white bg-surrealTeal/600';

  if (source === 'PLAY')
    sourceClassName = 'text-neutral/900 bg-tangerineDream/300';
  if (source === 'FLOW') sourceClassName = 'text-white bg-razzyRed/500';
  if (source === 'SPACES') sourceClassName = 'text-white bg-grapeshine/600';

  return (
    <div className={cx('px-[4px] py-[2px] rounded', sourceClassName)}>
      <Text className="font-semibold text-metadata-sm">
        {SOURCE_LABELS[source]}
      </Text>
    </div>
  );
};

export default ActionSource;
