import { decamelizeKeys } from 'humps';
import { isFunction, toString } from 'lodash-es';

import { logWarningToSentry } from 'Modules/Shared/sagas/errors';

export const USER_IDS_TO_IGNORE = [
  '50845',
  '50844',
  '1',
  '33414',
  '40257',
  '61094',
  '83846',
  '92182', // Google Smoke Test 2
  '92184', // Google Smoke Test 3
  '92185', // Google Smoke Test 4
  '92187', // Google Smoke Test 5
  '94246', // Google Smoke Test
];
export const ORG_IDS_TO_IGNORE = ['2537'];

export const identifyUser = ({
  id,
  email,
  userHash,
  role,
  provider,
  orgId,
}) => {
  try {
    if (
      !USER_IDS_TO_IGNORE.includes(toString(id)) &&
      !ORG_IDS_TO_IGNORE.includes(toString(orgId)) &&
      window &&
      window.analytics &&
      window.analytics.identify &&
      isFunction(window.analytics.identify)
    ) {
      window.analytics.identify(
        id,
        {
          email,
          role,
          provider,
        },
        {
          integrations: {
            Intercom: decamelizeKeys({ userHash }),
          },
        }
      );
    }
  } catch (e) {
    logWarningToSentry({ error: e, type: 'Segment identifyUser error' });
  }
};

export const trackEvent = (...params) => {
  try {
    if (
      window &&
      window.analytics &&
      window.analytics.track &&
      isFunction(window.analytics.track)
    ) {
      window.analytics.track(...params);
    }
  } catch (e) {
    logWarningToSentry({ error: e, type: 'Segment trackEvent error' });
  }
};
