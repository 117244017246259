import { Separator } from '@fluentui/react';
import { FC } from 'react';
import { memoizeFunction } from '@fluentui/react/lib/Utilities';

import theme from '../theme';

const getStyles = memoizeFunction(xMargin => ({
  root: {
    padding: 0,
    height: 0,
    marginLeft: xMargin || 16,
    marginRight: xMargin || 16,
    '&:before': { backgroundColor: theme['neutral/200'] },
  },
}));

const StyledSeparator: FC<{ xMargin?: number }> = ({ xMargin }) => {
  return <Separator styles={getStyles(xMargin)} />;
};

export default StyledSeparator;
