import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ClariTheme from 'Utils/theme/ClariTheme';

const styles = theme => ({
  root: {
    textTransform: 'uppercase',
    borderRadius: 3,
    height: 14,
    width: 'fit-content',
    fontSize: 10,
    letterSpacing: '0.39px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1px 4px',
    color: theme.palette.text.neutralColor,
  },
});

const getSuccessOrFailureColor = isSuccess =>
  isSuccess ? ClariTheme.palette.success.main : ClariTheme.palette.error.main;

const StatusChip = ({ classes, label, success, color, style }) => {
  const styleToAdd = {
    backgroundColor: color || getSuccessOrFailureColor(success),
    ...style,
  };

  return (
    <div className={classes.root} style={styleToAdd}>
      {label}
    </div>
  );
};

StatusChip.displayName = 'StatusChip';
StatusChip.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  success: PropTypes.bool,
  style: PropTypes.object,
};

StatusChip.defaultProps = {
  success: false,
  style: {},
};

export default withStyles(styles)(StatusChip);
