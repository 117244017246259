import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Fade from '@material-ui/core/Fade/Fade';
import Avatar from '@material-ui/core/Avatar/Avatar';

const avatarHeight = 24;
const avatarWidth = 24;
const styles = {
  avatarContainer: {
    width: avatarWidth,
    height: avatarHeight,
    marginRight: 8,
  },
  avatar: {
    width: avatarWidth,
    height: avatarHeight,
  },
  inputRoot: {
    flex: 1,
  },
  inputInnerInput: {
    padding: 0,
    fontSize: '0.875rem',
    fontWeight: 600,
    width: 'inherit',
  },
};

const defaultMuiInputProps = {};

@withStyles(styles)
export default class SyncedTextField extends Component {
  static propTypes = {
    // Passed form decorators
    classes: PropTypes.objectOf(PropTypes.string).isRequired,

    // Passed from parent
    handleOnBlur: PropTypes.func,
    handleOnFocus: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    setEditingUser: PropTypes.func.isRequired,
    editingUserId: PropTypes.number.isRequired,
    currentUserId: PropTypes.number.isRequired,
    editingUserImgSrc: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    muiInputProps: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    editingUserId: null,
    placeholder: '',
    muiInputProps: defaultMuiInputProps,
  };

  static getDerivedStateFromProps(props, state) {
    const { value = '' } = props;
    const { editInProgress } = state;
    if (editInProgress) {
      return state;
    }
    return { ...state, ...{ value, editInProgress: false } };
  }

  constructor(props) {
    super(props);
    this.state = { value: '', editInProgress: false };
  }

  handleOnInputUpdate = e => {
    const { onChange } = this.props;
    const { value } = e.target;

    this.setState({ value }, () => {
      onChange({ value: this.state.value });
    });
  };

  handleEditComplete = () => {
    const { handleOnBlur } = this.props;
    if (handleOnBlur) {
      handleOnBlur();
    }
    this.setState({ editInProgress: false });
  };

  handleEditStart = () => {
    const { handleOnFocus } = this.props;
    this.setState({ editInProgress: true });
    if (handleOnFocus) {
      handleOnFocus();
    }
  };

  isAnotherUserEditing() {
    const { editingUserId, currentUserId } = this.props;
    return !!editingUserId && editingUserId !== currentUserId;
  }

  createAvatar() {
    const { classes, editingUserImgSrc } = this.props;
    return (
      <div className={classes.avatarContainer}>
        <Fade in>
          <Avatar src={editingUserImgSrc} className={classes.avatar} />
        </Fade>
      </div>
    );
  }

  render() {
    const { classes, placeholder, muiInputProps } = this.props;
    const anotherUserIsEditing = this.isAnotherUserEditing();

    return (
      <Input
        onFocus={this.handleEditStart}
        onBlur={this.handleEditComplete}
        disabled={anotherUserIsEditing}
        value={this.state.value || ''}
        placeholder={placeholder}
        onChange={this.handleOnInputUpdate}
        classes={{
          input: classes.inputInnerInput,
          root: classes.inputRoot,
        }}
        startAdornment={anotherUserIsEditing ? this.createAvatar() : null}
        {...muiInputProps}
      />
    );
  }
}
