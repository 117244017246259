import { FC } from 'react';
import { ConstantDialIcon } from '@groove/ui/Components/BoogieIcon';
import useDialerStore from '@groove/dialer-components/hooks/useStore';
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';

import DialerBarButton from './DialerBarButton';

const ConstantBridgeButton: FC = () => {
  const cbdActiveFlag = useVariation('constant-bridge-dial');

  const isBridgeCallingEnabled = useDialerStore(
    store => store.isBridgeCallingEnabled,
  );
  const isConstantBridgeEnabled = useDialerStore(
    store => store.isConstantBridgeEnabled,
  );
  const constantBridgeSessionStatus = useDialerStore(
    store => store.constantBridgeSessionStatus,
  );

  const onClick = (): void =>
    useDialerStore
      .getState()
      .setConstantBridgeEnabled(!isConstantBridgeEnabled);

  const isButtonDisabled =
    !isBridgeCallingEnabled ||
    constantBridgeSessionStatus === 'active' ||
    !cbdActiveFlag;

  let tooltip = 'Enable Constant Bridge Dial.';

  if (!isBridgeCallingEnabled)
    tooltip = 'Enable Bridge Dial to use this feature.';

  if (constantBridgeSessionStatus === 'active')
    tooltip = 'Call is in progress.';

  if (!cbdActiveFlag)
    tooltip =
      'Please contact your admin to turn on Constant Bridge Dial feature';

  return (
    <DialerBarButton
      label="Constant"
      onClick={onClick}
      selected={isConstantBridgeEnabled}
      tooltip={tooltip}
      disabled={isButtonDisabled}
    >
      <ConstantDialIcon className="w-[20px]" />
    </DialerBarButton>
  );
};

export default ConstantBridgeButton;
