import { fromJS, List, Map, Set, OrderedMap } from 'immutable';
import { VISIBILITY_STATES } from 'Modules/Shared/constants/pane';
import { actionTypes } from 'Modules/Shared/actions/accountPreview';
import Query from 'Modules/Shared/records/Query';
import { SELECTED_PEOPLE_PATH } from 'Modules/Shared/constants/accountPreview';

const initialState = fromJS({
  activeAccountId: null,
  activePersonId: null,
  isLoading: false,
  addingPeopleToFlow: false,
  fetchingUnassociatedContacts: false,
  removingPeopleFromAllFlows: false,
  displayUnassociatedContacts: false,
  people: new Map({
    all: new List(),
    total: null,
    unassociatedContacts: new OrderedMap(),
    selected: new Map({
      loadingModal: false,
      oneFlowRestrictionData: new Set(),
      unassociatedContacts: new Set(),
      activeFlow: new Set(),
    }),
  }),
  isLoadingAccount: false,
  paneVisibility: VISIBILITY_STATES.CLOSED,
  query: new Query({
    limit: 7,
    offset: 0,
    orderBy: 'last_engaged_at',
    orderDir: 'desc',
    scope: 'all_people_in_organization',
  }),
});

const accountPreview = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_PREVIEW.PROGRESS:
      return state.set('isLoading', true);
    case actionTypes.FETCH_PREVIEW.FAILURE:
      return state
        .set('isLoading', false)
        .setIn(['people', 'all'], new List())
        .setIn(['people', 'total'], null);
    case actionTypes.FETCH_PREVIEW.SUCCESS: {
      const { people, total } = action.payload;

      return state
        .set('isLoading', false)
        .setIn(['people', 'all'], people)
        .setIn(['people', 'total'], total);
    }
    case actionTypes.SELECT_UNASSOCIATED_CONTACTS: {
      const { people } = action.payload;
      const existingPeople = state.getIn([
        ...SELECTED_PEOPLE_PATH,
        'unassociatedContacts',
      ]);

      const updatedPeople = existingPeople.union(people);

      return state.setIn(
        [...SELECTED_PEOPLE_PATH, 'unassociatedContacts'],
        updatedPeople
      );
    }

    case actionTypes.SELECT_ACTIVE_FLOW_PEOPLE: {
      const { people } = action.payload;
      const existingPeople = state.getIn(
        [...SELECTED_PEOPLE_PATH, 'activeFlow'],
        'activeFlow'
      );
      const updatedPeople = existingPeople.union(people);

      return state.setIn(
        [...SELECTED_PEOPLE_PATH, 'activeFlow'],
        updatedPeople
      );
    }

    case actionTypes.UNSELECT_UNASSOCIATED_CONTACTS: {
      const { people } = action.payload;
      const existingPeople = state.getIn([
        ...SELECTED_PEOPLE_PATH,
        'unassociatedContacts',
      ]);

      const updatedPeople = existingPeople.subtract(people);

      return state.setIn(
        [...SELECTED_PEOPLE_PATH, 'unassociatedContacts'],
        updatedPeople
      );
    }

    case actionTypes.UNSELECT_ACTIVE_FLOW_PEOPLE: {
      const { people } = action.payload;
      const existingPeople = state.getIn([
        ...SELECTED_PEOPLE_PATH,
        'activeFlow',
      ]);
      const updatedPeople = existingPeople.subtract(people);

      return state.setIn(
        [...SELECTED_PEOPLE_PATH, 'activeFlow'],
        updatedPeople
      );
    }

    case actionTypes.OPEN: {
      return state
        .set('displayUnassociatedContacts', false)
        .set('query', initialState.get('query'));
    }
    case actionTypes.CLOSE: {
      return state
        .set('displayUnassociatedContacts', false)
        .setIn(['people', 'all'], new List())
        .setIn(['people', 'unassociatedContacts'], new OrderedMap())
        .setIn([...SELECTED_PEOPLE_PATH, 'unassociatedContacts'], new Set())
        .setIn([...SELECTED_PEOPLE_PATH, 'activeFlow'], new Set())
        .setIn(['people', 'total'], null);
    }

    case actionTypes.ADD_PEOPLE_TO_FLOW.PROGRESS:
      return state.set('addingPeopleToFlow', true);
    case actionTypes.ADD_PEOPLE_TO_FLOW.SUCCESS:
      return state.set('addingPeopleToFlow', false);
    case actionTypes.ADD_PEOPLE_TO_FLOW.FAILURE:
      return state.set('addingPeopleToFlow', false);
    case actionTypes.REMOVE_PEOPLE_FROM_ALL_FLOWS.PROGRESS:
      return state.set('removingPeopleFromAllFlows', true);
    case actionTypes.REMOVE_PEOPLE_FROM_ALL_FLOWS.SUCCESS:
      return state.set('removingPeopleFromAllFlows', false);
    case actionTypes.REMOVE_PEOPLE_FROM_ALL_FLOWS.FAILURE:
      return state.set('removingPeopleFromAllFlows', false);
    case actionTypes.UPDATE_PREVIEW_QUERY:
      return state.mergeDeepIn(['query'], action.payload);
    case actionTypes.SET_ACTIVE_PERSON_ID:
      return state.set('activePersonId', action.payload);
    case actionTypes.UNSET_ACTIVE_PERSON_ID:
      return state.set('activePersonId', null);
    case actionTypes.FETCH_UNASSOCIATED_CONTACTS.SUCCESS: {
      const { contacts } = action.payload;
      return state
        .setIn(['people', 'unassociatedContacts'], contacts)
        .set('fetchingUnassociatedContacts', false);
    }
    case actionTypes.FETCH_UNASSOCIATED_CONTACTS.PROGRESS:
      return state.set('fetchingUnassociatedContacts', true);
    case actionTypes.FETCH_UNASSOCIATED_CONTACTS.FAILURE:
      return state
        .set('displayUnassociatedContacts', false)
        .set('fetchingUnassociatedContacts', false);
    case actionTypes.FETCH_UNASSOCIATED_CONTACTS.BEGIN:
      return state.set('displayUnassociatedContacts', true);
    case actionTypes.SET_ACTIVE_ACCOUNT_ID:
      return state.set('activeAccountId', action.payload.id);
    case actionTypes.CLEAR_SELECTED_PEOPLE: {
      const { clearActiveFlowPeople, clearUnassociatedContacts } =
        action.payload;
      let updatedState = state;
      if (clearActiveFlowPeople) {
        updatedState = updatedState.setIn(
          [...SELECTED_PEOPLE_PATH, 'activeFlow'],
          new Set()
        );
      }
      if (clearUnassociatedContacts) {
        updatedState = updatedState.setIn(
          [...SELECTED_PEOPLE_PATH, 'unassociatedContacts'],
          new Set()
        );
      }
      return updatedState;
    }
    case actionTypes.CLEAR_CONTACTS_FLOWS: {
      const { ids } = action.payload;
      let updatedState = state;
      ids.forEach(id => {
        updatedState = updatedState.setIn(
          ['people', 'all', id, 'flows'],
          new List()
        );
      });
      return updatedState;
    }
    case actionTypes.SET_VISIBILITY_STATE: {
      const { visibilityState } = action.payload;

      return state.set('paneVisibility', visibilityState);
    }
    case actionTypes.LOAD_ACCOUNT.BEGIN:
      return state.set('isLoadingAccount', true);
    case actionTypes.LOAD_ACCOUNT.SUCCESS:
      return state.set('isLoadingAccount', false);
    case actionTypes.LOAD_ACCOUNT.FAILURE:
      return state.set('isLoadingAccount', false);
    case actionTypes.FETCH_ONE_FLOW_RESTRICTION.BEGIN:
      return state.setIn(['people', 'selected', 'loadingModal'], true);
    case actionTypes.FETCH_ONE_FLOW_RESTRICTION.SUCCESS:
      return state
        .setIn(['people', 'selected', 'loadingModal'], false)
        .mergeIn(['people', 'selected'], {
          oneFlowRestrictionData: fromJS(action.payload),
        });
    case actionTypes.FETCH_ONE_FLOW_RESTRICTION.FAILURE:
      return state.setIn(['people', 'selected', 'loadingModal'], false);
    default: {
      return state;
    }
  }
};

export default accountPreview;
