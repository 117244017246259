import React from 'react';
import { Loader } from '@groove-labs/groove-ui';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  root: {
    position: 'fixed',
    top: '50%',
    width: '100%',
  },
};

const RouteLoader = ({ classes }) => (
  <div className={classes.root}>
    <Loader />
  </div>
);

RouteLoader.displayName = 'RouteLoader';
RouteLoader.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(RouteLoader);
