import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const DoubleChevronDown: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <path d="M12.1464 7.14645C12.3417 6.95119 12.6583 6.95119 12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.35355 12.3536C8.15829 12.5488 7.84171 12.5488 7.64645 12.3536L3.14645 7.85355C2.95118 7.65829 2.95118 7.34171 3.14645 7.14645C3.34171 6.95118 3.65829 6.95118 3.85355 7.14645L8 11.2929L12.1464 7.14645ZM12.1464 3.14645C12.3417 2.95119 12.6583 2.95119 12.8536 3.14645C13.0488 3.34171 13.0488 3.65829 12.8536 3.85355L8.35355 8.35355C8.15829 8.54882 7.84171 8.54882 7.64645 8.35355L3.14645 3.85355C2.95118 3.65829 2.95118 3.34171 3.14645 3.14645C3.34171 2.95118 3.65829 2.95118 3.85355 3.14645L8 7.29289L12.1464 3.14645Z" />
  </svg>
);
