import {
  cellRenderer,
  CellRendererProps,
  TableText,
  TableColDef,
} from '@clari/iris-table';
import { Action } from '@groove/api/gateway/v1/actions';
import DueAt from 'Modules/ActionsRewrite/components/customCells/DueAt';
import Source from 'Modules/ActionsRewrite/components/customCells/Source';
import Timezone from 'Modules/ActionsRewrite/components/customCells/Timezone';
import ActionType from 'Modules/ActionsRewrite/components/customCells/ActionType/ActionType';
import titleCellRenderer from 'Modules/ActionsRewrite/components/customCells/titleCellRenderer';
import PriorityChip from 'Modules/Shared/components/PriorityChip';
import SmartPriority from 'Modules/ActionsRewrite/components/customCells/SmartPriority';

export interface ActionCellParams extends CellRendererProps {
  data: Action;
}

// Individual column definitions are defined within this file
// to enable flexible ordering via the useColumnDefinitionsBuilder
// via the columnKeysToDefinitions defined at the end of this file

export type ColumnDefinition = TableColDef<Action>;

export const priorityDefinition: ColumnDefinition = {
  headerName: 'Priority',
  type: 'text',
  field: 'priority',
  cellRenderer: cellRenderer((params: ActionCellParams) => ({
    primaryContent: (
      <TableText>
        <PriorityChip priority={params.data.priority} />
      </TableText>
    ),
  })),
};

export const titleDefinition: ColumnDefinition = {
  headerName: 'Action Title',
  type: 'text',
  field: 'summary',
  cellRenderer: titleCellRenderer,
};

export const whoDefinition: ColumnDefinition = {
  headerName: 'Who',
  type: 'text',
  field: 'who.name',
  cellRenderer: cellRenderer((params: ActionCellParams) => ({
    primaryContent: <TableText>{params.data?.who?.name}</TableText>,
    secondaryContent: <TableText>{params.data.who?.title}</TableText>,
  })),
};

export const companyDefinition: ColumnDefinition = {
  headerName: 'Company',
  type: 'text',
  field: 'who.company',
};

export const dueAtDefinition: ColumnDefinition = {
  field: 'due_at',
  headerName: 'Due',
  type: 'datetime',
  cellRenderer: cellRenderer((params: ActionCellParams) => ({
    primaryContent: <DueAt action={params.data} />,
  })),
};

export const timezoneDefinition: ColumnDefinition = {
  field: 'who.tzid',
  headerName: 'Timezone',
  type: 'text',
  cellRenderer: cellRenderer((params: ActionCellParams) => ({
    primaryContent: <Timezone action={params.data} />,
  })),
};

export const sourceDefinition: ColumnDefinition = {
  field: 'source',
  headerName: 'Source',
  cellRenderer: cellRenderer((params: ActionCellParams) => ({
    primaryContent: <Source label={params.data.source} />,
  })),
};

export const typeDefinition: ColumnDefinition = {
  field: 'type',
  type: 'text',
  headerName: 'Type',
  cellRenderer: cellRenderer((params: ActionCellParams) => ({
    primaryContent: <ActionType action={params.data} />,
  })),
};

// TODO: WFA-118. Use Iris's Hovercard component
export const smartPriorityDefinition: ColumnDefinition = {
  field: 'smart_priority',
  headerName: 'Smart Priority',
  cellRenderer: cellRenderer((params: ActionCellParams) => ({
    primaryContent: (
      <SmartPriority
        actionId={params.data.id}
        smartPriority={params.data.smart_priority}
      />
    ),
  })),
};

export const managedByDefinition: ColumnDefinition = {
  headerName: 'Managed By',
  // NB: Requires suppressDotNotation=false within iris table
  field: 'assignee.name',
  type: 'text',
};

// Column Keys
export type ColumnDefinitionKey =
  | 'type'
  | 'priority'
  | 'title'
  | 'who'
  | 'company'
  | 'due'
  | 'timezone'
  | 'source'
  | 'managedBy'
  | 'smartPriority';

export type ColumnDefinitionsType = {
  [key in ColumnDefinitionKey]: TableColDef<Action>;
};

/**
 * This object streamlines creating an array of column definitions
 * configurable by an array of strings via useColumnDefinitionBuilder
 */
const columnKeysToDefinitions: ColumnDefinitionsType = {
  type: typeDefinition,
  priority: priorityDefinition,
  title: titleDefinition,
  who: whoDefinition,
  company: companyDefinition,
  due: dueAtDefinition,
  timezone: timezoneDefinition,
  smartPriority: smartPriorityDefinition,
  source: sourceDefinition,
  managedBy: managedByDefinition,
};

export default columnKeysToDefinitions;
