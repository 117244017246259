import { FC, useEffect, useMemo, useState } from 'react';
import LinkButton from '@groove/ui/Components/LinkButton';
import Tooltip from '@groove/ui/Components/Tooltip';
import { InfoIcon } from '@fluentui/react-icons-mdl2';
import Input from '@groove/ui/Components/Input';
import Text from '@groove/ui/Components/Text';
import PicklistNew, {
  StandardDropDownItem,
} from '@groove/ui/Components/PicklistNew';
import { ActionPriorityTypes } from '@groove/api/services/v1/actions';
import { CrmTypes } from '@groove/api/gateway/v1/activities';
import { getActionSource } from '@groove/api/hooks/useMutateAction';

import AssignTo from '../component/AssignTo';
import useStore from '../store/useStore';
import DueAt from '../component/DueAt';
import LoggingTo from '../component/LoggingTo';
import { addSFDCObjectToOmnibar } from '../utils/loggingToMethods';

const priorityArray: (ActionPriorityTypes | '')[] = [
  '',
  'low',
  'normal',
  'high',
];

export type StandardFieldsProps = {
  taskTypes: CrmTypes[];
  showSfTaskTypePicklist: boolean;
  editSfTaskTypePicklist: boolean;
};

const StandardFields: FC<StandardFieldsProps> = ({
  taskTypes,
  showSfTaskTypePicklist,
  editSfTaskTypePicklist,
}) => {
  const [taskItems, setTaskItems] = useState<StandardDropDownItem[]>([]);
  const [selectedTaskItem, setSelectedTaskItem] = useState<
    StandardDropDownItem[]
  >([{ key: '', value: '', text: 'None' }]);

  const crmTaskType = useStore(store => store.action.crmTaskType);
  const { action } = useStore.getState();
  const summary = useStore(store => store.action.summary);
  const priority = useStore(store => store.action.priority);
  const assignee = useStore(store => store.action.assignee);
  const creator = useStore(store => store.action.creator);
  const personStepId = useStore(store => store.action.personStepId);
  const description = useStore(store => store.action.description);
  const crmInstanceUrl = useStore(store => store.action.crmInstanceUrl);
  const crmObjectId = useStore(store => store.action.crmObjectId);

  const stringTaskItems = JSON.stringify(taskItems);
  const selectedTaskValue = selectedTaskItem[0].value;

  useEffect(() => {
    taskItems.forEach(taskItem => {
      if (crmTaskType === taskItem.value) setSelectedTaskItem([taskItem]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmTaskType]);

  // For Priorities
  const priorityItems: StandardDropDownItem[] = [
    { key: priorityArray[0], value: priorityArray[0], text: 'None' },
    { key: priorityArray[1], value: priorityArray[1], text: 'Low' },
    { key: priorityArray[2], value: priorityArray[2], text: 'Medium' },
    { key: priorityArray[3], value: priorityArray[3], text: 'High' },
  ];

  let selectedPriorities: StandardDropDownItem[] = [priorityItems[0]];

  priorityItems.forEach(item => {
    if (item.value === priority) selectedPriorities = [item];
  });

  useEffect(() => {
    setTaskItems(
      taskTypes.map(type => {
        const item = {
          key: type.value,
          value: type.value,
          text: type.label,
        };
        if (type.value === crmTaskType) setSelectedTaskItem([item]);
        return item;
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskTypes]);

  const onTaskItemChange = (item: StandardDropDownItem | null): void => {
    useStore.getState().updateAction({
      crmTaskType: (item?.value as string) || '',
    });
    if (item) setSelectedTaskItem([item]);
  };

  const showAssignedBy = creator && creator?.id !== assignee?.id;
  const selectedPriorityValue = selectedPriorities[0].value;

  // The extreme amount of useMemo is to ensure each component doesn't get rerender unnecessarily.
  return (
    <>
      {useMemo(
        () => (
          <>
            <Text variant="body-sm" className="mb-[4px] mt-[4px] font-semibold">
              Action title{' '}
              {getActionSource(action) !== 'ONE_OFF' && (
                <span className="text-error/600">*</span>
              )}
            </Text>
            <Input
              className="w-full mb-[4px] h-[30px]"
              value={summary || ''}
              onChange={e =>
                useStore
                  .getState()
                  .updateAction({ summary: e.target.value || '' })
              }
              disabled={!!personStepId}
            />
          </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [summary, personStepId],
      )}

      {useMemo(
        () => (
          <LoggingTo />
        ),
        [],
      )}
      {useMemo(
        () => (
          <>
            <AssignTo />
            {showAssignedBy && (
              <Text className="mb-[4px] text-xs" aria-label="Assigned By">
                Assigned By {creator?.name}
              </Text>
            )}
          </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showAssignedBy],
      )}
      <div className="flex flex-row">
        {useMemo(
          () => (
            <div className="flex-1">
              <DueAt />
            </div>
          ),
          [],
        )}
        {useMemo(
          () => (
            <div className="w-[80px] ml-[8px]">
              <Text
                variant="body-sm"
                className="mb-[4px] mt-[4px] font-semibold"
              >
                Priority
              </Text>
              <PicklistNew
                className="w-full mb-[4px]"
                selectedValues={selectedPriorities}
                items={priorityItems}
                onChange={item =>
                  useStore.getState().updateAction({
                    priority: (item?.value as ActionPriorityTypes) || '',
                  })
                }
                disabled={!!personStepId}
              />
            </div>
          ),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [selectedPriorityValue, personStepId],
        )}
      </div>
      {useMemo(
        () => {
          if (showSfTaskTypePicklist) {
            return (
              <>
                <div className="flex flex-row">
                  <Text
                    variant="body-sm"
                    className="mb-[4px] mt-[4px] font-semibold"
                  >
                    Salesforce task type
                  </Text>
                  <Tooltip content="Selecting a template will automatically set the Salesforce Task Type field based on the template’s predefined settings.">
                    <div className="ml-2">
                      <InfoIcon />
                    </div>
                  </Tooltip>
                </div>
                <PicklistNew
                  className="w-full mb-[4px]"
                  disabled={!editSfTaskTypePicklist}
                  selectedValues={selectedTaskItem}
                  items={taskItems}
                  hasSearch
                  onChange={onTaskItemChange}
                />
              </>
            );
          }
          return null;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
          stringTaskItems,
          selectedTaskValue,
          editSfTaskTypePicklist,
          showSfTaskTypePicklist,
        ],
      )}

      {useMemo(
        () => (
          <>
            <Text variant="body-sm" className="mb-[4px] mt-[4px] font-semibold">
              Description
            </Text>
            <Input
              multiple
              className="flex-1 w-full"
              value={description || ''}
              onChange={e =>
                useStore
                  .getState()
                  .updateAction({ description: e.target.value || '' })
              }
            />
          </>
        ),
        [description],
      )}

      {useMemo(
        () => (
          <>
            {crmObjectId && crmInstanceUrl && (
              <LinkButton
                href={`${crmInstanceUrl}/${crmObjectId}`}
                className="text-body-sm mt-[8px] mb-[-2px]"
                target="_blank"
                onMouseEnter={() => addSFDCObjectToOmnibar(crmObjectId)}
              >
                Link to Salesforce object
              </LinkButton>
            )}
          </>
        ),
        [crmObjectId, crmInstanceUrl],
      )}
    </>
  );
};

export default StandardFields;
