import { keyMirror } from '@groove-labs/action-utils';

// index & show
const WORKSPACE_SHARE_USERS__DATA_BASE_UI_KEYPATH = ['shareUsersData'];

export const SHARE_DIALOG_OPEN_KEYPATH = ['isWorkspaceShareDialogOpen'];
export const WORKSPACE_ALL_USERS_UI_KEYPATH = [
  ...WORKSPACE_SHARE_USERS__DATA_BASE_UI_KEYPATH,
  'allUsers',
];
export const WORKSPACE_SHARED_USERS_UI_KEYPATH = [
  ...WORKSPACE_SHARE_USERS__DATA_BASE_UI_KEYPATH,
  'sharedUsers',
];
export const WORKSPACE_SHARING_DIALOG_SELECTED_WORKSPACE_ID = [
  ...WORKSPACE_SHARE_USERS__DATA_BASE_UI_KEYPATH,
  'workspaceId',
];
export const WORKSPACE_SHARING_DIALOG_SELECTED_WORKSPACE_CREATOR_ID = [
  ...WORKSPACE_SHARE_USERS__DATA_BASE_UI_KEYPATH,
  'workspaceCreatorId',
];
export const WORKSPACE_DELETE_CONFIRM_DIALOG_KEYPATH = [
  'workspaceDeleteConfirmDialog',
];
export const WORKSPACE_DELETE_CONFIRM_DIALOG_WORKSPACE_NAME_KEYPATH = [
  'workspaceDeleteConfirmDialogWorkspaceName',
];

// index & import
export const SPACES_IMPORT_DIALOG_UI_KEY_PATH = ['spacesImportDialog'];
export const SPACES_IMPORT_DIALOG_IS_OPEN_UI_KEY_PATH = [
  ...SPACES_IMPORT_DIALOG_UI_KEY_PATH,
  'isOpen',
];
export const SPACES_IMPORT_DIALOG_ACTIVE_TAB_UI_KEY_PATH = [
  ...SPACES_IMPORT_DIALOG_UI_KEY_PATH,
  'activeTab',
];

export const TABS = keyMirror([
  'search',
  'ownershipSearch',
  'opportunitySearch',
  'salesforceReport',
]);

export const ACCOUNT_TAB_DATA = {
  [TABS.search]: {
    id: TABS.search,
    label: 'Search',
  },
  [TABS.ownershipSearch]: {
    id: TABS.ownershipSearch,
    label: 'Accounts I Own',
  },
  [TABS.opportunitySearch]: {
    id: TABS.opportunitySearch,
    label: 'Accounts where I have an Opp',
  },
  [TABS.salesforceReport]: {
    id: TABS.salesforceReport,
    label: 'Salesforce Report',
  },
};

export const OPPORTUNITY_TAB_DATA = {
  [TABS.search]: {
    id: TABS.search,
    label: 'Search',
  },
  [TABS.ownershipSearch]: {
    id: TABS.ownershipSearch,
    label: 'Opportunities I Own',
  },
  [TABS.salesforceReport]: {
    id: TABS.salesforceReport,
    label: 'Salesforce Report',
  },
};

export const WORKSPACE_TYPES = {
  OPPORTUNITY: 'OPPORTUNITY',
  ACCOUNT: 'ACCOUNT',
};
