import {
  PrimaryButton,
  DefaultButton,
  Spinner,
  SpinnerSize,
  Icon,
  Text,
} from '@fluentui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { WysiwygEditor, FONT_FAMILY_STRINGS } from '@groove/wysiwyg-editor';
import { useMutation } from 'react-query';
import { useGetOrgSettings } from '@groove/api/hooks/useUsers';
import {
  TemplateParams,
  templateCreate,
  templateUpdate,
} from '@groove/api/gateway/v1/template';
import useUserMergeFields from '@groove/api/hooks/useUserMergeFields';
import {
  DropDownItem,
  StandardDropDownItem,
} from '@groove/ui/Components/DropDownList';
import PicklistNew from '@groove/ui/Components/PicklistNew';
import Input from '@groove/ui/Components/Input';

import {
  TEMPLATES_ACTION_TYPE,
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  TEMPLATE_EDITOR_CLOSED_VIA,
  trackTemplatesEvent,
} from './analytics/templatesEvents';

import './assets/icons.js';

import useStore from './store/useStore';
import buildFolderDropdownByOwner from './buildFolderDropdownByOwner';

export type TemplateEditorAction = 'EDIT' | 'CREATE';

type TemplateEditorProps = {
  action: TemplateEditorAction;
  templateId?: number;
  initialFolderId: number;
  initialName: string;
  initialSubject: string;
  initialBody: string;
  dismissEditor: () => void;
  refetchOnSuccess: () => void;
};

const TemplateEditor: FC<TemplateEditorProps> = ({
  action,
  templateId,
  initialFolderId,
  initialName,
  initialSubject,
  initialBody,
  dismissEditor,
  refetchOnSuccess,
}) => {
  const templateFolders = useStore(store => store.templateFolders);
  const [selectedFolderId, setSelectedFolderId] = useState(
    initialFolderId || templateFolders.data[0]?.id,
  );
  const selectedTemplate = useStore(store => store.selectedTemplate);
  const templateType = useStore(store => store.templateType);

  const foldersToShow =
    templateFolders.data.length > 0 ? templateFolders.data : undefined;

  const selectedFolder = templateFolders?.data?.find(
    folder => folder.id === selectedFolderId,
  );

  const dropdownOptions: DropDownItem[] = foldersToShow
    ? buildFolderDropdownByOwner(
        foldersToShow.filter(folder => !!folder.is_editable),
      )
    : [];

  const [showSavedText, setShowSavedText] = useState<boolean | undefined>(
    false,
  );

  const {
    mutateAsync: updateTemplate,
    isLoading: updateLoading,
    isSuccess: updateSuccess,
    isError: updateError,
  } = useMutation(templateUpdate);
  const {
    mutateAsync: createTemplate,
    isLoading: createLoading,
    isSuccess: createSuccess,
    isError: createError,
  } = useMutation(templateCreate);

  const [editorTemplateBody, setEditorTemplateBody] = useState<string>(
    initialBody || '',
  );

  const mutationLoading = updateLoading || createLoading;
  const mergeFields = useUserMergeFields();

  useEffect(() => {
    const handleMergeFieldInserted = (event: Event): void => {
      const mergeFieldEvent = event as CustomEvent;
      trackTemplatesEvent({
        eventName: TEMPLATES_EVENT_TYPE.MERGE_FILED_INSERTED,
        templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
        templateActionType:
          action === 'CREATE'
            ? TEMPLATES_ACTION_TYPE.NEW
            : TEMPLATES_ACTION_TYPE.EDIT,
        fallbackAdded: mergeFieldEvent.detail.fallbackAdded,
        mergeField: mergeFieldEvent.detail.mergeField,
      });
    };
    window.addEventListener('merge-field-inserted', handleMergeFieldInserted);
    return () =>
      window.removeEventListener(
        'merge-field-inserted',
        handleMergeFieldInserted,
      );
    // Only create listener on mount. No need to add dependencies
  }, []); // eslint-disable-line

  const handleSaveTemplate = useCallback(
    values => {
      const mergedValues: TemplateParams = {
        ...values,
        template_folder_id: selectedFolderId,
        html_body: editorTemplateBody,
        template_type: templateType,
      };
      if (action === 'CREATE') {
        createTemplate(mergedValues);
      } else {
        updateTemplate(mergedValues);
      }
    },
    [
      action,
      createTemplate,
      editorTemplateBody,
      selectedFolderId,
      updateTemplate,
      templateType,
    ],
  );

  const {
    handleSubmit: handleFormSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      template_id: templateId || selectedTemplate?.id,
      name: initialName,
      subject: initialSubject,
    },
  });

  useEffect(() => {
    if (updateSuccess || createSuccess) {
      setShowSavedText(true);
      setTimeout(() => {
        refetchOnSuccess();
        dismissEditor();
        trackTemplatesEvent({
          eventName: TEMPLATES_EVENT_TYPE.SAVED,
          templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
          templateActionType:
            action === 'CREATE'
              ? TEMPLATES_ACTION_TYPE.NEW
              : TEMPLATES_ACTION_TYPE.EDIT,
          success: true,
          templateFolder: selectedFolder?.name || '',
        });
      }, 1500);
    }
  }, [
    action,
    createSuccess,
    dismissEditor,
    refetchOnSuccess,
    selectedFolder?.name,
    updateSuccess,
  ]);

  useEffect(() => {
    if (updateError || createError) {
      trackTemplatesEvent({
        eventName: TEMPLATES_EVENT_TYPE.SAVED,
        templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
        templateActionType:
          action === 'CREATE'
            ? TEMPLATES_ACTION_TYPE.NEW
            : TEMPLATES_ACTION_TYPE.EDIT,
        success: false,
        templateFolder: selectedFolder?.name || '',
      });
    }
  }, [updateError, createError, action, selectedFolder?.name]);

  const isOutlook = useStore(store => store.isOutlook);
  const fontFamily = isOutlook
    ? FONT_FAMILY_STRINGS.CALIBRI_FAMILY
    : FONT_FAMILY_STRINGS.ARIAL_FAMILY;
  const fontSize = isOutlook ? '12pt' : '13px';
  const { data } = useGetOrgSettings();
  const isTrueAttachment = !!data?.direct_attachment_enabled;

  return (
    <form onSubmit={handleFormSubmit(handleSaveTemplate)}>
      <div className="flex flex-col p-3 pt-2">
        {/* Template Folder and Template Name */}
        <div className="flex w-full">
          <div className="flex flex-col w-[49%] flex-1">
            <Text className="font-semibold text-sm text-black mb-1">
              Folder
            </Text>
            <PicklistNew
              selectedValues={
                dropdownOptions.filter(
                  folder => folder.key === selectedFolderId,
                ) as StandardDropDownItem[] | []
              }
              onChange={value => {
                setSelectedFolderId(value?.key as number);
              }}
              items={dropdownOptions}
            />
          </div>

          <div className="flex flex-col w-full pl-3 flex-1">
            <Text className="font-semibold text-sm text-black mb-1">Name</Text>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  aria-label="Template Name"
                  aria-invalid={errors.name ? 'true' : 'false'}
                  id="name"
                  className={`h-[30px] ${errors.name && 'border-error/600'}`}
                  placeholder="Ex. Demo follow-up"
                />
              )}
            />
            {errors.name && (
              <Text className="text-xs pt-1 text-error/600" role="alert">
                Name cannot be empty
              </Text>
            )}
          </div>
        </div>
        {/* Email Subject */}
        <div className="flex flex-col w-full pt-3">
          <Text className="font-semibold text-sm text-black mb-1">Subject</Text>
          <Controller
            name="subject"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                aria-label="Template Subject"
                className={`h-[30px] ${errors.subject && 'border-error/600'}`}
              />
            )}
          />
          {errors.subject && (
            <Text className="text-xs pt-1 text-error/600" role="alert">
              Subject cannot be empty
            </Text>
          )}
        </div>
        {/* Email Body */}
        <div className="flex-1 pt-3">
          <Text className="font-semibold text-sm text-black">Body</Text>
          <div className="mt-1">
            <WysiwygEditor
              config={{
                height: '340px',
                init_instance_callback: editor => {
                  editor.getBody().style.fontFamily = fontFamily;
                  editor.getBody().style.fontSize = fontSize;
                  editor.execCommand('fontName', false, fontFamily);
                  editor.execCommand('fontSize', false, fontSize);
                },
              }}
              initialValue={initialBody}
              mergeFieldProps={{
                mergeFields,
              }}
              onChange={setEditorTemplateBody}
              isOutlook={isOutlook}
              trueAttachmentsProps={{
                isTrueAttachments: isTrueAttachment,
              }}
            />
          </div>
        </div>
        {/* Buttons */}
        <div className="flex flex-row-reverse items-center pt-3">
          <PrimaryButton
            className="ml-2 text-white bg-clari-blue/600 hover:bg-clari-blue/700 active:bg-clari-blue/800 rounded disabled:text-neutral/400 disabled:bg-neutral/75"
            type="submit"
            text="Save Template"
            aria-label="Save Template"
            disabled={showSavedText}
          />
          <DefaultButton
            text="Close"
            aria-label="Dismiss Editor"
            className="text-neutral/900 border-neutral/200 bg-white hover:border-neutral/900 hover:bg-white rounded"
            onClick={() => {
              dismissEditor();
              trackTemplatesEvent({
                eventName: TEMPLATES_EVENT_TYPE.EDITOR_CLOSED,
                templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
                templateActionType:
                  action === 'CREATE'
                    ? TEMPLATES_ACTION_TYPE.NEW
                    : TEMPLATES_ACTION_TYPE.EDIT,
                closedVia: TEMPLATE_EDITOR_CLOSED_VIA.CLOSE_BUTTON,
              });
            }}
          />
          {mutationLoading && (
            <Spinner
              aria-label="Saving"
              className="items-start pl-2 mr-2"
              size={SpinnerSize.medium}
            />
          )}
          {showSavedText && (
            <Icon
              iconName="CompletedSolid"
              aria-label="Saved"
              className="pl-2 mr-2 text-xl transition-opacity duration-200 text-green"
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default TemplateEditor;
