import { Record, Map } from 'immutable';

const defaults = {
  id: null,
  company: '',
  name: '',
  title: '',
  email: '',
  emails: Map(),
  phones: Map(),
  sfdcId: '',
  phone: '',
  mobilePhone: '',
  tzid: '',
  effortAndEngagementRollup: [],
  engagementScore: null,
};

export default class Person extends Record(defaults) {
  static from(person, emails = null, phones = null) {
    const sfdcFields = person.get('sfdcFields') || new Map();
    return new Person({
      id: person.get('id'),
      company: person.get('company'),
      name: person.get('name'),
      title: person.get('title'),
      email: sfdcFields?.get('email') || person.get('email'),
      emails:
        emails ??
        Map({ Email: sfdcFields?.get('email') || person.get('email') }),
      sfdcId: person.get('sfdcId'),
      tzid: person.get('tzid'),
      phone: sfdcFields.get('phone'),
      mobilePhone: sfdcFields.get('mobilePhone'),
      phones:
        phones ??
        Map({
          Phone: sfdcFields.get('phone'),
          MobilePhone: sfdcFields.get('mobilePhone'),
        }),
      engagementScore: person.get('engagementScore'),
      ...(person.has('effortAndEngagementRollup') && {
        effortAndEngagementRollup: person.get('effortAndEngagementRollup'),
      }),
    });
  }
}
