import { createBrowserHistory as createHistory } from 'history';
import createSentryMiddleware from 'redux-sentry-middleware';
import LogRocket from 'logrocket';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { actionTypes as errorActionTypes } from 'Modules/Shared/actions/errors';
import { fromJS } from 'immutable';

let store = null; // eslint-disable-line
export const history = createHistory();

export default function getOrConfigureStore({
  initialState,
  createRootReducer,
  SagaManager,
  Sentry,
} = {}) {
  // Memoize store so that a new one is not created by HMR
  if (store) return store;

  if (process.env.NODE_ENV === 'test') {
    // eslint-disable-next-line global-require, import/no-extraneous-dependencies
    const configureMockStore = require('redux-mock-store').default;
    const mockStore = configureMockStore();
    const initialState = fromJS({});
    return mockStore(initialState);
  }

  const sentryMiddleware = createSentryMiddleware(Sentry, {
    stateTransformer: state =>
      state.filter((value, key) =>
        [
          'form',
          'ui',
          'app',
          'productUsage',
          state.getIn(['app', 'location', 'routeName']),
          ...state.getIn(['app', 'location', 'previousRouteNames']).toJS(),
        ].includes(key)
      ),
  });

  const composeEnhancers =
    (typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const sagaMiddleware = createSagaMiddleware({
    onError: error => {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(
          'onError was hit. Please look into this; errors should normally not bubble up this far',
          error
        );
        Sentry.captureException(error);
      }
      store.dispatch({
        type: errorActionTypes.LOG_ERROR,
        payload: { error },
      });
    },
  });

  // Will log all actions and state change to LogRocket and remove undefined keys
  const logRocketMiddleware = LogRocket.reduxMiddleware({
    stateSanitizer(state) {
      return {
        ...state,
        salesforceMeta: undefined,
      };
    },
  });

  store = {
    ...createStore(
      createRootReducer(),
      initialState,
      composeEnhancers(
        applyMiddleware(sagaMiddleware, sentryMiddleware, logRocketMiddleware)
      )
    ),
    runSaga: sagaMiddleware.run,
  };

  store.asyncReducers = [];

  store.addAsyncReducer = (name, asyncReducer) => {
    store.asyncReducers[name] = asyncReducer;
    store.replaceReducer(createRootReducer(store.asyncReducers));
  };

  SagaManager.startSagas(sagaMiddleware);

  // TODO: Why do we do this?
  store.close = () => store.dispatch(END);

  return store;
}
