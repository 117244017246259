import { Record } from 'immutable';
import moment from 'moment';
import { PRIORITIES } from 'Modules/Shared/constants';

const defaults = {
  id: null,
  creatorId: null,
  assigneeId: null,
  createdAt: null,
  completedAt: null,
  dueAt: null,
  sfdcId: null,
  priority: PRIORITIES.NONE,
  description: '',
};

export default class Task extends Record(defaults) {
  static from({ dueAt, createdAt, completedAt, ...rest }) {
    return new Task({
      ...rest,
      ...(dueAt && { dueAt: moment(dueAt) }),
      ...(createdAt && { createdAt: moment(createdAt) }),
      ...(completedAt && { completedAt: moment(completedAt) }),
    });
  }
}
