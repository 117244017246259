export const styles = {
  actionsRoot: {
    padding: 24,
    height: 'calc(100% - 48px)',
    display: 'flex',
    flexDirection: 'column',
  },
  iframe: {
    position: 'relative',
  },
  root: {
    position: 'relative',
    height: '100%',
  },
  errorContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 24,
    justifyContent: 'flex-start',
  },
  pausedFlowContainer: {
    position: 'absolute',
    right: 22,
    top: 55,
  },
  fadeContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
};
