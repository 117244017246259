import {
  ChangeEvent,
  useState,
  KeyboardEvent,
  FocusEventHandler,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import cx from 'classnames';
import Input from '@groove/ui/Components/Input';
import Text from '@groove/ui/Components/Text';
import hasElementLostFocused from '@groove/ui/helpers/hasElementLostFocus';
import { VariableSizeList } from 'react-window';
import { findOrCreatePerson } from '@groove/api/gateway/v1/people';
import { FullAction } from '@groove/api/gateway/v1/actionCompose';
import MultiSelectDropDown from '@groove/ui/Components/MultiSelectDropDown';
import Item, { StandardDropDownItem } from '@groove/ui/Components/DropDownItem';
import { useQuery } from 'react-query';
import { grooveContactLeadSearch } from '@groove/api/visualforce/grooveSearch';
import transformGrooveEngineResult from '@groove/search-and-select/transformGrooveEngineResults';
import { CheckmarkCircle } from '@groove/ui/Components/BoogieIcon';
import {
  getNextSelectableIndex,
  getPrevSelectableIndex,
} from '@groove/ui/utils/dropDownKeyInputHelpers';
import { Spinner } from '@fluentui/react';
import SelectTag, { SelectItem } from '@groove/ui/Components/SelectTag';
import { useDebounce } from 'use-debounce';

import useStore from '../store/useStore';
import {
  addSFDCObjectToOmnibar,
  setAutoSalesforceWhos,
} from '../utils/loggingToMethods';
import transformGrooveSearch, {
  getSfdcTypeIcon,
} from '../utils/transformGrooveSearch';
import { getOptOutValues, syncWhoData } from '../utils/syncWhoData';
import useGrooveMeta from '../hooks/useGrooveMeta';
import { RECIPIENT_NOT_FOUND } from '../constants';

export type EmailRecipientFieldRef = {
  focusInput: () => void;
};

type EmailRecipientFieldProps = {
  fieldLabel: string;
  fieldKey: 'toRecipients' | 'ccRecipients' | 'bccRecipients';
  disableFirst?: boolean;
};

const EmailRecipientField = forwardRef<
  EmailRecipientFieldRef,
  EmailRecipientFieldProps
>(({ fieldLabel, fieldKey, disableFirst }, forwardedRef) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropDownRef = useRef<VariableSizeList<any>>(null);
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  const [queryString, setQueryString] = useState<string>('');
  const [showList, setShowList] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [itemClicked, setItemClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rawItemList, setItemList] = useState<StandardDropDownItem[]>([]);
  const recipients = useStore(store => store.action[fieldKey]);
  const { data: grooveMeta } = useGrooveMeta();

  // adding debouncer so it doesn't make multiple unnecessary search calls
  const [debouncedQueryString] = useDebounce(queryString, 250);

  const selectedHash: { [key: string]: boolean } = {};
  recipients?.forEach(value => (selectedHash[value.sfdcId || ''] = true));

  const itemList =
    isLoading || queryString.length < 3
      ? []
      : rawItemList.map(item => {
          if (item.type || !selectedHash[item.value]) return item;

          return {
            ...item,
            disabled: true,
            icon: <CheckmarkCircle />,
          };
        });

  useImperativeHandle(forwardedRef, () => ({
    focusInput: () => {
      inputRef.current?.focus();
      setShowList(true);
    },
  }));

  const renderedRecipients = recipients?.map(recipient => {
    return {
      name: recipient.name,
      displayText: recipient.name || recipient.email,
      sfdcId: recipient.sfdcId,
      email: recipient.email,
      emailField: recipient.emailField,
      type: recipient.attributes?.type,
      items: recipient.emails?.map(email => ({
        key: email.field || email.value,
        value: email.value,
        text: email.value,
        secondaryText: email.label,
      })),
    };
  });

  useQuery(
    ['email-recipient', fieldKey, debouncedQueryString],
    () => grooveContactLeadSearch(queryString),
    {
      enabled: queryString.length > 2,
      onSettled: () => setIsLoading(false),
      onSuccess: data => {
        let items = transformGrooveEngineResult(data || [])
          .flat?.()
          .map(transformGrooveSearch);
        if (/^.+@.+\.[\w-]{2,4}$/.test(queryString) && items.length === 0)
          items = [
            {
              key: queryString,
              value: queryString,
              text: queryString,
              icon: getSfdcTypeIcon('Unknown'),
              tooltip: RECIPIENT_NOT_FOUND,
              metaData: {
                email: queryString,
                name: '',
                type: 'Unknown',
              },
            },
          ];
        setItemList(items);
        setIsLoading(false);
      }, // Doesn't need cache or stale time since the search is always cleared to nil and we disable when the string is less than 2 long
    },
  );

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value.length > 2) setIsLoading(true);
    setQueryString(value);
  };

  const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    let newIndex;
    event.stopPropagation();
    if (event.key === 'Enter' && highlightedIndex > -1) {
      const selectItem = itemList[highlightedIndex];
      if (itemList.length > 0 && !selectItem.type && !selectItem.disabled)
        onItemSelect(selectItem);
    } else if (event.key === 'ArrowDown') {
      if (highlightedIndex + 1 >= itemList.length)
        newIndex = getNextSelectableIndex(itemList, 0);
      else newIndex = getNextSelectableIndex(itemList, highlightedIndex + 1);
      setHighlightedIndex(newIndex);
      dropDownRef.current?.scrollToItem(newIndex);
    } else if (event.key === 'ArrowUp') {
      if (highlightedIndex <= 0)
        newIndex = getPrevSelectableIndex(itemList, itemList.length - 1);
      else newIndex = getPrevSelectableIndex(itemList, highlightedIndex - 1);
      setHighlightedIndex(newIndex);
      dropDownRef.current?.scrollToItem(newIndex);
    }
  };

  // This is to make sure that the height of the individual components are rendered
  // correctly
  useEffect(() => {
    dropDownRef.current?.resetAfterIndex(0, true);
  }, [itemList.length]);

  useEffect(() => {
    if (itemClicked && !queryString) {
      inputRef?.current?.focus();
      setItemClicked(false);
    }
  }, [inputRef, itemClicked, queryString]);

  const onInputFocus: FocusEventHandler<HTMLInputElement> = () => {
    if (queryString) setShowList(true);
  };

  const onToClick = (): void => {
    if (inputRef.current) inputRef.current.focus();
  };

  const onItemSelect = async (item: StandardDropDownItem): Promise<void> => {
    setQueryString('');
    setHighlightedIndex(-1);
    setItemClicked(true);

    try {
      const cachedRecipients = [...(recipients || [])];

      // This is to make sure that we have the value shown to the end user immediately
      // So that they don't have to wait for the API call to see the recipient
      useStore.getState().updateAction({
        [fieldKey]: [
          ...cachedRecipients,
          {
            id: '',
            sfdcId: '',
            email: item.metaData?.email,
            name: item.metaData?.name,
            attributes: { type: item.metaData?.type },
          },
        ],
      });

      const sfdcId = (item.value || '') as string;
      const person = await findOrCreatePerson(sfdcId);
      if (!person) return;

      syncWhoData([sfdcId], grooveMeta);

      const updateAction: Partial<FullAction> = {
        [fieldKey]: [
          ...cachedRecipients,
          {
            email: person.email,
            name: person.name,
            id: person.id,
            doNotEmail:
              getOptOutValues(person.sfdcId, person.sfdcData)?.doNotEmail ||
              person.sfdcData.hasOptedOutOfEmail,
            doNotCall:
              getOptOutValues(person.sfdcId, person.sfdcData)?.doNotCall ||
              person.sfdcData.doNotCall,
            doNotSms:
              getOptOutValues(person.sfdcId, person.sfdcData)?.doNotSms ||
              person.sfdcData.hasOptedOutOfSms,
            phone: person.phone,
            attributes: person.sfdcData.attributes,
            sfdcId: person.sfdcId,
          },
        ],
      };

      if (
        (!recipients || recipients?.length === 0) &&
        fieldKey === 'toRecipients'
      ) {
        updateAction.personId = person.id;
        updateAction.who = {
          company: person.companyName,
          doNotCall:
            getOptOutValues(person.sfdcId, person.sfdcData)?.doNotCall ||
            person.sfdcData.doNotCall,
          doNotEmail:
            getOptOutValues(person.sfdcId, person.sfdcData)?.doNotEmail ||
            person.sfdcData.hasOptedOutOfEmail,
          doNotSms:
            getOptOutValues(person.sfdcId, person.sfdcData)?.doNotSms ||
            person.sfdcData.hasOptedOutOfSms,
          email: person.email,
          firstName: person.sfdcData.firstName,
          lastName: person.sfdcData.lastName,
          id: person.id,
          mobilePhone: person.mobilePhone,
          name: person.name,
          phone: person.phone,
          sfdcId: person.sfdcId,
          sfdcType: person.sfdcData?.attributes?.type,
          title: person.sfdcData?.title,
        };
      }

      setAutoSalesforceWhos({ addedPerson: person });
      useStore.getState().updateAction(updateAction);
    } catch (error) {
      console.error(error);
    }
  };

  const onTagRemove = (index: number) => () => {
    if (!recipients) return;
    const actionUpdate: Partial<Omit<FullAction, 'id' | 'loggingTo'>> = {};
    const modifiedRecipients = [...recipients];
    const recipient = modifiedRecipients.splice(index, 1)[0];
    useStore.getState().updateAction({
      [fieldKey]: modifiedRecipients,
    });

    actionUpdate[fieldKey] = modifiedRecipients;

    if (modifiedRecipients.length < 1 && fieldKey === 'toRecipients') {
      actionUpdate.personId = null;
      actionUpdate.who = null;
    }

    setAutoSalesforceWhos({ removedSfdcId: recipient.sfdcId });
    useStore.getState().updateAction(actionUpdate);
  };

  const onChangeEmail = (index: number, value: SelectItem): void => {
    let tempRecipients = [...(recipients || [])];
    const recipient = {
      ...tempRecipients[index],
      email: value.value || tempRecipients[index].email,
      emailField: value.key || tempRecipients[index].emailField,
    };

    tempRecipients = [
      ...tempRecipients.slice(0, index),
      { ...recipient },
      ...tempRecipients.slice(index + 1),
    ];

    const actionUpdate = { ...useStore.getState().action };
    actionUpdate[fieldKey] = tempRecipients;

    const { who } = actionUpdate;
    if (who && who.sfdcId === recipient.sfdcId) {
      actionUpdate.who = {
        ...who,
        email: recipient.email,
      };
    }
    useStore.getState().updateAction(actionUpdate);
  };

  const onBlur: FocusEventHandler<HTMLDivElement> = event => {
    if (hasElementLostFocused(event)) {
      setShowList(false);
    }
  };

  const tooltipRender = (
    name?: string,
    email?: string,
    type?: string,
    disabled?: boolean,
  ): string => {
    const disabledText = disabled
      ? 'Primary flow recipients cannot be removed - '
      : '';
    if (type === 'Unknown') return RECIPIENT_NOT_FOUND;
    if (name && email) return `${disabledText}${name}<${email}>`;
    if (name) return `${disabledText}${name}`;
    if (email) return `${disabledText}${email}`;
    return `${disabledText}Unknown Person`;
  };

  // Components to be rendered in Multiselect list header
  let listHeader: JSX.Element | undefined;

  const additionalListText = (
    emptyText: string | JSX.Element,
    smallBold = false,
  ): JSX.Element => (
    <div className="flex text-left w-full bg-white items-center overflow-hidden p-0 relative min-h-[32px]">
      <Text
        className={cx(
          'p-[8px] my-auto',
          smallBold && 'text-neutral/600 text-metadata-sm font-semibold',
          !smallBold && 'text-metadata text-neutral/400',
        )}
      >
        {emptyText}
      </Text>
    </div>
  );

  if (isLoading && queryString.length > 2) {
    listHeader = (
      <div
        className="flex h-[32px] justify-center"
        data-testid="loading-spinner"
      >
        <Spinner />
      </div>
    );
  } else if (itemList.length < 1) {
    if (queryString.length < 3) {
      listHeader = additionalListText('Please type to search');
    } else {
      listHeader = additionalListText('No contacts found');
    }
  }

  return (
    <div className="flex flex-1 flex-row items-center min-w-0" onBlur={onBlur}>
      <div
        onClick={onToClick}
        onKeyUp={onToClick}
        role="button"
        tabIndex={0}
        className="h-full flex items-center pr-2"
      >
        <Text className="text-body text-neutral/600 cursor-text">
          {fieldLabel}
        </Text>
      </div>
      <div className="flex-1 relative flex-row flex items-center flex-wrap pt-[4px] min-w-0">
        {renderedRecipients &&
          renderedRecipients.length > 0 &&
          renderedRecipients.map((tag, index) => (
            <div
              className="pr-2 pt-[2px] pb-[4px] min-w-0"
              key={index.toString()}
            >
              <SelectTag
                displayText={tag.displayText}
                fullWidth
                onCancel={
                  disableFirst && index === 0 ? undefined : onTagRemove(index)
                }
                onHover={() => addSFDCObjectToOmnibar(tag.sfdcId, tag.email)}
                onChange={value => onChangeEmail(index, value)}
                selectedValue={tag.items?.find(
                  item => item.key === tag.emailField,
                )}
                inValid={tag.type === 'Unknown'}
                items={tag.items || []}
                tooltip={tooltipRender(
                  tag.name,
                  tag.email,
                  tag.type,
                  disableFirst && index === 0,
                )}
              />
            </div>
          ))}
        <Input
          innerRef={inputRef}
          className="border-0 text-body flex-1 h-[36px] pl-0 focus:bg-white min-w-[60px] pt-0"
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          onFocusCapture={onInputFocus}
          value={queryString}
          autoCapitalize="off"
          autoCorrect="off"
          aria-autocomplete="list"
          variant="no-outline"
          role="combobox"
          onFocus={event => {
            if (event.target.autocomplete) {
              event.target.autocomplete = '';
            }
            setShowList(true);
          }}
        />
        <MultiSelectDropDown
          itemList={itemList}
          itemSize={index => {
            if (itemList.length < 1 || index < 0) return 0;
            const item = itemList[index];
            if (item.type === 'header') return 26;
            if (item.type === 'divider') return 4;
            return 32 + (item.secondaryText?.length || 0) * 16;
          }}
          isVariable
          showDropDown={showList}
          renderedItem={Item}
          width="100%"
          innerRef={dropDownRef}
          itemData={{
            hasSearch: true,
            highlightedIndex,
            items: itemList,
            onClick: onItemSelect,
          }}
        >
          {listHeader}
        </MultiSelectDropDown>
      </div>
    </div>
  );
});

export default EmailRecipientField;
