import { useEffect, RefObject } from 'react';

const clickHandlersMap = new Map<number, () => void>();

const useHandleOutsideClicks = (
  ref: RefObject<HTMLElement>,
  onClick: () => void,
): void => {
  useEffect(() => {
    const randomId = Math.floor(Math.random() * 1000000000); // generate a random id to map the click to
    const handleClickOutside = (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClick();
      }
    };
    clickHandlersMap.set(randomId, onClick);

    document.addEventListener('mousedown', handleClickOutside);

    const iframes = document.getElementsByClassName(
      'tox-edit-area__iframe',
    ) as HTMLCollectionOf<HTMLIFrameElement>;

    Array.from(iframes).forEach(iframe => {
      iframe.contentWindow?.document.addEventListener(
        'mousedown',
        handleClickOutside,
      );
    });

    return () => {
      const iframes = document.getElementsByClassName(
        'tox-edit-area__iframe',
      ) as HTMLCollectionOf<HTMLIFrameElement>;

      Array.from(iframes).forEach(iframe => {
        iframe.contentWindow?.document.removeEventListener(
          'mousedown',
          handleClickOutside,
        );
      });

      document.removeEventListener('mousedown', handleClickOutside);
      clickHandlersMap.delete(randomId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const fireAllOutsideCLickHandlers = (): void => {
  clickHandlersMap.forEach((value, _key) => {
    value();
  });
};

export default useHandleOutsideClicks;
