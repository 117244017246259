import { FC } from 'react';
import { CLARI_COLORS } from 'theme-constants';

type SalesforceIconProps = {
  fill?: string;
  className?: string;
};
const SalesforceIcon: FC<SalesforceIconProps> = ({
  className,
  fill = CLARI_COLORS['clari-blue/600'],
}) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.34132 1.60205C2.45653 1.60205 1.73926 2.31932 1.73926 3.20412V16.0207C1.73926 16.9055 2.45653 17.6227 3.34133 17.6227H16.1579C17.0427 17.6227 17.7599 16.9055 17.7599 16.0207V3.20412C17.7599 2.31932 17.0427 1.60205 16.1579 1.60205H3.34132ZM12.8159 14.9284H15.2768V11.105C15.2768 8.97516 14.065 7.98609 12.5226 7.98609C11.2794 7.98609 10.5004 8.65951 10.3564 9.13137V8.0522H7.91844C7.94986 8.62516 7.91844 14.9284 7.91844 14.9284H10.3564V11.2074L10.3561 11.1333C10.3551 10.952 10.3541 10.7761 10.4094 10.6454C10.577 10.2319 10.9371 9.80285 11.5845 9.80285C12.431 9.80285 12.8159 10.4387 12.8159 11.3701V14.9284ZM5.45299 7.19204H5.43728C4.6517 7.19204 4.14237 6.66639 4.14237 6.00009C4.14237 5.32083 4.66675 4.8062 5.46805 4.8062C6.26869 4.8062 6.76099 5.31954 6.77671 5.99815C6.77671 6.66445 6.26869 7.19204 5.45299 7.19204ZM6.59339 8.05219H4.42321V14.9284H6.59339V8.05219Z"
      fill={fill}
      className="fill-current"
    />
  </svg>
);

export default SalesforceIcon;
