import React, { memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  chip: {
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    minWidth: 50,
    margin: '5px 15px 0px 0px',
    height: 16,
    borderRadius: 3,
    background: theme.palette.text.secondary,
    padding: '4px 6px',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  chipText: {
    color: theme.palette.text.neutralColor,
    fontSize: 12,
    width: '100%',
    textAlign: 'center',
  },
});

const ShowChipsButton = ({ classes, filterCount, onClick }) => (
  // eslint-disable-next-line global-require, jsx-a11y/interactive-supports-focus
  <div className={classes.chip} onClick={onClick} role="button">
    <Typography className={classes.chipText}>
      {filterCount} {pluralize('filter', filterCount)}
    </Typography>
  </div>
);

ShowChipsButton.displayName = 'ShowChipsButton';
ShowChipsButton.propTypes = {
  classes: PropTypes.shapeOf({
    chip: PropTypes.string.isRequired,
    chipText: PropTypes.string.isRequired,
  }).isRequired,
  filterCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(memo(ShowChipsButton));
