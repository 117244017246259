import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const SearchIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <path d="M9.309 10.016a4.5 4.5 0 1 1 .707-.707l3.838 3.837a.5.5 0 0 1-.708.708L9.31 10.016zM10 6.5a3.5 3.5 0 1 0-7 0 3.5 3.5 0 0 0 7 0z" />
  </svg>
);
