// Group of functions that check if a JWT is valid
import jwtDecode from 'jwt-decode';

interface DecodedJWT {
  exp: number;
}

/**
 * Check if the provided JWT has expired or is about to expire within the given offset
 * @param {string} jwt The JWT string
 * @param {number} offset Accepts positive integers and represents number of seconds
 * before the expire time do you want this function to return true. By default this param is zero
 * @returns Returns false if the token has not expired or has not reached the offset cutoff.
 * Returns true otherwise
 */
export default function isJWTExpired(jwt: string, offset = 0): boolean {
  const decodedJWT: DecodedJWT = jwtDecode(jwt);
  if (decodedJWT.exp - offset <= Date.now() / 1000) {
    return true;
  }
  return false;
}
