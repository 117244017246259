import { put, call, delay } from 'redux-saga/effects';
import { serviceConnectionSuccessful } from 'Modules/Troubleshooting/actions';
import { refresh } from 'GrooveHTTPClient/salesforceMeta';
import { addJob } from 'Modules/Shared/actions/batchJobs';
import { watchJob } from 'Modules/Shared/sagas/batchJobs';
import {
  SERVICE_CONNECTION_TYPES,
  REFRESH_SFDC_META_JOB_NAME,
} from 'Modules/Troubleshooting/constants';

export default function* refreshSfdcMeta() {
  yield delay(300);

  const response = yield call(refresh);

  const {
    data: { batchId },
  } = response;

  yield put(
    addJob({
      id: batchId,
      name: REFRESH_SFDC_META_JOB_NAME,
    })
  );

  yield call(watchJob, batchId);

  yield put(
    serviceConnectionSuccessful({
      healthCheck: SERVICE_CONNECTION_TYPES.sfdcMeta,
    })
  );
}
