import React from 'react';
import PropTypes from 'prop-types';
import ClariTheme from 'Utils/theme/ClariTheme';

const CancelIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 141 47"
    preserveAspectRatio="xMinYMin slice"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0
      1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm0
      10.293l5.293-5.293.707.707-5.293 5.293 5.293 5.293-.707.707-5.293-5.293-5.293
      5.293-.707-.707 5.293-5.293-5.293-5.293.707-.707 5.293 5.293z"
      id="cancel-icon"
      fill={ClariTheme.palette.text.primary}
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

CancelIcon.displayName = 'Cancel';
CancelIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

CancelIcon.defaultProps = {
  width: 30,
  height: 30,
};

export default CancelIcon;
