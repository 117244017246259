import { FC, useRef, useState, useEffect } from 'react';
import {
  Dropdown,
  IDropdownOption,
  IconButton,
  TooltipHost,
  Text,
  ResponsiveMode,
} from '@fluentui/react';
import useUserGet from '@groove/api/hooks/useUserGet';
import { SelectedActionsFilter } from '@groove/api/services/v1/actions';
// @groove/ui
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';

import PicklistDropdown from '../PicklistDropdown';
import useHandleOutsideClicks from '../../helpers/useHandleOutsideClicks';

import useStore from './store/useStore';
import { formatActionFilters } from './stickyFilters';
import useGetTimezoneOptions from './hooks/useGetTimezoneOptions';
import useGetAssigneesOptions from './hooks/useGetAssigneesOptions';
import useGetCreatorsOptions from './hooks/useGetCreatorsOptions';
// NB: Modified to handle visual differences here and omnibar: showing text
import FilterMenuButton from './FilterMenuButton';
import {
  PRIORITY_FILTER,
  SOURCE_FILTER,
  SOURCE_FILTER_WITH_PLAYS,
  SMART_ACTION_FORRESTER_FEATURE_FLAG,
  SMART_PRIORITY_FILTER,
  PLAYS_FEATURE_FLAG,
  TYPE_FILTER,
  DUE_FILTER,
} from './constants';
import useUpdateFilters from './hooks/useUpdateFilters';

interface FilterMenuOption extends IDropdownOption {
  secondaryText?: string;
}

const tooltipStyles = { root: 'pr-[2px]' };
const btnStyles = { icon: 'text-xs font-semibold' };

type ActionFilterParams = {
  showFilterText?: boolean;
};

export const ActionFilter: FC<ActionFilterParams> = ({
  showFilterText = false,
}) => {
  const smartPrioritiesEnabled = useVariation(
    SMART_ACTION_FORRESTER_FEATURE_FLAG,
  );
  const playsEnabled = useVariation(PLAYS_FEATURE_FLAG);

  const pendingFilters = useStore(store => store.pendingFilters);
  const selectedFilters = useStore(store => store.selectedFilters);
  const filterCount = useStore(store => store.filterCount);
  const setPendingFilters = useStore(store => store.setPendingFilters);
  const setSelectedFilters = useStore(store => store.setSelectedFilters);
  const setFilterCount = useStore(store => store.setFilterCount);
  const setActionFilters = useStore(store => store.setActionFilters);

  const { data: user } = useUserGet();
  const updateFilters = useUpdateFilters();
  const stickyFilters = user?.data?.settings?.sticky_filters_omnibar;
  const timezoneFilter = useGetTimezoneOptions();
  const assignees = useGetAssigneesOptions();
  const creators = useGetCreatorsOptions();

  useEffect(() => {
    if (stickyFilters) {
      const actionFilters = stickyFilters as SelectedActionsFilter;
      const formattedActionFilters = formatActionFilters(stickyFilters);
      setPendingFilters(actionFilters);
      setSelectedFilters(actionFilters);
      setActionFilters(formattedActionFilters);
    }
  }, [setActionFilters, setPendingFilters, setSelectedFilters, stickyFilters]);

  useEffect(() => {
    if (selectedFilters) {
      const newSelectedFilters = { ...selectedFilters };
      // search should not count as part of the filters
      delete newSelectedFilters.search;
      const filterSerializer = [].concat(...Object.values(newSelectedFilters));

      setFilterCount(filterSerializer.length);
    }
  }, [setFilterCount, selectedFilters]);

  const closeMenu = (): void => {
    setPendingFilters({});
    setShowDropdown(!showDropdown);
  };

  const onChange = (
    type: keyof SelectedActionsFilter,
    option?: IDropdownOption,
  ): void => {
    const selected = option?.selected;
    let newFilter = [...(pendingFilters[type] || [])];

    if (option) {
      newFilter = selected
        ? [...newFilter, option.key as string]
        : newFilter.filter(k => k !== option.key);
    }

    setPendingFilters({ ...(pendingFilters || {}), [type]: newFilter });
  };

  const onSave = (): void => {
    const filtersToSave = formatActionFilters(pendingFilters);

    // pending filters should not overwrite search
    setSelectedFilters({
      ...pendingFilters,
      search: selectedFilters.search,
    });
    setActionFilters(filtersToSave);
    updateFilters.mutate(pendingFilters);
    setShowDropdown(false);
  };

  const ActionFiltersMenu: FC<{
    items: IDropdownOption[];
    type: keyof SelectedActionsFilter;
    placeholder?: string;
    label?: string;
    ariaLabel: string;
  }> = ({ items, type, placeholder, ariaLabel, label }) => {
    const renderOption = (option: FilterMenuOption): JSX.Element => {
      if (option.secondaryText) {
        return (
          <div className="flex-col py-3">
            <div className="text-[10px] leading-snug">{option.text}</div>
            <div className="text-[8px] text-gray0 leading-snug">
              {option.secondaryText}
            </div>
          </div>
        );
      }
      return <div className="py-3">{option.text}</div>;
    };

    let multiSelectEnabled = true;

    // handle when actions do not have filter specific data.
    let dropdownValue = items;
    if (items === undefined || !items.length) {
      dropdownValue = [
        {
          key: `${placeholder}-undetected`,
          ariaLabel: `${placeholder}-undetected`,
          text: `No results found`,
        },
      ];
      multiSelectEnabled = false;
    }

    return (
      <Dropdown
        className="p-1 z-[1000003]"
        label={label}
        aria-label={ariaLabel}
        placeholder={placeholder}
        multiSelect={multiSelectEnabled}
        options={dropdownValue}
        onChange={(_, option) => onChange(type, option)}
        defaultSelectedKeys={pendingFilters[type] as string[]}
        responsiveMode={ResponsiveMode.large}
        onRenderOption={renderOption}
      />
    );
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useHandleOutsideClicks(ref, () => {
    setShowDropdown(false);
  });

  return (
    <div className={`flex ${filterCount > 0 ? 'w-16' : 'w-8'}`}>
      <div
        className="flex cursor-pointer"
        aria-label="Action filter"
        role="button"
        tabIndex={0}
        onClick={(): void => {
          setShowDropdown(!showDropdown);
        }}
        onKeyDown={event =>
          event.key === 'Enter' && setShowDropdown(!showDropdown)
        }
      >
        <FilterMenuButton
          totalFilters={filterCount}
          showDropdown={showDropdown}
          showFilterText={showFilterText}
        />
      </div>
      {filterCount > 0 && (
        <TooltipHost
          id="reset-filter-tooltip"
          styles={tooltipStyles}
          content={<Text>Clear all filters</Text>}
        >
          <IconButton
            aria-label="Clear all filters"
            className="w-6 h-full rounded-l-none rounded-r bg-clari-blue/600 hover:bg-clari-blue/700 border-[1px] border-clari-blue/600 border-solid hover:border-clari-blue/700 text-white hover:text-white"
            iconProps={{ iconName: 'Cancel' }}
            styles={btnStyles}
            onClick={() => {
              setPendingFilters({});
              // clearing the filters shouldn't clear search filters
              setSelectedFilters({ search: selectedFilters.search });
              setActionFilters({});
              updateFilters.mutate({});
            }}
          />
        </TooltipHost>
      )}
      <PicklistDropdown
        showShadow
        showDropDown={showDropdown}
        width={240}
        xDirection="left-0"
        yDirectionOuter="top-8"
        yDirectionInner="top-0"
        onClose={closeMenu}
        onSave={onSave}
      >
        <ActionFiltersMenu
          ariaLabel="due_at-filter"
          label="Due"
          type="due_at"
          items={DUE_FILTER.data}
        />
        <ActionFiltersMenu
          ariaLabel="priority-filter"
          label="Priority"
          type="priority"
          items={PRIORITY_FILTER.data}
        />
        {smartPrioritiesEnabled && (
          <ActionFiltersMenu
            ariaLabel="smart-priority-filter"
            label="Smart Priority"
            type="smart_priority"
            items={SMART_PRIORITY_FILTER.data}
          />
        )}
        <ActionFiltersMenu
          ariaLabel="source-filter"
          label="Source"
          type="source"
          items={
            playsEnabled ? SOURCE_FILTER_WITH_PLAYS.data : SOURCE_FILTER.data
          }
        />
        <ActionFiltersMenu
          ariaLabel="timezone-filter"
          label="Timezone"
          type="timezone"
          items={timezoneFilter.data}
        />
        <ActionFiltersMenu
          ariaLabel="type-filter"
          label="Type"
          type="type"
          items={TYPE_FILTER.data}
        />
        <ActionFiltersMenu
          ariaLabel="creator-filter"
          label="Assigned by"
          type="creator"
          items={creators}
        />
        <ActionFiltersMenu
          ariaLabel="assignees-filter"
          label="Assigned to"
          type="assignee"
          items={assignees}
        />
      </PicklistDropdown>
    </div>
  );
};

export default ActionFilter;
