import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SPACES_IMPORT: {
    ...keyMirror([
      'RESET_REDUCER',
      'TOGGLE_OBJECT_SELECTIONS',
      'CLEAR_SELECTED_OBJECTS',
    ]),
    SEARCH_BY_NAME: standardActions(),
    SEARCH_BY_FIELD: standardActions(),
    SEARCH_WITH_OPPORTUNITIES: standardActions(),
    SEARCH_BY_REPORT: standardActions(),
    FETCH_USERS: standardActions(),
    BATCH_CREATE: standardActions(),
  },
}).SPACES_IMPORT;

export const resetReducer = () => ({
  type: actionTypes.RESET_REDUCER,
});

export const toggleObjectSelections = selectedObjectIds => ({
  type: actionTypes.TOGGLE_OBJECT_SELECTIONS,
  payload: selectedObjectIds,
});

export const searchByName = ({ name }) => ({
  type: actionTypes.SEARCH_BY_NAME.BEGIN,
  payload: { name },
});

export const searchByReport = ({ sfdcReportId }) => ({
  type: actionTypes.SEARCH_BY_REPORT.BEGIN,
  payload: { sfdcReportId },
});

export const batchCreate = sObjects => ({
  type: actionTypes.BATCH_CREATE.BEGIN,
  payload: sObjects,
});

export const clearSelectedObjects = () => ({
  type: actionTypes.CLEAR_SELECTED_OBJECTS,
});
