import Configuration from '../Configuration';

export type ApiUploadResponse = {
  location: string;
};

const uploadFile = (file: File): Promise<ApiUploadResponse> => {
  const formData = new FormData();
  formData.append('file', file);

  return Configuration.client
    .post('api/v2/uploads/file', {
      body: formData,
    })
    .json<ApiUploadResponse>();
};

export default uploadFile;
