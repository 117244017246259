import * as Login from 'Modules/Login';
import * as Error from 'Modules/Error';
import RefreshSalesforceMeta from 'Modules/Shared/components/RefreshSalesforceMeta';
import refreshSalesforceMeta from 'Modules/Shared/sagas/refreshSalesforceMeta';
import { redirectHandler } from 'Modules/Shared/sagas/redirect';
import { TABS as SETTINGS_TABS } from 'Modules/Settings/constants';
import roles from 'Modules/Shared/constants/roles';
import ActionLoader from 'Modules/Shared/components/ActionsRouteLoader';
import OutcomesLoader from 'Modules/Outcomes/components/OutcomesRouteLoader';
import WorkStepLoader from 'Modules/Shared/components/WorkStepRouteLoader';
import { ACTION_PREFIX as ACTION_COMPOSE_ACTION_PREFIX } from 'Modules/ActionCompose/constants';
import EmailsModuleRoot from 'Modules/Emails/containers/ModuleRoot';
import Route from './Route';
import {
  ACCOUNTS_ROUTE_NAME,
  SPACES_SHOW_ROUTE_NAME,
  ROUTE_NAMES,
} from './constants';
import { isFlowUiEnabled } from 'Modules/Flows/utils';

// These may be referenced outside of the routing configuration to determine if a pattern matches
// a value selected out of the app.location reducer.
//
// ORDER MATTERS! Register more specific routes before more ambiguous ones.
export const routeConfiguration = [
  new Route({
    routeName: 'home',
    pattern: '/',
    loader: () => import(/* webpackChunkName: "home" */ 'Modules/Home'),
    actionPrefix: 'HOME',
  }),
  new Route({
    routeName: ROUTE_NAMES.ACTIONS,
    pattern: '/actions',
    loader: () => import(/* webpackChunkName: "actions" */ 'Modules/Actions'),
    actionPrefix: 'ACTIONS',
    loadingComponent: ActionLoader,
    disableModuleRootTransition: true,
    children: [
      new Route({
        routeName: ROUTE_NAMES.ACTION_COMPOSE,
        pattern: '/compose',
        loader: () =>
          import(
            /* webpackChunkName: "actionCompose" */ 'Modules/ActionCompose'
          ),
        actionPrefix: ACTION_COMPOSE_ACTION_PREFIX,
        hideLayout: true,
        disableModuleRootTransition: true,
        requireAuth: false,
      }),
    ],
  }),
  new Route({
    routeName: ROUTE_NAMES.EMAILS,
    pattern: '/emails',
    loader: () => import(/* webpackChunkName: "emails" */ 'Modules/Emails'),
    actionPrefix: 'EMAILS',
    hideLayout: true,
    loadingComponent: EmailsModuleRoot,
    disableModuleRootTransition: true,
  }),
  new Route({
    routeName: ROUTE_NAMES.ACTIVITY_LOG,
    pattern: '/activity-log',
    loader: () =>
      import(/* webpackChunkName: "activityLog" */ 'Modules/ActivityLog'),
    actionPrefix: 'ACTIVITY_LOG',
  }),
  new Route({
    routeName: 'flows',
    pattern: '/flows',
    loader: () => import(/* webpackChunkName: "flows" */ 'Modules/Flows'),
    actionPrefix: 'FLOWS',
    tabs: [
      {
        label: 'Active',
        value: 'active',
        shouldRender: () => !isFlowUiEnabled(),
      },
      {
        label: 'Due',
        value: 'due',
        shouldRender: () => !isFlowUiEnabled(),
      },
      {
        label: 'Shared',
        value: 'shared',
        shouldRender: () => !isFlowUiEnabled(),
      },
      {
        label: 'Archived',
        value: 'archived',
        shouldRender: () => !isFlowUiEnabled(),
      },
      {
        label: 'All',
        value: 'all',
        shouldRender: () => !isFlowUiEnabled(),
      },
    ],
    children: [
      new Route({
        routeName: 'flowsWizard',
        pattern: '/wizard',
        loader: () =>
          import(/* webpackChunkName: "flowsWizard" */ 'Modules/FlowsWizard'),
        actionPrefix: 'FLOWS_WIZARD',
      }),
      new Route({
        routeName: 'flowsShow',
        pattern: '/:id/:tabName?',
        loader: () =>
          import(/* webpackChunkName: "flowsShow" */ 'Modules/FlowsShow'),
        actionPrefix: 'FLOWS_SHOW',
      }),
    ],
  }),
  new Route({
    routeName: 'workStep',
    pattern: '/steps/:id/work',
    loader: () => import(/* webpackChunkName: "workStep" */ 'Modules/WorkStep'),
    loadingComponent: WorkStepLoader,
    disableModuleRootTransition: true,
    actionPrefix: 'WORKSTEP',
  }),
  new Route({
    routeName: 'templates',
    pattern: '/templates',
    loader: () =>
      import(/* webpackChunkName: "templates" */ 'Modules/Templates'),
    actionPrefix: 'TEMPLATES',
  }),
  new Route({
    routeName: ROUTE_NAMES.SHARED_CONVERSATION,
    pattern: '/shared_conversation/:id',
    loader: () =>
      import(
        /* webpackChunkName: "CISharedRecording" */ 'Modules/Conversations/SharedRecording'
      ),
    actionPrefix: 'CONVERSATIONS_SHARED_RECORDING',
    hideLayout: true,
    disableModuleRootTransition: true,
    requireAuth: false,
  }),
  new Route({
    routeName: 'conversations',
    pattern: '/conversations',
    loader: () =>
      import(/* webpackChunkName: "conversations" */ 'Modules/Conversations'),
    actionPrefix: 'CONVERSATIONS',
    children: [
      new Route({
        routeName: 'conversationsShow',
        pattern: '/:id',
        loader: () =>
          import(
            /* webpackChunkName: "conversationsShow" */ 'Modules/Conversations/Show'
          ),
        actionPrefix: 'CONVERSATIONS_SHOW',
      }),
      new Route({
        routeName: 'conversationsShowPopup',
        pattern: '/popup/:id',
        loader: () =>
          import(
            /* webpackChunkName: "CIPopup" */ 'Modules/Conversations/Popup'
          ),
        actionPrefix: 'CONVERSATIONS_SHOW_POPUP',
        hideLayout: true,
        disableModuleRootTransition: true,
        requireAuth: false,
      }),
    ],
  }),
  new Route({
    routeName: 'analytics',
    pattern: '/analytics',
    // we changed this name because analytics was triggering a false positive in adblockers
    loader: () =>
      import(/* webpackChunkName: "groove-analytics" */ 'Modules/Analytics'),
    actionPrefix: 'ANALYTICS',
    tabs: [
      { label: 'ACTIVITY', value: 'activities' },
      { label: 'FLOWS', value: 'flows' },
      { label: 'TEMPLATES', value: 'templates' },
      { label: 'EMAILS', value: 'emails' },
      { label: 'CALLS', value: 'calls' },
      {
        label: 'PROCESS',
        value: 'process',
        shouldRender: ({ currentUser }) =>
          currentUser &&
          (currentUser.get('role') !== 'agent' ||
            currentUser.getIn(['orgSettings', 'teamAnalyticsVisible'])),
      },
    ],
  }),
  new Route({
    routeName: 'outcomes',
    pattern: '/outcomes',
    loader: () => import(/* webpackChunkName: "outcomes" */ 'Modules/Outcomes'),
    loadingComponent: OutcomesLoader,
  }),
  new Route({
    routeName: 'lightningout',
    pattern: '/lightningout',
    loader: () =>
      import(/* webpackChunkName: "plays" */ 'Modules/LightningOut'),
    requireAuth: false,
    hideLayout: true,
  }),
  new Route({
    routeName: ROUTE_NAMES.MOBILE_WYSIWYG,
    pattern: '/mobile_wysiwyg',
    loader: () =>
      import(/* webpackChunkName: "mobileWysiwyg" */ 'Modules/MobileWysiwyg'),
    requireAuth: false,
    hideLayout: true,
  }),
  new Route({
    routeName: 'playbooks',
    pattern: '/playbooks',
    loader: () => import(/* webpackChunkName: "plays" */ 'Modules/Plays'),
    children: [
      new Route({
        routeName: 'playsShow',
        pattern: '/:id',
        loader: () =>
          import(/* webpackChunkName: "playsShow" */ 'Modules/Plays/show'),
        actionPrefix: 'Plays_SHOW',
      }),
      new Route({
        routeName: 'playsHistory',
        pattern: '/:playbookid/plays/:playid/history',
        loader: () =>
          import(
            /* webpackChunkName: "playsHistory" */ 'Modules/Plays/history/list'
          ),
        actionPrefix: 'Plays_History_LIST',
      }),
      new Route({
        routeName: 'playsHistoryDetails',
        pattern: '/:playbookid/plays/:playid/history/:changeEventId',
        loader: () =>
          import(
            /* webpackChunkName: "playsHistory" */ 'Modules/Plays/history/details'
          ),
        actionPrefix: 'Plays_History_DETAILS',
      }),
    ],
  }),
  new Route({
    routeName: ACCOUNTS_ROUTE_NAME,
    pattern: '/accounts',
    loader: () => import(/* webpackChunkName: "accounts" */ 'Modules/Accounts'),
    actionPrefix: 'ACCOUNTS',
    tabs: [
      { label: 'Accounts', value: 'my' },
      { label: 'Starred Accounts', value: 'starred' },
    ],
  }),
  new Route({
    routeName: 'scheduledEmails',
    pattern: '/scheduled_emails',
    loader: () =>
      import(
        /* webpackChunkName: "scheduledEmails" */ 'Modules/ScheduledEmails'
      ),
    actionPrefix: 'SCHEDULED_EMAILS',
  }),
  new Route({
    routeName: 'emailReminders',
    pattern: '/email_reminders',
    loader: () =>
      import(/* webpackChunkName: "emailReminders" */ 'Modules/EmailReminders'),
    actionPrefix: 'EMAIL_REMINDERS',
  }),
  new Route({
    routeName: 'people',
    pattern: '/people',
    loader: () => import(/* webpackChunkName: "people" */ 'Modules/People'),
    actionPrefix: 'PEOPLE',
    tabs: [
      { label: 'My People', value: 'my_people_only' },
      { label: 'My Hotlist', value: 'my_hotlist' },
      // { label: 'All People in Organization', value: 'all_people_in_organization' }, // disabled in relation to issue #6816
    ],
  }),
  new Route({
    routeName: 'settings',
    pattern: '/settings',
    loader: () => import(/* webpackChunkName: "settings" */ 'Modules/Settings'),
    actionPrefix: 'SETTINGS',
    tabs: [
      { label: 'Settings', value: SETTINGS_TABS.MY_SETTINGS },
      {
        label: 'Manage My Org',
        value: SETTINGS_TABS.MANAGE_MY_ORG,
        shouldRender: ({ currentUser }) =>
          currentUser && currentUser.get('role') === roles.ADMIN,
      },
      {
        label: 'Manage My Teams',
        value: SETTINGS_TABS.MANAGE_MY_TEAMS,
        shouldRender: ({ currentUser }) =>
          currentUser && currentUser.get('role') === roles.TEAM_LEAD,
      },
    ],
  }),
  new Route({
    routeName: 'spaces',
    pattern: '/spaces',
    loader: () =>
      import(/* webpackChunkName: "spacesIndex" */ 'Modules/Spaces/index'),
    actionPrefix: 'SPACES',
    children: [
      new Route({
        routeName: SPACES_SHOW_ROUTE_NAME,
        pattern: '/:id',
        loader: () =>
          import(/* webpackChunkName: "spacesShow" */ 'Modules/Spaces/show'),
        actionPrefix: 'SPACES_SHOW',
      }),
    ],
  }),
  new Route({
    routeName: 'coaching',
    pattern: '/coaching',
    loader: () =>
      import(/* webpackChunkName: "coaching" */ 'Modules/Coaching/index'),
    actionPrefix: 'COACHING',
  }),
  new Route({
    routeName: 'troubleshooting',
    pattern: '/troubleshooting',
    loader: () =>
      import(
        /* webpackChunkName: "troubleshooting" */ 'Modules/Troubleshooting/index'
      ),
    actionPrefix: 'TROUBLESHOOTING',
  }),
  new Route({
    routeName: 'login',
    pattern: '/login',
    requireAuth: false,
    upstartSaga: Login.sagas.loginRedirect,
  }),
  new Route({
    routeName: 'loginCallback',
    pattern: '/login/callback',
    upstartSaga: Login.sagas.loginCallbackHandler,
    requireAuth: false,
  }),
  new Route({
    routeName: 'setupWizard',
    pattern: '/setup',
    requireAuth: false,
    hideLayout: true,
  }),
  new Route({
    routeName: 'error',
    pattern: '/error',
    moduleRoot: Error.ModuleRoot,
    upstartSaga: Error.sagas.upstart,
    requireAuth: false,
  }),
  new Route({
    routeName: 'refreshSalesforceMeta',
    pattern: '/refresh_salesforce_meta',
    moduleRoot: RefreshSalesforceMeta,
    upstartSaga: refreshSalesforceMeta,
    requireAuth: false,
  }),
  new Route({
    routeName: 'redirect',
    pattern: '/redirect',
    upstartSaga: redirectHandler,
    requireAuth: false,
  }),
  new Route({
    routeName: 'onboarding',
    pattern: '/onboarding',
    loader: () =>
      import(
        /* webpackChunkName: "onboardingLanding" */ 'Modules/Onboarding/landing'
      ),
    requireAuth: false,
    hideLayout: true,
    actionPrefix: 'ONBOARDING_LANDING',
    children: [
      new Route({
        routeName: 'onboardingSteps',
        pattern: '/steps',
        loader: () =>
          import(
            /* webpackChunkName: "onboardingSteps" */ 'Modules/Onboarding/steps'
          ),
        actionPrefix: 'ONBOARDING_STEPS',
        requireAuth: false,
        hideLayout: true,
      }),
      new Route({
        routeName: 'microsoftOnboardingSteps',
        pattern: '/microsoft_steps',
        loader: () =>
          import(
            /* webpackChunkName: "microsoftOnboardingSteps" */ 'Modules/Onboarding/microsoft_steps'
          ),
        actionPrefix: 'MICROSOFT_ONBOARDING_STEPS',
        requireAuth: false,
        hideLayout: true,
      }),
    ],
  }),
  new Route({
    routeName: ROUTE_NAMES.SALESNAV_CONNECTED,
    pattern: '/salesnavconnected',
    loader: () =>
      import(
        /* webpackChunkName: "salesNavigatorConnected" */ 'Modules/SalesNavigatorConnected'
      ),
    actionPrefix: 'SALES_NAVIGATOR',
    requireAuth: false,
    hideLayout: true,
  }),
  new Route({
    routeName: 'notFound',
    pattern: '*',
    loader: () =>
      import(/* webpackChunkName: "not-found" */ 'Modules/NotFound'),
    requireAuth: false,
  }),
];
