import { createActions } from '@groove-labs/action-utils';
import { FSA } from 'flux-standard-action';

const types = {
  SHARED: {
    ERRORS: {
      LOG_ERROR: 'LOG_ERROR',
      SHOW_REPORT_DIALOG: 'SHOW_REPORT_DIALOG',
    },
  },
};

export const actionTypes = createActions<typeof types>(types).SHARED.ERRORS;

export type LogErrorPayload = {
  error: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  isUiError: boolean;
  retryCount: number;
  errorType: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  logSilently: boolean;
};

export function logError({
  error,
  isUiError,
  retryCount,
  errorType,
  logSilently,
}: LogErrorPayload): FSA<string, LogErrorPayload> {
  return {
    type: actionTypes.LOG_ERROR,
    payload: {
      error,
      isUiError,
      retryCount,
      errorType,
      logSilently,
    },
  };
}

export function triggerShowReportDialog(): FSA {
  return {
    type: actionTypes.SHOW_REPORT_DIALOG,
  };
}
