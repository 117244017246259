import { SelectedItem } from '../useStore';
import { SelectedItem as SearchAndSelectSelectedItem } from '../../SearchAndSelect';

export type Activity = {
  names: SelectedItem[];
  relatedTo: SelectedItem | null;
};

const deriveActivities = (
  names: SelectedItem[],
  relatedTos: SelectedItem[],
): Activity[] => {
  let activities: Activity[] = [];

  if (names.length === 0 && relatedTos.length === 0) {
    return activities;
  }

  if (names.length === 0) {
    activities = relatedTos.map(relatedTo => ({
      names: [],
      relatedTo,
    }));
  }

  if (relatedTos.length === 0) {
    activities.push({
      names,
      relatedTo: null,
    });
  }

  if (names.length > 0 && relatedTos.length > 0) {
    relatedTos.forEach(relatedTo => {
      activities.push({
        names,
        relatedTo,
      });
    });
  }

  return activities;
};
export const deriveNamesAndRelatedTosFromSearchAndSelectSelectedItems = (
  selectedItems: SearchAndSelectSelectedItem[],
): { names: SelectedItem[]; relatedTos: SelectedItem[] } => {
  let names: SelectedItem[] = [];
  let relatedTos: SelectedItem[] = [];
  selectedItems.forEach(selectedItem => {
    selectedItem.text = selectedItem.name;
    if (['Contact', 'Lead'].includes(selectedItem.type)) {
      names = [...names, selectedItem as SelectedItem];
      relatedTos = [
        ...relatedTos,
        ...(Object.values(selectedItem.subItems || {})?.map(item => ({
          ...item,
          text: item.name || '',
        })) as SelectedItem[]),
      ];
    } else {
      relatedTos = [...relatedTos, selectedItem as SelectedItem];
      names = [
        ...names,
        ...(Object.values(selectedItem.subItems || {})?.map(item => ({
          ...item,
          text: item.name || '',
        })) as SelectedItem[]),
      ];
    }
  });

  return {
    names,
    relatedTos,
  };
};

export const deriveSearchAndSelectSelectedItemsFromNamesAndRelatedTos = (
  names: SelectedItem[],
  relatedTos: SelectedItem[],
): { [key: string]: SearchAndSelectSelectedItem } => {
  const selectedItems: { [key: string]: SearchAndSelectSelectedItem } = {};
  if (relatedTos.length === 0) {
    names.forEach(name => {
      selectedItems[name.id] = {
        id: name.id,
        name: name.text,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        type: name.type!,
        text: name.text,
        secondaryText: name.secondaryText,
        tertiaryText: name.tertiaryText,
        quarteraryText: name.quarteraryText,
        quinaryText: name.quinaryText,
      };
    });
    return selectedItems;
  }

  relatedTos.forEach(relatedTo => {
    selectedItems[relatedTo.id] = {
      id: relatedTo.id,
      name: relatedTo.text,
      text: relatedTo.text,
      secondaryText: relatedTo.secondaryText,
      tertiaryText: relatedTo.tertiaryText,
      quarteraryText: relatedTo.quarteraryText,
      quinaryText: relatedTo.quinaryText,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      type: relatedTo.type!,
      subItems: names.reduce((acc, name) => {
        acc[name.id] = {
          id: name.id,
          name: name.text,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          type: name.type!,
          text: name.text,
          secondaryText: name.secondaryText,
          tertiaryText: name.tertiaryText,
          quarteraryText: name.quarteraryText,
          quinaryText: name.quinaryText,
        };
        return acc;
      }, {} as Record<string, SearchAndSelectSelectedItem>),
    };
  });

  return selectedItems;
};

export default deriveActivities;
