import getClient, { TunnelStrategy, strategy } from './getClient';
import { ApiGrooveContext } from './grooveContext';
import GrooveSearch from './types/GrooveSearch';

export type ApiGrooveSearch = GrooveSearch;
export type GrooveSearchParams = {
  q: string;
};

export const grooveSearch = (
  q?: string | null,
  sobj?: string | null,
  useGrooveViewObjects?: boolean,
  orderBy?: string | null,
  orderDir?: string | null,
  myRecordsOnly?: boolean | null,
  openOpportunitiesOnly?: boolean | null,
  contactLeadSearch?: boolean | null,
): Promise<ApiGrooveSearch> =>
  getClient()
    .get((strategy as TunnelStrategy)?.path || 'api/v2/visualforce/tunnel', {
      searchParams: {
        page: 'GrooveSearch',
        ...(q && { q }),
        ...(useGrooveViewObjects && { omnibar: true }),
        ...(sobj && { sobj }),
        ...(orderBy && { orderBy }),
        ...(orderDir && { orderDir }),
        ...(myRecordsOnly !== null && myRecordsOnly !== undefined
          ? { myRecordsOnly }
          : {}),
        ...buildOpportunitiesOnlyOption(openOpportunitiesOnly),
        ...(contactLeadSearch !== null && contactLeadSearch !== undefined
          ? { contactLeadSearch }
          : {}),
      },
    })
    .json();

export const grooveWhoSearch = (
  whoId: string | null,
  whatType: string | null = null,
): Promise<ApiGrooveContext> =>
  getClient()
    .get((strategy as TunnelStrategy)?.path || 'api/v2/visualforce/tunnel', {
      searchParams: {
        page: 'GrooveSearch',
        ...(whoId && { whoId }),
        ...(whatType && { whatType }),
      },
    })
    .json();

export const grooveWhatSearch = (
  whatId: string | null,
  whatType: string | null = null,
): Promise<ApiGrooveSearch> =>
  getClient()
    .get((strategy as TunnelStrategy)?.path || 'api/v2/visualforce/tunnel', {
      searchParams: {
        page: 'GrooveSearch',
        ...(whatId && { whatId }),
        ...(whatType && { whatType }),
      },
    })
    .json();

const buildOpportunitiesOnlyOption = (
  openOpportunitiesOnly: boolean | null | undefined,
): { openOpportunitiesOnly?: boolean; opportunityFilter?: string } => {
  // We need to pass both parameters to provide backward compatibility for Manage Packages.
  if (openOpportunitiesOnly === true) {
    return {
      openOpportunitiesOnly,
      opportunityFilter: 'OPEN',
    };
  }

  return {};
};

export const grooveContactLeadSearch = (
  q: string,
): Promise<ApiGrooveSearch | null> =>
  grooveSearch(q, null, false, null, null, null, null, true);

export default grooveSearch;
