import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderRow = () => {
  return (
    <ContentLoader height="112">
      <rect x="22" y="16" rx="2" ry="2" width="150" height="16" />
      <rect x="22" y="38" rx="2" ry="2" width="250" height="16" />
      <rect x="22" y="60" rx="2" ry="2" width="190" height="16" />
      <rect x="22" y="86" rx="2" ry="2" width="195" height="12" />
    </ContentLoader>
  );
};

export default LoaderRow;
