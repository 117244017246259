import {
  all,
  call,
  fork,
  select,
  takeLatest,
  takeEvery,
  put,
} from 'redux-saga/effects';
import { getImportableRows } from 'Modules/PeopleImportDialog/submodules/peopleTable/selectors';
import {
  actionTypes,
  loadData,
  storeEngagedFlows,
  unselectAllRows,
  engagedFlowsRequestFailure,
  engagedFlowsRequestProgress,
} from 'Modules/PeopleImportDialog/submodules/peopleTable/actions';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import * as Sentry from '@sentry/browser';
import HTTPError from 'GrooveHTTPClient/HTTPError';

import { searchEngagedWithByEmailOrSfdcId } from 'GrooveHTTPClient/flows';

import listToOrderedMap from 'Utils/list-to-ordered-map';

function* handleSelectAllRows() {
  // select All rows should not select hidden rows, this is specialised handling of that case
  const selectRows = yield select(getImportableRows);
  const nonBlockedRows = selectRows.filter(row => !row.get('checkboxDisabled'));
  yield put(
    loadData({
      selectedRows: listToOrderedMap(nonBlockedRows, row => row.get('id')),
    })
  );
}

function* handleHideDuplicatesToggled() {
  // whenever we toggle between show/hide duplicates, we need to clear selection in order not to have
  // hidden rows selected.
  yield put(unselectAllRows());
}

function* handleFetchEngagedFlows({ payload }) {
  const { personEmail, personSfdcId } = payload;

  try {
    yield put(engagedFlowsRequestProgress({ personSfdcId }));
    const response = yield call(searchEngagedWithByEmailOrSfdcId, {
      email: personEmail,
      sfdcId: personSfdcId,
    });

    const flows = response.data ? response.data : [];
    yield put(storeEngagedFlows(personSfdcId, flows));
  } catch (e) {
    let message;
    if (e instanceof HTTPError) {
      message = e.response.getIn(['message', 'data', 'message']);
    } else {
      message = 'Could not get flows person is engaged in';
    }
    yield put(
      engagedFlowsRequestFailure({
        message,
        personSfdcId,
      })
    );
    yield put(pushSnackbarMessage({ message }));

    Sentry.withScope(scope => {
      scope.setExtra('sagaStack', e.sagaStack);
      Sentry.captureException(e.stack);
    });
  }
}

// -------------- Watchers --------------
function* watchPeopleImportSelectAll() {
  yield takeLatest(actionTypes.SELECT_ALL_ROWS, handleSelectAllRows);
}

function* watchToggleHideDuplicates() {
  yield takeEvery(
    actionTypes.TOGGLE_HIDE_DUPLICATE_SEARCH_RESULTS,
    handleHideDuplicatesToggled
  );
}

function* watchFetchEngagedFlows() {
  yield takeEvery(
    actionTypes.FETCH_ENGAGED_FLOWS.BEGIN,
    handleFetchEngagedFlows
  );
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([
    fork(watchPeopleImportSelectAll),
    fork(watchToggleHideDuplicates),
    fork(watchFetchEngagedFlows),
  ]);
}
