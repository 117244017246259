import { stepMapSubtypes } from 'Modules/FlowsShow/utils';
import { List, fromJS } from 'immutable';
import Step from 'Modules/Shared/records/Step';
import StepVariant from 'Modules/Shared/data/StepVariant';

// TODO Make this take asc/desc
export const sortIntraDaySteps = (stepA, stepB) => {
  if (stepA.get('intraDayOrder') < stepB.get('intraDayOrder')) {
    return -1;
  }
  if (stepA.get('intraDayOrder') > stepB.get('intraDayOrder')) {
    return 1;
  }
  return 0;
};

export const sortSteps = (stepA, stepB) => {
  if (stepA.get('dayNumber') < stepB.get('dayNumber')) {
    return -1;
  }
  if (stepA.get('dayNumber') > stepB.get('dayNumber')) {
    return 1;
  }
  if (stepA.get('dayNumber') === stepB.get('dayNumber')) {
    return sortIntraDaySteps(stepA, stepB);
  }
  return 0;
};

const extractOrderedSteps = stepsResponse => {
  const mappedStepsResponse = stepsResponse.data.map(stepMapSubtypes);
  return fromJS(mappedStepsResponse)
    .map(step => {
      const variants = step
        .get('variants', List())
        .map(variant => StepVariant.from(variant.toJS()));
      return new Step(fromJS({ ...step.toJS(), variants }));
    })
    .sort(sortSteps);
};

export default extractOrderedSteps;
