import React, { MouseEventHandler, useState } from 'react';
import sharedStyles from 'Modules/Actions/containers/Table/styles';
import SmartPriorityChipFactory from 'Modules/ActionsRewrite/components/customCells/SmartPriority/SmartPriorityChip/SmartPriorityChipFactory';
import HoverCard from 'Modules/ActionsRewrite/components/customCells/SmartPriority/HoverCard/hoverCard';

const styles = {
  root: {
    ...sharedStyles.cell,
    width: 155,
    alignItems: 'center',
  },
};

type SmartPriorityParams = {
  smartPriority: number;
  actionId: number;
};

// TODO: WFA-118. Use Iris's Hovercard component
const SmartPriority: React.FC<SmartPriorityParams> = ({
  smartPriority,
  actionId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen: MouseEventHandler<HTMLParagraphElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose: MouseEventHandler<
    HTMLParagraphElement
  > = _event => {
    setAnchorEl(null);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div style={styles.root}>
      <p onMouseOver={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        <SmartPriorityChipFactory
          actionId={actionId}
          smartPriority={smartPriority}
        />
        {Boolean(anchorEl) && (
          <HoverCard
            anchorEl={anchorEl}
            actionId={actionId}
            smartPriority={smartPriority}
          />
        )}
      </p>
    </div>
  );
};

export default SmartPriority;
