import { createConsumer } from '@rails/actioncable';

type ContextChangePayload = {
  type: 'CONTEXT_CHANGE';
  person: {
    sfdcId: string;
  };
};

type CallLoggedPayload = {
  type: 'CALL_LOGGED';
  actionId: string | null;
  person: {
    id: string | null;
    sfdcId: string | null;
  };
};

export type ExtensionDialerPayload = ContextChangePayload | CallLoggedPayload;

export default createConsumer(
  `wss://${new URL(process.env.NX_GROOVE_ENGINE_ROOT_URL!).host}/cable`,
);
