import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import cx from 'classnames';
import { useQueryClient } from 'react-query';
import {
  useGetCurrentUser,
  useUserAdditionalLoggingFields,
} from '@groove/api/hooks/useUsers';
import { useIdentifyMixpanelUser } from '@groove/api/analytics/hooks/useMixpanel';
import { motion } from 'framer-motion';
import useGatewayLaunchDarkly from '@groove/api/hooks/launchdarkly/useGatewayLaunchDarkly';
import useGrooveMessageEvent from '@groove/api/hooks/useGrooveMessageEvent';
import useGlobalBlockWarnings from '@groove/api/hooks/useGlobalBlockWarnings';
import { useAvailablePicklistValues } from '@groove/api/hooks/useAvailablePicklistValues';
import { useFindOrCreatePerson } from '@groove/api/hooks/useFindOrCreatePerson';
import useUserMergeFields from '@groove/api/hooks/useUserMergeFields';
import tinymce from 'tinymce';
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';

import useStore from './store/useStore';
import {
  ACTION_ORIGIN,
  ActionComposeEnum,
  IMPORT_RULES_FF,
  LOAD_ACTION_COMPOSE,
  PICKLIST_TYPE,
  SET_ACTION_COMPOSE_STATE,
  UPDATE_ACTION_COMPOSE,
} from './constants';
import Header from './container/Header';
import MainContainer from './container/MainContainer';
import ExecutionBar from './container/ExecutionBar';
import ModalPortal from './component/ModalPortal';
import useActionMutating from './hooks/useActionMutating';
import useGrooveMeta from './hooks/useGrooveMeta';
import useExternalMetadata from './hooks/useExternalMetadata';
import MessageBarContainer from './container/MessageBarContainer';
import { CompleteType, LoadAction, UpdateExistingAction } from './types';
import updateExistingActionHandler from './utils/updateExistingActionHandler';
import loadActionHandler from './utils/loadActionHandler';
import DialerInActionLoader from './component/dialer/DialerInActionLoader';

/**
 * Or you can use these forward refs to control Action Compose
 */
export type ActionComposeRef = {
  loadAction: (action: LoadAction) => void;
  updateExistingAction: (action: UpdateExistingAction) => void;
  setActionComposeState: (action: ActionComposeEnum) => void;
};

type ActionComposeStyleOverriders = {
  headerStyles?: string;
};

export type ActionComposeProps = {
  showHeader: boolean;
  onComplete?: (id?: string, type?: CompleteType) => void;
  onClose?: () => void;
  onLoadingComplete?: (complete: boolean) => void;
  initialState?: ActionComposeEnum;
  initialAction?: LoadAction;
  saveAndCloseOnly?: true;
  invokingApp: ACTION_ORIGIN;
  styles?: ActionComposeStyleOverriders;
  enableImportRules?: boolean;
};
const ActionCompose = forwardRef<ActionComposeRef, ActionComposeProps>(
  (
    {
      showHeader,
      onComplete,
      onClose,
      initialState,
      initialAction,
      saveAndCloseOnly,
      onLoadingComplete,
      invokingApp,
      styles,
      enableImportRules,
    },
    ref,
  ) => {
    const [personSfdcId, setPersonSfdcId] = useState('');
    const [recipientSfdcIds, setRecipientSfdcIds] = useState<string[]>([]);
    const status = useStore(store => store.actionComposeStatus);
    const isFetching = useStore(store => store.otherValues.isFetching);
    const flowId = useStore(store => store.action.flow.id?.toString()) || null;
    const toRecipients = useStore(store => store.action.toRecipients);
    const ccRecipients = useStore(store => store.action.ccRecipients);
    const bccRecipients = useStore(store => store.action.bccRecipients);

    // Getting the current query client
    const client = useQueryClient();

    // Prefetch the following useQuery items
    useGrooveMeta();
    useAvailablePicklistValues(PICKLIST_TYPE);
    useIdentifyMixpanelUser(); // necessary for the web-app
    useGatewayLaunchDarkly();
    useGrooveMessageEvent();
    useGetCurrentUser();
    useUserAdditionalLoggingFields();
    useFindOrCreatePerson(personSfdcId);
    useUserMergeFields();

    const dialerInActionEnabled = useVariation('dialer-in-action');

    useEffect(() => {
      const peopleToImport = [
        ...(toRecipients || []),
        ...(ccRecipients || []),
        ...(bccRecipients || []),
      ];

      const newRecipientSfdcIds: string[] = [];
      peopleToImport.forEach(person => {
        if (person.sfdcId) {
          newRecipientSfdcIds.push(person.sfdcId);
        }
      });

      if (
        newRecipientSfdcIds &&
        newRecipientSfdcIds.join('-') !== recipientSfdcIds.join('-')
      ) {
        setRecipientSfdcIds(newRecipientSfdcIds);
      }
    }, [toRecipients, ccRecipients, bccRecipients, recipientSfdcIds]);

    const importRulesFFEnabled = useVariation(IMPORT_RULES_FF);
    const globalBlockWarningsEnabled =
      !!enableImportRules || !!importRulesFFEnabled;
    // Resets global block validation for each action
    useEffect(() => {
      useStore.getState().clearErrorByPrefix('block-warning');
      if (globalBlockWarningsEnabled) {
        useStore.getState().addExecutionError({ id: 'block-initial-load' });
      } else {
        useStore.getState().clearErrorByPrefix('block-initial-load');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipientSfdcIds, globalBlockWarningsEnabled]);

    // Fetches global block warnings and performs validation
    useGlobalBlockWarnings({
      flowId,
      recipientSfdcIds,
      addExecutionError: useStore.getState().addExecutionError,
      addMessage: useStore.getState().addMessage,
      clearErrorByPrefix: useStore.getState().clearErrorByPrefix,
      enabled: globalBlockWarningsEnabled,
    });

    const { isFetching: isLoadingMetadata } = useExternalMetadata();
    const isLoading = useActionMutating() || isFetching || isLoadingMetadata;

    // Forward ref version of Action Compose Methods
    useImperativeHandle(ref, () => ({
      loadAction: action => {
        if (action.personSfdcId) setPersonSfdcId(action.personSfdcId);
        if (action) loadActionHandler({ action, client, invokingApp });
      },
      updateExistingAction: action => {
        if (action.personSfdcId) setPersonSfdcId(action.personSfdcId);
        if (action) updateExistingActionHandler({ action, client });
      },
      setActionComposeState: status =>
        useStore.getState().setActionComposeStatus(status),
    }));

    // Listener  version of Action Compose Methods
    useEffect(() => {
      const updateListener = (message: MessageEvent): void => {
        // Listen for changes when there is LOAD_ACTION_COMPOSE message
        if (message?.data?.type === LOAD_ACTION_COMPOSE) {
          const action = message?.data?.payload as LoadAction;
          if (action.personSfdcId) setPersonSfdcId(action.personSfdcId);
          if (action) loadActionHandler({ action, client, invokingApp });
        }
        // Listen for changes when there is UPDATE_ACTION_COMPOSE message
        if (message?.data?.type === UPDATE_ACTION_COMPOSE) {
          const action = message?.data?.payload as UpdateExistingAction;
          // skip RECORD_CREATED as this will create duplicate person in the DB
          if (action.personSfdcId && action.message !== 'RECORD_CREATED')
            setPersonSfdcId(action.personSfdcId);
          if (action) updateExistingActionHandler({ action, client });
        }
        // Listen for changes when there is SET_ACTION_COMPOSE_STATE message
        if (message?.data?.type === SET_ACTION_COMPOSE_STATE) {
          const status = message?.data?.payload as ActionComposeEnum;
          useStore.getState().setActionComposeStatus(status);
        }
      };
      const closeWysiwygMenu = (): void => {
        if (tinymce?.activeEditor?.queryCommandState('ToggleToolbarDrawer'))
          tinymce.activeEditor.execCommand('ToggleToolbarDrawer');
      };
      window.addEventListener('message', updateListener);
      window.addEventListener('click', closeWysiwygMenu);
      return () => {
        window.removeEventListener('message', updateListener);
        window.removeEventListener('click', closeWysiwygMenu);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      onLoadingComplete?.(!isFetching);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching]);

    // Separated out the initialization the store
    useEffect(() => {
      if (initialAction)
        loadActionHandler({ action: initialAction, client, invokingApp });

      if (initialState !== undefined)
        useStore.getState().setActionComposeStatus(initialState);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleComplete = (id?: string, type?: CompleteType): void => {
      onComplete?.(id, type);
      useStore.getState().setActionComposeStatus(ActionComposeEnum.CLOSED);
    };

    // TODO: add some animations here
    if (status === ActionComposeEnum.CLOSED) return null;

    return (
      <div
        className={cx(
          'overflow-hidden flex flex-col relative bg-white',
          status === ActionComposeEnum.MINIMIZED
            ? 'h-[40px]'
            : 'h-[660px] min-h-[660px]',
          showHeader
            ? 'w-[760px] rounded-t-lg'
            : '!w-full !h-[100vh] box-border min-w-[550px]',
        )}
      >
        <Header
          showHeader={showHeader}
          headerStyles={styles?.headerStyles}
          onClose={onClose}
        />
        {dialerInActionEnabled && <DialerInActionLoader />}
        {status !== ActionComposeEnum.MINIMIZED && (
          <div className="relative flex flex-col flex-1 min-h-0">
            {isLoading && (
              <motion.div
                className="h-full w-full top-0 left-0 absolute z-[10000] bg-black"
                initial={{ opacity: 0.4 }}
                animate={{
                  opacity: [0.4, 0.6, 0.4],
                }}
                transition={{
                  duration: 3,
                  ease: 'easeInOut',
                  repeat: Infinity,
                }}
              />
            )}
            <div
              className={cx(
                'relative flex flex-col flex-1 min-h-0',
                isLoading && 'blur-[2px]',
              )}
            >
              <MessageBarContainer />
              <MainContainer />
              <ExecutionBar
                onComplete={handleComplete}
                saveAndCloseOnly={saveAndCloseOnly}
              />
              <ModalPortal />
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default ActionCompose;
