/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  all,
  fork,
  put,
  takeEvery,
  takeLatest,
  select,
  call,
  delay,
} from 'redux-saga/effects';
import { range, keyBy, isEmpty, values } from 'lodash-es';
import { actions } from '@groove-labs/groove-ui';
import { SagaIterator } from 'redux-saga';
import { FSA } from 'flux-standard-action';
import { fromJS, List, OrderedMap } from 'immutable';
import * as HttpStatusCodes from 'http-status-codes';
import HTTPError from 'GrooveHTTPClient/HTTPError';

import { ROWS_PER_REQUEST_LIMIT } from 'Modules/PeopleImportDialog/submodules/csvLookup/constants';
import {
  searchAccounts,
  getRecommendedAccounts,
  bulkCreateAsync,
  bulkCreateBatchStatus,
  createImportJob,
} from 'GrooveHTTPClient/autoCreationOfRecords';
import { importPeople } from 'GrooveHTTPClient/peopleImport';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { handleInvalidSalesforceConnectionHTTPRequest } from 'GrooveHTTPClient/sagas';
import {
  AUTO_CREATION_OF_RECORDS_DIALOG_KEY_PATH,
  SFDC_FIELD_MAPPING_DIALOG_KEY_PATH,
  AUTO_CREATION_CONFIRMATION_DIALOG_KEY_PATH,
  ADD_PEOPLE_JOB_ID_KEYPATH,
} from 'Modules/Shared/constants';
import {
  AccountSearchPayload,
  actionTypes,
  SearchPayload,
  handleRecommendedAccountSearchBegin as getAccounts,
  getFlowSettingsBegin,
  addToFlowFailure,
  addToFlowBegin,
  addToFlowSuccess,
} from 'Modules/Shared/actions/autoCreationOfRecords';
import {
  getCsvLookupHeader,
  getCsvLookupRows,
  getPeopleNotFoundInSalesforce,
  getSelectedObjectField,
  getLookupByColumnIdx,
  getSelectedSObj,
  getCsvMappedHeaders,
  makeGetCsvRows,
  isFlowShowOpen,
  isFlowWizardOpen,
  getFlowShowId,
  getFlowWizardId,
  getLeadFieldSets,
  getContactFieldSets,
  getSelectedRecords,
  getRequiredFields,
} from 'Modules/Shared/selectors/autoCreationOfRecords';
import { addJob } from 'Modules/Shared/actions/batchJobs';
import { watchJob } from 'Modules/Shared/sagas/batchJobs';
import {
  combineResponses,
  emailRegexp,
} from 'Modules/PeopleImportDialog/submodules/csvLookup/utils';
import { getFlowSettings } from 'GrooveHTTPClient/userSettings';
import {
  getAssignBasedOnUserColumn,
  getAssignBasedOnUserColumnIdx,
} from 'Modules/PeopleImportDialog/submodules/csvLookup/selectors';
import { getAssignableUsersData } from 'Modules/PeopleImportDialog/selectors';

const { setProperty } = actions.ui;

function* handleOpenDialog(): SagaIterator {
  yield put(
    setProperty({
      uiKeyPath: AUTO_CREATION_OF_RECORDS_DIALOG_KEY_PATH,
      data: true,
    })
  );

  yield put({ type: actionTypes.FETCH_DATA.BEGIN });
}

function* handleSObjChange(): SagaIterator {
  yield put({ type: actionTypes.FETCH_FIELD_SET_DATA.BEGIN });
}

function* handleCloseDialog(): SagaIterator {
  yield put(
    setProperty({
      uiKeyPath: AUTO_CREATION_OF_RECORDS_DIALOG_KEY_PATH,
      data: false,
    })
  );
}

function* handleOpenSfdcFieldMappingDialog(): SagaIterator {
  const requiredSalesforceFields = yield select(getRequiredFields);
  const csvRows = yield select(getCsvLookupRows);
  const csvHeadersMissing =
    csvRows.get(0) &&
    requiredSalesforceFields &&
    csvRows.get(0).size < requiredSalesforceFields.size;
  if (csvHeadersMissing === false) {
    yield put(
      setProperty({
        uiKeyPath: SFDC_FIELD_MAPPING_DIALOG_KEY_PATH,
        data: true,
      })
    );
  }
}

function* handleCloseSfdcFieldMappingDialog(): SagaIterator {
  yield put(
    setProperty({
      uiKeyPath: SFDC_FIELD_MAPPING_DIALOG_KEY_PATH,
      data: false,
    })
  );
}

function* handleCloseAutoCreationConfirmationModal(): SagaIterator {
  yield put(
    setProperty({
      uiKeyPath: AUTO_CREATION_CONFIRMATION_DIALOG_KEY_PATH,
      data: false,
    })
  );
}

function isFieldMapped(
  field: OrderedMap<string, string>,
  csvHeaders: List<string>,
  header: string
) {
  return (
    csvHeaders.includes(field.get('label').toLowerCase()) &&
    field.get('label').toLowerCase() === header.toLowerCase()
  );
}

function getMappedHeader(
  field: OrderedMap<string, string>,
  current: string,
  idx: number,
  mapType: string
) {
  return {
    csvHeader: current,
    label: field ? field.get('label') : null,
    name: field ? field.get('name') : null,
    idx,
    type: field ? field.get('type') : null,
    isMapped: mapType,
    length: field ? field.get('length') || field.get('maxLength') : null,
    required: field ? !field.get('nillable') : false,
    referenceTo: field ? field.get('referenceTo') : null,
    picklist: field ? field.get('picklist') : null,
  };
}

function makeIndex(
  map: OrderedMap<any, any>,
  index: number,
  headersCount: number
): number {
  /**
   * If the current index has not been set already,
   * return the current index.
   */
  if (!map.get(index)) return index;

  /**
   * Otherwise, return the first unused valid index.
   * There are at most HeaderSize such indexes.
   */
  const usedIndexes = Object.keys(map.toObject());
  const allowedIndexes = Array.from(Array(headersCount).keys());
  return allowedIndexes
    .filter(index => !usedIndexes.includes(index.toString()))
    .shift();
}

function findMappedHeaders(
  salesforceRequiredFields: OrderedMap<any, any>,
  headers: List<string>,
  salesforceObjectFields: List<OrderedMap<string, any>>,
  assignBasedOnUserColumnIdx: number | undefined
) {
  let requiredFields = salesforceRequiredFields;
  let csvHeaders = headers;

  const mappedCsvHeaders = csvHeaders.reduce((orderedMap, header, idx: any) => {
    const csvHeaderName = csvHeaders.get(idx) || '';
    const isAssignBasedOnUserColumn = assignBasedOnUserColumnIdx === idx;
    const mappedFields = salesforceObjectFields.filter(salesforceField => {
      return (
        (salesforceField.get('label').toLowerCase() ===
          csvHeaderName.toLowerCase() &&
          salesforceField.get('nillable') === true) ||
        (isAssignBasedOnUserColumn && salesforceField.get('name') === 'OwnerId')
      );
    });
    let mappedHeader: any = {};

    let field = mappedFields.first();

    field ||= requiredFields.first();

    if (field?.get('name') === 'OwnerId') {
      field = null;
    }

    if (isAssignBasedOnUserColumn) {
      field = requiredFields.get('OwnerId');
    }

    field ||= mappedFields.first();

    const isRequiredField =
      field && requiredFields.getIn([field.get('name'), 'required']);
    if (isRequiredField) {
      field = field.set('nillable', false);
    }

    const isMapped =
      field &&
      (isAssignBasedOnUserColumn ||
        isFieldMapped(field, csvHeaders, csvHeaderName));

    const modifiedCsvHeaders = csvHeaders.map(header => header?.toLowerCase());
    const mappedFieldIdx = modifiedCsvHeaders.indexOf(
      field && field.get('label').toLowerCase()
    );
    /**
     * We cannot set the current index here if the above line doesn't return a match
     * The potential bug is that, if we had set a previously mapped header with this index then
     * not finding an exact header at this index simply overwrites the previously mapped
     * header with null values. There is no danger here because
     * 1. Unmapped fields don't show up.
     * 2. Manual mapping fixes the randomness introduced by 'makeIndex()'
     */
    const csvIdx =
      mappedFieldIdx >= 0 && !orderedMap.get(mappedFieldIdx)
        ? mappedFieldIdx
        : makeIndex(orderedMap, idx, headers.size);

    if (isMapped && mappedFieldIdx === idx && field.get('nillable') === false) {
      const headerName = csvHeaders.get(mappedFieldIdx);
      mappedHeader = getMappedHeader(field, headerName, csvIdx, 'auto');
    } else if (isMapped) {
      mappedHeader = getMappedHeader(field, csvHeaderName, csvIdx, 'auto');
    } else {
      let csvHeader;
      if (mappedFieldIdx >= 0) {
        csvHeader = csvHeaders.get(mappedFieldIdx);
        csvHeaders = csvHeaders
          .set(mappedFieldIdx, csvHeaderName)
          .set(idx, csvHeader);
      }
      // If a required field doesnt get mapped through name matching we still want to add the value because it is a required value.
      mappedHeader = getMappedHeader(
        field,
        csvHeader,
        headers.indexOf(csvHeader),
        csvHeader ? 'auto' : null
      );
    }
    if (mappedHeader) {
      // Remove duplicate values for required fields.
      requiredFields = requiredFields.delete(mappedHeader.name);
    }

    return orderedMap.set(csvIdx, fromJS(mappedHeader));
  }, OrderedMap());

  return mappedCsvHeaders;
}

function createRequiredFieldsMap(
  requiredSalesforceFieldSets: OrderedMap<any, any>
) {
  return requiredSalesforceFieldSets.reduce((orderedMap, fieldSet) => {
    const requiredFields: any = {
      csvHeader: null,
      label: fieldSet.get('label'),
      name: fieldSet.get('name'),
      idx: null,
      type: fieldSet.get('type'),
      isMapped: null,
      length: fieldSet.get('length') || fieldSet.get('maxLength') || null,
      required:
        !fieldSet.get('nillable') ||
        fieldSet.get('name').toLowerCase() === 'email',
      referenceTo: fieldSet.get('referenceTo') || null,
      picklist: fieldSet.get('picklist') || null,
      searchResults: null,
    };

    return orderedMap.set(fieldSet.get('name'), fromJS(requiredFields));
  }, OrderedMap());
}

function filterPeopleNotFound(
  emails: List<string>,
  csvRows: List<List<string>>,
  lookupByColumnIdx: number
) {
  return csvRows.filter((row: List<string>) =>
    emails.includes(row.get(lookupByColumnIdx))
  );
}

function* handleInitialPageLoad(): SagaIterator {
  const csvHeaders = yield select(getCsvLookupHeader);
  const csvRows = yield select(getCsvLookupRows);
  const emails = yield select(getPeopleNotFoundInSalesforce);
  const selectedLookupField = yield select(getSelectedObjectField);
  const lookupByColumnIdx = yield select(getLookupByColumnIdx);
  let filteredRows =
    selectedLookupField === 'Email'
      ? filterPeopleNotFound(emails, csvRows, lookupByColumnIdx)
      : List();
  let filteredCsvHeaders = csvHeaders;
  if (filteredCsvHeaders?.size) {
    filteredCsvHeaders = csvHeaders.filter((header: string) => !!header);
    filteredRows = filteredRows.map(record =>
      record.slice(0, filteredCsvHeaders.size)
    );
  }

  const csvRowsEmails = csvRows
    .map((rows: Array<string>) => rows.find(cell => emailRegexp.test(cell)))
    .filter((v: string) => v);

  yield put(getAccounts({ values: csvRowsEmails }));

  yield put({
    type: actionTypes.FETCH_DATA.SUCCESS,
    payload: {
      csvHeaders: filteredCsvHeaders,
      csvRows: filteredRows,
      selectedLookupField,
      isLoading: false,
    },
  });
}

export function findWarnings(
  mappedHeaders: OrderedMap<any, any>,
  csvRows: List<List<string>>
): OrderedMap<any, any> {
  const requiredHeaders = mappedHeaders.filter(
    header => header.get('required') === true
  );
  let warnings = OrderedMap();
  csvRows.map((row, index) => {
    const requiredWarnings = requiredHeaders.map((_header, index) => {
      const requiredCell = row.get(index) === null;
      return requiredCell;
    });
    warnings = warnings.set(index, requiredWarnings);
    return warnings;
  });
  return warnings;
}

function* handleFetchFieldSetData(): SagaIterator {
  const csvHeaders = yield select(getCsvLookupHeader);
  const selectedSObj = yield select(getSelectedSObj);
  const csvRows = yield select(getCsvLookupRows);
  const assignBasedOnUserColumn = yield select(getAssignBasedOnUserColumn);
  const assignBasedOnUserColumnIdx = yield select(
    getAssignBasedOnUserColumnIdx
  );

  const showRequiredOwnerIdColumn =
    assignBasedOnUserColumn && assignBasedOnUserColumnIdx !== undefined;

  yield put(getFlowSettingsBegin());

  let salesforceObjectFieldSet =
    selectedSObj === 'contact'
      ? yield select(getContactFieldSets)
      : yield select(getLeadFieldSets);
  salesforceObjectFieldSet = salesforceObjectFieldSet
    .filter((fieldSet: Map<string, any>) => {
      return (
        (fieldSet.get('updateable') === true &&
          fieldSet.get('defaultedOnCreate') === false) ||
        (showRequiredOwnerIdColumn && fieldSet.get('name') === 'OwnerId')
      );
    })
    .sort(
      (a: Map<string, any>, b: Map<string, any>) =>
        a.get('nillable') - b.get('nillable')
    );

  const requiredSalesforceFields = salesforceObjectFieldSet.filter(
    (fieldSet: Map<string, any>) => {
      return (
        (fieldSet.get('updateable') === true &&
          fieldSet.get('nillable') === false &&
          fieldSet.get('defaultedOnCreate') === false) ||
        fieldSet.get('name').toLowerCase() === 'email' ||
        (showRequiredOwnerIdColumn && fieldSet.get('name') === 'OwnerId')
      );
    }
  );

  let filteredCsvHeaders = csvHeaders;
  if (filteredCsvHeaders?.size) {
    filteredCsvHeaders = csvHeaders.filter((header: string) => !!header);
  }
  const requiredFieldsMap = createRequiredFieldsMap(requiredSalesforceFields);
  const mappedHeaders = findMappedHeaders(
    requiredFieldsMap,
    filteredCsvHeaders,
    salesforceObjectFieldSet,
    showRequiredOwnerIdColumn ? assignBasedOnUserColumnIdx : undefined
  );
  const warnings = findWarnings(mappedHeaders, csvRows);

  yield put({
    type: actionTypes.FETCH_FIELD_SET_DATA.SUCCESS,
    payload: {
      salesforceObjectFieldSet,
      mappedHeaders,
      isLoading: false,
      warnings,
      requiredSalesforceFields,
    },
  });
}

function* handleReferenceFieldSearchBegin({
  payload: { value },
}: FSA<string, SearchPayload>): SagaIterator {
  yield delay(200);
  if (value.length > 2) {
    const response = yield call(searchAccounts, value);
    yield put({
      type: actionTypes.REFERENCE_FIELD_SEARCH.SUCCESS,
      payload: {
        data: response.data,
      },
    });
  }
}

function domainReducer(
  accumulator: Record<string, Array<string>>,
  email: string
) {
  const domain = email.split('@')[1];
  if (!accumulator[domain]) {
    accumulator.domains.push(domain);
    accumulator[domain] = [domain];
  }
  return accumulator;
}

export function* handleGetRecommendedAccounts(domains_string: string) {
  try {
    return yield call(getRecommendedAccounts, domains_string);
  } catch (e) {
    yield put(
      pushSnackbarMessage({
        message: 'Something went wrong when retrieving accounts',
      })
    );
  }
  return { data: {} };
}

export function* handleRecommendedAccountSearchBegin({
  payload: { values },
}: FSA<string, AccountSearchPayload>): SagaIterator {
  let recommendedAccounts = [];
  const emailDomains = values.toArray().reduce(domainReducer, {
    domains: [],
  }).domains;

  if (emailDomains.length < ROWS_PER_REQUEST_LIMIT) {
    const response = yield call(
      handleGetRecommendedAccounts,
      emailDomains.join(',')
    );
    recommendedAccounts = Object.keys(response.data).map(
      key => response.data[key]
    );
  } else {
    const upperLimit = Math.ceil(emailDomains.length / ROWS_PER_REQUEST_LIMIT);

    const responses = yield all(
      range(0, upperLimit).map(index => {
        const lower = index * ROWS_PER_REQUEST_LIMIT;
        const upper = Math.min(
          lower + ROWS_PER_REQUEST_LIMIT,
          emailDomains.length
        );
        const valuesChunk = emailDomains.slice(lower, upper);
        return call(handleGetRecommendedAccounts, valuesChunk.join(','));
      })
    );

    const combinedResponses: any = combineResponses(responses);
    recommendedAccounts = Object.keys(combinedResponses).map(
      key => combinedResponses[key]
    );
  }

  yield put({
    type: actionTypes.RECOMMENDED_ACCOUNT_SEARCH.SUCCESS,
    payload: {
      data: recommendedAccounts,
    },
  });
}

export function* createBatch(records, selectedSObject) {
  let response;
  try {
    response = yield call(bulkCreateAsync, {
      records: JSON.stringify(records),
      sobject_type: selectedSObject,
    });
  } catch (e) {
    if (e?.response?.status === HttpStatusCodes.FORBIDDEN) {
      yield put({
        type: actionTypes.BULK_CREATION_STATUS,
        payload: 'serviceAccountInvalid',
      });
    } else {
      yield put({
        type: actionTypes.BULK_CREATION_STATUS,
        payload: 'failed',
      });
    }
    yield put(pushSnackbarMessage({ message: 'Something went wrong' }));
    yield put({ type: actionTypes.IS_LOADING, payload: false });
  }

  return response;
}

export function* checkBatchStatus(createBatchResponse) {
  const jobId = createBatchResponse.data.jobId;
  const batchId = createBatchResponse.data.batchId;

  let counter = 0;
  let response;
  // wait 15 seconds before creating a worker to handle the request
  while (counter < 30) {
    try {
      response = yield call(bulkCreateBatchStatus, { jobId, batchId });
      if (response.data.jobId) {
        yield delay(500);
      } else {
        break;
      }
    } catch (e) {
      if (e?.response?.status === HttpStatusCodes.FORBIDDEN) {
        yield put({
          type: actionTypes.BULK_CREATION_STATUS,
          payload: 'serviceAccountInvalid',
        });
      } else {
        yield put({
          type: actionTypes.BULK_CREATION_STATUS,
          payload: 'failed',
        });
      }
      yield put(pushSnackbarMessage({ message: 'Something went wrong' }));
      yield put({ type: actionTypes.IS_LOADING, payload: false });
      break;
    }
    counter += 1;
  }

  return response;
}

export function* createImportWorker(batchStatusResponse) {
  const jobId = batchStatusResponse.data.jobId;
  const batchId = batchStatusResponse.data.batchId;
  const flowShow = yield select(isFlowShowOpen);
  const flowWizard = yield select(isFlowWizardOpen);
  let flowId;
  if (flowShow) {
    flowId = yield select(getFlowShowId);
  } else if (flowWizard) {
    flowId = yield select(getFlowWizardId);
  }

  try {
    yield call(createImportJob, { jobId, batchId, flowId });
    yield put({
      type: actionTypes.BULK_CREATION_STATUS,
      payload: 'delayed',
    });
  } catch (e) {
    yield put({
      type: actionTypes.BULK_CREATION_STATUS,
      payload: 'failed',
    });
  }

  yield put({
    type: actionTypes.IS_LOADING,
    payload: false,
  });
}

function findPersonFlowOwnerId(
  people: { sfdcId: string; email?: string },
  recordsByEmail: any,
  assignableUsersDataBySfdcId: any
) {
  const ownerSfdcId = recordsByEmail[people.email]?.OwnerId;

  return ownerSfdcId && assignableUsersDataBySfdcId[ownerSfdcId]?.id;
}

function buildErrorMessage(httpError: HTTPError) {
  const responseData = httpError?.response?.data;

  if (!isEmpty(responseData?.blockedPeopleData?.meta)) {
    const metaData = values(responseData?.blockedPeopleData?.meta);

    const firstError = metaData[0] && values(metaData[0]);

    const firstViolatedRuleName = firstError?.[0]?.violatedRuleName;

    if (firstViolatedRuleName)
      return `Some people were not added to the flow due to the "${firstViolatedRuleName}" Import Rule.`;
  }

  return (
    httpError?.response?.data?.message ||
    'There was a problem importing people. Please try again'
  );
}

export function* handleBulkCreation(): SagaIterator {
  yield put({ type: actionTypes.IS_LOADING, payload: true });

  yield put(
    setProperty({
      uiKeyPath: AUTO_CREATION_CONFIRMATION_DIALOG_KEY_PATH,
      data: true,
    })
  );

  const mappedHeaders = yield select(getCsvMappedHeaders);
  const csvRows = yield select(makeGetCsvRows);
  const selectedSObject = yield select(getSelectedSObj);
  const selectedRecords = yield select(getSelectedRecords);
  const selectedRows = selectedRecords.map((index: number) =>
    csvRows.get(index)
  );

  const records = selectedRows.reduce((responseHash: any, row: any) => {
    const hash: any = {};
    mappedHeaders.map((header: any) => {
      if (header.get('isMapped') !== null) {
        hash[header.get('name')] = row.get(header.get('idx'));
        return hash;
      }
      return undefined;
    });
    return responseHash.push(hash);
  }, List());

  yield put({
    type: actionTypes.BULK_CREATION_STATUS,
    payload: 'inProgress',
  });

  let response = yield call(createBatch, records, selectedSObject);

  if (response?.data?.jobId) {
    response = yield call(checkBatchStatus, response);
  }

  if (response) {
    if (response.data?.jobId) {
      yield call(createImportWorker, response);
      return;
    }

    const { batchResponse, recordsFailed, recordsProcessed, newSfdcRecords } =
      response.data;

    yield put({
      type: actionTypes.BULK_CREATE.SUCCESS,
      payload: {
        batchResponse: fromJS(batchResponse),
        recordsFailed,
        recordsProcessed,
      },
    });

    const flowShow = yield select(isFlowShowOpen);
    const flowWizard = yield select(isFlowWizardOpen);

    const recordsByEmail = keyBy(records.toJS(), 'Email');

    const assignableUsersData = yield select(getAssignableUsersData);
    const assignableUsersDataBySfdcId = keyBy(
      assignableUsersData,
      'sfdcUserId'
    );

    const peopleToAdd = newSfdcRecords.reduce(
      // eslint-disable-next-line camelcase
      (acc: any, people: { sfdcId: string; email?: string }) => {
        const personFlowOwnerId = findPersonFlowOwnerId(
          people,
          recordsByEmail,
          assignableUsersDataBySfdcId
        );
        acc[people.sfdcId] = {
          sfdc_id: people.sfdcId,
          ...people,
        };

        if (personFlowOwnerId)
          acc[people.sfdcId].personFlowOwnerId = personFlowOwnerId;

        return acc;
      },
      {}
    );

    const newSfdcRecordsMap = fromJS(peopleToAdd);

    let flowId;
    if (flowShow) {
      flowId = yield select(getFlowShowId);
    } else if (flowWizard) {
      flowId = yield select(getFlowWizardId);
    }

    if (newSfdcRecordsMap.size !== 0) {
      yield put({
        type: actionTypes.BULK_CREATION_STATUS,
        payload: 'successful',
      });
      yield put({
        type: actionTypes.ADD_TO_FLOW_IN_PROGRESS,
        payload: true,
      });
      yield put(addToFlowBegin());

      let peopleResponse;
      try {
        peopleResponse = yield call(
          handleInvalidSalesforceConnectionHTTPRequest,
          importPeople,
          flowId,
          peopleToAdd
        );
      } catch (e) {
        if (
          e instanceof HTTPError &&
          (e?.response?.status === HttpStatusCodes.UNPROCESSABLE_ENTITY ||
            e?.response?.status === HttpStatusCodes.FORBIDDEN)
        ) {
          const message = buildErrorMessage(e);

          yield put(addToFlowFailure(message));
          yield put(pushSnackbarMessage({ message }));
          return;
        } else {
          throw e;
        }
      }

      if (peopleResponse.data.batchId) {
        const jobId = peopleResponse.data.batchId;
        yield put(
          addJob({
            id: jobId,
            name: 'People import',
          })
        );

        yield put(
          setProperty({
            uiKeyPath: ADD_PEOPLE_JOB_ID_KEYPATH,
            data: jobId,
          })
        );

        yield call(watchJob, jobId);
      }
      yield put({
        type: actionTypes.ADD_TO_FLOW_IN_PROGRESS,
        payload: false,
      });
      yield put(addToFlowSuccess());
    } else {
      yield put({
        type: actionTypes.BULK_CREATION_STATUS,
        payload: 'failed',
      });
    }
  }
  yield put({
    type: actionTypes.IS_LOADING,
    payload: false,
  });
}

function* handleFetchingFlowSettings(): SagaIterator {
  const flowSettingResponse = yield call(getFlowSettings);
  if (flowSettingResponse.status === HttpStatusCodes.OK) {
    const { autoCreatedRecordCount, organizationCsvLimit } =
      flowSettingResponse.data;

    yield put({
      type: actionTypes.FLOW_SETTINGS.SUCCESS,
      payload: {
        autoCreatedRecordLimit: organizationCsvLimit,
        autoCreatedRecordCount,
      },
    });
  }
}

function* watchOpenDialog(): SagaIterator {
  yield takeEvery<FSA>(actionTypes.OPEN_DIALOG, handleOpenDialog);
}

function* watchCloseDialog(): SagaIterator {
  yield takeEvery<FSA>(actionTypes.CLOSE_DIALOG, handleCloseDialog);
}

function* watchOpenSfdcFieldMappingDialog(): SagaIterator {
  yield takeEvery<FSA>(
    actionTypes.OPEN_SFDC_FIELD_MAPPING_DIALOG,
    handleOpenSfdcFieldMappingDialog
  );
}

function* watchCloseSfdcFieldMappingDialog(): SagaIterator {
  yield takeEvery<FSA>(
    actionTypes.CLOSE_SFDC_FIELD_MAPPING_DIALOG,
    handleCloseSfdcFieldMappingDialog
  );
}

function* watchFetchFieldSetDataBegin(): SagaIterator {
  yield takeLatest<FSA>(
    actionTypes.FETCH_FIELD_SET_DATA.BEGIN,
    handleFetchFieldSetData
  );
}

function* watchFetchDataBegin(): SagaIterator {
  yield takeEvery<FSA>(actionTypes.FETCH_DATA.BEGIN, handleInitialPageLoad);
}

function* watchSelectSObject(): SagaIterator {
  yield takeLatest<FSA>(actionTypes.HANDLE_SOBJ_CHANGE, handleSObjChange);
}

function* watchReferenceFieldSearchBegin(): SagaIterator {
  yield takeLatest<FSA<string, SearchPayload>>(
    actionTypes.REFERENCE_FIELD_SEARCH.BEGIN,
    handleReferenceFieldSearchBegin
  );
}

function* watchRecommendedAccountSearchBegin(): SagaIterator {
  yield takeLatest<FSA<string, AccountSearchPayload>>(
    actionTypes.RECOMMENDED_ACCOUNT_SEARCH.BEGIN,
    handleRecommendedAccountSearchBegin
  );
}

function* watchBulkCreation(): SagaIterator {
  yield takeEvery<FSA>(actionTypes.BULK_CREATE.BEGIN, handleBulkCreation);
}

function* watchCloseAutoCreationConfirmationModal(): SagaIterator {
  yield takeEvery<FSA>(
    actionTypes.AUTO_CREATION_CONFIRMATION_CLOSE_DIALOG,
    handleCloseAutoCreationConfirmationModal
  );
}

function* watchFlowSettingsBegin(): SagaIterator {
  yield takeEvery<FSA>(
    actionTypes.FLOW_SETTINGS.BEGIN,
    handleFetchingFlowSettings
  );
}

export default function* root(): SagaIterator {
  yield all([
    fork(watchOpenDialog),
    fork(watchCloseDialog),
    fork(watchFetchDataBegin),
    fork(watchSelectSObject),
    fork(watchFetchFieldSetDataBegin),
    fork(watchReferenceFieldSearchBegin),
    fork(watchCloseSfdcFieldMappingDialog),
    fork(watchOpenSfdcFieldMappingDialog),
    fork(watchRecommendedAccountSearchBegin),
    fork(watchBulkCreation),
    fork(watchCloseAutoCreationConfirmationModal),
    fork(watchFlowSettingsBegin),
  ]);
}
