import { hotlist } from 'GrooveHTTPClient/people';
import { actionTypes } from 'Modules/People/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

function* toggleHotlist({ payload }) {
  try {
    yield put({ type: actionTypes.TOGGLE_HOTLIST.PROGRESS });
    yield call(hotlist, payload);
    yield put({ type: actionTypes.TOGGLE_HOTLIST.SUCCESS, payload });
  } catch (e) {
    yield put({ type: actionTypes.TOGGLE_HOTLIST.FAILURE, error: e });
  }
}

function* watchToggleHotlistBegin() {
  yield takeEvery(actionTypes.TOGGLE_HOTLIST.BEGIN, toggleHotlist);
}

export default function* root() {
  yield all([fork(watchToggleHotlistBegin)]);
}
