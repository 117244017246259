import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SHARED: {
    FLOWS: {
      CREATE_TEMPLATE_OVERRIDE: standardActions(),
      DELETE_TEMPLATE_OVERRIDE: standardActions(),
      ...keyMirror([
        'SET_IS_USER_OVERRIDING_TEMPLATE',
        'SKELETON_STEP_TOGGLED',
      ]),
      CREATE_OR_UPDATE_STEP: keyMirror([
        'ADD_PEOPLE_YES',
        'ADD_PEOPLE_NO',
        'BEGIN',
        'FAILURE',
        'PROGRESS',
        'SUCCESS',
      ]),
    },
  },
}).SHARED.FLOWS;

export function createOrUpdateStep(payload) {
  return {
    type: actionTypes.CREATE_OR_UPDATE_STEP.BEGIN,
    payload,
  };
}

export function createOrUpdateStepProgress() {
  return {
    type: actionTypes.CREATE_OR_UPDATE_STEP.PROGRESS,
  };
}

export function createOrUpdateStepSuccess(step) {
  return {
    type: actionTypes.CREATE_OR_UPDATE_STEP.SUCCESS,
    payload: { step },
  };
}

export function createOrUpdateStepFailure(error) {
  return {
    type: actionTypes.CREATE_OR_UPDATE_STEP.FAILURE,
    payload: { error },
  };
}

export function createOrUpdateStepAddPeople() {
  return {
    type: actionTypes.CREATE_OR_UPDATE_STEP.ADD_PEOPLE_YES,
  };
}

export function createOrUpdateStepDontAddPeople() {
  return {
    type: actionTypes.CREATE_OR_UPDATE_STEP.ADD_PEOPLE_NO,
  };
}

export function createStepTemplateOverride(payload) {
  return {
    type: actionTypes.CREATE_TEMPLATE_OVERRIDE.BEGIN,
    payload,
  };
}

export function successfullyCreatedOrUpdatedStepTemplateOverride({
  stepId,
  template,
}) {
  return {
    type: actionTypes.CREATE_TEMPLATE_OVERRIDE.SUCCESS,
    payload: { stepId, template },
  };
}

export function deleteStepTemplateOverride({
  stepId,
  templateOverrideId,
  isOpenUiKeyPath,
}) {
  return {
    type: actionTypes.DELETE_TEMPLATE_OVERRIDE.BEGIN,
    payload: { stepId, templateOverrideId, isOpenUiKeyPath },
  };
}

export function successfullyDeletedStepTemplateOverride({ stepId }) {
  return {
    type: actionTypes.DELETE_TEMPLATE_OVERRIDE.SUCCESS,
    payload: { stepId },
  };
}

export function skeletonStepToggled({ toggle }) {
  return {
    type: actionTypes.SKELETON_STEP_TOGGLED,
    payload: { toggle },
  };
}
