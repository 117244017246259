import { FC } from 'react';
import sanitizeHtml from 'sanitize-html';
import Text from '@groove/ui/Components/Text';
import { useGetOrgSettings } from '@groove/api/hooks/useUsers';
import UploadedFile from '@groove/wysiwyg-editor/UploadedFile';
import { useFetchFooterMerged } from '@groove/api/hooks/useFetchFooter';
import useFetchEmailSignature from '@groove/api/hooks/useFetchEmailSignature';

import { getAttachmentsFormBody } from '../utils/attachmentsUtils';
import useStore from '../store/useStore';

const { allowedTags, allowedAttributes } = sanitizeHtml.defaults;

const EmailPreview: FC = () => {
  const body = useStore(store => store.action.body);
  const fromEmail = useStore(store => store.action.fromEmail);

  const { data: signatureData } = useFetchEmailSignature(fromEmail || '');
  const { data } = useGetOrgSettings();
  const who = useStore(store => store.action.loggingTo.who);
  const what = useStore(store => store.action.loggingTo.what);
  // There is a chance that id are not define, use ?? save check to make sure everything is defined
  const whoId = who?.[0]?.id ?? '';
  const whatId = what?.[0]?.id ?? '';
  const { data: emailFooterData } = useFetchFooterMerged(whoId, whatId);

  const isTrueAttachments = !!data?.direct_attachment_enabled;

  const sanitizeBody = sanitizeHtml(body || '', {
    allowedTags: allowedTags.concat(['img', 'a', 'span']),
    allowedAttributes: {
      ...allowedAttributes,
      img: allowedAttributes.img.concat(['style', 'width', 'height']),
      a: allowedAttributes.a.concat(['style']),
      span: ['style'],
      div: ['dir'],
      '*': ['href', 'align', 'alt', 'center', 'bgcolor', 'style'],
    },
  });

  const attachments = getAttachmentsFormBody(body || '');
  const signature = signatureData?.email_signature;
  const footer = emailFooterData?.email_footer || '';

  return (
    <div className="relative flex-1 border-0 !border-t border-solid border-neutral/75 overflow-auto w-full">
      <Text className="pt-[12px] text-body-sm">
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: sanitizeBody }} />
        <br />
        {/* eslint-disable react/no-danger */}
        {signature && <div dangerouslySetInnerHTML={{ __html: signature }} />}
        {footer && <div dangerouslySetInnerHTML={{ __html: footer }} />}
        {/* eslint-enable react/no-danger */}
      </Text>
      {isTrueAttachments &&
        attachments &&
        attachments.map(attachment => {
          return (
            <span style={{ padding: 2 }}>
              <UploadedFile
                uploadLocation={attachment.uploadLocation}
                fileName={attachment.name}
                id={attachment.id}
              />
            </span>
          );
        })}
    </div>
  );
};

export default EmailPreview;
