import { call, put } from 'redux-saga/effects';
import { setProductUsage } from 'Modules/Shared/actions/productUsage';
import { show as getProductUsage } from 'GrooveHTTPClient/productUsage';
import HTTPError from 'GrooveHTTPClient/HTTPError';
import HttpStatusCodes from 'http-status-codes';

export default function* getProductUsageData() {
  try {
    const productUsageResponse = yield call(getProductUsage);
    yield put(setProductUsage(productUsageResponse.data));
  } catch (e) {
    const isUnauthorizedHTTPError =
      e instanceof HTTPError &&
      e.response.status === HttpStatusCodes.UNAUTHORIZED;
    if (!isUnauthorizedHTTPError) {
      throw e;
    }
  }
}
