import { templateFoldersGet } from '@groove/api/services/v1/templateFolders';
import { useQuery, useQueryClient } from 'react-query';
import { Template, TemplateType } from '@groove/api/gateway/v1/template';
import { IconButton, Text } from '@fluentui/react';

import useTemplatesStore from './store/useStore';
import {
  TEMPLATES_ACTION_TYPE,
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  TEMPLATE_EDITOR_CLOSED_VIA,
  trackTemplatesEvent,
} from './analytics/templatesEvents';
import TemplateEditor from './TemplateEditor';

type Props = {
  template?: Template;
  initialSubject?: string;
  initialBody?: string;
  action: 'CREATE' | 'EDIT';
  dismissEditor: () => void;
  templateType?: TemplateType;
};

const TemplatesEditorStandalone: React.FC<Props> = ({
  template,
  initialSubject,
  initialBody,
  action,
  dismissEditor,
  templateType = 'email',
}) => {
  const queryClient = useQueryClient();
  const selectedTemplate = useTemplatesStore(store => store.selectedTemplate);
  const initialFolderId = selectedTemplate?.folder_id;
  const initialName = selectedTemplate?.name;

  const { data: templateFolders } = useQuery(['templateFoldersGet'], () =>
    templateFoldersGet(false),
  );

  const refetchOnSuccess = (): void => {
    queryClient.invalidateQueries('templateFoldersSearch');
    queryClient.invalidateQueries('templateGet');
    queryClient.invalidateQueries('favoriteTemplatesGet');
    queryClient.invalidateQueries('recentTemplatesGet');
    queryClient.invalidateQueries('templateFoldersGet');
  };

  return templateFolders && templateFolders.data.length > 0 ? (
    <>
      <div className="flex px-3 pt-4">
        <Text className="text-lg font-semibold capitalize">
          {action === 'CREATE' ? 'New Template' : 'Edit Template'}
        </Text>
        <IconButton
          className="ml-auto text-clari-blue/600"
          iconProps={{ iconName: 'cancel' }}
          onClick={() => {
            dismissEditor();
            trackTemplatesEvent({
              eventName: TEMPLATES_EVENT_TYPE.EDITOR_CLOSED,
              templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
              templateActionType:
                action === 'CREATE'
                  ? TEMPLATES_ACTION_TYPE.NEW
                  : TEMPLATES_ACTION_TYPE.EDIT,
              closedVia: TEMPLATE_EDITOR_CLOSED_VIA.X_BUTTON,
            });
          }}
        />
      </div>
      <TemplateEditor
        templateId={template?.id}
        initialFolderId={initialFolderId || templateFolders.data[0]?.id}
        initialName={initialName || ''}
        initialSubject={
          template?.subject || selectedTemplate?.subject || initialSubject || ''
        }
        initialBody={
          template?.body || selectedTemplate?.body || initialBody || ''
        }
        action={action}
        dismissEditor={dismissEditor}
        refetchOnSuccess={refetchOnSuccess}
      />
    </>
  ) : null;
};

export default TemplatesEditorStandalone;
