import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  FLOWS_SHOW: {
    AUTOMATED_ACTIONS: {
      NEW: keyMirror(['BEGIN', 'UPDATE', 'CONFIRM', 'CANCEL']),
      EDIT: keyMirror(['BEGIN', 'UPDATE', 'CONFIRM', 'CANCEL']),
      SAVE: standardActions(),
      FETCH: standardActions(),
      CREATE: standardActions(),
      UPDATE: standardActions(),
      DESTROY: standardActions(),
    },
  },
}).FLOWS_SHOW.AUTOMATED_ACTIONS;

export const fetchBegin = () => ({
  type: actionTypes.FETCH.BEGIN,
});

export const fetchSuccess = payload => ({
  type: actionTypes.FETCH.SUCCESS,
  payload,
});

export const newBegin = () => ({
  type: actionTypes.NEW.BEGIN,
});

export const newConfirm = () => ({
  type: actionTypes.NEW.CONFIRM,
});

export const editBegin = automatedAction => ({
  type: actionTypes.EDIT.BEGIN,
  payload: automatedAction,
});

export const editUpdate = (id, automatedActionEdits) => ({
  type: actionTypes.EDIT.UPDATE,
  payload: {
    id,
    ...automatedActionEdits,
  },
});

export const editConfirm = id => ({
  type: actionTypes.EDIT.CONFIRM,
  payload: { id },
});

export const editCancel = id => ({
  type: actionTypes.EDIT.CANCEL,
  payload: { id },
});

export const destroyBegin = id => ({
  type: actionTypes.DESTROY.BEGIN,
  payload: { id },
});

export const saveBegin = id => ({
  type: actionTypes.SAVE.BEGIN,
  payload: { id },
});

export const saveSuccess = (id, savedAutomatedAction) => ({
  type: actionTypes.SAVE.SUCCESS,
  payload: {
    id,
    savedAutomatedAction,
  },
});

export const saveFailure = id => ({
  type: actionTypes.SAVE.FAILURE,
  payload: { id },
});
