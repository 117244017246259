import { FC } from 'react';
import Text from '@groove/ui/Components/Text';
import cx from 'classnames';
import Tooltip from '@groove/ui/Components/Tooltip';

type DialerBarButtonProps = {
  label: string;
  dataTestid?: string;
  disabled?: boolean;
  ariaLabel?: string;
  onClick: () => void;
  onDisabledClick?: () => void;
  selected?: boolean;
  tooltip?: string | JSX.Element;
};

const DialerBarButton: FC<DialerBarButtonProps> = ({
  children,
  label,
  dataTestid,
  disabled,
  onClick,
  onDisabledClick,
  selected,
  ariaLabel,
  tooltip,
}) => {
  const handleOnClick = (): void => {
    if (disabled) onDisabledClick?.();
    else if (onClick) onClick();
  };

  let className = 'text-clari-blue/600 hover:text-white';
  if (selected) className = 'text-white';
  if (disabled) className = 'text-neutral/400';

  const buttonRender = (
    <div
      className={cx(
        'flex flex-col p-[4px] items-center h-[46px] relative m-[4px] rounded-md box-border',
        selected
          ? 'bg-clari-blue/600 active:bg-clari-blue/800'
          : !disabled && 'hover:bg-clari-blue/600 active:bg-clari-blue/800',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        className,
      )}
      data-testid={dataTestid || `dialer-button-${label}`}
      onClick={handleOnClick}
      onKeyDown={event => event.key === 'Enter' && handleOnClick()}
      tabIndex={0}
      role="button"
      aria-label={ariaLabel}
    >
      <div className="flex-1 flex justify-center">{children}</div>
      <Text className="text-metadata-xs overflow-hidden whitespace-nowrap ">
        {label}
      </Text>
    </div>
  );
  return (
    <>
      {tooltip && <Tooltip content={tooltip}>{buttonRender}</Tooltip>}
      {!tooltip && buttonRender}
    </>
  );
};

export default DialerBarButton;
