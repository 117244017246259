import { FC } from 'react';

interface EmptyStarProps {
  className?: string;
  onClick?: () => void;
  borderColor?: string;
  strokeWidth?: number;
}

const EmptyStar: FC<EmptyStarProps> = ({
  className = '',
  onClick,
  borderColor,
  strokeWidth = 1,
}) => (
  <svg
    className={className}
    onClick={onClick}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.1939 1.10179C6.52403 0.432869 7.47789 0.432865 7.80802 1.10179L9.32911 4.18384L12.7304 4.67807C13.4685 4.78534 13.7633 5.69251 13.2291 6.2132L10.768 8.61224L11.349 11.9997C11.4751 12.735 10.7034 13.2956 10.0431 12.9485L7.00096 11.3491L3.95879 12.9485C3.29853 13.2956 2.52684 12.735 2.65294 11.9997L3.23394 8.61224L0.772773 6.2132C0.238609 5.69251 0.533365 4.78534 1.27156 4.67807L4.67281 4.18384L6.1939 1.10179ZM7.00096 1.72605L5.54628 4.67355C5.41519 4.93918 5.16178 5.12329 4.86864 5.16589L1.61588 5.63854L3.9696 7.93285C4.18171 8.13961 4.27851 8.43751 4.22843 8.72947L3.6728 11.9691L6.58215 10.4395C6.84434 10.3017 7.15758 10.3017 7.41977 10.4395L10.3291 11.9691L9.77348 8.72947C9.72341 8.43751 9.8202 8.13961 10.0323 7.93285L12.386 5.63854L9.13328 5.16589C8.84014 5.12329 8.58673 4.93918 8.45564 4.67355L7.00096 1.72605Z"
      fill="currentColor"
      stroke={borderColor}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default EmptyStar;
