import EmailIcon from '@material-ui/icons/Email';
import LinkIcon from '@material-ui/icons/Link';
import ReplyIcon from '@material-ui/icons/Reply';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import DesktopIcon from '@material-ui/icons/DesktopWindows';
import AttachmentIcon from '@material-ui/icons/Attachment';
import EmailEvent from 'Modules/Emails/data/EmailEvent';

const EMAIL_EVENT_TYPE_LABELS = Object.freeze({
  [EmailEvent.TYPES.EmailOpen]: 'Opened',
  [EmailEvent.TYPES.LinkClick]: 'Clicked',
  [EmailEvent.TYPES.EmailReply]: 'Replied',
  [EmailEvent.TYPES.AttachmentDownload]: 'Attachment Opened',
});

const EMAIL_EVENT_TYPE_ICONS = Object.freeze({
  [EmailEvent.TYPES.EmailOpen]: EmailIcon,
  [EmailEvent.TYPES.LinkClick]: LinkIcon,
  [EmailEvent.TYPES.EmailReply]: ReplyIcon,
  [EmailEvent.TYPES.AttachmentDownload]: AttachmentIcon,
});

const EMAIL_EVENT_DEVICE_TYPE_ICONS = Object.freeze({
  MOBILE: SmartphoneIcon,
  DESKTOP: DesktopIcon,
});

const FILTER_TYPES = Object.freeze({
  OPENED: 'OPENED',
  CLICKED: 'CLICKED',
  REPLIED: 'REPLIED',
  UNOPENED: 'UNOPENED',
  ALL: 'ALL',
});

const SOURCES = Object.freeze({
  FLOW: 'FLOW',
  EMAIL: 'EMAIL',
  ALL: 'ALL',
});

const ORDER_FIELDS = Object.freeze({
  LAST_EVENT_AT: 'lastEventAt',
  REPLIED_AT: 'repliedAt',
  COMPLETED_AT: 'completedAt',
});

const MINIMUM_SEARCH_LENGTH = 3;
const SEARCH_DELAY = 250;
const EMAILS_PAGE_SIZE = 10;

export {
  EMAIL_EVENT_TYPE_LABELS,
  EMAIL_EVENT_TYPE_ICONS,
  EMAIL_EVENT_DEVICE_TYPE_ICONS,
  FILTER_TYPES,
  SOURCES,
  ORDER_FIELDS,
  MINIMUM_SEARCH_LENGTH,
  SEARCH_DELAY,
  EMAILS_PAGE_SIZE,
};
