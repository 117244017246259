import {
  createActions,
  standardActions,
  keyMirror,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  ACTIVITY_LOG: {
    FETCH_EVENT_LOGS: standardActions(),
    SEARCH_USERS: standardActions(),
    SEARCH_FLOWS: standardActions(),
    ...keyMirror([
      'SET_LOG_STATS',
      'SET_ACTIVE_LOG_ID_AND_METADATA',
      'UPDATE_FILTERS',
      'FILTER_LOGS',
      'INITIALIZE_TABLE',
      'CLEAR_FILTER',
      'ADD_ENTITY',
      'MOVE_TO_PAGE',
      'RESET_LOG_SETTINGS',
      'SET_DATE_FILTER',
    ]),
  },
}).ACTIVITY_LOG;

export const resetLogSettings = () => ({
  type: actionTypes.RESET_LOG_SETTINGS,
});

export const clearFilter = ({ type }) => ({
  type: actionTypes.CLEAR_FILTER,
  payload: { type },
});

export const addEntity = ({ type, entity }) => ({
  type: actionTypes.ADD_ENTITY,
  payload: { type, entity },
});

export const moveToPage = page => ({
  type: actionTypes.MOVE_TO_PAGE,
  payload: page,
});

export const beginSearchingUsers = ({ phrase }) => ({
  type: actionTypes.SEARCH_USERS.BEGIN,
  payload: { phrase },
});

export const failedSearchingUsers = () => ({
  type: actionTypes.SEARCH_USERS.FAILED,
});

export const successfullySearchedUsers = ({ users }) => ({
  type: actionTypes.SEARCH_USERS.SUCCESS,
  payload: { users },
});

export const beginSearchingFlows = ({ phrase }) => ({
  type: actionTypes.SEARCH_FLOWS.BEGIN,
  payload: { phrase },
});

export const failedSearchingFlows = () => ({
  type: actionTypes.SEARCH_FLOWS.FAILED,
});

export const successfullySearchedFlows = ({ flows }) => ({
  type: actionTypes.SEARCH_FLOWS.SUCCESS,
  payload: { flows },
});

export const filterLogs = () => ({
  type: actionTypes.FILTER_LOGS,
});

export const updateFilters = filters => ({
  type: actionTypes.UPDATE_FILTERS,
  payload: filters,
});

export const setDateFilter = filter => ({
  type: actionTypes.SET_DATE_FILTER,
  payload: filter,
});

export const beginFetchingEventLogs = () => ({
  type: actionTypes.FETCH_EVENT_LOGS.BEGIN,
});

export const successfullyFetchedEventLogs = ({ logs, offsetId }) => ({
  type: actionTypes.FETCH_EVENT_LOGS.SUCCESS,
  payload: {
    logs,
    offsetId,
  },
});

export const failedFetchingEventLogs = () => ({
  type: actionTypes.FETCH_EVENT_LOGS.FAILURE,
});

export const setLogStats = stats => ({
  type: actionTypes.SET_LOG_STATS,
  payload: stats,
});

export const setActiveLogIdAndMetadata = ({ id, metadata }) => ({
  type: actionTypes.SET_ACTIVE_LOG_ID_AND_METADATA,
  payload: { id, metadata },
});

export const initializeTable = () => ({
  type: actionTypes.INITIALIZE_TABLE,
});
