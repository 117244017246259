import { Spinner } from '@fluentui/react';
import { FC } from 'react';
import useSalesforceLogFields from '@groove/api/hooks/useSalesforceLogFields';

import AdditionalFieldFactory from '../AdditionalFields/AdditionalFieldFactory';

import useStore from './useStore';

type ActivityFieldsProps = {
  onChange?: () => void;
};

const ActivityFields: FC<ActivityFieldsProps> = ({
  onChange,
}: ActivityFieldsProps) => {
  const additionalFieldsType = useStore(store => store.additionalFieldsType);

  const { data: logFields, isLoading } = useSalesforceLogFields(
    additionalFieldsType,
    !!additionalFieldsType,
  );

  const additionalFields = useStore(store => store.additionalFields);
  const setAdditionalField = useStore(store => store.setAdditionalField);

  return (
    <div className="p-2 overflow-y-auto">
      {isLoading && <Spinner className="p-2" />}
      {logFields?.data.map(field => (
        <div key={field.name}>
          <AdditionalFieldFactory
            additionalField={field}
            value={additionalFields[field.name.toLowerCase()] ?? null}
            onChange={newValue => {
              setAdditionalField(field.name.toLowerCase(), newValue);
              if (onChange) {
                onChange();
              }
            }}
          />
        </div>
      ))}
      <div className="h-[200px]" />
    </div>
  );
};

export default ActivityFields;
