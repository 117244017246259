import { FC, useEffect, useState } from 'react';
import { WysiwygEditor } from '@groove/wysiwyg-editor';
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';
import { useGetOrgSettings } from '@groove/api/hooks/useUsers';
import useUserMergeFields from '@groove/api/hooks/useUserMergeFields';
import tinymce from 'tinymce';
import { useDebouncedCallback } from 'use-debounce';
import { fireAllOutsideCLickHandlers } from '@groove/ui/helpers/useHandleOutsideClicks';
import useGrooveMessageEvent from '@groove/api/hooks/useGrooveMessageEvent';

import useStore, { PaneValues } from '../store/useStore';
import { ACTION_ORIGIN } from '../constants';

const EmailEditor: FC = () => {
  const body = useStore(store => store.action.body);
  const toRecipients = useStore(store => store.action.toRecipients);
  const id = useStore(store => store.action.id);
  const variants = useStore(store => store.action.variants);
  const variantId = useStore(store => store.action.variantId);
  const invokingApp = useStore(store => store.otherValues.invokingApp);
  const wysiwygEdited = useStore(store => store.otherValues.wysiwygEdited);

  const prospectingEnabled = useVariation('gen-ai-prospecting-emails');
  const followupEnabled = useVariation('gen-ai-smart-followups');
  const highspotEnabled = useVariation('highspot-integration');
  const wysiwygDemoEnabled = useVariation('wysiwyg-editor-demo');
  const genAiFlag = prospectingEnabled || followupEnabled;

  const { data, isLoading } = useGetOrgSettings();
  const vidyardEnabled = !!data?.vidyard_enabled;
  const showVidyardLinkEnabled = !!data?.show_vidyard_link;
  const seismicEnabled = !!data?.seismic_enabled;
  const mergeFields = useUserMergeFields();
  const trackClicks = useStore(store => store.action.dynamicData.trackClicks);
  const trackOpens = useStore(store => store.action.dynamicData.trackOpens);
  const dynamicData = useStore(store => store.action.dynamicData);
  const flowId = useStore(store => store.action.flow.id);

  const [key, setKey] = useState(id);

  const { data: grooveMessageEvent } = useGrooveMessageEvent();
  const trackingEnabled = grooveMessageEvent?.trackingEnabled;

  const fontFamily =
    invokingApp === ACTION_ORIGIN.ADDIN
      ? 'Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif'
      : 'arial,helvetica,sans-serif';
  // flag will initially be false and then on response from launchdarkly will switch to true
  // This forces tinymce to unmount and then mount and correctly init when the flag changes
  useEffect(() => {
    if (key === id) setKey(id + id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genAiFlag]);

  const onEmailTrackingChange = (
    trackClicks: boolean,
    trackOpens: boolean,
  ): void => {
    useStore.getState().updateAction({
      dynamicData: {
        ...dynamicData,
        trackClicks,
        trackOpens,
      },
    });
  };

  const otherBodyChange = (body: string): void => {
    useStore.getState().setOtherValues({
      wysiwygSelection: tinymce.activeEditor?.selection?.getSel(),
    });
    useStore.getState().updateAction({ body });
  };

  const [debounceBodyChange] = useDebouncedCallback(otherBodyChange, 250);

  const onBodyChange = (body: string): void => {
    useStore.getState().setOtherValues({ wysiwygEdited: true });
    debounceBodyChange(body);
  };

  const paneValues: PaneValues = {
    selectionStart: 0,
    selectionEnd: 0,
  };

  return (
    <div className="relative flex-1" id="editor">
      {!isLoading && (
        <WysiwygEditor
          key={key}
          // for some reason, the initial value is being accepted
          // multiple times, causing a reset of the cursor position
          // to the beginning of the editor. This is a workaround
          // to prevent that from happening.
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(!wysiwygEdited && { initialValue: body || '' })}
          mergeFieldProps={{
            mergeFields,
          }}
          trueAttachmentsProps={{
            isTrueAttachments: !!data?.direct_attachment_enabled,
          }}
          onChange={onBodyChange}
          recipientEmail={(toRecipients && toRecipients[0]?.email) || ''}
          integrations={{
            highspot: highspotEnabled,
            vidyard: vidyardEnabled,
            showVidyardLink: showVidyardLinkEnabled,
            seismic: seismicEnabled,
          }}
          config={{
            height: '100%',
            content_style: `
            body {
              font-family: ${fontFamily};
              font-size: ${
                invokingApp === ACTION_ORIGIN.ADDIN ? '11pt' : 'small'
              };
              margin: 8px;
            }
            `,
            fontsize_formats: [
              'small',
              '8pt',
              '10pt',
              '11pt',
              '12pt',
              '14pt',
              '18pt',
              '24pt',
              '36pt',
            ].join(' '),
          }}
          inconsistentFormattingFeatureFlag={!!wysiwygDemoEnabled}
          onTemplateClick={() => useStore.getState().toggleTemplate(paneValues)}
          onVariantClick={variantId => {
            useStore.getState().updateSelectedVariant(variantId);
          }}
          onEmailTrackingChange={onEmailTrackingChange}
          isFlow={!!flowId}
          showEmailOpenTracking={trackOpens}
          showEmailLinkTracking={trackClicks}
          trackingEnabled={trackingEnabled}
          variants={variants}
          variantId={variantId}
          onSchedulerClick={() =>
            useStore.getState().toggleScheduler(paneValues)
          }
          onEditorClick={() => {
            fireAllOutsideCLickHandlers();
          }}
          onGenAiClick={
            genAiFlag
              ? () => useStore.getState().toggleEmailGeneration(paneValues)
              : undefined
          }
          invokingApp={
            invokingApp === ACTION_ORIGIN.ADDIN ? 'Add-In' : undefined
          }
        />
      )}
    </div>
  );
};

export default EmailEditor;
