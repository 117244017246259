import { decamelizeKeys } from 'humps';
import makeHTTPRequest from '../makeHTTPRequest';

const BASE_URL = '/api/v2/health_checks';

export const googleTokenHealthCheck = (forceRecheck = false, options = {}) =>
  makeHTTPRequest({
    path: `${BASE_URL}/google_token`,
    queryParameters: decamelizeKeys({ forceRecheck }),
    ...options,
  });

export const oauthTokenTokenHealthCheck = (
  forceRecheck = false,
  options = {}
) =>
  makeHTTPRequest({
    path: `${BASE_URL}/oauth_token`,
    queryParameters: decamelizeKeys({ forceRecheck }),
    ...options,
  });

export const microsoftTokenHealthCheck = (forceRecheck = false, options = {}) =>
  makeHTTPRequest({
    path: `${BASE_URL}/microsoft_token`,
    queryParameters: decamelizeKeys({ forceRecheck }),
    ...options,
  });

export const sfdcTokenHealthCheck = (forceRecheck = false, options = {}) =>
  makeHTTPRequest({
    path: `${BASE_URL}/sfdc_token`,
    queryParameters: decamelizeKeys({ forceRecheck }),
    ...options,
  });

export const sfdcMetaHealthCheck = (forceRecheck = false, options = {}) =>
  makeHTTPRequest({
    path: `${BASE_URL}/sfdc_meta`,
    queryParameters: decamelizeKeys({ forceRecheck }),
    ...options,
  });

export const grooveLicenseHealthCheck = (forceRecheck = false, options = {}) =>
  makeHTTPRequest({
    path: `${BASE_URL}/groove_license`,
    queryParameters: decamelizeKeys({ forceRecheck }),
    ...options,
  });

export const sfdcApiAccess = (forceRecheck = false, options = {}) =>
  makeHTTPRequest({
    path: `${BASE_URL}/sfdc_api_access`,
    queryParameters: decamelizeKeys({ forceRecheck }),
    ...options,
  });
