import { decamelizeKeys, camelize } from 'humps';

import makeHTTPRequest from '../makeHTTPRequest';

export const spacesTransformResponse = data =>
  Object.keys(data).reduce((acc, key) => {
    acc[camelize(key)] = data[key];
    return acc;
  }, {});

const transformResponse = data =>
  spacesTransformResponse(JSON.parse(data).data.results);

export const searchByName = ({ name, id, userId }) =>
  makeHTTPRequest({
    path: `/api/v2/workspaces/${id}/accounts_search/`,
    queryParameters: {
      name,
      user_id: userId,
    },
    transformResponse,
  });

export const searchByField = ({ sfdcFieldName, sfdcFieldValue }) =>
  makeHTTPRequest({
    path: '/api/v2/salesforce_accounts_search/field',
    queryParameters: decamelizeKeys({
      sfdcFieldName,
      sfdcFieldValue,
    }),
    transformResponse,
  });

export const searchWithOpportunities = () =>
  makeHTTPRequest({
    path: '/api/v2/salesforce_accounts_search/with_opportunities',
    transformResponse,
  });

export const searchByReport = ({ sfdcReportId }) =>
  makeHTTPRequest({
    path: '/api/v2/salesforce_accounts_search/report',
    queryParameters: decamelizeKeys({
      sfdcReportId,
    }),
    transformResponse,
  });
