import { takeEvery } from 'redux-saga/effects';
import {
  createSafeActionHandler,
  ANALYTICS_BASE_PROPERTIES,
} from 'Modules/Shared/sagas/analytics';
import { trackEvent } from 'Utils/segment';
import { actionTypes } from 'Modules/People/actions';

function* hotlisted() {
  yield takeEvery(
    actionTypes.TOGGLE_HOTLIST.SUCCESS,
    createSafeActionHandler(({ payload }) => {
      const { hotlisted } = payload;
      const personHotlisted = !hotlisted;
      trackEvent('Person Hotlisted', {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory: 'People',
        action: personHotlisted ? 'applied' : 'removed',
      });
    })
  );
}

export default hotlisted;
