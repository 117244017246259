import ClariTheme from 'Utils/theme/ClariTheme';

export default {
  flowListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px 2px',
    minHeight: '26px',
  },
  flowLinkText: {
    fontSize: 12,
    '&:hover': {
      cursor: 'pointer',
      color: ClariTheme.palette.primary.main,
    },
  },
  flowLinkContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  flowDescription: {
    fontSize: 10,
    '&:hover': {
      cursor: 'default',
    },
  },
  removeFromFlowText: {
    fontSize: 10,
  },
  removeFromFlowContainer: {
    display: 'flex',
    width: 70,
    justifyContent: 'center',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
};
