import { createActions, keyMirror } from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SHARED: {
    BATCH_JOBS: keyMirror([
      'ADD_JOB',
      'UPDATE_JOB_PROGRESS',
      'DELETE_JOB',
      'JOB_FINISHED',
    ]),
  },
}).SHARED.BATCH_JOBS;

export function addJob({ id, name }) {
  return {
    type: actionTypes.ADD_JOB,
    payload: { id, name },
  };
}

export function updateJobProgress({ id, progress }) {
  return {
    type: actionTypes.UPDATE_JOB_PROGRESS,
    payload: { id, progress },
  };
}

export function deleteJob({ id }) {
  return {
    type: actionTypes.DELETE_JOB,
    payload: { id },
  };
}

export function jobFinished({ id, batchStatus }) {
  return {
    type: actionTypes.JOB_FINISHED,
    payload: { id, batchStatus },
  };
}
