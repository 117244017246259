import cx from 'classnames';
import { FC, useEffect, useRef } from 'react';
import {
  ActionButton,
  Callout,
  Pivot,
  PivotItem,
  DirectionalHint,
} from '@fluentui/react';
import theme from '@groove/ui/theme';
import { CLARI_COLORS } from 'theme-constants';
import { isLead } from '@groove/api/helpers/sfdcObjectPrefixes';
import useGrooveMessageEvent from '@groove/api/hooks/useGrooveMessageEvent';
import PivotItemWithTooltip from '@groove/ui/Components/PivotItemWithTooltip';
import * as Sentry from '@sentry/browser';
import { isClientConfigured } from '@groove/api/services/v1/getClient';
import useSalesforceLogFields from '@groove/api/hooks/useSalesforceLogFields';

import Name from './Name';
import RelatedTo from './RelatedTo';
import ActivityFields from './ActivityFields';
import Review from './Review';
import useStore, { SelectedItem } from './useStore';

const calloutStyles = { root: 'w-[382px] h-[450px]' };
const pivotStyles = {
  root: 'flex justify-between flex-grow w-full bg-gray4',
  link: 'flex-1 disabled:text-gray3 disabled:cursor-not-allowed h-9 text-[13px] font-semibold text-gray1',
  linkIsSelected: {
    '&.is-selected, &.is-selected:hover': {
      color: theme.primary,
      cursor: 'auto',
    },
    '::before': {
      backgroundColor: CLARI_COLORS['clari-blue/600'],
    },
  },
};

type SearchAndSelectMiniProps = {
  hideActivityFields?: boolean;
  open?: boolean;
  onClose?: () => void;
  onOpenFullSearchAndSelect?: (
    searchQuery: string,
    tab?: 'name' | 'relatedTo',
  ) => void;
  onConfirm?: (
    selectedNames: SelectedItem[],
    selectedRelatedTos: SelectedItem[],
    additionalFields?: {
      [key: string]: string | number | boolean | null;
    },
  ) => void;
  disableMultipleRelatedTos?: boolean;
  hideSaveButton?: boolean;
  customFooter?: JSX.Element;
  onSelectedItemChange?: (isSelected: boolean, item: SelectedItem) => void;
  handleOmnibarContextChange?: (id: string) => void;
  onTabChange?: (tab: 'name' | 'relatedTo' | 'activityFields') => void;
  onSearch?: (tab: 'name' | 'relatedTo') => void;
  onReviewSelection?: (
    tab: 'name' | 'relatedTo' | 'activityFields' | 'review',
  ) => void;
  onActivityFieldsChange?: () => void;
};

const SearchAndSelectMini: FC<SearchAndSelectMiniProps> = ({
  hideActivityFields,
  open,
  onOpenFullSearchAndSelect,
  onConfirm,
  disableMultipleRelatedTos = false,
  hideSaveButton = false,
  customFooter,
  onSelectedItemChange,
  handleOmnibarContextChange,
  onTabChange,
  onSearch,
  onReviewSelection,
  onActivityFieldsChange,
}) => {
  const additionalFieldsType = useStore(store => store.additionalFieldsType);
  const selectedTab = useStore(store => store.selectedTab);
  const setSelectedTab = useStore(store => store.setSelectedTab);

  const { data: grooveMessageEvent } = useGrooveMessageEvent();

  const hasAdditionalFields = grooveMessageEvent?.logEmailFields
    ? grooveMessageEvent?.logEmailFields.split(',').length > 0
    : false;

  useEffect(() => {
    Sentry.setTag('component', 'search-and-select-mini');
  }, []);

  useSalesforceLogFields(
    additionalFieldsType,
    !!additionalFieldsType && !hideActivityFields && isClientConfigured(),
  );

  const selectedNames = useStore(store => store.selectedNames);
  const selectedRelatedTos = useStore(store => store.selectedRelatedTos);
  const additionalFields = useStore(store => store.additionalFields);

  const ref = useRef<HTMLDivElement>(null);

  const relatedToDisabled = selectedNames.some(name => isLead(name.id));

  return (
    <div
      className="box-border relative flex flex-col h-full overflow-hidden"
      ref={ref}
    >
      <div className="flex w-full bg-gray4">
        <Pivot
          headersOnly
          onLinkClick={item => {
            const tab = item?.props?.itemKey as
              | 'name'
              | 'relatedTo'
              | 'activityFields';
            setSelectedTab(tab);
            if (onTabChange) {
              onTabChange(tab);
            }
          }}
          styles={pivotStyles}
          selectedKey={selectedTab}
          className="w-full"
        >
          <PivotItem
            itemKey="name"
            headerText={`Name${
              selectedNames.length ? ` (${selectedNames.length})` : ''
            }`}
          />
          <PivotItem
            itemKey="relatedTo"
            onRenderItemLink={() => (
              <PivotItemWithTooltip
                text={`Related To${
                  selectedRelatedTos.length
                    ? ` (${selectedRelatedTos.length})`
                    : ''
                }`}
                selected={selectedTab === 'relatedTo'}
                disabled={relatedToDisabled}
                hoverMessage={
                  relatedToDisabled
                    ? 'Related records cannot be attached to activities with leads'
                    : ''
                }
                isSearchSelect
              />
            )}
            headerButtonProps={{
              disabled: relatedToDisabled,
            }}
          />
          {!hideActivityFields && (
            <PivotItem
              itemKey="activityFields"
              onRenderItemLink={() => (
                <PivotItemWithTooltip
                  text="Activity Fields"
                  selected={selectedTab === 'activityFields'}
                  disabled={!hasAdditionalFields}
                  hoverMessage={
                    !hasAdditionalFields ? 'No additional fields' : ''
                  }
                />
              )}
              headerButtonProps={{
                disabled: !hasAdditionalFields,
              }}
            />
          )}
        </Pivot>
      </div>
      {selectedTab === 'name' && (
        <Name
          onOpenFullSearchAndSelect={onOpenFullSearchAndSelect}
          onSelectedItemChange={onSelectedItemChange}
          handleOmnibarContextChange={handleOmnibarContextChange}
          onSearch={() => {
            if (onSearch) {
              onSearch('name');
            }
          }}
        />
      )}
      {selectedTab === 'relatedTo' && (
        <RelatedTo
          onOpenFullSearchAndSelect={onOpenFullSearchAndSelect}
          disableMultiple={disableMultipleRelatedTos}
          onSelectedItemChange={onSelectedItemChange}
          handleOmnibarContextChange={handleOmnibarContextChange}
          onSearch={() => {
            if (onSearch) {
              onSearch('relatedTo');
            }
          }}
        />
      )}
      {selectedTab === 'activityFields' && (
        <ActivityFields onChange={onActivityFieldsChange} />
      )}
      {open && selectedTab === 'review' && (
        <Callout
          hidden={selectedTab !== 'review' || !open}
          styles={calloutStyles}
          target={ref.current}
          coverTarget
          isBeakVisible={false}
          alignTargetEdge
          directionalHint={DirectionalHint.topCenter}
          dismissOnTargetClick
          doNotLayer
          onDismiss={() => {
            setSelectedTab('name');
          }}
        >
          <Review onBack={() => setSelectedTab('name')} />
        </Callout>
      )}
      <div className="absolute bottom-0 w-full bg-gray5 height-[20px]">
        <div className="flex items-center justify-end">
          {customFooter && (
            <div className="pl-2 text-[13px]">{customFooter}</div>
          )}
          <ActionButton
            onClick={() => {
              if (onReviewSelection) {
                onReviewSelection(selectedTab);
              }
              setSelectedTab('review');
            }}
            className={cx(
              'pl-2 text-clari-blue/600 hover:text-clari-blue/700',
              customFooter && 'ml-auto',
              !customFooter && 'mr-auto',
            )}
            style={{
              fontSize: '13px',
              lineHeight: 1.43,
              fontWeight: 600,
              visibility:
                selectedTab === 'review' ||
                (selectedNames.length === 0 && selectedRelatedTos.length === 0)
                  ? 'hidden'
                  : 'visible',
            }}
          >
            Review selection
          </ActionButton>
          {!hideSaveButton && (
            <ActionButton
              primary
              onClick={() =>
                onConfirm &&
                onConfirm(selectedNames, selectedRelatedTos, additionalFields)
              }
              className="pr-2 text-clari-blue/600 hover:text-clari-blue/700 text-[13px] font-semibold"
            >
              Save
            </ActionButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchAndSelectMini;
