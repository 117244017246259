import { renderToStaticMarkup } from 'react-dom/server';
import groupBy from 'lodash.groupby';
import chunk from 'lodash.chunk';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import momentTimezone from 'moment-timezone';
import { ResponseTimeSlot } from '@groove/api/services/v1/scheduler';
import theme from '@groove/ui/theme';
import convertHexToRGB from '@groove/ui/utils/convertHexToRGB';

export interface OrganizationBrandData {
  /**
   * The brands background colors to use for borders / buttons
   */
  hex_color: string;

  /**
   * The url of the logo to include as an image
   *
   * @remarks
   * When use_custom_branding is false, this will be an empty string
   */
  logo_url: string;

  /**
   * A flag to decide whether to show brand colors and logo at all
   */
  use_custom_branding: boolean;
}

export const generateMeetingTimeSlotURL = (
  meetingSlug: string,
  timeSlotId: string,
): string => `${process.env.SCHEDULER_BASE_URL}/${meetingSlug}/${timeSlotId}`;

const generateHtml = (
  timeSlots: ResponseTimeSlot[],
  timeZone: string,
  scheduleFor: string,
  organizationBrandData: OrganizationBrandData,
  customMeetingLink: string,
): string => {
  const color = organizationBrandData.use_custom_branding
    ? organizationBrandData.hex_color || theme.secondary
    : theme.secondary;
  const rgbaColor = `rgba(${convertHexToRGB(color)},0.1)`;
  const logoUrl = organizationBrandData.use_custom_branding
    ? organizationBrandData.logo_url
    : null;
  const timeSlotsGroups = Object.entries(
    groupBy(timeSlots, (timeSlot: ResponseTimeSlot) =>
      formatInTimeZone(
        parseISO(timeSlot.starts_at),
        timeZone,
        'E, MMM dd',
      ).toString(),
    ),
  );
  const meetingSlug = customMeetingLink;
  const timeSlotGroupTableRows = chunk(timeSlotsGroups, 3);
  const html = renderToStaticMarkup(
    <body>
      <br />
      <div
        style={{
          fontFamily: 'sans-serif',
          width: 500,
          userSelect: 'none',
          border: '1px solid rgba(0,0,0,0.19)',
          borderRadius: 2,
          position: 'relative',
        }}
        contentEditable={false}
        spellCheck={false}
      >
        <table width={500}>
          <tr>
            <td
              style={{
                height: 8,
                backgroundColor: color,
              }}
            />
          </tr>
        </table>
        <div style={{ paddingLeft: 16 }}>
          {logoUrl && (
            <span>
              <br />
              <img
                src={logoUrl}
                alt="logo"
                height={32}
                style={{
                  height: 32,
                }}
              />
              <br />
            </span>
          )}
          <br />
          <div style={{ fontSize: 16 }}>Meeting with {scheduleFor}</div>
          <div style={{ fontSize: 12, color: theme.gray1 }}>
            These time slots are in {momentTimezone.tz(timeZone).format('z')}.
            Click to book a meeting.
          </div>
        </div>
        <table>
          {timeSlotGroupTableRows.map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={index}>
              {row.map(([date, timeSlots]) => (
                <td style={{ verticalAlign: 'top', marginRight: 6 }} key={date}>
                  <div
                    style={{
                      paddingLeft: 8,
                      textAlign: 'center',
                    }}
                  >
                    <br />
                    <div style={{ fontSize: 16 }}>{date}</div>
                    <table style={{ borderSpacing: 6 }}>
                      {timeSlots.map((timeSlot: ResponseTimeSlot) => (
                        <tr
                          key={timeSlot.public_id}
                          style={{ marginBottom: 14 }}
                        >
                          <td
                            key={timeSlot.public_id}
                            style={{
                              width: 120,
                              display: 'block',
                              background: rgbaColor,
                              border: `1px solid ${color}`,
                              padding: 6,
                              textAlign: 'center',
                            }}
                          >
                            <a
                              aria-label={`Meeting for ${timeSlot.starts_at}`}
                              href={generateMeetingTimeSlotURL(
                                meetingSlug,
                                timeSlot.public_id || '',
                              )}
                              style={{
                                display: 'block',
                                margin: 'auto',
                                color: 'black',
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                            >
                              <span style={{ fontSize: 12 }}>
                                {formatInTimeZone(
                                  parseISO(timeSlot.starts_at),
                                  timeZone,
                                  'h:mm a',
                                )}
                                {' - '}
                                {formatInTimeZone(
                                  parseISO(timeSlot.ends_at),
                                  timeZone,
                                  'h:mm a',
                                )}
                              </span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      <br />
    </body>,
  );

  return html;
};

export default generateHtml;
