import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getMoboEnabled,
  getCurrentUserRole,
} from 'Modules/Shared/selectors/users';
import { USER_ROLES } from 'Modules/Shared/constants';

const useMoboEnabled = (): boolean => {
  const [isEnabled, setIsEnabled] = useState(false);
  const isOrgSettingEnabled = useSelector(getMoboEnabled);
  const role = useSelector(getCurrentUserRole);

  useEffect(() => {
    setIsEnabled(isOrgSettingEnabled && role === USER_ROLES.ADMIN);
  }, [isOrgSettingEnabled, role]);

  return isEnabled;
};

export default useMoboEnabled;
