import * as React from 'react';
import cx from 'classnames';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon } from '@material-ui/icons';
import { Input, formGroupConnect } from '@groove-labs/groove-ui';

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
};

const useStyles = makeStyles(theme => ({
  searchIcon: {
    color: theme.palette.primary.main,
    position: 'relative',
    zIndex: 1,
    left: 2,
  },
  searchBarContainer: {
    marginLeft: 10,
    ...flexCenter,
  },
  searchBar: {
    position: 'relative',
    paddingLeft: theme.spacing(1),
    ...flexCenter,
    width: '100%',
  },
  searchInput: {
    visibility: 'visible',
    width: '100%',
    marginTop: 10,
  },
  inputTag: {
    paddingTop: 10,
    paddingLeft: 0,
  },
  hidden: {
    visibility: 'hidden',
  },
}));

interface SearchBarPropTypes {
  fieldId: string;
  groupId: string;
  placeholder: string;
  disableSearch?: boolean;
  searchIsVisible: boolean;
  onInputChange?: () => void;
  handleShowSearchClick: () => void;
}

const SearchBar: React.FC<SearchBarPropTypes> = ({
  fieldId,
  groupId,
  onInputChange,
  searchIsVisible,
  handleShowSearchClick,
  disableSearch = false,
  placeholder = 'Search',
}) => {
  const classes = useStyles({});
  const muiProps = {
    placeholder,
    classes: { input: classes.inputTag },
  };

  return (
    <div className={classes.searchBarContainer}>
      <div className={classes.searchBar}>
        <IconButton
          className={classes.searchIcon}
          onClick={handleShowSearchClick}
          disabled={disableSearch}
          aria-label="Search"
        >
          <SearchIcon />
        </IconButton>
        <div
          className={cx({
            [classes.searchInput]: true,
            [classes.hidden]: !searchIsVisible,
          })}
        >
          <Input
            groupId={groupId}
            fieldId={fieldId}
            muiProps={muiProps}
            disableSearch={disableSearch}
            onChange={onInputChange}
          />
        </div>
      </div>
    </div>
  );
};

SearchBar.displayName = 'SearchBar';

export default formGroupConnect()(SearchBar);
