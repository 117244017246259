import cx from 'classnames';
import { FC, useCallback, useState } from 'react';
import {
  IPersonaProps,
  Label,
  NormalPeoplePicker,
  PeoplePickerItem,
  ValidationState,
} from '@fluentui/react';
import RecordMenuItem from '@groove/ui/Components/RecordMenuItem';
import grooveSearch from '@groove/api/visualforce/grooveSearch';
import GrooveSearchResult from '@groove/api/visualforce/types/GrooveSearch/GrooveSearchResult';

import { DisplayableSearchResult } from '../transformSearchResult';

import { AdditionalFieldProps } from './types';

const pickerStyles = {
  root: cx(false && 'max-w-[150px]'),
  subComponentStyles: {
    personaCoin: { coin: { display: 'none' } },
    persona: { root: cx(false && 'w-[150px]') },
  },
};

const pickerContainerStyles = {
  root: cx(false && 'flex-grow pl-1'),
  text: cx('after:border-secondary', false && 'border-0 min-w-[120px]'),
};

const Lookup: FC<AdditionalFieldProps<string>> = ({
  additionalField,
  value,
  required,
  onChange,
}) => {
  const handleResolveSuggestions = useCallback(
    async (filter: string) => {
      if (filter.length < 3) {
        return [];
      }
      let results: GrooveSearchResult[] = [];
      const searchResults = await grooveSearch(
        filter,
        additionalField.referenceTo,
      );
      // Sometimes GE likes to coerce responses into the data/meta shape when the response is empty
      // which is horrifying, so this is just a quick and dirty way to see if the response is an array
      if (searchResults && searchResults.flat) {
        results = results.concat(searchResults.flat());
      }
      return results?.map(
        result =>
          ({
            key: result.Id,
            text: result.Name,
            secondaryText: result.Account?.Name || result.Type || result.Status,
            tertiaryText:
              result.Email ||
              result.StageName ||
              result.Industry ||
              result.Priority,
            'aria-label': result.Name,
          } as IPersonaProps),
      );
    },
    [additionalField.referenceTo],
  );

  const [selectedItems, setSelectedItems] = useState<IPersonaProps[]>(
    value
      ? [
          {
            key: additionalField.label,
            text: value,
          },
        ]
      : [],
  );

  const onItemsChange = useCallback(
    (items: IPersonaProps[] | undefined): void => {
      onChange((items?.[0]?.key as string) || null);
      setSelectedItems(items || []);
    },
    [onChange],
  );

  return (
    <div className={cx('relative border-gray1')}>
      <div className={cx('flex items-center justify-between')}>
        <Label htmlFor={additionalField.name} className="text-right">
          {additionalField.label}
        </Label>
      </div>
      <NormalPeoplePicker
        inputProps={{
          id: additionalField.name,
          'aria-label': 'Record Search',
          placeholder: 'Search for a record',
        }}
        itemLimit={1}
        pickerSuggestionsProps={{
          suggestionsContainerAriaLabel: 'Search Results',
        }}
        styles={pickerContainerStyles}
        onResolveSuggestions={handleResolveSuggestions}
        selectedItems={selectedItems}
        onChange={onItemsChange}
        resolveDelay={300}
        onRenderItem={props => (
          <PeoplePickerItem
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            styles={pickerStyles}
            item={{ ...props.item, ValidationState: ValidationState.valid }}
            removeButtonAriaLabel="Remove Record"
          />
        )}
        onRenderSuggestionsItem={(props, itemProps) => (
          <RecordMenuItem
            id={props.key as string}
            text={props.text}
            secondaryText={props.secondaryText}
            secondaryTextLabel={
              (props as DisplayableSearchResult).secondaryTextLabel
            }
            tertiaryText={(props as DisplayableSearchResult).tertiaryText}
            tertiaryTextLabel={
              (props as DisplayableSearchResult).tertiaryTextLabel
            }
            quarteraryText={(props as DisplayableSearchResult).quarteraryText}
            quarteraryTextLabel={
              (props as DisplayableSearchResult).quarteraryTextLabel
            }
            quinaryText={(props as DisplayableSearchResult).quinaryText}
            quinaryTextLabel={
              (props as DisplayableSearchResult).quinaryTextLabel
            }
          />
        )}
      />
    </div>
  );
};

export default Lookup;
