import { POST, PUT, DELETE } from 'GrooveHTTPClient/HTTPMethod';

import makeHTTPRequest from '../makeHTTPRequest';

const BASE_PATH = '/api/v2/saved_searches';

export const index = (type = 'PeopleImportSavedSearch') =>
  makeHTTPRequest({
    path: BASE_PATH,
    queryParameters: {
      type,
    },
  });

/**
 * @param {SavedSearch} savedSearch
 *
 * @return {Promise}
 * */
export const create = savedSearch =>
  makeHTTPRequest({
    path: BASE_PATH,
    method: POST,
    // this is explicitly NOT decamelized because the server
    // expects camelCase inside the savedSearch object
    body: { saved_search: savedSearch },
  });

/**
 * @param {SavedSearch} savedSearch
 *
 * @return {Promise}
 * */
export const update = savedSearch =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${savedSearch.id}`,
    method: PUT,
    body: { saved_search: savedSearch },
  });

/**
 * @param {Number} savedSearchId
 *
 * @return {Promise}
 * */
export const destroy = savedSearchId =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${savedSearchId}`,
    method: DELETE,
  });

/**
 * @param {Number} id
 *
 * @return {Promise}
 * */
export const show = ({ id }) => makeHTTPRequest({ path: `${BASE_PATH}/${id}` });
