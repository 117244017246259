export default function styles(theme) {
  return {
    root: {
      position: 'sticky',
      top: '0',
      zIndex: '1000',
      width: '100%',
    },
    appBarRoot: {
      transition: 'height 0.5s',
      overflow: 'visible',
    },
    appBarRootNoTabs: {
      height: 50,
    },
    appBarRootWithTabs: {
      height: 98,
    },
    logoTextContainer: {
      paddingRight: 10,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
    toolbar: {
      minHeight: 50,
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexItem: {},
    flexItemTitle: {
      marginLeft: theme.groove.unit * 6,
    },
    flexItemSearchBar: {
      flex: '0 0 auto',
    },
    flexItemRightMenu: {
      flex: '1 1 auto',
      justifyContent: 'flex-end',
    },
    appBarPrimaryColor: {
      background: theme.palette.text.neutralColor,
    },
    appBarOrangeColor: {
      background: theme.palette['naranja/400'],
    },
    headerTextColor: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightRegular,
    },
    rightAlignedContent: {
      display: 'flex',
    },
    userDisplayContainer: {
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    switchToClassicButton: {
      minWidth: 110,
      fontSize: 12,
    },
    switchToClassicButtonAnchor: {},
    grooveWordIcon: {
      paddingTop: theme.spacing.unit,
      marginLeft: theme.spacing.unit * 1.5,
    },
    routeTabsContainer: {
      justifyContent: 'center',
      minHeight: 0,
    },
  };
}
