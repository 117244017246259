import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const UpArrow: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 10 12"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <path d="M4.5 11.5C4.5 11.7761 4.72386 12 5 12C5.27614 12 5.5 11.7761 5.5 11.5L5.5 1.80298L9.12835 5.83448C9.31308 6.03974 9.62922 6.05638 9.83448 5.87165C10.0397 5.68692 10.0564 5.37077 9.87164 5.16552L5.37165 0.165518C5.27683 0.0601606 5.14174 0 5 0C4.85826 0 4.72317 0.0601606 4.62835 0.165518L0.128357 5.16552C-0.0563721 5.37077 -0.0397334 5.68692 0.165522 5.87165C0.370776 6.05638 0.686921 6.03974 0.871651 5.83448L4.5 1.80298L4.5 11.5Z" />
  </svg>
);
