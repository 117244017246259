import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const CheckmarkCircle: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <path d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM11.3584 5.64645C11.532 5.82001 11.5513 6.08944 11.4163 6.28431L11.3584 6.35355L7.35355 10.3584C7.17999 10.532 6.91056 10.5513 6.71569 10.4163L6.64645 10.3584L4.64645 8.35842C4.45118 8.16316 4.45118 7.84658 4.64645 7.65131C4.82001 7.47775 5.08944 7.45846 5.28431 7.59346L5.35355 7.65131L7 9.298L10.6513 5.64645C10.8466 5.45118 11.1632 5.45118 11.3584 5.64645Z" />
  </svg>
);
