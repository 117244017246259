import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import Typography from '@material-ui/core/Typography';
import {
  currentUser,
  canUseLoginAs,
  loginAsActive,
} from 'Modules/Shared/selectors/users';
import { USER_ROLES } from 'Modules/Shared/constants';
import trackUserMenuEvent from 'Modules/App/utils/trackUserMenuEvent';
import { RIT, actions } from '@groove-labs/groove-ui';
import { Divider, MenuItem } from '@material-ui/core';
import { LOGIN_AS_DIALOG_UI_KEY_PATH } from 'Modules/App/constants';
import { logout } from 'Modules/Shared/actions/app';
import { topNavTabClicked } from 'Modules/App/actions';
import { setActiveTab } from 'Modules/Shared/actions/location';
import { location } from 'Modules/Shared/selectors/location';
import { TABS } from 'Modules/Settings/constants';
import { ROUTE_NAMES } from 'Routing/constants';
import { history } from 'Utils/history';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

const { setProperty } = actions.ui;

export const USER_MENU_ACTIONS = {
  MANAGE_MY_ORG: 'Manage my Org',
  LOGIN_AS: 'Login as user',
  HELP: 'Help',
  CLARI_UNIVERSITY: 'Clari University',
  BEST_PRACTICES: 'Best Practices',
  INSTALL_EXTENSION: 'Install Extension',
  INSTALL_OUTLOOK_ADDIN: 'Install Outlook Add-in',
  LOGOUT: 'Log out',
  SETTINGS: 'Settings',
  KNOWLEDGE_BASE: 'Knowledge Base',
  CONTACT_SUPPORT: 'Contact Support',
};

@connect(
  state => {
    // Arguments: state, ownProps
    return {
      canUseLoginAs: canUseLoginAs(state),
      currentUser: currentUser(state),
      loginAsActive: loginAsActive(state),
      location: location(state),
    };
  },
  {
    setProperty,
    logout,
    topNavTabClicked,
    setActiveTab,
  }
)
export default class UserMenuOptions extends Component {
  static propTypes = {
    setProperty: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    currentUser: PropTypes.instanceOf(Map),
    location: PropTypes.instanceOf(Map).isRequired,
    loginAsActive: PropTypes.bool.isRequired,
    canUseLoginAs: PropTypes.bool.isRequired,
    topNavTabClicked: PropTypes.func.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    // parent props
    closeUserMenu: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentUser: null,
  };

  onManageMyOrgClick = () => {
    const { closeUserMenu, currentUser } = this.props;

    trackUserMenuEvent({
      currentUser,
      actionName: USER_MENU_ACTIONS.MANAGE_MY_ORG,
    });

    history.push(`/${ROUTE_NAMES.SETTINGS}?activeTab=${TABS.MANAGE_MY_ORG}`);
    closeUserMenu();
  };

  handleSettingsRedirect = () => {
    const { topNavTabClicked, setActiveTab, location, closeUserMenu } =
      this.props;

    const rootPath = location.get('rootPath');
    const activeTab = location.get('activeTab');

    const currentTabIsNotSettings = !(
      activeTab === TABS.MY_SETTINGS || activeTab === null
    );
    trackUserMenuEvent({ currentUser, actionName: USER_MENU_ACTIONS.SETTINGS });
    if (rootPath !== ROUTE_NAMES.SETTINGS) {
      history.push(`/${ROUTE_NAMES.SETTINGS}`);
    } else if (currentTabIsNotSettings) {
      setActiveTab({
        activeTab: TABS.MY_SETTINGS,
        clearAllOtherParameters: false,
      });
      topNavTabClicked(rootPath, TABS.MY_SETTINGS);
    }
    closeUserMenu();
  };

  buildSettingsMenuItem = () => (
    <MenuItem onClick={this.handleSettingsRedirect}>
      <Typography variant="body1">{USER_MENU_ACTIONS.SETTINGS}</Typography>
    </MenuItem>
  );

  buildManageMyOrgMenuItem = () => (
    <MenuItem onClick={this.onManageMyOrgClick}>
      <Typography variant="body1">{USER_MENU_ACTIONS.MANAGE_MY_ORG}</Typography>
    </MenuItem>
  );

  handleLoginAsUser = () => {
    const { currentUser } = this.props;
    trackUserMenuEvent({ currentUser, actionName: USER_MENU_ACTIONS.LOGIN_AS });
    this.props.setProperty({
      uiKeyPath: LOGIN_AS_DIALOG_UI_KEY_PATH,
      data: true,
    });
  };

  buildInstallOutlookAddinLink() {
    const { currentUser } = this.props;
    return currentUser?.get('provider') === 'microsoft_graph_auth' ? (
      <MenuItem onClick={this.handleInstallOutlookAddinLink}>
        <Typography variant="body1">
          {USER_MENU_ACTIONS.INSTALL_OUTLOOK_ADDIN}
        </Typography>
      </MenuItem>
    ) : null;
  }

  buildInstallExtensionLink() {
    return (
      <MenuItem onClick={this.handleInstallExtensionLink}>
        <Typography variant="body1">
          {USER_MENU_ACTIONS.INSTALL_EXTENSION}
        </Typography>
      </MenuItem>
    );
  }

  handleInstallExtensionLink = () => {
    const { currentUser, closeUserMenu } = this.props;
    trackUserMenuEvent({
      currentUser,
      actionName: USER_MENU_ACTIONS.INSTALL_EXTENSION,
    });
    closeUserMenu();
    const newTab = window.open(`${grooveEngineRootUrl}/setup`, '_blank');
    newTab.focus();
  };

  handleInstallOutlookAddinLink = () => {
    const { currentUser, closeUserMenu } = this.props;

    trackUserMenuEvent({
      currentUser,
      actionName: USER_MENU_ACTIONS.INSTALL_OUTLOOK_ADDIN,
    });
    closeUserMenu();
    const newTab = window.open(
      'https://appsource.microsoft.com/en-US/product/office/WA200002356?exp=ubp8&tab=Overview',
      '_blank',
      'noopener'
    );
    newTab.focus();
  };

  handleLogout = () => {
    trackUserMenuEvent({ currentUser, actionName: USER_MENU_ACTIONS.LOGOUT });
    this.props.logout();
    this.props.closeUserMenu();
  };

  renderLoginAsMenuItem = () => (
    <MenuItem onClick={this.handleLoginAsUser}>
      <Typography variant="body1">{USER_MENU_ACTIONS.LOGIN_AS}</Typography>
    </MenuItem>
  );

  render() {
    const { currentUser, loginAsActive, canUseLoginAs } = this.props;
    const currentUserIsAdmin =
      currentUser && currentUser.get('role') === USER_ROLES.ADMIN;

    return (
      <React.Fragment>
        {this.buildSettingsMenuItem()}
        {RIT(currentUserIsAdmin, this.buildManageMyOrgMenuItem)}
        {RIT(!loginAsActive && canUseLoginAs, this.renderLoginAsMenuItem)}
        <Divider light />
        {this.buildInstallExtensionLink()}
        {this.buildInstallOutlookAddinLink()}
        <MenuItem onClick={this.handleLogout}>
          <Typography variant="body1">
            {loginAsActive && 'Log back in as myself'}
            {!loginAsActive && USER_MENU_ACTIONS.LOGOUT}
          </Typography>
        </MenuItem>
      </React.Fragment>
    );
  }
}
