export const TWILIO_CONNECTION_NETWORK_WARNINGS = [
  'high-rtt',
  'low-mos',
  'high-jitter',
  'high-packet-loss',
  'high-packets-lost-fraction',
  'low-bytes-received',
  'low-bytes-sent',
  'ice-connectivity-lost',
];

export const VALID_CALL_WAITING_REASONS = [
  'open',
  'pending',
  'reconnecting',
  'ringing',
];

// constant bridge idle countdown durations
export const START_IDLE_COUNTDOWN_DURATION = 420000;
export const END_CONFERENCE_DURATION = 90000;
export const HELP_CENTER_LINK =
  'https://clari.my.site.com/customer/articles/Knowledge/Introducing-Groove-OmniDialer';
export const CACHED_CONTEXT_KEY = 'cachedContext';

export const WAIT_DEBOUNCE_MILLISECONDS = 3000;

export const FETCHED_TWILIO_TOKEN_TIME = 'fetchedTokenTime';
export const TWILIO_VALID_CLIENT_TOKEN_DURATION = 18 * 60 * 60 * 1000;
export const TWILIO_TOKEN_CHECK_INTERVAL = 1 * 60 * 60 * 1000;

export const EXPIRED_SALESFORCE_TOKENS = 'EXPIRED_SALESFORCE_TOKENS';
