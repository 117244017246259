import React, { FC, useEffect, useRef } from 'react';
import tinymce from 'tinymce';

import { VIDYARD_PLAYER_WIDTH_LIMIT } from '../constants';

interface VidyardContainerProps {
  onClose: () => void;
  recipientEmail?: string;
  showVidyardLink?: boolean;
}

declare global {
  // eslint-disable-next-line
  var Vidyard: {
    goVideo: {
      createLibrary: (
        container: HTMLDivElement,
        options: { clientId: string },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) => { on: (event: string, cb: (response: any) => void) => void };
    };
  };
}

const VidyardContainer: FC<VidyardContainerProps> = ({
  onClose,
  recipientEmail,
  showVidyardLink,
}) => {
  const vidyardContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (vidyardContainer && vidyardContainer.current) {
      const lib = Vidyard.goVideo.createLibrary(vidyardContainer.current, {
        clientId: [
          process.env.WEB_APP_PRODUCTION_URL,
          process.env.ADD_IN_PRODUCTION_URL,
        ].includes(
          // eslint-disable-next-line no-restricted-globals
          location.origin,
        )
          ? 'grooveprod.co'
          : 'testgroove.co',
      });
      lib.on('player:created', response => {
        const videoUrl = response.embed_codes.branded_sharing_page;
        if (!videoUrl) {
          return;
        }

        // when person email is passed we don't set an "email" merge field
        const vyemail = recipientEmail || '{!email}';

        // Don't allow too big vidyard image/preview
        const widthAttr =
          response.player.width > VIDYARD_PLAYER_WIDTH_LIMIT
            ? `width="${VIDYARD_PLAYER_WIDTH_LIMIT}" `
            : '';

        const videoLink = `<a href="${videoUrl}?vyemail=${vyemail}"><img src="https:${response.embed_codes.thumbnail}" alt="${response.player?.name}" ${widthAttr}/></a>`;
        tinymce.activeEditor.insertContent(videoLink);
        if (showVidyardLink) {
          const vidyardVideoLink = `<br /><a href="${videoUrl}?vyemail=${vyemail}">${
            response.player?.name || 'Video Link'
          }</a>`;
          tinymce.activeEditor.insertContent(vidyardVideoLink);
        }
        onClose();
      });
    }
  }, [recipientEmail, onClose, showVidyardLink]);

  return <div ref={vidyardContainer} />;
};

export default VidyardContainer;
