import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const BuildingIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, 'fill-current')}
  >
    <path d="M6.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM7 7a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-.5 3a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-.5 3a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm.5 2a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-5-8A1.5 1.5 0 0 0 4 3.5v10a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-5A1.5 1.5 0 0 0 11.5 7H11V3.5A1.5 1.5 0 0 0 9.5 2h-4zM5 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 1 .5.5V13h-1v-1.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5V13H5V3.5zm5 8.5v1h-.997v-1H10zm-1.997 0v1H7v-1h1.003z" />
  </svg>
);
