import { useMutation, UseMutationResult } from 'react-query';
import grooveUpdate, {
  ApiGrooveUpdate,
} from '@groove/api/visualforce/grooveUpdate';

export type UseGrooveUpdateParams = {
  id: string;
  sobject: string;
  additionalParams?: Record<string, string>;
};

const useGrooveUpdate = (
  id: string,
  sobject: string,
): UseMutationResult<
  ApiGrooveUpdate,
  unknown,
  UseGrooveUpdateParams,
  unknown
> => {
  return useMutation(({ additionalParams }: UseGrooveUpdateParams) =>
    grooveUpdate({
      id,
      sobject,
      ...additionalParams,
    }),
  );
};

export default useGrooveUpdate;
