import { Record } from 'immutable';

const defaults = {
  reason: '',
  isRecoverable: true,
  description: '',
};

export const FAILURES = {
  CONTACT_NOT_FOUND: 'CONTACT_NOT_FOUND',
  DEACTIVATED_USER: 'DEACTIVATED_USER',
  INVALID_RECIPIENT: 'INVALID_RECIPIENT',
  GOOGLE_API_ERROR: 'GOOGLE_API_ERROR',
  INVALID_GOOGLE_TOKEN: 'INVALID_GOOGLE_TOKEN',
  INVALID_SFDC_TOKEN: 'INVALID_SFDC_TOKEN',
  USER_CANCELLED: 'USER_CANCELLED',
  EMAIL_BOUNCED: 'EMAIL_BOUNCED',
  MERGE_FIELD_ISSUE: 'MERGE_FIELD_ISSUE',
  OPTED_OUT: 'OPTED_OUT',
  FLOW_NOT_ACTIVE: 'FLOW_NOT_ACTIVE',
  UNKNOWN: 'UNKNOWN',
  FLOW_ACTIONS_LIMIT_REACHED: 'FLOW_ACTIONS_LIMIT_REACHED',
};

const failureDescriptions = {
  [FAILURES.OPTED_OUT]: 'Opted out of receiving emails',
  [FAILURES.MERGE_FIELD_ISSUE]: 'Merge field issue',
  [FAILURES.EMAIL_BOUNCED]: 'Email bounced',
  [FAILURES.USER_CANCELLED]: 'Cancelled send of email',
  [FAILURES.INVALID_SFDC_TOKEN]: 'Salesforce connection issue',
  [FAILURES.INVALID_GOOGLE_TOKEN]: 'Google connection issue',
  [FAILURES.GOOGLE_API_ERROR]: 'Google connection issue',
  [FAILURES.INVALID_RECIPIENT]: 'Invalid email address',
  [FAILURES.DEACTIVATED_USER]: 'Groove user deactivated',
  [FAILURES.CONTACT_NOT_FOUND]: 'Contact not found in Salesforce',
  [FAILURES.FLOW_NOT_ACTIVE]: 'Flow is not active',
  [FAILURES.UNKNOWN]: 'Unknown',
  [FAILURES.FLOW_ACTIONS_LIMIT_REACHED]: 'Flow actions limit reached',
};
export default class Failure extends Record(defaults) {
  static from(failure) {
    const reason = failure.get('reason');

    return new Failure({
      reason,
      isRecoverable: failure.get('isRecoverable'),
      description: failureDescriptions[reason],
    });
  }
}
