import { TemplateFolder } from '@groove/api/services/v1/templateFolders';
import { FC } from 'react';
import { Stack } from '@fluentui/react';
import StyledSeparator from '@groove/ui/Components/StyledSeparator';
import TruncatedTextWithTooltip from '@groove/ui/Components/TruncatedTextWithTooltip';

import TemplateFolderIcon from '../icons/folder_icon.svg';
import TemplateFolderStarrer from '../TemplateFolderStarrer';

const TemplateFolderItem: FC<{
  folder: TemplateFolder;
  setSelectedFolder: (folder: TemplateFolder) => void;
}> = ({ folder, setSelectedFolder }) => {
  return (
    <>
      <Stack
        className="flex flex-row items-center w-auto p-3 cursor-pointer hover:bg-neutral/200 hover:text-primary"
        aria-label={`template-folder-item-${folder.id}`}
        onClick={() => setSelectedFolder(folder)}
      >
        <img src={TemplateFolderIcon} alt="folder-icon" width={20} />
        <TruncatedTextWithTooltip
          text={folder.name}
          textClasses="pl-1 text-xs text-primary font-semibold"
        />
        <div className="flex flex-grow justify-end">
          <TemplateFolderStarrer isFavorite templateFolderId={folder.id} />
        </div>
      </Stack>
      <StyledSeparator xMargin={12} />
    </>
  );
};

export default TemplateFolderItem;
