import { createActions } from '@groove-labs/action-utils';

import { Action } from 'Src/@types/reducer.d.ts';
import { TemplateData } from 'Modules/Templates/types.d.ts';

type cachedTemplateDataType = {
  cachedTemplateData: TemplateData;
};

export type CachedAction = Action<cachedTemplateDataType> | Action<null>;

const types = {
  SHARED: {
    CACHED: {
      STORE_TEMPLATE_DATA: 'STORE_TEMPLATE_DATA',
      CLEAR_TEMPLATE_DATA: 'CLEAR_TEMPLATE_DATA',
      COPYING_TEMPLATE: 'COPYING_TEMPLATE',
      NOT_COPYING_TEMPLATE: 'NOT_COPYING_TEMPLATE',
    },
  },
};

export const actionTypes = createActions<typeof types>(types).SHARED.CACHED;

export const storeCachedTemplateData = ({
  cachedTemplateData,
}: cachedTemplateDataType): CachedAction => ({
  type: actionTypes.STORE_TEMPLATE_DATA,
  payload: { cachedTemplateData },
});

export const clearCachedTemplateData = (): CachedAction => ({
  type: actionTypes.CLEAR_TEMPLATE_DATA,
});

export const copyingTemplate = (): CachedAction => ({
  type: actionTypes.COPYING_TEMPLATE,
});

export const notCopyingTemplate = (): CachedAction => ({
  type: actionTypes.NOT_COPYING_TEMPLATE,
});
