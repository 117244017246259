import { createSelector } from 'reselect';
import { isUndefined } from 'lodash-es';
import {
  AVAILABLE_ACTORS_KEY_PATH,
  CURRENT_ACTORS_KEY_PATH,
  OWNER_SCOPE,
  WRITE_SCOPE,
  IS_MASTER_FLOW_KEY_PATH,
} from 'Modules/SharingDialog/constants';
import { testActorEquality } from 'Modules/SharingDialog/utils';

export const isLoading = state => state.getIn(['SharingDialog', 'loading']);
export const currentActors = state =>
  state.getIn(['ui', ...CURRENT_ACTORS_KEY_PATH]);
export const availableActors = state =>
  state.getIn(['ui', ...AVAILABLE_ACTORS_KEY_PATH]);
export const isMasterFlow = state =>
  state.getIn(['ui', ...IS_MASTER_FLOW_KEY_PATH]);
export const canonicalAvailableActors = state =>
  state.getIn(AVAILABLE_ACTORS_KEY_PATH);
export const canonicalCurrentActors = state =>
  state.getIn(CURRENT_ACTORS_KEY_PATH);
export const canonicalIsMasterFlow = state =>
  state.getIn(IS_MASTER_FLOW_KEY_PATH);

/**
 * Has the list of currentActors changed compared to the cononical list?
 *
 * @return boolean
 * */
export const wereActorsRemoved = createSelector(
  currentActors,
  canonicalCurrentActors,
  (currentActors, canonicalCurrentActors) => {
    if (canonicalCurrentActors.size > currentActors.size) {
      return true;
    }
    return canonicalCurrentActors
      .entrySeq()
      .some(([actorKey]) => !currentActors.get(actorKey));
  }
);

/**
 * Has the list of current Actors been changed at all? Used to gate whether or not there are
 * changes that need to be committed.
 *
 * @return boolean
 * */
export const isDirty = createSelector(
  currentActors,
  canonicalCurrentActors,
  isMasterFlow,
  canonicalIsMasterFlow,
  (
    sourceCurrentActors,
    canonicalCurrentActors,
    isMasterFlow,
    canonicalIsMasterFlow
  ) => {
    // Immediately return true if they are not the same size
    if (canonicalCurrentActors.size !== sourceCurrentActors.size) {
      return true;
    }

    // Return true if the actorType is flow and the masterFlow sharing settings have changed
    if (!isUndefined(isMasterFlow) && isMasterFlow !== canonicalIsMasterFlow) {
      return true;
    }

    // The state is dirty if:
    // 1. A source actor is not in canonical
    // 2. A source actor differs from canonical
    return sourceCurrentActors.entrySeq().some(([actorKey, actor]) => {
      const actorInCanonical = canonicalCurrentActors.get(actorKey);
      // Return true if actor is not in canonical
      if (!actorInCanonical) return true;

      return !testActorEquality(actor, actorInCanonical);
    });
  }
);

/**
 * Select Actor from currentActors.
 *
 * @param {string} actorType
 * @param {number} actorId
 *
 * @return {function}
 * */
export const makeGetCurrentActor = (actorType, actorId) => {
  return createSelector(currentActors, actors => {
    return actors.find(actor => {
      return actorId === actor.get('id') && actorType === actor.get('type');
    });
  });
};

/**
 * Select canonical data for Actor, whether or ont it is in availableActors or currentActors.
 *
 * @param {string} actorType
 * @param {number} actorId
 *
 * @return {function}
 * */
export const makeGetCanonicalActor = (actorType, actorId) => {
  return createSelector(
    canonicalAvailableActors,
    canonicalCurrentActors,
    (availableActors, currentActors) => {
      const allActors = availableActors.concat(currentActors);

      return allActors.find(actor => {
        return actorId === actor.get('id') && actorType === actor.get('type');
      });
    }
  );
};

/**
 * Is the current Actor an owner of the Entity?
 *
 * @param {string} actorType
 * @param {number} actorId
 *
 * @return {function}
 * */
export const makeGetIsOwner = (actorType, actorId) => {
  return createSelector(makeGetCurrentActor(actorType, actorId), actor =>
    actor.get('scopes').includes(OWNER_SCOPE)
  );
};

/**
 * Can the current Actor edit the Entity?
 *
 * @param {string} actorType
 * @param {number} actorId
 *
 * @return {function}
 * */
export const makeGetCanEdit = (actorType, actorId) => {
  return createSelector(makeGetCurrentActor(actorType, actorId), actor =>
    actor.get('scopes').includes(WRITE_SCOPE)
  );
};
