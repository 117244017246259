import { call } from 'redux-saga/effects';
import {
  initialize as ldInitialize,
  LDClient as LDClientInterface,
  LDUser,
} from 'launchdarkly-js-client-sdk';
import { SagaIterator } from 'redux-saga';
import * as Sentry from '@sentry/browser';
import { getLdUser } from 'GrooveHTTPClient/users';
import {
  isUsingGEReviewApp,
  isUsingGEStaging,
  isUsingGEDemo,
  isUsingGEProduction,
  isUsingGEQA,
} from 'Utils/reviewAppUtils';

// eslint-disable-next-line import/no-mutable-exports
let ldClient: LDClientInterface;

export function* initialize(): SagaIterator {
  const { data: ldUser }: { data: LDUser } = yield call(getLdUser);
  let clientSideId;
  if (isUsingGEReviewApp() || isUsingGEStaging()) {
    clientSideId = process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID_STAGING;
  } else if (isUsingGEQA()) {
    clientSideId = process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID_QA;
  } else if (isUsingGEDemo()) {
    clientSideId = process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID_DEMO;
  } else if (isUsingGEProduction()) {
    clientSideId = process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID_PRODUCTION;
  } else {
    clientSideId =
      process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID || '5e8539599e80da08315d4440';
  }

  try {
    ldClient = ldInitialize(clientSideId, ldUser);
    yield call(ldClient.waitForInitialization);
  } catch (e) {
    Sentry.captureException(e);
    ldClient = {
      variation: () => false,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      on: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      off: () => {},
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
  }
}

export const getLdClient = (): LDClientInterface => ldClient;
