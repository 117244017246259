import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  Breadcrumbs,
  GlobalHandlers,
  LinkedErrors,
  Dedupe,
} from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

const useSentry = (): void => {
  useEffect(() => {
    if (process.env.SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.SENTRY_DSN,
        attachStacktrace: true,
        integrations: [
          new Breadcrumbs(),
          new GlobalHandlers(),
          new LinkedErrors(),
          new Dedupe(),
          new BrowserTracing(),
        ],
        tracesSampleRate: 0.01,
      });
    }
  }, []);
};

export default useSentry;
