import makeHTTPRequest from '../makeHTTPRequest';
import { POST } from 'GrooveHTTPClient/HTTPMethod';

export const getContactFieldSets = () =>
  makeHTTPRequest({ path: 'api/v2/contacts/sfdc_fields' });

export const getLeadFieldSets = () =>
  makeHTTPRequest({ path: 'api/v2/leads/sfdc_fields' });

export const searchAccounts = searchParams =>
  makeHTTPRequest({
    path: `/api/v2/salesforce_lookup_field_search/search_by_account_name?search=${searchParams}`,
  });

export const getRecommendedAccounts = searchParams =>
  makeHTTPRequest({
    path: `/api/v2/people_import/people_accounts?search=${searchParams}`,
  });

export const bulkCreateContacts = records =>
  makeHTTPRequest({
    path: 'api/v2/contacts/batch_create',
    method: POST,
    body: {
      records,
    },
  });

export const bulkCreateLeads = records =>
  makeHTTPRequest({
    path: 'api/v2/leads/batch_create',
    method: POST,
    body: {
      records,
    },
  });

export const bulkCreateAsync = ({ records, sobject_type }) =>
  makeHTTPRequest({
    path: 'api/v2/salesforce_bulk_api/bulk_create_async',
    method: POST,
    body: {
      records,
      sobject_type,
    },
  });

export const bulkCreateBatchStatus = ({ jobId, batchId }) =>
  makeHTTPRequest({
    path: 'api/v2/salesforce_bulk_api/batch_status',
    method: POST,
    body: { job_id: jobId, batch_id: batchId },
  });

export const createImportJob = ({ jobId, batchId, flowId }) =>
  makeHTTPRequest({
    path: 'api/v2/salesforce_bulk_api/create_import_job',
    method: POST,
    body: { job_id: jobId, batch_id: batchId, flow_id: flowId },
  });
