import { Record, Set } from 'immutable';

const defaults = {
  filters: new Set(),
  orderBy: null,
  orderDir: null,
  offset: null,
  limit: null,
  scope: null,
  sortDirection: null,
  sortField: null,
};

export default class Query extends Record(defaults) {}
