import { FC } from 'react';
import { TextField } from '@fluentui/react';

import { AdditionalFieldProps } from './types';

const Text: FC<AdditionalFieldProps<string>> = ({
  additionalField,
  value,
  required,
  onChange,
  multiline,
}) => {
  return (
    <TextField
      multiline={multiline}
      label={additionalField.label}
      value={value || ''}
      required={required}
      onChange={(e, newValue) => onChange(newValue || '')}
    />
  );
};

export default Text;
