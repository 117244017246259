type CloudsParams = {
  width: number;
  height: number;
};

export default function Clouds({ width, height }: CloudsParams) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 793 107"
    >
      <g fill="#F2F6F9" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path
          d="M480 107h195s-9.394-28.205-33.47-28.205c-18.027 0-26.144 11.915-43.45 11.915-17.305
          0-33.55-29.71-67.345-29.71C496.525 61 480 107 480 107zM793 51H601s13.106-32.792 40.014-31.41c26.908
          1.382 23.79 21.267 39.547 21.267C696.318 40.857 707.515 0 738.291 0 779.199 0 793 51 793 51zM0 72h205c-7.663-16.155-19.314-30.416-35.19-30.893-18.949-.567-27.476
          21.935-45.676 21.935C105.934 63.042 95.614 8 60.06 8 24.099 8 7.469 44.255 0 72z"
          transform="translate(-157 -186) translate(157 186)"
        />
      </g>
    </svg>
  );
}
