import { actions, selectors } from '@groove-labs/groove-ui';
import { List } from 'immutable';
import {
  contactAndLeadIdsFromReport,
  reportFolders,
  reports,
  searchBySoqlQuery,
} from 'GrooveHTTPClient/peopleImport';
import { handleInvalidSalesforceConnectionHTTPRequest } from 'GrooveHTTPClient/sagas';
import { range, uniqBy, isArray } from 'lodash-es';
import {
  actionTypes as peopleImportActionTypes,
  requestProcessSearchResults,
  setNoResultsFound,
  setSearching,
} from 'Modules/PeopleImportDialog/actions';
import { resetTableResults } from 'Modules/PeopleImportDialog/submodules/peopleTable/actions';
import { actionTypes } from 'Modules/PeopleImportDialog/submodules/reports/actions/index';
import { buildSoqlQuery } from 'Modules/PeopleImportDialog/submodules/reports/utils';
import { combineResponses } from 'Modules/PeopleImportDialog/submodules/csvLookup/utils';
import {
  REPORT_SIDEBAR_GROUP_ID,
  ROWS_PER_REQUEST_LIMIT,
} from 'Modules/PeopleImportDialog/submodules/reports/constants';
import {
  contactIds,
  leadIds,
} from 'Modules/PeopleImportDialog/submodules/reports/selectors/index';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import {
  all,
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { getFlowId } from 'Modules/FlowsShow/selectors';

const { actionTypes: formActionTypes } = actions.form;
const { makeGetFieldValue, makeGetSelectedData } = selectors.form;

// -------------- Helpers ---------------

function* handlePeopleFetchResponse(data, success, message) {
  if (success) {
    yield put({ type: actionTypes.FETCH_PEOPLE.SUCCESS });
    yield put(requestProcessSearchResults({ data }));
  } else {
    yield put({ type: actionTypes.FETCH_PEOPLE.FAILURE });
    yield put(
      pushSnackbarMessage({
        message: message || 'Something went wrong when searching',
      })
    );
    yield put(resetTableResults());
  }
}

function* fetchPeopleForReportHelper({ ids, rowsLimit, selectedObjectType }) {
  // this will handle the actual API call and return the data for continued processing
  const fields =
    selectedObjectType === 'Contact'
      ? new List(['Name', 'Email', 'Account.Name', 'Id', 'Title'])
      : new List(['Name', 'Email', 'Company', 'Id', 'Title']);
  const flowId = yield select(getFlowId);

  if (ids.size <= rowsLimit) {
    // If the number of ids are smaller than the limit, do a normal non-batch request
    const soqlQuery = buildSoqlQuery(selectedObjectType, fields, ids);

    try {
      const response = yield call(
        handleInvalidSalesforceConnectionHTTPRequest,
        searchBySoqlQuery,
        {
          objectName: selectedObjectType,
          soqlQuery,
          flowId,
        }
      );

      return {
        responseData: response.data,
        responseSuccess: response.meta.success,
        reponseMessage: response.data && response.data.message,
      };
    } catch (e) {
      // TODO: @jaebradley clean this up - we're doing this to avoid breaking changes
      // I have modified function to helper method, returns nulls if something screws up. - Oti.
      Sentry.withScope(scope => {
        scope.setExtra('sagaStack', e.sagaStack);
        Sentry.captureException(e.stack);
      });

      return {
        responseData: null,
        responseSuccess: false,
        reponseMessage: null,
      };
    }
  } else {
    // Get the number of calls we need to make. e.g. if the ids are 420 and the rowsLimit is 100, we will make
    // 4 calls of 100 each plus one call of 20, totalling to 5 calls.
    const upperLimit = Math.ceil(ids.size / rowsLimit);

    // break ids into chunks by the rows limit and send one call each
    let responses;
    try {
      responses = yield all(
        range(0, upperLimit).map(index => {
          const startIndex = index * rowsLimit;
          const endIndex = Math.min(startIndex + rowsLimit, ids.size);
          const idsChunk = ids.slice(startIndex, endIndex);
          const soql = buildSoqlQuery(selectedObjectType, fields, idsChunk);
          return call(
            handleInvalidSalesforceConnectionHTTPRequest,
            searchBySoqlQuery,
            {
              objectName: selectedObjectType,
              soqlQuery: soql,
              flowId,
            }
          );
        })
      );
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setExtra('sagaStack', e.sagaStack);
        Sentry.captureException(e.stack);
      });
    }

    if (responses && responses[0]) {
      const combinedData = combineResponses(responses);
      return {
        responseData: combinedData,
        responseSuccess: responses[0].meta.success,
        reponseMessage: responses[0].data && responses[0].data.message,
      };
    }
    // something went wrong return default null values
    return { responseData: null, responseSuccess: false, reponseMessage: null };
  }
}

// -------------- Handlers --------------

export function* fetchReportFolders() {
  yield put({ type: actionTypes.FETCH_REPORT_FOLDERS.PROGRESS });
  try {
    const response = yield* handleInvalidSalesforceConnectionHTTPRequest(
      reportFolders
    );
    const salesforceFolders = response.data;

    const allFolders = [
      { name: 'Unfiled Public Reports', value: 'Public Reports' },
      { name: 'My Personal Custom Reports', value: 'Private Reports' },
    ].concat(salesforceFolders);
    yield put({
      type: actionTypes.FETCH_REPORT_FOLDERS.SUCCESS,
      payload: allFolders,
    });
  } catch (e) {
    yield put({ type: actionTypes.FETCH_REPORT_FOLDERS.FAILURE });
  }
}

function* fetchReports({ payload }) {
  // this action is used in both import by reports and automated actions
  const { groupId = REPORT_SIDEBAR_GROUP_ID, reportFolder } = payload;

  const showOnlyMyReports = yield select(
    makeGetFieldValue('showOnlyMyReports'),
    { groupId }
  );

  yield put({ type: actionTypes.FETCH_REPORTS.PROGRESS });

  // when reportFolder is passed take it preor
  const folder =
    reportFolder ||
    (yield select(makeGetSelectedData('reportFolders'), { groupId }));

  // when value is blank simply set no reports
  if (folder && !isArray(folder)) {
    const folderName = folder.get('value') || folder.get('name');
    try {
      const response = yield* handleInvalidSalesforceConnectionHTTPRequest(
        reports,
        { folderName, showOnlyMyReports }
      );
      yield put({
        type: actionTypes.FETCH_REPORTS.SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      yield put({ type: actionTypes.FETCH_REPORTS.FAILURE });
    }
  } else {
    yield put({ type: actionTypes.FETCH_REPORTS.SUCCESS, payload: [] });
  }
}

function* fetchContactAndLeadIdsFromReport() {
  yield put({ type: actionTypes.FETCH_REPORT.PROGRESS });
  const reportField = yield select(makeGetSelectedData('reports'), {
    groupId: REPORT_SIDEBAR_GROUP_ID,
  });

  try {
    const response = yield* handleInvalidSalesforceConnectionHTTPRequest(
      contactAndLeadIdsFromReport,
      { reportId: reportField.get('value') }
    );

    const { contactIds, leadIds, sfdcWhatIdMap } = response.data;

    yield put({
      type: actionTypes.FETCH_REPORT.SUCCESS,
      payload: {
        contactIds,
        leadIds,
        sfdcWhatIdMap,
      },
    });
  } catch (e) {
    yield put({ type: actionTypes.FETCH_REPORT.FAILURE });

    let message;
    if (e.response && e.response.data && e.response.data.message) {
      message = e.response.data.message;
    } else {
      message = 'Failed to fetch the selected report';
    }

    yield put(pushSnackbarMessage({ message }));
    yield cancel();
  }
}

function* fetchPeopleForReport({ payload }) {
  yield put({ type: actionTypes.FETCH_PEOPLE.PROGRESS });
  const rowsLimit = payload ? payload.rowsLimit : ROWS_PER_REQUEST_LIMIT;

  try {
    // reset table to remove pagination
    yield put(resetTableResults());

    // Before searching ensure the no results indicator is set to false.
    yield put(setNoResultsFound(false));

    yield put(setSearching(true));

    const contactListIds = yield select(contactIds);
    const leadListIds = yield select(leadIds);

    if (contactListIds.size === 0 && leadListIds.size === 0) {
      // this report contains no contacts or leads.
      yield put(setSearching(false));
      yield put({ type: actionTypes.FETCH_PEOPLE.FAILURE });
      yield put(
        pushSnackbarMessage({
          message:
            'No contacts or leads found in this report. Please check that your report includes the contact or lead object and try again.',
        })
      );
      return;
    } else if (contactListIds.size > 0 && leadListIds.size > 0) {
      // Fetch both contacts and leads present in report - make 2 API calls to retrieve data
      // cancel the action when a tab is switched
      const { responses, cancel } = yield race({
        responses: all([
          call(fetchPeopleForReportHelper, {
            ids: contactListIds,
            rowsLimit,
            selectedObjectType: 'Contact',
          }),
          call(fetchPeopleForReportHelper, {
            ids: leadListIds,
            rowsLimit,
            selectedObjectType: 'Lead',
          }),
        ]),
        cancel: take(peopleImportActionTypes.REQUEST_SET_ACTIVE_TAB),
      });

      if (cancel) {
        yield put(setSearching(false));
        return;
      }

      const [
        {
          responseData: contactData,
          responseSuccess: contactSuccess,
          responseMessage: contactMessage,
        },
        {
          responseData: leadData,
          responseSuccess: leadSuccess,
          responseMessage: leadMessage,
        },
      ] = responses;

      // combine API responses
      let combinedResponse = null;
      let combinedMessage = null;
      if (leadSuccess && contactSuccess) {
        combinedMessage = contactMessage || leadMessage;

        combinedResponse = {
          columnData: uniqBy(
            [...contactData.columnData, ...leadData.columnData],
            'fieldNameOrPath'
          ),
          hasContactConditions:
            contactData.hasContactConditions || leadData.hasContactConditions,
          intersectionEmails: [
            ...contactData.intersectionEmails,
            ...leadData.intersectionEmails,
          ],
          intersectionIds: [
            ...contactData.intersectionIds,
            ...leadData.intersectionIds,
          ],
          peopleResults: [
            ...contactData.peopleResults,
            ...leadData.peopleResults,
          ],
          results: [...contactData.results, ...leadData.results],
          importRuleViolations: {
            ...contactData.importRuleViolations,
            ...leadData.importRuleViolations,
          },
          duplicatePeopleInFlow: {
            ...contactData.duplicatePeopleInFlow,
            ...leadData.duplicatePeopleInFlow,
          },
        };
      }

      yield* handlePeopleFetchResponse(
        combinedResponse,
        leadSuccess && contactSuccess,
        combinedMessage
      );
    } else {
      // in this case we have either contacts or leads not both
      // we have either contacts only or leads only handle appropriately
      const ids = contactListIds.size > 0 ? contactListIds : leadListIds;
      const selectedObjectType = contactListIds.size > 0 ? 'Contact' : 'Lead';

      // Fetch data and cancel the action when a tab is switched
      const { response, cancel } = yield race({
        response: call(fetchPeopleForReportHelper, {
          ids,
          rowsLimit,
          selectedObjectType,
        }),
        cancel: take(peopleImportActionTypes.REQUEST_SET_ACTIVE_TAB),
      });

      const { responseData, responseSuccess, responseMessage } = response;

      if (cancel) {
        yield put(setSearching(false));
        return;
      }

      yield* handlePeopleFetchResponse(
        responseData,
        responseSuccess,
        responseMessage
      );
    }
  } catch (e) {
    const TINY_LIMIT = 40;
    // when we encounter an error we try to fetch the result in smaller batches, once more
    if (rowsLimit > TINY_LIMIT) {
      yield put({
        type: actionTypes.FETCH_PEOPLE.BEGIN,
        payload: { rowsLimit: TINY_LIMIT },
      });
    } else {
      console.error(e); // eslint-disable-line
      Sentry.withScope(scope => {
        scope.setExtra('sagaStack', e.sagaStack);
        Sentry.captureException(e.stack);
      });
      yield put({ type: actionTypes.FETCH_PEOPLE.FAILURE });
      yield put(
        pushSnackbarMessage({
          message: 'Something went wrong when searching',
        })
      );
      yield put(resetTableResults());
    }
  }
  yield put(setSearching(false));
}

export { fetchPeopleForReportHelper };

// -------------- Watchers --------------

function* watchPeopleImportDialogUpstart() {
  yield takeEvery(peopleImportActionTypes.UPSTART, fetchReportFolders);
}

function* watchSelectOnlyMyReports() {
  yield takeEvery(
    action =>
      action.type === formActionTypes.UPDATE_FIELD_VALUE &&
      action.payload.fieldId === 'showOnlyMyReports',
    fetchReports
  );
}

function* watchSelectReportsFolder() {
  yield takeEvery(actionTypes.FETCH_REPORTS.BEGIN, fetchReports);
}

function* watchSelectReport() {
  yield takeEvery(
    actionTypes.FETCH_REPORT.BEGIN,
    fetchContactAndLeadIdsFromReport
  );
}

function* watchFetchPeople() {
  yield takeEvery(actionTypes.FETCH_PEOPLE.BEGIN, fetchPeopleForReport);
}

function* watchFetchReportFolders() {
  yield takeEvery(actionTypes.FETCH_REPORT_FOLDERS.BEGIN, fetchReportFolders);
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([
    fork(watchPeopleImportDialogUpstart),
    fork(watchSelectReportsFolder),
    fork(watchSelectReport),
    fork(watchFetchReportFolders),
    fork(watchSelectOnlyMyReports),
    fork(watchFetchPeople),
  ]);
}
