import { Stack } from '@fluentui/react';
import { ApiTemplateFolders } from '@groove/api/services/v1/templateFolders';
import { FC } from 'react';
import { QueryObserverResult } from 'react-query/types/core/types';
import { Template } from '@groove/api/gateway/v1/template';

import useStore from './store/useStore';
import TemplateViewer from './TemplateViewer';
import TemplatesMenu from './TemplatesMenu';
import NoSelectedTemplate from './NoSelectedTemplate';

type TemplatesNavigatorProps = {
  templateFolders: ApiTemplateFolders | undefined;
  onSubmit: (data: Template | undefined) => void;
  onReplace?: (data: Template | undefined) => void;
  refetchTemplateFolders: () => Promise<QueryObserverResult>;
};

const TemplatesNavigator: FC<TemplatesNavigatorProps> = ({
  templateFolders,
  onSubmit,
  onReplace,
  refetchTemplateFolders,
}) => {
  const selectedTemplate = useStore(store => store.selectedTemplate);

  return (
    <Stack horizontal className="flex h-full">
      <Stack.Item>
        <TemplatesMenu templateFolders={templateFolders} />
      </Stack.Item>
      <Stack.Item>
        <div className="h-full border-r-[1px] border-0 border-solid border-neutral/200" />
      </Stack.Item>
      <Stack.Item className="flex w-full">
        {selectedTemplate ? (
          <TemplateViewer
            key={selectedTemplate.id}
            onSubmit={onSubmit}
            onReplace={onReplace}
            refetchTemplateFolders={refetchTemplateFolders}
          />
        ) : (
          <NoSelectedTemplate />
        )}
      </Stack.Item>
    </Stack>
  );
};

export default TemplatesNavigator;
