import GrooveSearchResult from '@groove/api/visualforce/types/GrooveSearch/GrooveSearchResult';
import { StandardDropDownItem } from '@groove/ui/Components/DropDownItem';
import {
  ContactIcon,
  LeadIcon,
  BuildingIcon,
  LightbulbIcon,
  MoneyIcon,
  WarningIcon,
} from '@groove/ui/Components/BoogieIcon';

export const getSfdcTypeIcon = (type: string): JSX.Element => {
  switch (type) {
    case 'Contact':
      return <ContactIcon className="text-neutral/900 w-[20px]" />;
    case 'Lead':
      return <LeadIcon className="text-neutral/900 w-[20px]" />;
    case 'Account':
      return <BuildingIcon className="text-neutral/900 w-[20px]" />;
    case 'Opportunity':
      return <MoneyIcon className="text-neutral/900 w-[20px]" />;
    case 'Unknown':
      return <WarningIcon className="text-neutral/900 w-[20px]" />;
    default:
      return <LightbulbIcon className="text-neutral/900 w-[20px]" />; // new icon
  }
};

const transformGrooveSearch = (
  rawData: GrooveSearchResult,
): StandardDropDownItem => {
  const result: StandardDropDownItem = {
    key: rawData.Id || '',
    text: '',
    tooltip: rawData.attributes.type || undefined,
    value: rawData.Id || '',
    icon: getSfdcTypeIcon(rawData.attributes.type || ''),
  };
  const joinTextArray = [];
  result.secondaryText = [] as string[];
  result.metaData = { type: rawData.attributes.type || '' };
  switch (rawData.attributes.type) {
    case 'Contact':
    case 'Lead':
      result.text = rawData.Name || '<No Name>';

      if (rawData.Company) joinTextArray.push(rawData.Company);
      if (rawData.Email) {
        joinTextArray.push(rawData.Email);
        result.metaData.email = rawData.Email;
      }
      if (joinTextArray.length > 0)
        result.secondaryText.push(joinTextArray.join(' - '));

      if (rawData.Title) result.secondaryText.push(rawData.Title);

      return result;
    case 'Account':
      result.text = rawData.Name || '<No Name>';
      if (rawData.Industry) result.secondaryText.push(rawData.Industry);

      return result;
    case 'Opportunity':
      joinTextArray.push(rawData.Name || '<No Name>');
      if (rawData.Account?.Name) joinTextArray.push(rawData.Account?.Name);
      result.text = joinTextArray.join(' - ');

      if (rawData.Type) result.secondaryText.push(rawData.Type);
      if (rawData.StageName) result.secondaryText.push(rawData.StageName);

      return result;
    default:
      result.text = rawData.Name || '<No Name>';
      result.secondaryText.push(`Type: ${rawData.attributes.type}`);
      return result;
  }
};

export default transformGrooveSearch;
