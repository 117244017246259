import { INTERNAL_SERVER_ERROR } from 'http-status-codes';
import { Record, Map, isImmutable } from 'immutable';

const defaults = {
  message: new Map(),
  status: null,
  statusText: '',
  headers: new Map(),
  request: new Map(),
};

class HTTPResponse extends Record(defaults) {
  // To maintain backwards compatibility with responses that don't have a data attribute
  get data() {
    const data = this.message.get('data');
    if (isImmutable(data)) {
      return data.toJS();
    }
    return this.message.toJS();
  }

  get meta() {
    const meta = this.message.get('meta');
    if (isImmutable(meta)) {
      return meta.toJS();
    }
    return null;
  }

  isServerError() {
    return this.status >= INTERNAL_SERVER_ERROR && this.status < 600;
  }
}

export default HTTPResponse;
