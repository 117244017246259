import { Dialog, actions, selectors } from '@groove-labs/groove-ui';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  confirmRemovePersonFromAllFlows,
  confirmRemovePersonFromFlow,
} from 'Modules/App/actions';
import { currentUser } from 'Modules/Shared/selectors/users';
import {
  PERSON_TO_REMOVE_UI_KEY_PATH,
  FLOW_TO_REMOVE_UI_KEY_PATH,
  REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH,
} from 'Modules/App/constants';
import { Person } from 'Modules/People/records';
import Flow from 'Modules/Shared/data/Flow';
import { getRemoveInProgress } from 'Modules/App/selectors';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const { getProperty } = selectors.ui;
const { batchSetProperty } = actions.ui;

const styles = theme => ({
  root: {
    background: 'blue',
  },
  warningText: {
    marginBottom: 20,
    fontSize: '14px',
    lineHeight: '20px',
  },
  flowListText: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: theme.spacing.unit * 10,
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    width: '300px',
  },
  buttonSize: {
    display: 'flex',
    width: theme.spacing.unit * 12,
    height: theme.spacing.unit * 4.5,
    alignItems: 'center',
  },
  indicator: {
    display: 'flex',
    paddingBottom: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 0.5,
  },
});

@withStyles(styles)
@connect(
  state => {
    const person = getProperty(state, PERSON_TO_REMOVE_UI_KEY_PATH, null);
    const flow = getProperty(state, FLOW_TO_REMOVE_UI_KEY_PATH, null);
    const user = currentUser(state) || Map({ id: 0 });
    return {
      personToRemove: person === null ? undefined : person, // Need to set to `undefined` to the defaultProp is used
      flowToRemove: flow === null ? undefined : flow, // Need to set to `undefined` to the defaultProp is used
      removeInProgress: getRemoveInProgress(state),
      userId: user.get('id'),
    };
  },
  {
    cancel: () =>
      batchSetProperty([
        {
          uiKeyPath: REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH,
          data: false,
        },
        {
          uiKeyPath: PERSON_TO_REMOVE_UI_KEY_PATH,
          data: null,
        },
        {
          uiKeyPath: FLOW_TO_REMOVE_UI_KEY_PATH,
          data: null,
        },
      ]),
    confirmRemovePersonFromAllFlows,
    confirmRemovePersonFromFlow,
  }
)
export default class SearchRemoveFromFlowDialog extends Component {
  static propTypes = {
    personToRemove: PropTypes.instanceOf(Person),
    classes: PropTypes.instanceOf(Object).isRequired,
    cancel: PropTypes.func.isRequired,
    removeInProgress: PropTypes.bool.isRequired,
    confirmRemovePersonFromAllFlows: PropTypes.func.isRequired,
    confirmRemovePersonFromFlow: PropTypes.func.isRequired,
    flowToRemove: PropTypes.instanceOf(Flow),
    userId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    personToRemove: new Person({ flows: [], personRollups: [] }),
    flowToRemove: new Flow(),
  };

  handleCancel = () => {
    const { cancel } = this.props;
    cancel();
  };

  handleSubmit = () => {
    const {
      personToRemove,
      confirmRemovePersonFromAllFlows,
      confirmRemovePersonFromFlow,
      flowToRemove,
    } = this.props;

    if (flowToRemove.id) {
      confirmRemovePersonFromFlow({
        personId: personToRemove.id,
        flowId: flowToRemove.id,
      });
    } else {
      confirmRemovePersonFromAllFlows(personToRemove);
    }
  };

  generateDialogText = () => {
    const { personToRemove, flowToRemove } = this.props;
    const removeDescription = flowToRemove.id
      ? 'this flow'
      : 'all of your flows that they are in';
    return `${personToRemove.get(
      'name'
    )} will be removed from ${removeDescription}. This cannot be undone. Are you sure?`;
  };

  render() {
    const { personToRemove, classes, removeInProgress, flowToRemove, userId } =
      this.props;
    const flows = flowToRemove.id
      ? List.of(flowToRemove)
      : personToRemove.flows;
    const displayFlows = flows.filter(flow => flow.get('userId') === userId);

    return (
      <Dialog small isOpenUiKeyPath={REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH}>
        <DialogTitle>
          Remove from {flowToRemove.id ? '1 flow' : 'all my flows'}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" classes={{ root: classes.warningText }}>
            {this.generateDialogText()}
          </Typography>
          <Typography variant="body2" classes={{ root: classes.flowListText }}>
            {displayFlows.valueSeq().map(flow => {
              return <div key={flow.get('id')}>{flow.get('name')}</div>;
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <Button
            className={classes.buttonSize}
            variant="contained"
            color="primary"
            disabled={removeInProgress}
            onClick={this.handleSubmit}
          >
            {removeInProgress ? (
              <CircularProgress classes={classes.indicator} size={15} />
            ) : (
              "I'm sure"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
