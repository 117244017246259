export const PRIMITIVE_TYPES = {
  BASE64: 'base64',
  BOOLEAN: 'boolean',
  BYTE: 'byte',
  DATE: 'date',
  DATE_TIME: 'datetime',
  DOUBLE: 'double',
  INT: 'int',
  PERCENT: 'percent',
  STRING: 'string',
  TIME: 'time',
};

export const FIELD_TYPES = {
  ...PRIMITIVE_TYPES,
  ADDRESS: 'address',
  ANY_TYPE: 'anyType',
  CALCULATED: 'calculated',
  COMBOBOX: 'combobox',
  CURRENCY: 'currency',
  DATA_CATEGORTY_GROUP_REFERENCE: 'DataCategoryGroupReference',
  EMAIL: 'email',
  ENCRYPTED_STRING: 'encryptedstring',
  ID: 'ID',
  JUNCTION_ID_LIST: 'JunctionIdList',
  LOCATION: 'location',
  MASTER_RECORD: 'masterrecord',
  MULTI_PICKLIST: 'multipicklist',
  PERCENT: 'percent',
  PHONE: 'phone',
  PICKLIST: 'picklist',
  REFERENCE: 'reference',
  TEXT: 'text',
  TEXTAREA: 'textarea',
  URL: 'url',
};

export const salesforceRequiredTagMessage =
  'At least one tag required is required for this flow.';
