import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const DownArrow: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 10 12"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <path d="M5.5 0.5C5.5 0.223858 5.27615 0 5 0C4.72386 0 4.5 0.223858 4.5 0.5L4.5 10.197L0.871651 6.16552C0.686922 5.96026 0.370777 5.94362 0.165522 6.12835C-0.0397331 6.31308 -0.0563723 6.62923 0.128357 6.83448L4.62836 11.8345C4.72318 11.9398 4.85826 12 5 12C5.14175 12 5.27683 11.9398 5.37165 11.8345L9.87165 6.83448C10.0564 6.62923 10.0397 6.31308 9.83449 6.12835C9.62923 5.94362 9.31309 5.96026 9.12836 6.16552L5.5 10.197L5.5 0.5Z" />
  </svg>
);
