import { FC, useRef } from 'react';
import Text from '@groove/ui/Components/Text';
import WysiwygLite from '@groove/ui/Components/WysiwygLite';

import PhoneRecipient from '../component/PhoneRecipient';
import useStore, { PaneValues } from '../store/useStore';
import openMergeModal from '../component/MergeModal';

const SmsPane: FC = () => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const body = useStore(store => store.action.body);

  const paneValues = (): PaneValues => ({
    getCurrentInputRef: () => textAreaRef,
    selectionStart: textAreaRef.current?.selectionStart || 0,
    selectionEnd: textAreaRef.current?.selectionEnd || 0,
  });

  const onChange = (body: string): void =>
    useStore.getState().updateAction({ body });

  return (
    <div className="flex-1 pr-[12px] py-[12px] pl-[20px] flex flex-col min-w-0">
      <PhoneRecipient />
      <Text variant="body-sm" className="mb-[4px] mt-[16px] font-semibold">
        Body
      </Text>
      <WysiwygLite
        onTemplateClick={() => useStore.getState().toggleTemplate(paneValues())}
        onSchedulerClick={() =>
          useStore.getState().toggleScheduler(paneValues())
        }
        onMergeFieldClick={() => {
          useStore.getState().setOtherValues(paneValues());
          openMergeModal();
        }}
        value={body || ''}
        onChange={onChange}
        ref={textAreaRef}
        onMouseUp={() => useStore.getState().setOtherValues(paneValues())}
      />
    </div>
  );
};

export default SmsPane;
