import getClient from './getClient';

export type TemplateType = 'email' | 'call' | 'other';
export type TemplateTypeValue = 'Email' | 'Call' | 'Other';
export const templateTypeHash: { [key in TemplateType]: TemplateTypeValue } = {
  email: 'Email',
  call: 'Call',
  other: 'Other',
};

export type Template = {
  id: number;
  folder_id: number;
  name: string;
  subject: string;
  body?: string;
  sent_count: number;
  link_click_count: number;
  open_count: number;
  reply_count: number;
  updated_at: string;
  template_folder_name?: string;
  html_body?: string;
  template_type_value?: string;
};

export type ApiTemplate = {
  data: Template;
};

export type ApiTemplateSearch = {
  data: Template[];
};

export type ApiTemplateMerge = {
  data: {
    preppedEmailBody?: string;
    preppedEmailSubject?: string;
  };
};

export type ApiRawMerge = {
  data: { body: string; subject: string };
};

export type ApiTemplateDelete = {
  data: {
    delete_message: string;
  };
};

export type ApiTemplateUsage = {
  data: {
    id: number;
    name: string;
  }[];
};

export type TemplateParams = {
  template_id?: number; // not required for create endpoint
  html_body: string;
  name: string;
  subject: string;
  template_folder_id: number;
  template_type: TemplateType;
};

export type TemplateMergeParams = {
  template_id: number;
  recipient_email: string;
  who_id: string;
  what_id: string;
};

export type RawMergeParams = {
  body?: string;
  subject?: string;
  user_id?: string | number;
  what_id: string;
  who_id: string;
};

export const templateGet = (templateId: number): Promise<ApiTemplate> =>
  getClient()
    .get('templates', { searchParams: { template_id: templateId } })
    .json();

export const templateCreate = (
  templateParams: TemplateParams,
): Promise<ApiTemplate> =>
  getClient()
    .post('templates', {
      json: {
        ...templateParams,
        template_type_value: templateTypeHash[templateParams.template_type],
      },
    })
    .json();

export const templateUpdate = (
  templateParams: TemplateParams,
): Promise<ApiTemplate> =>
  getClient()
    .put('templates', {
      json: {
        ...templateParams,
      },
    })
    .json();

export const templateDelete = (
  templateId: number,
): Promise<ApiTemplateDelete> =>
  getClient()
    .delete('templates', { json: { template_id: templateId } })
    .json();

export const templateSearch = (
  searchTerm: string,
  templateType: TemplateType,
): Promise<ApiTemplateSearch> =>
  getClient()
    .post('templates/search', {
      json: {
        search_term: searchTerm,
        template_type: templateType,
      },
    })
    .json();

export const templateStar = (templateId: number): Promise<void> =>
  getClient()
    .post('templates/star', { json: { template_id: templateId } })
    .json();

export const templateUnstar = (templateId: number): Promise<void> =>
  getClient()
    .post('templates/unstar', { json: { template_id: templateId } })
    .json();

export const templateMerge = (
  params: TemplateMergeParams,
): Promise<ApiTemplateMerge> => {
  return getClient()
    .post('templates/merge', { json: { ...params } })
    .json();
};

export const rawMerge = ({
  body = '',
  subject = '',
  user_id,
  what_id,
  who_id,
}: RawMergeParams): Promise<ApiRawMerge> =>
  getClient()
    .post('templates/raw_merge', {
      json: {
        body,
        subject,
        user_id,
        what_id,
        who_id,
      },
    })
    .json();

export const templateUsage = (templateId: number): Promise<ApiTemplateUsage> =>
  getClient()
    .post('templates/usage', { json: { template_id: templateId } })
    .json();
