import { List, Map, fromJS } from 'immutable';
import { actionTypes as appActionTypes } from 'Modules/App/actions';
import { DEFAULT_SEARCH_FILTER_OPTION } from 'Modules/App/constants';
import { actionTypes as errorActionTypes } from 'Modules/Shared/actions/errors';
import { actionTypes as sharedActionTypes } from 'Modules/Shared/actions/app';
import { actionTypes as locationActiontypes } from 'Modules/Shared/actions/location';
import { actionTypes as snackbarWarningsActionTypes } from 'Modules/App/actions/snackbarWarnings';
import isUndefined from 'lodash-es/isUndefined';
import ErrorDetails from 'Modules/Shared/records/ErrorDetails';

const initialLocationState = fromJS({
  routeName: null,
  previousRouteNames: [],
  previousPaths: [],
  resolved: false,
  activeTab: null,
  fullPath: null,
  rootPath: null,
  routeParams: {},
  searchParams: {},
});

const initialState = fromJS({
  appLoading: true,
  appUpstartSucceeded: false,
  routerReady: false,
  contentLoading: false,
  searchFilter: DEFAULT_SEARCH_FILTER_OPTION,
  searchQuery: '',
  searchResults: [],
  searchInProgress: false,
  removeInProgress: false,
  snackbarOpen: false,
  showTopNavTabs: true,
  snackbarQueue: new List(),
  location: initialLocationState,
  routeScrolls: {},
  retryCount: 0,
  releaseVersion: {
    version: process.env.REACT_APP_VERSION,
    shouldReload: false,
  },
  error: new ErrorDetails(),
});

export default function app(state = initialState, action = {}) {
  switch (action.type) {
    case locationActiontypes.UPDATE: {
      return state.set(
        'location',
        initialLocationState.merge(fromJS(action.payload))
      );
    }

    case locationActiontypes.START_CHANGE: {
      return state.setIn(['location', 'resolved'], false);
    }

    case locationActiontypes.SET_PAGE_SCROLL: {
      return state.setIn(
        ['routeScrolls', action.payload.routeName],
        action.payload.scroll
      );
    }

    case locationActiontypes.FINISH_CHANGE: {
      return state.setIn(['location', 'resolved'], true);
    }

    case locationActiontypes.MERGE_SEARCH_PARAMS: {
      const { searchParams = {} } = action.payload;

      return state.mergeIn(['location', 'searchParams'], fromJS(searchParams));
    }

    case locationActiontypes.SET_SEARCH_PARAMS: {
      const { searchParams } = action.payload;

      return state.setIn(['location', 'searchParams'], searchParams);
    }

    case locationActiontypes.SET_ACTIVE_TAB: {
      const { activeTab } = action.payload;

      return state.setIn(['location', 'activeTab'], activeTab);
    }

    case appActionTypes.READY: {
      return state.set('appLoading', false);
    }

    case appActionTypes.UPSTART.SUCCESS: {
      return state.set('appUpstartSucceeded', true);
    }

    case sharedActionTypes.ROUTER_READY: {
      return state.set('routerReady', true);
    }

    case sharedActionTypes.SET_CONTENT_LOADING: {
      return state.merge({ contentLoading: action.payload });
    }

    case sharedActionTypes.SET_SNACKBAR_OPEN: {
      return state.merge({ snackbarOpen: true });
    }

    case sharedActionTypes.SET_SNACKBAR_CLOSED: {
      return state.merge({ snackbarOpen: false });
    }

    case sharedActionTypes.UPDATE_SNACKBAR_MESSAGE: {
      return state.merge({ snackbarQueue: fromJS([action.payload]) });
    }

    case sharedActionTypes.SHIFT_SNACKBAR_MESSAGE: {
      const changedSnackbarQueue = state.get('snackbarQueue').shift();
      return state.merge({ snackbarQueue: changedSnackbarQueue });
    }

    case appActionTypes.SEARCH_QUERY_UPDATED: {
      return state.merge({ searchQuery: action.payload });
    }

    case appActionTypes.SEARCH_FILTER_UPDATED: {
      return state.merge({
        searchFilter: action.payload,
        searchQuery: '',
        searchResults: new List(),
      });
    }

    case appActionTypes.TYPEAHEAD_SEARCH.SUCCESS: {
      return state.merge({
        searchResults: fromJS(action.payload),
        searchInProgress: false,
      });
    }

    case appActionTypes.TYPEAHEAD_SEARCH.FAILURE: {
      return state.merge({ searchInProgress: false });
    }

    case appActionTypes.TYPEAHEAD_SEARCH.PROGRESS: {
      return state.merge({ searchInProgress: true });
    }

    case appActionTypes.REMOVE_PERSON_FROM_FLOW.PROGRESS:
    case appActionTypes.REMOVE_PERSON_FROM_ALL_FLOWS.PROGRESS: {
      return state.merge({ removeInProgress: true });
    }

    case appActionTypes.REMOVE_PERSON_FROM_FLOW.SUCCESS:
    case appActionTypes.REMOVE_PERSON_FROM_ALL_FLOWS.SUCCESS: {
      return state.merge({ removeInProgress: false });
    }

    case appActionTypes.REMOVE_PERSON_FROM_FLOW.FAILURE:
    case appActionTypes.REMOVE_PERSON_FROM_ALL_FLOWS.FAILURE: {
      return state.merge({ removeInProgress: false });
    }

    case appActionTypes.TYPEAHEAD_SEARCH_CLEAR_SEARCH_RESULTS: {
      return state.merge({ searchResults: new List() });
    }

    case appActionTypes.UPDATE_LOCAL_SEARCH_RESULTS: {
      const { updatedSearchResults } = action.payload;
      return state.merge({ searchResults: fromJS(updatedSearchResults) });
    }

    case appActionTypes.SET_TOP_NAV_TAB_VISIBILITY: {
      return state.set('showTopNavTabs', action.payload);
    }

    case appActionTypes.UPDATE_RELEASE_VERSION: {
      const { version } = action.payload;
      return state.set(
        'releaseVersion',
        fromJS({ version, shouldReload: true })
      );
    }

    case appActionTypes.FORCE_RELOAD_ON_MODULE_CHANGE: {
      return state.mergeIn(['releaseVersion'], fromJS({ shouldReload: true }));
    }

    case errorActionTypes.LOG_ERROR: {
      return state.set('error', new ErrorDetails(action.payload));
    }

    case appActionTypes.SET_RETRY_COUNT: {
      if (!isUndefined(action.payload.retryCount)) {
        return state.set('retryCount', action.payload.retryCount);
      }

      return state;
    }

    case appActionTypes.LOGIN_AS_SEARCH_USERS.BEGIN: {
      return state
        .setIn(['loginAs', 'searchUsersInProgress'], true)
        .setIn(['loginAs', 'errorMessage'], null)
        .setIn(['loginAs', 'total'], 0)
        .setIn(['loginAs', 'page'], 0)
        .setIn(['loginAs', 'users'], null);
    }

    case appActionTypes.LOGIN_AS_SEARCH_USERS.SUCCESS: {
      const { page, searchQuery, total, users } = action.payload;

      return state
        .setIn(['loginAs', 'searchUsersInProgress'], false)
        .setIn(['loginAs', 'errorMessage'], null)
        .setIn(['loginAs', 'page'], page)
        .setIn(['loginAs', 'searchQuery'], searchQuery)
        .setIn(['loginAs', 'total'], total)
        .setIn(['loginAs', 'users'], fromJS(users));
    }

    case appActionTypes.LOGIN_AS_SEARCH_USERS.FAILURE: {
      const { errorMessage } = action.payload;

      return state
        .setIn(['loginAs', 'searchUsersInProgress'], false)
        .setIn(['loginAs', 'page'], 1)
        .setIn(['loginAs', 'errorMessage'], errorMessage);
    }

    case appActionTypes.LOGIN_AS_USER.SUCCESS: {
      return state.setIn(['loginAs'], Map());
    }

    case appActionTypes.LOGIN_AS_USER.FAILURE: {
      const { errorMessage } = action.payload;

      return state
        .setIn(['loginAs', 'loginInProgress'], false)
        .setIn(['loginAs', 'errorMessage'], errorMessage);
    }

    case snackbarWarningsActionTypes.SET:
      return state.set('snackbarWarnings', action.payload);

    default:
      return state;
  }
}
