import { TYPES, SOURCES } from 'Modules/Actions/constants';
import {
  POST_MESSAGE_FEBES_SOURCE,
  POST_MESSAGE_GMAIL_SOURCE,
  POST_MESSAGE_OUTLOOK_SOURCE,
  POST_MESSAGE_SALEFORCE_SOURCE,
  POST_MESSAGE_ZOOMINFO_SOURCE,
} from 'Modules/ActionCompose/constants';
import {
  GOOGLE_ORIGIN_URL,
  SALESFORCE_BASE_URL,
  ZOOMINFO_ORIGIN_URL,
} from 'Modules/Shared/constants/urls';
import EmailAction from 'Modules/ActionCompose/records/Email';
import CallAction from 'Modules/ActionCompose/records/Call';
import OtherAction from 'Modules/ActionCompose/records/Other';
import LinkedInMailAction from 'Modules/ActionCompose/records/LinkedInMail';
import LinkedInConnectAction from 'Modules/ActionCompose/records/LinkedInConnect';
import DirectMailAction from 'Modules/ActionCompose/records/DirectMail';
import InPersonVisitAction from 'Modules/ActionCompose/records/InPersonVisit';
import SMS from 'Modules/ActionCompose/records/SMS';
import WorkspaceTaskAction from 'Modules/ActionCompose/records/WorkspaceTask';
import Sendoso from 'Modules/ActionCompose/records/Sendoso';
import { List } from 'immutable';

export function createRecordByType({ data }) {
  const source = data.get('source');
  if (source === SOURCES.WORKSPACE) {
    return WorkspaceTaskAction.fromGraphQL(data);
  }

  const type = data.get('type');
  switch (type) {
    case TYPES.EMAIL:
      return EmailAction.fromGraphQL(data);
    case TYPES.CALL:
      return CallAction.fromGraphQL(data);
    case TYPES.OTHER:
      return OtherAction.fromGraphQL(data);
    case TYPES.IN_PERSON_VISIT:
      return InPersonVisitAction.fromGraphQL(data);
    case TYPES.DIRECT_MAIL:
      return DirectMailAction.fromGraphQL(data);
    case TYPES.IN_MAIL:
      return LinkedInMailAction.fromGraphQL(data);
    case TYPES.LINKEDIN_CONNECT:
      return LinkedInConnectAction.fromGraphQL(data);
    case TYPES.SMS:
      return SMS.fromGraphQL(data);
    case TYPES.SENDOSO:
      return Sendoso.fromGraphQL(data);
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
}

export function determineTarget(targetOrigin) {
  if (!targetOrigin) {
    return POST_MESSAGE_FEBES_SOURCE;
  }
  if (targetOrigin.includes(GOOGLE_ORIGIN_URL)) {
    return POST_MESSAGE_GMAIL_SOURCE;
  } else if (targetOrigin === process.env.PUBLIC_URL) {
    return POST_MESSAGE_FEBES_SOURCE;
  } else if (targetOrigin.includes(SALESFORCE_BASE_URL)) {
    return POST_MESSAGE_SALEFORCE_SOURCE;
  } else if (targetOrigin.includes(process.env.OUTLOOK_ADD_IN_URL)) {
    return POST_MESSAGE_OUTLOOK_SOURCE;
  } else if (targetOrigin.includes(ZOOMINFO_ORIGIN_URL)) {
    return POST_MESSAGE_ZOOMINFO_SOURCE;
  }
  return POST_MESSAGE_FEBES_SOURCE;
}

export function getTemplateName(action, executionContext) {
  const templateName =
    executionContext?.get('templateName') || action?.get('templateName', '');
  return templateName || '';
}

export function buildCCString(recipients = []) {
  return recipients.map(recipient => recipient.email)?.toString();
}

export function processRecipients(recipients = new List([])) {
  return recipients.map(recipient => ({
    id: recipient.get('id'),
    name: recipient.get('name'),
    email: recipient.get('email'),
  }));
}
