import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { TooltipHost } from '@fluentui/react';

import Text from './Text';

type TruncatedTextWithTooltipProps = {
  text: string;
  textClasses?: string;
  lineClamp?: number;
};

const TruncatedTextWithTooltip: FC<TruncatedTextWithTooltipProps> = ({
  text,
  textClasses = '',
  lineClamp = 1,
}) => {
  const textContainer = useRef<HTMLDivElement>(null);
  const [isTextClamped, setIsTextClamped] = useState(false);

  useEffect(() => {
    const el = textContainer.current?.firstElementChild;
    setIsTextClamped(el ? el.scrollHeight > el.clientHeight : false);
  }, [textContainer]);

  const TextElement = (): ReactElement => {
    return (
      <div ref={textContainer} style={{ wordBreak: 'break-word' }}>
        <Text className={`${textClasses} line-clamp-1`}>{text}</Text>
      </div>
    );
  };

  if (isTextClamped) {
    return (
      <TooltipHost content={<Text>{text}</Text>}>
        <TextElement />
      </TooltipHost>
    );
  }

  return <TextElement />;
};

export default TruncatedTextWithTooltip;
