import { FC, ReactElement } from 'react';
import Button, { AdditionMenuItems } from '@groove/ui/Components/Button';
import CalendarTimePicker from '@groove/ui/Components/CalendarTimePicker';
import { useMutation } from 'react-query';
import {
  ApiActionExecution,
  ExecuteAction,
  ExecuteActionOptions,
  NEW_ACTION_ID,
  executeAction,
} from '@groove/api/gateway/v1/actionCompose';
import {
  cleanAction,
  useCreateAction,
  useUpdateAction,
} from '@groove/api/hooks/useMutateAction';
import {
  addPeopleToFlow,
  flowsPauseUntil,
  removeFromFlow,
  skipPersonStep,
} from '@groove/api/gateway/v1/flows';
import {
  useGetCurrentUser,
  useGetOrgSettings,
} from '@groove/api/hooks/useUsers';
import useDialerStore from '@groove/dialer-components/hooks/useStore';
import { SearchUser } from '@groove/api/gateway/v1/users';
import { HTTPError } from 'ky';
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';
import { useBestLocalNumber } from '@groove/api/hooks/useCommonDialer';
import { LOCAL_DIAL_PARSE_ERROR } from '@groove/api/gateway/v1/commonDialer';
import pluralize from 'pluralize';

import useStore from '../store/useStore';
import {
  englishJoiner,
  followUpActionUtil,
  getErrorMessage,
  validationHash,
} from '../utils';
import { CompleteType } from '../types';
import {
  RECIPIENT_NOT_FOUND_MODAL_TEXT,
  errorMessages,
  ErrorType,
  DIALER_SETTINGS_URL,
  DISABLED_CALL_TOOLTIP,
  MARK_AS_COMPLETE_TOOLTIP_TEXT,
  CALL_ERROR_MESSAGE_ID,
} from '../constants';
import { generateCompleteTooltip } from '../utils/generateTooltip';
import {
  ACTION_EVENT,
  ACTION_VERSION,
  trackActionEvents,
} from '../analytics/actionComposeEvents';
import { messagesHashed } from '../store/messageBarStore';

import ExecuteAddToFlowDialog from './ExecuteAddToFlowDialog';

export type PrimaryExecuteButtonProps = {
  onComplete: (id?: string, type?: CompleteType) => void;
};

const PrimaryExecuteButton: FC<PrimaryExecuteButtonProps> = ({
  onComplete,
}) => {
  const id = useStore(store => store.action.id);
  const type = useStore(store => store.action.type);
  const personStepId = useStore(store => store.action.personStepId);
  const flow = useStore(store => store.action.flow);
  const whoId = useStore(store => store.action.who?.id);
  const recipientPhone =
    useStore(store => store.action.toRecipients?.[0]?.phone) || '';
  const recipientName =
    useStore(store => store.action.toRecipients?.[0]?.name) || '';
  const name = useStore(store => store.action.who?.name);
  const assigneeId = useStore(store => store.action.assignee?.id);
  // this is to trigger re render when executionErrors changes
  useStore(store => messagesHashed(store.executionErrors));
  const { executionErrors } = useStore.getState();
  const hasCalled = useStore(store => store.action.dynamicData.hasCalled);
  const callState = useDialerStore(store => store.callState);

  const dialerInActionEnabled = useVariation('dialer-in-action');

  const { data: userData } = useGetCurrentUser();
  const { data: orgSettings } = useGetOrgSettings();

  const { data: localPhoneData } = useBestLocalNumber(
    { toPhoneNumber: recipientPhone },
    false,
  );

  const grooveNumber = userData?.phone_number || '';
  const isDialerInActionEnabled =
    dialerInActionEnabled &&
    userData?.dialer_enabled &&
    !orgSettings?.third_party_dialer_enabled;

  const markAsCompleteTooltip =
    generateCompleteTooltip() || MARK_AS_COMPLETE_TOOLTIP_TEXT;

  const { mutateAsync: createActionAsync } = useCreateAction();
  const { mutate: updateAction } = useUpdateAction();
  const { mutate: onExecuteAction } = useMutation(executeAction, {
    onError: error => {
      console.error(errorMessages.tryAgain('execute action'), error);
      if (error instanceof HTTPError) {
        const { status } = error.response;
        trackActionEvents({
          eventName: ACTION_EVENT.LOGGED,
          action: useStore.getState().action,
          actionVersion: ACTION_VERSION.V2,
          errorCode: status.toString(),
        });
        const message = getErrorMessage(ErrorType.ExecuteActionError, status);
        useStore
          .getState()
          .setRequestErrorMessage(ErrorType.ExecuteActionError, message);
      } else {
        useStore
          .getState()
          .setRequestErrorMessage(
            ErrorType.ExecuteActionError,
            `Unable to execute action ${error}.`,
          );
      }
    },
    mutationKey: ['executeAction'],
  });
  const { mutate: onAddPeopleToFlow } = useMutation(addPeopleToFlow, {
    onError: error => {
      console.error('Unable to add the person to the flow.', error);
      useStore
        .getState()
        .setRequestErrorMessage(
          ErrorType.ExecuteActionError,
          'Unable to add the person to the flow.',
        );
    },
    mutationKey: ['addPeopleToFlow'],
  });
  const { mutate: onPauseUntil } = useMutation(flowsPauseUntil, {
    onSuccess: () => onComplete(id.toString(), 'pauseFromFlow'),
    mutationKey: ['flowsPauseUntil'],
  });
  const { mutate: removePeople } = useMutation(removeFromFlow, {
    onSuccess: () => onComplete(id.toString(), 'removeFromFlow'),
    mutationKey: ['removeFromFlow'],
  });
  const { mutate: skipStep } = useMutation(skipPersonStep, {
    onSuccess: (_, params) =>
      onComplete(
        id.toString(),
        params.skip_type === 'ALL_CALL_STEPS' ? 'skipAllCallSteps' : 'skipStep',
      ),
    mutationKey: ['skipPersonStep'],
  });
  const { mutate: markAsComplete } = useMutation(executeAction, {
    onSuccess: () => {
      onComplete(id.toString(), 'markAsComplete');
    },
    onError: error => {
      console.error(errorMessages.tryAgain('mark action as complete'), error);
      useStore
        .getState()
        .setRequestErrorMessage(
          ErrorType.ExecuteActionError,
          errorMessages.tryAgain('mark action as complete'),
        );
    },
    mutationKey: ['executeAction'],
  });

  // Mark as complete clicks
  // TODO: Keeping this here until we finish Dialer in Actions
  const onMarkAsComplete = async (): Promise<void> => {
    const currentAction = useStore.getState().action;
    let cleanedAction = cleanAction(currentAction);
    let newId = id;

    try {
      if (id === NEW_ACTION_ID) {
        const rawAction = await createActionAsync(useStore.getState().action);
        cleanedAction = cleanAction(rawAction);
        newId = rawAction.id;
      }

      const newAction: ExecuteAction = {
        ...cleanedAction,
        assigneeId: currentAction.assignee?.id || 0,
        markAsComplete: true,
      };

      trackActionEvents({
        eventName: ACTION_EVENT.MARKED_AS_COMPLETE,
        action: useStore.getState().action,
        actionVersion: ACTION_VERSION.V2,
      });

      markAsComplete({ action: newAction, id: newId });
    } catch (e) {
      console.error('Was not able to mark action as complete');
    }
  };

  const sendAndExecute = async (
    options?: ExecuteActionOptions,
    overrideOnSuccess?: (data: ApiActionExecution) => void,
  ): Promise<void> => {
    const currentAction = useStore.getState().action;
    let cleanedAction = cleanAction(currentAction);
    let newId = id;

    try {
      if (id === NEW_ACTION_ID) {
        const rawAction = await createActionAsync(useStore.getState().action);
        cleanedAction = cleanAction(rawAction);
        newId = rawAction.id;
      }
      const newAction: ExecuteAction = {
        ...cleanedAction,
        assigneeId: currentAction.assignee?.id || 0,
        ...options,
        trackClicks: currentAction.dynamicData.trackClicks
          ? currentAction.dynamicData.trackClicks
          : undefined,
        trackOpens: currentAction.dynamicData.trackOpens
          ? currentAction.dynamicData.trackOpens
          : undefined,
      };
      onExecuteAction(
        { action: newAction, id: newId },
        {
          onSuccess: data => {
            if (!overrideOnSuccess) onComplete(id.toString(), 'execute');
            else overrideOnSuccess(data);
          },
        },
      );
    } catch (e) {
      console.error('Was not able to execute action');
    }
  };

  // All Actions
  const onSendAndExecute = async (
    options?: ExecuteActionOptions,
    overrideOnSuccess?: (data: ApiActionExecution) => void,
  ): Promise<void> => {
    trackActionEvents({
      eventName: ACTION_EVENT.LOGGED,
      action: useStore.getState().action,
      actionVersion: ACTION_VERSION.V2,
    });
    const currentAction = useStore.getState().action;

    const unknownRecipientFound = currentAction.toRecipients?.some(
      item => item.attributes?.type === 'Unknown',
    );
    if (unknownRecipientFound)
      useStore.getState().setNewModal({
        isOpen: true,
        onCancel: () => {
          useStore.getState().setModalValues({
            isOpen: false,
          });
          sendAndExecute(options, overrideOnSuccess);
        },
        contentText: RECIPIENT_NOT_FOUND_MODAL_TEXT,
        title: 'Recipient not found in Salesforce',
        cancelText: 'Send without logging',
        confirmText: 'Do not send',
      });
    else sendAndExecute(options, overrideOnSuccess);
  };

  const onExecuteAndFollowup = (): void => {
    onSendAndExecute({}, () =>
      useStore
        .getState()
        .upsertAction(followUpActionUtil(userData as SearchUser)),
    );
  };

  // Flow Actions

  const onPauseFlowExecute = (): void => {
    onSendAndExecute({}, () =>
      onPauseUntil(
        {
          flowId: flow.id || 0,
          pausedDate: useStore.getState().otherValues.pauseDate.toISOString(),
          peopleIds: [whoId || 0],
        },
        {
          onSuccess: () => onComplete(id.toString(), 'pauseFromFlow'),
        },
      ),
    );
  };

  const PauseFlows: FC = () => {
    const pauseDate = useStore(store => store.otherValues.pauseDate);
    return (
      <CalendarTimePicker
        date={pauseDate}
        onDateChange={pauseDate => {
          useStore.getState().setOtherValues({ pauseDate });
        }}
        className="py-[12px] min-w-[435px]"
        minDate={new Date()}
      />
    );
  };

  const openPauseFlowExecute = (): void => {
    useStore.getState().setNewModal({
      isOpen: true,
      confirmText: 'Pause',
      onConfirm: onPauseFlowExecute,
      content: <PauseFlows />,
      title: `Pause flow for ${name}`,
    });
  };

  const onExecuteAndTryAgain = (): void => {
    onSendAndExecute({ logAndTryAgain: true }, results => {
      useStore.getState().upsertAction({ ...results.data });
      useStore.getState().setHasCalled(false);
    });
  };

  const onCallAgain = (): void => {
    useStore.getState().setHasCalled(false);
    onSendAndExecute({ callAgain: true }, results => {
      useStore.getState().upsertAction({ ...results.data });
    });
  };

  const onExecuteAndRemove = (): void => {
    onSendAndExecute({}, () =>
      removePeople(
        {
          flowId: flow.id || 0,
          peopleIds: [whoId || 0],
        },
        {
          onSuccess: () => onComplete(id.toString(), 'removeFromFlow'),
        },
      ),
    );
  };

  const onExecuteAndSkipAll = (): void => {
    onSendAndExecute({}, () =>
      skipStep(
        {
          actionId: id,
          skip_type: 'ALL_CALL_STEPS',
        },
        {
          onSuccess: () => onComplete(id.toString(), 'skipAllCallSteps'),
        },
      ),
    );
  };

  const onSendAndAddToFlow = (): void => {
    const { selectedFlow } = useStore.getState().otherValues;
    if (!selectedFlow) return;

    const { value: flowId } = selectedFlow;
    onSendAndExecute({}, () =>
      onAddPeopleToFlow(
        { flowId, peopleIds: [whoId || 0] },
        { onSuccess: () => onComplete(id.toString(), 'addToFlow') },
      ),
    );
  };

  const openExecuteAndAddToFlow = (): void => {
    useStore.getState().setNewModal({
      isOpen: true,
      title: 'Add to flow',
      onConfirm: onSendAndAddToFlow,
      content: <ExecuteAddToFlowDialog />,
      confirmText: type === 'TEMPLATE' ? 'Send' : 'Log',
    });
  };

  const onCallClick = async (): Promise<void> => {
    const store = useStore.getState();
    const { action } = store;
    const { toRecipients, loggingTo } = store.action;
    store.updateAction({ fromPhoneNumber: grooveNumber });
    store.removeMessage(CALL_ERROR_MESSAGE_ID);
    store.setHasCalled(true);
    // Make sure we send the updated Action to the backend
    if (action.id && action.id !== NEW_ACTION_ID) updateAction(action);
    useDialerStore.getState().makeCall({
      from: grooveNumber,
      to: toRecipients?.[0]?.phone || '',
      who: {
        sfdcId: loggingTo.who?.[0]?.id || '',
        text: loggingTo.who?.[0]?.text || '',
      },
      what: {
        sfdcId: loggingTo.what?.[0]?.id || '',
        text: loggingTo.what?.[0]?.text || '',
      },
      errorCallback: message => {
        store.setHasCalled(false);
        if (action.id && action.id !== NEW_ACTION_ID) updateAction(action);
        store.addMessage({
          level: 'error',
          id: CALL_ERROR_MESSAGE_ID,
          message,
          noCloseButton: true,
        });
      },
      isOmnidialer: 'false',
    });
  };

  const onHangupClick = (): void => {
    updateAction({
      ...useStore.getState().action,
      twilioSid: useDialerStore.getState().getActiveConnectionSid() || '',
    });
    useDialerStore.getState().endCall(false);
  };

  const markAsCompleteItem: AdditionMenuItems = {
    text: 'Mark as complete',
    onClick: onMarkAsComplete,
    tooltip: markAsCompleteTooltip,
    showTooltipText: true,
  };
  let primaryTaskItems: AdditionMenuItems[] | null = null;

  if (!personStepId)
    primaryTaskItems = [
      markAsCompleteItem,
      {
        text: 'Log and add to flow',
        onClick: openExecuteAndAddToFlow,
      },
    ];

  const errorLocations = executionErrors
    .filter(error => error.readableLocation)
    .map(error => error.readableLocation);
  let primaryTooltip = 'Mark action as complete and log to Salesforce';
  if (errorLocations.length) {
    primaryTooltip = `Please complete required fields: ${englishJoiner(
      errorLocations,
    )}`;
  } else if (executionErrors.length) {
    primaryTooltip = `Please see the ${pluralize(
      'message',
      executionErrors.length,
    )} in the message bar.`;
  }

  // Task
  let primaryButton = (
    <Button
      actionName="Action Compose Log"
      variant="primary"
      onClick={onSendAndExecute}
      className="ml-[8px]"
      additionalMenuItems={primaryTaskItems || undefined}
      additionalMenuWidth={202}
      additionalMenuDirection="topRight"
      disabled={!!executionErrors.length}
      tooltip={primaryTooltip}
    >
      Log
    </Button>
  );

  // Email
  const ScheduleSend: FC = () => {
    const executeAt = useStore(store => store.action.executeAt);
    const parsedExecuteAt = executeAt ? new Date(executeAt) : new Date();

    const onExecuteAtChange = (date: Date): void => {
      useStore.getState().updateAction({ executeAt: date.toISOString() });
    };
    return (
      <CalendarTimePicker
        date={parsedExecuteAt}
        onDateChange={onExecuteAtChange}
        className="py-[12px] min-w-[435px]"
        minDate={new Date()}
      />
    );
  };

  const openScheduleSend = (): void => {
    useStore.getState().setNewModal({
      isOpen: true,
      confirmText: 'Schedule send',
      onConfirm: onSendAndExecute,
      content: <ScheduleSend />,
      title: 'Schedule send',
    });
  };

  const primaryEmailItems: AdditionMenuItems[] = [
    {
      text: 'Schedule send',
      onClick: openScheduleSend,
    },
    {
      text: 'Send and create follow-up',
      onClick: onExecuteAndFollowup,
    },
  ];

  if (!personStepId) {
    primaryEmailItems.unshift(markAsCompleteItem);
    primaryEmailItems.push({
      text: 'Send and add to flow',
      onClick: openExecuteAndAddToFlow,
    });
  }
  let assigneeMatch = true;
  if (type === 'TEMPLATE' || type === 'STEP_SUBTYPE_SMS') {
    let tooltipComplete = generateCompleteTooltip();

    if (!tooltipComplete && assigneeId !== userData?.id) {
      tooltipComplete = `Assignee does not match current user `;
      assigneeMatch = false;
    }

    primaryButton = (
      <Button
        actionName="Action Compose Send"
        variant="primary"
        onClick={onSendAndExecute}
        className="ml-[8px]"
        additionalMenuItems={primaryEmailItems}
        additionalMenuWidth={202}
        additionalMenuDirection="topRight"
        disabled={!!executionErrors.length || !assigneeMatch}
        tooltip={tooltipComplete}
      >
        Send
      </Button>
    );
  }

  // Call
  let callItems: AdditionMenuItems[] = [];

  const nonFlowItems: AdditionMenuItems[] = [
    markAsCompleteItem,
    {
      text: 'Log and create follow-up',
      onClick: onExecuteAndFollowup,
    },
    {
      text: 'Log and try again',
      onClick: onExecuteAndTryAgain,
    },
    {
      text: 'Log and add to flow',
      onClick: openExecuteAndAddToFlow,
    },
  ];

  const flow3rdPartyItems: AdditionMenuItems[] = [
    {
      text: 'Complete and create follow-up',
      onClick: onExecuteAndFollowup,
    },
    {
      text: 'Complete and pause until...',
      onClick: openPauseFlowExecute,
    },
    {
      text: 'Complete and try again',
      onClick: onExecuteAndTryAgain,
    },
  ];

  const flowNoDialerItems: AdditionMenuItems[] = [
    {
      text: 'Log and create follow-up',
      onClick: onExecuteAndFollowup,
    },
    {
      text: 'Log and move to another flow',
      onClick: openExecuteAndAddToFlow,
    },
    {
      text: 'Log and pause until...',
      onClick: openPauseFlowExecute,
    },
    {
      text: 'Log and remove from flow',
      onClick: onExecuteAndRemove,
    },
    {
      text: 'Log and skip future call steps',
      onClick: onExecuteAndSkipAll,
    },
    {
      text: 'Log and try again',
      onClick: onExecuteAndTryAgain,
    },
  ];

  let dialAgainButton = null;

  if (type === 'CALL') {
    const doNotCall = useStore.getState().action.who?.doNotCall;
    if (!personStepId) callItems = nonFlowItems;
    else callItems = flowNoDialerItems;

    if (orgSettings?.third_party_dialer_enabled) callItems = flow3rdPartyItems;

    let tooltip = generateCompleteTooltip(userData);

    if (!tooltip && orgSettings?.third_party_dialer_enabled) {
      tooltip = MARK_AS_COMPLETE_TOOLTIP_TEXT;
    }

    // if the dialer is enabled, have the primary button show Mark as Complete
    const callButtonText = orgSettings?.third_party_dialer_enabled
      ? 'Mark as complete'
      : 'Log';
    const onClick = orgSettings?.third_party_dialer_enabled
      ? onMarkAsComplete
      : onSendAndExecute;
    primaryButton = (
      <Button
        actionName="Action Compose Log"
        variant="primary"
        data-testid="call-log"
        onClick={onClick}
        className="ml-[8px]"
        additionalMenuItems={callItems}
        additionalMenuWidth={200}
        additionalMenuDirection="topRight"
        disabled={
          !!executionErrors.length ||
          (userData?.dialer_enabled &&
            id !== NEW_ACTION_ID &&
            !dialerInActionEnabled)
        }
        tooltip={tooltip}
      >
        {callButtonText}
      </Button>
    );

    if (isDialerInActionEnabled) {
      let callTooltip: string | ReactElement = DISABLED_CALL_TOOLTIP;
      let disableCall = true;

      if (doNotCall === true) {
        tooltip = validationHash.callOptOut(recipientName);
      } else if (!grooveNumber) {
        callTooltip = (
          <span>
            Please make sure you have provisioned a Groove phone number in{' '}
            <a href={DIALER_SETTINGS_URL} target="_blank" rel="noreferrer">
              settings.
            </a>
          </span>
        );
      } else if (localPhoneData?.message_id === LOCAL_DIAL_PARSE_ERROR) {
        callTooltip = `${recipientName}'s phone number is invalid, please select a different one.`;
      } else if (!recipientPhone) {
        callTooltip = `The recipient doesn't have a phone number`;
      } else if (
        userData.bridge_calling_enabled &&
        !userData.bridge_calling_number
      ) {
        callTooltip = (
          <span>
            Please make sure you have provisioned a Bridge Dial number in{' '}
            <a href={DIALER_SETTINGS_URL} target="_blank" rel="noreferrer">
              settings.
            </a>
          </span>
        );
      } else {
        callTooltip = `Call ${recipientName} at ${recipientPhone}`;
        disableCall = false;
      }

      if (callState === 'not-connected' && !hasCalled) {
        primaryButton = (
          <Button
            variant="primary"
            onClick={onCallClick}
            className="ml-[8px]"
            disabled={disableCall}
            tooltip={callTooltip}
          >
            Call
          </Button>
        );
      } else if (callState === 'not-connected' && hasCalled) {
        dialAgainButton = (
          <Button
            variant="secondary"
            onClick={onCallAgain}
            className="ml-[8px]"
            tooltip={`Log the call and call ${recipientName || 'person'} again`}
          >
            Call again
          </Button>
        );
      } else if (callState !== 'not-connected') {
        primaryButton = (
          <Button
            variant="danger"
            onClick={onHangupClick}
            className="ml-[8px]"
            tooltip="Click to end call"
          >
            End Call
          </Button>
        );
      }
    }
  }

  return (
    <>
      {dialAgainButton}
      {primaryButton}
    </>
  );
};

export default PrimaryExecuteButton;
