import { Record } from 'immutable';
import isNil from 'lodash-es/isNil';
import moment from 'moment';

import Template from './Template';

const defaults = {
  id: null,
  deletedAt: null,
  hasLinkTrackingEnabled: false,
  template: Template(),
};

export default class StepVariant extends Record(defaults) {
  static from({ id, deletedAt, template, hasLinkTrackingEnabled }) {
    return new StepVariant({
      id,
      template: Template(template),
      ...(deletedAt && { deletedAt: moment(deletedAt) }),
      ...(hasLinkTrackingEnabled != null && { hasLinkTrackingEnabled }),
    });
  }

  isActive() {
    return isNil(this.deletedAt);
  }
}
