import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  ACTIONS: {
    FETCH_INITIAL_LOAD_DATA: standardActions(),
    FETCH_ADDITIONAL_DATA: standardActions(),
    FETCH_ACTION_COUNTS: standardActions(),
    EXECUTE_DESTRUCTION: standardActions(),
    ...keyMirror([
      'SET_COLUMNS',
      'SET_PAGE_INFO',
      'SORT_COLUMN',
      'SET_ACTIVE_ACTION',
      'REMOVE_ACTION',
      'RESET_REDUCER',
      'FETCH_FILTERED_ACTIONS',
      'FETCH_TIMEZONE_FILTERS',
      'TRACK_SEARCH_INPUT_UPDATED',
      'SET_ACTIONS',
      'SET_TABLE_TOTAL_WIDTH',
      'SET_ACTIVE_TAB',
      'SET_STATUS',
      'CLEAR_ALL_ROW_DATA',
      'UPDATE_CURRENT_TAB_COUNT',
      'SAVE_ACTION_FILTERS',
      'UPDATE_ACTION_FILTERS',
    ]),
    ORDER_COLUMNS: standardActions(),
    FETCH_TIMEZONE_FILTERS: standardActions(),
  },
}).ACTIONS;

export const updateCurrentTabCount = ({ count }) => ({
  type: actionTypes.UPDATE_CURRENT_TAB_COUNT,
  payload: { count },
});

export const beginFetchingActionCounts = () => ({
  type: actionTypes.FETCH_ACTION_COUNTS.BEGIN,
});

export const failedFetchingActionCounts = () => ({
  type: actionTypes.FETCH_ACTION_COUNTS.FAILURE,
});

export const successfullyFetchedActionCounts = ({ counts }) => ({
  type: actionTypes.FETCH_ACTION_COUNTS.SUCCESS,
  payload: { counts },
});

export const setStatusFilter = ({ status }) => ({
  type: actionTypes.SET_STATUS,
  payload: { status },
});

export const setActiveTab = ({ tab }) => ({
  type: actionTypes.SET_ACTIVE_TAB,
  payload: { tab },
});

export const setTableTotalWidth = ({ width }) => ({
  type: actionTypes.SET_TABLE_TOTAL_WIDTH,
  payload: { width },
});

export const fetchFilteredActions = () => ({
  type: actionTypes.FETCH_FILTERED_ACTIONS,
});

export const beginFetchingTimezoneFilters = () => ({
  type: actionTypes.FETCH_TIMEZONE_FILTERS.BEGIN,
});

export const successfullyFetchedTimezoneFilters = ({ timezones }) => ({
  type: actionTypes.FETCH_TIMEZONE_FILTERS.SUCCESS,
  payload: { timezones },
});

export const failedFetchingTimezoneFilters = () => ({
  type: actionTypes.FETCH_TIMEZONE_FILTERS.FAILURE,
});

export const trackSearchInputUpdated = () => ({
  type: actionTypes.TRACK_SEARCH_INPUT_UPDATED,
});

export const removeAction = ({ id }) => ({
  type: actionTypes.REMOVE_ACTION,
  payload: { id },
});

export const sortColumn = ({ id }) => ({
  type: actionTypes.SORT_COLUMN,
  payload: { id },
});

export const setColumns = ({ columns }) => ({
  type: actionTypes.SET_COLUMNS,
  payload: { columns },
});

export const setPageInfo = ({ pageInfo }) => ({
  type: actionTypes.SET_PAGE_INFO,
  payload: { pageInfo },
});

export const beginFetchingInitialLoadData = () => ({
  type: actionTypes.FETCH_INITIAL_LOAD_DATA.BEGIN,
});

export const successfullyFetchedInitialLoadData = ({ rows, rowIds }) => ({
  type: actionTypes.FETCH_INITIAL_LOAD_DATA.SUCCESS,
  payload: { rows, rowIds },
});

export const failedFetchingInitialLoadData = () => ({
  type: actionTypes.FETCH_INITIAL_LOAD_DATA.FAILURE,
});

export const beginFetchingAdditionalData = () => ({
  type: actionTypes.FETCH_ADDITIONAL_DATA.BEGIN,
});

export const successfullyFetchedAdditionalData = ({ rows, rowIds }) => ({
  type: actionTypes.FETCH_ADDITIONAL_DATA.SUCCESS,
  payload: { rows, rowIds },
});

export const failedFetchingAdditionalData = () => ({
  type: actionTypes.FETCH_ADDITIONAL_DATA.FAILURE,
});

export const beginOrderingColumns = () => ({
  type: actionTypes.ORDER_COLUMNS.BEGIN,
});

export const orderingColumnsProgress = ({ oldIndex, newIndex }) => ({
  type: actionTypes.ORDER_COLUMNS.PROGRESS,
  payload: { oldIndex, newIndex },
});

export const successfullyOrderedColumns = ({ nextColumnOrder }) => ({
  type: actionTypes.ORDER_COLUMNS.SUCCESS,
  payload: { nextColumnOrder },
});

export const saveFilterMenu = () => ({
  type: actionTypes.SAVE_FILTER_MENU,
});

export const setActiveAction = payload => ({
  type: actionTypes.SET_ACTIVE_ACTION,
  payload,
});

export const setActions = ({ rows, rowIds }) => ({
  type: actionTypes.SET_ACTIONS,
  payload: { rows, rowIds },
});

export const clearAllRowData = () => ({
  type: actionTypes.CLEAR_ALL_ROW_DATA,
});

export const saveActionFilters = () => ({
  type: actionTypes.SAVE_ACTION_FILTERS,
});

export const updateActionFilters = payload => ({
  type: actionTypes.UPDATE_ACTION_FILTERS,
  payload,
});

export const beginExecutingDestruction = ({ destructionType, actionId }) => ({
  type: actionTypes.EXECUTE_DESTRUCTION.BEGIN,
  payload: { destructionType, actionId },
});

export const successfullyExecutedDestruction = () => ({
  type: actionTypes.EXECUTE_DESTRUCTION.SUCCESS,
});

export const failedToExecuteDestruction = () => ({
  type: actionTypes.EXECUTE_DESTRUCTION.FAILURE,
});
