class GraphQLError extends Error {
  constructor({ error, operation }) {
    super(error.message);

    this.name = error.name;
    this.operation = operation;
    this.fullError = error;
    this.errorType = error.networkError ? 'NETWORK' : 'GRAPHQL';
  }
}

export default GraphQLError;
