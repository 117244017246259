import { useQuery, UseQueryOptions } from 'react-query';

import { ApiMergeFields, userMergeFields } from '../gateway/v1/users';

export type MergeFieldGroup = {
  label: string;
  options: MergeField[];
};

export type MergeField = {
  label: string;
  name: string;
};

const useUserMergeFields = (
  options?:
    | Omit<
        UseQueryOptions<
          ApiMergeFields,
          unknown,
          ApiMergeFields,
          'userMergeFields'
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
): MergeFieldGroup[] => {
  const mergeFields: MergeFieldGroup[] = [];
  const mergeFieldsResult = useQuery(
    'userMergeFields',
    userMergeFields,
    options,
  );

  const popularFields = {
    label: 'Most popular',
    options: [
      { label: 'First Name', name: 'firstname' },
      { label: 'Name', name: 'name' },
      { label: 'Company', name: 'company' },
      { label: 'Last Name', name: 'lastname' },
      { label: 'User First Name', name: 'user.firstname' },
    ],
  };

  if (mergeFieldsResult?.data) {
    mergeFields.push(popularFields);
    Object.keys(mergeFieldsResult?.data).forEach(key => {
      mergeFields.push({
        label: key,
        options: mergeFieldsResult?.data?.[key],
      });
    });
  }

  return mergeFields;
};

export default useUserMergeFields;
