import { call } from 'redux-saga/effects';

import getProductUsageData from 'Modules/Shared/sagas/productUsage';

function* handleMoboUserChanged() {
  yield call(getProductUsageData);

  return null;
}

export default handleMoboUserChanged;
