import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
function NoResultsSvg({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="153"
      height="104"
      viewBox="0 0 153 104"
    >
      <defs>
        <path
          id="a"
          d="M112.387 47.222l-13 3.973c1.494 9.43 1.108 19.056 1.108 19.056S98.6 72.574 82.08 77.76c-9.46.636-17.74-.265-24.84-2.702 0 0-1.077-7.38-1.618-11.302-4.583 2.645-12.39 6.582-19.899 7.702C23.226 73.32.388 51.862.388 51.862l12.107-9.95 21.668 7.1 23.924-14.25.005-.003c12.495-7.661 17.831-6.477 23.764-4.33 4.808 1.74 13.498-2.351 13.498-2.351L84.29 9.92 99.16.118l19.507 25.697c3.607 4.847 4.128 9.75 1.564 14.711-2.086 3.58-4.7 5.812-7.843 6.696z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#1F2128" strokeWidth="1.382">
          <path
            strokeLinecap="round"
            d="M4.32 20.304L.864 24.192"
            transform="translate(-158 -152) translate(159 152) matrix(-1 0 0 1 23.04 39.6)"
          />
          <path
            d="M11.232 22.464c6.203 0 11.232-5.029 11.232-11.232C22.464 5.029 17.435 0 11.232 0 5.029 0 0 5.029 0 11.232c0 6.203 5.029 11.232 11.232 11.232z"
            transform="translate(-158 -152) translate(159 152) matrix(-1 0 0 1 23.04 39.6)"
          />
        </g>
        <g transform="translate(-158 -152) translate(159 152) translate(25.92)">
          <circle
            cx="73.8"
            cy="51.48"
            r="51.48"
            fill="#03A3BB"
            opacity="0.251"
          />
          <circle
            cx="73.8"
            cy="51.48"
            r="51.48"
            fill="#03A3BB"
            opacity="0.251"
          />
          <path
            fill="#FFD0C3"
            fillRule="nonzero"
            d="M74.05 62.624l.196-.016a7.083 7.083 0 004.78-2.537 7.03 7.03 0 001.581-5.16l-2.395-24.68a7.027 7.027 0 00-2.516-4.831 7.103 7.103 0 00-7.887-.8 7.074 7.074 0 00-3.443 4.226 7.02 7.02 0 00-.242 2.765l2.395 24.68a7.042 7.042 0 002.472 4.698 7.095 7.095 0 005.059 1.655z"
          />
          <path
            fill="#FFD0C3"
            fillRule="nonzero"
            d="M68.928 48.8l-1.322.118a10.686 10.686 0 01-4.535-.578 10.539 10.539 0 01-3.855-2.416 10.289 10.289 0 01-2.441-3.794 10.118 10.118 0 01-.568-4.455l.727-7.469a10.239 10.239 0 013.087-6.365 10.585 10.585 0 016.532-2.929 10.63 10.63 0 017.644 2.432 10.244 10.244 0 013.654 7.025l.596 7.35a10.176 10.176 0 01-2.44 7.46 10.565 10.565 0 01-7.079 3.621z"
          />
          <path
            fill="#D6AA7E"
            fillRule="nonzero"
            d="M95.69 65s-5.351 13.427-15.797 7.454c-17.292-9.892-13.75-22.883-8.88-28.823 1.939-2.366 4.655-3.413 7.489-4.271-8.247-7.28-4.199-13.07-4.199-13.07S52.26 24.858 59.381 14.398c7.121-10.46 19.854-9.081 26.606-4.532 15.695 10.58 10.363 21.348 13.945 25.317 20.312 22.522-4.242 29.816-4.242 29.816z"
            transform="rotate(-18 82.97 40.212)"
          />
          <path
            stroke="#002329"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.54"
            d="M60.8266399 33.12L60.48 39.4598675 62.64 39.6"
          />
          <path
            fill="#24272D"
            fillRule="nonzero"
            d="M65.7929597 48.9156587L69.84 48.24 66.128361 52.2859405z"
          />
          <path
            fill="#002329"
            fillRule="nonzero"
            d="M64.44 35.28a1.08 1.08 0 10-.001-2.161 1.08 1.08 0 00.001 2.161zM57.96 36a1.08 1.08 0 10-.001-2.161A1.08 1.08 0 0057.96 36zM67.68 40.32c-.099 0-3.278 0-3.6 2.16l3.6-2.16z"
          />
          <path
            fill="#FFD0C3"
            fillRule="nonzero"
            d="M87.91 34.27l-7.479-6.313s-7.694-4.205-5.316-7.44c0 0-1.197-.7-.91-1.47.285-.77 4.213-1.066 4.213-1.066s2.46-2.09 5.212-1.201c2.754.888 7.618 3.41 8.424 4.097 1.094.93 2.986 8.182 2.986 8.182s-2.28 6.989-7.13 5.212z"
          />
          <g transform="translate(0 25.2)">
            <mask id="b" fill="#fff">
              <use xlinkHref="#a" />
            </mask>
            <use fill="#FBCD7E" xlinkHref="#a" />
            <g strokeLinecap="round" strokeWidth="5.58" mask="url(#b)">
              <path
                stroke="#016776"
                d="M.005 48.34l13.57 13.496M29.132 64.911L17.143 76.834"
                transform="translate(3.027 -6.429)"
              />
              <path
                stroke="#FFA161"
                d="M29.132 32.867L17.143 44.79"
                transform="translate(3.027 -6.429)"
              />
              <path
                stroke="#016776"
                d="M48.811 77.859L62.384 64.36M65.476 80.934l11.99 11.922M33.254 48.89l11.99 11.922"
                transform="translate(3.027 -6.429)"
              />
              <path
                stroke="#FFA161"
                d="M64.479 15.564l13.57 13.497"
                transform="translate(3.027 -6.429)"
              />
              <path
                stroke="#016776"
                d="M61.384 32.136L49.398 44.06"
                transform="translate(3.027 -6.429)"
              />
              <path
                stroke="#FFA161"
                d="M126.658 63.356l-11.989 11.923"
                transform="translate(3.027 -6.429)"
              />
              <path
                stroke="#016776"
                d="M93.606 32.136L81.617 44.06"
                transform="translate(3.027 -6.429)"
              />
              <path
                stroke="#FFA161"
                d="M93.606.092L81.617 12.015"
                transform="translate(3.027 -6.429)"
              />
              <path
                stroke="#016776"
                d="M113.286 45.084l13.572-13.498M81.486 77.565l13.57-13.496M63.59 49.016l11.988 11.923M97.468 49.016l11.989 11.923M97.728 16.114l11.99 11.923"
                transform="translate(3.027 -6.429)"
              />
            </g>
          </g>
          <path
            fill="#FFD0C3"
            fillRule="nonzero"
            d="M81.48 38.23c1.123-1.754.624-4.093-1.113-5.225a3.724 3.724 0 00-5.177 1.125l-1.151 1.8c-1.122 1.754-.622 4.093 1.115 5.225a3.722 3.722 0 005.175-1.125l1.15-1.8z"
          />
          <path
            stroke="#002329"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.54"
            d="M78.48 36l-2.16 2.88"
          />
          <path
            fill="#D6AA7E"
            fillRule="nonzero"
            d="M103.986 61.587s-5.352 13.428-15.797 7.454C70.896 59.15 74.439 46.16 79.308 40.22c3.408-.556 5.46-1.513 6.154-2.871 2.368-4.628 4.905-6.055 7.058-3.989 2.238 2.149 4.067 8.065 5.28 17.545.922 7.206 7.69 7.824 6.186 10.683z"
            transform="rotate(-18 89.758 51.504)"
          />
        </g>
        <path
          fill="#FFD0C3"
          fillRule="nonzero"
          d="M20.151 62.283s8.051-2.016 11.298-.547c3.247 1.47 4.958 4.154 4.468 7.01-.238 1.381-8.607 7.574-8.607 7.574l-8.59-7.013 1.431-7.024z"
          transform="translate(-158 -152) translate(159 152) matrix(-1 0 0 1 54.72 0)"
        />
      </g>
    </svg>
  );
}
/* eslint-enable max-len */

NoResultsSvg.displayName = 'NoResultsSvg';
NoResultsSvg.propTypes = {
  className: PropTypes.string,
};

NoResultsSvg.defaultProps = {
  className: null,
};

export default NoResultsSvg;
