import { isNull, set, get } from 'lodash-es';

const namespace = 'grooveStore';
const replicationNamespace = `${namespace}Replication`;

const parseStore = () => {
  const jsonStore = localStorage.getItem(namespace);
  return isNull(jsonStore) ? {} : JSON.parse(jsonStore);
};

export const save = (key, val) => {
  const store = parseStore();
  store[key] = val;
  localStorage.setItem(namespace, JSON.stringify(store));
};

export const saveIn = (keyPath, val) => {
  const store = parseStore();
  set(store, keyPath, val);
  localStorage.setItem(namespace, JSON.stringify(store));
};

export const remove = key => {
  const store = parseStore();
  delete store[key];
  localStorage.setItem(namespace, JSON.stringify(store));
};

export const fetch = key => {
  const store = parseStore();
  return store[key];
};

export const fetchIn = keyPath => {
  const store = parseStore();
  return get(store, keyPath);
};

// Should only be used for the LOGIN AS feature
export const reloadOriginalStore = () => {
  const replicatedStore = localStorage.getItem(replicationNamespace);
  localStorage.setItem(namespace, replicatedStore);
  localStorage.setItem(`${namespace}Replication`, JSON.stringify({}));
};

// Should only be used for the LOGIN AS feature
export const replicateStore = () => {
  const grooveStore = parseStore();
  localStorage.setItem(replicationNamespace, JSON.stringify(grooveStore));
  localStorage.setItem(namespace, JSON.stringify({}));
};
