import { useQuery } from 'react-query';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import { getActionsTimezones } from '@groove/api/gateway/v1/actions';
import { FilterDropdownOptions } from '@groove/api/services/v1/actions';

export default function useGetTimezoneOptions(): FilterDropdownOptions {
  const { data: timezones } = useQuery('timezones', getActionsTimezones);
  return {
    label: 'timezone',
    text: 'Timezone',
    data:
      timezones?.data
        .map(tz => ({
          key: tz,
          ariaLabel: tz,
          text: tz.replace('_', ' '),
          secondaryText: `${formatInTimeZone(
            new Date(),
            tz,
            'HH:mm a',
          )}, (UTC ${getTimezoneOffset(tz, new Date()) / (60 * 60 * 1000)})`,
        }))
        .sort((a, b) => getTimezoneOffset(b.key) - getTimezoneOffset(a.key)) ||
      [],
  };
}
