import {
  isAnalyticsEnabled,
  mixpanelSafeTrack,
} from '@groove/api/analytics/hooks/useMixpanel';

export const templatesBaseProperties = {
  app: 'chrome extension',
  app_feature: 'templates',
  transaction_type: 'user',
};

export enum TEMPLATES_EVENT_TYPE {
  PANEL_OPENED = 'Panel Opened',
  TAB_SWITCHED = 'Tab Switched',
  MODAL_OPENED = 'Modal Opened',
  PREVIEW_OPENED = 'Preview Opened',
  PREVIEW_CLOSED = 'Preview Closed',
  INSERTED = 'Inserted',
  SEARCHED = 'Searched',
  STAR_CLICKED = 'Star Clicked',
  CREATE_NEW_MODAL_OPENED = 'Create New Modal Opened',
  EDIT_MODAL_OPENED = 'Edit Modal Opened',
  MERGE_FILED_INSERTED = 'Merge Field Inserted',
  DELETED = 'Deleted',
  SAVED = 'Saved',
  CLOSED = 'Closed',
  ALL_FOLDERS_LIST_OPENED = 'All Folders List Opened',
  FOLDER_SELECTED = 'Folder Selected',
  EDITOR_CLOSED = 'Editor Closed',
}

export enum TEMPLATES_EVENT_UI_SOURCE {
  COMPOSE = 'compose bar',
  PANEL = 'panel',
  MODAL = 'modal',
}

export enum TEMPLATES_TAB {
  TEMPLATES = 'templates',
  FAVORITES = 'favorites',
}

export enum TEMPLATES_MODAL_SOURCE {
  SEARCH = 'search',
  EXPAND = 'expand',
}

export enum TEMPLATES_VIEW {
  LIST = 'list',
  PREVIEW = 'preview',
}

export enum TEMPLATES_ACTION_TYPE {
  NEW = 'new',
  EDIT = 'edit',
}

export enum CLOSED_VIA {
  X = 'x',
  CLOSE = 'close',
  OUTSIDE_CLICK = 'outside_click',
}

export enum TEMPLATE_STAR_STATE {
  CLICKED = 'clicked',
  UNCLICKED = 'unclicked',
}

export enum TEMPLATE_EDITOR_CLOSED_VIA {
  X_BUTTON = 'x',
  CLOSE_BUTTON = 'close',
  OUTSIDE_CLICK = 'outside_click',
}

export type Props = {
  eventName: TEMPLATES_EVENT_TYPE;
  extensionSourceUi?: TEMPLATES_EVENT_UI_SOURCE;
  templatesSourceUi?: TEMPLATES_EVENT_UI_SOURCE;
  templateTab?: TEMPLATES_TAB;
  templatesModalSource?: TEMPLATES_MODAL_SOURCE;
  templateView?: TEMPLATES_VIEW;
  deletedConfirmed?: boolean;
  templateActionType?: TEMPLATES_ACTION_TYPE;
  fallbackAdded?: boolean;
  starState?: TEMPLATE_STAR_STATE;
  mergeField?: string;
  success?: boolean;
  templateFolder?: string;
  closedVia?: TEMPLATE_EDITOR_CLOSED_VIA;
};

export function trackTemplatesEvent(props: Props): void {
  const {
    eventName,
    extensionSourceUi = null,
    templateTab = null,
    templatesSourceUi = null,
    templatesModalSource = null,
    templateView = null,
    deletedConfirmed = null,
    templateActionType = null,
    fallbackAdded = null,
    starState = null,
    mergeField = null,
    success = null,
    templateFolder = null,
    closedVia = null,
  } = props;
  if (!isAnalyticsEnabled()) return;
  mixpanelSafeTrack(`Ext: Templates: ${eventName}`, {
    ...templatesBaseProperties,
    extension_source_ui: extensionSourceUi,
    templates_source_ui: templatesSourceUi,
    template_tab: templateTab,
    templates_modal_source: templatesModalSource,
    template_view: templateView,
    delete_confirmed: deletedConfirmed,
    template_action_type: templateActionType,
    fallback_added: fallbackAdded,
    star_state: starState,
    merge_field: mergeField,
    success,
    template_folder: templateFolder,
    closed_via: closedVia,
  });
}
