import { Record } from 'immutable';
import moment from 'moment';

export const SmsMessage = new Record({
  body: '',
  dateCreated: moment(),
  outbound: true,
});

export const PhoneNumber = new Record({
  raw: null,
  display: null,
});

export const PhoneNumbers = new Record({
  phone: new PhoneNumber(),
  mobilephone: new PhoneNumber(),
});
