import ruta3 from 'ruta3';

export default class RouteMatcher {
  constructor(routes) {
    // Initialize route pattern matcher
    const matcher = ruta3();
    routes.forEach(route => matcher.addRoute(route.pattern, route));
    this._matcher = matcher;
  }

  find(path) {
    let route = null;
    try {
      route = this._matcher.match(path);
    } catch (e) {
      throw new Error(
        `Failed to find route definition for path '${path}'. Check routing configuration at src/js/routing/routeConfiguration: ${e}`
      );
    }
    return route.action;
  }
}
