import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const RecordIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 21 20"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, 'fill-current')}
  >
    <path d="M6.5 10C6.5 9.44772 6.94772 9 7.5 9C8.05228 9 8.5 9.44772 8.5 10C8.5 10.5523 8.05228 11 7.5 11C6.94772 11 6.5 10.5523 6.5 10ZM13.5 8C14.2423 8 14.8902 8.40442 15.2353 9.00494C16.4733 9.05705 17.6054 9.51838 18.5 10.2572V6C18.5 4.89543 17.6046 4 16.5 4L4.5 4C3.39543 4 2.5 4.89543 2.5 6L2.5 14C2.5 15.1046 3.39543 16 4.5 16H9.70703C9.57217 15.5232 9.5 15.02 9.5 14.5C9.5 12.7852 10.2848 11.2536 11.5148 10.2449C11.505 10.1647 11.5 10.0829 11.5 10C11.5 9.63571 11.5974 9.29417 11.7676 9L9.23244 9C9.40261 9.29417 9.5 9.63571 9.5 10C9.5 11.1046 8.60457 12 7.5 12C6.39543 12 5.5 11.1046 5.5 10C5.5 8.89543 6.39543 8 7.5 8L13.5 8Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 19C17.4853 19 19.5 16.9853 19.5 14.5C19.5 12.0147 17.4853 10 15 10C12.5147 10 10.5 12.0147 10.5 14.5C10.5 16.9853 12.5147 19 15 19ZM13.3536 14.1464C13.1583 13.9512 12.8417 13.9512 12.6464 14.1464C12.4512 14.3417 12.4512 14.6583 12.6464 14.8536L14.646 16.8532L14.6486 16.8557C14.696 16.9026 14.7505 16.938 14.8086 16.9621C14.8667 16.9861 14.9303 16.9996 14.997 17L15 17L15.003 17C15.0697 16.9996 15.1333 16.9861 15.1914 16.9621C15.2504 16.9377 15.3056 16.9015 15.3536 16.8536L17.3536 14.8536C17.5488 14.6583 17.5488 14.3417 17.3536 14.1464C17.1583 13.9512 16.8417 13.9512 16.6464 14.1464L15.5 15.2929L15.5 12.5C15.5 12.2239 15.2761 12 15 12C14.7239 12 14.5 12.2239 14.5 12.5L14.5 15.2929L13.3536 14.1464Z"
    />
  </svg>
);
