import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  TEMPLATES: {
    SEARCH: standardActions(),
    CREATE_FOLDER: standardActions(),
    UPDATE_FOLDER: standardActions(),
    DELETE_FOLDER: standardActions(),
    FETCH_TEMPLATES: standardActions(),
    CREATE_OR_UPDATE_TEMPLATE: standardActions(),
    DELETE_TEMPLATE: standardActions(),
    COPY_TEMPLATE: standardActions(),
    FETCH_TEMPLATE_HTML_BODY: standardActions(),
    ...keyMirror([
      'LOAD_DATA',
      'ACTIVE_TEMPLATE_SET',
      'ACTIVE_FOLDER_SET',
      'UPDATE_SORTING',
      'SEND_SAMPLE',
      'TEMPLATE_FLOW_USAGE',
      'LOADING',
      'RESET_REDUCER',
      'LOAD_TEMPLATE_FOLDERS',
      'SET_FOLDER_TEMPLATES',
      'CLEAR_SEARCH_RESULTS',
      'ADD_LABEL_INSTANCES',
      'REMOVE_LABEL_INSTANCES',
      'SET_SEARCH_QUERY',
      'JUMP_TO_TEMPLATE_FOLDER',
      'ADD_TEMPLATE_TO_FOLDER',
      'RESET_ACTIVE_DATA',
      'FINISH_LOADING_TEMPLATES',
      'SET_FOLDER_VIEW',
      'REDIRECT_TO_TEMPLATE',
      'REDIRECT_TO_TEMPLATE_FOLDER',
      'GET_TEMPLATE_BY_FOLDER_ID',
      'UPDATE_TEMPLATE_FILTER_OPTION',
      'SET_FOLDER_SERACH_QUERY',
    ]),
  },
}).TEMPLATES;

export const setActiveTemplate = ({ templateId, templateFolderId }) => {
  return {
    type: actionTypes.ACTIVE_TEMPLATE_SET,
    payload: { templateId, templateFolderId },
  };
};

export const setActiveFolderId = id => {
  return {
    type: actionTypes.ACTIVE_FOLDER_SET,
    payload: id,
  };
};

export function setSearchQuery({ query }) {
  return {
    type: actionTypes.SET_SEARCH_QUERY,
    payload: { query },
  };
}

export function setFolderSearchQuery({ query }) {
  return {
    type: actionTypes.SET_FOLDER_SERACH_QUERY,
    payload: { query },
  };
}

export const loadData = ({ templates, templateFolders }) => {
  return {
    type: actionTypes.LOAD_DATA,
    payload: {
      templates,
      templateFolders,
    },
  };
};

export const triggerSortingChange = orderBy => {
  return {
    type: actionTypes.UPDATE_SORTING,
    payload: orderBy,
  };
};

export const redirectToFolder = templateFolderId => {
  return {
    type: actionTypes.REDIRECT_TO_TEMPLATE_FOLDER,
    payload: templateFolderId,
  };
};

export const redirectToTemplate = ({ templateFolderId, templateId }) => {
  return {
    type: actionTypes.REDIRECT_TO_TEMPLATE,
    payload: {
      templateFolderId,
      templateId,
    },
  };
};

export const getTemplateByFolderId = ({ templateFolderId, templateId }) => {
  return {
    type: actionTypes.GET_TEMPLATE_BY_FOLDER_ID,
    payload: { templateFolderId, templateId },
  };
};

export const beginCopyingTemplate = payload => {
  return {
    type: actionTypes.COPY_TEMPLATE.BEGIN,
    payload,
  };
};

export function templateSuccessfullyCopied() {
  return {
    type: actionTypes.COPY_TEMPLATE.SUCCESS,
  };
}

export const sendSampleEmail = payload => {
  return {
    type: actionTypes.SEND_SAMPLE,
    payload,
  };
};

export const beginDeletingTemplate = templateId => {
  return {
    type: actionTypes.DELETE_TEMPLATE.BEGIN,
    payload: templateId,
  };
};

export const successDeletingTemplate = ({ templateId, hardDelete = false }) => {
  return {
    type: actionTypes.DELETE_TEMPLATE.SUCCESS,
    payload: { templateId, hardDelete },
  };
};

export const beginCreatingFolder = payload => {
  return {
    type: actionTypes.CREATE_FOLDER.BEGIN,
    payload,
  };
};

export const createFolderSuccess = ({ templateFolder }) => {
  return {
    type: actionTypes.CREATE_FOLDER.SUCCESS,
    payload: { templateFolder },
  };
};

export const beginUpdatingFolder = folderName => {
  return {
    type: actionTypes.UPDATE_FOLDER.BEGIN,
    payload: folderName,
  };
};

export const updateFolderSuccess = ({ templateFolder }) => {
  return {
    type: actionTypes.UPDATE_FOLDER.SUCCESS,
    payload: { templateFolder },
  };
};

export const beginDeletingFolder = folderId => {
  return {
    type: actionTypes.DELETE_FOLDER.BEGIN,
    payload: folderId,
  };
};

export const deletingFolderSuccess = folderId => {
  return {
    type: actionTypes.DELETE_FOLDER.SUCCESS,
    payload: folderId,
  };
};

export const triggerTemplateFlowUsageFetch = payload => {
  return {
    type: actionTypes.TEMPLATE_FLOW_USAGE,
    payload,
  };
};

export function resetActiveData() {
  return {
    type: actionTypes.RESET_ACTIVE_DATA,
  };
}

export function beginCreateOrUpdateTemplate(templateFolderId) {
  return {
    type: actionTypes.CREATE_OR_UPDATE_TEMPLATE.BEGIN,
    payload: templateFolderId,
  };
}

export function createOrUpdateTemplateSuccess({ template, successVerb }) {
  return {
    type: actionTypes.CREATE_OR_UPDATE_TEMPLATE.SUCCESS,
    payload: { template, successVerb },
  };
}

export function setTemplateLoading(payload) {
  return {
    type: actionTypes.LOADING,
    payload,
  };
}

export function loadTemplateFolders({ templateFolders }) {
  return {
    type: actionTypes.LOAD_TEMPLATE_FOLDERS,
    payload: { templateFolders },
  };
}

export function setFolderTemplates({ templateFolderId, templates }) {
  return {
    type: actionTypes.SET_FOLDER_TEMPLATES,
    payload: { templateFolderId, templates },
  };
}
export function addLabelInstances({
  labelInstances,
  activeFolderId,
  activeTemplateId,
}) {
  return {
    type: actionTypes.ADD_LABEL_INSTANCES,
    payload: { activeFolderId, activeTemplateId, labelInstances },
  };
}

export function removeLabelInstances({
  activeFolderId,
  activeTemplateId,
  instanceIds,
}) {
  return {
    type: actionTypes.REMOVE_LABEL_INSTANCES,
    payload: { activeFolderId, activeTemplateId, instanceIds },
  };
}

export function beginFetchingTemplates() {
  return {
    type: actionTypes.FETCH_TEMPLATES.BEGIN,
  };
}

export function fetchingTemplatesSuccess() {
  return {
    type: actionTypes.FETCH_TEMPLATES.SUCCESS,
  };
}

export function searchSuccess({
  templates,
  templateFolders,
  hasCheckedLabels,
  searchQuery,
}) {
  return {
    type: actionTypes.SEARCH.SUCCESS,
    payload: { templates, templateFolders, hasCheckedLabels, searchQuery },
  };
}

export function jumpToTemplateFolder({ folderId, templateId }) {
  return {
    type: actionTypes.JUMP_TO_TEMPLATE_FOLDER,
    payload: { folderId, templateId },
  };
}

export function clearSearchResults() {
  return {
    type: actionTypes.CLEAR_SEARCH_RESULTS,
  };
}

export function addTemplateToFolder({ folderId, template }) {
  return {
    type: actionTypes.ADD_TEMPLATE_TO_FOLDER,
    payload: { folderId, template },
  };
}

export function finishLoadingTemplates() {
  return {
    type: actionTypes.FINISH_LOADING_TEMPLATES,
  };
}

export function setFolderView() {
  return {
    type: actionTypes.SET_FOLDER_VIEW,
  };
}

export function fetchTemplateHtmlDataBegin({ templateId, templateFolderId }) {
  return {
    type: actionTypes.FETCH_TEMPLATE_HTML_BODY.BEGIN,
    payload: { templateId, templateFolderId },
  };
}

export function fetchTemplateHtmlDataSuccess({
  templateId,
  templateFolderId,
  data,
}) {
  return {
    type: actionTypes.FETCH_TEMPLATE_HTML_BODY.SUCCESS,
    payload: { templateId, templateFolderId, data },
  };
}

export const triggerTemplateFilterMenuChange = value => {
  return {
    type: actionTypes.UPDATE_TEMPLATE_FILTER_OPTION,
    payload: value,
  };
};
