import { fromJS } from 'immutable';
import { subscribe } from 'wonka';

import GraphQLClient from 'GrooveHTTPClient/GraphQLClient';
import GraphQLResponse from 'GrooveHTTPClient/GraphQLResponse';
import GraphQLError from 'GrooveHTTPClient/GraphQLError';

export default async (gqlRequest, gqlOptions = {}) => {
  // @TODO Put check for keys in gqlRequest
  const client = GraphQLClient();
  const dataStream = await client.executeQuery(gqlRequest, gqlOptions);
  const response = await new Promise((resolve, reject) => {
    try {
      subscribe(resolve)(dataStream);
    } catch (error) {
      reject({ error });
    }
  }).catch(error => error);

  if (response.error && !response.data) {
    throw new GraphQLError(response);
  }

  return new GraphQLResponse(fromJS(response));
};
