import {
  FC,
  FocusEventHandler,
  MutableRefObject,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';

import useHandleOutsideClicks from '../helpers/useHandleOutsideClicks';
import { stringInserter } from '../helpers/stringInserter';

import { MergeFieldIcon, ScheduleIcon, TemplateIcon } from './BoogieIcon';
import Text from './Text';
import MergeFieldDropDown, {
  DropDownItem,
  StandardDropDownItem,
} from './MergeFieldDropDown';

export type OnSelectionChange = ({
  selectionStart,
  selectionEnd,
}: {
  selectionStart: number;
  selectionEnd: number;
}) => void;

type WysiwygLiteBaseProps = {
  onTemplateClick?: () => void;
  onSchedulerClick?: () => void;
  value: string;
  onChange: (value: string) => void;
  mergeFieldItems?: DropDownItem[];
  onMergeFieldClick?: () => void;
  /** Mainly used to check if a user changed the highlighting */
  onMouseUp?: () => void;
};

type WysiwygLiteProps = WysiwygLiteBaseProps & {
  mergeFieldItems?: DropDownItem[];
};

type WysiwygLiteAltProps = WysiwygLiteBaseProps & {
  onMergeFieldClick?: () => void;
};

type HeaderButtonProps = {
  icon: JSX.Element;
  text: string;
  onClick: () => void;
  disabled?: boolean;
};

const WysiwygLite = forwardRef<
  HTMLTextAreaElement,
  WysiwygLiteProps | WysiwygLiteAltProps
>(
  (
    {
      value,
      onChange,
      mergeFieldItems,
      onTemplateClick,
      onSchedulerClick,
      onMergeFieldClick,
      onMouseUp,
    },
    importedTextAreaRef,
  ) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const backupTextAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const textAreaRef = (importedTextAreaRef ||
      backupTextAreaRef) as MutableRefObject<HTMLTextAreaElement | null>;

    const [showMergeFields, setShowMergeFields] = useState(false);
    // const [showShowLink, setShowShowLink] = useState(false);
    const [selectionStart, setSelectionStart] = useState(0);
    const [selectionEnd, setSelectionEnd] = useState(0);
    const [refocus, setRefocus] = useState(false);

    useEffect(() => {
      if (textAreaRef.current && refocus) {
        textAreaRef.current.focus();
        textAreaRef.current.selectionStart = selectionStart;
        textAreaRef.current.selectionEnd = selectionEnd;
        setRefocus(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, refocus]);

    const HeaderButton: FC<HeaderButtonProps> = ({
      icon,
      text,
      onClick,
      disabled,
    }) => {
      const handleClick = (): void => {
        if (!disabled) onClick();
      };
      return (
        <div
          role="button"
          className={cx(
            'h-full flex items-center px-[6px]  select-none',
            disabled
              ? 'text-neutral/400'
              : 'text-neutral/900 hover:text-clari-blue/700 active:text-clari-blue/800 cursor-pointer',
          )}
          onClick={handleClick}
          tabIndex={0}
          onKeyDown={event => event.key === 'Enter' && handleClick()}
          aria-label={`${text} button`}
        >
          {icon}
          <Text className="text-metadata pl-[4px]">{text}</Text>
        </div>
      );
    };

    const onMergeFieldChange = (item: StandardDropDownItem | null): void => {
      const { newCursorPosition, combinedString } = stringInserter({
        originalString: value,
        newString: `{!${item?.value}}`,
        selectionStart,
        selectionEnd,
      });

      setSelectionStart(newCursorPosition);
      setSelectionEnd(newCursorPosition);
      setRefocus(true);

      onChange(combinedString);
    };

    const onBlur: FocusEventHandler<HTMLInputElement & HTMLTextAreaElement> = (
      event,
    ): void => {
      const { selectionStart, selectionEnd } = event.target;
      setSelectionStart(selectionStart);
      setSelectionEnd(selectionEnd);
    };

    useHandleOutsideClicks(ref, () => {
      setShowMergeFields(false);
    });

    // This memo is to prevent the setting of the selectionStart and the selectionEnd form rerendering the buttons
    // and causing glitch-y openings
    const memoHeader = useMemo(
      () => (
        <div className="flex flex-row h-[35px] border-0 !border-b border-solid border-neutral/200 px-[6px]">
          {onTemplateClick && (
            <HeaderButton
              icon={<TemplateIcon className="h-[16px]" />}
              text="Templates"
              onClick={onTemplateClick}
            />
          )}
          {onSchedulerClick && (
            <HeaderButton
              icon={<ScheduleIcon className="h-[14px]" />}
              text="Scheduler"
              onClick={onSchedulerClick}
            />
          )}

          {/* Commented out since we don't want a generate button for now */}
          {/* <TooltipHost
          content="Coming soon!"
          directionalHint={DirectionalHint.bottomCenter}
        >
          <HeaderButton
            icon={<GenerateIcon className="h-[16px]" />}
            text="Generate"
            onClick={() => setShowMergeFields(!showMergeFields)}
            disabled
          />
        </TooltipHost> */}
          <div className="relative" ref={ref}>
            <HeaderButton
              icon={<MergeFieldIcon className="h-[14px]" />}
              text="Merge fields"
              onClick={() => {
                if (onMergeFieldClick) return onMergeFieldClick();
                return setShowMergeFields(true);
              }}
            />
            {!onMergeFieldClick && mergeFieldItems && (
              <MergeFieldDropDown
                items={mergeFieldItems}
                isOpen={showMergeFields}
                onOpenChange={value => setShowMergeFields(value)}
                onChange={onMergeFieldChange}
              />
            )}
          </div>

          {/* <HeaderButton
            icon={<LinkIcon className="h-[8px]" />}
            text="Link"
            onClick={() => setShowShowLink(!showShowLink)}
          /> */}
        </div>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [showMergeFields],
    );

    return (
      <div className="flex flex-col flex-1 border border-neutral/200 border-solid rounded-[4px] overflow-hidden">
        {memoHeader}
        <textarea
          className="font-groove text-left text-body-sm bg-white background px-[8px] py-[6px] border-none !outline-none focus:ring-0 focus:bg-white resize-none h-full flex-1"
          value={value}
          onChange={e => onChange(e.target.value || '')}
          onBlur={onBlur}
          ref={textAreaRef}
          onMouseUp={onMouseUp}
        />
      </div>
    );
  },
);

export default WysiwygLite;
