import { createActions, keyMirror } from '@groove-labs/action-utils';

export const baseActionTypes = createActions({
  INCOMING_ACTION_COMPOSE: {
    ...keyMirror([
      'LOAD_ACTION_DATA',
      'INSERT_SNIPPET',
      'OPEN_PANE',
      'SET_GROOVE_TOKENS_AS_RESOLVED',
      'LOAD_INSTANT_ACTION',
      'SET_MOBO_USER',
    ]),
  },
});

export const actionTypes = baseActionTypes.INCOMING_ACTION_COMPOSE;
