import { PartialTheme } from '@fluentui/react';

// https://www.figma.com/file/eiLuNTptdzt4wnZk7WCRmM/Groove-Design-System-v2?node-id=0%3A1
export type Theme = {
  /**
   * @name Wavy Navy
   * @description Primary color for the website; Main usage on typography (read only and text links), cta's, and global nav background. AAA compliant.
   */
  primary: string;
  /**
   * @name Wavy Navy 1
   * @description Disabled state. AA compliant with White.
   */
  'primary-dark': string;
  /**
   * @name Wavy Navy 2
   * @description Pressed state. AAA compliant with Wavy Navy to Black.
   */
  'primary-light': string;
  /**
   * @name Wavy Navy 3
   * @description Hover state
   */
  'primary-lighter': string;
  /**
   * @name Surreal Teal
   * @description Alternative text link color. AA compliant with White.
   */
  'primary-dark-alt': string;
  /**
   * @name Groovey Bluey
   * @description Primary accent color for the website; Main usage on primary cta button, branding accents. If overlaying text, only use Wavy Navy for AA compliance.
   */
  secondary: string;
  /**
   * @name Groovey Bluey 1
   * @description Disabled state. AA compliant with White.
   */
  'secondary-light': string;
  /**
   * @name Groovey Bluey 2
   * @description Pressed state. AAA compliant with Wavy Navy to Black.
   */
  'secondary-lighter': string;
  /**
   * @name Groovey Bluey 3
   * @description Hover state.
   */
  'secondary-lighter-alt': string;
  /**
   * @name Groovey Bluey 4
   * @description Alternative Bluey for some icons.
   */
  'secondary-dark': string;
  /**
   * @name Groovey Bluey 5
   * @description Another Alternative Bluey for some icons.
   */
  'secondary-lightest': string;
  /**
   * @name Groovey Bluey 6
   * @description Another Alternative Bluey for some icons.
   */
  'secondary-dark-alt': string;
  /**
   * @name Tangerine Dream
   * @description Secondary accent color for the website.
   */
  tertiary: string;
  /**
   * @name Product Black
   * @description Darkest gray variant for typography. AAA compliant.
   */
  black: string;
  gray0: string;
  gray1: string;
  gray2: string;
  gray3: string;
  gray3_5: string;
  gray4: string;
  gray4_5: string;
  gray5: string;
  gray6: string;
  /**
   * @name Spot on Cotton
   * @description Primary background color.
   */
  background: string;
  /**
   * @description Secondary background color, text.
   */
  white: string;
  severeWarning?: string;
  red?: string;
  redDark?: string;
  redLight?: string;
  redLighter?: string;
  redAlt?: string;
  redAltLight?: string;
  green?: string;
  greenLight?: string;
  blueLight?: string;
  orange?: string;
  purple: string;
  'groove-extended-1': string;
  'groove-extended-2': string;
  'groove-extended-3': string;
  error2: string;
  success4: string;
  warning: string;
  'clari-blue/200': string;
  'clari-blue/500': string;
  'clari-blue/600': string;
  'clari-blue/700': string;
  'clari-blue/800': string;
  'neutral/25-spot-on-cotton': string;
  'neutral/75-gray-day': string;
  'neutral/200': string;
  'neutral/400': string;
  'neutral/600': string;
  'neutral/900': string;
  'turquoise/500': string;
};
export const theme: Theme = {
  primary: '#303030',
  'primary-dark': '#577693',
  'primary-light': '#C6DAEC',
  'primary-lighter': '#E1ECF7',
  'primary-dark-alt': '#0960B2',
  secondary: '#2563eb',
  'secondary-light': '#B8ECED',
  'secondary-lighter': '#E4F8F9',
  'secondary-lighter-alt': '#A4E2E3',
  'secondary-dark': '#03A3BB',
  'secondary-dark-alt': '#007e91',
  'secondary-lightest': '#EEF6F7',
  tertiary: '#FBC122',
  black: '#303030',
  gray0: '#535353',
  gray1: '#757575',
  gray2: '#9E9E9E',
  gray3: '#CACACA',
  gray3_5: '#eeecec',
  gray4: '#F3F2F1',
  gray4_5: '#dfe0e0',
  gray5: '#F8F7F7',
  gray6: '#eeeeee',
  background: '#F8F7F7',
  white: '#FFFFFF',
  severeWarning: '#D83B01',
  red: '#A80000',
  redDark: '#A80000',
  redLight: '#FDE7E9',
  redLighter: '#FFDAD4',
  redAlt: '#EE6352',
  redAltLight: '#F44336',
  green: '#107C10',
  greenLight: '#DFF6DD',
  blueLight: '#b9ebed',
  orange: '#F19539',
  purple: '#553E9B',
  warning: '#fff4ce',
  'groove-extended-1': '#41B3FF',
  'groove-extended-2': '#093D74',
  'groove-extended-3': '#E41E6B',
  error2: '#DD3730',
  success4: '#4CAF50',
  'clari-blue/200': '#67b3ff',
  'clari-blue/500': '#0280ff',
  'clari-blue/600': '#2563eb',
  'clari-blue/700': '#1d4ed8',
  'clari-blue/800': '#1e40af',
  'neutral/25-spot-on-cotton': '#f8f7f7',
  'neutral/75-gray-day': '#eeeeee',
  'neutral/200': '#c4c4c4',
  'neutral/400': '#969696',
  'neutral/600': '#6b6b6b',
  'neutral/900': '#303030',
  'turquoise/500': '#00d7b8',
};

export const paletteNames: Theme = {
  primary: 'Wavy Navy',
  'primary-dark': 'Wavy Navy 1',
  'primary-light': 'Wavy Navy 2',
  'primary-lighter': 'Wavy Navy 3',
  'primary-dark-alt': 'Surreal Teal',
  secondary: 'Groovey Bluey',
  'secondary-light': 'Groovey Bluey 1',
  'secondary-lighter': 'Groovey Bluey 2',
  'secondary-lighter-alt': 'Groovey Bluey 3',
  'secondary-dark': 'Groovey Bluey 4',
  'secondary-dark-alt': 'Groovey Bluey 5',
  'secondary-lightest': 'Groovey Bluey 6',
  tertiary: 'Tangerine Dream',
  black: 'Product Black',
  gray0: 'Gray 0',
  gray1: 'Gray 1',
  gray2: 'Gray 2',
  gray3: 'Gray 3',
  gray4_5: 'Gray 4/5',
  gray3_5: 'Other Gray 4',
  gray4: 'Gray 4',
  gray5: 'Gray 5',
  gray6: 'Gray 6',
  purple: 'Purple',
  background: 'Background',
  white: 'Product White',
  redLighter: 'Error Lighter',
  'groove-extended-1': 'Groove Extended 1',
  'groove-extended-2': 'Groove Extended 2',
  'groove-extended-3': 'Groove Extended 3',
  error2: 'Status Error Light',
  success4: 'Status Success Light',
  warning: 'Warning',
  'clari-blue/200': 'Clari Blue 200',
  'clari-blue/500': 'Clari Blue 500',
  'clari-blue/600': 'Clari Blue 600',
  'clari-blue/700': 'Clari Blue 700',
  'clari-blue/800': 'Clari Blue 800',
  'neutral/25-spot-on-cotton': 'Neutral 25 spot on cotton',
  'neutral/75-gray-day': 'Neutral 75 gray day',
  'neutral/200': 'Neutral 200',
  'neutral/400': 'Neutral 400',
  'neutral/600': 'Neutral 600',
  'neutral/900': 'Neutral 900',
  'turquoise/500': 'Turquoise 500',
};

export const paletteDescriptions = {
  primary:
    "Primary color for the website; Main usage on typography (read only and text links), cta's, and global nav background. AAA compliant.",
  'primary-dark': 'Disabled state. AA compliant with White.',
  'primary-light': 'Pressed state. AAA compliant with Wavy Navy to Black.',
  'primary-lighter': 'Hover state',
  'primary-dark-alt': 'Alternative text link color. AA compliant with White.',
  secondary:
    'Primary accent color for the website; Main usage on primary cta button, branding accents. If overlaying text, only use Wavy Navy for AA compliance.',
  'secondary-light': 'Disabled state. AA compliant with White.',
  'secondary-lighter': 'Pressed state. AAA compliant with Wavy Navy to Black.',
  tertiary: 'Secondary accent color for the website.',
  black: 'Darkest gray variant for typography. AAA compliant.',
  gray1: null,
  gray2: null,
  gray3: null,
  gray4: null,
  background: 'Primary background color',
  white: null,
};

export const fluentTheme: PartialTheme = {
  defaultFontStyle: {
    fontFamily: [
      'MaisonNeue',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      '"Liberation Sans"',
      'sans-serif',
    ].join(','),
    fontWeight: 'regular',
  },
  palette: {
    ...theme,
    themePrimary: '#2563EB',
    themeLighterAlt: '#cfddea',
    themeLighter: '#a7bfd6',
    themeLight: '#84a4c2',
    themeTertiary: '#648baf',
    themeSecondary: '#2563EB',
    themeDarkAlt: '#0960B2',
    themeDark: '#1e4a74',
    themeDarker: '#0f3960',
    black: '#494847',
    white: '#FFF',
    red: '#A80000',
    green: '#107C10',
    greenLight: '#DFF6DD',
    blueLight: '#b9ebed',
    orange: '#F19539',
    purple: '#553E9B',
    neutralPrimaryAlt: '#2563EB',
    neutralLight: '#eeeeee',
    neutralLighter: '#f3f3f3',
    neutralDark: '#757575',
    neutralSecondaryAlt: '#9e9e9e',
    neutralTertiaryAlt: '#cacaca',
    neutralQuaternaryAlt: '#f3f2f1',
  },
  fonts: {
    xSmall: {
      fontSize: 9,
    },
    small: {
      fontSize: 10,
    },
    smallPlus: {
      fontSize: 11,
    },
    medium: {
      fontSize: 12,
    },
    mediumPlus: {
      fontSize: 14,
      fontWeight: 600,
    },
    large: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  components: {
    DefaultButton: {
      styles: {
        root: {
          borderRadius: 4,
          borderColor: theme['neutral/200'],
          transition: 'background-color 0.15s, color 0.15s',
        },
        rootHovered: {
          backgroundColor: '#ffffff',
          borderColor: theme['neutral/900'],
          color: theme['neutral/900'],
        },
        rootPressed: {
          backgroundColor: '#ffffff',
          borderColor: theme['neutral/900'],
          color: theme['neutral/900'],
        },
        rootDisabled: {
          backgroundColor: theme['neutral/75-gray-day'], // neutral/75-gray-day
          border: 'none',
          color: theme['neutral/400'],
        },
      },
    },
    Panel: {
      styles: {
        root: {
          '.ms-PanelAction-close:hover': {
            border: `1px solid ${theme['neutral/900']}`,
            backgroundColor: 'transparent',
            borderRadius: 4,
          },
        },
      },
    },
    Dropdown: {
      styles: {
        root: {
          '.ms-Dropdown-caretDown, .ms-Dropdown-caretDown:hover, .ms-Dropdown:hover .ms-Dropdown-caretDown,  .ms-Dropdown:focus .ms-Dropdown-caretDown':
            {
              color: theme['clari-blue/600'],
            },
          '.ms-Dropdown-title': {
            borderRadius: 4,
            border: `1px solid ${theme['neutral/200']}`,
            color: theme['neutral/900'],
          },
          '.ms-Dropdown-title:hover, .ms-Dropdown-title:focus': {
            border: `1px solid ${theme['neutral/900']}`,
            color: theme['neutral/900'],
          },
          '.ms-Dropdown:hover .ms-Dropdown-title': {
            color: theme['neutral/900'],
          },
        },
      },
    },
    TextField: {
      styles: {
        root: {
          '&.ms-TextField--borderless .ms-TextField-wrapper .ms-TextField-fieldGroup':
            {
              border: 'none',
            },
          '.ms-TextField-fieldGroup': {
            borderRadius: 4,
            border: `1px solid ${theme['neutral/200']}`,
            color: theme['neutral/900'],
          },
          '.ms-TextField-fieldGroup:hover': {
            borderRadius: 4,
            border: `1px solid ${theme['neutral/900']}`,
            color: theme['neutral/900'],
          },
        },
      },
    },
    TagPicker: {
      styles: {
        root: {
          '.ms-TagItem .ms-Button': {
            borderRadius: '0 4px 4px 0',
          },
          '.ms-TagItem:hover .ms-Button, .ms-TagItem .ms-Button:focus, .ms-TagItem .ms-Button:active, .ms-TagItem .ms-Button:hover':
            {
              backgroundColor: theme['clari-blue/600'],
              color: theme.white,
            },
          '.is-selected .ms-TagItem-text': {
            color: theme['neutral/900'],
          },
          '.ms-TagItem:hover': {
            border: `1px solid ${theme['clari-blue/600']}`,
            color: theme['neutral/900'],
            backgroundColor: 'transparent',
          },
          '.ms-TagItem-close:focus::after': {
            border: 'none !important',
            outline: 'none !important',
          },
          '.ms-TagItem': {
            border: `1px solid ${theme['neutral/200']}`,
            borderRadius: 6,
          },
        },
      },
    },
    PrimaryButton: {
      styles: {
        root: {
          background: theme['clari-blue/600'],
          border: 'none',
          borderRadius: 4,
          color: '#ffffff',
          '&.is-disabled': {
            backgroundColor: theme['neutral/75-gray-day'],
            border: 'none',
            color: theme['neutral/400'],
          },
        },
        rootHovered: {
          backgroundColor: theme['clari-blue/700'],
          border: 'none',
          color: '#ffffff',
        },
        rootPressed: {
          backgroundColor: theme['clari-blue/800'],
          border: 'none',
          color: '#ffffff',
        },
        rootDisabled: {
          backgroundColor: theme['neutral/75-gray-day'],
          border: 'none',
          color: theme['neutral/400'],
        },
      },
    },
    Label: {
      styles: {
        root: {
          color: theme['neutral/900'],
        },
      },
    },
    Toggle: {
      styles: {
        pill: {
          '&[aria-checked="true"]:hover': {
            background: theme['clari-blue/700'],
          },
          '&[aria-checked="true"]': {
            background: theme['clari-blue/600'],
          },
        },
      },
    },
    Checkbox: {
      styles: {
        root: {
          '.ms-Checkbox-checkbox': {
            border: `1px solid ${theme['neutral/200']}`,
          },
          '&.is-checked .ms-Checkbox-checkbox': {
            background: theme['clari-blue/600'],
            border: 'none',
          },
          '&.is-checked:hover .ms-Checkbox-checkbox': {
            background: theme['clari-blue/700'],
            border: 'none',
          },
          '.ms-Checkbox-text': {
            color: theme['neutral/900'],
          },
        },
      },
    },
    Icon: {
      styles: {
        root: {
          fontFamily: `FabricMDL2Icons, FabricMDL2Icons-0, FabricMDL2Icons-1, FabricMDL2Icons-2,
            FabricMDL2Icons-3, FabricMDL2Icons-4, FabricMDL2Icons-5, FabricMDL2Icons-6, FabricMDL2Icons-7,
            FabricMDL2Icons-8, FabricMDL2Icons-9,FabricMDL2Icons-10, FabricMDL2Icons-11, FabricMDL2Icons-12,
            FabricMDL2Icons-13, FabricMDL2Icons-14, FabricMDL2Icons-15, FabricMDL2Icons-16, FabricMDL2Icons-17,
            FabricMDL2Icons-18, FabricMDL2Icons-19, FabricMDL2Icons-20, FabricMDL2Icons-21 !important`,
        },
      },
    },
    Layer: {
      styles: {
        content: 'groove',
      },
    },
    ComboBox: {
      styles: {
        root: {
          '& .ms-ComboBox-container:before': {
            borderColor: theme['neutral/200'],
          },
        },
      },
    },
    ChoiceGroup: {
      styles: {
        root: {
          '& .ms-ChoiceField-field:before': {
            borderColor: theme['neutral/200'],
          },
          '& .ms-ChoiceField-field:after': {
            background: theme['clari-blue/600'],
            borderColor: theme['clari-blue/600'],
          },
          '.ms-ChoiceField-field.is-checked:before': {
            borderColor: theme['clari-blue/600'],
          },
          '.ms-ChoiceField-field.is-checked:after': {
            background: theme['clari-blue/600'],
            borderColor: theme['clari-blue/600'],
          },
          '& .ms-ChoiceField-wrapper:hover .ms-ChoiceField-field.is-checked:before':
            {
              borderColor: theme['clari-blue/700'],
            },
          '& .ms-ChoiceField-wrapper:hover .ms-ChoiceField-field.is-checked:after':
            {
              background: theme['clari-blue/700'],
              borderColor: theme['clari-blue/700'],
            },
          '& .ms-ChoiceField-wrapper:hover .ms-ChoiceField-field.:before': {
            background: theme['neutral/900'],
          },
          '& .ms-ChoiceField-wrapper:hover .ms-ChoiceField-field.:after': {
            background: theme['neutral/600'],
            borderColor: theme['neutral/900'],
          },
          '& .ms-ChoiceField-wrapper:hover .ms-ChoiceFieldLabel': {
            color: theme['neutral/900'],
          },
        },
      },
    },
  },
};

export default theme;
