import { GrooveFetchRecord } from '@groove/api/visualforce/types/GrooveFetchRecords';
import grooveFetchRecords from '@groove/api/visualforce/grooveFetchRecords';
import type { SalesforceMeta } from '@groove/api/gateway/v1/salesforce';
import {
  FullAction,
  RecipientType,
  SimpleFieldType,
} from '@groove/api/gateway/v1/actionCompose';
import { CONTACT_PREFIX, LEAD_PREFIX } from '@groove/api/gateway/v1/salesforce';
import { findOrCreatePerson } from '@groove/api/gateway/v1/people';

import useStore from '../store/useStore';

import { setAutoSalesforceWhos } from './loggingToMethods';

import { buildNewActionWhoFromPerson } from './index';

type RecipeintField = 'toRecipients' | 'ccRecipients' | 'bccRecipients';
type ResultRecordType = {
  text: string;
  name: string;
  company: string;
  title: string;
  email: string;
  emailField: string;
  emails: SimpleFieldType[];
  phone: string;
  phoneField: string;
  phones: SimpleFieldType[];
  doNotEmail?: boolean;
  doNotCall?: boolean;
  doNotSms?: boolean;
};

export const syncAllWhoData = async (
  action: FullAction,
  grooveMeta: SalesforceMeta | undefined,
): Promise<void> => {
  if (!action || !grooveMeta) return;

  const leadIds = [];
  const contactIds = [];

  if (action?.who?.sfdcId) {
    if (action?.who?.sfdcId.startsWith(CONTACT_PREFIX))
      contactIds.push(action.who.sfdcId);
    if (action?.who?.sfdcId.startsWith(LEAD_PREFIX))
      leadIds.push(action.who.sfdcId);
  }

  const recipientFields: RecipeintField[] = [
    'toRecipients',
    'ccRecipients',
    'bccRecipients',
  ];
  recipientFields.forEach(type => {
    if (action[type]?.length) {
      action[type]?.forEach((recipient: RecipientType) => {
        const sfdcId =
          recipient.id && recipient.id.toString().startsWith('00')
            ? recipient.id.toString()
            : recipient.sfdcId || '';

        if (sfdcId.startsWith(CONTACT_PREFIX)) contactIds.push(sfdcId);
        if (sfdcId.startsWith(LEAD_PREFIX)) leadIds.push(sfdcId);
      });
    }
  });

  if (contactIds.length) await syncWhoData(contactIds, grooveMeta);
  if (leadIds.length) await syncWhoData(leadIds, grooveMeta);
};

// fetch latest version of person data from salesforce
export const syncWhoData = async (
  sfdcIds: string[],
  grooveMeta: SalesforceMeta | undefined,
): Promise<void> => {
  if (!grooveMeta || !sfdcIds?.length) return;

  let objectType = '';
  if (sfdcIds[0].startsWith(CONTACT_PREFIX)) objectType = 'contact';
  if (sfdcIds[0].startsWith(LEAD_PREFIX)) objectType = 'lead';

  if (objectType === '') return;

  // get all email, phone attributes from groove meta
  const attributes = getEmailPhoneAttributes(objectType, grooveMeta);
  // fetch latest data from salesforce for this record and attributes
  const { data: fetchResult } = await grooveFetchRecords([
    { ids: sfdcIds, attributes },
  ]);

  if (fetchResult)
    sfdcIds.forEach(sfdcId => {
      updateActionData(
        sfdcId,
        transformGrooveFetchResult(sfdcId, fetchResult, grooveMeta),
      );
    });
};

export const syncUnknownWhoData = async (
  personEmail: string,
  personSfdcId: string,
  grooveMeta: SalesforceMeta | undefined,
): Promise<void> => {
  // find a person with same email and attributes.type === Unknown
  // findOrCreate this person in Groove DB
  // update to/bcc/ccRecipients, who object (if primary who)
  // syncWhoData for the new person, and add to loggingTo
  if (!personEmail || !personSfdcId || !grooveMeta) return;

  const recipientFields: RecipeintField[] = [
    'toRecipients',
    'ccRecipients',
    'bccRecipients',
  ];

  const { action } = useStore.getState();
  const person = await findOrCreatePerson(personSfdcId);
  if (!person) return;

  let found = false;
  recipientFields.forEach(async type => {
    if (action[type]?.length) {
      const recipientsList = action[type];
      if (recipientsList?.length) {
        const index = recipientsList.findIndex(
          item =>
            item.attributes?.type === 'Unknown' && item.email === personEmail,
        );
        if (index > -1) {
          found = true;
          const personRecord = {
            ...recipientsList[index],
            email: personEmail,
            sfdcId: personSfdcId,
            name: person.name,
            id: person.id,
            doNotEmail:
              getOptOutValues(person.sfdcId, person.sfdcData).doNotEmail ||
              person.sfdcData?.hasOptedOutOfEmail,
            doNotCall:
              getOptOutValues(person.sfdcId, person.sfdcData).doNotCall ||
              person.sfdcData?.doNotCall,
            doNotSms:
              getOptOutValues(person.sfdcId, person.sfdcData).doNotSms ||
              person.sfdcData?.hasOptedOutOfSms,
            phone: person.phone,
            attributes: person.sfdcData?.attributes,
          };

          useStore.getState().updateAction({
            [type]: [
              ...recipientsList.slice(0, index),
              { ...recipientsList[index], ...personRecord },
              ...recipientsList.slice(index + 1),
            ],
          });

          if (index === 0 && type === 'toRecipients') {
            useStore.getState().updateAction({
              personId: person.id,
              who: buildNewActionWhoFromPerson(person),
            });
          }
        }
      }
    }
  });
  if (found) {
    syncWhoData([personSfdcId], grooveMeta);
    setAutoSalesforceWhos({ addedPerson: person });
  }
};

// search action [who, toRecipients, bcRecipients, ccRecipients]
// and update matched record with data from salesforce
const updateActionData = (sfdcId: string, record: ResultRecordType): void => {
  const { text, ...personRecord } = record;
  const { name, ...crmRecord } = record;

  const { action } = useStore.getState();

  // update action loggingTo
  const loggingToWho = action.loggingTo.who || [];
  const index = loggingToWho.findIndex(record => record.id === sfdcId);
  if (index > -1) {
    useStore.getState().updateCurrentLoggingTo({
      who: [
        ...loggingToWho.slice(0, index),
        { ...loggingToWho[index], ...crmRecord },
        ...loggingToWho.slice(index + 1),
      ],
    });
  }

  // update action recipients
  const { flow } = useStore.getState().action;
  const recipientFields: RecipeintField[] = [
    'toRecipients',
    'ccRecipients',
    'bccRecipients',
  ];
  recipientFields.forEach(field => {
    const recipientsList = action[field];
    if (recipientsList?.length) {
      const index = recipientsList.findIndex(
        record => record.sfdcId === sfdcId || record.id === sfdcId,
      );
      if (index > -1) {
        // if user already selected an email/phone set as default
        const defaultFlowFields = sfdcId.startsWith(CONTACT_PREFIX)
          ? flow?.settings?.contact
          : flow?.settings?.lead;

        // priority based on if value exist or not:
        // 1. emailField saved in recipient object
        // 2. email value exist
        // 3. flow default email field
        // 4. default Email field
        // 5. first email in the list
        const emailFieldFromValue = personRecord.emails.find(
          item => item.value === recipientsList[index].email,
        );
        const emailfields = [
          recipientsList[index].emailField,
          emailFieldFromValue?.field,
          defaultFlowFields?.emailFieldName,
          'Email',
        ];

        for (const field of emailfields) {
          const existRecord = personRecord.emails.find(
            item => item.field === field?.toLowerCase(),
          );
          if (existRecord) {
            personRecord.email = existRecord.value;
            personRecord.emailField = field?.toLowerCase() || '';
            break;
          }
        }

        if (!personRecord.email) {
          personRecord.email = personRecord.emails[0]?.value;
          personRecord.emailField = personRecord.emails[0]?.field;
        }

        const phoneFieldFromValue = personRecord.phones.find(
          item => item.value === recipientsList[index].phone,
        );
        const phonefields = [
          recipientsList[index].phoneField,
          phoneFieldFromValue?.field,
          defaultFlowFields?.phoneFieldName,
          'Phone',
        ];

        for (const field of phonefields) {
          const existRecord = personRecord.phones.find(
            item => item.field === field?.toLowerCase(),
          );
          if (existRecord) {
            personRecord.phone = existRecord.value;
            personRecord.phoneField = field?.toLowerCase() || '';
            break;
          }
        }

        if (!personRecord.phone) {
          personRecord.phone = personRecord.phones[0]?.value;
          personRecord.phoneField = personRecord.phones[0]?.field;
        }

        useStore.getState().updateAction({
          [field]: [
            ...recipientsList.slice(0, index),
            { ...recipientsList[index], ...personRecord },
            ...recipientsList.slice(index + 1),
          ],
        });
      }
    }
  });

  // update action who
  if (action.who && action.who.sfdcId === sfdcId) {
    useStore
      .getState()
      .updateAction({ who: { ...action.who, ...personRecord } });
  }
};

const transformGrooveFetchResult = (
  sfdcId: string,
  fetchResult: GrooveFetchRecord<{ [key: string]: string }>[],
  grooveMetaResult: SalesforceMeta,
): ResultRecordType => {
  const result: ResultRecordType = {
    text: '',
    name: '',
    title: '',
    company: '',
    emailField: '',
    emails: [],
    phoneField: '',
    phones: [],
  };
  if (!fetchResult?.length) return result;

  fetchResult.forEach(record => {
    if (record.id === sfdcId) {
      result.text = record.attributes.Name;
      result.name = record.attributes.Name;
      result.title = record.attributes.Title;
      result.company =
        record.attributes.Account?.Name || record.attributes.Company;
      result.emails = parseAttributes(record, 'email', grooveMetaResult);
      result.phones = parseAttributes(record, 'phone', grooveMetaResult);
      const optoutValues = getOptOutValues(sfdcId, record.attributes);
      if (optoutValues.doNotEmail !== undefined)
        result.doNotEmail = optoutValues.doNotEmail;
      if (optoutValues.doNotCall !== undefined)
        result.doNotCall = optoutValues.doNotCall;
      if (optoutValues.doNotSms !== undefined)
        result.doNotSms = optoutValues.doNotSms;
    }
  });

  return result;
};

const parseAttributes = (
  currentObject: GrooveFetchRecord<{ [key: string]: string }>,
  fieldType: string,
  grooveMetaResult: SalesforceMeta,
): SimpleFieldType[] => {
  const results: SimpleFieldType[] = [];
  const objectType = currentObject.type.toLowerCase();

  Object.keys(currentObject.attributes).forEach(attribute => {
    const metaField =
      grooveMetaResult?.[objectType]?.fields?.[attribute.toLowerCase()];
    if (metaField?.type === fieldType && currentObject.attributes[attribute])
      results.push({
        label: metaField.label,
        field: attribute.toLowerCase(),
        value: currentObject.attributes[attribute],
      });
  });

  return results;
};

const getEmailPhoneAttributes = (
  objectType: string,
  grooveMeta: SalesforceMeta,
): string[] => {
  let attributes = ['name', 'title', 'email', 'phone'];
  const optionalFields = getOptOutFields(objectType);
  const emailsPhones = ['email', 'phone'];

  if (objectType === 'contact') attributes.push('account.name');
  else attributes.push('company');

  if (grooveMeta[objectType]?.fields)
    Object.keys(grooveMeta[objectType]?.fields).forEach(key => {
      const metaField = grooveMeta[objectType]?.fields?.[key];
      if (
        (metaField?.type && emailsPhones.indexOf(metaField?.type) > -1) ||
        optionalFields.indexOf(key) > -1
      ) {
        attributes.push(key);
      }
    });

  attributes = attributes.filter(
    (value, index, self) => self.indexOf(value) === index,
  );

  return attributes;
};

const getOptOutFields = (objectType: string): string[] => {
  const { otherValues } = useStore.getState();
  if (objectType === 'contact')
    return [
      (
        otherValues.contactEmailOptOutField || 'HasOptedOutOfEmail'
      ).toLowerCase(),
      (otherValues.contactCallOptOutField || 'DoNotCall').toLowerCase(),
      (otherValues.contactSmsOptOutField || 'HasOptedOutOfSms').toLowerCase(),
    ];
  if (objectType === 'lead')
    return [
      (otherValues.leadEmailOptOutField || 'HasOptedOutOfEmail').toLowerCase(),
      (otherValues.leadCallOptOutField || 'DoNotCall').toLowerCase(),
      (otherValues.leadSmsOptOutField || 'HasOptedOutOfSms').toLowerCase(),
    ];
  return [];
};

export const getOptOutValues = (
  sfdcId: string,
  attributes: any,
): {
  doNotEmail: boolean | undefined;
  doNotCall: boolean | undefined;
  doNotSms: boolean | undefined;
} => {
  let objectType = null;
  const optoutValues = {
    doNotEmail: undefined,
    doNotCall: undefined,
    doNotSms: undefined,
  };

  if (sfdcId && sfdcId.startsWith(CONTACT_PREFIX)) objectType = 'contact';
  else if (sfdcId && sfdcId.startsWith(LEAD_PREFIX)) objectType = 'lead';

  if (objectType && attributes)
    Object.keys(attributes).forEach(key => {
      if (key.toLowerCase() === getOptOutFields(objectType)[0])
        optoutValues.doNotEmail = attributes[key];
      if (key.toLowerCase() === getOptOutFields(objectType)[1])
        optoutValues.doNotCall = attributes[key];
      if (key.toLowerCase() === getOptOutFields(objectType)[2])
        optoutValues.doNotSms = attributes[key];
    });

  return optoutValues;
};
