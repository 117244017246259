import { fromJS, Set, Map } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/activityLog';
import { EVENT_TYPES } from 'Modules/Shared/constants/activityLog';
import moment from 'moment-timezone';

const initialState = fromJS({
  stats: {
    total: 0,
    failed: 0,
    eventTypes: Map(Object.keys(EVENT_TYPES).map(x => [x, 0])),
  },
  isFetchingLogs: false,
  users: {
    isFetching: false,
    searchResults: [],
    added: new Set(),
  },
  flows: {
    isFetching: false,
    searchResults: [],
    added: new Set(),
  },
  filters: {
    status: new Set(),
    eventTypes: new Set(),
    userIds: new Set(),
    flowIds: new Set(),
    offsetId: null,
    startDate: moment().subtract(7, 'days'),
    endDate: moment(),
  },
  activeMetadata: {},
  logs: [],
  total: 0,
});

export default function activityLog(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.CLEAR_FILTER:
      return state.setIn(['filters', action.payload.type], new Set());

    case actionTypes.ADD_ENTITY: {
      const { entity, type } = action.payload;

      return state.updateIn([type, 'added'], entities => entities.add(entity));
    }

    case actionTypes.SEARCH_USERS.BEGIN:
      return state.setIn(['users', 'isFetching'], true);

    case actionTypes.SEARCH_USERS.SUCCESS:
      return state
        .setIn(['users', 'isFetching'], false)
        .setIn(['users', 'searchResults'], action.payload.users);

    case actionTypes.SEARCH_USERS.FAILURE:
      return state.setIn(['users', 'isFetching'], false);

    case actionTypes.SEARCH_FLOWS.BEGIN:
      return state.setIn(['flows', 'isFetching'], true);

    case actionTypes.SEARCH_FLOWS.SUCCESS:
      return state
        .setIn(['flows', 'isFetching'], false)
        .setIn(['flows', 'searchResults'], action.payload.flows);

    case actionTypes.UPDATE_FILTERS: {
      const filters = action.payload;
      if (!filters) {
        return state.set('filters', initialState.get('filters'));
      }
      return state.mergeIn(['filters'], action.payload);
    }

    case actionTypes.SET_DATE_FILTER: {
      const { type, value } = action.payload;
      return state.setIn(['filters', type], value);
    }

    case actionTypes.SET_LOG_STATS:
      return state.mergeIn(['stats'], action.payload);

    case actionTypes.FETCH_EVENT_LOGS.BEGIN:
      return state.set('isFetchingLogs', true);

    case actionTypes.FETCH_EVENT_LOGS.FAILURE:
      return state.set('isFetchingLogs', false);

    case actionTypes.FETCH_EVENT_LOGS.SUCCESS: {
      const { logs, offsetId } = action.payload;
      const updatedOffsetId = state.getIn(['filters', 'offsetId']) || offsetId;
      return state
        .setIn(['filters', 'offsetId'], updatedOffsetId)
        .set('isFetchingLogs', false)
        .set('logs', logs);
    }

    case actionTypes.SET_ACTIVE_LOG_ID_AND_METADATA:
      return state
        .set('activeLogId', action.payload.id)
        .set('activeMetadata', action.payload.metadata);

    default:
      return state;
  }
}
