import { Record, Map } from 'immutable';
import Person from 'Modules/Actions/records/Person';
import { ACTION_CHILD_ID_KEY_PATH } from 'Modules/ActionCompose/constants/graphql';
import sharedDefaults from './defaults';

const defaults = {
  ...sharedDefaults,
  workspaceSObjectTaskId: null,
  currentValues: new Map({
    body: null,
  }),
};

export default class WorkspaceTaskAction extends Record(defaults) {
  static fromGraphQL(action) {
    const body = action.get('summary');

    return new WorkspaceTaskAction({
      id: action.get('id'),
      workspaceSObjectTaskId: action.getIn(ACTION_CHILD_ID_KEY_PATH),
      source: action.get('source'),
      type: action.get('type'),
      summary: action.get('summary'),
      priority: action.get('priority'),
      ...(action.get('who') && { recipient: Person.from(action.get('who')) }),
      body,
      currentValues: new Map({
        body,
      }),
    });
  }
}
