import { FC, useState } from 'react';
import { List, Text } from '@fluentui/react';
import { Template } from '@groove/api/gateway/v1/template';
import { TemplateFolder } from '@groove/api/services/v1/templateFolders';

import EmptyFavoritesImage from '../icons/empty_favorites.svg';
import useStore from '../store/useStore';

import TemplateListLoader from './TemplateListLoader';
import TemplateFolderList from './TemplateFolderList';
import TemplateFolderItem from './TemplateFolderItem';
import TemplateItem from './TemplateItem';

type Props = {
  onInsert: (template: Template) => void;
  isFetchingFavorites?: boolean;
};

const FavoriteTemplatesList: FC<Props> = ({
  onInsert,
  isFetchingFavorites,
}) => {
  const favoriteTemplates = useStore(store => store.favoriteTemplates);
  const favoriteTemplateFolders = useStore(
    store => store.favoriteTemplateFolders,
  );
  const [favoriteFolderSelected, setFavoriteFolderSelected] = useState<
    TemplateFolder | undefined
  >(undefined);

  if (isFetchingFavorites) {
    return <TemplateListLoader />;
  }

  if (!favoriteTemplates?.length && !favoriteTemplateFolders?.length) {
    return (
      <div className="flex flex-col w-60 h-full p-10 items-center justify-center">
        <Text className="text-xs text-gray2 text-center pb-3">
          You don&#39;t have favorite templates yet. Click the star to add a
          template to your favorites.
        </Text>
        <img src={EmptyFavoritesImage} alt="empty-favorites-img" />
      </div>
    );
  }

  if (favoriteFolderSelected) {
    return (
      <TemplateFolderList
        onInsert={onInsert}
        folder={favoriteFolderSelected}
        setSelectedFolder={setFavoriteFolderSelected}
      />
    );
  }

  return (
    <div className="flex flex-col flex-grow pb-5 overflow-y-auto">
      {favoriteTemplateFolders?.length > 0 && (
        <List
          className="w-full"
          items={favoriteTemplateFolders}
          onRenderCell={folder =>
            folder && (
              <TemplateFolderItem
                folder={folder}
                setSelectedFolder={setFavoriteFolderSelected}
              />
            )
          }
        />
      )}

      {favoriteTemplates?.length > 0 && (
        <List
          className="w-full"
          items={favoriteTemplates}
          onRenderCell={template =>
            template && <TemplateItem template={template} onInsert={onInsert} />
          }
        />
      )}
    </div>
  );
};

export default FavoriteTemplatesList;
