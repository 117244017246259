import keyMirror from 'Utils/key-mirror';

export const actionTypes = keyMirror(
  {
    SET_AUTHORIZATIONS: null,
  },
  'SHARED',
  'SET_AUTHORIZATIONS'
);

export const setAuthorizations = authorizations => {
  return {
    type: actionTypes.SET_AUTHORIZATIONS,
    payload: authorizations,
  };
};
