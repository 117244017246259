export const STANDARD_FONTS = new Set([
  'arial',
  'comic sans ms',
  'comic sans',
  'courier new',
  'georgia',
  'lucida sans unicode',
  'tahoma',
  'times new roman',
  'trebuchet ms',
  'verdana',
]);
export const VIDYARD_PLAYER_WIDTH_LIMIT = 450;

export const seismicStoreKey = 'Groove_WYSISWYG_seismic_tenant';

export enum ErrorType {
  'onClose',
  'other',
  'sessionEnded',
  null,
}

export const REGEX_TO_REMOVE_HEADERS = /<\/?h[^>]*>/g;
