import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import grooveMessageEvent, {
  ApiGrooveMessageEvent,
} from '../visualforce/grooveMessageEvent';
import GrooveMessageEvent from '../visualforce/types/GrooveMessageEvent';
import { isClientConfigured } from '../visualforce/getClient';

const useGrooveMessageEvent = (
  options?:
    | Omit<
        UseQueryOptions<
          GrooveMessageEvent,
          unknown,
          GrooveMessageEvent,
          'grooveMessageEvent'
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
): UseQueryResult<ApiGrooveMessageEvent, unknown> => {
  const settingOptions = { ...options };
  if (!isClientConfigured()) {
    settingOptions.enabled = false;
  }

  return useQuery('grooveMessageEvent', () => grooveMessageEvent(), {
    cacheTime: 5 * 60 * 1000, // grooveMessageEvent shouldn't change often
    staleTime: 5 * 60 * 1000,
    ...settingOptions,
  });
};

export default useGrooveMessageEvent;
