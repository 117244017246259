import { Record } from 'immutable';

// TODO: @jaebradley populate additional fields when A/B testing work progresses

const Template = Record({
  id: null,
  templateFolderId: null,
  name: '',
  subject: '',
  htmlBody: '',
  templateType: '',
});

export default Template;
