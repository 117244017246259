import { select } from 'redux-saga/effects';
import { location as locationSelector } from 'Modules/Shared/selectors/location';
import { ROUTES } from 'Routing/routesSaga';

function* sagaErrorHandler({
  error,
  routeSagaErrorHandlerName,
  defaultSagaErrorHandler,
}) {
  const location = yield select(locationSelector);
  const routeName = location.get('routeName');
  const route = ROUTES.find(route => route.routeName === routeName);

  if (route && route[routeSagaErrorHandlerName]) {
    try {
      yield* route[routeSagaErrorHandlerName](error);
    } catch (e) {
      yield* defaultSagaErrorHandler({ error: e, location });
    }
  } else {
    yield* defaultSagaErrorHandler({ error, location });
  }
}

export default sagaErrorHandler;
