import { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import useUserMergeFields from '@groove/api/hooks/useUserMergeFields';
import Input from '@groove/ui/Components/Input';
import FuzzySearch from 'fuzzy-search';
import Text from '@groove/ui/Components/Text';

import useStore from '../store/useStore';

export const MergeModal: FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const mergingField = useStore(store => store.otherValues.mergingField);

  const mergeFields = useUserMergeFields();

  const filteredMergeFields = mergeFields.map(section => ({
    ...section,
    options: new FuzzySearch(section.options, ['name', 'label'], {
      caseSensitive: false,
    }).search(searchTerm),
  }));

  useEffect(() => {
    useStore.getState().setOtherValues({ mergingField: '' });
  }, []);

  const handleItemClick = (value: string): void => {
    useStore.getState().setOtherValues({ mergingField: value });
  };

  return (
    <>
      <Input
        className="w-full mb-[4px] h-[30px]"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value || '')}
      />
      <div className="h-[400px] w-[350px] overflow-scroll">
        {filteredMergeFields.map(section => (
          <div key={`${section.label}-container`}>
            {section.options.length > 0 && (
              <div
                className="w-full overflow-hidden h-[32px] flex items-center"
                key={section.label}
              >
                <Text className="px-[8px] text-metadata font-semibold">
                  {section.label}
                </Text>
              </div>
            )}
            {section.options.map(mergeField => (
              <div
                aria-label={`${mergeField.label} item`}
                role="button"
                className={cx(
                  'flex text-left w-full items-center overflow-hidden cursor-pointer hover:bg-neutral/50 active:bg-neutral/100 h-[32px]',
                  mergingField === mergeField.name
                    ? 'bg-neutral/100'
                    : 'bg-white',
                )}
                key={mergeField.name}
                onClick={() => handleItemClick(mergeField.name)}
                onKeyDown={event =>
                  event.key === 'Enter' && handleItemClick(mergeField.name)
                }
                tabIndex={0}
              >
                <Text className="my-auto text-body-sm whitespace-nowrap px-[8px]">
                  {mergeField.label}
                </Text>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

const handleInsertMergeField = (): void => {
  useStore.getState().insertAtCursorAndUpdateAction({
    body: `{!${useStore.getState().otherValues.mergingField}}`,
  });
};

const openMergeModal = (): void => {
  useStore.getState().setNewModal({
    isOpen: true,
    confirmText: 'Insert',
    onConfirm: handleInsertMergeField,
    content: <MergeModal />,
  });
};

export default openMergeModal;
