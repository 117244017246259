import { Theme } from '@material-ui/core';

import { IProps } from '../vendor/tinymce-react/components/Editor';
import Configuration from '../Configuration';

import createToolbar from './createToolbar';
import plugins from './plugins';
import {
  FONT_FORMATS,
  FONT_SIZE_FORMATS,
  OUTLOOK_FONT_SIZE_FORMATS,
} from './fonts';

let props: IProps;

const getConfig = (theme: Theme, isOutook: boolean): typeof props.init => ({
  plugins: plugins.join(' '),

  branding: false,
  contextmenu: false,
  convert_urls: false,
  relative_urls: false,

  images_upload_credentials: true,
  image_title: true,
  automatic_uploads: true,
  imagetools_cors_hosts: ['amazonaws.com'],
  imagetools_credentials_hosts: ['amazonaws.com'],
  images_upload_handler: (blobInfo, success, failure) => {
    const formData = new FormData();
    formData.append('file', blobInfo.blob());

    Configuration.client
      .post('api/v2/uploads/image', { body: formData })
      .json()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((result: any) => {
        if (result.location) {
          success(result.location);
        }
      })
      .catch(e => {
        failure(e.message);
      });
  },

  file_picker_types: 'image',
  /* and here's our custom image picker */
  file_picker_callback(cb) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    /*
      Note: In modern browsers input[type="file"] is functional without
      even adding it to the DOM, but that might not be the case in some older
      or quirky browsers like IE, so you might want to add it to the DOM
      just in case, and visually hide it. And do not forget do remove it
      once you do not need it anymore.
    */

    input.onchange = function handler(event) {
      const file = (event.target as HTMLInputElement).files?.[0];

      const reader = new FileReader();
      if (file) {
        reader.onload = function handler() {
          const formData = new FormData();
          formData.append('file', file);

          Configuration.client
            .post('api/v2/uploads/image', { body: formData })
            .json()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((result: any) => {
              if (result.location) {
                cb(result.location, { title: file.name });
              }
            });
        };
        reader.readAsDataURL(file);
      }
    };

    input.click();
  },

  paste_data_images: true,

  browser_spellcheck: true,

  menubar: false,
  statusbar: false,
  toolbar: createToolbar(),
  toolbar_items_size: 'small',

  font_formats: FONT_FORMATS.join(';'),
  fontsize_formats: isOutook
    ? OUTLOOK_FONT_SIZE_FORMATS.join(' ')
    : FONT_SIZE_FORMATS.join(' '),

  height: 300,

  content_css: 'https://assets.grooveapp.com/skins/content/default/content.css',
  skin_url: 'https://assets.grooveapp.com/skins/ui/oxide',
  icons_url: 'https://assets.grooveapp.com/icons/default/icons.js',

  // Tiny uses a p tag by default for every new line,
  // this prevents that from happening
  forced_root_block: 'div',

  content_style: `
    a {
      text-decoration: none;
      color: ${theme.palette.primary.main};
    }

    body {
      font-family: Arial,sans-serif;
      font-size: 12px;
    }
  `,

  // the paste plugin's default behavior includes
  // a whole bunch of strange preprocessing that
  // doesn't work for us, so these flags disable them.
  powerpaste_html_import: 'merge',
  paste_enable_default_filters: false,
  paste_remove_styles_if_webkit: false,
});

export default getConfig;
