import { createActions, keyMirror } from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SPACES_SHOW_CUSTOM_COLUMNS: {
    ...keyMirror([
      'UPDATE_CUSTOM_COLUMNS',
      'CUSTOM_COLUMNS_LOADED',
      'DELETE_CUSTOM_COLUMNS',
      'CREATE_CUSTOM_COLUMN',
      'COLUMN_HEADER_CHANGE',
      'SET_EDITING_USER_FOR_COLUMN',
      'SET_WORKSPACE_DOCUMENT_DATA',
      'UPDATE_ACCOUNTS',
      'SET_CELL_EDITING_STATUS',
      'REMOVE_ACCOUNTS',
      'CHANGE_SINGLE_SELECT_OPTION',
      'SORT_CUSTOM_COLUMN',
      'SINGLE_SELECT_OPTION_LABEL_CHANGE',
      'SINGLE_SELECT_OPTION_SET_EDITING_USER',
      'SINGLE_SELECT_RADIO_SELECT',
      'SINGLE_SELECT_DELETE_OPTION',
      'SINGLE_SELECT_CREATE_OPTION',
      'TOGGLE_FIRESTORE_UPSTART_STATUS',
      'REMOVE_CUSTOM_COLUMNS',
      'REMOVE_CUSTOM_COLUMNS_CONFIRMATION',
      'REMOVE_CUSTOM_COLUMNS_CANCELLATION',
      'UPDATE_FIRESTORE_COLUMN_ORDER',
      'UPDATE_FIRESTORE_CELL_VALUE',
      'UPDATE_FIRESTORE_EDITING_USER',
      'UPDATE_FIRESTORE',
    ]),
  },
}).SPACES_SHOW_CUSTOM_COLUMNS;

// --------------GENERAL ACTIONS------------------

export function toggleFirestoreUpstartStatus({ category }) {
  return {
    type: actionTypes.TOGGLE_FIRESTORE_UPSTART_STATUS,
    payload: { category },
  };
}

// --------------FIRESTORE --> REDUCER ACTIONS------------------

export function updateCustomColumns({ columns }) {
  return {
    type: actionTypes.UPDATE_CUSTOM_COLUMNS,
    payload: { columns },
  };
}

export function customColumnsLoaded() {
  return {
    type: actionTypes.CUSTOM_COLUMNS_LOADED,
  };
}

export function deleteCustomColumns({ columnIds }) {
  return {
    type: actionTypes.DELETE_CUSTOM_COLUMNS,
    payload: { columnIds },
  };
}

export function setWorkspaceDocumentData({ workspace }) {
  return {
    type: actionTypes.SET_WORKSPACE_DOCUMENT_DATA,
    payload: { workspace },
  };
}

export function updateAccounts({ accounts }) {
  return {
    type: actionTypes.UPDATE_ACCOUNTS,
    payload: { accounts },
  };
}

export function setCellEditingStatus({ accountId, columnId, editingUserId }) {
  return {
    type: actionTypes.SET_CELL_EDITING_STATUS,
    payload: { accountId, columnId, editingUserId },
  };
}

export function removeAccounts({ accountIds }) {
  return {
    type: actionTypes.REMOVE_ACCOUNTS,
    payload: { accountIds },
  };
}

export function sortCustomColumn({ columnId, orderDirection }) {
  return {
    type: actionTypes.SORT_CUSTOM_COLUMN,
    payload: { columnId, orderDirection },
  };
}

export function updateFirestoreColumnOrder({
  missingIds,
  extraneousIds,
  visibleIds,
}) {
  return {
    type: actionTypes.UPDATE_FIRESTORE_COLUMN_ORDER,
    payload: {
      missingIds,
      extraneousIds,
      visibleIds,
    },
  };
}

// --------------FEBES --> FIRESTORE ACTIONS------------------

export function createCustomColumn({ type, indexPosition }) {
  return {
    type: actionTypes.CREATE_CUSTOM_COLUMN,
    payload: { type, indexPosition },
  };
}

export function removeCustomColumn(id) {
  return {
    type: actionTypes.REMOVE_CUSTOM_COLUMNS,
    payload: id,
  };
}

export function removeCustomColumnConfirmation() {
  return {
    type: actionTypes.REMOVE_CUSTOM_COLUMNS_CONFIRMATION,
  };
}

export function removeCustomColumnCancellation() {
  return {
    type: actionTypes.REMOVE_CUSTOM_COLUMNS_CANCELLATION,
  };
}

export function columnHeaderChange({ value, id }) {
  return {
    type: actionTypes.COLUMN_HEADER_CHANGE,
    payload: { value, id },
  };
}

export function setEditingUserForColumn({ userId, columnId }) {
  return {
    type: actionTypes.SET_EDITING_USER_FOR_COLUMN,
    payload: { userId, columnId },
  };
}

export function singleSelectOptionLabelChange({ value, columnId, optionId }) {
  return {
    type: actionTypes.SINGLE_SELECT_OPTION_LABEL_CHANGE,
    payload: {
      value,
      columnId,
      optionId,
    },
  };
}

export function singleSelectOptionSetEditingUser({ columnId, optionId }) {
  return {
    type: actionTypes.SINGLE_SELECT_OPTION_SET_EDITING_USER,
    payload: {
      columnId,
      optionId,
    },
  };
}

export function singleSelectRadioSelect({ rowId, columnId, optionId }) {
  return {
    type: actionTypes.SINGLE_SELECT_RADIO_SELECT,
    payload: {
      rowId,
      columnId,
      optionId,
    },
  };
}

export function singleSelectDeleteOption({ columnId, optionId }) {
  return {
    type: actionTypes.SINGLE_SELECT_DELETE_OPTION,
    payload: {
      columnId,
      optionId,
    },
  };
}

export function singleSelectCreateOption({ columnId, label }) {
  return {
    type: actionTypes.SINGLE_SELECT_CREATE_OPTION,
    payload: { columnId, label },
  };
}

export function updateFirestoreCellValue({ accountId, columnId, value }) {
  return {
    type: actionTypes.UPDATE_FIRESTORE_CELL_VALUE,
    payload: { accountId, columnId, value },
  };
}

export function updateFirestoreEditingUser({ accountId, columnId, userId }) {
  return {
    type: actionTypes.UPDATE_FIRESTORE_EDITING_USER,
    payload: { accountId, columnId, userId },
  };
}

export function updateFirestore({ accountId, columnId, properties }) {
  return {
    type: actionTypes.UPDATE_FIRESTORE,
    payload: { accountId, columnId, properties },
  };
}
