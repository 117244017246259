import { createSelector } from 'reselect';
import { getLdClient } from 'Utils/ldClient';
import { SEND_PARTIAL_WIDGET_ID } from 'Modules/WorkStep/constants';

export const dialerMinutesQuota = state => {
  return state.getIn(['productUsage', 'dialer', 'minutes', 'quota']);
};

export const dialerMinutesUsed = state => {
  return state.getIn(['productUsage', 'dialer', 'minutes', 'used']);
};

export const flowActionsQuota = state => {
  return state.getIn(['productUsage', 'flow', 'actions', 'quota']);
};

export const flowActionsUsed = state => {
  return state.getIn(['productUsage', 'flow', 'actions', 'used']);
};

export const flowActionsResetDate = state => {
  return state.getIn(['productUsage', 'flow', 'actions', 'resetDate']);
};

export const flowQuotaResetInterval = state => {
  return state.getIn(['productUsage', 'flow', 'actions', 'resetInterval']);
};

export const flowActionsLimitReached = state => {
  const flowActionsLimitEnabled =
    getLdClient()?.variation('flow-actions-limit');
  const actionsData = state.getIn(['productUsage', 'flow', 'actions']);
  const actionsUsed = actionsData.get('used');
  const actionsQuota = actionsData.get('quota');
  const isUnlimited = actionsQuota === -1;
  return flowActionsLimitEnabled && !isUnlimited && actionsUsed >= actionsQuota;
};

export const insufficientFlowsForSendAll = state => {
  const flowActionsLimitEnabled = getLdClient().variation('flow-actions-limit');
  const people = state.getIn(['workStep', 'people'], new Map());
  const sendPartialEnabled = state.getIn([
    'ui',
    SEND_PARTIAL_WIDGET_ID,
    'enabled',
  ]);
  const partialSendSize = state.getIn(['workStep', 'partialSendSize']);
  const actionsData = state.getIn(['productUsage', 'flow', 'actions']);
  const actionsUsed = actionsData.get('used');
  const actionsQuota = actionsData.get('quota');
  const isUnlimited = actionsQuota === -1;
  const peopleToSendSize = sendPartialEnabled ? partialSendSize : people.size;
  return (
    flowActionsLimitEnabled &&
    !isUnlimited &&
    peopleToSendSize > actionsQuota - actionsUsed
  );
};

const flowActionsData = state =>
  state.getIn(['productUsage', 'flow', 'actions']);

// Does the User have a Flow Unlimited license?
export const makeGetFlowActionsUnlimited = () => {
  return createSelector([flowActionsData], data => {
    return !data ? false : data.get('quota') === -1;
  });
};
