import { FC } from 'react';
import { MuteIcon } from '@groove/ui/Components/BoogieIcon';
import useDialerStore from '@groove/dialer-components/hooks/useStore';

import useStore from '../../store/useStore';

import DialerBarButton from './DialerBarButton';

const MuteButton: FC = () => {
  const isMuted = useStore(store => store.dialerState.isMuted);
  const isBridgeCallingEnabled = useDialerStore(
    store => store.isBridgeCallingEnabled,
  );
  const getActiveConnection = useDialerStore(
    store => store.getActiveConnection,
  );
  const callState = useDialerStore(store => store.callState);

  const isMuteDisabled =
    callState === 'not-connected' || isBridgeCallingEnabled;
  let muteTooltip = 'Please start a call.';

  if (isBridgeCallingEnabled) muteTooltip = 'Bridge Dial is currently enabled.';

  if (!isMuteDisabled) {
    if (isMuted) muteTooltip = 'Click to unmute the call.';
    else muteTooltip = 'Click to mute the call.';
  }

  const onMuteClick = (): void => {
    useStore.getState().updateDialerState({ isMuted: !isMuted });
    getActiveConnection()?.mute(!isMuted);
  };

  return (
    <DialerBarButton
      label="Mute"
      onClick={onMuteClick}
      selected={isMuted}
      tooltip={muteTooltip}
      disabled={isMuteDisabled}
    >
      <MuteIcon className="w-[20px]" />
    </DialerBarButton>
  );
};

export default MuteButton;
