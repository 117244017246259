import { call, select } from 'redux-saga/effects';
import sendPostMessage from 'Modules/Shared/utilities/sendPostMessage';
import { getSearchParams } from 'Modules/Shared/selectors/location';
import { INCOMING_POST_MESSAGE_TARGET as ACTION_COMPOSE_SOURCE } from 'Modules/ActionCompose/constants';
import { determineTarget } from 'Modules/ActionCompose/utils';

function* sendOutgoingAction({ type, payload = {} }) {
  const searchParams = yield select(getSearchParams);
  const targetOrigin = searchParams.get('origin');
  const target = determineTarget(targetOrigin);

  yield call(sendPostMessage, {
    source: ACTION_COMPOSE_SOURCE,
    type,
    payload,
    target,
    targetOrigin,
  });
}

export default sendOutgoingAction;
