import keyMirror from 'Utils/key-mirror';

export const actionTypes = keyMirror(
  {
    UPSTART: null,
    TEARDOWN: null,
    LOAD_DATA: null,
    ADD_CURRENT_ACTOR: null,
    REMOVE_CURRENT_ACTOR: null,
    UPDATE_CURRENT_ACTOR: null,
    SAVE_CHANGES: null,
    SET_LOADING: null,
    UPDATE_SUCCESSFUL: null,
  },
  'SHARING_DIALOG'
);

export function triggerSharingDialogUpstart({
  entityType,
  entityId,
  isOpenUiKeyPath,
  isMasterFlow,
}) {
  return {
    type: actionTypes.UPSTART,
    payload: { entityType, entityId, isOpenUiKeyPath, isMasterFlow },
  };
}

export function triggerSharingDialogTeardown() {
  return {
    type: actionTypes.TEARDOWN,
  };
}

export function loadData({
  isOpenUiKeyPath,
  entityType,
  entityId,
  scopes,
  currentActors,
  availableActors,
  isMasterFlow,
}) {
  return {
    type: actionTypes.LOAD_DATA,
    payload: {
      isOpenUiKeyPath,
      entityType,
      entityId,
      scopes,
      currentActors,
      availableActors,
      isMasterFlow,
    },
  };
}

export function addCurrentActor(actor) {
  return {
    type: actionTypes.ADD_CURRENT_ACTOR,
    payload: actor,
  };
}

export function removeCurrentActor(actor) {
  return {
    type: actionTypes.REMOVE_CURRENT_ACTOR,
    payload: actor,
  };
}

export function updateCurrentActor({ actorType, actorId, scopes }) {
  return {
    type: actionTypes.UPDATE_CURRENT_ACTOR,
    payload: { actorType, actorId, scopes },
  };
}

export function saveChanges() {
  return {
    type: actionTypes.SAVE_CHANGES,
  };
}

export function setLoading(value) {
  return {
    type: actionTypes.SET_LOADING,
    payload: value,
  };
}

export function updateSuccessful({ entityId, entityType }) {
  return {
    type: actionTypes.UPDATE_SUCCESSFUL,
    payload: { entityType, entityId },
  };
}
