import { fromJS } from 'immutable';

const initialState = fromJS({});

export default function error(state = initialState, action = {}) {
  switch (action.type) {
    case '':
    default:
      return state;
  }
}
