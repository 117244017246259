import ky, { Options } from 'ky';

class Configuration {
  baseURL: string | undefined;

  client!: typeof ky;

  configure(baseURL: string, options: Options = {}) {
    this.baseURL = baseURL;
    this.client = ky.create({
      credentials: 'include',
      prefixUrl: `${this.baseURL}/`,
      timeout: false,
      ...options,
    });
  }
}

export default new Configuration();
