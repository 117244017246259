import create from 'zustand';
import {
  LDClient,
  LDUser,
  initialize as ldInitialize,
} from 'launchdarkly-js-client-sdk';

export type Store = {
  ldClient?: LDClient;
  initializeLDClient: (data: LDUser) => void;
  initializeLdClientAsync: (data: LDUser) => Promise<void>;
};

const useStore = create<Store>((set, get) => ({
  ldClient: undefined,
  initializeLDClient: (data: LDUser) => {
    const key =
      process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID || '5e8539599e80da08315d4440';
    const ldClient = ldInitialize(key, data);
    ldClient.on('initialized', () => {
      set({ ldClient });
    });
  },
  initializeLdClientAsync: async (data: LDUser) => {
    const { ldClient: existingLdClient } = get();
    if (existingLdClient) {
      return;
    }
    const key =
      process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID || '5e8539599e80da08315d4440';
    const ldClient = ldInitialize(key, data);
    await ldClient.waitForInitialization();
    set({ ldClient });
  },
}));

export default useStore;
