import { FC } from 'react';

const EditIcon: FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.733 4.56a1.914 1.914 0 0 1 2.707 2.708l-.733.734-2.708-2.708.734-.733zm-1.44 1.441-5.955 5.954a1.65 1.65 0 0 0-.398.644l-.914 2.743a.5.5 0 0 0 .632.633l2.743-.915c.243-.08.463-.217.644-.398L14 8.709 11.292 6z"
      fill="currentColor"
    />
  </svg>
);

export default EditIcon;
