import { fromJS } from 'immutable';
import { TABS } from 'Modules/PeopleImportDialog/constants';
import { combineReducers } from 'redux-immutable';
import { actionTypes as flowsActionTypes } from 'Modules/Flows/actions';

import { actionTypes } from './actions';
import advancedSearchRootReducer from './submodules/advancedSearch/reducer';
import basicSearchRootReducer from './submodules/basicSearch/reducer';
import listViewRootReducer from './submodules/listView/reducer';
import peopleTableRootReducer from './submodules/peopleTable/reducer';
import reportsRootReducer from './submodules/reports/reducer';
import csvLookupRootReducer from './submodules/csvLookup/reducer';

const initialState = fromJS({
  flowId: null,
  isOpenUiKeyPath: null,
  loading: false,
  searching: false,
  submitting: false,
  failed: false,
  peopleInFlow: [],
  activeTab: TABS.search,
  isDrawerOpen: false,
  noResultsFound: false,
  isFetchingImportOnBehalfOfUsers: false,
  assignableUsers: [],
});

function peopleImportDialog(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.UPSTART: {
      const { flowId, isOpenUiKeyPath } = action.payload;
      return state
        .set('flowId', flowId)
        .set('isOpenUiKeyPath', isOpenUiKeyPath);
    }

    case actionTypes.LOAD_DATA: {
      return state.merge(action.payload);
    }

    case flowsActionTypes.RESET_REDUCER:
    case actionTypes.RESET: {
      return initialState;
    }

    case actionTypes.SET_SALESFORCE_OBJECT: {
      return state.set('salesforceObject', action.payload);
    }

    case actionTypes.UNSET_SALESFORCE_OBJECT: {
      return state.set('salesforceObject', null);
    }

    case actionTypes.SELECTED_OBJECT_VALUE: {
      return state.set('selectedObjectValue', action.payload);
    }

    case actionTypes.SUBMIT.BEGIN: {
      return state.set('submitting', true).set('failed', false);
    }

    case actionTypes.SUBMIT.SUCCESS: {
      return state.set('submitting', false).set('failed', false);
    }

    case actionTypes.SUBMIT.FAILURE: {
      return state.set('submitting', false).set('failed', true);
    }

    case actionTypes.CSV_SUBMIT.BEGIN: {
      return state.set('submitting', false);
    }

    case actionTypes.SET_ACTIVE_TAB: {
      return state.set('activeTab', action.payload);
    }

    case actionTypes.SET_SEARCHING: {
      return state.set('searching', action.payload);
    }

    case actionTypes.OPEN_DRAWER: {
      return state.set('isDrawerOpen', true);
    }

    case actionTypes.CLOSE_DRAWER: {
      return state.set('isDrawerOpen', false);
    }

    case actionTypes.NO_RESULTS_FOUND: {
      return state.set('noResultsFound', action.payload);
    }

    case actionTypes.FETCH_IMPORT_ON_BEHALF_OF_USERS.BEGIN: {
      return state.set('isFetchingImportOnBehalfOfUsers', true);
    }

    case actionTypes.FETCH_IMPORT_ON_BEHALF_OF_USERS.SUCCESS: {
      return state
        .set('assignableUsers', action.payload)
        .set('isFetchingImportOnBehalfOfUsers', false);
    }

    default:
      return state;
  }
}

export default combineReducers({
  root: peopleImportDialog,
  peopleTable: peopleTableRootReducer,
  basicSearch: basicSearchRootReducer,
  advancedSearch: advancedSearchRootReducer,
  listView: listViewRootReducer,
  reports: reportsRootReducer,
  csvLookup: csvLookupRootReducer,
});
