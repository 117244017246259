import { camelizeKeys } from 'humps';

import makeHTTPRequest from '../makeHTTPRequest';

export const show = () =>
  makeHTTPRequest({
    path: '/api/v2/activity_settings',
    transformResponse: data =>
      camelizeKeys(JSON.parse(data), (key, convert) => {
        // keeps when a key name includes `__` - it's a custom cutomer field
        return /__/.test(key) ? key : convert(key);
      }),
  });
