import React, { FC, useCallback } from 'react';
import { MenuItem } from '@material-ui/core';

import { MergeField } from '.';

interface MergeFieldMenuItemProps {
  mergeField: MergeField;
  onSelect: (mergeField: MergeField) => void;
}

const MergeFieldMenuItem: FC<MergeFieldMenuItemProps> = ({
  mergeField,
  onSelect,
}) => {
  const handleClick = useCallback(() => {
    onSelect(mergeField);
  }, [mergeField, onSelect]);

  return (
    <MenuItem
      value={mergeField.name}
      data-testid={mergeField.name}
      onClick={handleClick}
    >
      {mergeField.label}
    </MenuItem>
  );
};

export default MergeFieldMenuItem;
