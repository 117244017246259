import { safelyLogEvent } from 'Modules/Shared/sagas/analytics';

function trackHttpError({ currentUser, statusCode, routeName }) {
  safelyLogEvent({
    currentUser,
    eventName: 'Http Error Encountered',
    properties: {
      featureCategory: 'error',
      statusCode,
      routeName,
    },
  });
}

export default trackHttpError;
