import { fromJS } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/featureFlags';

export default function ui(state = fromJS({}), action = {}) {
  switch (action.type) {
    case actionTypes.SET_FEATURE_FLAGS: {
      return state.merge(fromJS(action.payload));
    }

    default:
      return state;
  }
}
