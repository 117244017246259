import querystring from 'querystring';
import { put } from 'redux-saga/effects';
import { fromJS, Map } from 'immutable';
import { setSearchParams } from 'Modules/Shared/actions/location';
import { pushHistoryState } from 'Utils/history';

export default function* handleSettingQueryParameter({
  key,
  value,
  clearAllOtherParameters = false,
  extraQueryParameters,
}) {
  let searchParams = new Map();

  if (!clearAllOtherParameters) {
    searchParams = fromJS(
      querystring.parse(new URLSearchParams(window.location.search).toString())
    );
  }
  let newSearchParams = searchParams;

  if (value) {
    newSearchParams = searchParams.set(key, value);
  }

  if (extraQueryParameters) {
    newSearchParams = newSearchParams.merge(extraQueryParameters);
  }
  const qs = querystring.stringify(newSearchParams.toJS());

  const fullPath = window.location.pathname;
  const path = `${fullPath}?${qs}`;
  yield put(setSearchParams({ searchParams: newSearchParams }));
  pushHistoryState(`${window.location.origin}${path}`);
}
