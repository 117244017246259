import getClient from './getClient';

export type ResponseTimeSlot = {
  /**
   * The start time of the slot in ISO format
   *
   * @example
   * 2021-01-01T00:00:00.000Z
   */
  starts_at: string;

  /**
   * The send time of the slot in ISO format
   *
   * @example
   * 2021-01-01T00:30:00.000Z
   */
  ends_at: string;

  /**
   * The unique identifier for the time slot
   */
  public_id: string;
};

export type MeetingType = {
  created_at: string;
  custom_link: string;
  description: string | null;
  duration: number;
  enabled: boolean;
  id: number;
  location: string | null;
  max_days_ahead: string | null;
  min_hours_ahead: string | null;
  name: string;
  subject: string | null;
  updated_at: string;
  video_conferencing: string;
};

export type ApiMeetingTypes = {
  data: MeetingType[];
};

export type ApiScheduler = {
  data: {
    time_zone: string;
  };
};

export type SchedulerUpdateParams = {
  variables: {
    time_zone?: string;
  };
};

export type ApiScheduleFor = {
  data: {
    id: string;
    name: string;
    email: string;
    meeting_custom_link: string;
    type: 'user' | 'group';
  }[];
};

export type Event = {
  id: string;
  user_id: number;
  start: string;
  end: string;
  i_cal_uid: string;
  title: string;
  is_all_day: boolean;
  is_greater_than_24_hours: boolean;
  transparency: 'transparent' | 'opaque';
  visibility: string | null;
};

export type ApiEvents = {
  data: Event[];
};

export const schedulerGet = (): Promise<ApiScheduler> => {
  return getClient().post('scheduler/get').json();
};

export const schedulerUpdate = ({
  variables,
}: SchedulerUpdateParams): Promise<ApiScheduler> => {
  return getClient()
    .post('scheduler/update', {
      json: {
        variables,
      },
    })
    .json();
};

export const scheduleFor = (): Promise<ApiScheduleFor> => {
  return getClient().post('scheduler/schedule_for').json();
};

export const schedulerEvents = (
  week: number,
  year: number,
  user_ids: number[],
): Promise<ApiEvents> => {
  return getClient()
    .post('scheduler/events', {
      json: { variables: { week, year, user_ids } },
    })
    .json();
};

/**
 * Get the MeetingRequestTemplate data for a given user
 *
 * @param userId - the id of the user
 * @param mergeFieldEmailAddress - the primary recipient for the scheduler.
 *   when `requestMergeFields` is true, the api will try to merge any merge
 *   fields in that template to sfdc records for that email address
 * @param requestMergeFields - a flag to know if the api should try to merge
 *   fields or not. When false, it will return the raw template data.
 *
 * @note when the mergeFieldEmailAddress is falsey but requestMergeFields is
 *   true, the api will use fallback values.
 */
export const meetingTypes = (
  user_id: number,
  merge_field_email_address = '',
  request_merge_fields = true,
): Promise<ApiMeetingTypes> => {
  return getClient()
    .post('scheduler/meeting_types', {
      json: {
        variables: { user_id, merge_field_email_address, request_merge_fields },
      },
    })
    .json();
};
