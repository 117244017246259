import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@groove-labs/groove-ui';
import { Error as ErrorIcon } from '@material-ui/icons';

const iconStyle = {
  width: '21px',
  height: '21px',
};

const FlowLimitTooltip = ({ buttonText, limitReached, isSendAll }) => {
  const toolTipPrompt = isSendAll
    ? 'Sending All will exceed your flow actions limit. You can still execute individual actions until you’ve hit your limit. Please contact your admin to upgrade for more actions.'
    : 'Your flow action limit has been reached. Please contact your admin with any questions.';
  return limitReached ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '5px',
      }}
    >
      <Tooltip title={toolTipPrompt} placement="bottom">
        {buttonText || <ErrorIcon color="error" style={iconStyle} />}
      </Tooltip>
    </div>
  ) : (
    buttonText
  );
};

FlowLimitTooltip.defaultProps = {
  buttonText: null,
  isSendAll: false,
};

FlowLimitTooltip.displayName = 'FlowLimitTooltip';
FlowLimitTooltip.propTypes = {
  buttonText: PropTypes.node,
  limitReached: PropTypes.bool.isRequired,
  isSendAll: PropTypes.bool.isRequired,
};

export default FlowLimitTooltip;
