import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { actions } from '@groove-labs/groove-ui';
import { FILTER_MENU_GROUP_ID } from 'Modules/Actions/constants/filterMenu';
import { getActionFilters } from 'Modules/Actions/selectors';

const { updateFieldValue } = actions.form;

const styles = theme => ({
  root: {
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    minWidth: 50,
    margin: '5px 15px 0px 0px',
    height: 16,
    borderRadius: 3,
    background: theme.palette.text.secondary,
    padding: '4px 8px',
    justifyContent: 'space-between',
  },
  text: {
    color: theme.palette.text.neutralColor,
    fontSize: 12,
    width: '100%',
  },
  closeIcon: {
    height: 16,
    width: 16,
    marginLeft: 4,
    color: theme.palette.text.neutralColor,
    cursor: 'pointer',
  },
});

@withStyles(styles)
@connect(
  state => ({
    savedFilters: getActionFilters(state),
  }),
  {
    updateFieldValue,
  }
)
class Chip extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    fieldId: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    savedFilters: ImmutablePropTypes.map.isRequired,
    onRemove: ImmutablePropTypes.func.isRequired,
  };

  onClick = () => {
    const { fieldId, value, savedFilters, onRemove, updateFieldValue } =
      this.props;

    onRemove();
    updateFieldValue({
      groupId: FILTER_MENU_GROUP_ID,
      fieldId,
      value: savedFilters.get(fieldId).filter(val => val !== value),
    });
  };

  render() {
    const { classes, text } = this.props;

    return (
      <div className={classes.root}>
        <Typography className={classes.text}>{text}</Typography>
        <CloseIcon
          classes={{ root: classes.closeIcon }}
          onClick={this.onClick}
        />
      </div>
    );
  }
}

export default Chip;
