import { NEW_ACTION_ID } from '@groove/api/gateway/v1/actionCompose';
import { ApiUser } from '@groove/api/gateway/v1/users';
import pluralize from 'pluralize';
import useLaunchDarklyStore from '@groove/api/hooks/launchdarkly/useStore';

import {
  SMS_NOT_ENABLED_MESSAGE,
  SMS_PHONE_NUMBER_MISSING_MESSAGE,
} from '../constants';
import useStore from '../store/useStore';
import { englishJoiner, validationHash } from '../utils';

export const getOptedOutTooltip = (): string | undefined => {
  const { doNotCall, doNotSms, doNotEmail } =
    useStore.getState().action.who || {};
  const { isEmailOptOutSafeguardDisabled } = useStore.getState().otherValues;
  const emailOptedOut = doNotEmail && !isEmailOptOutSafeguardDisabled;
  const { toRecipients, type } = useStore.getState().action;
  let tooltip;

  if (type === 'TEMPLATE' && emailOptedOut === true) {
    tooltip = validationHash.emailOptOut(toRecipients?.[0].name);
  } else if (type === 'CALL' && doNotCall === true) {
    tooltip = validationHash.callOptOut(toRecipients?.[0].name);
  } else if (type === 'STEP_SUBTYPE_SMS' && doNotSms === true) {
    tooltip = validationHash.smsOptOut(toRecipients?.[0].name);
  }

  return tooltip;
};

export const generateCompleteTooltip = (
  userData: ApiUser | null = null,
): string | undefined => {
  const { smsEnabled, phoneNumber } = useStore.getState().otherValues;
  const { executionErrors, dialerEnabled } = useStore.getState();
  const { toRecipients, type, id } = useStore.getState().action;
  const { ldClient } = useLaunchDarklyStore.getState();
  const dialerInActionEnabled = ldClient?.variation('dialer-in-action');
  const optedOutTooltip = getOptedOutTooltip();

  const errorLocations = executionErrors
    .filter(error => error.readableLocation)
    .map(error => error.readableLocation);

  let tooltip;

  if (optedOutTooltip) tooltip = optedOutTooltip;
  else if (type === 'STEP_SUBTYPE_SMS' && !smsEnabled) {
    tooltip = SMS_NOT_ENABLED_MESSAGE;
  } else if (type === 'STEP_SUBTYPE_SMS' && !phoneNumber) {
    tooltip = SMS_PHONE_NUMBER_MISSING_MESSAGE;
  } else if (
    type === 'CALL' &&
    userData?.dialer_enabled &&
    id !== NEW_ACTION_ID &&
    !dialerInActionEnabled
  ) {
    tooltip = 'Please complete call in dialer';
  } else if (
    (type === 'CALL' || type === 'STEP_SUBTYPE_SMS') &&
    toRecipients?.[0] &&
    !toRecipients[0].phone &&
    dialerEnabled
  ) {
    tooltip = validationHash.noPhone(toRecipients?.[0].name);
  } else if (errorLocations.length) {
    tooltip = `Please complete required fields: ${englishJoiner(
      errorLocations,
    )}`;
  } else if (executionErrors.length) {
    tooltip = `Please see the ${pluralize(
      'message',
      executionErrors.length,
    )} in the message bar.`;
  }

  return tooltip;
};

export const generateSaveTooltip = (): string | undefined => {
  const { saveErrors } = useStore.getState();
  const optedOutTooltip = getOptedOutTooltip();
  let tooltipSave;

  if (optedOutTooltip) tooltipSave = optedOutTooltip;
  else if (saveErrors.length) {
    tooltipSave = `Please complete required fields: ${englishJoiner(
      saveErrors.map(error => error.readableLocation),
    )}`;
  }

  return tooltipSave;
};
