import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PersonSearchFlowsItem from 'Modules/App/components/PersonSearchFlowsItem';
import styles from './styles';

const PersonSearchFlowsList = ({
  classes,
  flows,
  currentUserId,
  onRemoveFromFlowClick,
}) => {
  return (
    <div className={classes.flowListContainer}>
      <Typography className={classes.flowTitle}>Flows</Typography>
      <div className={classes.flowList}>
        {flows.map(flow => (
          <PersonSearchFlowsItem
            currentUserId={currentUserId}
            flow={flow}
            key={flow.id}
            onRemoveFromFlowClick={onRemoveFromFlowClick}
          />
        ))}
      </div>
    </div>
  );
};

PersonSearchFlowsList.displayName = 'PersonSearchFlowsList';
PersonSearchFlowsList.propTypes = {
  classes: PropTypes.shape({
    flowTitle: PropTypes.string.isRequired,
    flowListContainer: PropTypes.string.isRequired,
    flowList: PropTypes.string.isRequired,
  }).isRequired,
  flows: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ).isRequired,
  currentUserId: PropTypes.number.isRequired,
  onRemoveFromFlowClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(PersonSearchFlowsList);
