import { FC, useEffect, useState } from 'react';
import Text from '@groove/ui/Components/Text';
import PicklistNew, {
  StandardDropDownItem,
} from '@groove/ui/Components/PicklistNew';
import { useQuery } from 'react-query';
import { useAllActiveFlowsKey } from '@groove/api/hooks/useAllActiveFlows';
import { flowsFetch } from '@groove/api/gateway/v1/flows';
import { debounce } from 'lodash-es';

import useStore from '../store/useStore';

const ExecuteAddToFlowDialog: FC = () => {
  const [flowSearchTerm, setFlowSearchTerm] = useState('');
  const [fetchedFlows, setFetchedFlow] = useState<StandardDropDownItem[]>([]);

  const selectedFlow = useStore(store => store.otherValues.selectedFlow);
  const name = useStore(store => store.action.who?.name);

  const { refetch } = useQuery(
    [useAllActiveFlowsKey, flowSearchTerm],
    () =>
      flowsFetch(
        {
          searchTerm: flowSearchTerm,
          isFlowActive: true,
          notCompleted: true,
        },
        0,
        20,
      ),
    {
      onSuccess: data => {
        const fetchedFlows: StandardDropDownItem[] = data.map(flow => ({
          key: flow.id,
          value: flow.id,
          text: flow.name,
          showTooltipText: true,
        }));
        setFetchedFlow(fetchedFlows);
      },
      cacheTime: 5 * 60 * 1000, // 5 minutes because we don't expect the flow to change while we are on this popup dialog screen
      staleTime: 5 * 60 * 1000,
    },
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-[230px]">
      <Text className="py-[12px] text-body-sm">
        Please select a flow to add {name} to
      </Text>
      <PicklistNew
        className="flex-1 mb-[20px]"
        onChange={item =>
          item && useStore.getState().setOtherValues({ selectedFlow: item })
        }
        items={fetchedFlows}
        selectedValues={selectedFlow ? [selectedFlow] : []}
        onSearchChange={debounce(setFlowSearchTerm, 300)}
        placeholder="Select a flow"
        hasSearch
      />
    </div>
  );
};

export default ExecuteAddToFlowDialog;
