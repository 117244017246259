import { SagaIterator } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';

import { trackEvent } from 'Utils/segment';
import {
  createSafeActionHandler,
  ANALYTICS_BASE_PROPERTIES,
} from 'Modules/Shared/sagas/analytics';
import { actionTypes } from 'Modules/Shared/actions/accountPreview';
import { ROUTE_NAMES } from 'Routing/constants';

const ACTION_TO_NAME_MAPPING: { [key: string]: string } = {
  [actionTypes.ADD_PEOPLE_TO_FLOW.BEGIN]: 'Add people to flow',
  [actionTypes.REMOVE_PEOPLE_FROM_ALL_FLOWS.BEGIN]: 'Remove people from flow',
  [actionTypes.FETCH_UNASSOCIATED_CONTACTS.BEGIN]:
    'See additional contacts in salesforce',
};

type SafeActionHandlerPayload = {
  type: string;
};

function* salesforceObjectPopOutActionExecuted(): SagaIterator {
  yield takeEvery(
    (action: SafeActionHandlerPayload) =>
      action.type === actionTypes.ADD_PEOPLE_TO_FLOW.BEGIN ||
      action.type === actionTypes.REMOVE_PEOPLE_FROM_ALL_FLOWS.BEGIN ||
      action.type === actionTypes.FETCH_UNASSOCIATED_CONTACTS.BEGIN,
    createSafeActionHandler(({ type }: SafeActionHandlerPayload) => {
      trackEvent('Salesforce Object Popout Action Executed', {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory: ROUTE_NAMES.SPACES,
        actionType: ACTION_TO_NAME_MAPPING[type],
      });
    })
  );
}

export default salesforceObjectPopOutActionExecuted;
