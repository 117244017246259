import * as React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import { MODULE_ROOT_PADDING } from 'Modules/Shared/constants/styles';
import LoaderTable from 'Modules/Outcomes/components/OutcomesRouteLoader/LoaderTable';
import LoaderCards from 'Modules/Outcomes/components/OutcomesRouteLoader/LoaderCards';
import LoaderChart from 'Modules/Outcomes/components/OutcomesRouteLoader/LoaderChart';
import { ModifiedTheme } from 'Utils/theme/ClariTheme';

const useStyles = makeStyles((theme: ModifiedTheme) => ({
  root: {
    padding: MODULE_ROOT_PADDING,
    display: 'flex',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 160px)',
  },
  contentRoot: {
    width: '100%',
    maxWidth: 1300,
    position: 'relative',
  },
  title: {
    color: theme.palette.text.primary,
    marginTop: 15,
    marginBottom: 15,
  },
  subtitle: {
    color: theme.palette.text.primary,
    marginTop: 30,
    marginBottom: 15,
    fontWeight: 600,
  },
}));

const OutcomesRouteLoader: React.FC = () => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <div className={classes.contentRoot}>
        <Typography variant="h3" className={classes.title}>
          Flow Analytics
        </Typography>
        <LoaderCards failed={false} />
        <div className={classes.subtitle}>Top Performances</div>
        <LoaderChart failed={false} />
        <div className={classes.subtitle}>All Flows</div>
        <LoaderTable failed={false} />
      </div>
    </div>
  );
};

export default OutcomesRouteLoader;
