import { put } from 'redux-saga/effects';

import {
  setCurrentUser,
  fetchCurrentUser,
  setIsLoggedIn,
} from 'Modules/Shared/actions/users';

export default function* resolveOutlookAddInExchangeToken() {
  const promise = new Promise(resolve => {
    const handler = message => {
      const { auth } = message?.data;
      if (auth && auth.microsoftToken) {
        window.localStorage.setItem('microsoftToken', auth.microsoftToken);
        resolve();
      }
    };
    window.addEventListener('message', handler);

    window.parent.postMessage(
      {
        type: 'OUTGOING_ACTION_COMPOSE/READY',
        target: 'febes',
        payload: {},
        meta: { target: 'febes', source: 'actionCompose' },
      },
      '*'
    );
  });

  yield promise;
  const currentUser = yield put(fetchCurrentUser());
  yield put(setCurrentUser(currentUser));
  yield put(setIsLoggedIn(true));
}
