export default function LowPriorityIcon({
  className,
  style,
}: {
  className: string;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <circle cx="6" cy="6.5" r="6" fill="#F19539" />
      <path
        opacity=".8"
        d="M3.11 8.37a.335.335 0 0 0 .53 0L6 5.573 8.36 8.37a.335.335 0 0 0 .53 0 .505.505 0 0 0 0-.629l-2.625-3.11a.335.335 0 0 0-.53 0L3.11 7.74a.505.505 0 0 0 0 .629z"
        fill="#fff"
      />
    </svg>
  );
}
