import { Checkbox, ICheckboxProps } from '@fluentui/react';
import { FC } from 'react';
import { memoizeFunction } from '@fluentui/react/lib/Utilities';

const getStyles = memoizeFunction(styles => ({
  root: 'w-3',
  checkbox: 'w-3 h-3',
  checkmark: { fontSize: 8 },
  ...styles,
}));

const SmallCheckbox: FC<ICheckboxProps> = ({ styles, ...rest }) => {
  return (
    <Checkbox
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      styles={getStyles(styles)}
    />
  );
};

export default SmallCheckbox;
