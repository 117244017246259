import { DoccenterParam, LiveSyncLinkParam } from './Seismic';

export const doccenter = ({ image, link, name }: DoccenterParam): string =>
  `<table style="width:180px">
    <tr>
      <td style="text-align: center">
        <table border="0" cellpadding="0" cellspacing="0"  style="margin:auto;margin-bottom: 5px;">
          <tr>
            <td>
              <a href="${link}">
                <img src="${image}" alt="${name}"/>
              </a>
            </td>
          </tr>
        </table>
        <a href="${link}">${name}</a>
      </td>
    </tr>
  </table>`;

export const liveSyncLink = ({ link, name }: LiveSyncLinkParam): string =>
  `<div><a href="${link}">${name}</a></div>`;
