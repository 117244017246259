export const FIREBASE_ERROR = 'FirebaseError';
export const NUMBER_VERIFICATION_CONTEXT = 'NumberVerificationContext';
export const SPACES_CONTEXT = 'SpacesContext';
export const COACHING_CONTEXT = 'CoachingContext';

export class FirebaseAuthenticationError extends Error {
  constructor() {
    super();
    this.name = `${FIREBASE_ERROR}::AuthenticationError`;
    this.message = 'Failed to Authenticate with Firebase';
  }
}

export class DocumentOrCollectionMissingError extends Error {
  constructor(type: string, path: string) {
    super();
    this.name = `${FIREBASE_ERROR}::DocumentOrCollectionMissingError`;
    this.message = `Firestore ${type} path [${path}] does not exist.`;
  }
}

export class CreateFirestoreChannelError extends Error {
  constructor(path: string) {
    super();
    this.name = `${FIREBASE_ERROR}::CreateFirestoreChannelError`;
    this.message = `Failed to create Channel for path [${path}]`;
  }
}
