import { fromJS, OrderedMap } from 'immutable';
import { actionTypes } from 'Modules/Emails/actions';
import { FILTER_TYPES, SOURCES } from 'Modules/Emails/constants';

export const initialState = fromJS({
  data: OrderedMap(),
  eventFilter: FILTER_TYPES.OPENED,
  source: SOURCES.ALL,
  search: '',
  isInSearchMode: false,
  pageInfo: {
    endCursor: null,
    hasNextPage: false,
  },
  isFetchingTheNextPageOfEmails: false,
  emailsOnboardingCompleted: true,
});

export default function emails(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.FETCH.BEGIN: {
      return state.set('isFetchingTheNextPageOfEmails', true);
    }
    case actionTypes.FETCH.SUCCESS: {
      const { emails, pageInfo } = action.payload;

      return state
        .mergeIn(['data'], emails)
        .set('pageInfo', pageInfo)
        .set('isFetchingTheNextPageOfEmails', false);
    }
    case actionTypes.FETCH_SINGLE_EMAIL.SUCCESS: {
      const email = action.payload;

      return state.setIn(['data', email.id], email);
    }
    case actionTypes.FETCH.CANCEL:
    case actionTypes.FETCH.FAILURE: {
      return state.set('isFetchingTheNextPageOfEmails', false);
    }

    case actionTypes.SET_EVENT_FILTER: {
      return state.set('eventFilter', action.payload);
    }

    case actionTypes.SET_SOURCE: {
      return state.set('source', action.payload);
    }

    case actionTypes.SET_IS_SEARCH_ACTIVE: {
      return state.set('isInSearchMode', action.payload);
    }

    case actionTypes.SET_SEARCH: {
      return state.set('search', action.payload);
    }

    case actionTypes.MARK_EMAIL_AS_READ: {
      return state.updateIn(['data', action.payload, 'events'], events =>
        events.map(event => event.set('readByUser', true))
      );
    }

    case actionTypes.MARK_ALL_EMAIL_EVENTS_AS_READ: {
      return state.update('data', data =>
        data.map(email =>
          email.update('events', events =>
            events.map(event => event.set('readByUser', true))
          )
        )
      );
    }

    case actionTypes.SET_IS_EMAIL_MUTED.SUCCESS: {
      const { id, mutedAt } = action.payload;
      return state.setIn(['data', id, 'mutedAt'], mutedAt);
    }

    case actionTypes.CLEAR_EMAILS: {
      return state
        .set('data', initialState.get('data'))
        .set('pageInfo', initialState.get('pageInfo'));
    }

    case actionTypes.SET_SHOULD_ONBOARD: {
      return state.set('emailsOnboardingCompleted', !action.payload);
    }

    case actionTypes.COMPLETE_EMAILS_ONBOARDING.SUCCESS: {
      return state.set('emailsOnboardingCompleted', true);
    }

    case actionTypes.RESET_REDUCER:
      return initialState;

    default:
      return state;
  }
}
