import { Checkbox } from '@fluentui/react';
import RecordMenuItem from '@groove/ui/Components/RecordMenuItem';
import { FC } from 'react';
import { isContactOrLead } from '@groove/api/helpers/sfdcObjectPrefixes';

import { DisplayableSearchResult } from './transformSearchResult';
import useStore from './useStore';
import WhatList from './WhatList';
import WhoList from './WhoList';

type ResultItemProps = {
  item: DisplayableSearchResult;
  handleOmnibarContextChange?: (id: string) => void;
};

const ResultItem: FC<ResultItemProps> = ({
  item,
  handleOmnibarContextChange,
}) => {
  const selectedItems = useStore(store => store.selectedItems);
  const subItemIds = [] as string[];

  Object.values(selectedItems).forEach(item => {
    if (item.subItems) {
      subItemIds.push(...Object.keys(item.subItems));
    }
  });

  const selectedSubItemIds = item.id
    ? Object.values(selectedItems[item.id]?.subItems || [])?.map(
        subItem => subItem.id,
      )
    : [];
  const isSelected =
    (!!item.id && !!selectedItems[item.id]) || subItemIds.includes(item.id);

  const isIdInSelectedWhoWhatPairs = useStore(
    store => store.isIdInSelectedItems,
  );
  const handleChangeTopLevelListItem = useStore(
    store => store.handleChangeTopLevelListItem,
  );
  const handleChangeWhatListItem = useStore(
    store => store.handleChangeWhatListItem,
  );
  const handleChangeWhoListItem = useStore(
    store => store.handleChangeWhoListItem,
  );

  return (
    <div key={item.id}>
      <div className="flex items-center">
        <Checkbox
          ariaLabel={`Select-${item.id}`}
          checked={isSelected}
          onChange={() => {
            if (item.id) {
              handleChangeTopLevelListItem(item);
            }
          }}
        />
        <RecordMenuItem
          fullWidth
          showLinkToSalesforce
          id={item.id as string}
          text={item.text || ''}
          secondaryText={item.secondaryText}
          secondaryTextLabel={
            (item as DisplayableSearchResult).secondaryTextLabel
          }
          tertiaryText={(item as DisplayableSearchResult).tertiaryText}
          tertiaryTextLabel={
            (item as DisplayableSearchResult).tertiaryTextLabel
          }
          quarteraryText={(item as DisplayableSearchResult).quarteraryText}
          quarteraryTextLabel={
            (item as DisplayableSearchResult).quarteraryTextLabel
          }
          quinaryText={(item as DisplayableSearchResult).quinaryText}
          quinaryTextLabel={(item as DisplayableSearchResult).quinaryTextLabel}
          senaryText={(item as DisplayableSearchResult).senaryText}
          senaryTextLabel={(item as DisplayableSearchResult).senaryTextLabel}
          septenaryText={(item as DisplayableSearchResult).septenaryText}
          septenaryTextLabel={
            (item as DisplayableSearchResult).septenaryTextLabel
          }
          handleOmnibarContextChange={handleOmnibarContextChange}
        />
      </div>
      {item.id && isIdInSelectedWhoWhatPairs(item.id) && (
        <div className="pl-2">
          {isContactOrLead(item.id || '') && (
            <WhatList
              whoId={item.id?.toString() || ''}
              whoType={item.type || ''}
              selectedWhatId={selectedSubItemIds[0]}
              onWhatSelected={what => {
                if (item.id) {
                  handleChangeWhatListItem(what, item.id);
                }
              }}
            />
          )}
          {!isContactOrLead(item.id || '') && (
            <WhoList
              whatId={item.id?.toString() || ''}
              whatType={item.type || ''}
              selectedWhoIds={selectedSubItemIds}
              onWhoSelected={who => {
                if (item.id) {
                  handleChangeWhoListItem(who, item.id);
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ResultItem;
