import useTemplateStar from '@groove/api/hooks/useTemplateStar';
import useTemplateUnstar from '@groove/api/hooks/useTemplateUnstar';
import { IconButton, Text, TooltipHost } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { memoizeFunction } from '@fluentui/react/lib/Utilities';

import {
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  TEMPLATES_VIEW,
  TEMPLATE_STAR_STATE,
  trackTemplatesEvent,
} from './analytics/templatesEvents';
import useStore from './store/useStore';
import Star from './icons/Star';
import EmptyStar from './icons/EmptyStar';

type TemplateStarrerProps = {
  isFavorite: boolean;
  templateId: number;
  isActive?: boolean;
  isPreviewView?: boolean;
  isInModalView?: boolean;
  size?: 'small' | 'medium';
};

const getBtnStyles = memoizeFunction(size => ({
  root: `${
    size === 'small' ? 'h-5 w-5 p-0' : 'h-8 w-8 p-1'
  } text-neutral/400 hover:text-neutral/900`,
}));

const TemplateStarrer: FC<TemplateStarrerProps> = ({
  isFavorite,
  templateId,
  isActive,
  isPreviewView,
  isInModalView,
  size = 'small',
}) => {
  const { mutate: starTemplate } = useTemplateStar();
  const { mutate: unstarTemplate } = useTemplateUnstar();

  // Need a local state to reduce lag when starring/unstarring
  const [isStarred, setIsStarred] = useState(isFavorite);
  const selectedTemplatePanelTab = useStore(
    store => store.selectedTemplatePanelTab,
  );

  useEffect(() => {
    setIsStarred(isFavorite);
  }, [isFavorite]);

  const handleToggleFavorite = (e: React.MouseEvent): void => {
    e.stopPropagation();
    if (isStarred) {
      unstarTemplate({ templateId });
    } else {
      starTemplate({ templateId });
    }
    setIsStarred(prev => !prev);
    trackTemplatesEvent({
      eventName: TEMPLATES_EVENT_TYPE.STAR_CLICKED,
      templatesSourceUi: isInModalView
        ? TEMPLATES_EVENT_UI_SOURCE.MODAL
        : TEMPLATES_EVENT_UI_SOURCE.PANEL,
      templateView: isPreviewView
        ? TEMPLATES_VIEW.PREVIEW
        : TEMPLATES_VIEW.LIST,
      templateTab: !isInModalView ? selectedTemplatePanelTab : undefined,
      starState: isStarred
        ? TEMPLATE_STAR_STATE.UNCLICKED
        : TEMPLATE_STAR_STATE.CLICKED,
    });
  };

  const starTooltipText = isStarred
    ? 'Remove from favorites'
    : 'Add to favorites';

  return (
    <TooltipHost content={<Text>{starTooltipText}</Text>}>
      <IconButton
        aria-label="favorite-button"
        onClick={e => handleToggleFavorite(e as React.MouseEvent)}
        styles={getBtnStyles(size)}
      >
        {isStarred ? <Star /> : <EmptyStar />}
      </IconButton>
    </TooltipHost>
  );
};

export default TemplateStarrer;
