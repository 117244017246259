import { createActions, standardActions } from '@groove-labs/action-utils';
import { FSA } from 'flux-standard-action';
import { List } from 'immutable';

const types = {
  AUTO_CREATION_OF_RECORDS: {
    OPEN_DIALOG: 'OPEN_DIALOG',
    CLOSE_DIALOG: 'CLOSE_DIALOG',
    FETCH_DATA: standardActions(),
    HANDLE_SOBJ_CHANGE: 'HANDLE_SOBJ_CHANGE',
    FETCH_FIELD_SET_DATA: standardActions(),
    INPUT_CHANGE: 'INPUT_CHANGE',
    REFERENCE_FIELD_SEARCH: standardActions(),
    CLEAR_SEARCH_RESULTS: 'CLEAR_SEARCH_RESULTS',
    OPEN_SFDC_FIELD_MAPPING_DIALOG: 'OPEN_SFDC_FIELD_MAPPING_DIALOG',
    CLOSE_SFDC_FIELD_MAPPING_DIALOG: 'CLOSE_SFDC_FIELD_MAPPING_DIALOG',
    MAP_FIELD: 'MAP_FIELD',
    UNMAP_FIELD: 'UNMAP_FIELD',
    RECOMMENDED_ACCOUNT_SEARCH: standardActions(),
    BULK_CREATE: standardActions(),
    AUTO_CREATION_CONFIRMATION_OPEN_DIALOG:
      'AUTO_CREATION_CONFIRMATION_OPEN_DIALOG',
    AUTO_CREATION_CONFIRMATION_CLOSE_DIALOG:
      'AUTO_CREATION_CONFIRMATION_CLOSE_DIALOG',
    BULK_CREATION_STATUS: 'BULK_CREATION_STATUS',
    ADD_TO_FLOW_IN_PROGRESS: 'ADD_TO_FLOW_IN_PROGRESS',
    ADD_TO_FLOW_STATUS: standardActions(),
    IS_LOADING: 'IS_LOADING',
    SELECT_ALL: 'SELECT_ALL',
    UNSELECT_ALL: 'UNSELECT_ALL',
    UNSELECT_ALL_WITH_WARNINGS: 'UNSELECT_ALL_WITH_WARNINGS',
    SELECT_ALL_WITHOUT_WARNINGS: 'SELECT_ALL_WITHOUT_WARNINGS',
    SELECT_RECORD: 'SELECT_RECORD',
    UNSELECT_RECORD: 'UNSELECT_RECORD',
    FLOW_SETTINGS: standardActions(),
    RESET_WARNING: 'RESET_WARNING',
  },
};

export const actionTypes =
  createActions<typeof types>(types).AUTO_CREATION_OF_RECORDS;

export type AutoCreationOfRecordPayload = {
  initialData: {
    csvHeaders: List<string>;
    salesforceObjectFieldSet: List<string>;
    csvRows: List<string>;
    mappedHeaders: List<string>;
    csvSelectedSObject: string;
    isLoading: boolean;
  };
};

export const openAutoCreationOfRecordsDialog = (): FSA => ({
  type: actionTypes.OPEN_DIALOG,
});

export const closeAutoCreationOfRecordsDialog = (): FSA => ({
  type: actionTypes.CLOSE_DIALOG,
});

export const fetchDataBegin = (): FSA => ({
  type: actionTypes.FETCH_DATA.BEGIN,
});

export const fetchDataSuccess = ({
  initialData,
}: AutoCreationOfRecordPayload): FSA<string, AutoCreationOfRecordPayload> => ({
  type: actionTypes.FETCH_DATA.SUCCESS,
  payload: {
    initialData,
  },
});

export const fetchFieldSetDataBegin = (): FSA => ({
  type: actionTypes.FETCH_FIELD_SET_DATA.BEGIN,
});

export const fetchFieldSetDataSuccess = ({
  initialData,
}: AutoCreationOfRecordPayload): FSA<string, AutoCreationOfRecordPayload> => ({
  type: actionTypes.FETCH_FIELD_SET_DATA.SUCCESS,
  payload: {
    initialData,
  },
});

export const fetchDataFailure = (): FSA => ({
  type: actionTypes.FETCH_DATA.FAILURE,
});

export const selectSalesforceObject = (
  payload: string
): FSA<string, string> => ({
  type: actionTypes.HANDLE_SOBJ_CHANGE,
  payload,
});

type InputPayload = {
  rowIdx: number;
  colIdx: number;
  value: string;
};

export const inputChange = (
  payload: InputPayload
): FSA<string, InputPayload> => ({
  type: actionTypes.INPUT_CHANGE,
  payload,
});

export type SearchPayload = {
  value: string;
};

export const handleReferenceFieldSearchBegin = (
  payload: SearchPayload
): FSA<string, SearchPayload> => ({
  type: actionTypes.REFERENCE_FIELD_SEARCH.BEGIN,
  payload,
});

export const clearSearchResults = (): FSA => ({
  type: actionTypes.CLEAR_SEARCH_RESULTS,
});

export const openSfdcFieldMappingDialog = (): FSA => ({
  type: actionTypes.OPEN_SFDC_FIELD_MAPPING_DIALOG,
});

export const closeSfdcFieldMappingDialog = (): FSA => ({
  type: actionTypes.CLOSE_SFDC_FIELD_MAPPING_DIALOG,
});

export type MapFieldsPayload = {
  csvHeader: string;
  sFieldName: string;
  fieldKey: string;
};

export const mapFields = (
  payload: MapFieldsPayload
): FSA<string, MapFieldsPayload> => ({
  type: actionTypes.MAP_FIELD,
  payload,
});

export const unmapFields = (
  payload: MapFieldsPayload
): FSA<string, MapFieldsPayload> => ({
  type: actionTypes.UNMAP_FIELD,
  payload,
});

export type AccountSearchPayload = {
  values: List<string>;
};

export const handleRecommendedAccountSearchBegin = (
  payload: AccountSearchPayload
): FSA<string, AccountSearchPayload> => ({
  type: actionTypes.RECOMMENDED_ACCOUNT_SEARCH.BEGIN,
  payload,
});

export const bulkCreationBegin = (): FSA => ({
  type: actionTypes.BULK_CREATE.BEGIN,
});

export type BulkCreatePayload = {
  batchResponse: List<string>;
  recordsFailed: number;
  recordsProcessed: number;
};

export const bulkCreationSuccess = (
  payload: BulkCreatePayload
): FSA<string, BulkCreatePayload> => ({
  type: actionTypes.BULK_CREATE.SUCCESS,
  payload,
});

export const openAutoCreationConfirmationModal = (): FSA => ({
  type: actionTypes.AUTO_CREATION_CONFIRMATION_OPEN_DIALOG,
});

export const closeAutoCreationConfirmationModal = (): FSA => ({
  type: actionTypes.AUTO_CREATION_CONFIRMATION_CLOSE_DIALOG,
});

export const updateRecordsCreationStatus = (
  payload: boolean
): FSA<string, boolean> => ({
  type: actionTypes.BULK_CREATION_STATUS,
  payload,
});

export const updateAddToFlowInProgress = (
  payload: boolean
): FSA<string, boolean> => ({
  type: actionTypes.ADD_TO_FLOW_IN_PROGRESS,
  payload,
});

export const addToFlowBegin = (): FSA => ({
  type: actionTypes.ADD_TO_FLOW_STATUS.BEGIN,
});
export const addToFlowSuccess = (): FSA => ({
  type: actionTypes.ADD_TO_FLOW_STATUS.SUCCESS,
});
export const addToFlowFailure = (payload: string): FSA<string, string> => ({
  type: actionTypes.ADD_TO_FLOW_STATUS.FAILURE,
  payload,
});

export const isLoading = (payload: boolean): FSA<string, boolean> => ({
  type: actionTypes.IS_LOADING,
  payload,
});

export const selectAll = (payload: any): FSA => ({
  type: actionTypes.SELECT_ALL,
  payload,
});

export const unselectAll = (): FSA => ({
  type: actionTypes.UNSELECT_ALL,
});

export const unselectAllWithWarnings = (
  payload: Array<number>
): FSA<string, Array<number>> => ({
  type: actionTypes.UNSELECT_ALL_WITH_WARNINGS,
  payload,
});

export const selectAllWithoutWarnings = (
  payload: Array<number>
): FSA<string, Array<number>> => ({
  type: actionTypes.SELECT_ALL_WITHOUT_WARNINGS,
  payload,
});

export const selectRecord = (
  payload: Array<number>
): FSA<string, Array<number>> => ({
  type: actionTypes.SELECT_RECORD,
  payload,
});

export const unselectRecord = (
  payload: Array<number>
): FSA<string, Array<number>> => ({
  type: actionTypes.UNSELECT_RECORD,
  payload,
});

export const getFlowSettingsBegin = (): FSA => ({
  type: actionTypes.FLOW_SETTINGS.BEGIN,
});

export type WarningPayload = {
  rowIdx: number;
  colIdx: number;
};

export const fixWarning = (
  payload: WarningPayload
): FSA<string, WarningPayload> => ({
  type: actionTypes.RESET_WARNING,
  payload,
});
