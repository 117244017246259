import { fromJS, List } from 'immutable';

import ClariTheme from 'Utils/theme/ClariTheme';

export const SALESFORCE_OBJECTS = {
  Account: 'Account',
  Campaign: 'Campaign',
  Lead: 'Lead',
  Contact: 'Contact',
  Opportunity: 'Opportunity',
  CampaignMember: 'CampaignMember',
};

export const TEMPLATE_DROPDOWN_OPTIONS = {
  NO_TEMPLATE: "Don't use a template",
  NEW_TEMPLATE: 'Create a new template',
};
export const TEMPLATE_ADD_EDIT_FORM_GROUP = 'TemplateAddEdit';

export const MERGE_FIELD_DIALOG_OPEN_KEY_PATH = [
  'shared',
  'mergeFieldDialogOpen',
];
export const MERGE_FIELD_GROUP_ID = 'mergeFieldGroup';
export const MERGE_FIELD_SELECT_ID = 'mergeFieldSelect';

export const ADDABLE_FLOWS_UI_KEY_PATH = ['addableFlows'];

export const CLOUDFRONT_TTL = 150000;

export const ROUTES_TO_CHECK_SFDC_CREDENTIALS = [
  'flowsShow',
  'analytics',
  'workStep',
  'flowsWizard',
];

export const CHECK_SDFC_CREDENTIALS_DELAY = 1 * 60 * 1000; // the amount of time to wait before checking sfdc creds on route change

export const STEP_ADD_EDIT_BATCH_ID_UI_KEYPATH = ['stepAddEditBatchId'];

export const STEP_TEMPLATE_NAME_FIELD = 'stepTemplateName';

export const CACHED_TEMPLATE_DATA = 'cachedTemplateData';

export const GMAIL_LINK = 'https://mail.google.com/mail/u/0/#inbox';

export const VIDYARD_DIALOG_OPEN_KEY_PATH = ['shared', 'vidyardDialogOpen'];

export const AVAILABLE_USER_ROLES = [
  'agent',
  'teamLead',
  'analyst',
  'orgAdmin',
  'admin',
];
export const USER_ROLES = Object.freeze({
  ADMIN: 'admin',
  AGENT: 'agent',
  ANALYST: 'analyst',
  ORG_ADMIN: 'orgAdmin',
  TEAM_LEAD: 'teamLead',
});

export const ERROR_TYPE_UI_KEY_PATH = ['error', 'type'];

export const ERROR_DIALOG_UI_KEY_PATH = ['errorDialog', 'open'];

export const ERROR_TYPES = {
  INVALID_SALESFORCE_META: 'INVALID_SALESFORCE_META',
  EMAIL_OR_EVENT_CONNECTION: 'EMAIL_OR_EVENT_CONNECTION',
  SFDC_REQUEST_LIMIT_EXCEEDED: 'SFDC_REQUEST_LIMIT_EXCEEDED',
  REQUEST_TIMEOUT: 'REQUEST_TIMEOUT',
  NETWORK_ERROR: 'NETWORK_ERROR',
  UNHANDLED_HTTP_ERROR: 'UNHANDLED_HTTP_ERROR',
  GENERIC: 'GENERIC',
  FIREBASE_NETWORK_AUTH_ERROR: 'FIREBASE_NETWORK_AUTH_ERROR',
};

export const LOADING_ERROR_BAR_TEXT =
  'Groove has failed to load this section, please try again';
export const LOADING_ERROR_BAR_LINK_TEXT = 'Retry';

// Routes where omnibar should be shown in Febes
export const OMNIBAR_SUPPORTED_ROUTES = [
  'conversations',
  'conversationsShow',
  'workStep',
  'people',
  'accounts',
  'spacesShow',
  'actions',
  'outcomes',
];
// If only a certain tab is supported in a route, add it here.
export const OMNIBAR_SUPPORTED_TABS = [
  {
    routeName: 'flowsShow',
    tabName: 'custom_merge_fields',
  },
];

export const VIDYARD_PLAYER_WIDTH_LIMIT = 450;

export const ASC = 'asc';
export const DESC = 'desc';

export const STEP_TYPE_AUTO_SEND_EMAIL = 'auto-send-email';
export const STEP_TYPE_EMAIL = 'email';
export const STEP_TYPE_CALL = 'call';
export const STEP_TYPE_OTHER = 'other';
export const STEP_TYPE_DIRECT_MAIL = 'direct_mail';
export const STEP_TYPE_IN_PERSON_VISIT = 'in_person_visit';
export const STEP_TYPE_LINKEDIN_CONNECT = 'linkedin_connect';
export const STEP_TYPE_LINKEDIN_INMAIL = 'linkedin_inmail';
export const STEP_TYPE_SENDOSO = 'sendoso';
export const STEP_TYPE_SMS = 'sms';

export const STEP_TYPE_LABELS = [
  {
    label: 'send an email',
    value: STEP_TYPE_EMAIL,
  },
  {
    label: 'auto-send an email',
    value: STEP_TYPE_AUTO_SEND_EMAIL,
  },
  {
    label: 'make a call',
    value: STEP_TYPE_CALL,
  },
  {
    label: 'take another action',
    value: STEP_TYPE_OTHER,
  },
  {
    label: 'send direct mail',
    value: STEP_TYPE_DIRECT_MAIL,
  },
  {
    label: 'have an in-person visit',
    value: STEP_TYPE_IN_PERSON_VISIT,
  },
  {
    label: 'Connect via LinkedIn Sales Navigator',
    value: STEP_TYPE_LINKEDIN_CONNECT,
  },
  {
    label: 'Send Inmail via LinkedIn Sales Navigator',
    value: 'linkedin_inmail',
  },
  {
    label: 'send Sendoso gift',
    value: 'sendoso',
  },
  {
    label: 'send an SMS message',
    value: STEP_TYPE_SMS,
  },
];

export const PRIORITY_FIELD_ID = 'priorityType';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PRIORITIES: any = {
  NONE: null,
  NONE_FOR_FILTER: 'none',
  LOW: 'low',
  NORMAL: 'normal',
  HIGH: 'high',
};

export const PRIORITY_OPTIONS = fromJS({
  [PRIORITIES.NONE]: {
    label: 'None',
    value: PRIORITIES.NONE,
  },
  [PRIORITIES.LOW]: {
    label: 'Low',
    value: PRIORITIES.LOW,
  },
  [PRIORITIES.NORMAL]: {
    label: 'Medium',
    value: PRIORITIES.NORMAL,
  },
  [PRIORITIES.HIGH]: {
    label: 'High',
    value: PRIORITIES.HIGH,
  },
});

export const PRIORITY_DATA = List([
  {
    label: 'None',
    value: PRIORITIES.NONE,
  },
  {
    label: 'Low',
    value: PRIORITIES.LOW,
  },
  {
    label: 'Medium',
    value: PRIORITIES.NORMAL,
  },
  {
    label: 'High',
    value: PRIORITIES.HIGH,
  },
]);

export const PRIORITY_FILTER = List([
  {
    label: 'None',
    value: PRIORITIES.NONE_FOR_FILTER,
  },
  {
    label: 'Low',
    value: PRIORITIES.LOW,
  },
  {
    label: 'Medium',
    value: PRIORITIES.NORMAL,
  },
  {
    label: 'High',
    value: PRIORITIES.HIGH,
  },
]);

export const PRIORITY_COLORS = {
  [PRIORITIES.HIGH]: ClariTheme.palette['error/600'],
  [PRIORITIES.NORMAL]: ClariTheme.palette['naranja/400'],
  [PRIORITIES.LOW]: ClariTheme.palette['neutral/200'],
};

export const PRIORITY_ABBREVIATION = {
  [PRIORITIES.HIGH]: 'H',
  [PRIORITIES.NORMAL]: 'M',
  [PRIORITIES.LOW]: 'L',
};

export const PRIORITY_TITLES = {
  [PRIORITIES.HIGH]: 'High Priority',
  [PRIORITIES.NORMAL]: 'Medium Priority',
  [PRIORITIES.LOW]: 'Low Priority',
};

export const STEP_TYPES_WITHOUT_SUBJECT = [
  STEP_TYPE_CALL,
  STEP_TYPE_OTHER,
  STEP_TYPE_DIRECT_MAIL,
  STEP_TYPE_IN_PERSON_VISIT,
  STEP_TYPE_LINKEDIN_CONNECT,
  STEP_TYPE_SMS,
];

export const STEP_SUBTYPES = {
  direct_mail: 'STEP_SUBTYPE_DIRECT_MAIL',
  in_person_visit: 'STEP_SUBTYPE_IN_PERSON_VISIT',
  linkedin_inmail: 'STEP_SUBTYPE_LINKEDIN_INMAIL',
  linkedin_connect: 'STEP_SUBTYPE_LINKEDIN_CONNECT',
  sendoso: 'STEP_SUBTYPE_SENDOSO',
  sms: 'STEP_SUBTYPE_SMS',
};

export const TOUCH_TEXT = {
  Email: 'Emailed',
  Call: 'Called',
  LinkedInInmail: 'Sent a LinkedIn InMail',
  LinkedInConnect: 'Connected on LinkedIn',
  SMS: 'Sent an SMS',
  Sendoso: 'Sent Sendoso gift',
  DirectMail: 'Direct mailed',
  InPersonVisit: 'Visited in person',
  Action: 'Other action taken',
};

export const BACKEND_STEP_TYPES = {
  TEMPLATE: 'email',
  CALL: 'call',
  OTHER: 'other',
};

export const ADD_EDIT_GROUP_ID_FALLBACK_VALUE = 'addEditStepDialog';

export const CREATE_OR_UPDATE_STEP_UI_KEYPATH = [
  'creatingOrUpdatingStepFailure',
];

export const LEAVE_PAGE_CONFIRMATION_KEY_PATH = ['leavePageConfirmation'];

export const PREVENT_USER_AND_ANALYST_FLOW_CREATION_CONFIG =
  'preventAgentAndAnalystFlowCreation';

export const OAUTH_PROVIDERS = Object.freeze({
  GOOGLE: 'google_oauth2',
  MICROSOFT_GRAPH: 'microsoft_graph_auth',
});

export const OAUTH_LABELS = Object.freeze({
  GOOGLE: 'Google',
  MICROSOFT: 'Microsoft',
});

export const CONFIRMATION_DIALOG_KEY_PATH = ['showConfirmationDialog'];

export const AUTO_CREATION_OF_RECORDS_DIALOG_KEY_PATH = [
  'flowShow',
  'isAutoCreationOfRecordsDialogOpen',
];

export const SFDC_FIELD_MAPPING_DIALOG_KEY_PATH = [
  'autoCreationOfRecords',
  'isSfdcFieldMappingDialogOpen',
];

export const AUTO_CREATION_CONFIRMATION_DIALOG_KEY_PATH = [
  'autoCreationOfRecords',
  'isConfirmationModalOpen',
];

export const ADD_PEOPLE_JOB_ID_KEYPATH = [
  'PeopleImportDialog',
  'root',
  'addPeopleJobId',
];

export const CUSTOM_SFDC_FIELD_TYPES = {
  OPPORTUNITY: 'opportunity',
  ACCOUNT: 'account',
};

export const HTTP_ERROR_MESSAGES = {
  unauthorized: 'Unexpected Authorization Error',
};

export const LINKED_IN_SAVE_KEY = 'linkedInToken';

export const OTHER_USERS_CAN_OVERRIDE_TEMPLATE_FIELD_ID =
  'otherUsersCanOverrideTemplate';

export const OVERRIDE_TEMPLATE_FIELD_ID = 'overrideTemplate';

export const MASTER_FLOW_ENHANCEMENT_FEATURE_FLAG = 'master-flow-enhancement';

export const DISABLE_STEP_PERSONALIZATION_ID = 'disablePersonalization';
export const DISABLE_STEP_PERSONALIZATION_FEATURE_FLAG =
  'lock-step-personalization';

export const HTML_BODY_ID = 'htmlBody';
