import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { selectors, actions } from '@groove-labs/groove-ui';
import moment from 'moment-timezone';
import Chip from 'Modules/Actions/containers/Chip';
import { List, Map } from 'immutable';
import {
  FILTER_MENU_GROUP_ID,
  FIELD_ID_TO_CHIP_TITLE,
  FILTER_TIMEZONE_FIELD_ID,
  FILTER_CREATOR_FIELD_ID,
  FILTER_ASSIGNEE_FIELD_ID,
} from 'Modules/Actions/constants/filterMenu';
import { READABLE_NAME_LOOK_UP } from 'Modules/Actions/constants';
import { getActionFilters } from 'Modules/Actions/selectors';
import ShowChipsButton from 'Modules/Actions/components/ShowChipsButton';
import {
  updateActionFilters,
  fetchFilteredActions,
  saveActionFilters,
} from 'Modules/Actions/actions';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: 40,
    margin: '5px 0',
    alignItems: 'center',
  },
  hiddenRoot: {
    display: 'none',
  },
  chipsContainer: {
    marginRight: 10,
    height: '100%',
  },
  linkButton: {
    cursor: 'pointer',
    minWidth: 90,
    outline: 'none',
    marginRight: 15,
  },
  chip: {
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    minWidth: 50,
    margin: '5px 15px 0px 0px',
    height: 16,
    borderRadius: 3,
    background: theme.palette.text.secondary,
    padding: '4px 6px',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  chipText: {
    color: theme.palette.text.neutralColor,
    fontSize: 12,
    width: '100%',
    textAlign: 'center',
  },
}));

const { getGroupFields } = selectors.form;
const { deleteGroup } = actions.form;

type ChipsRowTypes = {
  savedFilters: Map<string, string | number>;
  updateActionFilters: (filter: Map<string, string | number>) => void;
  fetchFilteredActions: () => void;
  saveActionFilters: () => void;
  deleteGroup: (group: { groupId: string }) => void;
};

const ChipsRow: React.FC<ChipsRowTypes> = ({
  savedFilters,
  updateActionFilters,
  fetchFilteredActions,
  saveActionFilters,
  deleteGroup,
}) => {
  const classes = useStyles();
  const [isHidden, setIsHidden] = React.useState<boolean>(false);

  const getChipsText = (prefix, currentValue, fieldId) => {
    if (fieldId === FILTER_TIMEZONE_FIELD_ID) {
      let value = null;
      if (List.isList(currentValue)) {
        value = currentValue.flatten(Infinity).first();
      } else if (Array.isArray(currentValue)) {
        value = currentValue.flat(Infinity)[0];
      } else {
        value = currentValue;
      }
      const timezone = moment().tz(value).format('z');
      return `${prefix} ${timezone}`;
    }

    if (
      fieldId === FILTER_CREATOR_FIELD_ID ||
      fieldId === FILTER_ASSIGNEE_FIELD_ID
    ) {
      return `${prefix} user #${currentValue}`;
    }
    return `${prefix} ${READABLE_NAME_LOOK_UP[currentValue]}`;
  };

  const onRemove = (fieldId, value) => () => {
    const newSavedFilter = {
      ...savedFilters,
      [fieldId]: savedFilters[fieldId].filter(item => item !== value),
    };
    updateActionFilters(newSavedFilter);
    fetchFilteredActions();
    saveActionFilters();
  };

  const clearFilters = () => {
    updateActionFilters(Map({}));
    deleteGroup({ groupId: FILTER_MENU_GROUP_ID });
    fetchFilteredActions();
    saveActionFilters();
  };

  const getChips = () => {
    const filters = Object.keys(savedFilters);

    return filters
      .map(fieldId => {
        const currentFilterValue = savedFilters[fieldId];
        const prefix = FIELD_ID_TO_CHIP_TITLE[fieldId];
        if (!currentFilterValue && typeof currentFilterValue !== 'object')
          return null;
        return currentFilterValue.map(value => (
          <Chip
            key={`${FILTER_MENU_GROUP_ID}-${fieldId}-${value}`}
            text={getChipsText(prefix, value, fieldId)}
            fieldId={fieldId}
            value={value}
            onRemove={onRemove(fieldId, value)}
          />
        ));
      })
      .reduce((accum, curr) => {
        if (List.isList(curr)) {
          return [...accum, ...curr.toArray()];
        }
        return [...accum, curr];
      }, []);
  };

  const getFilterCount = () => {
    const filters = Object.keys(savedFilters);

    let total = 0;
    filters.forEach(fieldId => {
      const field = savedFilters[fieldId];
      if (field && typeof field === 'object') {
        total += field.length || 0;
      }
    });
    return total;
  };

  const getHideButton = filterCount => {
    return filterCount >= 4 ? (
      <a
        className={classes.linkButton}
        onClick={toggleHiddenState}
        role="button"
        tabIndex={-1}
      >
        {isHidden ? 'Show Filters' : 'Hide Filters'}
      </a>
    ) : null;
  };

  const getClearButton = filterCount => {
    return filterCount >= 2 ? (
      <a
        className={classes.linkButton}
        onClick={clearFilters}
        role="button"
        tabIndex={-1}
      >
        Clear All
      </a>
    ) : null;
  };

  const toggleHiddenState = () => setIsHidden(!isHidden);

  const filterCount = getFilterCount();

  return (
    <div
      className={cx({
        [classes.root]: filterCount > 0,
        [classes.hiddenRoot]: filterCount === 0,
      })}
    >
      <div className={classes.chipsContainer}>
        {isHidden ? (
          <ShowChipsButton
            onClick={toggleHiddenState}
            filterCount={filterCount}
          />
        ) : (
          getChips()
        )}
      </div>

      {getHideButton(filterCount)}
      {getClearButton(filterCount)}
    </div>
  );
};

export default connect(
  state => ({
    filterData: getGroupFields(state, { groupId: FILTER_MENU_GROUP_ID }),
    savedFilters: getActionFilters(state).toJS(),
  }),
  {
    updateActionFilters,
    fetchFilteredActions,
    saveActionFilters,
    deleteGroup,
  }
)(ChipsRow);
