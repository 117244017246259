import { createSelector } from 'reselect';
import { getIsThirdPartyDialerEnabled } from 'Modules/Shared/selectors/users';

const dialerSettings = state => state.getIn(['dialer', 'settings']);

export const getDialerEnabled = createSelector(
  dialerSettings,
  dialerSettings => dialerSettings.dialerEnabled
);

export const getUserPhoneNumberPresent = createSelector(
  dialerSettings,
  dialerSettings => dialerSettings.phoneNumberPresent
);

export const getSmsEnabled = createSelector(
  dialerSettings,
  dialerSettings => dialerSettings.smsEnabled
);

export const smsStepTypeEnabled = createSelector(
  dialerSettings,
  getIsThirdPartyDialerEnabled,
  (dialerSettings, thirdPartyDialerEnabled) =>
    dialerSettings.smsEnabled || thirdPartyDialerEnabled
);

export const smsEnabled = createSelector(
  dialerSettings,
  dialerSettings => dialerSettings.smsEnabled
);

export const callActivityCache = state =>
  state.getIn(['dialer', 'callActivityCache']);

export const makeFetchCallActivityCacheValue = key => {
  return createSelector(callActivityCache, cache => cache.get(key));
};

export const isCallInProgress = state =>
  state.getIn(['dialer', 'isCallInProgress']);

export const getDialerIsOpen = state =>
  state.getIn(['dialer', 'isOpen'], false);
