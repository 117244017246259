import { Link } from 'Utils/history';
import ZeroActionsState from 'Modules/ActionsRewrite/components/tableTabs/zeroState/ZeroActionsState';

export default function ActiveActionsZeroState() {
  return (
    <ZeroActionsState
      title="You don't have any Actions due."
      subtitle={
        <>
          Actions will populate from
          <Link to="/flows"> Flows</Link>,<Link to="/spaces"> Spaces</Link> or
          One Off Actions.
        </>
      }
    />
  );
}
