import { createSelector } from 'reselect';
import { Map, List } from 'immutable';
import { OAUTH_PROVIDERS } from 'Modules/Shared/constants';
import { REQUIRE_ADDITIONAL_CALL_LOG_FIELDS_KEY } from 'Modules/Settings/constants/additionalLoggingFields';

export const getCsrfToken = state => state.getIn(['users', 'csrfToken']);

export const getLinkedInSAT = state => state.getIn(['users', 'linkedInSAT']);

export const currentUser = state => state.getIn(['users', 'currentUser']);

export const getCurrentUserOrgSettings = state =>
  state.getIn(['users', 'currentUser', 'orgSettings']);

export const getCurrentUserRole = createSelector(currentUser, currentUser =>
  currentUser ? currentUser.get('role') : ''
);

export const getIsGrooveCurrentUser = state =>
  state.getIn(['users', 'currentUser', 'isGrooveUser']);

export const getUserCampaignPermission = state =>
  state.getIn(['users', 'currentUser', 'canCreateSfdcCampaigns'], false);

export const getIsFlowActionsSendAllDisabled = state =>
  state.getIn(['users', 'currentUser', 'isFlowActionsSendAllDisabled'], false);

export const getStickyActionFilters = state =>
  state.getIn(['users', 'currentUser', 'stickyFilters'], Map());

export const getChildCampaignEnforcingOptionEnabled = createSelector(
  getCurrentUserOrgSettings,
  userOrgSettings => {
    return userOrgSettings.get('childCampaignEnforcingOptionEnabled');
  }
);

export const getCanUserEnforceChildCampaignCreation = createSelector(
  getUserCampaignPermission,
  getChildCampaignEnforcingOptionEnabled,
  (canMakeCampaign, childCampaignEnforcingOptionEnabled) => {
    return canMakeCampaign && childCampaignEnforcingOptionEnabled;
  }
);

export const getUserCanCreateMasterFlow = createSelector(
  getCurrentUserOrgSettings,
  userOrgSettings => {
    return userOrgSettings.get('masterFlowCreationEnabled');
  }
);

export const getCurrentUserCanCreateMasterFlow = createSelector(
  getUserCanCreateMasterFlow,
  getCurrentUserRole,
  (masterFlowEnabled, currentUserRole) =>
    currentUserRole !== 'agent' || masterFlowEnabled
);

export const getIsEditEmailAddressInWorkstepDisabled = state =>
  state.getIn(
    [
      'users',
      'currentUser',
      'orgSettings',
      'isEditEmailAddressInWorkstepDisabled',
    ],
    false
  );

export const getOrgOneFlowRestrictionEnabled = state =>
  state.getIn(
    ['users', 'currentUser', 'orgSettings', 'oneFlowRestrictionEnabled'],
    false
  );

export const getRequireAdditionalCallLogFields = state =>
  state.getIn(
    [
      'users',
      'currentUser',
      'orgSettings',
      REQUIRE_ADDITIONAL_CALL_LOG_FIELDS_KEY,
    ],
    false
  );

export const isLoggedIn = state => state.getIn(['users', 'isLoggedIn']);

export const getActivityResultOptions = state =>
  state.getIn(['users', 'globalSettings', 'activityResults'], new List());
export const getTemplateTypes = state =>
  state.getIn(['users', 'globalSettings', 'templateTypes'], new List());
export const canUseLoginAs = createSelector(currentUser, currentUser => {
  if (!currentUser) {
    return false;
  }

  const isAdmin = currentUser.get('role') === 'admin';
  const isGrooveUser = currentUser.get('isGrooveUser');

  // adding `|| false` because javascript is sometimes dumb
  return isAdmin || isGrooveUser || false;
});

export const loginAsActive = createSelector(currentUser, currentUser => {
  if (!currentUser) {
    return false;
  }

  return currentUser.get('loginAsActive') || false;
});

export const getIntegrationState = key =>
  createSelector(currentUser, currentUser =>
    currentUser.getIn(['orgSettings', key], false)
  );

export const getTaskField = state =>
  state
    .getIn(['users', 'currentUser', 'orgSettings', 'taskFields'], List())
    .get(0, '');

export const getFlowsTaskFieldsRequired = state =>
  state.getIn(
    ['users', 'currentUser', 'orgSettings', 'flowsTaskFieldsRequired'],
    false
  );

export const getAddLicenseDisabled = createSelector(
  currentUser,
  currentUser =>
    !!(currentUser || Map()).getIn(
      ['orgSettings', 'orgDisableAddLicenses'],
      false
    )
);

export const getLinkedInClientId = createSelector(currentUser, currentUser =>
  currentUser.getIn(['orgSettings', 'orgLinkedinClientId'], '')
);

export const getEmailSendInterval = createSelector(currentUser, currentUser => {
  if (!currentUser) {
    return false;
  }

  return currentUser.getIn(['orgSettings', 'emailSendInterval']);
});

export const getRemainingDailyEmailSendQuota = state =>
  state.getIn(['users', 'currentUser', 'remainingDailyEmailSendQuota']);

export const getIsSfdcAdmin = state =>
  state.getIn(['users', 'currentUser', 'isSfdcAdmin'], false);

export const getIsGrooveAdmin = createSelector(
  currentUser,
  currentUser => currentUser?.get('role') === 'admin'
);

export const getIsMicrosoftUser = createSelector(
  currentUser,
  currentUser =>
    currentUser?.get('provider') === OAUTH_PROVIDERS.MICROSOFT_GRAPH
);

export const getIsGoogleUser = createSelector(
  currentUser,
  currentUser => currentUser?.get('provider') === OAUTH_PROVIDERS.GOOGLE
);

export const getOAuthProviderName = createSelector(
  getIsMicrosoftUser,
  getIsGoogleUser,
  (isMicrosoftUser, isGoogleUser) => {
    if (isMicrosoftUser) {
      return 'Microsoft';
    } else if (isGoogleUser) {
      return 'Google';
    }
    throw new Error('Unknown OAuth provider');
  }
);

export const getActivityResultDisplayedForCalls = state =>
  state.getIn([
    'users',
    'currentUser',
    'orgSettings',
    'activityResultDisplayedForCalls',
  ]);

export const getActivityResultIsMandatoryForCalls = createSelector(
  currentUser,
  currentUser => {
    if (!currentUser) {
      return false;
    }
    return currentUser.getIn([
      'orgSettings',
      'activityResultIsMandatoryForCalls',
    ]);
  }
);

export const getArePausedFlows = createSelector(
  currentUser,
  currentUser => currentUser && currentUser.get('pausedFlows')
);

export const getIsHolidayPauseActive = createSelector(
  currentUser,
  currentUser => currentUser && currentUser.get('holidayPausedFlows')
);

export const getUserDwaEnabled = createSelector(currentUser, currentUser =>
  currentUser?.get('dwaEnabled')
);

export const getDailyEmailSendLimit = state =>
  state.getIn(['users', 'currentUser', 'dailyEmailSendLimit']);

export const getIsMicrosoftOrg = state =>
  state.getIn(['users', 'currentUser', 'orgSettings', 'isMicrosoftOrg']);

export const getIsThirdPartyDialerEnabled = createSelector(
  currentUser,
  currentUser => {
    if (!currentUser) return false;

    const orgSettings = currentUser.get('orgSettings');
    if (!orgSettings) return false;

    return orgSettings.get('thirdPartyDialerEnabled') || false;
  }
);

export const getIsGoogleOrg = state =>
  state.getIn(['users', 'currentUser', 'orgSettings', 'isGoogleOrg']);

export const getCanAdjustMasterFlowTemplates = state =>
  state.getIn([
    'users',
    'currentUser',
    'orgSettings',
    'canAdjustMasterFlowTemplates',
  ]);

export const getIsDirectAttachmentsEnabled = state =>
  state.getIn([
    'users',
    'currentUser',
    'orgSettings',
    'directAttachmentEnabled',
  ]);

export const getCalendarSyncSettingEnabled = state =>
  state.getIn(['users', 'calendarSyncSettingEnabled']);

export const getEmailSyncSettingEnabled = state =>
  state.getIn(['users', 'emailSyncSettingEnabled']);

export const getTeamLeadCanManageTeamEnabled = state =>
  state.getIn(['users', 'currentUser', 'orgSettings', 'teamLeadCanManageTeam']);

export const getFilterTeamLeadFromAnalytics = state =>
  state.getIn([
    'users',
    'currentUser',
    'orgSettings',
    'filterTeamLeadFromAnalytics',
  ]);

export const getMoboEnabled = createSelector(currentUser, currentUser => {
  return (
    currentUser &&
    currentUser.getIn(['orgSettings', 'manageOnBehalfOfEnabled'], false)
  );
});

/**
 * Get the active user id
 *
 * @remarks
 * When the user is Managing On Behalf Of another user it will
 * return the MOBO user id, otherwise will return the current user id
 */
export const getUserId = state => {
  // eslint-disable-next-line global-require
  const moboUser = require('Modules/App/Store/useStore').default.getState()
    .moboUser;
  return moboUser?.id || state.getIn(['users', 'currentUser', 'id']);
};
