import { createActions, keyMirror } from '@groove-labs/action-utils';

export const actionTypes = createActions({
  CONVERSATIONS: {
    ...keyMirror(['SET_ACTIVE_PERSON_SFDCID_AND_EMAIL']),
  },
}).CONVERSATIONS;

export function setActivePersonSfdcIdAndEmail(person) {
  return {
    type: actionTypes.SET_ACTIVE_PERSON_SFDCID_AND_EMAIL,
    payload: person,
  };
}
