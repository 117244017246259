import numeral from 'numeral';
import pluralize from 'pluralize';

export default function postFlowImportMessage(numRows, batchJobData) {
  let message;
  if (batchJobData) {
    const totalCount = batchJobData.get('total') || 0;
    const failureCount = batchJobData.get('failures') || 0;
    const successCount = totalCount - failureCount;
    message = `${numeral(successCount).format('0,0')} ${pluralize(
      'person',
      successCount
    )} added to Flow`;
    if (failureCount > 0) {
      message += `, ${numeral(failureCount).format('0,0')} failed`;
    }
  } else {
    message = `${numeral(numRows).format('0,0')} ${pluralize(
      'person',
      numRows
    )} added to Flow`;
  }
  return message;
}
