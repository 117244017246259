import cx from 'classnames';
import { RIT } from '@groove-labs/groove-ui';
import noop from 'lodash-es/noop';
import { List, Map, isImmutable, Set } from 'immutable';
import { intersection } from 'lodash-es';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import recursiveToJs from 'Utils/recursive-to-js';
import { ArrowDropDown as DropDownIcon } from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import { LOCAL_DIAL_TABLE_ID } from 'Modules/Settings/constants/internationalProvisioning';

const ALL_ROWS_SELECTED = 'all';
const ANY_ROWS_SELECTED = 'any';
const ROWS_PARTIALLY_SELECTED = 'partial';

const styles = theme => ({
  root: {},
  checkboxRow: {
    width: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: 0,
    verticalAlign: 'top',
  },
  // Override these in the parent component to style the table
  tableRow: {},
  tableCell: {
    fontSize: 12,
  },
  tableHeadRow: {},
  tableHeadCell: {},
  tableHeadLabel: {
    fontSize: 13,
    fontWeight: 600,
  },
  checkAllBox: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: 0,
    paddingBottom: 4,
    paddingTop: 4,
    position: 'relative',
  },
  icon: {
    color: theme.palette.text.primary,
  },
  spaceNoWrap: {
    whiteSpace: 'nowrap',
  },
  multiSelectDropDown: {
    cursor: 'pointer',
    position: 'absolute',
    top: '20%',
    right: '-22px',
    fontSize: '30px',
  },
  emptyState: {
    display: 'table-row',
  },
  emptyStateBody: {
    width: '100%',
    borderBottom: 'unset',
    paddingTop: theme.spacing.unit * 10,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '300px',
    verticalAlign: 'top',
  },
  emptyStateTypography: {
    maxWidth: '360px',
    textAlign: 'center',
    margin: '0 auto',
  },
  emptyStateAction: {
    marginTop: theme.spacing.unit * 2,
    background: 'none',
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    border: 'none',
  },
});

@withStyles(styles)
export default class Table extends Component {
  static displayName = 'Table';
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,

    // TODO maybe not object, but some custom thing (helper class instance?)
    columns: PropTypes.oneOfType([
      PropTypes.instanceOf(List),
      PropTypes.instanceOf(Array),
    ]).isRequired,
    rows: PropTypes.oneOfType([
      PropTypes.instanceOf(List),
      PropTypes.instanceOf(Array),
    ]).isRequired,
    selected: PropTypes.oneOfType([
      PropTypes.instanceOf(List),
      PropTypes.instanceOf(Array),
      PropTypes.instanceOf(Set),
    ]),
    orderBy: PropTypes.string,
    orderDirection: PropTypes.oneOf(['asc', 'desc']),
    selectRowOnClick: PropTypes.bool,
    tableRowHover: PropTypes.bool,
    tableId: PropTypes.string,
    emptyState: PropTypes.object,

    rowIdSelector: PropTypes.func,
    rowKeySelector: PropTypes.func,
    onRowClick: PropTypes.func,
    onRequestSort: PropTypes.func.isRequired,
    onRequestSelectRows: PropTypes.func,
    onRequestUnselectRows: PropTypes.func,
    selectable: PropTypes.bool,
    isEmpty: PropTypes.bool,
    csvLookup: PropTypes.bool,
    selectAllWithoutWarnings: PropTypes.bool,
    selectMenuOpen: PropTypes.bool,
    selectMenuAnchorEl: PropTypes.instanceOf(Object),
    handleSelectMenuOpen: PropTypes.func,
    handleSelectMenuClose: PropTypes.func,
    handleSelectMenuItemSelect: PropTypes.func,
  };

  static defaultProps = {
    selected: [],
    orderBy: null,
    orderDirection: 'desc',
    selectRowOnClick: false,
    tableRowHover: true,
    selectable: true,
    isEmpty: false,

    onRowClick: noop,
    rowIdSelector: row => {
      if (Map.isMap(row)) return row.get('id');
      return row.id;
    },
    rowKeySelector: row => {
      if (Map.isMap(row)) return row.get('id') || row.get('label');
      return row.id || row.label;
    },
    onRequestSort: orderBy => console.warn('Table: onRequestSort', orderBy), // eslint-disable-line no-console
    onRequestSelectRows: selectedRows => {
      console.warn('Table: onRequestSelectRows', selectedRows); // eslint-disable-line no-console
    },
    onRequestUnselectRows: selectedRows => {
      console.warn('Table: onRequestUnselectRows', selectedRows); // eslint-disable-line no-console
    },
  };

  constructor(props) {
    super(props);

    const { rows, columns, selected } = props;
    this.state = {
      rows: recursiveToJs(rows),
      columns: recursiveToJs(columns),
      selected: recursiveToJs(selected),
    };
  }

  componentWillReceiveProps({ rows, columns, selected }) {
    if (rows || columns || selected) {
      const newState = {};
      newState.rows = recursiveToJs(rows);
      newState.columns = recursiveToJs(columns);
      newState.selected = recursiveToJs(selected);

      this.setState(newState);
    }
  }

  createSortHandler = property => {
    return () => {
      const { onRequestSort } = this.props;

      onRequestSort(property);
    };
  };

  createRowSelectHandler = rowId => {
    return (_, checked) => {
      const { onRequestSelectRows, onRequestUnselectRows } = this.props;

      if (checked) {
        onRequestSelectRows([rowId]);
      } else {
        onRequestUnselectRows([rowId]);
      }
    };
  };

  rowClickHandler = (event, rowId) => {
    const {
      selectRowOnClick,
      onRequestSelectRows,
      onRequestUnselectRows,
      onRowClick,
    } = this.props;

    onRowClick(rowId);

    if (!selectRowOnClick) return;

    // An element inside the cell was clicked, ignore row select
    if (event.target !== event.currentTarget) return;

    if (this.isRowSelected(rowId)) {
      onRequestUnselectRows([rowId]);
    } else {
      onRequestSelectRows([rowId]);
    }
  };

  standardRows = () => {
    const { rows } = this.props;
    const filteredRows = rows.filter(row => row.rowType !== 'custom');
    return isImmutable(filteredRows) ? filteredRows.toJS() : filteredRows;
  };

  /**
   * Checks whether all, any or some of the rows are selected.
   *
   * @param {String} selectedCase oneOf: 'all', 'any' or 'partial'
   *
   * @return {Boolean}
   */
  isRowsSelected = selectedCase => {
    const { selected } = this.state;
    const { rowIdSelector } = this.props;

    const standardRows = this.standardRows();
    const selectableRows = standardRows.filter(row => !row.checkboxDisabled);
    const rowIds = selectableRows.map(rowIdSelector);
    const selectedLength = intersection(rowIds, selected).length;

    switch (selectedCase) {
      case ALL_ROWS_SELECTED:
        return selectedLength === selectableRows.length;
      case ANY_ROWS_SELECTED:
        return selectedLength > 0;
      case ROWS_PARTIALLY_SELECTED:
        return selectedLength < selectableRows.length;
      default:
        return false;
    }
  };

  handleSelectAll = () => {
    const { rows } = this.state;
    const { rowIdSelector, onRequestUnselectRows, onRequestSelectRows } =
      this.props;
    const selectableRows = rows.filter(row => !row.checkboxDisabled);
    const rowIds = selectableRows.map(rowIdSelector);

    if (this.isRowsSelected(ROWS_PARTIALLY_SELECTED)) {
      onRequestSelectRows(rowIds);
    } else {
      onRequestUnselectRows(rowIds);
    }
  };

  isRowSelected = rowId => {
    const { selected } = this.state;

    return selected.includes(rowId);
  };

  render() {
    const { rows, columns } = this.state;
    const {
      classes,
      orderBy,
      orderDirection,
      rowIdSelector,
      rowKeySelector,
      tableRowHover,
      selectable,
      isEmpty,
      csvLookup,
      selectAllWithoutWarnings,
      selectMenuOpen,
      selectMenuAnchorEl,
      handleSelectMenuOpen,
      handleSelectMenuClose,
      handleSelectMenuItemSelect,
      tableId,
      emptyState,
    } = this.props;

    const showEmptyState = () => {
      if (tableId === LOCAL_DIAL_TABLE_ID && rows.length === 0)
        return (
          <MuiTableRow className={classes.emptyState}>
            <MuiTableCell className={classes.emptyStateBody} colSpan="100%">
              <Typography
                className={classes.emptyStateTypography}
                variant="body1"
              >
                {emptyState.message}
              </Typography>
              <button
                onClick={emptyState.action}
                className={classes.emptyStateAction}
              >
                {emptyState.actionText}
              </button>
            </MuiTableCell>
          </MuiTableRow>
        );

      return null;
    };

    return (
      <MuiTable classes={{ root: classes.root }} aria-label="Table">
        <MuiTableHead>
          <MuiTableRow classes={{ root: classes.tableHeadRow }}>
            {RIT(selectable, () => (
              <MuiTableCell
                key="checkbox"
                padding="checkbox"
                className={classes.checkAllBox}
              >
                <Checkbox
                  color="primary"
                  indeterminate={
                    this.isRowsSelected(ANY_ROWS_SELECTED) &&
                    this.isRowsSelected(ROWS_PARTIALLY_SELECTED)
                  }
                  checked={this.isRowsSelected(ALL_ROWS_SELECTED) && !isEmpty}
                  onChange={this.handleSelectAll}
                  disabled={isEmpty}
                />
                {RIT(csvLookup, () => (
                  <>
                    <DropDownIcon
                      onClick={handleSelectMenuOpen}
                      classes={{ root: classes.multiSelectDropDown }}
                    />
                    <div className={classes.topBarLeftRowSelectContainer}>
                      <Menu
                        open={selectMenuOpen}
                        anchorEl={selectMenuAnchorEl}
                        onClose={handleSelectMenuClose}
                      >
                        <MenuItem
                          onClick={() =>
                            handleSelectMenuItemSelect('selectAll')
                          }
                        >
                          Select All
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleSelectMenuItemSelect('unSelectAll')
                          }
                        >
                          Un-Select All
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleSelectMenuItemSelect('unSelectWithWarning')
                          }
                        >
                          Un-Select All With Warnings
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleSelectMenuItemSelect('withoutWarning')
                          }
                        >
                          {selectAllWithoutWarnings
                            ? 'Un-Select all without warnings'
                            : 'Select all without warnings'}
                        </MenuItem>
                      </Menu>
                    </div>
                  </>
                ))}
              </MuiTableCell>
            ))}
            {columns.map(
              ({
                id,
                label,
                sortable = false,
                orderDirection: defaultOrderDirection,
                type,
                ...rest
              }) => {
                return (
                  <MuiTableCell
                    aria-label="Header Cell"
                    key={id}
                    classes={{
                      root: cx(
                        classes.tableCell,
                        classes.tableHeadCell,
                        classes.tableHeadLabel
                      ),
                    }}
                    {...rest}
                  >
                    {RIT(sortable, () => (
                      <MuiTableSortLabel
                        key={id}
                        active={orderBy === id}
                        direction={orderDirection}
                        onClick={this.createSortHandler(id)}
                        classes={{
                          root: classes.tableHeadLabel,
                          icon: classes.icon,
                        }}
                      >
                        <Typography variant="body2">{label}</Typography>
                      </MuiTableSortLabel>
                    ))}

                    {RIT(!sortable, () => label)}
                  </MuiTableCell>
                );
              },
              this
            )}
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          {showEmptyState()}
          {rows.map(row => {
            const type = row.rowType || 'default';
            const rowId = rowIdSelector(row);
            const rowKey = rowKeySelector(row);
            const selected = this.isRowSelected(rowId);
            switch (type) {
              case 'custom':
                return row.contents;
              default:
                return (
                  <MuiTableRow
                    classes={{ root: cx(classes.tableRow, row.classes) }}
                    key={rowKey}
                    selected={selected}
                    hover={tableRowHover}
                    aria-label="Row"
                  >
                    {RIT(selectable, () => (
                      <MuiTableCell
                        key="checkbox"
                        classes={{ root: classes.checkboxRow }}
                      >
                        <Checkbox
                          color="primary"
                          checked={selected}
                          onChange={this.createRowSelectHandler(rowId)}
                          disabled={row.checkboxDisabled || false}
                        />
                      </MuiTableCell>
                    ))}
                    {columns.map(
                      ({
                        id,
                        sortable,
                        orderDirection: defaultOrderDirection,
                        type,
                        noWrap,
                        ...rest
                      }) => {
                        return (
                          <MuiTableCell
                            aria-label="Cell"
                            classes={{
                              root: cx(classes.tableCell, row.classesCells, {
                                [classes.spaceNoWrap]: noWrap,
                              }),
                            }}
                            key={`row:${rowId}-column:${id}`}
                            onClick={event =>
                              !row.checkboxDisabled &&
                              this.rowClickHandler(event, rowId)
                            }
                            {...rest}
                          >
                            {row[id]}
                          </MuiTableCell>
                        );
                      }
                    )}
                  </MuiTableRow>
                );
            }
          })}
        </MuiTableBody>
      </MuiTable>
    );
  }
}
