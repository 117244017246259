import { all, put } from 'redux-saga/effects';
import { setContentLoading } from 'Modules/Shared/actions/app';

export function* upstart() {
  yield put(setContentLoading(false));
}

export default function* root() {
  yield all([]);
}
