import { SearchPerson } from '@groove/api/gateway/v1/people';
import { crmObject } from '@groove/api/gateway/v1/actionCompose';

import useStore from '../store/useStore';

import { setSalesforceRelatedTo } from './loggingToMethods';

const updateLoggingTo = (finalLoggingToWho: crmObject[]): void => {
  useStore.getState().updateCurrentLoggingTo({ who: finalLoggingToWho });
  useStore.getState().updateOriginalLoggingTo({ who: finalLoggingToWho });
};
export const setUpLoggingTo = (
  who: SearchPerson | null,
  loggingToWho?: crmObject[] | undefined,
): void => {
  // loggingToWho should be array type when there are multiple loggingTo users.
  if (!who) return;
  const finalLoggingToWho =
    loggingToWho && loggingToWho.length > 0
      ? loggingToWho.map(person => ({
          id: person.id,
          text: person.text || person?.attributes?.name || '',
          type: person.type,
          autoCreated: person.autoCreated ?? false,
        }))
      : [
          {
            id: who.sfdcId,
            text: who.name,
            type: who.sfdcType,
            autoCreated: true,
          },
        ];
  updateLoggingTo(finalLoggingToWho);
  setSalesforceRelatedTo([who.email], who.sfdcId, true, true);
};
