import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

import { LOGROCKET_DISABLED_ORG_IDS } from 'Modules/App/constants';
import setupLogRocketReact from 'Modules/App/utils/createSetupLogRocketReact';

export default function setupLogRocket(currentUser) {
  const deployEnv = process.env.REACT_APP_DEPLOY_ENV || 'development';
  const orgBlacklisted =
    deployEnv === 'production' &&
    LOGROCKET_DISABLED_ORG_IDS.includes(currentUser.orgId);
  const shouldSetupLogRocket =
    process.env.NODE_ENV === 'production' && !orgBlacklisted;
  if (shouldSetupLogRocket) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT_ID);

    LogRocket.identify(currentUser.id, {
      name: currentUser.name,
      email: currentUser.email,
    });

    // Sets up Sentry <> LogRocket integration
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra('sessionURL', sessionURL);
      });
      if (window.newrelic) {
        window.newrelic.setCustomAttribute('logrocket_url', sessionURL);
      }
    });
    // Allows LogRocket to categorize sessions by React component
    setupLogRocketReact();
  }
}
