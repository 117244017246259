import { ITheme } from '@fluentui/react';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core';

import useGetFactors from 'Modules/ActionsRewrite/components/customCells/SmartPriority/HoverCard/useGetFactors';
import HoverCardHeader from 'Modules/ActionsRewrite/components/customCells/SmartPriority/HoverCard/hoverCardHeader';
import DisplayTopFactors from 'Modules/ActionsRewrite/components/customCells/SmartPriority/HoverCard/displayTopFactors/displayTopFactors';

/**
 * This component was created from the old actions table.
 * Most of this code is still temporary because Iris has a built in
 * hover-card option for cells
 *
 * The popper was set to top -4 for historical reasons with the previous header,
 * but that is no longer an issue as of April 2024
 */
const useStyles = makeStyles((theme: ITheme) => ({
  hoverCardParent: {
    backgroundColor: '#4b4b4b',
    width: 278,
    position: 'absolute',
    top: -4,
  },
  priorityScoreLabel: {
    color: theme.palette.white,
    fontSize: 12,
  },
  priorityScoreText: {
    color: theme.palette.white,
    fontSize: 12,
  },
}));

type HoverCardParams = {
  anchorEl: HTMLAnchorElement;
  actionId: number;
  smartPriority: number;
};

const HoverCard = ({ anchorEl, actionId, smartPriority }: HoverCardParams) => {
  const styles = useStyles();

  const { data, isLoading } = useGetFactors(actionId);
  const { factors, actionsPriorityScoreLastSyncedAt: lastSyncedAt } =
    data || {};

  return (
    <Popper
      id="mouse-over-popover"
      // Available in v5 but not in current v4...
      // https://mui.com/material-ui/api/popper/
      // sx={{
      //   pointerEvents: 'none',
      // }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="right"
    >
      <Paper className={styles.hoverCardParent}>
        <HoverCardHeader
          lastSyncedAt={lastSyncedAt}
          smartPriority={smartPriority}
          isLoading={isLoading}
        />
        {(factors || []).length > 0 && (
          <>
            <Divider />
            <DisplayTopFactors isLoading={isLoading} factors={factors} />
          </>
        )}
      </Paper>
    </Popper>
  );
};

export default HoverCard;
