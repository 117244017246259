import getClient from './getClient';

const DEFAULT_BEST_LOCAL_DISTANCE = 50;

export type BestLocalNumberParams = {
  toPhoneNumber: string;
  distance?: number;
};

export const LOCAL_DIAL_PARSE_ERROR = 'UnableToParseError';
export const LOCAL_DIAL_PROVISION_ERROR = 'UnableToProvisionError';

export type ApiBestLocalNumber = {
  phone_number: string;
  friendly_name: string;
  message_id?:
    | typeof LOCAL_DIAL_PARSE_ERROR
    | typeof LOCAL_DIAL_PROVISION_ERROR;
  country_code?: string;
  area_code?: string;
};

export type ApiCheckResult = {
  requires_consent: boolean;
};

export type ApiRecordingResult = {
  recording_sid: string;
};

type DropVoicemailOptions = {
  conferenceFriendlyName: string;
  voicemailId: number;
  phoneNumber: string;
  shouldEndUserCall: boolean;
  participantCallSid?: string;
};
type VoicemailDropResult = {
  data?: { message?: string };
  meta: {
    status: number;
    success: boolean;
  };
};

export const getBestLocalNumber = ({
  toPhoneNumber,
  distance = DEFAULT_BEST_LOCAL_DISTANCE,
}: BestLocalNumberParams): Promise<ApiBestLocalNumber> =>
  getClient()
    .get('dialer/best_local_number', {
      searchParams: {
        target_number: toPhoneNumber,
        distance,
      },
    })
    .json();

export const checkNumber = (phoneNumber: string): Promise<ApiCheckResult> =>
  getClient()
    .get('dialer/check_number', {
      searchParams: { phone_number: phoneNumber },
    })
    .json();

export const startRecording = (
  callSid: string,
  toPhoneNumber: string,
): Promise<ApiRecordingResult> =>
  getClient()
    .post('dialer/call_recording_start', {
      json: {
        call_sid: callSid,
        to_phone_number: toPhoneNumber,
      },
    })
    .json();

export const stopRecording = (callSid: string): Promise<ApiRecordingResult> =>
  getClient()
    .post('dialer/call_recording_stop', {
      json: {
        call_sid: callSid,
      },
    })
    .json();

export const voicemailDrop = ({
  conferenceFriendlyName,
  voicemailId,
  phoneNumber,
  shouldEndUserCall,
  participantCallSid,
}: DropVoicemailOptions): Promise<VoicemailDropResult> =>
  getClient()
    .post('dialer/voicemail_drop', {
      json: {
        variables: {
          conference_name: conferenceFriendlyName,
          voicemail_id: voicemailId,
          to_phone_number: phoneNumber,
          should_end_user_call: shouldEndUserCall,
          participant_call_sid: participantCallSid,
        },
      },
    })
    .json();
