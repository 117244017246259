import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { templateUnstar } from '../gateway/v1/template';

export type UseTemplateUnstarParams = {
  templateId: number;
};

const useTemplateUnstar = (): UseMutationResult<
  unknown,
  unknown,
  UseTemplateUnstarParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(({ templateId }) => templateUnstar(templateId), {
    onSuccess: () => {
      queryClient.invalidateQueries('favoriteTemplatesGet');
    },
  });
};

export default useTemplateUnstar;
