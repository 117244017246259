import { FC, useEffect, useState } from 'react';
import ButtonArray from '@groove/ui/Components/ButtonArray';
import {
  ActionType,
  NEW_ACTION_ID,
} from '@groove/api/gateway/v1/actionCompose';
import {
  useGetCurrentUser,
  useGetOrgSettings,
} from '@groove/api/hooks/useUsers';
import PivotHeader from '@groove/ui/Components/PivotHeader';
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';
import { CrmTypes } from '@groove/api/gateway/v1/activities';
import { useGetActivityOptions } from '@groove/api/hooks/useActivities';
import useDialerStore from '@groove/dialer-components/hooks/useStore';

import useStore from '../store/useStore';
import {
  ACTION_TYPES,
  TYPES,
  fieldsPivotItems,
  FieldsPivotItemValues,
  SF_MAPPING_TYPES,
  ACTION_ORIGIN,
} from '../constants';
import CustomForm from '../component/CustomForm';
import StandardFields from '../component/StandardFields';

const TaskPane: FC = () => {
  const [taskTypes, setTaskTypes] = useState<CrmTypes[]>([]);

  const customFieldsFlag = useVariation('additional-fields-dialer');
  const loggingForm = useStore(store => store.action.dynamicData?.loggingForm);
  const selectedPivot = useStore(
    store => store.otherValues.selectedFieldsPivot,
  );
  const personStepId = useStore(store => store.action.personStepId);
  const crmTaskType = useStore(store => store.action.crmTaskType);
  const type = useStore(store => store.action.type);
  const id = useStore(store => store.action.id);
  const callState = useDialerStore(store => store.callState);
  const invokingApp = useStore(store => store.otherValues.invokingApp);

  // For custom fields
  const showCustomFields =
    customFieldsFlag && loggingForm?.fields?.length > 0 && type === TYPES.CALL;

  useEffect(() => {
    if (loggingForm?.fields?.length) {
      const {
        updateAction,
        action: { dynamicData },
      } = useStore.getState();
      updateAction({
        dynamicData: { ...dynamicData, loggingFormFlag: customFieldsFlag },
      });
    }
  }, [loggingForm?.fields?.length, customFieldsFlag]);

  // For Type Change
  const handleTypeChange = (newType: ActionType): void => {
    const { body } = useStore.getState().action;
    const renderType =
      ACTION_TYPES.find(typeData => (typeData.value as ActionType) === newType)
        ?.label || 'Task';
    useStore.getState().setOtherValues({ wysiwygEdited: false });
    if (
      (newType === 'STEP_SUBTYPE_SMS' ||
        newType === 'STEP_SUBTYPE_LINKEDIN_INMAIL' ||
        newType === 'STEP_SUBTYPE_LINKEDIN_CONNECT') &&
      body &&
      body.length > 1 &&
      !(
        type === 'STEP_SUBTYPE_SMS' ||
        type === 'STEP_SUBTYPE_LINKEDIN_INMAIL' ||
        type === 'STEP_SUBTYPE_LINKEDIN_CONNECT'
      )
    ) {
      useStore.getState().setNewModal({
        isOpen: true,
        onConfirm: () => useStore.getState().updateAction({ type: newType }),
        contentText: `If you change the type to ${renderType}, the current body formatting for this action will be removed`,
        title: 'Are you sure you want to proceed',
        confirmText: `Change to ${renderType}`,
      });
    } else {
      useStore.getState().updateAction({
        type: newType,
        crmTaskType:
          orgSettings && orgSettings.show_sf_task_type_picklist
            ? orgSettings.sf_task_type_mapping[SF_MAPPING_TYPES[newType]]
            : null,
      });
    }
  };

  const { data: orgSettings } = useGetOrgSettings();
  const { data: currentUser } = useGetCurrentUser();

  // if sendoso or sms is disabled for the user, don't show the sendoso or sms action type
  const filteredActionTypes = ACTION_TYPES.filter(
    action_type =>
      !(
        action_type.label === 'Sendoso' &&
        (orgSettings?.sendoso_enabled === false ||
          invokingApp === ACTION_ORIGIN.DESKTOP)
      ) &&
      !(
        action_type.label === 'SMS' &&
        currentUser?.sms_enabled === false &&
        (id === NEW_ACTION_ID || type !== 'STEP_SUBTYPE_SMS')
      ),
  );

  // For CRM Task types
  const taskTypesQuery = useGetActivityOptions();

  useEffect(() => {
    if (taskTypesQuery.data) {
      const { crm_types } = taskTypesQuery.data.data;
      const rawTaskTypes = [{ value: '', label: 'None' }, ...crm_types];
      setTaskTypes(rawTaskTypes);
    }
  }, [taskTypesQuery.data]);

  useEffect(() => {
    if (orgSettings && orgSettings.sf_task_type_mapping && !crmTaskType) {
      const value = orgSettings.sf_task_type_mapping[SF_MAPPING_TYPES[type]];
      useStore.getState().updateAction({
        crmTaskType: value,
      });
    }
  }, [crmTaskType, orgSettings, type]);

  return (
    <div className="p-[12px] flex flex-col flex-1">
      <ButtonArray
        buttonList={filteredActionTypes}
        onChange={type => handleTypeChange(type as ActionType)}
        selectedValue={type}
        dropDownWidth="180px"
        disabled={!!personStepId || callState !== 'not-connected'}
        tooltip={callState !== 'not-connected' ? 'Call is in progress.' : ''}
      />
      {showCustomFields && (
        <>
          <PivotHeader
            selectedValue={selectedPivot}
            headerItems={fieldsPivotItems}
            onSelect={(value: string) => {
              useStore.getState().setOtherValues({
                selectedFieldsPivot: value as FieldsPivotItemValues,
              });
            }}
            className="mb-2 mt-1"
          />

          {selectedPivot === 'custom-fields' && (
            <CustomForm formName="loggingForm" formData={loggingForm} />
          )}
        </>
      )}

      {(selectedPivot === 'standard-fields' || !showCustomFields) && (
        <StandardFields
          taskTypes={taskTypes}
          editSfTaskTypePicklist={
            orgSettings ? orgSettings.edit_sf_task_type_picklist : true
          }
          showSfTaskTypePicklist={
            orgSettings ? orgSettings.show_sf_task_type_picklist : true
          }
        />
      )}
    </div>
  );
};

export default TaskPane;
