import { FC, HTMLProps, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import Text from '@groove/ui/Components/Text';
import calcTextWidth from '@groove/ui/helpers/calcTextWidth';
import useHandleOutsideClicks from '@groove/ui/helpers/useHandleOutsideClicks';
import {
  FullAction,
  RecipientType,
  crmObject,
} from '@groove/api/gateway/v1/actionCompose';
import SelectTag, { SelectItem } from '@groove/ui/Components/SelectTag';

import useStore from '../store/useStore';
import {
  addSFDCObjectToOmnibar,
  setSalesforceRelatedTo,
} from '../utils/loggingToMethods';
import { RECIPIENT_NOT_FOUND } from '../constants';

import EmailRecipientField, {
  EmailRecipientFieldRef,
} from './EmailRecipientField';

type RenderRecipientType = Omit<RecipientType, 'id'> & {
  onCancel?: () => void;
  onClick?: () => void;
  onChange?: (value: SelectItem) => void;
  color?: HTMLProps<HTMLElement>['className'];
  items: SelectItem[];
  displayText: string;
  type?: string;
};

const BUTTON_PADDING = 18 + 8;
const BUTTON_CLOSE_WIDTH = 20;

const EmailRecipientFields: FC = () => {
  const toRef = useRef<EmailRecipientFieldRef>(null);
  const ccRef = useRef<EmailRecipientFieldRef>(null);
  const bccRef = useRef<EmailRecipientFieldRef>(null);
  const minRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isMinimized, setIsMinimized] = useState(true);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isCcOpen, setIsCcOpen] = useState(false);
  const [isBccOpen, setIsBccOpen] = useState(false);
  const [isToClicked, setIsToClicked] = useState(false);
  const [isCcClicked, setIsCcClicked] = useState(false);
  const [isBccClicked, setIsBccClicked] = useState(false);
  const [renderedMin, setRenderedMin] = useState<RenderRecipientType[]>([]);

  const id = useStore(store => store.action.id);
  const who = useStore(store => store.action.who);
  const personStepId = useStore(store => store.action.personStepId);
  const toRecipients = useStore(store => store.action.toRecipients);
  const ccRecipients = useStore(store => store.action.ccRecipients);
  const bccRecipients = useStore(store => store.action.bccRecipients);
  const updateOriginalAction = useStore(store => store.updateOriginalAction);

  useEffect(() => {
    if (toRef && toRef.current && isToClicked) {
      toRef.current.focusInput();
      setIsToClicked(false);
    }
  }, [toRef, isCcOpen, isToClicked]);

  useEffect(() => {
    if (ccRef && ccRef.current && isCcClicked) {
      ccRef.current.focusInput();
      setIsCcClicked(false);
    }
  }, [ccRef, isCcClicked, isCcOpen]);

  useEffect(() => {
    if (bccRef && bccRef.current && isBccClicked) {
      bccRef.current.focusInput();
      setIsBccClicked(false);
    }
  }, [bccRef, isBccClicked, isBccOpen]);

  useEffect(() => {
    if (!who || toRecipients?.length) return;

    const mainRecipient: RecipientType = {
      id: who.id.toString(),
      name: who.name,
      email: who.email,
      emails: [{ label: '', value: who.email, field: '' }],
      phone: who.phone,
      phones: [{ label: '', value: who.phone, field: '' }],
      sfdcId: who.sfdcId,
    };

    useStore.getState().updateAction({ toRecipients: [mainRecipient] });
    updateOriginalAction({
      toRecipients: [mainRecipient],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [who?.id, id, who?.email, who?.name]);

  const handleClickOutside = (): void => {
    setIsCcOpen(false);
    setIsBccOpen(false);
    setIsMinimized(true);
  };

  useHandleOutsideClicks(containerRef, handleClickOutside);

  const calcTagLayout = (): void => {
    const calcButtonWidth = (label: string, padding: number): number =>
      calcTextWidth(label, minRef, 'email-recipient-canvas', padding);

    // We just need to round down th make sure we don't accidentally add a pixel
    const maxWidth = (minRef.current?.clientWidth || 0) - 1;
    let additionToText;
    const ccText = `${ccRecipients?.length} Cc`;
    const bccText = `${bccRecipients?.length} Bcc`;
    const ccWidth =
      ccRecipients && ccRecipients.length > 0
        ? calcButtonWidth(ccText, BUTTON_PADDING)
        : 0;
    const bccWidth =
      bccRecipients && bccRecipients.length > 0
        ? calcButtonWidth(bccText, BUTTON_PADDING)
        : 0;
    let toWidth = ccWidth + bccWidth;
    const shownTags: RenderRecipientType[] = [];
    let toIndex = 0;

    const isTooWide = (tagWidth: number, recipientsLeft: number): boolean => {
      additionToText = `+ ${recipientsLeft}`;
      if (recipientsLeft === 1) return toWidth + tagWidth > maxWidth;

      return (
        toWidth + tagWidth + calcButtonWidth(additionToText, BUTTON_PADDING) >
        maxWidth
      );
    };

    if (toRecipients) {
      for (let i = 0; i < toRecipients.length; i += 1) {
        const tag = toRecipients[i];
        const tagWidth = Math.min(
          calcButtonWidth(
            tag.name || tag.email,
            BUTTON_PADDING + BUTTON_CLOSE_WIDTH,
          ),
          210,
        );
        toIndex = i;
        if (isTooWide(tagWidth, toRecipients.length - i)) break;
        const emails =
          tag.emails?.map(email => {
            return {
              key: email.field,
              value: email.value,
              text: email.value,
              secondaryText: email.label,
            };
          }) || [];

        shownTags.push({
          ...tag,
          displayText: tag.name || tag.email,
          items: emails,
          type: tag.attributes?.type,
          onCancel: onTagRemove(i),
          onClick: () => addSFDCObjectToOmnibar(tag.sfdcId, tag.email),
          onChange: value => onChangeEmail(i, value),
        });
        toWidth += tagWidth;
        if (toRecipients.length - i === 1) additionToText = null;
      }
      if (shownTags.length === 0 && toRecipients && toRecipients.length > 0) {
        shownTags.push({
          ...toRecipients[0],
          type: toRecipients[0].attributes?.type,
          displayText: toRecipients[0].name || toRecipients[0].email,
          items: [
            {
              key: toRecipients[0].emailField || toRecipients[0].email,
              value: toRecipients[0].email,
              text: toRecipients[0].email,
              secondaryText: 'Email',
            },
          ],
          onCancel: onTagRemove(0),
          onClick: () =>
            addSFDCObjectToOmnibar(
              toRecipients[0].sfdcId,
              toRecipients[0].email,
            ),
          onChange: value => onChangeEmail(0, value),
        });
      }
    }

    const toType = toRecipients?.some(
      (item, index) => item.attributes?.type === 'Unknown' && index >= toIndex,
    )
      ? 'Unknown'
      : '';

    const ccType = ccRecipients?.some(
      item => item.attributes?.type === 'Unknown',
    )
      ? 'Unknown'
      : '';

    const bccType = bccRecipients?.some(
      item => item.attributes?.type === 'Unknown',
    )
      ? 'Unknown'
      : '';

    if (additionToText)
      shownTags.push({
        email: additionToText,
        onClick: onMaximizeClick,
        displayText: additionToText,
        type: toType,
        items: [],
      });
    if (ccWidth)
      shownTags.push({
        email: ccText,
        onClick: onCcClick,
        color: 'bg-neutral/75',
        displayText: ccText,
        type: ccType,
        items: [],
      });
    if (bccWidth)
      shownTags.push({
        email: bccText,
        onClick: onBccClick,
        color: 'bg-neutral/75',
        displayText: bccText,
        type: bccType,
        items: [],
      });

    setRenderedMin(shownTags);
  };

  // The reason why we have two different use effect is to make sure that we are trigger the firing of the
  // of the calcTagLayout is only from either the resizing of the parent container or minimizing of to field
  useEffect(() => {
    const element = containerRef?.current;

    const observer = new ResizeObserver((): void => {
      const currentWidth = Math.ceil(containerRef.current?.clientWidth || 0);
      setContainerWidth(currentWidth);
    });

    if (!element) return;

    observer.observe(element);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);

  useEffect(() => {
    calcTagLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerWidth]);

  useEffect(() => {
    calcTagLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toRecipients, ccRecipients, bccRecipients]);

  const onTagRemove = (index: number) => () => {
    const tempWhoArray: crmObject[] = [];
    const actionUpdate: Partial<Omit<FullAction, 'id' | 'loggingTo'>> = {};
    const tempToRecipient = [...(toRecipients || [])];
    const recipient = tempToRecipient.splice(index, 1)[0];
    const { loggingTo } = useStore.getState().action;

    actionUpdate.toRecipients = tempToRecipient;

    if (tempToRecipient.length < 1) {
      actionUpdate.personId = null;
      actionUpdate.who = null;
    }

    loggingTo.who?.forEach(item => {
      if (!item.autoCreated || item.id !== recipient.sfdcId)
        tempWhoArray.push(item);
    });

    setSalesforceRelatedTo(tempWhoArray.map(item => item.email || ''));
    useStore.getState().updateCurrentLoggingTo({
      who: tempWhoArray,
    });
    useStore.getState().updateAction(actionUpdate);
    useStore.getState().setOtherValues({ relatedToLastOpen: 'to' });

    setIsToClicked(true);
  };

  const onChangeEmail = (index: number, value: SelectItem): void => {
    let tempToRecipient = [...(toRecipients || [])];
    const recipient = {
      ...tempToRecipient[index],
      email: value.value || tempToRecipient[index].email,
      emailField: value.key || tempToRecipient[index].emailField,
    };

    tempToRecipient = [
      ...tempToRecipient.slice(0, index),
      { ...recipient },
      ...tempToRecipient.slice(index + 1),
    ];

    const actionUpdate = { ...useStore.getState().action };
    actionUpdate.toRecipients = tempToRecipient;

    const { who } = actionUpdate;
    if (who && who.sfdcId === recipient.sfdcId) {
      actionUpdate.who = {
        ...who,
        email: recipient.email,
      };
    }
    useStore.getState().updateAction(actionUpdate);
  };

  // This is needed to make sure that we open all the fields that have users in it
  const openCcAndBcc = (): void => {
    if (ccRecipients && ccRecipients.length > 0) setIsCcOpen(true);
    if (bccRecipients && bccRecipients.length > 0) setIsBccOpen(true);
  };

  const onCcClick = (): void => {
    setIsCcOpen(true);
    setIsCcClicked(true);
    setIsMinimized(false);
    useStore.getState().setOtherValues({ relatedToLastOpen: 'to' });
    openCcAndBcc();
  };

  const onBccClick = (): void => {
    setIsBccOpen(true);
    setIsBccClicked(true);
    setIsMinimized(false);
    useStore.getState().setOtherValues({ relatedToLastOpen: 'to' });
    openCcAndBcc();
  };

  const onMaximizeClick = (): void => {
    setIsToClicked(true);
    setIsMinimized(false);
    useStore.getState().setOtherValues({ relatedToLastOpen: 'to' });
    openCcAndBcc();
  };

  const ccButton = (
    <div
      className="h-[30px] px-[8px] cursor-pointer flex items-center"
      onClick={onCcClick}
      onKeyUp={onCcClick}
      role="button"
      tabIndex={0}
      key="ccButton"
    >
      <Text className="text-body-sm font-semibold text-neutral/900">Cc</Text>
    </div>
  );

  const bccButton = (
    <div
      className="h-[30px] px-[8px] cursor-pointer flex items-center"
      onClick={onBccClick}
      onKeyUp={onBccClick}
      role="button"
      tabIndex={0}
      key="bccButton"
    >
      <Text className="text-body-sm font-semibold text-neutral/900">Bcc</Text>
    </div>
  );

  const tooltipRender = (
    name?: string,
    email?: string,
    type?: string,
    disabled?: boolean,
  ): string => {
    const disabledText = disabled
      ? 'Primary flow recipients cannot be removed - '
      : '';
    if (type === 'Unknown') return RECIPIENT_NOT_FOUND;
    if (name && email) return `${disabledText}${name}<${email}>`;
    if (name) return `${disabledText}${name}`;
    if (email) return `${disabledText}${email}`;
    return `${disabledText}Unknown Person`;
  };

  const buttonArray = [];

  if (!isCcOpen) buttonArray.push(ccButton);
  if (!isBccOpen) buttonArray.push(bccButton);

  const minButtonArray = [];

  if (!ccRecipients || ccRecipients.length === 0) minButtonArray.push(ccButton);
  if (!bccRecipients || bccRecipients.length === 0)
    minButtonArray.push(bccButton);

  return (
    <div
      className="border-0 !border-b border-solid border-neutral/75 text-body relative"
      ref={containerRef}
    >
      <div
        className={cx(
          'flex flex-1 flex-row items-center h-[40px] w-full',
          !isMinimized && 'absolute z-[-10] opacity-0',
        )}
      >
        <div
          className="flex flex-1 flex-row items-center min-w-0"
          onClick={onMaximizeClick}
          onKeyUp={onMaximizeClick}
          role="button"
          tabIndex={0}
        >
          <div className="h-full flex items-center pr-2">
            <Text className="text-body text-neutral/600 cursor-text">To</Text>
          </div>
          <div
            className="flex-1 relative flex-row flex items-start pt-[2px] font-body font-groove min-w-0"
            ref={minRef}
          >
            {isMinimized &&
              renderedMin &&
              renderedMin.length > 0 &&
              renderedMin.map((tag, index) => (
                <div
                  className="pr-2 pt-[2px] pb-[4px] min-w-0"
                  key={index.toString()}
                >
                  <SelectTag
                    displayText={tag.displayText}
                    fullWidth
                    onCancel={
                      !!personStepId && index === 0 ? undefined : tag.onCancel
                    }
                    onHover={tag.onClick}
                    onChange={value => tag.onChange?.(value)}
                    className={cx(tag.color, 'max-w-[200px]')}
                    selectedValue={tag.items?.find(
                      item => item.key === tag.emailField,
                    )}
                    inValid={tag.type === 'Unknown'}
                    items={tag.items}
                    tooltip={tooltipRender(
                      tag.name,
                      tag.email,
                      tag.type,
                      !!personStepId && index === 0,
                    )}
                  />
                </div>
              ))}
          </div>
        </div>
        {minButtonArray}
      </div>

      {!isMinimized && (
        <>
          <div className="relative flex flex-row items-center">
            <EmailRecipientField
              key="toRecipients"
              fieldKey="toRecipients"
              fieldLabel="To"
              ref={toRef}
              disableFirst={!!personStepId}
            />
          </div>
          {isCcOpen && (
            <div className="relative flex flex-row items-center">
              <EmailRecipientField
                key="ccRecipients"
                fieldKey="ccRecipients"
                fieldLabel="Cc"
                ref={ccRef}
              />
            </div>
          )}
          {isBccOpen && (
            <div className="relative flex flex-row items-center">
              <EmailRecipientField
                key="bccRecipients"
                fieldKey="bccRecipients"
                fieldLabel="Bcc"
                ref={bccRef}
              />
            </div>
          )}
          <div className="relative flex flex-row items-center justify-end">
            {buttonArray}
          </div>
        </>
      )}
    </div>
  );
};

export default EmailRecipientFields;
