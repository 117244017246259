import getClient from './getClient';
import { Template } from './template';

export type ApiTemplateFolder = {
  data: Template[];
};

export const templateFolderGet = (
  templateFolderId: number,
): Promise<ApiTemplateFolder> => {
  return getClient()
    .post('template_folder/get', {
      json: {
        variables: { template_folder_id: templateFolderId },
      },
    })
    .json();
};

export const templateFolderStar = (templateFolderId: number): Promise<void> => {
  return getClient()
    .post('template_folder/star', {
      json: {
        variables: { template_folder_id: templateFolderId },
      },
    })
    .json();
};

export const templateFolderUnstar = (
  templateFolderId: number,
): Promise<void> => {
  return getClient()
    .post('template_folder/unstar', {
      json: {
        variables: { template_folder_id: templateFolderId },
      },
    })
    .json();
};
