import { List, Record, Map } from 'immutable';
import Person from 'Modules/Actions/records/Person';
import FlowMetadata from 'Modules/Actions/records/FlowMetadata';
import {
  ACTION_PERSON_FLOW_ID_KEY_PATH,
  ACTION_CHILD_ID_KEY_PATH,
  ACTION_FLOW_ID_KEY_PATH,
} from 'Modules/ActionCompose/constants/graphql';
import { getTemplateName } from 'Modules/ActionCompose/utils';
import { hasUnmergedField as checkHasUnmergedField } from 'Modules/WorkStep/utils';
import sharedDefaults from './defaults';
import flowDefaults from './flowDefaults';
import { isLead } from 'Utils/salesforce-object-identifier';
import { DEFAULT_ADDRESS_FIELD_NAMES } from 'Modules/FlowsShow/constants/index.ts';

const defaults = {
  ...sharedDefaults,
  ...flowDefaults,
  personStepId: null,
  personFlowId: null,
  ccRecipients: new List(),
  bccRecipients: new List(),
  variants: new List(),
  currentValues: new Map({
    body: null,
    subject: null,
    hasUnmergedSubject: false,
    hasUnmergedBody: false,
  }),
  hasOptedOutOfEmail: false,
  disablePersonalization: false,
};

export default class EmailAction extends Record(defaults) {
  static fromGraphQL(action) {
    const executionContext = action.get('executionContext');
    const mergedTemplate = executionContext?.get('mergedTemplate');
    const variants = executionContext?.get('variants');
    const body = mergedTemplate?.get('body') || executionContext?.get('body');
    const subject =
      mergedTemplate?.get('subject') || executionContext?.get('subject');
    const templateName = getTemplateName(action, executionContext);
    const emails = (executionContext?.get('emails') || new List())
      .fromEntrySeq()
      .toMap();
    const who = action.has('who')
      ? Person.from(action.get('who'), emails)
      : null;
    const isWhoLead = isLead(who?.get('sfdcId'));
    const selectedFieldKey = isWhoLead
      ? DEFAULT_ADDRESS_FIELD_NAMES.lead.email
      : DEFAULT_ADDRESS_FIELD_NAMES.contact.email;
    const selectedRecipientEmailField =
      action.getIn(['child', 'flow', selectedFieldKey]) || 'email';

    return new EmailAction({
      id: action.get('id'),
      type: action.get('type'),
      summary: action.get('summary'),
      sourceMetadata: FlowMetadata.from(
        action.get('sourceMetadata')?.toJS() || {}
      ),
      priority: action.get('priority'),
      templateName,
      source: action.get('source'),
      failedData: action.get('failedData'),
      error: action.get('error'),
      personStepId: action.getIn(ACTION_CHILD_ID_KEY_PATH),
      personFlowId: action.getIn(ACTION_PERSON_FLOW_ID_KEY_PATH),
      flowId: action.getIn(ACTION_FLOW_ID_KEY_PATH),
      hasOptedOutOfEmail: action.getIn(['who', 'hasOptedOutOfEmail']) || false,
      disablePersonalization:
        action.getIn(['child', 'step', 'disablePersonalization']) || false,
      isInMasterFlow: action?.getIn(['child', 'flow', 'isMasterFlow']),
      ...(action.has('who') && {
        recipient: who,
      }),
      body,
      subject,
      variants,
      currentValues: Map({
        body,
        subject,
        alias: null,
        hasUnmergedBody: body && checkHasUnmergedField(body),
        hasUnmergedSubject: subject && checkHasUnmergedField(subject),
        selectedRecipientEmailField,
      }),
      ccRecipients: action.get('ccRecipients'),
      bccRecipients: action.get('bccRecipients'),
    });
  }
}
