import client from '../client';

export interface ApiToken {
  token: string;
  uuid: string;
}

const token = (): Promise<ApiToken> => {
  return client
    .get('twilio/token', { searchParams: { allow_incoming: true } })
    .json();
};

export default token;
