import { createSelector } from 'reselect';
import {
  OMNIBAR_SUPPORTED_ROUTES,
  OMNIBAR_SUPPORTED_TABS,
} from 'Modules/Shared/constants';
import { location } from 'Modules/Shared/selectors/location';

export const omnibarOpen = state => state.getIn(['omnibar', 'omnibarOpen']);
export const shouldShowOmnibar = createSelector(location, location => {
  if (!location) return false;

  const routeName = location.get('routeName');
  const tabName = location.getIn(['routeParams', 'tabName']);
  return (
    OMNIBAR_SUPPORTED_ROUTES.includes(routeName) ||
    OMNIBAR_SUPPORTED_TABS.some(
      route => route.tabName === tabName && route.routeName === routeName
    )
  );
});

export const getActiveAccountSfdcId = state =>
  state.getIn(['omnibar', 'activeAccountSfdcId']);
