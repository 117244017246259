const round = (number, places) => {
  return +`${Math.round(`${number}e+${places}`)}e-${places}`;
};

const sanitizer = value => {
  if ([null, Infinity].includes(value) || Number.isNaN(value)) {
    return 0;
  }
  return round(value, 1);
};

export default sanitizer;
