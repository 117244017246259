import { Checkbox, IPersonaProps, List, Spinner, Text } from '@fluentui/react';
import { grooveWhatSearch } from '@groove/api/visualforce/grooveSearch';
import RecordMenuItem from '@groove/ui/Components/RecordMenuItem';
import { FC } from 'react';
import { useQuery } from 'react-query';

import transformGrooveEngineResult from './transformGrooveEngineResults';
import transformSearchResult, {
  DisplayableSearchResult,
} from './transformSearchResult';

type WhoListProps = {
  whatId: string;
  whatType: string;
  selectedWhoIds?: string[];
  onWhoSelected: (who: DisplayableSearchResult) => void;
};

const WhoList: FC<WhoListProps> = ({
  whatId,
  whatType,
  selectedWhoIds,
  onWhoSelected,
}) => {
  const { data, isLoading } = useQuery(['search', whatId], async () => {
    const data = await grooveWhatSearch(whatId, whatType);
    return transformGrooveEngineResult(data);
  });
  const transformedData: DisplayableSearchResult[] | undefined = data
    ?.flat?.()
    .map(transformSearchResult);

  return (
    <>
      {isLoading && <Spinner />}
      {transformedData && transformedData.length > 0 && (
        <Text block className="my-1">
          Related Contacts
        </Text>
      )}
      <List
        items={transformedData as IPersonaProps[]}
        onRenderCell={(item?: IPersonaProps) =>
          item?.id && (
            <div className="flex items-center">
              <Checkbox
                ariaLabel={`Select-${item.id}`}
                className="checked:bg-gray-200"
                checked={selectedWhoIds?.includes(item.id)}
                onChange={() =>
                  item.id && onWhoSelected(item as DisplayableSearchResult)
                }
              />
              <RecordMenuItem
                fullWidth
                showLinkToSalesforce
                id={item.id}
                text={item.text || ''}
                secondaryText={item.secondaryText}
                secondaryTextLabel={
                  (item as DisplayableSearchResult).secondaryTextLabel
                }
                tertiaryText={(item as DisplayableSearchResult).tertiaryText}
                tertiaryTextLabel={
                  (item as DisplayableSearchResult).tertiaryTextLabel
                }
                quarteraryText={
                  (item as DisplayableSearchResult).quarteraryText
                }
                quarteraryTextLabel={
                  (item as DisplayableSearchResult).quarteraryTextLabel
                }
                quinaryText={(item as DisplayableSearchResult).quinaryText}
                quinaryTextLabel={
                  (item as DisplayableSearchResult).quinaryTextLabel
                }
              />
            </div>
          )
        }
      />
    </>
  );
};

export default WhoList;
