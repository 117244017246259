import { MessageBarType } from '@fluentui/react';
import { DocumentNode, OperationDefinitionNode, print } from 'graphql';
import * as SentryReact from '@sentry/react';

import useStore from '../../hooks/useStore';
import client from '../client';
import { EXPIRED_SALESFORCE_TOKENS } from '../../constants';

import { ApiGraphQl } from './types';

const graphql = async <T>(
  query: DocumentNode,
  variables = {},
  shouldThrow = true,
): Promise<ApiGraphQl<T>> => {
  const operation = query.definitions[0] as OperationDefinitionNode;
  const body = {
    query: print(query),
    variables: JSON.stringify(variables),
  };
  const response = await client
    .post(`api/graphql?op=${operation.name?.value || 'unknown'}`, {
      json: body,
    })
    .json<ApiGraphQl<T>>();

  if (response.errors) {
    if (response.errors[0].type === EXPIRED_SALESFORCE_TOKENS) {
      useStore.getState().setMessageBar(
        {
          message:
            'There seems to be an issue with your Salesforce connection. Log back into Salesforce to fix this.',
          type: MessageBarType.error,
          actionType: EXPIRED_SALESFORCE_TOKENS,
        },
        30000,
      );
    }
    response.errors.forEach(error => {
      SentryReact.captureException(error.message, { extra: error });
    });
    if (shouldThrow) {
      return Promise.reject(response);
    }
  }

  return Promise.resolve(response);
};

export default graphql;
