import moment from 'moment';
import { COLUMN_TYPES } from 'Modules/Shared/components/Table/constants';

export const REPORTING_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULTS = {
  tab: { label: 'ACTIVITY', value: 'activities' },
  teamScope: [],
  startDate: moment().startOf('quarter').format(REPORTING_DATE_FORMAT),
  endDate: moment().format(REPORTING_DATE_FORMAT),
};

export const MESSAGE_ERROR_BAR_TEXT =
  'The page for your filter selection has failed to load, try a smaller date range or a different selection. Please try again.';
export const MESSAGE_ERROR_BAR_LINK_TEXT = 'Retry';

export const tableColumns = {
  emails: [
    {
      id: 'subject',
      label: 'Emails',
      type: COLUMN_TYPES.string,
    },
    {
      id: 'userName',
      label: 'Sender',
      type: COLUMN_TYPES.string,
    },
    {
      id: 'recipient',
      label: 'Recipients',
      type: COLUMN_TYPES.string,
    },
    {
      id: 'eventsCount',
      label: 'Events',
      type: COLUMN_TYPES.integer,
    },
    {
      id: 'timesOpened',
      label: 'Opens',
      type: COLUMN_TYPES.integer,
    },
    {
      id: 'timesLinkClicked',
      label: 'Clicks',
      type: COLUMN_TYPES.integer,
    },
    {
      id: 'replyRecorded',
      label: 'Responses',
      type: COLUMN_TYPES.string,
    },
  ],
  flows: [
    {
      id: 'name',
      label: 'Flow name',
      sortable: 'true',
    },
    {
      id: 'createdBy',
      label: 'Created By',
      sortable: 'true',
      type: COLUMN_TYPES.string,
    },
    {
      id: 'shared',
      label: 'Shared',
      type: COLUMN_TYPES.boolean,
    },
    {
      id: 'completed',
      label: 'Sent',
      sortable: 'true',
      type: COLUMN_TYPES.integer,
    },
    {
      id: 'opened',
      label: 'Opened',
      sortable: 'true',
      type: COLUMN_TYPES.string,
      percentage: true,
    },
    {
      id: 'clicked',
      label: 'Clicked',
      sortable: 'true',
      type: COLUMN_TYPES.string,
      percentage: true,
    },
    {
      id: 'replied',
      label: 'Replied',
      sortable: 'true',
      type: COLUMN_TYPES.string,
      percentage: true,
    },
  ],
  templates: [
    {
      id: 'name',
      label: 'Template name',
      sortable: 'true',
    },
    {
      id: 'folder',
      label: 'Folder',
      sortable: 'true',
      type: COLUMN_TYPES.string,
    },
    {
      id: 'createdBy',
      label: 'Created By',
      sortable: 'true',
      type: COLUMN_TYPES.string,
    },
    {
      id: 'shared',
      label: 'Shared',
      type: COLUMN_TYPES.boolean,
    },
    {
      id: 'completed',
      label: 'Sent',
      sortable: 'true',
      type: COLUMN_TYPES.integer,
    },
    {
      id: 'opened',
      label: 'Opened',
      sortable: 'true',
      type: COLUMN_TYPES.string,
      percentage: true,
    },
    {
      id: 'clicked',
      label: 'Clicked',
      sortable: 'true',
      type: COLUMN_TYPES.string,
      percentage: true,
    },
    {
      id: 'replied',
      label: 'Replied',
      sortable: 'true',
      type: COLUMN_TYPES.string,
      percentage: true,
    },
  ],
};

export const ANALYTICS_GROUP_ID = 'analyticsGroup';
export const ANALYTICS_SEARCH_FIELD_ID = 'analyticsSearchFilter';

export const ANALYTICS_CALLS_LOG_GROUP_ID = 'analyticsCallsLog';
export const ANALYTICS_CALLS_LOG_FIELD_ID = 'analyticsCallsLogSearchFilter';

export const TEMPLATES_DRILLDOWN_DIALOG_IS_OPEN_UI_KEYPATH = [
  'templatesDrilldownDialogIsOpen',
];

export const FLOWS_DRILLDOWN_DIALOG_IS_OPEN_UI_KEYPATH = [
  'flowsDrilldownDialogIsOpen',
];
export const FLOWS_DRILLDOWN_DIALOG_FLOW_ID_KEYPATH = [
  'flowsDrilldownDialogFlowId',
];

export const PROCESS_TAB_USER_TABLE_DATA_KEYS = [
  'newPeopleStarted',
  'stepsCompleted',
  'stepsCompletedOnTime',
  'stepsSkipped',
];

export const PROCESS_TAB_USER_TABLE_PERCENTAGE_KEYS = [
  'stepsCompletedOnTime',
  'stepsSkipped',
];

export const PROCESS_TAB_STEP_TOTALS_STEPS = 'stepsCompleted';

export const EMAILS_TAB_AGENT_DATA_KEYS = [
  'subject',
  'recipientName',
  'eventsCount',
  'timesOpened',
  'timesLinkClicked',
  'replyCount',
  'degreeOfPersonalization',
];

export const EMAIL_AGENT_LOG_TABLE_COLUMNS = [
  {
    id: 'subject',
    label: 'Email Subject',
  },
  {
    id: 'recipientName',
    label: 'Recipients',
  },
  {
    id: 'eventsCount',
    label: 'Events',
  },
  {
    id: 'timesOpened',
    label: 'Opened',
  },
  {
    id: 'timesLinkClicked',
    label: 'Clicks',
  },
  {
    id: 'replied',
    label: 'Replied',
  },
  {
    id: 'degreeOfPersonalization',
    label: 'Personalization',
    percentage: true,
  },
];

export const EMAIL_ALL_AGENTS_LOG_TABLE_COLUMNS = [
  {
    id: 'subject',
    label: 'Email Subject',
  },
  {
    id: 'userName',
    label: 'Sender',
  },
  {
    id: 'recipientName',
    label: 'Recepients',
  },
  {
    id: 'eventsCount',
    label: 'Events',
  },
  {
    id: 'timesOpened',
    label: 'Opened',
  },
  {
    id: 'timesLinkClicked',
    label: 'Clicks',
  },
  {
    id: 'replied',
    label: 'Replied',
  },
];

export const EMAILS_TAB_AGENT_SUMMARY_KEYS = [
  'totalSent',
  'averageDegreeOfPersonalization',
  'timesOpened',
  'clicked',
  'replied',
];

export const EMAILS_TAB_AGENT_SUMMARY_PERCENT_KEYS = [
  'averageDegreeOfPersonalization',
  'timesOpened',
  'clicked',
  'replied',
];

export const EMAILS_TAB_USERS_KEYS = [
  'timesOpened',
  'clicked',
  'replied',
  'userId',
  'totalSent',
];

export const ACTIVITIES_TAB_USER_TABLE_DATA_KEYS = [
  'activities',
  'emails',
  'calls',
  'actions',
];
export const PROCESS_TAB_STEP_TOTALS_STEPS_MAP = {
  stepsCompletedOnTime: ['stepsCompleted'],
  stepsSkipped: ['stepsCompleted', 'stepsSkipped'],
};

export const SCOPE_SELECTOR_SEARCH_GROUP_ID = 'scopeSelectorSearchGroup';
export const SCOPE_SELECTOR_TEAM_GROUP_ID = 'scopeSelectorTeamGroup';
export const SCOPE_SELECTOR_USER_GROUP_ID = 'scopeSelectorUserGroup';
export const SCOPE_SELECTOR_USERS_NOT_IN_A_TEAM_GROUP_ID =
  'scopeSelectorUsersNotInATeamGroup';

export const FLOWS_TAB_USER_TABLE_DATA_KEYS = [
  'total',
  'opened',
  'clicked',
  'replied',
  'bookedMeetings',
  'positiveConversations',
];

export const TEMPLATES_TAB_USER_TABLE_DATA_KEYS = [
  'name',
  'folder_name',
  'owner',
  'sent',
  'opened',
  'clicked',
  'replied',
];

export const EMAIL_LOG_DEFAULT_ORDER_BY = 'timesOpened';
export const EMAIL_LOG_DEFAULT_ORDER_DIRECTION = 'desc';

export const EMAIL_USER_BREAKDOWN_DEFAULT_ORDER_BY = 'totalSent';
export const EMAIL_USER_ORDER_DEFAULT_DIRECTION = 'desc';

export const EMAILS_TAB_TOTAL_KEY = 'totalSent';

export const EMAILS_OPEN_DIALOG_KEYPATH = ['emailDialogOpen'];

export const ALL_EMAILS_OPEN_DIALOG_KEYPATH = ['allEmailsDialogOpen'];

export const EMAILS_TAB_SUMMARY_CARD_PERCENTAGE_KEYS = [
  'clicked',
  'replied',
  'timesOpened',
];

export const REPLY_COUNT_DATA_KEY = 'replyCount';

export const TEMPLATES_TAB_USER_TABLE_PERCENTAGE_KEYS = [
  'opened',
  'clicked',
  'replied',
];
export const FLOW_FILTER_KEYS = ['name', 'owner'];

export const TEMPLATES_TAB_STEP_TOTALS_STEPS_MAP = {
  opened: 'sent',
  clicked: 'sent',
  replied: 'sent',
};

export const TEMPLATE_FILTER_KEYS = ['name', 'owner'];

export const TOOLTIP_TEXT = {
  opened: 'of TRACKED emails opened',
  clicked: 'of TRACKED emails clicked',
  replied: 'of TRACKED emails replied to',
  degreeOfPersonalization:
    'Average amount of personalization made to Flow emails',
};

export const CALLS_TAB_TO_HOUR = 19;
export const CALLS_TAB_FROM_HOUR = 8;
export const CALLS_TAB_PERCENTAGE_KEYS = [
  'positiveConnections',
  'positiveConversations',
];
export const CALLS_TAB_DIALOG_KEYPATH = ['callsTabEmailLogIsOpen'];

export const CALLS_TAB_AGENT_DATA_KEYS = [
  'sfdcId',
  'userName',
  'contactName',
  'flowName',
  'createdAt',
  'callDuration',
  'result',
  'sentiment',
  'recordingUrl',
];
export const CALL_USER_DEFAULT_ORDER_BY = 'totalCalls';
export const CALL_USER_DEFAULT_ORDER_DIRECTION = 'desc';

export const CALL_LOG_CONTACT_NAME_TABLE_COLUMN = {
  label: 'Contact Name',
  id: 'contactName',
};

export const CALL_LOG_TEAM_MEMBER_TABLE_COLUMN = {
  label: 'Team Member',
  id: 'userName',
};

export const CALL_LOG_DEFAULT_TABLE_COLUMNS = [
  {
    label: 'Date/Time',
    id: 'createdAt',
  },
  {
    label: 'Duration',
    id: 'callDuration',
  },
  {
    label: 'Result',
    id: 'result',
  },
  {
    label: 'Sentiment',
    id: 'sentiment',
  },
  {
    label: 'Flow Name',
    id: 'flowName',
  },
  {
    label: 'Recording',
    id: 'recording',
  },
];

export const FLOWS_DEFAULT_ORDER_BY = 'total';
export const FLOWS_DEFAULT_ORDER_DIRECTION = 'desc';

export const ACTIVITIES_DEFAULT_ORDER_BY = 'activities';
export const ACTIVITIES_DEFAULT_ORDER_DIRECTION = 'desc';

export const TEMPLATES_DEFAULT_ORDER_BY = 'sent';
export const TEMPLATE_DEFAULT_ORDER_DIRECTION = 'desc';

export const PROCESS_DEFAULT_ORDER_BY = 'stepsCompleted';
export const PROCESS_DEFAULT_ORDER_DIRECTION = 'desc';

export const ANALYTICS_SCOPE_STORAGE_KEY = 'analyticsScopeMap';
export const ANALYTICS_STORED_STATE_KEYS = [
  'teamScope',
  'userScope',
  'startDate',
  'endDate',
  'usersNotInATeamScope',
];

export const USERS_NOT_IN_TEAMS_ID = '-1';

export const EXPORT_LOGS_AS_CSV_PER_PAGE = 50;

export const TABS_WITH_ASYNC_EMAIL_EXPORT = [
  'activities',
  'emails',
  'flows',
  'templates',
  'calls',
  'process',
];

export const USER_HIERARCHY_ANALYTICS_FF_KEY =
  'sfdc-user-hierarchy-in-analytics-and-outcomes';
