import { fromJS } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/campaignAssociation';

const initialState = {
  loading: false,
  campaigns: [],
};

export default function ui(state = fromJS(initialState), action = {}) {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGNS.BEGIN: {
      return state.set('loading', true);
    }

    case actionTypes.FETCH_CAMPAIGNS.SUCCESS: {
      return state.set('campaigns', action.payload).set('loading', false);
    }

    case actionTypes.FETCH_CAMPAIGNS.FAILURE: {
      return state.set('loading', false);
    }

    default:
      return state;
  }
}
