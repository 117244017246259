import { QueryClient, useQuery, UseQueryResult } from 'react-query';
import { camelizeKeys } from 'humps';

import fetchPeopleImportWarnings, {
  optOutDataType,
  importRuleViolationsHashType,
  sfdcFailedRequestsType,
  PeopleImport,
} from '../gateway/v1/peopleImportWarnings';

export type ErrorType = {
  id: string;
  readableLocation?: string;
};

export type Message = {
  level: MessageLevel;
  id: string;
  message: string;
  noCloseButton?: boolean;
  hide?: boolean;
};
export type MessageLevel = 'info' | 'warning' | 'error' | 'success';

export type GlobalBlockWarningParams = {
  flowId: string | null;
  recipientSfdcIds: string[];
  addExecutionError: (location: ErrorType) => void;
  addMessage: (message: Message) => void;
  clearErrorByPrefix: (idPrefix: string) => void;
  enabled: boolean;
};

export type fetchGlobalBlockWarningsParams = GlobalBlockWarningParams & {
  client: QueryClient;
};

export type ActionFlowType = {
  id: number | null;
  name: string | null;
  settings: {
    contact: {
      emailFieldName: string | null;
      phoneFieldName: string | null;
    } | null;
    lead: {
      emailFieldName: string | null;
      phoneFieldName: string | null;
    } | null;
  };
};

export type PeopleImportWarningResponse = {
  optOutData: optOutDataType;
  importRuleViolationsHash: importRuleViolationsHashType;
  sfdcFailedRequests: sfdcFailedRequestsType;
};

const useGlobalBlockWarnings = ({
  flowId,
  recipientSfdcIds = [],
  addExecutionError,
  addMessage,
  clearErrorByPrefix,
  enabled,
}: GlobalBlockWarningParams): UseQueryResult => {
  const peopleImportHash: PeopleImport = {};

  recipientSfdcIds.forEach(sfdcId => {
    peopleImportHash[sfdcId] = {
      Id: sfdcId,
    };
  });

  return useQuery(
    ['globalBlockWarnings', flowId, recipientSfdcIds],
    () => fetchPeopleImportWarnings(flowId, peopleImportHash),
    {
      enabled,
      onSettled: (data: PeopleImportWarningResponse | undefined) => {
        if (data) {
          const camelizedData = camelizeKeys(data) as
            | PeopleImportWarningResponse
            | undefined;

          if (
            camelizedData &&
            Object.keys(camelizedData.importRuleViolationsHash).length
          ) {
            const messages = extractWarningMessages(camelizedData);

            messages.forEach(warning => {
              addExecutionError({ id: `block-warning-${warning.id}` });
              addMessage({
                id: `block-warning-${warning.id}`,
                message: warning.message,
                level: 'warning',
              });
            });
          }
        }
        return clearErrorByPrefix('block-initial-load');
      },
    },
  );
};

export const extractWarningMessages = (
  importData: PeopleImportWarningResponse,
): Message[] | [] => {
  const importHash = importData.importRuleViolationsHash;
  const messages: Message[] = [];

  if (importHash && Object.keys(importHash).length > 0) {
    Object.keys(importHash).forEach(personSfdcId => {
      Object.keys(importHash[personSfdcId]).forEach(ruleId => {
        messages.push({
          level: 'error',
          message: `This action cannot be executed due to the following rule: ${importHash[personSfdcId][ruleId].violatedRuleName}`,
          id: ruleId,
          noCloseButton: true,
          hide: false,
        });
      });
    });
  }
  return messages;
};

export default useGlobalBlockWarnings;
