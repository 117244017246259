import { IconButton } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { CloseIcon, MinimizeIcon } from '@groove/ui/Components/BoogieIcon';
import ActionSource, { SourceType } from '@groove/ui/Components/ActionSource';
import cx from 'classnames';
import { NEW_ACTION_ID } from '@groove/api/gateway/v1/actionCompose';
import Text from '@groove/ui/Components/Text';
import { motion } from 'framer-motion';
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';
import Timer from 'react-compound-timer';
import useDialerStore from '@groove/dialer-components/hooks/useStore';
import { END_CONFERENCE_DURATION } from '@groove/dialer-components/constants';

import useStore from '../store/useStore';
import { didActionChange } from '../utils';
import { ActionComposeEnum } from '../constants';
import useActionMutating from '../hooks/useActionMutating';
import {
  trackActionEvents,
  ACTION_EVENT,
  ACTION_VERSION,
} from '../analytics/actionComposeEvents';

export type HeaderProps = {
  showHeader: boolean;
  headerStyles?: string;
  onClose?: () => void;
};

const formatMinutes = (number: number): string =>
  number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
const formatSeconds = (number: number): string =>
  number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

const Header: FC<HeaderProps> = ({ showHeader, headerStyles, onClose }) => {
  const [constantBridgeTimer, setConstantBridgeTimer] =
    useState<NodeJS.Timeout | null>(null);

  const actionComposeStatus = useStore(store => store.actionComposeStatus);
  const id = useStore(store => store.action.id);
  const playNodeRunId = useStore(store => store.action.playNodeRunId);
  const personStepId = useStore(store => store.action.personStepId);
  const workspaceAccountTaskId = useStore(
    store => store.action.workspaceAccountTaskId,
  );
  const workspaceSObjectTaskId = useStore(
    store => store.action.workspaceSObjectTaskId,
  );
  const initialSummary = useStore(store => store.action.summary) || '';
  const storedSource = useStore(store => store.action.source);
  const isFetching = useStore(store => store.otherValues.isFetching);
  const callState = useDialerStore(store => store.callState);
  const constantBridgeSessionStatus = useDialerStore(
    store => store.constantBridgeSessionStatus,
  );

  const isLoading = useActionMutating() || isFetching;
  const shouldRemoveBetaTag = useVariation('action-compose-beta-tag');

  useEffect(() => {
    const { endConstantBridgeConference } = useDialerStore.getState();
    if (constantBridgeSessionStatus !== 'inactive' && !constantBridgeTimer)
      setConstantBridgeTimer(
        setTimeout(() => {
          endConstantBridgeConference();
        }, END_CONFERENCE_DURATION),
      );
    return () => {
      if (constantBridgeTimer) clearTimeout(constantBridgeTimer);
      if (constantBridgeSessionStatus !== 'inactive')
        endConstantBridgeConference();
    };
  }, [constantBridgeSessionStatus, constantBridgeTimer]);

  const minimizePane = (): void => {
    // Make sure that TinyMCE reads the body data when reopening
    useStore.getState().setOtherValues({ wysiwygEdited: false });
    if (actionComposeStatus === ActionComposeEnum.MINIMIZED) {
      useStore.getState().setActionComposeStatus(ActionComposeEnum.MAXIMIZED);
    } else {
      useStore.getState().setActionComposeStatus(ActionComposeEnum.MINIMIZED);
    }
  };

  let summary = initialSummary;
  if (id === NEW_ACTION_ID && summary.length < 3) summary = 'New Action';

  let source: SourceType = 'ONE OFF';

  if (playNodeRunId) source = 'PLAY';
  if (personStepId) source = 'FLOW';
  if (workspaceAccountTaskId || workspaceSObjectTaskId) source = 'SPACES';

  if (storedSource === 'WORKSPACE') source = 'SPACES';
  else if (storedSource === 'ONE_OFF') source = 'ONE OFF';
  else if (storedSource && storedSource !== 'SUGGESTED_ACTION')
    source = storedSource;

  const onModalClose = (): void => {
    useStore.getState().cleanUpActionCompose();
    trackActionEvents({
      eventName: ACTION_EVENT.CLOSED,
      action: useStore.getState().action,
      actionVersion: ACTION_VERSION.V2,
    });
  };

  const handleCloseButton = (): void => {
    const { originalAction, action: currentAction } = useStore.getState();
    if (didActionChange(originalAction, currentAction)) {
      useStore.getState().setNewModal({
        isOpen: true,
        onConfirm: onModalClose,
        contentText: 'Are you sure you want to close without saving?',
        title: 'Close action',
        confirmText: 'Close',
      });
    } else {
      onModalClose();
    }
    onClose?.();
  };

  let headerText = (
    <>
      <div className="pr-[12px] overflow-hidden flex-1">
        <Text className="font-semibold truncate text-neutral/900 text-body">
          {summary}
        </Text>
      </div>
      <ActionSource source={source} />
    </>
  );

  if (isLoading)
    headerText = (
      <motion.div
        animate={{
          opacity: [1, 0.65, 1],
        }}
        transition={{
          duration: 3,
          ease: 'easeInOut',
          repeat: Infinity,
        }}
      >
        <Text className="font-semibold truncate text-neutral/900 text-body">
          Updating...
        </Text>
      </motion.div>
    );

  if (callState !== 'not-connected')
    headerText = (
      <div className="flex">
        <Text className="font-semibold text-body text-neutral/900">
          Call:{' '}
          <Timer>
            <Timer.Minutes formatValue={formatMinutes} />
            :
            <Timer.Seconds formatValue={formatSeconds} />
          </Timer>
        </Text>
      </div>
    );

  return (
    <>
      <div
        className={cx(
          'w-full h-[40px] bg-white flex self-center relative border-neutral/100 border-t-0 border-solid border-r-0 border-l-0 border-b',
          headerStyles && headerStyles,
          callState !== 'not-connected' && 'bg-success/50',
        )}
      >
        <div className="m-auto flex flex-row max-w-[310px] static items-center">
          {!shouldRemoveBetaTag && (
            <div className="absolute left-3 align-middle">
              <div className="px-[4px] py-[2px] rounded text-white bg-grapeshine/600">
                <Text className="text-metadata-sm font-semibold">BETA</Text>
              </div>
            </div>
          )}
          {headerText}
          {constantBridgeSessionStatus === 'idle' && (
            <div className="ml-[12px] bg-warning/50 rounded-[4px] px-[8px] py-[5px]">
              <Text className="text-body-sm font-semibold text-neutral/900">
                Bridge Dial idle timer:{' '}
                <Timer
                  initialTime={END_CONFERENCE_DURATION}
                  direction="backward"
                >
                  <Timer.Minutes formatValue={formatMinutes} />
                  :
                  <Timer.Seconds formatValue={formatSeconds} />
                </Timer>
              </Text>
            </div>
          )}
        </div>
        {showHeader && (
          <div className="absolute right-3 top-[3px] align-middle">
            <IconButton
              ariaLabel="Close Action Compose Pane"
              onClick={minimizePane}
              className="text-neutral/900 hover:text-neutral/900"
            >
              <MinimizeIcon
                className={cx(
                  actionComposeStatus === ActionComposeEnum.MINIMIZED &&
                    'rotate-180',
                )}
              />
            </IconButton>
            <IconButton
              className="text-neutral/900 hover:text-neutral/900"
              ariaLabel="Close Action Compose Pane"
              onClick={handleCloseButton}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
