export const FONT_FAMILY_STRINGS = Object.freeze({
  CALIBRI_FAMILY:
    'Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif',
  ARIAL_FAMILY: 'arial,helvetica,sans-serif',
});

export const FONT_FORMATS = Object.freeze([
  `Arial=${FONT_FAMILY_STRINGS.ARIAL_FAMILY}`,
  `Calibri=${FONT_FAMILY_STRINGS.CALIBRI_FAMILY}`,
  'Courier New=courier new,courier,monospace',
  'Georgia=georgia,palatino',
  'Segoe UI="Segoe UI", Optima, Arial, sans-serif',
  'Times New Roman=times new roman,times',
  'Verdana=verdana,geneva,sans-serif',
]);

export const OUTLOOK_FONT_SIZE_FORMATS = Object.freeze([
  '8pt',
  '10pt',
  '11pt',
  '12pt',
  '14pt',
  '18pt',
  '24pt',
  '36pt',
]);

export const FONT_SIZE_FORMATS = Object.freeze([
  '8px',
  '10px',
  '11px',
  '12px',
  '14px',
  '18px',
  '24px',
  '36px',
]);
