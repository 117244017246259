import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { templateFolderStar } from '../services/v1/templateFolder';

export type UseTemplateFolderStarParams = {
  templateFolderId: number;
};

const useTemplateFolderStar = (): UseMutationResult<
  unknown,
  unknown,
  UseTemplateFolderStarParams,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ templateFolderId }) => templateFolderStar(templateFolderId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('favoriteTemplateFoldersGet');
      },
    },
  );
};

export default useTemplateFolderStar;
