import moment from 'moment-timezone';

import { ApiActionsCreators, ApiAssignees } from '../../services/v1/actions';

import {
  SourceType as ActionComposeSourceType,
  ActionType as ActionComposeActionType,
} from './actionCompose';
import getClient from './getClient';
// TODO: Check these (PLAYB-155 / WFA-110)

export type GetActionsResponse = {
  data: Action[];
};

export type ActionPriorityTypes = 'high' | 'normal' | 'low' | 'none';
// Double check with omnibar action type for overlap
// libs/api/src/lib/services/v1/actions.ts
export type Action = {
  id: number;
  summary: string;
  source: SourceType;
  who: Who;
  due_at: string;
  creator: Person;
  assignee: Person;
  body?: string;
  subject?: string;
  priority?: ActionPriorityTypes;
  deleted_at?: string;
  completed_at?: string;
  failed?: boolean;
  type?: ActionType;
  smart_priority?: number;
  summary_v2?: string[];
  step_id?: number;
  flow_id?: number;
};

export type SourceType = ActionComposeSourceType;
export type ActionType = ActionComposeActionType | AdditionalActionTypes;
type AdditionalActionTypes =
  // overlap:
  //   | 'CALL'
  //   | 'GENERAL'
  //   | 'OTHER'
  | 'EMAIL'
  | 'IN_MAIL'
  | 'LINKEDIN_CONNECT'
  | 'SENDOSO'
  | 'SMS'
  // Are these still a thing?
  | 'IN_PERSON_VISIT'
  | 'DIRECT_MAIL'
  | 'WORKSPACE'
  | 'SALESFORCE';

// TODO: Check these (PLAYB-155)
export type Who = {
  id: number;
  name: string;
  company?: string;
  title?: string;
  tzid?: string;
  engagement_score?: number;
  sfdc_id: string;
};

export type Person = {
  id: number;
  name: string;
};

// TODO: fix this duplicate. Type should be import from the actions rewrite.
type TabName = 'Active' | 'Failed' | 'Executed Actions' | 'Assigned to Others';

export type OrderType = {
  field: string;
  direction: 'asc' | 'desc';
};

export type DueAtOperator = 'BEFORE' | 'ON' | 'AFTER';
type DueAt = {
  operator: DueAtOperator;
  date_time: string;
};

// TODO: WFA-105. Adding todos on actions filter and action type
// these need to be synchronized with the omnibar action types and filters
export type ActionsFilter = {
  search: string;
  type: ActionType[];
  source: SourceType;
  due_at: DueAt[] | DueAtOperator[];
  priority: ActionPriorityTypes[];
  smart_priority_cutoff: ActionPriorityTypes[];
  exclude_people: string[]; // TODO: identify this
  scope: string; // TODO: identify
  status: string; // TODO: identify
  timezone: string;
};

/**
 * The filter object in omnibar sends an array of strings (operators)
 * the web-app endpoint expects an operator with a specific datetime
 */
export function processDueAtFilter(filters?: DueAtOperator[]): DueAt[] {
  if (!filters) return [];

  const currentDate = moment().toISOString();
  return filters.map(filter => ({
    operator: filter,
    date_time: currentDate,
  }));
}

export type BodyParams = {
  page: number;
  per_page: number;
  tab: TabName;
  order?: OrderType;
  filter: Partial<ActionsFilter>;
};

export const getActions = (
  bodyParams?: Partial<BodyParams>,
): Promise<GetActionsResponse> =>
  getClient().post('actions', { json: bodyParams }).json();

type ActionCounts = {
  [tabName in TabName]: number;
};

type GetActionCountsResponse = {
  data: ActionCounts;
};

export const postActionCounts = (
  filter = {} as Partial<ActionsFilter>,
): Promise<GetActionCountsResponse> =>
  getClient().post('actions/tabs_count', { json: { filter } }).json();

export type ApiTimezones = {
  data: string[];
};

export const getActionsTimezones = (): Promise<ApiTimezones> => {
  return getClient().get('actions/timezones').json();
};

export const getActionsAssignees = (): Promise<ApiAssignees> => {
  return getClient().get('actions/assignees').json();
};

export const getActionsCreators = (): Promise<ApiActionsCreators> => {
  return getClient().get('actions/creators').json();
};
