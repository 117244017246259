import { Record, Map, List } from 'immutable';
import Person from 'Modules/Actions/records/Person';
import FlowMetadata from 'Modules/Actions/records/FlowMetadata';
import {
  ACTION_PERSON_FLOW_ID_KEY_PATH,
  ACTION_CHILD_ID_KEY_PATH,
  ACTION_FLOW_ID_KEY_PATH,
} from 'Modules/ActionCompose/constants/graphql';
import { getTemplateName } from 'Modules/ActionCompose/utils';
import sharedDefaults from './defaults';
import flowDefaults from './flowDefaults';
import { isLead } from 'Utils/salesforce-object-identifier';
import { DEFAULT_ADDRESS_FIELD_NAMES } from 'Modules/FlowsShow/constants/index.ts';

const defaults = {
  ...sharedDefaults,
  ...flowDefaults,
  messages: new List(),
  messageHistories: new Map(),
  currentValues: new Map({
    sfdcType: null,
    body: null,
    activityResultType: null,
    activityResultId: null,
  }),
};

export default class SMSAction extends Record(defaults) {
  static fromGraphQL(action) {
    const executionContext = action.get('executionContext') || new Map();
    const person = action.get('who') || new Map();
    const sfdcFields = person.get('sfdcFields') || new Map();

    const mergedTemplate = executionContext?.get('mergedTemplate');
    const body = mergedTemplate?.get('body') || executionContext?.get('body');
    const templateName = getTemplateName(action, executionContext);
    const phones = (executionContext?.get('phones') || new List())
      .fromEntrySeq()
      .toMap();

    const isWhoLead = isLead(person?.get('sfdcId'));
    const selectedFieldKey = isWhoLead
      ? DEFAULT_ADDRESS_FIELD_NAMES.lead.phone
      : DEFAULT_ADDRESS_FIELD_NAMES.contact.phone;
    const selectedRecipientPhoneField =
      action.getIn(['child', 'flow', selectedFieldKey]) || 'phone';

    return new SMSAction({
      id: action.get('id'),
      source: action.get('source'),
      type: action.get('type'),
      personStepId: action.getIn(ACTION_CHILD_ID_KEY_PATH),
      summary: action.get('summary'),
      sourceMetadata: FlowMetadata.from(
        action.get('sourceMetadata')?.toJS() || {}
      ),
      priority: action.get('priority'),
      failedData: action.get('failedData'),
      error: action.get('error'),
      templateName,
      personFlowId: action.getIn(ACTION_PERSON_FLOW_ID_KEY_PATH),
      flowId: action.getIn(ACTION_FLOW_ID_KEY_PATH),
      ...(action.has('who') && {
        recipient: Person.from(person, null, phones),
      }),
      messages: executionContext.get('messages'),
      body,
      phones,
      currentValues: new Map({
        body,
        sfdcType: executionContext.get('sfdcType'),
        activityResultType: null,
        activityResultId: null,
        phone: sfdcFields.get('phone') || sfdcFields.get('mobilePhone'),
        selectedRecipientPhoneField,
      }),
    });
  }
}
