import { cellRenderer, TableText } from '@clari/iris-table';
import { SourceType } from '@groove/api/gateway/v1/actions';
import { Link } from '@material-ui/core';
import { BLANK_PLACEHOLDER } from 'Modules/ActionCompose/constants';
import { ActionCellParams } from 'Modules/ActionsRewrite/components/columnDefs/keysToColumnDefinitions';

const titleCellRenderer = cellRenderer((params: ActionCellParams) => {
  const { summary, source, summary_v2, flow_id, step_id } = params?.data || {};

  if (!shouldSplit(summary_v2, source))
    return {
      primaryContent: (
        <TableText>{summary === BLANK_PLACEHOLDER ? '' : summary}</TableText>
      ),
    };

  return {
    primaryContent: (
      <TableText>
        <Link href={`/flows/${flow_id}`} target="_blank">
          {summary_v2[0]}
        </Link>
      </TableText>
    ),
    secondaryContent: (
      <TableText>
        <Link href={`/steps/${step_id}/work`} target="_blank">
          {summary_v2[1]}
        </Link>
      </TableText>
    ),
  };
});

export default titleCellRenderer;

function shouldSplit(splitSummary: string[], source: SourceType) {
  return splitSummary.length === 2 && source === 'FLOW';
}
