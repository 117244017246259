import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, Menu, MenuItem, IconButton } from '@material-ui/core';
import { ArrowDropDownCircle } from '@material-ui/icons';
import {
  removeCustomColumn,
  sortCustomColumn,
} from 'Modules/Spaces/show/actions/firestore';
import { setNextOrderDirection } from 'Modules/Spaces/show/actions';
import {
  getAccountsTable,
  isExternalUserEditingColumnOrder as getIsExternalUserEditingColumnOrder,
  getAllSObjectsAreLoaded,
} from 'Modules/Spaces/show/selectors';
import { SORT_BY_KEY } from 'Modules/Spaces/show/constants';
import { DESC, ASC } from 'Modules/Shared/components/Table/constants';
import AddCustomColumnMenu from 'Modules/Spaces/show/containers/AddCustomColumnMenu';
import { RIT } from '@groove-labs/groove-ui';
import ClariTheme from 'Utils/theme/ClariTheme';

const muiClasses = {
  inputRoot: {
    width: 'calc(100% - 60px)',
  },
  svgIconRoot: {
    width: 30,
    height: 30,
  },
  svgIconLabel: {
    width: 'unset',
    color: ClariTheme.palette.text.secondary,
  },
  svgIconLabelDisabled: {
    width: 'unset',
    color: ClariTheme.palette.text.disabled,
  },
  menuItemRoot: {
    fontSize: 14,
    padding: '7px 15px 7px 15px',
  },
  menuPaper: {
    width: 134,
  },
};

const htmlClasses = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
const styles = {
  ...muiClasses,
  ...htmlClasses,
};

@withStyles(styles)
@connect(
  (state, ownProps) => {
    const accountsTable = getAccountsTable(state);
    const columnOrder = accountsTable.get('columnOrder');
    const customColumns = accountsTable.get('customColumns');
    const isCustomColumn = customColumns.has(ownProps.id);
    const indexPosition = columnOrder.findIndex(
      value => value.get('id') === ownProps.id
    );
    const isExternalUserEditingColumnOrder =
      getIsExternalUserEditingColumnOrder(state);
    const allSObjectsAreLoaded = getAllSObjectsAreLoaded(state);

    return {
      indexPosition,
      isCustomColumn,
      id: ownProps.id,
      columnOptionsDisabled:
        isExternalUserEditingColumnOrder || !allSObjectsAreLoaded,
    };
  },
  {
    removeCustomColumn,
    setNextOrderDirection,
    sortCustomColumn,
  }
)
export default class ColumnCellDropdown extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string,
      inputRoot: PropTypes.string,
      svgIconRoot: PropTypes.string,
    }).isRequired,
    // Parent Props
    id: PropTypes.string.isRequired,
    // Redux State
    indexPosition: PropTypes.number.isRequired,
    isCustomColumn: PropTypes.bool.isRequired,
    columnOptionsDisabled: PropTypes.bool.isRequired,
    // Redux Actions
    removeCustomColumn: PropTypes.func.isRequired,
    setNextOrderDirection: PropTypes.func.isRequired,
    sortCustomColumn: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  handleRemoveColumn = () => {
    const { id, removeCustomColumn } = this.props;

    removeCustomColumn(id);
    this.handleClose();
  };

  createRemoveColumnMenuOption = () => {
    const { classes, columnOptionsDisabled } = this.props;

    return (
      <MenuItem
        classes={{ root: classes.menuItemRoot }}
        key="removeColumn"
        onClick={this.handleRemoveColumn}
        disabled={columnOptionsDisabled}
      >
        Remove Column
      </MenuItem>
    );
  };

  handleSortColumn = direction => () => {
    const { id, isCustomColumn, setNextOrderDirection, sortCustomColumn } =
      this.props;

    if (isCustomColumn) {
      sortCustomColumn({
        columnId: id,
        orderDirection: direction,
      });
    } else {
      setNextOrderDirection({
        orderBy: id,
        sortBy: SORT_BY_KEY[id] || 'data',
        orderDirection: direction,
      });
    }
    this.handleClose();
  };

  createSortingMenuItems() {
    const { classes, columnOptionsDisabled } = this.props;
    const options = [
      {
        label: 'Sort ascending',
        direction: ASC,
      },
      {
        label: 'Sort descending',
        direction: DESC,
      },
    ];

    return options.map(({ label, direction }) => (
      <MenuItem
        classes={{ root: classes.menuItemRoot }}
        key={label}
        onClick={this.handleSortColumn(direction)}
        disabled={columnOptionsDisabled}
      >
        {label}
      </MenuItem>
    ));
  }

  handleOpen = ({ currentTarget }) =>
    this.setState({ open: true, anchorEl: currentTarget });

  handleClose = () => this.setState({ open: false });

  render() {
    const { classes, columnOptionsDisabled, indexPosition, isCustomColumn } =
      this.props;

    const { anchorEl, open } = this.state;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleOpen}
          classes={{
            root: classes.svgIconRoot,
            label: columnOptionsDisabled
              ? classes.svgIconLabelDisabled
              : classes.svgIconLabel,
          }}
          disabled={columnOptionsDisabled}
        >
          <ArrowDropDownCircle />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          classes={{ paper: classes.menuPaper }}
        >
          {this.createSortingMenuItems()}
          {RIT(isCustomColumn, this.createRemoveColumnMenuOption)}
          <AddCustomColumnMenu
            indexPosition={indexPosition}
            closeMainMenu={this.handleClose}
          />
        </Menu>
      </div>
    );
  }
}
