/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import makeHTTPRequest from 'GrooveHTTPClient/makeHTTPRequest';
import { DELETE, POST, PATCH } from 'GrooveHTTPClient/HTTPMethod';

export const BASE_PATH = '/api/v2/template_overrides';

export const create = (
  flowId: number,
  stepId: number,
  templateId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  templatePayload: any
) =>
  makeHTTPRequest({
    path: `${BASE_PATH}`,
    method: POST,
    body: {
      flow_Id: flowId,
      step_id: stepId,
      template_id: templateId,
      template: templatePayload.template,
    },
  });

export const update = (
  templateOverrideId: number,
  flowId: number,
  stepId: number,
  templateId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  templatePayload: any
) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${templateOverrideId}`,
    method: PATCH,
    body: {
      flow_Id: flowId,
      step_id: stepId,
      template_id: templateId,
      template: templatePayload.template,
    },
  });

export const destroy = (templateOverrideId: number) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${templateOverrideId}`,
    method: DELETE,
  });
