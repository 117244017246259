export const DURATIONS = [
  { text: '15 minutes', key: 15 },
  { text: '30 minutes', key: 30 },
  { text: '45 minutes', key: 45 },
  { text: '60 minutes', key: 60 },
  { text: '75 minutes', key: 75 },
  { text: '90 minutes', key: 90 },
  { text: '105 minutes', key: 105 },
  { text: '120 minutes', key: 120 },
];

export const TIME_ZONES = [
  { text: '(UTC) Coordinated Universal Time', key: 'UTC' },
  { text: '(GMT-11:00) Niue', key: 'Pacific/Niue' },
  { text: '(GMT-11:00) Pago Pago', key: 'Pacific/Pago_Pago' },
  { text: '(GMT-10:00) Hawaii Time', key: 'Pacific/Honolulu' },
  { text: '(GMT-10:00) Rarotonga', key: 'Pacific/Rarotonga' },
  { text: '(GMT-10:00) Tahiti', key: 'Pacific/Tahiti' },
  { text: '(GMT-09:30) Marquesas', key: 'Pacific/Marquesas' },
  { text: '(GMT-09:00) Alaska Time', key: 'America/Anchorage' },
  { text: '(GMT-09:00) Gambier', key: 'Pacific/Gambier' },
  { text: '(GMT-08:00) Pacific Time', key: 'America/Los_Angeles' },
  { text: '(GMT-08:00) Pacific Time - Tijuana', key: 'America/Tijuana' },
  { text: '(GMT-08:00) Pacific Time - Vancouver', key: 'America/Vancouver' },
  {
    text: '(GMT-08:00) Pacific Time - Whitehorse',
    key: 'America/Whitehorse',
  },
  { text: '(GMT-08:00) Pitcairn', key: 'Pacific/Pitcairn' },
  {
    text: '(GMT-07:00) Mountain Time - Dawson Creek',
    key: 'America/Dawson_Creek',
  },
  { text: '(GMT-07:00) Mountain Time', key: 'America/Denver' },
  { text: '(GMT-07:00) Mountain Time - Edmonton', key: 'America/Edmonton' },
  {
    text: '(GMT-07:00) Mountain Time - Hermosillo',
    key: 'America/Hermosillo',
  },
  {
    text: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
    key: 'America/Mazatlan',
  },
  { text: '(GMT-07:00) Mountain Time - Arizona', key: 'America/Phoenix' },
  {
    text: '(GMT-07:00) Mountain Time - Yellowknife',
    key: 'America/Yellowknife',
  },
  { text: '(GMT-06:00) Belize', key: 'America/Belize' },
  { text: '(GMT-06:00) Central Time', key: 'America/Chicago' },
  { text: '(GMT-06:00) Costa Rica', key: 'America/Costa_Rica' },
  { text: '(GMT-06:00) El Salvador', key: 'America/El_Salvador' },
  { text: '(GMT-06:00) Guatemala', key: 'America/Guatemala' },
  { text: '(GMT-06:00) Managua', key: 'America/Managua' },
  {
    text: '(GMT-06:00) Central Time - Mexico City',
    key: 'America/Mexico_City',
  },
  { text: '(GMT-06:00) Central Time - Regina', key: 'America/Regina' },
  {
    text: '(GMT-06:00) Central Time - Tegucigalpa',
    key: 'America/Tegucigalpa',
  },
  { text: '(GMT-06:00) Central Time - Winnipeg', key: 'America/Winnipeg' },
  { text: '(GMT-06:00) Galapagos', key: 'Pacific/Galapagos' },
  { text: '(GMT-05:00) Bogota', key: 'America/Bogota' },
  { text: '(GMT-05:00) America Cancun', key: 'America/Cancun' },
  { text: '(GMT-05:00) Cayman', key: 'America/Cayman' },
  { text: '(GMT-05:00) Guayaquil', key: 'America/Guayaquil' },
  { text: '(GMT-05:00) Havana', key: 'America/Havana' },
  { text: '(GMT-05:00) Eastern Time - Iqaluit', key: 'America/Iqaluit' },
  { text: '(GMT-05:00) Jamaica', key: 'America/Jamaica' },
  { text: '(GMT-05:00) Lima', key: 'America/Lima' },
  { text: '(GMT-05:00) Nassau', key: 'America/Nassau' },
  { text: '(GMT-05:00) Eastern Time', key: 'America/New_York' },
  { text: '(GMT-05:00) Panama', key: 'America/Panama' },
  { text: '(GMT-05:00) Port-au-Prince', key: 'America/Port-au-Prince' },
  { text: '(GMT-05:00) Rio Branco', key: 'America/Rio_Branco' },
  { text: '(GMT-05:00) Eastern Time - Toronto', key: 'America/Toronto' },
  { text: '(GMT-05:00) Easter Island', key: 'Pacific/Easter' },
  { text: '(GMT-04:30) Caracas', key: 'America/Caracas' },
  { text: '(GMT-03:00) Asuncion', key: 'America/Asuncion' },
  { text: '(GMT-04:00) Barbados', key: 'America/Barbados' },
  { text: '(GMT-04:00) Boa Vista', key: 'America/Boa_Vista' },
  { text: '(GMT-03:00) Campo Grande', key: 'America/Campo_Grande' },
  { text: '(GMT-03:00) Cuiaba', key: 'America/Cuiaba' },
  { text: '(GMT-04:00) Curacao', key: 'America/Curacao' },
  { text: '(GMT-04:00) Grand Turk', key: 'America/Grand_Turk' },
  { text: '(GMT-04:00) Guyana', key: 'America/Guyana' },
  { text: '(GMT-04:00) Atlantic Time - Halifax', key: 'America/Halifax' },
  { text: '(GMT-04:00) La Paz', key: 'America/La_Paz' },
  { text: '(GMT-04:00) Manaus', key: 'America/Manaus' },
  { text: '(GMT-04:00) Martinique', key: 'America/Martinique' },
  { text: '(GMT-04:00) Port of Spain', key: 'America/Port_of_Spain' },
  { text: '(GMT-04:00) Porto Velho', key: 'America/Porto_Velho' },
  { text: '(GMT-04:00) Puerto Rico', key: 'America/Puerto_Rico' },
  { text: '(GMT-04:00) Santo Domingo', key: 'America/Santo_Domingo' },
  { text: '(GMT-04:00) Thule', key: 'America/Thule' },
  { text: '(GMT-04:00) Bermuda', key: 'Atlantic/Bermuda' },
  {
    text: '(GMT-03:30) Newfoundland Time - St. Johns',
    key: 'America/St_Johns',
  },
  { text: '(GMT-03:00) Araguaina', key: 'America/Araguaina' },
  {
    text: '(GMT-03:00) Buenos Aires',
    key: 'America/Argentina/Buenos_Aires',
  },
  { text: '(GMT-03:00) Salvador', key: 'America/Bahia' },
  { text: '(GMT-03:00) Belem', key: 'America/Belem' },
  { text: '(GMT-03:00) Cayenne', key: 'America/Cayenne' },
  { text: '(GMT-03:00) Fortaleza', key: 'America/Fortaleza' },
  { text: '(GMT-03:00) Godthab', key: 'America/Godthab' },
  { text: '(GMT-03:00) Maceio', key: 'America/Maceio' },
  { text: '(GMT-03:00) Miquelon', key: 'America/Miquelon' },
  { text: '(GMT-03:00) Montevideo', key: 'America/Montevideo' },
  { text: '(GMT-03:00) Paramaribo', key: 'America/Paramaribo' },
  { text: '(GMT-03:00) Recife', key: 'America/Recife' },
  { text: '(GMT-03:00) Santiago', key: 'America/Santiago' },
  { text: '(GMT-03:00) Sao Paulo', key: 'America/Sao_Paulo' },
  { text: '(GMT-03:00) Palmer', key: 'Antarctica/Palmer' },
  { text: '(GMT-03:00) Rothera', key: 'Antarctica/Rothera' },
  { text: '(GMT-03:00) Stanley', key: 'Atlantic/Stanley' },
  { text: '(GMT-02:00) Noronha', key: 'America/Noronha' },
  { text: '(GMT-02:00) South Georgia', key: 'Atlantic/South_Georgia' },
  { text: '(GMT-01:00) Scoresbysund', key: 'America/Scoresbysund' },
  { text: '(GMT-01:00) Azores', key: 'Atlantic/Azores' },
  { text: '(GMT-01:00) Cape Verde', key: 'Atlantic/Cape_Verde' },
  { text: '(GMT+00:00) Abidjan', key: 'Africa/Abidjan' },
  { text: '(GMT+00:00) Accra', key: 'Africa/Accra' },
  { text: '(GMT+00:00) Bissau', key: 'Africa/Bissau' },
  { text: '(GMT+00:00) Casablanca', key: 'Africa/Casablanca' },
  { text: '(GMT+00:00) El Aaiun', key: 'Africa/El_Aaiun' },
  { text: '(GMT+00:00) Monrovia', key: 'Africa/Monrovia' },
  { text: '(GMT+00:00) Danmarkshavn', key: 'America/Danmarkshavn' },
  { text: '(GMT+00:00) Canary Islands', key: 'Atlantic/Canary' },
  { text: '(GMT+00:00) Faeroe', key: 'Atlantic/Faroe' },
  { text: '(GMT+00:00) Reykjavik', key: 'Atlantic/Reykjavik' },
  { text: '(GMT+00:00) GMT (no daylight saving)', key: 'Etc/GMT' },
  { text: '(GMT+00:00) Dublin', key: 'Europe/Dublin' },
  { text: '(GMT+00:00) Lisbon', key: 'Europe/Lisbon' },
  { text: '(GMT+00:00) London', key: 'Europe/London' },
  { text: '(GMT+01:00) Algiers', key: 'Africa/Algiers' },
  { text: '(GMT+01:00) Ceuta', key: 'Africa/Ceuta' },
  { text: '(GMT+01:00) Lagos', key: 'Africa/Lagos' },
  { text: '(GMT+01:00) Ndjamena', key: 'Africa/Ndjamena' },
  { text: '(GMT+01:00) Tunis', key: 'Africa/Tunis' },
  { text: '(GMT+02:00) Windhoek', key: 'Africa/Windhoek' },
  { text: '(GMT+01:00) Amsterdam', key: 'Europe/Amsterdam' },
  { text: '(GMT+01:00) Andorra', key: 'Europe/Andorra' },
  {
    text: '(GMT+01:00) Central European Time - Belgrade',
    key: 'Europe/Belgrade',
  },
  { text: '(GMT+01:00) Berlin', key: 'Europe/Berlin' },
  { text: '(GMT+01:00) Brussels', key: 'Europe/Brussels' },
  { text: '(GMT+01:00) Budapest', key: 'Europe/Budapest' },
  { text: '(GMT+01:00) Copenhagen', key: 'Europe/Copenhagen' },
  { text: '(GMT+01:00) Gibraltar', key: 'Europe/Gibraltar' },
  { text: '(GMT+01:00) Luxembourg', key: 'Europe/Luxembourg' },
  { text: '(GMT+01:00) Madrid', key: 'Europe/Madrid' },
  { text: '(GMT+01:00) Malta', key: 'Europe/Malta' },
  { text: '(GMT+01:00) Monaco', key: 'Europe/Monaco' },
  { text: '(GMT+01:00) Oslo', key: 'Europe/Oslo' },
  { text: '(GMT+01:00) Paris', key: 'Europe/Paris' },
  {
    text: '(GMT+01:00) Central European Time - Prague',
    key: 'Europe/Prague',
  },
  { text: '(GMT+01:00) Rome', key: 'Europe/Rome' },
  { text: '(GMT+01:00) Stockholm', key: 'Europe/Stockholm' },
  { text: '(GMT+01:00) Tirane', key: 'Europe/Tirane' },
  { text: '(GMT+01:00) Vienna', key: 'Europe/Vienna' },
  { text: '(GMT+01:00) Warsaw', key: 'Europe/Warsaw' },
  { text: '(GMT+01:00) Zurich', key: 'Europe/Zurich' },
  { text: '(GMT+02:00) Cairo', key: 'Africa/Cairo' },
  { text: '(GMT+02:00) Johannesburg', key: 'Africa/Johannesburg' },
  { text: '(GMT+02:00) Maputo', key: 'Africa/Maputo' },
  { text: '(GMT+02:00) Tripoli', key: 'Africa/Tripoli' },
  { text: '(GMT+02:00) Amman', key: 'Asia/Amman' },
  { text: '(GMT+02:00) Beirut', key: 'Asia/Beirut' },
  { text: '(GMT+02:00) Damascus', key: 'Asia/Damascus' },
  { text: '(GMT+02:00) Gaza', key: 'Asia/Gaza' },
  { text: '(GMT+02:00) Jerusalem', key: 'Asia/Jerusalem' },
  { text: '(GMT+02:00) Nicosia', key: 'Asia/Nicosia' },
  { text: '(GMT+02:00) Athens', key: 'Europe/Athens' },
  { text: '(GMT+02:00) Bucharest', key: 'Europe/Bucharest' },
  { text: '(GMT+02:00) Chisinau', key: 'Europe/Chisinau' },
  { text: '(GMT+02:00) Helsinki', key: 'Europe/Helsinki' },
  { text: '(GMT+02:00) Istanbul', key: 'Europe/Istanbul' },
  { text: '(GMT+02:00) Moscow-01 - Kaliningrad', key: 'Europe/Kaliningrad' },
  { text: '(GMT+02:00) Kiev', key: 'Europe/Kiev' },
  { text: '(GMT+02:00) Riga', key: 'Europe/Riga' },
  { text: '(GMT+02:00) Sofia', key: 'Europe/Sofia' },
  { text: '(GMT+02:00) Tallinn', key: 'Europe/Tallinn' },
  { text: '(GMT+02:00) Vilnius', key: 'Europe/Vilnius' },
  { text: '(GMT+03:00) Khartoum', key: 'Africa/Khartoum' },
  { text: '(GMT+03:00) Nairobi', key: 'Africa/Nairobi' },
  { text: '(GMT+03:00) Syowa', key: 'Antarctica/Syowa' },
  { text: '(GMT+03:00) Baghdad', key: 'Asia/Baghdad' },
  { text: '(GMT+03:00) Qatar', key: 'Asia/Qatar' },
  { text: '(GMT+03:00) Riyadh', key: 'Asia/Riyadh' },
  { text: '(GMT+03:00) Minsk', key: 'Europe/Minsk' },
  { text: '(GMT+03:00) Moscow+00 - Moscow', key: 'Europe/Moscow' },
  { text: '(GMT+03:30) Tehran', key: 'Asia/Tehran' },
  { text: '(GMT+04:00) Baku', key: 'Asia/Baku' },
  { text: '(GMT+04:00) Dubai', key: 'Asia/Dubai' },
  { text: '(GMT+04:00) Tbilisi', key: 'Asia/Tbilisi' },
  { text: '(GMT+04:00) Yerevan', key: 'Asia/Yerevan' },
  { text: '(GMT+04:00) Moscow+01 - Samara', key: 'Europe/Samara' },
  { text: '(GMT+04:00) Mahe', key: 'Indian/Mahe' },
  { text: '(GMT+04:00) Mauritius', key: 'Indian/Mauritius' },
  { text: '(GMT+04:00) Reunion', key: 'Indian/Reunion' },
  { text: '(GMT+04:30) Kabul', key: 'Asia/Kabul' },
  { text: '(GMT+05:00) Mawson', key: 'Antarctica/Mawson' },
  { text: '(GMT+05:00) Aqtau', key: 'Asia/Aqtau' },
  { text: '(GMT+05:00) Aqtobe', key: 'Asia/Aqtobe' },
  { text: '(GMT+05:00) Ashgabat', key: 'Asia/Ashgabat' },
  { text: '(GMT+05:00) Dushanbe', key: 'Asia/Dushanbe' },
  { text: '(GMT+05:00) Karachi', key: 'Asia/Karachi' },
  { text: '(GMT+05:00) Tashkent', key: 'Asia/Tashkent' },
  {
    text: '(GMT+05:00) Moscow+02 - Yekaterinburg',
    key: 'Asia/Yekaterinburg',
  },
  { text: '(GMT+05:00) Kerguelen', key: 'Indian/Kerguelen' },
  { text: '(GMT+05:00) Maldives', key: 'Indian/Maldives' },
  { text: '(GMT+05:30) India Standard Time', key: 'Asia/Calcutta' },
  { text: '(GMT+05:30) Kolkata', key: 'Asia/Kolkata' },
  { text: '(GMT+05:30) Colombo', key: 'Asia/Colombo' },
  { text: '(GMT+05:45) Katmandu', key: 'Asia/Katmandu' },
  { text: '(GMT+06:00) Vostok', key: 'Antarctica/Vostok' },
  { text: '(GMT+06:00) Almaty', key: 'Asia/Almaty' },
  { text: '(GMT+06:00) Bishkek', key: 'Asia/Bishkek' },
  { text: '(GMT+06:00) Dhaka', key: 'Asia/Dhaka' },
  { text: '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk', key: 'Asia/Omsk' },
  { text: '(GMT+06:00) Thimphu', key: 'Asia/Thimphu' },
  { text: '(GMT+06:00) Chagos', key: 'Indian/Chagos' },
  { text: '(GMT+06:30) Rangoon', key: 'Asia/Rangoon' },
  { text: '(GMT+06:30) Cocos', key: 'Indian/Cocos' },
  { text: '(GMT+07:00) Davis', key: 'Antarctica/Davis' },
  { text: '(GMT+07:00) Bangkok', key: 'Asia/Bangkok' },
  { text: '(GMT+07:00) Hovd', key: 'Asia/Hovd' },
  { text: '(GMT+07:00) Jakarta', key: 'Asia/Jakarta' },
  { text: '(GMT+07:00) Moscow+04 - Krasnoyarsk', key: 'Asia/Krasnoyarsk' },
  { text: '(GMT+07:00) Hanoi', key: 'Asia/Saigon' },
  { text: '(GMT+07:00) Christmas', key: 'Indian/Christmas' },
  { text: '(GMT+08:00) Casey', key: 'Antarctica/Casey' },
  { text: '(GMT+08:00) Brunei', key: 'Asia/Brunei' },
  { text: '(GMT+08:00) Choibalsan', key: 'Asia/Choibalsan' },
  { text: '(GMT+08:00) Hong Kong', key: 'Asia/Hong_Kong' },
  { text: '(GMT+08:00) Moscow+05 - Irkutsk', key: 'Asia/Irkutsk' },
  { text: '(GMT+08:00) Kuala Lumpur', key: 'Asia/Kuala_Lumpur' },
  { text: '(GMT+08:00) Macau', key: 'Asia/Macau' },
  { text: '(GMT+08:00) Makassar', key: 'Asia/Makassar' },
  { text: '(GMT+08:00) Manila', key: 'Asia/Manila' },
  { text: '(GMT+08:00) China Time - Beijing', key: 'Asia/Shanghai' },
  { text: '(GMT+08:00) Singapore', key: 'Asia/Singapore' },
  { text: '(GMT+08:00) Taipei', key: 'Asia/Taipei' },
  { text: '(GMT+08:00) Ulaanbaatar', key: 'Asia/Ulaanbaatar' },
  { text: '(GMT+08:00) Western Time - Perth', key: 'Australia/Perth' },
  { text: '(GMT+08:30) Pyongyang', key: 'Asia/Pyongyang' },
  { text: '(GMT+09:00) Dili', key: 'Asia/Dili' },
  { text: '(GMT+09:00) Jayapura', key: 'Asia/Jayapura' },
  { text: '(GMT+09:00) Seoul', key: 'Asia/Seoul' },
  { text: '(GMT+09:00) Tokyo', key: 'Asia/Tokyo' },
  { text: '(GMT+09:00) Moscow+06 - Yakutsk', key: 'Asia/Yakutsk' },
  { text: '(GMT+09:00) Palau', key: 'Pacific/Palau' },
  { text: '(GMT+10:30) Central Time - Adelaide', key: 'Australia/Adelaide' },
  { text: '(GMT+09:30) Central Time - Darwin', key: 'Australia/Darwin' },
  { text: "(GMT+10:00) Dumont D'Urville", key: 'Antarctica/DumontDUrville' },
  { text: '(GMT+10:00) Moscow+07 - Magadan', key: 'Asia/Magadan' },
  {
    text: '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk',
    key: 'Asia/Vladivostok',
  },
  { text: '(GMT+10:00) Eastern Time - Brisbane', key: 'Australia/Brisbane' },
  { text: '(GMT+11:00) Eastern Time - Hobart', key: 'Australia/Hobart' },
  {
    text: '(GMT+11:00) Eastern Time - Melbourne, Sydney',
    key: 'Australia/Sydney',
  },
  { text: '(GMT+10:00) Truk', key: 'Pacific/Chuuk' },
  { text: '(GMT+10:00) Guam', key: 'Pacific/Guam' },
  { text: '(GMT+10:00) Port Moresby', key: 'Pacific/Port_Moresby' },
  { text: '(GMT+11:00) Efate', key: 'Pacific/Efate' },
  { text: '(GMT+11:00) Guadalcanal', key: 'Pacific/Guadalcanal' },
  { text: '(GMT+11:00) Kosrae', key: 'Pacific/Kosrae' },
  { text: '(GMT+11:00) Norfolk', key: 'Pacific/Norfolk' },
  { text: '(GMT+11:00) Noumea', key: 'Pacific/Noumea' },
  { text: '(GMT+11:00) Ponape', key: 'Pacific/Pohnpei' },
  {
    text: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
    key: 'Asia/Kamchatka',
  },
  { text: '(GMT+13:00) Auckland', key: 'Pacific/Auckland' },
  { text: '(GMT+13:00) Fiji', key: 'Pacific/Fiji' },
  { text: '(GMT+12:00) Funafuti', key: 'Pacific/Funafuti' },
  { text: '(GMT+12:00) Kwajalein', key: 'Pacific/Kwajalein' },
  { text: '(GMT+12:00) Majuro', key: 'Pacific/Majuro' },
  { text: '(GMT+12:00) Nauru', key: 'Pacific/Nauru' },
  { text: '(GMT+12:00) Tarawa', key: 'Pacific/Tarawa' },
  { text: '(GMT+12:00) Wake', key: 'Pacific/Wake' },
  { text: '(GMT+12:00) Wallis', key: 'Pacific/Wallis' },
  { text: '(GMT+14:00) Apia', key: 'Pacific/Apia' },
  { text: '(GMT+13:00) Enderbury', key: 'Pacific/Enderbury' },
  { text: '(GMT+13:00) Fakaofo', key: 'Pacific/Fakaofo' },
  { text: '(GMT+13:00) Tongatapu', key: 'Pacific/Tongatapu' },
  { text: '(GMT+14:00) Kiritimati', key: 'Pacific/Kiritimati' },
];

// This needs to match MeetingRequestTemplate::VideoConferencings in GE
export const VIDEO_CONFERENCINGS = {
  hangouts: 0,
  zoom: 1,
  msft_teams: 2,
};

// ID created to support the need of having a one-off meeting without
// clashing with existing Meeting Types IDs that are fecthed from the database
export const ONE_OFF_MEETING_STATIC_ID = -10;
export const ONE_OFF_MEETING_OBJECT = {
  created_at: '',
  custom_link: '',
  description: '',
  duration: 1800,
  enabled: true,
  id: ONE_OFF_MEETING_STATIC_ID,
  location: null,
  max_days_ahead: null,
  min_hours_ahead: null,
  name: "Don't use a saved meeting type",
  subject: '',
  updated_at: '',
  video_conferencing: '',
};
