import cx from 'classnames';
import {
  FocusEventHandler,
  HTMLProps,
  KeyboardEventHandler,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { VariableSizeList } from 'react-window';
import { motion } from 'framer-motion';

import useHandleOutsideClicks from '../helpers/useHandleOutsideClicks';
import { RequireAtLeastOne } from '../type';

import Text from './Text';
import DropDownFrame from './DropDownFrame';
import Item, { DropDownItem, StandardDropDownItem } from './DropDownItem';

// export all the types from DropDown Item
export * from './DropDownItem';

export type PicklistRef = {
  closePane: () => void;
  openPane: () => void;
  focusInput: () => void;
};

export type DropDownListProps = {
  internalChild?: JSX.Element;
  renderedText?: string;
  items: DropDownItem[];
  disabled?: boolean;
  onChange?: (value: StandardDropDownItem | null) => void;
  onSearchChange?: (value: string) => void;
  onClear?: () => void;
  onOpenChange?: (value: boolean) => void;
  onInputFocusChange?: (value: boolean) => void;
  selectedValues?: StandardDropDownItem[] | null;
  className?: HTMLProps<HTMLElement>['className'];
  maxHeight?: number;
  hasSearch?: true;
  borderNone?: true;
};

const DropDownList = forwardRef<
  PicklistRef,
  RequireAtLeastOne<DropDownListProps, 'internalChild' | 'renderedText'>
>(
  (
    {
      internalChild = null,
      renderedText,
      items,
      disabled,
      onChange,
      onSearchChange,
      onClear,
      onOpenChange,
      onInputFocusChange,
      selectedValues,
      className,
      maxHeight = 200,
      hasSearch,
      borderNone,
    },
    forwardedRef,
  ) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const listRef = useRef<VariableSizeList | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dropDownRef = useRef<VariableSizeList<any>>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [inputFocused, setInputFocused] = useState(false);

    // These two helper functions are needed because dividers and headers are part of a DropDown
    const getNextSelectableIndex = (index: number): number => {
      if (items.length < 1) return -1;
      if (!items[index].type) return index;

      for (let i = index; i < items.length; i += 1) {
        if (!items[i].type) return i;
      }

      return -1;
    };

    const getPrevSelectableIndex = (index: number): number => {
      if (!items[index].type) return index;

      for (let i = index; i >= 0; i -= 1) {
        if (!items[i].type) return i;
      }

      return items.length - 1;
    };

    const [highlightedIndex, setHighlightedIndex] = useState<number>(() =>
      getNextSelectableIndex(0),
    );

    useImperativeHandle(forwardedRef, () => ({
      closePane: () => {
        setIsOpen(false);
        removeFocus();
      },
      openPane: onInputClick,
      focusInput: () => inputRef.current?.focus(),
    }));

    useEffect(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }, [items]);

    useEffect(() => {
      updateHighlightedIndex();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const setSearch = (value: string): void => {
      setSearchValue(value);
      onSearchChange?.(value);
    };

    const removeFocus = (): void => {
      onInputFocusChange?.(false);
      setInputFocused(false);
      setSearch('');
      inputRef.current?.blur();
    };

    const onFocus: FocusEventHandler<
      HTMLInputElement & HTMLTextAreaElement
    > = event => {
      onInputFocusChange?.(true);
      setInputFocused(true);
      event.target.setAttribute('autocomplete', 'off');
    };

    const updateHighlightedIndex = (): void => {
      let index = 0;
      items.forEach((item, i) => {
        if (item && !item.type && item.value === selectedValues?.[0]?.value) {
          index = i;
        }
        return index;
      });
      setHighlightedIndex(index);
    };

    const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (
      event,
    ): void => {
      let newIndex;
      if (event.key === 'Enter') {
        removeFocus();
        if (searchValue.length > 0 || !items[highlightedIndex].type)
          handleItemClick(items[highlightedIndex] as StandardDropDownItem);
      } else if (event.key === 'ArrowDown') {
        if (highlightedIndex + 1 >= items.length)
          newIndex = getNextSelectableIndex(0);
        else newIndex = getNextSelectableIndex(highlightedIndex + 1);
        setHighlightedIndex(newIndex);
        dropDownRef.current?.scrollToItem(newIndex);
      } else if (event.key === 'ArrowUp') {
        if (highlightedIndex <= 0)
          newIndex = getPrevSelectableIndex(items.length - 1);
        else newIndex = getPrevSelectableIndex(highlightedIndex - 1);
        setHighlightedIndex(newIndex);
        dropDownRef.current?.scrollToItem(newIndex);
      } else {
        updateHighlightedIndex();
        setIsOpen(true);
        onOpenChange?.(true);
      }
    };

    const handleItemClick = (value: StandardDropDownItem): void => {
      onChange?.(value);
      removeFocus();
      setIsOpen(false);
      onOpenChange?.(false);
    };

    useHandleOutsideClicks(ref, () => {
      setSearch('');
      setIsOpen(false);
      onOpenChange?.(false);
      removeFocus();
    });

    const shouldShowDropdown = isOpen && items.length > 0;

    const onInputClick = (): void => {
      if (disabled) return;
      updateHighlightedIndex();
      setIsOpen(true);
      onOpenChange?.(true);
      inputRef.current?.focus();
    };

    const handleClear = (): void => {
      onClear?.();
      removeFocus();
      setIsOpen(false);
      onOpenChange?.(false);
    };

    return (
      <div className={cx('relative', className)} ref={ref}>
        <button
          type="button"
          className={cx(
            'text-left bg-white background w-full flex items-center border-solid rounded active:border-clari-blue/600 focus:border-clari-blue/600 focus:outline-none flex-row border-neutral/200 hover:border-neutral/900 relative box-border',
            disabled && 'bg-neutral/50 cursor-not-allowed',
            !disabled &&
              !isOpen &&
              'hover:border-neutral/900 active:border-clari-blue/600 active:border-[1px] active:p-0 cursor-pointer',
            isOpen && 'border-clari-blue/600 border-[1px] h-[30px] p-0',
            !isOpen && 'border-[1px] h-[30px] p-[1px]',
            borderNone && '!border-transparent',
          )}
          onClick={onInputClick}
        >
          {hasSearch && (
            <input
              disabled={disabled}
              className="flex-grow bg-transparent border-0 outline-none w-full box-border absolute top-0 py-0 h-full font-groove body-sm flex-1 z-10 px-[7px] box"
              value={searchValue}
              onFocus={onFocus}
              ref={inputRef}
              onKeyDown={onInputKeyDown}
              onChange={e => setSearch(e.target.value || '')}
            />
          )}
          <motion.div
            className="relative flex-1 h-full items-center flex min-w-0"
            initial={{ opacity: 1 }}
            animate={{
              opacity: inputFocused && hasSearch ? 0 : 1,
            }}
            transition={{
              duration: 0.2,
              ease: 'easeInOut',
              delay: inputFocused ? 0 : 0.1,
            }}
          >
            {internalChild || (
              <div className="overflow-hidden px-[7px] min-w-0">
                <Text
                  variant="body-sm"
                  className={cx(
                    'whitespace-pre pointer-events-none truncate',
                    disabled && 'text-neutral/400',
                  )}
                >
                  {renderedText}
                </Text>
              </div>
            )}
          </motion.div>
        </button>

        <DropDownFrame
          itemList={items}
          itemSize={index => {
            const item = items[index];
            if (item.type === 'header') return 32;
            if (item.type === 'clear') return 32;
            if (item.type === 'divider') return 4;
            return item.secondaryText ? 48 : 32;
          }}
          isVariable
          showDropDown={shouldShowDropdown}
          renderedItem={Item}
          width="100%"
          maxHeight={maxHeight}
          innerRef={dropDownRef}
          itemData={{
            hasSearch,
            highlightedIndex,
            items,
            onClick: handleItemClick,
            onClear: handleClear,
            searchTerm: searchValue,
            truncate: true,
          }}
        />
      </div>
    );
  },
);

export default DropDownList;
