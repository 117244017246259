import { reducers as sharedReducers } from '@groove-labs/groove-ui';

import accountPreview from './accountPreview';
import accessControls from './accessControls';
import activitySettings from './activitySettings';
import activityLog from './activityLog';
import authorizations from './authorizations';
import batchJobs from './batchJobs';
import cached from './cached';
import dialer from './dialer';
import featureFlags from './featureFlags';
import omnibar from './omnibar';
import productUsage from './productUsage';
import salesforceMeta from './salesforceMeta';
import savedSearches from './savedSearches';
import labels from './labels';
import users from './users';
import autoCreationOfRecords from './autoCreationOfRecords';
import campaignAssociation from './campaignAssociation';

const { ui, form } = sharedReducers;

export {
  accountPreview,
  accessControls,
  activityLog,
  activitySettings,
  authorizations,
  batchJobs,
  cached,
  dialer,
  featureFlags,
  form,
  labels,
  omnibar,
  productUsage,
  salesforceMeta,
  savedSearches,
  ui,
  users,
  autoCreationOfRecords,
  campaignAssociation,
};
