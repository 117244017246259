// https://github.com/jfairbank/redux-saga-router#browser-history-with-react
import querystring from 'querystring';
import { createBrowserHistory } from '@groove-labs/redux-saga-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { setActiveTab } from 'Modules/Shared/actions/location';

const basename = new URL(process.env.PUBLIC_URL).pathname;

const history = createBrowserHistory({ basename });

export function getPathName() {
  if (basename === '/') {
    return window.location.pathname;
  }
  return window.location.pathname.split(basename)[1] || '/';
}

// Done this way as opposed to location.replace/history.replace to avoid reloading the browser window
export function pushHistoryState(newUrl) {
  window.history.pushState({ path: `${basename}${newUrl}` }, '', newUrl);
}

@connect(
  () => {
    // Arguments: state, ownProps
    return {};
  },
  {
    pushSnackbarMessage,
    setActiveTab,
  }
)
export class Link extends Component {
  static propTypes = {
    children: PropTypes.any, // eslint-disable-line
    className: PropTypes.string,
    duration: PropTypes.number,
    message: PropTypes.string,
    pushSnackbarMessage: PropTypes.func.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    to: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    duration: 5000,
    message: '',
    disabled: false,
  };

  onClick = e => {
    const { to, message, duration, pushSnackbarMessage, setActiveTab } =
      this.props;

    const [toPathName, toQueryString] = to.split('?');

    e.preventDefault();
    if (e.metaKey) {
      window.open(`${window.location.origin}${to}`);
    } else if (toPathName === location.pathname) {
      pushHistoryState(to);
      const queryParams = querystring.parse(toQueryString);
      if (queryParams.activeTab) {
        setActiveTab({ activeTab: queryParams.activeTab });
      }
    } else {
      history.push(to);
    }

    if (message && duration) {
      pushSnackbarMessage({ message, duration });
    }
  };

  render() {
    const { to, className, children, disabled } = this.props;

    return disabled ? (
      <span className={className}>{children}</span>
    ) : (
      <a href={to} className={className} onClick={this.onClick}>
        {children}
      </a>
    );
  }
}

export { history };
