import { todaysProjectedEmailUsage } from 'GrooveHTTPClient/users';

import { actionTypes } from 'Modules/Shared/actions/remainingEmailSendQuota';
import { add as addWarning } from 'Modules/App/actions/snackbarWarnings';

import { ALERT, WARN } from 'Modules/App/constants';

import {
  CHECK_INTERVAL,
  CLOSE_EXCEED_LIMIT,
  DAILY_EMAIL_SEND_LIMIT_WARNING_KEY,
  SUPER_CLOSE_EXCEED_LIMIT,
} from 'Modules/Shared/constants/remainingEmailSendQuota';

import {
  all,
  call,
  delay,
  fork,
  race,
  take,
  takeLatest,
  put,
} from 'redux-saga/effects';

// -------------- Handlers --------------

function* intervalCheck() {
  while (true) {
    const response = yield call(todaysProjectedEmailUsage);

    if (response) {
      const dailyEmailSendLimit = response.data.dailyEmailSendLimit;

      const remainingQuota =
        dailyEmailSendLimit - response.data.todaysProjectedEmailUsage;

      if (remainingQuota <= 0) {
        yield put(
          addWarning({
            key: DAILY_EMAIL_SEND_LIMIT_WARNING_KEY,
            message: `Email Send Limit Reached (${dailyEmailSendLimit} max)`,
            submessage: 'All pending emails will be rescheduled',
            variant: ALERT,
          })
        );
      } else if (remainingQuota <= SUPER_CLOSE_EXCEED_LIMIT) {
        yield put(
          addWarning({
            key: DAILY_EMAIL_SEND_LIMIT_WARNING_KEY,
            message: 'Approaching Email Send Limit',
            submessage: `${remainingQuota} Left`,
            variant: WARN,
          })
        );
      } else if (remainingQuota <= CLOSE_EXCEED_LIMIT) {
        yield put(
          addWarning({
            key: DAILY_EMAIL_SEND_LIMIT_WARNING_KEY,
            message: 'Reaching Email Send Limit',
            submessage: `${remainingQuota} Left`,
            variant: WARN,
          })
        );
      }
    }

    const { stop } = yield race({
      stop: take(actionTypes.STOP_INTERVAL_CHECK),
      proceed: delay(CHECK_INTERVAL),
    });

    // break the loop when stop action is run
    if (stop) break;
  }
}

// -------------- Watchers --------------

function* watchStartIntervalCheck() {
  yield takeLatest(actionTypes.START_INTERVAL_CHECK, intervalCheck);
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([fork(watchStartIntervalCheck)]);
}
