import { ActionType, FullAction } from '@groove/api/gateway/v1/actionCompose';

import { ErrorMessage, SourceType } from './types';

export const SCHEDULER_SETTINGS_URL = `${
  process.env.WEB_APPLICATION_BASE_URL || process.env.NX_FEBES_URL
}/settings?optionsGroup=scheduler`;
export const DIALER_SETTINGS_URL = `${
  process.env.WEB_APPLICATION_BASE_URL || process.env.NX_FEBES_URL
}/settings?activeTab=my_settings&optionsGroup=phoneNumbers`;
export const VOICEMAIL_SETTINGS = `${
  process.env.WEB_APPLICATION_BASE_URL || process.env.NX_FEBES_URL
}/settings?optionsGroup=voicemailRecording`;
export const LINKEDIN_ACTIONS_URL =
  'https://www.linkedin.com/sales/widget/profile-matching';
export const GMAIL_EMAIL_HEADER_CLASS = '.aeH';
export const APP_MODULE_ROOT_CLASS = 'div[class^="webapp-ModuleRoot-root"]';
export const COMPOSE_PANE_IFRAME_NAME = 'febes-action-compose';
export const COMPOSE_PANE_IFRAME_NATIVE_NAME = 'actionCompose';
export const LIGHT_GREY = 'rgba(0, 0, 0, 0.12)';
export const GROOVE_IFRAME_ROOT = 'groove-actions-iframe-root';
export const TASK_PANE_LOCAL_KEY = 'actionComposeTaskPane';
export const TYPES: { [key: string]: ActionType } = {
  EMAIL: 'TEMPLATE',
  CALL: 'CALL',
  IN_MAIL: 'STEP_SUBTYPE_LINKEDIN_INMAIL',
  LINKEDIN_CONNECT: 'STEP_SUBTYPE_LINKEDIN_CONNECT',
  SENDOSO: 'STEP_SUBTYPE_SENDOSO',
  SMS: 'STEP_SUBTYPE_SMS',
  IN_PERSON_VISIT: 'STEP_SUBTYPE_IN_PERSON_VISIT',
  DIRECT_MAIL: 'STEP_SUBTYPE_DIRECT_MAIL',
  OTHER: 'OTHER',
  GENERAL: 'GENERAL',
};

export const SF_MAPPING_TYPES: { [key: string]: string } = {
  TEMPLATE: 'sf_email_mapping',
  CALL: 'sf_call_mapping',
  STEP_SUBTYPE_LINKEDIN_INMAIL: 'sf_linkedin_inmail_mapping',
  STEP_SUBTYPE_LINKEDIN_CONNECT: 'sf_linkedin_connect_mapping',
  STEP_SUBTYPE_SMS: 'sf_sms_mapping',
  STEP_SUBTYPE_SENDOSO: 'sf_sendoso_mapping',
  STEP_SUBTYPE_IN_PERSON_VISIT: 'sf_in_person_visit_mapping',
  GENERAL: 'sf_task_mapping',
  OTHER: 'sf_task_mapping',
};
export const SOURCE_TYPE: { [key in SourceType]: SourceType } = {
  FLOW: 'FLOW',
  WORKSPACE: 'WORKSPACE',
  ONE_OFF: 'ONE_OFF',
  PLAY: 'PLAY',
  SUGGESTED_ACTION: 'SUGGESTED_ACTION',
};

export const ErrorType: { [key: string]: string } = {
  UpdateActionError: 'UpdateActionError',
  CreateActionError: 'CreateActionError',
  DeleteActionError: 'DeleteActionError',
  GetActionError: 'GetActionError',
  ExecuteActionError: 'ExecuteActionError',
  UnknownError: 'UnknownError',
};

export const errorMessages = {
  unauthorized: (action: string) =>
    `You’re not authorized to ${action} this action. Please contact your admin for additional assistance.`,
  invalidField: (action: string) =>
    `Unable to ${action} action due to invalid fields.`,
  notFound: (action: string) => `Unable to ${action} action. Action not found.`,
  tryAgain: (action: string) => `Unable to ${action}, please try again.`,
  unknown: () =>
    'Oops! Something went wrong on our end. Our team is looking into it but for immediate help, please reach out to your support contact.',
};

export const errorMessageMap: { [key: string]: ErrorMessage } = {
  UpdateActionError: {
    400: errorMessages.invalidField('save'),
    404: errorMessages.notFound('save'),
    422: errorMessages.tryAgain('save action'),
  },
  CreateActionError: {
    400: errorMessages.invalidField('create'),
  },
  DeleteActionError: {
    404: errorMessages.notFound('delete'),
  },
  GetActionError: {
    401: errorMessages.unauthorized('view'),
    404: errorMessages.notFound('view'),
  },
  ExecuteActionError: {
    400: errorMessages.invalidField('execute'),
    422: errorMessages.tryAgain('log this action to Salesforce'),
  },
  UnknownError: {
    500: 'Oops! Something went wrong on our end. Our team is looking into it but for immediate help, please reach out to your support contact.',
  },
};

type ActionTyping = {
  value: ActionType;
  label: string;
};

export type ActionTypings = ActionTyping[];

export const ACTION_TYPES: ActionTypings = [
  {
    value: 'GENERAL',
    label: 'Task',
  },
  {
    value: 'TEMPLATE',
    label: 'Email',
  },
  {
    value: 'CALL',
    label: 'Call',
  },
  {
    value: 'STEP_SUBTYPE_SMS',
    label: 'SMS',
  },
  {
    value: 'STEP_SUBTYPE_LINKEDIN_CONNECT',
    label: 'LinkedIn Connect',
  },
  {
    value: 'STEP_SUBTYPE_LINKEDIN_INMAIL',
    label: 'LinkedIn InMail',
  },
  {
    value: 'STEP_SUBTYPE_SENDOSO',
    label: 'Sendoso',
  },
  {
    value: 'STEP_SUBTYPE_IN_PERSON_VISIT',
    label: 'In Person Visit',
  },
];

export type ActionHash = { [key in ActionType]: string };

export const ACTION_HASH: ActionHash = {
  TEMPLATE: 'Email Action',
  CALL: 'Call Action',
  STEP_SUBTYPE_LINKEDIN_INMAIL: 'LinkedIn InMail',
  STEP_SUBTYPE_LINKEDIN_CONNECT: 'LinkedIn Connect',
  STEP_SUBTYPE_SENDOSO: 'Sendoso Action',
  STEP_SUBTYPE_SMS: 'SMS Message',
  STEP_SUBTYPE_IN_PERSON_VISIT: 'In Person Visit',
  STEP_SUBTYPE_DIRECT_MAIL: 'Direct Mail',
  OTHER: 'Other Action',
  GENERAL: 'General Task',
  WORKSPACE_ACCOUNT_TASK: 'Workspace Task',
  UPDATE_SALESFORCE: 'Update Salesforce Values',
};

export const emptyAction: FullAction = {
  id: '',
  type: 'GENERAL',
  accountId: null,
  accountName: null,
  assignee: null,
  bccRecipients: null,
  body: null,
  ccRecipients: null,
  creatorId: null,
  creator: null,
  crmResults: null,
  crmInstanceUrl: null,
  crmObjectId: null,
  crmTaskType: null,
  description: null,
  dueAt: null,
  dynamicData: {
    loggingForm: { title: null, subtitle: null, fields: [] },
  },
  executeAt: null,
  flow: {
    id: null,
    name: null,
    settings: {
      contact: {
        emailFieldName: null,
        phoneFieldName: null,
      },
      lead: {
        emailFieldName: null,
        phoneFieldName: null,
      },
    },
  },
  fromEmail: null,
  loggingTo: {},
  notes: null,
  personId: null,
  personStepId: null,
  playNodeRunId: null,
  priority: null,
  results: null,
  subject: null,
  suggestedAction: null,
  summary: null,
  template: null,
  toRecipients: null,
  usedAdvanceSS: false,
  who: null,
  workspaceAccountTaskId: null,
  workspaceSObjectTaskId: null,
};

export const INCOMING_MESSAGE_TYPES = {
  LOAD_ACTION_DATA: 'INCOMING_ACTION_COMPOSE/LOAD_ACTION_DATA',
  UPDATE_ACTION_RECORD: 'INCOMING_ACTION_COMPOSE/UPDATE_ACTION_RECORD',
};

export const OUTGOING_MESSAGE_TYPES = {
  SWITCH_ACTION_CANCELED_BY_USER:
    'OUTGOING_ACTION_COMPOSE/SWITCH_ACTION_CANCELED_BY_USER',
};

export type FieldsPivotItemValues = 'standard-fields' | 'custom-fields';
export const fieldsPivotItems = [
  { label: 'Standard fields', value: 'standard-fields' },
  { label: 'Custom fields', value: 'custom-fields' },
];

export const MARK_AS_COMPLETE_TOOLTIP_TEXT =
  'Marks action as complete without logging to Salesforce';

export const SAVE_AND_NEXT_TOOLTIP_TEXT =
  'Save changes and move to the next Action';

export const SAVE_AND_CLOSE_TOOLTIP_TEXT = 'Save changes and close Action';

export const SMS_PHONE_NUMBER_MISSING_MESSAGE =
  'You do not have a phone number assigned to you, please configure in Dialer Settings';
export const SMS_NOT_ENABLED_MESSAGE =
  'You do not have an SMS license. Please contact your admin to have one assigned';

export const EMAIL_GENERATION_TYPES = [
  {
    value: 'COLD_EMAIL',
    label: 'Cold email',
    description:
      'Generate a first touch email that includes insights from the contact and account.',
  },
  {
    value: 'FOLLOWUP_EMAIL',
    label: 'Meeting follow-up',
    description:
      'Generate a follow-up email that includes insights from the selected Copilot meeting.',
  },
];
export const EMAIL_WRITING_STYLES = [
  {
    value: 'challenger_sale',
    key: 'challenger_sale',
    text: 'Challenger Sale',
    secondaryText: ['For a conversational and insightful approach'],
    hideTooltip: true,
  },
  {
    value: 'clever_poet',
    key: 'clever_poet',
    text: 'Clever Poet',
    secondaryText: ['To craft content with a creative, poetic twist'],
    hideTooltip: true,
  },
  {
    value: 'cxo_pitch',
    key: 'cxo_pitch',
    text: 'Cxo Pitch',
    secondaryText: ['For concise, executive-level pitches'],
    hideTooltip: true,
  },
  {
    value: 'data_driven',
    key: 'data_driven',
    text: 'Data Driven',
    secondaryText: ['For content backed by data and statistics'],
    hideTooltip: true,
  },
];
export const FOLLOW_UP_TONE_TYPES = [
  { value: 'CASUAL', label: 'Casual' },
  { value: 'NEUTRAL', label: 'Neutral' },
  { value: 'FORMAL', label: 'Formal' },
];

export const LOAD_ACTION_COMPOSE = 'ACTION_COMPOSE/LOAD_ACTION_COMPOSE';
export const UPDATE_ACTION_COMPOSE = 'ACTION_COMPOSE/UPDATE_ACTION_COMPOSE';
export const SET_ACTION_COMPOSE_STATE =
  'ACTION_COMPOSE/SET_ACTION_COMPOSE_STATE';

export const PICKLIST_TYPE = 'Task';

export enum ActionComposeEnum {
  'MAXIMIZED',
  'MINIMIZED',
  'CLOSED',
}

export const ACTION_COMPOSE_INJECTION = 'action-compose-injection';

export const COMPLETE_IN_DIALER = 'Please complete call in dialer';
export const DISABLED_CALL_TOOLTIP =
  'Please select a call recipient with a phone number';

export const CONTENT_TYPES = [
  {
    value: 'full_emails',
    key: 'full_emails',
    text: 'Full Email',
    secondaryText: ['Generates a full personalized email'],
    metaData: {
      description:
        'Generates a full personalized email, from the greeting to call-to-action',
    },
    hideTooltip: true,
  },
  {
    value: 'openers',
    key: 'openers',
    text: 'Email Introduction',
    secondaryText: ['Generates a personalized email introduction'],
    metaData: {
      description:
        'Generates a personalized email introduction, containing the greeting and hook',
    },
    hideTooltip: true,
  },
];

export const CALL_PROCESSED = 'POST_PROCESSING_DONE';
export const RECIPIENT_NOT_FOUND =
  'Recipient not found in Salesforce, use Omnibar to create a new record';
export const RECIPIENT_NOT_FOUND_MODAL_TEXT =
  'We couldn’t find a matching Salesforce record for one or more of the recipients you’ve entered. As a result, sending this email will not be logged for those recipients not in Salesforce. Consider creating a Salesforce record for the indicated recipients before sending.';

export type ActionState = 'NEW' | 'EXISTING' | 'ERROR';

export type ActionStateHash = { [key in ActionState]: string };

export const ACTION_STATE: ActionStateHash = {
  NEW: 'New',
  EXISTING: 'Existing',
  ERROR: 'Error',
};

export type ActionEvent =
  | 'OPENED'
  | 'SAVED'
  | 'LOGGED'
  | 'MARKED_AS_COMPLETE'
  | 'CLOSED'
  | 'GENERATE_COLD_EMAIL'
  | 'GENERATE_FOLLOW_UP'
  | 'SELECT_COLD_EMAIL'
  | 'SELECT_FOLLOW_UP';

export type ActionEventHash = { [key in ActionEvent]: string };

export const ACTION_EVENT: ActionEventHash = {
  OPENED: 'Opened',
  SAVED: 'Saved',
  LOGGED: 'Logged',
  MARKED_AS_COMPLETE: 'Marked as Complete',
  CLOSED: 'Closed',
  SELECT_COLD_EMAIL: 'GenAI Email: Cold Email Intent Selected',
  SELECT_FOLLOW_UP: 'GenAI Email: Meeting Follow Up Intent Selected',
  GENERATE_COLD_EMAIL: 'GenAI Email: Generate Cold Email',
  GENERATE_FOLLOW_UP: 'GenAI Email: Generate Smart Follow Up',
};

export type ActionSourceHash = { [key in SourceType]: string };

export const ACTION_SOURCE: ActionSourceHash = {
  FLOW: 'Flow',
  WORKSPACE: 'Space',
  ONE_OFF: 'One-Off',
  PLAY: 'Play',
  SUGGESTED_ACTION: 'Suggested Action',
};

export enum ACTION_ORIGIN {
  WEBAPP = 'Webapp',
  GMAIL = 'Gmail',
  SALESFORCE = 'Salesforce',
  ADDIN = 'Add-In',
  FLOWS = 'Flows',
  DESKTOP = 'Desktop-add-in',
}

export type ActionTypeHash = { [key in ActionType]: string };

export const ACTION_TYPE: ActionTypeHash = {
  TEMPLATE: 'Email',
  CALL: 'Call',
  OTHER: 'Other',
  GENERAL: 'Task',
  STEP_SUBTYPE_SMS: 'Sms',
  STEP_SUBTYPE_DIRECT_MAIL: 'Direct Mail',
  STEP_SUBTYPE_IN_PERSON_VISIT: 'In Person Visit',
  STEP_SUBTYPE_LINKEDIN_CONNECT: 'LinkedIn Connect',
  STEP_SUBTYPE_LINKEDIN_INMAIL: 'LinkedIn InMail',
  STEP_SUBTYPE_SENDOSO: 'Sendoso',
  UPDATE_SALESFORCE: 'Update Salesforce',
  WORKSPACE_ACCOUNT_TASK: 'Workspace',
};

export enum ACTION_VERSION {
  V1 = 'V1',
  V2 = 'V2',
}

export const NO_GROOVE_NUMBER = 'noGrooveNumberError';
export const INVALID_BRIDGE_DIAL = 'invalidBridgeDial';
export const CALL_ERROR_MESSAGE_ID = 'CallErrorMessageId';

export enum SALESFORCE_RECORD_TEXT {
  ACCOUNT = 'Account',
  EMAIL = 'Email',
  CAMPAIGN = 'Campaign',
  CASE = 'Case',
  CLOSE_DATE = 'Close Date',
  COMPANY = 'Company',
  CONTACT = 'Contact',
  DESCRIPTION = 'Description',
  LEAD = 'Lead',
  NO_NAME = '<No Name>',
  ORGANIZATION = 'Organization',
  OPPORTUNITY = 'Opportunity',
  STAGE = 'Stage',
  TITLE = 'Title',
}
export const IMPORT_RULES_FF = 'apply-import-rules-to-action-execution';
