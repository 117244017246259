import axios from 'axios';
import isJSON from 'is-json';
import { camelizeKeys } from 'humps';
import { stringify } from 'qs';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

const client = axios.create({
  baseURL: grooveEngineRootUrl || '',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: params => stringify(params, { arrayFormat: 'brackets' }),
  transformResponse: data => {
    if (isJSON(data)) {
      return camelizeKeys(JSON.parse(data));
    }
    return data;
  },
  withCredentials: true,
});

export default client;
