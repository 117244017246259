import {
  salesforceMeta,
  ApiSalesforceMeta,
  SalesforceMeta,
} from '@groove/api/gateway/v1/salesforce';
import {
  useQuery,
  UseQueryResult,
  UseQueryOptions,
  QueryClient,
} from 'react-query';

import { syncAllWhoData } from '../utils/syncWhoData';
import useStore from '../store/useStore';
import { setUpLoggingTo } from '../utils/setupLoggingTo';

type UseGrooveMetaOptions = Omit<
  UseQueryOptions<ApiSalesforceMeta, unknown, SalesforceMeta, 'salesforceMeta'>,
  'queryKey' | 'queryFn'
>;

type FetchGrooveMetaParams = {
  client: QueryClient;
  options?: UseGrooveMetaOptions;
};

const useGrooveMeta = (
  options?: UseGrooveMetaOptions,
): UseQueryResult<SalesforceMeta, unknown> => {
  return useQuery('salesforceMeta', salesforceMeta, {
    onSuccess: async data => {
      await syncAllWhoData(useStore.getState().action, data);
      if (useStore.getState().action.who)
        setUpLoggingTo(
          useStore.getState().action.who,
          useStore.getState().action.loggingTo.who,
        );
    },
    onError: error => {
      console.error(error);
    },
    select(data) {
      return Object.fromEntries(
        Object.entries(data.data).map(([k, v]) => [k.toLowerCase(), v]),
      );
    },
    cacheTime: 10 * 60 * 1000, // 10 minutes because you don't expect salesforce meta data to change often since that is only ran once an hour for our db anyways
    staleTime: 10 * 60 * 1000,
    ...options,
  });
};

export const fetchGrooveMeta = async ({
  client,
  options,
}: FetchGrooveMetaParams): Promise<SalesforceMeta> => {
  const grooveMetaRaw = await client.fetchQuery(
    'salesforceMeta',
    salesforceMeta,
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
    },
  );
  return Object.fromEntries(
    Object.entries(grooveMetaRaw.data).map(([k, v]) => [k.toLowerCase(), v]),
  );
};

export default useGrooveMeta;
