import React, { FC } from 'react';

interface InlineUploadedFileProps {
  uploadLocation: string;
  fileName: string;
  id?: string;
}
// This component is injected directly into the
// editor and as such, needs to be completely inline-styled
const InlineUploadedFile: FC<InlineUploadedFileProps> = ({
  uploadLocation,
  fileName,
  id,
}) => {
  return (
    <>
      <a
        // This classname is used in the extension and in GE for attachment tracking
        id={id}
        className="fr-file"
        href={uploadLocation}
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {fileName}
      </a>
      &nbsp;
    </>
  );
};

InlineUploadedFile.defaultProps = {
  id: '',
};

export default InlineUploadedFile;
