import { List, Record, Map } from 'immutable';
import Person from 'Modules/Actions/records/Person';
import FlowMetadata from 'Modules/Actions/records/FlowMetadata';
import {
  ACTION_PERSON_FLOW_ID_KEY_PATH,
  ACTION_CHILD_ID_KEY_PATH,
  ACTION_FLOW_ID_KEY_PATH,
} from 'Modules/ActionCompose/constants/graphql';
import { getTemplateName } from 'Modules/ActionCompose/utils';
import sharedDefaults from './defaults';
import flowDefaults from './flowDefaults';
import { hasUnmergedField as checkHasUnmergedField } from 'Modules/WorkStep/utils';
import { isLead } from 'Utils/salesforce-object-identifier';
import { DEFAULT_ADDRESS_FIELD_NAMES } from 'Modules/FlowsShow/constants/index.ts';

const defaults = {
  ...sharedDefaults,
  ...flowDefaults,
  currentValues: new Map({
    sfdcType: null,
    body: null,
    subject: null,
  }),
};

export default class CallAction extends Record(defaults) {
  static fromGraphQL(action) {
    const executionContext = action.get('executionContext');
    const sfdcType = executionContext?.get('sfdcType');
    const phone = executionContext?.get('phone');
    const templateName = getTemplateName(action, executionContext);

    const mergedTemplate = executionContext?.get('mergedTemplate');
    const body = mergedTemplate?.get('body') || executionContext?.get('body');
    const subject =
      mergedTemplate?.get('subject') || executionContext?.get('subject');
    const phones = (executionContext?.get('phones') || new List())
      .fromEntrySeq()
      .toMap();
    const who = action.has('who')
      ? Person.from(action.get('who'), null, phones)
      : null;
    const isWhoLead = isLead(who?.get('sfdcId'));
    const selectedFieldKey = isWhoLead
      ? DEFAULT_ADDRESS_FIELD_NAMES.lead.phone
      : DEFAULT_ADDRESS_FIELD_NAMES.contact.phone;
    const selectedRecipientPhoneField =
      action.getIn(['child', 'flow', selectedFieldKey]) || 'phone';

    return new CallAction({
      id: action.get('id'),
      type: action.get('type'),
      source: action.get('source'),
      summary: action.get('summary'),
      priority: action.get('priority'),
      failedData: action.get('failedData'),
      error: action.get('error'),
      sourceMetadata: FlowMetadata.from(
        action.get('sourceMetadata')?.toJS() || {}
      ),
      personStepId: action.getIn(ACTION_CHILD_ID_KEY_PATH),
      personFlowId: action.getIn(ACTION_PERSON_FLOW_ID_KEY_PATH),
      flowId: action.getIn(ACTION_FLOW_ID_KEY_PATH),
      stepId: action.getIn(ACTION_FLOW_ID_KEY_PATH),
      ...(action.has('who') && { recipient: who }),
      templateName,
      sfdcType,
      body,
      subject,
      phone,
      phones,
      currentValues: new Map({
        sfdcType,
        body,
        phone,
        subject,
        hasUnmergedBody: body && checkHasUnmergedField(body),
        hasUnmergedSubject: subject && checkHasUnmergedField(subject),
        executionType: null,
        activityResultType: null,
        activityResultId: null,
        selectedRecipientPhoneField,
      }),
    });
  }
}
