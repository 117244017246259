import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { Typography, MenuItem, Select } from '@material-ui/core';
import { FILTER_TYPES, SOURCES } from 'Modules/Emails/constants';
import {
  setEventFilter,
  setSource,
  setSearch,
  setIsInSearchMode,
} from 'Modules/Emails/actions';
import {
  getEventFilter,
  getSource,
  getSearch,
  getIsInSearchMode,
} from 'Modules/Emails/selectors';
import { inLoadingState } from 'Modules/App/selectors';

const styles = theme => ({
  root: {
    height: 56,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchInput: {
    flexGrow: 1,
  },
});

const FILTER_TYPE_LABELS = Object.freeze({
  [FILTER_TYPES.OPENED]: 'Opened',
  [FILTER_TYPES.CLICKED]: 'Clicked',
  [FILTER_TYPES.REPLIED]: 'Replied',
  [FILTER_TYPES.UNOPENED]: 'Unopened',
  [FILTER_TYPES.ALL]: 'All',
});

const SOURCE_LABELS = Object.freeze({
  [SOURCES.FLOW]: 'Flow',
  [SOURCES.EMAIL]: 'Email',
  [SOURCES.ALL]: 'All',
});

@withStyles(styles)
@connect(
  state => ({
    eventFilter: getEventFilter(state),
    source: getSource(state),
    search: getSearch(state),
    isInSearchMode: getIsInSearchMode(state),
    inLoadingState: inLoadingState(state),
  }),
  {
    onEventFilterChange: setEventFilter,
    onSourceChange: setSource,
    onSearchChange: setSearch,
    onSearchActiveChange: setIsInSearchMode,
  }
)
export default class Header extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      searchInput: PropTypes.string.isRequired,
      root: PropTypes.string.isRequired,
    }).isRequired,
    eventFilter: PropTypes.oneOf(Object.keys(FILTER_TYPES)).isRequired,
    source: PropTypes.oneOf(Object.keys(SOURCES)).isRequired,
    isInSearchMode: PropTypes.bool.isRequired,
    inLoadingState: PropTypes.bool.isRequired,
    search: PropTypes.string.isRequired,
    onEventFilterChange: PropTypes.func.isRequired,
    onSourceChange: PropTypes.func.isRequired,
    onSearchActiveChange: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired,
  };

  handleEventFilterChange = e => this.props.onEventFilterChange(e.target.value);
  handleSourceChange = e => this.props.onSourceChange(e.target.value);
  handleSearchClick = () => this.props.onSearchActiveChange(true);
  handleCloseClick = () => this.props.onSearchActiveChange(false);
  handleSearchChange = e => this.props.onSearchChange(e.target.value);

  render() {
    const {
      classes,
      eventFilter,
      source,
      search,
      isInSearchMode,
      inLoadingState,
    } = this.props;

    return (
      <div className={classes.root}>
        {isInSearchMode && (
          <Fragment>
            <Input
              autoFocus
              placeholder="Search"
              className={classes.searchInput}
              onChange={this.handleSearchChange}
              value={search}
            />
            <IconButton onClick={this.handleCloseClick}>
              <CloseIcon />
            </IconButton>
          </Fragment>
        )}
        {!isInSearchMode && (
          <Fragment>
            <Typography variant="subtitle1">Email Tracking Activity</Typography>
            <Select
              disabled={inLoadingState}
              value={eventFilter}
              onChange={this.handleEventFilterChange}
            >
              {Object.keys(FILTER_TYPES).map(value => (
                <MenuItem key={value} value={value}>
                  {FILTER_TYPE_LABELS[value]}
                </MenuItem>
              ))}
            </Select>
            <Select
              disabled={inLoadingState}
              value={source}
              onChange={this.handleSourceChange}
            >
              {Object.keys(SOURCES).map(value => (
                <MenuItem key={value} value={value}>
                  {SOURCE_LABELS[value]}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              disabled={inLoadingState}
              onClick={this.handleSearchClick}
            >
              <SearchIcon />
            </IconButton>
          </Fragment>
        )}
      </div>
    );
  }
}
