import {
  DialogFooter,
  Link,
  Shimmer,
  ShimmerElementType,
} from '@fluentui/react';
import { Template, templateUsage } from '@groove/api/gateway/v1/template';
import Button from '@groove/ui/Components/Button';
import Text from '@groove/ui/Components/Text';
import { FC } from 'react';
import { useQuery } from 'react-query';

type TemplateDeleteDialogProps = {
  template: Template | undefined;
  onDeleteClick: () => void;
  onCancelClick: () => void;
};

const TemplateDeleteDialogLoader: FC = () => {
  return (
    <div aria-label="template-delete-dialog-loader" className="w-[350px]">
      <Shimmer
        className="mb-2"
        shimmerElements={[
          { type: ShimmerElementType.line, width: '50%', height: 20 },
          { type: ShimmerElementType.gap, width: '50%' },
        ]}
      />
      {Array.from({ length: 2 }, (_v, n) => (
        <Shimmer
          key={n}
          className="my-2"
          shimmerElements={[
            { type: ShimmerElementType.line, width: '100%', height: 20 },
          ]}
        />
      ))}
      {Array.from({ length: 2 }, (_v, flowNumber) => (
        <Shimmer
          key={flowNumber}
          className="pl-2"
          shimmerElements={[
            { type: ShimmerElementType.line, width: '25%', height: 10 },
            { type: ShimmerElementType.gap, width: '75%' },
          ]}
        />
      ))}
    </div>
  );
};

const TemplateDeleteDialog: FC<TemplateDeleteDialogProps> = ({
  template,
  onDeleteClick,
  onCancelClick,
}) => {
  const { data: templateUsageData, isFetching: isFetchingTemplateUsage } =
    useQuery(['templateUsage', template?.id], () =>
      templateUsage(template?.id || 0),
    );

  const numberOfFlowsWithTemplate = templateUsageData?.data?.length || 0;

  if (isFetchingTemplateUsage) {
    return <TemplateDeleteDialogLoader />;
  }

  if (numberOfFlowsWithTemplate > 0) {
    return (
      <>
        <Text variant="header" weight="semibold">
          Template cannot be deleted
        </Text>
        <Text variant="body" className="mt-4 pt-2">
          The template <span className="font-semibold">{template?.name}</span>{' '}
          cannot be deleted because it is used in:
        </Text>
        <ul className="pl-6 m-1">
          {templateUsageData?.data?.slice(0, 3).map(flow => (
            <li key={flow.id}>
              <Link
                href={`${process.env.NX_FEBES_URL}/flows/${flow.id}`}
                target="_blank"
              >
                <Text variant="body" className="text-clari-blue/600">
                  {flow.name}
                </Text>
              </Link>
            </li>
          ))}
        </ul>
        {numberOfFlowsWithTemplate > 3 && (
          <Text variant="body" className="pl-6">
            ... and {numberOfFlowsWithTemplate - 3} more flows.
          </Text>
        )}
        <DialogFooter>
          <Button
            variant="primary"
            aria-label="template-delete-dialog-close"
            onClick={onCancelClick}
          >
            Close
          </Button>
        </DialogFooter>
      </>
    );
  }

  return (
    <>
      <Text variant="header" weight="semibold">
        Delete template?
      </Text>
      <Text variant="body" className="mt-4 py-2">
        Deleting the template{' '}
        <span className="font-semibold">{template?.name}</span> is permanent and
        you will no longer be able to use it.
      </Text>
      <DialogFooter>
        <Button
          variant="tertiary"
          aria-label="template-delete-dialog-cancel"
          onClick={() => onCancelClick()}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          aria-label="template-delete-dialog-delete"
          onClick={onDeleteClick}
        >
          Delete
        </Button>
      </DialogFooter>
    </>
  );
};

export default TemplateDeleteDialog;
