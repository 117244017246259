import React, { useState, FC } from 'react';
import { Menu } from '@material-ui/core';
import HelpIconButton from './HelpIconButton';
import { HelpMenuOptions } from './HelpMenuOptions';

export const HelpDisplayAndMenu: FC = () => {
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const open = Boolean(helpMenuAnchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setHelpMenuAnchorEl(event.currentTarget);
  };

  const handleMenuCloseClick = () => {
    setHelpMenuAnchorEl(null);
  };

  return (
    <div>
      <HelpIconButton open={open} onClick={handleMenuClick} />
      <Menu
        open={open}
        anchorEl={helpMenuAnchorEl}
        onClose={handleMenuCloseClick}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <HelpMenuOptions closeHelpMenu={handleMenuCloseClick} />
      </Menu>
    </div>
  );
};
