import { SagaIterator } from 'redux-saga';
import { select, put, call } from 'redux-saga/effects';
import { actions } from '@groove-labs/groove-ui';

import { searchByReport as searchByAccountsReport } from 'GrooveHTTPClient/salesforceAccountSearch';
import { searchByReport as searchByOpportunitiesReport } from 'GrooveHTTPClient/salesforceOpportunitiesSearch';
import {
  actionTypes,
  clearSelectedObjects,
} from 'Modules/Spaces/import/actions';
import { getWorkspaceType } from 'Modules/Spaces/show/selectors';
import { SPACES_IMPORT_DIALOG_PAGE_UI_KEY_PATH } from 'Modules/Spaces/import/constants';
import { WORKSPACE_TYPES } from 'Modules/Spaces/shared/constants';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { logErrorToSentry } from 'Modules/Shared/sagas/errors';

const { setProperty } = actions.ui;

function* searchSObjects({ sfdcReportId }: SearchByReportType): SagaIterator {
  const workspaceType = yield select(getWorkspaceType);

  if (workspaceType === WORKSPACE_TYPES.ACCOUNT) {
    const response = yield call(searchByAccountsReport, { sfdcReportId });
    return response.data;
  }
  const response = yield call(searchByOpportunitiesReport, { sfdcReportId });
  return response.data;
}

type SearchByReportType = {
  sfdcReportId: string;
};

type PayloadType = {
  payload: SearchByReportType;
};

function* handleSearchByReport({ payload }: PayloadType): SagaIterator {
  try {
    yield put(clearSelectedObjects());
    yield put({ type: actionTypes.SEARCH_BY_REPORT.PROGRESS });
    const workspaceType = yield select(getWorkspaceType);

    const { sfdcReportId } = payload;
    const results = yield call(searchSObjects, { sfdcReportId });
    yield put({
      type: actionTypes.SEARCH_BY_REPORT.SUCCESS,
      payload: {
        results,
        workspaceType,
      },
    });
    yield put(
      setProperty({
        uiKeyPath: SPACES_IMPORT_DIALOG_PAGE_UI_KEY_PATH,
        data: 1,
      })
    );
  } catch (e) {
    logErrorToSentry(e);
    yield put(
      pushSnackbarMessage({
        message:
          'There was an issue retrieving your report results. Please try again.',
      })
    );
    yield put({ type: actionTypes.SEARCH_BY_REPORT.FAILURE });
  }
}

export default handleSearchByReport;
