import { ITheme } from '@fluentui/react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { Factor as FactorType } from 'Modules/ActionsRewrite/components/customCells/SmartPriority/HoverCard/useGetFactors';
import Factor from 'Modules/ActionsRewrite/components/customCells/SmartPriority/HoverCard/displayTopFactors/factor';
import { sharedFactorRowSpacingStyle } from 'Modules/ActionsRewrite/components/customCells/SmartPriority/constants';

const useStyles = makeStyles((theme: ITheme) => ({
  whiteText: {
    color: theme.palette.white,
    fontSize: 12,
    ...sharedFactorRowSpacingStyle,
  },
  factorsList: {
    width: '100%',
    padding: 0,
    margin: 0,
  },
}));

type DisplayTopFactorsParams = {
  factors: FactorType[];
  isLoading?: boolean;
};

const DisplayTopFactors = ({ factors, isLoading }: DisplayTopFactorsParams) => {
  const styles = useStyles();
  if (isLoading) return null;
  if ((factors || []).length === 0) return null;

  return (
    <Box data-testid="smartPriorityScores-topFactors">
      <Typography className={styles.whiteText} variant="h6">
        Top Factors
      </Typography>
      <Divider />
      <Box component="ul" className={styles.factorsList}>
        {factors.map(factor => (
          <React.Fragment key={factor.text}>
            <Factor factor={factor} />
            <Divider />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default DisplayTopFactors;
