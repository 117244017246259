import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SyncedTextField from 'Modules/Spaces/show/containers/SyncedTextField';
import {
  getCustomColumnName,
  getLastUpdatedByCustomColumnId,
} from 'Modules/Spaces/show/selectors';
import {
  columnHeaderChange,
  setEditingUserForColumn,
} from 'Modules/Spaces/show/actions/firestore';
import { UNTITLED_COLUMN_LABEL } from 'Modules/Spaces/show/constants';

const styles = {
  root: {
    display: 'flex',
    paddingRight: 10,
    flex: 1,
  },
};

@withStyles(styles)
@connect(
  (state, props) => {
    const { columnId } = props;

    return {
      columnName: getCustomColumnName(state, columnId),
      editingUserId: getLastUpdatedByCustomColumnId(state, columnId),
    };
  },
  {
    columnHeaderChange,
    setEditingUserForColumn,
  }
)
export default class CustomHeaderInput extends Component {
  static propTypes = {
    // Passed from decorators
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    columnName: PropTypes.string.isRequired,
    editingUserId: PropTypes.number,
    columnHeaderChange: PropTypes.func.isRequired,
    setEditingUserForColumn: PropTypes.func.isRequired,

    // Passed from parent
    columnId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    editingUserId: null,
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.columnName !== this.props.columnName ||
      nextProps.editingUserId !== this.props.editingUserId
    );
  }

  onInputChange = ({ value }) =>
    this.props.columnHeaderChange({ value, id: this.props.columnId });

  setEditingUser = ({ userId }) =>
    this.props.setEditingUserForColumn({
      userId,
      columnId: this.props.columnId,
    });

  render() {
    const { classes, columnName, editingUserId } = this.props;

    return (
      <div className={classes.root}>
        <SyncedTextField
          value={columnName}
          editingUserId={editingUserId}
          setEditingUser={this.setEditingUser}
          onChange={this.onInputChange}
          placeholder={UNTITLED_COLUMN_LABEL}
        />
      </div>
    );
  }
}
