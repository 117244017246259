import React from 'react';
import PropTypes from 'prop-types';
import ClariTheme from 'Utils/theme/ClariTheme';

const Pin = ({ width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <path d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
  </svg>
);

Pin.displayName = 'Pin';
Pin.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Pin.defaultProps = {
  width: 24,
  height: 24,
  fill: ClariTheme.palette.text.neutralColor,
};

export default Pin;
