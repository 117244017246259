import { Record } from 'immutable';
import { CUSTOM_COLUMN_TYPES } from 'Modules/Spaces/show/constants';

const schema = {
  id: null,
  name: '',
  editingUserId: null,
};

// TODO: @darthneel
// Consider settings up a base class for custom columns
export default class TextFieldColumn extends Record(schema) {
  static from(attributes = {}) {
    return new TextFieldColumn(attributes);
  }

  formatForFirestore() {
    return {
      type: CUSTOM_COLUMN_TYPES.TEXT_FIELD,
      name: this.name,
      editingUserId: null,
    };
  }
}
