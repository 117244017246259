import { FC, useEffect, useState } from 'react';
import Text from '@groove/ui/Components/Text';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { CloseIcon, RightChevron } from '@groove/ui/Components/BoogieIcon';
import TemplatePickerPane from '@groove/templates/newTemplateComponents/TemplatePickerPane';
import useTemplatesStore from '@groove/templates/store/useStore';
import {
  Template,
  TemplateType,
  templateGet,
} from '@groove/api/gateway/v1/template';
import { IconButton, Modal } from '@fluentui/react';
import useRecentTemplatesSet from '@groove/api/hooks/useRecentTemplatesSet';
import TemplatesEditorStandalone from '@groove/templates/TemplatesEditorStandalone';
import { FullAction } from '@groove/api/gateway/v1/actionCompose';

import useStore from '../store/useStore';
import ExtendedTaskPane from '../component/ExtendedTaskPane';
import SchedulerLite from '../component/SchedulerLite';
import AddSpecificTimesModal from '../component/AddSpecificTimesModal';
import AllTemplatesModal from '../component/AllTemplatesModal';
import { TASK_PANE_LOCAL_KEY } from '../constants';

import TaskPane from './TaskPane';
import EmailGenerationPane from './EmailGenerationPane';

const modalLayerProps = {
  styles: {
    content: 'groove',
  },
};

const TaskPaneContainer: FC = () => {
  const [isAllTemplatesOpen, setIsAllTemplatesOpen] = useState(false);

  const isTaskPaneOpenRaw = useStore(store => store.otherValues.isTaskPaneOpen);
  const taskPaneInitialClose = useStore(
    store => store.otherValues.taskPaneInitialClose,
  );
  const isExtendedTaskPaneOpen = useStore(
    store => store.otherValues.isExtendedTaskPaneOpen,
  );
  const taskPaneType = useStore(store => store.otherValues.taskPaneType);
  const setOtherValues = useStore(store => store.setOtherValues);
  const type = useStore(store => store.action.type);
  const subject = useStore(store => store.action.subject);
  const body = useStore(store => store.action.body);
  const templateId = useStore(store => store.action.templateId);
  const selectionStart = useStore(store => store.otherValues.selectionStart);
  const selectionEnd = useStore(store => store.otherValues.selectionEnd);
  const isTemplateEditorOpen = useStore(
    store => store.otherValues.isTemplateEditorOpen,
  );

  const isTaskPaneOpen = isTaskPaneOpenRaw && !taskPaneInitialClose;

  const { mutate: setRecentTemplate } = useRecentTemplatesSet();

  useEffect(() => {
    if (taskPaneType === 'TEMPLATE')
      useTemplatesStore.getState().setSearchTerm('');
  }, [taskPaneType]);

  const togglePane = (): void => {
    window.localStorage.setItem(
      TASK_PANE_LOCAL_KEY,
      (!isTaskPaneOpen).toString(),
    );
    setOtherValues({
      isTaskPaneOpen: !isTaskPaneOpen,
      taskPaneInitialClose: false,
    });
  };

  const onTemplateClose = (): void => {
    useStore
      .getState()
      .setOtherValues({ taskPaneType: 'TASK', isExtendedTaskPaneOpen: false });
    useTemplatesStore.getState().setSelectedTemplate(undefined);
  };

  const onTemplateInsert = async (template: Template): Promise<void> => {
    const templateData = await templateGet(template.id);
    const partialAction: Partial<FullAction> = {
      body: templateData?.data?.body || '',
    };

    if (!subject) partialAction.subject = templateData?.data?.subject;
    if (!body && !subject)
      partialAction.crmTaskType = template?.template_type_value;
    // Add the template id to the action if there is not already a template id for insert
    if (!templateId) partialAction.templateId = template?.id;

    useStore
      .getState()
      .setOtherValues({ taskPaneType: 'TASK', wysiwygEdited: false });
    useStore.getState().insertAtCursorAndUpdateAction(partialAction);
    onTemplateClose();
    setRecentTemplate({ templateId: template?.id });
    useTemplatesStore.getState().setSelectedTemplate(undefined);
  };

  // The reason why we are not doing string interpolation is that Tailwind doesn't like it
  // It doesn't have an engine to dynamically generate the classes
  // TODO: Add more widths once we have the different sizes
  let taskPaneWidth = 'w-[16px]';

  if (isTaskPaneOpen) {
    taskPaneWidth = 'w-[260px]';
  }

  let templateType: TemplateType = 'other';
  if (type === 'TEMPLATE') templateType = 'email';
  else if (type === 'CALL') templateType = 'call';

  let action: 'EDIT' | 'CREATE' = 'EDIT';
  if (useTemplatesStore.getState().isNewTemplate) {
    action = 'CREATE';
  }

  useEffect(() => {
    const fluentLayer = document.getElementById('fluent-default-layer-host');
    if (fluentLayer) {
      if (isAllTemplatesOpen || isTemplateEditorOpen) {
        // this has a default zindex of 1000000, we need to lower it so popups in tinymce show.
        fluentLayer.style.zIndex = '1002';
      } else {
        fluentLayer.style.zIndex = '1000000';
      }
    }
  }, [isAllTemplatesOpen, isTemplateEditorOpen]);

  return (
    <div
      className={cx(
        `h-full bg-neutral/25 border-neutral/100 border-0 border-r border-solid flex flex-col`,
        taskPaneWidth,
        type === 'STEP_SUBTYPE_SENDOSO' ? 'absolute' : 'relative',
      )}
    >
      <AllTemplatesModal
        isOpen={isAllTemplatesOpen}
        onClose={() => {
          setIsAllTemplatesOpen(false);
          onTemplateClose();
        }}
      />
      <Modal
        isOpen={isTemplateEditorOpen}
        layerProps={modalLayerProps}
        onDismiss={() => {
          useStore.getState().setOtherValues({ isTemplateEditorOpen: false });
          useTemplatesStore.getState().setIsNewTemplate(false);
        }}
        forceFocusInsideTrap={false}
        closeButtonAriaLabel="Dismiss Templates Editor"
        styles={{ layer: { zIndex: 1000 } }}
      >
        <TemplatesEditorStandalone
          initialSubject={subject || ''}
          initialBody={body || ''}
          action={action}
          dismissEditor={() => {
            useStore.getState().setOtherValues({ isTemplateEditorOpen: false });
            useTemplatesStore.getState().setIsNewTemplate(false);
          }}
        />
      </Modal>
      <AddSpecificTimesModal />
      <button
        type="button"
        onClick={togglePane}
        className={cx(
          'rounded-[20px] h-[26px] w-[26px] border bg-white border-neutral/100 border-solid absolute right-[-13px] top-[14px] cursor-pointer hover:bg-gray6 active:bg-gray3_5 z-50',
          (taskPaneType !== 'TASK' || isExtendedTaskPaneOpen) && 'hidden',
        )}
      >
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: isTaskPaneOpen ? -180 : 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className="flex items-center justify-center"
        >
          <RightChevron className="h-[10px]" />
        </motion.div>
      </button>
      {isTaskPaneOpen && taskPaneType === 'TASK' && <TaskPane />}
      {isTaskPaneOpen && taskPaneType === 'TEMPLATE' && (
        <div className="flex flex-col h-full">
          <div className="flex flex-row pl-[12px] pr-[4px] pt-[6px] items-center">
            <Text className="flex-1 text-body-lg">Templates</Text>
            <IconButton
              className="text-white disabled:bg-primary disabled:text-neutral/600 hover:text-primary"
              ariaLabel="Close Template Side Pane"
              onClick={onTemplateClose}
            >
              <CloseIcon className="text-neutral/900 w-[20px]" />
            </IconButton>
          </div>
          <TemplatePickerPane
            customActionText={
              selectionEnd === selectionStart ? undefined : 'Replace'
            }
            className="flex-1"
            onDismiss={onTemplateClose}
            onAllTemplates={() => setIsAllTemplatesOpen(true)}
            onNewTemplate={() =>
              useStore.getState().setOtherValues({ isTemplateEditorOpen: true })
            }
            onInsert={onTemplateInsert}
            templateType={templateType}
            templateSubType={type}
          />
        </div>
      )}
      {isTaskPaneOpen && taskPaneType === 'SCHEDULER' && <SchedulerLite />}
      {isTaskPaneOpen && taskPaneType === 'EMAIL_GENERATION' && (
        <EmailGenerationPane />
      )}
      <ExtendedTaskPane />
    </div>
  );
};

export default TaskPaneContainer;
