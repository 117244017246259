import React, { FC } from 'react';
import { SalesforceMetaItem } from '@groove/api/services/v1/salesforce';

import Text from './Text';
import Checkbox from './Checkbox';
import Picklist from './Picklist';
import Lookup from './Lookup';

type AdditionalFieldFactoryProps = {
  additionalField: SalesforceMetaItem;
  value: string | number | boolean | null;
  onChange: (newValue: string | number | boolean | null) => void;
};

/**
 * A factory to automatically return the correct component based on the
 * type of additional field passed
 *
 * @param additionalField - the AdditionalField to be rendered
 * @param key - the key/index of the additionalField in the redux store
 */
const AdditionalFieldFactory: FC<AdditionalFieldFactoryProps> = ({
  additionalField,
  value,
  onChange,
}) => {
  switch (additionalField.type) {
    case 'boolean':
      return (
        <Checkbox
          additionalField={additionalField}
          value={value as boolean}
          onChange={onChange}
        />
      );
    case 'picklist':
      return (
        <Picklist
          additionalField={additionalField}
          value={value as string}
          onChange={onChange}
        />
      );
    case 'reference':
      return (
        <Lookup
          additionalField={additionalField}
          value={value as string}
          onChange={onChange}
        />
      );

    case 'textarea':
    case 'string':
      return (
        <Text
          additionalField={additionalField}
          value={value as string}
          multiline={additionalField.type === 'textarea'}
          onChange={onChange}
        />
      );
    default:
      return (
        <Text
          additionalField={additionalField}
          value={value as string}
          onChange={onChange}
        />
      );
  }
};

export default AdditionalFieldFactory;
