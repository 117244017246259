import { camelizeKeys, decamelizeKeys } from 'humps';

import getClient from './getClient';

export type sfdcTypes = 'Contact' | 'Account' | 'Lead' | 'Opportunity';

export type SearchPerson = {
  company: string;
  doNotCall: boolean;
  doNotEmail: boolean;
  doNotSms: boolean;
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  mobilePhone: string;
  name: string;
  phone: string;
  sfdcId: string;
  sfdcType: sfdcTypes;
  title: string;
};

export type FindPerson = {
  accountId: number;
  attachmentsDownloaded: number;
  bouncedReason: string;
  callsMade: number;
  companyMetaId: string;
  companyName: string;
  createdAt: string;
  effort: number;
  email: string;
  emailReplies: number;
  emailsOpened: number;
  emailsSent: number;
  engagement: number;
  engagementSyncedToSfdc: boolean;
  id: number;
  lastEngagedAt: string;
  lastEngagementType: string;
  lastEnrichedAt: string;
  lastTouchType: string;
  lastTouchedAt: string;
  linksClicked: number;
  mobilePhone: string;
  name: string;
  organizationId: number;
  personMetaId: number;
  phone: string;
  sfdcConvertedDate: string;
  sfdcData: {
    attributes: {
      type: sfdcTypes;
      url: string;
    };
    company: string;
    convertedAccountId: string;
    convertedContactId: string;
    convertedOpportunityId: string;
    daScoopComposerEmail2C: string;
    doNotCall: boolean;
    email: string;
    firstName: string;
    hasOptedOutOfEmail: boolean;
    hasOptedOutOfSms: boolean;
    id: string;
    isConverted: boolean;
    lastName: string;
    name: string;
    ownerId: string;
    phone: string;
    salutation: string;
    status: string;
    title: string;
  };
  sfdcId: string;
  sfdcLeadId: string;
  sfdcWhatId: string;
  source: string;
  suggestedActionType: string;
  updatedAt: string;
  userId: number;
};

export type ApiPeople = {
  data: SearchPerson[];
};

export type peopleSearchParam = {
  filters?: {
    field: string;
    value: string;
  }[];
  orderBy?: string;
  orderDir?: string;
  offset?: number;
  limit?: number;
  scope?: string;
  omitTotal?: boolean;
};

export const getPeople = (
  searchParams?: peopleSearchParam,
): Promise<ApiPeople> =>
  getClient()
    .post(`people/search`, {
      json: decamelizeKeys(searchParams || {}),
    })
    .json()
    .then(
      // eslint-disable-next-line @typescript-eslint/ban-types
      value => camelizeKeys(value as object[]) as unknown as ApiPeople,
    );

export const findOrCreatePerson = (sfdcId: string): Promise<FindPerson> =>
  getClient()
    .post(`people/find_or_create`, {
      json: { sfdc_id: sfdcId },
    })
    .json()
    .then(
      // eslint-disable-next-line @typescript-eslint/ban-types
      value => camelizeKeys(value as object) as unknown as FindPerson,
    );

export const findAPerson = (id: string): Promise<FindPerson> =>
  getClient()
    .get(`people/find_person`, { searchParams: { id } })
    .json()
    .then(
      // eslint-disable-next-line @typescript-eslint/ban-types
      value => camelizeKeys(value as object) as unknown as FindPerson,
    );
