import { FC, useRef, useState } from 'react';
import cx from 'classnames';
import Text from '@groove/ui/Components/Text';
import { useQuery } from 'react-query';
import { debounce } from 'lodash-es';
import MultiSelect, { MultiSelectRef } from '@groove/ui/Components/MultiSelect';
import { StandardDropDownItem } from '@groove/ui/Components/DropDownItem';
import { Spinner } from '@fluentui/react';
import { SearchUser, searchUsers } from '@groove/api/gateway/v1/users';
import { ApiUsers } from '@groove/api/v2/users';

import useStore from '../store/useStore';
import { UsersHash } from '../types';

const AssignTo: FC = () => {
  const whoRef = useRef<MultiSelectRef | null>(null);
  const personStepId = useStore(store => store.action.personStepId);
  const assignee = useStore(store => store.action.assignee);

  const [assignedToSearch, setAssignedToSearch] = useState('');
  const [assignedToHash, setAssignedToHash] = useState<UsersHash>({});
  const [assignedToItems, setAssignedToItems] = useState<
    StandardDropDownItem[]
  >([]);

  const processFetchedUserList = (data: ApiUsers): void => {
    const hash: UsersHash = {};
    const assignedToItems: StandardDropDownItem[] = (data?.data || []).map(
      user => {
        const { email, id, name } = user;
        hash[email] = { email, id, name };

        return {
          value: user.email,
          key: user.id,
          text: user.name || user.email,
          secondaryText: user.name && user.email ? [user.email] : undefined,
        };
      },
    );
    setAssignedToHash(hash);
    setAssignedToItems(assignedToItems);
  };

  const { isFetching: isAssignedToSearchFetching } = useQuery(
    ['assigned-to-user-list', assignedToSearch],
    () => searchUsers(assignedToSearch, { limit: 20 }),
    { onSuccess: processFetchedUserList },
  );

  const onAssignedToChange = (
    item: StandardDropDownItem | null,
    event?: React.MouseEvent | React.KeyboardEvent,
  ): void => {
    event?.stopPropagation();
    if (item) {
      useStore.getState().updateAction({
        assignee: assignedToHash[item.value] as SearchUser,
      });
    }
  };

  const getSelectedValues = (): StandardDropDownItem[] => {
    if (assignee) {
      return [
        {
          key: assignee.id,
          value: assignee.id,
          text: assignee.name,
          secondaryText: [assignee.email],
        },
      ];
    }
    return [];
  };

  const handleAssignedToSearch = (term: string): void => {
    setAssignedToSearch(term);
    if (term.length < 3) setAssignedToItems([]);
  };

  // Components to be rendered in Multiselect list header
  let whoHeader: JSX.Element | undefined;

  const spinner = (
    <div className="flex h-[32px] justify-center">
      <Spinner />
    </div>
  );

  const additionalListText = (
    emptyText: string | JSX.Element,
    smallBold = false,
  ): JSX.Element => (
    <div className="flex text-left w-full bg-white items-center overflow-hidden p-0 relative min-h-[32px]">
      <Text
        className={cx(
          'p-[8px] my-auto',
          smallBold && 'text-neutral/600 text-metadata-sm font-semibold',
          !smallBold && 'text-metadata text-neutral/400',
        )}
      >
        {emptyText}
      </Text>
    </div>
  );

  if (isAssignedToSearchFetching && assignedToSearch.length > 2) {
    whoHeader = spinner;
  } else if (assignedToItems.length < 1) {
    if (assignedToSearch.length < 3) {
      whoHeader = additionalListText('Please type to search');
    } else {
      whoHeader = additionalListText('No user found');
    }
  }

  return (
    <>
      <Text variant="body-sm" className="mb-[4px] mt-[4px] font-semibold">
        Assign to
      </Text>
      <MultiSelect
        className="w-full mb-[4px]"
        closeAfterSelect
        onSearchChange={debounce(handleAssignedToSearch, 200)}
        selectedValues={getSelectedValues()}
        placeholder="Name"
        items={isAssignedToSearchFetching ? [] : assignedToItems}
        onSelect={(item, event) => onAssignedToChange(item, event)}
        listHeader={whoHeader}
        onOpen={() => {
          useStore.getState().setOtherValues({ relatedToLastOpen: 'who' });
        }}
        ref={whoRef}
        disabled={!!personStepId}
      />
    </>
  );
};

export default AssignTo;
