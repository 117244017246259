import { useEffect } from 'react';
import { UseQueryResult, useQuery, useQueryClient } from 'react-query';
import { findOrCreatePerson, FindPerson } from '@groove/api/gateway/v1/people';
import {
  findOrCreateAccount,
  findOrCreateOpportunity,
} from '@groove/api/gateway/v1/relatedRecords';
import { grooveContactLeadSearch } from '@groove/api/visualforce/grooveSearch';
import {
  isAccount,
  isContact,
  isContactOrLead,
  isOpportunity,
} from '@groove/api/helpers/sfdcObjectPrefixes';
import { SearchUser, searchUsers } from '@groove/api/gateway/v1/users';
import {
  crmObject,
  FullAction,
  NEW_ACTION_ID,
} from '@groove/api/gateway/v1/actionCompose';

import useStore from '../store/useStore';
import { ACTION_TYPES } from '../constants';
import { getCurrentUserAsAssignee } from '../utils/loadActionHandler';
import { buildNewActionWhoFromPerson } from '../utils';

const useExternalMetadata = (): UseQueryResult<null[], unknown> => {
  const externalMetadata = useStore(store => store.externalMetadata);
  const updateOriginalAction = useStore(store => store.updateOriginalAction);
  const updateCurrentLoggingTo = useStore(
    store => store.updateCurrentLoggingTo,
  );
  const updateOriginalLoggingTo = useStore(
    store => store.updateOriginalLoggingTo,
  );
  const setExternalMetadataRecords = useStore(
    store => store.setExternalMetadataRecords,
  );
  const client = useQueryClient();

  const loggingTo = useStore(store => store.action.loggingTo);
  const updateAction = useStore(store => store.updateAction);
  const type = useStore(store => store.action.type);
  useEffect(() => {
    if (
      !externalMetadata.lookups.length ||
      (!loggingTo.who && !loggingTo.what)
    ) {
      return;
    }

    let title = `${
      ACTION_TYPES.find(actionType => actionType.value === type)?.label ||
      'Task'
    } with `;
    const loggingToWho = loggingTo?.who?.[0];
    const loggingToWhat = loggingTo?.what?.[0];
    if (loggingToWho) {
      title += loggingToWho.text;
    } else if (loggingToWhat) {
      title += loggingToWhat.text;
    }
    updateAction({ summary: title });
  }, [loggingTo, externalMetadata.lookups.length, type, updateAction]);

  return useQuery(
    [
      'externalMetadata',
      ...externalMetadata.lookups?.map(lookup => lookup.value),
      externalMetadata.description,
      externalMetadata.body,
    ],
    async () => {
      let foundAssignee = false;
      let assignee: SearchUser | null = null;
      let who: crmObject[] = [];
      let what: crmObject[] = [];
      const people: FindPerson[] = [];
      const results = await Promise.all(
        externalMetadata.lookups.map(async lookup => {
          if (lookup.type === 'id') {
            console.info('groove - looking up by id', lookup.value);
            if (isContactOrLead(lookup.value)) {
              const person = await findOrCreatePerson(lookup.value);
              if (!person) {
                console.info('groove - person not found, continuing');
                return null;
              }
              people.push(person);
              console.info('groove - found person', person);
              who.push({
                id: person.sfdcId,
                text: person.name,
                type: isContact(person.sfdcId) ? 'Contact' : 'Lead',
                email: person.email,
                phone: person.phone,
                autoCreated: true,
              } as crmObject);
            } else if (isAccount(lookup.value)) {
              const account = await findOrCreateAccount(lookup.value);
              if (!account) {
                console.info('groove - account not found, continuing');
                return null;
              }
              console.info('groove - found account', account);
              what.push({
                id: account.sfdc_id.toString(),
                text: account.name,
                type: 'Account',
                autoCreated: true,
              } as crmObject);
            } else if (isOpportunity(lookup.value)) {
              const opportunity = await findOrCreateOpportunity(lookup.value);
              if (!opportunity) {
                console.info('groove - opportunity not found, continuing');
                return null;
              }
              console.info('groove - found opportunity', opportunity);
              what.push({
                id: opportunity.sfdc_id.toString(),
                text: opportunity.name,
                secondaryText: opportunity.stage_name,
                secondaryTextLabel: 'Stage',
                tertiaryText: opportunity.close_date,
                tertiaryTextLabel: 'Close Date',
                type: 'Opportunity',
                autoCreated: true,
              } as crmObject);
            }
          } else if (lookup.type === 'query') {
            console.info('groove - looking up by query', lookup.value);
            const results =
              (await grooveContactLeadSearch(lookup.value))?.flat() || [];
            if (!results || results.length === 0) {
              console.info('groove - no results found, continuing');
            }
            console.log('found query results', results);
            const sfdcPerson = results[0];
            if (!sfdcPerson?.Id) {
              console.info('groove - no id found, continuing');
              return null;
            }
            const foundPeople = await Promise.all(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              results.map(result => findOrCreatePerson(result.Id!)),
            );
            const [person] = foundPeople;
            console.info('groove - found person', person);
            people.push(person);
            who.push({
              id: person.sfdcId,
              text: person.name,
              type: isContact(person.sfdcId) ? 'Contact' : 'Lead',
              email: person.email,
              phone: person.phone,
              autoCreated: true,
            } as crmObject);
          } else if (lookup.type === 'user') {
            console.info('groove - looking up by user', lookup.value);
            const { data: users } = await searchUsers(lookup.value);
            if (users.length > 0) {
              console.info(`groove - found user for ${lookup.value}`, users[0]);
              if (foundAssignee) {
                console.info(`groove - assignee already found, continuing`);
              } else {
                updateOriginalAction({
                  assignee: users[0],
                });
                [assignee] = users;
                foundAssignee = true;
              }
            } else {
              console.info(
                `groove - user not found for ${lookup.value}, continuing`,
              );
            }
          }
          return null;
        }),
      );

      const externalMetadataSfdcIds = externalMetadata.lookups.map(
        lookup => lookup.value,
      );
      const compareFunction = (a: string, b: string): number => {
        return (
          externalMetadataSfdcIds.indexOf(a) -
          externalMetadataSfdcIds.indexOf(b)
        );
      };
      // dedupe who and what
      who = who
        .filter(
          (person, index, self) =>
            self.findIndex(p => p.id === person.id) === index,
        )
        .sort((a, b) => compareFunction(a.id, b.id));
      what = what
        .filter(
          (person, index, self) =>
            self.findIndex(p => p.id === person.id) === index,
        )
        .sort((a, b) => compareFunction(a.id, b.id));
      const primaryWhat = what[0];
      updateOriginalLoggingTo({
        who: who.length > 0 ? [who[0]] : [],
        what: what.length > 0 ? [primaryWhat] : [],
      });

      updateCurrentLoggingTo({
        who: who.length > 0 ? [who[0]] : [],
        what: what.length > 0 ? [primaryWhat] : [],
      });
      setExternalMetadataRecords({
        who: who.length > 0 ? [who[0]] : [],
        what: what.length > 0 ? [primaryWhat] : [],
      });

      const personData = people.sort((a, b) =>
        compareFunction(a.sfdcId, b.sfdcId),
      )[0];
      const tempAction: Partial<FullAction> = {
        id: NEW_ACTION_ID,
        summary: `Task with ${personData?.name || primaryWhat?.text}`,
        body: externalMetadata.body,
        description: externalMetadata.description,
        dueAt: new Date().toISOString(),
      };
      if (assignee) {
        tempAction.assignee = assignee;
      } else {
        const userAssignee = await getCurrentUserAsAssignee(client);
        tempAction.assignee = userAssignee;
      }
      if (personData) {
        tempAction.personId = personData.id;
        tempAction.who = buildNewActionWhoFromPerson(personData);
      }
      updateAction(tempAction);

      return results;
    },
    {
      enabled: !!externalMetadata.lookups.length,
      onSuccess: () => {
        updateOriginalAction({
          body: externalMetadata.body,
          description: externalMetadata.description,
        });
        useStore.getState().setOtherValues({ wysiwygEdited: true });
        useStore.getState().setOtherValues({ wysiwygEdited: false });
      },
      onError: error => {
        updateOriginalAction({
          body: externalMetadata.body,
          description: externalMetadata.description,
        });
        useStore.getState().setOtherValues({ wysiwygEdited: true });
        useStore.getState().setOtherValues({ wysiwygEdited: false });
      },
    },
  );
};

export default useExternalMetadata;
