export default function HighPriorityIcon({
  className,
  style,
}: {
  className: string;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <circle cx="6" cy="6" r="6" fill="#BA1B1B" />
      <path
        opacity=".8"
        d="M3.11 5.87a.335.335 0 0 0 .53 0L6 3.073 8.36 5.87a.335.335 0 0 0 .53 0 .505.505 0 0 0 0-.629l-2.625-3.11a.335.335 0 0 0-.53 0L3.11 5.24a.505.505 0 0 0 0 .629z"
        fill="#fff"
      />
      <path
        opacity=".8"
        d="M3.11 8.87a.335.335 0 0 0 .53 0L6 6.073 8.36 8.87a.335.335 0 0 0 .53 0 .505.505 0 0 0 0-.629l-2.625-3.11a.335.335 0 0 0-.53 0L3.11 8.24a.505.505 0 0 0 0 .629z"
        fill="#fff"
      />
    </svg>
  );
}
