import { useEffect } from 'react';
import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';
import * as Sentry from '@sentry/browser';

import useUserGet from '../../hooks/useUserGet';

export const isAnalyticsEnabled = (): boolean => {
  return (
    process.env.DEPLOY_ENV === 'review' ||
    process.env.DEPLOY_ENV === 'staging' ||
    process.env.DEPLOY_ENV === 'production' ||
    !!(process.env.DEPLOY_ENV && process.env.DEPLOY_ENV.startsWith('qa'))
  );
};

export const identifyMixpanelUser = (userId: string): void => {
  if (!isAnalyticsEnabled()) return;

  // check to see if mixpanel has been initialized already
  try {
    mixpanel.get_distinct_id();
  } catch {
    mixpanel.init(process.env.MIXPANEL_PROJECT_TOKEN as string);
    mixpanel.identify(userId);
    console.log('Mixpanel initialized');
  }
};

export const useIdentifyMixpanelUser = (): void => {
  const { data: userData } = useUserGet();

  useEffect(() => {
    const user = userData?.data;
    if (user) {
      identifyMixpanelUser(`${user.id}`);
    }
  }, [userData?.data]);
};

export const mixpanelSafeTrack = (
  event_name: string,
  properties?: Dict,
  optionsOrCallback?: RequestOptions | Callback,
  callback?: Callback,
): void => {
  try {
    mixpanel.track(event_name, properties, optionsOrCallback, callback);
  } catch (e) {
    console.log(e);
    // TODO: replace Sentry capture exception with splunk logging lib when https://github.com/GrooveLabs/monorepo/pull/2451 is merged to master
    Sentry.captureException(e);
  }
};
