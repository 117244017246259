import { useState, useEffect } from 'react';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';

import { getLdClient } from 'Utils/ldClient';

/**
 * subscribe to the ldClient change event for a specific key
 * update the flag value when it changes
 *
 * a change event is also triggered when the client
 * changes user via ldClient.identify()
 *
 * because this is a subscription to launch darkly,
 * the component will automatically re-render when the
 * variation changes on their end.
 */
const useVariation = (key: string): string => {
  const ldClient = getLdClient();
  const [flag, setFlag] = useState(ldClient?.variation(key) || false);
  const handleChange = (newValue: LDFlagValue): void => setFlag(newValue);

  // If this hook is used before the client is ready,
  // it would not work correctly, so we need to add
  // this additional effect to ensure that the flag is set
  // to the value populated after initialization.
  useEffect(() => {
    (async () => {
      await ldClient?.waitUntilReady();
      setFlag(ldClient?.variation(key));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, setFlag, ldClient]);

  useEffect(() => {
    if (ldClient) {
      ldClient.on(`change:${key}`, handleChange);
    }
    return (): void => {
      if (ldClient) {
        ldClient.off('change', handleChange);
      }
    };
  }, [ldClient, key]);

  return flag;
};

export default useVariation;
