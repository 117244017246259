import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const ConstantDialIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, 'fill-current')}
  >
    <path d="M7.25 4C7.25 4.69036 6.69036 5.25 6 5.25C5.30964 5.25 4.75 4.69036 4.75 4C4.75 3.30964 5.30964 2.75 6 2.75C6.69036 2.75 7.25 3.30964 7.25 4Z" />
    <path d="M5.25695 9.00527C5.33753 9.00177 5.41856 9 5.5 9C5.87042 9 6.23229 9.03662 6.58218 9.10643C6.97929 8.89705 7.25 8.48014 7.25 8C7.25 7.30964 6.69036 6.75 6 6.75C5.30964 6.75 4.75 7.30964 4.75 8C4.75 8.41207 4.94939 8.77756 5.25695 9.00527Z" />
    <path d="M10.2826 17.2179C10.8368 17.0898 11.25 16.5932 11.25 16C11.25 15.6934 11.1396 15.4126 10.9565 15.1952C10.8646 15.9234 10.6304 16.6072 10.2826 17.2179Z" />
    <path d="M10.7852 12.9727C11.0687 12.7435 11.25 12.3929 11.25 12C11.25 11.3096 10.6904 10.75 10 10.75C9.85539 10.75 9.71651 10.7746 9.58731 10.8197C10.1366 11.4294 10.5515 12.1626 10.7852 12.9727Z" />
    <path d="M11.25 4C11.25 4.69036 10.6904 5.25 10 5.25C9.30964 5.25 8.75 4.69036 8.75 4C8.75 3.30964 9.30964 2.75 10 2.75C10.6904 2.75 11.25 3.30964 11.25 4Z" />
    <path d="M10 9.25C10.6904 9.25 11.25 8.69036 11.25 8C11.25 7.30964 10.6904 6.75 10 6.75C9.30964 6.75 8.75 7.30964 8.75 8C8.75 8.69036 9.30964 9.25 10 9.25Z" />
    <path d="M15.25 4C15.25 4.69036 14.6904 5.25 14 5.25C13.3096 5.25 12.75 4.69036 12.75 4C12.75 3.30964 13.3096 2.75 14 2.75C14.6904 2.75 15.25 3.30964 15.25 4Z" />
    <path d="M14 9.25C14.6904 9.25 15.25 8.69036 15.25 8C15.25 7.30964 14.6904 6.75 14 6.75C13.3096 6.75 12.75 7.30964 12.75 8C12.75 8.69036 13.3096 9.25 14 9.25Z" />
    <path d="M15.25 12C15.25 12.6904 14.6904 13.25 14 13.25C13.3096 13.25 12.75 12.6904 12.75 12C12.75 11.3096 13.3096 10.75 14 10.75C14.6904 10.75 15.25 11.3096 15.25 12Z" />
    <path d="M1 14.5C1 16.9853 3.01472 19 5.5 19C7.98528 19 10 16.9853 10 14.5C10 12.0147 7.98528 10 5.5 10C3.01472 10 1 12.0147 1 14.5ZM7.5 11.5C7.77614 11.5 8 11.7239 8 12V13.5C8 13.7761 7.77614 14 7.5 14H6C5.72386 14 5.5 13.7761 5.5 13.5C5.5 13.2239 5.72386 13 6 13H6.46821C6.41787 12.9722 6.36628 12.9464 6.31355 12.923C6.06824 12.8137 5.80344 12.755 5.53495 12.7503C5.26646 12.7456 4.99977 12.795 4.75079 12.8956C4.5018 12.9962 4.27563 13.1459 4.08575 13.3358C3.89048 13.531 3.5739 13.531 3.37864 13.3358C3.18338 13.1405 3.18338 12.8239 3.37864 12.6287C3.66346 12.3439 4.00273 12.1193 4.3762 11.9684C4.74967 11.8175 5.14971 11.7434 5.55245 11.7505C5.95518 11.7575 6.35238 11.8456 6.72034 12.0094C6.81617 12.0521 6.90951 12.0997 7 12.1519V12C7 11.7239 7.22386 11.5 7.5 11.5ZM6.6238 17.0316C6.25033 17.1825 5.85029 17.2566 5.44755 17.2495C5.04482 17.2425 4.64762 17.1544 4.27966 16.9906C4.18383 16.9479 4.09049 16.9003 4 16.8481V17C4 17.2761 3.77614 17.5 3.5 17.5C3.22386 17.5 3 17.2761 3 17L3 15.5C3 15.2239 3.22386 15 3.5 15L5 15C5.27614 15 5.5 15.2239 5.5 15.5C5.5 15.7761 5.27614 16 5 16H4.53179C4.58213 16.0278 4.63372 16.0536 4.68645 16.077C4.93176 16.1863 5.19656 16.245 5.46505 16.2497C5.73354 16.2544 6.00023 16.205 6.24921 16.1044C6.4982 16.0038 6.72437 15.8541 6.91425 15.6642C7.10951 15.469 7.4261 15.469 7.62136 15.6642C7.81662 15.8595 7.81662 16.1761 7.62136 16.3713C7.33654 16.6561 6.99727 16.8807 6.6238 17.0316Z" />
  </svg>
);
