import React, { FC } from 'react';
import PropTypes from 'prop-types';

type SearchNoResultsIconProps = {
  className: string;
};

/* eslint max-len: ["error", { "code": 1000 }] */
const SearchNoResultsIcon: FC<SearchNoResultsIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="216"
      height="136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipRule="evenodd">
        <path
          fillRule="evenodd"
          d="M208.281 36.647H7.718c-3.159 0-5.718-2.58-5.718-5.76V8.76C2 5.58 4.56 3 7.718 3H208.28C211.439 3 214 5.58 214 8.76v22.127c0 3.18-2.561 5.76-5.719 5.76z"
          fill="#b8eced"
        />
        <path
          d="M206.291 133.578H9.709c-4.24 0-7.71-3.494-7.71-7.765V10.637c0-4.27 3.47-7.765 7.71-7.765h196.582c4.24 0 7.709 3.494 7.709 7.765v115.176c0 4.271-3.469 7.765-7.709 7.765z"
          stroke="#04294c"
          strokeWidth="4"
        />
        <path
          fillRule="evenodd"
          d="M21.954 119.343h60.388V52.049H21.954v67.294z"
          fill="#b8eced"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.29 66.702h-79.821c-3.894 0-7.08-3.21-7.08-7.133s3.186-7.133 7.08-7.133h79.821c3.894 0 7.081 3.21 7.081 7.133s-3.187 7.133-7.081 7.133zM168.271 94.942h-61.415c-1.908 0-3.467-1.572-3.467-3.493v-.71c0-1.921 1.559-3.493 3.467-3.493h61.415c1.907 0 3.467 1.572 3.467 3.494v.71c0 1.92-1.56 3.492-3.467 3.492zM194.234 94.21h-16.51c-1.725 0-3.136-1.423-3.136-3.162v-.642c0-1.736 1.411-3.159 3.136-3.159h16.51c1.725 0 3.137 1.423 3.137 3.16v.641c0 1.74-1.412 3.162-3.137 3.162zM123.035 118.577h-16.51c-1.725 0-3.136-1.423-3.136-3.161v-.642c0-1.736 1.411-3.159 3.136-3.159h16.51c1.725 0 3.137 1.423 3.137 3.159v.642c0 1.738-1.412 3.161-3.137 3.161zM150.09 118.577h-16.508c-1.727 0-3.138-1.423-3.138-3.161v-.642c0-1.736 1.411-3.159 3.138-3.159h16.508c1.726 0 3.138 1.423 3.138 3.159v.642c0 1.738-1.412 3.161-3.138 3.161zM168.271 108.134h-61.415c-1.908 0-3.467-1.572-3.467-3.493v-.709c0-1.922 1.559-3.494 3.467-3.494h61.415c1.907 0 3.467 1.572 3.467 3.494v.709c0 1.921-1.56 3.493-3.467 3.493z"
        fill="#b8eced"
      />
      <path d="M1.644 36.874h213.712" stroke="#04294c" strokeWidth="4" />
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.203 10.626c1.848 0 3.46 1.051 4.319 2.613a5.871 5.871 0 012.442-.53c3.333 0 6.036 2.782 6.036 6.214 0 3.432-2.703 6.214-6.036 6.214-.408 0-.805-.041-1.189-.12-.757 1.376-2.198 2.306-3.852 2.306a4.314 4.314 0 01-1.93-.454c-.767 1.84-2.553 3.131-4.635 3.131-2.167 0-4.015-1.4-4.724-3.363-.31.067-.631.102-.96.102-2.582 0-4.674-2.157-4.674-4.819 0-1.783.94-3.34 2.337-4.174a5.614 5.614 0 01-.448-2.204c0-3.06 2.435-5.542 5.439-5.542 1.763 0 3.33.855 4.324 2.181.903-.96 2.16-1.555 3.551-1.555z"
          fill="#04294c"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.263 68.858A10.666 10.666 0 0051 68c-1.513 0-2.94.286-4.28.858a11.293 11.293 0 00-3.506 2.322 10.85 10.85 0 00-2.355 3.416A10.24 10.24 0 0040 78.77h4.4c0-.897.172-1.739.516-2.524a6.477 6.477 0 011.409-2.053 6.631 6.631 0 012.097-1.38A6.596 6.596 0 0151 72.308a6.631 6.631 0 014.675 1.885 6.477 6.477 0 011.41 2.052c.343.785.515 1.627.515 2.524 0 .965-.218 1.817-.653 2.558-.436.74-.98 1.441-1.633 2.103a39.766 39.766 0 01-2.114 1.986 17.76 17.76 0 00-2.114 2.204 11.758 11.758 0 00-1.633 2.71c-.435.998-.653 2.17-.653 3.516V96h4.4v-2.154c0-.942.218-1.79.653-2.54.435-.752.98-1.459 1.633-2.12a39.725 39.725 0 012.114-1.987 17.76 17.76 0 002.114-2.204 11.758 11.758 0 001.633-2.709c.435-.998.653-2.17.653-3.517 0-1.48-.292-2.872-.877-4.173a11.092 11.092 0 00-2.371-3.416 11.35 11.35 0 00-3.49-2.322zM53 105v-4h-4v4h4z"
        fill="#04294c"
      />
    </svg>
  );
};

export default SearchNoResultsIcon;
