import { Record, Map } from 'immutable';

const defaults = {
  data: new Map(),
  error: null,
  operation: new Map(),
};

class GraphQLResponse extends Record(defaults) {}

export default GraphQLResponse;
