import client from '../../client';

export type ApiMakeConstantBridgeCall = {
  conference_friendly_name: string;
  conference_sid: string;
};

export type ApiAddToConference = {
  call_sid: string;
};

export const makeConstantBridgeCall = (
  from: string,
  to: string,
  should_record: boolean,
): Promise<ApiMakeConstantBridgeCall> => {
  return client
    .post('api/dialer/constant_bridge/call', {
      json: { to, from, should_record },
    })
    .json();
};

export const addPersonToConference = (
  from: string,
  to: string,
  conference_friendly_name: string,
  should_record: boolean,
): Promise<ApiAddToConference> => {
  return client
    .post('api/dialer/constant_bridge/call/add_person_to_conference', {
      json: { from, to, conference_friendly_name, should_record },
    })
    .json();
};

export const removePersonFromConference = (
  conference_sid: string,
  target_person_call_sid: string,
): Promise<void> => {
  return client
    .post('api/dialer/constant_bridge/call/remove_person_from_conference', {
      json: { conference_sid, target_person_call_sid },
    })
    .json();
};

export const endConference = (conferenceSid: string): Promise<void> => {
  return client
    .delete('api/dialer/constant_bridge/call', {
      json: {
        conference_sid: conferenceSid,
      },
    })
    .json();
};
