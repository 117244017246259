import makeHTTPRequest from 'GrooveHTTPClient/makeHTTPRequest';
import { DELETE, PUT, POST } from 'GrooveHTTPClient/HTTPMethod';
import { getAdminContentVisibilityEnabled } from 'GrooveHTTPClient/flows';

const BASE_PATH = '/api/v2/template_folders';

export const index = () =>
  makeHTTPRequest({
    path: BASE_PATH,
    queryParameters: {
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });
export const indexV2 = (payload = {}) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/index_v2`,
    queryParameters: {
      query: payload.query,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const update = ({ folderId, folderName }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${folderId}`,
    method: PUT,
    body: {
      name: folderName,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const create = ({ name }) =>
  makeHTTPRequest({
    path: BASE_PATH,
    method: POST,
    body: {
      name,
    },
  });

export const destroy = folderId =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${folderId}`,
    queryParameters: {
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
    method: DELETE,
  });

export const templatesWithoutHtmlBody = ({ id }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${id}/templates_without_html_body`,
    queryParameters: {
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });
