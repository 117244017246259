import { isUndefined } from 'lodash-es';
import { createSelector } from 'reselect';
import { Set, Map } from 'immutable';

export const getPeople = state => state.getIn(['people', 'people']);
export const getAddingPeopleToFlow = state =>
  state.getIn(['people', 'addingPeopleToFlow']);
export const getRemovingPeopleFromAllFlows = state =>
  state.getIn(['people', 'removingPeopleFromAllFlows']);
export const getTotal = state => state.getIn(['people', 'total']);
export const getSelectedPeople = state => {
  return state.getIn(['people', 'selectedPeople']).reduce((set, person) => {
    if (!isUndefined(person)) {
      return set.add(person);
    }

    return set;
  }, new Set());
};
export const getActivePersonId = state =>
  state.getIn(['people', 'activePersonId']);
export const getQuery = state => state.getIn(['people', 'query']);
export const getLoading = state => state.getIn(['people', 'loading']);

export const getSearchRules = state => state.getIn(['people', 'searchRules']);
export const getEditedSearchRules = state =>
  state.getIn(['people', 'editedSearchRules']);
export const getActiveSearchRules = state =>
  state.getIn(['people', 'activeSearchRules']);

export const getSavedSearches = state =>
  state.getIn(['people', 'savedSearches']);
export const getSelectedSavedSearchId = state =>
  state.getIn(['people', 'selectedSavedSearchId']);
export const getActivePerson = createSelector(
  getPeople,
  getActivePersonId,
  (people, activePersonId) => {
    if (!activePersonId || !people) return new Map();

    return people.get(activePersonId);
  }
);
export const getOneFlowRestriction = state =>
  state.getIn(['people', 'flowRestrictionData']);
export const getIsLoadingModal = state =>
  state.getIn(['people', 'loadingModal']);
export const getSelectedPeopleAvailableToJoinFlow = createSelector(
  getSelectedPeople,
  getOneFlowRestriction,
  (selectedPeople, oneFlowRestrictionData) =>
    selectedPeople.filterNot(person => oneFlowRestrictionData.get(`${person}`))
);
