import { PEOPLE_IMPORT_ROOT_UI_KEY_PATH } from 'Modules/PeopleImportDialog/constants';

export const SAVED_SEARCH_GROUP_ID = 'peopleImportSavedSearch';
export const SAVED_SEARCH_SELECT_FIELD_ID = 'peopleImportSavedSearchSelect';

export const OBJECT_NAME_FIELD_ID = 'fieldName';
export const OPERATOR_FIELD_ID = 'operator';
export const VALUE_FIELD_ID = 'value';

export const SAVE_SEARCH_DIALOG_OPEN_KEYPATH = [
  ...PEOPLE_IMPORT_ROOT_UI_KEY_PATH,
  'saveDialogOpen',
];
export const DELETE_SEARCH_DIALOG_OPEN_KEYPATH = [
  ...PEOPLE_IMPORT_ROOT_UI_KEY_PATH,
  'deleteDialogOpen',
];
export const SAVE_SEARCH_GROUP_ID = 'saveSearchDialog';
export const SAVE_SEARCH_NAME_FIELD_ID = 'name';
export const REQUEST_IN_PROGRESS_KEY_PATH = [
  ...PEOPLE_IMPORT_ROOT_UI_KEY_PATH,
  'requestInProgress',
];
export const SCOPE_SELECTOR_GROUP_ID = 'scopeSelector';
export const SCOPE_SELECTOR_FIELD_ID = 'isMine';
export const FILTER_WARNING_DIALOG_KEYPATH = [
  ...PEOPLE_IMPORT_ROOT_UI_KEY_PATH,
  'filterWarningDialogOpen',
];

export const SOQL_QUERY_REQUEST_LIMIT = 500;
export const SOQL_QUERY_REQUEST_OFFSET_LIMIT = 2000;
