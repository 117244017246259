import { createSelector } from 'reselect';

export const jobs = state => state.getIn(['batchJobs', 'jobs']);

export const createGetJob = id => {
  return createSelector(jobs, jobs => jobs.get(id));
};

export const createGetJobByName = name => {
  return createSelector(jobs, jobs =>
    jobs.find(job => job.get('name') === name)
  );
};

export const createGetJobProgress = id => {
  return createSelector(createGetJob(id), job => {
    if (!job) {
      return -1;
    }
    return job.get('progress');
  });
};

export const createGetJobProgressByName = name => {
  return createSelector(createGetJobByName(name), job =>
    !job ? -1 : job.get('progress')
  );
};
