import React, { Component, Fragment } from 'react';
import { OrderedMap } from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  getEmails,
  getNoTrackingActivity,
  getNoMatchingResults,
  getIsSearchTooShort,
  shouldShowLoader,
} from 'Modules/Emails/selectors';
import EmailRecord from 'Modules/Emails/data/Email';
import Email from 'Modules/Emails/components/Email';
import Header from 'Modules/Emails/containers/Header';
import { Divider, Typography } from '@material-ui/core';
import { MINIMUM_SEARCH_LENGTH } from 'Modules/Emails/constants';
import Loader from 'Modules/Emails/components/Loader';
import NoTrackingActivity from 'Modules/Emails/components/NoTrackingActivity';
import NoResults from 'Modules/Emails/components/NoResults';

const styles = theme => ({
  root: {
    width: 444,
  },
  zeroState: {
    padding: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
  },
});

@withStyles(styles)
@connect(state => {
  return {
    emails: getEmails(state),
    noTrackingActivity: getNoTrackingActivity(state),
    isSearchTooShort: getIsSearchTooShort(state),
    noMatchingResults: getNoMatchingResults(state),
    shouldShowLoader: shouldShowLoader(state),
  };
}, {})
export default class Emails extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string.isRequired,
    }).isRequired,
    emails: ImmutablePropTypes.orderedMapOf(
      PropTypes.instanceOf(EmailRecord),
      PropTypes.string
    ),
    isSearchTooShort: PropTypes.bool.isRequired,
    noTrackingActivity: PropTypes.bool.isRequired,
    noMatchingResults: PropTypes.bool.isRequired,
    shouldShowLoader: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    emails: OrderedMap(),
  };

  render() {
    const {
      emails,
      classes,
      isSearchTooShort,
      noTrackingActivity,
      noMatchingResults,
      shouldShowLoader,
    } = this.props;
    return (
      <div className={classes.root}>
        <Header />
        <Divider />
        {isSearchTooShort && (
          <Typography className={classes.zeroState}>
            {`Enter at least ${MINIMUM_SEARCH_LENGTH} characters to search`}
          </Typography>
        )}
        {noTrackingActivity && <NoTrackingActivity />}
        {noMatchingResults && <NoResults />}
        {shouldShowLoader && <Loader />}
        {!shouldShowLoader &&
          emails.map(email => (
            <Fragment key={email.id}>
              <Email email={email} />
              <Divider />
            </Fragment>
          ))}
      </div>
    );
  }
}
