import React, { FC } from 'react';
import { Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LiveSendErrorsProps } from './Seismic';
import SeismicButton from './SeismicButton';

const useStyles = makeStyles({
  buttonContainer: {
    margin: 'auto',
    paddingTop: 30,
  },
  container: {
    overflow: 'hidden',
    maxHeight: 'unset',
    maxWidth: 400,
    padding: 35,
    textAlign: 'center',
  },
  errorText: {
    fontSize: '0.875rem',
  },
});

const LiveSendErrors: FC<LiveSendErrorsProps> = ({ onClose, errorMessage }) => {
  const { buttonContainer, container, errorText } = useStyles();
  return (
    <Dialog open onClose={onClose} classes={{ paper: container }}>
      <Typography classes={{ root: errorText }}>{errorMessage}</Typography>
      <div className={buttonContainer}>
        <SeismicButton onClick={onClose}>Close</SeismicButton>
      </div>
    </Dialog>
  );
};

export default LiveSendErrors;
