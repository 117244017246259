import { FC, HTMLProps } from 'react';
import cx from 'classnames';

import Tooltip from './Tooltip';
import Text from './Text';
import { CloseIcon } from './BoogieIcon';

type TagProps = {
  onCancel?: () => void;
  onClick?: () => void;
  onHover?: () => void;
  label: string;
  className?: HTMLProps<HTMLElement>['className'];
  dataValue?: string;
  datatype?: string;
  size?: 'small' | 'normal';
  tooltip?: string;
  disabled?: boolean;
  icon?: JSX.Element;
};

const Tag: FC<TagProps> = ({
  onCancel,
  onClick,
  onHover,
  label,
  tooltip,
  className,
  dataValue,
  datatype,
  size = 'normal',
  disabled,
  icon,
}) => {
  let divProps: Partial<HTMLProps<HTMLDivElement>> = {};
  let hoverTimeout: NodeJS.Timeout;

  if (onClick)
    divProps = {
      role: 'button',
      onClick,
      onKeyDown: event => event.key === 'Enter' && onClick(),
      tabIndex: 0,
    };

  if (onHover)
    divProps = {
      ...divProps,
      // Time out and the clear timeout makes sure that we don't trigger onHover on an
      // accidental brush over the tag
      onMouseEnter: () => {
        hoverTimeout = setTimeout(onHover, 500);
      },
      onMouseLeave: () => {
        if (hoverTimeout) clearTimeout(hoverTimeout);
      },
    };

  return (
    <Tooltip content={tooltip || label}>
      <div
        className={cx(
          'flex flex-row items-center border border-solid border-neutral/100 truncate rounded-[6px] min-w-0',
          onClick && 'cursor-pointer',
          size === 'normal' ? 'h-[26px] px-[8px]' : 'h-[22px] px-[6px]',
          className,
          disabled && 'text-neutral/600 bg-neutral/75',
        )}
        datatype={datatype}
        aria-label={dataValue}
        {...divProps}
      >
        {icon && <div className="mr-[6px] flex w-[16px]">{icon}</div>}
        <Text
          className={cx(
            'whitespace-nowrap truncate min-w-0',
            size === 'normal' ? 'text-body' : 'text-body-sm',
          )}
        >
          {label}
        </Text>
        {onCancel && !disabled && (
          <div
            onClick={event => {
              event.stopPropagation();
              onCancel();
            }}
            onKeyUp={event => event.key === 'Enter' && onCancel()}
            role="button"
            tabIndex={0}
            className="cursor-pointer flex items-center"
          >
            <CloseIcon className="text-neutral/600 w-[12px] h-[12px] ml-[8px]" />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default Tag;
