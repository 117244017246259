import { TemplateFolder } from '@groove/api/services/v1/templateFolders';
import { FC } from 'react';
import { Icon, List, Stack } from '@fluentui/react';
import StyledSeparator from '@groove/ui/Components/StyledSeparator';
import TruncatedTextWithTooltip from '@groove/ui/Components/TruncatedTextWithTooltip';
import { Template } from '@groove/api/gateway/v1/template';

import TemplateFolderIcon from '../icons/folder_icon.svg';

import TemplateItem from './TemplateItem';

const TemplateFolderList: FC<{
  onInsert: (template: Template) => void;
  folder: TemplateFolder;
  setSelectedFolder: (folder: TemplateFolder | undefined) => void;
}> = ({ folder, onInsert, setSelectedFolder }) => {
  const { templates } = folder;

  return (
    <div className="flex flex-col flex-grow pb-5 overflow-y-auto">
      <Stack
        className="flex flex-row items-center w-auto px-3 py-2 cursor-pointer"
        onClick={() => setSelectedFolder(undefined)}
      >
        <Icon iconName="ChevronLeft" aria-label="favorite-folder-back" />
        <img
          className="pl-1"
          src={TemplateFolderIcon}
          alt="folder-icon"
          width={16}
        />
        <TruncatedTextWithTooltip
          text={folder.name}
          textClasses="pl-1 text-metadata-xs text-primary"
        />
      </Stack>
      <StyledSeparator xMargin={12} />
      {templates?.length > 0 && (
        <List
          className="w-full"
          items={templates}
          onRenderCell={template =>
            template && <TemplateItem template={template} onInsert={onInsert} />
          }
        />
      )}
    </div>
  );
};

export default TemplateFolderList;
