import { useQuery, UseQueryResult } from 'react-query';

import {
  ApiAvailablePicklistValues,
  availablePicklistValues,
} from '../gateway/v1/users';

export const useAvailablePicklistValues = (
  type: string,
): UseQueryResult<ApiAvailablePicklistValues> =>
  useQuery(
    ['availablePicklistValues', type],
    () => availablePicklistValues(type),
    {
      cacheTime: 5 * 60 * 1000, // The avaliable picklistValues won't change much
      staleTime: 5 * 60 * 1000,
    },
  );
