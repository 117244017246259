import humps from 'humps';
import { omit, isUndefined, isEmpty } from 'lodash-es';
import { stringify as toQueryString } from 'qs';
import HttpStatus from 'http-status-codes';
import * as GrooveLocalStorage from 'GrooveLocalStorage';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';
import { UnauthorizedError } from './errors';
import { POST_MESSAGE_OUTLOOK_SOURCE } from 'Modules/ActionCompose/constants';

const camelizeKeys = object => {
  return humps.camelizeKeys(object);
};

export default async (path, options = {}) => {
  const { queryParams = {}, body = {}, method = 'get' } = options;
  const grooveEngineRoot = grooveEngineRootUrl || '';
  let fullPath = grooveEngineRoot + path;
  const csrfToken = GrooveLocalStorage.fetch('csrfToken');
  let authHeaders = { 'X-CSRF-Token': csrfToken };
  const params = new URLSearchParams(window.location.search);
  if (params.get('source') === POST_MESSAGE_OUTLOOK_SOURCE) {
    authHeaders = {
      'X-Microsoft-Exchange-Identity-Token':
        window.localStorage.getItem('microsoftToken'),
    };
  }

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...authHeaders,
  };

  const requestOptions = {
    method,
    headers,
    credentials: 'include',
  };

  // If there is query string data, modify the fullPath to include the query string part.
  if (!isEmpty(queryParams)) {
    fullPath = `${grooveEngineRoot}${path}?${toQueryString(queryParams, {
      arrayFormat: 'brackets',
    })}`;
  }

  if (!isEmpty(body)) {
    if (method === 'get' || method === 'head') {
      throw new Error('GET and HEAD requests cannot include a body.');
    } else {
      // Remove all undefined values from the body object.
      requestOptions.body = JSON.stringify(omit(body, isUndefined));
    }
  }

  const response = await fetch(fullPath, requestOptions);

  if (response.status === HttpStatus.UNAUTHORIZED) {
    throw new UnauthorizedError();
  }

  if (response.status !== HttpStatus.NO_CONTENT) {
    const json = await response.json();
    return camelizeKeys(json);
  }

  return {
    meta: {
      success: true,
      error: null,
    },
  };
};
