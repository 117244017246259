import { IpcRenderer } from 'electron';
import { GetState, SetState } from 'zustand';

import { Store } from '../useStore';

type DownloadProgress = {
  total: number;
  transferred: number;
  percent: number;
  bytesPerSecond: number;
};

export type ConfigurationStore = {
  automaticallyCheckForUpdates: boolean;
  setAutomaticallyCheckForUpdates: (
    automaticallyCheckForUpdates: boolean,
  ) => void;

  isCheckingForUpdates: boolean;
  setIsCheckingForUpdates: (isCheckingForUpdates: boolean) => void;

  isUpdateAvailable: boolean | null;
  setIsUpdateAvailable: (isUpdateAvailable: boolean) => void;

  downloadProgress: DownloadProgress | null;
  setDownloadProgress: (downloadProgress: DownloadProgress | null) => void;

  isUpdateDownloaded: boolean;
  setIsUpdateDownloaded: (isUpdateDownloaded: boolean) => void;

  checkForUpdates: () => void;

  recordFromStart: boolean;
  setRecordFromStart: (recordFromStart: boolean) => void;

  isLocalDialEnabled: boolean;
  setLocalDialEnabled: (isLocalDialEnabled: boolean) => void;

  isBridgeCallingEnabled: boolean;
  setBridgeCallingEnabled: (isBridgeCallingEnabled: boolean) => void;

  isConstantBridgeEnabled: boolean;
  setConstantBridgeEnabled: (isConstantBridgeEnabled: boolean) => void;

  hasPreviouslyCompletedSetup: boolean;
  setHasPreviouslyCompletedSetup: (
    hasPreviouslyCompletedSetup: boolean,
  ) => void;

  isInSetup: boolean;
  setIsInSetup: (isInSetup: boolean) => void;

  isOnboarding: boolean;
  setIsOnboarding: (isOnboarding: boolean) => void;

  launchAtStartup: boolean;
  setLaunchAtStartup: (isOnboarding: boolean) => void;

  enterToSendChat: boolean;
  setEnterToSendChat: (enterToSendChat: boolean) => void;

  defaultCountryCode: string;
  setDefaultCountryCode: (defaulCountryCode: string) => void;

  ipcRenderer?: IpcRenderer;
  setIpcRenderer: (renderer: IpcRenderer) => void;
};
export const configurationStore = (
  set: SetState<Store>,
  get: GetState<Store>,
): ConfigurationStore => ({
  automaticallyCheckForUpdates:
    localStorage.getItem('doNotAutomaticallyCheckForUpdates') !== 'true',
  setAutomaticallyCheckForUpdates: automaticallyCheckForUpdates => {
    set({ automaticallyCheckForUpdates });
    localStorage.setItem(
      'doNotAutomaticallyCheckForUpdates',
      String(!automaticallyCheckForUpdates),
    );
  },

  isCheckingForUpdates: false,
  setIsCheckingForUpdates: isCheckingForUpdates =>
    set({ isCheckingForUpdates }),

  isUpdateAvailable: null,
  setIsUpdateAvailable: isUpdateAvailable => set({ isUpdateAvailable }),

  downloadProgress: null,
  setDownloadProgress: updateProgress =>
    set({ downloadProgress: updateProgress }),

  isUpdateDownloaded: false,
  setIsUpdateDownloaded: isUpdateDownloaded => set({ isUpdateDownloaded }),

  checkForUpdates: () => {
    const {
      setIsCheckingForUpdates,
      setIsUpdateAvailable,
      setDownloadProgress,
      downloadProgress,
      isUpdateDownloaded,
      setIsUpdateDownloaded,
    } = get();
    if (isUpdateDownloaded || downloadProgress) {
      return;
    }
    setIsCheckingForUpdates(true);
    get().ipcRenderer?.send('asynchronous-message', 'check-for-updates');
    get().ipcRenderer?.on(
      'asynchronous-reply',
      (_e, arg: { type: string; payload: unknown }) => {
        if (arg.type === 'update-not-available') {
          setIsUpdateAvailable(false);
          setIsCheckingForUpdates(false);
        }
        if (arg.type === 'update-available') {
          setIsUpdateAvailable(true);
        }

        if (arg.type === 'download-progress') {
          setDownloadProgress(arg.payload as DownloadProgress);
        }

        if (arg.type === 'update-downloaded') {
          setIsUpdateDownloaded(true);
          setIsCheckingForUpdates(false);
        }
      },
    );
  },
  recordFromStart: localStorage.getItem('recordFromStart') !== 'true',
  setRecordFromStart: recordFromStart => {
    set({ recordFromStart });
    localStorage.setItem('recordFromStart', String(recordFromStart));
  },
  isLocalDialEnabled: false,
  setLocalDialEnabled: (isLocalDialEnabled: boolean) =>
    set({ isLocalDialEnabled }),
  isBridgeCallingEnabled: false,
  setBridgeCallingEnabled: (isBridgeCallingEnabled: boolean) => {
    set({ isBridgeCallingEnabled });
    if (!isBridgeCallingEnabled) {
      set({ isConstantBridgeEnabled: false });
    }
  },

  isConstantBridgeEnabled:
    localStorage.getItem('isConstantBridgeEnabled') === 'true',
  setConstantBridgeEnabled: (isConstantBridgeEnabled: boolean) => {
    set({ isConstantBridgeEnabled });
    localStorage.setItem(
      'isConstantBridgeEnabled',
      String(isConstantBridgeEnabled),
    );
  },
  hasPreviouslyCompletedSetup:
    localStorage.getItem('hasPreviouslyCompletedSetup') === 'true',
  setHasPreviouslyCompletedSetup: hasPreviouslyCompletedSetup => {
    set({ hasPreviouslyCompletedSetup });
    localStorage.setItem(
      'hasPreviouslyCompletedSetup',
      String(hasPreviouslyCompletedSetup),
    );
  },
  isInSetup: false,
  setIsInSetup: (isInSetup: boolean) => set({ isInSetup }),

  isOnboarding: false,
  setIsOnboarding: (isOnboarding: boolean) => set({ isOnboarding }),

  launchAtStartup:
    localStorage.getItem('launchAtStartup') === null
      ? true
      : localStorage.getItem('launchAtStartup') === 'true',
  setLaunchAtStartup: launchAtStartup => {
    set({ launchAtStartup });
    localStorage.setItem('launchAtStartup', String(launchAtStartup));
    get().ipcRenderer?.send('asynchronous-message', {
      type: 'set-launch-at-startup',
      payload: launchAtStartup,
    });
  },

  enterToSendChat: localStorage.getItem('enterToSendChat') === 'true',
  setEnterToSendChat: enterToSendChat => {
    set({ enterToSendChat });
    localStorage.setItem('enterToSendChat', String(enterToSendChat));
  },

  defaultCountryCode: localStorage.getItem('defaultCountryCode') || '1',
  setDefaultCountryCode: defaultCountryCode => {
    set({ defaultCountryCode });
    localStorage.setItem('defaultCountryCode', String(defaultCountryCode));
  },

  ipcRenderer: undefined,
  setIpcRenderer: ipcRenderer => set({ ipcRenderer }),
});
