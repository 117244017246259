import { eventChannel } from 'redux-saga';
import { take, put } from 'redux-saga/effects';
import { getLdClient } from 'Utils/ldClient';
import { forceReloadOnModuleChange } from 'Modules/App/actions';

export default function* launchdarkly() {
  const client = getLdClient();

  const channel = eventChannel(emitter => {
    const handler = changeSet => {
      emitter(changeSet);
    };
    client.on('change', handler);

    return () => client.off('change', handler);
  });

  while (true) {
    yield take(channel);
    yield put(forceReloadOnModuleChange());
  }
}
