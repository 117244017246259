import { InfiniteData, QueryClient } from 'react-query';

import { ChatChannel, ChatChannelAttributes } from '../api/graphql/types';

const setChannelAttributesQueryData = (
  queryClient: QueryClient,
  channelSid: string,
  attributes: Partial<ChatChannelAttributes>,
): void => {
  queryClient.setQueryData<InfiniteData<ChatChannel> | undefined>(
    ['chat-channel', channelSid],
    data => {
      if (!data || !data.pages) return undefined;

      return {
        pages: [
          {
            ...data!.pages[0],
            attributes: {
              ...data!.pages[0].attributes,
              ...attributes,
            },
          },
          ...data!.pages.slice(1),
        ],
        pageParams: data!.pageParams,
      };
    },
  );
};

export default setChannelAttributesQueryData;
