import { ITheme } from '@fluentui/react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import FactorArrow from 'Modules/ActionsRewrite/components/customCells/SmartPriority/HoverCard/displayTopFactors/factorArrow';
import { sharedFactorRowSpacingStyle } from 'Modules/ActionsRewrite/components/customCells/SmartPriority/constants';
import { Factor as FactorType } from 'Modules/ActionsRewrite/components/customCells/SmartPriority/HoverCard/useGetFactors';

const useStyles = makeStyles((theme: ITheme) => ({
  whiteText: {
    color: theme.palette.white,
    fontSize: 12,
    marginLeft: 8,
  },
  factorRow: {
    ...sharedFactorRowSpacingStyle,
    marginRight: 12,
  },
}));

const Factor = ({ factor }: { factor: FactorType }) => {
  const styles = useStyles();

  return (
    <Box display="flex" className={styles.factorRow} component="li">
      <FactorArrow effect={factor.effect} />
      <Typography className={styles.whiteText}>{factor.text}</Typography>
    </Box>
  );
};

export default Factor;
