import ky, { Options } from 'ky';

export const options = {
  prefixUrl: `${process.env.NX_GROOVE_ENGINE_ROOT_URL}/oauth`,
  timeout: 25000,
} as Options;

const client = ky.create(options);

export default client;
