import ky, { Options } from 'ky';

// eslint-disable-next-line import/no-mutable-exports
export let options: Options | null = null;

export const configureClient = (clientOptions: Options | null): void => {
  if (clientOptions) {
    options = { ...clientOptions, timeout: 25000 };
  }
};

export default (extraOptions: Options = {}): typeof ky => {
  if (options === null) {
    throw new Error(
      'The client is not configured. Please call `configureClient` before using it.',
    );
  }
  return ky.create({
    ...options,
    ...extraOptions,
  });
};
