import { createActions, keyMirror } from '@groove-labs/action-utils';

const actionTypes = createActions({
  FLOWS_SHOW: {
    VARIANTS: {
      ANALYTICS_DIALOG: keyMirror(['OPEN', 'CLOSE']),
      ...keyMirror([
        'CLOSE_VARIANT_STATISTICS',
        'SET_ACTIVE_VARIANTS_ANALYTICS',
        'SET_INACTIVE_VARIANTS_ANALYTICS',
        'SET_PEOPLE_TABLE_VARIANTS',
        'SHOW_ADD_DIALOG',
        'SHOW_EDIT_DIALOG',
        'CLOSE_VARIANT_DIALOG',
        'TOGGLE_VARIANT_ANALYTICS_SORTING',
        'UPDATE_TEMPLATE',
        'CREATE',
        'DELETE',
        'TRACK_VARIANT_ACTION',
      ]),
    },
  },
}).FLOWS_SHOW.VARIANTS;

const trackVariantAction = ({ actionName }) => ({
  type: actionTypes.TRACK_VARIANT_ACTION,
  value: { actionName },
});

const closeAnalyticsDialog = () => ({
  type: actionTypes.ANALYTICS_DIALOG.CLOSE,
});

const showAddDialog = ({ stepId }) => ({
  type: actionTypes.SHOW_ADD_DIALOG,
  payload: {
    stepId,
  },
});

const showEditDialog = ({ stepId, variantId }) => ({
  type: actionTypes.SHOW_EDIT_DIALOG,
  payload: {
    stepId,
    variantId,
  },
});

const closeVariantDialog = () => ({
  type: actionTypes.CLOSE_VARIANT_DIALOG,
});

const showAnalyticsDialog = () => ({
  type: actionTypes.ANALYTICS_DIALOG.OPEN,
});

const setPeopleTableVariants = variants => ({
  type: actionTypes.SET_PEOPLE_TABLE_VARIANTS,
  payload: variants,
});

const setActiveVariantsAnalytics = data => ({
  type: actionTypes.SET_ACTIVE_VARIANTS_ANALYTICS,
  payload: data,
});

const setInactiveVariantsAnalytics = data => ({
  type: actionTypes.SET_INACTIVE_VARIANTS_ANALYTICS,
  payload: data,
});

const toggleVariantAnalyticsSorting = columnName => ({
  type: actionTypes.TOGGLE_VARIANT_ANALYTICS_SORTING,
  payload: columnName,
});

const deleteVariant = ({ stepId, variantId }) => ({
  type: actionTypes.DELETE,
  payload: {
    stepId,
    variantId,
  },
});

const createVariant = ({ stepId, templateId }) => ({
  type: actionTypes.CREATE,
  payload: {
    stepId,
    templateId,
  },
});

const updateTemplate = template => ({
  type: actionTypes.UPDATE_TEMPLATE,
  payload: template,
});

export {
  actionTypes,
  closeAnalyticsDialog,
  setActiveVariantsAnalytics,
  setInactiveVariantsAnalytics,
  setPeopleTableVariants,
  deleteVariant,
  showAddDialog,
  showEditDialog,
  closeVariantDialog,
  showAnalyticsDialog,
  toggleVariantAnalyticsSorting,
  createVariant,
  updateTemplate,
  trackVariantAction,
};
