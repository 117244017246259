import { fromJS } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/omnibar';

const initialState = fromJS({
  omnibarOpen: false,
  activeAccountSfdcId: null,
});

export default function omnibar(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SET_OMNIBAR_OPEN: {
      return state.set('omnibarOpen', action.payload);
    }

    case actionTypes.SET_ACTIVE_ACCOUNT_SFDC_ID: {
      return state.set('activeAccountSfdcId', action.payload.sfdcId);
    }

    default: {
      return state;
    }
  }
}
