const roles = Object.freeze({
  ADMIN: 'admin',
  AGENT: 'agent',
  ANALYST: 'analyst',
  TEAM_LEAD: 'team_lead',
});

export const ROLE_VALUES = Object.freeze(Object.values(roles));

export default roles;
