import { List } from '@fluentui/react';
import { Template } from '@groove/api/gateway/v1/template';
import { FC } from 'react';

import TemplateItem from './TemplateItem';

type TemplatesListProps = {
  templates: Template[] | undefined;
  onInsert: (template: Template) => void;
};

/**
 * Multi purpose list component for templates
 * Currently used for recent templates and search results templates
 *
 * @param templates
 * @returns Component
 */
const TemplatesList: FC<TemplatesListProps> = ({ templates, onInsert }) => {
  return (
    <div className="flex flex-1 pb-5 overflow-y-auto">
      {templates && templates.length > 0 && (
        <List
          style={{ width: '100%' }}
          items={templates}
          onRenderCell={template =>
            template && <TemplateItem template={template} onInsert={onInsert} />
          }
        />
      )}
    </div>
  );
};

export default TemplatesList;
