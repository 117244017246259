import { List, Map, OrderedMap } from 'immutable';
import { createGetObjectFieldsMetadata } from 'Modules/Shared/selectors/salesforceMeta';
import { createGetJobProgress } from 'Modules/Shared/selectors/batchJobs';
import { createSelector } from 'reselect';

export const searchResults = state =>
  state.getIn(['SpacesImportDialog', 'searchResults'], new Map());
export const selectedObjectIds = state =>
  state.getIn(['SpacesImportDialog', 'selectedObjectIds']);
export const users = state => state.getIn(['SpacesImportDialog', 'users']);
export const isSearching = state =>
  state.getIn(['SpacesImportDialog', 'isSearching']);
export const isBatchCreating = state =>
  state.getIn(['SpacesImportDialog', 'isBatchCreating']);

const fieldFilter = field =>
  field.get('type') === 'reference' && field.get('referenceTo') === 'User';

export const getSObjectFieldsMetadata = sObject =>
  createSelector(
    state => createGetObjectFieldsMetadata(sObject, fieldFilter)(state),
    accountFieldsMetadata => {
      const formattedMetadata = accountFieldsMetadata
        .map(el => el.set('label', el.get('label').replace('ID', '').trim()))
        .sort((item1, item2) => {
          if ([item1.get('label'), item2.get('label')].includes('Owner')) {
            return 1;
          }
          return 0;
        });

      return new OrderedMap(
        formattedMetadata.map(accountFieldData => [
          accountFieldData.get('name'),
          accountFieldData,
        ])
      );
    }
  );

export const getSfdcColumnNames = createSelector(
  state => searchResults(state).get('sfdcColumnNames'),
  sfdcColumnNames => {
    if (sfdcColumnNames && sfdcColumnNames.size > 0) {
      return new List(['Name']).concat(
        sfdcColumnNames.filter(name => name !== 'Name')
      );
    }

    return new List();
  }
);

export const getAddAccountsJobId = state =>
  state.getIn(['SpacesImportDialog', 'addAccountsJobId'], null);

export const getAddAccountsJobProgress = createSelector(
  isBatchCreating,
  state => {
    const jobId = getAddAccountsJobId(state);
    if (!jobId) {
      return null;
    }
    return createGetJobProgress(jobId)(state);
  },
  (isBatchCreating, jobProgress) => {
    // Add accounts action is process in two steps:
    // 1. batch_create - isBatchCreating is true and jobProgress is null
    // 2. add_accounts - isBatchCreating is true and jobProgress is a number
    // To simplfy to logic we hook up the progress to the second step - add accounts process
    if (isBatchCreating) {
      if (!jobProgress) {
        return 0;
      }
      return jobProgress;
    }
    return null;
  }
);
