import { fromJS } from 'immutable';
import { fetch as getLocalStorageValue } from 'GrooveLocalStorage';
import isObject from 'lodash-es/isObject';

import client from './client';
import HTTPResponse from './HTTPResponse';
import HTTPError from './HTTPError';
import { GET } from './HTTPMethod';
import { sanitizeURLPath } from './utilities';
import { POST_MESSAGE_OUTLOOK_SOURCE } from 'Modules/ActionCompose/constants';
import { getPathName } from 'Utils/history';
import store from 'Modules/App/Store/useStore';

const setMessageFromResponse = response => {
  // To maintain backwards compatibility with responses that don't have a data attribute
  if (isObject(response) && isObject(response.data)) {
    response.message = response.data;
    delete response.data;
  }
};

const executeHTTPRequest = async ({
  path,
  method = GET,
  headers = {},
  queryParameters = {},
  body = {},
  // transformResponse is an optional function that allows to change the default response transformation
  // Eg. data => (data) skips the transform at all
  transformResponse = null,
  cancelToken = null,
}) => {
  let response;

  let authHeaders = { 'X-CSRF-Token': getLocalStorageValue('csrfToken') };

  const febesPage = getPathName()?.split('/')[1];
  const moboUser = store.getState().moboUser;
  const moboHeader = {
    'X-Mobo-User': moboUser ? moboUser.id : '',
    'X-Febes-Page': febesPage,
  };

  const params = new URLSearchParams(window.location.search);
  if (params.get('source') === POST_MESSAGE_OUTLOOK_SOURCE) {
    authHeaders = {
      'X-Microsoft-Exchange-Identity-Token':
        window.localStorage.getItem('microsoftToken'),
    };
  }

  const requestParams = {
    url: path,
    method,
    headers: {
      ...headers,
      ...authHeaders,
      ...moboHeader,
    },
    data: body,
    params: queryParameters,
    cancelToken,
  };

  if (transformResponse) {
    requestParams.transformResponse = transformResponse;
  }

  try {
    response = await client.request(requestParams);
  } catch (error) {
    setMessageFromResponse(error.response);
    return {
      result: new HTTPError(
        fromJS({
          ...error,
          response: new HTTPResponse(fromJS(error.response)),
          path: sanitizeURLPath(path),
        })
      ),
    };
  }

  setMessageFromResponse(response);

  return {
    result: new HTTPResponse(fromJS(response)),
  };
};

export default executeHTTPRequest;
