import { FC } from 'react';
import { Dropdown } from '@fluentui/react';

import { AdditionalFieldProps } from './types';

const Picklist: FC<AdditionalFieldProps<string>> = ({
  additionalField,
  value,
  required,
  onChange,
}) => {
  return (
    <Dropdown
      options={
        additionalField.picklist?.map(({ label, value }) => ({
          key: value,
          ariaLabel: label,
          text: label,
        })) || []
      }
      label={additionalField.label}
      selectedKey={value || ''}
      required={required}
      onChange={(e, option) => option && onChange(option.key.toString())}
    />
  );
};

export default Picklist;
