import { Template } from '@groove/api/gateway/v1/template';
import {
  ApiTemplateFolders,
  TemplateFolder,
} from '@groove/api/services/v1/templateFolders';
import StyledSeparator from '@groove/ui/Components/StyledSeparator';
import { FC } from 'react';
import { Text } from '@fluentui/react';

import useStore from './store/useStore';
import TemplateFolderListItem from './TemplateFoldersListItem';
import TemplatesList from './TemplatesList';
import TemplatesListItem from './TemplatesListItem';
import { TEMPLATES_MENU_WIDTH } from './constants';
import findTemplatesByNameOrSubject from './findTemplatesByNameOrSubject';

type SearchResultsProps = {
  results: ApiTemplateFolders | undefined;
};

const partitionResults = (
  results: TemplateFolder[],
  searchTerm: string,
): { folders: TemplateFolder[]; templates: Template[] } => {
  return results.reduce(
    (acc, folder) => {
      if (folder.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        acc.folders.push(folder);
      }

      acc.templates.push(...findTemplatesByNameOrSubject(folder, searchTerm));

      return acc;
    },
    { folders: [], templates: [] } as {
      folders: TemplateFolder[];
      templates: Template[];
    },
  );
};

const SearchResults: FC<SearchResultsProps> = ({ results }) => {
  const searchTerm = useStore(store => store.searchTerm);
  const selectedFolder = useStore(store => store.selectedFolder);

  const { folders, templates } = partitionResults(
    results?.data || [],
    searchTerm,
  );

  if (folders.length === 0 && templates.length === 0) {
    return (
      <div className={`flex flex-col w-[${TEMPLATES_MENU_WIDTH}]`}>
        <div className="flex flex-col p-4">
          <Text className="pb-2 text-xs font-semibold">
            No templates were found matching your query.
          </Text>
          <StyledSeparator xMargin={0} />
          <Text className="pt-2 text-xs">
            Try searching for different words that mean the same thing.
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col h-full overflow-y-scroll w-[${TEMPLATES_MENU_WIDTH}]`}
    >
      {selectedFolder ? (
        <TemplatesList />
      ) : (
        <div>
          {folders.map(folder => (
            <div key={folder.id}>
              <TemplateFolderListItem key={folder.id} folder={folder} />
              <StyledSeparator />
            </div>
          ))}
          {templates?.map(template => (
            <TemplatesListItem template={template} key={template.id} isSearch />
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchResults;
