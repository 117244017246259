import React from 'react';

import { SMART_PRIORITY_CUTOFFS } from 'Modules/ActionsRewrite/components/customCells/SmartPriority/constants';
import DefaultChip from 'Modules/ActionsRewrite/components/customCells/SmartPriority/SmartPriorityChip/DefaultChip';
import HighChip from 'Modules/ActionsRewrite/components/customCells/SmartPriority/SmartPriorityChip/HighChip';
import LowChip from 'Modules/ActionsRewrite/components/customCells/SmartPriority/SmartPriorityChip/LowChip';
import MediumChip from 'Modules/ActionsRewrite/components/customCells/SmartPriority/SmartPriorityChip/MediumChip';

type SmartPriorityChipFactoryParams = {
  smartPriority: number;
  actionId: number;
};

const SmartPriorityChipFactory = ({
  smartPriority,
  actionId,
}: SmartPriorityChipFactoryParams) => {
  const hasNoSmartPriority = !smartPriority && smartPriority !== 0;
  if (hasNoSmartPriority || !actionId) return <DefaultChip />;
  if (smartPriority >= SMART_PRIORITY_CUTOFFS.HIGH) return <HighChip />;
  if (smartPriority >= SMART_PRIORITY_CUTOFFS.MEDIUM) return <MediumChip />;
  if (smartPriority >= SMART_PRIORITY_CUTOFFS.LOW) return <LowChip />;

  return <DefaultChip />;
};

export default SmartPriorityChipFactory;
