import { QueryClient, useQuery, UseQueryResult } from 'react-query';

import {
  ApiAdditionalLoggingFields,
  apiEmailAlias,
  ApiOrgSettings,
  ApiUser,
  getCurrentUser,
  getEmailAliases,
  getOrgSettings,
  userAdditionalLoggingFields,
} from '../gateway/v1/users';

export const useGetOrgSettings = (): UseQueryResult<
  ApiOrgSettings,
  unknown
> => {
  return useQuery('gatewayUserOrgSettings', getOrgSettings, {
    cacheTime: 1000 * 2,
    staleTime: 1000 * 2,
  });
};

export const fetchOrgSettings = (
  client: QueryClient,
): Promise<ApiOrgSettings> =>
  client.fetchQuery('gatewayUserOrgSettings', getOrgSettings, {
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 10,
  });

export const useGetCurrentUser = (): UseQueryResult<ApiUser, unknown> => {
  return useQuery('gatewayCurrentUser', getCurrentUser, {
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 10,
  });
};

export const fetchCurrentUser = (client: QueryClient): Promise<ApiUser> =>
  client.fetchQuery('gatewayCurrentUser', getCurrentUser, {
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 10,
  });

export const useGetEmailAliases = (): UseQueryResult<
  apiEmailAlias,
  unknown
> => {
  return useQuery('emailAliases', getEmailAliases, {
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 10,
  });
};

export const useUserAdditionalLoggingFields = (): UseQueryResult<
  ApiAdditionalLoggingFields,
  unknown
> =>
  useQuery('userAdditionalLoggingFields', userAdditionalLoggingFields, {
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 10,
  });

export const fetchAdditionalLoggingFields = (
  client: QueryClient,
): Promise<ApiAdditionalLoggingFields> =>
  client.fetchQuery(
    'userAdditionalLoggingFields',
    userAdditionalLoggingFields,
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
    },
  );
