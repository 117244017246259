import { omitBy, isUndefined, isNumber } from 'lodash-es';
import { STEP_SUBTYPES } from 'Modules/Shared/constants';

/**
 * Prepares data to be sent to the backend.
 * Return a mapping of reducer/front end keys to backend keys.
 * Meant to be used in conjunction with steps/create and steps/update in GrooveHTTPClient
 *
 * @param {number} flowId
 * @param {object} data
 *
 * @return {object}
 * */
export const stepAddEditMapPayload = (flowId, data) => {
  let overrideTemplate;

  if (isUndefined(data.overrideTemplate)) {
    // When editing a step or template, this value wont be present.
    // In this case, we manually set it to `true`, since we want to update templates in those scenarios.
    overrideTemplate = true;
  } else {
    // When creating a step, this checkbox is present, allowing the user to make the decision
    // of whether or not to override the template. In this case, we can rely on the user-provided value.
    overrideTemplate = data.overrideTemplate;
  }

  const STEP_TYPE_VALUE_MAP = {
    email: 'TEMPLATE',
    'auto-send-email': 'TEMPLATE',
    call: 'CALL',
    other: 'OTHER',
  };
  const stepTypeIsMapped = {}.hasOwnProperty.call(
    STEP_TYPE_VALUE_MAP,
    data.stepType
  );
  const stepTypeValue = stepTypeIsMapped
    ? STEP_TYPE_VALUE_MAP[data.stepType]
    : data.stepType;

  const stepData = {
    // step_type and step_subtype come from stepType in the reducer
    step_type: {}.hasOwnProperty.call(STEP_SUBTYPES, stepTypeValue)
      ? 'OTHER'
      : stepTypeValue,
    step_subtype: {}.hasOwnProperty.call(STEP_SUBTYPES, data.stepType)
      ? STEP_SUBTYPES[data.stepType]
      : null,
    day_number: data.dayNumber,
    priority: data.priorityType,
    // Backend uses `none` string to determine if its template-less step.
    template_id: overrideTemplate ? data.templateId : 'none',
    keep_gmail_thread_id: data.keepGmailThreadId,
    auto_trigger_hour: isNumber(data.autoTriggerHour)
      ? data.autoTriggerHour
      : Number(data.autoTriggerHour),
    text_body: data.textBody,
    subject: data.templatelessSubject || data.templateSubject,
    // For templateless steps, is_link_tracking_on is stored on the step. Include it in payload
    is_link_tracking_on: data.trackLink,
    html_body:
      data.htmlBody && data.htmlBody.length > 0 ? data.htmlBody : undefined,
    other_users_can_override_template: data.otherUsersCanOverrideTemplate,
    disable_personalization: data.disablePersonalization || false,
  };

  const templateData = {
    template_id: data.templateId,
    template_folder_id: data.templateFolder,
    name: data.templateName,
    subject: data.templateSubject,
    is_link_tracking_on: data.trackLink,
    template_type: data.templateType,
    template_type_value: data.templateTypeValue,
    html_body:
      data.htmlBody && data.htmlBody.length > 0 ? data.htmlBody : undefined,
  };

  return {
    flow_id: flowId,
    step: omitBy(stepData, isUndefined),
    template: omitBy(templateData, isUndefined),
  };
};

export const templateMapPayload = data => {
  const templateData = {
    template_id: data.templateId,
    template_folder_id: data.templateFolder,
    name: data.templateName,
    subject: data.templateSubject,
    is_link_tracking_on: data.trackLink,
    template_type: data.templateType,
    template_type_value: data.templateTypeValue,
    html_body:
      data.htmlBody && data.htmlBody.length > 0 ? data.htmlBody : undefined,
  };

  return {
    template: omitBy(templateData, isUndefined),
  };
};
