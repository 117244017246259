import Fade from '@material-ui/core/Fade';
import CurrentTabZeroActions from 'Modules/ActionsRewrite/components/tableTabs/zeroState/CurrentTabZeroActions';

import { Table, useTable } from '@clari/iris-table';
import React from 'react';
import { ActionComposeEnum } from '@groove/action-compose/constants';
import ActionCompose from '@groove/action-compose';
import useGetActionsInfinitely from 'Modules/ActionsRewrite/hooks/useGetActionsInfinitely';
import useBuildDefaultColumnOrder from 'Modules/ActionsRewrite/components/columnDefs/useBuildDefaultColumnOrder';
import useColumnDefinitionsBuilder from 'Modules/ActionsRewrite/components/columnDefs/useColumnDefinitionsBuilder';
import useStore from 'Modules/ActionsRewrite/store/useStore';
import LoadingTable from 'Modules/ActionsRewrite/components/LoadingTable';
// TODO: rename below file...
import useActionFilterStore from '@groove/ui/Components/ActionFilter/store/useStore';
import useActionComposeStore from '@groove/action-compose/store/useStore';
import { NEW_ACTION_ID } from '@groove/api/gateway/v1/actionCompose';
import { loadAction } from '@groove/action-compose/utils/actionComposeHelpers';

import '@clari/iris-react/style.css';
import '@clari/iris-table/style.css';

const ActionsTable: React.FC = () => {
  const defaultColumnOrder = useBuildDefaultColumnOrder();
  const columnDefinitionsBuilder =
    useColumnDefinitionsBuilder(defaultColumnOrder);
  const [order, setOrder] = React.useState(null);
  const selectedFilters = useActionFilterStore(store => store.selectedFilters);
  const hasNoSelectedFilters = Object.keys(selectedFilters || {}).length === 0;
  const [rowData, setRowData] = React.useState([]);
  const currentActionId = useActionComposeStore(state => state.action?.id);
  const { table } = useTable('iris-table-1');

  const activeTab = useStore(store => store.activeTab);
  const {
    data: actionPages,
    fetchNextPage,
    isLoading,
  } = useGetActionsInfinitely(
    columnDefinitionsBuilder.columnsQueryKey,
    activeTab,
    order,
    selectedFilters
  );
  const actions = actionPages?.pages?.flatMap(actionPage => actionPage.data);

  React.useEffect(() => {
    if (actions) {
      setRowData(actions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions?.length, activeTab]);

  const handleSort = sortModel => {
    const sortData = sortModel.columnApi
      .getColumnState()
      .find(col => Boolean(col.sort));

    if (sortData?.sortIndex) {
      const direction = sortData.sort;
      const field = sortData.colId;
      setOrder({ direction, field });
    } else {
      setOrder(null);
    }
  };

  const getColumnDefinitions = () => {
    return columnDefinitionsBuilder.columnDefinitions.map(column =>
      column.field === order?.field
        ? { ...column, sort: order?.direction }
        : column
    );
  };

  const handleOnComplete = () => {
    let nextActionId;
    if (currentActionId === NEW_ACTION_ID) {
      nextActionId = actions[0]?.id;
    } else {
      const actionIndex = actions.findIndex(
        action => action.id.toString() === currentActionId
      );
      nextActionId = actions[actionIndex + 1]?.id;
    }
    if (nextActionId) {
      loadAction({ id: nextActionId.toString() });
      handleSelectRow(nextActionId);
    }
  };

  const handleSelectRow = nextActionId => {
    const gridApi = table?.api;
    if (!gridApi) return;
    gridApi.forEachNode(node => {
      if (node.data?.id === Number(nextActionId)) {
        node.setSelected(true, true);
      }
    });
  };

  return (
    <>
      <div className="flex-1 w-auto">
        {isLoading && <LoadingTable />}
        <Fade in={!isLoading} timeout={250}>
          {!actions?.length && hasNoSelectedFilters ? (
            <CurrentTabZeroActions />
          ) : (
            <Table
              rowSelection="single"
              onSortChanged={handleSort}
              loadingCellRenderer={() => <span>Loading</span>}
              id="iris-table-1"
              rowData={rowData}
              loadingOverlayComponent={LoadingTable}
              suppressScrollOnNewData // To prevent jumping to the top upon receiving new data
              onBodyScrollEnd={event => {
                if (event.direction === 'vertical') fetchNextPage();
              }}
              defaultColDef={{
                cellStyle: params => ({
                  display: 'flex',
                  alignItems: 'center',
                }),
              }}
              suppressFieldDotNotation={false} // To enable accessing nested properties via `.`
              columnDefs={getColumnDefinitions()}
            />
          )}
        </Fade>
      </div>
      <div
        className="absolute right-[50px] bottom-0 "
        style={{
          boxShadow:
            'rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px',
        }}
      >
        <ActionCompose
          showHeader
          initialState={ActionComposeEnum.MINIMIZED}
          onComplete={handleOnComplete}
        />
      </div>
    </>
  );
};

export default ActionsTable;
