import { QueryClient, useQuery, UseQueryResult } from 'react-query';

import {
  ApiBestLocalNumber,
  ApiCheckResult,
  BestLocalNumberParams,
  checkNumber,
  getBestLocalNumber,
} from '../gateway/v1/commonDialer';

export const useBestLocalNumber = (
  params: BestLocalNumberParams,
  enabled?: boolean,
): UseQueryResult<ApiBestLocalNumber, unknown> => {
  return useQuery(
    ['useBestLocalNumber', params.toPhoneNumber, params.distance],
    () => getBestLocalNumber(params),
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
      enabled,
    },
  );
};

export const fetchBestLocalNumber = (
  client: QueryClient,
  params: BestLocalNumberParams,
): Promise<ApiBestLocalNumber> =>
  client.fetchQuery(
    ['useBestLocalNumber', params.toPhoneNumber, params.distance],
    () => getBestLocalNumber(params),
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
    },
  );

export const useCheckNumber = (
  phoneNumber: string,
  enabled?: boolean,
): UseQueryResult<ApiCheckResult, unknown> => {
  return useQuery(
    ['useCheckNumber', phoneNumber],
    () => checkNumber(phoneNumber),
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
      enabled,
    },
  );
};

export const fetchCheckNumber = (
  client: QueryClient,
  phoneNumber: string,
): Promise<ApiBestLocalNumber> =>
  client.fetchQuery(
    ['useCheckNumber', phoneNumber],
    () => checkNumber(phoneNumber),
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
    },
  );
