import { FC } from 'react';
import Text from '@groove/ui/Components/Text';

import useStore from '../store/useStore';
import WEditor from '../component/WEditor';

const GeneralTaskPane: FC = () => {
  const body = useStore(store => store.action.body);
  const id = useStore(store => store.action.id);

  return (
    <div
      data-testid="general-task-pane"
      className="flex-1 pr-[12px] py-[12px] pl-[20px] flex flex-col min-w-0"
    >
      <Text variant="body-sm" className="mb-[4px] font-semibold">
        Task Body
      </Text>
      <WEditor
        onChange={body => useStore.getState().updateAction({ body })}
        initialValue={body || ''}
        id={id}
      />
    </div>
  );
};

export default GeneralTaskPane;
