/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FixedSizeList, VariableSizeList } from 'react-window';
import cx from 'classnames';

import {
  DropDownBaseProps,
  DropDownFixed,
  DropDownFrameProps,
  DropDownVariable,
} from './DropDownFrame';

// This functional overload is to make sure that users can only use one typing in the Union
function MultiSelectDropDown<T extends any>(
  props: DropDownBaseProps<T> & DropDownVariable,
): ReturnType<FC<DropDownBaseProps<T> & DropDownVariable>>;
function MultiSelectDropDown<T extends any>(
  props: DropDownBaseProps<T> & DropDownFixed,
): ReturnType<FC<DropDownBaseProps<T> & DropDownFixed>>;
function MultiSelectDropDown<T extends any>(
  props: DropDownFrameProps<T>,
): ReturnType<FC<DropDownFrameProps<T>>> {
  const {
    showDropDown,
    itemList,
    renderedItem,
    maxHeight = 300,
    width = '100%',
    direction = 'bottomLeft',
    itemData,
    isVariable,
    innerRef,
    children,
    listFooter,
  } = props;

  const childrenRef = useRef<HTMLDivElement>(null);

  const [childrenHeight, setChildrenHeight] = useState(0);
  const [initialized, setInitialized] = useState(false);

  let xDirection = 'left-0';
  let yDirectionOuter = 'bottom-0';
  let yDirectionInner = 'top-0';

  if (direction === 'bottomRight') {
    xDirection = 'right-0';
  } else if (direction === 'topLeft') {
    yDirectionOuter = 'top-0';
    yDirectionInner = 'bottom-0';
  } else if (direction === 'topRight') {
    xDirection = 'right-0';
    yDirectionOuter = 'top-0';
    yDirectionInner = 'bottom-0';
  }

  const calcChildrenHeight = (): void =>
    setChildrenHeight(childrenRef.current?.clientHeight || 0);

  // ResizeError is a known Chrome issue that isn't a problem: https://stackoverflow.com/questions/63653605/resizeobserver-loop-limit-exceeded-api-is-never-used
  // TODO: We want to remove this with a better solution
  const ignoreResizeError = (error: ErrorEvent): void => {
    if (
      error.message ===
      'ResizeObserver loop completed with undelivered notifications.'
    ) {
      console.log(error);
      // prevent React's listener from firing
      error.stopImmediatePropagation();
      // prevent the browser's console error message
      error.preventDefault();
    }
  };

  useEffect(() => {
    calcChildrenHeight();
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!initialized) return;

    const element = childrenRef?.current;

    if (!element) return;

    const observer = new ResizeObserver(calcChildrenHeight);
    observer.observe(element);
    window.addEventListener('error', ignoreResizeError);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
      window.removeEventListener('error', ignoreResizeError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenRef, initialized]);

  if (isVariable) {
    const { itemSize } = props;
    let calcListHeight = 0;
    itemList.forEach((_, index) => (calcListHeight += itemSize(index)));
    const listHeight = Math.min(calcListHeight, maxHeight - childrenHeight);
    const totalHeight = Math.min(calcListHeight + childrenHeight, maxHeight);

    return (
      <div className={cx('h-0 absolute w-full', xDirection, yDirectionOuter)}>
        <motion.div
          className={cx(
            'absolute z-50 bg-white border-0.5 border-gray-300 rounded drop-shadow-md w-full',
            xDirection,
            yDirectionInner,
            !showDropDown && 'overflow-hidden',
          )}
          animate={{
            opacity: showDropDown ? 1 : 0,
            y: showDropDown ? 0 : -10,
            width,
            height: showDropDown ? totalHeight : 0,
          }}
          initial={{ opacity: 0, y: -10, width: 0, height: 0 }}
          transition={{ duration: 0.15 }}
        >
          <div ref={childrenRef}>{children}</div>
          <VariableSizeList
            className="overflow-y-auto"
            height={listHeight}
            width="100%"
            overscanCount={20}
            itemSize={itemSize}
            ref={innerRef}
            itemCount={itemList.length}
            itemData={itemData}
          >
            {renderedItem}
          </VariableSizeList>
          <div>{listFooter}</div>
        </motion.div>
      </div>
    );
  }

  const { lineHeight = 40 } = props;

  const listHeight = Math.min(
    (itemList.length + 0.3) * lineHeight,
    maxHeight - childrenHeight,
  );
  const totalHeight = Math.min(
    (itemList.length + 0.3) * lineHeight + childrenHeight,
    maxHeight,
  );
  return (
    <div className={cx('h-0 absolute w-full', xDirection, yDirectionOuter)}>
      <motion.div
        className={cx(
          'absolute z-50 bg-white border-0.5 border-gray-300 rounded drop-shadow-md w-full',
          xDirection,
          yDirectionInner,
          !showDropDown && 'overflow-hidden',
        )}
        animate={{
          opacity: showDropDown ? 1 : 0,
          y: showDropDown ? 0 : -10,
          width,
          height: showDropDown ? totalHeight : 0,
        }}
        initial={{ opacity: 0, y: -10, width: 0, height: 0 }}
        transition={{ duration: 0.15 }}
      >
        <div ref={childrenRef}>{children}</div>
        <FixedSizeList
          className="overflow-y-auto"
          height={listHeight}
          width="100%"
          overscanCount={20}
          itemSize={lineHeight}
          itemCount={itemList.length}
          itemData={itemData}
          ref={innerRef}
        >
          {renderedItem}
        </FixedSizeList>
        {listFooter}
      </motion.div>
    </div>
  );
}

export default MultiSelectDropDown;
