import { createActions, keyMirror } from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SHARED: {
    REMAINING_EMAIL_SEND_QUOTA: keyMirror([
      'START_INTERVAL_CHECK',
      'STOP_INTERVAL_CHECK',
      'IGNORE_WARNING',
    ]),
  },
}).SHARED.REMAINING_EMAIL_SEND_QUOTA;

export const startIntervalCheck = () => ({
  type: actionTypes.START_INTERVAL_CHECK,
});

export const stopIntervalCheck = () => ({
  type: actionTypes.STOP_INTERVAL_CHECK,
});

export const ignoreWarning = () => ({
  type: actionTypes.IGNORE_WARNING,
});
