import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NoTrackingActivitySvg from 'Modules/Emails/components/NoTrackingActivitySvg';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 7,
  },
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 3,
    fontSize: 18,
    fontWeight: 500,
  },
  secondaryText: {
    width: 297,
    textAlign: 'center',
  },
});

const NoResults = ({ classes }) => {
  return (
    <div className={classes.root}>
      <NoTrackingActivitySvg />
      <Typography className={classes.text}>No Tracking Activity</Typography>
      <Typography className={classes.secondaryText}>
        Make sure “Track email activity” is on when you send emails to see when
        they have activity
      </Typography>
    </div>
  );
};

NoResults.displayName = 'NoResults';
NoResults.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(NoResults);
