import FuzzySearch from 'fuzzy-search';
import React, { FC, Fragment } from 'react';
import { Typography } from '@material-ui/core';

import MergeFieldMenuItem from './MergeFieldMenuItem';

import { MergeField, MergeFieldGroup } from '.';

interface MergeFieldSearchResultsProps {
  mergeFields: MergeFieldGroup[];
  onSelectMergeField: (mergeField: MergeField) => void;
  value: string;
}

const MergeFieldSearchResults: FC<MergeFieldSearchResultsProps> = ({
  mergeFields,
  onSelectMergeField,
  value,
}) => {
  const filteredMergeFields = mergeFields.map(section => ({
    ...section,
    options: new FuzzySearch(section.options, ['name', 'label'], {
      caseSensitive: false,
    }).search(value),
  }));

  return (
    <>
      {filteredMergeFields.map(section => (
        <Fragment key={section.label}>
          {section.options.length > 0 && (
            <Typography variant="caption">{section.label}</Typography>
          )}
          {section.options.map(mergeField => (
            <MergeFieldMenuItem
              onSelect={onSelectMergeField}
              key={mergeField.name}
              mergeField={mergeField}
            />
          ))}
        </Fragment>
      ))}
    </>
  );
};

export default MergeFieldSearchResults;
