import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { templateFolderUnstar } from '../services/v1/templateFolder';

export type UseTemplateFolderUnstarParams = {
  templateFolderId: number;
};

const useTemplateUnstar = (): UseMutationResult<
  unknown,
  unknown,
  UseTemplateFolderUnstarParams,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ templateFolderId }) => templateFolderUnstar(templateFolderId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('favoriteTemplateFoldersGet');
      },
    },
  );
};

export default useTemplateUnstar;
