import React, { Fragment } from 'react';
import LoaderRow from 'Modules/Emails/components/LoaderRow';
import shortId from 'shortid';
import { Divider } from '@material-ui/core';

const rows = [...Array(10)].fill(null).map(shortId.generate);

const Loader = () => {
  return (
    <div>
      {rows.map(value => (
        <Fragment key={value}>
          <LoaderRow />
          <Divider />
        </Fragment>
      ))}
    </div>
  );
};

export default Loader;
