import { List, Record } from 'immutable';
import { PRIORITIES } from 'Modules/Shared/constants';

const StepSchema = {
  peopleAtStepCount: undefined,
  nameWithDayCount: '',
  dayNumber: undefined,
  stepType: '',
  stepSubtype: '',
  name: '',
  subject: null,
  priority: PRIORITIES.NONE,
  htmlBody: null,
  subjectLine: null,
  textBody: null,
  intraDayOrder: undefined,
  actionsDueCount: undefined,
  currentCount: undefined,
  failedCount: undefined,
  failedAndRecoverableCount: undefined,
  failedAndUnrecoverableCount: undefined,
  flowId: undefined,
  autoTriggerHour: null,
  template: undefined, // Immutable Map
  templateOverride: undefined,
  isLinkTrackingOn: null,
  keepGmailThreadId: null,
  id: undefined,
  updatingPersonFlowsInBackground: false,
  managedBy: null, // will be null unless it's in a master flow you have access to.
  variants: List(),
  otherUsersCanOverrideTemplate: null,
  disablePersonalization: false,
};

export default class Step extends Record(StepSchema, 'Step') {
  /**
   * Determines is a step is template-less
   * @return {Boolean} Returns true if Step is a templateless
   */
  isTemplateless() {
    return !this.template;
  }

  hasTemplateOverride() {
    return !!this.templateOverride;
  }

  /**
   * Determines if a step is persisted
   * @return {Boolean} [description]
   */
  isPersisted() {
    return !!this.id;
  }

  hasActiveVariants() {
    return this.variants.findIndex(variant => variant.isActive()) >= 0;
  }
}
