import { LDUser } from 'launchdarkly-js-client-sdk';
import { useQuery } from 'react-query';
import { useEffect } from 'react';

import { isClientConfigured } from '../../gateway/v1/getClient';
import { getLaunchDarklyUser } from '../../gateway/v1/users';
import {
  LAUNCH_DARKLY_CACHE_TIME,
  LAUNCH_DARKLY_STALE_TIME,
} from '../../utils/cacheSettings';

import useStore from './useStore';

/**
 * Request the launch darkly data of the current user. and uses it to identify the launchdarkly singleton
 *
 * @remarks
 * In order for Launch Darkly to properly target users, it must consistently
 * receive the same initialization parameters. If, for example, the LDClient is
 * initialized with different custom attributes, the system will not properly
 * target that user. An endpoint in groove engine was created to ensure all
 * LDClients can be initialized with the same information.
 *
 */
const useGatewayLaunchDarkly = (): void => {
  const { data: ldUserData } = useQuery('ld-user', getLaunchDarklyUser, {
    enabled: isClientConfigured(),
    cacheTime: LAUNCH_DARKLY_CACHE_TIME,
    staleTime: LAUNCH_DARKLY_STALE_TIME,
  });
  const initializeLDClient = useStore(state => state.initializeLDClient);
  const { ldClient } = useStore.getState();

  useEffect(() => {
    if (ldUserData && !ldClient) {
      const user: LDUser = ldUserData;
      initializeLDClient(user);
    }
  }, [ldUserData, initializeLDClient, ldClient]);
};

export default useGatewayLaunchDarkly;
