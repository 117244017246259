import { Separator, Shimmer, ShimmerElementType } from '@fluentui/react';
import { FC } from 'react';

const TemplateItemLoader: FC = key => (
  <>
    <Shimmer
      shimmerElements={[
        { type: ShimmerElementType.gap, width: '5%' },
        { type: ShimmerElementType.line, width: '50%', height: 10 },
        { type: ShimmerElementType.gap, width: '25%' },
        { type: ShimmerElementType.line, width: '15%', height: 20 },
        { type: ShimmerElementType.gap, width: '5%' },
      ]}
    />
    <Shimmer
      shimmerElements={[
        { type: ShimmerElementType.gap, width: '5%' },
        { type: ShimmerElementType.line, width: '70%', height: 25 },
        { type: ShimmerElementType.gap, width: '5%' },
        { type: ShimmerElementType.line, width: '15%', height: 30 },
        { type: ShimmerElementType.gap, width: '5%' },
      ]}
    />
    <Separator />
  </>
);

const TemplateListLoader: FC = () => {
  return (
    <div aria-label="Loading templates" className="mt-4">
      {Array.from({ length: 3 }, (_v, templateNumber) => (
        <TemplateItemLoader key={templateNumber} />
      ))}
    </div>
  );
};

export default TemplateListLoader;
