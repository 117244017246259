import { call, select, put, all, spawn } from 'redux-saga/effects';
import HTTPError from 'GrooveHTTPClient/HTTPError';
import HttpStatusCodes from 'http-status-codes';
import { actions } from '@groove-labs/groove-ui';
import { setContentLoading } from 'Modules/Shared/actions/app';
import { isLoggedIn as isLoggedInSelector } from 'Modules/Shared/selectors/users';
import { fetchFeatureFlags } from 'GrooveHTTPClient/featureFlags';
import { show as getSalesforceMeta } from 'GrooveHTTPClient/salesforceMeta';
import {
  getActivityOptions,
  getCurrentUser,
  getUserOrgSettings,
} from 'GrooveHTTPClient/users';
import { actionTypes as appActionTypes, ready } from 'Modules/App/actions';
import {
  addGlobalSetting,
  setCurrentUser,
  loginSuccess,
} from 'Modules/Shared/actions/users';
import { setFeatureFlags } from 'Modules/Shared/actions/featureFlags';
import { setSalesforceMeta } from 'Modules/Shared/actions/salesforceMeta';
import setupAnalytics from 'Modules/App/sagas/upstarts/helpers/setupAnalytics';
import handleSetAuthToken from 'Modules/App/sagas/upstarts/helpers/handleSetAuthToken';
import assembleSetCurrentUserPayload from 'Modules/App/sagas/upstarts/helpers/assembleSetCurrentUserPayload';
import {
  DISPLAY_GROOVE_CONNECTION_ERROR_UI_KEY_PATH,
  POST_MESSAGE_OUTLOOK_SOURCE,
} from 'Modules/ActionCompose/constants';
import sendOutgoingAction from 'Modules/ActionCompose/sagas/sendOutgoingAction';
import { actionTypes as outgoingActionTypes } from 'Modules/ActionCompose/actions/outgoing';
import { logErrorToSentry } from 'Modules/Shared/sagas/errors';
import { initialize as initializeLdClient } from 'Utils/ldClient';
import launchdarkly from 'Modules/App/sagas/launchdarkly';
import fixCsrfToken from 'Modules/App/sagas/upstarts/helpers/fixCsrfToken';
import getProductUsageData from 'Modules/Shared/sagas/productUsage';
import { fetchSettings as fetchDialerSettings } from 'Modules/Shared/actions/dialer';
import resolveOutlookAddInExchangeToken from './outlookActionComposeUpstart';
import { location as locationSelector } from 'Modules/Shared/selectors/location';

const { setProperty } = actions.ui;

function* actionComposeUpstart(options = { skipContentLoading: false }) {
  try {
    // when the source of the request is outlook desktop we will not have any information
    // about the current user so we need to refetech the user using microsoft exchange token
    const location = yield select(locationSelector);
    const source = location.getIn(['searchParams', 'source']);

    if (source === POST_MESSAGE_OUTLOOK_SOURCE) {
      yield call(resolveOutlookAddInExchangeToken);
    }
    yield call(handleSetAuthToken);

    yield call(fixCsrfToken);

    const isLoggedIn = yield select(state => isLoggedInSelector(state));
    if (!isLoggedIn) {
      yield put(
        setProperty({
          uiKeyPath: DISPLAY_GROOVE_CONNECTION_ERROR_UI_KEY_PATH,
          data: true,
        })
      );
      yield call(sendOutgoingAction, { type: outgoingActionTypes.READY });
      yield put(ready());
      yield put({ type: appActionTypes.UPSTART.SUCCESS });
      return;
    }

    const shouldSetContentLoading = !options.skipContentLoading;
    if (shouldSetContentLoading) {
      yield put(setContentLoading(true));
    }
    const [
      currentUserResponse,
      userOrgSettingsResponse,
      featureFlagsResponse,
      salesforceMetaResponse,
      activityOptionsResponse,
    ] = yield all([
      call(getCurrentUser),
      call(getUserOrgSettings),
      call(fetchFeatureFlags),
      call(getSalesforceMeta),
      call(getActivityOptions),
    ]);

    const currentUser = currentUserResponse.updateIn(
      ['message', 'data', 'orgSettings'],
      m => m.merge(userOrgSettingsResponse.data)
    );

    yield call(getProductUsageData);
    yield call(initializeLdClient);
    yield spawn(launchdarkly);
    yield put(fetchDialerSettings());

    // setup Sentry & segment & logrocket & splunk
    yield call(setupAnalytics, currentUser.data);

    yield all([
      put(
        setCurrentUser(
          assembleSetCurrentUserPayload({ currentUserResponse: currentUser })
        )
      ),
      put(setFeatureFlags(featureFlagsResponse.data)),
      put(setSalesforceMeta(salesforceMetaResponse.data)),
      put(
        addGlobalSetting({
          setting: 'activityResults',
          value: activityOptionsResponse.data.activityResults,
        })
      ),
      put(
        addGlobalSetting({
          setting: 'templateTypes',
          value: activityOptionsResponse.data.templateTypes,
        })
      ),
    ]);

    yield put(loginSuccess());
    yield put(ready());
    yield put({ type: appActionTypes.UPSTART.SUCCESS });
  } catch (e) {
    const isUnauthorizedHTTPError =
      e instanceof HTTPError &&
      e.response.status === HttpStatusCodes.UNAUTHORIZED;
    if (isUnauthorizedHTTPError) {
      yield put(
        setProperty({
          uiKeyPath: DISPLAY_GROOVE_CONNECTION_ERROR_UI_KEY_PATH,
          data: true,
        })
      );
    } else {
      logErrorToSentry(e);
    }
    yield put({ type: appActionTypes.UPSTART.SUCCESS });
  }
}

export default actionComposeUpstart;
