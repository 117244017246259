import { isNull, isArray, isEmpty } from 'lodash-es';

/**
 * Constructs an enumeration with keys equal to their value.
 *
 * For example:
 *
 *   var COLORS = keyMirror({blue: null, red: null});
 *   var myColor = COLORS.blue;
 *   var isColorValid = !!COLORS[myColor];
 *
 * The last line could not be performed if the values of the generated enum were
 * not equal to their keys.
 *
 *   Input:  {key1: val1, key2: val2}
 *   Output: {key1: key1, key2: key2}
 *
 * Example with a namespace:
 *
 *  var COLORS = keyMirror({blue: null, red: null}, 'COLORS')
 *
 *  Input:  {key1: val1, key2: val2}
 *  Output: {key1: COLORS/key1, key2: COLORS/key2}
 *
 *  var PLANETS = keyMirror({earth: null, mars: null}, 'SPACE', 'SOLAR_SYSTEM')
 *
 *  Input:  {earth: val1, mars: val2}
 *  Output: {earth: SPACE/SOLAR_SYSTEM/key1, mars: SPACE/SOLAR_SYSTEM/key2}
 *
 * @param {object} obj
 * @param {...string} namespace
 * @return {object}
 */
export default function keyMirror(obj, ...namespace) {
  const ret = {};

  if (!(obj instanceof Object && !isArray(obj))) {
    throw new Error('keyMirror(...): Argument must be an object.');
  }

  Object.keys(obj).forEach(key => {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      return;
    }

    if (isNull(obj[key])) {
      ret[key] = isEmpty(namespace) ? key : [...namespace, key].join('/');
    } else {
      ret[key] = obj[key];
    }
  });

  return ret;
}
