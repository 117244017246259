/**
 * Function converts 15 digit salesforceId to 18 digit one
 * and returns the 18 digit salesforce ID
 * @param {String} id
 *
 * @return {String} 18digitId
 */

const upperCaseAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
function isUppercase(c) {
  return new RegExp(c).test(upperCaseAlphabet);
}

export default function get18DigitId(id) {
  if (!id) return null;
  if (typeof id !== 'string') throw new Error("The given id isn't a string");
  if (id.length === 18) return id; // if we pass in 18 digit id give it back
  if (id.length !== 15)
    throw new Error("The given id isn't 15 characters long.");

  let i;
  let j;
  let flags;
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ012345';

  let processedId = id;

  for (i = 0; i < 3; i += 1) {
    flags = 0;
    for (j = 0; j < 5; j += 1) {
      if (isUppercase(id.charAt(i * 5 + j))) {
        flags += 1 << j; // eslint-disable-line no-bitwise
      }
    }
    processedId += alphabet.charAt(flags);
  }

  return processedId;
}
