import { decamelizeKeys } from 'humps';
import makeHTTPRequest from '../../makeHTTPRequest';
import { DELETE, PATCH, POST } from '../../HTTPMethod';

const BASE_PATH = '/api/v2/admin/automated_actions';

export const index = (triggerType = null) =>
  makeHTTPRequest({
    path: BASE_PATH,
    queryParameters: decamelizeKeys({
      triggerType,
    }),
  });

/**
 * @param {AutomatedAction} automatedAction
 * */
export const create = automatedAction =>
  makeHTTPRequest({
    path: BASE_PATH,
    method: POST,
    body: {
      automated_action: {
        ...decamelizeKeys(automatedAction.toJS()),
        id: null,
      },
    },
  });

/**
 * @param {AutomatedAction} automatedAction
 * */
export const update = automatedAction =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${automatedAction.id}`,
    method: PATCH,
    body: {
      automated_action: decamelizeKeys(automatedAction.toJS()),
    },
  });

/**
 * @param {Number} automatedActionId
 * */
export const destroy = automatedActionId =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${automatedActionId}`,
    method: DELETE,
  });
