import { Map, Set, fromJS } from 'immutable';
import { guessTimeZone } from 'Modules/WorkStep/utils';
import moment from 'moment';
import { capitalize } from 'lodash-es';

export const COMPANY_COLUMN_ID = 'company';

export const DEFAULT_PERSON_DATA_COLUMNS = fromJS([
  {
    id: COMPANY_COLUMN_ID,
    label: 'Company',
  },
]);

export const DEFAULT_PERSON_DATA_COLUMN_LABELS =
  DEFAULT_PERSON_DATA_COLUMNS.reduce(
    (columns, defaultColumnData) =>
      columns.set(defaultColumnData.get('id'), defaultColumnData.get('label')),
    Map()
  );

export const DEFAULT_PERSON_DATA_COLUMN_TYPES = fromJS({
  company: 'string',
  dueDate: 'date',
});

export const DEFAULT_TABLE_COLUMNS = fromJS([
  {
    id: 'removeIcon',
    label: '',
    style: {
      width: '25px',
      padding: '0px 0px',
    },
  },
  {
    id: 'hotlisted',
    label: '',
    sortable: false,
    style: {
      width: '25px',
      padding: '0px 0px',
    },
  },
  {
    id: 'alerts',
    label: 'Alerts',
    sortable: true,
    style: {
      width: '50px',
      padding: '0px 20px',
    },
  },
]);

export const DONT_SHOW_COLUMNS = [
  'convertedcontactid',
  'converteddate',
  'convertedopportunityid',
  'firstname',
  'hasoptedoutofemail',
  'id',
  'events',
  'tasks',
  'lastactivitydate',
  'lastname',
  'salutation',
];

export const EMAIL_COLUMN_ID = 'email';
export const EDITABLE_TYPES = ['string', 'phone', EMAIL_COLUMN_ID];

export const uiReducerInitialState = {
  workstepTableConfig: {
    orderDirection: 'asc',
    orderBy: null,
    selected: new Set(),
  },
};

export const TEMPLATE_TYPES = {
  EMAIL: 'template',
  AUTOEMAIL: 'template',
  CALL: 'call',
  DIRECT_MAIL: 'direct_mail',
  IN_PERSON_VISIT: 'in_person_visit',
  LINKEDIN_CONNECT: 'linkedin_connect',
  LINKEDIN_INMAIL: 'linkedin_inmail',
  OTHER: 'other',
  SENDOSO: 'sendoso',
  SMS: 'sms',
};

const DEFAULT_COLUMNS = [
  'title',
  'name',
  EMAIL_COLUMN_ID,
  COMPANY_COLUMN_ID,
  'phone',
  'mobilephone',
  'tzid',
];

export const DEFAULT_COLUMNS_FOR_STEP_TYPE = {
  [TEMPLATE_TYPES.EMAIL]: [
    'title',
    'name',
    EMAIL_COLUMN_ID,
    COMPANY_COLUMN_ID,
    'dueDate',
  ],
  [TEMPLATE_TYPES.AUTOEMAIL]: [
    'title',
    'name',
    EMAIL_COLUMN_ID,
    COMPANY_COLUMN_ID,
    'dueDate',
  ],
  [TEMPLATE_TYPES.CALL]: DEFAULT_COLUMNS,
  [TEMPLATE_TYPES.DIRECT_MAIL]: DEFAULT_COLUMNS,
  [TEMPLATE_TYPES.IN_PERSON_VISIT]: DEFAULT_COLUMNS,
  [TEMPLATE_TYPES.LINKEDIN_CONNECT]: DEFAULT_COLUMNS,
  [TEMPLATE_TYPES.LINKEDIN_INMAIL]: DEFAULT_COLUMNS,
  [TEMPLATE_TYPES.SENDOSO]: DEFAULT_COLUMNS,
  [TEMPLATE_TYPES.SMS]: DEFAULT_COLUMNS,
  [TEMPLATE_TYPES.OTHER]: DEFAULT_COLUMNS,
};

export const EMAIL_PREVIEW_GROUP_ID = 'workStepPreview';
export const CALL_LOGGER_GROUP_ID = 'callLogger';
export const WYSIWYG_DATA_UI_KEY_PATH = ['wysiwygData'];
export const CC_SHOW_UI_KEY_PATH = ['showCcField'];
export const BCC_SHOW_UI_KEY_PATH = ['showBccField'];
export const WORKSTEP_SUBMIT_DISABLED_KEY_PATH = ['submitDisabled'];
export const WORKSTEP_SKIP_DISABLED_KEY_PATH = ['skipDisabled'];
export const SHOW_EMAIL_PREVIEW_DIALOG_KEY_PATH = ['emailPreviewOpen'];
export const OUTDATED_WORKSTEPS_DIALOG_KEY_PATH = ['outdatedWorkstepData'];
export const SALESFORCE_ERROR_KEY_PATH = ['salesforceError'];

export const DEFAULT_SCHEDULED_SEND_VALUES = {
  dateTime: moment().add(1, 'hour').format(),
  date: moment().add(1, 'day').startOf('day').format('YYYY-MM-DD'),
  time: moment().startOf('day').format('h:mm a'),
  timeZone: guessTimeZone(),
};

export const SEND_EMAIL_LATER_WIDGET_ID = 'sendEmailLaterWidget';

export const CALL_OR_OTHER_STEP_LOGGER_MORE_OPTIONS = {
  LOG_AND_PAUSE: 'logAndPause',
  LOG_AND_REMOVE: 'logAndRemove',
  LOG_AND_MOVE_TO_FLOW: 'logAndMoveToFlow',
  LOG_AND_SKIP_ALL_CALL_STEP: 'logAndSkipAllCallStep',
  MARK_AS_COMPLETE_DONT_LOG: 'markAsCompleteDontLog',
  SKIP_THIS_STEP: 'skipThisStep',
  SKIP_ALL_CALL_STEPS: 'skipAllCallSteps',
};

export const CALL_OR_OTHER_STEP_SUCCESS_MESSAGES = {
  [CALL_OR_OTHER_STEP_LOGGER_MORE_OPTIONS.LOG_AND_PAUSE]: (
    activePerson,
    noun
  ) => `Logged ${noun} and paused ${activePerson.get('name')} from flow`,
  [CALL_OR_OTHER_STEP_LOGGER_MORE_OPTIONS.LOG_AND_REMOVE]: (
    activePerson,
    noun
  ) => `Logged ${noun} and removed ${activePerson.get('name')} from flow`,
  [CALL_OR_OTHER_STEP_LOGGER_MORE_OPTIONS.LOG_AND_MOVE_TO_FLOW]: (
    activePerson,
    noun
  ) => `Logged ${noun} and moved ${activePerson.get('name')} from flow`,
  [CALL_OR_OTHER_STEP_LOGGER_MORE_OPTIONS.LOG_AND_SKIP_ALL_CALL_STEP]: (
    activePerson,
    noun
  ) =>
    `Logged ${noun} and skipped all call steps for ${activePerson.get(
      'name'
    )} in flow`,
  [CALL_OR_OTHER_STEP_LOGGER_MORE_OPTIONS.MARK_AS_COMPLETE_DONT_LOG]:
    activePerson => `Marked ${activePerson.get('name')} as complete`,
  [CALL_OR_OTHER_STEP_LOGGER_MORE_OPTIONS.SKIP_THIS_STEP]: activePerson =>
    `Skipping this step for ${activePerson.get('name')}`,
};

export const CALL_STEP_DEFAULT_SUCCESS_MESSAGE = (activePerson, noun) =>
  `${capitalize(noun)} for ${activePerson.get('name')} logged`;

export const CALL_OR_OTHER_STEP_LOGGER_UI_KEY = 'callOrOtherStepLoggerData';

export const CALL_OR_OTHER_STEP_LOGGER_PAUSE_WIDGET_ID =
  'callOrOtherStepLoggerPauseTimeWidget';

export const CALL_STEP_LOGGER_PAUSE_DEFAULT_DATE_TIME_VALUES = [
  {
    value: moment().add(3, 'day').format('YYYY-MM-DD'),
    label: moment().add(3, 'day').format('dddd, MMMM Do YYYY'),
  },
  {
    value: moment().add(5, 'day').format('YYYY-MM-DD'),
    label: moment().add(5, 'day').format('dddd, MMMM Do YYYY'),
  },
  {
    value: moment().add(1, 'week').format('YYYY-MM-DD'),
    label: moment().add(1, 'week').format('dddd, MMMM Do YYYY'),
  },
  {
    value: moment().add(2, 'week').format('YYYY-MM-DD'),
    label: moment().add(2, 'week').format('dddd, MMMM Do YYYY'),
  },
  {
    value: moment().add(1, 'month').format('YYYY-MM-DD'),
    label: moment().add(1, 'month').format('dddd, MMMM Do YYYY'),
  },
];

export const DEFAULT_LIMIT = 50;

export const VISIBLE_OPTIONAL_COLUMNS_PATH = [
  'workStep',
  'visibleOptionalColumns',
];

export const WORKSTEP_SEND_BUTTON_MAXIMUM_DISABLE_DURATION = 3000;

export const WORKSTEP_FILTER_DELAY = 300;

export const DEFAULT_SCHEDULE_WIDGET_VALUES = {
  oneHour: '1 hour',
  fourHours: '4 hours',
  twelveHours: '12 hours',
  nextMorning: 'Next Morning',
  twoDays: 'Two days',
  threeDays: 'Three days',
  fourDays: 'Four days',
  fiveDays: 'Five days',
};

export const PREVIOUS_COMPLETED_VIEW_SCOPE = 'previousCompleted';

export const SMS_DATE_CREATED = 'dateCreated';

export const FAILED_REASONS = {
  CONTACT_NOT_FOUND: 'contact_not_found',
  DEACTIVATED_USER: 'deactivated_user',
  EMAIL_BOUNCED: 'email_bounced',
  GOOGLE_API_ERROR: 'google_api_error',
  INVALID_GOOGLE_TOKEN: 'invalid_google_token',
  INVALID_RECIPIENT: 'invalid_recipient',
  INVALID_SFDC_TOKEN: 'invalid_sfdc_token',
  MERGE_FIELD_ISSUE: 'merge_field_issue',
  OPTED_OUT: 'opted_out',
  UNKNOWN: 'unknown',
  USER_CANCELLED: 'user_cancelled',
};

export const INVALID_ACTION_TYPES = ['input-blur', 'menu-close'];

export const SEND_LATER_WIDGET_ID = 'sendAllWidget';
export const SEND_PARTIAL_WIDGET_ID = 'sendPartialWidget';
