import { useEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';

import incomingSms from '../assets/incoming_sms.mp3';
import pushMessageToChannelQueryData from '../utils/pushMessageToChannelQueryData';
import setChannelAttributesQueryData from '../utils/setChannelAttributesQueryData';
import useStore from '../hooks/useStore';
import cable from '../api/socket/cable';
import { SubscriptionType } from '../components/homeTypes';

const useSubscription = (): void => {
  const currentContext = useStore(store => store.currentContext);
  const queryClient = useQueryClient();
  const callState = useStore(store => store.callState);

  const sfdcIdInContext = useRef<string>();
  useEffect(() => {
    // Store the context in Ref so we can reference in the subscription without it being a dependency
    sfdcIdInContext.current = currentContext?.sfdcId || '';
  }, [currentContext?.sfdcId]);

  const currentCallState = useRef<string>();
  useEffect(() => {
    // Store the call State in a ref so we can reference it without it being a dependency for action-cable subscription
    currentCallState.current = callState;
  }, [callState]);

  useEffect(() => {
    const extensionSubscription =
      cable.subscriptions.create('ExtensionChannel');

    const {
      checkForUpdates,
      exitLog,
      setActiveChannelSid,
      setBridgeCallStatus,
      setConstantBridgeStatus,
      setCurrentContext,
      setInstantDialEligibleFlag,
      setShowVoicemailNotification,
      setTransferCallStatus,
    } = useStore.getState();

    const subscription = cable.subscriptions.create(
      {
        channel: 'DialerChannel',
      },
      {
        received: (message: SubscriptionType) => {
          extensionSubscription.send({ type: 'PING' });
          switch (message.type) {
            case 'EFFECTS/EXTERNAL/OPEN_DIALER': {
              useStore
                .getState()
                .ipcRenderer?.send('asynchronous-message', 'focus');
              break;
            }
            case 'FORCE_CHECK_FOR_UPDATES': {
              if (process.env.DISABLE_AUTO_UPDATES !== 'true') {
                checkForUpdates();
              }
              break;
            }
            case 'EFFECTS/EXTERNAL/ACTIONS/LOAD_ACTIONS_CALL_ACTION_ID': {
              useStore
                .getState()
                .ipcRenderer?.send('asynchronous-message', 'focus');

              if (currentCallState.current === 'connected') {
                break;
              }

              exitLog();
              setCurrentContext(context => {
                context.personStepId = null;
                context.sfdcId = message.payload.personSfdcId;
                context.actionId = message.payload.actionId;
                context.phoneNumber = '';
              });
              setInstantDialEligibleFlag(false);
              break;
            }
            case 'EFFECTS/EXTERNAL/CLICK_TO_CALL': {
              useStore
                .getState()
                .ipcRenderer?.send('asynchronous-message', 'focus');

              if (currentCallState.current === 'connected') {
                break;
              }
              // when a click to call event comes in we want to maintain state if the sfdcId of the event is the same as the one in context
              // in that case simply update the number and do nothing else
              if (
                sfdcIdInContext.current &&
                sfdcIdInContext.current === message.payload.sfdcId
              ) {
                setCurrentContext(context => {
                  context.phoneNumber = message.payload.toPhoneNumber;
                });
                return;
              }

              // set either sfdcAccountId or sfdcId based on whether it's an account sfdcId or not
              // but not both at the same time
              let contactOrLeadSfdcId: string | null = null;
              let sfdcAccountId: string | null = null;
              if (message.payload.sfdcId?.startsWith('001')) {
                sfdcAccountId = message.payload.sfdcId;
              } else {
                contactOrLeadSfdcId = message.payload.sfdcId;
              }

              exitLog();
              setCurrentContext(context => {
                context.personStepId = null;
                context.sfdcId = contactOrLeadSfdcId;
                context.actionId = null;
                context.sfdcAccountId = sfdcAccountId;
                context.step = null;
                context.phoneNumber = message.payload.toPhoneNumber;
                context.sfdcWhatId = message.payload.sfdcWhatId;
              });
              setInstantDialEligibleFlag(true);
              break;
            }
            case 'EFFECTS/EXTERNAL/FLOW/LOAD_WORK_STEP_DATA': {
              useStore
                .getState()
                .ipcRenderer?.send('asynchronous-message', 'focus');

              if (currentCallState.current === 'connected') {
                break;
              }

              exitLog();
              setCurrentContext(context => {
                context.personStepId =
                  message.payload.callActivity.person_step_id.toString();
                context.sfdcId = message.payload.personSfdcId;
                context.actionId = null;
                context.step = null;
                context.phoneNumber = message.payload.toPhoneNumber;
              });
              setInstantDialEligibleFlag(false);
              break;
            }

            case 'BRIDGE/CALL_STATUS_EVENT': {
              const {
                status_event,
                conference_friendly_name,
                is_groove_user_event,
              } = message.payload;
              setBridgeCallStatus(
                status_event,
                conference_friendly_name,
                is_groove_user_event,
              );
              break;
            }

            case 'CONSTANT_BRIDGE/CALL_STATUS_EVENT': {
              setConstantBridgeStatus(message.payload);
              break;
            }

            case 'BRIDGE/TARGET_STATUS_EVENT': {
              const { status_event, call_sid } = message.payload;
              setTransferCallStatus(status_event, call_sid);
              break;
            }

            case 'SMS_RECEIVED': {
              const notification = new Notification(
                `Message from ${message.payload.recipient_name}`,
                {
                  body: message.payload.message || '<no message>',
                  silent: true,
                },
              );
              const notificationSound = new Audio(incomingSms);
              notificationSound.play();

              notification.addEventListener('click', () => {
                setActiveChannelSid(message.payload.channel_sid);
              });
              pushMessageToChannelQueryData(
                queryClient,
                message.payload.channel_sid,
                message.payload.message,
                false,
                message.payload.attributes,
              );
              break;
            }

            case 'CHANNEL_LOGGED': {
              setChannelAttributesQueryData(
                queryClient,
                message.payload.channel_sid,
                { latestTaskId: message.payload.attributes.latestTaskId },
              );
              break;
            }

            case 'SETTINGS_UPDATED': {
              queryClient.invalidateQueries('settings');
              queryClient.invalidateQueries('settings-additional-numbers');
              break;
            }

            case 'VOICEMAIL_RECEIVED': {
              setShowVoicemailNotification(true);
              break;
            }

            default: {
              break;
            }
          }
        },
      },
    );
    return () => {
      subscription.unsubscribe();
      extensionSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSubscription;
