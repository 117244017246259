import { keyMirror } from '@groove-labs/action-utils';
import SingleSelectColumn from 'Modules/Spaces/show/records/SingleSelectColumn';
import TextFieldColumn from 'Modules/Spaces/show/records/TextFieldColumn';
import CustomColumnHeaderCell from 'Modules/Spaces/show/containers/AccountsTableWrapper/TableHeaderCells/CustomColumnCell';

// table constants
export const ROW_HEIGHT = 60;
export const TABLE_OVERSCAN = 7;

// account constants
export const MAX_COLUMNS_SELECTED = 15;
export const ACCOUNTS_SEARCH_GROUP_ID = 'showAccountsFilter';
export const ACCOUNTS_SEARCH_FIELD_ID = 'searchValue';
export const SEARCH_VISIBLE_KEY_PATH = ['spacesShow', 'searchIsVisible'];
export const MOVE_ACCOUNTS_VISIBLE_KEY_PATH = [
  'spacesShow',
  'moveAccountsIsVisible',
];
export const RENAME_WORKSPACE_DIALOG_KEY_PATH = [
  'spacesShow',
  'renameDialogIsVisible',
];
export const SHARE_USERS_DIALOG_KEY_PATH = [
  'spacesShow',
  'shareUsersDialogIsVisible',
];
export const EDIT_WORKSPACE_GROUP_ID = 'editWorkspace';
export const RENAME_WORKSPACE_FIELD_ID = 'newWorkspaceName';
export const EDIT_WORKSPACE_DESCRIPTION_FIELD_ID = 'editWorkspaceDescription';
export const MOVE_ACCOUNTS_GROUP_ID = 'moveAccounts';
export const SPACES_TASKS_KEY_PATH = ['spacesShow', 'tasks'];
export const REMOVE_CUSTOM_COLUMN_DIALOG_UI_KEYPATH = [
  'spacesShow',
  'removeCustomColumnConfirmDialogOpen',
];
export const WORKSPACE_DESCRIPTION_PLACEHOLDER =
  'Please enter a description, max 160 characters';

// task constants
export const NEW_TASK_GROUP_ID = 'newTaskGroup';
export const SALESFORCE_TASK_FIELD_ID = 'createSalesforceTask';
export const DESCRIPTION_FIELD_ID = 'taskDescription';
export const ASSIGNED_USER = 'assigneeId';
export const TASK_CREATOR = 'creatorId';
export const TASK_ACTION_DISPLAY_DATE_FORMAT = 'MMM DD';
export const IS_SALESFORCE_TASK_CHECKBOX_KEY_PATH = [
  'spacesShow',
  SALESFORCE_TASK_FIELD_ID,
];
export const ASSIGNED_TEAM_MEMBER_KEY_PATH = [
  'spacesShow',
  'assignedTeamMember',
];
export const PRIORITY_KEY_PATH = ['spacesShow', 'priority'];
export const DUE_DATE_KEY_PATH = ['spacesShow', 'dueDate'];
export const IS_TASK_DIALOG_VISIBLE_KEY_PATH = [
  'spacesShow',
  'isTaskDialogVisible',
];
export const ACCOUNT_TASK_DETAILS_KEY_PATH = [
  'spacesShow',
  'accountTaskDetails',
];

export const SORT_BY_KEY = {
  accountName: 'name',
  lastTouch: 'date',
  lastEngagement: 'date',
  status: 'data',
  hotlist: 'count',
  effortEngagement: 'score',
  salesforceEntry: 'data',
  task: 'count',
  name: 'name',
  count: 'count',
  date: 'date',
};

export const DEFAULT_SPACES_COLUMNS = Object.freeze({
  ACCOUNT_NAME: 'accountName',
  LAST_TOUCH: 'lastTouch',
  LAST_ENGAGEMENT: 'lastEngagement',
  STATUS: 'status',
  HOTLIST: 'hotlist',
  EFFORT_ENGAGEMENT: 'effortEngagement',
  SALESFORCE_ENTRY: 'salesforceEntry',
  TASK: 'task',
});

export const STEP_TYPE_MAPPING = Object.freeze({
  TEMPLATE: 'Email',
  CALL: 'Call',
  OTHER: 'Flow Task for',
  STEP_SUBTYPE_LINKEDIN_INMAIL: 'LinkedIn InMail',
  STEP_SUBTYPE_LINKEDIN_CONNECT: 'LinkedIn Connect with',
});

export const REQUIRED_ACCOUNT_COLUMNS = ['accountName'];
export const REQUIRED_OPPORTUNITY_COLUMNS = ['name'];

export const ENGAGEMENT_TEXT = {
  EmailOpen: 'Email Opened',
  LinkClick: 'Link Clicked',
  EmailReply: 'Replied to you',
  PhoneCall: 'Called You',
};

export const FIELD_TYPES = {
  PICKLIST: 'picklist',
  BOOLEAN: 'boolean',
  PHONE: 'phone',
  ID: 'id',
  STRING: 'string',
};

export const CUSTOM_COLUMN_TYPES = keyMirror(['SINGLE_SELECT', 'TEXT_FIELD']);

export const TYPE_TO_RECORD_MAP = {
  [CUSTOM_COLUMN_TYPES.SINGLE_SELECT]: SingleSelectColumn,
  [CUSTOM_COLUMN_TYPES.TEXT_FIELD]: TextFieldColumn,
};

export const COLUMN_RECORD_TO_HEADER_CELL_COMPONENT_MAP = {
  [SingleSelectColumn]: CustomColumnHeaderCell,
  [TextFieldColumn]: CustomColumnHeaderCell,
};

export const FIRESTORE_DOC_CHANGE_TYPES = {
  ADDED: 'added',
  MODIFIED: 'modified',
  REMOVED: 'removed',
};

export const FIRESTORE_WATCHER_CATEGORY = {
  ACCOUNTS: 'accounts',
  COLUMNS: 'columns',
  WORKSPACE: 'workspace',
};

export const UNTITLED_COLUMN_LABEL = 'Untitled Column';

export const DEFAULT_OPPORTUNITY_COLUMNS = [
  'stageName',
  'amount',
  'ownerName',
  'closeDate',
  'name',
];

export const TABS = {
  TABLE: 'TABLE',
  LIVE_SPACE_SETTINGS: 'LIVE_SPACE_SETTINGS',
};

export const READABLE_TAB_NAMES = {
  TABLE: {
    ACCOUNT: 'Account',
    OPPORTUNITY: 'Opportunity',
  },
  LIVE_SPACE_SETTINGS: 'Live Space Settings',
};

export const DECIMAL_PLACES = 1;
export const CURRENCY_DECIMAL_PLACES = 2;
