import { reduce } from 'lodash-es';
import { fromJS } from 'immutable';

import { customSortComparator } from 'Utils/customSortComparator';
/**
 * @param {string} actorType
 * @param {string} actorId
 *
 * @return {string}
 * */
export const actorUniqueId = (actorType, actorId) => {
  return `${actorType}-${actorId}`;
};

/**
 * @param {Immutable.Map} actorA
 * @param {Immutable.Map} actorB
 *
 * @return {boolean}
 * */
export const testActorEquality = (actorA, actorB) => {
  return actorA.equals(actorB);
};

/**
 * Return the name of the Actor, which is polymorphic, and for some types, a name is not returned.
 * For example, Organization does not have a name, so it's just view logic to show it as
 * "My Organization" or whatever makes sense.
 *
 * @param {Immutable.Map} actor
 *
 * @return {string}
 * */
export const actorName = actor => {
  const actorType = actor.get('type');

  if (actorType === 'organization') {
    return 'My Organization';
  }

  return actor.get('name');
};

/**
 * Convert an immutable map of actors into the list format expected by the searchable dropdown menu.
 *
 * @param {Immutable.Map} actorImmutableList
 *
 * @return {Array}
 */
export const transformActorsMap = (actorsImmutableMap, allowOrg) => {
  const actorsGroupedByType = reduce(
    actorsImmutableMap.toJS(),
    (result, actor) => {
      const actorAttributesMap = {
        ...actor,
        name: actorName(fromJS(actor)),
      };
      const obj = { ...result };
      if (!allowOrg && actor.type === 'organization') {
        return obj;
      }
      if (obj[actor.type]) {
        obj[actor.type].push(actorAttributesMap);
      } else {
        obj[actor.type] = [actorAttributesMap];
      }

      return obj;
    },
    {}
  );

  return reduce(
    actorsGroupedByType,
    (result, actorsList, actorType) => {
      result.push({
        title: actorType,
        suggestions: actorsList?.sort((a, b) =>
          customSortComparator(a?.name, b?.name)
        ),
      });

      return result;
    },
    []
  );
};
