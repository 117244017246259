import { StickyFilters } from '../../user';

import getClient from './getClient';

export type User = {
  org_id: string;
  id: number;
  name: string;
  email: string;
  sfdc_org_id: string;
  sfdc_user_id: string;
  sfdc_instance_url: string | null;
  provider: 'google_oauth2' | 'microsoft_graph_auth';
  settings: {
    persona: string | null;
    sticky_filters?: StickyFilters;
    sticky_filters_omnibar?: StickyFilters;
    chrome_extension_onboarding_completed?: boolean;
    gmail_inapp_onboarding_completed?: boolean;
    gmail_card_number?: number;
    gcal_inapp_onboarding_completed?: boolean;
    gcal_card_number?: number;
    sfdc_inapp_onboarding_completed?: boolean;
    sfdc_card_number?: number;
    is_3c_logic_dialer_enabled?: boolean;
    sticky_filters_add_to_flow: string[];
  } | null;
  flow_enabled: boolean;
  dialer_enabled: boolean;
  sms_enabled: boolean;
  profile_image_url: string | null;
  deactivated_at?: string | null;
  time_zone: string;
  role: string;
};

export type ApiUser = {
  data: User;
};

export type UserUpdateParams = {
  variables: {
    persona?: string;
    sticky_filters?: StickyFilters;
    sticky_filters_omnibar?: StickyFilters;
    chrome_extension_onboarding_completed?: boolean;
    gmail_inapp_onboarding_completed?: boolean;
    gmail_card_number?: number;
    gcal_inapp_onboarding_completed?: boolean;
    gcal_card_number?: number;
    sfdc_inapp_onboarding_completed?: boolean;
    sfdc_card_number?: number;
    sticky_filters_add_to_flow?: string[];
  };
};

export type ApiMergeFields = {
  data: {
    [key: string]: {
      name: string;
      label: string;
    }[];
  };
};

export type ApiHasZoomEmail = {
  data: boolean;
};

// current thing
export const userGet = (): Promise<ApiUser> => {
  return getClient().post('user/get').json();
};

export const userMarkExtensionLogin = (): Promise<ApiUser> => {
  return getClient().post('user/mark_login').json();
};

export const userUpdate = ({
  variables,
}: UserUpdateParams): Promise<ApiUser> => {
  return getClient()
    .post('user/update', {
      json: {
        variables,
      },
    })
    .json();
};

export const userMergeFields = (): Promise<ApiMergeFields> => {
  return getClient().post('user/available_merge_fields').json();
};

export const userHasZoomEmail = (user_id: number): Promise<ApiHasZoomEmail> => {
  return getClient()
    .post('user/has_zoom_email', { json: { variables: { user_id } } })
    .json();
};

export const userEmailAliasesGet = (): Promise<{ data: Array<string> }> => {
  return getClient().post('user/email_aliases').json();
};
