import { IStyle } from '@fluentui/react';
import { useSetTimeout } from '@fluentui/react-hooks';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

// In firefox, setTimeout with duration 0 too short for browser notice the changes in dom
const initialTransitDuration = 20;

const PHASE = {
  CLOSE: 'close',
  CLOSING: 'closing',
  CLOSED: 'closed',
  OPEN: 'open',
  OPENING: 'opening',
  OPENED: 'opened',
};

type ExpandProps = {
  open: boolean;
  duration?: number;
  easin?: string;
  className?: string;
  tag?: string;
  transitions?: string[];
  minHeight?: number;
  useGradient?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function usePrevious(value: any): any {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const Expand: FC<ExpandProps> = ({
  open,
  duration = 400,
  tag = 'div',
  children,
  minHeight = 0,
  useGradient = false,
}) => {
  const [status, setStatus] = useState(open ? PHASE.OPEN : PHASE.CLOSE);
  const prevOpen = usePrevious(open);
  const [timeoutId, setTimeoutId] = useState(0);
  const { setTimeout, clearTimeout } = useSetTimeout();

  const getDefaultExpandStyle = (): IStyle => {
    switch (status) {
      case PHASE.OPENING:
      case PHASE.CLOSE:
      case PHASE.CLOSED:
        return {
          height: minHeight,
          opacity: useGradient ? 1 : 0,
          overflow: 'hidden',
        };
      case PHASE.OPENED:
      case PHASE.CLOSING:
        return {
          height: ref.current?.scrollHeight,
          opacity: 1,
          overflow: 'hidden',
        };
      default:
        return { height: 'auto', opacity: 1, overflow: 'unset' };
    }
  };

  const transit = useCallback(
    (entering: string, entered: string, enter: string) => {
      setStatus(entering);

      const timeoutId = setTimeout(() => {
        setStatus(entered);

        setTimeout(() => {
          setStatus(enter);
        }, duration);
      }, initialTransitDuration);
      setTimeoutId(timeoutId);
    },
    [duration, setTimeout],
  );

  const timeoutIdRef = useRef(timeoutId);

  useEffect(() => {
    clearTimeout(timeoutIdRef.current);

    if (open && !prevOpen) {
      transit(PHASE.OPENING, PHASE.OPENED, PHASE.OPEN);
      // eslint-disable-next-line no-dupe-else-if
    } else if (prevOpen && !open) {
      transit(PHASE.CLOSING, PHASE.CLOSED, PHASE.CLOSE);
    }
  }, [clearTimeout, open, transit, prevOpen]);

  const ref = useRef<HTMLElement | null>(null);

  return React.createElement(
    tag,
    {
      ref,
      className: 'relative transition-all',
      style: getDefaultExpandStyle(),
    },
    [
      children,
      useGradient && !open && (
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-white" />
      ),
    ],
  );
};

export default Expand;
