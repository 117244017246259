import { IPersonaProps } from '@fluentui/react';

import getClient from './getClient';
import { ResponseTimeSlot } from './scheduler';

type SchedulerUser = {
  email: string;
  id: number;
  name: string;
};

export type ApiMeetingProposal = {
  data: {
    attendees: SchedulerUser[];
    creator: SchedulerUser;
    description: string;
    double_bookable: boolean;
    id: number;
    location: string;
    organization_brand: {
      company_logo_url: string;
      custom_meeting_enabled: boolean;
      deco_color: string;
      header_height: string;
    };
    sender: SchedulerUser;
    status: string;
    time_slots: ResponseTimeSlot[];
    time_zone_name: string;
    title: string;
    video_conferencing: number;
  };
};

export type MeetingParams = {
  scheduleFor: IPersonaProps | null;
  attendees: IPersonaProps[];
  title: string | null;
  location: string | null;
  description: string | null;
  timeZone: string | null;
  timeSlots: { starts_at: number; ends_at: number }[];
  allowDoubleBooking: boolean | false;
  videoConferencing: number | null;
};

export const meetingProposalCreate = (
  scheduleFor: IPersonaProps | null,
  attendees: IPersonaProps[],
  title: string | null,
  location: string | null,
  description: string | null,
  timeZone: string | null,
  timeSlots: { starts_at: number; ends_at: number }[],
  allowDoubleBooking: boolean | false,
  videoConferencing: number | null,
): Promise<ApiMeetingProposal> => {
  return getClient()
    .post('meeting_proposal/create', {
      json: {
        variables: {
          sender_id: scheduleFor?.key,
          attendee_ids: attendees?.map(attendee => attendee.key),
          title,
          location,
          time_zone_name: timeZone,
          description,
          time_slots: timeSlots,
          status: 'sent',
          double_bookable: allowDoubleBooking,
          video_conferencing: videoConferencing,
        },
      },
    })
    .json();
};
