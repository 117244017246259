import { fork, call } from 'redux-saga/effects';
import shared from 'Modules/Shared/sagas';
import app from 'Modules/App/sagas';
import sharingDialog from 'Modules/SharingDialog/sagas';
import peopleImportDialog from 'Modules/PeopleImportDialog/sagas';
import * as spacesImportDialog from 'Modules/Spaces/import/sagas';
import rootSagaErrorHandler from 'ErrorHandling/rootSagaErrorHandler';

import routes from '../routing/routesSaga';

// -------------- Exporting the root saga for integration with the store --------------
const sagas = [
  // shared needs to be invoked before app so
  // the error saga is running in case anything
  // goes wrong in app upstart
  shared,
  app,
  routes,
  sharingDialog,
  peopleImportDialog,
  spacesImportDialog.default,
];

function createRetryableSaga(saga) {
  const runSaga = function* doRunSaga() {
    try {
      yield call(saga);
    } catch (error) {
      // it's VERY important that the sagas are retried first
      // before the error handler, otherwise the logError saga would never
      // get invoked
      yield fork(runSaga);
      yield* rootSagaErrorHandler(error);
    }
  };
  return runSaga;
}

const SagaManager = {
  startSagas(sagaMiddleware) {
    sagas.map(createRetryableSaga).forEach(saga => sagaMiddleware.run(saga));
  },
};

export default SagaManager;
