import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
function NoTrackingActivity({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="120"
      height="120"
      viewBox="0 0 120 120"
    >
      <defs>
        <circle id="a" cx="60" cy="60" r="60" />
        <path
          id="c"
          d="M92.077 38.118s-8.93.88-36.98 3.779C21.874 45.329 7.797 61.39.84 81.74c-2.18 6.385-.072 13.395 6.676 13.44l30.418.242 40.53.344 7.096-23.93 28.588-8.783c11.941-3.67 16.228-18.366 8.132-27.882L92.506.187 75.74 11.168l16.337 26.95z"
        />
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-190 -163) translate(190 163)"
      >
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#03A3BB" fillOpacity="0.25" xlinkHref="#a" />
        <g mask="url(#b)">
          <g transform="translate(-6.512 16.744)">
            <path
              fill="#F19F4D"
              fillRule="nonzero"
              stroke="none"
              d="M82.809 20.465l-4.432-3.92s-11.19 3.175-11.93-1.972c0 0-1.782.45-2.222-.52-.44-.972 3.017-4.836 3.017-4.836s.403-4.173 3.837-5.876C74.514 1.638 83.056-.765 84.016.236c.96 1.002 4.332 12.475 4.332 12.475s.707 4.947-5.54 7.754z"
            />
            <path
              fill="#016776"
              fillRule="nonzero"
              stroke="none"
              d="M92.13 47.327s-8.937.881-37.007 3.788C21.875 54.555 7.788 70.655.826 91.055c-2.181 6.402-.073 13.429 6.68 13.474l71 .587 7.102-23.989 28.61-8.804c11.95-3.679 16.24-18.41 8.138-27.95L92.56 9.303 75.78 20.31l16.35 27.016z"
            />
            <g
              fill="none"
              fillRule="evenodd"
              stroke="none"
              strokeWidth="1"
              transform="translate(0 9.302)"
            >
              <mask id="d" fill="#fff">
                <use xlinkHref="#c" />
              </mask>
              <g fillRule="nonzero" mask="url(#d)">
                <path
                  fill="#3361C2"
                  d="M8.634 81.781a8.634 8.634 0 100-17.268 8.634 8.634 0 100 17.268z"
                  transform="translate(-5.023 -.28)"
                />
                <path
                  fill="#EE6352"
                  d="M45.885 81.781a8.634 8.634 0 100-17.268 8.634 8.634 0 000 17.268zM83.414 81.781a8.634 8.634 0 100-17.268 8.634 8.634 0 100 17.268zM27.781 60.5a8.634 8.634 0 100-17.27 8.634 8.634 0 000 17.27zM65.034 60.5a8.634 8.634 0 100-17.27 8.634 8.634 0 000 17.27zM102.285 60.5a8.634 8.634 0 100-17.27 8.634 8.634 0 100 17.27zM83.414 37.71a8.634 8.634 0 100-17.268 8.634 8.634 0 100 17.269zM120.876 37.71a8.634 8.634 0 100-17.268 8.634 8.634 0 000 17.269zM102.285 17.269a8.634 8.634 0 100-17.269 8.634 8.634 0 100 17.269z"
                  transform="translate(-5.023 -.28)"
                />
                <path
                  fill="#3361C2"
                  d="M27.781 105.287a8.634 8.634 0 100-17.268 8.634 8.634 0 000 17.268z"
                  transform="translate(-5.023 -.28)"
                />
              </g>
            </g>
            <path
              fill="#F19F4D"
              fillRule="nonzero"
              stroke="none"
              d="M58.203 62.326h-.273a8.687 8.687 0 01-3.375-.789 8.881 8.881 0 01-2.825-2.054 9.138 9.138 0 01-1.843-3.007 9.32 9.32 0 01-.581-3.503l.852-32.02a9.218 9.218 0 012.77-6.366 8.75 8.75 0 016.346-2.49 8.777 8.777 0 016.203 2.843 9.245 9.245 0 012.427 6.512l-.855 32.02a9.216 9.216 0 01-2.674 6.267 8.752 8.752 0 01-6.172 2.587z"
            />
            <path
              fill="#F19F4D"
              fillRule="nonzero"
              stroke="none"
              d="M64.433 44.612l1.714.036c1.993.042 3.972-.35 5.794-1.146a13.554 13.554 0 004.749-3.461 13.282 13.282 0 002.802-5.12 13.14 13.14 0 00.324-5.806l-1.615-9.58a13.297 13.297 0 00-4.56-7.954 13.695 13.695 0 00-8.69-3.208c-1.781-.018-3.549.31-5.202.964a13.584 13.584 0 00-4.43 2.849 13.337 13.337 0 00-2.986 4.3 13.163 13.163 0 00-1.085 5.098l-.085 9.548a13.236 13.236 0 003.821 9.421 13.673 13.673 0 009.449 4.06z"
            />
            <path
              fill="#F19F4D"
              fillRule="nonzero"
              stroke="none"
              d="M48.154 34.304c1.58 2.179 4.57 2.623 6.679.992 2.109-1.63 2.538-4.72.959-6.899l-1.62-2.235c-1.58-2.18-4.57-2.624-6.68-.993-2.108 1.63-2.538 4.72-.958 6.899l1.62 2.236z"
            />
            <path
              fill="none"
              stroke="#002329"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.837"
              d="M48.372 28.837l3.721 3.721"
            />
            <path
              fill="none"
              stroke="#002329"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.837"
              d="M72.4914932 23.255814L73.4883721 31.2520522 70.6976744 31.627907"
            />
            <path
              fill="#6B3E29"
              fillRule="nonzero"
              stroke="none"
              d="M56.212 31.628L58.67 17.42l.58-3.372a11.167 11.167 0 003.089 1.923c4.1 1.726 15.814-.167 16.678-8.486.717-6.785-6.087-2.308-9.793-2.394-5.818-.137-10.13-3.127-17.62 1.798-8.97 5.897-3.359 17.863-3.359 17.863s4.02-1.962 7.967 6.876z"
            />
            <path
              fill="#24272D"
              fillRule="nonzero"
              stroke="none"
              d="M67.2309592 44.6511628L62.3255814 44.6537254 67.2309592 48.3555221z"
            />
            <path
              fill="#002329"
              fillRule="nonzero"
              stroke="none"
              d="M68.372 27.907a1.395 1.395 0 100-2.79 1.395 1.395 0 000 2.79zM76.744 27.907a1.395 1.395 0 100-2.79 1.395 1.395 0 000 2.79zM65.116 33.495c.122 0 4.035-.28 4.651 2.784l-4.65-2.784z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
/* eslint-enable max-len */

NoTrackingActivity.displayName = 'NoTrackingActivity';
NoTrackingActivity.propTypes = {
  className: PropTypes.string,
};

NoTrackingActivity.defaultProps = {
  className: null,
};

export default NoTrackingActivity;
