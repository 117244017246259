import { CommandButton, DefaultButton } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import useUserGet from '@groove/api/hooks/useUserGet';

type ActionsProps = {
  disabled: boolean;
  onToggleReview: () => void;
  isReviewing: boolean;
  reviewItems: number;
  onSubmit: () => void;
  onDismiss?: () => void;
  actionBtnText?: string;
};

const Actions: FC<ActionsProps> = ({
  onSubmit,
  onToggleReview,
  isReviewing,
  reviewItems,
  disabled,
  onDismiss,
  actionBtnText = 'Log to Salesforce',
}) => {
  const { data: user } = useUserGet();
  const [isOutlook, setIsOutlook] = useState<boolean>(false);

  useEffect(() => {
    if (user?.data.provider) {
      setIsOutlook(user.data.provider === 'microsoft_graph_auth');
    }
  }, [setIsOutlook, user]);
  return (
    <div
      className={`box-border fixed ${
        isOutlook
          ? 'bottom-0 left-0 w-full'
          : 'bottom-[15vh] left-[20vw] w-[60vw]'
      } z-10 flex justify-between p-2 bg-white`}
    >
      <CommandButton
        ariaLabel="Review"
        onClick={onToggleReview}
        disabled={disabled}
        iconProps={{
          iconName: isReviewing ? 'DoubleChevronDown12' : 'DoubleChevronUp12',
        }}
      >
        Review Items ({reviewItems})
      </CommandButton>
      <div className="flex gap-2">
        {onDismiss && (
          <DefaultButton ariaLabel="Advanced search cancel" onClick={onDismiss}>
            Cancel
          </DefaultButton>
        )}
        <DefaultButton
          primary
          ariaLabel="Submit"
          onClick={() => {
            onSubmit();
          }}
        >
          {actionBtnText}
        </DefaultButton>
      </div>
    </div>
  );
};

export default Actions;
