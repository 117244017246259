import { Map, fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { getFlowAutoImport } from 'Modules/FlowsShow/selectors';
import { AUTO_IMPORT_TYPES } from 'Modules/FlowsShow/constants/index.ts';

const ROOT_KEY_PATH = ['PeopleImportDialog', 'reports'];

export const reportFolders = state =>
  state.getIn([...ROOT_KEY_PATH, 'reportFolders']);
export const reports = state => state.getIn([...ROOT_KEY_PATH, 'reports']);
export const fetching = state => state.getIn([...ROOT_KEY_PATH, 'fetching']);
export const fetchingReport = state =>
  state.getIn([...ROOT_KEY_PATH, 'fetchingReport']);
export const contactIds = state =>
  state.getIn([...ROOT_KEY_PATH, 'contactIds']);
export const leadIds = state => state.getIn([...ROOT_KEY_PATH, 'leadIds']);
export const getSfdcWhatIdMap = state =>
  state.getIn([...ROOT_KEY_PATH, 'sfdcWhatIdMap']);

export const reportSelected = state =>
  state.getIn([...ROOT_KEY_PATH, 'reportSelected']);
export const fetchingFolders = state =>
  state.getIn([...ROOT_KEY_PATH, 'fetchingFolders']);

export const getReportFolderOptions = createSelector(
  reportFolders,
  reportFolders => {
    return reportFolders.map(folder =>
      fromJS({
        id: folder.get('sfdcId'),
        name: folder.get('label'),
        value: folder.get('value'),
      })
    );
  }
);

export const getAutoImportReportFolder = createSelector(
  getReportFolderOptions,
  getFlowAutoImport,
  (folderOptions, flowAutoImport) => {
    const autoImportIsReport =
      flowAutoImport &&
      flowAutoImport.importVia === AUTO_IMPORT_TYPES.SALESFORCE_REPORT;
    if (!autoImportIsReport || folderOptions.isEmpty()) return Map();

    return folderOptions.find(
      option => option.get('value') === flowAutoImport.reportFolder,
      this,
      Map()
    );
  }
);

export const getAutoImportReport = createSelector(
  reports,
  getFlowAutoImport,
  (reports, flowAutoImport) => {
    if (reports.isEmpty() || !flowAutoImport) return null;

    return reports.find(report => report.get('id') === flowAutoImport.id);
  }
);

export const getInitialAutoImportReport = createSelector(
  reports,
  getFlowAutoImport,
  (reports, flowAutoImport) => {
    if (!reports.isEmpty() && flowAutoImport) {
      return (
        reports.find(report => report.get('value') === flowAutoImport.id) ||
        Map()
      );
    }
    return Map();
  }
);
