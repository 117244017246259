import { camelizeKeys, decamelizeKeys } from 'humps';

import getClient from './getClient';

export type GeneratedContent = {
  subject: string;
  content: string;
  contentItemId: string;
  intent: string;
};

export type ColdEmailParams = {
  contactEmail: string;
  contactName?: string;
  contactTitle?: string;
  contactCompany?: string;
  writingStyle?: string;
  version?: string;
  contentType?: string;
  valueProp?: string;
};

export type FollowupEmailParams = {
  callId: string;
  recipients: string[];
  fullSummary: string;
  actionItems: string[];
  fromEmail?: string;
  tone?: string;
};

export type GenerateContentsError = {
  message: string;
  errorCode: string;
  statusCode: number;
};

export const generateColdEmail = (
  params: ColdEmailParams,
): Promise<GeneratedContent> =>
  getClient()
    .post('generate_contents/cold_email', {
      json: decamelizeKeys(params),
    })
    .json()
    .then(
      // eslint-disable-next-line @typescript-eslint/ban-types
      value => camelizeKeys(value as object) as GeneratedContent,
    )
    .catch(async error => {
      const parsedError = camelizeKeys(await error.response.json());
      throw parsedError;
    });

export const generateFollowupEmail = (
  params: FollowupEmailParams,
): Promise<GeneratedContent> =>
  getClient()
    .post('generate_contents/followup_email', {
      json: decamelizeKeys(params),
    })
    .json()
    .then(
      // eslint-disable-next-line @typescript-eslint/ban-types
      value => camelizeKeys(value as object) as GeneratedContent,
    )
    .catch(async error => {
      const parsedError = camelizeKeys(await error.response.json());
      throw parsedError;
    });
