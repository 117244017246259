import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { EMAIL_EVENT_TYPE_LABELS } from 'Modules/Emails/constants';
import EmailEventRecord from 'Modules/Emails/data/EmailEvent';

const styles = {
  root: {},
  typography: {
    display: 'inline-block',
    color: 'inherit',
    fontSize: 'inherit',
  },
};

const EmailEventTimeDetails = ({ classes, emailEvent }) => {
  const date = moment(emailEvent.triggeredAt);
  const eventOccurredToday = date.isSame(moment(), 'day');

  return (
    <span className={classes.root}>
      <Typography
        variant="caption"
        component="span"
        className={classes.typography}
      >
        {EMAIL_EVENT_TYPE_LABELS[emailEvent.type]}{' '}
        {eventOccurredToday
          ? `at ${date.format('LT')}`
          : `on ${date.format('L')}`}
      </Typography>
    </span>
  );
};

EmailEventTimeDetails.displayName = 'EmailEventTimeDetails';
EmailEventTimeDetails.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    typography: PropTypes.string.isRequired,
  }).isRequired,
  emailEvent: PropTypes.instanceOf(EmailEventRecord).isRequired,
};

export default withStyles(styles)(EmailEventTimeDetails);
