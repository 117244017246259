import { createActions, standardActions } from '@groove-labs/action-utils';
import { FSA } from 'flux-standard-action';

export type ReportSfdcIdPayload = {
  reportSfdcId: string;
};

export type ReportFailurePayload = {
  reportSfdcId: string;
  message: string;
};

export type BeginReportValidationPayload = {
  reportSfdcId: string;
  objectType: string;
};

const types = {
  PEOPLE_IMPORT_DIALOG: {
    REPORTS: {
      VALIDATE_AUTO_IMPORT_REPORT: standardActions(),
    },
  },
};

export const actionTypes =
  createActions<typeof types>(types).PEOPLE_IMPORT_DIALOG.REPORTS;

export const validateAutoImportReportProgress = ({
  reportSfdcId,
}: ReportSfdcIdPayload): FSA<string, ReportSfdcIdPayload> => ({
  type: actionTypes.VALIDATE_AUTO_IMPORT_REPORT.PROGRESS,
  payload: {
    reportSfdcId,
  },
});

export const validateAutoImportReportBegin = ({
  reportSfdcId,
  objectType,
}: BeginReportValidationPayload): FSA<
  string,
  BeginReportValidationPayload
> => ({
  type: actionTypes.VALIDATE_AUTO_IMPORT_REPORT.BEGIN,
  payload: {
    reportSfdcId,
    objectType,
  },
});

export const validateAutoImportReportSuccess = ({
  reportSfdcId,
}: ReportSfdcIdPayload): FSA<string, ReportSfdcIdPayload> => ({
  type: actionTypes.VALIDATE_AUTO_IMPORT_REPORT.SUCCESS,
  payload: {
    reportSfdcId,
  },
});

export const validateAutoImportReportFailure = ({
  message,
  reportSfdcId,
}: ReportFailurePayload): FSA<string, ReportFailurePayload> => ({
  type: actionTypes.VALIDATE_AUTO_IMPORT_REPORT.FAILURE,
  payload: {
    message,
    reportSfdcId,
  },
});
