import { FC, useEffect, useState } from 'react';

import MultiSelect from './MultiSelect';
import PicklistNew, { StandardDropDownItem } from './PicklistNew';

export type PicklistItem = {
  label: string;
  value: string;
  validFor: string | null;
};
export type FieldType = 'picklist' | 'multipicklist';
export type FieldValue = string | number | (string | number)[] | null;
export type FormField = {
  name: string;
  label: string;
  value: FieldValue;
  type: FieldType;
  required: boolean;
  updateable: boolean;
  dependentPicklist: boolean | null;
  controllerName: string | null;
  picklist?: PicklistItem[];
  restrictedPicklist: boolean | null;
};

export type DependentPicklistProps = {
  field: FormField;
  parentField: FormField | undefined | null;
  onChange: (newValue: FieldValue) => void;
  availableValues: PicklistItem[];
};

const DependentPicklist: FC<DependentPicklistProps> = ({
  field,
  parentField,
  onChange,
  availableValues,
}) => {
  const [selectedItems, setSelectedItems] = useState<StandardDropDownItem[]>(
    [],
  );

  let items: StandardDropDownItem[] = [];
  if (field.dependentPicklist) {
    const parentValueIndex = parentField?.picklist
      ? parentField?.picklist?.findIndex(
          item => item.value === parentField.value,
        )
      : -1;
    // https://developer.salesforce.com/docs/atlas.en-us.api.meta/api/sforce_api_calls_describesobjects_describesobjectresult.htm#aboutPicklists
    /* eslint-disable no-bitwise */
    const filteredItems = availableValues.filter(
      item =>
        !!(
          atob(item.validFor || '').charCodeAt(parentValueIndex >> 3) &
          (128 >> parentValueIndex % 8)
        ),
    );
    /* eslint-enable no-bitwise */
    items = filteredItems.map(item => ({
      key: item.value,
      value: item.value,
      text: item.label,
    }));
  } else {
    items = availableValues.map(item => ({
      key: item.value,
      value: item.value,
      text: item.label,
    }));
  }
  // reset value when parent picklist changes filtered items
  useEffect(() => {
    if (!availableValues.length) return;

    if (field.type === 'picklist') {
      if (!items.find(item => item.value === field.value)) {
        onChange(null);
      }
    } else if (field.type === 'multipicklist') {
      const oldValue = (field.value as string[]) || [];
      const newValue = oldValue?.filter(item =>
        items.find(dropItem => dropItem.value === item),
      );
      if (!oldValue.every(item => newValue.includes(item))) onChange(newValue);
    }
  }, [parentField?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  // set selected items when value changes
  useEffect(() => {
    if (Array.isArray(field.value)) {
      setSelectedItems(
        items.filter(item =>
          (field?.value as (string | number)[])?.includes(item.value),
        ),
      );
    } else {
      setSelectedItems(items.filter(item => item.value === field.value));
    }
  }, [field.value, availableValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {field.type === 'picklist' && (
        <PicklistNew
          items={items}
          disabled={!field.updateable || !items.length}
          selectedValues={selectedItems}
          className="w-full mb-[4px]"
          onChange={selectedItem => {
            if (!selectedItem) return;
            onChange(selectedItem.value);
          }}
        />
      )}
      {field.type === 'multipicklist' && (
        <MultiSelect
          items={items}
          disabled={!field.updateable || !items.length}
          selectedValues={selectedItems}
          className="w-full mb-[4px]"
          onSelect={selectedItem => {
            if (!selectedItem) return;

            const oldValue = (field.value as string[]) || [];
            const newValue = [...oldValue, selectedItem.value];
            onChange(newValue);
          }}
          onRemove={index => {
            const oldValue = (field.value as string[]) || [];
            const newValue = [
              ...oldValue.slice(0, index),
              ...oldValue.slice(index + 1),
            ];
            onChange(newValue);
          }}
        />
      )}
    </>
  );
};

export default DependentPicklist;
