import makeHTTPCall from '../makeHTTPCall';

const BASE_PATH = '/api/thin/mobo';

export const getUsers = async phrase => {
  const url = `${BASE_PATH}/get_users`;
  const response = await makeHTTPCall(url, {
    method: 'post',
    body: {
      operationName: 'mobo/get_users',
      variables: {
        phrase,
      },
    },
  });

  return response.data;
};
