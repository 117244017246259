import { take, select } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { Configuration as GrooveWysiwygEditorConfiguration } from '@groove/wysiwyg-editor';
import { SagaIterator } from 'redux-saga';

import { actionTypes } from 'Modules/Shared/actions/featureFlags';
import { featureFlags as getFeatureFlags } from 'Modules/Shared/selectors/featureFlags';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

export default function* wysiwygSagaManager(): SagaIterator {
  let featureFlags = yield select(getFeatureFlags);

  // on initial load, feature flags haven't been fetched yet
  // so this blocks the saga from continuing until that happens
  if (featureFlags.isEmpty()) {
    featureFlags = fromJS((yield take(actionTypes.SET_FEATURE_FLAGS)).payload);
  }
  GrooveWysiwygEditorConfiguration.configure(grooveEngineRootUrl);
}
