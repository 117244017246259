import { keyMirror } from '@groove-labs/action-utils';

export const SERVICE_CONNECTION_TYPES = {
  ...keyMirror([
    'oauthToken',
    'sfdcToken',
    'sfdcMeta',
    'grooveLicense',
    'sfdcApiAccess',
  ]),
};

export const STATUSES = keyMirror([
  'pending',
  'inProgress',
  'successful',
  'failed',
]);

export const REFRESH_SFDC_META_JOB_NAME = 'Refresh Salesforce Meta';
