import { createActions } from '@groove-labs/action-utils';
import { FSA } from 'flux-standard-action';

const types = {
  SHARED: {
    ACCESS_CONTROLS: {
      SET_ACCESS_CONTROLS: 'SET_ACCESS_CONTROLS',
    },
  },
};

export const actionTypes =
  createActions<typeof types>(types).SHARED.ACCESS_CONTROLS;

type Scope = 'read' | 'write' | 'owner';
interface AccessControl {
  createdAt: string;
  updatedAt: string;
  id: number;
  actorId: number;
  actorType: string;
  entityId: number;
  entityType: string;
  scopes: [Scope];
}

export function setAccessControls(
  payload: AccessControl[]
): FSA<string, AccessControl[]> {
  return {
    type: actionTypes.SET_ACCESS_CONTROLS,
    payload,
  };
}
