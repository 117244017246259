import { FC } from 'react';
import Text from '@groove/ui/Components/Text';
import { useGetCurrentUser } from '@groove/api/hooks/useUsers';
import useDialerStore from '@groove/dialer-components/hooks/useStore';
import { useBestLocalNumber } from '@groove/api/hooks/useCommonDialer';

import useStore from '../../store/useStore';

const DialerBarMessageArea: FC = () => {
  const isLocalDialEnabled = useDialerStore(store => store.isLocalDialEnabled);
  const toRecipients = useStore(store => store.action.toRecipients);

  const { data: userData } = useGetCurrentUser();
  const { isFetching, data: localPhoneData } = useBestLocalNumber(
    { toPhoneNumber: toRecipients?.[0]?.phone || '' },
    false,
  );

  let formattedGrooveNumber =
    userData?.formatted_phone_number || 'Unknown number';

  if (isLocalDialEnabled && isFetching)
    formattedGrooveNumber = 'Loading local number...';

  if (isLocalDialEnabled && localPhoneData?.phone_number) {
    formattedGrooveNumber = localPhoneData?.friendly_name;
    useStore
      .getState()
      .updateAction({ fromPhoneNumber: formattedGrooveNumber });
  }

  return (
    <div className="flex-1 pl-[20px]">
      <Text className="font-semibold text-metadata">Caller ID:</Text>
      <Text data-testid="caller-id" className=" text-metadata">
        {formattedGrooveNumber}
      </Text>
    </div>
  );
};

export default DialerBarMessageArea;
