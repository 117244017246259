import { FC } from 'react';
import { SearchBox } from '@fluentui/react';
import { useDebouncedCallback } from 'use-debounce';

import useTemplatesStore from '../store/useStore';
import {
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  trackTemplatesEvent,
} from '../analytics/templatesEvents';

const TemplatesSearchBox: FC = () => {
  const searchTerm = useTemplatesStore(store => store.searchTerm);
  const setSearchTerm = useTemplatesStore(store => store.setSearchTerm);

  const [debouncedTrackEvent] = useDebouncedCallback(() => {
    trackTemplatesEvent({
      eventName: TEMPLATES_EVENT_TYPE.SEARCHED,
      templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.PANEL,
    });
  }, 300);

  return (
    <div className="flex flex-col">
      <SearchBox
        ariaLabel="Search All accessible Templates"
        className="max-w-full mx-3 my-2 min-h-[2rem] border-neutral/200 rounded after:border-clari-blue/600 after:rounded"
        placeholder="Search templates"
        onChange={(_, value) => {
          setSearchTerm(value || '');
          debouncedTrackEvent();
        }}
        onClear={() => setSearchTerm('')}
        clearButtonProps={{ ariaLabel: 'Clear Search' }}
        value={searchTerm}
      />
    </div>
  );
};

export default TemplatesSearchBox;
