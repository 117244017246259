import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Dialog,
  Collapse,
  Fade,
  makeStyles,
} from '@material-ui/core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import tinymce from 'tinymce';

import MergeFieldSearchResults from './MergeFieldSearchResults';

const useStyles = makeStyles({
  dialogSize: {
    width: 520,
    maxHeight: 450,
  },
  inputRoot: {
    display: 'block',
    marginBottom: 8,
  },
  topDivider: {
    marginBottom: 16,
  },
  mergeFieldContainer: {
    flexGrow: 1,
    overflowY: 'scroll',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  fadeOut: {
    left: 0,
    width: '100%',
    bottom: 0,
    position: 'sticky',
    height: '25px',
    background:
      'linear-gradient(to bottom, rgba(255,255,255,0.25) 0%,rgba(255,255,255,1) 100%)',
  },
});

export type MergeFieldGroup = {
  label: string;
  options: MergeField[];
};

export type MergeField = {
  label: string;
  name: string;
};

interface MergeFieldDialogProps {
  onClose: () => void;
  isOpen: boolean;
  onSelect?: (mergeValue: string) => void;
  mergeFieldProps: {
    mergeFields: MergeFieldGroup[];
    fallbackRequired?: boolean;
  };
}

const MergeFieldDialog: FC<MergeFieldDialogProps> = ({
  isOpen,
  onClose,
  onSelect,
  mergeFieldProps,
}) => {
  const { mergeFields, fallbackRequired } = mergeFieldProps;
  const [value, setValue] = useState('');
  const [fallbackValue, setFallbackValue] = useState('');

  const [selectedMergeField, setSelectedMergeField] =
    useState<MergeField | null>(null);

  useEffect(() => {
    if (isOpen) {
      setValue('');
      setFallbackValue('');
      setSelectedMergeField(null);
    }
  }, [isOpen]);

  const handleOnChange = useCallback(e => {
    setValue(e.target.value);
    setSelectedMergeField(null);
  }, []);

  const handleOnChangeFallbackValue = useCallback(e => {
    setFallbackValue(e.target.value);
  }, []);

  const handleSelectMergeField = useCallback(selectedMergeField => {
    setValue(selectedMergeField.label);
    setSelectedMergeField(selectedMergeField);
  }, []);

  const isFallbackValueValid = () => {
    let isValid = true;

    if (fallbackRequired) {
      isValid = isValid && !!fallbackValue;
    }

    return isValid && !fallbackValue.includes('"');
  };

  const getFallbackValueErrorText = () => {
    if (isFallbackValueValid()) {
      return '';
    }

    if (fallbackRequired) {
      return 'The fallback value is required and cannot contain a "';
    }

    return 'The fallback value cannot contain a "';
  };

  const handleInsert = useCallback(() => {
    if (selectedMergeField) {
      let mergeValue = `{!${selectedMergeField.name}}`;
      if (fallbackValue)
        mergeValue = `{!${selectedMergeField.name} || "${fallbackValue}"}`;

      if (onSelect) {
        onSelect(mergeValue);
        return;
      }

      tinymce.activeEditor.insertContent(mergeValue);
      onClose();
      window.dispatchEvent(
        new CustomEvent('merge-field-inserted', {
          detail: {
            fallbackAdded: !!fallbackValue,
            mergeField: selectedMergeField.name,
          },
        }),
      );
    }
  }, [fallbackValue, selectedMergeField, onClose, onSelect]);

  const classes = useStyles({});

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ paper: classes.dialogSize }}
    >
      <DialogContent className={classes.dialogContent}>
        <TextField
          autoFocus
          fullWidth
          classes={{
            root: classes.inputRoot,
          }}
          label="Field"
          inputProps={{ 'data-testid': 'field-input' }}
          placeholder="Search for a merge field"
          value={value}
          onChange={handleOnChange}
        />
        <Collapse
          in={!selectedMergeField}
          className={classes.mergeFieldContainer}
        >
          <MergeFieldSearchResults
            onSelectMergeField={handleSelectMergeField}
            mergeFields={mergeFields}
            value={value}
          />
          <div className={classes.fadeOut} />
        </Collapse>
        {selectedMergeField && (
          <Fade in={!!selectedMergeField}>
            <TextField
              fullWidth
              classes={{
                root: classes.inputRoot,
              }}
              label={fallbackRequired ? 'Fallback *' : 'Fallback'}
              inputProps={{ 'data-testid': 'fallback-input' }}
              placeholder="Fallback to this value when the field is not set"
              onChange={handleOnChangeFallbackValue}
              error={!isFallbackValueValid()}
              helperText={getFallbackValueErrorText()}
            />
          </Fade>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          aria-label="Insert"
          disabled={!selectedMergeField || !isFallbackValueValid()}
          onClick={handleInsert}
        >
          Insert
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MergeFieldDialog;
