import { ITheme } from '@fluentui/react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import DisplayScore from 'Modules/ActionsRewrite/components/customCells/SmartPriority/HoverCard/displayScore';

const useStyles = makeStyles((theme: ITheme) => ({
  headerContainer: {
    marginLeft: 12,
    marginRight: 12,
    paddingTop: 4,
    paddingBottom: 4,
  },
  whiteText: {
    color: theme.palette.white,
    fontSize: 12,
  },
}));

type HoverCardHeaderParams = {
  lastSyncedAt: string;
  smartPriority: number;
  isLoading?: boolean;
};

const HoverCardHeader = ({
  lastSyncedAt,
  smartPriority,
  isLoading,
}: HoverCardHeaderParams) => {
  const styles = useStyles();
  const updatedMessage = isLoading
    ? 'pending'
    : `Last updated ${moment(lastSyncedAt).format('h:mm a')}`;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      className={styles.headerContainer}
    >
      <Box display="block">
        <Typography className={styles.whiteText} variant="h6">
          Smart Priority Score
        </Typography>
        <Typography className={styles.whiteText}>{updatedMessage}</Typography>
      </Box>
      <DisplayScore smartPriority={smartPriority} />
    </Box>
  );
};

export default HoverCardHeader;
