import moment from 'moment';

export const getLastActiveDuplicate = (lastActiveDuplicate, person) => {
  if (person.lastActivityDate && lastActiveDuplicate) {
    if (moment(lastActiveDuplicate.date) > moment(person.lastActivityDate)) {
      return lastActiveDuplicate;
    }
  }
  return {
    date: person.lastActivityDate,
    person_id: person.id,
  };
};
