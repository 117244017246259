import { listToOrderedMap } from '@groove-labs/groove-ui';
import { fromJS, OrderedMap, Set } from 'immutable';
import { actionTypes } from 'Modules/Spaces/import/actions';
import { WORKSPACE_TYPES } from 'Modules/Spaces/shared/constants';

const initialState = fromJS({
  searchResults: {
    sfdcObjects: new OrderedMap(),
    sfdcColumnNames: [],
  },
  selectedObjectIds: new Set(),
  isSearching: null,
  isBatchCreating: false,
  users: new OrderedMap(),
});

export default function spacesImport(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SEARCH_BY_NAME.PROGRESS:
    case actionTypes.SEARCH_BY_FIELD.PROGRESS:
    case actionTypes.SEARCH_WITH_OPPORTUNITIES.PROGRESS:
    case actionTypes.SEARCH_BY_REPORT.PROGRESS: {
      return state.set('isSearching', true);
    }

    case actionTypes.SEARCH_BY_NAME.FAILURE:
    case actionTypes.SEARCH_BY_FIELD.FAILURE:
    case actionTypes.SEARCH_WITH_OPPORTUNITIES.FAILURE:
    case actionTypes.SEARCH_BY_REPORT.FAILURE: {
      return state.set('isSearching', false);
    }
    case actionTypes.SEARCH_BY_NAME.SUCCESS:
    case actionTypes.SEARCH_BY_FIELD.SUCCESS:
    case actionTypes.SEARCH_WITH_OPPORTUNITIES.SUCCESS:
    case actionTypes.SEARCH_BY_REPORT.SUCCESS: {
      const { workspaceType } = action.payload;

      let results = fromJS(action.payload.results);
      const sfdcSObjectType =
        workspaceType === WORKSPACE_TYPES.ACCOUNT
          ? 'sfdcAccounts'
          : 'sfdcOpportunities';

      results = results.set(
        'sfdcColumnNames',
        results
          .get('sfdcColumnNames')
          .filterNot(colummName => colummName === 'Id')
      );

      results = results.set(
        'sfdcObjects',
        listToOrderedMap(results.get(sfdcSObjectType), sfdcObject =>
          sfdcObject.get('Id')
        )
      );

      return state.set('isSearching', false).set('searchResults', results);
    }
    case actionTypes.FETCH_USERS.SUCCESS: {
      return state.set('users', action.payload);
    }

    case actionTypes.BATCH_CREATE.BEGIN: {
      return state.set('isBatchCreating', true);
    }
    case actionTypes.BATCH_CREATE.PROGRESS: {
      return state.set(
        'addAccountsJobId',
        action.payload && action.payload.batchId
      );
    }

    case actionTypes.BATCH_CREATE.SUCCESS:
    case actionTypes.BATCH_CREATE.FAILURE: {
      return state.set('isBatchCreating', false).set('addAccountsJobId', null);
    }

    case actionTypes.TOGGLE_OBJECT_SELECTIONS: {
      const newSet = new Set(action.payload);
      const oldSet = state.get('selectedObjectIds');

      // some set magic that removes existing entries and adds new ones
      const finalSet = oldSet.subtract(newSet).union(newSet.subtract(oldSet));

      return state.set('selectedObjectIds', finalSet);
    }

    case actionTypes.CLEAR_SELECTED_OBJECTS: {
      return state.set('selectedObjectIds', new Set());
    }

    case actionTypes.RESET_REDUCER: {
      return initialState;
    }
    default:
      return state;
  }
}
