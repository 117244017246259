import { GetState, SetState } from 'zustand';

import { VoicemailTabType } from '../../api/voicemail';
import { Store } from '../useStore';

import { MessageBar } from './MessageBar';

let currentTimeout: number;

export type RecentTabType = 'Calls' | 'Messages';
export type SettingsTabType = 'General' | 'Numbers' | 'Audio';
export type TabType = 'Home' | 'Recent' | 'Settings' | 'Voicemails';

export type UiStore = {
  currentTab: TabType;
  setCurrentTab: (tab: TabType) => void;

  currentRecentTab: RecentTabType;
  setCurrentRecentTab: (tab: RecentTabType) => void;

  currentVoicemailsTab: VoicemailTabType;
  setCurrentVoicemailsTab: (tab: VoicemailTabType) => void;

  currentSettingsTab: SettingsTabType;
  setCurrentSettingsTab: (tab: SettingsTabType) => void;

  messageBar: MessageBar | null;
  setMessageBar: (messageBar: MessageBar | null, timeout?: number) => void;

  isDialPadOpen: boolean;
  setIsDialPadOpen: (isDialPadOpen: boolean) => void;

  isCallFeedbackDialogOpen: boolean;
  setIsCallFeedbackDialogOpen: (isCallFeedbackDialogOpen: boolean) => void;

  showVoicemailNotification: boolean;
  setShowVoicemailNotification: (showVoicemailNotification: boolean) => void;
};
export const uiStore = (
  set: SetState<Store>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _get: GetState<Store>,
): UiStore => ({
  currentTab: 'Home',
  setCurrentTab: tab => set({ currentTab: tab }),

  currentRecentTab: 'Calls',
  setCurrentRecentTab: tab => set({ currentRecentTab: tab }),

  currentVoicemailsTab: 'Voicemail',
  setCurrentVoicemailsTab: tab => set({ currentVoicemailsTab: tab }),

  currentSettingsTab: 'General',
  setCurrentSettingsTab: tab => set({ currentSettingsTab: tab }),

  messageBar: null,
  setMessageBar: (messageBar: MessageBar | null, timeout?) => {
    clearTimeout(currentTimeout);
    set({ messageBar });
    currentTimeout = window.setTimeout(
      () => set({ messageBar: null }),
      timeout || 3000,
    );
  },
  isDialPadOpen: false,
  setIsDialPadOpen: isDialPadOpen => {
    if (isDialPadOpen) {
      set({ currentTab: 'Home' });
    }
    set({ isDialPadOpen });
  },

  isCallFeedbackDialogOpen: false,
  setIsCallFeedbackDialogOpen: isCallFeedbackDialogOpen => {
    set({ isCallFeedbackDialogOpen });
  },

  showVoicemailNotification: false,
  setShowVoicemailNotification: showVoicemailNotification => {
    set({ showVoicemailNotification });
  },
});
