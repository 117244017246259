import ActiveActionsZeroState from 'Modules/ActionsRewrite/components/tableTabs/zeroState/ActiveActionsZeroState';
import ZeroActionsState from 'Modules/ActionsRewrite/components/tableTabs/zeroState/ZeroActionsState';
import ReturnToActionsSubtitle from 'Modules/ActionsRewrite/components/tableTabs/zeroState/ReturnToActionsSubtitle';

import useStore from 'Modules/ActionsRewrite/store/useStore';

export default function CurrentTabZeroActions() {
  const { activeTab } = useStore();
  switch (activeTab) {
    case 'Active':
      return <ActiveActionsZeroState />;
    case 'Failed':
      return (
        <ZeroActionsState
          title="No failures to review."
          subtitle={<ReturnToActionsSubtitle />}
        />
      );
    case 'Assigned to Others':
      return (
        <ZeroActionsState
          title="No Actions assigned to others."
          subtitle={<ReturnToActionsSubtitle />}
        />
      );
    case 'Executed Actions':
      return (
        <ZeroActionsState
          title="No executed Actions."
          subtitle={<ReturnToActionsSubtitle />}
        />
      );
    default:
      return <ActiveActionsZeroState />;
  }
}
