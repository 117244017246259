import * as Sentry from '@sentry/browser';
import HTTPError from 'GrooveHTTPClient/HTTPError';
import { getSentryTags } from 'GrooveHTTPClient/utilities';

export const initializeSentry = () => {
  Sentry.init({
    dsn: process.env.NX_WEBAPP_SENTRY_DSN,
    environment: process.env.DEPLOY_ENV,
    release: process.env.REACT_APP_VERSION,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
    ],
    beforeSend: (event, hint) => {
      const exception = hint.originalException;

      if (exception instanceof HTTPError) {
        const { exception: originalException, ...eventWithoutException } =
          event;
        return {
          ...eventWithoutException,
          fingerprint: [exception.path, exception.response.get('status') || 0],
          message: exception?.message,
          tags: {
            ...eventWithoutException.tags,
            ...getSentryTags(exception),
          },
        };
      } else {
        const message = exception?.message;
        const stack = exception?.stack;
        event.fingerprint = [message, stack, exception];
      }

      return event;
    },
  });
};
