import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const RightChevron: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 6 10"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <path d="M0.646447 0.146447C0.451184 0.341709 0.451184 0.658291 0.646447 0.853553L4.79289 5L0.646447 9.14645C0.451185 9.34171 0.451185 9.65829 0.646447 9.85355C0.841709 10.0488 1.15829 10.0488 1.35355 9.85355L5.85355 5.35355C6.04882 5.15829 6.04882 4.84171 5.85355 4.64645L1.35355 0.146447C1.15829 -0.0488155 0.841709 -0.0488155 0.646447 0.146447Z" />
  </svg>
);
