import { FC, useCallback, useEffect, useState } from 'react';
import {
  Template,
  TemplateType,
  templateSearch,
} from '@groove/api/gateway/v1/template';
import { Icon, Stack, Text } from '@fluentui/react';
import StyledSeparator from '@groove/ui/Components/StyledSeparator';
import { debounce } from 'lodash-es';
import { useQuery } from 'react-query';

import {
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  TEMPLATES_MODAL_SOURCE,
  TEMPLATES_TAB,
  trackTemplatesEvent,
} from '../analytics/templatesEvents';
import useTemplatesStore from '../store/useStore';

import TemplatesList from './TemplatesList';
import TemplateListLoader from './TemplateListLoader';

type TemplatesSearchListProps = {
  onInsert: (template: Template) => void;
  dismiss: () => void;
  setIsTemplatesAppOpen: (open: boolean) => void;
  templateType: TemplateType;
};

const TemplatesSearchList: FC<TemplatesSearchListProps> = ({
  onInsert,
  dismiss,
  setIsTemplatesAppOpen,
  templateType,
}) => {
  const [debouncedSearchTerm, setDecouncedSearchTerm] = useState('');

  const searchTerm = useTemplatesStore(store => store.searchTerm);
  const setTemplatesAppSearchTerm = useTemplatesStore(
    store => store.templatesApp.setInitialSearchTerm,
  );
  const resetTemplatesStore = useTemplatesStore(
    store => store.resetTemplatesStore,
  );

  const { data: templateSearchData, isFetching: isFetchingSearch } = useQuery(
    ['templatesSearch', debouncedSearchTerm, templateType],
    () => templateSearch(debouncedSearchTerm, templateType),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchSetter = useCallback(
    debounce(setDecouncedSearchTerm, 500),
    [],
  );

  useEffect(() => {
    debouncedSearchSetter(searchTerm);
  }, [searchTerm, debouncedSearchSetter]);

  if (isFetchingSearch) {
    return <TemplateListLoader />;
  }

  return templateSearchData?.data?.length &&
    templateSearchData.data.length > 0 ? (
    <div className="flex flex-col flex-grow overflow-y-auto">
      <Stack
        className="flex flex-row items-center pb-1"
        aria-label="open-in-advanced-search-text"
        onClick={() => {
          setTemplatesAppSearchTerm(debouncedSearchTerm);
          resetTemplatesStore();
          dismiss();
          setIsTemplatesAppOpen(true);
          trackTemplatesEvent({
            eventName: TEMPLATES_EVENT_TYPE.MODAL_OPENED,
            templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.PANEL,
            templatesModalSource: TEMPLATES_MODAL_SOURCE.SEARCH,
          });
        }}
        style={{ cursor: 'pointer' }}
      >
        <Icon className="px-3" iconName="Zoom" />
        <Text className="font-semibold pb-1">{`Open "${debouncedSearchTerm}" in advanced search`}</Text>
      </Stack>
      <StyledSeparator xMargin={12} />

      <TemplatesList templates={templateSearchData?.data} onInsert={onInsert} />
    </div>
  ) : (
    <div className="px-4">
      <Text className="font-semibold">
        No templates were found matching your query
      </Text>
      <div className="py-2">
        <StyledSeparator xMargin={0} />
      </div>
      <Text>What to do next:</Text>
      <ul className="m-2 mx-4 p-0">
        <li>
          <Text>Search for different words that mean the same thing.</Text>
        </li>
        <li>
          <Text
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setTemplatesAppSearchTerm(debouncedSearchTerm);
              resetTemplatesStore();
              dismiss();
              setIsTemplatesAppOpen(true);
              trackTemplatesEvent({
                eventName: TEMPLATES_EVENT_TYPE.MODAL_OPENED,
                templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.PANEL,
                templatesModalSource: TEMPLATES_MODAL_SOURCE.SEARCH,
              });
            }}
            className="text-clari-blue/600"
          >{`Open "${debouncedSearchTerm}" in advanced search`}</Text>
          <Text> to also search for folder names.</Text>
        </li>
      </ul>
    </div>
  );
};

export default TemplatesSearchList;
