import * as Sentry from '@sentry/browser';
import { call, put } from 'redux-saga/effects';

import { checkSalesforceConnection } from 'GrooveHTTPClient/users';
import HTTPError from 'GrooveHTTPClient/HTTPError';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { redirectToFix } from 'Modules/Shared/sagas/location';

function* handleInvalidSalesforceConnectionHTTPRequest(clientMethod, ...args) {
  try {
    return yield call(clientMethod, ...args);
  } catch (e) {
    if (e instanceof HTTPError) {
      const {
        data: { hasValidSalesforceConnection },
      } = yield call(checkSalesforceConnection);
      if (!hasValidSalesforceConnection) {
        Sentry.withScope(scope => {
          scope.setLevel('debug');
          scope.setTag('type', 'InvalidSalesforceConnection');
          Sentry.captureMessage('Invalid Salesforce Connection');
        });

        yield put(
          pushSnackbarMessage({
            message:
              'Detected an invalid Salesforce connection - please reconnect',
          })
        );

        return yield call(redirectToFix);
      }
    }

    throw e;
  }
}

export { handleInvalidSalesforceConnectionHTTPRequest };
