import getClient from './getClient';

export type optOutDataType = {
  [key: string]: {
    id: string;
    sfdcId: string;
    hasOptedOutOfEmail: boolean;
  };
};

export type importRuleViolationsHashType = {
  [key: string]: {
    [key: string]: {
      ruleScopeType: string;
      violatedRuleName: string;
    };
  };
};

export type sfdcFailedRequestsType = { [key: string]: boolean };

export type PeopleImportWarningResponse = {
  optOutData: optOutDataType;
  importRuleViolationsHash: importRuleViolationsHashType;
  sfdcFailedRequests: sfdcFailedRequestsType;
};

export type PeopleImport = Record<string, { Id: string }>;

export const fetchPeopleImportWarnings = (
  selectedFlowId: string | null,
  peopleToImport: PeopleImport,
): Promise<PeopleImportWarningResponse> => {
  if (peopleToImport && Object.keys(peopleToImport).length > 0) {
    return getClient()
      .post('people/get_people_import_warnings', {
        json: { flow_id: selectedFlowId, people_to_import: peopleToImport },
      })
      .json();
  }
  return Promise.resolve({
    optOutData: {},
    importRuleViolationsHash: {},
    sfdcFailedRequests: {},
  });
};

export default fetchPeopleImportWarnings;
