import { UseQueryResult, useQuery } from 'react-query';

import {
  salesforceLogFields,
  ApiSalesforceLogFields,
} from '../services/v1/salesforce';

import useGrooveMessageEvent from './useGrooveMessageEvent';

export const LOG_FIELD_CACHE_TIME = 1000 * 60 * 60 * 24;
export const LOG_FIELD_STALE_TIME = 1000 * 60 * 60 * 24;

const useSalesforceLogFields = (
  additionalFieldsType: 'email' | 'calendar' | null,
  enabled: boolean,
): UseQueryResult<ApiSalesforceLogFields> => {
  const { data: grooveMessageEvent } = useGrooveMessageEvent();
  const additionalFields =
    additionalFieldsType === 'email'
      ? grooveMessageEvent?.logEmailFields
      : grooveMessageEvent?.logCalendarFields;

  return useQuery(
    ['logFields', additionalFieldsType],
    () =>
      additionalFieldsType &&
      salesforceLogFields(additionalFieldsType, additionalFields),
    {
      enabled: enabled && !!grooveMessageEvent,
      cacheTime: LOG_FIELD_CACHE_TIME,
      staleTime: LOG_FIELD_STALE_TIME,
    },
  );
};

export default useSalesforceLogFields;
