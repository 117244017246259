import keyMirror from 'Utils/key-mirror';

export const ASC = 'asc';
export const DESC = 'desc';
export const COLUMN_TYPES = keyMirror({
  string: null,
  integer: null,
  float: null,
  date: null,
  dateTime: null,
  boolean: null,
});
