import * as React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formGroupConnect } from '@groove-labs/groove-ui';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ReactSelect from 'react-select';
import { configureClient as configureGatewayClient } from '@groove/api/gateway/v1/getClient';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

import useStore from 'Modules/App/Store/useStore';
import useMoboEnabled from 'Modules/Shared/hooks/useMoboEnabled';
import useMoboDropdownOptions from 'Modules/Shared//hooks/useMoboDropdownOptions';
import { trackEvent } from 'Utils/segment';
import { ANALYTICS_BASE_PROPERTIES } from 'Modules/Shared/sagas/analytics';
import ClariTheme from 'Utils/theme/ClariTheme';
import { ActionComposeEnum } from '@groove/action-compose/constants';
import { setActionComposeState } from '@groove/action-compose/utils/actionComposeHelpers';
import { fetchFilteredActions } from 'Modules/Actions/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    fontSize: '11px',
    paddingLeft: '20px',
  },
  container: {
    display: 'flex',
    width: '200px',
    height: '5px',
    color: ClariTheme.palette.text.primary,
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  label: {
    flexDirection: 'column',
    minWidth: '57.25px',
  },
}));

const customReactSelectOptions = {
  control: (base: CSSProperties) => ({
    ...base,
    backgroundColor: ClariTheme.palette.text.neutralColor,
    border: 'none',
    borderBottom: `1px solid ${ClariTheme.palette.text.borders}`,
    borderRadius: 0,
    boxShadow: 'none',
    boxSizing: 'content-box',
    flex: 1,
    height: 36,
    minHeight: 36,
    overflowY: 'auto',
    '&:focus': {
      height: 36,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  container: (base: CSSProperties) => ({
    ...base,
    height: 36,
    minWidth: '90%',
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    marginTop: 0,
    position: 'absolute',
    zIndex: 7,
  }),
};

const MoboDropdown: React.FC = () => {
  const { root, label, container } = useStyles({});
  const [value, setValue] = useState<Record<string, string>>();
  const dispatch = useDispatch();

  const moboUser = useStore(state => state.moboUser);
  const setMoboUser = useStore(state => state.setMoboUser);
  const clearMoboUser = useStore(state => state.clearMoboUser);
  const isMoboEnabled = useMoboEnabled();
  const [searchText, setSearchText] = useState('');
  const options = useMoboDropdownOptions(searchText);
  const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));

  /**
   * When the moboUser is cleared, also clear out the value from the picklist
   */
  useEffect(() => {
    if (!moboUser) {
      setValue(undefined);
    }
    // Make sure that gateway is using the mobo header
    configureGatewayClient({
      prefixUrl: `${
        grooveEngineRootUrl || process.env.NX_GROOVE_ENGINE_ROOT_URL
      }/api/gateway/v1/`,
      headers: { 'x-mobo-user': moboUser?.id },
    });

    dispatch(fetchFilteredActions());

    // Close action since the endpoint has changed and we want to close the action since we will be using the credentials of the person you switched to
    setActionComposeState(ActionComposeEnum.CLOSED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moboUser?.id]);

  const handleChange = (option?: Record<string, string>) => {
    if (option) {
      const id = option.value;
      const mobo = options.find(o => o.value === id);
      if (!mobo) {
        return;
      }

      setMoboUser({
        id: mobo.value,
        name: mobo.label,
        email: mobo.email,
      });

      trackEvent('Clicked MOBO Drop Down', {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory: 'mobo',
      });
    } else {
      clearMoboUser();
    }

    setValue(option);
  };

  const updateSearchText = (input: string) => {
    if (input !== searchText) {
      setSearchText(input);
    }
  };

  if (!isMoboEnabled) {
    return null;
  }

  return (
    <div className={root}>
      <div className={label}>
        <div>Manage on</div>
        <div>Behalf of: </div>
      </div>
      <div className={container}>
        <ReactSelect
          isClearable
          options={sortedOptions}
          styles={customReactSelectOptions}
          value={value || null}
          onInputChange={updateSearchText}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default formGroupConnect()(MoboDropdown);
