import { createSelector } from 'reselect';
import { isUndefined } from 'lodash-es';

export const createFeatureFlagSelector = featureFlag => {
  return createSelector(
    [state => state.getIn(['featureFlags', featureFlag])],
    featureFlagStatus => {
      if (isUndefined(featureFlagStatus)) {
        return false;
      }

      return featureFlagStatus;
    }
  );
};

export const featureFlags = state => state.getIn(['featureFlags']);
