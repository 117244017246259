export const location = state => state.getIn(['app', 'location']);
export const activeTab = state => state.getIn(['app', 'location', 'activeTab']);
export const getScroll = routeName => state =>
  state.getIn(['app', 'routeScrolls', routeName], 0);
export const getRouteParams = state =>
  state.getIn(['app', 'location', 'routeParams']);
export const getFullPath = state =>
  state.getIn(['app', 'location', 'fullPath']);
export const getSearchParams = state =>
  state.getIn(['app', 'location', 'searchParams']);
export const getRouteName = state =>
  state.getIn(['app', 'location', 'routeName']);
