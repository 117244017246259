import { Record, Map } from 'immutable';
import LabelOwner from 'Modules/Shared/records/LabelOwner';

const defaults = {
  id: null,
  name: '',
  labelOwner: new LabelOwner(),
  category: null,
  details: new Map(),
  instanceCount: 0,
};

export default class Label extends Record(defaults) {
  static from({ label }) {
    const labelOwner = new LabelOwner({
      id: label.get('labelOwnerId'),
      type: label.get('labelOwnerType'),
    });
    return new Label({
      labelOwner,
      id: label.get('id'),
      details: label.get('details'),
      name: label.get('name'),
      category: label.get('category'),
      instanceCount: label.get('instanceCount'),
    });
  }
}
