import { useState } from 'react';

import columnKeysToDefinitions, {
  ColumnDefinitionKey,
} from 'Modules/ActionsRewrite/components/columnDefs/keysToColumnDefinitions';

export default function useColumnDefinitionsBuilder(
  initialColumnOrder: ColumnDefinitionKey[] = []
) {
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  const mapKeysToDefinitions = (columnKeys: ColumnDefinitionKey[]) =>
    columnKeys.map(key => columnKeysToDefinitions[key]).filter(Boolean);

  const [columnDefinitions, setColumnDefinitions] = useState(
    mapKeysToDefinitions(columnOrder)
  );

  const updateColumns = (newColumnKeys: ColumnDefinitionKey[]) => {
    const newDefinitions = mapKeysToDefinitions(newColumnKeys);

    setColumnOrder(newColumnKeys);
    setColumnDefinitions(newDefinitions);
    return newDefinitions;
  };

  // When useQuery hashes the query key, the order matters
  const columnsQueryKey = columnOrder.sort();

  return { columnOrder, columnDefinitions, updateColumns, columnsQueryKey };
}
