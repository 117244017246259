import * as React from 'react';

const ConfusedMan: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="146"
      height="146"
      fill="none"
      viewBox="0 0 146 146"
    >
      <g>
        <path
          fill="#03A3BB"
          fillOpacity="0.25"
          fillRule="evenodd"
          d="M73 146c40.317 0 73-32.683 73-73S113.317 0 73 0 0 32.683 0 73s32.683 73 73 73z"
          clipRule="evenodd"
        />
        <mask
          id="mask0"
          width="146"
          height="146"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M73 146c40.317 0 73-32.683 73-73S113.317 0 73 0 0 32.683 0 73s32.683 73 73 73z"
            clipRule="evenodd"
          />
        </mask>
        <g mask="url(#mask0)">
          <g>
            <path
              fill="#F19F4D"
              d="M92.828 45.272l-5.392-4.77s-13.614 3.863-14.516-2.4c0 0-2.167.549-2.702-.633-.536-1.18 3.67-5.883 3.67-5.883s.49-5.077 4.67-7.149c4.178-2.071 14.57-4.996 15.739-3.777 1.168 1.218 5.27 15.178 5.27 15.178s.861 6.018-6.739 9.434z"
            />
            <path
              fill="#016776"
              d="M104.169 77.954s-10.873 1.072-45.025 4.608c-40.452 4.187-57.59 23.775-66.061 48.595-2.654 7.788-.089 16.338 8.127 16.392l86.384.715 8.64-29.187 34.808-10.711c14.54-4.476 19.759-22.4 9.902-34.007L104.692 31.69 84.277 45.084l19.892 32.87z"
            />
            <g>
              <mask
                id="mask1"
                width="154"
                height="118"
                x="-8"
                y="31"
                maskUnits="userSpaceOnUse"
              >
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M104.104 78.067s-10.865 1.07-44.992 4.597C18.691 86.841 1.564 106.381-6.9 131.14c-2.652 7.768-.088 16.297 8.122 16.352l37.009.295 49.31.418 8.635-29.115 34.782-10.685c14.528-4.465 19.744-22.345 9.894-33.923l-36.225-42.564-20.4 13.36 19.877 32.79z"
                  clipRule="evenodd"
                />
              </mask>
              <g mask="url(#mask1)">
                <g>
                  <path
                    fill="#3361C2"
                    d="M-3.529 130.851c5.802 0 10.505-4.703 10.505-10.505 0-5.802-4.703-10.505-10.505-10.505-5.802 0-10.505 4.703-10.505 10.505 0 5.802 4.703 10.505 10.505 10.505z"
                  />
                  <path
                    fill="#EE6352"
                    d="M41.792 130.851c5.802 0 10.505-4.703 10.505-10.505 0-5.802-4.703-10.505-10.505-10.505-5.801 0-10.505 4.703-10.505 10.505 0 5.802 4.704 10.505 10.505 10.505z"
                  />
                  <path
                    fill="#EE6352"
                    d="M87.453 130.851c5.802 0 10.505-4.703 10.505-10.505 0-5.802-4.703-10.505-10.505-10.505-5.802 0-10.505 4.703-10.505 10.505 0 5.802 4.703 10.505 10.505 10.505z"
                  />
                  <path
                    fill="#EE6352"
                    d="M19.767 104.959c5.802 0 10.505-4.704 10.505-10.505 0-5.802-4.703-10.506-10.505-10.506-5.802 0-10.506 4.704-10.506 10.506 0 5.801 4.704 10.505 10.506 10.505z"
                  />
                  <path
                    fill="#EE6352"
                    d="M65.091 104.959c5.802 0 10.505-4.704 10.505-10.505 0-5.802-4.703-10.506-10.505-10.506-5.802 0-10.505 4.704-10.505 10.506 0 5.801 4.703 10.505 10.505 10.505z"
                  />
                  <path
                    fill="#EE6352"
                    d="M110.412 104.959c5.802 0 10.505-4.704 10.505-10.505 0-5.802-4.703-10.506-10.505-10.506-5.802 0-10.505 4.704-10.505 10.506 0 5.801 4.703 10.505 10.505 10.505z"
                  />
                  <path
                    fill="#EE6352"
                    d="M87.453 77.233c5.802 0 10.505-4.704 10.505-10.506 0-5.802-4.703-10.505-10.505-10.505-5.802 0-10.505 4.703-10.505 10.505 0 5.802 4.703 10.506 10.505 10.506z"
                  />
                  <path
                    fill="#EE6352"
                    d="M133.032 77.233c5.802 0 10.505-4.704 10.505-10.506 0-5.802-4.703-10.505-10.505-10.505-5.802 0-10.505 4.703-10.505 10.505 0 5.802 4.703 10.506 10.505 10.506z"
                  />
                  <path
                    fill="#EE6352"
                    d="M110.412 52.361c5.802 0 10.505-4.703 10.505-10.505 0-5.802-4.703-10.505-10.505-10.505-5.802 0-10.505 4.703-10.505 10.505 0 5.802 4.703 10.505 10.505 10.505z"
                  />
                  <path
                    fill="#3361C2"
                    d="M19.767 159.45c5.802 0 10.505-4.703 10.505-10.505 0-5.802-4.703-10.505-10.505-10.505-5.802 0-10.506 4.703-10.506 10.505 0 5.802 4.704 10.505 10.506 10.505z"
                  />
                </g>
              </g>
            </g>
            <path
              fill="#F19F4D"
              d="M62.892 96.202h-.333a10.57 10.57 0 01-4.106-.959 10.805 10.805 0 01-3.437-2.5 11.117 11.117 0 01-2.243-3.658 11.34 11.34 0 01-.707-4.262l1.036-38.957a11.216 11.216 0 013.372-7.745 10.646 10.646 0 017.72-3.03c2.863.08 5.578 1.324 7.547 3.459a11.248 11.248 0 012.953 7.923l-1.04 38.957a11.212 11.212 0 01-3.253 7.625 10.649 10.649 0 01-7.51 3.147z"
            />
            <path
              fill="#F19F4D"
              d="M70.47 74.65l2.086.044c2.425.051 4.832-.425 7.05-1.394a16.49 16.49 0 005.777-4.211 16.16 16.16 0 003.41-6.229c.657-2.298.792-4.71.394-7.064l-1.965-11.657a16.178 16.178 0 00-5.548-9.677 16.662 16.662 0 00-10.572-3.903 16.736 16.736 0 00-6.33 1.173 16.527 16.527 0 00-5.39 3.467 16.228 16.228 0 00-3.632 5.232 16.014 16.014 0 00-1.32 6.202l-.104 11.616a16.104 16.104 0 004.65 11.463 16.636 16.636 0 0011.495 4.939z"
            />
            <path
              fill="#F19F4D"
              d="M50.665 62.109c1.922 2.651 5.56 3.192 8.126 1.207 2.566-1.984 3.088-5.742 1.167-8.393l-1.972-2.72c-1.921-2.651-5.56-3.192-8.125-1.208-2.566 1.984-3.089 5.742-1.168 8.394l1.972 2.72z"
            />
            <path
              stroke="#002329"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.755"
              d="M50.93 55.459l4.527 4.527"
            />
            <path
              stroke="#002329"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.755"
              d="M80.275 48.667l1.213 9.729-3.395.457"
            />
            <path
              fill="#6B3E29"
              d="M60.469 58.853l2.99-17.286.707-4.103a13.587 13.587 0 003.757 2.34c4.989 2.1 19.24-.203 20.292-10.324.873-8.256-7.407-2.809-11.915-2.913-7.078-.167-12.324-3.805-21.438 2.187-10.914 7.175-4.086 21.734-4.086 21.734s4.89-2.388 9.693 8.365z"
            />
            <path
              fill="#24272D"
              d="M73.875 74.699l-5.968.003 5.968 4.504v-4.507z"
            />
            <path
              fill="#002329"
              d="M75.264 54.326a1.698 1.698 0 100-3.396 1.698 1.698 0 000 3.396z"
            />
            <path
              fill="#002329"
              d="M85.45 54.326a1.698 1.698 0 100-3.395 1.698 1.698 0 000 3.395z"
            />
            <path
              fill="#002329"
              d="M71.302 61.124c.149 0 4.91-.34 5.66 3.388l-5.66-3.388z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ConfusedMan;
