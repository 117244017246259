import GrooveSearchResult from '@groove/api/visualforce/types/GrooveSearch/GrooveSearchResult';
import moment from 'moment';

export type DisplayableSearchResult = {
  id: string;
  type: string | null;
  text: string;
  secondaryText?: string | null;
  tertiaryText?: string | null;
  quarteraryText?: string | null;
  quinaryText?: string | null;
  senaryText?: string | null;
  septenaryText?: string | null;
  originalSearchResult?: GrooveSearchResult;
  secondaryTextLabel?: string;
  tertiaryTextLabel?: string;
  quarteraryTextLabel?: string;
  quinaryTextLabel?: string;
  senaryTextLabel?: string;
  septenaryTextLabel?: string;
};

const transformSearchResult = (
  result: GrooveSearchResult,
): DisplayableSearchResult => {
  const displayableSearchResult = {
    id: result.Id,
    type: result.attributes.type,
    originalSearchResult: result,
  } as DisplayableSearchResult;
  switch (result.attributes.type) {
    case 'Contact':
      return {
        ...displayableSearchResult,
        text: result.Name || '<No Name>',
        secondaryText: result.Account?.Name,
        secondaryTextLabel: 'Account',
        tertiaryText: result.Email,
        tertiaryTextLabel: 'Email',
        quarteraryText: result.Title,
        quarteraryTextLabel: 'Title',
        quinaryText: moment(result.LastModifiedDate).format('ll'),
        quinaryTextLabel: 'Last Modified',
        senaryText: moment(result.LastActivityDate).format('ll'),
        senaryTextLabel: 'Last Activity',
      };
    case 'Account':
      return {
        ...displayableSearchResult,
        text: result.Name || '<No Name>',
        secondaryText: result.Type,
        secondaryTextLabel: 'Type',
        tertiaryText: result.Industry,
        tertiaryTextLabel: 'Industry',
        quarteraryText: moment(result.LastModifiedDate).format('ll'),
        quarteraryTextLabel: 'Last Modified',
        quinaryText: moment(result.LastActivityDate).format('ll'),
        quinaryTextLabel: 'Last Activity',
      };
    case 'Lead':
      return {
        ...displayableSearchResult,
        text: result.Name || '<No Name>',
        secondaryText: result.Company,
        secondaryTextLabel: 'Company',
        tertiaryText: result.Email,
        tertiaryTextLabel: 'Email',
        quarteraryText: moment(result.LastModifiedDate).format('ll'),
        quarteraryTextLabel: 'Last Modified',
        quinaryText: moment(result.LastActivityDate).format('ll'),
        quinaryTextLabel: 'Last Activity',
      };
    case 'Opportunity':
      return {
        ...displayableSearchResult,
        text: result.Name || '<No Name>',
        secondaryText: result.Type || 'Opportunity',
        secondaryTextLabel: 'Type',
        tertiaryText: result.Account?.Name || '<No Account>',
        tertiaryTextLabel: 'Account',
        quarteraryText: result.StageName,
        quarteraryTextLabel: 'Stage',
        quinaryText: moment(result.CloseDate).format('ll'),
        quinaryTextLabel: 'Close Date',
        senaryText: moment(result.LastModifiedDate).format('ll'),
        senaryTextLabel: 'Last Modified',
        septenaryText: moment(result.LastActivityDate).format('ll'),
        septenaryTextLabel: 'Last Activity',
      };
    default:
      return {
        ...displayableSearchResult,
        text: result.Name || result.Subject || '<No Name>',
        secondaryText: result.attributes.type,
        secondaryTextLabel: 'Type',
        tertiaryText: moment(result.LastModifiedDate).format('ll'),
        tertiaryTextLabel: 'Last Modified',
        quarteraryText: moment(result.LastActivityDate).format('ll'),
        quarteraryTextLabel: 'Last Activity',
      };
  }
};

export default transformSearchResult;
