import { Record, Map } from 'immutable';
import { generateSingleSelectOptionId } from 'Modules/Spaces/show/utils';
import { CUSTOM_COLUMN_TYPES } from 'Modules/Spaces/show/constants';

const schema = {
  id: null,
  name: '',
  options: null,
  editingUserId: null,
};

export default class SingleSelectColumn extends Record(
  schema,
  'SingleSelectColumn'
) {
  static from(attributes = {}) {
    const values = { ...schema, ...attributes };
    const { id, name, options, editingUserId } = values;

    const optionsToSet =
      options ||
      new Map({
        [generateSingleSelectOptionId(1)]: {
          label: 'Option 1',
          editingUserId: null,
        },
        [generateSingleSelectOptionId(2)]: {
          label: 'Option 2',
          editingUserId: null,
        },
        [generateSingleSelectOptionId(3)]: {
          label: 'Option 3',
          editingUserId: null,
        },
      });

    return new SingleSelectColumn({
      id,
      name,
      options: optionsToSet,
      editingUserId,
    });
  }

  formatForFirestore() {
    return {
      type: CUSTOM_COLUMN_TYPES.SINGLE_SELECT,
      name: this.name,
      options: this.options.toJS(),
      editingUserId: null,
    };
  }
}
