import { Record } from 'immutable';
import { ERROR_TYPES } from 'Modules/Shared/constants';

const defaults = {
  error: new Map(),
  errorType: ERROR_TYPES.GENERIC,
  isUiError: false,
};

class ErrorDetails extends Record(defaults) {}

export default ErrorDetails;
