import { styled } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const WarningMessage = styled('div')(() => ({
  margin: 10,
  display: 'flex',
  justifyContent: 'space-between',
}));

export const Left = styled('div')(() => ({
  display: 'flex',
}));

export const Right = styled('div')(() => ({
  display: 'flex',
}));

export const Warning = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '16px',
  letterSpacing: 'normal',
  textAlign: 'left',
  color: ' #6f5400',
  fontSize: 12,
  margin: 'auto',
}));

export const SuccessMessage = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '16px',
  letterSpacing: 'normal',
  textAlign: 'left',
  color: ' #107C10',
  fontSize: 12,
  margin: 'auto',
}));

export const SuccessIcon = styled(CheckCircleIcon)(() => ({
  color: '#107C10',
  width: 20,
  height: 20,
  padding: 5,
  margin: 'auto',
  marginRight: 8,
}));

export const IconWarning = styled(WarningIcon)(() => ({
  color: '#dd9200',
  width: 20,
  height: 20,
  padding: 5,
  margin: 'auto',
  marginRight: 8,
}));

export const FixButton = styled(Button)(() => ({
  height: 24,
  width: 60,
  display: 'flex',
  flexDirection: 'row',
  padding: '2px 20px',
  borderRadius: 4,
  border: 'solid 1px #757575',
  backgroundColor: '#fff',
  marginTop: 4,
  margin: 'auto',
  marginLeft: 12,
  minWidth: 60,
  minHeight: 24,
  '&:hover': {
    backgroundColor: '#EEEEEE',
  },
}));

export const FixButtonText = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  lineHeight: '16px',
  letterSpacing: 'normal',
  fontSize: 12,
  color: '#04294c',
  textTransform: 'capitalize',
}));

export const CloseButton = styled(CloseIcon)(() => ({
  width: 16,
  height: 16,
  color: '#6f5400;',
  cursor: 'pointer',
  margin: 'auto',
  marginLeft: 4,
}));
