import { FC } from 'react';
import Text from '@groove/ui/Components/Text';
import { useGetEmailAliases } from '@groove/api/hooks/useUsers';
import PicklistNew, {
  StandardDropDownItem,
} from '@groove/ui/Components/PicklistNew';

import useStore from '../store/useStore';

const EmailFromDropDown: FC = () => {
  const fromEmail = useStore(store => store.action.fromEmail);

  // Email alias list is used as the dropdown list values
  const { data: emailAliasList } = useGetEmailAliases();
  const selectedEmail: StandardDropDownItem[] = [];

  // FromEmail is created with the default alias setting value and updated with any selection
  if (fromEmail) {
    selectedEmail.push({
      key: fromEmail,
      value: fromEmail,
      text: fromEmail,
    });
  }

  const emailItems =
    emailAliasList?.data?.map(alias => {
      const dropDownItem: StandardDropDownItem = {
        key: alias.email,
        value: alias.email,
        text: alias.name ? `${alias.name} <${alias.email}>` : alias.email,
      };

      return dropDownItem;
    }) || [];

  if (emailItems.length < 2) return null;

  return (
    <div className="flex flex-row items-center h-[40px] border-0 !border-b border-solid border-neutral/75">
      <Text className="text-body text-neutral/600 cursor-text">From</Text>
      <PicklistNew
        selectedValues={selectedEmail}
        items={emailItems}
        borderNone
        onChange={item =>
          useStore.getState().updateAction({ fromEmail: item?.value as string })
        }
      />
    </div>
  );
};

export default EmailFromDropDown;
