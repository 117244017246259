import { actions } from '@groove-labs/groove-ui';
import { globalPeopleSearch } from 'GrooveHTTPClient/people';
import { isEmpty } from 'lodash-es';
import {
  actionTypes as appActionTypes,
  clearSearchResults,
  setTopNavTabVisibility,
} from 'Modules/App/actions';
import {
  GLOBAL_SEARCH_FIELD_ID,
  GLOBAL_SEARCH_GROUP_ID,
  PERSON_TO_REMOVE_UI_KEY_PATH,
  FLOW_TO_REMOVE_UI_KEY_PATH,
  REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH,
  SEARCH_FILTER_VALUES,
} from 'Modules/App/constants';
import { getSearchFilter, getSearchInProgress } from 'Modules/App/selectors';
import { fetchPeopleSuccess, setActivePersonId } from 'Modules/People/actions';
import { actionTypes as locationActionTypes } from 'Modules/Shared/actions/location';
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { getPathName, history } from 'Utils/history';
import handleRemovePersonFromFlow from 'Modules/App/sagas/handleRemovePersonFromFlow';
import handleRemovePersonFromAllFlows from 'Modules/App/sagas/handleRemovePersonFromAllFlows';
import handleUpdateLocalSearchData from 'Modules/App/sagas/handleUpdateLocalSearchData';

import { routeMatcher } from '../../routing/routesSaga.js';

const { updateFieldValue } = actions.form;
const { batchSetProperty } = actions.ui;

// A generic saga meant to be called by the saga for each top level module that supports search.
// This is built this way because each module's saga might need to fetch and save data in unique ways.
// This lets this saga and any 'client' saga save/load data independently of each other.
export function* handleTypeaheadSearch(saga, args) {
  yield put({ type: appActionTypes.TYPEAHEAD_SEARCH.BEGIN });

  yield put({ type: appActionTypes.TYPEAHEAD_SEARCH.PROGRESS });

  // Make query for typeahead data
  let response;

  try {
    response = yield call(saga, ...args);
  } catch (e) {
    yield put({ type: appActionTypes.TYPEAHEAD_SEARCH.FAILURE });
    return null;
  }

  yield put({
    type: appActionTypes.TYPEAHEAD_SEARCH.SUCCESS,
    payload: response.data.results,
  });

  return response;
}

// -------------- Queries --------------
function* queryPeople(action) {
  const { payload } = action;
  const query = {
    filters: [
      {
        field: 'name_or_account_or_email',
        value: payload.trim(),
      },
    ],
    orderBy: 'engagement',
    orderDir: 'desc',
    offset: 0,
    limit: 15,
    scope: 'all_people_in_organization',
    omitTotal: true,
  };

  // Trigger the search action
  yield call(handleTypeaheadSearch, globalPeopleSearch, [query]);
}

function* handleGlobalSearch(action) {
  // debounce by 500ms
  yield delay(500);

  yield put(clearSearchResults());

  const { payload } = action;

  // if the if the searchQuery is empty (i.e. select all and delete), we exit the saga
  if (isEmpty(payload)) {
    const searchInProgress = yield select(getSearchInProgress);

    if (searchInProgress) {
      yield put({ type: appActionTypes.TYPEAHEAD_SEARCH.FAILURE });
    }
    return;
  }

  const searchFilter = yield select(getSearchFilter);

  let queryAction;

  switch (searchFilter) {
    case SEARCH_FILTER_VALUES.PEOPLE: {
      queryAction = queryPeople;
      break;
    }
    case SEARCH_FILTER_VALUES.ACCOUNTS: {
      // queryAction = queryPeople
      break;
    }
    case SEARCH_FILTER_VALUES.FLOWS: {
      // queryAction = queryPeople
      break;
    }
    case SEARCH_FILTER_VALUES.TEMPLATES: {
      // queryAction = queryPeople
      break;
    }

    default: {
      break;
    }
  }

  // Trigger the search action
  yield call(queryAction, action);
}

function* handlePersonSelect(person) {
  // Redirect to person page if we're not already there
  const fullPath = getPathName();

  const matchedRoute = routeMatcher.find(fullPath);

  if (matchedRoute.routeName !== 'people') {
    yield call(history.push, '/people');

    // Block until the location update is resolved.
    yield take(locationActionTypes.FINISH_CHANGE);
  }

  yield put(setTopNavTabVisibility(false));
  yield put(setActivePersonId(person.id));
  yield put(fetchPeopleSuccess(person));
}

function* handleGlobalSearchSelect(action) {
  const { payload: selectedItem } = action;

  const searchFilter = yield select(getSearchFilter);
  let saga;

  switch (searchFilter) {
    case SEARCH_FILTER_VALUES.PEOPLE: {
      saga = handlePersonSelect;
      break;
    }
    case SEARCH_FILTER_VALUES.ACCOUNTS: {
      // saga = queryPeople
      break;
    }
    case SEARCH_FILTER_VALUES.FLOWS: {
      // saga = queryPeople
      break;
    }
    case SEARCH_FILTER_VALUES.TEMPLATES: {
      // saga = queryPeople
      break;
    }

    default: {
      break;
    }
  }
  // call the appropriate saga based on the type of selectedItem that was selected
  yield call(saga, selectedItem);
}

function* handleGlobalSearchFilterChange() {
  // Clean input value when search filter changes.
  yield put(
    updateFieldValue({
      groupId: GLOBAL_SEARCH_GROUP_ID,
      fieldId: GLOBAL_SEARCH_FIELD_ID,
      value: '',
    })
  );
}

function* handleShowRemovePersonFromAllFlowsDialog({ payload: { person } }) {
  yield put(
    batchSetProperty([
      {
        uiKeyPath: REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH,
        data: true,
      },
      {
        uiKeyPath: PERSON_TO_REMOVE_UI_KEY_PATH,
        data: person,
      },
    ])
  );
}

function* handleShowRemovePersonFromFlowDialog({ payload: { person, flow } }) {
  yield put(
    batchSetProperty([
      {
        uiKeyPath: REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH,
        data: true,
      },
      {
        uiKeyPath: PERSON_TO_REMOVE_UI_KEY_PATH,
        data: person,
      },
      {
        uiKeyPath: FLOW_TO_REMOVE_UI_KEY_PATH,
        data: flow,
      },
    ])
  );
}

// -------------- Watchers --------------
function* watchGlobalSearch() {
  // will cancel current running handleInput task
  yield takeLatest(appActionTypes.SEARCH_QUERY_UPDATED, handleGlobalSearch);
}

function* watchLocationUpdate() {
  yield takeEvery(
    locationActionTypes.FINISH_CHANGE,
    handleGlobalSearchFilterChange
  );
}

function* watchGlobalSearchSelect() {
  yield takeEvery(appActionTypes.SEARCH_ITEM_SELECT, handleGlobalSearchSelect);
}

function* watchGlobalSearchFilterChange() {
  yield takeEvery(
    appActionTypes.SEARCH_FILTER_UPDATED,
    handleGlobalSearchFilterChange
  );
}

function* watchShowRemovePersonFromFlowDialog() {
  yield takeEvery(
    appActionTypes.LOAD_REMOVE_FROM_FLOW_CONFIRM_DIALOG_DATA,
    handleShowRemovePersonFromFlowDialog
  );
}

function* watchShowRemovePersonFromAllFlowsDialog() {
  yield takeEvery(
    appActionTypes.LOAD_REMOVE_FROM_ALL_FLOWS_CONFIRM_DIALOG_DATA,
    handleShowRemovePersonFromAllFlowsDialog
  );
}

function* watchConfirmRemovePersonFromAllFlows() {
  yield takeEvery(
    appActionTypes.REMOVE_PERSON_FROM_ALL_FLOWS.BEGIN,
    handleRemovePersonFromAllFlows
  );
}

function* watchConfirmRemovePersonFromFlow() {
  yield takeEvery(
    appActionTypes.REMOVE_PERSON_FROM_FLOW.BEGIN,
    handleRemovePersonFromFlow
  );
}

function* watchSuccessRemovePerson() {
  yield takeEvery(
    [
      appActionTypes.REMOVE_PERSON_FROM_FLOW.SUCCESS,
      appActionTypes.REMOVE_PERSON_FROM_ALL_FLOWS.SUCCESS,
    ],
    handleUpdateLocalSearchData
  );
}

export default function* root() {
  yield all([
    fork(watchGlobalSearch),
    fork(watchLocationUpdate),
    fork(watchGlobalSearchSelect),
    fork(watchGlobalSearchFilterChange),
    fork(watchShowRemovePersonFromFlowDialog),
    fork(watchShowRemovePersonFromAllFlowsDialog),
    fork(watchConfirmRemovePersonFromAllFlows),
    fork(watchConfirmRemovePersonFromFlow),
    fork(watchSuccessRemovePerson),
  ]);
}
