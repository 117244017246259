import getClient from './getClient';
import { Template, TemplateType } from './template';

export type ApiFavoriteTemplates = {
  data: Template[];
};

export const favoriteTemplatesGet = (
  templateType: TemplateType,
  templateSubType = '',
): Promise<ApiFavoriteTemplates> => {
  return getClient()
    .post('favorite_templates/get', {
      json: {
        variables: {
          template_type: templateType,
          template_subtype: templateSubType,
        },
      },
    })
    .json();
};
