import getClient, { TunnelStrategy, strategy } from './getClient';
import GrooveUpdate from './types/GrooveUpdate';

export type ApiGrooveUpdate = GrooveUpdate;

export type GrooveUpdateParams = {
  id: string;
  sobject: string;
  [additionalParams: string]: string | number | null;
};

/**
 * API to send bulk edit request to sfdc
 * Uses GrooveEdit Apex class with 'update' action
 *
 * @param id string sfdc id of the object we are updating
 * @param sobject string sfdc object type - contact/lead/opp etc
 * @param additionalParams [key: string]: string|number|null all other info sent as a hash
 *
 * @returns GrooveUpdate response message and status
 */
const grooveUpdate = async ({
  id,
  sobject,
  ...additionalParams
}: GrooveUpdateParams): Promise<ApiGrooveUpdate> => {
  const response = await getClient()
    .get((strategy as TunnelStrategy)?.path || 'api/v2/visualforce/tunnel', {
      searchParams: {
        page: 'GrooveEdit',
        action: 'update',
        id,
        sobject,
        ...additionalParams,
      },
    })
    .json<ApiGrooveUpdate>();
  if (response.resultCode !== 200) {
    throw new Error(response.resultmessage);
  }
  return response;
};

export default grooveUpdate;
