import { trackEvent } from 'Utils/segment';
import { takeEvery, take } from 'redux-saga/effects';
import {
  createSafeActionHandler,
  ANALYTICS_BASE_PROPERTIES,
} from 'Modules/Shared/sagas/analytics';
import { actionTypes } from 'Modules/Shared/actions/steps';

function* stepCreated() {
  yield takeEvery(
    actionTypes.CREATE_OR_UPDATE_STEP.BEGIN,
    createSafeActionHandler(function* track({ payload }) {
      const { stepId } = payload;
      const isNewStep = !stepId;
      if (isNewStep) {
        const step = (yield take(actionTypes.CREATE_OR_UPDATE_STEP.SUCCESS))
          .payload.step;
        trackEvent('Step Created', {
          ...ANALYTICS_BASE_PROPERTIES,
          featureCategory: 'Steps',
          stepType: step.get('stepType').toLowerCase(),
          priority: step.get('priority'),
        });
      }
    })
  );
}

export default stepCreated;
