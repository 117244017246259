module.exports = {
  mode: 'jit',
  important: '.groove',
  purge: ['apps/**/*.{js,jsx,ts,tsx,vue}', 'libs/**/*.{js,jsx,ts,tsx,vue}'],
  darkMode: false, // or 'media' or 'class'
  corePlugins: {
    preflight: false,
    backgroundOpacity: false,
    textOpacity: false,
    container: false,
  },
  theme: {
    extend: {
      fontFamily: {
        groove:
          'MaisonNeue, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif',
      },
      flex: {
        0: '0',
        'flex-auto': '1 1 auto',
      },
      fontSize: {
        'metadata-xs': '10px',
        'metadata-sm': '11px',
        metadata: '12px',
        'body-sm': '13px',
        body: '14px',
        'body-lg': '15px',
        'subject-title': '16px',
        header: '18px',
        'mobile-label': '17px',
        'pane-header': '20px',
        'page-title': '28px',
        'greeting-title': '32px',
        'hero-title': '42px',
        'numerics-xl': '68px',
      },
    },
    fontFamily: {
      display: [
        'MaisonNeue',
        'ui-sans-serif',
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        '"Liberation Sans"',
        'sans-serif',
      ],
    },
    colors: {
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',

      // Neutral colors
      white: '#FFFFFF',
      'neutral/25': '#F8F7F7',
      'neutral/50': '#F3F3F3',
      'neutral/75': ' #EEEEEE',
      'neutral/100': '#DCDCDC',
      'neutral/200': '#C4C4C4',
      'neutral/400': '#969696',
      'neutral/600': '#6B6B6B',
      'neutral/900': '#303030',

      // Blues
      'groovy-bluey/10': '#EBF8FA',
      'surreal-teal/600': '#0960B2',
      'bubblegum-blue/300': '#41B3FF',
      'info/50': '#E7F3FE',
      'info/600': '#0960B2',
      'info/900': '#053461',
      'clari-blue/200': '#67b3ff',
      'clari-blue/500': '#0280ff',
      'clari-blue/600': '#2563EB',
      'clari-blue/700': '#1D4ED8',
      'clari-blue/800': '#1E40AF',

      // Oranges
      'warning/50': '#FFF4CE',
      'warning/600': '#DD9200',
      'warning/900': '#6F5400',
      'tangerine-dream/300': '#FBC122',
      'naranja/25': '#FCF5EE',
      'naranja/50': '#FFDD7E',
      'naranja/400': '#F19539',

      // Pinks and reds
      'bittersweet-red': '#FF6666',
      'razzy-red/25': '#FFF3F8',
      'razzy-red/100': '#FF98CB',
      'razzy-red/500': '#E41F6B',
      'error/50': '#FDE7E9',
      'error/600': '#BA1B1B',
      'error/900': '#831515',

      // Purples
      'grapeshine/25': 'F5F2FD',
      'grapeshine/200': '#C9A7FF',
      'grapeshine/600': '#553E9B',

      // Greens
      'success/50': '#DFF6DD',

      'success/600': '#107C14',
      'success/900': '#083F08',

      // Turquoise
      'turquoise/500': '#00d7b8',

      /**
       *
       * Below are the old tailwind colors that will need to be deleted as we transition
       * to the new color pallette.
       *
       * Once the colors below have been removed from our repo, please delete them and use
       * the colors above.
       *
       */

      // Wavy Navies
      'wavyNavy/900': '#4b4b4b', // Theme Primary
      'wavyNavy/600': '#4E668F',
      'wavyNavy/100': '#CBDDF9',
      'wavyNavy/50': '#E1EEFC',
      'wavyNavy/25': '#F2F9FF',

      // Groovey Bluey
      'grooveyBluey/800': '#008182',
      'grooveyBluey/600': '#00A2A3',
      'grooveyBluey/300': '#2680eb', // Theme Primary
      'grooveyBluey/200': '#81E4E4',
      'grooveyBluey/100': '#A8F0F0',
      'grooveyBluey/25': '#E4F8F9',
      'grooveyBluey/10': '#EBF8FA',

      // Tangerine Dream
      'tangerineDream/300': '#FBC122',

      // Surreal Teal
      'surrealTeal/600': '#0960B2',

      // Bubblegum Blue
      'bubblegumBlue/300': '#41B3FF',

      // Razzy Red
      'razzyRed/500': '#E41F6B',
      'razzyRed/100': '#FF98CB',
      'razzyRed/25': '#FFF3F8',

      // TODO: Deprecate
      // The following colors are being sunset
      primary: '#303030',
      'primary-dark': '#577693',
      'primary-light': '#C6DAEC',
      'primary-lighter': '#E1ECF7',
      'primary-lighter-alt': '#f2f9ff',
      'primary-dark-alt': '#0960B2',
      secondary: '#29D5D5',
      'secondary-light': '#B8ECED',
      'secondary-lighter': '#E4F8F9',
      'secondary-lighter-alt': '#A4E2E3',
      'secondary-dark': '#03A3BB',
      'secondary-dark-alt': '#007e91',
      'secondary-lightest': '#EEF6F7',
      tertiary: '#FBC122',
      black: '#303030',
      gray0: '#535353',
      gray1: '#757575',
      gray2: '#9E9E9E',
      gray3: '#CACACA',
      gray3_5: '#eeecec',
      gray4: '#F3F2F1',
      gray4_5: '#dfe0e0',
      gray5: '#F8F7F7',
      gray6: '#EEEEEE',
      'spot-on-cotton': '#F8F7F7',
      background: '#F8F7F7',
      whiteAlt: '#f8f7f7',
      warning: '#fff4ce',
      severeWarning: '#D83B01',
      red: '#A80000',
      redDark: '#831515',
      redLight: '#FDE7E9',
      redLighter: '#FFDAD4',
      redAlt: '#EE6352',
      redAltLight: '#F44336',
      green: '#107C10',
      greenLight: '#DFF6DD',
      blueLight: '#b9ebed',
      orange: '#F19539',
      purple: '#553E9B',
      'groove-extended-1': '#41B3FF',
      'groove-extended-2': '#093D74',
      'groove-extended-3': '#E41E6B',
      error2: '#DD3730',
      success4: '#4CAF50',
      backgroundGreen: '#E4F5DF',
      backgroundRed: '#F9DCD5',
      backgroundBlueGray: '#eef6f7',
      magenta: '#e41f6b',
      magentaLight: '#fff3f8',
    },
    stroke: {
      black: '#303030',
    },
    boxShadow: {
      '3xl': '0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)',
    },
  },
  variants: {
    extend: {
      cursor: ['disabled'],
      backgroundColor: ['disabled'],
      color: ['disabled'],
    },
    lineClamp: ['responsive', 'hover'],
  },
  plugins: [require('@tailwindcss/line-clamp')],
};
