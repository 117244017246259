import { decamelizeKeys } from 'humps';

import makeHTTPRequest from '../makeHTTPRequest';
import { PUT, POST } from '../HTTPMethod';

const BASE_PATH = '/api/v2/settings';

export const getFlowSettings = () =>
  makeHTTPRequest({
    path: `${BASE_PATH}/flows`,
  });

export const getEmailSignature = (alias = null) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/fetch_email_signature`,
    queryParameters: {
      ...(alias && { alias }),
    },
  });

export const getEmailCalendarSettings = () =>
  makeHTTPRequest({
    path: `${BASE_PATH}/email_calendar`,
  });

export const getSchedulerSettings = () =>
  makeHTTPRequest({
    path: `${BASE_PATH}/scheduler`,
  });

export const getDialerSettings = () =>
  makeHTTPRequest({
    path: `${BASE_PATH}/dialer`,
  });

export const getAccountSettings = () =>
  makeHTTPRequest({
    path: `${BASE_PATH}/account`,
  });

export const deprovisionPhoneNumbers = () =>
  makeHTTPRequest({
    path: `${BASE_PATH}/deprovision_phone_numbers`,
    method: POST,
  });

export const deprovisionGrooveNumber = () =>
  makeHTTPRequest({
    path: `${BASE_PATH}/deprovision_groove_number`,
    method: POST,
  });

export const updateEmailSync = value =>
  makeHTTPRequest({
    path: `${BASE_PATH}/update_email_sync`,
    method: POST,
    body: {
      value,
    },
  });

export const checkAbilityToLogEventsInSalesforce = () =>
  makeHTTPRequest({
    path: `${BASE_PATH}/check_ability_to_log_events_in_salesforce`,
  });

export const checkAbilityToLogEmailsInSalesforce = () =>
  makeHTTPRequest({
    path: `${BASE_PATH}/check_ability_to_log_emails_in_salesforce`,
  });

export const setCallForwardingPhoneNumber = ({ phoneNumber }) =>
  makeHTTPRequest({
    path: '/dialer/phone_numbers/set_call_forwarding_number',
    method: POST,
    body: decamelizeKeys({
      phoneNumber,
    }),
  });

export const updateBridgePhoneNumber = ({ bridgeDialNumber, id }) =>
  makeHTTPRequest({
    path: '/dialer/phone_numbers/update_bridge_number',
    method: POST,
    body: decamelizeKeys({
      bridgeDialNumber,
      id,
    }),
  });

export const updateCallerIdPhoneNumber = ({ id }) =>
  makeHTTPRequest({
    path: '/dialer/phone_numbers/update_caller_id_number',
    method: POST,
    body: {
      id,
    },
  });

export const updateCalendarSync = value =>
  makeHTTPRequest({
    path: `${BASE_PATH}/update_calendar_sync`,
    method: POST,
    body: {
      value,
    },
  });

export const update = ({
  scheduler_link,
  send_daily_digest_email,
  progress_flows_on_weekdays_only,
  scheduler_welcome_message,
  track_events_in_salesforce_by_default,
  disable_logged_out_notices,
  scheduler_private_email,
  send_as_email,
  time_zone,
  bridge_dialing_enabled,
  call_forwarding_enabled,
  user_verified_phone_number,
  bridge_calling_phone_number,
  caller_id_phone_number,
  email_signature,
  pause_flows_until,
  sticky_filters,
}) =>
  makeHTTPRequest({
    path: BASE_PATH,
    method: PUT,
    body: {
      scheduler_link,
      send_daily_digest_email,
      progress_flows_on_weekdays_only,
      scheduler_welcome_message,
      track_events_in_salesforce_by_default,
      disable_logged_out_notices,
      scheduler_private_email,
      send_as_email,
      time_zone,
      bridge_dialing_enabled,
      call_forwarding_enabled,
      user_verified_phone_number,
      bridge_calling_phone_number,
      caller_id_phone_number,
      email_signature,
      pause_flows_until,
      sticky_filters,
    },
  });

export const removeVerifiedNumber = () =>
  makeHTTPRequest({
    path: `${BASE_PATH}/remove_verified_number`,
    method: POST,
  });
