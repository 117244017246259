import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import { HTTPError } from 'ky';

import {
  MutateAction,
  createAction,
  updateAction,
  deleteAction,
  FullAction,
  RecipientType,
  SourceType,
  ActionType,
} from '../gateway/v1/actionCompose';

export type CreateActionOptions = UseMutationOptions<
  FullAction,
  string | HTTPError,
  FullAction,
  unknown
>;
export type UpdateActionOptions = UseMutationOptions<
  string,
  string | HTTPError,
  FullAction,
  unknown
>;

export type DeleteActionOptions = UseMutationOptions<
  number[],
  string | HTTPError,
  number | string,
  unknown
>;

export const getActionSource = (action: FullAction): SourceType => {
  let source: SourceType = 'ONE_OFF';

  if (action.playNodeRunId) source = 'PLAY';
  else if (action.personStepId) source = 'FLOW';
  else if (action.workspaceAccountTaskId || action.workspaceSObjectTaskId)
    source = 'WORKSPACE';
  else if (action.source) source = action.source;

  return source;
};

const actionTypeEnglish = (type: ActionType): string => {
  const englishTypes = {
    TEMPLATE: 'Email',
    CALL: 'Call',
    STEP_SUBTYPE_LINKEDIN_INMAIL: 'LinkedIn InMail',
    STEP_SUBTYPE_LINKEDIN_CONNECT: 'LinkedIn Connect',
    STEP_SUBTYPE_SENDOSO: 'Sendoso',
    STEP_SUBTYPE_SMS: 'SMS',
    STEP_SUBTYPE_IN_PERSON_VISIT: 'In Person Visit',
    STEP_SUBTYPE_DIRECT_MAIL: 'Direct Mail',
    OTHER: 'Other',
    GENERAL: 'Task',
    WORKSPACE_ACCOUNT_TASK: 'Workspace Task',
    UPDATE_SALESFORCE: 'Update Salesforce',
  };
  return englishTypes[type] || englishTypes.GENERAL;
};

const getActionSummary = (action: FullAction): string => {
  let { summary } = action;
  const typeEnglish = actionTypeEnglish(action.type);
  if (!summary?.trim() && getActionSource(action) === 'ONE_OFF') {
    if (action.subject && action.subject.length > 3) summary = action.subject;
    else if (action.loggingTo?.who?.[0]?.text)
      summary = `${typeEnglish} with ${action.loggingTo.who[0].text}`;
    else if (action.loggingTo?.what?.[0]?.text)
      summary = `${typeEnglish} for ${action.loggingTo.what[0].text}`;
    else summary = `${typeEnglish} Action`;
  }
  return summary || '%%';
};

export const cleanAction = (action: FullAction): MutateAction => ({
  accountId: action.accountId,
  assigneeId: action.assignee?.id || null,
  bccRecipients:
    action.bccRecipients?.map(recipient => {
      const object: RecipientType = {
        id: recipient.id,
        email: recipient.email,
        emailField: recipient.emailField,
        phone: recipient.phone,
        phoneField: recipient.phoneField,
        name: recipient.name,
        sfdcId: recipient.sfdcId,
      };
      if (recipient.attributes?.type)
        object.attributes = { type: recipient.attributes?.type };
      return object;
    }) || null,
  body: action.body,
  ccRecipients:
    action.ccRecipients?.map(recipient => {
      const object: RecipientType = {
        id: recipient.id,
        email: recipient.email,
        emailField: recipient.emailField,
        phone: recipient.phone,
        phoneField: recipient.phoneField,
        name: recipient.name,
        sfdcId: recipient.sfdcId,
      };
      if (recipient.attributes?.type)
        object.attributes = { type: recipient.attributes?.type };
      return object;
    }) || null,
  crmResults: action.crmResults,
  crmTaskType: action.crmTaskType,
  description: action.description,
  dueAt: action.dueAt,
  dynamicData: action.dynamicData,
  executeAt: action.executeAt,
  fromEmail: action.fromEmail,
  fromPhoneNumber: action.fromPhoneNumber,
  loggingTo: action.loggingTo,
  notes: action.notes,
  personId: action.personId,
  priority: action.priority,
  results: action.results,
  subject: action.subject,
  summary: getActionSummary(action),
  toRecipients:
    action.toRecipients?.map(recipient => {
      const object: RecipientType = {
        id: recipient.id,
        email: recipient.email,
        emailField: recipient.emailField,
        phone: recipient.phone,
        phoneField: recipient.phoneField,
        name: recipient.name,
        sfdcId: recipient.sfdcId,
      };
      if (recipient.attributes?.type)
        object.attributes = { type: recipient.attributes?.type };
      return object;
    }) || null,
  type: action.type,
  twilioSid: action.twilioSid,
  templateId: action.templateId,
  variantId: action.variantId,
});

export const useCreateAction = (
  mutationOptions: CreateActionOptions = {},
): UseMutationResult<FullAction, unknown, FullAction, unknown> =>
  useMutation(action => createAction(cleanAction(action)), {
    ...mutationOptions,
    mutationKey: ['createAction'],
  });

export const useUpdateAction = (
  mutationOptions: UpdateActionOptions = {},
): UseMutationResult<string, unknown, FullAction, unknown> =>
  useMutation(action => updateAction(cleanAction(action), action.id), {
    ...mutationOptions,
    mutationKey: ['updateAction'],
  });

export const useDeleteAction = (
  mutationOptions: DeleteActionOptions = {},
): UseMutationResult<number[], unknown, number | string, unknown> =>
  useMutation(actionId => deleteAction(actionId), {
    ...mutationOptions,
    mutationKey: ['deleteAction'],
  });
