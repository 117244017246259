import { CloseIcon } from '@groove/ui/Components/BoogieIcon';
import { IconButton, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import { InfoIcon } from '@fluentui/react-icons-mdl2';
import Text from '@groove/ui/Components/Text';
import { FC, useEffect, useState } from 'react';
import { format } from 'date-fns';
import ButtonArraySingle from '@groove/ui/Components/ButtonArraySingle';
import Button from '@groove/ui/Components/Button';
import {
  ColdEmailParams,
  GeneratedContent,
  GenerateContentsError,
  generateColdEmail as generateColdEmailApi,
  generateFollowupEmail as generateFollowupEmailApi,
} from '@groove/api/gateway/v1/generateContents';
import { useMutation, useQuery } from 'react-query';
import PicklistNew, {
  StandardDropDownItem,
} from '@groove/ui/Components/PicklistNew';
import {
  CopilotCall,
  getCopilotCall,
  listCopilotCalls,
} from '@groove/api/gateway/v1/copilotCalls';
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';
import { useGetOrgSettings } from '@groove/api/hooks/useUsers';
import DropDownList from '@groove/ui/Components/DropDownList';
import { NEW_ACTION_ID } from '@groove/api/gateway/v1/actionCompose';
import { availableValuePropositions } from '@groove/api/gateway/v1/users';
import {
  ActivityType,
  CopilotActionEvent,
  CopilotEventType,
  trackCopilot,
} from '@groove/api/analytics/trackCopilot';

import {
  ACTION_EVENT,
  ACTION_VERSION,
  CALL_PROCESSED,
  CONTENT_TYPES,
  EMAIL_GENERATION_TYPES,
  EMAIL_WRITING_STYLES,
  FOLLOW_UP_TONE_TYPES,
} from '../constants';
import useStore from '../store/useStore';
import {
  EMAIL_GENERATION_ERROR_ID,
  Intent,
  Tone,
} from '../store/EmailGenerationStore';
import { trackActionEvents } from '../analytics/actionComposeEvents';

const EmailGenerationPane: FC = () => {
  const recipients = useStore(store => store.action.toRecipients);
  const actionId = useStore(store => store.action.id);

  const recipient = recipients?.[0];

  const defaultEmailGeneration = useStore(
    store => store.defaultEmailGeneration,
  );

  const [selectedIntent, setSelectedIntent] = useState<Intent | null>(
    defaultEmailGeneration.emailIntent,
  );
  const [selectedTone, setSelectedTone] = useState<Tone>('NEUTRAL');
  const [selectedCopilotCall, setSelectedCopilotCall] =
    useState<CopilotCall | null>(defaultEmailGeneration.copilotCall);
  const [isGetCallLoading, setIsGetCallLoading] = useState(false);
  const [valueProp, setValueProp] = useState<StandardDropDownItem | null>(null);

  const generatedContent = useStore
    .getState()
    .getCurrentGeneratedContent(selectedCopilotCall?.icaluid ?? '');

  const onClose = (): void => useStore.getState().closeGenerationPane();
  const [writingStyle, setWritingStyle] = useState<StandardDropDownItem | null>(
    null,
  );
  const [contentType, setContentType] = useState<StandardDropDownItem | null>(
    null,
  );

  const { data: orgSettings } = useGetOrgSettings();
  const fromEmail = useStore(store => store.action.fromEmail);
  const prospectingV2Flag = useVariation('gen-ai-prospecting-emails-v2');
  const prospectingEnabled = useVariation('gen-ai-prospecting-emails');
  const followupFlag = useVariation('gen-ai-smart-followups');
  const followupEnabled = followupFlag && !!orgSettings?.copilot_enabled;

  const filteredEmailTypes = [];
  if (prospectingEnabled) filteredEmailTypes.push(EMAIL_GENERATION_TYPES[0]);
  if (followupEnabled) filteredEmailTypes.push(EMAIL_GENERATION_TYPES[1]);
  const { personStepId } = useStore.getState().action;

  if (!selectedIntent) {
    if (prospectingEnabled && (personStepId || !followupEnabled))
      setSelectedIntent('COLD_EMAIL');
    else if (followupEnabled) setSelectedIntent('FOLLOWUP_EMAIL');
  }

  const { data: valuePropositions } = useQuery(
    'listValuePropositions',
    availableValuePropositions,
    { enabled: selectedIntent === 'COLD_EMAIL' && prospectingV2Flag },
  );
  const renderedValueProps: StandardDropDownItem[] =
    valuePropositions?.map(item => ({
      key: item.id,
      text: item.name,
      value: item.description,
      secondaryText: [item.description],
    })) || [];

  const { data: copilotCalls, isLoading: isListCallsLoading } = useQuery(
    'listCopilotCalls',
    () => listCopilotCalls(recipient?.email || ''),
    {
      enabled:
        !!recipient?.email &&
        followupEnabled &&
        selectedIntent === 'FOLLOWUP_EMAIL' &&
        !defaultEmailGeneration.copilotCall,
    },
  );

  const copilotCallsItems: StandardDropDownItem[] =
    (copilotCalls || (selectedCopilotCall && [selectedCopilotCall]))?.map(
      call => {
        return {
          key: call.id,
          value: call.id,
          text: call.title,
          secondaryText: call.time ? [format(new Date(call.time), 'Pp')] : [],
          disabled: call.status !== CALL_PROCESSED,
          hideTooltip: true,
        };
      },
    ) || [];

  const selectedCallItem: StandardDropDownItem[] = selectedCopilotCall
    ? [
        {
          key: selectedCopilotCall.id,
          value: selectedCopilotCall.id,
          text: selectedCopilotCall.title,
        },
      ]
    : [];

  const validForm =
    !!recipient &&
    recipient.email &&
    !generatedContent &&
    ((selectedIntent === 'FOLLOWUP_EMAIL' &&
      !!selectedCopilotCall &&
      selectedCopilotCall.fullSummary) ||
      (selectedIntent === 'COLD_EMAIL' && !prospectingV2Flag) ||
      (selectedIntent === 'COLD_EMAIL' && !!valueProp));

  const openerSelected =
    selectedIntent === 'COLD_EMAIL' && contentType?.value === 'openers';

  const onMutateSuccess = (data: GeneratedContent): void => {
    if (openerSelected) onInsert(data);
    else onReplace(data);
    useStore
      .getState()
      .updateCurrentGeneratedContent(selectedCopilotCall?.icaluid ?? '', data);
  };
  const onMutateError = (error: GenerateContentsError): void =>
    useStore.getState().addMessage({
      level: 'error',
      message: error.message,
      id: EMAIL_GENERATION_ERROR_ID,
      noCloseButton: true,
      hide: false,
    });

  const { mutate: generateColdEmail, isLoading: isColdEmailLoading } =
    useMutation(generateColdEmailApi, {
      onSuccess: onMutateSuccess,
      onError: onMutateError,
      mutationKey: ['generateColdEmail'],
    });

  const { mutate: generateFollowupEmail, isLoading: isFollowupEmailLoading } =
    useMutation(generateFollowupEmailApi, {
      onSuccess: onMutateSuccess,
      onError: onMutateError,
      mutationKey: ['generateFollowupEmail'],
    });

  const onGenerate = async (): Promise<void> => {
    if (validForm) {
      if (selectedIntent === 'COLD_EMAIL') {
        const params: ColdEmailParams = {
          contactEmail: recipient?.email,
          contactName: recipient?.name || '',
          contactTitle: recipient?.title || '',
          contactCompany: recipient?.company || '',
          writingStyle: (writingStyle?.value || '') as string,
          contentType: (contentType?.value || '') as string,
          valueProp: (valueProp?.value || '') as string,
        };
        if (prospectingV2Flag) params.version = 'v2';
        generateColdEmail(params);
      } else if (selectedIntent === 'FOLLOWUP_EMAIL') {
        const singRecipient = recipient?.name
          ? [recipient.name]
          : [recipient?.email];
        const multiRecipients =
          recipients?.map(recipient =>
            recipient?.name ? recipient.name : recipient?.email,
          ) ?? [];
        const params = {
          callId: selectedCopilotCall?.id || '',
          recipients:
            multiRecipients.length > 0 ? multiRecipients : singRecipient,
          fullSummary: selectedCopilotCall?.fullSummary || '',
          actionItems: selectedCopilotCall?.actionItems || [],
          fromEmail: fromEmail || '',
          tone: selectedTone,
        };
        generateFollowupEmail(params);
      }

      trackActionEvents({
        eventName:
          selectedIntent === 'COLD_EMAIL'
            ? ACTION_EVENT.GENERATE_COLD_EMAIL
            : ACTION_EVENT.GENERATE_FOLLOW_UP,
        action: useStore.getState().action,
        actionVersion: ACTION_VERSION.V2,
      });
    }
    trackCopilot({
      type: CopilotEventType.ACTION,
      event: CopilotActionEvent.GENERATE_AND_REPLACE_CLICKED,
      activityType: useStore.getState().action.activityType as ActivityType,
    });
  };

  const isLoading = isColdEmailLoading || isFollowupEmailLoading;

  const onReplace = (content: GeneratedContent): void => {
    useStore
      .getState()
      .updateAction({ subject: content.subject, body: content.content });
    useStore.getState().setOtherValues({ wysiwygEdited: false });
  };

  const onInsert = (content: GeneratedContent): void => {
    useStore
      .getState()
      .insertAtCursorAndUpdateAction({ body: content.content || '' });
  };

  const onSelectCopilotCall = async (
    call: CopilotCall | undefined,
  ): Promise<void> => {
    if (
      !call ||
      (call.id === selectedCopilotCall?.id && !!selectedCopilotCall.fullSummary)
    )
      return;
    setIsGetCallLoading(true);
    setSelectedCopilotCall(call);
    const callDetails = await getCopilotCall(call.id);
    setSelectedCopilotCall(callDetails);
    setIsGetCallLoading(false);
  };

  useEffect(() => {
    if (defaultEmailGeneration.copilotCall) {
      setSelectedCopilotCall(defaultEmailGeneration.copilotCall);
      setSelectedIntent(defaultEmailGeneration.emailIntent);
    }
  }, [setSelectedCopilotCall, setSelectedIntent, defaultEmailGeneration]);

  useEffect(() => {
    const defaultGeneratedContent = useStore
      .getState()
      .getCurrentGeneratedContent(selectedCopilotCall?.icaluid ?? '');

    if (
      defaultGeneratedContent &&
      defaultEmailGeneration.copilotCall &&
      actionId === NEW_ACTION_ID
    ) {
      onReplace(defaultGeneratedContent);
    } else if (defaultEmailGeneration.shouldBeInvoked) {
      onGenerate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultEmailGeneration, selectedCopilotCall]);

  const generateButtonText = (): string => {
    return openerSelected ? 'Generate and insert' : 'Generate and replace';
  };

  const onSelectIntent = (intent: Intent): void => {
    setSelectedIntent(intent);
    trackActionEvents({
      eventName:
        intent === 'COLD_EMAIL'
          ? ACTION_EVENT.SELECT_COLD_EMAIL
          : ACTION_EVENT.SELECT_FOLLOW_UP,
      action: useStore.getState().action,
      actionVersion: ACTION_VERSION.V2,
    });
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex flex-row px-[10px] pt-[8px] items-center">
        <Text className="flex-1 text-body-lg">Email Generation </Text>
        <IconButton
          className="text-white disabled:bg-primary disabled:text-gray1 hover:text-primary"
          ariaLabel="Close Email Generation Pane"
          onClick={onClose}
        >
          <CloseIcon className="text-neutral/900 w-[20px]" />
        </IconButton>
      </div>

      <div className="p-[10px]">
        <div className="text-body-sm box-border font-groove">
          {filteredEmailTypes.length > 1 && (
            <div className="inline-block relative">
              <Text
                variant="body-sm"
                className="mb-[4px] mt-[4px] font-semibold"
              >
                Intent
              </Text>
              <div className="rounded border border-solid border-gray3 overflow-hidden h-[30px] inline-block">
                {filteredEmailTypes.map((item, index) => (
                  <ButtonArraySingle
                    onClick={() => onSelectIntent(item.value as Intent)}
                    isActive={selectedIntent === item.value}
                    isLast={index === filteredEmailTypes.length - 1}
                    key={item.value}
                  >
                    {item.label}
                  </ButtonArraySingle>
                ))}
              </div>
            </div>
          )}
          <Text
            variant="body-sm"
            className="mb-[4px] mt-[4px] font-normal text-neutral/600"
          >
            {
              EMAIL_GENERATION_TYPES.find(item => item.value === selectedIntent)
                ?.description
            }
          </Text>
        </div>

        {selectedIntent === 'COLD_EMAIL' && !prospectingV2Flag && (
          <div className="mt-5 w-full">
            <Text variant="body-sm" className="mb-[4px] mt-[4px] font-semibold">
              Writing style
            </Text>
            <PicklistNew
              selectedValues={writingStyle ? [writingStyle] : null}
              items={EMAIL_WRITING_STYLES}
              onChange={item => setWritingStyle(item)}
              placeholder="Select one..."
            />
            <Text
              variant="body-sm"
              className="mb-[4px] mt-[4px] font-normal text-neutral/600"
            >
              Select a writing style to adjust the tone and length of the
              content
            </Text>
          </div>
        )}

        {selectedIntent === 'COLD_EMAIL' && prospectingV2Flag && (
          <div className="mt-5 w-full min-h-[110px]">
            <Text variant="body-sm" className="mb-[4px] mt-[4px] font-semibold">
              Content type
            </Text>
            <PicklistNew
              selectedValues={contentType ? [contentType] : null}
              items={CONTENT_TYPES}
              onChange={item => setContentType(item)}
              placeholder="Select one..."
            />
            <Text
              variant="body-sm"
              className="mb-[4px] mt-[4px] font-normal text-neutral/600"
            >
              {contentType?.metaData?.description}
            </Text>
            <div className="flex flex-row">
              <Text
                variant="body-sm"
                className="mb-[4px] mt-[4px] font-semibold"
              >
                Value propositions
              </Text>
              <TooltipHost
                content="The value proposition you determines what benefits of the product or service are stressed in the generated email."
                calloutProps={{ isBeakVisible: false }}
                hostClassName="ml-2"
              >
                <InfoIcon />
              </TooltipHost>
            </div>
            <PicklistNew
              selectedValues={valueProp ? [valueProp] : null}
              items={renderedValueProps}
              onChange={item => setValueProp(item)}
              placeholder="Select one..."
            />
          </div>
        )}

        {selectedIntent === 'FOLLOWUP_EMAIL' && (
          <>
            <div className="mt-3 w-full">
              <div className="flex flex-row">
                <Text
                  variant="body-sm"
                  className="mb-[4px] mt-[4px] font-semibold"
                >
                  Copilot Meeting
                </Text>
                <TooltipHost
                  content="Only meetings recorded in Copilot related to this contact will be accessible."
                  calloutProps={{ isBeakVisible: false }}
                  hostClassName="ml-2"
                >
                  <InfoIcon />
                </TooltipHost>
              </div>

              <DropDownList
                className="w-full mb-[4px]"
                selectedValues={selectedCallItem}
                renderedText={selectedCallItem[0]?.text || 'Select one...'}
                items={copilotCallsItems}
                disabled={isListCallsLoading || isGetCallLoading}
                onOpenChange={isOpen => {
                  if (isOpen) {
                    trackCopilot({
                      type: CopilotEventType.ACTION,
                      event: CopilotActionEvent.EVENT_DROPDOWN_OPENED,
                      activityType: useStore.getState().action
                        .activityType as ActivityType,
                    });
                  }
                }}
                onChange={item => {
                  onSelectCopilotCall(
                    copilotCalls?.find(call => call.id === item?.key),
                  );
                  trackCopilot({
                    type: CopilotEventType.ACTION,
                    event: CopilotActionEvent.NEW_MEETING_SELECTED,
                    activityType: useStore.getState().action
                      .activityType as ActivityType,
                  });
                }}
              />
              <div>
                {(isListCallsLoading || isGetCallLoading) && (
                  <Spinner className="mr-2" size={SpinnerSize.small} />
                )}
              </div>
            </div>

            <div className="inline-block relative">
              <Text
                variant="body-sm"
                className="mb-[4px] mt-[4px] font-semibold"
              >
                Tone
              </Text>
              <div className="rounded border border-solid border-gray3 overflow-hidden h-[30px] inline-block">
                {FOLLOW_UP_TONE_TYPES.map((item, index) => (
                  <ButtonArraySingle
                    onClick={() => {
                      setSelectedTone(item.value as Tone);
                      trackCopilot({
                        type: CopilotEventType.ACTION,
                        event: CopilotActionEvent.TONE_SWITCHED,
                        activityType: useStore.getState().action
                          .activityType as ActivityType,
                        props: { tone: item.value },
                      });
                    }}
                    isActive={selectedTone === item.value}
                    isLast={index === FOLLOW_UP_TONE_TYPES.length - 1}
                    key={item.value}
                  >
                    {item.label}
                  </ButtonArraySingle>
                ))}
              </div>
            </div>
          </>
        )}

        <div className="flex flex-row justify-center mt-5 gap-4 items-center">
          <div className="flex flex-row justify-center mt-auto gap-4 items-center">
            <Button
              variant="secondary"
              className="text-center"
              disabled={!validForm || isLoading}
              onClick={onGenerate}
            >
              {isLoading ? 'Loading..' : generateButtonText()}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailGenerationPane;
