import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { isNull } from 'lodash-es';
import { connect } from 'react-redux';
import { currentUser } from 'Modules/Shared/selectors/users';
import { Map } from 'immutable';
import { isFunction } from 'util';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginRight: 10,
  },
  clickable: {
    cursor: 'pointer',
  },
};

@withStyles(styles)
@connect(
  state => ({
    currentUser: currentUser(state),
  }),
  {}
)
export default class UserNameAndAvatar extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    currentUser: PropTypes.instanceOf(Map),
    handleClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userProfileImageUrl: null,
    onRequestShowUserMenu: null,
    currentUser: null,
  };

  renderAvatar() {
    const { currentUser, handleClick, classes } = this.props;
    const userName = currentUser.get('name');
    const userProfileImageUrl = currentUser.get('profileImageUrl');
    const avatarProps = {};
    if (isFunction(handleClick)) {
      avatarProps.className = classes.clickable;
      avatarProps.onClick = handleClick;
    }

    if (isNull(userProfileImageUrl)) {
      return (
        <Avatar onClick={handleClick} {...avatarProps}>
          {userName.split('')[0].toUpperCase()}
        </Avatar>
      );
    }

    return (
      <Avatar
        alt={userName}
        src={userProfileImageUrl}
        onClick={handleClick}
        {...avatarProps}
      />
    );
  }

  render() {
    const { classes, currentUser } = this.props;
    const userName = currentUser.get('name');

    return (
      <div className={classes.root}>
        <div className={classes.name}>
          <Typography variant="body1">{userName}</Typography>
        </div>
        {this.renderAvatar()}
      </div>
    );
  }
}
