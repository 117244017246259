import { Record } from 'immutable';
import Label from 'Modules/Shared/records/Label';
import Labelable from 'Modules/Shared/records/Labelable';

const defaults = {
  id: null,
  label: new Label(),
  labelable: new Labelable(),
};

export default class LabelInstance extends Record(defaults) {
  static from({ labelInstance }) {
    const label = Label.from({ label: labelInstance.get('label') });
    const labelable = new Labelable({
      id: labelInstance.get('labelableId'),
      type: labelInstance.get('labelableType'),
    });
    return new LabelInstance({
      id: labelInstance.get('id'),
      label,
      labelable,
    });
  }
}
