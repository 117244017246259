import { makeStyles } from '@material-ui/core/styles';
import ClariTheme from 'Utils/theme/ClariTheme';
import RelaxingLady from 'Modules/ActionsRewrite/components/tableTabs/zeroState/assets/RelaxingLady';
import Clouds from 'Modules/ActionsRewrite/components/tableTabs/zeroState/assets/Clouds';

const useStyles = makeStyles(_theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: ClariTheme.palette.text.neutralColor,
    flex: 1,
    minHeight: 440,
    minWidth: 400,
    boxShadow: `0px 1px 2px 0px ${ClariTheme.palette.text.shadows}`,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%',
  },
  cloudsContainer: {
    display: 'flex',
    margin: '5% 0px 0px 15%',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0px 5% 5% 0px',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 82,
  },
  title: {
    fontSize: 24,
  },
  subtitle: {
    fontSize: 14,
  },
}));

type ZeroActionsStateParams = {
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
};

export default function ZeroActionsState({
  title,
  subtitle,
}: ZeroActionsStateParams) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.cloudsContainer}>
        <Clouds width={793} height={107} />
      </div>
      <div className={classes.content}>
        <div className={classes.text}>
          <div className={classes.title}>{title}</div>
          <div className={classes.subtitle}>{subtitle}</div>
        </div>
      </div>
      <div className={classes.imageContainer}>
        <RelaxingLady width={304} height={207} />
      </div>
    </div>
  );
}
