import SplunkOtelWeb from '@splunk/otel-web';
import SplunkSessionRecorder from '@splunk/otel-web-session-recorder';
import { EventName } from '@splunk/otel-web/dist/cjs/upstream/user-interaction/types';
import { Span, trace } from '@opentelemetry/api';

export const SPLUNK_FF = 'splunk-rum';
export const SPLUNK_ATTRIBUTES = {
  ACTION_ID: 'action.id',
};
export const SPLUNK_SPANS = {
  LOAD_ACTION_COMPOSE: 'Load Action Compose',
};
export const WEB_APP = 'groove-web-app';
export const OUTLOOK_ADDIN = 'groove-outlook-add-in';
export const GROOVE_EXTENSION = 'groove-extension';

const SPLUNK_REALM = 'us0';
let splunkAppName = 'unknown';

export const initializeSplunk = (currentUser, grooveApp: string): void => {
  splunkAppName = grooveApp || 'unknown';
  SplunkOtelWeb.init({
    realm: SPLUNK_REALM,
    rumAccessToken: process.env.SPLUNK_ACCESS_TOKEN,
    applicationName: grooveApp || 'unknown',
    version:
      grooveApp === GROOVE_EXTENSION
        ? chrome.runtime.getManifest().version
        : process.env.REACT_APP_VERSION,
    deploymentEnvironment: process.env.DEPLOY_ENV,
    globalAttributes: {
      'groove.user.id': currentUser?.id,
      'groove.user.role': currentUser?.role,
      'groove.user.email': currentUser?.email,
      'groove.user.name': currentUser?.name,
      'groove.organization.id': currentUser?.organization?.id,
    },
    debug: false,
    instrumentations: {
      errors: true,
      interactions: {
        events: { mousedown: false, mouseup: false },
        shouldPreventSpanCreation: (
          _eventType: EventName,
          element: HTMLElement,
          span: Span,
        ) => {
          span.setAttribute('target_id', element?.id || '');
          span.setAttribute('target_text', element?.textContent || '');
          span.setAttribute('target_class', element?.className || '');
          span.setAttribute('target_role', element?.role || '');
          span.setAttribute(
            'target_action',
            element?.getAttribute('data-splunk-action') ||
              element?.getAttribute('data-dd-action-name') ||
              element?.getAttribute('aria-label') ||
              '',
          );

          return false;
        },
      },
    },
    ignoreUrls: [
      /.*pendo\.io.*/,
      /.*launchdarkly\.com.*/,
      /.*nr-data\.net.*/,
      /.*segment\.io.*/,
      /.*mxpnl\.com.*/,
      /.*mixpanel\.com.*/,
      /.*[chat|play]\.google\.com.*/,
    ],
  });

  SplunkSessionRecorder.init({
    realm: SPLUNK_REALM,
    rumAccessToken: process.env.SPLUNK_ACCESS_TOKEN,
  });
};

export const wrapWithSplunkSpan = (
  spanName: string,
  invokeFunc: () => void,
  attributes: { [key: string]: string } = {},
): void => {
  const span = trace.getTracer(splunkAppName).startSpan(spanName);
  if (attributes && typeof attributes === 'object')
    Object.keys(attributes).forEach(key =>
      span.setAttribute(key, attributes[key]),
    );
  invokeFunc?.();
  span.end();
};

export const storeCurrentUser = (
  currentUser: Record<string, unknown>,
): void => {
  const storeUser = (({ id, name, email, organization, role }) => ({
    id,
    name,
    email,
    organization,
    role,
  }))(currentUser || {});
  window.localStorage.setItem('currentUser', JSON.stringify(storeUser));
};

export const getStoredCurrentUser = (): Record<string, unknown> => {
  let currentUser = {};
  try {
    currentUser = JSON.parse(
      window.localStorage.getItem('currentUser') || '{}',
    );
  } catch (e) {
    console.log('Failed to retreive currentUser for Splunk');
  }
  return currentUser;
};
