export const AVAILABLE_ACTORS_KEY_PATH = ['SharingDialog', 'availableActors'];
export const CURRENT_ACTORS_KEY_PATH = ['SharingDialog', 'currentActors'];
export const FLOW_DIALOG_OPEN_KEY_PATH = [
  'SharingDialog',
  'flowDialog',
  'open',
];
export const USER_WARNING_OPEN_KEY_PATH = [
  'SharingDialog',
  'userWarning',
  'open',
];
export const IS_MASTER_FLOW_KEY_PATH = ['SharingDialog', 'isMasterFlow'];
export const READ_SCOPE = 'read';
export const WRITE_SCOPE = 'write';
export const OWNER_SCOPE = 'owner';
