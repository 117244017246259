// Function for detecting inconsistent formatting in a HTML element.
// Return true if HTML element contains multiple font-family or font-size.

/**
 * traverse is recurrsive function which checks each node element if it has consistent formatting
 * @param node: HTMLElement
 * @param parentFontFamily: String
 * @param parentFontSize: String
 * @param fontFamilySet: Set<string>
 * @param fontSizeSet: Set<string>
 * @returns Returns a boolean
 */
const traverse = (
  node: HTMLElement,
  parentFontFamily: string,
  parentFontSize: string,
  fontFamilySet: Set<string>,
  fontSizeSet: Set<string>,
): boolean => {
  if (node.hasChildNodes()) {
    const children = node.childNodes;
    const childrenArr = Array.from(children);

    const textNodes: ChildNode[] = [];
    const nonTextNodes: ChildNode[] = [];
    childrenArr.forEach(node => {
      if (node.nodeType === Node.TEXT_NODE) textNodes.push(node);
      if (node.nodeType === Node.ELEMENT_NODE) nonTextNodes.push(node);
    });

    const hasNonEmptyTextNodes = textNodes.some(n => !!n?.textContent?.trim());

    const parentFontFamilyToPassDown =
      node.style.fontFamily || parentFontFamily;
    const parentFontSizeToPassDown = node.style.fontSize || parentFontSize;

    if (hasNonEmptyTextNodes) {
      if (node.style.fontFamily) {
        fontFamilySet.add(node.style.fontFamily);
      } else if (parentFontFamily) {
        fontFamilySet.add(parentFontFamily);
      } else {
        fontFamilySet.add(node.style.fontFamily);
      }
      if (node.style.fontSize) {
        fontSizeSet.add(node.style.fontSize);
      } else if (parentFontSize) {
        fontSizeSet.add(parentFontSize);
      } else {
        fontSizeSet.add(node.style.fontSize);
      }
    }
    if (fontFamilySet.size >= 2 || fontSizeSet.size >= 2) {
      return true;
    }

    return nonTextNodes.some(nodeText =>
      traverse(
        nodeText as HTMLElement,
        parentFontFamilyToPassDown,
        parentFontSizeToPassDown,
        fontFamilySet,
        fontSizeSet,
      ),
    );
  }
  return false;
};

/**
 * @param element: HTMLElement
 * @returns Returns a boolean
 */

export default function detectInconsistentFormating(
  element: HTMLElement,
): boolean {
  return traverse(element, '', '', new Set(), new Set());
}
