import { Record } from 'immutable';

const defaults = {
  firstName: '',
  lastName: '',
  mobilePhone: null,
  phone: null,
};

export default class SfdcFields extends Record(defaults) {
  static from(action) {
    return new SfdcFields({
      firstName: action.get('firstName'),
      lastName: action.get('lastName'),
      phone: action.get('phone'),
      mobilePhone: action.get('mobilePhone'),
    });
  }
}
