import { call } from 'redux-saga/effects';
import { trackBasicEvent } from 'Modules/Shared/sagas/analytics';
import { actionTypes } from 'Modules/FlowsShow/actions';
import { FEATURE_CATEGORY } from 'Modules/FlowsShow/sagas/analytics';

function* flowDeleted() {
  yield call(trackBasicEvent, {
    featureCategory: FEATURE_CATEGORY,
    eventName: 'Flow Deleted',
    action: actionTypes.DELETE_FLOW.SUCCESS,
  });
}

export default flowDeleted;
