import {
  createActions,
  standardActions,
  keyMirror,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  ANALYTICS: {
    ...keyMirror([
      'UPSTART',
      'LOAD_DATA',
      'LOAD_TAB_DATA',
      'SCOPE_CHANGED',
      'NEW_SCOPE_CHANGED',
      'DATE_RANGE_CHANGED',
      'INITIATE_SEARCH',
      'SET_TAB_DATA_LOADING',
      'MERGE_TAB_DATA',
      'MERGE_FLOW_STEP_STATS',
      'MERGE_TEMPLATE_DATA',
      'GO_TO_PAGE',
      'TRIGGER_FLOWS_DRILLDOWN_DIALOG',
      'SET_FLOWS_DIALOG_DATA',
      'SET_SORTED_FLOWS_DIALOG_DATA',
      'FLOWS_DRILLDOWN_REQUEST_SORT',
      'RESET_FLOWS_DRILLDOWN_DIALOG_STATE',
      'TRIGGER_TEMPLATE_DRILLDOWN_DIALOG',
      'SET_TEMPLATE_DIALOG_DATA',
      'SET_FLOWS_PROCESS_DATA',
      'TEMPLATES_DRILLDOWN_REQUEST_SORT',
      'RESET_TEMPLATE_DRILLDOWN_DIALOG_STATE',
      'SET_SORTED_TEMPLATES_DIALOG_DATA',
      'RESET_REDUCER',
      'TRACK_REPORTING_PICKER_USE',
      'TRACK_TAB_CHANGE',
      'SET_EXPORT_MODAL',
      'SET_EXPORT_MODAL_SUCCESS',
      'SET_EXPORT_MODAL_FAILED',
      'SET_EXPORT_DATE',
    ]),
    FLOWS_DRILLDOWN_REQUEST_EXPAND_STEP_STATS_ROW: standardActions(),
    FETCH_TABLE_DATA: standardActions(),
    EXPORT_AS_CSV: standardActions(),
    EXPORT_LOGS_AS_CSV: standardActions(),
    EXPORT_TO_ENDPOINT: standardActions(),
  },
}).ANALYTICS;

export function loadData({ teams, users, teamAnalyticsVisible }) {
  return {
    type: actionTypes.LOAD_DATA,
    payload: {
      teams,
      users,
      teamAnalyticsVisible,
    },
  };
}

export function triggerScopeChange() {
  return {
    type: actionTypes.SCOPE_CHANGED,
  };
}

export function triggerNewScopeChange({
  teamScope = [],
  isUsersNotInTeams = false,
  userScope = [],
}) {
  return {
    type: actionTypes.NEW_SCOPE_CHANGED,
    payload: { teamScope, userScope, isUsersNotInTeams },
  };
}

export function triggerDateRangeChange(dateRange) {
  return {
    type: actionTypes.DATE_RANGE_CHANGED,
    payload: dateRange,
  };
}

export function triggerFilterChange() {
  return {
    type: actionTypes.INITIATE_SEARCH,
  };
}

export function loadTabData({ tab, data, ...rest }) {
  return {
    type: actionTypes.LOAD_TAB_DATA,
    payload: { tab, data, rest },
  };
}

export function setTabDataLoading(isLoading) {
  return {
    type: actionTypes.SET_TAB_DATA_LOADING,
    payload: isLoading,
  };
}

export function mergeTabData(dataToMerge) {
  return {
    type: actionTypes.MERGE_TAB_DATA,
    payload: dataToMerge,
  };
}

export function goToPage(payload) {
  return {
    type: actionTypes.GO_TO_PAGE,
    payload,
  };
}

export function triggerFlowsDrilldownDialog({ flowId }) {
  return {
    type: actionTypes.TRIGGER_FLOWS_DRILLDOWN_DIALOG,
    payload: { flowId },
  };
}

export function setFlowsDialogData({
  userBreakdown,
  stepStats,
  templateStats,
}) {
  return {
    type: actionTypes.SET_FLOWS_DIALOG_DATA,
    payload: { userBreakdown, stepStats, templateStats },
  };
}

export function setSortedFlowsDialogData({
  type,
  data,
  orderBy,
  orderDirection,
}) {
  return {
    type: actionTypes.SET_SORTED_FLOWS_DIALOG_DATA,
    payload: { type, data, orderBy, orderDirection },
  };
}

export function flowsDrilldownRequestSort({ type, orderBy, orderDirection }) {
  return {
    type: actionTypes.FLOWS_DRILLDOWN_REQUEST_SORT,
    payload: { type, orderBy, orderDirection },
  };
}

export function resetFlowsDrilldownDialogState() {
  return {
    type: actionTypes.RESET_FLOWS_DRILLDOWN_DIALOG_STATE,
  };
}

export function flowsDrilldownRequestExpandStepStatsRow(stepId) {
  return {
    type: actionTypes.FLOWS_DRILLDOWN_REQUEST_EXPAND_STEP_STATS_ROW.BEGIN,
    payload: { stepId },
  };
}

export function flowsDrilldownExpandStepStatsRowSuccess({ templateData }) {
  return {
    type: actionTypes.FLOWS_DRILLDOWN_REQUEST_EXPAND_STEP_STATS_ROW.SUCCESS,
    payload: { templateData },
  };
}

export function triggerTemplateDrilldownDialog({ templateId, isSfdcId }) {
  return {
    type: actionTypes.TRIGGER_TEMPLATE_DRILLDOWN_DIALOG,
    payload: { templateId, isSfdcId },
  };
}

export function setTemplateDialogData({ userBreakdown, templateData }) {
  return {
    type: actionTypes.SET_TEMPLATE_DIALOG_DATA,
    payload: { userBreakdown, templateData },
  };
}

export function resetTemplateDrilldownDialogState() {
  return {
    type: actionTypes.RESET_TEMPLATE_DRILLDOWN_DIALOG_STATE,
  };
}

export function templatesDrilldownRequestSort({
  type = 'userBreakdown',
  orderBy,
  orderDirection,
}) {
  return {
    type: actionTypes.TEMPLATES_DRILLDOWN_REQUEST_SORT,
    payload: { type, orderBy, orderDirection },
  };
}

export function setSortedTemplatesDialogData({
  type = 'userBreakdown',
  data,
  orderBy,
  orderDirection,
}) {
  return {
    type: actionTypes.SET_SORTED_TEMPLATES_DIALOG_DATA,
    payload: { type, data, orderBy, orderDirection },
  };
}

export function setFlowsProcessData({ summary }) {
  return {
    type: actionTypes.SET_FLOWS_PROCESS_DATA,
    payload: { summary },
  };
}

export function triggerExportAsCsv() {
  return {
    type: actionTypes.EXPORT_AS_CSV.BEGIN,
  };
}

export function openExportModal() {
  const isOpen = true;

  return {
    type: actionTypes.SET_EXPORT_MODAL,
    payload: { isOpen },
  };
}

export function closeExportModal() {
  const isOpen = false;

  return {
    type: actionTypes.SET_EXPORT_MODAL,
    payload: { isOpen },
  };
}

export function closeExportModalSuccess() {
  const isOpen = false;

  return {
    type: actionTypes.SET_EXPORT_MODAL_SUCCESS,
    payload: { isOpen },
  };
}

export function closeExportModalFailed() {
  const isOpen = false;

  return {
    type: actionTypes.SET_EXPORT_MODAL_FAILED,
    payload: { isOpen },
  };
}

export const setExportDate = payload => ({
  type: actionTypes.SET_EXPORT_DATE,
  payload,
});

export function triggerExportToEndpoint() {
  return {
    type: actionTypes.EXPORT_TO_ENDPOINT.BEGIN,
  };
}

export function trackReportingPickerUse({ filterBy, filterValue }) {
  return {
    type: actionTypes.TRACK_REPORTING_PICKER_USE,
    payload: { filterBy, filterValue },
  };
}

export function trackTabChange(activeTab) {
  return {
    type: actionTypes.TRACK_TAB_CHANGE,
    payload: { activeTab },
  };
}

export const exportLogsAsCsvBegin = payload => ({
  type: actionTypes.EXPORT_LOGS_AS_CSV.BEGIN,
  payload,
});
