import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const MuteIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, 'fill-current')}
  >
    <path d="M13 10.0007C12.9999 10.2692 12.9646 10.5294 12.8984 10.777L7.00232 4.88098C7.06481 3.2793 8.38301 2 10 2C11.6569 2 13 3.34315 13 5L13 10.0007ZM7 7.70712V9.99998C7 11.6568 8.34315 13 10 13C10.6479 13 11.2478 12.7946 11.7382 12.4454L12.8088 13.5159C12.039 14.1318 11.0625 14.5 10 14.5C7.51472 14.5 5.5 12.4853 5.5 9.99998C5.5 9.72384 5.27614 9.49998 5 9.49998C4.72386 9.49998 4.5 9.72384 4.5 9.99998C4.5 12.869 6.69675 15.2249 9.5 15.4776V17.5C9.5 17.7761 9.72386 18 10 18C10.2761 18 10.5 17.7761 10.5 17.5V15.4776C11.6434 15.3745 12.6858 14.9216 13.5195 14.2266L17.1464 17.8536C17.3417 18.0488 17.6583 18.0488 17.8536 17.8536C18.0488 17.6583 18.0488 17.3417 17.8536 17.1465L2.85355 2.14646C2.65829 1.9512 2.34171 1.9512 2.14645 2.14646C1.95118 2.34172 1.95118 2.65831 2.14645 2.85357L7 7.70712ZM14.803 12.6817C15.247 11.8883 15.5 10.9737 15.5 9.99998C15.5 9.72384 15.2761 9.49998 15 9.49998C14.7239 9.49998 14.5 9.72384 14.5 9.99998C14.5 10.6947 14.3426 11.3527 14.0614 11.9401L14.803 12.6817Z" />
  </svg>
);
