import { createActions, keyMirror } from '@groove-labs/action-utils';

export const baseActionTypes = createActions({
  OUTGOING_ACTION_COMPOSE: {
    ...keyMirror([
      'MINIMIZE_PANE',
      'MAXIMIZE_PANE',
      'CLOSE_PANE',
      'CLEAR_ACTIVE_ACTION',
      'READY',
      'ACTION_COMPLETED',
      'REMOVE_DESTROYED_PERSON_FLOW_ACTIONS',
      'ACTIVE_ACTION_REQUESTED',
      'ACTION_CREATED',
      'ACTION_DELETED',
      'RESET_FOR_MOBO',
      'UPDATE_OMNIBAR_RECORD',
    ]),
  },
});

export const actionTypes = baseActionTypes.OUTGOING_ACTION_COMPOSE;

export const minimizePane = () => ({
  type: actionTypes.MINIMIZE_PANE,
});

export const maximizePane = () => ({
  type: actionTypes.MAXIMIZE_PANE,
});

export const closePane = () => ({
  type: actionTypes.CLOSE_PANE,
});

export const clearActiveAction = () => ({
  type: actionTypes.CLEAR_ACTIVE_ACTION,
});

export const createAction = () => ({
  type: actionTypes.ACTION_CREATED,
});

export const deleteAction = () => ({
  type: actionTypes.ACTION_DELETED,
});

export const completeAction = payload => ({
  type: actionTypes.ACTION_COMPLETED,
  payload,
});

export const removeDestroyedPersonFlowActions = ({ personFlowId }) => ({
  type: actionTypes.REMOVE_DESTROYED_PERSON_FLOW_ACTIONS,
  payload: { personFlowId },
});

export const resetForMobo = () => ({
  type: actionTypes.RESET_FOR_MOBO,
});

export const updateOmnibarRecord = ({ id, email }) => ({
  type: actionTypes.UPDATE_OMNIBAR_RECORD,
  payload: {
    id,
    email,
  },
});
