import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
} from '@material-ui/icons';
import { omit, keys, isNull, noop } from 'lodash-es';

export const DESC = 'desc';
export const ASC = 'asc';

class SortDirectionButton extends Component {
  static propTypes = {
    onRequestChangeDirection: PropTypes.func,
    direction: PropTypes.oneOf([DESC, ASC]),
  };

  static defaultProps = {
    onRequestChangeDirection: noop,
    direction: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDescending: true,
    };

    // If the component is controlled, we need to set the default value from props
    if (this.isControlled()) {
      this.state.isDescending = props.direction === DESC;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.isControlled()) {
      this.setState({ isDescending: nextProps.direction === DESC });
    }
  }

  handleClickCb() {
    const { onRequestChangeDirection } = this.props;

    if (!this.isControlled()) {
      this.setState({ isDescending: !this.state.isDescending });
      onRequestChangeDirection(this.state.isDescending ? DESC : ASC);
    } else {
      // When controlled, dispatch what the new direction SHOULD be.
      onRequestChangeDirection(this.state.isDescending ? ASC : DESC);
    }
  }

  isControlled() {
    const { direction } = this.props;

    return !isNull(direction);
  }

  render() {
    const { ...rest } = this.props;
    const props = omit(rest, keys(SortDirectionButton.propTypes));

    return (
      <IconButton onClick={this.handleClickCb.bind(this)} {...props}>
        {this.state.isDescending ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
      </IconButton>
    );
  }
}

export default SortDirectionButton;
