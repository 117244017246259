import cx from 'classnames';
import {
  forwardRef,
  HTMLProps,
  KeyboardEvent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { VariableSizeList } from 'react-window';
import { motion } from 'framer-motion';

import useHandleOutsideClicks from '../helpers/useHandleOutsideClicks';
import calcTextWidth from '../helpers/calcTextWidth';
import {
  getNextSelectableIndex,
  getPrevSelectableIndex,
} from '../utils/dropDownKeyInputHelpers';

import { CheckmarkCircle, DownChevron } from './BoogieIcon';
import Item, { DropDownItem, StandardDropDownItem } from './DropDownItem';
import Tag from './Tag';
import MultiSelectDropDown from './MultiSelectDropDown';
import InputFrame from './InputFrame';
import Input from './Input';

export type MultiSelectRef = {
  closePane: () => void;
  openPane: () => void;
  focusInput: () => void;
};

export type MultiSelectProps = {
  placeholder?: string;
  items: DropDownItem[];
  disabled?: boolean;
  onSelect: (
    value: StandardDropDownItem | null,
    event?: React.MouseEvent | React.KeyboardEvent,
  ) => void;
  onRemove?: (index: number) => void;
  closeAfterSelect?: boolean;
  onSearchChange?: (value: string) => void;
  selectedValues?: StandardDropDownItem[] | null;
  className?: HTMLProps<HTMLElement>['className'];
  maxTagRows?: number; // Max number of rows of tags that a user sees
  selectedIcon?: JSX.Element;
  listHeader?: JSX.Element; // This component will show up between the tags and the top of the list
  listFooter?: JSX.Element; // This component will show up at the bottom of the list
  onOpen?: () => void;
  showChevron?: boolean;
  showSelectedIcon?: boolean;
  secondaryTextPosition?: 'right' | 'below';
  dropDownWidth?: string;
  wrapText?: boolean;
  truncate?: boolean;
  inputHeightClassName?: string;
};

const MultiSelect = forwardRef<MultiSelectRef, MultiSelectProps>(
  (
    {
      placeholder,
      items: rawItems,
      disabled,
      onSelect,
      onSearchChange,
      onRemove,
      closeAfterSelect,
      selectedValues,
      className,
      maxTagRows = 3,
      selectedIcon = <CheckmarkCircle />,
      listHeader,
      listFooter,
      onOpen,
      showSelectedIcon = false,
      showChevron = false,
      secondaryTextPosition = 'below',
      dropDownWidth = '115%',
      wrapText = false,
      truncate = false,
      inputHeightClassName = 'h-[28px]',
    },
    forwardedRef,
  ) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const listRef = useRef<VariableSizeList | null>(null);
    const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dropDownRef = useRef<VariableSizeList<any>>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [displayText, setDisplayText] = useState('');
    const [additionalText, setAdditionalText] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const selectedHash: { [key: string]: boolean } = {};
    selectedValues?.forEach(value => (selectedHash[value.value] = true));

    const items = rawItems.map(item => {
      if (item.type || !selectedHash[item.value]) return item;

      const result = { ...item };
      result.disabled = true;
      if (result.icon || showSelectedIcon) result.icon = selectedIcon;
      return result;
    });

    const calcTagLayout = (): void => {
      const calcButtonWidth = (label: string, padding: number): number =>
        calcTextWidth(label, inputRef, 'email-recipient-canvas', padding);

      // We just need to round down th make sure we don't accidentally add a pixel
      const maxWidth = (inputRef.current?.clientWidth || 0) - 1;
      let additionToText = '';
      let width = 0;
      let shownText = '';

      const isTooWide = (
        tagWidth: number,
        selectedValuesLeft: number,
      ): boolean => {
        additionToText =
          selectedValuesLeft > 0 ? `, +${selectedValuesLeft}` : '';
        if (selectedValuesLeft === 1) return width + tagWidth > maxWidth;

        return width + tagWidth + calcButtonWidth(additionToText, 0) > maxWidth;
      };

      if (selectedValues && selectedValues.length > 0) {
        for (let i = 0; i < selectedValues.length; i += 1) {
          let selectedText = '';
          if (i > 0) selectedText += ', ';
          selectedText += selectedValues[i].text;
          const fullText = shownText + selectedText;

          const textWidth = Math.min(calcButtonWidth(fullText, 0), 210);
          if (isTooWide(textWidth, selectedValues.length - i) && i > 0) break;

          shownText = fullText;
          width += textWidth;
          if (selectedValues.length - i === 1) additionToText = '';
        }
      }

      setDisplayText(shownText);
      setAdditionalText(additionToText);
    };

    useImperativeHandle(forwardedRef, () => ({
      closePane: () => {
        setIsOpen(false);
        removeFocus();
      },
      openPane: onInputClick,
      focusInput: () => inputRef.current?.focus(),
    }));

    useEffect(() => {
      calcTagLayout();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValues]);

    // This is to make sure that the height of the individual components are rendered
    // correctly
    useEffect(() => {
      dropDownRef.current?.resetAfterIndex(0, true);
    }, [items.length]);

    const updateHighlightedIndex = (): void => {
      let index = 0;
      items.forEach((item, i) => {
        if (item && !item.type && item.value === selectedValues?.[0]?.value) {
          index = i;
        }
        return index;
      });
      setHighlightedIndex(index);
    };

    useEffect(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }, [items]);

    const setSearch = (value: string): void => {
      setSearchTerm(value);
      setHighlightedIndex(-1);
      onSearchChange?.(value);
    };

    const removeFocus = (): void => {
      setSearch('');
      inputRef.current?.blur();
    };

    const handleRemove = (index: number): void => {
      onRemove?.(index);
      inputRef.current?.focus();
    };

    const handleSelect = (
      item: StandardDropDownItem,
      event?: React.MouseEvent | React.KeyboardEvent,
    ): void => {
      onSelect(item, event);
      if (closeAfterSelect) {
        setIsOpen(false);
        removeFocus();
      }
    };

    const onInputClick = (): void => {
      if (disabled) return;
      updateHighlightedIndex();
      setIsOpen(true);
      onOpen?.();
      inputRef.current?.focus();
    };

    const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
      let newIndex;
      event.stopPropagation();
      if (event.key === 'Enter' && highlightedIndex > -1) {
        const selectItem = items[highlightedIndex];
        if (items.length > 0 && !selectItem.type && !selectItem.disabled) {
          handleSelect(selectItem, event);
        }
      } else if (event.key === 'ArrowDown') {
        if (highlightedIndex + 1 >= items.length)
          newIndex = getNextSelectableIndex(items, 0);
        else newIndex = getNextSelectableIndex(items, highlightedIndex + 1);
        setHighlightedIndex(newIndex);
        dropDownRef.current?.scrollToItem(newIndex);
      } else if (event.key === 'ArrowUp') {
        if (highlightedIndex <= 0)
          newIndex = getPrevSelectableIndex(items, items.length - 1);
        else newIndex = getPrevSelectableIndex(items, highlightedIndex - 1);
        setHighlightedIndex(newIndex);
        dropDownRef.current?.scrollToItem(newIndex);
      } else if (event.key !== 'Enter') {
        updateHighlightedIndex();
        setIsOpen(true);
        onOpen?.();
      }
    };

    useHandleOutsideClicks(ref, () => {
      setIsOpen(false);
      removeFocus();
    });

    let renderedText: string | JSX.Element = '';

    if (selectedValues && selectedValues.length > 0) {
      renderedText = (
        <>
          <span className="truncate">{displayText}</span>
          <span>{additionalText}</span>
        </>
      );
    }

    return (
      <div ref={ref} className={cx(className, 'relative')}>
        <div
          className="relative"
          onClick={onInputClick}
          role="button"
          tabIndex={0}
          onKeyDown={event => event.key === 'Enter' && onInputClick()}
        >
          <InputFrame
            value={renderedText}
            placeholder={placeholder}
            disabled={disabled}
            className={cx(
              { truncate },
              { 'pr-6': truncate },
              inputHeightClassName,
            )}
          />
          <Input
            value={searchTerm}
            placeholder={placeholder}
            innerRef={inputRef}
            className={cx(
              '!absolute top-0 left-0 bottom-0 right-0 z-10',
              isOpen ? 'opacity-100' : 'opacity-0',
              { truncate },
            )}
            style={{
              paddingRight: showChevron ? '3rem' : '',
            }}
            onKeyDown={onInputKeyDown}
            onChange={e => setSearch(e.target.value || '')}
            onFocus={event => event.target.setAttribute('autocomplete', 'off')}
            disabled={disabled}
          />
          {showChevron && (
            <div className="absolute inset-y-0 right-0 pr-3 z-50 flex items-center pointer-events-none">
              <motion.div
                initial={{ rotate: 0 }}
                animate={{ rotate: isOpen ? 180 : 0 }}
                transition={{ duration: 0.2, ease: 'easeInOut' }}
              >
                <DownChevron
                  className={cx(
                    'm-auto w-[12px]',
                    disabled ? 'text-neutral/00' : 'text-clari-blue/600',
                  )}
                />
              </motion.div>
            </div>
          )}
        </div>
        <MultiSelectDropDown
          itemList={items}
          itemSize={index => {
            if (items.length < 1 || index < 0) return 0;
            const item = items[index];
            if (item.type === 'header') return 26;
            if (item.type === 'clear') return 26;
            if (item.type === 'divider') return 4;

            // This is how we calculate the height of the items since we have to have an estimate before rendering
            let length = item.secondaryText?.length || 0;
            if (secondaryTextPosition === 'right' && length > 0) length -= 1;

            return 32 + length * 16;
          }}
          isVariable
          showDropDown={isOpen}
          renderedItem={Item}
          width={dropDownWidth}
          // maxHeight={maxHeight}
          innerRef={dropDownRef}
          itemData={{
            hasSearch: true,
            highlightedIndex,
            items,
            onClick: (item, event) => {
              handleSelect(item, event);
            },
            searchTerm,
            secondaryTextPosition,
            showSelectedIcon,
            wrapText,
            truncate,
          }}
          listFooter={listFooter}
        >
          <>
            {onRemove && selectedValues && selectedValues.length > 0 && (
              <div
                className="relative overflow-y-auto border-0 !border-b border-solid border-neutral/75"
                style={{ maxHeight: (maxTagRows + 0.3) * 34 + 4 }}
              >
                <div className="flex-1 relative flex-row flex items-start flex-wrap px-[4px] pt-[3px] min-w-0">
                  {selectedValues.map((selectedValue, index) => (
                    <div
                      className="pr-2 pt-[2px] pb-[4px] min-w-0"
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${index.toString()}${index}`}
                    >
                      <Tag
                        onCancel={() => handleRemove(index)}
                        label={selectedValue.text}
                        dataValue={selectedValue.value.toString()}
                        datatype="email"
                        tooltip={selectedValue.secondaryText?.[0]}
                        icon={selectedValue.icon}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {listHeader}
          </>
        </MultiSelectDropDown>
      </div>
    );
  },
);

export default MultiSelect;
