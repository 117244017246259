import { UseQueryResult, useQuery } from 'react-query';

import {
  ApiFetchEmailSignature,
  fetchEmailSignature,
} from '../gateway/v1/settings';

const useFetchEmailSignature = (
  fromEmail: string,
): UseQueryResult<ApiFetchEmailSignature, unknown> =>
  useQuery([fromEmail], () => fetchEmailSignature({ alias: fromEmail }), {
    enabled: !!fromEmail && fromEmail.length > 0,
    cacheTime: 1 * 60 * 1000, // 1 minute
    staleTime: 1 * 60 * 1000, // 1 minute
  });

export default useFetchEmailSignature;
