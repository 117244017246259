import { FC } from 'react';

interface StarProps {
  className?: string;
  onClick?: () => void;
  fillColor?: string;
}

const Star: FC<StarProps> = ({
  className = '',
  onClick,
  fillColor = '#FBC122',
}) => (
  <svg
    className={className}
    onClick={onClick}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.1939 1.10179C6.52403 0.432869 7.47789 0.432865 7.80802 1.10179L9.32911 4.18384L12.7304 4.67807C13.4685 4.78534 13.7633 5.69251 13.2291 6.2132L10.768 8.61224L11.349 11.9997C11.4751 12.735 10.7034 13.2956 10.0431 12.9485L7.00096 11.3491L3.95879 12.9485C3.29853 13.2956 2.52684 12.735 2.65294 11.9997L3.23394 8.61224L0.772773 6.2132C0.238609 5.69251 0.533365 4.78534 1.27156 4.67807L4.67281 4.18384L6.1939 1.10179Z"
      fill={fillColor}
    />
  </svg>
);

export default Star;
