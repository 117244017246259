import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Dialog,
  Button,
  DialogActions,
  Typography,
} from '@material-ui/core';

const styles = {
  buttonLabel: {
    fontWeight: 400,
  },
  buttonRoot: {
    boxShadow: 'unset',
    '&:hover': {
      filter: 'brightness(90%)',
    },
  },
  dialogRootClass: {
    maxWidth: 400,
    padding: 30,
    width: '50%',
  },
  titleRoot: {
    marginBottom: 10,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25,
  },
};

const UserActionDialog = ({ classes, buttons, title, open, icon = null }) => {
  const sharedButtonProps = {
    classes: { label: classes.buttonLabel, root: classes.buttonRoot },
    color: 'primary',
    variant: 'contained',
  };
  const dialogButtons = buttons.map(({ text, onClick, color }, idx) => {
    const props = {
      ...sharedButtonProps,
      onClick,
      key: idx,
    };
    if (color) {
      props.style = { backgroundColor: color };
    }
    return <Button {...props}>{text}</Button>;
  });

  const dialogProps = {
    disableBackdropClick: true,
    disableEscapeKeyDown: true,
    open,
    classes: {
      paper: classes.dialogRootClass,
    },
  };

  return (
    <div>
      <Dialog {...dialogProps}>
        <div className={classes.iconContainer}>{icon}</div>
        <Typography variant="subtitle1" classes={{ root: classes.titleRoot }}>
          {title}
        </Typography>
        <DialogActions>{dialogButtons}</DialogActions>
      </Dialog>
    </div>
  );
};

UserActionDialog.displayName = 'UserActionDialog';
UserActionDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      color: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  icon: PropTypes.element,
};

UserActionDialog.defaultProps = {
  open: true,
  icon: null,
};

export default withStyles(styles)(UserActionDialog);
