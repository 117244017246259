import { KeyboardEventHandler, MouseEventHandler } from 'react';
import uuid from 'uuid';

export enum SNACKBAR_MESSAGE_TYPE {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum SNACKBAR_POSITIONS {
  TOP_CENTER = 'top center',
  TOP_LEFT = 'top left',
  TOP_RIGHT = 'top right',
  BOTTOM_LEFT = 'bottom left',
  BOTTOM_RIGHT = 'bottom right',
  BOTTOM_CENTER = 'bottom center',
}

function randomId(type: SNACKBAR_MESSAGE_TYPE): string {
  return `${type}-${uuid()}`;
}

type Props = {
  message: string;
  type: SNACKBAR_MESSAGE_TYPE;
  position?: SNACKBAR_POSITIONS;
  actionUrl?: string;
  actionBtnText?: string;
  onActionButtonClick?: MouseEventHandler<HTMLSpanElement>;
  onKeyDown?: KeyboardEventHandler<HTMLSpanElement>;
  actionTarget?: string | null;
  timeout?: number;
};

export type SnackbarNotificationMsg = Props & {
  id: string;
};

export function createNotification({
  message,
  type,
  actionUrl,
  position,
  actionBtnText,
  onActionButtonClick,
  actionTarget,
  timeout,
}: Props): string {
  const id = randomId(type);
  window.dispatchEvent(
    new CustomEvent('snackbar-notification-added', {
      detail: {
        id,
        type,
        message,
        actionUrl,
        position,
        actionBtnText,
        onActionButtonClick,
        actionTarget,
        timeout,
      },
    }),
  );
  return id;
}
