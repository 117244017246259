import create from 'zustand';
import produce from 'immer';
import {
  FormField,
  FullAction,
  LoggingTo,
  UserPicklistValues,
  WhoType,
  crmObject,
} from '@groove/api/gateway/v1/actionCompose';
import { Template } from '@groove/api/gateway/v1/template';
import { stringInserter } from '@groove/ui/helpers/stringInserter';
import { MutableRefObject } from 'react';
import { StandardDropDownItem } from '@groove/ui/Components/DropDownItem';
import sanitizeHtml from 'sanitize-html';
import { ITag } from '@fluentui/react';
import tinymce from 'tinymce';
import GrooveMessageEvent from '@groove/api/visualforce/types/GrooveMessageEvent';
import { cloneDeep } from 'lodash-es';
import useTemplatesStore from '@groove/templates/store/useStore';

import {
  TASK_PANE_LOCAL_KEY,
  emptyAction,
  FieldsPivotItemValues,
  ActionComposeEnum,
  ACTION_ORIGIN,
} from '../constants';
import { actionValidator } from '../utils';
import mergeFieldHandler from '../utils/mergeFieldHandler';

import {
  oldActionComposeStore,
  OldActionComposeStore,
} from './oldActionComposeStore';
import {
  EmailGenerationStore,
  INIT_DEFAULT_COPILOT_CALL,
  emailGenerationStore,
} from './EmailGenerationStore';
import { phoneStore, PhoneStore } from './PhoneStore';
import { MessageBarStore, messageBarStore } from './messageBarStore';

export * from './oldActionComposeStore';

export type PartialWho = Partial<WhoType>;

export type PartialAction = Partial<Omit<FullAction, 'loggingTo' | 'who'>> & {
  who?: PartialWho;
};

export type LastUnmergedFields = {
  [fieldName: string]: true;
};

export type PaneValues = {
  getCurrentInputRef?: () => MutableRefObject<HTMLTextAreaElement | null>;
  selectionStart: number;
  selectionEnd: number;
};

export type OtherValues = PaneValues & {
  isTaskPaneOpen: boolean;
  isTaskPaneOpenPast: boolean;
  isExtendedTaskPaneOpen: boolean;
  taskPaneType: 'TASK' | 'TEMPLATE' | 'SCHEDULER' | 'EMAIL_GENERATION';
  selectedTemplate: Template | null;
  selectedFlow?: StandardDropDownItem;
  loggingToRelatedResults?: StandardDropDownItem[];
  relatedToRelatedResults?: StandardDropDownItem[];
  scheduleForId?: string;
  meetingTypeId?: number;
  schedulerAttendees?: ITag[];
  pauseDate: Date;
  wysiwygEdited: boolean;
  wysiwygSelection: Selection | null;
  isTemplatesAppOpen: boolean;
  isTemplateEditorOpen: boolean;
  relatedToLastOpen: 'who' | 'what' | 'to' | null;
  grooveMessageEvent?: GrooveMessageEvent;
  selectedFieldsPivot: FieldsPivotItemValues;
  availablePicklistValues?: UserPicklistValues;
  invokingApp?: ACTION_ORIGIN;
  isCallResultRequired: boolean;
  isCallResultDisplayed: boolean;
  trackingEnabled: boolean;
  lastUnmergedFields: LastUnmergedFields;
  isFieldsMerging: boolean;
  isFetching: boolean;
  isMutating: boolean;
  taskPaneInitialClose: boolean;
  mergingField: string;
  isEmailOptOutSafeguardDisabled: boolean;
  contactEmailOptOutField: string;
  contactCallOptOutField: string;
  contactSmsOptOutField: string;
  leadEmailOptOutField: string;
  leadCallOptOutField: string;
  leadSmsOptOutField: string;
  smsEnabled: boolean;
  phoneNumber: string | null;
};

export type ModalValues = {
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  content?: JSX.Element;
  contentText?: string;
  isLinkedInFallback: boolean;
  linkedInFallbackText: string;
  boldTitle?: boolean;
};

export type ExternalModalValues = {
  isSchedulerOpen: boolean;
};

type LookupType = 'id' | 'query' | 'user';

export type Lookup = {
  type: LookupType;
  value: string;
};

export type ExternalMetadata = {
  lookups: Lookup[];
  description?: string;
  body?: string;
};

export type IdArrayType = {
  id: string | number;
  email?: string;
};

export type DialerStateType = {
  isRecording: boolean;
  isMuted: boolean;
  microphoneAccess: boolean;
  mediaRecorder?: MediaRecorder;
};

export type Store = OldActionComposeStore &
  EmailGenerationStore &
  PhoneStore &
  MessageBarStore & {
    action: FullAction;
    upsertAction: (action: FullAction) => void;
    originalAction: FullAction;
    // This method is used to update the original action which we use for dirty checking in the action
    // compose window
    updateOriginalAction: (partialAction: Partial<FullAction>) => void;
    updateOriginalLoggingTo: (loggingTo: Partial<LoggingTo>) => void;
    updateAction: (partialAction: Partial<FullAction>) => void;
    updateCurrentLoggingTo: (loggingTo: Partial<LoggingTo>) => void;
    insertAtCursorAndUpdateAction: (partialAction: Partial<FullAction>) => void;
    actionComposeStatus: ActionComposeEnum;
    setActionComposeStatus: (status: ActionComposeEnum) => void;
    otherValues: OtherValues;
    setOtherValues: (values: Partial<OtherValues>) => void;
    toggleTemplate: (values: PaneValues) => void;
    toggleScheduler: (values: PaneValues) => void;
    toggleEmailGeneration: (values: PaneValues) => void;
    modalValues: ModalValues;
    setNewModal: (values: Partial<ModalValues>) => void;
    setModalValues: (values: Partial<ModalValues>) => void;
    externalModalValues: ExternalModalValues;
    setExternalModalValues: (values: Partial<ExternalModalValues>) => void;
    externalMetadata: ExternalMetadata;
    setExternalMetadata: (values: ExternalMetadata) => void;
    externalMetadataRecords: LoggingTo;
    setExternalMetadataRecords: (values: LoggingTo) => void;
    resetExternalMetadata: () => void;
    updateSelectedVariant: (variantId: number) => void;
    cleanUpActionCompose: () => void;
    updateDynamicData: (formName: 'loggingForm', field: FormField) => void;
    onActionComposePaneComplete: (() => void) | null;
    setOnActionComposePaneComplete: (value: (() => void) | null) => void;
    dialerEnabled: boolean;
    dialerState: DialerStateType;
    updateDialerState: (state: Partial<DialerStateType>) => void;
    setHasCalled: (state: boolean) => void;
  };

export const defaultOtherValues: OtherValues = {
  isTaskPaneOpen:
    window.localStorage.getItem(TASK_PANE_LOCAL_KEY)?.toLowerCase() !== 'false',
  isTaskPaneOpenPast:
    window.localStorage.getItem(TASK_PANE_LOCAL_KEY)?.toLowerCase() !== 'false',
  isExtendedTaskPaneOpen: false,
  taskPaneType: 'TASK',
  selectedTemplate: null,
  getCurrentInputRef: undefined,
  selectedFlow: undefined,
  selectionStart: 0,
  selectionEnd: 0,
  pauseDate: new Date(),
  wysiwygEdited: false,
  wysiwygSelection: null,
  isTemplateEditorOpen: false,
  isTemplatesAppOpen: false,
  relatedToLastOpen: null,
  selectedFieldsPivot: 'standard-fields',
  isCallResultRequired: false,
  isCallResultDisplayed: false,
  trackingEnabled: false,
  lastUnmergedFields: {},
  isFieldsMerging: false,
  isFetching: false,
  isMutating: false,
  taskPaneInitialClose: false,
  mergingField: '',
  isEmailOptOutSafeguardDisabled: false,
  contactEmailOptOutField: 'HasOptedOutOfEmail',
  contactCallOptOutField: 'DoNotCall',
  contactSmsOptOutField: 'HasOptedOutOfSms',
  leadEmailOptOutField: 'HasOptedOutOfEmail',
  leadCallOptOutField: 'DoNotCall',
  leadSmsOptOutField: 'HasOptedOutOfSms',
  smsEnabled: false,
  phoneNumber: null,
};

export const defaultModalValues: ModalValues = {
  isOpen: false,
  content: undefined,
  onConfirm: undefined,
  title: undefined,
  confirmText: undefined,
  contentText: undefined,
  isLinkedInFallback: false,
  linkedInFallbackText: '',
};

export const defaultExternalModalValues: ExternalModalValues = {
  isSchedulerOpen: false,
};

export const defaultDialerState: DialerStateType = {
  isRecording: false,
  isMuted: false,
  microphoneAccess: false,
};

const sanitizedBody = (action: FullAction): FullAction => {
  const { body, type, summary } = action;
  const tempAction = {
    ...action,
    summary: sanitizeHtml(summary || '', {
      allowedTags: [],
    }),
  };
  if (
    type !== 'STEP_SUBTYPE_SMS' &&
    type !== 'STEP_SUBTYPE_LINKEDIN_INMAIL' &&
    type !== 'STEP_SUBTYPE_LINKEDIN_CONNECT'
  ) {
    if (body) {
      tempAction.body = body.replace(
        '{!groove.sfdc_instance_url}',
        action.crmInstanceUrl || '',
      );
    }
    return tempAction;
  }

  return {
    ...tempAction,
    body: sanitizeHtml(body || '', {
      allowedTags: [],
    }),
  };
};

const dedupRecipientArray = <T>(array: T): T => {
  const resultArray: T[] = [];
  const recipientHash: { [key: string]: boolean } = {};

  if (!array || !Array.isArray(array)) return array;

  array.forEach(recipient => {
    const key =
      (recipient.id ? recipient.id.toString() : recipient.email?.toString()) ||
      '';
    if (!recipientHash[key]) {
      recipientHash[key] = true;
      resultArray.push(recipient);
    }
  });

  return resultArray as T;
};

const dedupArrayFields = (action: FullAction): FullAction => {
  const { toRecipients, ccRecipients, bccRecipients, loggingTo } = action;

  return {
    ...action,
    loggingTo: {
      ...loggingTo,
      who: dedupRecipientArray(loggingTo.who) as crmObject[],
      what: dedupRecipientArray(loggingTo.what) as crmObject[],
    },
    toRecipients: dedupRecipientArray(toRecipients),
    ccRecipients: dedupRecipientArray(ccRecipients),
    bccRecipients: dedupRecipientArray(bccRecipients),
  };
};

const useStore = create<Store>((set, get) => ({
  ...oldActionComposeStore(set, get),
  ...emailGenerationStore(set, get),
  ...phoneStore(set, get),
  ...messageBarStore(set, get),
  action: emptyAction,
  // upsertAction is for new actions
  upsertAction: action => {
    set(state => {
      get().clearMessages();
      actionValidator(action);
      return {
        otherValues: {
          ...state.otherValues,
          wysiwygEdited: false,
        },
        action: sanitizedBody(action),
        originalAction: sanitizedBody(action),
      };
    });
  },
  originalAction: emptyAction,
  updateOriginalAction: partialAction =>
    set(
      produce((draft: Store) => {
        const { originalAction } = get();
        draft.originalAction = sanitizedBody({
          ...originalAction,
          ...partialAction,
        });
      }),
    ),
  updateOriginalLoggingTo: loggingTo =>
    set(
      produce((draft: Store) => {
        const { originalAction } = get();
        draft.originalAction = {
          ...originalAction,
          loggingTo: { ...originalAction.loggingTo, ...loggingTo },
        };
      }),
    ),
  updateAction: partialAction =>
    set(store => {
      let action = {
        ...store.action,
        ...partialAction,
      };

      if (
        partialAction.toRecipients ||
        partialAction.ccRecipients ||
        partialAction.bccRecipients
      )
        action = dedupArrayFields(action);

      if (partialAction.body || partialAction.type || partialAction.subject)
        action = sanitizedBody(action);

      mergeFieldHandler(action);
      actionValidator(action);

      return { action };
    }),
  updateCurrentLoggingTo: async newLoggingTo => {
    const { grooveMessageEvent: eventData } = get().otherValues;
    const { toRecipients } = get().action;
    const loggingTo = { ...newLoggingTo };

    const isMultipleRelatedTo =
      (eventData?.bestMatchEmailLogging &&
        eventData.bestMatchAllowMultiSelect) ||
      !eventData?.bestMatchEmailLogging;

    if (!isMultipleRelatedTo && loggingTo.what && loggingTo.what.length > 1)
      loggingTo.what = [loggingTo.what[0]];

    if (loggingTo.who && loggingTo.who.length > 50)
      loggingTo.who = loggingTo.who.slice(0, 49);

    if (loggingTo.who && loggingTo.who.length < 1 && loggingTo.removedWhat)
      loggingTo.removedWhat = {};
    if ((!toRecipients || toRecipients.length < 1) && loggingTo.removedWho)
      loggingTo.removedWho = {};

    return set(
      produce((draft: Store) => {
        draft.action.loggingTo = {
          ...get().action.loggingTo,
          ...loggingTo,
        };
      }),
    );
  },
  updateSelectedVariant: (variantId: number) => {
    const currentAction = get().action;
    const selectedVariant = currentAction?.variants?.find(
      variant => variant.id === variantId,
    );

    if (selectedVariant) {
      useStore.getState().updateAction({
        variantId,
        subject: selectedVariant.template.subject,
        body: selectedVariant.template.body,
      });
    }
    useStore.getState().setOtherValues({ wysiwygEdited: false });
  },
  cleanUpActionCompose: () => {
    get().upsertAction({ ...emptyAction });
    get().setModalValues(defaultModalValues);
    get().setOtherValues(defaultOtherValues);
    get().setDefaultEmailGeneration(INIT_DEFAULT_COPILOT_CALL);
    get().setActionComposeStatus(ActionComposeEnum.CLOSED);
    useTemplatesStore.getState().setSelectedTemplate(undefined);
  },
  insertAtCursorAndUpdateAction: partialAction => {
    const { action } = get();
    const { getCurrentInputRef, selectionStart, selectionEnd } =
      get().otherValues;

    const insertText = partialAction.body || '';
    const currentText = action.body || '';
    let newText = '';

    if (
      action.type !== 'STEP_SUBTYPE_SMS' &&
      action.type !== 'STEP_SUBTYPE_LINKEDIN_INMAIL' &&
      action.type !== 'STEP_SUBTYPE_LINKEDIN_CONNECT'
    ) {
      tinymce?.activeEditor?.insertContent(insertText);
      newText = tinymce?.activeEditor?.getContent();
    } else {
      if (!getCurrentInputRef) return;
      const currentInputRef = getCurrentInputRef();

      if (!currentInputRef || !currentInputRef.current) return;

      const { newCursorPosition, combinedString } = stringInserter({
        originalString: currentText,
        newString: insertText,
        selectionStart,
        selectionEnd,
      });

      newText = combinedString;
      currentInputRef.current.focus();
      currentInputRef.current.selectionStart = newCursorPosition;
      currentInputRef.current.selectionEnd = newCursorPosition;
    }

    const draftAction = sanitizedBody({
      ...action,
      ...partialAction,
      body: newText,
    });

    actionValidator(draftAction);
    mergeFieldHandler(draftAction);

    set({ action: draftAction });
  },
  actionComposeStatus: ActionComposeEnum.CLOSED,
  setActionComposeStatus: status => {
    if (status === ActionComposeEnum.CLOSED)
      set({ actionComposeStatus: status, action: emptyAction });
    else set({ actionComposeStatus: status });
  },
  otherValues: defaultOtherValues,
  setOtherValues: values => {
    const otherValues = { ...get().otherValues };
    set({
      otherValues: {
        ...otherValues,
        ...values,
      },
    });
  },
  toggleTemplate: values =>
    set({
      otherValues: {
        ...get().otherValues,
        taskPaneType:
          get().otherValues.taskPaneType === 'TEMPLATE' ? 'TASK' : 'TEMPLATE',
        isTaskPaneOpenPast:
          get().otherValues.taskPaneType === 'TASK'
            ? get().otherValues.isTaskPaneOpen
            : get().otherValues.isTaskPaneOpenPast,
        isTaskPaneOpen:
          get().otherValues.isTaskPaneOpen &&
          get().otherValues.taskPaneType === 'TEMPLATE'
            ? get().otherValues.isTaskPaneOpenPast
            : true,
        isExtendedTaskPaneOpen: false,
        ...values,
      },
    }),
  toggleScheduler: values =>
    set({
      otherValues: {
        ...get().otherValues,
        taskPaneType:
          get().otherValues.taskPaneType === 'SCHEDULER' ? 'TASK' : 'SCHEDULER',
        isTaskPaneOpenPast:
          get().otherValues.taskPaneType === 'TASK'
            ? get().otherValues.isTaskPaneOpen
            : get().otherValues.isTaskPaneOpenPast,
        isTaskPaneOpen:
          get().otherValues.isTaskPaneOpen &&
          get().otherValues.taskPaneType === 'SCHEDULER'
            ? get().otherValues.isTaskPaneOpenPast
            : true,
        isExtendedTaskPaneOpen: false,
        ...values,
      },
    }),
  toggleEmailGeneration: values =>
    set({
      otherValues: {
        ...get().otherValues,
        taskPaneType:
          get().otherValues.taskPaneType === 'EMAIL_GENERATION'
            ? 'TASK'
            : 'EMAIL_GENERATION',
        isTaskPaneOpenPast:
          get().otherValues.taskPaneType === 'TASK'
            ? get().otherValues.isTaskPaneOpen
            : get().otherValues.isTaskPaneOpenPast,
        isTaskPaneOpen:
          get().otherValues.isTaskPaneOpen &&
          get().otherValues.taskPaneType === 'EMAIL_GENERATION'
            ? get().otherValues.isTaskPaneOpenPast
            : true,
        isExtendedTaskPaneOpen: false,
        ...values,
      },
    }),
  modalValues: defaultModalValues,
  setNewModal: values =>
    set({
      modalValues: {
        ...defaultModalValues,
        ...values,
      },
    }),
  setModalValues: values =>
    set({
      modalValues: {
        ...get().modalValues,
        ...values,
      },
    }),
  externalModalValues: defaultExternalModalValues,
  setExternalModalValues: values =>
    set({
      externalModalValues: {
        ...get().externalModalValues,
        ...values,
      },
    }),
  externalMetadata: {
    lookups: [],
  },
  setExternalMetadata: values =>
    set({
      externalMetadata: values,
    }),
  externalMetadataRecords: {},
  setExternalMetadataRecords: values =>
    set({
      externalMetadataRecords: values,
    }),
  resetExternalMetadata: () =>
    set({
      externalMetadata: {
        lookups: [],
      },
      externalMetadataRecords: {},
    }),
  dialerState: defaultDialerState,
  updateDialerState: values =>
    set({
      dialerState: {
        ...get().dialerState,
        ...values,
      },
    }),
  updateDynamicData: (formName, field) => {
    const action: FullAction = cloneDeep(get().action);
    const currentFields = action.dynamicData[formName]?.fields;
    const fieldIndex = currentFields
      ? currentFields?.findIndex(
          currentField => currentField.name === field.name,
        )
      : -1;
    const currentForm = action.dynamicData[formName];

    if (currentForm && currentFields && fieldIndex > -1) {
      currentForm.fields = [
        ...currentFields.slice(0, fieldIndex),
        { ...currentFields[fieldIndex], value: field.value },
        ...currentFields.slice(fieldIndex + 1),
      ];
    }

    actionValidator(action);
    set({ action });
  },
  onActionComposePaneComplete: null,
  setOnActionComposePaneComplete: (
    newOnActionComposePaneComplete: (() => void) | null,
  ) => {
    set({
      onActionComposePaneComplete: newOnActionComposePaneComplete,
    });
  },
  dialerEnabled: false,
  setHasCalled: state => {
    const action: FullAction = cloneDeep(get().action);
    action.dynamicData.hasCalled = state;
    set({ action });
  },
}));

export default useStore;
