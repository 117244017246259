import { decamelizeKeys } from 'humps';
import { POST } from 'GrooveHTTPClient/HTTPMethod';
import makeHTTPRequest from 'GrooveHTTPClient/makeHTTPRequest';
import uploadFileChannel from 'GrooveHTTPClient/makeHTTPUploadCall';

const BASE_PATH = '/api/v2/people_import';

// Note: leaving these API routes alone for now given how utter shit they are.

export const quickSearch = ({ phrase, flowId = null }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/quick_search`,
    queryParameters: decamelizeKeys({
      phrase,
      flowId,
    }),
  });

export const listViews = sObjectType =>
  makeHTTPRequest({
    path: `${BASE_PATH}/list_views`,
    queryParameters: {
      sobject_type: sObjectType,
    },
  });

export const listView = ({
  sObjectType,
  listViewId,
  relationshipName,
  flowId = null,
}) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/list_view`,
    queryParameters: decamelizeKeys({
      sobject_type: sObjectType,
      listViewId,
      relationshipName,
      flowId,
    }),
  });

export const reportFolders = () =>
  makeHTTPRequest({ path: `${BASE_PATH}/report_folders` });

export const reports = ({ folderName, showOnlyMyReports }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/reports`,
    queryParameters: {
      folder_name: folderName,
      show_only_my_reports: showOnlyMyReports,
    },
  });

export const reportInfo = ({ reportId }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/report_info`,
    queryParameters: {
      report_id: reportId,
    },
  });

export const contactAndLeadIdsFromReport = ({ reportId }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/contact_and_lead_ids_from_report`,
    queryParameters: decamelizeKeys({
      reportId,
    }),
  });

export const searchBySoqlQuery = ({
  objectName,
  soqlQuery,
  relationshipName,
  contactConditions,
  limit,
  offset,
  flowId = null,
}) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/fetch_objects_by_query`,
    queryParameters: decamelizeKeys({
      soqlQuery,
      selected_object: objectName,
      relationshipName,
      contactConditions,
      limit,
      offset,
      flowId,
    }),
  });

export const searchPeople = ({
  objectName,
  values,
  selectedObjectField,
  limit,
  offset,
  flowId = null,
}) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/people`,
    queryParameters: decamelizeKeys({
      values,
      selected_object: objectName,
      selectedObjectField,
      limit,
      offset,
      flowId,
    }),
  });

export const getFilterableFields = salesforceObject =>
  makeHTTPRequest({
    path: `${BASE_PATH}/get_object_describe`,
    queryParameters: {
      sobject_type: salesforceObject,
    },
  });

/**
 * @param {number} flowId
 * @param {object} peopleData
 * @param {boolean} createSfdcCampaign
 *
 * @return {Promise}
 * */
export const importPeople = (
  flowId,
  peopleData,
  personFlowOwnerId = null,
  createSfdcCampaign = false,
  duplicatedContactsVisible = false
) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/import`,
    method: POST,
    body: {
      flow_id: flowId,
      peopleToImport: peopleData,
      personFlowOwnerId,
      isCreateSfdcCampaign: createSfdcCampaign,
      duplicatedContactsVisible,
    },
  });

export const uploadCsv = file => {
  return uploadFileChannel('/api/v2/people_import/upload_csv', file);
};

export const reportUserColumns = reportSfdcId =>
  makeHTTPRequest({
    path: `${BASE_PATH}/report_user_columns`,
    queryParameters: {
      report_id: reportSfdcId,
    },
  });
