import keyMirror from 'Utils/key-mirror';
import { standardActions } from '@groove-labs/action-utils';

export const actionTypes = keyMirror(
  {
    FETCH_ENGAGED_FLOWS: standardActions(),
    LOAD_DATA: null,
    UPDATE_PAGINATION: null,
    UPDATE_SORTING: null,
    ADD_SELECTED_ROWS: null,
    REMOVE_SELECTED_ROWS: null,
    TOGGLE_HIDE_DUPLICATE_SEARCH_RESULTS: null,
    TOGGLE_SELECT_ALL_WITHOUT_WARNINGS: null,
    SELECT_ALL_ROWS: null,
    UNSELECT_ALL_ROWS: null,
    SELECT_ALL_ROWS_WITHOUT_WARNING: null,
    UNSELECT_ALL_ROWS_WITH_WARNING: null,
    RESET_TABLE: null,
  },
  'PEOPLE_IMPORT_DIALOG',
  'PEOPLE_TABLE'
);

export function loadData(value) {
  return {
    type: actionTypes.LOAD_DATA,
    payload: value,
  };
}

export function updatePagination({ page, perPage }) {
  return {
    type: actionTypes.UPDATE_PAGINATION,
    payload: { page, perPage },
  };
}

export function updateSorting({ orderBy, orderDirection }) {
  return {
    type: actionTypes.UPDATE_SORTING,
    payload: { orderBy, orderDirection },
  };
}

export function addSelectedRows(rows) {
  return {
    type: actionTypes.ADD_SELECTED_ROWS,
    payload: rows,
  };
}

export function removeSelectedRows(rows) {
  return {
    type: actionTypes.REMOVE_SELECTED_ROWS,
    payload: rows,
  };
}

export function toggleHideDuplicateSearchResults() {
  return {
    type: actionTypes.TOGGLE_HIDE_DUPLICATE_SEARCH_RESULTS,
  };
}

export function toggleSelectAllWithoutWarnings() {
  return {
    type: actionTypes.TOGGLE_SELECT_ALL_WITHOUT_WARNINGS,
  };
}

export function selectAllRows() {
  return {
    type: actionTypes.SELECT_ALL_ROWS,
  };
}

export function unselectAllRows() {
  return {
    type: actionTypes.UNSELECT_ALL_ROWS,
  };
}

export function selectAllRowsWithoutWarnings() {
  return {
    type: actionTypes.SELECT_ALL_ROWS_WITHOUT_WARNING,
  };
}

export function unselectAllRowsWithWarnings() {
  return {
    type: actionTypes.UNSELECT_ALL_ROWS_WITH_WARNING,
  };
}

export function resetTableResults() {
  return {
    type: actionTypes.RESET_TABLE,
  };
}

export function fetchEngagedFlows(personEmail, personSfdcId) {
  return {
    type: actionTypes.FETCH_ENGAGED_FLOWS.BEGIN,
    payload: { personEmail, personSfdcId },
  };
}

export function storeEngagedFlows(personSfdcId, flows) {
  return {
    type: actionTypes.FETCH_ENGAGED_FLOWS.SUCCESS,
    payload: { personSfdcId, flows },
  };
}

export function engagedFlowsRequestProgress({ personSfdcId }) {
  return {
    type: actionTypes.FETCH_ENGAGED_FLOWS.PROGRESS,
    payload: { personSfdcId },
  };
}

export function engagedFlowsRequestFailure({ message, personSfdcId }) {
  return {
    type: actionTypes.FETCH_ENGAGED_FLOWS.FAILURE,
    payload: { personSfdcId, message },
  };
}
