import { put, select } from 'redux-saga/effects';
import { getTypeaheadSearchResults } from 'Modules/App/selectors';
import { actionTypes as appActionTypes } from 'Modules/App/actions';
import { currentUser } from 'Modules/Shared/selectors/users';
import moment from 'moment';

function* handleUpdateLocalSearchData({ payload: { personId, flowId } }) {
  const searchResults = yield select(getTypeaheadSearchResults);
  const personToUpdateIndex = searchResults.findIndex(
    personRecord => personRecord.get('id') === personId
  );
  const shouldUpdateOneFlow = !!flowId;
  const dateFormat = 'MM-DD-YYYY';
  const user = yield select(currentUser);
  const userId = user.get('id');

  if (personToUpdateIndex >= 0) {
    const personToUpdate = searchResults.get(personToUpdateIndex);

    let updatedPersonFlows;
    if (shouldUpdateOneFlow) {
      const flowToUpdateIndex = personToUpdate
        .get('flows')
        .findIndex(flow => flow.get('id') === flowId);
      updatedPersonFlows = personToUpdate
        .get('flows')
        .update(flowToUpdateIndex, flow =>
          flow.set('disabledAt', moment().format(dateFormat))
        );
    } else {
      updatedPersonFlows = personToUpdate
        .get('flows')
        .map(flow =>
          flow.get('userId') === userId
            ? flow.set('disabledAt', moment().format(dateFormat))
            : flow
        );
    }

    const updatedPersonRecord = personToUpdate.set('flows', updatedPersonFlows);
    const updatedSearchResults = searchResults.set(
      personToUpdateIndex,
      updatedPersonRecord
    );
    yield put({
      type: appActionTypes.UPDATE_LOCAL_SEARCH_RESULTS,
      payload: { updatedSearchResults },
    });
  }
}

export default handleUpdateLocalSearchData;
