import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { getLoginAsLoginInProgress } from 'Modules/App/selectors';
import { loginAsUserBegin } from 'Modules/App/actions';
import { getIsGrooveCurrentUser } from 'Modules/Shared/selectors/users';

import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

const styles = theme => ({
  row: {
    height: 'auto',
    '&:hover': {
      '& td:last-child': {
        visibility: 'visible',
      },
    },
  },
  cell: {
    borderBottom: 'none',
    cursor: 'pointer',
    paddingLeft: 0,
    paddingRight: theme.groove.unit * 3,
    paddingTop: 10,
    paddingBottom: 10,
    '&:last-child': {
      paddingRight: theme.spacing.unit,
    },
    '&:first-child': {
      paddingLeft: theme.groove.unit * 3,
    },
  },
  cellActions: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    visibility: 'hidden',
    fontWeight: 500,
  },
});

@withStyles(styles)
@connect(
  state => ({
    isGrooveCurrentUser: getIsGrooveCurrentUser(state),
    loginAsInProgress: getLoginAsLoginInProgress(state),
  }),
  {
    loginAsUserBegin,
  }
)
export default class UserTableRow extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    isGrooveCurrentUser: PropTypes.bool.isRequired,
    loginAsUserBegin: PropTypes.func.isRequired,
    user: ImmutablePropTypes.map.isRequired,
  };

  loginAsUser = () => {
    const { loginAsUserBegin, user } = this.props;

    loginAsUserBegin({
      userId: user.get('id'),
    });
  };

  renderOrganizationCell = () => {
    const { classes, user } = this.props;

    const orgSfdcId = user.get('orgSfdcId');

    if (!orgSfdcId) {
      return null;
    }

    return (
      <TableCell align="left" classes={{ root: classes.cell }}>
        {orgSfdcId}
      </TableCell>
    );
  };

  render() {
    const { classes, isGrooveCurrentUser, user } = this.props;

    return (
      <TableRow
        classes={{ root: classes.row }}
        hover
        onClick={this.loginAsUser}
      >
        {isGrooveCurrentUser && (
          <TableCell align="left" classes={{ root: classes.cell }}>
            {user.get('id')}
          </TableCell>
        )}
        <TableCell align="left" classes={{ root: classes.cell }}>
          {user.get('name')}
        </TableCell>
        <TableCell align="left" classes={{ root: classes.cell }}>
          {user.get('email')}
        </TableCell>
        {isGrooveCurrentUser && this.renderOrganizationCell()}
        <TableCell classes={{ root: cx(classes.cell, classes.cellActions) }}>
          Select
        </TableCell>
      </TableRow>
    );
  }
}
