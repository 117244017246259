import ClariTheme from 'Utils/theme/ClariTheme';

const pulse = {
  backgroundColor: ClariTheme.palette.text.backgroundColor,
  animationName: '$pulse',
  animationDuration: '1.5s',
  animationIterationCount: 'infinite',
};

export default pulse;
