import PicklistFieldValues from '../../v2/types/PicklistFieldValues';

import getClient from './getClient';

export type ApiSalesforceUserInfo = {
  data: {
    username: string;
  };
};

export const salesforceUserInfo = (): Promise<ApiSalesforceUserInfo> => {
  return getClient().post('salesforce/user_info').json();
};

export const salesforceDisconnect = async (): Promise<void> => {
  await getClient().post('salesforce/disconnect').json();
  window.dispatchEvent(new CustomEvent('UPDATE_TROUBLESHOOTING_PANE'));
};

export type ApiPicklistFieldValues = {
  data: PicklistFieldValues;
};

export const salesforcePicklistFieldValues = async (
  sObjectType: string,
  recordTypeId: string | null,
): Promise<ApiPicklistFieldValues> => {
  return getClient()
    .post('salesforce/picklist_field_values', {
      json: {
        variables: { s_object_type: sObjectType, record_type_id: recordTypeId },
      },
    })
    .json();
};

type PicklistItem = {
  label: string;
  value: string;
  validFor: string | null;
};

export type SalesforceMetaItem = {
  name: string;
  label: string;
  updateable: boolean;
  type:
    | 'boolean'
    | 'date'
    | 'datetime'
    | 'double'
    | 'integer'
    | 'lookup'
    | 'multipicklist'
    | 'percent'
    | 'picklist'
    | 'reference'
    | 'string'
    | 'textarea'
    | 'time';
  dependentPicklist: boolean;
  restrictedpicklist: boolean;
  reservedField: boolean;
  picklist?: PicklistItem[];
  referenceTo?: string;
};

export type ApiSalesforceLogFields = {
  data: SalesforceMetaItem[];
};

export const salesforceLogFields = (
  type = 'email',
  additionalFields: string | undefined,
): Promise<ApiSalesforceLogFields> => {
  return getClient()
    .post('salesforce/log_fields', {
      json: {
        variables: {
          type,
          additional_fields: additionalFields,
        },
      },
    })
    .json();
};

export type SalesforcePerson = {
  Id: string;
  Name: string;
  FirstName?: string;
  LastName?: string;
  Title?: string;
  Account?: {
    Name?: string;
  };
  Company?: string;
  Email?: string;
  Phone?: string;
};

export type ApiSalesforcePeople = {
  data: SalesforcePerson[] | null;
};

export const salesforceGetListView = async (
  listViewId: string,
  sobjectType: string,
): Promise<ApiSalesforcePeople> => {
  return getClient()
    .post('salesforce/list_view', {
      json: {
        variables: {
          list_view_alias: listViewId,
          sobject_type: sobjectType,
        },
      },
    })
    .json();
};

export const salesforceGetReportPeople = async (
  reportId: string,
): Promise<ApiSalesforcePeople> => {
  return getClient()
    .post('salesforce/report_people', {
      json: {
        variables: {
          report_id: reportId,
        },
      },
    })
    .json();
};
