import cx from 'classnames';
import { favoriteTemplatesGet } from '@groove/api/services/v1/favoriteTemplates';
import { FC, HTMLProps, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Template, TemplateType } from '@groove/api/gateway/v1/template';
import PivotHeader, { HeaderItem } from '@groove/ui/Components/PivotHeader';
import Button from '@groove/ui/Components/Button';
import {
  templateFoldersFavorites,
  templateFoldersSearch,
} from '@groove/api/services/v1/templateFolders';

import useStore from '../store/useStore';
import {
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  TEMPLATES_MODAL_SOURCE,
  TEMPLATES_TAB,
  trackTemplatesEvent,
} from '../analytics/templatesEvents';

import TemplatesSearchBox from './TemplatesSearchBox';
import TemplatesSearchList from './TemplatesSearchList';
import RecentlyUsedTemplatesList from './RecentlyUsedTemplatesList';
import FavoriteTemplatesList from './FavoriteTemplatesList';

type TemplatePickerPaneProps = {
  onInsert: (template: Template) => void;
  onDismiss: () => void;
  onNewTemplate: (open: boolean) => void;
  onAllTemplates: (open: boolean) => void;
  className?: HTMLProps<HTMLElement>['className'];
  /** This is text that is used to replace the insert button */
  customActionText?: string;
  templateType?: TemplateType;
  templateSubType?: string;
};

const pivotItems: HeaderItem[] = [
  {
    value: 'templates',
    label: 'Templates',
  },
  {
    value: 'favorites',
    label: 'Favorites',
  },
];

const TemplatePickerPane: FC<TemplatePickerPaneProps> = ({
  onInsert,
  onDismiss,
  onNewTemplate,
  onAllTemplates,
  className,
  customActionText,
  templateType = 'email',
  templateSubType = '',
}) => {
  const [selectedPivot, setSelectedPivot] = useState(pivotItems[0].value);
  const setSelectedTemplatePanelTab = useStore(
    store => store.setSelectedTemplatePanelTab,
  );
  const selectedTemplatePanelTab = useStore(
    store => store.selectedTemplatePanelTab,
  );
  const { data: favoriteTemplatesData } = useQuery(
    [`favoriteTemplatesGet`, templateType],
    () => favoriteTemplatesGet(templateType, templateSubType),
  );
  const {
    data: favoriteTemplateFoldersData,
    isFetching: isFetchingFavoriteFolders,
  } = useQuery(['favoriteTemplateFoldersGet', templateType], () =>
    templateFoldersFavorites(templateType, templateSubType),
  );

  const setFavoriteTemplates = useStore(store => store.setFavoriteTemplates);
  const setFavoriteTemplateFolders = useStore(
    store => store.setFavoriteTemplateFolders,
  );
  const searchTerm = useStore(store => store.searchTerm);
  // This is stored and later used by TemplatePreviewPane to show if template is editable or not
  const { data: templateFolders, isFetching: isFetchingTemplateFolders } =
    useQuery(['templateFoldersSearch', 'initialFoldersQuery'], () => {
      return templateFoldersSearch({
        search_term: '',
        template_type: templateType,
        template_subtype: templateSubType,
      });
    });

  useEffect(() => {
    if (templateFolders) {
      useStore.getState().setTemplateFolders(templateFolders);
    }
  }, [searchTerm, templateFolders]);

  useEffect(() => {
    useStore.setState({ templateType });
  }, [templateType]);

  useEffect(() => {
    if (favoriteTemplatesData?.data) {
      setFavoriteTemplates(favoriteTemplatesData.data);
    }
  }, [favoriteTemplatesData, setFavoriteTemplates]);

  useEffect(() => {
    if (favoriteTemplateFoldersData?.data) {
      setFavoriteTemplateFolders(favoriteTemplateFoldersData.data);
    }
  }, [favoriteTemplateFoldersData, setFavoriteTemplateFolders]);

  useEffect(() => {
    useStore.getState().templatesApp.setCustomActionText(customActionText);
  }, [customActionText]);

  return (
    <div
      className={cx(
        'flex overflow-hidden items-center justify-center flex-col',
        className,
      )}
    >
      <PivotHeader
        selectedValue={selectedPivot}
        headerItems={pivotItems}
        onSelect={(value: string) => {
          setSelectedPivot(value);
          useStore.getState().setSelectedTemplate(undefined);
          trackTemplatesEvent({
            eventName: TEMPLATES_EVENT_TYPE.TAB_SWITCHED,
            templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.PANEL,
            templateTab: value as TEMPLATES_TAB,
          });
        }}
        className="px-[12px] box-border bg-neutral/25"
      />
      <div className="flex flex-col flex-1 min-h-0 min-w-0 w-full">
        {selectedPivot === pivotItems[0].value && <TemplatesSearchBox />}
        {selectedPivot === pivotItems[0].value && searchTerm && (
          <TemplatesSearchList
            onInsert={onInsert}
            dismiss={onDismiss}
            setIsTemplatesAppOpen={onAllTemplates}
            templateType={templateType}
          />
        )}
        {selectedPivot === pivotItems[0].value && !searchTerm && (
          <RecentlyUsedTemplatesList
            onInsert={onInsert}
            templateType={templateType}
            templateSubType={templateSubType}
          />
        )}
        {selectedPivot === pivotItems[1].value && (
          <FavoriteTemplatesList
            onInsert={onInsert}
            isFetchingFavorites={
              isFetchingTemplateFolders || isFetchingFavoriteFolders
            }
          />
        )}
      </div>

      <div
        id="templates-popup-footer"
        className="flex justify-end w-full border-solid border-0 border-t-[1px] border-gray6"
      >
        <Button
          onClick={() => {
            useStore.getState().setSearchTerm('');
            onAllTemplates(true);
            onDismiss();
            trackTemplatesEvent({
              eventName: TEMPLATES_EVENT_TYPE.MODAL_OPENED,
              templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.PANEL,
              templatesModalSource: TEMPLATES_MODAL_SOURCE.EXPAND,
            });
          }}
          className="my-2"
          variant="secondary"
        >
          See all templates
        </Button>
        <Button
          onClick={() => {
            onNewTemplate(true);
            useStore.getState().setIsNewTemplate(true);
            onDismiss();
            trackTemplatesEvent({
              eventName: TEMPLATES_EVENT_TYPE.CREATE_NEW_MODAL_OPENED,
              templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.PANEL,
              templateTab: selectedTemplatePanelTab,
            });
          }}
          className="m-2"
          variant="secondary"
        >
          New
        </Button>
      </div>
    </div>
  );
};

export default TemplatePickerPane;
