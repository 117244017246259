import { UseQueryResult, useQuery } from 'react-query';

import {
  ApiFetchEmailFooter,
  fetchEmailFooter,
  fetchEmailFooterMerged,
} from '../gateway/v1/users';

const useFetchFooter = (
  flowId?: number | null,
): UseQueryResult<ApiFetchEmailFooter, unknown> =>
  useQuery(
    ['emailFooter', flowId],
    () => fetchEmailFooter({ flow_id: flowId }),
    {
      cacheTime: 1 * 60 * 1000, // 1 minute
      staleTime: 1 * 60 * 1000, // 1 minute
    },
  );

export const useFetchFooterMerged = (
  who_id?: string | null,
  what_id?: string | null,
): UseQueryResult<ApiFetchEmailFooter, unknown> =>
  useQuery(
    ['emailFooterMerged', who_id, what_id],
    () => fetchEmailFooterMerged({ who_id, what_id }),
    {
      cacheTime: 1 * 60 * 1000, // 1 minute
      staleTime: 1 * 60 * 1000, // 1 minute
    },
  );

export default useFetchFooter;
