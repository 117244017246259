import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { recentTemplatesSet } from '../services/v1/recentTemplates';

export type UseRecentTemplatesSetParams = {
  templateId: number;
};

const useRecentTemplatesSet = (): UseMutationResult<
  unknown,
  unknown,
  UseRecentTemplatesSetParams,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ templateId }) => recentTemplatesSet(templateId), {
    onSuccess: () => {
      queryClient.invalidateQueries('recentTemplatesGet');
    },
  });
};

export default useRecentTemplatesSet;
