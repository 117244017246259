import { FC, ChangeEvent, useState } from 'react';
import Input from '@groove/ui/Components/Input';
import { Toggle } from '@fluentui/react';
import Text from '@groove/ui/Components/Text';
import { fluentTheme } from '@groove/ui/theme';
import useFetchFooter from '@groove/api/hooks/useFetchFooter';
import useFetchEmailSignature from '@groove/api/hooks/useFetchEmailSignature';

import useStore from '../store/useStore';
import EmailEditor from '../component/EmailEditor';
import EmailPreview from '../component/EmailPreview';
import EmailRecipientFields from '../component/EmailRecipientFields';
import EmailFromDropDown from '../component/EmailFromDropDown';

const EmailPane: FC = () => {
  const [isPreview, setIsPreview] = useState<boolean | undefined>(false);

  const subject = useStore(store => store.action.subject);
  const fromEmail = useStore(store => store.action.fromEmail);
  const flowId = useStore(store => store.action.flow?.id);

  // prefetch the footer so that we don't have to load it again when opening preview
  useFetchFooter(flowId);
  useFetchEmailSignature(fromEmail || '');

  const onSubjectChange = (event: ChangeEvent<HTMLInputElement>): void =>
    useStore.getState().updateAction({ subject: event.target.value });

  return (
    // We need min width = 0 to make overflow work
    <div
      data-testid="email-pane"
      className="flex-1 pr-[12px] pl-[20px] flex flex-col flex-grow relative min-w-0"
    >
      <EmailFromDropDown />
      <EmailRecipientFields />
      <div className="flex flex-row h-[40px] align-middle">
        <div className="relative flex-1 h-full pr-3 flex flex-row items-center overflow-hidden">
          {isPreview && (
            <Text className="text-body-sm truncate">{subject || ''}</Text>
          )}
          {!isPreview && (
            <Input
              className="!border-0 !w-full !h-full !px-0 !text-body"
              onChange={onSubjectChange}
              value={subject || ''}
              placeholder="Subject"
            />
          )}
        </div>
        <div className="items-center flex">
          <Toggle
            className="m-0 mr-[8px]"
            onChange={(_, checked) => {
              useStore.getState().setOtherValues({ wysiwygEdited: false });
              setIsPreview(checked);
            }}
            checked={isPreview}
            styles={fluentTheme.components?.Toggle?.styles}
          />
          <Text variant="body">Preview</Text>
        </div>
      </div>
      {isPreview ? <EmailPreview /> : <EmailEditor />}
    </div>
  );
};

export default EmailPane;
