import { show } from 'GrooveHTTPClient/activitySettings';
import { location as getLocation } from 'Modules/Shared/selectors/location';
import { all, call, fork, put, select, takeLeading } from 'redux-saga/effects';

import { pushSnackbarMessage } from 'Modules/Shared/actions/app';

import {
  actionTypes,
  fetchSuccess,
} from 'Modules/Shared/actions/activitySettings';

export function* fetch() {
  try {
    const response = yield call(show);
    yield put(fetchSuccess(response.data));
  } catch (e) {
    const location = yield select(getLocation);
    const message = 'Failed to fetch Call Settings';

    yield put(pushSnackbarMessage({ message }));

    // don't throw error on MMOrg page
    if (
      !(
        location.get('rootPath') === 'settings' &&
        location.get('activeTab') === 'manage_my_org'
      )
    ) {
      throw e;
    }
  }
}

function* watchFetch() {
  yield takeLeading(actionTypes.FETCH.BEGIN, fetch);
}

export default function* root() {
  yield all([fork(watchFetch)]);
}
