import { Dialog } from '@groove-labs/groove-ui';
import React, { Component } from 'react';
import { ERROR_DIALOG_UI_KEY_PATH } from 'Modules/Shared/constants';
import Error from 'Modules/Shared/components/ErrorStates/ErrorDialog';
import LogRocket from 'logrocket';

export default class ErrorDialog extends Component {
  static muiDialogProps = {
    onEnter: () => LogRocket._isInitialized && LogRocket.track('ICD Displayed'),
  };

  render() {
    return (
      <Dialog
        isOpenUiKeyPath={ERROR_DIALOG_UI_KEY_PATH}
        muiProps={ErrorDialog.muiDialogProps}
      >
        <Error
          showRecoveryButton
          showSubmitFeedbackButton
          onRecovery={() => window.location.reload()} // Make sure we always reload to the same location
        />
      </Dialog>
    );
  }
}
