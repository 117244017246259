import { call } from 'redux-saga/effects';
import { trackBasicEvent } from 'Modules/Shared/sagas/analytics';
import { actionTypes } from 'Modules/FlowsShow/actions/variants';

function* stepStatsViewed() {
  yield call(trackBasicEvent, {
    featureCategory: 'A/B Testing',
    eventName: 'A/B Step Stats Viewed',
    action: actionTypes.ANALYTICS_DIALOG.OPEN,
  });
}

export default stepStatsViewed;
