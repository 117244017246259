import { camelizeKeys, decamelizeKeys } from 'humps';

import { ActionPriorityTypes } from '../../services/v1/actions';
import { Variant } from '../../services/v1/variant';

import getClient from './getClient';
import { SearchUser } from './users';
import { SearchPerson } from './people';

export type SourceType =
  | 'FLOW'
  | 'WORKSPACE'
  | 'ONE_OFF'
  | 'SUGGESTED_ACTION'
  | 'PLAY';

export type RecipientType = {
  id?: string;
  name?: string;
  title?: string;
  company?: string;
  email: string;
  emailField?: string;
  emails?: SimpleFieldType[] | [];
  phone?: string;
  phoneField?: string;
  phones?: SimpleFieldType[] | [];
  attributes?: {
    type: 'Contact' | 'Opportunity' | 'Lead' | 'Account' | 'Unknown';
  };
  disabled?: boolean;
  doNotEmail?: boolean;
  doNotCall?: boolean;
  sfdcId?: string;
  autoCreated?: boolean;
};

export type ActionType =
  | 'TEMPLATE'
  | 'CALL'
  | 'OTHER'
  | 'GENERAL'
  | 'STEP_SUBTYPE_LINKEDIN_INMAIL'
  | 'STEP_SUBTYPE_LINKEDIN_CONNECT'
  | 'STEP_SUBTYPE_SMS'
  | 'STEP_SUBTYPE_SENDOSO'
  | 'STEP_SUBTYPE_DIRECT_MAIL'
  | 'STEP_SUBTYPE_IN_PERSON_VISIT'
  | 'WORKSPACE_ACCOUNT_TASK'
  | 'UPDATE_SALESFORCE';

export const NEW_ACTION_ID = 'NEW_ONE_OFF_ACTION';

export type UserPicklistValues = {
  [key: string]: {
    controllerValues: { [key: string]: number };
    values: PicklistItem[];
    defaultValue: string | null;
  };
};
export type PicklistItem = {
  label: string;
  value: string;
  validFor: string | null;
};
export type FieldType = 'picklist' | 'multipicklist';
export type FieldValue = string | number | (string | number)[] | null;
export type FormField = {
  name: string;
  label: string;
  value: FieldValue;
  type: FieldType;
  required: boolean;
  updateable: boolean;
  dependentPicklist: boolean | null;
  controllerName: string | null;
  picklist?: PicklistItem[];
  restrictedPicklist: boolean | null;
};

export type DynamicForm = {
  title: string | null;
  subtitle: string | null;
  fields: FormField[];
};

export type DynamicData = {
  loggingForm: DynamicForm;
  loggingFormFlag?: boolean;
  trackClicks?: boolean;
  trackOpens?: boolean;
  hasCalled?: boolean;
};

export type SimpleFieldType = {
  label: string;
  value: string;
  field: string;
};

export type WhoType = SearchPerson & {
  phones?: SimpleFieldType[];
  emails?: SimpleFieldType[];
};

export type crmObject = {
  id: string;
  text: string;
  type: string;
  autoCreated?: boolean;
  relatedCrmId?: string;
  email?: string;
  emails?: SimpleFieldType[] | [];
  phone?: string;
  phones?: SimpleFieldType[] | [];
  attributes?: {
    name?: string;
  };
};

export type LoggingTo = {
  what?: crmObject[];
  removedWhat?: { [key: string]: crmObject };
  who?: crmObject[];
  removedWho?: { [key: string]: crmObject };
};

export type ActionBase = {
  accountId: string | null;
  bccRecipients: RecipientType[] | null;
  body: string | null;
  ccRecipients: RecipientType[] | null;
  crmResults: {
    activity_result: string;
    activity_outcome: string;
  } | null;
  crmTaskType: string | null;
  description: string | null;
  dueAt: string | null;
  dynamicData: DynamicData;
  executeAt: string | null;
  fromEmail: string | null;
  fromPhoneNumber: string | null;
  loggingTo: LoggingTo;
  /** Mainly for Call Notes */
  notes: string | null;
  personId: number | null;
  priority: ActionPriorityTypes | null;
  /** TODO: REMOVE Call result */
  results: string | null;
  subject: string | null;
  summary: string | null;
  toRecipients: RecipientType[] | null;
  type: ActionType;
};

export type MutateAction = ActionBase & {
  assigneeId: number | null;
  variantId: number | null;
};

export type ExecuteActionOptions = {
  logAndTryAgain?: boolean;
  trackClicks?: boolean;
  trackOpens?: boolean;
  markAsComplete?: boolean;
  activityResultId?: string;
  activityOutcomeId?: string;
  activityId?: string;
  callSid?: string;
  smsChannelSid?: string;
  sendAt?: string;
  callAgain?: boolean;
};

export type ExecuteAction = MutateAction & ExecuteActionOptions;

export type Template = {
  id: number;
  folderId: number;
  name: string;
  subject: string;
  body?: string;
  sentCount: number;
  linkClickCount: number;
  isLinkTrackingOn?: boolean;
  openCount: number;
  replyCount: number;
  updatedAt: string;
};

export type FullAction = ActionBase & {
  /** The name of the account that the person is tied to */
  accountName: string | null;
  activityType?: string | null;
  assignee: SearchUser | null;
  creatorId: string | null;
  creator: SearchUser | null;
  crmInstanceUrl: string | null;
  crmObjectId: string | null;
  completedAt: string | null;
  toRecipients: RecipientType[] | null;
  ccRecipients: RecipientType[] | null;
  bccRecipients: RecipientType[] | null;
  flowAlias: string;
  userAlias: string;
  flow: {
    id: number | null;
    name: string | null;
    settings: {
      contact: {
        emailFieldName: string | null;
        phoneFieldName: string | null;
      } | null;
      lead: {
        emailFieldName: string | null;
        phoneFieldName: string | null;
      } | null;
    };
  };
  id: string | typeof NEW_ACTION_ID;
  personStepId: number | null;
  playNodeRunId: number | null;
  source?: SourceType;
  suggestedAction: {
    id: string;
    sentiment: { aspect: string; sentiment: number }[];
  } | null;
  template: Template | null;
  templateId?: number | null;
  trackClicks?: boolean;
  trackOpens?: boolean;
  twilioSid?: string;
  variants?: Variant[] | null;
  variantId?: number | null;
  usedAdvanceSS: boolean;
  who: WhoType | null;
  workspaceAccountTaskId: number | null;
  workspaceSObjectTaskId: number | null;
};

export type ApiActionCompose = {
  data: FullAction;
};

export type ExecuteActionParams = {
  action: ExecuteAction;
  id: string | number;
};

export type ApiActionExecution = {
  data: FullAction;
};

export const getActionCompose = (
  id: number | string,
): Promise<ApiActionCompose> =>
  getClient()
    .get(`action_compose/${id}`)
    .json()
    .then(
      // eslint-disable-next-line @typescript-eslint/ban-types
      value => camelizeKeys(value as object[]) as unknown as ApiActionCompose,
    );

export const createAction = (action: MutateAction): Promise<FullAction> =>
  getClient()
    .post(`action_compose`, {
      json: decamelizeKeys(action),
    })
    .json()
    .then(
      // eslint-disable-next-line @typescript-eslint/ban-types
      value => camelizeKeys(value as object[]) as unknown as FullAction,
    );

export const updateAction = (
  action: MutateAction,
  id: string | number,
): Promise<string> =>
  getClient()
    .put(`action_compose/${id}`, {
      json: decamelizeKeys(action),
    })
    .json();

export const deleteAction = (id: string | number): Promise<number[]> =>
  getClient().delete(`action_compose/${id}`).json();

export const executeAction = ({
  action,
  id,
}: ExecuteActionParams): Promise<ApiActionExecution> =>
  getClient()
    .post(`action_compose/${id}/execute`, {
      json: decamelizeKeys(action),
    })
    .json()
    .then(
      // eslint-disable-next-line @typescript-eslint/ban-types
      value => camelizeKeys(value as object[]) as unknown as ApiActionExecution,
    );
