import getClient from './getClient';

export type ActivityResults = {
  id: number;
  type: 'activity_result' | 'activity_outcome';
  name: string;
};

export type CrmTypes = {
  label: string;
  value: string;
};

export type ActivityOptions = {
  activity_results: ActivityResults[];
  crm_types: CrmTypes[];
};

export type ApiActivityOptions = {
  data: ActivityOptions;
};

export const getActivityOptions = (): Promise<ApiActivityOptions> =>
  getClient().get('activities/options').json();
