import { Map } from 'immutable';
import Person from 'Modules/Actions/records/Person';

const defaults = {
  id: null,
  type: '',
  source: '',
  body: '',
  subject: '',
  summary: '',
  personStepId: null,
  personFlowId: null,
  priority: null,
  flowId: null,
  stepId: null,
  sfdcType: null,
  phone: null,
  recipient: new Person(),
  currentValues: new Map(),
  templateName: '',
  sourceMetadata: new Map(),
  failedData: null,
  error: null,
};

export default defaults;
