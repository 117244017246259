import ky, { Options } from 'ky';

import session from './dialer/session';
import refresh from './oauth/refresh';

export const options = {
  credentials: 'include',
  prefixUrl: `${process.env.NX_GROOVE_ENGINE_ROOT_URL}/`,
  // Due to request timeout issues, matching it with Rack timeout.
  timeout: 25000,
  hooks: {
    beforeRequest: [
      request => {
        if (localStorage.getItem('csrfToken')) {
          request.headers.set(
            'X-CSRF-Token',
            localStorage.getItem('csrfToken')!,
          );
        }
        // Added specifically so that we can validate users in Outlook, for Dialer in Action
        if (window?.localStorage?.getItem('userIdentityToken')) {
          request.headers.set(
            'X-Microsoft-Exchange-Identity-Token',
            window.localStorage.getItem('userIdentityToken') || '',
          );
        }
      },
    ],
    afterResponse: [
      async (request, _options, response) => {
        if (response.status === 401 && localStorage.getItem('refreshToken')) {
          if (request.url.endsWith('/dialer/session')) {
            try {
              const json = await refresh();
              localStorage.setItem('accessToken', json.access_token);
              localStorage.setItem('refreshToken', json.refresh_token);
            } catch (e) {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              location.reload();
            }
          } else {
            const sessionData = await session();
            localStorage.setItem('csrfToken', sessionData.csrf_token);
          }
        }
      },
    ],
  },
} as Options;

const client = ky.create(options);

export default client;
