import { fromJS, Map } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/salesforceMeta';

export default function ui(state = fromJS({}), action = {}) {
  switch (action.type) {
    case actionTypes.SET_SALESFORCE_META: {
      return state.merge(fromJS(action.payload));
    }

    case actionTypes.UPDATE_SALESFORCE_META: {
      const { object, fields } = action.payload;
      return state.setIn(['sfdcMeta', object, 'fields'], Map(fields));
    }

    default:
      return state;
  }
}
