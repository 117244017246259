import { select, put, call, all, delay, take } from 'redux-saga/effects';
import { actions } from '@groove-labs/groove-ui';
import pluralize from 'pluralize';

import { addJob } from 'Modules/Shared/actions/batchJobs';
import { logError } from 'Modules/Shared/actions/errors';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import {
  getWorkspaceId,
  getWorkspaceName,
  getWorkspaceType,
  getWorkspaceObjectType,
} from 'Modules/Spaces/show/selectors';
import { actionTypes } from 'Modules/Spaces/import/actions';
import {
  WORKSPACE_TYPES,
  SPACES_IMPORT_DIALOG_IS_OPEN_UI_KEY_PATH,
} from 'Modules/Spaces/shared/constants';
import makeBatchJobFinishedListener from 'Utils/makeBatchJobFinishedListener';
import addSObjectsToWorkspace from './addSObjectsToWorkspace';

const { setProperty } = actions.ui;

function* getSObjectType() {
  const workspaceType = yield select(getWorkspaceType);
  if (workspaceType === WORKSPACE_TYPES.OPPORTUNITY) {
    return 'WorkspaceOpportunitySObject';
  }
  return 'WorkspaceAccountSObject';
}

function* handleAddSObjectsToWorkspace(sObjectIds) {
  const [workspaceId, workspaceName] = yield all([
    select(getWorkspaceId),
    select(getWorkspaceName),
  ]);

  const sObjectType = yield call(getSObjectType);

  try {
    const batchId = yield call(addSObjectsToWorkspace, {
      workspaceId,
      sObjectIds,
      sObjectType,
    });
    yield all([
      put({
        type: actionTypes.BATCH_CREATE.PROGRESS,
        payload: {
          batchId,
        },
      }),
      put(addJob({ name: 'workspaceAddSObjects', id: batchId })),
    ]);

    const batchJob = yield take(makeBatchJobFinishedListener(batchId));
    const {
      payload: {
        batchStatus: { failures, total },
      },
    } = batchJob;

    let message;
    const workspaceObjectType = yield select(state =>
      getWorkspaceObjectType({ plural: false })(state)
    );
    if (failures > 0) {
      message = `Failed to add ${failures} ${pluralize(
        workspaceObjectType,
        failures
      )}!`;
      if (failures < total) {
        message = `${message} Other ${total - failures} was added`;
      }
    } else {
      message = `Added ${sObjectIds.count()} ${pluralize(
        workspaceObjectType,
        total
      )} to the ${workspaceName} Space`;
    }

    // Give the user a little time to read the snackbar (also gives us a little time to re-fetch/normalize
    // the data for this workspace that happens when we dispatch fetchCurrentWorkspace() above)
    yield delay(800);

    yield all([
      put(pushSnackbarMessage({ message })),
      put(
        setProperty({
          uiKeyPath: SPACES_IMPORT_DIALOG_IS_OPEN_UI_KEY_PATH,
          data: false,
        })
      ),
    ]);
  } catch (e) {
    yield all([
      put(logError({ error: e, isUiError: true })),
      put(
        setProperty({
          uiKeyPath: SPACES_IMPORT_DIALOG_IS_OPEN_UI_KEY_PATH,
          data: false,
        })
      ),
      put(
        pushSnackbarMessage({
          message: 'Sorry, something went wrong. Please try again.',
        })
      ),
    ]);
  }
}

export default handleAddSObjectsToWorkspace;
