import theme from '@groove/ui/theme';
import {
  ActionButton,
  FontIcon,
  Icon,
  IPersonaProps,
  MessageBar,
  MessageBarType,
  NormalPeoplePicker,
  Separator,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { grooveContactLeadSearch } from '@groove/api/visualforce/grooveSearch';
import RecordMenuItem from '@groove/ui/Components/RecordMenuItem';
import { FC, Fragment } from 'react';
import { createLocalStorageStateHook } from 'use-local-storage-state';
import { isContact, isLead } from '@groove/api/helpers/sfdcObjectPrefixes';
import * as Sentry from '@sentry/browser';

import transformGrooveEngineResult from '../transformGrooveEngineResults';
import transformSearchResult, {
  DisplayableSearchResult,
} from '../transformSearchResult';

import useStore, { SelectedItem } from './useStore';
import SmallCheckbox from './SmallCheckbox';
import { TooltipStyles } from './TooltipStyles';

const actionBtnStyles = {
  root: 'p-0 text-sm font-semibold text-clari-blue/600 text-clari-blue/700 h-[24px]',
  label: 'p-0 m-0',
};

const cancelBtnStyles = { root: 'h-[25px]' };
const pickerStyles = {
  root: 'm-2',
  text: 'border-neutral/200 hover:border-neutral/900 rounded after:border-clari-blue/600 after:rounded',
};

type NameProps = {
  onOpenFullSearchAndSelect?: (searchQuery: string) => void;
  onSelectedItemChange?: (isSelected: boolean, item: SelectedItem) => void;
  handleOmnibarContextChange?: (id: string) => void;
  onSearch?: () => void;
};

export const useShowContactLimitWarning = createLocalStorageStateHook(
  'showContactLimitWarning',
  true,
);

const Name: FC<NameProps> = ({
  onOpenFullSearchAndSelect,
  onSelectedItemChange,
  handleOmnibarContextChange,
  onSearch,
}) => {
  const searchQuery = useStore(store => store.nameSearchQuery);
  const setSearchQuery = useStore(store => store.setNameSearchQuery);

  const handleResolveSuggestions = async (
    filter: string,
  ): Promise<IPersonaProps[]> => {
    if (filter.length < 3) {
      return [];
    }
    Sentry.setTag('component', 'search-and-select-mini');
    const data = await grooveContactLeadSearch(filter);

    const items = transformGrooveEngineResult(data)
      ?.flat?.()
      .map(transformSearchResult) as IPersonaProps[];

    return [{ key: 'ADVANCED_SEARCH' }, ...items] as IPersonaProps[];
  };

  const selectedNames = useStore(store => store.selectedNames);
  const setSelectedNames = useStore(store => store.setSelectedNames);
  const setSelectedRelatedTos = useStore(store => store.setSelectedRelatedTos);

  const recentlySearchedNames = useStore(store => store.recentlySearchedNames);
  const setRecentlySearchedNames = useStore(
    store => store.setRecentlySearchedNames,
  );
  const recentlySearchedContacts = recentlySearchedNames.filter(name =>
    isContact(name.id),
  );
  const recentlySearchedLeads = recentlySearchedNames.filter(
    name => !isContact(name.id),
  );

  const suggestedNames = useStore(store => store.suggestedNames);
  const suggestedContacts = suggestedNames?.filter(item => isContact(item.id));
  const suggestedLeads = suggestedNames?.filter(item => isLead(item.id));

  const [showContactLimitWarning, setShowContactLimitWarning] =
    useShowContactLimitWarning();

  const isAllSuggestedContactsSelected = suggestedContacts.every(
    suggestedContact =>
      !!selectedNames.find(
        selectedName => selectedName.id === suggestedContact.id,
      ),
  );

  return (
    <div className="flex flex-col flex-grow">
      <NormalPeoplePicker
        inputProps={{
          placeholder: 'Type at least 3 characters to search…',
          'aria-label': 'Search Name',
          type: 'search',
          value: searchQuery,
          className: 'rounded',
        }}
        onInputChange={input => {
          setSearchQuery(input);
          if (onSearch) {
            onSearch();
          }
          return input;
        }}
        pickerSuggestionsProps={{
          suggestionsContainerAriaLabel: 'Search Results',
          suggestionsItemClassName: 'after:border-none',
        }}
        styles={pickerStyles}
        onResolveSuggestions={handleResolveSuggestions}
        resolveDelay={300}
        onRenderItem={props => <Fragment key={props.item.id} />}
        selectedItems={selectedNames}
        onItemSelected={item => {
          if (item?.key === 'ADVANCED_SEARCH') {
            onOpenFullSearchAndSelect?.(searchQuery);
            return null;
          }
          if (item) {
            if (isContact(item.id as string)) {
              setSelectedNames([
                ...selectedNames.filter(name => name.type === 'Contact'),
                item as SelectedItem,
              ]);
            } else {
              setSelectedNames([item as SelectedItem]);
              setSelectedRelatedTos([]);
            }
          }
          setRecentlySearchedNames([
            ...(recentlySearchedNames || []),
            item as SelectedItem,
          ]);

          return item || null;
        }}
        onRenderSuggestionsItem={props =>
          props.key === 'ADVANCED_SEARCH' ? (
            <div className="flex items-center w-full h-8 px-3">
              <Icon iconName="Search" className="mr-4" />
              <Text
                className="font-semibold whitespace-pre-wrapA"
                block
                variant="medium"
              >
                Open &quot;{searchQuery}&quot; in advanced search
              </Text>
            </div>
          ) : (
            <RecordMenuItem
              id={props.id as string}
              key={props.id}
              text={props.text}
              secondaryText={(props as DisplayableSearchResult).secondaryText}
              secondaryTextLabel={
                (props as DisplayableSearchResult).secondaryTextLabel
              }
              tertiaryText={(props as DisplayableSearchResult).tertiaryText}
              tertiaryTextLabel={
                (props as DisplayableSearchResult).tertiaryTextLabel
              }
              quarteraryText={(props as DisplayableSearchResult).quarteraryText}
              quarteraryTextLabel={
                (props as DisplayableSearchResult).quarteraryTextLabel
              }
              quinaryText={(props as DisplayableSearchResult).quinaryText}
              quinaryTextLabel={
                (props as DisplayableSearchResult).quinaryTextLabel
              }
              handleOmnibarContextChange={handleOmnibarContextChange}
            />
          )
        }
      />
      {showContactLimitWarning && selectedNames.length > 0 && (
        <MessageBar
          messageBarType={MessageBarType.info}
          className="bg-primary-lighter"
          isMultiline={false}
          actions={
            <ActionButton
              styles={cancelBtnStyles}
              ariaLabel="Close Contact Limit Warning"
              onClick={() => setShowContactLimitWarning(false)}
            >
              <Icon iconName="Cancel" />
            </ActionButton>
          }
        >
          Salesforce activities allow 0-50 contacts, or 1 lead in the Name field
        </MessageBar>
      )}
      <div className="flex-grow h-1 overflow-y-auto">
        {recentlySearchedNames.length > 0 && (
          <div className="p-2">
            <Text block className="text-sm text-base font-semibold">
              Recently Searched
            </Text>

            {recentlySearchedContacts.length > 0 && (
              <>
                <Separator className="h-1 py-0 before:bg-gray4" />
                <TooltipHost
                  content="Salesforce allows you to add 0-50 contacts, or 1 lead per activity"
                  styles={TooltipStyles}
                >
                  <Text block className="text-xs text-[11px]">
                    Contacts
                  </Text>
                  <FontIcon
                    aria-label="InfoSolid"
                    iconName="InfoSolid"
                    className="text-neutral/900"
                  />
                </TooltipHost>
              </>
            )}
            {recentlySearchedContacts.map(item => (
              <div key={item.id} className="flex items-center pl-1">
                <SmallCheckbox
                  ariaLabel={`Recently Searched ${item.text}`}
                  checked={
                    !!selectedNames.find(
                      selectedName => selectedName.id === item.id,
                    )
                  }
                  onChange={(_, checked) => {
                    if (checked) {
                      setSelectedNames([
                        ...selectedNames.filter(
                          name => name.type === 'Contact',
                        ),
                        item as SelectedItem,
                      ]);
                    } else {
                      setSelectedNames(
                        selectedNames.filter(
                          selectedName => selectedName.id !== item.id,
                        ),
                      );
                    }
                    onSelectedItemChange?.(!!checked, item);
                  }}
                />
                <RecordMenuItem
                  fullWidth
                  showLinkToSalesforce
                  id={item.id as string}
                  text={item.text || ''}
                  secondaryText={item.secondaryText}
                  secondaryTextLabel={item.secondaryTextLabel}
                  tertiaryText={item.tertiaryText}
                  tertiaryTextLabel={item.tertiaryTextLabel}
                  quarteraryText={item.quarteraryText}
                  quarteraryTextLabel={item.quarteraryTextLabel}
                  quinaryText={item.quinaryText}
                  quinaryTextLabel={item.quinaryTextLabel}
                  handleOmnibarContextChange={handleOmnibarContextChange}
                />
              </div>
            ))}

            {recentlySearchedLeads.length > 0 && (
              <>
                <Separator className="h-1 py-0 before:bg-gray4" />
                <Text block className="text-xs text-[11px] mt-2">
                  Leads
                </Text>
              </>
            )}
            {recentlySearchedLeads.map(item => (
              <div key={item.id} className="flex items-center pl-1">
                <input
                  aria-label={`Recently Searched ${item.text}`}
                  type="radio"
                  className="checked:bg-gray-200"
                  style={{ accentColor: theme.secondary }}
                  checked={
                    !!selectedNames.find(
                      selectedName => selectedName.id === item.id,
                    )
                  }
                  onChange={e => {
                    setSelectedNames([item]);
                    setSelectedRelatedTos([]);
                  }}
                />
                <RecordMenuItem
                  fullWidth
                  showLinkToSalesforce
                  id={item.id as string}
                  text={item.text || ''}
                  secondaryText={item.secondaryText}
                  secondaryTextLabel={item.secondaryTextLabel}
                  tertiaryText={item.tertiaryText}
                  tertiaryTextLabel={item.tertiaryTextLabel}
                  quarteraryText={item.quarteraryText}
                  quarteraryTextLabel={item.quarteraryTextLabel}
                  quinaryText={item.quinaryText}
                  quinaryTextLabel={item.quinaryTextLabel}
                  handleOmnibarContextChange={handleOmnibarContextChange}
                />
              </div>
            ))}
          </div>
        )}
        {suggestedNames.length > 0 && (
          <div className="p-2">
            <Text block className="text-sm text-base font-semibold">
              Suggested
            </Text>

            <Separator className="h-1 py-0 before:bg-gray4" />
            {suggestedContacts.length > 0 && (
              <TooltipHost
                content="Salesforce allows you to add 0-50 contacts, or 1 lead per activity"
                styles={TooltipStyles}
              >
                <Text block className="text-xs text-[11px]">
                  Contacts
                </Text>
                <FontIcon
                  aria-label="InfoSolid"
                  iconName="InfoSolid"
                  className="text-neutral/900"
                />
              </TooltipHost>
            )}
            {suggestedContacts.length > 1 && (
              <ActionButton
                onClick={() => {
                  if (isAllSuggestedContactsSelected) {
                    setSelectedNames([]);
                  } else {
                    setSelectedNames([...suggestedContacts]);
                  }
                }}
                styles={actionBtnStyles}
              >
                {isAllSuggestedContactsSelected ? 'Unselect all' : 'Select all'}
              </ActionButton>
            )}
            {suggestedContacts.map(item => (
              <div key={item.id} className="flex items-center pl-1">
                <SmallCheckbox
                  checked={
                    !!selectedNames.find(
                      selectedName => selectedName.id === item.id,
                    )
                  }
                  ariaLabel={`Suggested ${item.text}`}
                  onChange={(_, checked) => {
                    if (checked) {
                      setSelectedNames([
                        ...selectedNames.filter(
                          name => name.type === 'Contact',
                        ),
                        item as SelectedItem,
                      ]);
                    } else {
                      setSelectedNames(
                        selectedNames.filter(
                          selectedName => selectedName.id !== item.id,
                        ),
                      );
                    }
                    onSelectedItemChange?.(!!checked, item);
                  }}
                />
                <RecordMenuItem
                  fullWidth
                  showLinkToSalesforce
                  id={item.id as string}
                  text={item.text || ''}
                  secondaryText={item.secondaryText}
                  secondaryTextLabel={item.secondaryTextLabel}
                  tertiaryText={item.tertiaryText}
                  tertiaryTextLabel={item.tertiaryTextLabel}
                  quarteraryText={item.quarteraryText}
                  quarteraryTextLabel={item.quarteraryTextLabel}
                  quinaryText={item.quinaryText}
                  quinaryTextLabel={item.quinaryTextLabel}
                  handleOmnibarContextChange={handleOmnibarContextChange}
                />
              </div>
            ))}

            {suggestedLeads.length > 0 && (
              <>
                <Separator className="h-1 py-0 before:bg-gray4" />
                <Text block className="text-sm font-bold mt-2">
                  Leads
                </Text>
              </>
            )}
            {suggestedLeads.map(item => (
              <div key={item.id} className="flex items-center pl-1">
                <input
                  aria-label={`Suggested ${item.text}`}
                  type="radio"
                  className="checked:bg-gray-200"
                  style={{ accentColor: theme.secondary }}
                  checked={
                    !!selectedNames.find(
                      selectedName => selectedName.id === item.id,
                    )
                  }
                  onChange={e => {
                    setSelectedNames([item]);
                    setSelectedRelatedTos([]);
                    onSelectedItemChange?.(true, item);
                  }}
                />
                <RecordMenuItem
                  fullWidth
                  showLinkToSalesforce
                  id={item.id as string}
                  text={item.text || ''}
                  secondaryText={item.secondaryText}
                  secondaryTextLabel={item.secondaryTextLabel}
                  tertiaryText={item.tertiaryText}
                  tertiaryTextLabel={item.tertiaryTextLabel}
                  quarteraryText={item.quarteraryText}
                  quarteraryTextLabel={item.quarteraryTextLabel}
                  quinaryText={item.quinaryText}
                  quinaryTextLabel={item.quinaryTextLabel}
                  handleOmnibarContextChange={handleOmnibarContextChange}
                />
              </div>
            ))}
          </div>
        )}
        <div className="h-[50px]" />
      </div>
    </div>
  );
};

export default Name;
