export const CREATE_BUNDLE_GROUP_ID = 'createRegulatoryBundle';
export const CREATE_BUNDLE_COUNTRY_FIELD_ID = 'country';
export const CREATE_BUNDLE_PHONE_TYPE_FIELD_ID = 'phoneType';
export const SELECTED_ACCEPTED_DOC_KEY_PATH = [
  'manageMyOrg',
  'internationalProvisioning',
  'selectedAcceptedDocument',
];
export const UPLOAD_DOCUMENT_INPUTS_KEY_PATH = [
  'manageMyOrg',
  'internationalProvisioning',
  'input',
];
export const ADDRESS_FORM_GROUP_ID = 'internationalProvisioningAddress';
export const CREATE_BUNDLE_ERROR_DIALOG_KEYPATH = [
  'manageMyOrg',
  'internationalProvisioning',
  'createBundleErrorOpen',
];
export const CONFIRM_ASSIGN_DIALOG_UI_KEYPATH = [
  'manageMyOrg',
  'internationalProvisioning',
  'confirmAssignDialogOpen',
];
export const ASSIGN_NUMBER_USER_DETAILS = [
  'manageMyOrg',
  'internationalProvisioning',
  'confirmAssignUserDetails',
];

export const TWILIO_INPUT_DATA_TYPES = {
  String: 'text',
  Date: 'date',
  List: 'address',
};

export const LOCAL_DIAL_TABLE_ID = 'localDial';

// our npm package includes alpha2 country codes that are not supported
// so we list them here for purposes of filtering it out for context see
// https://groovelabs.atlassian.net/browse/DIAL-421
export const UNSUPPORTED_ALPHA2_CODES = [
  'AC',
  'AN',
  'BU',
  'CP',
  'CS',
  'CS',
  'CT',
  'DD',
  'DG',
  'DY',
  'EA',
  'EU',
  'FQ',
  'FX',
  'HV',
  'IC',
  'JT',
  'MI',
  'NH',
  'NQ',
  'NT',
  'PC',
  'PU',
  'PZ',
  'RH',
  'SU',
  'TA',
  'TP',
  'UK',
  'VD',
  'WK',
  'XK',
  'YD',
  'YU',
  'ZR',
];
