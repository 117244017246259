import { createActions, standardActions } from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SHARED: {
    ACTIVITY_SETTINGS: {
      FETCH: standardActions(),
    },
  },
}).SHARED.ACTIVITY_SETTINGS;

export const fetchBegin = () => ({
  type: actionTypes.FETCH.BEGIN,
});

export const fetchSuccess = activitySettings => ({
  type: actionTypes.FETCH.SUCCESS,
  payload: activitySettings,
});
