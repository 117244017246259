import { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import Text from '@groove/ui/Components/Text';
import PicklistNew, {
  StandardDropDownItem,
  DropDownItem,
} from '@groove/ui/Components/PicklistNew';
import { useGetActivityOptions } from '@groove/api/hooks/useActivities';
import Input from '@groove/ui/Components/Input';
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';
import {
  useGetCurrentUser,
  useGetOrgSettings,
} from '@groove/api/hooks/useUsers';

import { callResultSerializer } from '../utils/callResultSerializer';
import PhoneRecipient from '../component/PhoneRecipient';
import useStore from '../store/useStore';
import WEditor from '../component/WEditor';
import DialerBar from '../component/dialer/DialerBar';

const CallPane: FC = () => {
  const [resultItems, setResultItems] = useState<DropDownItem[]>([]);
  const [selectedResultItems, setSelectedResultItems] = useState<
    StandardDropDownItem[]
  >([]);

  const body = useStore(store => store.action.body);
  const id = useStore(store => store.action.id);
  const results = useStore(store => store.action.results);
  const notes = useStore(store => store.action.notes);
  const isTaskPaneOpenRaw = useStore(store => store.otherValues.isTaskPaneOpen);
  const taskPaneInitialClose = useStore(
    store => store.otherValues.taskPaneInitialClose,
  );
  const dialerInActionEnabled = useVariation('dialer-in-action');
  const { data: userData } = useGetCurrentUser();
  const isTaskPaneOpen = isTaskPaneOpenRaw && !taskPaneInitialClose;
  const { data: orgSettings, isLoading } = useGetOrgSettings();

  const { data } = useGetActivityOptions();

  useEffect(() => {
    const rawResult = data ? data.data.activity_results : [];
    const resultItems = rawResult.map(result => ({
      key: `${result.id}`,
      value: `${result.type}:${result.id}`,
      text: result.name,
    }));

    const sortedResultItems = callResultSerializer(resultItems);

    setResultItems(sortedResultItems);

    if (results) {
      const selectedId = results.split(':')[1];
      setSelectedResultItems([]);
      const selectedResultItem = resultItems.find(
        item => item.key === selectedId,
      );

      if (selectedResultItem) {
        setSelectedResultItems([selectedResultItem]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data.activity_results.length, id]);

  const onResultChange = (item: StandardDropDownItem | null): void => {
    useStore.getState().updateAction({
      results: (item?.value as string) || '',
    });
    if (item) setSelectedResultItems([item]);
  };

  return (
    <div className="flex flex-col flex-1 min-w-0">
      <div
        data-testid="call-pane"
        className="flex-1 pr-[12px] py-[12px] pl-[20px] flex flex-col min-w-0"
      >
        <PhoneRecipient />
        <div
          className={cx(
            'flex flex-1',
            isTaskPaneOpen ? 'flex-col' : 'flex-row',
          )}
        >
          <div
            className={cx(
              'flex flex-1 flex-col',
              !isTaskPaneOpen && 'pr-[4px]',
            )}
          >
            <Text
              variant="body-sm"
              className="mb-[4px] mt-[16px] font-semibold"
            >
              Call Script
            </Text>
            {isLoading ? (
              <Input multiple className="w-full flex-1" />
            ) : (
              <WEditor
                onChange={body => useStore.getState().updateAction({ body })}
                initialValue={body || ''}
                id={id}
              />
            )}
          </div>
          <div
            className={cx(
              'flex-1 flex flex-col',
              !isTaskPaneOpen && 'pl-[4px]',
            )}
          >
            <div
              className={cx(isTaskPaneOpen ? 'w-[242px]' : 'w-full mt-[4px]')}
            >
              <Text
                variant="body-sm"
                className="mb-[4px] mt-[12px] font-semibold"
              >
                Call result
                {orgSettings?.activity_result_is_mandatory_for_calls && (
                  <span className="text-error/600"> *</span>
                )}
              </Text>
              <PicklistNew
                className="w-full mb-[4px]"
                selectedValues={selectedResultItems}
                items={resultItems}
                onChange={onResultChange}
                placeholder="Select one"
              />
            </div>
            <Text
              variant="body-sm"
              className="mb-[4px] mt-[12px] font-semibold"
            >
              Call notes
            </Text>
            <Input
              multiple
              className="w-full flex-1"
              value={notes || ''}
              onChange={e =>
                useStore
                  .getState()
                  .updateAction({ notes: e.target.value || '' })
              }
            />
          </div>
        </div>
      </div>
      {dialerInActionEnabled &&
        userData?.dialer_enabled &&
        !orgSettings?.third_party_dialer_enabled && <DialerBar />}
    </div>
  );
};

export default CallPane;
