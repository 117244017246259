import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const tableActionTypes = createActions({
  SPACES_SHOW: {
    UPDATE_COLUMN_ORDER: standardActions(),
    REMOVE_ACCOUNTS: standardActions(),
    UPDATE_SALESFORCE_VALUE: standardActions(),
    REFRESH_DATA: standardActions(),
    ...keyMirror([
      'MERGE_STANDARD_ROW_DATA',
      'SET_NEXT_SORTED_ORDER',
      'SET_CHECKBOX',
      'SET_CHECKBOX_SELECTED',
      'STAR_CLICK',
      'SET_SELECTED_COLUMNS',
      'UPDATE_COLUMN_VISIBILITY',
      'SET_WORKSPACE_DATA',
      'ADD_ACCOUNTS_ROW_DATA',
      'SET_STARRED_ACCOUNT',
      'EDIT_WORKSPACE',
      'SET_WORKSPACE_VALUE',
      'CLEAR_SELECTED_ROWS',
      'FETCH_WORKSPACES',
      'SET_WORKSPACES',
      'MOVE_SOBJECTS_TO_WORKSPACE',
      'SET_SHARED_USERS',
      'FETCH_CURRENT_WORKSPACE',
      'SET_SALESFORCE_COLUMN_NAMES',
      'SET_ACTIVE_ACCOUNT_ID',
      'SET_ACTIVE_TAB',
      'RESET_REDUCER',
      'SET_COLUMNS',
      'SET_ALL_S_OBJECTS_LOADED',
      'UPDATE_CELL_VALUE',
    ]),
  },
}).SPACES_SHOW;

export const taskActionTypes = createActions({
  SPACES_SHOW: {
    FETCH_ACCOUNT_TASKS: standardActions(),
    TASK_EXECUTION: standardActions(),
    TASK_FORM_EXECUTION: standardActions(),
    ...keyMirror([
      'SET_FLOW_ACTIONS',
      'SET_TASK_LIST',
      'DISPLAY_TASK',
      'SET_TASK_COMPLETED_AT',
      'CREATE_OR_UPDATE_TASK',
      'OPEN_TASK_DIALOG',
      'SET_WORKSPACE_ACCOUNT_ID',
      'SET_ACCOUNT_TASK_DETAILS',
      'CLOSE_TASK_DIALOG',
      'DELETE_TASK',
      'REDIRECT_TO_FLOW',
      'EXECUTE_FLOW_ACTION_REDIRECT',
    ]),
  },
}).SPACES_SHOW;

// --------------SAGA ACTIONS------------------
export function setColumns({ standardColumns }) {
  return {
    type: tableActionTypes.SET_COLUMNS,
    payload: { standardColumns },
  };
}

export function mergeStandardRowData(accountData) {
  return {
    type: tableActionTypes.MERGE_STANDARD_ROW_DATA,
    payload: accountData,
  };
}

export function setSelectedAccounts({ selectedAccounts }) {
  return {
    type: tableActionTypes.SET_CHECKBOX_SELECTED,
    payload: { selectedAccounts },
  };
}

export function setSelectedColumns({ selectedColumns }) {
  return {
    type: tableActionTypes.SET_SELECTED_COLUMNS,
    payload: { selectedColumns },
  };
}

export function setWorkspaceData({ workspaceData }) {
  return {
    type: tableActionTypes.SET_WORKSPACE_DATA,
    payload: { workspaceData },
  };
}

export function addTableRows({ rows }) {
  return {
    type: tableActionTypes.ADD_ACCOUNTS_ROW_DATA,
    payload: { rows },
  };
}

export function setStarredAccount({ rowId, isStarred }) {
  return {
    type: tableActionTypes.SET_STARRED_ACCOUNT,
    payload: { rowId, isStarred },
  };
}

export function setWorkspaceValues({ workspaceName, workspaceDescription }) {
  return {
    type: tableActionTypes.SET_WORKSPACE_VALUE,
    payload: { workspaceName, workspaceDescription },
  };
}

export function clearSelectedRows() {
  return {
    type: tableActionTypes.CLEAR_SELECTED_ROWS,
  };
}

export function setWorkspaces({ workspaces }) {
  return {
    type: tableActionTypes.SET_WORKSPACES,
    payload: { workspaces },
  };
}

export function setSharedUsers({ sharedUsers }) {
  return {
    type: tableActionTypes.SET_SHARED_USERS,
    payload: { sharedUsers },
  };
}

export function setSalesforceColumnNames({ columnNames }) {
  return {
    type: tableActionTypes.SET_SALESFORCE_COLUMN_NAMES,
    payload: {
      columnNames,
    },
  };
}

export function setAllSObjectsLoaded() {
  return {
    type: tableActionTypes.SET_ALL_S_OBJECTS_LOADED,
  };
}

export function beginRefreshingData({ accountId, columnId }) {
  return {
    type: tableActionTypes.REFRESH_DATA.BEGIN,
    payload: {
      accountId,
      columnId,
    },
  };
}

export function failedRefreshingData({ accountId, columnId }) {
  return {
    type: tableActionTypes.REFRESH_DATA.FAILURE,
    payload: {
      accountId,
      columnId,
    },
  };
}

export function successfullyRefreshedData({ accountId, columnId, value }) {
  return {
    type: tableActionTypes.REFRESH_DATA.SUCCESS,
    payload: {
      accountId,
      columnId,
      value,
    },
  };
}

export function beginUpdatingSalesforceValue({
  accountId,
  columnId,
  value = undefined,
}) {
  return {
    type: tableActionTypes.UPDATE_SALESFORCE_VALUE.BEGIN,
    payload: {
      accountId,
      columnId,
      value,
    },
  };
}

export function failedUpdatingSalesforceValue({ accountId, columnId }) {
  return {
    type: tableActionTypes.UPDATE_SALESFORCE_VALUE.FAILURE,
    payload: {
      accountId,
      columnId,
    },
  };
}

// ----------------COMPONENT ACTIONS--------------
export function setNextOrderDirection({ orderBy, orderDirection, sortBy }) {
  return {
    type: tableActionTypes.SET_NEXT_SORTED_ORDER,
    payload: { orderBy, orderDirection, sortBy },
  };
}

export function starClick({ rowId, isStarred }) {
  return {
    type: tableActionTypes.STAR_CLICK,
    payload: { rowId, isStarred },
  };
}

export function setNextCheckboxState({ rowId }) {
  return {
    type: tableActionTypes.SET_CHECKBOX,
    payload: { rowId },
  };
}

export function updateColumnVisibility({ columnId, visibility, required }) {
  return {
    type: tableActionTypes.UPDATE_COLUMN_VISIBILITY,
    payload: { columnId, visibility, required },
  };
}

export function beginRemovingAccounts({ accountIds }) {
  return {
    type: tableActionTypes.REMOVE_ACCOUNTS.BEGIN,
    payload: { accountIds },
  };
}

export function successfullyRemovedAccounts() {
  return {
    type: tableActionTypes.REMOVE_ACCOUNTS.SUCCESS,
  };
}

export function failedRemovingAccounts() {
  return {
    type: tableActionTypes.REMOVE_ACCOUNTS.FAILURE,
  };
}

export function editWorkspace({ shouldNameChange, shouldDescChange }) {
  return {
    type: tableActionTypes.EDIT_WORKSPACE,
    payload: {
      shouldNameChange,
      shouldDescChange,
    },
  };
}

export function fetchCurrentWorkspace() {
  return {
    type: tableActionTypes.FETCH_CURRENT_WORKSPACE,
  };
}

export function fetchWorkspaces() {
  return {
    type: tableActionTypes.FETCH_WORKSPACES,
  };
}

export function moveSObjectsToWorkspace({ sObjectIds, workspaceId }) {
  return {
    type: tableActionTypes.MOVE_SOBJECTS_TO_WORKSPACE,
    payload: { sObjectIds, workspaceId },
  };
}

export function beginUpdatingColumnOrder() {
  return {
    type: tableActionTypes.UPDATE_COLUMN_ORDER.BEGIN,
  };
}

export function updateColumnOrderProgress({ oldIndex, newIndex }) {
  return {
    type: tableActionTypes.UPDATE_COLUMN_ORDER.PROGRESS,
    payload: { oldIndex, newIndex },
  };
}

export function resetReducer() {
  return {
    type: tableActionTypes.RESET_REDUCER,
  };
}

// ----------------TASK ACTIONS--------------
export function setFlowActions({ flowActions }) {
  return {
    type: taskActionTypes.SET_FLOW_ACTIONS,
    payload: { flowActions },
  };
}

export function setTaskList({ taskList }) {
  return {
    type: taskActionTypes.SET_TASK_LIST,
    payload: { taskList },
  };
}

export function deleteTask({ id }) {
  return {
    type: taskActionTypes.DELETE_TASK,
    payload: { id },
  };
}

export function taskExecutionProgress({ id }) {
  return {
    type: taskActionTypes.TASK_EXECUTION.PROGRESS,
    payload: { id },
  };
}

export function taskExecutionFailure({ id }) {
  return {
    type: taskActionTypes.TASK_EXECUTION.FAILURE,
    payload: { id },
  };
}

export function taskExecutionSuccess({ id }) {
  return {
    type: taskActionTypes.TASK_EXECUTION.SUCCESS,
    payload: { id },
  };
}

export function displayTask({ task }) {
  return {
    type: taskActionTypes.DISPLAY_TASK,
    payload: { task },
  };
}

export function setTaskCompletedAt({ completedAt, task }) {
  return {
    type: taskActionTypes.SET_TASK_COMPLETED_AT,
    payload: { completedAt, task },
  };
}

export function createOrUpdateTask() {
  return {
    type: taskActionTypes.CREATE_OR_UPDATE_TASK,
  };
}

export function openTaskDialog({ accountName, sObjectId }) {
  return {
    type: taskActionTypes.OPEN_TASK_DIALOG,
    payload: {
      accountName,
      sObjectId,
    },
  };
}

export function closeTaskDialog() {
  return {
    type: taskActionTypes.CLOSE_TASK_DIALOG,
  };
}

export function setAccountTaskDetails({ accountTaskDetails }) {
  return {
    type: taskActionTypes.SET_ACCOUNT_TASK_DETAILS,
    payload: { accountTaskDetails },
  };
}

export function fetchTasksProgress() {
  return {
    type: taskActionTypes.FETCH_ACCOUNT_TASKS.PROGRESS,
  };
}

export function fetchTasksSuccess() {
  return {
    type: taskActionTypes.FETCH_ACCOUNT_TASKS.SUCCESS,
  };
}

export function fetchTasksFailure() {
  return {
    type: taskActionTypes.FETCH_ACCOUNT_TASKS.FAILURE,
  };
}

export function taskFormExecutionProgress() {
  return {
    type: taskActionTypes.TASK_FORM_EXECUTION.PROGRESS,
  };
}

export function taskFormExecutionSuccess() {
  return {
    type: taskActionTypes.TASK_FORM_EXECUTION.SUCCESS,
  };
}

export function taskFormExecutionFailure() {
  return {
    type: taskActionTypes.TASK_FORM_EXECUTION.FAILURE,
  };
}

export function redirectToFlow({ id }) {
  return {
    type: taskActionTypes.REDIRECT_TO_FLOW,
    payload: { id },
  };
}

export function executeFlowActionRedirect({ flowAction }) {
  return {
    type: taskActionTypes.EXECUTE_FLOW_ACTION_REDIRECT,
    payload: { flowAction },
  };
}

export function setActiveAccountId({ id }) {
  return {
    type: tableActionTypes.SET_ACTIVE_ACCOUNT_ID,
    payload: { id },
  };
}

export function setActiveTab({ tab }) {
  return {
    type: tableActionTypes.SET_ACTIVE_TAB,
    payload: { tab },
  };
}
