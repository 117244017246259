export const FILTER_MENU_GROUP_ID = 'actionsFilterMenu';
export const FILTER_TYPE_FIELD_ID = 'type';
export const FILTER_DUE_FIELD_ID = 'dueAt';
export const FILTER_SOURCE_FIELD_ID = 'source';
export const FILTER_TIMEZONE_FIELD_ID = 'timezone';
export const FILTER_PRIORITY_FILED_ID = 'priority';
export const FILTER_CREATOR_FIELD_ID = 'creator';
export const FILTER_ASSIGNEE_FIELD_ID = 'assignee';

export const IS_FILTER_MENU_OPEN_UI_KEY_PATH = ['actions', 'isFilterMenuOpen'];

export const FIELD_ID_TO_CHIP_TITLE = Object.freeze({
  [FILTER_TYPE_FIELD_ID]: 'Type',
  [FILTER_DUE_FIELD_ID]: '',
  [FILTER_SOURCE_FIELD_ID]: 'From',
  [FILTER_TIMEZONE_FIELD_ID]: 'Timezone:',
  [FILTER_PRIORITY_FILED_ID]: 'Priority',
  [FILTER_CREATOR_FIELD_ID]: 'Created by:',
  [FILTER_ASSIGNEE_FIELD_ID]: 'Assigned to:',
});
