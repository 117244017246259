import { actions } from '@groove-labs/groove-ui';
import { addJob } from 'Modules/Shared/actions/batchJobs';
import * as stepsHTTPClient from 'GrooveHTTPClient/steps';
import * as templateOverrideHTTPClient from 'GrooveHTTPClient/templateOverrides/index';
import { List, fromJS } from 'immutable';
import { stepMapSubtypes } from 'Modules/FlowsShow/utils';
import { trackEvent } from 'Utils/segment';
import { ANALYTICS_BASE_PROPERTIES } from 'Modules/Shared/sagas/analytics';
import { FEATURE_CATEGORY } from 'Modules/FlowsShow/sagas/analytics';
import {
  ADD_PEOPLE_DIALOG_OPEN_KEY_PATH,
  BATCH_ADD_PEOPLE_LIMIT,
} from 'Modules/FlowsShow/constants/index.ts';
import { logSkeletonStepsToggled } from 'Modules/Shared/sagas/flows/masterFlowEnhancements.js';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import {
  createOrUpdateStepFailure,
  createOrUpdateStepSuccess,
  actionTypes as stepActionTypes,
  successfullyCreatedOrUpdatedStepTemplateOverride,
  successfullyDeletedStepTemplateOverride,
} from 'Modules/Shared/actions/steps';
import { makeGetStepAddEditData } from 'Modules/Shared/selectors/steps';
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { watchJob } from 'Modules/Shared/sagas/batchJobs';
import {
  STEP_ADD_EDIT_BATCH_ID_UI_KEYPATH,
  ADD_EDIT_GROUP_ID_FALLBACK_VALUE,
  CREATE_OR_UPDATE_STEP_UI_KEYPATH,
} from 'Modules/Shared/constants';
import Step from 'Modules/Shared/records/Step';
import StepVariant from 'Modules/Shared/data/StepVariant';
import HTTPError from 'GrooveHTTPClient/HTTPError';
import { stepAddEditMapPayload, templateMapPayload } from './utils';
import { getSteps } from 'Modules/FlowsShow/selectors';

const { setProperty } = actions.ui;

function* createOrUpdateStep({
  payload: {
    flowId,
    stepId,
    groupId,
    isOpenUiKeyPath,
    peopleFinishedCount,
    maxDay,
  },
}) {
  yield put({ type: stepActionTypes.CREATE_OR_UPDATE_STEP.PROGRESS });
  yield put(
    setProperty({
      uiKeyPath: ['creatingOrUpdatingStep'],
      data: true,
    })
  );

  // TODO: Fallback string is because of some hardcoded selectors in `Modules/Shared/containers/StepAddEdit`
  const stepAddEditData = makeGetStepAddEditData(
    groupId || ADD_EDIT_GROUP_ID_FALLBACK_VALUE
  );
  let reducerData = yield select(state =>
    stepAddEditData(state, {
      groupId: groupId || ADD_EDIT_GROUP_ID_FALLBACK_VALUE,
    })
  );

  if (reducerData.get('templateId') === null) {
    const steps = yield select(state => getSteps(state));

    const step = steps.filter(step => step.get('id') === stepId).first();
    const templateId = step.getIn(['template', 'id']);
    reducerData = reducerData.set('templateId', templateId);
  }

  const payload = stepAddEditMapPayload(flowId, reducerData.toJS());
  // if there are completed people, and this is the new last step, show add people dialog
  if (
    isOpenUiKeyPath &&
    peopleFinishedCount > 0 &&
    maxDay <= payload.step.day_number
  ) {
    // Hide StepAddEditDialog
    yield put(
      setProperty({
        uiKeyPath: isOpenUiKeyPath,
        data: false,
      })
    );
    // Show AddPeopleToNewStepDialog
    yield put(
      setProperty({
        uiKeyPath: ADD_PEOPLE_DIALOG_OPEN_KEY_PATH,
        data: true,
      })
    );

    // Wait for either a yes or a no
    const { addPeople } = yield race({
      addPeople: take(stepActionTypes.CREATE_OR_UPDATE_STEP.ADD_PEOPLE_YES),
      dontAddPeople: take(stepActionTypes.CREATE_OR_UPDATE_STEP.ADD_PEOPLE_NO),
    });

    if (addPeople) {
      payload.add_people = true;
    }

    // Hide AddPeopleToNewStepDialog
    yield put(
      setProperty({
        uiKeyPath: ADD_PEOPLE_DIALOG_OPEN_KEY_PATH,
        data: false,
      })
    );
    // Show StepAddEditDialog
    yield put(
      setProperty({
        uiKeyPath: isOpenUiKeyPath,
        data: true,
      })
    );
  }

  const isNewStep = !stepId;

  let response;

  try {
    if (
      isNewStep &&
      payload.add_people &&
      peopleFinishedCount >= BATCH_ADD_PEOPLE_LIMIT
    ) {
      response = yield call(
        stepsHTTPClient.createAndBatchAddPeople,
        flowId,
        payload
      );
    } else if (isNewStep) {
      response = yield call(stepsHTTPClient.create, flowId, payload);
    } else {
      response = yield call(stepsHTTPClient.update, stepId, payload);
    }
  } catch (e) {
    const actionString = isNewStep ? 'create' : 'update';
    if (e instanceof HTTPError) {
      const errorMessage = e.response
        .getIn(['message', 'data', 'errors'])
        .first();
      yield put(pushSnackbarMessage({ message: errorMessage }));
    } else {
      yield put(
        pushSnackbarMessage({ message: `Failed to ${actionString} step` })
      );
    }
    yield put(createOrUpdateStepFailure(e));
    yield put(
      setProperty({
        uiKeyPath: ['creatingOrUpdatingStep'],
        data: false,
      })
    );
    yield put(
      setProperty({
        uiKeyPath: CREATE_OR_UPDATE_STEP_UI_KEYPATH,
        data: true,
      })
    );
    yield put(
      setProperty({
        uiKeyPath: CREATE_OR_UPDATE_STEP_UI_KEYPATH,
        data: false,
      })
    );
    return null;
  }

  const batchId = response.data.batchId;
  const newStepId = response.data.stepId;

  if (batchId) {
    yield put(addJob({ name: 'createOrUpdateStep', id: batchId }));

    // put batch ID in reducer for StepAddEditDialog to read
    yield put(
      setProperty({
        uiKeyPath: STEP_ADD_EDIT_BATCH_ID_UI_KEYPATH,
        data: batchId,
      })
    );

    // Wait until this job is finished
    yield* watchJob(batchId);
  }

  yield put(
    setProperty({
      uiKeyPath: ['creatingOrUpdatingStep'],
      data: false,
    })
  );

  // This saga is currently used in 2 separate ways-- FlowsShow and FlowsWizard.
  // Only in the case of FlowsShow do we want to invoke the delay, so we can
  // check for the presence of isOpenUiKeyPath because that will not exist
  // in FlowsWizard. Additionally, we only want to invoke the delay for
  // non-batch steps because all batch jobs have a built in delay (see
  // the processJob saga in the batchJobs shared saga file)
  if (isOpenUiKeyPath && !batchId) {
    yield delay(1000);
  }

  // close any dialog that may be open
  if (isOpenUiKeyPath) {
    yield put(
      setProperty({
        uiKeyPath: isOpenUiKeyPath,
        data: false,
      })
    );
  }

  if (isNewStep) {
    yield put(pushSnackbarMessage({ message: 'New step created' }));
  } else {
    yield put(pushSnackbarMessage({ message: 'Step Updated' }));
  }

  if (!isNewStep) {
    response = yield call(stepsHTTPClient.show, { stepId });
  } else if (newStepId) {
    response = yield call(stepsHTTPClient.show, { stepId: newStepId });
  }

  // Dispatch success action and pass along the API response
  // API response contains data on the step
  const mappedStepResponse = fromJS(stepMapSubtypes(response.data));
  const variants = mappedStepResponse
    .get('variants', List())
    .map(variant => StepVariant.from(variant.toJS()));
  const step = new Step(fromJS({ ...mappedStepResponse.toJS(), variants }));
  yield put(createOrUpdateStepSuccess(step));

  return null;
}

function* deleteStepTemplateOverride({
  payload: { stepId, templateOverrideId, isOpenUiKeyPath },
}) {
  try {
    yield all([
      call(templateOverrideHTTPClient.destroy, templateOverrideId),
      put(successfullyDeletedStepTemplateOverride({ stepId })),
      put(
        setProperty({
          uiKeyPath: isOpenUiKeyPath,
          data: false,
        })
      ),
    ]);
    trackEvent('Skeleton Template Applied', {
      ...ANALYTICS_BASE_PROPERTIES,
      featureCategory: FEATURE_CATEGORY,
      actionType: 'disabled',
    });
  } catch (e) {
    yield all([
      put(
        setProperty({
          uiKeyPath: isOpenUiKeyPath,
          data: false,
        })
      ),
      put(
        pushSnackbarMessage({
          message:
            'Sorry, an error occurred when deleting this template override ',
        })
      ),
    ]);
  }
}

function* createOrUpdateTemplateOverride({
  payload: { flowId, stepId, groupId, isOpenUiKeyPath },
}) {
  const stepAddEditData = makeGetStepAddEditData(
    groupId || ADD_EDIT_GROUP_ID_FALLBACK_VALUE
  );
  const reducerData = yield select(state =>
    stepAddEditData(state, {
      groupId: groupId || ADD_EDIT_GROUP_ID_FALLBACK_VALUE,
    })
  );

  const templatePayload = templateMapPayload(reducerData.toJS());
  const templateId = reducerData.get('templateId');
  const steps = yield select(state => getSteps(state));
  const step = steps.filter(step => step.get('id') === stepId).first();
  const templateOverride = step?.get('templateOverride');

  try {
    if (templateOverride) {
      const templateOverrideId = templateOverride.get('id');
      const updatedTemplateOverrideNetworkResponse = yield call(
        templateOverrideHTTPClient.update,
        templateOverrideId,
        flowId,
        stepId,
        templateId,
        templatePayload
      );
      yield put(
        successfullyCreatedOrUpdatedStepTemplateOverride({
          stepId,
          template: updatedTemplateOverrideNetworkResponse.getIn([
            'message',
            'data',
          ]),
        })
      );

      trackEvent('Skeleton Template Updated', {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory: FEATURE_CATEGORY,
      });
    } else {
      const newTemplateOverrideNetworkResponse = yield call(
        templateOverrideHTTPClient.create,
        flowId,
        stepId,
        templateId,
        templatePayload
      );
      yield put(
        successfullyCreatedOrUpdatedStepTemplateOverride({
          stepId,
          template: newTemplateOverrideNetworkResponse.getIn([
            'message',
            'data',
          ]),
        })
      );
      trackEvent('Skeleton Template Applied', {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory: FEATURE_CATEGORY,
        actionType: 'enabled',
      });
    }

    yield all([
      put(
        setProperty({
          uiKeyPath: isOpenUiKeyPath,
          data: false,
        })
      ),
    ]);
  } catch (e) {
    yield all([
      put(
        setProperty({
          uiKeyPath: isOpenUiKeyPath,
          data: false,
        })
      ),
      put(
        pushSnackbarMessage({
          message:
            'Sorry, an error occurred when trying to override this steps template',
        })
      ),
    ]);
  }
}

// -------------- Watchers --------------
function* watchCreateOrUpdateStep() {
  yield takeEvery(
    stepActionTypes.CREATE_OR_UPDATE_STEP.BEGIN,
    createOrUpdateStep
  );
}

function* watchCreateStepTemplateOverride() {
  yield takeEvery(
    stepActionTypes.CREATE_TEMPLATE_OVERRIDE.BEGIN,
    createOrUpdateTemplateOverride
  );
}

function* watchDeletionStepTemplateOverride() {
  yield takeEvery(
    stepActionTypes.DELETE_TEMPLATE_OVERRIDE.BEGIN,
    deleteStepTemplateOverride
  );
}

function* watchSkeletonStepToggled() {
  yield takeEvery(
    stepActionTypes.SKELETON_STEP_TOGGLED,
    logSkeletonStepsToggled
  );
}

export default function* root() {
  yield all([
    fork(watchCreateOrUpdateStep),
    fork(watchCreateStepTemplateOverride),
    fork(watchDeletionStepTemplateOverride),
    fork(watchSkeletonStepToggled),
  ]);
}
