import cx from 'classnames';
import { Icon, Link, Text } from '@fluentui/react';
import { FC, ReactElement, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useUserGet from '@groove/api/hooks/useUserGet';
import { USER_GET_CACHE_TIME } from '@groove/api/utils/cacheSettings';

import Tooltip from './Tooltip';

const sfdcPrefixToIcon: { [key: string]: string } = {
  '003': 'Contact',
  '00Q': 'ContactCard',
  '001': 'EMI',
  '006': 'Money',
  '005': 'Contact',
  '500': 'Ticket',
};

const sfdcPrefixToLabel: { [key: string]: string } = {
  '003': 'Contact',
  '00Q': 'Lead',
  '001': 'Account',
  '006': 'Opportunity',
  '005': 'User',
  '500': 'Case',
};

type RecordMenuItemProps = {
  canCheck?: boolean;
  checked?: boolean;
  id: string;
  text?: string;
  secondaryText?: string | ReactElement | null;
  secondaryTextLabel?: string | ReactElement | null;
  tertiaryText?: string | ReactElement | null;
  tertiaryTextLabel?: string | ReactElement | null;
  quarteraryText?: string | ReactElement | null;
  quarteraryTextLabel?: string | ReactElement | null;
  quinaryText?: string | ReactElement | null;
  quinaryTextLabel?: string | ReactElement | null;
  senaryText?: string | ReactElement | null;
  senaryTextLabel?: string | ReactElement | null;
  septenaryText?: string | ReactElement | null;
  septenaryTextLabel?: string | ReactElement | null;
  fullWidth?: boolean;
  showLinkToSalesforce?: boolean;
  handleOmnibarContextChange?: (id: string) => void;
};
const RecordMenuItem: FC<RecordMenuItemProps> = ({
  canCheck,
  checked,
  id,
  text,
  secondaryText,
  secondaryTextLabel,
  tertiaryText,
  tertiaryTextLabel,
  quarteraryText,
  quarteraryTextLabel,
  quinaryText,
  quinaryTextLabel,
  senaryText,
  senaryTextLabel,
  septenaryText,
  septenaryTextLabel,
  fullWidth,
  showLinkToSalesforce,
  handleOmnibarContextChange,
}) => {
  const { data: user } = useUserGet({
    enabled: showLinkToSalesforce,
    cacheTime: USER_GET_CACHE_TIME,
    staleTime: USER_GET_CACHE_TIME,
  });
  const [debouncedContextChange] = useDebouncedCallback((id: string) => {
    if (handleOmnibarContextChange) {
      handleOmnibarContextChange(id);
    }
  }, 500);
  const [showSalesforceLink, setShowSalesforceLink] = useState(false);

  return (
    <div
      data-id={id}
      className={cx(
        'flex items-center text-left p-2 w-full min-h-[32px]',
        canCheck && checked && 'bg-primary-light',
        !fullWidth && 'max-w-[320px]',
      )}
    >
      <div className="ml-1 mr-3">
        <Tooltip
          content={sfdcPrefixToLabel[id.substring(0, 3)]}
          hide={!sfdcPrefixToLabel[id.substring(0, 3)]}
        >
          <div>
            <Icon
              iconName={
                sfdcPrefixToIcon[id.substring(0, 3)] || 'CRMCustomerInsightsApp'
              }
              className="text-base"
            />
          </div>
        </Tooltip>
      </div>
      <div>
        <div className="flex items-center">
          <Tooltip
            content={text}
            hide={showSalesforceLink}
            onTooltipToggle={visible => {
              if (visible && id && handleOmnibarContextChange) {
                setShowSalesforceLink(visible);
                debouncedContextChange(id);
              }
            }}
          >
            <div>
              {(!showLinkToSalesforce || !user) && (
                <Text
                  className="font-semibold whitespace-pre-wrap"
                  block
                  variant="medium"
                >
                  {text}
                </Text>
              )}
              {showLinkToSalesforce && user && (
                <Link
                  href={`${user.data.sfdc_instance_url}/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text
                    className="font-semibold whitespace-pre-wrap"
                    block
                    variant="medium"
                  >
                    {text}
                  </Text>
                </Link>
              )}
            </div>
          </Tooltip>
        </div>
        <Text
          block
          variant="smallPlus"
          className="whitespace-pre-wrap text-gray1"
        >
          <div className="flex items-center">
            <Tooltip
              content={secondaryTextLabel || undefined}
              hide={!secondaryTextLabel}
            >
              <div>{secondaryText}</div>
            </Tooltip>
            {secondaryText && tertiaryText && <> · </>}
            <Tooltip
              content={tertiaryTextLabel || undefined}
              hide={!tertiaryTextLabel}
            >
              <div>{tertiaryText}</div>
            </Tooltip>
            {tertiaryText && quarteraryText && <> · </>}
          </div>
          <div className="flex items-center">
            <Tooltip
              content={quarteraryTextLabel || undefined}
              hide={!quarteraryTextLabel}
            >
              <div>{quarteraryText}</div>
            </Tooltip>
            {quarteraryText && quinaryText && <> · </>}
            <Tooltip
              content={quinaryTextLabel || undefined}
              hide={!quinaryTextLabel}
            >
              <div>{quinaryText}</div>
            </Tooltip>
            {quinaryText && senaryText && <> · </>}
            <Tooltip
              content={senaryTextLabel || undefined}
              hide={!senaryTextLabel}
            >
              <div>{senaryText}</div>
            </Tooltip>
            {senaryText && septenaryText && <> · </>}
            <Tooltip
              content={septenaryTextLabel || undefined}
              hide={!septenaryTextLabel}
            >
              <div>{septenaryText}</div>
            </Tooltip>
          </div>
        </Text>
      </div>
    </div>
  );
};
export default RecordMenuItem;
