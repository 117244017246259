import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const KeyPadIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, 'fill-current')}
  >
    <path d="M6 5.25C6.69036 5.25 7.25 4.69036 7.25 4C7.25 3.30964 6.69036 2.75 6 2.75C5.30964 2.75 4.75 3.30964 4.75 4C4.75 4.69036 5.30964 5.25 6 5.25ZM6 9.25C6.69036 9.25 7.25 8.69036 7.25 8C7.25 7.30964 6.69036 6.75 6 6.75C5.30964 6.75 4.75 7.30964 4.75 8C4.75 8.69036 5.30964 9.25 6 9.25ZM7.25 12C7.25 12.6904 6.69036 13.25 6 13.25C5.30964 13.25 4.75 12.6904 4.75 12C4.75 11.3096 5.30964 10.75 6 10.75C6.69036 10.75 7.25 11.3096 7.25 12ZM10 5.25C10.6904 5.25 11.25 4.69036 11.25 4C11.25 3.30964 10.6904 2.75 10 2.75C9.30964 2.75 8.75 3.30964 8.75 4C8.75 4.69036 9.30964 5.25 10 5.25ZM11.25 8C11.25 8.69036 10.6904 9.25 10 9.25C9.30964 9.25 8.75 8.69036 8.75 8C8.75 7.30964 9.30964 6.75 10 6.75C10.6904 6.75 11.25 7.30964 11.25 8ZM10 13.25C10.6904 13.25 11.25 12.6904 11.25 12C11.25 11.3096 10.6904 10.75 10 10.75C9.30964 10.75 8.75 11.3096 8.75 12C8.75 12.6904 9.30964 13.25 10 13.25ZM11.25 16C11.25 16.6904 10.6904 17.25 10 17.25C9.30964 17.25 8.75 16.6904 8.75 16C8.75 15.3096 9.30964 14.75 10 14.75C10.6904 14.75 11.25 15.3096 11.25 16ZM14 5.25C14.6904 5.25 15.25 4.69036 15.25 4C15.25 3.30964 14.6904 2.75 14 2.75C13.3096 2.75 12.75 3.30964 12.75 4C12.75 4.69036 13.3096 5.25 14 5.25ZM15.25 8C15.25 8.69036 14.6904 9.25 14 9.25C13.3096 9.25 12.75 8.69036 12.75 8C12.75 7.30964 13.3096 6.75 14 6.75C14.6904 6.75 15.25 7.30964 15.25 8ZM14 13.25C14.6904 13.25 15.25 12.6904 15.25 12C15.25 11.3096 14.6904 10.75 14 10.75C13.3096 10.75 12.75 11.3096 12.75 12C12.75 12.6904 13.3096 13.25 14 13.25Z" />
  </svg>
);
