import React, { FC } from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import trackUserMenuEvent from 'Modules/App/utils/trackUserMenuEvent';
import { useSelector } from 'react-redux';
import { currentUser as getCurrentUser } from 'Modules/Shared/selectors/users';
import {
  CLARI_DXP_CONTACT_SUPPORT_URL,
  CLARI_DXP_KNOWLEDGE_BASE_URL,
  CLARI_UNIVERSITY_URL,
} from 'Modules/Settings/constants';
import { USER_MENU_ACTIONS } from '../../UserDisplayAndMenu/UserMenuOptions';

type HelpMenuOptionsProps = {
  closeHelpMenu: () => void;
};
export const HelpMenuOptions: FC<HelpMenuOptionsProps> = ({
  closeHelpMenu,
}) => {
  const currentUser = useSelector(getCurrentUser);

  const handleBestPracticesLink = () => {
    trackUserMenuEvent({
      currentUser,
      actionName: USER_MENU_ACTIONS.BEST_PRACTICES,
    });
    closeHelpMenu();
    const newTab = window.open(
      'https://university.clari.com/learn', // TODO UPDATE LINKS -- old link: 'https://help.groove.co/en/collections/2640477-groove-best-practices',
      '_blank'
    );
    newTab.focus();
  };

  const handleKnowledgeBaseLink = () => {
    trackUserMenuEvent({
      currentUser,
      actionName: USER_MENU_ACTIONS.KNOWLEDGE_BASE,
    });
    closeHelpMenu();
    const newTab = window.open(CLARI_DXP_KNOWLEDGE_BASE_URL, '_blank');
    newTab.focus();
  };

  const handleContactSupportLink = () => {
    trackUserMenuEvent({
      currentUser,
      actionName: USER_MENU_ACTIONS.CONTACT_SUPPORT,
    });
    closeHelpMenu();
    const newTab = window.open(CLARI_DXP_CONTACT_SUPPORT_URL, '_blank');
    newTab.focus();
  };

  const handleUniversityLink = () => {
    trackUserMenuEvent({
      currentUser,
      actionName: USER_MENU_ACTIONS.CLARI_UNIVERSITY,
    });
    closeHelpMenu();
    const newTab = window.open(CLARI_UNIVERSITY_URL, '_blank');
    newTab.focus();
  };

  const menuOptions = [
    {
      text: USER_MENU_ACTIONS.KNOWLEDGE_BASE,
      onClick: handleKnowledgeBaseLink,
    },
    {
      text: USER_MENU_ACTIONS.CLARI_UNIVERSITY,
      onClick: handleUniversityLink,
    },
    {
      text: USER_MENU_ACTIONS.BEST_PRACTICES,
      onClick: handleBestPracticesLink,
    },
    {
      text: USER_MENU_ACTIONS.CONTACT_SUPPORT,
      onClick: handleContactSupportLink,
    },
  ];

  return (
    <div>
      {menuOptions.map(({ text, onClick }) => (
        <MenuItem onClick={onClick} key={`help-option-${text}`}>
          <Typography variant="body1">{text}</Typography>
        </MenuItem>
      ))}
    </div>
  );
};
