import React, { FC, useEffect } from 'react';
import tinymce from 'tinymce';
import ky from 'ky';

type HighspotMessage = {
  html: string;
  link: string;
  pitch_id: string;
  text: string;
  action: string;
  updatePitchEndpoint: string;
  highspot: true;
};

interface HighspotContainerProps {
  onClose: () => void;
  recipientEmail?: string;
}

const HighspotContainer: FC<HighspotContainerProps> = ({
  onClose,
  recipientEmail,
}) => {
  useEffect(() => {
    const handleHighspotMessage = (event: { data: HighspotMessage }) => {
      const { data } = event;

      if (data.action === 'cancel') {
        onClose();
      }

      if (data.action === 'insertPitch') {
        tinymce.activeEditor.insertContent(data.html);
        if (recipientEmail) {
          ky.put(data.updatePitchEndpoint, { json: { email: recipientEmail } });
        }
        onClose();
      }
    };
    window.addEventListener('message', handleHighspotMessage);
    return () => window.removeEventListener('message', handleHighspotMessage);
  }, [onClose, recipientEmail]);

  return (
    <div>
      <iframe
        src={`https://app.highspot.com/external/v1/pitches/insert?kind=Groove&insertPitchModal=true&contextHost=${
          recipientEmail ? 'oneoffEmail' : 'template'
        }`}
        title="highspot"
        frameBorder="0"
        width={602}
        height={574}
      />
    </div>
  );
};

export default HighspotContainer;
