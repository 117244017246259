import { Configuration as GrooveWysiwygEditorConfiguration } from '@groove/wysiwyg-editor';

export default function initializeGrooveWysiwygEditorConfiguration(): void {
  const userIdentityToken =
    window.localStorage.getItem('userIdentityToken') || '';

  const grooveHttpClientConfiguration = {
    baseURL: process.env.SERVER_BASE_URL || 'https://localhost:3000',
    defaultHeaders: Object.freeze({
      'X-Microsoft-Exchange-Identity-Token': userIdentityToken,
    }),
  };

  GrooveWysiwygEditorConfiguration.configure(
    grooveHttpClientConfiguration.baseURL,
    {
      headers: grooveHttpClientConfiguration.defaultHeaders,
    },
  );
}
