import * as tailwindConfig from './tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';

const { theme } = tailwindConfig;

const fullConfig = resolveConfig({
  theme,
  content: [],
});

export const CLARI_COLORS = fullConfig.theme.colors;
export const CLARI_THEME = fullConfig.theme;
export default fullConfig;
