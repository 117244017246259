import { isNumber, isFinite, isString } from 'lodash-es';

const hasADigit = value => {
  if (isNumber(value) && isFinite(value)) {
    return true;
  }

  if (isString(value)) {
    return /\d/.test(value);
  }

  return false;
};

const WHITELISTED_URL_PARTS = ['v1', 'v2'];

const sanitizeURLPath = path => {
  return path
    .split('/')
    .map(part => {
      if (hasADigit(part) && WHITELISTED_URL_PARTS.indexOf(part) < 0) {
        return '[:id]';
      }
      return part;
    })
    .join('/');
};

const getSentryTags = error => ({
  type: 'Groove HTTP API Error',
  'http.error.path': error.path,
  'http.error.status.code': error.response.get('status'),
});

export { hasADigit, sanitizeURLPath, getSentryTags };
