import { FullAction } from '@groove/api/gateway/v1/actionCompose';
import { rawMerge } from '@groove/api/gateway/v1/template';

import useStore, { LastUnmergedFields } from '../store/useStore';

const mergeFieldHandler = async (action: FullAction): Promise<void> => {
  const { body, subject, loggingTo } = action;
  const { lastUnmergedFields, isFieldsMerging } =
    useStore.getState().otherValues;
  const unmergedFields = findAllUnmergedFields(`${body} ${subject}`);

  if (
    unmergedFields.some(field => !lastUnmergedFields[field]) &&
    !isFieldsMerging
  ) {
    try {
      useStore.getState().setOtherValues({ isFieldsMerging: true });
      const data = (
        await rawMerge({
          body: body || '',
          who_id: loggingTo?.who?.[0]?.id || '',
          what_id: loggingTo?.what?.[0]?.id || '',
          subject: subject || '',
        })
      )?.data;

      useStore.getState().updateAction({
        body: data?.body,
        subject: data?.subject,
      });

      const unmergedFieldsHash: LastUnmergedFields = {};
      findAllUnmergedFields(`${body} ${subject}`).forEach(
        field => (unmergedFieldsHash[field] = true),
      );

      useStore.getState().setOtherValues({
        wysiwygEdited: false,
        isFieldsMerging: false,
        lastUnmergedFields: unmergedFieldsHash,
      });
    } catch (error) {
      // TODO: show error on bar and do retry
      // This requires redoing the message bar system
      console.error('Issue merging Body or Subject');

      useStore.getState().setOtherValues({ isFieldsMerging: false });
    }
  }
};

export const findAllUnmergedFields = (str: string | null): string[] => {
  let startIndex = -1;
  const matches: string[] = [];

  if (!str) return matches;

  for (let index = 0; index < str.length; index += 1) {
    const char = str[index];

    if (char === '{' && index + 1 < str.length && str[index + 1] === '!') {
      startIndex = index; // Remember the start of a potential match
      index += 1; // Skip the '!' character as it's already checked
    } else if (char === '}' && startIndex !== -1) {
      if (index > 2 && str[index - 1] !== '!') {
        const match = str.substring(startIndex, index + 1);
        matches.push(match); // Add the found match to the results
      }
      startIndex = -1;
    } else if (char === '}') {
      startIndex = -1;
    }
  }
  return matches;
};

export default mergeFieldHandler;
