import { all, call } from 'redux-saga/effects';
import { trackBasicEvent } from 'Modules/Shared/sagas/analytics';
import { actionTypes as peopleActionTypes } from 'Modules/People/actions';
import { actionTypes as advancedSearchActionTypes } from 'Modules/PeopleImportDialog/submodules/advancedSearch/actions';
import { ROUTE_NAMES } from 'Routing/constants';

const savedSearchActions = [
  {
    action: peopleActionTypes.SAVE_SAVED_SEARCH,
    featureCategory: ROUTE_NAMES.PEOPLE,
    source: 'People Advanced Search',
  },
  {
    action: advancedSearchActionTypes.SAVE_SEARCH,
    featureCategory: ROUTE_NAMES.FLOWS,
    source: 'People Import Dialog',
  },
];

function* stepCreated() {
  yield all(
    savedSearchActions.map(({ featureCategory, action, source }) => {
      return call(trackBasicEvent, {
        eventName: 'Saved Search Created',
        featureCategory,
        action,
        properties: { source },
      });
    })
  );
}

export default stepCreated;
