import { createTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Palette } from '@material-ui/core/styles/createPalette';
import {
  PrimaryButtonStyle,
  SecondaryButtonStyle,
} from 'Utils/theme/ButtonStyles';
import { CLARI_COLORS } from 'theme-constants';

export const regularFontFamily =
  'MaisonNeue, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif';
interface ClariPalette extends Palette {
  tertiary: {
    main: string;
    light: string;
    lighter: string;
  };
  quaternary: {
    main: string;
    dark: string;
    light: string;
    lighter: string;
  };
  text: {
    primary: string;
    secondary: string; // Gray_1
    disabled: string;
    hint: string;
    backgroundColor: string;
    borders: string;
    buttonBorders: string;
    accentGray: string; // Gray_2
    lightGray: string; // Gray_3
    neutralColor: string;
    shadows: string;
    effort: string;
    engagement: string;
  };
  chart: {
    email: string;
    call: string;
    main: string;
  };
}

export interface ModifiedTheme extends Theme {
  groove: {
    unit: number;
  };
  'Connect(SearchableDropdownMenu)': {
    menuItem: {
      height: number;
    };
  };

  palette: ClariPalette;
}

const modifiedMuiTheme = createTheme({
  palette: {
    primary: {
      dark: CLARI_COLORS['clari-blue/700'],
      main: CLARI_COLORS['clari-blue/600'],
      light: CLARI_COLORS['clari-blue/500'],
    },
    secondary: {
      main: CLARI_COLORS.tertiary,
    },
    error: {
      dark: CLARI_COLORS['error/900'],
      main: CLARI_COLORS['error/600'],
      light: CLARI_COLORS['error/50'],
    },
    warning: {
      dark: CLARI_COLORS['warning/900'],
      main: CLARI_COLORS['warning/600'],
      light: CLARI_COLORS['warning/50'],
    },
    success: {
      dark: CLARI_COLORS['success/900'],
      main: CLARI_COLORS['success/600'],
      light: CLARI_COLORS['success/50'],
    },
    info: {
      main: CLARI_COLORS['info/900'],
      light: CLARI_COLORS['info/50'],
    },
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: 0,
        width: 48,
        height: 48,
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiCardContent: {
      root: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    MuiInput: {
      root: {
        lineHeight: 'normal',
        color: CLARI_COLORS['neutral/600'],
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${CLARI_COLORS['neutral/100']}`,
        },
        '&:hover:not(.webapp-MuiInput-disabled-293):before': {
          borderBottom: `1px solid ${CLARI_COLORS['neutral/900']}`,
        },
        '&:focus:before': {
          borderBottom: `1px solid ${CLARI_COLORS['clari-blue/600']}`,
        },
        '&:active:before': {
          borderBottom: `1px solid ${CLARI_COLORS['clari-blue/600']}`,
        },
        '&:after': {
          borderBottom: `1px solid ${CLARI_COLORS['clari-blue/600']}`,
        },
      },
    },
    MuiSwitch: {
      colorPrimary: {
        color: `${CLARI_COLORS['neutral/200']} !important`,
        '&$checked': {
          color: `${CLARI_COLORS['clari-blue/600']} !important`,
        },
        '&$checked + $track': {
          backgroundColor: CLARI_COLORS['clari-blue/600'],
          opacity: 0.5,
        },
      },
      thumb: {
        color: 'inherit',
      },
      switchBase: {
        width: 'auto',
        height: 'auto',
      },
      track: {
        backgroundColor: CLARI_COLORS['neutral/400'],
        opacity: 1,
      },
    },
    MuiTable: {
      root: {
        overflow: 'hidden',
      },
    },
  },
  props: {
    MuiFab: {
      color: 'primary',
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiCheckbox: {
      color: 'primary',
    },
  },
});

const ClariTheme: ModifiedTheme = {
  ...modifiedMuiTheme,
  palette: {
    ...CLARI_COLORS,
    ...modifiedMuiTheme.palette,
    text: {
      primary: CLARI_COLORS['neutral/900'],
      secondary: CLARI_COLORS['neutral/600'],
      disabled: CLARI_COLORS['neutral/200'],
      hint: CLARI_COLORS['neutral/100'],
      borders: CLARI_COLORS['neutral/100'],
      buttonBorders: CLARI_COLORS['neutral/200'],
      backgroundColor: CLARI_COLORS['neutral/25'],
      accentGray: CLARI_COLORS['neutral/400'],
      lightGray: CLARI_COLORS['neutral/75'],
      neutralColor: CLARI_COLORS.white,
      shadows: CLARI_COLORS['neutral/200'],
      effort: CLARI_COLORS['naranja/400'],
      engagement: CLARI_COLORS['grapeshine/600'],
    },
    tertiary: {
      main: CLARI_COLORS['clari-blue/600'],
      light: CLARI_COLORS['neutral/100'],
      lighter: CLARI_COLORS.white,
    },
    quaternary: {
      main: CLARI_COLORS['clari-blue/600'],
      dark: CLARI_COLORS['neutral/900'],
      light: CLARI_COLORS['clari-blue/600'],
      lighter: CLARI_COLORS.white,
    },
    chart: {
      email: '#04294c',
      call: CLARI_COLORS['grooveyBluey/300'],
      main: 'rgb(9, 96, 178)',
    },
  },
  groove: {
    unit: 4,
  },
  'Connect(SearchableDropdownMenu)': {
    menuItem: {
      height: 24,
    },
  },
  typography: {
    ...modifiedMuiTheme.typography,
    fontFamily: regularFontFamily,
  },
  props: {
    MuiCheckbox: {
      color: 'primary',
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiTypography: {
      color: 'textPrimary',
      variant: 'body1',
    },
    MuiFab: {
      color: 'secondary',
    },
    MuiListItemText: {
      primaryTypographyProps: {
        variant: 'subtitle1',
      },
      secondaryTypographyProps: {
        variant: 'body1',
      },
    },
  },
  overrides: {
    ...modifiedMuiTheme.overrides,
    MuiAvatar: {
      root: {
        width: 40,
        height: 40,
      },
      colorDefault: {
        backgroundColor: CLARI_COLORS['neutral/75'],
        color: CLARI_COLORS['neutral/900'],
      },
    },
    MuiAppBar: {
      root: {
        color: CLARI_COLORS['neutral/900'],
        backgroundColor: CLARI_COLORS['neutral/25'],
        borderBottom: `solid 1px ${CLARI_COLORS['neutral/100']}`,
        'box-shadow': 'none !important',
        boxShadow: 'none !important',
      },
      colorPrimary: {
        color: CLARI_COLORS['neutral/900'],
        backgroundColor: CLARI_COLORS['neutral/25'],
        borderBottom: `solid 1px ${CLARI_COLORS['neutral/100']}`,
        'box-shadow': 'none !important',
        boxShadow: 'none !important',
      },
    },
    MuiButton: {
      root: {
        height: 36,
        borderRadius: 4,
        padding: '10px 14px',
        minWidth: 88,
        minHeight: 36,
        fontFamily: regularFontFamily,
        textTransform: 'capitalize',
        lineHeight: 'normal',
      },
      containedPrimary: PrimaryButtonStyle,
      outlinedPrimary: PrimaryButtonStyle,
      containedSecondary: SecondaryButtonStyle,
      outlinedSecondary: SecondaryButtonStyle,
      textSecondary: SecondaryButtonStyle,
      outlined: SecondaryButtonStyle,
      text: {
        fontSize: 12,
        fontWeight: 600,
        padding: '8px 16px',
        letterSpacing: 0.5,
      },
      containedSizeSmall: {
        padding: '7px 8px',
      },
    },
    MuiCardHeader: {
      root: {
        '@media (min-width: 600px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: CLARI_COLORS['clari-blue/600'],
      },
    },
    MuiCardContent: {
      root: {
        '@media (min-width: 600px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiChip: {
      avatar: {
        width: 32,
        color: CLARI_COLORS['neutral/400'],
        height: 32,
        fontSize: '1rem',
        marginLeft: 0,
        marginRight: '-4px',
      },
      root: {
        borderRadius: '4px',
        border: `solid 1px ${CLARI_COLORS['clari-blue/600']}`,
        backgroundColor: CLARI_COLORS.white,
        '& $avatar': {
          color: undefined,
          height: 32,
          width: 32,
          marginLeft: undefined,
          marginRight: undefined,
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        width: 48,
        height: 48,
        color: CLARI_COLORS['neutral/900'],
      },
      colorPrimary: {
        '&$checked': {
          color: CLARI_COLORS['clari-blue/600'],
        },
      },
    },
    MuiFab: {
      root: {
        fontFamily: 'inherit',
      },
      primary: PrimaryButtonStyle,
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'inherit',
        marginTop: 8,
        minHeight: '1em',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: -14,
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: regularFontFamily,
      },
      focused: {
        color: `${CLARI_COLORS['neutral/600']} !important`,
      },
    },
    MuiRadio: {
      root: {
        padding: 0,
        color: CLARI_COLORS['neutral/900'],
      },
    },
    MuiTabs: {
      root: {
        color: CLARI_COLORS['neutral/600'],
      },
      indicator: {
        backgroundColor: CLARI_COLORS['clari-blue/600'],
        height: '4px',
      },
    },
    MuiTab: {
      wrapper: {
        fontSize: '0.8125rem',
        letterSpacing: 'normal',
      },
      root: {
        fontFamily: 'inherit',
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: 'normal',
        '@media (min-width: 960px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
      textColorInherit: {
        opacity: 1,
      },
      selected: {
        color: CLARI_COLORS['neutral/900'],
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 'auto',
        minHeight: '1em',
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'inherit',
      },
      input: {
        width: '100%',
      },
      fullWidth: {
        '& $input': {
          width: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 4,
      },
    },
    MuiTable: {
      root: {
        fontFamily: 'inherit',
      },
    },
    MuiTableRow: {
      root: {
        height: 48,
        borderBottom: `1px solid ${CLARI_COLORS['neutral/100']}`,
      },
      selected: {
        '&$root': {
          backgroundColor: CLARI_COLORS['neutral/25'],
          '&:hover': {
            backgroundColor: CLARI_COLORS['neutral/25'],
          },
        },
      },
    },
    MuiTableHead: {
      root: {
        borderBottom: `2px solid ${CLARI_COLORS['neutral/100']}`,
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: 'inherit',
        padding: '4px 56px 4px 24px',
        fontSize: '0.75rem',
        letterSpacing: 'normal',
        lineHeight: 'inherit',
      },
      paddingCheckbox: {
        padding: 'inherit',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 8,
        paddingBottom: 8,
        '@media (min-width: 600px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
      dense: {
        paddingTop: 8,
        paddingBottom: 8,
      },
      selected: {
        color: `${CLARI_COLORS['neutral/900']} !important`,
      },
    },
    MuiListItemText: {
      root: {
        padding: '0 16px',
        marginTop: 0,
        marginBottom: 0,
        '&:first-child': {
          paddingLeft: 0,
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'inherit',
        paddingTop: 12,
        paddingBottom: 12,
        height: 24,
        boxSizing: 'content-box',
      },
      selected: {
        backgroundColor: `${CLARI_COLORS['neutral/100']} !important`,
      },
    },
    MuiSelect: {
      select: {
        width: '100%',
      },
      icon: {
        color: CLARI_COLORS['clari-blue/600'],
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: CLARI_COLORS['neutral/100'],
      },
    },
    MuiTypography: {
      root: {
        display: 'block',
        fontFamily: regularFontFamily,
      },
      body1: {
        fontSize: 13,
        fontFamily: 'inherit',
        fontWeight: 400,
        letterSpacing: '0.1px',
        lineHeight: 'normal',
      },
      body2: {
        fontSize: 13,
        fontFamily: 'inherit',
        fontWeight: 600,
        letterSpacing: '0.2px',
      },
      caption: {
        fontFamily: regularFontFamily,
        letterSpacing: 'normal',
      },
      subtitle1: {
        fontWeight: 600,
        fontFamily: 'inherit',
        letterSpacing: 'normal',
      },
      subtitle2: {
        fontFamily: 'inherit',
      },
      button: {
        fontFamily: 'inherit',
      },
      overline: {
        fontFamily: 'inherit',
      },
      h1: {
        fontFamily: 'inherit',
      },
      h2: {
        fontFamily: 'inherit',
      },
      h3: {
        fontSize: 18,
        fontWeight: 600,
        fontFamily: 'inherit',
      },
      h4: {
        fontFamily: 'inherit',
      },
      h5: {
        fontFamily: 'inherit',
      },
      h6: {
        fontSize: '1.3125rem',
        fontFamily: 'inherit',
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: '0.5px',
      },
    },
  },
};

export default ClariTheme;
