import { keyMirror } from '@groove-labs/action-utils';

import AutomatedAction from 'Modules/Shared/records/AutomatedAction';

type StringMap = { [key: string]: string };
type StringMapArray = { [key: string]: string[] };

type TriggerTypes = {
  Replied: 'Replied';
  Bounced: 'Bounced';
  Complete: 'Complete';
  Import: 'Import';
  FieldChange: 'FieldChange';
  MeetingBooked: 'MeetingBooked';
  AttachmentDownloaded: 'AttachmentDownloaded';
  LinkClicked: 'LinkClicked';
  Opened: 'Opened';
  ConversationPositive: 'ConversationPositive';
  ConversationNegative: 'ConversationNegative';
  ConversationNeutral: 'ConversationNeutral';
  ConversationResult: 'ConversationResult';
  ConversationOutcome: 'ConversationOutcome';
  DaysOverdue: 'DaysOverdue';
};

export type TriggerType = keyof TriggerTypes;

export const TRIGGER_TYPES = keyMirror([
  'Replied',
  'Bounced',
  'Complete',
  'Import',
  'FieldChange',
  'MeetingBooked',
  'AttachmentDownloaded',
  'LinkClicked',
  'Opened',
  'ConversationPositive',
  'ConversationNegative',
  'ConversationNeutral',
  'ConversationResult',
  'ConversationOutcome',
  'DaysOverdue',
]) as TriggerTypes;

export const CONVERSATION_TRIGGER_TYPES = keyMirror([
  'ConversationPositive',
  'ConversationNegative',
  'ConversationNeutral',
  'ConversationResult',
  'ConversationOutcome',
]) as StringMap;

export const TRIGGER_TYPES_WITH_CONDITION = [
  TRIGGER_TYPES.LinkClicked,
  TRIGGER_TYPES.Opened,
  TRIGGER_TYPES.ConversationResult,
  TRIGGER_TYPES.ConversationOutcome,
  TRIGGER_TYPES.DaysOverdue,
] as TriggerType[];

export const TRIGGER_TYPES_WITHOUT_DATA = ['AttachmentDownloaded'];

export const OWNER_ID = 'ownerid';

export const DEFAULT_SFDC_UPDATE_FIELDS = {
  Contact: ['hasoptedoutofemail', 'donotcall', OWNER_ID],
  Lead: ['status', 'donotcall', 'hasoptedoutofemail', 'isconverted', OWNER_ID],
  Opportunity: ['stagename', 'probability', 'isclosed', 'iswon'],
  Account: ['type'],
};

export const DEFAULT_SFDC_COLUMN_NAME = {
  accountName: 'Name',
  ownerName: 'Owner Name',
  lastTouch: 'Last Touch',
  lastEngagement: 'Last Engagement',
  status: 'Status',
  hotlist: 'Hotlist',
  effortEngagement: 'Effort/Engagement',
  task: 'Tasks',
  amount: 'Expected Amount',
  stageName: 'Stage Name',
  closeDate: 'Close Date',
  name: 'Deal Name',
};

// allowed sfdc fields for "On Salesforce Update" trigger
export const ADDITIONAL_SFDC_TRIGGER_FIELDS: StringMapArray = {
  Contact: [OWNER_ID],
  Lead: [OWNER_ID],
  Opportunity: [],
  Account: [],
};

// not supported known types are commented
export const SUPPORTED_SFDC_TRIGGER_FIELD_TYPES = [
  'address',
  'boolean',
  'currency',
  // 'date',
  // 'datetime',
  'double',
  'email',
  // 'id',
  // 'int',
  'number',
  'percent',
  'phone',
  'picklist',
  'string',
  'textarea',
  // 'multipicklist',
  // 'url',
  // 'time'
];

export const REFERENCE_FIELD_TYPE = 'reference';

export const SFDC_FIELD_TYPES = keyMirror([
  ...SUPPORTED_SFDC_TRIGGER_FIELD_TYPES,
  REFERENCE_FIELD_TYPE,
]) as StringMap;

export const SFDC_FIELD_OPERATOR_LABELS: StringMap = {
  INCLUDES: 'Includes',
  EXCLUDES: 'Not includes',
  EQUAL: 'Is equal to',
  'NOT EQUAL': 'Is not equal to',
  'GREATER THAN': 'Is greater than',
  'GREATER OR EQUAL THAN': 'Is greater than or equal to',
  'LESS THAN': 'Is less than',
  'LESS OR EQUAL THAN': 'Is less than or equal to',
  CONTAINS: 'Contains',
  'DOES NOT CONTAIN': 'Does not contain',
};

export const BOOLEAN_TEXT: StringMap = {
  true: 'is checked',
  false: 'is unchecked',
};

export const MODES = keyMirror([
  'NEW',
  'CREATING',
  'DISPLAY',
  'EDIT',
  'UPDATING',
  'DESTROYING',
]) as StringMap;

export const TRIGGER_TYPE_LABELS: StringMap = {
  [TRIGGER_TYPES.Replied]: 'On Reply',
  [TRIGGER_TYPES.Bounced]: 'On Bounce',
  [TRIGGER_TYPES.Complete]: 'On Complete',
  [TRIGGER_TYPES.Import]: 'On Import',
  [TRIGGER_TYPES.FieldChange]: 'On Salesforce Update',
  [TRIGGER_TYPES.MeetingBooked]: 'On Meeting Booked',
  [TRIGGER_TYPES.Opened]: 'On Opened',
  [TRIGGER_TYPES.LinkClicked]: 'On Clicked',
  [TRIGGER_TYPES.AttachmentDownloaded]: 'On Attachment Opened',
  [TRIGGER_TYPES.ConversationPositive]: 'On Positive Signal',
  [TRIGGER_TYPES.ConversationNegative]: 'On Negative Signal',
  [TRIGGER_TYPES.ConversationNeutral]: 'On Neutral Signal',
  [TRIGGER_TYPES.ConversationResult]: 'On Conversation Result',
  [TRIGGER_TYPES.ConversationOutcome]: 'On Conversation Outcome',
  [TRIGGER_TYPES.DaysOverdue]: 'On Days Overdue',
};

type Types = {
  AddToFlowAction: 'AddToFlowAction';
  RemoveFromFlowAction: 'RemoveFromFlowAction';
  RemoveAndAddToAnotherFlowAction: 'RemoveAndAddToAnotherFlowAction';
  RemoveAccountMembersFromFlowAction: 'RemoveAccountMembersFromFlowAction';
  SfdcTaskAction: 'SfdcTaskAction';
  FieldUpdateAction: 'FieldUpdateAction';
  ReassignOwnerAction: 'ReassignOwnerAction';
};

export type Type = keyof Types;

export const TYPES = keyMirror([
  'AddToFlowAction',
  'RemoveFromFlowAction',
  'RemoveAndAddToAnotherFlowAction',
  'RemoveAccountMembersFromFlowAction',
  'SfdcTaskAction',
  'FieldUpdateAction',
  'ReassignOwnerAction',
]) as Types;

export const TYPE_LABELS: { [key in Type]: string } = {
  [TYPES.AddToFlowAction]: 'Add Them to a Flow',
  [TYPES.RemoveFromFlowAction]: 'Remove from Flow',
  [TYPES.RemoveAndAddToAnotherFlowAction]:
    'Remove from Current Flow and Add Them to a New Flow',
  [TYPES.RemoveAccountMembersFromFlowAction]:
    'Remove Everyone in this Account from the Flow',
  [TYPES.SfdcTaskAction]: 'Add a Salesforce Task',
  [TYPES.FieldUpdateAction]: 'Update a Salesforce field',
  [TYPES.ReassignOwnerAction]:
    'Reassign to SFDC Owner (only applies to Master flows)',
};

export const EXCLUDED_ACTION_TYPES = {
  [TRIGGER_TYPES.Import]: [
    TYPES.RemoveFromFlowAction,
    TYPES.RemoveAccountMembersFromFlowAction,
    TYPES.RemoveAndAddToAnotherFlowAction,
  ],
  [TRIGGER_TYPES.Complete]: [
    TYPES.RemoveFromFlowAction,
    TYPES.RemoveAccountMembersFromFlowAction,
    TYPES.RemoveAndAddToAnotherFlowAction,
  ],
  [TRIGGER_TYPES.FieldChange]: [TYPES.FieldUpdateAction],
} as { [key in TriggerType]: Type[] };

export const FILTER_AUTOMATED_ACTION_TYPES = {
  [TYPES.ReassignOwnerAction]: (automatedAction: AutomatedAction): boolean =>
    automatedAction.triggerType === TRIGGER_TYPES.FieldChange &&
    ((automatedAction.triggerData &&
      automatedAction.triggerData.field === OWNER_ID) ||
      automatedAction.salesforceTriggerConditions.some(
        condition => condition.fieldName === OWNER_ID
      )),
} as { [key in Type]: (automatedAction: AutomatedAction) => boolean };

const SFDC_FIELD_PICKLIST_OPERATORS = ['INCLUDES', 'EXCLUDES'];
const SFDC_FIELD_BOOLEAN_OPERATORS = ['INCLUDES', 'EXCLUDES'];
const SFDC_FIELD_NUMBER_OPERATORS = [
  'EQUAL',
  'NOT EQUAL',
  'LESS THAN',
  'GREATER THAN',
  'LESS OR EQUAL THAN',
  'GREATER OR EQUAL THAN',
];
const SFDC_FIELD_STRING_OPERATORS = ['EQUAL', 'NOT EQUAL'];

export const SFDC_FIELD_OPERATORS: StringMapArray = {
  [SFDC_FIELD_TYPES.address]: SFDC_FIELD_STRING_OPERATORS,
  [SFDC_FIELD_TYPES.boolean]: SFDC_FIELD_BOOLEAN_OPERATORS,
  [SFDC_FIELD_TYPES.currency]: SFDC_FIELD_STRING_OPERATORS,
  [SFDC_FIELD_TYPES.double]: SFDC_FIELD_NUMBER_OPERATORS,
  [SFDC_FIELD_TYPES.email]: SFDC_FIELD_STRING_OPERATORS,
  [SFDC_FIELD_TYPES.number]: SFDC_FIELD_NUMBER_OPERATORS,
  [SFDC_FIELD_TYPES.percent]: SFDC_FIELD_NUMBER_OPERATORS,
  [SFDC_FIELD_TYPES.phone]: SFDC_FIELD_STRING_OPERATORS,
  [SFDC_FIELD_TYPES.picklist]: SFDC_FIELD_PICKLIST_OPERATORS,
  [SFDC_FIELD_TYPES.string]: SFDC_FIELD_STRING_OPERATORS,
  [SFDC_FIELD_TYPES.textarea]: SFDC_FIELD_STRING_OPERATORS,
  [SFDC_FIELD_TYPES.reference]: [],
};

const FLOW_IMPORT_STRING_OPERATORS = [
  ...SFDC_FIELD_STRING_OPERATORS,
  'CONTAINS',
  'DOES NOT CONTAIN',
];

export const FLOW_IMPORT_FIELD_OPERATORS: StringMapArray = {
  ...SFDC_FIELD_OPERATORS,
  [SFDC_FIELD_TYPES.address]: FLOW_IMPORT_STRING_OPERATORS,
  [SFDC_FIELD_TYPES.currency]: FLOW_IMPORT_STRING_OPERATORS,
  [SFDC_FIELD_TYPES.email]: FLOW_IMPORT_STRING_OPERATORS,
  [SFDC_FIELD_TYPES.phone]: FLOW_IMPORT_STRING_OPERATORS,
  [SFDC_FIELD_TYPES.string]: FLOW_IMPORT_STRING_OPERATORS,
  [SFDC_FIELD_TYPES.textarea]: FLOW_IMPORT_STRING_OPERATORS,
};

export const SUPPORTED_TYPES = keyMirror([
  'address',
  'boolean',
  'currency',
  'double',
  'email',
  'picklist',
  'percent',
  'phone',
  'reference',
  'string',
  'textarea',
]);

export const RELATION_FIELD_SEPARATOR = '.';

export const NEW_AUTOMATED_ACTION_ID = -1;

export const MAX_SALESFORCE_TRIGGER_CONDITIONS = 5;
