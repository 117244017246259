import { StandardDropDownItem } from '@groove/ui/Components/DropDownItem';
import { GetState, SetState } from 'zustand';
import { SalesforceMetaFields } from '@groove/api/gateway/v1/salesforce';
import { parsePhoneNumber } from 'libphonenumber-js';

import { Store } from './useStore';

export type PhoneStore = {
  originalPhoneItems: StandardDropDownItem[];
  currentPhoneItem: { type: string; value: string }; // captures the selection in the dropdown
  updatedPhoneItems: StandardDropDownItem[]; // holds the state of the edits in the dropdown
  updatePhoneItem: (key: string, value: string) => void; // update a single phone item in the updatedPhoneItems
  phoneTypeItems: StandardDropDownItem[]; // used to capture the option in the phone type dropdown
  phoneTypeKeyToPhoneLabelMap: Record<string, string>; // used to map the phone key to the label
  setOriginalPhoneItems: (originalPhoneItems: StandardDropDownItem[]) => void;
  initialize: (
    selectedPhoneItem: StandardDropDownItem,
    originalPhoneItems: StandardDropDownItem[],
  ) => void;
  getChangedPhoneItems: () => StandardDropDownItem[];
  updateCurrentPhoneItem: (key: string) => void;
  setPhoneTypeItems: (fields: SalesforceMetaFields) => void;
};

export const phoneStore = (
  set: SetState<Store>,
  get: GetState<Store>,
): PhoneStore => ({
  originalPhoneItems: [],
  phoneTypeItems: [],
  currentPhoneItem: { type: '', value: '' },
  updatedPhoneItems: [],
  phoneTypeKeyToPhoneLabelMap: {},
  updatePhoneItem: (key: string, value: string) => {
    const { updatedPhoneItems } = get();

    const newPhoneItems = updatedPhoneItems.map(item =>
      item.key === key ? { ...item, value } : item,
    );

    set({ updatedPhoneItems: newPhoneItems });

    set({ currentPhoneItem: { type: key, value } }); // reflects the update in the dialog
  },
  setOriginalPhoneItems: originalPhoneItems => set({ originalPhoneItems }),
  initialize: (currentPhoneItem, originalPhoneItems) => {
    const firstPhoneItem =
      originalPhoneItems.length > 0
        ? originalPhoneItems[0]
        : { key: '', value: '' };

    set({
      currentPhoneItem: {
        type:
          currentPhoneItem?.key?.toString() || (firstPhoneItem.key as string),
        value:
          currentPhoneItem?.value?.toString() ||
          (firstPhoneItem.value as string),
      },
    });
    set({ originalPhoneItems });
    set({ updatedPhoneItems: originalPhoneItems });
  },
  getChangedPhoneItems: (): StandardDropDownItem[] => {
    const { originalPhoneItems, updatedPhoneItems } = get();

    const originalItemsMap = new Map(
      originalPhoneItems.map(item => [item.key, item]),
    );

    const changes = updatedPhoneItems.reduce(
      (acc: StandardDropDownItem[], updatedItem) => {
        const originalItem = originalItemsMap.get(updatedItem.key);
        if (
          updatedItem.value !== '' &&
          (!originalItem || updatedItem.value !== originalItem.value)
        ) {
          acc.push(updatedItem);
        }

        return acc;
      },
      [],
    );

    return changes;
  },
  updateCurrentPhoneItem: key => {
    const { updatedPhoneItems } = get();
    set({
      currentPhoneItem: {
        value:
          updatedPhoneItems.find(item => item.key === key)?.value?.toString() ||
          '',
        type: key,
      },
    });
  },
  setPhoneTypeItems: fields => {
    // loop over the object values and get the ones that have the type phone
    const phoneFields = Object.values(fields).filter(
      field => field.type === 'phone',
    );
    // return an array of object with keys as key, text and value which are unique
    const phoneTypeItems = phoneFields.map(field => ({
      key: field.name.toLowerCase(),
      text: field.label,
      value: field.name.toLowerCase(),
    }));

    const phoneTypeKeyToPhoneLabelMap = phoneFields.reduce(
      (acc, field) => ({
        ...acc,
        [field.name.toLowerCase()]: field.label,
      }),
      {},
    );
    set({ phoneTypeKeyToPhoneLabelMap });
    // populate updatedPhoneItems with all the types from phoneTypeItems and if the value is not present in the original phone items, set it to ''
    // this will help the store to save any updates to phones for types that are not part of the original phone items
    // for example, if the original phone items are ['work', 'home'] with values for both of them  and the phoneTypeItems are ['work', 'home', 'mobile'],
    // the updatedPhoneItems will be ['work', 'home', 'mobile'] with values for work and home and '' for mobile
    const currentItemsMap = new Map(
      get().updatedPhoneItems.map(item => [item.key, item.value]),
    );

    const newPhoneItems = phoneTypeItems.map(item => ({
      ...item,
      key: item.key,
      value: currentItemsMap.get(item.key) || '', // Fallback to '' if the key doesn't exist
    }));

    set({
      updatedPhoneItems: newPhoneItems,
    });

    // set secondary text of the phoneTypeItems to the phone number if it exists
    const updatedPhoneTypeItems = phoneTypeItems.map(item => {
      const valueFromMap = currentItemsMap.get(item.key) as string | undefined;
      let formattedValue = '';

      if (valueFromMap) {
        try {
          const parsedNumber = parsePhoneNumber(valueFromMap);
          formattedValue = parsedNumber
            ? parsedNumber.formatInternational()
            : valueFromMap;
        } catch (error) {
          // Ignore errors and use the original valueFromMap
          formattedValue = valueFromMap;
        }
      }

      return {
        ...item,
        secondaryText: [formattedValue || ''],
      };
    });

    set({ phoneTypeItems: updatedPhoneTypeItems });
  },
});
