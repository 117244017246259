import { createActions, standardActions } from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SHARED: {
    FLOWS: {
      LOAD_ADDABLE_FLOWS: standardActions(),
    },
  },
}).SHARED.FLOWS;

export function loadAddableFlows() {
  return {
    type: actionTypes.LOAD_ADDABLE_FLOWS.BEGIN,
  };
}
