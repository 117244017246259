import { Record, Map } from 'immutable';
import {
  ACTION_PERSON_FLOW_ID_KEY_PATH,
  ACTION_CHILD_ID_KEY_PATH,
  ACTION_FLOW_ID_KEY_PATH,
} from 'Modules/ActionCompose/constants/graphql';
import { getTemplateName } from 'Modules/ActionCompose/utils';
import Person from 'Modules/Actions/records/Person';
import FlowMetadata from 'Modules/Actions/records/FlowMetadata';
import sharedDefaults from './defaults';
import SfdcFields from './SfdcFields';
import flowDefaults from './flowDefaults';

const defaults = {
  ...sharedDefaults,
  ...flowDefaults,
  type: '',
  templateName: '',
  sfdcFields: new SfdcFields(),
  currentValues: new Map({
    sfdcType: null,
  }),
};
export default class LinkedInConnect extends Record(defaults) {
  static fromGraphQL(action) {
    const executionContext = action.get('executionContext');

    const person = action.get('who') || new Map();
    const sfdcFields = person.get('sfdcFields') || new Map();
    const sfdcType = executionContext?.get('sfdcType');
    const mergedTemplate = executionContext?.get('mergedTemplate');
    const body = mergedTemplate?.get('body') || executionContext?.get('body');
    const templateName = getTemplateName(action, executionContext);

    return new LinkedInConnect({
      id: action.get('id'),
      source: action.get('source'),
      type: action.get('type'),
      ...(action.has('who') && { recipient: Person.from(action.get('who')) }),
      summary: action.get('summary'),
      priority: action.get('priority'),
      failedData: action.get('failedData'),
      error: action.get('error'),
      sourceMetadata: FlowMetadata.from(
        action.get('sourceMetadata')?.toJS() || {}
      ),
      personStepId: action.getIn(ACTION_CHILD_ID_KEY_PATH),
      personFlowId: action.getIn(ACTION_PERSON_FLOW_ID_KEY_PATH),
      flowId: action.getIn(ACTION_FLOW_ID_KEY_PATH),
      templateName,
      ...(action.has('who') && { recipient: Person.from(person) }),
      sfdcFields: SfdcFields.from(sfdcFields),
      body,
      currentValues: new Map({
        sfdcType,
      }),
    });
  }
}
