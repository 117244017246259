import { createSelector } from 'reselect';
import { currentUser } from 'Modules/Shared/selectors/users';
import { Map, List } from 'immutable';
import { location } from 'Modules/Shared/selectors/location';
import { featureFlags } from 'Modules/Shared/selectors/featureFlags';

export const appLoading = state => state.getIn(['app', 'appLoading']);
export const getAppUpstartSucceeded = state =>
  state.getIn(['app', 'appUpstartSucceeded']);
export const contentLoading = state => state.getIn(['app', 'contentLoading']);
export const routerResolved = state =>
  state.getIn(['app', 'location', 'resolved']);
export const getTypeaheadSearchResults = state =>
  state.getIn(['app', 'searchResults']);
export const getSearchInProgress = state =>
  state.getIn(['app', 'searchInProgress']);
export const getRemoveInProgress = state =>
  state.getIn(['app', 'removeInProgress']);
export const getShowTopNavTabs = state =>
  state.getIn(['app', 'showTopNavTabs']);
export const getSearchFilter = state => state.getIn(['app', 'searchFilter']);
export const getSearchQuery = state => state.getIn(['app', 'searchQuery']);
export const shouldReloadFromVersionUpdate = state =>
  state.getIn(['app', 'releaseVersion', 'shouldReload']);
export const getCurrentVersion = state =>
  state.getIn(['app', 'releaseVersion', 'version']);
export const getRetryCount = state => state.getIn(['app', 'retryCount']);

export const getAmazonData = state => state.getIn(['app', 'amazonData']);
export const getSchedulerLink = state =>
  state.getIn(['settings', 'mySettings', 'scheduler', 'link']);

export const isSnackbarOpen = state => state.getIn(['app', 'snackbarOpen']);
export const getSnackbarQueue = state => state.getIn(['app', 'snackbarQueue']);

export const hasFlowsEnabled = createSelector(currentUser, user =>
  (user || new Map({ flowEnabled: false })).get('flowEnabled')
);

export const inLoadingState = createSelector(
  appLoading,
  routerResolved,
  contentLoading,
  (appLoading, routerResolved, contentLoading) => {
    return appLoading || !routerResolved || contentLoading;
  }
);

export const getPreviousPath = state =>
  state.getIn(['app', 'location', 'previousPaths'], new List()).last();

export const makeGetModuleTabs = ROUTES =>
  createSelector(
    location,
    currentUser,
    featureFlags,
    (location, currentUser, featureFlags) => {
      const routeName = location.get('routeName');
      const route = ROUTES.find(route => route.routeName === routeName);

      if (!route) return [];
      return route.tabs.filter(tab => {
        if (tab.shouldRender) {
          return tab.shouldRender({ currentUser, featureFlags });
        }

        return true;
      });
    }
  );

export const getLoginAsUsers = state =>
  state.getIn(['app', 'loginAs', 'users']);
export const getLoginAsTotal = state =>
  state.getIn(['app', 'loginAs', 'total']);
export const getLoginAsSearchQuery = state =>
  state.getIn(['app', 'loginAs', 'searchQuery']);
export const getLoginAsPage = state =>
  state.getIn(['app', 'loginAs', 'page'], 1);
export const getLoginAsErrorMessage = state =>
  state.getIn(['app', 'loginAs', 'errorMessage']);
export const getLoginAsSearchUsersInProgress = state =>
  state.getIn(['app', 'loginAs', 'searchUsersInProgress']);
export const getLoginAsLoginInProgress = state =>
  state.getIn(['app', 'loginAs', 'loginInProgress']);

export const getSnackbarWarnings = state =>
  state.getIn(['app', 'snackbarWarnings'], null);

export const getLatestSnackbarWarning = createSelector(
  getSnackbarWarnings,
  warnings => {
    if (!warnings) {
      return null;
    }
    return warnings.findLast(warning => !warning.get('dismissed'));
  }
);
