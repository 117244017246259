import grooveSearch from '@groove/api/visualforce/grooveSearch';
import { useQuery, UseQueryResult } from 'react-query';
import { createLocalStorageStateHook } from 'use-local-storage-state';

import transformGrooveEngineResult from './transformGrooveEngineResults';
import transformSearchResult, {
  DisplayableSearchResult,
} from './transformSearchResult';
import useStore from './useStore';

export const useOrderBy = createLocalStorageStateHook(
  'orderBy',
  'lastmodifieddate',
);
export const useOrderDir = createLocalStorageStateHook('orderDir', 'desc');
export const useMyRecordsOnly = createLocalStorageStateHook(
  'myRecordsOnly',
  false,
);
export const useOpenOpportunitiesOnly = createLocalStorageStateHook(
  'openOpportunitiesOnly',
  false,
);

const useSearchResults = (): UseQueryResult<
  DisplayableSearchResult[] | undefined,
  unknown
> => {
  const [orderBy] = useOrderBy();
  const [orderDir] = useOrderDir();
  const [myRecordsOnly] = useMyRecordsOnly();
  const [openOpportunitiesOnly] = useOpenOpportunitiesOnly();

  const searchQuery = useStore(store => store.searchQuery);

  return useQuery(
    [
      'search',
      searchQuery,
      orderBy,
      orderDir,
      myRecordsOnly,
      openOpportunitiesOnly,
    ],
    async () => {
      const data = await grooveSearch(
        searchQuery,
        null,
        false,
        orderBy,
        orderDir,
        myRecordsOnly,
        openOpportunitiesOnly,
      );

      return transformGrooveEngineResult(data)
        .flat?.()
        .map(transformSearchResult);
    },
    { enabled: searchQuery.length > 2 },
  );
};

export const useSearchResultObjectTypes = ():
  | [string, number][]
  | undefined => {
  const searchQuery = useStore(store => store.searchQuery);

  const [orderBy] = useOrderBy();
  const [orderDir] = useOrderDir();
  const [myRecordsOnly] = useMyRecordsOnly();
  const [openOpportunitiesOnly] = useOpenOpportunitiesOnly();

  const { data } = useQuery(
    [
      'search',
      'objectTypes',
      searchQuery,
      orderBy,
      orderDir,
      myRecordsOnly,
      openOpportunitiesOnly,
    ],
    () => {
      return grooveSearch(
        searchQuery,
        null,
        false,
        orderBy,
        orderDir,
        myRecordsOnly,
        openOpportunitiesOnly,
      );
    },
    { enabled: searchQuery.length > 2 },
  );

  return data
    ? transformGrooveEngineResult(data)
        ?.filter(result => result?.length > 0)
        .filter(result => result[0].attributes?.type)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(result => [result[0].attributes.type!, result.length])
    : undefined;
};

export default useSearchResults;
