import { Text } from '@fluentui/react';
import { FC } from 'react';
import cx from 'classnames';

import ActionsFilterIcon from './ActionFilterIcon';

type FilterMenuButtonProps = {
  totalFilters: number;
  showDropdown: boolean;
  showFilterText?: boolean;
};

const FilterMenuButton: FC<FilterMenuButtonProps> = ({
  totalFilters,
  showDropdown,
  showFilterText = false,
}) => {
  return (
    <div
      className={cx(
        totalFilters > 0 ? 'rounded-l' : 'rounded',
        showDropdown ? 'bg-white' : 'bg-transparent',
        'flex h-full box-border items-center border-[1px] bg-white border-neutral/200 hover:border-neutral/900 border-solid px-1',
        showFilterText ? 'gap-x-2 gap-y-2' : '',
      )}
    >
      {totalFilters > 0 && (
        <Text className="pl-1 text-sm font-semibold text-clari-blue/600 hover:text-clari-blue/700">
          {totalFilters}
        </Text>
      )}
      <ActionsFilterIcon className="h-[20px] w-[20px] text-clari-blue/600 hover:text-clari-blue/700" />
      {showFilterText && (
        <Text className="text-sm font-semibold hover:text-clari-blue/700">
          Filter
        </Text>
      )}
    </div>
  );
};

export default FilterMenuButton;
