import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  flowActionsQuota,
  flowActionsUsed,
  flowQuotaResetInterval,
  flowActionsLimitReached,
  insufficientFlowsForSendAll,
} from 'Modules/Shared/selectors/productUsage';
import FlowLimitTooltip from 'Modules/Flows/components/FlowLimitTooltip';
import { getLdClient } from 'Utils/ldClient';
import FlowCount from './FlowCount';

const styles = theme => ({
  flowActionsQuota: {
    marginBottom: '3px',
    textAlign: 'right',
    gridColumn: '3',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flowActionsQuotaContent: {
    marginLeft: '5px',
    fontSize: 12,
    fontWeight: '600',
    paddingTop: '3px',
    color: theme.palette.text.primary,
  },
});

@withStyles(styles)
@connect(state => {
  return {
    flowActionsUsed: flowActionsUsed(state),
    flowActionsQuota: flowActionsQuota(state),
    flowQuotaResetInterval: flowQuotaResetInterval(state),
    flowActionsLimitReached: flowActionsLimitReached(state),
    insufficientFlowsForSendAll: insufficientFlowsForSendAll(state),
  };
})
export default class FlowLimitCounter extends Component {
  static propTypes = {
    flowActionsUsed: PropTypes.number.isRequired,
    flowActionsQuota: PropTypes.number.isRequired,
    flowActionsLimitReached: PropTypes.bool.isRequired,
    insufficientFlowsForSendAll: PropTypes.bool.isRequired,
    isSendAll: PropTypes.bool,
    flowQuotaResetInterval: PropTypes.oneOf(['Day', 'Week', 'Month'])
      .isRequired,
    classes: PropTypes.shape({
      flowActionsQuota: PropTypes.string.isRequired,
      flowActionsQuotaContent: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isSendAll: false,
  };

  isEnabled = () =>
    this.props.flowActionsQuota !== -1 &&
    getLdClient().variation('flow-actions-limit');

  getDurationText() {
    const { flowQuotaResetInterval } = this.props;

    if (flowQuotaResetInterval === 'Day') {
      return ' Actions Used Today';
    } else {
      return ` Actions Used this ${flowQuotaResetInterval}`;
    }
  }

  render() {
    const {
      classes,
      flowActionsUsed,
      flowActionsQuota,
      isSendAll,
      flowActionsLimitReached,
      insufficientFlowsForSendAll,
    } = this.props;
    const limitReached = isSendAll
      ? insufficientFlowsForSendAll
      : flowActionsLimitReached;
    const displayInsufficientActionsTooltip =
      isSendAll && !flowActionsLimitReached && insufficientFlowsForSendAll;

    return this.isEnabled() ? (
      <div className={classes.flowActionsQuota}>
        <FlowLimitTooltip
          limitReached={limitReached}
          isSendAll={displayInsufficientActionsTooltip}
        />
        <div className={classes.flowActionsQuotaContent}>
          <FlowCount used={flowActionsUsed} quota={flowActionsQuota} />
          {this.getDurationText()}
        </div>
      </div>
    ) : null;
  }
}
