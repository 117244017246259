import { call, put } from 'redux-saga/effects';
import { batchDestroy } from 'GrooveHTTPClient/labelInstances';
import { actions } from '@groove-labs/groove-ui';
import {
  LABEL_IDS_TO_UPDATE_UI_KEY_PATH,
  LABEL_CATEGORIES,
} from 'Modules/Shared/constants/labels';
import updateLabelable from 'Modules/Shared/sagas/labels/updateLabelable';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import HTTPError from 'GrooveHTTPClient/HTTPError';
import HttpStatusCodes from 'http-status-codes';
import { applyLabels } from './labelInstances/applyLabels';

const { setProperty } = actions.ui;

function* setCheckedLabelIds({ checkedLabelIds }) {
  yield put(
    setProperty({
      uiKeyPath: LABEL_IDS_TO_UPDATE_UI_KEY_PATH,
      data: checkedLabelIds,
    })
  );
}

function* handleLabelInstances({
  payload: { labelable, labelInstances, checkedLabelIds },
}) {
  yield call(setCheckedLabelIds, { checkedLabelIds });
  const labelInstancesWithoutGrooveSystem = labelInstances.filterNot(
    labelInstance =>
      labelInstance.label.category === LABEL_CATEGORIES.GROOVE_SYSTEM
  );

  const labelInstanceIdsToRemove = [];
  const labelIdsToApply = checkedLabelIds
    .filter(id => !labelInstancesWithoutGrooveSystem.has(id))
    .toJS();

  labelInstancesWithoutGrooveSystem.valueSeq().forEach(instance => {
    if (!checkedLabelIds.has(instance.label.id)) {
      labelInstanceIdsToRemove.push(instance.id);
    }
  });

  try {
    let appliedLabelInstances = [];

    if (labelIdsToApply.length > 0) {
      appliedLabelInstances = yield call(applyLabels, {
        labelable,
        labelIds: labelIdsToApply,
      });
    }
    if (labelInstanceIdsToRemove.length > 0) {
      yield call(batchDestroy, { labelInstanceIds: labelInstanceIdsToRemove });
    }

    yield call(updateLabelable, {
      labelableType: labelable.type,
      labelsToAdd: appliedLabelInstances,
      labelInstanceIdsToRemove,
    });
  } catch (e) {
    if (
      e instanceof HTTPError &&
      (e.response.status === HttpStatusCodes.NOT_FOUND ||
        e.response.status === HttpStatusCodes.INTERNAL_SERVER_ERROR)
    ) {
      yield put(
        pushSnackbarMessage({
          message:
            'There was an error updating labels. Please refresh the page and try again.',
        })
      );
      return;
    }
    throw e;
  }
}

export default handleLabelInstances;
