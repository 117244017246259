import { call, put, select, all, spawn } from 'redux-saga/effects';
import handleSetAuthToken from 'Modules/App/sagas/upstarts/helpers/handleSetAuthToken';
import setupAnalytics from 'Modules/App/sagas/upstarts/helpers/setupAnalytics';
import assembleSetCurrentUserPayload from 'Modules/App/sagas/upstarts/helpers/assembleSetCurrentUserPayload';
import { isLoggedIn as isLoggedInSelector } from 'Modules/Shared/selectors/users';
import { actionTypes as appActionTypes, ready } from 'Modules/App/actions';
import { setContentLoading } from 'Modules/Shared/actions/app';
import {
  getActivityOptions,
  getCurrentUser,
  getUserOrgSettings,
} from 'GrooveHTTPClient/users';
import { setAuthorizations } from 'Modules/Shared/actions/authorizations';
import { setFeatureFlags } from 'Modules/Shared/actions/featureFlags';
import { setProductUsage } from 'Modules/Shared/actions/productUsage';
import { setSalesforceMeta } from 'Modules/Shared/actions/salesforceMeta';
import { setSavedSearches } from 'Modules/Shared/actions/savedSearches';
import { fetchSettings } from 'Modules/Shared/actions/dialer';
import { index as getAccessControls } from 'GrooveHTTPClient/accessControls';
import { index as getAuthorizations } from 'GrooveHTTPClient/authorizations';
import { fetchFeatureFlags } from 'GrooveHTTPClient/featureFlags';
import { show as getProductUsage } from 'GrooveHTTPClient/productUsage';
import { show as getSalesforceMeta } from 'GrooveHTTPClient/salesforceMeta';
import { index as getSavedSearches } from 'GrooveHTTPClient/savedSearches';
import { setAccessControls } from 'Modules/Shared/actions/accessControls';
import {
  addGlobalSetting,
  setCurrentUser,
  loginSuccess,
} from 'Modules/Shared/actions/users';
import { location as locationSelector } from 'Modules/Shared/selectors/location';
import { initialize as initializeLdClient } from 'Utils/ldClient';
import launchdarkly from 'Modules/App/sagas/launchdarkly';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';
import fixCsrfToken from 'Modules/App/sagas/upstarts/helpers/fixCsrfToken';
import { history } from 'Utils/history';

function* appUpstart() {
  yield call(handleSetAuthToken);

  yield call(fixCsrfToken);

  const isLoggedIn = yield select(state => isLoggedInSelector(state));

  // If not logged in, kill the generator and toggle appLoading so that react router performs
  // the protected route logic
  if (!isLoggedIn) {
    yield put({ type: appActionTypes.UPSTART.FAILURE });
    return;
  }

  // Now that we know the user is logged in, make authenticated calls.

  yield put(setContentLoading(true));

  let currentUserResponse = yield call(getCurrentUser);
  const userOrgSettingsResponse = yield call(getUserOrgSettings);

  let numberOfAttempts = 0;
  while (!currentUserResponse?.data && numberOfAttempts < 10) {
    currentUserResponse = yield call(getCurrentUser);
    numberOfAttempts += 1;
  }

  const currentUser = currentUserResponse.updateIn(
    ['message', 'data', 'orgSettings'],
    m => m.merge(userOrgSettingsResponse.data)
  );

  if (currentUser.message.get('data').get('orgId') === null) {
    window.location = `${grooveEngineRootUrl}/register`;
    return;
  }

  // Load features flags product usage, and access control data into store
  const [
    featureFlagsResponse,
    productUsageResponse,
    accessControlsResponse,
    authorizationsResponse,
    savedSearchesResponse,
    salesforceMetaResponse,
    activityOptionsResponse,
  ] = yield all([
    call(fetchFeatureFlags),
    call(getProductUsage),
    call(getAccessControls),
    call(getAuthorizations),
    call(getSavedSearches),
    call(getSalesforceMeta),
    call(getActivityOptions),
  ]);

  // Start fetch of dialer settings
  yield put(fetchSettings());

  yield call(initializeLdClient);
  yield spawn(launchdarkly);

  // setup Sentry & segment & logrocket & splunk
  yield call(setupAnalytics, currentUser.data);

  yield all([
    put(
      setCurrentUser(
        assembleSetCurrentUserPayload({ currentUserResponse: currentUser })
      )
    ),
    put(setFeatureFlags(featureFlagsResponse.data)),
    put(setProductUsage(productUsageResponse.data)),
    put(setAccessControls(accessControlsResponse.data)),
    put(setAuthorizations(authorizationsResponse.data)),
    put(setSavedSearches(savedSearchesResponse.data)),
    put(setSalesforceMeta(salesforceMetaResponse.data)),
    put(
      addGlobalSetting({
        setting: 'activityResults',
        value: activityOptionsResponse.data.activityResults,
      })
    ),
    put(
      addGlobalSetting({
        setting: 'templateTypes',
        value: activityOptionsResponse.data.templateTypes,
      })
    ),
  ]);

  yield put(loginSuccess());

  yield put(ready());

  const location = yield select(locationSelector);

  // if they somehow force-nav'd to refresh_salesforce_meta and their meta is fine
  // redirect them back to home
  if (location.get('rootPath') === 'refresh_salesforce_meta') {
    yield call(history.push, '/');
  }
  yield put({ type: appActionTypes.UPSTART.SUCCESS });
}

export default appUpstart;
