import getClient from './getClient';

export type ApiLiRedirect = {
  url: string;
};

export type ApiLiToken = {
  token?: string;
  error?: string;
};

export const getLinkedinRegistration = (): Promise<ApiLiRedirect> =>
  getClient().get('linkedin/register').json();

export const getLinkedinSAToken = (): Promise<ApiLiToken> =>
  getClient().get('linkedin/sales_access_token').json();
