import ActionFilter from '@groove/ui/Components/ActionFilter/index';

export default function FilterButton() {
  return (
    <div className="flex ml-4">
      <div className="self-center">
        <ActionFilter showFilterText />
      </div>
    </div>
  );
}
