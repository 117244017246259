import { compact } from 'lodash-es';

/**
 * Build soql query for fetching contacts or leads
 *
 * @param {string} selectedObject one of ['Lead', 'Contact']
 * @param {List} fields
 * @param {List} ids array of SFDC Ids
 *
 * @return {string}
 */
export function buildSoqlQuery(selectedObject, fields, ids) {
  let soqlQuery = `SELECT ${fields
    .toJS()
    .join(', ')} FROM ${selectedObject} WHERE `;

  if (selectedObject.toLowerCase() === 'lead') {
    soqlQuery += '(isConverted = false) AND ';
  }

  // remove blank ids
  const compactedIds = compact(ids.toJS());
  soqlQuery += `Id IN ('${compactedIds.join("', '")}') `;

  return soqlQuery;
}
