import { createSelector } from 'reselect';

export const getHealthItems = state =>
  state.getIn(['users', 'health', 'items']);

export const getItemHealth = (state, key) =>
  state.getIn(['users', 'health', 'items', key, 'isValid']);

export const getLastCheckedAt = state =>
  state.getIn(['users', 'health', 'lastCheckedAt']);

export const getIsFixedByUserAction = (state, key) =>
  state.getIn(['users', 'health', 'items', key, 'isFixedByUserAction']);

export const getIsFixInProgress = (state, key) =>
  state.getIn(['users', 'health', 'items', key, 'fixInProgress']);

export const getHasMultipleUnhealthyItems = createSelector(
  getHealthItems,
  healthItems => healthItems.filter(item => !item.get('isValid')).size > 1
);

export const isUserHealthy = createSelector(getHealthItems, healthItems => {
  return healthItems.every(item => item.get('isValid'));
});
