import querystring from 'querystring';

import { SagaIterator } from 'redux-saga';
import { select, put, call, delay } from 'redux-saga/effects';
import { actions } from '@groove-labs/groove-ui';

import { searchByName as searchOpportunitiesByName } from 'GrooveHTTPClient/salesforceOpportunitiesSearch';
import { searchByName as searchAccountsByName } from 'GrooveHTTPClient/salesforceAccountSearch';
import {
  getWorkspaceId,
  getWorkspaceType,
} from 'Modules/Spaces/show/selectors';
import { actionTypes } from 'Modules/Spaces/import/actions';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { SPACES_IMPORT_DIALOG_PAGE_UI_KEY_PATH } from 'Modules/Spaces/import/constants';
import { WORKSPACE_TYPES } from 'Modules/Spaces/shared/constants';
import { currentUser } from 'Modules/Shared/selectors/users';
import { logErrorToSentry } from 'Modules/Shared/sagas/errors';

const { setProperty } = actions.ui;

type SearchByNameActionType = {
  name: string;
  fullPath: string;
};

type PayloadType = {
  payload: SearchByNameActionType;
};

type SearchByAccountPayload = {
  name: string;
  fullPath: string;
};

function* searchByName({
  name,
  fullPath,
}: SearchByAccountPayload): SagaIterator {
  const id = yield select(getWorkspaceId);
  const user = yield select(currentUser);
  const workspaceType = yield select(getWorkspaceType);

  try {
    if (workspaceType === WORKSPACE_TYPES.ACCOUNT) {
      const response = yield call(searchAccountsByName, {
        name,
        id,
        userId: user.get('id'),
      });

      return response.data;
    }
    const response = yield call(searchOpportunitiesByName, {
      id,
      userId: user.get('id'),
      name,
    });

    return response.data;
  } catch (e) {
    if (e.response && e.response.get('status') === 401) {
      const qs = querystring.stringify({
        original_url: `${window.location.origin}${fullPath}`,
        always_redirect: true,
      });
      yield put(
        pushSnackbarMessage({
          message:
            'Invalid SalesForce token detected. Redirecting you to fix that!',
        })
      );
      yield delay(1500);
      window.location.assign(`${grooveEngineRootUrl}/fix?${qs}`);
      return null;
    }
    throw e;
  }
}

function* handleSearchByName({ payload }: PayloadType): SagaIterator {
  yield delay(150);
  try {
    yield put({ type: actionTypes.SEARCH_BY_NAME.PROGRESS });
    const { name, fullPath } = payload;
    const workspaceType = yield select(getWorkspaceType);

    const results = yield call(searchByName, {
      name,
      fullPath,
    });
    yield put({
      type: actionTypes.SEARCH_BY_NAME.SUCCESS,
      payload: {
        results,
        workspaceType,
      },
    });
    yield put(
      setProperty({
        uiKeyPath: SPACES_IMPORT_DIALOG_PAGE_UI_KEY_PATH,
        data: 1,
      })
    );
  } catch (e) {
    logErrorToSentry(e);
    yield put(
      pushSnackbarMessage({
        message:
          'There was an issue retrieving your search results. Please try again.',
        duration: 3000,
      })
    );
    yield put({
      type: actionTypes.SEARCH_BY_NAME.FAILURE,
    });
  }
}

export default handleSearchByName;
