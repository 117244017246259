import { GetState, SetState } from 'zustand';

import { What, Who } from '../../api/graphql/types';
import { Store } from '../useStore';

export type ChatStore = {
  activeChannelSid: string | null;
  activeChannelPhoneNumber: string | null;
  setActiveChannelSid: (activeChannelSid: string | null) => void;
  setActiveChannelPhoneNumber: (
    activeChannelPhoneNumber: string | null,
  ) => void;
  chatContext: {
    who: Who | null;
    what: What | null;
  };
  setChatContext: (who?: Who | null, what?: What | null) => void;
  leaveChat: () => void;
};
export const chatStore = (
  set: SetState<Store>,
  get: GetState<Store>,
): ChatStore => ({
  activeChannelSid: null,
  activeChannelPhoneNumber: null,
  chatContext: {
    who: null,
    what: null,
  },
  setActiveChannelSid: activeChannelSid =>
    set({
      activeChannelSid,
      currentTab:
        activeChannelSid || get().activeChannelPhoneNumber ? 'Home' : 'Recent',
    }),

  setActiveChannelPhoneNumber: activeChannelPhoneNumber =>
    set({ activeChannelPhoneNumber }),
  setChatContext: (who, what) =>
    set({ chatContext: { who: who || null, what: what || null } }),
  leaveChat: () => {
    const { setActiveChannelSid, setActiveChannelPhoneNumber, setChatContext } =
      get();
    setActiveChannelSid(null);
    setActiveChannelPhoneNumber(null);
    setChatContext(null, null);
  },
});
