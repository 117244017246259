import { Record } from 'immutable';

const defaults = {
  to: null,
  cc: null,
};

export default class EmailRecipients extends Record(defaults) {
  hasMultiple() {
    return (this.to?.size || 0) + (this.cc?.size || 0) > 1;
  }
}
