import { FC, useEffect, useState } from 'react';

import SnackbarNotification from './SnackbarNotification';
import { SNACKBAR_POSITIONS, SnackbarNotificationMsg } from './snackbarUtils';

export type SnackBarProps = {
  containerClasses?: string;
};

const Snackbar: FC<SnackBarProps> = ({ containerClasses }) => {
  const [notifications, setNotifications] = useState<SnackbarNotificationMsg[]>(
    [],
  );
  const [position, setPosition] = useState<SNACKBAR_POSITIONS>(
    SNACKBAR_POSITIONS.TOP_CENTER,
  );

  useEffect(() => {
    window.addEventListener(
      'snackbar-notification-added',
      handleNotificationAdded,
    );
    return () =>
      window.removeEventListener(
        'snackbar-notification-added',
        handleNotificationAdded,
      );
  }, []);

  const handleNotificationAdded = (event: Event): void => {
    const notificationEvent = event as CustomEvent;
    setNotifications(n => [...n, notificationEvent.detail]);
    setPosition(notificationEvent.detail.position);
  };

  const handleDismissed = (id: string): void => {
    setNotifications(n => n.filter(n => n.id !== id));
  };

  let classesBasedOnPosition = '';
  switch (position) {
    case SNACKBAR_POSITIONS.TOP_CENTER:
      classesBasedOnPosition = 'left-[50%] translate-x-[-50%] top-5';
      break;
    case SNACKBAR_POSITIONS.TOP_LEFT:
      classesBasedOnPosition = 'top-5 left-5';
      break;
    case SNACKBAR_POSITIONS.TOP_RIGHT:
      classesBasedOnPosition = 'top-5 right-5';
      break;
    case SNACKBAR_POSITIONS.BOTTOM_CENTER:
      classesBasedOnPosition = 'left-[50%] translate-x-[-50%] bottom-5';
      break;
    case SNACKBAR_POSITIONS.BOTTOM_LEFT:
      classesBasedOnPosition = 'bottom-5 left-5';
      break;
    case SNACKBAR_POSITIONS.BOTTOM_RIGHT:
      classesBasedOnPosition = 'bottom-5 right-5';
      break;
    default:
      break;
  }

  return (
    <div
      className={`absolute z-[1000] font-display ${containerClasses} ${classesBasedOnPosition}`}
      aria-label="snackbar-container"
    >
      {notifications.map(notification => (
        <SnackbarNotification
          key={notification.id}
          notification={notification}
          onDismiss={handleDismissed}
        />
      ))}
    </div>
  );
};

export default Snackbar;
