import { camelizeKeys } from 'humps';

import getClient from './getClient';

export type CallStatus =
  | 'PROCESSING'
  | 'PROCESSED'
  | 'POST_PROCESSING_DONE'
  | 'WAITING_IN_QUEUE'
  | 'INPROGRESS'
  | 'SCHEDULED'
  | 'INITIATED';
export type CopilotCall = {
  id: string;
  sourceId: string;
  title: string;
  status: CallStatus;
  type: string;
  time: string;
  icaluid: string;
  calendarId: string;
  recurringEventId: string;
  originalStartTime: string;
  callReviewPageUrl: string;
  fullSummary: string;
  keyActionItems: string[];
  actionItems: string[];
  users: string[];
  externalParticipants: string[];
};

export type CopilotCallsError = {
  message: string;
  errorCode: string;
  statusCode: number;
};

export const listCopilotCalls = (
  contactEmail: string,
): Promise<CopilotCall[]> =>
  getClient()
    .get('copilot_calls', { searchParams: { contact_email: contactEmail } })
    .json()
    .then(
      // eslint-disable-next-line @typescript-eslint/ban-types
      value => camelizeKeys(value as object) as CopilotCall[],
    )
    .catch(async error => {
      const parsedError = camelizeKeys(await error.response.json());
      throw parsedError;
    });

export const getCopilotCall = (callId: string): Promise<CopilotCall> =>
  getClient()
    .get(`copilot_calls/${callId}`)
    .json()
    .then(
      // eslint-disable-next-line @typescript-eslint/ban-types
      value => camelizeKeys(value as object) as CopilotCall,
    )
    .catch(async error => {
      const parsedError = camelizeKeys(await error.response.json());
      throw parsedError;
    });
