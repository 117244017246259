import { call } from 'redux-saga/effects';

import executeGraphQLRequest from 'GrooveHTTPClient/executeGraphQLRequest';

import query from 'Modules/Spaces/import/gql/mutations/WorkspaceAddSObject.mutation.gql';

export default function* addSObjectToWorkspace({
  workspaceId,
  sObjectIds,
  sObjectType,
}) {
  const response = yield call(executeGraphQLRequest, {
    query,
    variables: {
      workspaceId,
      sObjectIds,
      sObjectType,
    },
  });

  const error = response.get('error');
  if (error) {
    throw error;
  }

  return response.getIn(['data', 'workspaceAddSObjects', 'batchId']);
}
