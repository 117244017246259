import { motion } from 'framer-motion';
import cx from 'classnames';
import {
  cloneElement,
  FC,
  ReactElement,
  useRef,
  useState,
  useEffect,
  HTMLProps,
} from 'react';

import { Portal } from './Portal';
import Text from './Text';

type TooltipProps = {
  content?: string | ReactElement;
  onTooltipToggle?: (showTooltip: boolean) => void;
  hide?: boolean;
  delay?: number;
  id?: string;
  className?: HTMLProps<HTMLElement>['className'];
};

type InnerTooltipProps = {
  content: string | ReactElement;
  childLeft?: number;
  childWidth?: number;
  childTop?: number;
  delay?: number;
  onTooltipToggle?: (showTooltip: boolean) => void;
  showTooltip: boolean;
};

const InnerTooltip: FC<InnerTooltipProps> = ({
  content,
  childLeft,
  childWidth,
  childTop,
  delay = 200,
  showTooltip,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [coords, setCoords] = useState({ left: 0, top: 0 });
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  function isText(data: unknown): data is string {
    return typeof data === 'string';
  }

  const ignoreResizeError = (error: ErrorEvent): void => {
    if (
      error.message ===
      'ResizeObserver loop completed with undelivered notifications.'
    ) {
      // prevent React's listener from firing
      error.stopImmediatePropagation();
      // prevent the browser's console error message
      error.preventDefault();
    }
  };

  useEffect(() => {
    const tooltipObserver = new ResizeObserver(() => {
      const boundingClientRect = tooltipRef.current?.getBoundingClientRect();
      if (tooltipRef.current?.clientWidth && boundingClientRect) {
        setWidth(tooltipRef.current.clientWidth);
      }
      if (tooltipRef.current?.clientHeight && boundingClientRect) {
        setHeight(tooltipRef.current.clientHeight + 8);
      }
    });
    if (tooltipRef.current) {
      tooltipObserver.observe(tooltipRef.current);
      window.addEventListener('error', ignoreResizeError);
    }

    return () => {
      tooltipObserver.disconnect();
    };
  }, [tooltipRef, childWidth, childTop]);

  useEffect(() => {
    if (
      width > 0 &&
      height > 0 &&
      typeof childLeft !== 'undefined' &&
      typeof childTop !== 'undefined' &&
      childWidth
    ) {
      const left = childLeft - (width - childWidth) / 2;
      setCoords({
        left: left > 0 ? left : 10,
        top: childTop - height,
      });
    }
  }, [width, height, childLeft, childWidth, childTop]);

  if (!showTooltip) return null;

  return (
    <motion.div
      className={cx(
        'tooltip bg-white text-black text-sm px-2 py-1 rounded absolute border-solid border-gray4 shadow-md',
      )}
      ref={tooltipRef}
      style={{
        top: coords.top,
        left: coords.left,
        maxWidth: 400,
        fontFamily: 'Open Sans',
        zIndex: 1000001,
      }}
      initial={{
        opacity: 0,
        y: 10,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        duration: 0.1,
        delay: delay / 1000,
      }}
    >
      {isText(content) ? (
        <Text variant="metadata">{content}</Text>
      ) : (
        <div>{content}</div>
      )}
    </motion.div>
  );
};

const Tooltip: FC<TooltipProps> = ({
  content,
  hide,
  children,
  delay = 200,
  id,
  className = '',
}) => {
  const childRef = useRef<HTMLDivElement>(null);

  const boundingClientRect = childRef.current?.getBoundingClientRect();
  const [showTooltip, setShowTooltip] = useState(false);

  if (!content) return <>{children}</>;

  return (
    <div
      className={cx('relative', className)}
      onMouseEnter={e => {
        if (!hide) {
          setShowTooltip(true);
        }
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      id={id}
    >
      {/* render child with ref */}
      {cloneElement((<div>{children}</div>) as ReactElement, { ref: childRef })}
      <Portal>
        <InnerTooltip
          childLeft={boundingClientRect?.left}
          childTop={boundingClientRect?.top}
          childWidth={boundingClientRect?.width}
          content={content}
          showTooltip={showTooltip}
        />
      </Portal>
    </div>
  );
};
export default Tooltip;
