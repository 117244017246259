import { createActions, keyMirror } from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SHARED: {
    OMNIBAR: keyMirror([
      'SET_OMNIBAR_OPEN',
      'OPEN_OMNIBAR',
      'SET_ACTIVE_ACCOUNT_SFDC_ID',
    ]),
  },
}).SHARED.OMNIBAR;

/**
 *
 * @param {string} sfdcId
 * @param {string} phoneNumber
 * @return {object}
 */
export function setOmnibarOpen(payload) {
  return {
    type: actionTypes.SET_OMNIBAR_OPEN,
    payload,
  };
}

export function openOmnibar() {
  return {
    type: actionTypes.OPEN_OMNIBAR,
  };
}

export function setActiveAccountSfdcId({ sfdcId }) {
  return {
    type: actionTypes.SET_ACTIVE_ACCOUNT_SFDC_ID,
    payload: { sfdcId },
  };
}
