import {
  Dialog,
  actions,
  Loader,
  PaginationButtons,
  selectors,
} from '@groove-labs/groove-ui';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import UserTableRow from 'Modules/App/containers/LoginAsDialog/UserTableRow';
import UserTableHead from 'Modules/App/containers/LoginAsDialog/UserTableHead';
import emptyStateSvg from 'Modules/App/containers/LoginAsDialog/empty-state.svg';
import emptyStateGroundSvg from 'Modules/App/containers/LoginAsDialog/empty-state-ground.svg';
import {
  LOGIN_AS_DIALOG_UI_KEY_PATH,
  LOGIN_AS_USER_LIST_LIMIT,
} from 'Modules/App/constants';
import {
  getLoginAsPage,
  getLoginAsErrorMessage,
  getLoginAsSearchUsersInProgress,
  getLoginAsTotal,
  getLoginAsSearchQuery,
  getLoginAsUsers,
} from 'Modules/App/selectors';
import {
  loginAsSearchUsersBegin,
  loginAsUserSuccess,
} from 'Modules/App/actions';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

const { setProperty } = actions.ui;
const { getProperty } = selectors.ui;

const styles = theme => ({
  root: {
    paddingBottom: 0,
  },
  dialogPaper: {
    padding: 0,
  },
  emptyStateSvg: {
    backgroundImage: `url(${emptyStateSvg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    height: 204,
  },
  emptyStateGroundSvg: {
    backgroundImage: `url(${emptyStateGroundSvg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    height: 25,
  },
  customDialogSize: {
    minWidth: 512,
    padding: 0,
  },
  queryInput: {
    position: 'relative',
  },
  paddingWrapper: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  loader: {
    position: 'absolute',
    width: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
    right: theme.spacing.unit,
    left: 'auto',
    top: 0,
  },
  pageNavContainer: {
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
  },
  pageNav: {
    margin: 'auto',
  },
  searchInput: {
    width: '100%',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogContent: {
    padding: theme.spacing.unit * 2,
    paddingTop: 0,
    minHeight: 300,
  },
  closeIcon: {
    marginRight: theme.spacing.unit,
  },
});

@withStyles(styles)
@connect(
  state => ({
    page: getLoginAsPage(state),
    errorMessage: getLoginAsErrorMessage(state),
    dialogOpen: getProperty(state, LOGIN_AS_DIALOG_UI_KEY_PATH, false),
    searchQuery: getLoginAsSearchQuery(state),
    searchUsersInProgress: getLoginAsSearchUsersInProgress(state),
    total: getLoginAsTotal(state),
    users: getLoginAsUsers(state),
  }),
  {
    loginAsSearchUsersBegin,
    loginAsUserSuccess,
    setProperty,
  }
)
export default class LoginAsDialog extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string.isRequired,
      dialogPaper: PropTypes.string.isRequired,
      emptyStateSvg: PropTypes.string.isRequired,
      emptyStateGroundSvg: PropTypes.string.isRequired,
      customDialogSize: PropTypes.string.isRequired,
      queryInput: PropTypes.string.isRequired,
      paddingWrapper: PropTypes.string.isRequired,
      loader: PropTypes.string.isRequired,
      pageNavContainer: PropTypes.string.isRequired,
      pageNav: PropTypes.string.isRequired,
      searchInput: PropTypes.string.isRequired,
      dialogHeader: PropTypes.string.isRequired,
      dialogContent: PropTypes.string.isRequired,
      closeIcon: PropTypes.string.isRequired,
    }).isRequired,
    page: PropTypes.number.isRequired,
    errorMessage: PropTypes.string,
    loginAsSearchUsersBegin: PropTypes.func.isRequired,
    loginAsUserSuccess: PropTypes.func.isRequired,
    setProperty: PropTypes.func.isRequired,
    dialogOpen: PropTypes.bool.isRequired,
    searchQuery: PropTypes.string,
    searchUsersInProgress: PropTypes.bool.isRequired,
    total: PropTypes.number,
    users: ImmutablePropTypes.list,
  };

  static defaultProps = {
    errorMessage: null,
    searchQuery: '',
    total: 0,
    users: null,
  };

  handleChange = e => {
    const { loginAsSearchUsersBegin } = this.props;

    loginAsSearchUsersBegin({
      searchQuery: e.target.value,
      page: 1,
    });
  };

  closeDialog = () => {
    const { setProperty } = this.props;

    setProperty({
      uiKeyPath: LOGIN_AS_DIALOG_UI_KEY_PATH,
      data: false,
    });
  };

  handleOnExited = () => {
    // success action clear the dialog state
    this.props.loginAsUserSuccess();
  };

  moveToSearchResultPage = page => {
    const { loginAsSearchUsersBegin, searchQuery } = this.props;

    loginAsSearchUsersBegin({
      searchQuery,
      page,
      skipDelay: true,
    });
  };

  renderErrorMessage = () => {
    const { classes, errorMessage } = this.props;

    return (
      <div className={classes.paddingWrapper}>
        <Typography color="error">
          {errorMessage}
          &nbsp;
        </Typography>
      </div>
    );
  };

  renderEmptyState() {
    const { classes, users } = this.props;

    if (users && users.size) {
      return null;
    }

    return (
      <div>
        <div className={classes.emptyStateSvg} />
        <div className={classes.emptyStateGroundSvg} />
      </div>
    );
  }

  renderUsersList() {
    const { classes, searchQuery, total, users } = this.props;

    if (!users) {
      return null;
    }

    if (users && total === 0) {
      return (
        <div className={classes.paddingWrapper}>
          <Typography variant="subtitle2">
            {`No matching users found for "${searchQuery}" query`}
          </Typography>
        </div>
      );
    }

    return (
      <Table>
        <UserTableHead />
        <TableBody>
          {users.map(user => (
            <UserTableRow key={user.get('id')} user={user} />
          ))}
        </TableBody>
      </Table>
    );
  }

  renderPageNav = () => {
    const { classes, page, total } = this.props;

    return (
      <div className={classes.paddingWrapper}>
        <div className={classes.pageNavContainer}>
          <PaginationButtons
            classes={{ root: classes.pageNav }}
            page={page}
            perPage={LOGIN_AS_USER_LIST_LIMIT}
            totalCount={total}
            onRequestPageChange={this.moveToSearchResultPage}
          />
        </div>
      </div>
    );
  };

  renderLoader() {
    const { classes } = this.props;

    return (
      <Loader
        classes={{ transparentRoot: classes.loader }}
        size={24}
        transparentBackground
      />
    );
  }

  render() {
    const { classes, dialogOpen, searchQuery, searchUsersInProgress, total } =
      this.props;

    return (
      <Dialog
        isOpenUiKeyPath={LOGIN_AS_DIALOG_UI_KEY_PATH}
        customSizeClass={classes.customDialogSize}
        classes={{
          dialogPaper: classes.dialogPaper,
        }}
        onExited={this.handleOnExited}
      >
        <div className={classes.dialogHeader}>
          <DialogTitle>Login as another user</DialogTitle>
          <IconButton className={classes.closeIcon} onClick={this.closeDialog}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.paddingWrapper}>
            <div className={classes.queryInput}>
              <TextField
                key={dialogOpen}
                autoFocus
                classes={{ root: classes.searchInput }}
                defaultValue={searchQuery}
                onChange={this.handleChange}
                placeholder="Enter user name, email or ID"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {searchUsersInProgress && this.renderLoader()}
            </div>
          </div>
          {this.renderErrorMessage()}
          {this.renderUsersList()}
          {this.renderEmptyState()}
          {total > LOGIN_AS_USER_LIST_LIMIT && this.renderPageNav()}
        </DialogContent>
      </Dialog>
    );
  }
}
