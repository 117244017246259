import { ActionType } from '@groove/api/gateway/v1/actions';
import { Tooltip } from '@groove-labs/groove-ui';
import { makeStyles } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { CLARI_COLORS } from 'theme-constants';
import {
  READABLE_NAME_LOOK_UP,
  ACTION_TYPE_ICONS,
} from 'Modules/ActionsRewrite/components/customCells/ActionType/ActionTypeConstants';
import HighPriorityIcon from 'Modules/ActionsRewrite/components/customCells/ActionType/Icons/highPriorityIcon';
import MediumPriorityIcon from 'Modules/ActionsRewrite/components/customCells/ActionType/Icons/mediumPriorityIcon';
import LowPriorityIcon from 'Modules/ActionsRewrite/components/customCells/ActionType/Icons/lowPriorityIcon';

export const useStyles = makeStyles(_theme => ({
  stepTypeIcon: {
    display: 'flex',
    width: '70%',
    height: '70%',
  },
  stepIconRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: CLARI_COLORS['clari-blue/600'],
    color: 'white',
    fill: 'white',
    width: 28,
    height: 28,
    borderRadius: '50%',
  },
  iconLabelContainer: {
    position: 'relative',
    justifyContent: 'top',
  },
  priorityIcon: {
    position: 'absolute',
    right: -4,
    top: -4,
  },
}));

type IconFactoryParams = {
  type: ActionType;
  iconStyle?: { backgroundColor: string };
};
export const IconFactory = ({ type, iconStyle }: IconFactoryParams) => {
  const classes = useStyles();

  const TypeIcon = ACTION_TYPE_ICONS[type];
  return (
    <Icon className={classes.stepIconRoot} style={iconStyle}>
      <TypeIcon className={classes.stepTypeIcon} style={iconStyle} />
    </Icon>
  );
};

const PriorityLabel = ({ priority, className = '' }) => {
  switch (priority) {
    case 'high':
      return <HighPriorityIcon className={className} />;
    // Medium
    case 'normal':
      return <MediumPriorityIcon className={className} />;
    case 'low':
      return <LowPriorityIcon className={className} />;
    default:
      return null;
  }
};

type LabeledIconParams = IconFactoryParams & {
  priority?: string;
};

export const LabeledIcon = ({
  type,
  priority,
  iconStyle,
}: LabeledIconParams) => {
  const classes = useStyles();
  const iconName = READABLE_NAME_LOOK_UP[type];
  const tooltipMessage = priority
    ? `${iconName} with ${priority.toLowerCase()} priority`
    : iconName;

  return (
    <Tooltip title={tooltipMessage}>
      <div className={classes.iconLabelContainer}>
        <IconFactory type={type} iconStyle={iconStyle} />
        <PriorityLabel priority={priority} className={classes.priorityIcon} />
      </div>
    </Tooltip>
  );
};

export default LabeledIcon;
