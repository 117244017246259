import { call, spawn, put, select } from 'redux-saga/effects';
import { setContentLoading } from 'Modules/Shared/actions/app';
import { refresh } from 'GrooveHTTPClient/salesforceMeta';
import { addJob } from 'Modules/Shared/actions/batchJobs';
import { watchJob } from 'Modules/Shared/sagas/batchJobs';
import { history } from 'Utils/history';
import { getPreviousPath } from 'Modules/App/selectors';
import { logError } from 'Modules/Shared/actions/errors';

function* doRefreshSalesforceMeta() {
  yield put(setContentLoading(false));

  let response;
  try {
    response = yield call(refresh);
  } catch (error) {
    // since we run this in a separate saga stack, we need to explicitly
    // log the error here if something goes wrong because it will not bubble
    // up the normal way.
    yield put(logError({ error }));
  }

  const jobId = response.data.batchId;

  yield put(addJob({ id: jobId, name: 'Refresh Salesforce Meta' }));
  yield* watchJob(jobId);

  const previousPath = (yield select(getPreviousPath)) || '/';

  yield call(history.push, previousPath);
}

export default function* refreshSalesforceMeta() {
  // it's important to spawn this so that the router resolution completes
  yield spawn(doRefreshSalesforceMeta);
}
