import { select, call, put, spawn } from 'redux-saga/effects';
import moment from 'moment-timezone';

import { getLastCheckedAt } from 'Modules/Shared/selectors/users/health';
import {
  setLastCheckedAt,
  setItemHealth,
} from 'Modules/Shared/actions/users/health';
import { logErrorToSentry } from 'Modules/Shared/sagas/errors';
import { SERVICE_CONNECTION_TYPES } from 'Modules/Troubleshooting/constants';
import {
  grooveLicenseHealthCheck,
  sfdcTokenHealthCheck,
  oauthTokenTokenHealthCheck,
  sfdcMetaHealthCheck,
  sfdcApiAccess,
} from 'GrooveHTTPClient/troubleshooting';

const HEALTH_CHECK_INTERVAL_IN_MINUTES = 10;
export const httpClientMethods = {
  [SERVICE_CONNECTION_TYPES.oauthToken]: oauthTokenTokenHealthCheck,
  [SERVICE_CONNECTION_TYPES.sfdcToken]: sfdcTokenHealthCheck,
  [SERVICE_CONNECTION_TYPES.sfdcApiAccess]: sfdcApiAccess,
  [SERVICE_CONNECTION_TYPES.grooveLicense]: grooveLicenseHealthCheck,
  [SERVICE_CONNECTION_TYPES.sfdcMeta]: sfdcMetaHealthCheck,
};

export function* checkServiceConnection({ httpClientMethod }) {
  const response = yield call(httpClientMethod);
  return response.getIn(['message', 'valid']);
}

export function* checkMinimumHealthItems() {
  let unhealthyItemCount = 0;

  for (const healthCheckItem of Object.keys(httpClientMethods)) {
    if (unhealthyItemCount > 1) return;

    const httpClientMethod = httpClientMethods[healthCheckItem];
    const isValid = yield call(checkServiceConnection, { httpClientMethod });

    if (!isValid) {
      unhealthyItemCount += 1;
    }

    yield put(
      setItemHealth({
        key: healthCheckItem,
        status: isValid,
      })
    );
  }
}

export function* handleHealthChecks() {
  try {
    const lastCheckedAt = yield select(getLastCheckedAt);
    const healthCheckIntervalElapsed =
      lastCheckedAt &&
      moment().diff(lastCheckedAt, 'minutes') >
        HEALTH_CHECK_INTERVAL_IN_MINUTES;
    const healthCheckRequired = !lastCheckedAt || healthCheckIntervalElapsed;

    if (healthCheckRequired) {
      yield call(checkMinimumHealthItems);
      const dateTimeRoundedToMinutes = moment().startOf('minute');
      yield put(setLastCheckedAt({ dateTime: dateTimeRoundedToMinutes }));
    }
  } catch (e) {
    logErrorToSentry(e);
  }
}

function* runHealthChecks() {
  yield spawn(handleHealthChecks);
}

export default runHealthChecks;
