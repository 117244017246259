import { FC, useCallback, useRef, useState, useEffect } from 'react';
import { KeyPadIcon } from '@groove/ui/Components/BoogieIcon';
import useDialerStore from '@groove/dialer-components/hooks/useStore';
import useHandleOutsideClicks from '@groove/ui/helpers/useHandleOutsideClicks';
import DropDownFrame from '@groove/ui/Components/DropDownFrame';
import Input from '@groove/ui/Components/Input';

import DialerBarButton from './DialerBarButton';
import SingleKeyPadButton, {
  SingleKeyPadButtonRef,
} from './SingleKeyPadButton';

const primaryValues = [
  ['1', '2', '3'],
  ['4', '5', '6'],
  ['7', '8', '9'],
  ['*', '0', '#'],
];

const secondaryValues = [
  [undefined, 'abc', 'def'],
  ['ghi', 'jkl', 'mno'],
  ['pqrs', 'tuv', 'wxyz'],
];

type ButtonRefObject = {
  current: Map<string, SingleKeyPadButtonRef>;
};

const KeyPad: FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef(null) as unknown as ButtonRefObject;

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const callState = useDialerStore(store => store.callState);

  useHandleOutsideClicks(ref, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (callState === 'connecting') setInputValue('');
    if (callState === 'not-connected') setIsOpen(false);
  }, [callState]);

  const onNumberSelect = useCallback(
    (digit: string) => {
      setInputValue(`${inputValue}${digit}`);
      useDialerStore.getState().getActiveConnection()?.sendDigits(digit);
    },
    [inputValue],
  );

  const getRefMap = useCallback((): Map<string, SingleKeyPadButtonRef> => {
    if (!buttonRef.current) {
      // This is how react's own doc does it, I wanted to use useState but it wouldn't work unfortunately
      // https://react.dev/learn/manipulating-the-dom-with-refs#how-to-manage-a-list-of-refs-using-a-ref-callback
      buttonRef.current = new Map();
    }
    return buttonRef.current;
  }, [buttonRef]);

  let tooltip = 'Click to open keypad';

  if (callState === 'not-connected') {
    tooltip = 'Please make a call to open keypad';
  }

  return (
    <div className="relative" ref={ref}>
      <DropDownFrame
        showDropDown={isOpen}
        width={180}
        direction="topRight"
        childrenLocation="below"
      >
        <div className=" px-[12px] pt-[12px] pb-[4px]">
          <Input
            onKeyDown={event =>
              getRefMap().forEach(node => node.handleClick(event.key))
            }
            variant="underlined"
            value={inputValue}
            className="px-[2px] py-[4px] !text-center !text-body pb-[12px]"
          />
          <div className="flex flex-col">
            {primaryValues.map((row, i) => {
              return (
                <div
                  className="flex flex-row flex-1 py-[4px]"
                  key={`${i.toString()}-keypad-row`}
                >
                  {row.map((value, k) => (
                    <SingleKeyPadButton
                      onClick={onNumberSelect}
                      ref={node => {
                        if (node) {
                          getRefMap().set(value, node);
                        } else {
                          getRefMap().delete(value);
                        }
                      }}
                      value={value}
                      secondaryValue={secondaryValues[i]?.[k]}
                      key={value}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </DropDownFrame>
      <DialerBarButton
        label="Keypad"
        onClick={() => setIsOpen(!isOpen)}
        tooltip={tooltip}
        selected={isOpen}
        disabled={callState === 'not-connected'}
      >
        <KeyPadIcon className="w-[20px]" />
      </DialerBarButton>
    </div>
  );
};

export default KeyPad;
