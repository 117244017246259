import { FC, useEffect } from 'react';
import useTwilioDevice from '@groove/dialer-components/hooks/useTwilioDevice';
import useSDKTokenRefresh from '@groove/dialer-components/hooks/useSDKTokenRefresh';
import useSubscription from '@groove/dialer-components/hooks/useSubscription';
import useDialerStore from '@groove/dialer-components/hooks/useStore';

import useStore from '../../store/useStore';

const DialerInActionLoader: FC = () => {
  useTwilioDevice({ isDialerInAction: true });
  useSDKTokenRefresh();
  useSubscription();

  useEffect(() => {
    console.info('Mounting Dialer in Action');
    // Clear out the dialer state whe closing and end the call
    return () => {
      useStore.getState().dialerState.mediaRecorder?.stop();
      useDialerStore.getState().endCall(false);
      console.info('Unmounting Dialer in Action');
    };
  }, []);

  return null;
};

export default DialerInActionLoader;
