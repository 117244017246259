import { createSelector } from 'reselect';
import { selectors } from '@groove-labs/groove-ui';
import {
  SAVED_SEARCH_SELECT_FIELD_ID,
  OBJECT_NAME_FIELD_ID,
} from 'Modules/PeopleImportDialog/submodules/advancedSearch/constants';

const { groups, makeGetSelectedData } = selectors.form;

const ROOT_KEY_PATH = ['PeopleImportDialog', 'advancedSearch'];

export const filters = state => state.getIn([...ROOT_KEY_PATH, 'filters']);

// saved search selectors
export const savedSearchEnabled = state =>
  state.getIn([...ROOT_KEY_PATH, 'savedSearch', 'enabled']);
export const selectedSavedSearch = makeGetSelectedData(
  SAVED_SEARCH_SELECT_FIELD_ID
);

export const makeGetFilter = groupId => {
  return createSelector(filters, filters => {
    return filters.get(groupId, {});
  });
};

export const makeGetFilterGroups = () => {
  return createSelector(groups, filters, (groups, filters) => {
    const filterGroupIds = filters.keySeq().toList();
    return groups.filter((_, groupId) => filterGroupIds.includes(groupId));
  });
};

export const makeGetIsFiltersValid = () => {
  return createSelector(makeGetFilterGroups(), filterGroups => {
    if (filterGroups.isEmpty()) {
      return false;
    }

    return filterGroups.every(group => {
      return group.get('fields').every(field => field.get('isValid'));
    });
  });
};

export const makeGetScopeInitialValue = () => {
  return createSelector(selectedSavedSearch, selectedSavedSearch => {
    if (!selectedSavedSearch) {
      return false;
    }

    return selectedSavedSearch.getIn(['definition', 'scope']) === 'mine';
  });
};

// Add filter should be enabled if:
// 1. There are no filters currently.
// 2. There are filters and all of them have a selected sfdc field.
export const makeGetShouldEnableAddFilter = () => {
  return createSelector(makeGetFilterGroups(), filterGroups => {
    if (filterGroups.isEmpty()) {
      return true;
    }

    return filterGroups.every(group => {
      return group.getIn(['fields', OBJECT_NAME_FIELD_ID]);
    });
  });
};
