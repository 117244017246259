import { isFunction } from 'lodash-es';

export function showNewMessage() {
  if (window.Intercom && isFunction(window.Intercom)) {
    window.Intercom('showNewMessage');
  }
}

export function hideIntercomWidget() {
  if (window.Intercom && isFunction(window.Intercom)) {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }
}
