import { StickyFilters } from '../../user';

import { FormField, UserPicklistValues } from './actionCompose';
import getClient from './getClient';

export type ApiOrgSettings = {
  add_unsubscribe_link_on_send_with_groove: boolean;
  admin_users_can_import_on_behalf_of: boolean;
  agent_users_can_import_on_behalf_of: boolean;
  play_effectiveness_enabled: boolean;
  allow_call_feedback: boolean;
  analyst_users_can_import_on_behalf_of: boolean;
  child_campaign_enforcing_option_enabled: boolean;
  contact_auto_capture_logging_enabled: boolean;
  conversation_intelligence_sharing: boolean;
  direct_attachment_enabled: boolean;
  disable_add_licences: boolean;
  disable_deletion_of_bounced_email_tasks: boolean;
  enable_person_search_indexing: boolean;
  flows_task_fields_required: boolean;
  manage_on_behalf_of_enabled: boolean;
  master_flow_creation_enabled: boolean;
  override_call_recording_compliance: boolean;
  call_recording_override_setting: 'off' | 'optional' | 'force';
  push_engagement_stats_to_sfdc: boolean;
  require_additional_call_log_fields: boolean;
  seismic_enabled: boolean;
  wingman_enabled: boolean;
  sendoso_enabled: boolean;
  vidyard_enabled: boolean;
  show_vidyard_link: boolean;
  skip_license_assignment_admin_check: boolean;
  synchronous_report_autoimport: boolean;
  team_analytics_visible: boolean;
  team_lead_users_can_import_on_behalf_of: boolean;
  use_aggregated_flow_analytics: boolean;
  voicemail_drop_disabled: boolean;
  filter_team_lead_from_team_analytics: boolean;
  team_lead_can_manage_team: boolean;
  suggested_actions_enabled: boolean;
  email_effectiveness_enabled: boolean;
  smart_email_scheduling_enabled: boolean;
  perform_email_sync_by_id: boolean;
  two_party_consent_disabled: boolean;
  enable_google_calendar_recurrence_rule_fix: boolean;
  google_calendar_recurring_events_fix_enabled: boolean;
  dialer_use_groove_restforce_client: boolean;
  accelerated_flow_auto_import_enabled: boolean;
  sfdc_groove_license_sync_enabled: boolean;
  // TODO: reenable when someone needs it
  // user_hierarchy_for_analytics: ;
  // user_hierarchy_field: ;
  use_telnyx_as_voip_provider: boolean;
  flow_import_related_contacts_per_account_limit: number;
  enable_email_bounce_codes_to_sfdc: boolean;
  org_linkedin_client_id: string;
  third_party_dialer_enabled: boolean;
  copilot_enabled: boolean;
  activity_result_displayed_for_calls: boolean;
  activity_result_is_mandatory_for_calls: boolean;
  disable_email_opt_out_safeguard: boolean;
  contact_opt_out_field: string;
  contact_call_opt_out_field: string;
  contact_sms_opt_out_field: string;
  lead_opt_out_field: string;
  lead_call_opt_out_field: string;
  lead_sms_opt_out_field: string;
  show_sf_task_type_picklist: boolean;
  edit_sf_task_type_picklist: boolean;
  sf_task_type_mapping: Record<string, string>;
};

export type emailAlias = {
  name: string | null;
  isDefault: boolean | null;
  email: string;
}[];

export type apiEmailAlias = {
  data?: emailAlias;
  message?: string;
};

export type ApiUser = {
  id: number;
  name: string;
  email: string;
  sfdc_org_id: string;
  sfdc_user_id: string;
  sfdc_instance_url: string | null;
  provider: 'google_oauth2' | 'microsoft_graph_auth';
  settings: {
    persona: string | null;
    sticky_filters?: StickyFilters;
    sticky_filters_omnibar?: StickyFilters;
    chrome_extension_onboarding_completed?: boolean;
    gmail_inapp_onboarding_completed?: boolean;
    gmail_card_number?: number;
  } | null;
  flow_enabled: boolean;
  dialer_enabled: boolean;
  sms_enabled: boolean;
  phone_number: string | null;
  formatted_phone_number: string | null;
  profile_image_url: string | null;
  deactivated_at?: string | null;
  meeting_custom_link: string | null;
  call_recording_setting: 'REQUIRED' | 'DISABLED' | 'OPTIONAL' | null;
  track_emails_by_default: boolean;
  track_link_clicks_by_default: boolean;
  bridge_calling_enabled: boolean;
  bridge_calling_number: boolean;
  time_zone?: string | null;
};

export type SearchUser = {
  id: number;
  email: string;
  name: string;
  sfdc_org_id: string;
  sfdc_user_id: string;
  meeting_custom_link: string | null;
};

export type ApiSearchUsers = {
  data: SearchUser[];
};

export type ScheduleForItem = {
  email: string;
  id: number;
  meeting_custom_link: string;
  name: string;
  type: 'user' | 'group';
};

export type MeetingTemplateItem = {
  custom_link: string | null;
  description: string | null;
  id: number;
  name: string | null;
};

export type SearchUsersOptions = {
  limit?: number;
  offset?: number;
};

export type ApiSearchUser = {
  data: SearchUser;
};

export type ApiFetchEmailFooter = {
  email_footer?: string | null;
};

export type FetchFooterParams = {
  flow_id?: number | null;
};

export type FetchFooterMergedParams = {
  who_id?: string | null;
  what_id?: string | null;
};

export type ApiMergeFields = {
  [key: string]: {
    name: string;
    label: string;
  }[];
};

export const getCurrentUser = (): Promise<ApiUser> =>
  getClient().get('user').json();

export const getOrgSettings = (): Promise<ApiOrgSettings> =>
  getClient().get('user/org_settings').json();

export const getEmailAliases = (): Promise<apiEmailAlias> =>
  getClient().get('user/email_aliases').json();

export const searchUsers = (
  searchQuery: string,
  options: SearchUsersOptions = {},
): Promise<ApiSearchUsers> =>
  getClient()
    .get('users', {
      searchParams: { search_query: searchQuery, ...options },
    })
    .json();

export const scheduleFor = (
  searchQuery: string,
  options: SearchUsersOptions = {},
): Promise<ScheduleForItem[]> =>
  getClient()
    .get('user/schedule_for', {
      searchParams: { search_query: searchQuery, ...options },
    })
    .json();

export const meetingRequestTemplates = (
  userId: string | number,
): Promise<MeetingTemplateItem[]> =>
  getClient()
    .get('user/meeting_request_templates', {
      searchParams: { user_id: userId },
    })
    .json();

export const getSearchUser = (id: number | string): Promise<ApiSearchUser> =>
  getClient().get(`users/${id}`).json();

export const fetchEmailFooter = ({
  flow_id,
}: FetchFooterParams): Promise<ApiFetchEmailFooter> => {
  const request = flow_id
    ? getClient().get('user/email_footers', { searchParams: { flow_id } })
    : getClient().get('user/email_footers');

  return request.json();
};

export const fetchEmailFooterMerged = ({
  who_id,
  what_id,
}: FetchFooterMergedParams): Promise<ApiFetchEmailFooter> => {
  const request = getClient().post('user/email_footers_merged', {
    json: { who_id, what_id },
  });

  return request.json();
};

export const userMergeFields = (): Promise<ApiMergeFields> => {
  return getClient().get('user/available_merge_fields').json();
};

export type ApiAdditionalLoggingFields = {
  data: FormField[];
};
export const userAdditionalLoggingFields =
  (): Promise<ApiAdditionalLoggingFields> => {
    return getClient().get('user/additional_logging_fields').json();
  };

export type ApiAvailablePicklistValues = UserPicklistValues;
export const availablePicklistValues = (
  recordType: string,
): Promise<ApiAvailablePicklistValues> => {
  return getClient()
    .get('user/available_picklist_values', {
      searchParams: { record_type: recordType },
    })
    .json();
};

export type ValueProposition = {
  id: number;
  name: string;
  description: string;
};
export type ApiAvailableValuePropositions = ValueProposition[];
export const availableValuePropositions =
  (): Promise<ApiAvailableValuePropositions> => {
    return getClient().get('user/available_value_propositions').json();
  };

export type LaunchDarklyUser = {
  key: string;
  name: string;
  email: string;
  custom: {
    groups: string[];
    organization_created_at: number;
  };
};

export const getLaunchDarklyUser = (): Promise<LaunchDarklyUser> => {
  return getClient().get('user/launch_darkly_user').json();
};
