import getClient from './getClient';
import { Template, TemplateType } from './template';

export type TemplateFolder = {
  id: number;
  name: string;
  is_editable: boolean;
  is_shared_with_organization: boolean;
  is_starred: boolean;
  is_shared: boolean;
  is_owned_by_current_user: boolean;
  user: {
    id: number;
    name: string;
  } | null;
  templates: Template[];
};

export type ApiTemplateFolders = {
  data: TemplateFolder[];
};

export type TemplateFoldersSearchParams = {
  search_term?: string;
  template_type?: TemplateType;
  template_subtype?: string;
};

export const templateFoldersGet = (
  include_templates = true,
): Promise<ApiTemplateFolders> => {
  return getClient()
    .post('template_folders/get', {
      json: {
        variables: { include_templates },
      },
    })
    .json();
};

export const templateFoldersSearch = ({
  search_term,
  template_type,
  template_subtype = '',
}: TemplateFoldersSearchParams): Promise<ApiTemplateFolders> => {
  return getClient()
    .post('template_folders/search', {
      json: {
        variables: { search_term, template_type, template_subtype },
      },
    })
    .json();
};

export const templateFoldersFavorites = (
  templateType: TemplateType,
  templateSubType = '',
): Promise<ApiTemplateFolders> => {
  return getClient()
    .post('template_folders/favorites', {
      json: {
        variables: {
          template_type: templateType,
          template_subtype: templateSubType,
        },
      },
    })
    .json();
};
