import { takeLatest, takeEvery } from 'redux-saga/effects';
import handleLabelInstances from 'Modules/Shared/sagas/labels/handleLabelInstances';
import removeLabelInstance from 'Modules/Shared/sagas/labels/labelInstances/remove';
import { actionTypes as labelInstanceActionTypes } from 'Modules/Shared/actions/labelInstances';

function* watchUpdateLabelInstances() {
  yield takeLatest(labelInstanceActionTypes.UPDATE, handleLabelInstances);
}

function* watchRemoveLabelInstance() {
  yield takeEvery(labelInstanceActionTypes.REMOVE, removeLabelInstance);
}

export { watchUpdateLabelInstances, watchRemoveLabelInstance };
