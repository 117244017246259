import { POST_MESSAGE_OUTLOOK_SOURCE } from 'Modules/ActionCompose/constants';

const params = new URLSearchParams(window.location.search);

export default function sendPostMessage({
  windowContext = window.top,
  targetOrigin,
  type,
  source,
  target,
  payload = {},
}) {
  if (!windowContext) return;

  if (params.get('source') === POST_MESSAGE_OUTLOOK_SOURCE) {
    /**
     * to send a message to iframe opened on outlook
     * we needed to use window.parent.postMessage instead
     * of just windowContext.postMessage
     */
    window.parent.postMessage(
      {
        type,
        target,
        payload,
        meta: { target, source },
      },
      '*'
    );

    return;
  }

  windowContext.postMessage(
    {
      type,
      target,
      payload,
      meta: { target, source },
    },
    targetOrigin || window.location.origin
  );
}
