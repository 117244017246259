import { FC, HTMLProps, useState, useRef, ReactElement } from 'react';
import cx from 'classnames';
import { debounce } from 'lodash-es';

import useHandleOutsideClicks from '../helpers/useHandleOutsideClicks';

import Tooltip from './Tooltip';
import { DownChevron } from './BoogieIcon';
import DropDownFrame from './DropDownFrame';
import Item, { DropDownItem, StandardDropDownItem } from './DropDownItem';

export type AdditionMenuItems = {
  text: string | ReactElement;
  onClick: () => void;
  tooltip?: string | ReactElement;
  disabled?: boolean;
  showTooltipText?: boolean;
};
export type ButtonProps = {
  // The type of Button
  variant?: 'primary' | 'secondary' | 'tertiary' | 'icon' | 'danger';
  size?: 'large' | 'normal' | 'small' | 'x-small';
  disabled?: boolean;
  className?: HTMLProps<HTMLElement>['className'];
  onClick?: () => void;
  // This allows the button to show an additional menu items.  Please see ExecutionBar for more examples
  // The button will be spilt if the root `onClick` is not null.
  additionalMenuItems?: AdditionMenuItems[];
  additionalMenuDirection?:
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight';
  // Can be number of pixels or css string
  additionalMenuWidth?: string | number;
  tooltip?: string | ReactElement;
  toggledOn?: boolean;
  hideChevron?: boolean;
  ariaLabel?: string;
  actionName?: string;
};

/**
 * Primary UI component for user interaction
 */
const Button: FC<ButtonProps> = ({
  variant = 'primary',
  size = 'normal',
  children,
  disabled,
  className,
  onClick,
  additionalMenuItems,
  additionalMenuWidth,
  additionalMenuDirection = 'topLeft',
  tooltip,
  toggledOn,
  hideChevron,
  ariaLabel,
  actionName,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [showDropdown, setShowDropdown] = useState(false);

  let variantClassName = 'bg-clari-blue/600';
  let borderClassName = 'border-none';
  let hoverBgClassName = 'hover:bg-clari-blue/700 active:bg-clari-blue/800';
  let textClassName = 'text-white';

  if (variant === 'secondary') {
    variantClassName = 'bg-white text-neutral/900';
    borderClassName =
      'border-solid border border-neutral/200 hover:border-neutral/900';
    hoverBgClassName = 'hover:bg-white active:bg-white';
    textClassName = 'text-neutral/900';
  } else if (variant === 'tertiary' || variant === 'icon') {
    variantClassName = 'bg-transparent';
    hoverBgClassName = 'hover:bg-white active:bg-white hover:text-neutral/900';
    textClassName = 'text-neutral/900';
  } else if (variant === 'danger') {
    variantClassName = 'bg-error/600 text-white';
    hoverBgClassName = 'hover:bg-error/500 active:bg-error/400';
    textClassName = 'text-white';
  }

  if (toggledOn) {
    variantClassName = 'bg-clari-blue/600';
    borderClassName = 'border-none';
    textClassName = 'text-white';
    hoverBgClassName = '';
  }

  if (disabled) {
    borderClassName = '!border-none';
  }

  let mainClassName = 'px-[20px]';
  let iconClassName = 'px-[16px]';
  let heightClassName = 'text-body-lg h-[52px] rounded';

  if (size === 'normal') {
    mainClassName = 'px-[16px]';
    iconClassName = 'px-[8px]';
    heightClassName = 'text-body h-[32px]';
  } else if (size === 'small') {
    mainClassName = 'px-[12px]';
    iconClassName = 'px-[7px]';
    heightClassName = 'text-body-sm h-[30px]';
  } else if (size === 'x-small') {
    mainClassName = 'px-[12px]';
    iconClassName = 'px-[6px]';
    heightClassName = 'text-[7px] h-[19px]';
  }

  if (variant === 'icon') {
    heightClassName += ' rounded-full';
  }

  const handleOnClick = (): void => {
    if (disabled) return;
    if (onClick) onClick();
    if (!onClick) handleMoreClick();
  };

  const handleMoreClick = (): void => {
    if (disabled) return;
    debounce(() => setShowDropdown(!showDropdown), 50)();
  };

  const handleItemClick = (value: StandardDropDownItem): void => {
    setShowDropdown(false);
    additionalMenuItems?.[value.value as number]?.onClick();
  };

  useHandleOutsideClicks(ref, () => {
    setShowDropdown(false);
  });

  const items: DropDownItem[] =
    additionalMenuItems?.map((item, index) => ({
      key: index,
      value: index,
      text: item.text,
      tooltip: item.tooltip,
      disabled: item.disabled,
      showTooltipText: item.showTooltipText,
    })) || [];

  const ButtonRender = (
    <div
      className={cx(
        'font-semibold font-groove rounded overflow-hidden relative box-border flex flex-row',
        borderClassName,
        heightClassName,
      )}
    >
      <div
        onClick={handleOnClick}
        aria-label={ariaLabel}
        data-dd-action-name={actionName || ariaLabel}
        data-splunk-action={actionName || ariaLabel}
        onKeyDown={event => event.key === 'Enter' && handleOnClick()}
        tabIndex={0}
        role="button"
        aria-disabled={disabled}
        className={cx(
          'h-full flex flex-row items-center whitespace-nowrap',
          children && variant !== 'icon' ? mainClassName : iconClassName,
          disabled
            ? 'text-neutral/400 cursor-not-allowed bg-neutral/75'
            : `${variantClassName} ${hoverBgClassName} ${textClassName} cursor-pointer`,
        )}
      >
        {children}
        {additionalMenuItems && !onClick && (
          <DownChevron className={`${textClassName} w-[12px] ml-[6px]`} />
        )}
      </div>
      {additionalMenuItems && onClick && (
        <div
          onClick={handleMoreClick}
          onKeyDown={event => event.key === 'Enter' && handleMoreClick()}
          tabIndex={0}
          role="button"
          className={cx(
            'h-full flex flex-row items-center',
            disabled
              ? 'text-neutral/400 cursor-not-allowed  bg-neutral/75'
              : `${variantClassName} ${hoverBgClassName} ${textClassName} cursor-pointer`,
          )}
        >
          <div className={cx('border-0 !border-r border-solid h-[16px]')} />
          <DownChevron
            className={cx(
              'w-[12px] !box-content',
              iconClassName,
              disabled ? 'text-neutral/400' : textClassName,
            )}
          />
        </div>
      )}
    </div>
  );

  return (
    <div className={cx('relative', className)} ref={ref}>
      {!!items && !!items.length && (
        <DropDownFrame
          itemList={items}
          lineHeight={32}
          showDropDown={showDropdown}
          renderedItem={Item}
          width={additionalMenuWidth}
          direction={additionalMenuDirection}
          itemData={{
            items,
            onClick: handleItemClick,
          }}
        />
      )}
      {tooltip && <Tooltip content={tooltip}>{ButtonRender}</Tooltip>}
      {!tooltip && ButtonRender}
    </div>
  );
};

export default Button;
