import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const LeadIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, 'fill-current')}
  >
    <path d="M1 4.75C1 3.784 1.784 3 2.75 3h10.5c.966 0 1.75.784 1.75 1.75v6.5A1.75 1.75 0 0 1 13.25 13H2.75A1.75 1.75 0 0 1 1 11.25v-6.5zM2.75 4a.75.75 0 0 0-.75.75v6.5c0 .414.336.75.75.75h10.5a.75.75 0 0 0 .75-.75v-6.5a.75.75 0 0 0-.75-.75H2.75zM9.5 6a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM5.261 7.714a1.357 1.357 0 1 0 0-2.714 1.357 1.357 0 0 0 0 2.714zm-1.403.678A.858.858 0 0 0 3 9.25a1.67 1.67 0 0 0 1.265 1.62l.053.014c.62.155 1.267.155 1.886 0l.054-.013a1.67 1.67 0 0 0 1.265-1.62.858.858 0 0 0-.858-.859H3.858z" />
  </svg>
);
