import { ApiGrooveSearch } from '@groove/api/visualforce/grooveSearch';
import { IconButton, Text } from '@fluentui/react';
import RecordMenuItem from '@groove/ui/Components/RecordMenuItem';
import { isContactOrLead } from '@groove/api/helpers/sfdcObjectPrefixes';
import { ApiGrooveContext } from '@groove/api/visualforce/grooveContext';
import { FC } from 'react';
import { useQueryClient } from 'react-query';
import useSalesforceLogFields from '@groove/api/hooks/useSalesforceLogFields';

import transformSearchResult, {
  DisplayableSearchResult,
} from './transformSearchResult';
import transformGrooveViewObject from './transformGrooveViewObject';
import useStore, { SelectedItems } from './useStore';
import AdditionalFieldFactory from './AdditionalFields/AdditionalFieldFactory';

type ReviewProps = {
  selectedItems: SelectedItems;
};

const Review: FC<ReviewProps> = ({ selectedItems }) => {
  const queryClient = useQueryClient();

  const removeTopLevelListItem = useStore(
    store => store.removeTopLevelListItem,
  );

  const additionalFieldsType = useStore(store => store.additionalFieldsType);

  const { data: logFields } = useSalesforceLogFields(
    additionalFieldsType,
    !!additionalFieldsType,
  );

  const additionalFields = useStore(store => store.additionalFields);
  const setAdditionalField = useStore(store => store.setAdditionalField);

  return (
    <div>
      {Object.values(selectedItems).length > 0 && (
        <Text variant="xLarge">
          Selected Items (
          {Object.values(selectedItems).length +
            Object.values(selectedItems).reduce(
              (acc, item) => acc + Object.values(item.subItems || {}).length,
              0,
            )}
          )
        </Text>
      )}
      {/* group who what pairs by primary id */}
      {Object.values(selectedItems).map(item => {
        return (
          <div key={item?.id}>
            <div className="flex items-center">
              <RecordMenuItem
                fullWidth
                showLinkToSalesforce
                id={item.id}
                text={item.text || ''}
                secondaryText={item.secondaryText}
                secondaryTextLabel={
                  (item as DisplayableSearchResult).secondaryTextLabel
                }
                tertiaryText={(item as DisplayableSearchResult).tertiaryText}
                tertiaryTextLabel={
                  (item as DisplayableSearchResult).tertiaryTextLabel
                }
                quarteraryText={
                  (item as DisplayableSearchResult).quarteraryText
                }
                quarteraryTextLabel={
                  (item as DisplayableSearchResult).quarteraryTextLabel
                }
                quinaryText={(item as DisplayableSearchResult).quinaryText}
                quinaryTextLabel={
                  (item as DisplayableSearchResult).quinaryTextLabel
                }
              />
              <IconButton
                iconProps={{ iconName: 'Delete' }}
                ariaLabel={`Remove ${item.text}`}
                onClick={() => {
                  removeTopLevelListItem(item.id);
                }}
              />
            </div>
            {Object.values(item.subItems || [])?.map(selectedSubItem => {
              let subItem: DisplayableSearchResult;
              if (!item) {
                return null;
              }
              if (isContactOrLead(item.id)) {
                const queryData = queryClient.getQueryData<ApiGrooveContext>([
                  'search',
                  item.id,
                ]);
                const what = queryData?.views
                  ?.map(view => view.objects)
                  .flat()
                  .find(item => selectedSubItem.id === item.attributes?.id);

                if (!what?.attributes) {
                  subItem = selectedSubItem as DisplayableSearchResult;
                } else {
                  subItem = transformGrooveViewObject(what);
                }
              } else {
                const queryData = queryClient.getQueryData<ApiGrooveSearch>([
                  'search',
                  item.id,
                ]);
                const who = queryData
                  ?.flat()
                  .find(item => item.Id === selectedSubItem.id);

                if (!who) {
                  subItem = selectedSubItem as DisplayableSearchResult;
                } else {
                  subItem = transformSearchResult(who);
                }
              }

              const { id } = selectedSubItem;

              if (!id) {
                return null;
              }

              return (
                <div key={selectedSubItem.id} className="pl-2">
                  <div className="flex items-center">
                    <RecordMenuItem
                      fullWidth
                      showLinkToSalesforce
                      id={id}
                      text={subItem?.text || ''}
                      secondaryText={item.secondaryText}
                      secondaryTextLabel={
                        (subItem as DisplayableSearchResult).secondaryTextLabel
                      }
                      tertiaryText={
                        (subItem as DisplayableSearchResult).tertiaryText
                      }
                      tertiaryTextLabel={
                        (subItem as DisplayableSearchResult).tertiaryTextLabel
                      }
                      quarteraryText={
                        (subItem as DisplayableSearchResult).quarteraryText
                      }
                      quarteraryTextLabel={
                        (subItem as DisplayableSearchResult).quarteraryTextLabel
                      }
                      quinaryText={
                        (subItem as DisplayableSearchResult).quinaryText
                      }
                      quinaryTextLabel={
                        (subItem as DisplayableSearchResult).quinaryTextLabel
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}

      {logFields?.data.map(field => (
        <div key={field.name}>
          <AdditionalFieldFactory
            additionalField={field}
            value={additionalFields[field.name.toLowerCase()] ?? null}
            onChange={newValue => {
              setAdditionalField(field.name.toLowerCase(), newValue);
            }}
          />
        </div>
      ))}
      <div className="h-[200px]" />
    </div>
  );
};

export default Review;
