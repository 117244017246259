import { Text } from '@fluentui/react';
import React, { FC } from 'react';

import SearchZeroStateIcon from './SearchZeroStateIcon';

const SearchZeroState: FC = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="flex flex-col justify-center mt-5 itesm-center">
        <Text block className="text-xl font-bold">
          Use the search bar above
        </Text>
        <Text block className="text-xl font-bold">
          to search for a record
        </Text>
      </div>
      <SearchZeroStateIcon className="mt-5 ml-4" />
    </div>
  );
};

export default SearchZeroState;
