import LoaderRow from 'Modules/Shared/components/ActionsRouteLoader/LoaderRow';
import shortId from 'shortid';
import { makeStyles } from '@material-ui/core/styles';
import sharedStyles from 'Modules/Actions/containers/Table/styles';

const rows = [...Array(31)].fill(null).map(shortId.generate);
const useStyles = makeStyles(_theme => ({
  table: {
    ...sharedStyles.tableRoot,
    height: 'calc(100vh - 224px)',
    overflow: 'hidden',
    scroll: 'none',
  },
}));

export default function LoadingTable() {
  const classes = useStyles();
  return (
    <div className={classes.table}>
      {rows.map(value => (
        <LoaderRow key={value} />
      ))}
    </div>
  );
}
