import { FC, KeyboardEvent, useEffect, useState } from 'react';
import {
  startOfMonth,
  getDay,
  getMonth,
  getYear,
  subDays,
  addDays,
  getDate,
  addMonths,
  subMonths,
  set,
  addHours,
} from 'date-fns';
import cx from 'classnames';

import Text from './Text';
import { UpArrow } from './BoogieIcon/UpArrow';
import { DownArrow } from './BoogieIcon';

type CalendarType = {
  selectedDate?: Date;
  onDateChange: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
};

const DAYS_OF_WEEK = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const Calendar: FC<CalendarType> = ({
  selectedDate = new Date(),
  onDateChange,
  minDate,
  maxDate,
}) => {
  const [highlightedDate, setHighlightedDate] = useState<Date>(new Date());
  const [dateInFirstCell, setDateInFirstCell] = useState<Date>(new Date());
  const today = new Date();

  const calcDateInFirstCell = (date: Date): void => {
    const monthStart = startOfMonth(date);
    setDateInFirstCell(subDays(monthStart, getDay(monthStart)));
  };

  useEffect(() => {
    setHighlightedDate(selectedDate);
    calcDateInFirstCell(selectedDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const onNextMonth = (): void => {
    calcDateInFirstCell(
      addMonths(dateInFirstCell, getDate(dateInFirstCell) === 1 ? 1 : 2),
    );
  };

  const onPrevMonth = (): void => {
    calcDateInFirstCell(
      subMonths(dateInFirstCell, getDate(dateInFirstCell) === 1 ? 1 : 0),
    );
  };

  const calcIsDisabled = (date: Date): boolean => {
    if (minDate && addHours(minDate, -23).getTime() > date.getTime())
      return true;
    if (maxDate && addHours(maxDate, 23).getTime() < date.getTime())
      return true;
    return false;
  };

  const moveHighlightedDate = (days: number): void => {
    const newDate = addDays(highlightedDate, days);
    if (calcIsDisabled(newDate)) return;
    setHighlightedDate(newDate);
    calcDateInFirstCell(newDate);
  };

  const onDateKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      onDateChange(highlightedDate);
    }
    if (event.key === 'ArrowDown') {
      moveHighlightedDate(7);
    }
    if (event.key === 'ArrowUp') {
      moveHighlightedDate(-7);
    }
    if (event.key === 'ArrowRight') {
      moveHighlightedDate(1);
    }
    if (event.key === 'ArrowLeft') {
      moveHighlightedDate(-1);
    }
  };

  const onDateClick = (date: Date): void =>
    onDateChange(
      set(selectedDate, {
        year: getYear(date),
        month: getMonth(date),
        date: getDate(date),
      }),
    );

  const onTodayClick = (): void => {
    const today = new Date();
    onDateChange(
      set(selectedDate, {
        year: getYear(today),
        month: getMonth(today),
        date: getDate(today),
      }),
    );
  };

  const calcIsSameDay = (date1: Date, date2: Date): boolean => {
    if (
      getDate(date1) === getDate(date2) &&
      getMonth(date1) === getMonth(date2) &&
      getYear(date1) === getYear(date2)
    )
      return true;
    return false;
  };

  const cell = (date: Date): JSX.Element => {
    const isHighlighted = calcIsSameDay(date, highlightedDate);
    const isSelected = calcIsSameDay(date, selectedDate);
    const isToday = calcIsSameDay(date, today);
    const isDisabled = calcIsDisabled(date);

    let cellInternal = <Text className="text-metadata">{getDate(date)}</Text>;

    if (isToday)
      cellInternal = (
        <div className="absolute z-50 top-[50%] left-[50%] w-[24px] h-[24px] rounded-[12px] translate-x-[-50%] translate-y-[-50%] bg-clari-blue/600 justify-center items-center flex">
          <Text className="text-white text-metadata">{getDate(date)}</Text>
        </div>
      );

    if (isDisabled) {
      return (
        <div
          className="flex justify-center items-center text-neutral/400 w-[28px] h-[28px] relative"
          key={`dayofmonth${Math.random()}`}
        >
          {cellInternal}
        </div>
      );
    }

    return (
      <div
        className={cx(
          'justify-center items-center flex border border-solid border-transparent hover:bg-neutral/75 active:bg-neutral/100 w-[26px] h-[26px] cursor-pointer relative !box-content',
          isHighlighted && 'bg-neutral/75',
          isSelected && 'bg-neutral/100 border-neutral/600',
        )}
        onClick={() => onDateClick(date)}
        onKeyDown={onDateKeyDown}
        role="button"
        tabIndex={0}
        key={`dayofmonth${Math.random()}`}
      >
        {cellInternal}
      </div>
    );
  };

  return (
    <div className="w-[196px]">
      <div className="flex flex-row">
        <div className="flex-1 pl-[10px] pt-[6px]">
          <Text className="body" weight="semibold">{`${addDays(
            dateInFirstCell,
            10,
          ).toLocaleString(undefined, {
            month: 'long',
          })} ${getYear(dateInFirstCell)}`}</Text>
        </div>
        <div
          className="w-[28px] h-[28px] flex justify-center items-center cursor-pointer relative"
          onClick={onPrevMonth}
          onKeyDown={event => event.key === 'Enter' && onPrevMonth()}
          role="button"
          tabIndex={0}
        >
          <UpArrow className="text-neutral/900 h-[12px]" />
        </div>
        <div
          className="w-[28px] h-[28px] flex justify-center items-center cursor-pointer relative"
          onClick={onNextMonth}
          onKeyDown={event => event.key === 'Enter' && onNextMonth()}
          role="button"
          tabIndex={0}
        >
          <DownArrow className="text-neutral/900 h-[12px]" />
        </div>
      </div>
      <div className="flex flex-row flex-1">
        {DAYS_OF_WEEK.map(day => (
          <div
            className="w-[28px] h-[28px] flex justify-center items-center cursor-pointer relative !box-content"
            key={`dayofweek${Math.random()}`}
          >
            <Text className="text-metadata text-neutral/900">{day}</Text>
          </div>
        ))}
      </div>
      {Array.from({ length: 6 }, (_, i) => (
        <div className="flex flex-row" key={`week${Math.random()}`}>
          {Array.from({ length: 7 }, (_, k) => {
            const tempDate = addDays(dateInFirstCell, i * 7 + k);

            return cell(tempDate);
          })}
        </div>
      ))}
      <div className="flex flex-row-reverse h-[28px] relative">
        <div
          className="flex items-center h-full cursor-pointer"
          onClick={onTodayClick}
          onKeyDown={event => event.key === 'Enter' && onTodayClick()}
          role="button"
          tabIndex={0}
        >
          <Text className="text-metadata text-clari-blue/600 hover:text-clari-blue/700 active:text-clari-blue/800 disabled:text-neutral/200 mr-1.5">
            Go to today
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
