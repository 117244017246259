import {
  FullAction,
  NEW_ACTION_ID,
  ActionType,
  RecipientType,
  crmObject,
  DynamicForm,
} from '@groove/api/gateway/v1/actionCompose';
import indefinite from 'indefinite';
import { format } from 'date-fns';
import { MessageLevel } from '@groove/ui/Components/MessageBar';
import { FindPerson, SearchPerson } from '@groove/api/gateway/v1/people';
import { SearchUser } from '@groove/api/gateway/v1/users';
import sanitizeHtml from 'sanitize-html';
import { getActionSource } from '@groove/api/hooks/useMutateAction';
import useLaunchDarklyStore from '@groove/api/hooks/launchdarkly/useStore';

import {
  ACTION_HASH,
  ActionHash,
  COMPLETE_IN_DIALER,
  SMS_NOT_ENABLED_MESSAGE,
  SMS_PHONE_NUMBER_MISSING_MESSAGE,
  errorMessageMap,
  emptyAction,
  TYPES,
} from '../constants';
import useStore from '../store/useStore';

import { findAllUnmergedFields } from './mergeFieldHandler';
import { getOptOutValues } from './syncWhoData';

export const followUpActionUtil = (currentUser: SearchUser): FullAction => {
  const {
    summary,
    fromEmail,
    description: oldDescription,
    loggingTo,
    notes,
    type,
    dynamicData,
    who,
  } = useStore.getState().action;
  const description = `This action was created as a follow-up to a ${
    ACTION_HASH[type as keyof ActionHash]
  } on
  ${format(new Date(), 'PPpp')} with the following details:
  ${notes || oldDescription}`;

  const fields =
    dynamicData?.loggingForm?.fields?.map(field => ({
      ...field,
      value: null,
    })) || [];

  return {
    ...emptyAction,
    id: NEW_ACTION_ID,
    type,
    assignee: currentUser,
    description,
    dynamicData: { loggingForm: { title: null, subtitle: null, fields } },
    fromEmail,
    loggingTo,
    summary: `Follow up to ${summary}`,
    who,
  };
};

export const englishJoiner = (stringArray: (string | undefined)[]): string => {
  const filteredArray = stringArray.filter(string => string);
  if (filteredArray.length < 3) return filteredArray.join(' and ');
  return `${filteredArray.slice(0, -1).join(', ')}, and ${
    filteredArray[filteredArray.length - 1]
  }`;
};

type Location = { [key in keyof FullAction]?: string };

export const enLocation: Location = {
  notes: 'Notes',
  loggingTo: 'Logging to',
  body: 'Body',
  fromEmail: 'From email',
  toRecipients: 'To recipient',
  summary: 'Action title',
  assignee: 'Assignee',
  dueAt: 'Due date',
  description: 'Description',
  results: 'Call result',
  subject: 'Subject',
  dynamicData: 'Custom fields',
};

export const validationHash = {
  noAssignee: `Please add an ${enLocation.assignee}`,
  noDueAt: `Please add a ${enLocation.dueAt}`,
  noSummary: `Please enter ${indefinite(
    enLocation.summary || '',
  )} of at least 3 characters`,
  recipient: (location: keyof FullAction) =>
    `Please enter ${indefinite(enLocation[location] || '')}`,
  unmergedField: (locations: (keyof FullAction)[]) => {
    const rawEnLocation = locations.map(location => enLocation[location]);
    return `There is an unmerged field in ${englishJoiner(rawEnLocation)}`;
  },
  emailOptOut: (personName: string | undefined) =>
    `${personName || 'This recipient'} is opted out of receiving Emails`,
  callOptOut: (personName: string | undefined) =>
    `${personName || 'This recipient'} is opted out of receiving Calls`,
  smsOptOut: (personName: string | undefined) =>
    `${personName || 'This recipient'} is opted out of receiving SMS`,
  noEmail: (personName?: string) =>
    `${personName || 'This recipient'} does not have an email`,
  noPhone: (personName?: string) =>
    `${personName || 'This recipient'} does not have a phone number`,
  missingSubject: (type: ActionType) =>
    `${ACTION_HASH[type]} does not have a subject`,
  missingBody: (type: ActionType) => {
    let field = '';

    if (
      type === 'GENERAL' ||
      type === 'STEP_SUBTYPE_IN_PERSON_VISIT' ||
      type === 'STEP_SUBTYPE_DIRECT_MAIL' ||
      type === 'OTHER' ||
      type === 'WORKSPACE_ACCOUNT_TASK' ||
      type === 'STEP_SUBTYPE_SENDOSO'
    )
      field = enLocation.description || '';

    if (
      type === 'STEP_SUBTYPE_SMS' ||
      type === 'TEMPLATE' ||
      type === 'STEP_SUBTYPE_LINKEDIN_CONNECT' ||
      type === 'STEP_SUBTYPE_LINKEDIN_INMAIL'
    )
      field = enLocation.body || '';

    if (field) return `${ACTION_HASH[type]} does not have a ${field}`;
    return '';
  },
};

export const getErrorMessage = (id: string, status: number): string => {
  if (status === 500) {
    return errorMessageMap.UnknownError[500];
  }
  return errorMessageMap[id][status];
};

// TODO: break validation up into own file and have the validation happen for each item atomically
export const actionValidator = (action: FullAction): void => {
  const notNewAction = action.id !== NEW_ACTION_ID;
  const newActionLevel = notNewAction ? 'error' : 'warning';
  const actionsWithBody = [
    'STEP_SUBTYPE_SMS',
    'TEMPLATE',
    'GENERAL',
    'OTHER',
    'WORKSPACE_ACCOUNT_TASK',
    'STEP_SUBTYPE_IN_PERSON_VISIT',
    'STEP_SUBTYPE_DIRECT_MAIL',
  ];
  const store = useStore.getState();

  const { isCallResultRequired, isCallResultDisplayed } = store.otherValues;

  // Validate an Action hasn't already been completed
  const COMPLETED_WARNING_ID = 'completedWarning';
  if (action.completedAt) {
    store.addSaveError({ id: COMPLETED_WARNING_ID });
    store.addExecutionError({ id: COMPLETED_WARNING_ID });
    store.addMessage({
      level: 'error',
      message: 'Action has already been completed.',
      id: COMPLETED_WARNING_ID,
      noCloseButton: true,
      hide: false,
    });
  } else {
    store.removeSaveError(COMPLETED_WARNING_ID);
    store.removeExecutionError(COMPLETED_WARNING_ID);
    store.removeMessage(COMPLETED_WARNING_ID);
  }

  // Complete Call Action in Dialer
  const { dialerEnabled } = store;
  const { isEmailOptOutSafeguardDisabled } = store.otherValues;
  const { phoneNumber, smsEnabled } = store.otherValues;
  const { ldClient } = useLaunchDarklyStore.getState();
  const dialerInActionEnabled = ldClient?.variation('dialer-in-action');

  // Warning to complete Action in Dialer if user doesn't have Dialer in Action
  const DIALER_WARNING_ID = 'dialerWarning';
  if (
    dialerEnabled &&
    action.type === 'CALL' &&
    notNewAction &&
    !dialerInActionEnabled
  ) {
    store.addMessage({
      level: 'warning',
      message: COMPLETE_IN_DIALER,
      id: DIALER_WARNING_ID,
      noCloseButton: true,
    });
  } else {
    store.removeMessage(DIALER_WARNING_ID);
  }

  // Validate Assignee
  const NO_ASSIGNEE_ID = 'assigneeError';
  if (!action.assignee) {
    const error = {
      id: NO_ASSIGNEE_ID,
      readableLocation: enLocation.assignee || '',
    };
    store.addSaveError(error);
    store.addExecutionError(error);
    store.addMessage({
      level: 'error',
      message: validationHash.noAssignee,
      id: NO_ASSIGNEE_ID,
      noCloseButton: true,
      hide: true,
    });
  } else {
    store.removeSaveError(NO_ASSIGNEE_ID);
    store.removeExecutionError(NO_ASSIGNEE_ID);
    store.removeMessage(NO_ASSIGNEE_ID);
  }

  // Validate title/summary, it's not needed for one off actions since it's being generated
  const NO_TITLE_ID = 'titleError';
  if (
    (!action.summary?.trim() && getActionSource(action) !== 'ONE_OFF') ||
    (action.summary && action.summary.length < 3)
  ) {
    const error = {
      id: NO_TITLE_ID,
      readableLocation: enLocation.summary || '',
    };
    if (getActionSource(action) !== 'ONE_OFF') store.addSaveError(error);
    store.addExecutionError(error);
    store.addMessage({
      level: 'error',
      message: validationHash.noSummary,
      id: NO_TITLE_ID,
      noCloseButton: true,
      hide: true,
    });
  } else {
    store.removeSaveError(NO_TITLE_ID);
    store.removeExecutionError(NO_TITLE_ID);
    store.removeMessage(NO_TITLE_ID);
  }

  // Validate Due At
  const NO_DUE_AT_ID = 'dueAtError';
  if (!action.dueAt) {
    const error = {
      id: NO_DUE_AT_ID,
      readableLocation: enLocation.dueAt || '',
    };
    store.addExecutionError(error);
    store.addMessage({
      level: 'error',
      message: validationHash.noDueAt,
      id: NO_DUE_AT_ID,
      noCloseButton: true,
      hide: true,
    });
  } else {
    store.removeExecutionError(NO_DUE_AT_ID);
    store.removeMessage(NO_DUE_AT_ID);
  }

  // Validate recipient
  const NO_RECIPIENT_ID = 'toRecipientError';
  if (!action.personId && (action.toRecipients || []).length < 1) {
    let location: keyof FullAction = 'loggingTo';
    const error = {
      id: NO_RECIPIENT_ID,
      readableLocation: enLocation.toRecipients || '',
    };

    store.removeExecutionError(NO_RECIPIENT_ID);

    if (
      action.type === 'TEMPLATE' ||
      action.type === 'CALL' ||
      action.type === 'STEP_SUBTYPE_SMS' ||
      action.type === 'STEP_SUBTYPE_LINKEDIN_INMAIL' ||
      action.type === 'STEP_SUBTYPE_LINKEDIN_CONNECT'
    ) {
      location = 'toRecipients';
      store.addExecutionError(error);
    }

    store.addMessage({
      level: newActionLevel,
      message: validationHash.recipient(location),
      id: NO_RECIPIENT_ID,
      noCloseButton: true,
      hide: true,
    });
  } else {
    store.removeExecutionError(NO_RECIPIENT_ID);
    store.removeMessage(NO_RECIPIENT_ID);
  }

  // Person has opted out of Call
  const CALL_OPT_OUT_ID = 'callOptOut';
  if (action.type === 'CALL' && action.who?.doNotCall) {
    if (!notNewAction) store.addSaveError({ id: CALL_OPT_OUT_ID });
    if (!dialerEnabled) store.addExecutionError({ id: CALL_OPT_OUT_ID });
    store.addMessage({
      level: notNewAction ? 'warning' : 'error',
      message: validationHash.callOptOut(action.who.name),
      id: CALL_OPT_OUT_ID,
      noCloseButton: true,
    });
  } else {
    store.removeSaveError(CALL_OPT_OUT_ID);
    store.removeExecutionError(CALL_OPT_OUT_ID);
    store.removeMessage(CALL_OPT_OUT_ID);
  }

  // Person has opted out of Email
  const EMAIL_OPT_OUT_ID = 'emailOptOut';
  if (action.type === 'TEMPLATE' && action.who?.doNotEmail) {
    if (!isEmailOptOutSafeguardDisabled) {
      if (!notNewAction) store.addSaveError({ id: EMAIL_OPT_OUT_ID });
      store.addExecutionError({ id: EMAIL_OPT_OUT_ID });
    }
    store.addMessage({
      level:
        isEmailOptOutSafeguardDisabled || notNewAction ? 'warning' : 'error',
      message: validationHash.emailOptOut(action.who.name || action.who.email),
      id: EMAIL_OPT_OUT_ID,
      noCloseButton: true,
    });
  } else {
    store.removeSaveError(EMAIL_OPT_OUT_ID);
    store.removeExecutionError(EMAIL_OPT_OUT_ID);
    store.removeMessage(EMAIL_OPT_OUT_ID);
  }

  // Person has opted out of Sms
  const SMS_OPT_OUT_ID = 'smsOptOut';
  if (action.type === 'STEP_SUBTYPE_SMS' && action.who?.doNotSms) {
    if (!notNewAction) store.addSaveError({ id: SMS_OPT_OUT_ID });
    store.addExecutionError({ id: SMS_OPT_OUT_ID });
    store.addMessage({
      level: notNewAction ? 'warning' : 'error',
      message: validationHash.smsOptOut(action.who.name),
      id: SMS_OPT_OUT_ID,
      noCloseButton: true,
    });
  } else {
    store.removeSaveError(SMS_OPT_OUT_ID);
    store.removeExecutionError(SMS_OPT_OUT_ID);
    store.removeMessage(SMS_OPT_OUT_ID);
  }

  // SMS specific validation errors
  const SMS_ERROR_ID = 'smsError';
  if (action.type === 'STEP_SUBTYPE_SMS' && (!phoneNumber || !smsEnabled)) {
    store.addExecutionError({ id: SMS_ERROR_ID });
    store.addMessage({
      level: notNewAction ? 'warning' : 'error',
      message: !smsEnabled
        ? SMS_NOT_ENABLED_MESSAGE
        : SMS_PHONE_NUMBER_MISSING_MESSAGE,
      id: SMS_ERROR_ID,
      noCloseButton: true,
    });
  } else {
    store.removeExecutionError(SMS_ERROR_ID);
    store.removeMessage(SMS_ERROR_ID);
  }

  // Validate person has an email address
  const NO_EMAIL_ID = 'noEmailError';
  if (
    action.type === 'TEMPLATE' &&
    action.toRecipients?.[0] &&
    !action.toRecipients[0]?.email &&
    action.toRecipients &&
    action.toRecipients.length > 0
  ) {
    store.addExecutionError({ id: NO_EMAIL_ID });
    store.addMessage({
      level: newActionLevel,
      message: validationHash.noEmail(action.toRecipients?.[0]?.name),
      id: NO_EMAIL_ID,
      noCloseButton: true,
    });
  } else {
    store.removeExecutionError(NO_EMAIL_ID);
    store.removeMessage(NO_EMAIL_ID);
  }

  // Validate person has a phone number
  const NO_PHONE_ID = 'noPhoneNumberError';
  if (
    (action.type === 'CALL' || action.type === 'STEP_SUBTYPE_SMS') &&
    action.toRecipients?.[0] &&
    !action.toRecipients[0].phone &&
    dialerEnabled
  ) {
    store.addExecutionError({ id: NO_PHONE_ID });
    store.addMessage({
      level: dialerEnabled ? newActionLevel : 'warning',
      message: validationHash.noPhone(action.toRecipients?.[0].name),
      id: NO_PHONE_ID,
      noCloseButton: true,
    });
  } else {
    store.removeExecutionError(NO_PHONE_ID);
    store.removeMessage(NO_PHONE_ID);
  }

  // Validate merge fields
  const UNMERGED_FIELD_ID = 'unmergedFieldError';
  if (action.type === 'TEMPLATE' || action.type === 'STEP_SUBTYPE_SMS') {
    const unmergedBody = findAllUnmergedFields(action.body).length > 0;
    const unmergedSubject = findAllUnmergedFields(action.subject).length > 0;
    if (unmergedBody || unmergedSubject) {
      const locations: (keyof FullAction)[] = [];
      if (unmergedBody) locations.push('body');
      if (unmergedSubject) locations.push('subject');
      store.addExecutionError({ id: UNMERGED_FIELD_ID });
      store.addMessage({
        level: newActionLevel,
        message: validationHash.unmergedField(locations),
        id: UNMERGED_FIELD_ID,
        noCloseButton: true,
      });
    } else {
      store.removeExecutionError(UNMERGED_FIELD_ID);
      store.removeMessage(UNMERGED_FIELD_ID);
    }
  } else {
    store.removeExecutionError(UNMERGED_FIELD_ID);
    store.removeMessage(UNMERGED_FIELD_ID);
  }

  // Validate Subject in Email Template
  const NO_SUBJECT_ID = 'noSubjectError';
  if (action.type === 'TEMPLATE' && !action.subject) {
    const error = {
      id: NO_SUBJECT_ID,
      readableLocation: enLocation.subject || '',
    };
    store.addExecutionError(error);
    store.addSaveError(error);
    store.addMessage({
      level: newActionLevel,
      message: validationHash.missingSubject(action.type),
      id: NO_SUBJECT_ID,
      noCloseButton: true,
      hide: true,
    });
  } else {
    store.removeExecutionError(NO_SUBJECT_ID);
    store.removeSaveError(NO_SUBJECT_ID);
    store.removeMessage(NO_SUBJECT_ID);
  }

  // Validate if the body is filled out
  const NO_BODY_ID = 'noBodyError';
  if (!action.body && actionsWithBody.includes(action.type)) {
    const error = {
      id: NO_BODY_ID,
      readableLocation: enLocation.body || '',
    };
    store.addExecutionError(error);
    store.addSaveError(error);
    store.addMessage({
      level: newActionLevel,
      message: validationHash.missingBody(action.type),
      id: NO_BODY_ID,
      noCloseButton: true,
      hide: true,
    });
  } else {
    store.removeExecutionError(NO_BODY_ID);
    store.removeSaveError(NO_BODY_ID);
    store.removeMessage(NO_BODY_ID);
  }

  // Validate if the Call results are filled out
  const NO_CALL_RESULT_ID = 'noCallResultsError';
  if (
    action.type === 'CALL' &&
    isCallResultDisplayed &&
    isCallResultRequired &&
    !action.results
  ) {
    const error = {
      id: NO_CALL_RESULT_ID,
      readableLocation: enLocation.results || '',
    };
    store.addExecutionError(error);
    store.addMessage({
      level: newActionLevel,
      message: 'Missing Call Results',
      id: NO_CALL_RESULT_ID,
      noCloseButton: true,
      hide: true,
    });
  } else {
    store.removeExecutionError(NO_CALL_RESULT_ID);
    store.removeMessage(NO_CALL_RESULT_ID);
  }

  // Validate Additional Fields for Calls
  const ADDITIONAL_FIELD_ID = 'additionalFieldsError';

  const additionalFields = action.dynamicData?.loggingForm?.fields || [];
  const uneditableFields: string[] = [];
  const missingFields: string[] = [];
  let messageLevel: MessageLevel | null = null;
  let message = '';

  additionalFields.forEach(field => {
    if (action.type !== TYPES.CALL || !action.dynamicData.loggingFormFlag)
      return;

    const errorId = `${ADDITIONAL_FIELD_ID}${field.name}`;

    store.removeSaveError(errorId);
    store.removeExecutionError(errorId);

    if (field.required && !field.updateable) {
      const error = {
        id: errorId,
        readableLocation: field.name,
      };
      store.addSaveError(error);
      store.addExecutionError(error);
      uneditableFields.push(field.name);
      messageLevel = 'error';
    } else if (!field.updateable) {
      uneditableFields.push(field.name);
      if (!messageLevel) messageLevel = 'warning';
    } else if (
      field.required &&
      (!field.value || (Array.isArray(field.value) && !field.value.length))
    ) {
      const error = {
        id: errorId,
        readableLocation: field.label,
      };
      store.addExecutionError(error);
      missingFields.push(field.name);
    }
  });

  if (messageLevel)
    message = `You do not have access to the following fields: ${englishJoiner(
      uneditableFields,
    )}. Contact your SFDC admin or Groove Admin to provide access.`;

  if (missingFields.length > 0 && !message) {
    message = 'Please complete required Custom Fields before logging.';
    messageLevel = newActionLevel;
  }

  if (messageLevel && message) {
    store.addMessage({
      level: messageLevel,
      message,
      id: ADDITIONAL_FIELD_ID,
      noCloseButton: true,
      hide: false,
    });
  } else {
    store.removeMessage(ADDITIONAL_FIELD_ID);
  }
};

export const buildNewActionWhoFromPerson = (
  person: FindPerson,
): SearchPerson => {
  return {
    company: person?.companyName,
    doNotCall:
      getOptOutValues(person?.sfdcId, person?.sfdcData)?.doNotCall ||
      person?.sfdcData?.doNotCall,
    doNotEmail:
      getOptOutValues(person?.sfdcId, person?.sfdcData)?.doNotEmail ||
      person?.sfdcData?.hasOptedOutOfEmail,
    doNotSms:
      getOptOutValues(person?.sfdcId, person?.sfdcData)?.doNotSms ||
      person?.sfdcData?.hasOptedOutOfSms,
    email: person?.email,
    firstName: person?.sfdcData?.firstName,
    lastName: person?.sfdcData?.lastName,
    id: person?.id,
    mobilePhone: person?.mobilePhone,
    name: person?.name,
    phone: person?.phone,
    sfdcId: person?.sfdcId,
    sfdcType: person?.sfdcData?.attributes?.type,
    title: person?.sfdcData?.title,
  };
};

export const didActionChange = (
  originalAction: FullAction,
  currentAction: FullAction,
): boolean => {
  // primitive values comparison
  let didActionChange = false;

  didActionChange =
    didActionChange ||
    !(
      arePrimitiveValuesEquivalent(
        sanitizeData(originalAction.body || ''),
        sanitizeData(currentAction.body || ''),
      ) &&
      arePrimitiveValuesEquivalent(
        originalAction.crmTaskType,
        currentAction.crmTaskType,
      ) &&
      arePrimitiveValuesEquivalent(
        originalAction.description,
        currentAction.description,
      ) &&
      arePrimitiveValuesEquivalent(originalAction.dueAt, currentAction.dueAt) &&
      arePrimitiveValuesEquivalent(
        originalAction.executeAt,
        currentAction.executeAt,
      ) &&
      arePrimitiveValuesEquivalent(
        originalAction.fromEmail,
        currentAction.fromEmail,
      ) &&
      arePrimitiveValuesEquivalent(originalAction.notes, currentAction.notes) &&
      arePrimitiveValuesEquivalent(
        originalAction.priority,
        currentAction.priority,
      ) &&
      arePrimitiveValuesEquivalent(
        originalAction.results,
        currentAction.results,
      ) &&
      arePrimitiveValuesEquivalent(
        sanitizeData(originalAction.subject || ''),
        sanitizeData(currentAction.subject || ''),
      ) &&
      arePrimitiveValuesEquivalent(
        originalAction.summary,
        currentAction.summary,
      ) &&
      arePrimitiveValuesEquivalent(
        originalAction.dynamicData.hasCalled,
        currentAction.dynamicData.hasCalled,
      )
    );

  // comparing the nested objects
  didActionChange =
    didActionChange ||
    didAssigneeChange(originalAction.assignee, currentAction.assignee) ||
    didRecipientsChange(currentAction.bccRecipients) ||
    didRecipientsChange(currentAction.ccRecipients) ||
    didCrmResultsChange(originalAction.crmResults, currentAction.crmResults) ||
    didLoggingToChange(originalAction.loggingTo, currentAction.loggingTo) ||
    didToRecipientsChange(
      originalAction.toRecipients,
      currentAction.toRecipients,
    ) ||
    didDynamicFormChange(
      originalAction.dynamicData.loggingForm,
      currentAction.dynamicData.loggingForm,
    );
  return didActionChange;
};

export const arePrimitiveValuesEquivalent = (
  originalPrimitive: unknown,
  currentPrimitive: unknown,
): boolean =>
  !originalPrimitive
    ? !currentPrimitive
    : originalPrimitive === currentPrimitive;

export const didAssigneeChange = (
  originalAssignee: SearchUser | null,
  currentAssignee: SearchUser | null,
): boolean =>
  !arePrimitiveValuesEquivalent(originalAssignee?.id, currentAssignee?.id);

export const didCrmResultsChange = (
  originalCrmResults: {
    activity_result: string;
    activity_outcome: string;
  } | null,
  currentCrmResults: {
    activity_result: string;
    activity_outcome: string;
  } | null,
): boolean =>
  !(
    arePrimitiveValuesEquivalent(
      originalCrmResults?.activity_result,
      currentCrmResults?.activity_result,
    ) &&
    arePrimitiveValuesEquivalent(
      originalCrmResults?.activity_outcome,
      currentCrmResults?.activity_outcome,
    )
  );

const didRecipientsChange = (
  currentRecipients: RecipientType[] | null,
): boolean => !!currentRecipients && currentRecipients.length > 0;

const didToRecipientsChange = (
  originalToRecipients: RecipientType[] | null,
  currentToRecipients: RecipientType[] | null,
): boolean => {
  if (
    originalToRecipients === null ||
    originalToRecipients === undefined ||
    originalToRecipients.length === 0
  ) {
    return !!currentToRecipients && currentToRecipients.length > 0;
  }
  return !arePrimitiveValuesEquivalent(
    originalToRecipients[0]?.email,
    currentToRecipients?.[0]?.email,
  );
};

const didLoggingToChange = (
  originalLoggingTo: { what?: crmObject[]; who?: crmObject[] } | null,
  currentLoggingTo: { what?: crmObject[]; who?: crmObject[] } | null,
): boolean => {
  if (!originalLoggingTo && !currentLoggingTo) return false;
  const originalWhatIds = originalLoggingTo?.what?.map(
    whatObject => whatObject.id,
  );
  const originalWhoIds = originalLoggingTo?.who?.map(whoObject => whoObject.id);
  const currentWhatIds = currentLoggingTo?.what?.map(
    whatObject => whatObject.id,
  );
  const currentWhoIds = currentLoggingTo?.who?.map(whoObject => whoObject.id);

  // I noticed a bug where action compose window is reopened, who ids are being duplicated
  // added a uniq filter to remove duplicates
  return !(
    doArraysHaveSameValues(
      (originalWhatIds || []).filter(onlyUnique),
      (currentWhatIds || []).filter(onlyUnique),
    ) &&
    doArraysHaveSameValues(
      (originalWhoIds || []).filter(onlyUnique),
      (currentWhoIds || []).filter(onlyUnique),
    )
  );
};

const didDynamicFormChange = (
  originalForm: DynamicForm,
  currentForm: DynamicForm,
): boolean => {
  if (!originalForm) return false;
  for (const originalField of originalForm.fields) {
    const currentField = currentForm.fields.find(
      field => field.name === originalField.name,
    );

    let result = true;
    if (originalField.type === 'picklist') {
      result = arePrimitiveValuesEquivalent(
        originalField.value,
        currentField?.value,
      );
    } else if (originalField.type === 'multipicklist') {
      result = doArraysHaveSameValues(
        (originalField.value as string[]) || [],
        (currentField?.value as string[]) || [],
      );
    }

    if (!result) return true;
  }

  return false;
};

/* eslint-disable no-plusplus */
const doArraysHaveSameValues = (
  array1: string[],
  array2: string[],
): boolean => {
  // Check if the arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Sort both arrays
  const sortedArray1 = [...array1].sort();
  const sortedArray2 = [...array2].sort();

  // Compare each element
  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }

  return true;
};
/* eslint-enable no-plusplus */

const onlyUnique = (value: string, index: number, array: string[]): boolean => {
  return array.indexOf(value) === index;
};

const sanitizeData = (data: string): string => {
  return sanitizeHtml(data, {
    allowedTags: [],
  });
};
