import cx from 'classnames';
import { FC, HTMLProps, useState } from 'react';
import {
  addBusinessDays,
  addHours,
  format,
  parse,
  roundToNearestMinutes,
  addWeeks,
  addMonths,
  isToday,
  set,
} from 'date-fns';

import { amPm, presetUnits, presets, timeList } from '../constants';

import Calendar from './Calendar';
import Text from './Text';
import LinkButton from './LinkButton';
import PicklistNew from './PicklistNew';
import { StandardPicklistItem } from './Picklist';

export type CalendarTimePickerProps = {
  date: Date;
  onDateChange: (date: Date) => void;
  className?: HTMLProps<HTMLElement>['className'];
  minDate?: Date;
  maxDate?: Date;
};

const CalendarTimePicker: FC<CalendarTimePickerProps> = ({
  date,
  onDateChange,
  className,
  minDate,
  maxDate,
}) => {
  const [timeSearch, setTimeSearch] = useState('');

  const ceilDueAt = roundToNearestMinutes(date, {
    nearestTo: 30,
    roundingMethod: 'ceil',
  });
  const ceilToday = roundToNearestMinutes(new Date(), {
    nearestTo: 30,
    roundingMethod: 'ceil',
  });
  const formattedHour = format(ceilDueAt, 'hh:mm');
  const formattedAmPm = format(ceilDueAt, 'a');

  const selectedHour: StandardPicklistItem = {
    key: formattedHour,
    value: formattedHour,
    text: formattedHour,
  };
  const selectedAmPm: StandardPicklistItem = {
    key: formattedAmPm,
    value: formattedAmPm,
    text: formattedAmPm,
  };

  const onTimeChange = (
    sHour?: StandardPicklistItem,
    sAmPm?: StandardPicklistItem,
  ): void => {
    if (sHour && sHour.value && sAmPm && sAmPm.value) {
      const newTime = parse(
        `${sHour.value} ${sAmPm.value}`,
        'hh:mm a',
        ceilDueAt,
      );
      onDateChange(newTime);
    }
  };

  const calcPreset = (number: number, unit: presetUnits): Date => {
    if (unit === 'hour') {
      return addHours(ceilToday, number);
    }
    if (unit === 'day') {
      return set(addBusinessDays(ceilToday, number), { hours: 8, minutes: 0 });
    }
    if (unit === 'week') {
      return set(addWeeks(ceilToday, number), { hours: 8, minutes: 0 });
    }

    return set(addMonths(ceilToday, number), { hours: 8, minutes: 0 });
  };

  const onTimeSearch = (time: string): void => setTimeSearch(time);

  let alwaysShowList: StandardPicklistItem[] = timeList;

  if (/[0-9]:[0-9]/.test(timeSearch)) {
    const newTimeString = format(
      parse(
        `${timeSearch} ${selectedAmPm?.value || 'AM'}`,
        'hh:mm a',
        ceilDueAt,
      ),
      'hh:mm',
    );
    alwaysShowList = [
      { key: `~${newTimeString}`, value: newTimeString, text: newTimeString },
    ];
  }

  const renderPreset = (date: Date): string => {
    const today = isToday(date);
    if (today) {
      return `Today, ${format(date, 'h:mm a')}`;
    }
    return format(date, 'E, h:mm a');
  };

  return (
    <div className={cx('flex flex-row', className)}>
      <div className="pr-[12px]">
        <Calendar
          onDateChange={onDateChange}
          selectedDate={ceilDueAt}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
      <div className="flex-1 pl-[12px]">
        <Text className="text-body font-semibold h-[20px] pt-[6px] !box-content">
          {`${format(ceilDueAt, 'eeee')}, ${format(ceilDueAt, 'PP')}`}
        </Text>
        <div className="flex flex-row pt-1 !box-content">
          <PicklistNew
            className="mb-[4px]"
            selectedValues={selectedHour && [selectedHour]}
            onSearchChange={onTimeSearch}
            items={timeList}
            nonFilteredItems={alwaysShowList}
            onChange={hour => hour && onTimeChange(hour, selectedAmPm)}
            maxHeight={192}
            hasSearch
          />
          <PicklistNew
            className="mb-[4px] pl-1"
            selectedValues={selectedAmPm && [selectedAmPm]}
            items={amPm}
            onChange={amPm => amPm && onTimeChange(selectedHour, amPm)}
            maxHeight={192}
          />
        </div>
        <Text className="text-metadata font-semibold h-[16px] pt-[20px] !box-content">
          {`${format(ceilDueAt, 'eeee')}, ${format(ceilDueAt, 'PP')}`}
        </Text>
        {presets.map(preset => {
          const presetDate = calcPreset(preset.number, preset.unit);
          return (
            <div
              className="flex flex-row pt-[12px] !box-content"
              key={Math.random()}
            >
              <LinkButton
                onClick={() => onDateChange(presetDate)}
                className="text-body-sm"
              >
                {preset.text}
              </LinkButton>
              <Text className="text-body-sm pl-[8px] text-neutral/600">
                {renderPreset(presetDate)}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarTimePicker;
