import { SourceType } from '@groove/api/gateway/v1/actions';
import sharedStyles from 'Modules/Actions/containers/Table/styles';
import Chip from 'Modules/Shared/components/LabeledChip';
import ClariTheme from 'Utils/theme/ClariTheme';

const teal = ClariTheme.palette['surreal-teal/600'];
const razzy = ClariTheme.palette['razzy-red/500'];
const grape = ClariTheme.palette['grapeshine/600'];
const tangerine = ClariTheme.palette['tangerine-dream/300'];

const styles = {
  root: {
    ...sharedStyles.cell,
    width: 155,
    alignItems: 'center',
    border: 'none',
  },
};

type SourceStyleMap = { [key in SourceType]?: string };

const backgroundColor = {
  FLOW: razzy,
  ONE_OFF: teal,
  WORKSPACE: grape,
  PLAY: tangerine,
} as SourceStyleMap;

const textColor = {
  FLOW: ClariTheme.palette.text.neutralColor,
  ONE_OFF: ClariTheme.palette.text.neutralColor,
  WORKSPACE: ClariTheme.palette.text.neutralColor,
  PLAY: ClariTheme.palette.text.primary,
} as SourceStyleMap;

// Ripping from omnibar
const formatLabel = (label: SourceType): string => {
  if (label === 'WORKSPACE') return 'SPACE';
  return label.replace('_', ' ');
};

const Source = ({ label }: { label: SourceType }) => {
  const color = backgroundColor[label];
  const style = { color: textColor[label] };
  const formattedLabel = formatLabel(label);

  return (
    <div style={styles.root}>
      <Chip label={formattedLabel} color={color} style={style} />
    </div>
  );
};

export default Source;
