import React, { Component } from 'react';
import { List, Map } from 'immutable';
import { withStyles } from '@material-ui/core';

import {
  formInputValidators,
  SearchableDropdownMenu,
} from '@groove-labs/groove-ui';
import noop from 'lodash-es/noop';
import { debounce } from 'lodash-es';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

import Template from 'Modules/Shared/data/Template';

export const FIELD_ID = 'TEMPLATE_SELECTOR';

const { PresenceValidator } = formInputValidators;

const styles = theme => ({
  selectorInputRoot: {
    width: 'unset',
  },
  selectorRoot: {
    width: '100%',
    zIndex: 2,
  },
  selectorPaper: {
    width: '100%',
  },
  selectorMenuList: {
    minHeight: `${theme.spacing.unit * 24}px`,
  },
});

@withStyles(styles)
export default class TemplateSelector extends Component {
  static displayName = 'TemplateSelector';
  // TODO: @jaebradley change the underlying Maps to Records

  static propTypes = {
    classes: PropTypes.shape({
      selectorInputRoot: PropTypes.string.isRequired,
      selectorRoot: PropTypes.string.isRequired,
      selectorPaper: PropTypes.string.isRequired,
      selectorMenuList: PropTypes.string.isRequired,
    }).isRequired,

    // parent props
    groupId: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    updateSelectorActiveStatusTo: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    // TODO: @jaebradley commenting this prop for now, but will use later when setting default template
    initialValue: ImmutablePropTypes.recordOf(Template),
    templateFolders: ImmutablePropTypes.list,
    activeVariantTemplateIds: ImmutablePropTypes.list,
    currentStepTemplateId: PropTypes.number,
    onSelection: PropTypes.func,
    onSearch: PropTypes.func,
  };

  static defaultProps = {
    templateFolders: List(),
    activeVariantTemplateIds: List(),
    currentStepTemplateId: noop,
    initialValue: Template(),
    onSelection: noop,
    onSearch: noop,
    updateSelectorActiveStatusTo: noop,
    onFocus: noop,
    onBlur: noop,
  };

  buildOptions() {
    const { templateFolders } = this.props;
    const result = templateFolders.toJS().map(templateFolder => ({
      ...templateFolder,
      title: templateFolder.name,
      suggestions: templateFolder.templates,
    }));
    return result;
  }

  handleOnChange = (_e, option) => {
    if (option?.suggestion) {
      this.props.onSelection(Map(option.suggestion));
      this.props.updateSelectorActiveStatusTo(false);
    }
  };

  onSearch = e => {
    const { onSearch, activeVariantTemplateIds, currentStepTemplateId } =
      this.props;
    if (e.target?.value !== undefined) {
      onSearch(
        e.target.value,
        activeVariantTemplateIds.isEmpty()
          ? [currentStepTemplateId]
          : activeVariantTemplateIds.toJS()
      );
    }
  };
  onInputChange = debounce(this.onSearch, 300);

  render() {
    const { groupId, initialValue, classes, active, onFocus, onBlur } =
      this.props;

    return (
      <SearchableDropdownMenu
        childGroup
        groupId={groupId}
        fieldId={FIELD_ID}
        multiSection
        getSuggestionValue={item => item.name}
        suggestions={this.buildOptions()}
        inputPlaceholder="Select a Template"
        expandListOnInputFocus
        defaultValue={initialValue?.name || ''}
        defaultSelectedData={initialValue || {}}
        validateBeforeRegistration={false}
        validators={[
          new PresenceValidator({ message: 'Template is required' }),
        ]}
        onSelectItem={this.handleOnChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onInputChange={this.onInputChange}
        classes={
          active
            ? {
                root: classes.selectorRoot,
                inputRoot: classes.selectorInputRoot,
                paper: classes.selectorPaper,
                menuList: classes.selectorMenuList,
              }
            : undefined
        }
      />
    );
  }
}
