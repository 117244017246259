import { useQuery, UseQueryResult } from 'react-query';

import {
  ApiLiRedirect,
  ApiLiToken,
  getLinkedinRegistration,
  getLinkedinSAToken,
} from '../gateway/v1/linkedin';

export const useGetLinkedinToken = ({
  onError,
  enabled,
}: {
  onError?: () => void;
  enabled?: boolean;
}): UseQueryResult<ApiLiToken, unknown> =>
  useQuery('getLinkedinSAToken', getLinkedinSAToken, {
    onError,
    cacheTime: 30 * 60 * 1000, // 30 minutes because we will always manually refetch
    staleTime: 30 * 60 * 1000, // 30 minutes because we will always manually refetch
    retry: false,
    enabled,
  });

export const useGetLinkedinRegistration = (): UseQueryResult<
  ApiLiRedirect,
  unknown
> => useQuery('getLinkedinRegistration', getLinkedinRegistration);
