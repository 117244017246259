import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  PEOPLE_IMPORT_DIALOG: {
    SUBMIT: standardActions(),
    CSV_SUBMIT: standardActions(),
    FETCH_IMPORT_ON_BEHALF_OF_USERS: standardActions(),
    ...keyMirror([
      'UPSTART',
      'TEARDOWN',
      'RESET',
      'LOAD_DATA',
      'SET_ACTIVE_TAB',
      'SET_SEARCHING',
      'OPEN_DRAWER',
      'CLOSE_DRAWER',
      'NO_RESULTS_FOUND',
      'SELECTED_OBJECT_VALUE',

      // Saga actions
      'REQUEST_SET_SALESFORCE_OBJECT',
      'SET_SALESFORCE_OBJECT',
      'UNSET_SALESFORCE_OBJECT',
      'REQUEST_PROCESS_SEARCH_RESULTS',
      'REQUEST_SET_ACTIVE_TAB',
      'TRACK_UPDATE_FLOW_ASSIGNEE',
    ]),
  },
}).PEOPLE_IMPORT_DIALOG;

export function triggerPeopleImportDialogUpstart({ flowId, isOpenUiKeyPath }) {
  return {
    type: actionTypes.UPSTART,
    payload: { flowId, isOpenUiKeyPath },
  };
}

export function requestSetSalesforceObject(salesforceObject) {
  return {
    type: actionTypes.REQUEST_SET_SALESFORCE_OBJECT,
    payload: salesforceObject,
  };
}

export function setSalesforceObject(salesforceObject) {
  return {
    type: actionTypes.SET_SALESFORCE_OBJECT,
    payload: salesforceObject,
  };
}

export function unsetSalesforceObject() {
  return {
    type: actionTypes.UNSET_SALESFORCE_OBJECT,
  };
}

export function reset() {
  return {
    type: actionTypes.RESET,
  };
}

export function triggerPeopleImportDialogTeardown() {
  return {
    type: actionTypes.TEARDOWN,
  };
}

export function loadData(data) {
  return {
    type: actionTypes.LOAD_DATA,
    payload: data,
  };
}

export function submit() {
  return {
    type: actionTypes.SUBMIT.BEGIN,
  };
}

export function submitSuccess() {
  return {
    type: actionTypes.SUBMIT.SUCCESS,
  };
}

export function submitFailure() {
  return {
    type: actionTypes.SUBMIT.FAILURE,
  };
}

export function submitCsvLookupSuccess(payload) {
  return {
    type: actionTypes.CSV_SUBMIT.SUCCESS,
    payload,
  };
}

export function submitCsvLookupBegin() {
  return {
    type: actionTypes.CSV_SUBMIT.BEGIN,
  };
}

export function setActiveTab(activeTab) {
  return {
    type: actionTypes.SET_ACTIVE_TAB,
    payload: activeTab,
  };
}

export function setSearching(value) {
  return {
    type: actionTypes.SET_SEARCHING,
    payload: value,
  };
}

export function openDrawer() {
  return {
    type: actionTypes.OPEN_DRAWER,
  };
}

export function closeDrawer() {
  return {
    type: actionTypes.CLOSE_DRAWER,
  };
}

export function requestProcessSearchResults(value) {
  return {
    type: actionTypes.REQUEST_PROCESS_SEARCH_RESULTS,
    payload: value,
  };
}

export function requestSetActiveTab(value) {
  return {
    type: actionTypes.REQUEST_SET_ACTIVE_TAB,
    payload: value,
  };
}

export function setNoResultsFound(value) {
  return {
    type: actionTypes.NO_RESULTS_FOUND,
    payload: value,
  };
}

export function setSelectedObjectValue(value) {
  return {
    type: actionTypes.SELECTED_OBJECT_VALUE,
    payload: value,
  };
}

export function fetchImportOnBehalfOfUsers() {
  return {
    type: actionTypes.FETCH_IMPORT_ON_BEHALF_OF_USERS.BEGIN,
  };
}

export function showImportOnBehalfOfUsers(users) {
  return {
    type: actionTypes.FETCH_IMPORT_ON_BEHALF_OF_USERS.SUCCESS,
    payload: users,
  };
}

export function trackUpdateFlowAssignee() {
  return {
    type: actionTypes.TRACK_UPDATE_FLOW_ASSIGNEE,
  };
}
