import { IIconProps, MessageBarType } from '@fluentui/react';
import { GetState, SetState } from 'zustand';
import { ActionType, Source } from '@groove/api/services/v1/actions';

import {
  Context,
  InsertSnippetParams,
  OpenActionComposePaneParams,
  VisibilityState,
} from '../types';

import { Store } from './useStore';

export type MessageBar = {
  actions?: JSX.Element;
  message: string;
  type: MessageBarType;
  dismissButtonAriaLabel?: string;
  messageBarIconProps?: IIconProps;
  onDismiss?: () => void;
};

export type LastFetchLocationType =
  | 'ACTION_PANE'
  | 'EXTENDED_ACTION_PANE'
  | 'GROOVE_TAB';

export type IframeValues = {
  iframeState: VisibilityState;
  id: string;
  source: Source | '';
  type: ActionType | '';
  startInitializing: boolean;
  initialized: boolean;
  shouldRefetchGrooveTab: boolean;
  shouldRefetchActionPane: boolean;
  shouldRefetchExtendedActionPane: boolean;
  lastFetchLocation: LastFetchLocationType;
  actionIndex: number; // this is for fetching the next action automatically
  groupName?: string;
  shouldShowNextAction: boolean;
  omnibarLocation: Context | '';
  febesURL?: string;
  personSfdcId: string;
};

export const defaultIframeValues: IframeValues = {
  iframeState: 'CLOSED',
  id: '',
  source: '',
  type: '',
  startInitializing: false,
  initialized: false,
  shouldRefetchGrooveTab: false,
  shouldRefetchActionPane: false,
  shouldRefetchExtendedActionPane: false,
  lastFetchLocation: 'ACTION_PANE',
  actionIndex: -1,
  groupName: undefined,
  shouldShowNextAction: false,
  omnibarLocation: '',
  febesURL: undefined,
  personSfdcId: '',
};

export type ReferenceInitialValue = {
  raw: string | null;
  value: string | null;
};

export type OldActionComposeStore = {
  actionComposeValues: IframeValues;
  setActionComposeValues: (
    newActionComposeValues: Partial<IframeValues>,
  ) => void;
  openActionComposePane: (params: OpenActionComposePaneParams) => void;
  setOpenActionComposePane: (
    func: (params: OpenActionComposePaneParams) => void,
  ) => void;
  activeActionId: string | null;
  setActiveActionId: (activeActionId: string | null) => void;
  insertSnippet: (params: InsertSnippetParams) => void;
  setInsertSnippet: (func: (params: InsertSnippetParams) => void) => void;
};

export const oldActionComposeStore = (
  set: SetState<Store>,
  get: GetState<Store>,
): OldActionComposeStore => ({
  actionComposeValues: defaultIframeValues,
  setActionComposeValues: newActionComposeValues => {
    const { actionComposeValues } = get();
    set({
      actionComposeValues: {
        ...actionComposeValues,
        ...newActionComposeValues,
      },
    });
  },
  openActionComposePane: (params: OpenActionComposePaneParams) => null,
  setOpenActionComposePane: func => set({ openActionComposePane: func }),
  activeActionId: null,
  setActiveActionId: activeActionId => set({ activeActionId }),
  insertSnippet: params => null,
  setInsertSnippet: func => set({ insertSnippet: func }),
});
