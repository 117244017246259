import cx from 'classnames';
import { motion } from 'framer-motion';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useId } from '@fluentui/react-hooks';

import useHandleOutsideClicks from '../helpers/useHandleOutsideClicks';

import Tooltip from './Tooltip';
import Text from './Text';
import {
  CheckmarkIcon,
  CloseIcon,
  ChevronIcon,
  WarningIcon,
} from './BoogieIcon';

export type SelectItem = {
  key: string;
  value: string | undefined;
  text: string | undefined;
  secondaryText?: string;
};

export type SelectProps = {
  items: SelectItem[];
  disabled?: boolean;
  onChange?: (value: SelectItem) => void;
  onCancel?: () => void;
  onHover?: () => void;
  selectedValue?: SelectItem | null;
  displayText: string;
  placeholder?: string;
  fullWidth?: boolean;
  label?: string;
  ariaLabel?: string;
  className?: string;
  tooltip?: string | ReactElement;
  inValid?: boolean;
};

// This componenet combines Tag and Select functionalities in one component
// displayText: is separate from items list, as we can display name, but the items are emails
//              if not provided, fallback to placeholer
// onCancel: if provided, will render the x button to remove this select
//           if not provided will ract as normal Select
// items: dropdown list of items, if empty the select caret will be hidden (act as normal Tag)

const SelectTag: FC<SelectProps> = ({
  placeholder,
  items,
  disabled,
  onChange,
  onCancel,
  selectedValue,
  displayText,
  fullWidth,
  label,
  ariaLabel,
  className,
  tooltip,
  onHover,
  inValid,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const id = useId();

  const handleItemClick = (value: SelectItem): void => {
    onChange?.(value);
    setIsOpen(false);
  };

  const handleCancelClick = (): void => {
    onCancel?.();
    setIsOpen(false);
  };
  let buttonProps = {};
  let hoverTimeout: NodeJS.Timeout;
  if (onHover)
    buttonProps = {
      ...buttonProps,
      onMouseEnter: () => {
        hoverTimeout = setTimeout(onHover, 500);
      },
      onMouseLeave: () => {
        if (hoverTimeout) clearTimeout(hoverTimeout);
      },
    };

  const ref = useRef<HTMLDivElement | null>(null);
  const listContainerRef = useRef<HTMLDivElement | null>(null);

  useHandleOutsideClicks(ref, () => setIsOpen(false));

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [items]);

  const listRef = useRef<VariableSizeList | null>(null);

  const shouldShowDropdown = isOpen && items.length > 1;
  const shouldShowCheveron = items && items.length > 1;
  const shouldActAsTag = !shouldShowCheveron && onCancel;

  const dropdownRect = listContainerRef.current?.getBoundingClientRect();

  const dropdownYPosition =
    (dropdownRect?.y || 0) + (dropdownRect?.height || 0) > window.innerHeight
      ? -1 * (dropdownRect?.height || 0)
      : 30;

  return (
    <>
      {label && (
        <label className="font-semibold text-body" htmlFor={id}>
          {label}
        </label>
      )}
      <div
        className="flex py-1"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div
          className={cx(
            `relative ${className}`,
            fullWidth && 'w-full',
            !fullWidth && 'w-[150px]',
          )}
          ref={ref}
        >
          <Tooltip content={tooltip || ''}>
            <div>
              <button
                id={id}
                aria-label={ariaLabel}
                data-selected-value={selectedValue?.value}
                type="button"
                className={cx(
                  'text-left bg-white background h-[30px] flex items-center px-1.5 py-1 border-solid border-[1px] focus:border-primaryDark focus:outline-none overflow-hidden w-full',
                  onCancel && 'rounded-l-md',
                  !onCancel && 'rounded-md',
                  shouldActAsTag && 'border-r-0',
                  disabled && 'border-gray4 cursor-not-allowed',
                  !disabled &&
                    !shouldActAsTag &&
                    'border-gray3 hover:border-gray2 active:border-gray3',
                  !disabled && isHover && shouldActAsTag && 'border-gray2',
                  !disabled && !isHover && shouldActAsTag && 'border-gray3',
                  inValid && 'border-warning/600 bg-warning/50',
                )}
                onClick={event => {
                  event.stopPropagation();
                  setIsOpen(true);
                }}
                {...buttonProps}
              >
                {inValid && <WarningIcon className="mr-1 w-[16px]" />}
                <Text
                  className={cx(
                    'overflow-x-hidden whitespace-pre overflow-ellipsis',
                    inValid && 'text-warning/900',
                  )}
                >
                  {displayText || placeholder}
                </Text>

                {shouldShowCheveron && (
                  <ChevronIcon className="ml-auto pl-1 w-[12px] h-[20px]" />
                )}
              </button>
            </div>
          </Tooltip>

          <motion.div
            className="absolute left-0 z-50 overflow-y-auto bg-white shadow-lg drop-shadow-md"
            animate={{
              opacity: shouldShowDropdown ? 1 : 0,
              top: shouldShowDropdown ? dropdownYPosition : 10,
            }}
            initial={{
              opacity: 0,
              top: 10,
            }}
            style={{
              pointerEvents: shouldShowDropdown ? 'all' : 'none',
              maxHeight: 150,
            }}
            transition={{ duration: 0.15 }}
            ref={listContainerRef}
          >
            {items.map(item => (
              <button
                aria-label={item.key}
                type="button"
                key={item.key}
                className="flex flex-col text-left w-full px-3 py-1.5 hover:bg-gray-100 cursor-pointer bg-white border-0 hover:bg-gray6"
                onClick={event => {
                  event.stopPropagation();
                  handleItemClick(item);
                }}
              >
                <div className="flex">
                  <div className="w-[32px]">
                    {selectedValue &&
                      selectedValue.key &&
                      selectedValue.key === item.key && (
                        <CheckmarkIcon className="w-[16px] h-[16px]" />
                      )}
                  </div>
                  <div>
                    <Text>{item.text}</Text>
                    {item.secondaryText && (
                      <Text variant="metadata-sm" className="text-gray2">
                        {item.secondaryText}
                      </Text>
                    )}
                  </div>
                </div>
              </button>
            ))}
          </motion.div>
        </div>

        {onCancel && (
          <div
            id={id}
            role="button"
            tabIndex={0}
            onClick={event => {
              event.stopPropagation();
              if (!disabled) handleCancelClick();
            }}
            onKeyUp={event => event.key === 'Enter' && handleCancelClick()}
            className={cx(
              'cursor-pointer flex items-center justify-center w-[20px] border-solid border-[1px] border-l-0 rounded-r-md',
              disabled && 'border-gray4 cursor-not-allowed',
              !disabled &&
                !shouldActAsTag &&
                'border-gray3 hover:border-gray2 active:border-gray3',
              !disabled && isHover && shouldActAsTag && 'border-gray2',
              !disabled && !isHover && shouldActAsTag && 'border-gray3',
              inValid && 'border-warning/600 bg-warning/50',
            )}
          >
            <CloseIcon
              className={cx(
                'text-neutral/600 w-[12px] h-[12px]',
                inValid && 'text-warning/900',
              )}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SelectTag;
