import getClient, { TunnelStrategy, strategy } from './getClient';

export type Who = {
  Name: string;
  Label: string;
  Id: string;
  Email: string;
  AccountId: string | null;
  AccountName: string | null;
  Salutation: string | null;
  FirstName: string;
  LastName: string;
  Title: string | null;
  Phone: string | null;
  MobilePhone: string | null;
};

export type What = {
  Name: string;
  ApiName: string;
  Label: string;
  Id: string;
  lastModifiedDate: string | null;
  stageName: string | null;
  amount: string | null;
  closeDate: string | null;
  ownerName: string | null;
  ownerId: string | null;
  lastActivityDate: string | null;
  accountName: string | null;
  type: string | null;
  subject: string | null;
  status: string | null;
  priority: string | null;
  description: string | null;
};

export type AttachmentResult = {
  who: Who | null;
  what: What | null;
  score: number;
};

export type AttachmentResults = {
  [key: string]: AttachmentResult;
};

export type ApiGrooveEmails = {
  isBestMatchOnly: boolean;
  blacklistedEmail: string | null;
  attachmentResults: AttachmentResults | null;
};

export type Header = {
  name: string;
  value?: string | boolean | string[];
};

export type Attachment = {
  filename: string;
  mimeType: string;
  body: string;
};

export type SalesforceMessage = {
  fromAddress: string;
  fromName: string;
  subject: string;
  headers: Header[];
  toAddresses: string[];
  to: string;
  ccAddresses: string[];
  plainTextBody: string;
  binaryAttachments: Attachment[];
  textAttachments: Attachment[];
  htmlBody: string;
  attachTo?: string;
  unloggedAttachments: string[];
};

export type GrooveEmailsCreatePayload = SalesforceMessage;

const grooveEmails = (emails: string[]): Promise<ApiGrooveEmails> =>
  getClient()
    .get((strategy as TunnelStrategy)?.path || 'api/v2/visualforce/tunnel', {
      searchParams: {
        page: 'GrooveEmails',
        preview: 'true',
        recipientList: emails.join(','),
      },
    })
    .json();

export type Task = {
  attributes: Attributes;
  Id: string;
  CreatedDate: string;
  WhoId: string | null;
  WhatId?: string | null;
  WhoCount: number;
  CurrencyIsoCode: string;
  TaskRelations: TaskRelations;
  Who: MessageIdWho | null;
  What?: MessageIdWhat | null;
};

export type Attributes = {
  type: string;
  url: string;
};

export type TaskRelations = {
  totalSize: number;
  done: boolean;
  records: Record[];
};

export type Record = {
  attributes: Attributes;
  TaskId: string;
  Id: string;
  RelationId: string;
  IsWhat: boolean;
};

export type MessageIdWho = {
  attributes: Attributes;
  Name: string;
  Id: string;
};

export type MessageIdWhat = {
  attributes: Attributes;
  Name: string;
  Id: string;
};

export type ApiGrooveEmailsByMessageId = Task[];

export const grooveEmailsByMessageId = (
  messageId: string,
): Promise<ApiGrooveEmailsByMessageId> => {
  // On the apex side, the messageId is urlDecoded which turns the + into a blank, doing this makes it so '+' signs are preserved
  const encodedMessageId = messageId.replace(/\+/g, '%2B');
  return getClient()
    .get((strategy as TunnelStrategy)?.path || 'api/v2/visualforce/tunnel', {
      searchParams: {
        page: 'GrooveEmails',
        messageId: encodedMessageId,
      },
    })
    .json();
};

export type ApiGrooveEmailsCreate = {
  resultMessage: string;
  resultCode: string;
  objectType: string;
  newTrackedMessage: unknown;
  newRecords: unknown[];
  newRecordIds: string[];
  meta: unknown;
  isSuccess: boolean;
  extraTaskFields: unknown;
  data: string;
  action: string;
  isSfdcConnectionError?: boolean;
  enqueueForBackendSync?: boolean;
};

export const grooveEmailsCreate = (
  email: SalesforceMessage,
): Promise<ApiGrooveEmailsCreate> => {
  const formData = new URLSearchParams();
  formData.append('email', JSON.stringify(email));
  formData.append('method', 'post');
  console.log('[Email - logging] Create a SF task: ', email?.attachTo);
  return getClient()
    .post((strategy as TunnelStrategy)?.path || 'api/v2/visualforce/tunnel', {
      searchParams: {
        page: 'GrooveEmails',
        method: 'post',
        ...(email.attachTo && { attachTo: email.attachTo }),
      },
      body: formData,
    })
    .json();
};

export default grooveEmails;
