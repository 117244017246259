import getClient from './getClient';

export type GetOrganizationBrand = {
  data: {
    hex_color: string;
    logo_url: string;
    use_custom_branding: boolean;
  };
};

export const getOrganizationBrand = (): Promise<GetOrganizationBrand> =>
  getClient().get('organization_brand').json();
