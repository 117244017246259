import { FocusEvent } from 'react';

/**
 * This Method allows us to use onBlur on parent elements and check it and it's child components have lost focus
 *
 * @param param0 Is of type FocusEvent
 * @returns a boolean
 */
const hasElementLostFocused = ({
  relatedTarget,
  currentTarget,
}: FocusEvent): boolean => {
  if (!relatedTarget) return true;

  let currentNode = relatedTarget.parentNode;

  while (currentNode) {
    if (currentNode === currentTarget) return false;
    currentNode = currentNode.parentNode;
  }

  return true;
};

export default hasElementLostFocused;
