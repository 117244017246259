import { FC } from 'react';
import { Icon, IconButton, Modal, Text } from '@fluentui/react';
import { QueryObserverResult } from 'react-query';
import { useBoolean } from '@fluentui/react-hooks';
import Button from '@groove/ui/Components/Button';

import {
  TEMPLATES_ACTION_TYPE,
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  TEMPLATE_EDITOR_CLOSED_VIA,
  trackTemplatesEvent,
} from './analytics/templatesEvents';
import TemplateEditor from './TemplateEditor';
import { CHROME_EXTENSION_WIDTH } from './constants';

const modalStyles = {
  scrollableContent: `w-[${CHROME_EXTENSION_WIDTH}]`,
  layer: { zIndex: 20 },
};

type CreateTemplateButtonProps = {
  selectedFolderId: number | undefined;
  refetchOnSuccess: () => Promise<QueryObserverResult>;
};

const CreateTemplateButton: FC<CreateTemplateButtonProps> = ({
  selectedFolderId,
  refetchOnSuccess,
}) => {
  const [isTemplatesEditorOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  return (
    <>
      <Button
        aria-label="Create Template"
        onClick={() => {
          showModal();
          trackTemplatesEvent({
            eventName: TEMPLATES_EVENT_TYPE.CREATE_NEW_MODAL_OPENED,
            templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
          });
        }}
        className="mx-2"
        variant="primary"
      >
        <Icon iconName="add" className="pr-2" />
        New
      </Button>
      <Modal
        isOpen={isTemplatesEditorOpen}
        forceFocusInsideTrap={false}
        className="groove"
        styles={modalStyles}
      >
        <div className="flex px-3 pt-4">
          <Text className="text-lg font-semibold">New Template</Text>
          <IconButton
            className="ml-auto"
            iconProps={{ iconName: 'cancel' }}
            onClick={() => {
              hideModal();
              trackTemplatesEvent({
                eventName: TEMPLATES_EVENT_TYPE.EDITOR_CLOSED,
                templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
                templateActionType: TEMPLATES_ACTION_TYPE.NEW,
                closedVia: TEMPLATE_EDITOR_CLOSED_VIA.X_BUTTON,
              });
            }}
            ariaLabel="Dismiss Templates Editor"
          />
        </div>
        <TemplateEditor
          initialFolderId={selectedFolderId || 0}
          initialName=""
          initialSubject=""
          initialBody=""
          action="CREATE"
          dismissEditor={hideModal}
          refetchOnSuccess={refetchOnSuccess}
        />
      </Modal>
    </>
  );
};

export default CreateTemplateButton;
