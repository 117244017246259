import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 14,
    marginBottom: 14,
  },
};

const SearchProgress = ({ classes }) => {
  return (
    <div className={classes.root}>
      <CircularProgress size={30} />
      <Typography variant="body1">Searching...</Typography>
    </div>
  );
};

SearchProgress.displayName = 'SearchProgress';
SearchProgress.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(SearchProgress);
