import { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  id?: string;
};

const Portal: FC<PortalProps> = ({ id, children }) => {
  const root = document.getElementById(id || 'root');
  if (!root) {
    const div = document.createElement('div');
    div.id = 'root';
    document.body.appendChild(div);
  }
  const portal = document.getElementById('root');
  const el = document.createElement('div');
  el.classList.add('groove');

  useEffect(() => {
    portal?.appendChild(el);
    return () => portal?.removeChild(el);
  }, [el, portal]);

  return createPortal(children, el);
};

export { Portal };
