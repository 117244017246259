import {
  isAnalyticsEnabled,
  mixpanelSafeTrack,
} from '@groove/api/analytics/hooks/useMixpanel';

export const schedulerBaseProperties = {
  app: 'chrome extension',
  app_feature: 'scheduler',
  transaction_type: 'user',
};

export enum SCHEDULER_SCHEDULED_FOR {
  SELF = 'self',
  TEAMMATE = 'teammate',
  RR_GROUP = 'rr_group',
}
export enum SCHEDULER_SOURCE_UI {
  NEW_EMAIL = 'new_email',
}
export enum SCHEDULER_UI {
  PANEL = 'panel',
  ADD_SPECIFIC_TIMES = 'add_specific_times',
}
export enum SCHEDULER_BLOCK_TYPES {
  MULTIPLE = 'multiple',
  INDIVIDUAL = 'individual',
}
type Props = {
  eventName: string;
  extensionSourceUi?: SCHEDULER_SOURCE_UI;
  schedulerUi?: SCHEDULER_UI;
  scheduledFor?: SCHEDULER_SCHEDULED_FOR;
  meetingTypeUsed?: string;
  numOfTeammatesAdded?: number;
  textCustomized?: boolean;
  blockTypes?: SCHEDULER_BLOCK_TYPES;
  titleUpdated?: boolean;
  durationUpdated?: boolean;
  locationUpdated?: boolean;
  descriptionUpdated?: boolean;
  timeZoneUpdated?: boolean;
  doubleBookingEnabled?: boolean;
  plainTextUsed?: boolean;
  videoConferencingUsed?: string;
};

export function trackSchedulerEvent(props: Props): void {
  const {
    eventName,
    scheduledFor,
    meetingTypeUsed,
    numOfTeammatesAdded,
    textCustomized,
    extensionSourceUi,
    schedulerUi,
    blockTypes,
    titleUpdated,
    durationUpdated,
    locationUpdated,
    descriptionUpdated,
    timeZoneUpdated,
    doubleBookingEnabled,
    plainTextUsed,
    videoConferencingUsed,
  } = props;
  if (!isAnalyticsEnabled()) return;
  mixpanelSafeTrack(`Ext: Scheduler: ${eventName}`, {
    ...schedulerBaseProperties,
    scheduled_for: scheduledFor,
    extension_source_ui: extensionSourceUi,
    scheduler_ui: schedulerUi,
    meeting_type_used: meetingTypeUsed,
    num_of_teammates_added: numOfTeammatesAdded,
    text_customized: textCustomized,
    block_types: blockTypes,
    title_updated: titleUpdated,
    duration_updated: durationUpdated,
    location_updated: locationUpdated,
    description_updated: descriptionUpdated,
    video_conferencing_used: videoConferencingUsed,
    time_zone_updated: timeZoneUpdated,
    double_booking_enabled: doubleBookingEnabled,
    plain_text_used: plainTextUsed,
  });
}
