import { List } from 'immutable';

import { isValidSfdcId } from 'Modules/PeopleImportDialog/submodules/csvLookup/utils';

const emailRegexp =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

export const isInvalidCell = (
  type: string,
  value: string,
  length: number,
  picklist: List<Map<string, string>>
): boolean => {
  switch (type.toLowerCase()) {
    case 'email':
      return !emailRegexp.test(value) || (length && value.length > length);
    case 'string':
      return length && value.length > length;
    case 'picklist':
      return (
        (length && value.length > length) ||
        !picklist.find(
          (item: Map<string, string>) => item.get('value') === value
        )
      );
    case 'reference':
      return !isValidSfdcId(value);
    case 'phone':
      return /\D/g.test(value.replace(/[+\-() ]/g, ''));
    default:
      return false;
  }
};

export const validateCsvRows = (
  csvRows: List<List<string>>,
  colIdx: number,
  {
    type,
    required,
    length,
    picklist,
  }: {
    type: string;
    required: boolean;
    length: number;
    picklist: List<Map<string, string>>;
  }
): List<List<string>> => {
  const updatedCsvRows = csvRows.map(row => {
    let updatedRow = row;
    if (
      required &&
      row.getIn([colIdx]) &&
      isInvalidCell(type, row.getIn([colIdx]), length, picklist)
    ) {
      updatedRow = row.setIn([colIdx], null);
    }
    return updatedRow;
  });
  return updatedCsvRows;
};
