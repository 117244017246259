import { fromJS, OrderedMap } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/savedSearches';

export const initialState = fromJS({
  savedSearches: new OrderedMap(),
  savedSearchHasError: false,
});

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SET_SAVED_SEARCHES: {
      return state.merge({ savedSearches: fromJS(action.payload) });
    }

    case actionTypes.FETCH.SUCCESS: {
      return state.updateIn(['savedSearches'], savedSearches =>
        savedSearches.push(fromJS(action.payload))
      );
    }

    case actionTypes.FETCH.FAILURE: {
      return state.set('savedSearchHasError', true);
    }

    default:
      return state;
  }
}
