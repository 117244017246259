import { createActions, standardActions } from '@groove-labs/action-utils';
import { FSA } from 'flux-standard-action';

const types = {
  SHARED: {
    CAMPAIGNS: {
      FETCH_CAMPAIGNS: standardActions(),
    },
  },
};

interface Campaign {
  id: number;
  name: number;
}

export const actionTypes = createActions<typeof types>(types).SHARED.CAMPAIGNS;

export function fetchCampaigns(): FSA {
  return {
    type: actionTypes.FETCH_CAMPAIGNS.BEGIN,
  };
}

export function setCampaigns(payload: Campaign[]): FSA<string, Campaign[]> {
  return {
    type: actionTypes.FETCH_CAMPAIGNS.SUCCESS,
    payload,
  };
}

export function failedToFetchCampaigns(): FSA {
  return {
    type: actionTypes.FETCH_CAMPAIGNS.FAILURE,
  };
}
