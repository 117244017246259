import { Record } from 'immutable';

const defaults = {
  stepCount: null,
  finishedAt: null,
  stepIndex: null,
  userId: null,
  name: '',
  disabledAt: null,
  flowOwnerName: '',
  pausedUntil: null,
  id: null,
};

export default class Flow extends Record(defaults) {}
