import { Text } from '@fluentui/react';
import React, { FC } from 'react';

import SearchNoResultsIcon from './SearchNoResultsIcon';

const SearchNoResults: FC = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="flex flex-col justify-center mt-5 itesm-center">
        <Text block className="text-xl font-bold">
          No matching results
        </Text>
      </div>
      <SearchNoResultsIcon className="mt-5 ml-4" />
    </div>
  );
};

export default SearchNoResults;
