import create from 'zustand';

import {
  ConfigurationStore,
  configurationStore,
} from './store/ConfigurationStore';
import { UiStore, uiStore } from './store/UiStore';
import { CallStore, callStore } from './store/CallStore';
import { LogStore, logStore } from './store/LogStore';
import { ChatStore, chatStore } from './store/ChatStore';

export type Store = ChatStore &
  CallStore &
  LogStore &
  UiStore &
  ConfigurationStore;

const useStore = create<Store>((set, get) => ({
  ...configurationStore(set, get),
  ...uiStore(set, get),
  ...callStore(set, get),
  ...logStore(set, get),
  ...chatStore(set, get),
}));

export default useStore;
