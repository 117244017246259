/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash-es';
import { useQuery } from 'react-query';

import { getUsers } from 'GrooveHTTPClient/thinApi/mobo';
import { User } from 'Modules/ActionCompose/constants/actionComposeTypes';

import useMoboEnabled from './useMoboEnabled';

/**
 * React-Select has some string typescript behaviors. This object
 * has three properties: label, value and email
 */

/**
 * Get the options for the Mobo Dropdown
 *
 * When Mobo is not enabled, it will not perform the query and will always
 * return an empty array.
 *
 * @param searchTerm - input from ReactSelect to filter the dropdown options
 *   and reperform the query on change
 *
 * @return an array of options with a `label` (user name), `value` (user id) and email property. Unfortunately
 * react-select has some weird typescript problems which is why returning
 * any as the type is necessary
 */
const useMoboDropdownOptions = (searchTerm: string): any[] => {
  const [options, setOptions] = useState<any[]>([]);
  const isEnabled = useMoboEnabled();
  const { data, refetch } = useQuery(['getUsers'], () => getUsers(searchTerm), {
    enabled: !!isEnabled,
  });
  const debouncedRefetch = useMemo(() => {
    return debounce((searchText: string) => {
      if (searchText.length > 2 || searchText.length === 0) {
        refetch();
      }
    }, 500);
  }, [refetch]);

  useEffect(() => {
    if (isEnabled) {
      debouncedRefetch(searchTerm);
    } else {
      setOptions([]);
    }
  }, [isEnabled, refetch, searchTerm, debouncedRefetch]);

  useEffect(() => {
    if (data) {
      const newOptions = data['mobo/getUsers']?.users.map((u: User) => ({
        label: u.name,
        value: u.id,
        email: u.email,
      }));

      setOptions(newOptions);
    }
  }, [data]);

  return options;
};

export default useMoboDropdownOptions;
