import { SagaIterator } from 'redux-saga';
import { select, put, call } from 'redux-saga/effects';
import { actions } from '@groove-labs/groove-ui';

import { searchByField } from 'GrooveHTTPClient/salesforceAccountSearch';
import { searchByMatchingField } from 'GrooveHTTPClient/salesforceOpportunitiesSearch';
import { actionTypes } from 'Modules/Spaces/import/actions';
import { getWorkspaceType } from 'Modules/Spaces/show/selectors';
import { SPACES_IMPORT_DIALOG_PAGE_UI_KEY_PATH } from 'Modules/Spaces/import/constants';
import { WORKSPACE_TYPES } from 'Modules/Spaces/shared/constants';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { logErrorToSentry } from 'Modules/Shared/sagas/errors';

const { setProperty } = actions.ui;

type SearchByFieldPayload = {
  sfdcFieldName: string;
  sfdcFieldValue: string;
};

function* searchSObjects({
  sfdcFieldName,
  sfdcFieldValue,
}: SearchByFieldPayload): SagaIterator {
  const workspaceType = yield select(getWorkspaceType);

  if (workspaceType === WORKSPACE_TYPES.ACCOUNT) {
    const response = yield call(searchByField, {
      sfdcFieldName,
      sfdcFieldValue,
    });
    return response.data;
  }
  const response = yield call(searchByMatchingField, {
    sfdcFieldName,
    sfdcFieldValue,
  });
  return response.data;
}

function* handleSearchByField({
  sfdcFieldName,
  sfdcFieldValue,
}: SearchByFieldPayload): SagaIterator {
  try {
    yield put({ type: actionTypes.SEARCH_BY_FIELD.PROGRESS });
    const workspaceType = yield select(getWorkspaceType);

    const results = yield call(searchSObjects, {
      sfdcFieldName,
      sfdcFieldValue,
    });

    yield put({
      type: actionTypes.SEARCH_BY_FIELD.SUCCESS,
      payload: {
        results,
        workspaceType,
      },
    });
    yield put(
      setProperty({
        uiKeyPath: SPACES_IMPORT_DIALOG_PAGE_UI_KEY_PATH,
        data: 1,
      })
    );
  } catch (e) {
    yield put({ type: actionTypes.SEARCH_BY_FIELD.FAILURE });
    logErrorToSentry(e);
    yield put(
      pushSnackbarMessage({
        message:
          'There was an issue searching by these fields. Please try again.',
      })
    );
  }
}

export default handleSearchByField;
