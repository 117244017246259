import * as React from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { location } from 'Modules/Shared/selectors/location';
import { MOBO_PAGES } from 'Modules/Shared/constants/mobo';
import CancelIcon from 'Modules/Shared/icons/CancelIcon';
import useStore from 'Modules/App/Store/useStore';
import useVariation from 'Modules/Shared/hooks/useVariation';
import { ModifiedTheme } from 'Utils/theme/ClariTheme';

const useStyles = makeStyles((theme: ModifiedTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.info.light,
    color: `${theme.palette.info.main} !important`,
    fontWeight: 'bold',
    height: '40px',
    zIndex: 100,
    justifyContent: 'center',
  },
  inactive: {
    backgroundColor: theme.palette.text.lightGray,
    color: `${theme.palette.text.primary} !important`,
    height: '40px',
    zIndex: 100,
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    display: 'inline-block',
    textAlign: 'center',
    color: 'inherit',
  },
  cancelIcon: {
    display: 'inline-block',
    cursor: 'pointer',
    height: '30px',
    width: '30px',
    minHeight: '30px',
    minWidth: '30px',
    padding: '8px 8px',
    color: 'inherit',
  },
}));

export const MoboBar: React.FC = () => {
  const { root, active, inactive, container, message, cancelIcon } = useStyles(
    {}
  );
  const moboUser = useStore(state => state.moboUser);
  const clearMoboUser = useStore(state => state.clearMoboUser);
  const current = useSelector(location);
  const isFeatureEnabled = useVariation('mobo');

  const handleCancelClick = () => {
    clearMoboUser();
  };
  const pageIsMoboEnabled = MOBO_PAGES.includes(current.get('rootPath'));
  const routeName = current.get('routeName');

  if (!isFeatureEnabled || !moboUser || routeName === 'actionCompose') {
    return null;
  }

  return (
    <div className={root}>
      <AppBar
        position="static"
        classes={{ root: pageIsMoboEnabled ? active : inactive }}
      >
        <div className={container}>
          <Typography variant="subtitle2" className={message}>
            Managing on Behalf of {moboUser?.name}
            {!pageIsMoboEnabled ? ' is disabled on this page' : null}
          </Typography>
          <Button
            className={cancelIcon}
            role="button"
            tabIndex={-1}
            onClick={handleCancelClick}
          >
            <CancelIcon />
          </Button>
        </div>
      </AppBar>
    </div>
  );
};
