import { takeEvery } from 'redux-saga/effects';
import {
  createSafeActionHandler,
  ANALYTICS_BASE_PROPERTIES,
} from 'Modules/Shared/sagas/analytics';
import { trackEvent } from 'Utils/segment';
import { actionTypes } from 'Modules/FlowsShow/actions';
import { ROUTE_NAMES } from 'Routing/constants';

function* automatedActionConfigured() {
  yield takeEvery(
    actionTypes.CREATE_AUTOMATED_ACTION.SUCCESS,
    createSafeActionHandler(({ payload }) => {
      const { triggerType, type } = payload;
      trackEvent('Automated Action Configured', {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory: ROUTE_NAMES.FLOWS_SHOW,
        trigger: triggerType,
        action: type,
        scope: ROUTE_NAMES.FLOWS,
      });
    })
  );
}

export default automatedActionConfigured;
