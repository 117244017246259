import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const CopyIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 12 16"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <path d="M4 0C2.89543 0 2 0.895431 2 2L2 12C2 13.1046 2.89543 14 4 14H10C11.1046 14 12 13.1046 12 12L12 2C12 0.89543 11.1046 0 10 0L4 0ZM3 2C3 1.44772 3.44772 1 4 1L10 1C10.5523 1 11 1.44772 11 2L11 12C11 12.5523 10.5523 13 10 13H4C3.44772 13 3 12.5523 3 12L3 2ZM0 4.00001C0 3.25973 0.402199 2.61339 1 2.26758L1 12.5C1 13.8807 2.11929 15 3.5 15L9.73244 15C9.38663 15.5978 8.74028 16 8 16L3.5 16C1.567 16 0 14.433 0 12.5L0 4.00001Z" />
  </svg>
);
