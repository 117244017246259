import {
  createActions,
  standardActions,
  keyMirror,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  TROUBLESHOOTING: {
    CHECK_SERVICE_CONNECTIONS: standardActions(),
    ...keyMirror([]),
  },
}).TROUBLESHOOTING;

export const beginCheckingServiceConnection = ({ healthCheck }) => ({
  type: actionTypes.CHECK_SERVICE_CONNECTIONS.BEGIN,
  payload: { healthCheck },
});

export const serviceConnectionCheckInProgress = ({ healthCheck }) => ({
  type: actionTypes.CHECK_SERVICE_CONNECTIONS.PROGRESS,
  payload: { healthCheck },
});

export const serviceConnectionSuccessful = ({ healthCheck }) => ({
  type: actionTypes.CHECK_SERVICE_CONNECTIONS.SUCCESS,
  payload: { healthCheck },
});

export const serviceConnectionCheckFailed = ({ healthCheck }) => ({
  type: actionTypes.CHECK_SERVICE_CONNECTIONS.FAILURE,
  payload: { healthCheck },
});
