import humps from 'humps';
import makeHTTPRequest from '../makeHTTPRequest';
import { POST, DELETE } from '../HTTPMethod';
import { getAdminContentVisibilityEnabled } from 'GrooveHTTPClient/flows';

const BASE_URL = '/api/v2/label_instances';

export const batchCreate = ({ labelIds, labelable, labelOwner }) =>
  makeHTTPRequest({
    path: `${BASE_URL}/batch_create`,
    method: POST,
    body: humps.decamelizeKeys({
      labelIds,
      labelableId: labelable.id,
      labelableType: labelable.type,
      labelOwnerType: labelOwner.type,
      labelOwnerId: labelOwner.id,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    }),
  });

export const batchDestroy = ({ labelInstanceIds }) =>
  makeHTTPRequest({
    path: `${BASE_URL}/batch_destroy`,
    method: DELETE,
    queryParameters: humps.decamelizeKeys({
      labelInstanceIds,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    }),
  });
