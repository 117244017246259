import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { triggerShowReportDialog } from 'Modules/Shared/actions/errors';
import ErrorPage from 'Modules/Shared/components/ErrorStates/ErrorPage';
import TimeoutState from 'Modules/Shared/components/ErrorStates/TimeoutState.tsx';
import { ERROR_TYPES } from 'Modules/Shared/constants';
import { getErrorType, getError } from 'Modules/Error/selectors';
import { showNewMessage as showNewIntercomMessage } from 'Utils/intercom';

@connect(
  state => ({
    error: getError(state),
    errorType: getErrorType(state),
  }),
  {
    triggerShowReportDialog,
  }
)
export default class ModuleRoot extends Component {
  static propTypes = {
    error: PropTypes.instanceOf(window.Error),
    errorType: PropTypes.oneOf(Object.values(ERROR_TYPES)),
  };

  static defaultProps = {
    error: null,
    errorType: null,
  };

  render() {
    const { errorType, error } = this.props;
    switch (errorType) {
      case ERROR_TYPES.REQUEST_TIMEOUT: {
        return (
          <TimeoutState
            primaryText="Request Timeout"
            secondaryText="There was a problem with your request."
            showRecoveryButton
            recoveryButtonText="Reload page"
          />
        );
      }
      case ERROR_TYPES.NETWORK_ERROR: {
        return (
          <TimeoutState
            primaryText="Network Error"
            secondaryText="There was a problem with your request, check your internet connection."
            showRecoveryButton
            recoveryButtonText="Try again"
          />
        );
      }
      case ERROR_TYPES.INVALID_SALESFORCE_META: {
        let secondaryText =
          'There was an issue gathering your SFDC data. Please contact Groove support.';

        if (error.name === 'MissingSalesforceObjectError') {
          secondaryText = `Your Salesforce account does not have access to the ${error.objectType} object. Please contact your Salesforce Admin or reach out to Groove support.`;
        }
        return (
          <ErrorPage
            primaryText="Salesforce Error"
            secondaryText={secondaryText}
            onRecovery={showNewIntercomMessage}
            showRecoveryButton
            recoveryButtonText="Contact Support"
          />
        );
      }
      case ERROR_TYPES.SFDC_REQUEST_LIMIT_EXCEEDED: {
        return (
          <ErrorPage
            primaryText="Salesforce Error: API limit reached"
            secondaryText={
              'Your Salesforce organization can no longer connect to Groove. Please contact your Salesforce Admin or reach out to Groove support.'
            }
            onRecovery={showNewIntercomMessage}
            showRecoveryButton
            recoveryButtonText="Contact Support"
          />
        );
      }
      case ERROR_TYPES.UNHANDLED_HTTP_ERROR:
      case ERROR_TYPES.GENERIC:
      case null:
      default: {
        return <ErrorPage showRecoveryButton showSubmitFeedbackButton />;
      }
    }
  }
}
