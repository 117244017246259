import keyMirror from 'Utils/key-mirror';

export const actionTypes = keyMirror(
  {
    LOAD_DATA: null,
    ADD_FILTER: null,
    BULK_ADD_FILTER: null,
    DELETE_FILTER: null,
    UPDATE_FILTER: null,
    CLEAR_FILTERS: null,

    // Saved search actions
    ENABLE_SAVED_SEARCH: null,
    SELECT_SAVED_SEARCH: null,
    SAVE_SEARCH: null,
    UPDATE_SAVED_SEARCH: null,
    DELETE_SAVED_SEARCH: null,

    // TODO These saga-specific actions are so weird.
    REQUEST_CREATE_FILTER: null,
    REQUEST_DELETE_FILTER: null,
    REQUEST_SEARCH: null,
  },
  'PEOPLE_IMPORT_DIALOG',
  'ADVANCED_SEARCH'
);

export function loadData(data) {
  return {
    type: actionTypes.LOAD_DATA,
    payload: data,
  };
}

export function addFilter(groupId, filter) {
  return {
    type: actionTypes.ADD_FILTER,
    payload: { groupId, filter },
  };
}

export function bulkAddFilter(filters) {
  return {
    type: actionTypes.BULK_ADD_FILTER,
    payload: filters,
  };
}

export function updateFilter(groupId, updates) {
  return {
    type: actionTypes.UPDATE_FILTER,
    payload: { groupId, updates },
  };
}

export function deleteFilter(groupId) {
  return {
    type: actionTypes.DELETE_FILTER,
    payload: groupId,
  };
}

export function requestCreateFilter() {
  return {
    type: actionTypes.REQUEST_CREATE_FILTER,
  };
}

export function requestDeleteFilter(groupId) {
  return {
    type: actionTypes.REQUEST_DELETE_FILTER,
    payload: groupId,
  };
}

export function requestSearch(payload = {}) {
  return {
    type: actionTypes.REQUEST_SEARCH,
    payload,
  };
}

export function clearFilters() {
  return {
    type: actionTypes.CLEAR_FILTERS,
  };
}

export function requestEnableSavedSearch() {
  return {
    type: actionTypes.ENABLE_SAVED_SEARCH,
  };
}

export function requestUpdateSelectedSearch(savedSearch) {
  return {
    type: actionTypes.SELECT_SAVED_SEARCH,
    payload: savedSearch,
  };
}

export function saveSearch() {
  return {
    type: actionTypes.SAVE_SEARCH,
  };
}

export function updateSavedSearch() {
  return {
    type: actionTypes.UPDATE_SAVED_SEARCH,
  };
}

export function deleteSavedSearch() {
  return {
    type: actionTypes.DELETE_SAVED_SEARCH,
  };
}
