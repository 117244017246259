import { FC, useEffect, useState } from 'react';
import { CloseIcon, SettingsIcon } from '@groove/ui/Components/BoogieIcon';
import { IconButton } from '@fluentui/react';
import Text from '@groove/ui/Components/Text';
import DropDownList, {
  StandardDropDownItem,
} from '@groove/ui/Components/DropDownList';
import MultiSelect from '@groove/ui/Components/MultiSelect';
import { debounce } from 'lodash-es';
import { useQuery } from 'react-query';
import {
  MeetingTemplateItem,
  ScheduleForItem,
  getSearchUser,
  meetingRequestTemplates,
  scheduleFor,
  searchUsers,
} from '@groove/api/gateway/v1/users';
import Button from '@groove/ui/Components/Button';
import { useGetCurrentUser } from '@groove/api/hooks/useUsers';
import PicklistNew from '@groove/ui/Components/PicklistNew';
import { ApiUsers } from '@groove/api/v2/users';
import Input from '@groove/ui/Components/Input';

import useStore from '../store/useStore';
import { SCHEDULER_SETTINGS_URL } from '../constants';

export type ScheduleForHash = {
  [email: string]: ScheduleForItem;
};

export type MeetingTemplateHash = {
  [id: string]: MeetingTemplateItem;
};

const SchedulerLite: FC = () => {
  const [scheduleForSearch, setScheduleForSearch] = useState('');
  const [scheduleForHash, setScheduleForHash] = useState<ScheduleForHash>({});
  const [selectedScheduleFor, setSelectedScheduleFor] =
    useState<ScheduleForItem>();
  const [scheduleForItems, setScheduleForItems] = useState<
    StandardDropDownItem[]
  >([]);
  const [meetingTemplateHash, setMeetingTemplateHash] =
    useState<MeetingTemplateHash>({});
  const [meetingTemplateItems, setMeetingTemplateItems] = useState<
    StandardDropDownItem[]
  >([]);
  const [selectedMeetingTemplate, setSelectedMeetingTemplate] = useState<
    StandardDropDownItem[]
  >([]);
  const [teammateItems, setTeammateItems] = useState<StandardDropDownItem[]>(
    [],
  );
  const [selectedTeammates, setSelectedTeammates] = useState<
    StandardDropDownItem[]
  >([]);
  const [customizedLinkText, setCustomizedLinkText] = useState(
    'Book a meeting with me',
  );

  const type = useStore(store => store.action.type);
  const assigneeId = useStore(store => store.action?.assignee?.id);

  const noHtml =
    type === 'STEP_SUBTYPE_LINKEDIN_CONNECT' ||
    type === 'STEP_SUBTYPE_LINKEDIN_INMAIL' ||
    type === 'STEP_SUBTYPE_SMS';

  // For Select For Input
  const { data: currentUserData } = useGetCurrentUser();

  const handleSelectedScheduleFor = (item: ScheduleForItem): void => {
    setSelectedScheduleFor(item);
    useStore.getState().setOtherValues({ scheduleForId: item?.id?.toString() });
  };

  const onScheduleForSuccess = (data: ScheduleForItem[]): void => {
    const hash: ScheduleForHash = {};
    const assignedToItems: StandardDropDownItem[] = (data || []).map(item => {
      const newKey = `${item.type}:${item.id}`;
      hash[newKey] = item;

      if (item.id === selectedScheduleFor?.id) handleSelectedScheduleFor(item);

      return {
        value: newKey,
        key: newKey,
        text: item.name,
        secondaryText: item.name && item.email ? [item.email] : undefined,
      };
    });
    setScheduleForHash(hash);
    setScheduleForItems(assignedToItems);
  };

  useQuery(
    ['schedule-for', scheduleForSearch],
    () => scheduleFor(scheduleForSearch, { limit: 20 }),
    {
      onSuccess: onScheduleForSuccess,
      enabled: scheduleForSearch.length >= 2,
      cacheTime: 10 * 60 * 1000, // 10 minutes because the list of users at the company is definitely not change very often and you would know if they were no longer part of the company
      staleTime: 10 * 60 * 1000,
    },
  );

  useQuery(['schedule-for'], () => scheduleFor('', { limit: 20 }), {
    onSuccess: onScheduleForSuccess,
    enabled: scheduleForSearch.length < 2,
    cacheTime: 10 * 60 * 1000, // 10 minutes because the list of users at the company is definitely not change very often and you would know if they were no longer part of the company
    staleTime: 10 * 60 * 1000,
  });

  const { data: useData } = useQuery(
    ['assigned-user', assigneeId],
    () => getSearchUser(assigneeId || ''),
    {
      enabled: !!assigneeId,
    },
  );

  useEffect(() => {
    if (useData?.data) {
      const { id, name, email, meeting_custom_link } = useData.data;
      handleSelectedScheduleFor({
        id,
        email,
        name,
        type: 'user',
        meeting_custom_link: meeting_custom_link || '',
      });
    }
  }, [useData]);

  const onScheduleForChange = (item: StandardDropDownItem | null): void => {
    if (item) handleSelectedScheduleFor(scheduleForHash[item.value]);
  };

  let scheduleForText = 'None';
  if (selectedScheduleFor) {
    scheduleForText = 'Unknown User';
    if (selectedScheduleFor.name) {
      scheduleForText = `${selectedScheduleFor.name}`;
    } else if (selectedScheduleFor.email) {
      scheduleForText = selectedScheduleFor.email;
    }
  }

  // For Meeting Templates/Types
  const handleSelectedMeetingTemplate = (item: StandardDropDownItem): void => {
    setSelectedMeetingTemplate([item]);
    const meetingTypeId =
      meetingTemplateHash[selectedMeetingTemplate?.[0]?.value]?.id || -1;
    useStore.getState().setOtherValues({ meetingTypeId });
  };

  let selectedUserId = selectedScheduleFor?.id || '';

  if (selectedScheduleFor?.type === 'group')
    selectedUserId = currentUserData?.id || '';

  const { data: meetingData } = useQuery(
    ['meeting-type', selectedUserId],
    () => meetingRequestTemplates(selectedUserId),
    {
      enabled: !!selectedUserId,
      cacheTime: 10 * 60 * 1000, // 10 minutes because the list of users at the company is definitely not change very often and you would know if they were no longer part of the company
      staleTime: 10 * 60 * 1000,
    },
  );

  useEffect(() => {
    if (!meetingData) return;
    const hash: MeetingTemplateHash = {};
    const newMeetingTemplateItems = meetingData.map(item => {
      hash[item.id] = item;
      return {
        value: item.id,
        key: item.id,
        text: item.name || '',
        secondaryText: item.description ? [item.description] : undefined,
      };
    });
    setMeetingTemplateHash(hash);
    setMeetingTemplateItems(newMeetingTemplateItems);
    handleSelectedMeetingTemplate(newMeetingTemplateItems[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(meetingData)]);

  useEffect(() => {
    useStore.getState().setOtherValues({
      schedulerAttendees: selectedTeammates.map(teammate => ({
        name: teammate.text,
        secondaryText: teammate.value,
        text: teammate.text,
        key: teammate.key,
      })),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedTeammates)]);

  // For Teammates Search
  const onTeammateSuccess = (data: ApiUsers): void => {
    const teammateItems: StandardDropDownItem[] = [];
    (data?.data || []).forEach(user => {
      if (!selectedTeammates.find(teammate => teammate.value === user.email))
        teammateItems.push({
          value: user.email || '',
          key: user.id,
          text: user.name || user.email,
          secondaryText: user.name && user.email ? [user.email] : undefined,
        });
    });
    setTeammateItems(teammateItems);
  };

  useQuery(
    ['teammate-search', scheduleForSearch],
    () => searchUsers(scheduleForSearch, { limit: 20 }),
    {
      onSuccess: onTeammateSuccess,
      enabled: scheduleForSearch.length >= 2,
      cacheTime: 10 * 60 * 1000, // 10 minutes because the list of users at the company is definitely not change very often and you would know if they were no longer part of the company
      staleTime: 10 * 60 * 1000,
    },
  );

  useQuery(['teammate-search'], () => searchUsers('', { limit: 20 }), {
    onSuccess: onTeammateSuccess,
    enabled: scheduleForSearch.length < 2,
    cacheTime: 10 * 60 * 1000, // 10 minutes because the list of users at the company is definitely not change very often and you would know if they were no longer part of the company
    staleTime: 10 * 60 * 1000,
  });

  const removeTeammate = (index: number): void => {
    setTeammateItems([selectedTeammates[index], ...teammateItems]);
    setSelectedTeammates([
      ...selectedTeammates.slice(0, index),
      ...selectedTeammates.slice(index + 1),
    ]);
  };

  // For the Action Buttons
  const onInsertLink = (): void => {
    if (!selectedScheduleFor || !selectedMeetingTemplate) return;
    const url = new URL(
      process.env.NX_GROOVE_ENGINE_ROOT_URL || 'http://localhost:3000',
    );
    url.pathname = `meetings/${selectedScheduleFor.meeting_custom_link}/${
      meetingTemplateHash[selectedMeetingTemplate?.[0].value].custom_link
    }`;

    if (selectedTeammates.length > 0) {
      url.searchParams.append(
        'invite',
        selectedTeammates.map(user => btoa(user.value.toString())).join(','),
      );
    }

    if (noHtml) {
      useStore
        .getState()
        .insertAtCursorAndUpdateAction({ body: url.href || '' });
    } else {
      const linkElement = document.createElement('a');
      linkElement.setAttribute('rel', 'noopener');
      linkElement.setAttribute('href', url.href);
      linkElement.innerHTML = customizedLinkText || url.href;
      useStore
        .getState()
        .insertAtCursorAndUpdateAction({ body: linkElement.outerHTML || '' });
    }

    useStore.getState().setOtherValues({ taskPaneType: 'TASK' });
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex flex-row pl-[12px] pr-[4px] pt-[6px] items-center">
        <Text className="flex-1 text-body-lg">Groove Scheduler</Text>
        <IconButton
          className="text-white disabled:bg-primary disabled:text-gray1 hover:text-primary"
          ariaLabel="Open Groove Scheduler Settings"
          href={SCHEDULER_SETTINGS_URL}
          target="_blank"
        >
          <SettingsIcon className="text-neutral/900 w-[18px]" />
        </IconButton>
        <IconButton
          className="text-white disabled:bg-primary disabled:text-gray1 hover:text-primary"
          ariaLabel="Close Action Compose Pane"
          onClick={() => {
            useStore.getState().setOtherValues({
              taskPaneType: 'TASK',
              isExtendedTaskPaneOpen: false,
            });
          }}
        >
          <CloseIcon className="text-neutral/900 w-[20px]" />
        </IconButton>
      </div>
      <div className="px-[12px]">
        <Text variant="body-sm" className="mb-[4px] mt-[8px] font-semibold">
          Schedule for
        </Text>
        <DropDownList
          className="w-full mb-[4px]"
          hasSearch
          onSearchChange={debounce(setScheduleForSearch, 200)}
          onChange={onScheduleForChange}
          items={scheduleForItems}
          renderedText={scheduleForText}
        />
        <Text variant="body-sm" className="mb-[4px] mt-[8px] font-semibold">
          Meeting type
        </Text>
        <PicklistNew
          className="w-full mb-[4px]"
          selectedValues={selectedMeetingTemplate}
          items={meetingTemplateItems}
          onChange={item => {
            if (item) handleSelectedMeetingTemplate(item);
          }}
        />
        <Text variant="body-sm" className="mb-[4px] mt-[8px] font-semibold">
          Add teammates
        </Text>
        <MultiSelect
          className="w-full mb-[4px]"
          onSearchChange={debounce(setScheduleForSearch, 200)}
          selectedValues={selectedTeammates}
          onSelect={item => {
            if (!item) return;
            setSelectedTeammates([...selectedTeammates, item]);
            setTeammateItems(
              teammateItems.filter(tempItem => item.value !== tempItem.value),
            );
          }}
          placeholder="Search for a teammate"
          items={teammateItems}
          onRemove={removeTeammate}
          listHeader={
            !teammateItems || teammateItems.length < 1 ? (
              <text>No teammates found</text>
            ) : undefined
          }
        />
        <Text variant="body-sm" className="mb-[4px] mt-[8px] font-semibold">
          Customize link text
        </Text>
        <Input
          className="w-full mb-[4px]"
          value={customizedLinkText}
          onChange={e => setCustomizedLinkText(e.target.value || '')}
        />
        <div className="flex flex-row pt-[12px]">
          <div className="flex-1" />
          <Button
            onClick={() =>
              useStore
                .getState()
                .setExternalModalValues({ isSchedulerOpen: true })
            }
            className="my-2"
            variant="secondary"
            size="small"
          >
            Add specific times
          </Button>
          <Button
            onClick={onInsertLink}
            className="my-2 ml-2"
            variant="secondary"
            size="small"
            disabled={
              !selectedScheduleFor ||
              !selectedMeetingTemplate ||
              selectedMeetingTemplate.length < 1
            }
          >
            Insert link
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SchedulerLite;
