import {
  ApiBestLocalNumber,
  LOCAL_DIAL_PARSE_ERROR,
  LOCAL_DIAL_PROVISION_ERROR,
} from '@groove/api/gateway/v1/commonDialer';

import useStore from '../../store/useStore';
// Set the warning messages for not being able to get local best number
export const dialerErrorValidation = (
  localPhoneData: ApiBestLocalNumber | undefined,
  isLocalDialEnabled: boolean,
): void => {
  useStore.getState().removeMessage(LOCAL_DIAL_PARSE_ERROR);
  useStore.getState().removeMessage(LOCAL_DIAL_PROVISION_ERROR);

  if (localPhoneData?.message_id === LOCAL_DIAL_PARSE_ERROR) {
    useStore.getState().addMessage({
      id: LOCAL_DIAL_PARSE_ERROR,
      level: 'warning',
      message:
        'Invalid recipient phone number. Please select a valid phone number.',
      noCloseButton: true,
    });
  } else if (localPhoneData?.message_id === LOCAL_DIAL_PROVISION_ERROR) {
    if (!isLocalDialEnabled) return;

    let message = `No phone number available with country code ${localPhoneData.country_code}. Please contact your sales admin or Groove support to purchase a local number.`;

    if (localPhoneData.area_code)
      message = `No phone number available with area code ${localPhoneData.area_code}. Please contact your sales admin or Groove support to purchase a local number.`;

    useStore.getState().addMessage({
      id: LOCAL_DIAL_PROVISION_ERROR,
      level: 'warning',
      message,
      noCloseButton: true,
    });
  }
};
