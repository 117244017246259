import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { greyContent, pulse, shimmer } from 'Modules/Shared/styles/shimmerBar';

const useStyles = makeStyles(() => ({
  statContainer: {
    display: 'flex',
    marginTop: 12,
    marginBottom: 12,
  },
  statCard: {
    marginRight: 24,
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '24px',
  },
  loadingBar: {
    ...greyContent,
    height: '20px',
    display: 'left',
    margin: '20px',
  },
  loading: {
    ...pulse,
  },
  ...shimmer,
}));

type LoaderChartPropTypes = {
  failed: boolean;
};

const LoaderChart: React.FC<LoaderChartPropTypes> = ({ failed }) => {
  const classes = useStyles({});
  const bars = Array.from({ length: 5 }, (e, i) => 5 - i);

  return (
    <div className={classes.statContainer}>
      <Card className={classes.statCard}>
        <CardContent className={classes.cardContent}>
          {bars.map(value => (
            <div
              key={value}
              className={`${classes.loadingBar} ${
                failed ? null : classes.loading
              }`}
              style={{ width: `${value * 15}%` }}
            />
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default LoaderChart;
