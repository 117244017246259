import keyMirror from 'Utils/key-mirror';

export const actionTypes = keyMirror(
  {
    UPDATE_SEARCH_QUERY: null,
  },
  'PEOPLE_IMPORT_DIALOG',
  'BASIC_SEARCH'
);

export function updateSearchQuery(query) {
  return {
    type: actionTypes.UPDATE_SEARCH_QUERY,
    payload: query,
  };
}
