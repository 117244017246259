import {
  DefaultButton,
  Dropdown,
  IPersonaProps,
  Label,
  NormalPeoplePicker,
  PeoplePickerItem,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Text,
  TextField,
  Toggle,
  TooltipHost,
  ValidationState,
  VirtualizedComboBox,
} from '@fluentui/react';
import { theme } from '@groove/ui/theme';
import { FC, useEffect, useState } from 'react';
import randomcolor from 'randomcolor';
import { useQuery } from 'react-query';
import {
  meetingTypes,
  MeetingType,
  ResponseTimeSlot,
  scheduleFor as organizationUsers,
} from '@groove/api/services/v1/scheduler';
import { meetingProposalCreate } from '@groove/api/services/v1/meetingProposal';
import { userGet, userHasZoomEmail } from '@groove/api/services/v1/user';
import { memoizeFunction } from '@fluentui/react/lib/Utilities';
import { useIdentifyMixpanelUser } from '@groove/api/analytics/hooks/useMixpanel';

import {
  SCHEDULER_SCHEDULED_FOR,
  trackSchedulerEvent,
} from './analytics/event';
import ZoomLogo from './assets/zoom_logo.png';
import GoogleMeetLogo from './assets/google_meet_logo.svg';
import Calendar from './Calendar/Calendar';
import {
  DURATIONS,
  ONE_OFF_MEETING_OBJECT,
  TIME_ZONES,
  VIDEO_CONFERENCINGS,
} from './constants';
import useStore from './store/useStore';

const getPeoplePickerStyles = memoizeFunction(backgroundColor => ({
  root: {
    backgroundColor: backgroundColor || theme['secondary-light'],
  },
}));

const pickerStyles = { text: 'rounded border-gray3' };
const dropDownStyles = { title: 'rounded border-gray3' };
const separatorStyles = { root: 'before:bg-gray4 p-0' };
const fieldGroupStyles = { fieldGroup: 'rounded border-gray3' };
const comboBoxStyles = {
  root: 'after:rounded after:border-gray3',
};

export type TimeSlotReponseType = {
  time_slots: ResponseTimeSlot[];
  time_zone: string;
  schedule_for: string;
  schedule_for_meeting_link: string;
  plain_text_enabled: boolean;
};

type SchedulerProps = {
  storeScheduleForId?: string;
  storeMeetingTypeId?: number;
  storeSchedulerAttendees?: IPersonaProps[];
  primaryRecipient?: string;
  onDismiss?: () => void;
  onSubmit: (data: TimeSlotReponseType) => void;
};
type VideoConferencingKeys = 'hangouts' | 'zoom' | 'msft_teams' | '';

const Scheduler: FC<SchedulerProps> = ({
  storeScheduleForId,
  storeMeetingTypeId,
  storeSchedulerAttendees,
  primaryRecipient,
  onSubmit,
  onDismiss,
}) => {
  const { data: usersData } = useQuery('organizationUsers', organizationUsers);
  const { data: userData } = useQuery('user', userGet);

  const [attendees, setAttendees] = useState<IPersonaProps[]>([]);
  const [title, setTitle] = useState<string | null>(null);
  const [location, setLocation] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [duration, setDuration] = useState<number | null>(30);
  const [timeZone, setTimeZone] = useState<string | null>(null);
  const [allowDoubleBooking, setAllowDoubleBooking] = useState<boolean>(false);
  const [scheduledForAnotherUser, setScheduledForAnotherUser] =
    useState<boolean>(false);
  const [savedMeetingApplied, setSavedMeetingApplied] = useState<MeetingType>(
    ONE_OFF_MEETING_OBJECT,
  );
  const [plainTextEnabled, setPlainTextEnabled] = useState<boolean>(false);
  const [isEventsLoading, setIsEventsLoading] = useState<boolean>(true);
  const [videoConferencing, setVideoConferencing] =
    useState<VideoConferencingKeys>('');
  const [scheduleForMeetingLink, setScheduleForMeetingLink] =
    useState<string>('');
  const [meetingTypesOptions, setMeetingTypesOptions] = useState<
    MeetingType[] | undefined
  >([]);

  useIdentifyMixpanelUser();

  const [matchingOptions, setMatchingOptions] = useState<
    { text: string; key: string }[]
  >([...TIME_ZONES]);

  const [durationUpdated, setDurationUpdated] = useState(false);
  const [titleUpdated, setTitleUpdated] = useState(false);
  const [timeZoneUpdated, setTimeZoneUpdated] = useState(false);

  const validTimeSlots = useStore(store => store.validTimeSlots);
  const allTimeSlots = useStore(store => store.timeSlots);
  const timeSlots = allowDoubleBooking ? allTimeSlots : validTimeSlots;

  const applyMeeting = (meeting: MeetingType): void => {
    setSavedMeetingApplied(meeting);
    setTitle(meeting.subject);
    setLocation(meeting.location);
    setDescription(meeting.description);
    setDuration(meeting.duration / 60);
    setVideoConferencing(meeting.video_conferencing as VideoConferencingKeys);
  };

  const allUsers =
    usersData?.data.map(
      user =>
        ({
          key: user.id,
          text: user.name,
          secondaryText: user.email,
          color: randomcolor({ luminosity: 'light', seed: user.id }),
        } as IPersonaProps),
    ) || [];

  const currentUser = userData?.data;

  const [scheduleFor, setScheduleFor] = useState<IPersonaProps | null>(
    storeScheduleForId
      ? allUsers.find(user => user.key?.toString() === storeScheduleForId) ||
          null
      : null,
  );

  const { data: meetingTypesData, isLoading: isMeetingTypesLoading } = useQuery(
    ['meeting-types', scheduleFor],
    () => meetingTypes(scheduleFor?.key as number, primaryRecipient),
    { enabled: !!scheduleFor },
  );

  useEffect(() => {
    if (meetingTypesData?.data) {
      setMeetingTypesOptions([
        ONE_OFF_MEETING_OBJECT,
        ...meetingTypesData?.data,
      ]);
    }
  }, [meetingTypesData]);

  useEffect(() => {
    if (storeMeetingTypeId) {
      const meeting = meetingTypesOptions?.find(
        meeting => meeting.id === storeMeetingTypeId,
      );
      if (meeting) {
        applyMeeting(meeting);
      }
    }
  }, [storeMeetingTypeId, meetingTypesOptions]);

  useEffect(() => {
    if (storeSchedulerAttendees) {
      setAttendees(storeSchedulerAttendees);
    }
  }, [storeSchedulerAttendees]);

  const { data: hasZoomEmail } = useQuery(
    ['hasZoomEmail', scheduleFor],
    () => userHasZoomEmail(scheduleFor?.key as number),
    { enabled: !!scheduleFor },
  );

  useEffect(() => {
    if (scheduleFor) {
      const meetingLink = usersData?.data.find(
        user => user.id.toString() === scheduleFor.key?.toString(),
      )?.meeting_custom_link;
      setScheduleForMeetingLink(meetingLink || '');
    }
  }, [scheduleFor, usersData?.data]);

  useEffect(() => {
    if (currentUser) {
      setScheduleFor({ key: currentUser?.id, text: currentUser?.name });
      setTimeZone(currentUser?.time_zone || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id]);

  let selectedUserIds = (attendees || []).map(attendee => attendee.key);
  if (scheduleFor) {
    selectedUserIds.push(scheduleFor?.key);
  }
  selectedUserIds = Array.from(new Set(selectedUserIds));

  const userColors: { [key: number]: string } = {};
  allUsers.forEach(
    user =>
      (userColors[user.key as number] = user.color || theme['neutral/200']),
  );

  userColors[scheduleFor?.key as number] = theme['neutral/200'];

  const handleInsertTimes = async (): Promise<void> => {
    trackSchedulerEvent({
      eventName: 'Time Blocks Inserted',
      scheduledFor: scheduledForAnotherUser
        ? SCHEDULER_SCHEDULED_FOR.TEAMMATE
        : SCHEDULER_SCHEDULED_FOR.SELF,
      meetingTypeUsed:
        savedMeetingApplied?.name === ONE_OFF_MEETING_OBJECT.name
          ? 'none'
          : savedMeetingApplied?.name,
      numOfTeammatesAdded: attendees?.length || 0,
      titleUpdated,
      durationUpdated,
      locationUpdated: !!location,
      descriptionUpdated: !!description,
      videoConferencingUsed: videoConferencing || 'none',
      timeZoneUpdated,
      doubleBookingEnabled: allowDoubleBooking,
      plainTextUsed: plainTextEnabled,
    });
    const response = await meetingProposalCreate(
      scheduleFor,
      attendees,
      title,
      location || '',
      description || '',
      timeZone,
      Array.from(timeSlots, ([_key, timeSlot]) => ({
        starts_at: timeSlot.start.getTime() / 1000,
        ends_at: timeSlot.end.getTime() / 1000,
      })),
      allowDoubleBooking,
      videoConferencing ? VIDEO_CONFERENCINGS[videoConferencing] : null,
    );
    onSubmit({
      time_slots: response.data.time_slots,
      time_zone: timeZone || userData?.data.time_zone || 'UTC',
      schedule_for: scheduleFor?.text || '',
      schedule_for_meeting_link: scheduleForMeetingLink || '',
      plain_text_enabled: plainTextEnabled,
    });
  };

  const onTimeZoneInputChange = (value: string): void => {
    const compareValue = value?.trim();
    const matches = TIME_ZONES.filter(option =>
      option.text.toLowerCase().includes(compareValue.toLowerCase()),
    );
    setMatchingOptions(matches);
  };

  return (
    <div className="flex overflow-y-hidden w-full h-full">
      <div className="mr-3 ml-3 flex flex-col w-[300px] overflow-y-auto h-auto">
        <Text variant="large" className="mt-2.5">
          Meeting Details
        </Text>
        <div>
          <Label htmlFor="schedule-for">Schedule For</Label>
          <NormalPeoplePicker
            styles={pickerStyles}
            onResolveSuggestions={query =>
              allUsers.filter(user =>
                user?.text?.toLowerCase().includes(query.toLowerCase()),
              )
            }
            onEmptyResolveSuggestions={() => allUsers}
            selectedItems={scheduleFor ? [scheduleFor] : []}
            onChange={selected => {
              setScheduledForAnotherUser(
                selected?.length ? selected[0].key !== currentUser?.id : false,
              );
              setScheduleFor(selected ? selected[0] : null);
            }}
            itemLimit={1}
            inputProps={{ id: 'schedule-for' }}
            onRenderItem={props => (
              <PeoplePickerItem
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                item={{
                  ...props.item,
                  ValidationState: ValidationState.valid,
                }}
                removeButtonAriaLabel="remove-user-button"
              />
            )}
          />
        </div>
        <Dropdown
          styles={dropDownStyles}
          label="Meeting Type"
          options={
            meetingTypesOptions?.map(item => ({
              key: item.id,
              text: item.name,
            })) || []
          }
          onChange={(_e, option) => {
            const meeting = meetingTypesOptions?.find(
              item => item.id === option?.key,
            );
            if (meeting) {
              applyMeeting(meeting);
            }
          }}
          selectedKey={savedMeetingApplied.id}
        />
        <div>
          <Label htmlFor="other-attendees">Add Teammates</Label>
          <NormalPeoplePicker
            styles={pickerStyles}
            onResolveSuggestions={query =>
              allUsers.filter(
                user =>
                  user?.text?.toLowerCase().includes(query.toLowerCase()) &&
                  !attendees.some(attendee => attendee.key === user.key),
              )
            }
            onRenderItem={props => (
              <PeoplePickerItem
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                item={{
                  ...props.item,
                  ValidationState: ValidationState.valid,
                }}
                styles={getPeoplePickerStyles(
                  props.item.key ? userColors[props.item.key as number] : null,
                )}
                removeButtonAriaLabel={`remove-attendee-button-${props.item.key}`}
              />
            )}
            selectedItems={attendees}
            onChange={selected => setAttendees(selected || [])}
            onEmptyResolveSuggestions={() =>
              allUsers.filter(
                user =>
                  user.key !== scheduleFor?.key &&
                  !attendees.some(attendee => attendee.key === user.key),
              )
            }
            inputProps={{
              id: 'other-attendees',
            }}
          />
        </div>
        <Separator styles={separatorStyles} />
        <TextField
          styles={fieldGroupStyles}
          label="Meeting Title"
          value={title || ''}
          onChange={(e, newValue) => {
            setTitle(newValue || '');
            setTitleUpdated(true);
          }}
          required
        />
        <Dropdown
          styles={dropDownStyles}
          options={DURATIONS}
          label="Duration"
          selectedKey={duration}
          onChange={(_e, option) => {
            setDurationUpdated(true);
            setDuration((option?.key as number) || null);
          }}
        />
        <div>
          <Label htmlFor="video-conferencing">Video Conferencing</Label>
          <div className="flex justify-between w-[100%]">
            {currentUser?.provider === 'microsoft_graph_auth' && (
              <DefaultButton
                toggle
                aria-label="MSFT Teams Button"
                checked={videoConferencing === 'msft_teams'}
                onClick={() => {
                  setVideoConferencing(
                    videoConferencing !== 'msft_teams' ? 'msft_teams' : '',
                  );
                }}
                className={`w-[100%] px-2 ${
                  videoConferencing === 'msft_teams'
                    ? 'bg-white border-2 border-primary'
                    : 'bg-gray4 opacity-60'
                }`}
              >
                <img
                  src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/teams_48x1.svg"
                  width="24"
                  height="24"
                  alt="Teams product icon"
                />
                <Text className="m-1 font-medium text-[12px]">
                  Microsoft Teams
                </Text>
              </DefaultButton>
            )}
            {currentUser?.provider === 'google_oauth2' && (
              <DefaultButton
                toggle
                aria-label="Google Meet Button"
                checked={videoConferencing === 'hangouts'}
                onClick={() => {
                  setVideoConferencing(
                    videoConferencing !== 'hangouts' ? 'hangouts' : '',
                  );
                }}
                className={`w-[100%] px-2 ${
                  videoConferencing === 'hangouts'
                    ? 'bg-white border-2 border-primary'
                    : 'bg-gray4 opacity-60'
                }`}
              >
                <img
                  src={GoogleMeetLogo}
                  width="24"
                  height="24"
                  alt="Google Meet logo"
                />
                <Text className="m-1 font-medium text-[12px]">Google Meet</Text>
              </DefaultButton>
            )}
            {hasZoomEmail?.data && (
              <DefaultButton
                toggle
                aria-label="Zoom Button"
                className={`w-[100%] px-2 ml-1 ${
                  videoConferencing === 'zoom'
                    ? 'bg-white border-2 border-primary'
                    : 'bg-gray4 opacity-60'
                }`}
                checked={videoConferencing === 'zoom'}
                onClick={() => {
                  setVideoConferencing(
                    videoConferencing !== 'zoom' ? 'zoom' : '',
                  );
                }}
              >
                <img className="h-3" src={ZoomLogo} alt="Zoom logo" />
              </DefaultButton>
            )}
          </div>
        </div>
        <TextField
          styles={fieldGroupStyles}
          label="Location"
          value={location || ''}
          placeholder={
            videoConferencing && !location
              ? 'This will populate with the video conferencing link'
              : ''
          }
          onChange={(e, newValue) => setLocation(newValue || '')}
        />
        <TextField
          styles={fieldGroupStyles}
          multiline
          label="Description"
          value={description || ''}
          onChange={(e, newValue) => setDescription(newValue || '')}
        />
        <TooltipHost
          directionalHint={0}
          content="Use prospect's timezone for best scheduling results"
        >
          <VirtualizedComboBox
            styles={comboBoxStyles}
            allowFreeform
            autoComplete="on"
            className="mb-2"
            options={matchingOptions}
            label="Timezone (displayed in invitation)"
            selectedKey={timeZone}
            onInputValueChange={value => {
              onTimeZoneInputChange(value);
              setTimeZoneUpdated(true);
            }}
            onChange={(_e, option) =>
              setTimeZone((option?.key as string) || null)
            }
          />
        </TooltipHost>
        <TooltipHost
          directionalHint={0}
          content="Allows recipients to book during existing meetings"
        >
          <Toggle
            inlineLabel
            label="Enable Double Booking"
            checked={allowDoubleBooking}
            onChange={(e, newValue) => setAllowDoubleBooking(newValue || false)}
          />
        </TooltipHost>
        <TooltipHost
          directionalHint={0}
          content="Available meeting times will be shown as plain text"
        >
          <Toggle
            inlineLabel
            label="Insert as plain Text"
            checked={plainTextEnabled}
            onChange={(e, newValue) => setPlainTextEnabled(newValue || false)}
          />
        </TooltipHost>
      </div>
      <div className="flex flex-col flex-grow">
        <Text variant="large" className="my-2.5">
          Choose Available Times
        </Text>
        <Calendar
          duration={duration}
          timeZone={currentUser?.time_zone || null}
          userIds={selectedUserIds as number[]}
          userColors={userColors}
          allowDoubleBooking={allowDoubleBooking}
          onIsEventsLoading={isLoading => setIsEventsLoading(isLoading)}
        />
        <div className="flex justify-end px-2 pt-4 pb-2">
          {onDismiss && (
            <DefaultButton
              ariaLabel="Cancel"
              className="mr-4"
              onClick={onDismiss}
            >
              Cancel
            </DefaultButton>
          )}
          <PrimaryButton
            ariaLabel="Insert"
            className="rounded disabled:bg-gray6 disabled:text-gray2"
            onClick={handleInsertTimes}
            disabled={!title?.trim() || !timeSlots.size}
          >
            Insert Times
          </PrimaryButton>
        </div>
      </div>
      {(isMeetingTypesLoading || isEventsLoading) && (
        <div className="absolute flex w-full h-full top-0 left-0 z-50 items-center justify-center bg-black/[.4]">
          <Spinner ariaLabel="Loading" size={SpinnerSize.large} />
        </div>
      )}
    </div>
  );
};
export default Scheduler;
