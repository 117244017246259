/**
 * This function is to compare 2 strings ignoring special characters.
 * If 2 strings are similar, then sort the Upper case first.
 * @param {string} val1
 * @param {string} val2
 *
 * @return {string}
 */
import { ignoreSpecialCharacters } from './ignoreSpecialCharacters';

export function customSortComparator(val1, val2) {
  return ignoreSpecialCharacters(val1)?.localeCompare(
    ignoreSpecialCharacters(val2),
    'en-US-u-kf-upper',
    { numeric: true }
  );
}
