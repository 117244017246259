import { useQuery, UseQueryResult } from 'react-query';

import {
  ApiActivityOptions,
  getActivityOptions,
} from '../gateway/v1/activities';

export const useGetActivityOptions = (): UseQueryResult<
  ApiActivityOptions,
  unknown
> =>
  useQuery('activityOptions', getActivityOptions, {
    cacheTime: 2 * 60 * 1000, // 2 minutes
    staleTime: 2 * 60 * 1000, // 2 minutes
  });
