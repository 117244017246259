import client from '../../client';

export type ApiBridgeCall = {
  conference_friendly_name: string;
};

export type ApiBridgeTransfer = {
  transfer_call_sid: string;
};

export const makeBridgeCall = (
  from: string,
  to: string,
  recordFromStart: boolean,
): Promise<ApiBridgeCall> => {
  return client
    .post('api/dialer/bridge/call', {
      json: { to, from, record_from_start: recordFromStart },
    })
    .json();
};

export const destroyBridgeCall = (
  conferenceFriendlyName: string,
): Promise<void> => {
  return client
    .delete('api/dialer/bridge/call', {
      json: {
        conference_friendly_name: conferenceFriendlyName,
      },
    })
    .json();
};

export const transferBridgeCall = (
  conferenceFriendlyName: string,
  phoneNumber: string,
): Promise<ApiBridgeTransfer> => {
  return client
    .post('api/dialer/bridge/call/transfer', {
      json: {
        transfer_to: phoneNumber,
        conference_friendly_name: conferenceFriendlyName,
      },
    })
    .json();
};

export const leaveBridgeCall = (
  conferenceFriendlyName: string,
): Promise<void> => {
  return client
    .post('api/dialer/bridge/call/leave', {
      json: {
        conference_friendly_name: conferenceFriendlyName,
      },
    })
    .json();
};

export const cancelCallTransfer = (
  conferenceFriendlyName: string,
  callSid: string,
): Promise<void> => {
  return client
    .post('api/dialer/bridge/call/cancel_transfer', {
      json: {
        conference_friendly_name: conferenceFriendlyName,
        call_sid: callSid,
      },
    })
    .json();
};
