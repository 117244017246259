import { OrderedMap, fromJS } from 'immutable';
import { actionTypes as peopleImportActionTypes } from 'Modules/PeopleImportDialog/actions';

import { actionTypes } from './actions';

const initialState = fromJS({
  filters: new OrderedMap(),
});

export default function advancedSearch(state = initialState, action = {}) {
  switch (action.type) {
    case peopleImportActionTypes.RESET: {
      return initialState;
    }

    case peopleImportActionTypes.SET_ACTIVE_TAB: {
      const { payload } = action;

      if (payload !== 'advancedSearch') {
        return initialState;
      }

      return state;
    }

    case actionTypes.ADD_FILTER: {
      const { groupId, filter } = action.payload;

      return state.setIn(['filters', groupId], fromJS(filter));
    }

    case actionTypes.BULK_ADD_FILTER: {
      return state.set('filters', action.payload);
    }

    case actionTypes.UPDATE_FILTER: {
      const { groupId, updates } = action.payload;

      return state.mergeIn(['filters', groupId], updates);
    }

    case actionTypes.DELETE_FILTER: {
      return state.deleteIn(['filters', action.payload]);
    }

    case actionTypes.CLEAR_FILTERS: {
      return state.set('filters', new OrderedMap());
    }

    case actionTypes.ENABLE_SAVED_SEARCH: {
      return state.setIn(['savedSearch', 'enabled'], true);
    }

    default:
      return state;
  }
}
