import cx from 'classnames';
import { Modal } from '@fluentui/react';
import { templateGet, TemplateType } from '@groove/api/gateway/v1/template';
import Templates from '@groove/templates';
import { FC } from 'react';
import useRecentTemplatesSet from '@groove/api/hooks/useRecentTemplatesSet';
import useTemplatesStore from '@groove/templates/store/useStore';
import { FullAction } from '@groove/api/gateway/v1/actionCompose';

import useStore from '../store/useStore';

export type AllTemplatesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AllTemplatesModal: FC<AllTemplatesModalProps> = ({ isOpen, onClose }) => {
  const type = useStore(store => store.action.type);
  const initialSearchTerm = useTemplatesStore(
    store => store.templatesApp.initialSearchTerm,
  );

  const { mutate: setRecentTemplate } = useRecentTemplatesSet();

  const handleTemplate = async (
    templateId = 0,
    replace = false,
    // eslint-disable-next-line consistent-return
  ): Promise<void> => {
    if (!templateId) return onClose();

    const template = (await templateGet(templateId))?.data;
    if (!template) return onClose();
    const { body, subject } = useStore.getState().action;
    const partialAction: Partial<FullAction> = {
      body: template.body || '',
    };

    if (!subject || replace) partialAction.subject = template.subject;
    if ((!body && !subject) || replace)
      partialAction.crmTaskType = template.template_type_value;

    // only update the task type when a template is inserted for the first time
    if (
      !useStore.getState().action.subject &&
      !useStore.getState().action.body
    ) {
      useStore.getState().updateAction({
        crmTaskType: template?.template_type_value,
      });
    }

    if (replace) {
      useStore.getState().updateAction(partialAction);
    } else {
      useStore.getState().insertAtCursorAndUpdateAction(partialAction);
    }
    useStore.getState().setOtherValues({ wysiwygEdited: false });
    onClose();
    setRecentTemplate({ templateId });
  };

  let templateType: TemplateType = 'other';
  if (type === 'TEMPLATE') templateType = 'email';
  else if (type === 'CALL') templateType = 'call';

  return (
    <div>
      <Modal
        styles={{
          scrollableContent: cx(
            'flex flex-col',
            'min-w-[80vw] min-h-[80vh] max-w-[100vw] max-h-[100vh] box-border',
            outerHeight > 700 && 'w-[80vw] h-[80vh]',
          ),
          main: 'overflow-hidden max-w-[100vw] max-h-[100vh]',
          layer: { zIndex: 20 },
        }}
        className="groove"
        isOpen={isOpen}
        ignoreExternalFocusing
        layerProps={{
          eventBubblingEnabled: true,
        }}
        isBlocking
        onDismiss={() => onClose()}
      >
        <Templates
          initialSearchTerm={initialSearchTerm}
          onSubmit={data => handleTemplate(data?.id, false)}
          onReplace={data => handleTemplate(data?.id, true)}
          onClose={() => onClose()}
          templateType={templateType}
          templateSubType={type}
        />
      </Modal>
    </div>
  );
};

export default AllTemplatesModal;
