import {
  all,
  delay,
  call,
  fork,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { getSearchFilter } from 'Modules/App/selectors';
import { listViewEnabled } from 'Modules/Flows/selectors';
import { actionTypes as locationActionTypes } from 'Modules/Shared/actions/location';
import { actionTypes as appActionTypes } from 'Modules/App/actions';
import { actionTypes as workStepActionTypes } from 'Modules/WorkStep/actions';
import { CALL_OR_OTHER_STEP_LOGGER_MORE_OPTIONS } from 'Modules/WorkStep/constants';
import {
  trackEvent,
  USER_IDS_TO_IGNORE,
  ORG_IDS_TO_IGNORE,
} from 'Utils/segment';
import {
  labelsApplied,
  labelCreated,
  filteredByLabels,
  filterBySingleLabel,
} from 'Modules/Shared/sagas/analytics/labels';
import { getStepPriority } from 'Modules/WorkStep/selectors';
import hotlisted from 'Modules/Shared/sagas/analytics/hotlisted';
import { ROUTE_NAMES } from 'Routing/constants';

import csvExported from 'Modules/Shared/sagas/analytics/csvExported';
import {
  peopleAdded,
  peopleRemoved,
} from 'Modules/Shared/sagas/analytics/addOrRemovePeople';
import stepCreated from 'Modules/Shared/sagas/analytics/stepCreated';
import columnSorted from 'Modules/Shared/sagas/analytics/columnSorted';
import savedSearchCreated from 'Modules/Shared/sagas/analytics/savedSearchCreated';
import automatedActions from 'Modules/Shared/sagas/analytics/automatedActions';
import automatedActionConfigured from 'Modules/Shared/sagas/analytics/automatedActionConfigured';
import entitySharingUpdated from 'Modules/Shared/sagas/analytics/entitySharingUpdated';
import salesforceObjectPopOutActionExecuted from 'Modules/Shared/sagas/analytics/salesforceObjectPopOutActionExecuted';
import { currentUser } from 'Modules/Shared/selectors/users';

const ANALYTICS_BASE_PROPERTIES = {
  version: process.env.REACT_APP_VERSION,
  ui: 'febes',
};

const printTrackingStarted = () =>
  console.debug('[groove-analytics] track event started'); // eslint-disable-line

const printTrackingFinished = () =>
  console.debug('[groove-analytics] track event finished'); // eslint-disable-line

const handleTrackingError = ({ error }) => {
  console.debug('[groove-analytics] track event error', error); // eslint-disable-line

  Sentry.withScope(scope => {
    scope.setExtra('error', error);
    scope.setLevel('warning');
    Sentry.captureMessage('Event tracking error');
  });
};

const shouldIgnoreUserEvents = user => {
  if (!user) return true;

  const userId = user.get('id').toString();
  const orgId = user.get('orgId').toString();
  return (
    USER_IDS_TO_IGNORE.includes(userId) || ORG_IDS_TO_IGNORE.includes(orgId)
  );
};

export function createSafeActionHandler(saga) {
  return function* safeHandler(...args) {
    try {
      const user = yield select(currentUser);

      if (shouldIgnoreUserEvents(user)) return;
      printTrackingStarted();
      yield call(saga, ...args);
    } catch (error) {
      handleTrackingError({ error });
    } finally {
      printTrackingFinished();
    }
  };
}

export function safelyLogEvent({ currentUser, eventName, properties = {} }) {
  try {
    if (shouldIgnoreUserEvents(currentUser)) return;

    printTrackingStarted();
    trackEvent(eventName, {
      ...ANALYTICS_BASE_PROPERTIES,
      ...properties,
    });
  } catch (error) {
    handleTrackingError({ error });
  } finally {
    printTrackingFinished();
  }
}

export function trackTabClick(activeTab) {
  if (window.newrelic) {
    window.newrelic.addPageAction('trackTabClick', { tabName: activeTab });
  }
}

export function* trackBasicEvent({
  featureCategory,
  eventName,
  action,
  properties = {},
}) {
  yield takeEvery(
    action,
    createSafeActionHandler(() => {
      trackEvent(eventName, {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory,
        ...properties,
      });
    })
  );
}

function* primaryNavigationChange() {
  yield takeEvery(
    locationActionTypes.UPDATE,
    createSafeActionHandler(({ payload }) => {
      const { routeName, previousRouteNames } = payload;

      // if routeName hasn't change but the location has been updated, that means
      // it is just a tab or query param change and we do not want to track those
      if (routeName !== previousRouteNames.last()) {
        trackEvent('Primary Navigation Clicked', {
          ...ANALYTICS_BASE_PROPERTIES,
          source: routeName,
        });
      }
    })
  );
}

function* secondaryNavigationChange() {
  yield takeEvery(
    appActionTypes.TOP_NAV_TAB_CLICKED,
    createSafeActionHandler(({ payload }) => {
      const { tabValue, namespace } = payload;
      trackEvent('Secondary Navigation Clicked', {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory: namespace,
        tabName: tabValue,
      });
    })
  );
}

function* globalSearchInputFocused() {
  yield takeLatest(
    appActionTypes.SEARCH_INPUT_FOCUSED,
    createSafeActionHandler(function* handler() {
      // debouncing this for the following scenario
      // - user clicks in input
      // - user then changes the filter
      // - user clicks back into input
      // without the debounce, we would capture 2 analytics events and the
      // first one would a filter being sent to Segment that was not actually used
      yield delay(2000);

      const searchFilter = yield select(getSearchFilter);

      const filterMap = {
        people: 'person',
      };

      const object = filterMap[searchFilter];

      trackEvent('Global Search', {
        ...ANALYTICS_BASE_PROPERTIES,
        object,
      });
    })
  );
}

function* workstepLoaded() {
  yield takeEvery(
    locationActionTypes.UPDATE,
    createSafeActionHandler(function* handler({ payload }) {
      const { routeName, previousRouteNames } = payload;

      if (routeName === ROUTE_NAMES.WORKSTEP) {
        const previousRoute = previousRouteNames.last();

        let source;
        switch (previousRoute) {
          case 'home':
            source = 'home';

            break;
          case 'flowsShow':
            source = 'detail';

            break;
          case 'flows':
            {
              const listView = yield select(listViewEnabled);
              if (listView) {
                source = 'list-view';
              } else {
                source = 'tile-view';
              }
            }
            break;
          default:
            source = '';
        }

        trackEvent('Workstep Loaded', {
          ...ANALYTICS_BASE_PROPERTIES,
          source,
        });
      }
    })
  );
}

function* callOrOtherStepLogged() {
  yield takeEvery(
    workStepActionTypes.LOG_CALL_OR_ACTION.BEGIN,
    createSafeActionHandler(function* handler({
      payload: { option, templateType },
    }) {
      const priority = yield select(getStepPriority);
      const properties = {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory: 'flow',
        type: templateType,
        priority,
      };

      if (
        option ===
        CALL_OR_OTHER_STEP_LOGGER_MORE_OPTIONS.MARK_AS_COMPLETE_DONT_LOG
      ) {
        trackEvent('Step Marked as Completed', properties);
      } else {
        // the cases are different below because we are matching what is being done in GE...
        if (option === CALL_OR_OTHER_STEP_LOGGER_MORE_OPTIONS.LOG_AND_REMOVE) {
          properties.additional_action = 'removed';
        }

        if (
          option === CALL_OR_OTHER_STEP_LOGGER_MORE_OPTIONS.LOG_AND_MOVE_TO_FLOW
        ) {
          properties.additional_action = 'move';
        }

        if (option === CALL_OR_OTHER_STEP_LOGGER_MORE_OPTIONS.LOG_AND_PAUSE) {
          properties.additional_action = 'paused';
        }
        trackEvent('Note Logged', properties);
      }
    })
  );
}

function* locationUpdated() {
  yield takeEvery(
    locationActionTypes.UPDATE,
    createSafeActionHandler(({ payload }) => {
      const { routeName } = payload;

      if (routeName === ROUTE_NAMES.SPACES_SHOW) {
        trackEvent('Workspace Visited', {
          ...ANALYTICS_BASE_PROPERTIES,
          featureCategory: ROUTE_NAMES.SPACES_SHOW,
        });
      }
    })
  );
}

export default function* root() {
  yield all([
    fork(primaryNavigationChange),
    fork(secondaryNavigationChange),
    fork(globalSearchInputFocused),
    fork(workstepLoaded),
    fork(callOrOtherStepLogged),
    fork(labelsApplied),
    fork(labelCreated),
    fork(filteredByLabels),
    fork(filterBySingleLabel),
    fork(csvExported),
    fork(locationUpdated),
    fork(peopleAdded),
    fork(peopleRemoved),
    fork(stepCreated),
    fork(columnSorted),
    fork(hotlisted),
    fork(savedSearchCreated),
    fork(automatedActions),
    fork(automatedActionConfigured),
    fork(entitySharingUpdated),
    fork(salesforceObjectPopOutActionExecuted),
  ]);
}

export { ANALYTICS_BASE_PROPERTIES };
