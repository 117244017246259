import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { isExternalUserEditingColumnOrder } from 'Modules/Spaces/show/selectors';
import { createCustomColumn } from 'Modules/Spaces/show/actions/firestore';
import { CUSTOM_COLUMN_TYPES } from 'Modules/Spaces/show/constants';

const styles = {
  menuItemRoot: {
    fontSize: 14,
    padding: '7px 15px 7px 15px',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

@withStyles(styles)
@connect(
  state => ({
    isExternalUserEditingColumnOrder: isExternalUserEditingColumnOrder(state),
  }),
  {
    createCustomColumn,
  }
)
export default class AddCustomColumnMenu extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,

    // Passable props
    indexPosition: PropTypes.number.isRequired,
    closeMainMenu: PropTypes.func.isRequired,

    // MapStateToProps
    isExternalUserEditingColumnOrder: PropTypes.bool.isRequired,

    // MapDispatchToProps
    createCustomColumn: PropTypes.func.isRequired,
  };

  static menuAnchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  };

  static menuTransformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  // TODO @darthneel
  // Break this function into its own React component
  createSubMenuItems() {
    const { classes, isExternalUserEditingColumnOrder } = this.props;

    const columnOptions = [
      {
        label: 'Single Select',
        onClick: this.handleCreateSingleSelect,
      },
      {
        label: 'Text Field',
        onClick: this.handleCreateTextField,
      },
    ];

    return columnOptions.map(option => (
      <MenuItem
        key={option.label}
        classes={{ root: classes.menuItemRoot }}
        onClick={option.onClick}
        disabled={isExternalUserEditingColumnOrder}
      >
        {option.label}
      </MenuItem>
    ));
  }

  handleOpenSubMenu = ({ currentTarget }) =>
    this.setState({ open: true, anchorEl: currentTarget });

  handleCloseSubMenu = () => this.setState({ open: false });

  handleCloseBothMenus = () => {
    const { closeMainMenu } = this.props;
    this.handleCloseSubMenu();
    closeMainMenu();
  };

  handleCreateCustomColumn = columnType => {
    const { createCustomColumn, indexPosition } = this.props;

    createCustomColumn({ type: columnType, indexPosition });
    this.handleCloseBothMenus();
  };

  handleCreateSingleSelect = () =>
    this.handleCreateCustomColumn(CUSTOM_COLUMN_TYPES.SINGLE_SELECT);
  handleCreateTextField = () =>
    this.handleCreateCustomColumn(CUSTOM_COLUMN_TYPES.TEXT_FIELD);

  render() {
    const { classes, isExternalUserEditingColumnOrder } = this.props;

    const { anchorEl, open } = this.state;

    return (
      <React.Fragment>
        <MenuItem
          classes={{ root: classes.menuItemRoot }}
          key="addColumn"
          onClick={this.handleOpenSubMenu}
          disabled={isExternalUserEditingColumnOrder}
        >
          Add Column
          <KeyboardArrowRight />
        </MenuItem>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleCloseSubMenu}
          anchorOrigin={AddCustomColumnMenu.menuAnchorOrigin}
          transformOrigin={AddCustomColumnMenu.menuTransformOrigin}
        >
          {this.createSubMenuItems()}
        </Menu>
      </React.Fragment>
    );
  }
}
