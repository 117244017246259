import React from 'react';

import { Checkbox } from '@groove-labs/groove-ui';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutline from '@material-ui/icons/InfoOutlined';

import PropTypes from 'prop-types';

const FIELD_ID = 'LINK_TRACKING';

function LinkTrackingCheckbox({
  classes,
  formGroupId,
  initialValue,
  editable,
}) {
  return (
    <div className={classes.root}>
      <Checkbox
        label="Track Link"
        groupId={formGroupId}
        fieldId={FIELD_ID}
        initialValue={initialValue}
        disabled={!editable}
      />
      <Tooltip
        title="Get notifications when the recipient clicks any link in your email"
        placement="bottom"
      >
        <InfoOutline />
      </Tooltip>
    </div>
  );
}

LinkTrackingCheckbox.displayName = 'LinkTrackingCheckbox';
LinkTrackingCheckbox.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  formGroupId: PropTypes.string.isRequired,
  initialValue: PropTypes.bool,
  editable: PropTypes.bool,
};

LinkTrackingCheckbox.defaultProps = {
  initialValue: false,
  editable: true,
};

export { FIELD_ID };

export default LinkTrackingCheckbox;
