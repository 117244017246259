import { GetState, SetState } from 'zustand';
import produce, { Draft } from 'immer';

import { CallActivity, CustomField } from '../../api/graphql/types';
import { Store } from '../useStore';

import { MessageBar } from './MessageBar';

type LogState = 'not-logging' | 'active' | 'waiting-for-server-response';
export type LogStore = {
  logState: LogState;
  setLogState: (logState: LogState) => void;

  currentCallActivity: CallActivity | null;
  setCurrentCallActivity: (
    setter: (draft: Draft<CallActivity>) => CallActivity | void,
  ) => void;

  logError: string | null;
  setLogError: (logError: string | null) => void;

  enterLog: (callActivity: CallActivity) => void;
  exitLog: (
    messageBar?: MessageBar | null,
    logState?: LogState,
    preserveContext?: boolean,
  ) => void;
  customFields: CustomField[];
  setCustomFields: (fieldToSet: CustomField) => void;
};
export const logStore = (
  set: SetState<Store>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  get: GetState<Store>,
): LogStore => ({
  currentCallActivity: null,

  logError: null,
  logState: 'not-logging',

  setLogError: logError => set({ logError }),
  setLogState: logState => set({ logState }),

  setCurrentCallActivity: setter =>
    set({
      currentCallActivity: produce(
        get().currentCallActivity || ({} as CallActivity),
        setter,
      ),
    }),
  enterLog: callActivity => {
    get().ipcRenderer?.send('asynchronous-message', 'resize');
    set({
      currentCallActivity: callActivity,
      activeChannelPhoneNumber: null,
      activeChannelSid: null,
      currentTab: 'Home',
      logState: 'active',
    });
  },
  exitLog: (
    messageBar = null,
    logState = 'not-logging',
    preserveContext?: boolean,
  ) => {
    const { setMessageBar, currentContext } = get();
    set({
      logState,
      currentCallActivity: null,
      logError: null,
      activeBridgeCallSid: '',
      currentTab: currentContext ? 'Home' : 'Recent',
      customFields: [],
    });

    if (!preserveContext) {
      set({ currentContext: null, instantDialEligible: false });
    }

    setMessageBar(messageBar);
  },
  customFields: [],
  setCustomFields: fieldToSet => {
    const { customFields } = get();

    const updatedCustomFields = produce(customFields, draft => {
      const index = draft.findIndex(field => field.name === fieldToSet.name);
      if (index !== -1) {
        draft[index] = fieldToSet;
      } else {
        draft.push(fieldToSet);
      }
    });
    set({ customFields: updatedCustomFields });
  },
});
