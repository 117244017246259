import cx from 'classnames';
import { createElement, FC, HTMLProps, ReactNode } from 'react';

export type TextVariant =
  | 'metadata-xs'
  | 'metadata-sm'
  | 'metadata'
  | 'body-sm'
  | 'body'
  | 'body-lg'
  | 'subject-title'
  | 'header'
  | 'mobile-label'
  | 'pane-header'
  | 'page-title'
  | 'greeting-title'
  | 'hero-title'
  | 'numerics-xl';

export type TextWeight = 'normal' | 'semibold';

export type TextProps = HTMLProps<HTMLDivElement> & {
  children: ReactNode;
  className?: HTMLProps<HTMLElement>['className'];
  component?: keyof JSX.IntrinsicElements;
  variant?: TextVariant;
  weight?: TextWeight;
};

const Text: FC<TextProps> = ({
  children,
  component = 'div',
  className,
  variant,
  weight = 'normal',
}) => {
  let calcVariant = variant;
  if (!className && !variant) calcVariant = 'body';
  return createElement(
    component,
    {
      className: cx(className, 'font-groove', {
        'text-metadata-xs': calcVariant === 'metadata-xs',
        'text-metadata-sm': calcVariant === 'metadata-sm',
        'text-metadata': calcVariant === 'metadata',
        'text-body-sm': calcVariant === 'body-sm',
        'text-body': calcVariant === 'body',
        'text-body-lg': calcVariant === 'body-lg',
        'text-subject-title': calcVariant === 'subject-title',
        'text-header': calcVariant === 'header',
        'text-mobile-label': calcVariant === 'mobile-label',
        'text-pane-header': calcVariant === 'pane-header',
        'text-page-title': calcVariant === 'page-title',
        'text-greeting-title': calcVariant === 'greeting-title',
        'text-hero-title': calcVariant === 'hero-title',
        'text-numerics-xl': calcVariant === 'numerics-xl',
        'font-normal': weight === 'normal',
        'font-semibold': weight === 'semibold',
      }),
    },
    children,
  );
};

export default Text;
