import { select, put, call, all } from 'redux-saga/effects';
import { List, OrderedMap, Map } from 'immutable';
import { SagaIterator } from 'redux-saga';

import { batchCreate } from 'GrooveHTTPClient/accounts';
import { logErrorToSentry } from 'Modules/Shared/sagas/errors';
import { searchResults } from 'Modules/Spaces/import/selectors';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { actionTypes } from 'Modules/Spaces/import/actions';
import { getWorkspaceType } from 'Modules/Spaces/show/selectors';
import { WORKSPACE_TYPES } from 'Modules/Spaces/shared/constants';

import createOpportunities from './createOpportunities';
import handleAddSObjectsToWorkspace from './handleAddSObjectsToWorkspace';

type HandleCreateSObjectsPayload = {
  payload: List<number>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* createSObjects(sObjects: OrderedMap<string, any>): SagaIterator {
  const workspaceType = yield select(getWorkspaceType);
  if (workspaceType === WORKSPACE_TYPES.OPPORTUNITY) {
    const createdOpportunities = yield call(createOpportunities, {
      sfdcOpportunities: sObjects,
    });

    return createdOpportunities.map((opp: Map<string, string>) =>
      Number(opp.get('id'))
    );
  }
  const response = yield call(batchCreate, sObjects.valueSeq().toJS());
  return response.getIn(['message', 'data', 'accountIds']);
}

function* handleCreateSObjects({
  payload: selectedSObjectIds,
}: HandleCreateSObjectsPayload): SagaIterator {
  const sfdcObjects = (yield select(searchResults)).get('sfdcObjects');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedSfdcObjects = sfdcObjects.filter((_: any, id: number) =>
    selectedSObjectIds.has(id)
  );

  yield put({ type: actionTypes.BATCH_CREATE.PROGRESS });

  try {
    const sObjectIds = yield call(createSObjects, selectedSfdcObjects);
    yield call(handleAddSObjectsToWorkspace, sObjectIds);

    yield put({
      type: actionTypes.BATCH_CREATE.SUCCESS,
    });
  } catch (e) {
    logErrorToSentry(e);
    yield all([
      put({ type: actionTypes.BATCH_CREATE.FAILURE }),
      put(
        pushSnackbarMessage({
          message: 'Sorry, something went wrong. Please try again.',
        })
      ),
    ]);
  }
}

export default handleCreateSObjects;
