import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {
  sharedLabelStyle,
  SMART_PRIORITY_LABELS,
} from 'Modules/ActionsRewrite/components/customCells/SmartPriority/constants';

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette['warning/900'],
    backgroundColor: theme.palette['warning/50'],
    ...sharedLabelStyle,
  },
}));

const MediumChip = () => {
  const styles = useStyles();

  return (
    <Typography className={styles.label}>
      {SMART_PRIORITY_LABELS.MEDIUM}
    </Typography>
  );
};

export default MediumChip;
