import { safelyLogEvent } from 'Modules/Shared/sagas/analytics';

function trackUserMenuEvent({ currentUser, actionName }) {
  safelyLogEvent({
    currentUser,
    eventName: `Webapp: Profile Menu: ${actionName} Menu Item Clicked`,
    properties: {
      featureCategory: 'navigation',
      actionName,
      app: 'webapp',
      app_feature: 'profile menu',
    },
  });
}

export default trackUserMenuEvent;
