import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { GenerateId } from 'jss';

import ClariTheme from 'Utils/theme/ClariTheme';
import { Stylesheet, resetIds } from '@fluentui/react';

const stylesheet = Stylesheet.getInstance();

stylesheet.setConfig({
  namespace: 'web-app',
});

const appRender = (
  Component: React.ReactElement<unknown, string>,
  // eslint-disable-next-line
  store: any,
  generateClassName: GenerateId
): void => {
  // reset stylesheet to prevent a fluent issue with dropdowns disappearing: https://github.com/microsoft/fluentui/issues/14832
  stylesheet.reset();
  resetIds();
  ReactDOM.render(
    <MuiThemeProvider theme={ClariTheme}>
      <Provider store={store}>
        <StylesProvider generateClassName={generateClassName}>
          {Component}
        </StylesProvider>
      </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
  );
};

export default appRender;
