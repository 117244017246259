export const ROWS_PER_REQUEST_LIMIT = 200;

export const MANDATORY_COLUMNS = {
  Lead: [
    {
      fieldNameOrPath: 'Name',
      hidden: false,
      label: 'Name',
      sortable: true,
      type: 'string',
    },
    {
      fieldNameOrPath: 'Email',
      hidden: false,
      label: 'Email',
      sortable: true,
      type: 'email',
    },
    {
      fieldNameOrPath: 'Id',
      hidden: true,
      label: 'Id',
    },
    {
      fieldNameOrPath: 'Company',
      hidden: false,
      label: 'Company',
      sortable: true,
      type: 'string',
    },
  ],
  Contact: [
    {
      fieldNameOrPath: 'Name',
      hidden: false,
      label: 'Name',
      sortable: true,
      type: 'string',
    },
    {
      fieldNameOrPath: 'Email',
      hidden: false,
      label: 'Email',
      sortable: true,
      type: 'email',
    },
    {
      fieldNameOrPath: 'Id',
      hidden: true,
      label: 'Id',
    },
    {
      fieldNameOrPath: 'Account.Name',
      hidden: false,
      label: 'Account',
      sortable: true,
      type: 'string',
    },
  ],
  Opportunity: [
    {
      fieldNameOrPath: 'Name',
      hidden: false,
      label: 'Name',
      sortable: true,
      type: 'string',
    },
    {
      fieldNameOrPath: 'CloseDate',
      hidden: false,
      label: 'Close Date',
      sortable: true,
      type: 'date',
    },
    {
      fieldNameOrPath: 'StageName',
      hidden: false,
      label: 'Stage',
      sortable: true,
      type: 'string',
    },
    {
      fieldNameOrPath: 'Id',
      hidden: true,
      label: 'Id',
    },
  ],
  Account: [
    {
      fieldNameOrPath: 'Name',
      hidden: false,
      label: 'Name',
      sortable: true,
      type: 'string',
    },
    {
      fieldNameOrPath: 'owner.name',
      hidden: false,
      label: 'Owner',
      sortable: true,
      type: 'string',
    },
    {
      fieldNameOrPath: 'Id',
      hidden: true,
      label: 'Id',
    },
  ],
  Campaign: [
    {
      fieldNameOrPath: 'Name',
      hidden: false,
      label: 'Campaign Name',
      sortable: true,
      type: 'string',
    },
    {
      fieldNameOrPath: 'Id',
      hidden: true,
      label: 'Campaign ID',
      sortable: false,
      type: 'id',
    },
  ],
  default: [
    {
      fieldNameOrPath: 'Name',
      hidden: false,
      label: 'Name',
      sortable: true,
      type: 'string',
    },
    {
      fieldNameOrPath: 'Id',
      hidden: true,
      label: 'Id',
    },
  ],
};

export const DEFAULT_CSV_SELECT_CONTACT_FIELDS = ['Phone', 'Title'];

export const AUTO_CREATION_MODAL_KEY_PATH = [
  'flowShow',
  'isAutoCreationModalOpen',
];
