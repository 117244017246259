import { makeStyles } from '@material-ui/core/styles';
import useStore from 'Modules/ActionsRewrite/store/useStore';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export default function FailedActionsZeroState() {
  const classes = useStyles();
  const { setActiveTab } = useStore();
  const handleOnClick = () => {
    setActiveTab('Active');
  };
  return (
    <>
      Return to{' '}
      <span
        className={classes.link}
        onClick={handleOnClick}
        role="button"
        tabIndex={-1}
      >
        Active
      </span>
    </>
  );
}
