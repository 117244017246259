import { createSelector } from 'reselect';
import { keyBy } from 'lodash-es';
import {
  contactChildren,
  createGetObjectFieldsMetadata,
  contactFieldsMeta,
} from 'Modules/Shared/selectors/salesforceMeta';
import { listToGroups, selectors } from '@groove-labs/groove-ui';
import {
  UNSUPPORTED_FIELD_TYPES,
  SPECIAL_CASE_SEARCH,
} from 'Modules/PeopleImportDialog/constants';
import { getFlow } from 'Modules/FlowsShow/selectors';
import { currentUser } from 'Modules/Shared/selectors/users';
import {
  IMPORT_ON_BEHALF_OF_GROUP_ID,
  IMPORT_ON_BEHALF_OF_ASSIGN_TO_FIELD_ID,
} from 'Modules/Settings/constants/importOnBehalfOf';
import { customSortComparator } from 'Utils/customSortComparator';

const { getProperty } = selectors.ui;
const { makeGetFieldValue } = selectors.form;

const ROOT_KEY_PATH = ['PeopleImportDialog', 'root'];

export const salesforceObject = state =>
  state.getIn([...ROOT_KEY_PATH, 'salesforceObject']);
export const selectedObjectValue = state =>
  state.getIn([...ROOT_KEY_PATH, 'selectedObjectValue']);

// If the selected object value is contained within the contact children, return the relationship name
// otherwise return undefined
export const relationshipName = createSelector(
  selectedObjectValue,
  contactChildren,
  (selectedObjectValue, contactChildren) => {
    const contactChild = contactChildren.find(
      object => object.get('objectQueryKey') === selectedObjectValue
    );

    if (contactChild) {
      return contactChild.get('fieldName');
    }

    return contactChild;
  }
);

export const getAssignableUsersBySfdcUserId = createSelector(
  state => state.getIn([...ROOT_KEY_PATH, 'assignableUsers']),
  assignableUsers => keyBy(assignableUsers, 'sfdcUserId')
);

export const searching = state => state.getIn([...ROOT_KEY_PATH, 'searching']);

export const isFetchingImportOnBehalfOfUsers = state =>
  state.getIn([...ROOT_KEY_PATH, 'isFetchingImportOnBehalfOfUsers']);

export const getAssignableUsers = state =>
  state
    .getIn([...ROOT_KEY_PATH, 'assignableUsers'])
    .map(user => ({ label: user.name, value: user.id }));

export const getAssignableUserSfdcIds = state =>
  state
    .getIn([...ROOT_KEY_PATH, 'assignableUsers'])
    .map(user => user.sfdcUserId);

export const getAssignableUser = createSelector(
  getAssignableUsersBySfdcUserId,
  (_, sfdcUserId) => sfdcUserId,
  (assignableUsers, sfdcUserId) => assignableUsers.get(sfdcUserId)
);

export const getAssignableUsersData = state =>
  state.getIn([...ROOT_KEY_PATH, 'assignableUsers']);

export const getCanImportOnBehalfOf = createSelector(
  currentUser,
  getFlow,
  (user, flow) =>
    user.get('canImportOnBehalfOf') &&
    flow &&
    (flow.get('userId') !== user.get('id') || flow.get('isMasterFlow'))
);

export const submitting = state =>
  state.getIn([...ROOT_KEY_PATH, 'submitting']);

export const getSubmitFailed = state =>
  state.getIn([...ROOT_KEY_PATH, 'failed'], false);

export const activeTab = state => state.getIn([...ROOT_KEY_PATH, 'activeTab']);

export const isDrawerOpen = state =>
  state.getIn([...ROOT_KEY_PATH, 'isDrawerOpen']);

export const peopleInFlow = state =>
  state.getIn([...ROOT_KEY_PATH, 'peopleInFlow']);

export const getAddPeopleUiKeyPath = state =>
  state.getIn([...ROOT_KEY_PATH, 'isOpenUiKeyPath']);

export const getFlowId = state => state.getIn([...ROOT_KEY_PATH, 'flowId']);

export const createGetPeopleInFlowSfdcIds = () => {
  return createSelector(peopleInFlow, people => people.keySeq());
};

export const noResultsFound = state =>
  state.getIn([...ROOT_KEY_PATH, 'noResultsFound']);

export const getFilterableFieldOptions = objectType =>
  createSelector(
    createGetObjectFieldsMetadata(objectType),
    contactFieldsMeta,
    selectedObjectValue,
    (fieldsMetadata, contactFieldsMeta, selectedObjectValue) => {
      const filteredFields = fieldsMetadata.filter(
        field =>
          // take supported type and filterable fields
          !UNSUPPORTED_FIELD_TYPES.includes(field.get('type')) &&
          !!field.get('filterable')
      );

      let groupedFields;
      if (SPECIAL_CASE_SEARCH.includes(selectedObjectValue.toLowerCase())) {
        const filteredContactFields = contactFieldsMeta.filter(
          field =>
            // take supported type and filterable fields
            !UNSUPPORTED_FIELD_TYPES.includes(field.get('type')) &&
            !!field.get('filterable')
        );

        const sectionHeading =
          selectedObjectValue === 'account' ? 'Account' : 'Deal';

        groupedFields = [
          {
            label: `${sectionHeading} fields`,
            options: filteredFields
              .toList()
              .map(field => field.set('isContactCondition', false))
              .toArray(),
          },
          {
            label: 'Contact fields',
            options: filteredContactFields
              .toList()
              .map(field => field.set('isContactCondition', true))
              .toArray(),
          },
        ];
      } else {
        // use the listToGroup utils function, and then adjust to Picklist option format
        groupedFields = [];
        listToGroups(filteredFields, field =>
          field.label[0].toUpperCase()
        ).reduce((picklistGroups, sddmGroup) => {
          picklistGroups.push({
            label: sddmGroup.get('title'),
            options: sddmGroup.get('suggestions').toArray(),
          });
          return picklistGroups;
        }, groupedFields);
      }

      groupedFields.forEach(group => {
        group.options.sort((option1, option2) =>
          customSortComparator(option1?.get('label'), option2?.get('label'))
        );
      });

      groupedFields.sort((group1, group2) =>
        customSortComparator(group1?.label, group2?.label)
      );

      return groupedFields;
    }
  );

export const getInitialField = (objectType, initialFieldName) =>
  createSelector(
    createGetObjectFieldsMetadata(objectType),
    contactFieldsMeta,
    (fieldsMetadata, contactFieldsMeta) => {
      if (!initialFieldName) {
        return null;
      }

      const field =
        fieldsMetadata.find(field => field.get('name') === initialFieldName) ||
        contactFieldsMeta.find(field => field.get('name') === initialFieldName);

      return field;
    }
  );

export const selectFlowId = state =>
  state.getIn(['PeopleImportDialog', 'root', 'flowId']);

export const selectUiKeyPath = state =>
  state.getIn(['PeopleImportDialog', 'root', 'isOpenUiKeyPath']);

export const selectCreateSfdcCampaign = state =>
  getProperty(state, ['createSfdcCampaign']);

export const selectPersonFlowOwnerId = state => {
  return makeGetFieldValue(IMPORT_ON_BEHALF_OF_ASSIGN_TO_FIELD_ID)(state, {
    groupId: IMPORT_ON_BEHALF_OF_GROUP_ID,
  });
};

export const selectShowDuplicates = state =>
  !state.getIn([
    'PeopleImportDialog',
    'peopleTable',
    'hideDuplicatesFromSearchResults',
  ]);
