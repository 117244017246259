import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import getToken from '../api/twilio/token';
import {
  TWILIO_TOKEN_CHECK_INTERVAL,
  FETCHED_TWILIO_TOKEN_TIME,
  TWILIO_VALID_CLIENT_TOKEN_DURATION,
} from '../constants';

import useStore from './useStore';

const useSDKTokenRefresh = (): void => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const maxTokenTTLCheck =
      (process.env.TWILIO_CLIENT_TOKEN_VALID_TTL as unknown as number) ||
      TWILIO_VALID_CLIENT_TOKEN_DURATION;
    const tokenCheckInterval =
      (process.env.TWILIO_TOKEN_CHECK_INTERVAL as unknown as number) ||
      TWILIO_TOKEN_CHECK_INTERVAL;

    const tokenChecker = async (): Promise<void> => {
      const { callState, device } = useStore.getState();
      const currentTime = new Date();
      const storedTokenDate = localStorage.getItem(FETCHED_TWILIO_TOKEN_TIME);
      if (!storedTokenDate || !device) return;

      const tokenAge =
        currentTime.getTime() - new Date(storedTokenDate).getTime();

      if (tokenAge >= maxTokenTTLCheck && callState === 'not-connected') {
        queryClient.invalidateQueries('token', {
          refetchActive: false,
          refetchInactive: false,
        });
        const { token: refetchedToken } = await getToken();
        if (refetchedToken) {
          device.updateToken(refetchedToken);
          const refetchedTokenDate = new Date();
          localStorage.setItem(
            FETCHED_TWILIO_TOKEN_TIME,
            refetchedTokenDate.toISOString(),
          );
        }
      }

      // In case we lost connection, re-register the device.
      if (device.state === 'unregistered') {
        device.register();
      }
    };

    // Make sure it runs at least once when initialized, mainly for outlook
    tokenChecker();
    const timer = window.setInterval(tokenChecker, tokenCheckInterval);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSDKTokenRefresh;
