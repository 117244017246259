export const CHROME_EXTENSION_WIDTH = '930px';
export const CHROME_EXTENSION_HEIGHT = '640px';

export const TEMPLATES_MENU_WIDTH = '400px';

export const REACT_QUERY_CACHE_STALE_TIME = {
  // 10 minutes
  cacheTime: 1000 * 60 * 10,
  staleTime: 1000 * 60 * 10,
};
