import { ReactElement, useEffect } from 'react';
import { Icon, Spinner, SpinnerSize } from '@fluentui/react';
import { motion } from 'framer-motion';

import {
  SNACKBAR_MESSAGE_TYPE,
  SnackbarNotificationMsg,
} from './snackbarUtils';

type Props = {
  notification: SnackbarNotificationMsg;
  onDismiss: (id: string) => void;
};

const SnackbarNotification = (props: Props): ReactElement => {
  const { notification, onDismiss } = props;
  const {
    message,
    actionUrl,
    id,
    type,
    actionBtnText,
    onActionButtonClick,
    onKeyDown,
    timeout = 5000,
  } = notification;

  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss(id);
    }, timeout);
    return () => clearTimeout(timer);
  }, [id, onDismiss, timeout]);

  let classesBasedOnType = '';
  let closeButtonClasses = '';
  let iconName = '';

  switch (type) {
    case SNACKBAR_MESSAGE_TYPE.SUCCESS:
      classesBasedOnType = 'bg-greenLight text-green';
      iconName = 'SkypeCircleCheck';
      closeButtonClasses = 'text-green';
      break;
    case SNACKBAR_MESSAGE_TYPE.ERROR:
      classesBasedOnType = ' bg-redLight text-redDark';
      closeButtonClasses = 'text-red';
      iconName = 'StatusErrorFull';
      break;
    case SNACKBAR_MESSAGE_TYPE.LOADING:
      classesBasedOnType = 'bg-primary-lighter text-primary-dark-alt';
      closeButtonClasses = 'text-primary';
      break;
    default:
      break;
  }

  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      layout
      className={`mb-2 px-2 py-1 flex min-w-[300px] max-w-[80vw] ${classesBasedOnType}`}
    >
      {type === SNACKBAR_MESSAGE_TYPE.LOADING ? (
        <Spinner className="mr-2" size={SpinnerSize.small} />
      ) : (
        <Icon iconName={iconName} className="mr-2 text-base my-auto" />
      )}
      <div
        className="flex-grow truncate text-xs my-auto"
        aria-label={`snackbar-${type}-msg`}
      >
        {message}
      </div>
      {actionUrl && (
        <a
          className="bg-white no-underline my-auto mx-2 px-3 border border-primary border-solid rounded"
          href={actionUrl}
          target="_blank"
          rel="noreferrer"
        >
          <span
            role="button"
            className="font-semibold text-sm flex my-auto text-primary"
            onClick={onActionButtonClick}
            onKeyDown={onKeyDown}
            tabIndex={0}
          >
            {actionBtnText || 'View'}
          </span>
        </a>
      )}
      <button
        type="button"
        aria-label="snackbar-close-btn"
        onClick={() => onDismiss(notification.id)}
        className={`${closeButtonClasses} flex-none cursor-pointer bg-transparent rounded boxshadow-none border-none`}
      >
        <Icon iconName="ChromeClose" style={{ fontSize: '11px' }} />
      </button>
    </motion.div>
  );
};

export default SnackbarNotification;
