import { fromJS, List } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/accessControls';

const initialState = new List();

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SET_ACCESS_CONTROLS: {
      return fromJS(action.payload);
    }

    default:
      return state;
  }
}
