import {
  SelectedActionsFilter,
  FilterActionsInput,
} from '@groove/api/services/v1/actions';
import create from 'zustand';

export type Store = {
  actionFilters: FilterActionsInput;
  setActionFilters: (actionFilters: FilterActionsInput) => void;
  selectedFilters: SelectedActionsFilter;
  setSelectedFilters: (selectedFilters: SelectedActionsFilter) => void;
  pendingFilters: SelectedActionsFilter;
  setPendingFilters: (pendingFilters: SelectedActionsFilter) => void;
  filterCount: number;
  setFilterCount: (filterCount: number) => void;
};

const useStore = create<Store>((set, get) => ({
  actionFilters: {},
  setActionFilters: actionFilters => set({ actionFilters }),
  actionOrder: {
    field: 'DUE_AT',
    direction: 'ASC',
    label: 'Due',
  },
  selectedFilters: {},
  setSelectedFilters: selectedFilters => set({ selectedFilters }),
  pendingFilters: {},
  setPendingFilters: pendingFilters => set({ pendingFilters }),
  filterCount: 0,
  setFilterCount: filterCount => set({ filterCount }),
}));

export default useStore;
