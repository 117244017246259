import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const TemplateIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <path d="M0 2.5C0 1.11929 1.11929 0 2.5 0L11.5 0C12.8807 0 14 1.11929 14 2.5L14 6.03544C13.8367 6.01209 13.6698 6 13.5 6H13L13 2.5C13 1.67157 12.3284 1 11.5 1L2.5 1C1.67157 1 1 1.67157 1 2.5L1 11.5C1 12.3284 1.67157 13 2.5 13L4 13V13.5C4 13.6698 4.01209 13.8367 4.03544 14L2.5 14C1.11929 14 0 12.8807 0 11.5L0 2.5ZM11 5.5C11 5.77614 10.7761 6 10.5 6L5.5 6C5.22386 6 5 5.77614 5 5.5C5 5.22386 5.22386 5 5.5 5L10.5 5C10.7761 5 11 5.22386 11 5.5ZM3 3.5C3 3.22386 3.22386 3 3.5 3L10.5 3C10.7761 3 11 3.22386 11 3.5C11 3.77614 10.7761 4 10.5 4L3.5 4C3.22386 4 3 3.77614 3 3.5ZM5 9.5C5 8.11929 6.11929 7 7.5 7L13.5 7C14.8807 7 16 8.11929 16 9.5L16 13.5C16 14.8807 14.8807 16 13.5 16H7.5C6.11929 16 5 14.8807 5 13.5L5 9.5ZM7.5 8C6.76327 8 6.15059 8.53113 6.02398 9.23138L10.4999 11.9169L14.976 9.23129C14.8494 8.53109 14.2367 8 13.5 8L7.5 8ZM6 13.5C6 14.3284 6.67157 15 7.5 15L13.5 15C14.3284 15 15 14.3284 15 13.5L15 10.3831L10.7572 12.9288C10.5988 13.0238 10.401 13.0238 10.2427 12.9288L6 10.3832L6 13.5Z" />
  </svg>
);
