import { getCurrentUser, getUserOrgSettings } from 'GrooveHTTPClient/users';

import { update as updateUserSettings } from 'GrooveHTTPClient/userSettings';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import {
  actionTypes,
  fetchCurrentUser,
  setCurrentUser,
} from 'Modules/Shared/actions/users';
import handleFetchLinkedInSat from './handleFetchLinkedInSAT';
import handleLinkedInSATFailure from './handleLinkedInSATFailure';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

function* handleFetchCurrentUser() {
  try {
    const [currentUserResponse, userOrgSettingsResponse] = yield all([
      call(getCurrentUser),
      call(getUserOrgSettings),
    ]);

    const currentUser = currentUserResponse.updateIn(
      ['message', 'data', 'orgSettings'],
      m => m.merge(userOrgSettingsResponse.data)
    );

    yield put(setCurrentUser(currentUser.data));
  } catch (e) {
    yield put(
      pushSnackbarMessage({ message: 'Failed to refresh your settings' })
    );
  }
}

function* handleResumePausedFlows() {
  try {
    yield call(updateUserSettings, { pause_flows_until: null });
    yield put(fetchCurrentUser());
  } catch (e) {
    yield put(
      pushSnackbarMessage({ message: 'Failed to resume paused flows' })
    );
  }
}

function* watchFetchCurrentUser() {
  yield takeLatest(actionTypes.FETCH_CURRENT_USER, handleFetchCurrentUser);
}

function* watchResumePausedFlows() {
  yield takeLatest(actionTypes.RESUME_PAUSED_FLOWS, handleResumePausedFlows);
}

function* watchBeginFetchLinkedInSat() {
  yield takeLatest(
    actionTypes.FETCH_LINKEDIN_SAT.BEGIN,
    handleFetchLinkedInSat
  );
}

function* watchLinkedInSATFailure() {
  yield takeLatest(
    actionTypes.FETCH_LINKEDIN_SAT.FAILURE,
    handleLinkedInSATFailure
  );
}

export default function* root() {
  yield all([
    fork(watchFetchCurrentUser),
    fork(watchResumePausedFlows),
    fork(watchBeginFetchLinkedInSat),
    fork(watchLinkedInSATFailure),
  ]);
}
