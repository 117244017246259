import { all, fork } from 'redux-saga/effects';
import flowDeleted from './flowDeleted';
import autoImportConfigured from './autoImportConfigured';
import stepStats from './stepStats';
import variants from './variants';
import stepStatsViewed from './stepStatsViewed';

export const FEATURE_CATEGORY = 'flows';

export default function* root() {
  yield all([
    fork(flowDeleted),
    fork(autoImportConfigured),
    fork(stepStats),
    fork(variants),
    fork(stepStatsViewed),
  ]);
}
