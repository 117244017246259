import { call, all } from 'redux-saga/effects';
import { trackBasicEvent } from 'Modules/Shared/sagas/analytics';
import { actionTypes } from 'Modules/FlowsShow/actions';
import { FEATURE_CATEGORY } from 'Modules/FlowsShow/sagas/analytics';

const stepEventsToTrack = [
  {
    actionName: 'Move to Step',
    action: actionTypes.BATCH_MOVE_PEOPLE_TO_STEP.BEGIN,
  },
  { actionName: 'Move to Flow', action: actionTypes.MOVE_PEOPLE_TO_FLOW },
  {
    actionName: 'Remove from Flow',
    action: actionTypes.REMOVE_PEOPLE_FROM_FLOW,
  },
  { actionName: 'Resume Person', action: actionTypes.RESUME_PERSON.BEGIN },
  { actionName: 'Outbox Cancelled', action: actionTypes.CANCEL_SCHEDULED_SEND },
];

const eventName = 'Step Stat Action Clicked';

function* watchStepEvents() {
  yield all(
    stepEventsToTrack.map(({ actionName, action }) =>
      call(trackBasicEvent, {
        featureCategory: FEATURE_CATEGORY,
        eventName,
        action,
        properties: { actionName },
      })
    )
  );
}

export default watchStepEvents;
