// TODO auto-generate this file, disallow direct modifications
import app from 'Modules/App/reducer';
import emails from 'Modules/Emails/reducer';
import PeopleImportDialog from 'Modules/PeopleImportDialog/reducer';
import SpacesImportDialog from 'Modules/Spaces/import/reducer';
import * as shared from 'Modules/Shared/reducers';
import SharingDialog from 'Modules/SharingDialog/reducer';
import { combineReducers } from 'redux-immutable';

export default (asyncReducers = []) =>
  combineReducers({
    app,
    emails,
    SharingDialog,
    PeopleImportDialog,
    SpacesImportDialog,
    ...shared,
    ...asyncReducers,
  });
