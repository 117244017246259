import makeHTTPRequest from '../../makeHTTPRequest';
import { POST } from '../../HTTPMethod';

import { getAdminContentVisibilityEnabled } from 'GrooveHTTPClient/flows';

/**
 * Get all entities for current user.
 * Not yet implemented.
 *
 * @return {Promise}
 * */
export const index = () =>
  makeHTTPRequest({ path: '/api/v2/access_controls/entities' });

/**
 * Get the Entity. Includes list of current_actors and available_actors acting on the Entity.
 *
 * @param {string} entityType
 * @param {number} entityId
 *
 * @return {Promise}
 * */
export const show = ({ entityType, entityId }) =>
  makeHTTPRequest({
    path: `/api/v2/access_controls/entities/${entityId}`,
    queryParameters: {
      type: entityType,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

/**
 * Change an Actor's scopes for the Entity.
 *
 * @param {string} entityType
 * @param {number} entityId
 * @param {string} actorType
 * @param {number} actorId
 * @param {Array.<string>} scopes
 *
 * @return {Promise}
 * */
export const setAccessControl = ({
  entityType,
  entityId,
  actorType,
  actorId,
  scopes = [],
}) =>
  makeHTTPRequest({
    path: `/api/v2/access_controls/entities/${entityId}/set_access_control`,
    method: POST,
    body: {
      type: entityType,
      actor_type: actorType,
      actor_id: actorId,
      scopes: scopes.join(','),
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });
