import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as Sentry from '@sentry/react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { ApiUser, userGet } from '../services/v1/user';
import {
  USER_GET_CACHE_TIME,
  USER_GET_STALE_TIME,
} from '../utils/cacheSettings';
import { isClientConfigured } from '../services/v1/getClient';

const useUserGet = (
  options?:
    | Omit<
        UseQueryOptions<ApiUser, unknown, ApiUser, 'userGet'>,
        'queryKey' | 'queryFn'
      >
    | undefined,
): UseQueryResult<ApiUser, unknown> => {
  const settingOptions = { ...options };
  if (!isClientConfigured()) {
    settingOptions.enabled = false;
  }

  const result = useQuery('userGet', userGet, {
    cacheTime: USER_GET_CACHE_TIME,
    staleTime: USER_GET_STALE_TIME,
    ...settingOptions,
  });

  if (result.data?.data) {
    Sentry.setUser({
      id: result.data.data.id.toString(),
      email: result.data.data.email,
      username: result.data.data.name,
    });
    datadogLogs.setUser({
      id: result.data.data.id.toString(),
      email: result.data.data.email,
      name: result.data.data.name,
    });
    datadogRum.setUser({
      id: result.data.data.id.toString(),
      email: result.data.data.email,
      name: result.data.data.name,
    });
  }

  return result;
};

export default useUserGet;
