import {
  actionTypes as omnibarActionTypes,
  setOmnibarOpen,
} from 'Modules/Shared/actions/omnibar';
import { location } from 'Modules/Shared/selectors/location';
import { handleWorkStepActivePersonChange } from 'Modules/Shared/sagas/dialer';
// workstep imports
import { actionTypes as workstepActionTypes } from 'Modules/WorkStep/actions';
import {
  setActiveAction,
  actionTypes as actionsActionTypes,
} from 'Modules/Actions/actions';
import {
  getActivePerson as workstepActivePerson,
  getStepType as workstepStepType,
} from 'Modules/WorkStep/selectors';
// people table imports
import { actionTypes as peopleActionTypes } from 'Modules/People/actions';
import { getActivePerson as peopleActivePerson } from 'Modules/People/selectors';
// action imports
import { getActiveAction as activeAction } from 'Modules/Actions/selectors';
import { actionTypes as locationActionTypes } from 'Modules/Shared/actions/location';
//  accounts pane imports
import {
  getActivePerson as accountsPaneActivePerson,
  getActiveUnassociatedContact as accountsPaneUnassociatedActiveContact,
} from 'Modules/Shared/selectors/accountPreview';
import { actionTypes as accountsPaneActionTypes } from 'Modules/Shared/actions/accountPreview';
import { actionTypes as conversationPaneActionTypes } from 'Modules/Shared/actions/conversations';
import { NEW_ONE_OFF_ACTION_ID, SOURCES } from 'Modules/Actions/constants';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  take,
  takeLatest,
  select,
} from 'redux-saga/effects';
import {
  shouldShowOmnibar,
  omnibarOpen,
  getActiveAccountSfdcId,
} from 'Modules/Shared/selectors/omnibar';
import { ACCOUNTS_ROUTE_NAME, SPACES_SHOW_ROUTE_NAME } from 'Routing/constants';

const DISPLAY_ACCOUNT_ROUTES = Object.freeze([
  ACCOUNTS_ROUTE_NAME,
  SPACES_SHOW_ROUTE_NAME,
]);

function* postMessageToOmnibar({ type, payload = {} }) {
  yield call(
    window.postMessage,
    {
      type,
      payload,
      meta: { target: 'omnibar' },
    },
    window.location.origin
  );
}

function createIncomingActionEventChannel() {
  return eventChannel(emitter => {
    const handler = message => {
      // Ensure the message came from this same window
      if (message.origin !== window.location.origin) return;

      // Ensure the message is a FSA from Chrome extension Dialer
      const action = message.data;
      if (!action) return;
      if (!action.meta || action.meta.source !== 'omnibar') return;

      emitter(action);
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  });
}

function* handlePostMessageToOmnibar(activePerson) {
  if (activePerson && activePerson.get('sfdcId')) {
    yield* postMessageToOmnibar({
      type: 'SET_ACTIVE_RECORD_IN_GE',
      payload: {
        id: activePerson.get('sfdcId'),
        email: activePerson.get('email'),
      },
    });
  }
}

function* handlePostPersonToOmnibar(activePerson) {
  if (activePerson && activePerson.sfdcId && activePerson.email) {
    yield* postMessageToOmnibar({
      type: 'SET_ACTIVE_RECORD_IN_GE',
      payload: {
        id: activePerson.sfdcId,
        email: activePerson.email,
      },
    });
  }
}

function* setActiveAccountRecord({ sfdcId }) {
  yield* postMessageToOmnibar({
    type: 'SET_ACTIVE_RECORD_IN_GE',
    payload: {
      id: sfdcId,
    },
  });
}

function* setActiveWorkstepPerson() {
  const activePerson = yield select(workstepActivePerson);
  yield handlePostMessageToOmnibar(activePerson);
}

function* setActiveActionRecipient() {
  const action = yield select(activeAction);

  if (!action) return;

  if (action.source === SOURCES.WORKSPACE) {
    const activeAccount = action.get('account');
    yield call(setActiveAccountRecord, { sfdcId: activeAccount.get('sfdcId') });
  }
  if ([SOURCES.FLOW, SOURCES.ONE_OFF, SOURCES.PLAY].includes(action.source)) {
    const activePerson = action?.get('who');
    yield handlePostMessageToOmnibar(activePerson);
  }
}

function* setActivePersonInAccountsPane(activePerson) {
  const person = yield select(activePerson);
  yield handlePostMessageToOmnibar(person);
}

function* setActivePersonInConversationsPane(action) {
  const { payload } = action;
  yield handlePostPersonToOmnibar(payload);
}

function* setActivePeoplePageRecord() {
  const activePerson = yield select(peopleActivePerson);
  yield handlePostMessageToOmnibar(activePerson);
}

function* processActionFromOmnibar(action) {
  const { type, payload } = action;

  switch (type) {
    case 'APP_READY': {
      yield* setActiveWorkstepPerson();
      break;
    }

    case 'SET_OMNIBAR_OPEN': {
      yield put(setOmnibarOpen(payload));
      break;
    }

    case 'CALL_WORKSTEP_CLICK_TO_DIAL': {
      yield* handleWorkStepActivePersonChange(payload);
      break;
    }

    case 'OPEN_ACTION': {
      yield put(setActiveAction(payload));
      break;
    }

    case 'CREATE_INSTANT_ACTION': {
      yield put(
        setActiveAction({
          ...payload,
          id: NEW_ONE_OFF_ACTION_ID,
          source: 'ONE_OFF',
        })
      );
      break;
    }

    default: {
      console.debug('Ignored action from omnibar', action); // eslint-disable-line no-console
    }
  }

  yield null;
}

function* triggerOpenOmnibar() {
  // If omnibar is closed, pop it open.
  const omnibarIsOpen = yield select(omnibarOpen);
  if (!omnibarIsOpen) {
    yield* postMessageToOmnibar({
      type: 'OPEN_OMNIBAR',
    });
  }
}

// -------------- Handlers --------------
function* handleLocationChangeStart() {
  // close omnibar whenever there's a route change
  yield put(setOmnibarOpen(false));
  yield* postMessageToOmnibar({
    type: 'CLOSE_OMNIBAR',
  });
}

function* handleLocationUpdate() {
  const locationMap = yield select(location);
  const showOmnibar = yield select(shouldShowOmnibar);
  const routeName = locationMap.get('routeName');
  if (!showOmnibar) {
    yield* postMessageToOmnibar({
      type: 'HIDE_OMNIBAR',
      payload: true,
    });
  } else {
    yield* postMessageToOmnibar({
      type: 'HIDE_OMNIBAR',
      payload: false,
    });
  }

  const activeRecordSetterForRouteName = {
    workStep: setActiveWorkstepPerson,
    people: setActivePeoplePageRecord,
    accounts: setActiveAccountRecord,
    spacesShow: setActiveAccountRecord,
  };

  const activeRecordSetter = activeRecordSetterForRouteName[routeName];
  if (activeRecordSetter) {
    if (DISPLAY_ACCOUNT_ROUTES.includes(routeName)) {
      const sfdcId = yield select(getActiveAccountSfdcId);
      if (sfdcId) {
        yield call(activeRecordSetter, { sfdcId });
      }
    } else {
      yield* activeRecordSetter();
    }
  }

  const payload = {
    routeName,
  };

  // If the routeName is workStep, include the type of step (call or template) in the payload
  if (routeName === 'workStep') {
    payload.stepType = yield select(workstepStepType);
  }

  yield* postMessageToOmnibar({
    type: 'UPDATE_GE_CONTEXT_INFO',
    payload,
  });
}

function* handlePeopleActivePersonChange() {
  yield* triggerOpenOmnibar();
  yield* setActivePeoplePageRecord();
}

function* handleActiveAccountChange({ payload: { sfdcId } }) {
  if (sfdcId) {
    yield call(setActiveAccountRecord, { sfdcId });
  }
}

// -------------- Watchers --------------

function* watchForIncomingActionsFromOmnibar() {
  const channel = createIncomingActionEventChannel();

  while (true) {
    const action = yield take(channel);
    yield call(processActionFromOmnibar, action);
  }
}

function* watchRouteChangeStart() {
  yield takeLatest(locationActionTypes.START_CHANGE, handleLocationChangeStart);
}

function* watchRouteChangeFinish() {
  yield takeLatest(locationActionTypes.FINISH_CHANGE, handleLocationUpdate);
}

// workstep watchers

function* watchWorkStepActivePersonChange() {
  yield takeLatest(
    workstepActionTypes.SET_ACTIVE_PERSON_ID,
    setActiveWorkstepPerson
  );
}

// people table watcher

function* watchPeopleActivePersonChange() {
  yield takeLatest(
    peopleActionTypes.SET_ACTIVE_PERSON_ID,
    handlePeopleActivePersonChange
  );
}

// accounts page watcher

function* watchActiveAccountChange() {
  yield takeLatest(
    omnibarActionTypes.SET_ACTIVE_ACCOUNT_SFDC_ID,
    handleActiveAccountChange
  );
}

function* watchActiveActionChange() {
  yield takeLatest(
    actionsActionTypes.SET_ACTIVE_ACTION,
    setActiveActionRecipient
  );
}

function* watchActivePersonInAccountsPaneChange() {
  yield takeLatest(
    accountsPaneActionTypes.SET_ACTIVE_PERSON_ID,
    setActivePersonInAccountsPane,
    accountsPaneActivePerson
  );
}

function* watchUnassociatedContactInAccountsPaneChange() {
  yield takeLatest(
    accountsPaneActionTypes.SELECT_UNASSOCIATED_CONTACTS,
    setActivePersonInAccountsPane,
    accountsPaneUnassociatedActiveContact
  );
}

function* watchActivePersonInConversationsPaneChange() {
  yield takeLatest(
    conversationPaneActionTypes.SET_ACTIVE_PERSON_SFDCID_AND_EMAIL,
    setActivePersonInConversationsPane
  );
}

function* watchOpenOmnibar() {
  yield takeLatest(omnibarActionTypes.OPEN_OMNIBAR, triggerOpenOmnibar);
}

export default function* root() {
  yield all([
    fork(watchForIncomingActionsFromOmnibar),
    fork(watchWorkStepActivePersonChange),
    fork(watchPeopleActivePersonChange),
    fork(watchActiveAccountChange),
    fork(watchRouteChangeStart),
    fork(watchRouteChangeFinish),
    fork(watchOpenOmnibar),
    fork(watchActiveActionChange),
    fork(watchActivePersonInAccountsPaneChange),
    fork(watchUnassociatedContactInAccountsPaneChange),
    fork(watchActivePersonInConversationsPaneChange),
  ]);
}
