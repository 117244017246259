import { Record } from 'immutable';

const defaults = {
  sObjectTaskId: null,
};

export default class WorkspacesMetadata extends Record(defaults) {
  static from({ sObjectTaskId }) {
    return new WorkspacesMetadata({
      sObjectTaskId,
    });
  }
}
