import { ResponseTimeSlot } from '@groove/api/services/v1/scheduler';
import groupBy from 'lodash.groupby';
import chunk from 'lodash.chunk';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import momentTimezone from 'moment-timezone';
import { renderToStaticMarkup } from 'react-dom/server';

const generatePlainText = (
  timeSlots: ResponseTimeSlot[],
  timeZone: string,
): string => {
  const timeSlotsGroups = Object.entries(
    groupBy(timeSlots, (timeSlot: ResponseTimeSlot) =>
      formatInTimeZone(
        parseISO(timeSlot.starts_at),
        timeZone,
        'E, MMM dd',
      ).toString(),
    ),
  );
  const timeSlotGroupTableRows = chunk(timeSlotsGroups, 3);

  const plainTextHTML = renderToStaticMarkup(
    <div>
      These time slots are in {momentTimezone.tz(timeZone).format('z')}.
      {timeSlotGroupTableRows.map((tableRow, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${tableRow}-${index}`}>
          {tableRow.map(([date, timeSlots]) => (
            <div>
              {date}
              <div key={date}>
                {timeSlots.map(timeSlot => (
                  <div key={timeSlot.starts_at.toString()}>
                    {' - '}
                    {formatInTimeZone(
                      parseISO(timeSlot.starts_at),
                      timeZone,
                      'h:mm a',
                    )}
                    {' - '}
                    {formatInTimeZone(
                      parseISO(timeSlot.ends_at),
                      timeZone,
                      'h:mm a',
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>,
  );
  return plainTextHTML;
};

export default generatePlainText;
