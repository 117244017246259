import { FC, useEffect } from 'react';
import Button, { AdditionMenuItems } from '@groove/ui/Components/Button';
import {
  useCreateAction,
  useUpdateAction,
  CreateActionOptions,
  UpdateActionOptions,
  DeleteActionOptions,
  useDeleteAction,
  cleanAction,
} from '@groove/api/hooks/useMutateAction';
import {
  ExecuteAction,
  ExecuteActionOptions,
  NEW_ACTION_ID,
  executeAction,
} from '@groove/api/gateway/v1/actionCompose';
import CalendarTimePicker from '@groove/ui/Components/CalendarTimePicker';
import { useMutation } from 'react-query';
import {
  flowsPauseUntil,
  removeFromFlow,
  skipPersonStep,
} from '@groove/api/gateway/v1/flows';
import {
  useGetCurrentUser,
  useGetOrgSettings,
} from '@groove/api/hooks/useUsers';
import useDialerStore from '@groove/dialer-components/hooks/useStore';
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';
import { HTTPError } from 'ky';

import useStore from '../store/useStore';
import PrimaryExecuteButton from '../component/PrimaryExecuteButton';
import { CompleteType } from '../types';
import { didActionChange, englishJoiner, getErrorMessage } from '../utils';
import {
  MARK_AS_COMPLETE_TOOLTIP_TEXT,
  SAVE_AND_NEXT_TOOLTIP_TEXT,
  SAVE_AND_CLOSE_TOOLTIP_TEXT,
  errorMessages,
  ErrorType,
  RECIPIENT_NOT_FOUND_MODAL_TEXT,
} from '../constants';
import { generateSaveTooltip } from '../utils/generateTooltip';
import {
  ACTION_EVENT,
  ACTION_VERSION,
  trackActionEvents,
} from '../analytics/actionComposeEvents';
import { messagesHashed } from '../store/messageBarStore';

export type ExecutionBarProps = {
  onComplete: (id?: string, type?: CompleteType) => void;
  saveAndCloseOnly?: true;
};

const ExecutionBar: FC<ExecutionBarProps> = ({
  onComplete,
  saveAndCloseOnly,
}) => {
  const assignee = useStore(store => store.action.assignee);
  const id = useStore(store => store.action.id);
  const personStepId = useStore(store => store.action.personStepId);
  const type = useStore(store => store.action.type);
  const name = useStore(store => store.action.who?.name);
  const whoId = useStore(store => store.action.who?.id);
  const flow = useStore(store => store.action.flow);
  useStore(store => messagesHashed(Array.from(store.saveErrors)));
  useStore(store => messagesHashed(store.executionErrors));
  const saveErrors = Array.from(useStore.getState().saveErrors);
  const { executionErrors } = useStore.getState();
  const hasCalled = useStore(store => store.action.dynamicData.hasCalled);
  const callState = useDialerStore(store => store.callState);

  const dialerInActionEnabled = useVariation('dialer-in-action');
  const { data: orgSettings } = useGetOrgSettings();

  const createActionOptions: CreateActionOptions = {
    // eslint-disable-next-line consistent-return
    onSuccess: action => {
      if (id !== NEW_ACTION_ID) {
        onComplete(id, 'saveAndNext');
      } else if (action.id) {
        onComplete();
        useStore.getState().updateAction({ id: action.id });
      }
    },
    onError: error => {
      console.error(errorMessages.tryAgain('create action'), error);
      if (error instanceof HTTPError) {
        const { status } = error.response;
        trackActionEvents({
          eventName: ACTION_EVENT.SAVED,
          action: useStore.getState().action,
          actionVersion: ACTION_VERSION.V2,
          errorCode: status.toString(),
        });
        const message = getErrorMessage(ErrorType.CreateActionError, status);
        useStore
          .getState()
          .setRequestErrorMessage(ErrorType.CreateActionError, message);
      } else {
        useStore
          .getState()
          .setRequestErrorMessage(
            ErrorType.CreateActionError,
            errorMessages.tryAgain('create action'),
          );
      }
    },
  };

  const updateActionOptions: UpdateActionOptions = {
    onSuccess: () => onComplete(id.toString(), 'saveAndNext'),
    onError: error => {
      console.error(errorMessages.tryAgain('save action'), error);
      if (error instanceof HTTPError) {
        const { status } = error.response;
        trackActionEvents({
          eventName: ACTION_EVENT.SAVED,
          action: useStore.getState().action,
          actionVersion: ACTION_VERSION.V2,
          errorCode: status.toString(),
        });
        const message = getErrorMessage(ErrorType.UpdateActionError, status);
        useStore
          .getState()
          .setRequestErrorMessage(ErrorType.UpdateActionError, message);
      } else {
        useStore
          .getState()
          .setRequestErrorMessage(
            ErrorType.UpdateActionError,
            errorMessages.tryAgain('save action'),
          );
      }
    },
  };

  const deleteActionOptions: DeleteActionOptions = {
    onSuccess: actionId => {
      console.log('success delete');
      if (actionId) onComplete(actionId.toString(), 'delete');
      else {
        console.error('Unable to delete action, most likely a flow action');
        useStore
          .getState()
          .setRequestErrorMessage(
            ErrorType.DeleteActionError,
            'Unable to delete action, most likely a flow action.',
          );
      }
    },
    onError: error => {
      console.error(errorMessages.tryAgain('delete action'), error);
      if (error instanceof HTTPError) {
        const { status } = error.response;
        trackActionEvents({
          eventName: 'delete',
          action: useStore.getState().action,
          actionVersion: ACTION_VERSION.V2,
          errorCode: status.toString(),
        });
        const message = getErrorMessage(ErrorType.DeleteActionError, status);
        useStore
          .getState()
          .setRequestErrorMessage(ErrorType.DeleteActionError, message);
      } else {
        useStore
          .getState()
          .setRequestErrorMessage(
            ErrorType.DeleteActionError,
            errorMessages.tryAgain('delete action'),
          );
      }
    },
  };

  const { data: userData } = useGetCurrentUser();
  const { mutate: createAction } = useCreateAction(createActionOptions);
  const { mutateAsync: createActionAsync } = useCreateAction();
  const { mutate: updateAction } = useUpdateAction(updateActionOptions);
  const { mutate: deleteAction } = useDeleteAction(deleteActionOptions);
  const { mutate: pauseUntil } = useMutation(flowsPauseUntil, {
    onSuccess: () => onComplete(id.toString(), 'pauseFromFlow'),
    mutationKey: ['flowsPauseUntil'],
  });
  const { mutate: removePeople } = useMutation(removeFromFlow, {
    onSuccess: () => onComplete(id.toString(), 'removeFromFlow'),
    mutationKey: ['removeFromFlow'],
  });
  const { mutate: skipStep } = useMutation(skipPersonStep, {
    onSuccess: (_, params) =>
      onComplete(
        id.toString(),
        params.skip_type === 'ALL_CALL_STEPS' ? 'skipAllCallSteps' : 'skipStep',
      ),
    mutationKey: ['skipPersonStep'],
  });
  const { mutate: markAsComplete } = useMutation(executeAction, {
    onSuccess: () => {
      onComplete(id.toString(), 'markAsComplete');
    },
    onError: error => {
      console.error(errorMessages.tryAgain('mark action as complete'), error);
      trackActionEvents({
        eventName: ACTION_EVENT.MARKED_AS_COMPLETE,
        action: useStore.getState().action,
        actionVersion: ACTION_VERSION.V2,
        errorCode: errorMessages.tryAgain('mark action as complete'),
      });
      useStore
        .getState()
        .setRequestErrorMessage(
          'MarkAsCompleteError',
          errorMessages.tryAgain('mark action as complete'),
        );
    },
    mutationKey: ['executeAction'],
  });

  const { mutate: onExecuteAction } = useMutation(executeAction, {
    onError: error => {
      console.error(errorMessages.tryAgain('execute action'), error);
      if (error instanceof HTTPError) {
        const { status } = error.response;
        trackActionEvents({
          eventName: ACTION_EVENT.LOGGED,
          action: useStore.getState().action,
          actionVersion: ACTION_VERSION.V2,
          errorCode: status.toString(),
        });
        const message = getErrorMessage(ErrorType.ExecuteActionError, status);
        useStore
          .getState()
          .setRequestErrorMessage(ErrorType.ExecuteActionError, message);
      } else {
        useStore
          .getState()
          .setRequestErrorMessage(
            ErrorType.ExecuteActionError,
            `Unable to execute action ${error}.`,
          );
      }
    },
    mutationKey: ['executeAction'],
  });

  useEffect(() => {
    useStore.getState().setOtherValues({ pauseDate: new Date() });
  }, [id]);

  // Delete Clicks
  const onDelete = (): void => {
    deleteAction(useStore.getState().action.id);
  };

  const handleDeleteButton = (): void => {
    const { originalAction, action: currentAction } = useStore.getState();
    if (id === NEW_ACTION_ID) {
      if (didActionChange(originalAction, currentAction)) {
        useStore.getState().setNewModal({
          isOpen: true,
          onConfirm: onComplete,
          contentText:
            'Deleting this action cannot be undone. Are you sure you want to proceed?',
          title: 'Delete action',
          confirmText: 'Delete',
        });
      } else {
        onComplete();
      }
    } else
      useStore.getState().setNewModal({
        isOpen: true,
        onConfirm: onDelete,
        contentText:
          'Deleting this action cannot be undone. Are you sure you want to proceed?',
        title: 'Delete action',
        confirmText: 'Delete',
      });
  };

  // Save clicks
  const createOrUpdate = (): void => {
    if (!id || id === NEW_ACTION_ID) {
      trackActionEvents({
        eventName: ACTION_EVENT.SAVED,
        action: useStore.getState().action,
        actionVersion: ACTION_VERSION.V2,
      });
      createAction(useStore.getState().action);
    } else {
      trackActionEvents({
        eventName: ACTION_EVENT.SAVED,
        action: useStore.getState().action,
        actionVersion: ACTION_VERSION.V2,
      });
      updateAction(useStore.getState().action);
    }
  };

  // Mark as complete clicks
  const onMarkAsComplete = async (): Promise<void> => {
    const currentAction = useStore.getState().action;
    let cleanedAction = cleanAction(useStore.getState().action);
    let newId = id;

    try {
      if (id === NEW_ACTION_ID) {
        const rawAction = await createActionAsync(useStore.getState().action);
        cleanedAction = cleanAction(rawAction);
        newId = rawAction.id;
      }

      const newAction: ExecuteAction = {
        ...cleanedAction,
        assigneeId: currentAction.assignee?.id || 0,
        markAsComplete: true,
      };

      trackActionEvents({
        eventName: ACTION_EVENT.MARKED_AS_COMPLETE,
        action: useStore.getState().action,
        actionVersion: ACTION_VERSION.V2,
      });
      markAsComplete({ action: newAction, id: newId });
    } catch (e) {
      console.error('Was not able to mark action as complete');
    }
  };

  const sendAndExecute = async (
    options?: ExecuteActionOptions,
    overrideOnSuccess?: (data?: Record<string, unknown>) => void,
  ): Promise<void> => {
    const currentAction = useStore.getState().action;
    let cleanedAction = cleanAction(currentAction);
    let newId = id;

    try {
      if (id === NEW_ACTION_ID) {
        const rawAction = await createActionAsync(useStore.getState().action);
        cleanedAction = cleanAction(rawAction);
        newId = rawAction.id;
      }
      const newAction: ExecuteAction = {
        ...cleanedAction,
        assigneeId: currentAction.assignee?.id || 0,
        ...options,
        trackClicks: currentAction.dynamicData.trackClicks
          ? currentAction.dynamicData.trackClicks
          : undefined,
        trackOpens: currentAction.dynamicData.trackOpens
          ? currentAction.dynamicData.trackOpens
          : undefined,
      };
      onExecuteAction(
        { action: newAction, id: newId },
        {
          onSuccess: data => {
            if (!overrideOnSuccess) onComplete(id.toString(), 'execute');
            else overrideOnSuccess(data);
          },
        },
      );
    } catch (e) {
      console.error('Was not able to execute action');
    }
  };

  // Handle onSendAndExecute
  const onSendAndExecute = async (
    options?: ExecuteActionOptions,
    overrideOnSuccess?: (data?: Record<string, unknown>) => void,
  ): Promise<void> => {
    trackActionEvents({
      eventName: ACTION_EVENT.LOGGED,
      action: useStore.getState().action,
      actionVersion: ACTION_VERSION.V2,
    });
    const currentAction = useStore.getState().action;

    const unknownRecipientFound = currentAction.toRecipients?.some(
      item => item.attributes?.type === 'Unknown',
    );
    if (unknownRecipientFound)
      useStore.getState().setNewModal({
        isOpen: true,
        onCancel: () => {
          useStore.getState().setModalValues({
            isOpen: false,
          });
          sendAndExecute(options, overrideOnSuccess);
        },
        contentText: RECIPIENT_NOT_FOUND_MODAL_TEXT,
        title: 'Recipient not found in Salesforce',
        cancelText: 'Send without logging',
        confirmText: 'Do not send',
      });
    else sendAndExecute(options, overrideOnSuccess);
  };

  // Flow Clicks
  const onPauseFlow = (): void => {
    if (!flow.id || !whoId) return;
    pauseUntil({
      flowId: flow.id,
      pausedDate: useStore.getState().otherValues.pauseDate.toISOString(),
      peopleIds: [whoId],
    });
  };
  const onRemoveFromFlow = (): void => {
    if (!flow.id || !whoId) return;
    removePeople({
      flowId: flow.id,
      peopleIds: [whoId],
    });
  };
  const onSkipStep = (): void => {
    if (!id) return;
    skipStep({
      skip_type: 'THIS_STEP',
      actionId: id,
    });
  };
  const onSkipAllSteps = (): void => {
    if (!id) return;
    skipStep({
      skip_type: 'ALL_CALL_STEPS',
      actionId: id,
    });
  };

  const PauseFlows: FC = () => {
    const pauseDate = useStore(store => store.otherValues.pauseDate);
    return (
      <CalendarTimePicker
        date={pauseDate}
        onDateChange={pauseDate => {
          useStore.getState().setOtherValues({ pauseDate });
        }}
        className="py-[12px] min-w-[435px]"
        minDate={new Date()}
      />
    );
  };

  const openPauseFlow = (): void => {
    useStore.getState().setNewModal({
      isOpen: true,
      confirmText: 'Pause',
      onConfirm: onPauseFlow,
      content: <PauseFlows />,
      title: `Pause flow for ${name}`,
    });
  };

  const openRemoveFromFlow = (): void => {
    useStore.getState().setNewModal({
      isOpen: true,
      onConfirm: onRemoveFromFlow,
      contentText: `Are you sure you want to remove ${name} from ${flow.name}?`,
      title: 'Remove from flow',
      confirmText: 'Remove',
    });
  };

  const openSkipStep = (): void => {
    useStore.getState().setNewModal({
      isOpen: true,
      onConfirm: onSkipStep,
      contentText: `Are you sure you want to skip this step - ${
        useStore.getState().action.summary
      }?`,
      title: 'Skip this step',
      confirmText: 'Skip',
    });
  };

  const openSkipAllSteps = (): void => {
    useStore.getState().setNewModal({
      isOpen: true,
      onConfirm: onSkipAllSteps,
      contentText: `Are you sure you want to skip all call steps for ${name} in ${flow.name}?`,
      title: 'Skip all call steps',
      confirmText: 'Skip all',
    });
  };

  const flowButtonItems: AdditionMenuItems[] = [];
  const isDialerInActionEnabled =
    dialerInActionEnabled &&
    userData?.dialer_enabled &&
    !orgSettings?.third_party_dialer_enabled;

  if (isDialerInActionEnabled) {
    flowButtonItems.push({
      text: 'Log without calling',
      onClick: onSendAndExecute,
      disabled: !!executionErrors.length,
      tooltip: executionErrors
        ? `Please complete required fields: ${englishJoiner(
            executionErrors.map(error => error.readableLocation),
          )}`
        : MARK_AS_COMPLETE_TOOLTIP_TEXT,
    });
  }

  if (!userData?.dialer_enabled || isDialerInActionEnabled)
    flowButtonItems.push({
      text: 'Mark as complete',
      onClick: onMarkAsComplete,
      disabled: !!executionErrors.length,
      tooltip: executionErrors.length
        ? `Please complete required fields: ${englishJoiner(
            executionErrors.map(error => error.readableLocation),
          )}`
        : MARK_AS_COMPLETE_TOOLTIP_TEXT,
    });

  flowButtonItems.push({
    text: 'Pause person in flow',
    onClick: openPauseFlow,
  });
  flowButtonItems.push({
    text: 'Remove person from flow',
    onClick: openRemoveFromFlow,
  });
  flowButtonItems.push({
    text: 'Skip this step',
    onClick: openSkipStep,
  });

  if (type === 'CALL')
    flowButtonItems.push({
      text: 'Skip all call steps',
      onClick: openSkipAllSteps,
    });

  let tooltipSave = generateSaveTooltip();
  if (!tooltipSave) {
    tooltipSave =
      id !== NEW_ACTION_ID && !saveAndCloseOnly
        ? SAVE_AND_NEXT_TOOLTIP_TEXT
        : SAVE_AND_CLOSE_TOOLTIP_TEXT;
  }

  const saveButtonText =
    id !== NEW_ACTION_ID && !saveAndCloseOnly
      ? 'Save and next'
      : 'Save and close';

  if (assignee?.id !== userData?.id) {
    return (
      <div className="w-full h-[48px] flex relative border-neutral/100 border-t border-solid border-r-0 border-l-0 border-b-0">
        {/* A spacer to force the buttons to the right */}
        <div className="flex-1" />
        <div className="flex flex-row p-[6px] items-center">
          <Button
            variant="tertiary"
            onClick={handleDeleteButton}
            className="ml-[8px]"
            disabled={!!personStepId}
            tooltip={
              personStepId ? 'Flow Actions cannot be deleted' : undefined
            }
          >
            Delete
          </Button>
          <Button
            variant="primary"
            onClick={createOrUpdate}
            className="ml-[8px]"
            additionalMenuWidth={202}
            additionalMenuDirection="topRight"
            tooltip="Assignee does not match current user"
          >
            Assign
          </Button>
        </div>
      </div>
    );
  }

  const shouldShowMainBar =
    (callState === 'not-connected' &&
      isDialerInActionEnabled &&
      type === 'CALL') ||
    type !== 'CALL' ||
    !isDialerInActionEnabled;

  return (
    <div className="w-full h-[48px] flex relative border-neutral/100 border-t border-solid border-r-0 border-l-0 border-b-0">
      {/* A spacer to force the buttons to the right */}
      <div className="flex-1" />
      <div className="flex flex-row p-[6px] items-center">
        {shouldShowMainBar && (
          <>
            <Button
              actionName="Action Compose Delete"
              variant="tertiary"
              onClick={handleDeleteButton}
              className="ml-[8px]"
              disabled={!!personStepId}
              tooltip={
                personStepId ? 'Flow Actions cannot be deleted' : undefined
              }
            >
              Delete
            </Button>
            <Button
              actionName={`Action Compose ${saveButtonText}`}
              variant="secondary"
              onClick={createOrUpdate}
              className="ml-[8px]"
              disabled={!!saveErrors.length}
              tooltip={tooltipSave}
            >
              {id !== NEW_ACTION_ID && !saveAndCloseOnly
                ? 'Save and next'
                : 'Save and close'}
            </Button>
            {!!personStepId && (!hasCalled || !isDialerInActionEnabled) && (
              <Button
                actionName="Action Compose More"
                variant="secondary"
                className="ml-[8px]"
                additionalMenuItems={flowButtonItems}
                additionalMenuWidth={165}
                additionalMenuDirection="topRight"
              >
                More
              </Button>
            )}
          </>
        )}
        <PrimaryExecuteButton onComplete={onComplete} />
      </div>
    </div>
  );
};

export default ExecutionBar;
