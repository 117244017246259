import ClariTheme from 'Utils/theme/ClariTheme';

export default {
  flowListContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: '8px',
    '&:hover': {
      cursor: 'default',
    },
  },
  flowTitle: {
    marginLeft: '50px',
    fontSize: '15px',
    color: ClariTheme.palette.text.primary,
  },
  flowList: {
    marginLeft: '47px',
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 55px)',
  },
};
