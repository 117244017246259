import { InfiniteData, QueryClient } from 'react-query';

import {
  ChatChannel,
  ChatMessage,
  ChatMessageAttributes,
} from '../api/graphql/types';

const pushMessageToChannelQueryData = (
  queryClient: QueryClient,
  channelSid: string,
  body: string,
  outbound: boolean,
  attributes: ChatMessageAttributes = {},
): void => {
  queryClient.setQueryData<InfiniteData<ChatChannel> | undefined>(
    ['chat-channel', channelSid],
    data => {
      if (!data || !data.pages) return undefined;

      return {
        pages: [
          {
            ...data!.pages[0],
            messages: {
              nodes: [
                {
                  body,
                  dateCreated: new Date().toISOString(),
                  outbound,
                  attributes,
                } as ChatMessage,
                ...data!.pages[0].messages!.nodes,
              ],
              pageInfo: data!.pages[0].messages!.pageInfo,
            },
          },
          ...data!.pages.slice(1),
        ],
        pageParams: data!.pageParams,
      };
    },
  );
};

export default pushMessageToChannelQueryData;
