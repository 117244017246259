import ky, { Options } from 'ky';

import getClient from '../../getClient';

// eslint-disable-next-line import/no-mutable-exports
export let options: Options | null = null;

export const configureClient = (clientOptions: Options | null): void => {
  options = clientOptions;
};

export const isClientConfigured = (): boolean => !!options;

export default (): typeof ky => {
  if (options === null || !options.prefixUrl) {
    throw new Error(
      'The services client requires a prefix URL to be specified.',
    );
  }
  return getClient().extend(options);
};
