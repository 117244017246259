import { Record, Map } from 'immutable';

import HTTPResponse from './HTTPResponse';

const defaults = {
  response: new HTTPResponse(),
  request: new Map(),
  path: '',
};

class HTTPError extends Record(defaults) {
  // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/status
  // Browsers report a status of 0 in case of XMLHttpRequest errors
  get message() {
    return `${this.path} - ${this.response.get('status') || 0}`;
  }
}

export default HTTPError;
