import { connect } from 'react-redux';
import { actions, selectors } from '@groove-labs/groove-ui';
import { LEAVE_PAGE_CONFIRMATION_KEY_PATH } from 'Modules/Shared/constants';
import LeavePageConfirmation from 'Modules/Shared/components/LeavePageConfirmation';
import { isFunction } from 'lodash-es';
import { Map } from 'immutable';

const { getProperty } = selectors.ui;
const { setProperty } = actions.ui;

const mapStateToProps = state => ({
  confirmationStatus: getProperty(
    state,
    LEAVE_PAGE_CONFIRMATION_KEY_PATH,
    Map({
      show: false,
      type: null,
    })
  ),
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  handleOk: () => {
    if (isFunction(ownProps.onConfirm)) {
      ownProps.onConfirm();
    }
    dispatch(
      setProperty({
        uiKeyPath: LEAVE_PAGE_CONFIRMATION_KEY_PATH,
        data: {
          show: false,
          type: null,
          nextRoute: null,
        },
      })
    );
  },
  handleCancel: () => {
    if (isFunction(ownProps.onCancel)) {
      ownProps.onCancel();
    }
    dispatch(
      setProperty({
        uiKeyPath: LEAVE_PAGE_CONFIRMATION_KEY_PATH,
        data: {
          show: false,
          type: null,
          nextRoute: null,
        },
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeavePageConfirmation);
