import { TemplateFolder } from '@groove/api/services/v1/templateFolders';
import { DropDownItem } from '@groove/ui/Components/DropDownItem';
import { Html5Entities } from 'html-entities';

export default function buildFolderDropdownByOwner(
  folders: TemplateFolder[],
): DropDownItem[] {
  const [ownedFolders, sharedFolders] = [
    folders.filter(folder => folder.is_owned_by_current_user),
    folders.filter(folder => !folder.is_owned_by_current_user),
  ];

  const ownedFoldersDropdown: DropDownItem[] = ownedFolders.length
    ? [
        {
          key: 'ownedFoldersHeader',
          type: 'header',
          value: 'My folders',
        },
        ...ownedFolders.map(folder => ({
          key: folder.id,
          value: folder.id,
          text: Html5Entities.decode(folder.name),
        })),
      ]
    : [];

  const sharedFoldersDropdown: DropDownItem[] = sharedFolders.length
    ? [
        {
          key: 'sharedFoldersHeader',
          type: 'header',
          value: 'Shared folders',
        },
        ...sharedFolders.map(folder => ({
          key: folder.id,
          value: folder.id,
          text: Html5Entities.decode(folder.name),
        })),
      ]
    : [];

  return [...ownedFoldersDropdown, ...sharedFoldersDropdown];
}
