export const IMPORT_ON_BEHALF_OF_GROUP_ID = 'importOnBehalfOf';
export const IMPORT_ON_BEHALF_OF_ASSIGN_TO_FIELD_ID = 'assignTo';
export const IMPORT_ON_BEHALF_OF_ROLES_FIELD_ID = 'roles';
export const IMPORT_ON_BEHALF_OF_TEAMS_FIELD_ID = 'teams';
export const USER_ROLES = [
  {
    value: 'teamLead',
    name: 'Team Lead',
  },
  {
    value: 'analyst',
    name: 'Analyst',
  },
  {
    value: 'admin',
    name: 'Groove Admin',
  },
];
