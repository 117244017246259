import { call, put, select } from 'redux-saga/effects';
import { actions } from '@groove-labs/groove-ui';
import { batchDestroy } from 'GrooveHTTPClient/labelInstances';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { LABEL_IDS_TO_UPDATE_UI_KEY_PATH } from 'Modules/Shared/constants/labels';
import { getCheckedLabelIds } from 'Modules/Shared/selectors/labels';
import HTTPError from 'GrooveHTTPClient/HTTPError';
import HttpStatusCodes from 'http-status-codes';
import updateLabelable from 'Modules/Shared/sagas/labels/updateLabelable';

const { setProperty } = actions.ui;

function* removeLabelInstance({ payload: { id, labelInstances, labelable } }) {
  const labelInstance = labelInstances.get(id);
  const labelId = labelInstance.label.id;

  try {
    yield call(batchDestroy, { labelInstanceIds: [id] });
  } catch (e) {
    if (
      e instanceof HTTPError &&
      (e.response.status === HttpStatusCodes.NOT_FOUND ||
        e.response.status === HttpStatusCodes.INTERNAL_SERVER_ERROR)
    ) {
      yield put(
        pushSnackbarMessage({
          message:
            'There was an error removing labels. Please refresh the page and try again.',
        })
      );
      return;
    }
    throw e;
  }

  yield call(updateLabelable, {
    labelableType: labelable.type,
    labelsToAdd: [],
    labelInstanceIdsToRemove: [id],
  });

  let checkedLabelIds = yield select(state =>
    getCheckedLabelIds(state, LABEL_IDS_TO_UPDATE_UI_KEY_PATH)
  );
  checkedLabelIds = checkedLabelIds.delete(labelId);
  yield put(
    setProperty({
      uiKeyPath: LABEL_IDS_TO_UPDATE_UI_KEY_PATH,
      data: checkedLabelIds,
    })
  );
}

export default removeLabelInstance;
