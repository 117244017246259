import create from 'zustand';

import { SelectedItem } from './SearchAndSelect';
import { DisplayableSearchResult } from './transformSearchResult';

export type SelectedItems = {
  [key: string]: SelectedItem;
};

export type Store = {
  additionalFieldsType: 'email' | 'calendar' | null;
  setAdditionalFieldsType: (type: 'email' | 'calendar') => void;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  additionalFields: {
    [key: string]: string | number | boolean | null;
  };
  setAdditionalField: (
    key: string,
    value: string | number | boolean | null,
  ) => void;
  setAdditionalFields: (additionalFields: {
    [key: string]: string | number | boolean | null;
  }) => void;
  selectedItems: SelectedItems;
  setSelectedItems: (selectedItems: SelectedItems) => void;

  objectFilter: 'All' | string;
  setObjectFilter: (objectFilter: 'All' | string) => void;

  isIdInSelectedItems: (id: string) => boolean;
  removeTopLevelListItem: (id: string) => void;
  handleChangeTopLevelListItem: (
    displayableSearchResult: DisplayableSearchResult,
  ) => void;
  handleChangeWhatListItem: (
    what: DisplayableSearchResult,
    parentWhoId: string,
  ) => void;
  handleChangeWhoListItem: (
    who: DisplayableSearchResult,
    parentWhatId: string,
  ) => void;
  isReviewing: boolean;
  setIsReviewing: (isReviewing: boolean) => void;
  reset: () => void;
};

const useStore = create<Store>((set, get) => ({
  additionalFieldsType: null,
  setAdditionalFieldsType: (additionalFieldsType: 'email' | 'calendar') =>
    set({ additionalFieldsType }),
  searchQuery: '',
  setSearchQuery: (searchQuery: string) => {
    set({ searchQuery });
  },
  additionalFields: {},
  setAdditionalField: (
    key: string,
    value: string | number | boolean | null,
  ) => {
    const { additionalFields } = get();
    if (value === null) {
      delete additionalFields[key];
      set({ additionalFields });
    } else {
      set({
        additionalFields: {
          ...additionalFields,
          [key]: value,
        },
      });
    }
  },
  setAdditionalFields: (additionalFields: {
    [key: string]: string | number | boolean | null;
  }) => {
    set({
      additionalFields,
    });
  },

  selectedItems: {},
  setSelectedItems: (selectedItems: SelectedItems) => {
    set({ selectedItems });
  },
  objectFilter: 'All',
  setObjectFilter: (objectFilter: 'All' | string) => {
    set({ objectFilter });
  },
  isIdInSelectedItems: (id: string): boolean => !!get().selectedItems[id],
  removeTopLevelListItem: (id: string) => {
    const { selectedItems, setSelectedItems } = get();
    delete selectedItems[id];
    setSelectedItems({ ...selectedItems });
  },
  handleChangeTopLevelListItem: (
    displayableSearchResult: DisplayableSearchResult,
  ): void => {
    const { selectedItems, setSelectedItems } = get();
    if (!displayableSearchResult.id) {
      return;
    }

    // check to see if item is already a selected subitem
    let existingSubItem = false;
    Object.values(selectedItems).forEach(item => {
      if (item.subItems) {
        Object.keys(item.subItems).every(subItemId => {
          if (subItemId === displayableSearchResult.id) {
            delete selectedItems[item.id].subItems?.[subItemId];
            setSelectedItems({ ...selectedItems });
            existingSubItem = true;
            return false; // short circuits the every loop
          }
          return true;
        });
      }
    });

    if (existingSubItem) {
      return;
    }

    const existingSelectedItem = selectedItems[displayableSearchResult.id];
    if (existingSelectedItem) {
      delete selectedItems[displayableSearchResult.id];
    } else if (displayableSearchResult.id && displayableSearchResult.type) {
      const item: SelectedItem = {
        id: displayableSearchResult.id,
        name: displayableSearchResult.text,
        type: displayableSearchResult.type,
        text: displayableSearchResult.text,
        secondaryText: displayableSearchResult.secondaryText,
        secondaryTextLabel: displayableSearchResult.secondaryTextLabel,
        tertiaryText: displayableSearchResult.tertiaryText,
        tertiaryTextLabel: displayableSearchResult.tertiaryTextLabel,
        quarteraryText: displayableSearchResult.quarteraryText,
        quarteraryTextLabel: displayableSearchResult.quarteraryTextLabel,
        quinaryText: displayableSearchResult.quinaryText,
        quinaryTextLabel: displayableSearchResult.quinaryTextLabel,
      };
      selectedItems[displayableSearchResult.id] = item;
    }
    setSelectedItems({ ...selectedItems });
  },

  handleChangeWhatListItem: (
    what: DisplayableSearchResult,
    parentWhoId: string,
  ): void => {
    const { selectedItems, setSelectedItems } = get();
    const existingSelectedItem = selectedItems[parentWhoId];
    existingSelectedItem.subItems = {};
    if (what.id && what.type) {
      existingSelectedItem.subItems[what.id] = {
        id: what.id,
        name: what.text,
        type: what.type,
      };
      setSelectedItems({ ...selectedItems });
    }
  },

  handleChangeWhoListItem: (
    who: DisplayableSearchResult,
    parentWhatId: string,
  ): void => {
    const { selectedItems, setSelectedItems } = get();
    const existingSelectedItem = selectedItems[parentWhatId];
    existingSelectedItem.subItems ||= {};
    if (who.id && who.type) {
      if (existingSelectedItem.subItems[who.id]) {
        delete existingSelectedItem.subItems[who.id];
      } else {
        existingSelectedItem.subItems[who.id] = {
          id: who.id,
          name: who.text,
          type: who.type,
        };
      }
      setSelectedItems({ ...selectedItems });
    }
  },
  isReviewing: false,
  setIsReviewing: (isReviewing: boolean) => {
    set({ isReviewing });
  },
  reset: () => {
    set({
      additionalFieldsType: null,
      searchQuery: '',
      additionalFields: {},
      selectedItems: {},
      objectFilter: 'All',
    });
  },
}));

export default useStore;
