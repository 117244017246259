import { createActions } from '@groove-labs/action-utils';
import { FSA } from 'flux-standard-action';

const types = {
  SHARED: {
    APP: {
      SET_CONTENT_LOADING: 'SET_CONTENT_LOADING',
      PUSH_SNACKBAR_MESSAGE: 'PUSH_SNACKBAR_MESSAGE',
      UPDATE_SNACKBAR_MESSAGE: 'UPDATE_SNACKBAR_MESSAGE',
      SHIFT_SNACKBAR_MESSAGE: 'SHIFT_SNACKBAR_MESSAGE',
      SET_SNACKBAR_OPEN: 'SET_SNACKBAR_OPEN',
      SET_SNACKBAR_CLOSED: 'SET_SNACKBAR_CLOSED',
      REQUEST_SNACKBAR_CLOSE: 'REQUEST_SNACKBAR_CLOSE',
      CANCEL_ALL_SAGAS: 'CANCEL_ALL_SAGAS',
      LOGOUT: 'LOGOUT',
    },
  },
};

export const actionTypes = createActions<typeof types>(types).SHARED.APP;

export function setContentLoading(value: boolean): FSA<string, boolean> {
  return {
    type: actionTypes.SET_CONTENT_LOADING,
    payload: value,
  };
}

type PushSnackbarMessagePayload = {
  message: string;
  duration?: number;
  link?: string;
  linkText?: string;
};

export function pushSnackbarMessage({
  message,
  duration = 3000,
  link = null,
  linkText = null,
}: PushSnackbarMessagePayload): FSA<string, PushSnackbarMessagePayload> {
  return {
    type: actionTypes.PUSH_SNACKBAR_MESSAGE,
    payload: {
      message,
      duration,
      link,
      linkText,
    },
  };
}

// TODO not actually shared, move to App/actions
export function shiftSnackbarMessage(): FSA {
  return {
    type: actionTypes.SHIFT_SNACKBAR_MESSAGE,
  };
}

// TODO not actually shared, move to App/actions
export function setSnackbarOpen(): FSA {
  return {
    type: actionTypes.SET_SNACKBAR_OPEN,
  };
}

// TODO not actually shared, move to App/actions
export function setSnackbarClosed(): FSA {
  return {
    type: actionTypes.SET_SNACKBAR_CLOSED,
  };
}

// TODO not actually shared, move to App/actions
export function requestSnackbarClose(): FSA {
  return {
    type: actionTypes.REQUEST_SNACKBAR_CLOSE,
  };
}

export function logout(): FSA {
  return {
    type: actionTypes.LOGOUT,
  };
}
