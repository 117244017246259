import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {
  sharedLabelStyle,
  SMART_PRIORITY_LABELS,
} from 'Modules/ActionsRewrite/components/customCells/SmartPriority/constants';

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette['error/900'],
    backgroundColor: theme.palette['error/50'],
    ...sharedLabelStyle,
  },
}));

const HighChip = () => {
  const styles = useStyles();

  return (
    <Typography className={styles.label}>
      {SMART_PRIORITY_LABELS.HIGH}
    </Typography>
  );
};

export default HighChip;
