import { Call, Device } from '@twilio/voice-sdk';
import { GetState, SetState } from 'zustand';
import * as SentryReact from '@sentry/react';
import produce, { Draft } from 'immer';
import { MessageBarType } from '@fluentui/react';

import {
  CallEventsType,
  ConstantBridgeEventPayload,
  TransferCallEventsType,
} from '../../components/homeTypes';
import {
  START_IDLE_COUNTDOWN_DURATION,
  TWILIO_CONNECTION_NETWORK_WARNINGS,
  VALID_CALL_WAITING_REASONS,
  CACHED_CONTEXT_KEY,
} from '../../constants';
import { formatPhoneNumber } from '../../utils/phoneNumberUtils';
import {
  addPersonToConference,
  endConference,
  makeConstantBridgeCall,
  removePersonFromConference,
} from '../../api/dialer/constant_bridge/calls';
import {
  makeBridgeCall,
  destroyBridgeCall,
} from '../../api/dialer/bridge/calls';
import { What, Who } from '../../api/graphql/types';
import updatePhoneNumber from '../../api/graphql/mutations/updatePhoneNumber';
import { Store } from '../useStore';

import { Context } from './Context';

type MakeCallOptions = {
  from: string;
  to: string;
  who: Who | null;
  what: What | null;
  subject?: string;
  notes?: string;
  errorCallback?: (message: string) => void;
  // This is intentional, `isOmnidialer` is expected to be a string automatically defaults to `true`
  isOmnidialer?: 'false';
};

type ConstantBridgeSessionStatusType = 'active' | 'inactive' | 'idle';

const endCallStatuses = ['completed', 'no-answer', 'failed', 'busy'];

export type CallStore = {
  callState: 'connecting' | 'connected' | 'not-connected';

  currentContext: Context | null;
  setCurrentContext: (
    setter: (draft: Draft<Context>) => Context | void,
  ) => void;

  experiencingNetworkIssues: boolean;

  device: Device | null;
  setDevice: (device: Device) => void;
  previousConnection: Call | null;

  incomingConnection: Call | null;
  setIncomingConnection: (incomingConnection: Call | null) => void;
  acceptIncomingConnection: (who: Who | null, what: What | null) => void;
  rejectIncomingConnection: () => void;
  activeConnection: Call | null;
  getActiveConnection: () => Call | null;
  getActiveConnectionSid: () => string | null;

  setActiveConnectionCallbacks: () => void;

  makeCall: (options: MakeCallOptions) => void;
  endCall: (allowCallFeedback: boolean) => void;
  endConstantBridgeConference: () => void;

  instantDialEligible: boolean;
  setInstantDialEligibleFlag: (allowInstantDial: boolean) => void;

  conferenceFriendlyName: string;
  conferenceNameForFeedback?: string;
  bridgeCallStatus: CallEventsType | 'inactive';
  activeBridgeCallSid: string;
  transferCallSid: string;
  transferCallStatus: TransferCallEventsType;
  setBridgeCallStatus: (
    bridgeCallStatus: CallEventsType,
    conferenceFriendlyName: string,
    isGrooveUserEvent: boolean,
  ) => void;
  conferenceSid: string;
  constantBridgeSessionStatus: ConstantBridgeSessionStatusType;
  setConstantBridgeSessionStatus: (
    sessionStatus: ConstantBridgeSessionStatusType,
  ) => void;
  constantBridgeCallStatus: CallEventsType;
  setConstantBridgeStatus: (statusEvent: ConstantBridgeEventPayload) => void;
  idleConstantBridgeTimeout?: number;
  setTransferCallStatus: (
    transferCallStatus: TransferCallEventsType,
    callSid: string,
  ) => void;

  phoneInlineEditedType: string;
  setPhoneInlineEditedType: (phoneInlineEditedType: string) => void;
};

export const DEFAULT_CURRENT_CONTEXT: Context = {
  actionId: null,
  personStepId: null,
  sfdcId: null,
  sfdcAccountId: null,
  sfdcWhatId: null,
  phoneNumber: null,
  step: null,
  source: null,
  subject: null,
  notes: null,
};

export const callStore = (
  set: SetState<Store>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  get: GetState<Store>,
): CallStore => ({
  callState: 'not-connected',

  currentContext: DEFAULT_CURRENT_CONTEXT,
  setCurrentContext: setter => {
    const { setCurrentTab, currentContext } = get();
    set({
      currentContext: produce(currentContext || ({} as Context), setter),
    });
    setCurrentTab('Home');
  },

  experiencingNetworkIssues: false,

  device: null,
  setDevice: device => set({ device }),

  activeConnection: null,
  getActiveConnection: () => {
    return get().activeConnection;
  },

  getActiveConnectionSid: () => {
    const { activeBridgeCallSid, activeConnection } = get();

    return activeBridgeCallSid || activeConnection?.parameters.CallSid || null;
  },

  previousConnection: null,

  incomingConnection: null,
  setIncomingConnection: incomingConnection => set({ incomingConnection }),
  acceptIncomingConnection: (who: Who | null, what: What | null) => {
    get().ipcRenderer?.send('asynchronous-message', 'resize');
    const {
      incomingConnection,
      setCurrentContext,
      setCurrentCallActivity,
      setActiveConnectionCallbacks,
    } = get();
    if (incomingConnection) {
      incomingConnection.accept();
      set({
        callState: 'connected',
        incomingConnection: null,
        logState: 'active',
        // move incoming connection to active connection slot
        activeConnection: incomingConnection,
      });
      setCurrentContext(context => {
        context.personStepId = null;
        context.sfdcId = null;
        context.actionId = null;
        context.sfdcAccountId = null;
        context.sfdcWhatId = null;
        context.step = null;
        context.source = null;
        context.phoneNumber = incomingConnection.parameters.From;
      });
      setCurrentCallActivity(activity => {
        activity.who = who;
        activity.what = what;
      });
      setActiveConnectionCallbacks();
    }
  },
  rejectIncomingConnection: () => {
    const { incomingConnection } = get();
    if (incomingConnection) {
      incomingConnection.reject();
      set({ incomingConnection: null });
    }
  },

  makeCall: async ({
    to,
    from,
    who,
    what,
    errorCallback,
    isOmnidialer = 'true',
  }) => {
    const {
      device,
      setCurrentCallActivity,
      setCurrentContext,
      recordFromStart,
      isBridgeCallingEnabled,
      isConstantBridgeEnabled,
      constantBridgeSessionStatus,
      conferenceFriendlyName,
      setActiveConnectionCallbacks,
      setMessageBar,
      idleConstantBridgeTimeout,
      phoneInlineEditedType,
    } = get();
    if (!to) {
      return;
    }

    setCurrentContext(context => {
      context.phoneNumber = to;
    });

    const formattedTo = formatPhoneNumber(to);

    if (!formattedTo) {
      return;
    }

    if (phoneInlineEditedType && who?.grooveId) {
      updatePhoneNumber(who.grooveId, phoneInlineEditedType, formattedTo);
      set({ phoneInlineEditedType: '' });
    }

    // Clear idle constant bridge countdown when a new call is made
    clearTimeout(idleConstantBridgeTimeout);
    set({ idleConstantBridgeTimeout: undefined });
    if (constantBridgeSessionStatus === 'idle') {
      set({ constantBridgeSessionStatus: 'active' });
    }

    if (isBridgeCallingEnabled || isConstantBridgeEnabled) {
      set({ callState: 'connecting' });
      setCurrentCallActivity(activity => {
        activity.who = who;
        activity.what = what;
      });
    }

    try {
      if (isConstantBridgeEnabled) {
        if (['active', 'idle'].includes(constantBridgeSessionStatus)) {
          addPersonToConference(
            from,
            formattedTo,
            conferenceFriendlyName,
            recordFromStart,
          );
        } else {
          const response = await makeConstantBridgeCall(
            from,
            formattedTo,
            recordFromStart,
          );
          set({
            conferenceFriendlyName: response.conference_friendly_name,
            conferenceNameForFeedback: response.conference_friendly_name,
            conferenceSid: response.conference_sid,
          });
        }
      } else if (isBridgeCallingEnabled) {
        const response = await makeBridgeCall(
          from,
          formattedTo,
          recordFromStart,
        );
        set({
          conferenceFriendlyName: response.conference_friendly_name,
          conferenceNameForFeedback: response.conference_friendly_name,
        });
      } else if (!isBridgeCallingEnabled && device) {
        const connection = await device.connect({
          params: {
            To: formattedTo,
            From: from,
            // Pass this property to the backend where it will be handled via twilio webhook hence why it has to be a a string
            // this .connect passes the parameters to our backend endpoint responding to the webhook
            CallRecordingEnabled: recordFromStart.toString(),
            IsOmnidialer: isOmnidialer,
          },
        });
        set({
          callState: 'connecting',
          logState: 'active',
          activeConnection: connection,
        });
        setCurrentCallActivity(activity => {
          activity.who = who;
          activity.what = what;
        });
        setActiveConnectionCallbacks();
      }
    } catch (e) {
      const message = 'Unknown error. Please try again';
      SentryReact.captureException(e);
      setMessageBar(
        {
          type: MessageBarType.error,
          message,
        },
        1000,
      );
      errorCallback?.(message);
      setCurrentContext(context => {
        context.personStepId = null;
        context.sfdcId = null;
        context.sfdcAccountId = null;
        context.sfdcWhatId = null;
        context.actionId = null;
        context.step = null;
        context.phoneNumber = null;
        context.source = null;
        context.subject = null;
        context.notes = null;
      });
      set({ callState: 'not-connected' });
    }
  },
  setActiveConnectionCallbacks: () => {
    const { device, activeConnection, currentContext } = get();
    if (!device) {
      return;
    }

    const connection = activeConnection;
    if (!connection) {
      return;
    }
    // this is referenced when providing feedback after call has ended
    set({ previousConnection: activeConnection });

    let experiencingNetworkIssuesTimeout: number;
    const waitingToConnectTimeout: number = window.setTimeout(() => {
      const connectionStatus = connection.status(); // get the current connection status

      if (!VALID_CALL_WAITING_REASONS.includes(connectionStatus)) {
        // cache the values of current context in localStorage, will be read back when dialer reloads
        localStorage.setItem(
          CACHED_CONTEXT_KEY,
          JSON.stringify(currentContext),
        );
        set({
          callState: 'not-connected',
          experiencingNetworkIssues: false,
          isDialPadOpen: false,
          activeConnection: null,
        });
        get().ipcRenderer?.send('asynchronous-message', 'reload');
      }
    }, 10000);

    const connectionInterval: number = window.setInterval(() => {
      const status = connection.status();
      if (status === 'open') {
        get().ipcRenderer?.send('asynchronous-message', 'resize');
        window.clearTimeout(waitingToConnectTimeout);
        localStorage.removeItem(CACHED_CONTEXT_KEY);
        set({ callState: 'connected' });
      }
    }, 500);
    connection.on('warning', warning => {
      if (TWILIO_CONNECTION_NETWORK_WARNINGS.includes(warning)) {
        experiencingNetworkIssuesTimeout = window.setTimeout(
          () => set({ experiencingNetworkIssues: true }),
          20000,
        );
      }
    });
    connection.on('error', error => {
      SentryReact.captureException(error.message, { extra: error.code });
      window.clearTimeout(experiencingNetworkIssuesTimeout);
      window.clearTimeout(waitingToConnectTimeout);
      clearInterval(connectionInterval);
    });
    connection.on('warning-cleared', warning => {
      if (TWILIO_CONNECTION_NETWORK_WARNINGS.includes(warning)) {
        window.clearTimeout(experiencingNetworkIssuesTimeout);
        set({ experiencingNetworkIssues: false });
      }
    });

    // emitted when a call successfully reconnects after losing connection
    connection.on('reconnected', () => {
      window.clearTimeout(waitingToConnectTimeout);
    });
    connection.on('disconnect', () => {
      window.clearTimeout(experiencingNetworkIssuesTimeout);
      window.clearTimeout(waitingToConnectTimeout);
      clearInterval(connectionInterval);
      localStorage.removeItem(CACHED_CONTEXT_KEY);
      set({
        callState: 'not-connected',
        experiencingNetworkIssues: false,
        isDialPadOpen: false,
        activeConnection: null,
      });
    });
  },
  endCall: async allowCallFeedback => {
    const {
      device,
      isBridgeCallingEnabled,
      isConstantBridgeEnabled,
      conferenceFriendlyName,
      conferenceSid,
      activeBridgeCallSid,
      endConstantBridgeConference,
    } = get();
    if (isConstantBridgeEnabled) {
      if (!conferenceSid) return;

      if (!activeBridgeCallSid) {
        endConstantBridgeConference();
      } else {
        try {
          await removePersonFromConference(conferenceSid, activeBridgeCallSid);
        } catch (e) {
          SentryReact.captureException(e);
        }
        set({
          callState: 'not-connected',
          conferenceNameForFeedback: conferenceSid,
        });
      }
    } else if (isBridgeCallingEnabled && conferenceFriendlyName) {
      destroyBridgeCall(conferenceFriendlyName);
      if (!activeBridgeCallSid) {
        set({
          logState: 'not-logging',
          conferenceFriendlyName: '',
        });
      }
      set({
        callState: 'not-connected',
        bridgeCallStatus: 'inactive',
        conferenceNameForFeedback: conferenceFriendlyName,
        conferenceFriendlyName: '',
        transferCallStatus: 'inactive',
        transferCallSid: '',
      });
    } else if (device) {
      set({
        callState: 'not-connected',
        experiencingNetworkIssues: false,
        isDialPadOpen: false,
      });
      if (allowCallFeedback) {
        set({
          isCallFeedbackDialogOpen: true,
        });
      }
      device.disconnectAll();
    }

    if (device?.state === 'unregistered') {
      // device must be registered to receive calls with Twilio. Each time call ends, check this connection
      device?.register();
    }
  },
  endConstantBridgeConference: async () => {
    const { conferenceSid } = get();
    await endConference(conferenceSid);
    set({
      logState: 'not-logging',
      conferenceFriendlyName: '',
      conferenceSid: '',
      callState: 'not-connected',
      constantBridgeSessionStatus: 'inactive',
    });
  },
  conferenceFriendlyName: '',
  activeBridgeCallSid: '',
  transferCallSid: '',
  bridgeCallStatus: 'inactive',
  transferCallStatus: 'inactive',
  setTransferCallStatus: (
    transferCallStatus: TransferCallEventsType,
    transferCallSid: string,
  ) => {
    set({ transferCallStatus, transferCallSid });
  },
  setBridgeCallStatus: (
    bridgeCallStatus: CallEventsType,
    passedConferenceName: string,
    isGrooveUserEvent: boolean,
  ) => {
    const { conferenceFriendlyName, callState, isConstantBridgeEnabled } =
      get();

    if (
      conferenceFriendlyName !== passedConferenceName ||
      isConstantBridgeEnabled
    ) {
      return;
    }

    if (
      ['ringing', 'in-progress'].includes(bridgeCallStatus) &&
      callState !== 'connected' &&
      !isGrooveUserEvent
    ) {
      set({
        callState: 'connected',
        activeBridgeCallSid: conferenceFriendlyName,
        logState: 'active',
      });
    } else if (endCallStatuses.includes(bridgeCallStatus)) {
      set({ callState: 'not-connected', conferenceFriendlyName: '' });
    }
    set({ bridgeCallStatus });
  },
  conferenceSid: '',
  constantBridgeSessionStatus: 'inactive',
  constantBridgeCallStatus: 'completed',
  setConstantBridgeStatus: (statusEventPayload: ConstantBridgeEventPayload) => {
    const {
      status_event,
      is_target_person_event,
      conference_friendly_name: passedConferenceName,
      call_sid,
    } = statusEventPayload;
    const {
      conferenceFriendlyName,
      callState,
      constantBridgeSessionStatus,
      activeBridgeCallSid,
      idleConstantBridgeTimeout,
      setConstantBridgeSessionStatus,
      isConstantBridgeEnabled,
    } = get();
    if (
      conferenceFriendlyName !== passedConferenceName ||
      !isConstantBridgeEnabled
    ) {
      return;
    }

    if (is_target_person_event) {
      if (
        ['ringing', 'in-progress'].includes(status_event) &&
        callState !== 'connected'
      ) {
        set({
          callState: 'connected',
          activeBridgeCallSid: call_sid!,
          logState: 'active',
        });
      } else if (
        (['completed', 'no-answer', 'busy'].includes(status_event) &&
          call_sid === activeBridgeCallSid) ||
        status_event === 'failed'
      ) {
        set({ callState: 'not-connected' });
        clearTimeout(idleConstantBridgeTimeout);
        const timeoutId = window.setTimeout(() => {
          setConstantBridgeSessionStatus('idle');
        }, START_IDLE_COUNTDOWN_DURATION);
        set({ idleConstantBridgeTimeout: timeoutId });
      }
      set({ constantBridgeCallStatus: status_event });
      return;
    }

    if (
      status_event === 'in-progress' &&
      constantBridgeSessionStatus !== 'active'
    ) {
      set({ constantBridgeSessionStatus: 'active' });
    } else if (endCallStatuses.includes(status_event)) {
      clearTimeout(idleConstantBridgeTimeout);
      set({ idleConstantBridgeTimeout: undefined });
      set({
        constantBridgeSessionStatus: 'inactive',
        callState: 'not-connected',
        conferenceFriendlyName: '',
        conferenceSid: '',
      });
    }
  },
  setConstantBridgeSessionStatus: (
    sessionStatus: ConstantBridgeSessionStatusType,
  ) => {
    const { callState } = get();

    if (
      sessionStatus === 'idle' &&
      ['connecting', 'connected'].includes(callState)
    ) {
      // ignore timeout since a countdown ended but there's an active call
      set({ idleConstantBridgeTimeout: undefined });
      return;
    }

    set({ constantBridgeSessionStatus: sessionStatus });
  },
  instantDialEligible: false,
  setInstantDialEligibleFlag: allowInstantDial => {
    set({ instantDialEligible: allowInstantDial });
  },

  phoneInlineEditedType: '',
  setPhoneInlineEditedType: type => set({ phoneInlineEditedType: type }),
});
