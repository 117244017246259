import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const CheckmarkIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <path
      d="M13.864 3.656a.5.5 0 0 1-.021.707l-7.93 7.474a.6.6 0 0 1-.839-.016l-2.68-2.72a.5.5 0 0 1 .712-.702l2.406 2.442 7.645-7.206a.5.5 0 0 1 .707.021z"
      fill="#6B6B6B"
    />
  </svg>
);
