import * as React from 'react';

import ErrorPage from 'Modules/Shared/components/ErrorStates/ErrorPage';
import Clock from 'Modules/Shared/components/Images/Clock';

const TimeoutState: React.FC<TimeoutStatePropTypes> = ({
  primaryText,
  secondaryText,
  showRecoveryButton,
  showSubmitFeedbackButton,
  recoveryButtonText,
  onRecovery,
  icon,
}) => {
  return (
    <ErrorPage
      icon={icon}
      primaryText={primaryText}
      secondaryText={secondaryText}
      showRecoveryButton={showRecoveryButton}
      showSubmitFeedbackButton={showSubmitFeedbackButton}
      recoveryButtonText={recoveryButtonText}
      onRecovery={onRecovery}
    />
  );
};

type TimeoutStatePropTypes = {
  showRecoveryButton: boolean;
  showSubmitFeedbackButton: boolean;
  primaryText?: string;
  secondaryText?: string;
  recoveryButtonText: string;
  onRecovery?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
};

TimeoutState.defaultProps = {
  showRecoveryButton: true,
  showSubmitFeedbackButton: false,
  primaryText: 'Network Timeout',
  secondaryText: '',
  onRecovery: null,
  icon: <Clock />,
  recoveryButtonText: 'Reload Page',
};

export default TimeoutState;
