export default function removeAllNonStandardFonts(
  nonStandardFonts: Set<string>,
  html: HTMLElement,
): HTMLElement {
  html.querySelectorAll<HTMLElement>('[style*="font-family"]').forEach(el => {
    if (nonStandardFonts.has(el.style.fontFamily)) {
      el.style.fontFamily = '';
    }
  });
  return html;
}
