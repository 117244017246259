import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';
import { CUSTOM_SFDC_FIELD_TYPES } from 'Modules/Shared/constants/index';

export const actionTypes = createActions({
  ACCOUNT_PREVIEW: {
    ADD_PEOPLE_TO_FLOW: standardActions(),
    FETCH_PREVIEW: standardActions(),
    REMOVE_PEOPLE_FROM_ALL_FLOWS: standardActions(),
    FETCH_UNASSOCIATED_CONTACTS: standardActions(),
    LOAD_ACCOUNT: standardActions(),
    FETCH_ONE_FLOW_RESTRICTION: standardActions(),
    ...keyMirror([
      'CLOSE',
      'MOVE_TO_PREVIEW_PAGE',
      'OPEN',
      'SELECT_UNASSOCIATED_CONTACTS',
      'SELECT_ACTIVE_FLOW_PEOPLE',
      'SET_ACTIVE_PERSON_ID',
      'SET_VISIBILITY_STATE',
      'UNSET_ACTIVE_PERSON_ID',
      'UNSELECT_UNASSOCIATED_CONTACTS',
      'UNSELECT_ACTIVE_FLOW_PEOPLE',
      'UPDATE_PREVIEW_QUERY_AND_REFETCH',
      'UPDATE_PREVIEW_QUERY',
      'SET_ACTIVE_ACCOUNT_ID',
      'CLEAR_SELECTED_PEOPLE',
      'CLEAR_CONTACTS_FLOWS',
    ]),
  },
}).ACCOUNT_PREVIEW;

export function updatePreviewQuery(payload) {
  return {
    type: actionTypes.UPDATE_PREVIEW_QUERY,
    payload,
  };
}

export function updatePreviewQueryAndRefetch(payload) {
  return {
    type: actionTypes.UPDATE_PREVIEW_QUERY_AND_REFETCH,
    payload,
  };
}

export function open({
  id,
  name,
  objectType = CUSTOM_SFDC_FIELD_TYPES.ACCOUNT,
}) {
  return {
    type: actionTypes.OPEN,
    payload: { id, name, objectType },
  };
}

export function close() {
  return {
    type: actionTypes.CLOSE,
  };
}

export function beginAddingPeopleToFlows({ flowId, selectedIds }) {
  return {
    type: actionTypes.ADD_PEOPLE_TO_FLOW.BEGIN,
    payload: {
      flowId,
      selectedIds,
    },
  };
}

export function addingPeopleToFlowsIsInProgress() {
  return {
    type: actionTypes.ADD_PEOPLE_TO_FLOW.PROGRESS,
  };
}

export function addingPeopleToFlowsFailed(error) {
  return {
    type: actionTypes.ADD_PEOPLE_TO_FLOW.FAILURE,
    error,
  };
}

export function addingPeopleToFlowsSucceeded() {
  return {
    type: actionTypes.ADD_PEOPLE_TO_FLOW.SUCCESS,
  };
}

export function beginRemovingPeopleFromAllFlows(payload) {
  return {
    type: actionTypes.REMOVE_PEOPLE_FROM_ALL_FLOWS.BEGIN,
    payload,
  };
}

export function removingPeopleFromAllFlowsIsInProgress(error) {
  return {
    type: actionTypes.REMOVE_PEOPLE_FROM_ALL_FLOWS.PROGRESS,
    error,
  };
}

export function removingPeopleFromAllFlowsFailed(error) {
  return {
    type: actionTypes.REMOVE_PEOPLE_FROM_ALL_FLOWS.FAILURE,
    error,
  };
}

export function removingPeopleFromAllFlowsSucceeded(payload) {
  return {
    type: actionTypes.REMOVE_PEOPLE_FROM_ALL_FLOWS.SUCCESS,
    payload,
  };
}

export function moveToPreviewPage(payload) {
  return {
    type: actionTypes.MOVE_TO_PREVIEW_PAGE,
    payload,
  };
}

export function fetchingInProgress() {
  return {
    type: actionTypes.FETCH_PREVIEW.PROGRESS,
  };
}

export function fetchingFailed() {
  return {
    type: actionTypes.FETCH_PREVIEW.FAILURE,
  };
}

export function fetchingSucceeded({ people, total }) {
  return {
    type: actionTypes.FETCH_PREVIEW.SUCCESS,
    payload: {
      people,
      total,
    },
  };
}

export function setActivePersonId(personId) {
  return {
    type: actionTypes.SET_ACTIVE_PERSON_ID,
    payload: personId,
  };
}

export function unsetActivePersonId() {
  return {
    type: actionTypes.UNSET_ACTIVE_PERSON_ID,
  };
}

export function beginFetchingUnassociatedContacts() {
  return {
    type: actionTypes.FETCH_UNASSOCIATED_CONTACTS.BEGIN,
  };
}

export function fetchingUnassociatedContactsProgress() {
  return {
    type: actionTypes.FETCH_UNASSOCIATED_CONTACTS.PROGRESS,
  };
}

export function successfullyFetchedUnassociatedContacts({ contacts }) {
  return {
    type: actionTypes.FETCH_UNASSOCIATED_CONTACTS.SUCCESS,
    payload: { contacts },
  };
}

export function fetchingUnassociatedContactsFailed() {
  return {
    type: actionTypes.FETCH_UNASSOCIATED_CONTACTS.FAILURE,
  };
}

export function selectActiveFlowPeople(people) {
  return {
    type: actionTypes.SELECT_ACTIVE_FLOW_PEOPLE,
    payload: { people },
  };
}

export function unselectActiveFlowPeople(people) {
  return {
    type: actionTypes.UNSELECT_ACTIVE_FLOW_PEOPLE,
    payload: { people },
  };
}

export function selectUnassociatedContacts(people) {
  return {
    type: actionTypes.SELECT_UNASSOCIATED_CONTACTS,
    payload: { people },
  };
}

export function unselectUnassociatedContacts(people) {
  return {
    type: actionTypes.UNSELECT_UNASSOCIATED_CONTACTS,
    payload: { people },
  };
}

export function setActiveAccountId({ id }) {
  return {
    type: actionTypes.SET_ACTIVE_ACCOUNT_ID,
    payload: { id },
  };
}

export function clearSelectedPeople({
  clearActiveFlowPeople = true,
  clearUnassociatedContacts = true,
}) {
  return {
    type: actionTypes.CLEAR_SELECTED_PEOPLE,
    payload: { clearActiveFlowPeople, clearUnassociatedContacts },
  };
}

// this clears the flows for contacts after they have been removed from all flows
export function clearContactsFlows({ ids }) {
  return {
    type: actionTypes.CLEAR_CONTACTS_FLOWS,
    payload: { ids },
  };
}

export function setVisibilityState({ visibilityState }) {
  return {
    type: actionTypes.SET_VISIBILITY_STATE,
    payload: { visibilityState },
  };
}

export function isLoadingAccount() {
  return {
    type: actionTypes.LOAD_ACCOUNT.BEGIN,
  };
}

export function successfullyLoadedAccount() {
  return {
    type: actionTypes.LOAD_ACCOUNT.SUCCESS,
  };
}

export function loadAccountFailed() {
  return {
    type: actionTypes.LOAD_ACCOUNT.FAILURE,
  };
}

export function fetchOneFlowRestrictionBegin() {
  return {
    type: actionTypes.FETCH_ONE_FLOW_RESTRICTION.BEGIN,
  };
}

export function fetchOneFlowRestrictionSuccess(payload) {
  return {
    type: actionTypes.FETCH_ONE_FLOW_RESTRICTION.SUCCESS,
    payload,
  };
}

export function fetchOneFlowRestrictionFailure() {
  return {
    type: actionTypes.FETCH_ONE_FLOW_RESTRICTION.FAILURE,
  };
}
