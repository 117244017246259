import getClient from './getClient';
import {
  GrooveFetchRecord,
  GrooveFetchRecordParam,
} from './types/GrooveFetchRecords';

/**
 * Make an Apex Request to fetch records
 *
 * @typeParam T - the additional attributes that should be included in each
 *   record
 * @param params - groupings of ids and attributes to fetch
 * @return - an array of records
 *
 * @remarks
 * The query string has a very distinct syntax. The purpose of this function is
 * to worry about the building this complex string! Here are the details:
 *
 * - All groupings are separated by a semicolon (;)
 * - All groupings must include ids of the exact same type
 * - All ids for a group must by surrounded by an array
 *   - Each id must be surrounded in quotes
 *   - Each id must be separated by a comma
 * - The array is separated by a colon from the attributes
 * - Each attribute is separated by a comma
 *
 * @example
 * '["0034W00002B8JRiQAN","0034W00002BgiFMQAZ"]:name;["00Q0L00001M6DijUAF"]:id,name'
 */
export default async function grooveFetchRecords<T>(
  params: GrooveFetchRecordParam[],
): Promise<GrooveFetchRecord<T>[]> {
  const queryString = params
    .map(param => {
      const quotedIds = param.ids.map(id => `"${id}"`).join(',');
      const attributes = param.attributes.join(',');
      return `[${quotedIds}]:${attributes}`;
    })
    .join(';');

  return grooveFetchRecordsRequest(queryString);
}

export async function grooveFetchRecordsRequest(
  queryString: string,
): Promise<GrooveFetchRecord<T>[]> {
  return getClient()
    .get('api/v2/visualforce/tunnel', {
      searchParams: {
        page: 'GrooveFetchRecords',
        query: queryString,
      },
    })
    .json();
}
