import React, { useEffect } from 'react';
import Input from '@groove/ui/Components/Input';
import Text from '@groove/ui/Components/Text';
import { CONTACT_PREFIX, LEAD_PREFIX } from '@groove/api/gateway/v1/salesforce';
import { StandardDropDownItem } from '@groove/ui/Components/DropDownList';
import MultiSelect from '@groove/ui/Components/MultiSelect';
import { CheckMarkIcon } from '@fluentui/react-icons-mdl2';

import useGrooveMeta from '../hooks/useGrooveMeta';
import useStore from '../store/useStore';

interface EditPhoneDialogProps {
  phoneItems: StandardDropDownItem[];
  selectedPhoneItem: StandardDropDownItem[];
}

export const PHONE_UPDATE_MESSAGE_ID = 'phoneUpdate';

const EditPhoneDialog: React.FC<EditPhoneDialogProps> = ({
  phoneItems,
  selectedPhoneItem,
}) => {
  const who = useStore(store => store.action.who);
  const { data: grooveMeta } = useGrooveMeta();

  const {
    currentPhoneItem: currentSelectionInDialog,
    updateCurrentPhoneItem,
    updatePhoneItem,
    initialize: initializePhoneStore,
    setPhoneTypeItems,
    phoneTypeItems,
  } = useStore(state => ({
    currentPhoneItem: state.currentPhoneItem,
    updateCurrentPhoneItem: state.updateCurrentPhoneItem,
    updatePhoneItem: state.updatePhoneItem,
    initialize: state.initialize,
    setPhoneTypeItems: state.setPhoneTypeItems,
    phoneTypeItems: state.phoneTypeItems,
  }));

  const selectedPhoneValue = currentSelectionInDialog?.value;
  const selectedPhoneType =
    currentSelectionInDialog?.type ||
    (phoneTypeItems.length > 0 ? phoneTypeItems[0].key : '');

  let objectType = '';
  if (who?.sfdcId?.startsWith(CONTACT_PREFIX)) objectType = 'contact';
  if (who?.sfdcId?.startsWith(LEAD_PREFIX)) objectType = 'lead';

  useEffect(() => {
    initializePhoneStore(selectedPhoneItem?.[0], phoneItems);
    setPhoneTypeItems(grooveMeta?.[objectType]?.fields || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(grooveMeta), JSON.stringify(phoneItems)]);

  useEffect(() => {
    const firstPhoneItem =
      phoneItems.length > 0 ? phoneItems[0] : { key: '', value: '' };
    initializePhoneStore(selectedPhoneItem?.[0] || firstPhoneItem, phoneItems);
    setPhoneTypeItems(grooveMeta?.[objectType]?.fields || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(grooveMeta), JSON.stringify(phoneItems)]);

  const onPhoneValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    updatePhoneItem(selectedPhoneType?.toString() || '', event.target.value);
  };

  const onPhoneTypeChange = (phoneType: StandardDropDownItem | null): void => {
    const newPhoneTypeItem = phoneTypeItems.find(
      item => item.value === phoneType?.key,
    );
    updateCurrentPhoneItem(newPhoneTypeItem?.key?.toString() || '');
  };

  return (
    <div className="flex flex-col w-64">
      <Text className="text-body mt-6 text-black" weight="semibold">
        Type
      </Text>
      <MultiSelect
        className="flex-1 mt-[3px] min-w-0"
        items={phoneTypeItems}
        onSelect={onPhoneTypeChange}
        selectedValues={phoneTypeItems.filter(
          item => item.key === selectedPhoneType,
        )}
        placeholder={
          (phoneTypeItems.filter(item => item.key === selectedPhoneType)?.[0]
            ?.text as string) || 'Select a phone type'
        }
        showChevron
        closeAfterSelect
        showSelectedIcon
        selectedIcon={<CheckMarkIcon />}
        secondaryTextPosition="right"
        dropDownWidth="100%"
        wrapText
      />
      <Text className="text-body-sm mt-2" weight="semibold">
        Phone number
      </Text>
      <div className="mb-[15px]">
        <Input
          className="w-full mt-1 h-7"
          value={selectedPhoneValue}
          onChange={onPhoneValueChange}
        />
      </div>
    </div>
  );
};

export default EditPhoneDialog;
