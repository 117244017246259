import { FC } from 'react';
import { Checkbox as FluentCheckbox } from '@fluentui/react';

import { AdditionalFieldProps } from './types';

const Checkbox: FC<AdditionalFieldProps<boolean>> = ({
  additionalField,
  value,
  required,
  onChange,
  multiline,
}) => {
  return (
    <FluentCheckbox
      className="py-2"
      label={additionalField.label}
      ariaLabel={additionalField.label}
      checked={!!value}
      required={required}
      onChange={(_e, newValue) => onChange(!!newValue)}
    />
  );
};

export default Checkbox;
