import { call, delay, cancelled } from 'redux-saga/effects';
import { createFirebaseJwtToken } from 'GrooveHTTPClient/session';
import firebase from 'Utils/firebase';
import logout from 'ErrorHandling/logout';
import HTTPError from 'GrooveHTTPClient/HTTPError';

let firestore = null;
let initializingFirestore = false;

const MAX_RETRIES = 5;
const INITIAL_BACKOFF_DELAY = 1000; // Initial back-off delay in milliseconds

/*
 * Gets a Firestore instance
 * On the first invocation, will also auth the user to Firebase
 *
 * @return Firestore instance
 * */
export function* getFirestore() {
  try {
    return yield call(initializeFirestore);
  } finally {
    // this is important to ensure initializingFirestore is reset when the caller generator is cancelled
    // without this change the Promise returned from initializeFirestore will go into infinite loop when
    // multiple sagas calls initializeFirestore and the first one is canceled leading the next callers
    // to wait indefinitly, or any subsequent calls to getFirestore.
    if (yield cancelled()) {
      initializingFirestore = false;
    }
  }
}

function* initializeFirestore() {
  if (firestore) {
    return firestore;
  }

  if (initializingFirestore) {
    yield call(
      () =>
        new Promise(resolve => {
          const handler = () => {
            if (firestore) {
              resolve();
            } else {
              setTimeout(handler, 100);
            }
          };
          setTimeout(handler, 100);
        })
    );
    return firestore;
  }
  initializingFirestore = true;

  for (let retry = 0; retry < MAX_RETRIES; retry += 1) {
    try {
      // Fetches a custom JWT token encoded with our Firebase info from GrooveEngine
      const {
        data: { token },
      } = yield call(createFirebaseJwtToken);
      yield call(
        firebase.auth().signInWithCustomToken.bind(firebase.auth()),
        token
      );

      // Break the loop if successful
      break;
    } catch (e) {
      if (retry === MAX_RETRIES - 1) {
        // Temporary measure to prevent /error on app upstart
        if (e instanceof HTTPError && e.response.status === 422) {
          yield call(logout, e);
        } else {
          initializingFirestore = false;
          return null;
        }
      } else {
        yield delay(INITIAL_BACKOFF_DELAY * 2 ** retry);
      }
    }
  }

  firestore = firebase.firestore();

  // This setting is required to avoid an issue with a future
  // Firestore release deprecating JS dates.
  // See - https://github.com/firebase/firebase-js-sdk/issues/726
  const settings = { timestampsInSnapshots: true };

  firestore.settings(settings);
  return firestore;
}
