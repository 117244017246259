import { Record, Map } from 'immutable';

const defaults = {
  id: null,
  name: '',
  sfdcId: '',
  absFields: new Map(),
  engagementScore: null,
  effortAndEngagementRollup: [],
};

export default class Account extends Record(defaults) {
  static from(account) {
    return new Account({
      id: account.get('id'),
      name: account.get('name'),
      sfdcId: account.get('sfdcId'),
      absFields: account.get('absFields'),
      engagementScore: account.get('engagementScore'),
      ...(account.has('effortAndEngagementRollup') && {
        effortAndEngagementRollup: account.get('effortAndEngagementRollup'),
      }),
    });
  }
}
