import { getCurrentUser, getUserOrgSettings } from 'GrooveHTTPClient/users';
import * as GrooveLocalStorage from 'GrooveLocalStorage';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import {
  setCsrfToken,
  setIsLoggedIn,
  setCurrentUser,
} from 'Modules/Shared/actions/users';
import { location as locationSelector } from 'Modules/Shared/selectors/location';
import { all, call, put, select } from 'redux-saga/effects';
import { history } from 'Utils/history';
import { LOGIN_WINDOW_NAME } from 'Modules/ActionCompose/constants';
import assembleSetCurrentUserPayload from 'Modules/App/sagas/upstarts/helpers/assembleSetCurrentUserPayload';
import handleLoginFromComposePane from './handleLoginFromComposePane';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

export function* loginRedirect() {
  yield call(window.location.assign.bind(window.location), grooveEngineRootUrl);
}

export function* loginCallbackHandler() {
  const location = yield select(locationSelector);
  const searchParams = location.get('searchParams');

  const csrfToken = searchParams.get('csrf');
  yield put(setCsrfToken(csrfToken));
  yield call(GrooveLocalStorage.save, 'csrfToken', csrfToken);

  yield put(setIsLoggedIn(true));

  if (window.name === LOGIN_WINDOW_NAME) {
    yield call(handleLoginFromComposePane);
    return;
  }

  const [currentUserResponse, userOrgSettingsResponse] = yield all([
    call(getCurrentUser),
    call(getUserOrgSettings),
  ]);

  const currentUser = currentUserResponse.updateIn(
    ['message', 'data', 'orgSettings'],
    m => m.merge(userOrgSettingsResponse.data)
  );

  // TODO error handling

  yield put(
    setCurrentUser(
      assembleSetCurrentUserPayload({ currentUserResponse: currentUser })
    )
  );

  yield put(
    pushSnackbarMessage({
      message: 'You have been logged in',
    })
  );

  // Redirect to the home route unless a next URL was provided
  const nextUrl = searchParams.get('next');
  if (nextUrl) {
    yield call(history.push, nextUrl);
  } else {
    yield call(history.push, '/');
  }
}
