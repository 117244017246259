import { Separator } from '@fluentui/react';
import { FC } from 'react';

import { DisplayableSearchResult } from './transformSearchResult';
import useSearchResults from './useSearchResults';
import useStore from './useStore';
import ResultItem from './ResultItem';

type ResultsProps = {
  handleOmnibarContextChange?: (id: string) => void;
};

const Results: FC<ResultsProps> = ({ handleOmnibarContextChange }) => {
  const { data } = useSearchResults();
  const objectFilter = useStore(store => store.objectFilter);

  const transformedData = data?.filter(item => {
    if (objectFilter === 'All') {
      return true;
    }

    return item.type === objectFilter;
  });

  // group transformed data by itemType
  const groupedData = transformedData?.reduce((acc, item) => {
    const { type } = item;
    if (!type) {
      return acc;
    }
    const items = acc[type] || [];
    acc[type] = [...items, item];
    return acc;
  }, {} as { [key: string]: DisplayableSearchResult[] });

  return (
    <>
      {groupedData &&
        Object.entries(groupedData)?.map(([sectionName, section]) => {
          return (
            <div key={sectionName}>
              {sectionName && (
                <Separator
                  className="p-2 before:bg-gray1"
                  alignContent="start"
                  key={sectionName}
                >
                  {sectionName}
                </Separator>
              )}
              {section.map(item => {
                return (
                  <ResultItem
                    key={item.id}
                    item={item}
                    handleOmnibarContextChange={handleOmnibarContextChange}
                  />
                );
              })}
            </div>
          );
        })}
    </>
  );
};

export default Results;
