import SearchBar from 'Modules/Shared/components/SearchInput/searchBar';

import useActionFilterStore from '@groove/ui/Components/ActionFilter/store/useStore';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ActionSearchInput } from '@groove/api/services/v1/actions';

const ActionsSearchPlaceholder =
  'Search by flow name, person, title, or company...';

export default function Search() {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const [debouncedSearch] = useDebouncedCallback((value?: string) => {
    setSearchValue(value);
  }, 300);

  const selectedFilters = useActionFilterStore(store => store.selectedFilters);
  const setSelectedFilters = useActionFilterStore(
    store => store.setSelectedFilters
  );

  useEffect(() => {
    const searchFilter: ActionSearchInput[] = [
      {
        field: 'COMPANY',
        query: searchValue || '',
      },
      {
        field: 'WHO_NAME',
        query: searchValue || '',
      },
      {
        field: 'TITLE',
        query: searchValue || '',
      },
      {
        field: 'SUMMARY',
        query: searchValue || '',
      },
    ];

    setSelectedFilters({ ...selectedFilters, search: searchFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, setSelectedFilters]);

  return (
    <div
      // className="w-auto m-w-[30%]"
      className="min-w-[40%]"
    >
      <SearchBar
        placeholder={ActionsSearchPlaceholder}
        searchIsVisible
        onInputChange={(_, field) => {
          debouncedSearch(field.fieldValue);
        }}
      />
    </div>
  );
}
