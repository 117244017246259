import { call, put } from 'redux-saga/effects';
import refreshSfdcMeta from 'Modules/Troubleshooting/sagas/refreshSfdcMeta';
import {
  beginFixingHealthItem,
  failedFixingHealthItem,
  successfullyRanHealthFix,
} from 'Modules/Shared/actions/users/health';
import { sfdcMetaHealthCheck } from 'GrooveHTTPClient/troubleshooting';
import { SERVICE_CONNECTION_TYPES } from 'Modules/Troubleshooting/constants';

function* handleRefreshSfdcMeta() {
  try {
    yield put(
      beginFixingHealthItem({
        key: SERVICE_CONNECTION_TYPES.sfdcMeta,
      })
    );
    yield call(refreshSfdcMeta);
    const response = yield call(sfdcMetaHealthCheck, true);
    const isValid = response.getIn(['message', 'valid']);

    yield put(
      successfullyRanHealthFix({
        key: SERVICE_CONNECTION_TYPES.sfdcMeta,
        isFixed: isValid,
      })
    );
  } catch (e) {
    yield put(
      failedFixingHealthItem({
        key: SERVICE_CONNECTION_TYPES.sfdcMeta,
      })
    );
  }
}

export default handleRefreshSfdcMeta;
