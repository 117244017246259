import keyMirror from 'Utils/key-mirror';

export const SEARCH_FILTER_VALUES = {
  PEOPLE: 'people',
};

// Hardcode the default to people - This should be `SEARCH_FILTER_VALUES.FLOWS` once the other search integrations are done
export const DEFAULT_SEARCH_FILTER_OPTION = SEARCH_FILTER_VALUES.PEOPLE;

export const STEP_TYPES = {
  TEMPLATE: 'TEMPLATE',
  CALL: 'CALL',
  OTHER: 'OTHER',
  ALL: 'ALL',
  DIRECT_MAIL: 'direct_mail',
  IN_PERSON_VISIT: 'in_person_visit',
  LINKEDIN_CONNECT: 'linkedin_connect',
  LINKEDIN_INMAIL: 'linkedin_inmail',
  SENDOSO: 'sendoso',
  SMS: 'sms',
  GENERAL: 'GENERAL',
};

export const TEMPLATE_STEP = 'TEMPLATE';
export const CALL_STEP = 'CALL';
export const OTHER_STEP = 'OTHER';

export const DRAGGABLE_TYPES = keyMirror(
  {
    STEP_SUMMARY: null,
  },
  'DRAGGABLE_TYPES'
);

export const GLOBAL_SEARCH_GROUP_ID = 'globalSearch';
export const GLOBAL_SEARCH_FIELD_ID = 'globalSearch';

export const REMOVE_FROM_FLOW_DIALOG_UI_KEY_PATH = [
  'globalSearch',
  'removeFromFlowDialogVisible',
];
export const PERSON_TO_REMOVE_UI_KEY_PATH = ['globalSearch', 'personToRemove'];
export const FLOW_TO_REMOVE_UI_KEY_PATH = ['globalSearch', 'flowToRemove'];

export const LOGIN_AS_DIALOG_UI_KEY_PATH = ['app', 'loginAsDialogOpen'];
export const LOGIN_AS_USER_LIST_LIMIT = 5;

export const MENU_ITEM_STATES = keyMirror({
  INACTIVE_MODULE: null,
  ACTIVE_MODULE: null,
  ACTIVE_SUBMODULE: null,
});

export const LOGROCKET_DISABLED_ORG_IDS = Object.freeze([
  1, 12, 20, 581, 1109, 1141, 1203, 1278, 1285, 1293, 1372, 1398, 1476, 1508,
  1543, 1613,
]);

export const GLOBAL_SEARCH_WIDTH = 800;
export const OMNIBAR_WIDTH = 320;

export const ALERT = 'alert';
export const WARN = 'warn';

export const UNAUTHORIZED_ERROR_HANDLING_EXEMPT_PATHS = ['/actions/compose'];
