import { actionTypes } from 'Modules/Shared/actions/authorizations';
import { fromJS } from 'immutable';

const initialState = fromJS({
  userCanCreateFlows: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTHORIZATIONS: {
      return state.merge(action.payload);
    }

    default:
      return state;
  }
};
