import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import tinymce from 'tinymce';

import removeAllNonStandardFonts from '../utils/removeAllNonStandardFonts';

interface FontCleanDialogProps {
  onClose: () => void;
  onCancel: () => void;
  isOpen: boolean;
  nonStandardFonts: Set<string>;
  node: HTMLElement | null;
}

const FontCleanDialog: FC<FontCleanDialogProps> = ({
  isOpen,
  onClose,
  onCancel,
  nonStandardFonts,
  node,
}) => {
  const cleanFonts = useCallback(() => {
    if (node) {
      const html = removeAllNonStandardFonts(nonStandardFonts, node);
      tinymce.activeEditor.insertContent(html.innerHTML);
      onClose();
    }
  }, [node, nonStandardFonts, onClose]);
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Warning!</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          Your email contains non-standard fonts which may show up weird for
          your recipients.
        </Typography>
        <Typography variant="body1" paragraph>
          Click CLEAN to have us remove these fonts - or keep them at your own
          risk.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Keep
        </Button>
        <Button variant="contained" onClick={cleanFonts} color="primary">
          Clean
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FontCleanDialog;
