import { fromJS } from 'immutable';
import { actionTypes as peopleImportActionTypes } from 'Modules/PeopleImportDialog/actions';

import { actionTypes } from './actions';

const initialState = fromJS({
  query: '',
});

export default function peopleImportDialog(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.UPDATE_SEARCH_QUERY: {
      return state.set('query', action.payload);
    }

    case peopleImportActionTypes.RESET: {
      return initialState;
    }

    case peopleImportActionTypes.SET_ACTIVE_TAB: {
      const { payload } = action;

      if (payload !== 'search') {
        return initialState;
      }

      return state;
    }

    default:
      return state;
  }
}
