import { renderToString } from 'react-dom/server';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Input,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import tinymce from 'tinymce';

import InlineUploadedFile from '../InlineUploadedFile';

import { MAX_FILE_SIZE, SAFE_EXTENSIONS } from './constants';
import uploadFile from './uploadFile';

const useStyles = makeStyles({
  uploadFileButton: {
    marginBottom: 8,
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  hiddenInput: {
    display: 'none',
  },
  error: {
    paddingTop: 8,
  },
});

interface FileUploadDialogProps {
  onClose: (attachment: unknown) => void;
  isOpen: boolean;
  isTrueAttachments?: boolean;
  totalFilesSize: number;
}

const FileUploadDialog: FC<FileUploadDialogProps> = ({
  onClose,
  isOpen,
  isTrueAttachments,
  totalFilesSize,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const { mutate, isLoading, isSuccess, data, error, reset } = useMutation(
    () => {
      if (file) {
        return uploadFile(file);
      }
      return Promise.reject();
    },
  );

  const handleUploadFile = useCallback(() => {
    mutate();
  }, [mutate]);

  useEffect(() => {
    if (file && isSuccess && data) {
      const id = `${uuidv4()}*${file.size}`;
      const uploadLocation = data.location;

      if (isTrueAttachments) {
        onClose({
          name: file.name,
          uploadLocation,
          size: file.size,
          id,
        });
      } else {
        const html = renderToString(
          <InlineUploadedFile
            id={id}
            fileName={file.name}
            uploadLocation={uploadLocation}
          />,
        );

        const node = tinymce.activeEditor.selection.getNode() as HTMLElement;

        // if the caret is outside the hidden div, adds the attachments to it
        // otherwise, adds to the end of the body
        if (node?.offsetParent?.localName.toLowerCase() === 'body') {
          tinymce.activeEditor.insertContent(html);
        } else {
          tinymce.activeEditor.dom.add(
            tinymce.activeEditor.getBody(),
            'div',
            {},
            html,
          );
        }

        onClose(null);
      }
    }
  }, [data, file, isSuccess, onClose, isLoading, isTrueAttachments]);

  const cancel = () => {
    onClose(null);
  };

  useEffect(() => {
    if (isOpen) {
      setFile(null);
      reset();
    }
  }, [isOpen, reset]);

  const handleSelectFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }, []);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadFileClick = useCallback(() => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const isTooBig = () => {
    return !!file && file.size > MAX_FILE_SIZE;
  };

  const isExceededTheFilesLimit = () => {
    return (
      !!file && isTrueAttachments && file.size + totalFilesSize > MAX_FILE_SIZE
    );
  };

  const isPotentiallyMalicious = () => {
    if (!file) return false;

    const extension = file.name.split('.').pop();

    return !!extension && !SAFE_EXTENSIONS.includes(extension.toLowerCase());
  };

  const classes = useStyles({});

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        <Input
          className={classes.hiddenInput}
          type="file"
          inputRef={fileInputRef}
          inputProps={{ 'data-testid': 'file-input' }}
          onChange={handleSelectFile}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleUploadFileClick}
          data-testid="upload"
          className={classes.uploadFileButton}
        >
          Upload file
        </Button>
        {file && <Typography>{file.name}</Typography>}
        {isTooBig() && (
          <Typography
            className={classes.error}
            data-testid="error"
            variant="caption"
            color="error"
          >
            The size of the provided file must be below{' '}
            {MAX_FILE_SIZE / (1024 * 1024)}MB
          </Typography>
        )}
        {isExceededTheFilesLimit() && (
          <Typography
            className={classes.error}
            data-testid="error"
            variant="caption"
            color="error"
          >
            The total files size must be less than{' '}
            {MAX_FILE_SIZE / (1024 * 1024)}MB
          </Typography>
        )}
        {isPotentiallyMalicious() && (
          <Typography
            className={classes.error}
            data-testid="error"
            variant="caption"
            color="error"
          >
            This file is potentially harmful and has been blocked
          </Typography>
        )}
        {error && (
          <Typography
            className={classes.error}
            data-testid="error"
            variant="caption"
            color="error"
          >
            {(error as Error).message}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          aria-label={isLoading ? 'Uploading' : 'Insert File'}
          disabled={
            !file ||
            isTooBig() ||
            isPotentiallyMalicious() ||
            isExceededTheFilesLimit() ||
            isLoading
          }
          onClick={handleUploadFile}
        >
          {isLoading && 'Uploading'}
          {!isLoading && 'Insert File'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;
