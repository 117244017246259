import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PersonSearchItem from 'Modules/App/components/PersonSearchItem';
import { Person } from 'Modules/People/records';
import Flow from 'Modules/Shared/data/Flow';
import {
  searchSelect,
  loadRemoveFromFlowData,
  loadRemoveFromAllFlowsData,
} from 'Modules/App/actions';
import { currentUser } from 'Modules/Shared/selectors/users';

@connect(
  state => {
    return {
      currentUserId: currentUser(state).get('id'),
    };
  },
  {
    searchSelect,
    loadRemoveFromAllFlowsData,
    loadRemoveFromFlowData,
  }
)
export default class GlobalPersonSearchItem extends Component {
  static propTypes = {
    suggestion: PropTypes.instanceOf(Object).isRequired,
    suggestionValue: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
    renderHighlightedSuggestionValue: PropTypes.func.isRequired,
    searchSelect: PropTypes.func.isRequired,
    loadRemoveFromFlowData: PropTypes.func.isRequired,
    loadRemoveFromAllFlowsData: PropTypes.func.isRequired,
    currentUserId: PropTypes.number.isRequired,
  };

  handleRemoveFromAllFlowsClick = event => {
    const { loadRemoveFromAllFlowsData, suggestion } = this.props;
    const personRecord = new Person(suggestion);
    loadRemoveFromAllFlowsData({ person: personRecord });
    event.stopPropagation();
  };

  handleRemoveFromFlowClick = flow => {
    const { suggestion, loadRemoveFromFlowData } = this.props;
    const personRecord = new Person(suggestion);
    const flowRecord = new Flow(flow);
    loadRemoveFromFlowData({ person: personRecord, flow: flowRecord });
  };

  handleClick = person => {
    const { searchSelect } = this.props;
    const personRecord = new Person(person);
    searchSelect(personRecord);
  };

  render() {
    const {
      suggestion,
      suggestionValue,
      query,
      renderHighlightedSuggestionValue,
      currentUserId,
    } = this.props;

    return (
      <PersonSearchItem
        person={suggestion}
        query={query}
        suggestionValue={suggestionValue}
        onRemoveFromAllFlowsClick={this.handleRemoveFromAllFlowsClick}
        onRemoveFromFlowClick={this.handleRemoveFromFlowClick}
        onPersonClick={this.handleClick}
        renderHighlightedSuggestionValue={renderHighlightedSuggestionValue}
        currentUserId={currentUserId}
      />
    );
  }
}
