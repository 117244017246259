/**
 * These 3 helper functions allow any app to control Action Compose Pane anywhere via postMessage
 */

import {
  ActionComposeEnum,
  LOAD_ACTION_COMPOSE,
  SET_ACTION_COMPOSE_STATE,
  UPDATE_ACTION_COMPOSE,
} from '../constants';
import { LoadAction, UpdateExistingAction } from '../types';

/**
 * Both loads an Action into the Action Compose given the passed params.
 * Only needs just the id, but can add more items to "prefetch" them
 * Also opens the Action Compose Pane if closed
 * @param action LoadAction
 * @returns void
 */
export const loadAction = (action: LoadAction): void =>
  window.postMessage(
    {
      type: LOAD_ACTION_COMPOSE,
      payload: action,
    },
    window.location.origin,
  );

/**
 * Updates an Action that is already loaded into Action Compose Pane, such as email for a
 * to recipient changed.  Please update as needed
 * @param action UpdateExistingAction
 * @returns void
 */
export const updateExistingAction = (action: UpdateExistingAction): void =>
  window.postMessage(
    {
      type: UPDATE_ACTION_COMPOSE,
      payload: action,
    },
    window.location.origin,
  );

/**
 * This method changes the state of the Action Compose Pane, either open, minimize or close it
 * @param status ActionComposeEnum
 * @returns
 */
export const setActionComposeState = (status: ActionComposeEnum): void =>
  window.postMessage(
    {
      type: SET_ACTION_COMPOSE_STATE,
      payload: status,
    },
    window.location.origin,
  );
