import cx from 'classnames';
import { CSSProperties, FC, ReactElement } from 'react';

import Tooltip from './Tooltip';
import Text from './Text';

export type StandardDropDownItem = {
  type?: never;
  key: string | number;
  value: string | number;
  text: string;
  secondaryText?: string[];
  tooltip?: string | ReactElement;
  disabled?: boolean;
  hideTooltip?: boolean;
  showTooltipText?: boolean;
  icon?: JSX.Element;
  metaData?: {
    [key: string]: string;
  };
};

export type HeaderDropDownItem = {
  type: 'header';
  key: string;
  value: string;
  className?: string;
};

export type DividerDropDownItem = {
  type: 'divider';
  key: string;
  className?: string;
};

export type ClearDropDownItem = {
  type: 'clear';
  key: string;
  text: string;
  value: string;
  className?: string;
  disabled?: boolean;
};

export type DropDownItem =
  | StandardDropDownItem
  | HeaderDropDownItem
  | DividerDropDownItem
  | ClearDropDownItem;

type DropDownItemProps = {
  index: number;
  style: CSSProperties;
  data: {
    hasSearch?: boolean;
    highlightedIndex?: number;
    items: DropDownItem[];
    onClick: (
      item: StandardDropDownItem,
      event?: React.MouseEvent | React.KeyboardEvent,
    ) => void;
    searchTerm?: string;
    secondaryTextPosition?: 'right' | 'below';
    showSelectedIcon?: boolean;
    truncate?: boolean;
    wrapText?: boolean;
    onClear?: () => void;
  };
};

const DropDownItem: FC<DropDownItemProps> = ({
  index,
  style,
  data: {
    hasSearch,
    highlightedIndex,
    items,
    onClick,
    onClear,
    secondaryTextPosition = 'below',
    showSelectedIcon = false,
    truncate,
    wrapText,
  },
}) => {
  const item = items[index];

  if (item && !item.type) {
    return (
      <div
        aria-label={`${item.text} item`}
        data-dd-action-name={`${item.text} item`}
        data-splunk-action={`${item.text} item`}
        role="button"
        className={cx(
          `flex text-left w-full bg-white items-center overflow-hidden p-0 relative`,
          highlightedIndex === index &&
            hasSearch &&
            !item.disabled &&
            'bg-neutral/100',
          item.disabled
            ? 'cursor-not-allowed text-neutral/400'
            : 'cursor-pointer hover:bg-neutral/50 active:bg-neutral/100',
        )}
        key={item.key}
        style={style}
        onClick={event => !item.disabled && onClick(item, event)}
        onKeyDown={event =>
          event.key === 'Enter' && !item.disabled && onClick(item)
        }
        tabIndex={0}
      >
        <div
          className={cx(
            `px-[8px] flex flex-row items-center w-full ${
              secondaryTextPosition === 'right' ? 'justify-between' : ''
            }`,
            { truncate },
          )}
        >
          <div className="flex flex-row items-center max-w-full">
            {item.icon && (
              <Tooltip
                content={item.tooltip || item.text}
                hide={item.hideTooltip}
              >
                <div className="mr-[8px] flex w-[16px]">{item.icon}</div>
              </Tooltip>
            )}
            <div
              className={cx('flex flex-col', {
                truncate,
                'whitespace-normal': wrapText,
                'ml-[24px]': showSelectedIcon && !item.icon,
              })}
            >
              {item.showTooltipText ? (
                <Tooltip content={item.tooltip || item.text}>
                  <Text
                    className={cx('my-auto text-metadata', {
                      truncate,
                      'whitespace-normal': wrapText,
                    })}
                    data-dd-action-name={`${item.text} item`}
                    data-splunk-action={`${item.text} item`}
                  >
                    {item.text}
                  </Text>
                </Tooltip>
              ) : (
                <Text
                  className={cx('my-auto text-metadata', {
                    truncate,
                    'whitespace-normal': wrapText,
                  })}
                  data-dd-action-name={`${item.text} item`}
                  data-splunk-action={`${item.text} item`}
                >
                  {item.text}
                </Text>
              )}
              {secondaryTextPosition === 'below' && item.secondaryText && (
                <div
                  className={cx({
                    truncate,
                    'whitespace-normal': wrapText,
                  })}
                >
                  {Array.isArray(item.secondaryText) ? (
                    item.secondaryText.map((text, index) => (
                      <Text
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${text}-${index}`}
                        className={cx(
                          'my-auto text-neutral/400 text-metadata-sm',
                          {
                            truncate,
                            'whitespace-normal': wrapText,
                          },
                        )}
                      >
                        {text}
                      </Text>
                    ))
                  ) : (
                    <Text
                      className={cx(
                        'my-auto text-neutral/400 text-metadata-sm',
                        {
                          truncate,
                          'whitespace-normal': wrapText,
                        },
                      )}
                    >
                      {item.secondaryText}
                    </Text>
                  )}
                </div>
              )}
            </div>
          </div>
          {secondaryTextPosition === 'right' && item.secondaryText && (
            <div
              className={cx('flex flex-col text-right ml-auto', {
                truncate,
                'whitespace-normal': wrapText,
              })}
            >
              {Array.isArray(item.secondaryText) ? (
                item.secondaryText.map((text, index) => (
                  <Text
                    key={text}
                    className={cx('my-auto text-neutral/400 text-metadata-sm', {
                      truncate,
                      'whitespace-normal': wrapText,
                    })}
                  >
                    {text}
                  </Text>
                ))
              ) : (
                <Text
                  className={cx('my-auto text-neutral/400 text-metadata-sm', {
                    truncate,
                    'whitespace-normal': wrapText,
                  })}
                >
                  {item.secondaryText}
                </Text>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  if (item && item.type === 'header') {
    return (
      <div
        aria-label={`${item.value} header`}
        key={item.key}
        className={`flex w-full text-left ${item.className || ''}`}
        style={style}
      >
        <div className="w-full px-[8px] !border-b border-solid border-neutral/75 text-neutral/600 items-end flex flex-1 pb-[3px] border-0 h-[26px]">
          <Tooltip content={item.value}>
            <Text weight="semibold" className="text-metadata-sm">
              {item.value}
            </Text>
          </Tooltip>
        </div>
      </div>
    );
  }

  if (item && item.type === 'divider') {
    return (
      <div key={item.key} style={style} className={item.className}>
        <hr />
      </div>
    );
  }

  if (item && item.type === 'clear') {
    return (
      <div
        aria-label={`${item.text}`}
        key={item.key}
        className={cx(
          `flex w-full text-left text-xs ${item.className || ''}`,
          item.disabled
            ? 'cursor-not-allowed text-neutral/400'
            : 'cursor-pointer text-neutral/900 hover:bg-neutral/50 active:bg-neutral/100',
        )}
        style={style}
        role="button"
        onClick={() => !item.disabled && onClear?.()}
        tabIndex={0}
        onKeyDown={event =>
          event.key === 'Enter' && !item.disabled && onClear?.()
        }
      >
        <div className="w-full px-[8px] items-end flex flex-1 pb-[3px] h-[26px]">
          <Text weight="normal" className="text-metadata-sm">
            {item.text}
          </Text>
        </div>
      </div>
    );
  }

  return null;
};

export default DropDownItem;
