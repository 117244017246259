export const stdTimezoneOffset = (): number => {
  const jan = new Date(2023, 0, 1);
  const jul = new Date(2023, 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const getLocalOffset = (startStr: string): number => {
  const today = new Date();

  const selectedTimeZone = parseInt(startStr.slice(-6).slice(0, 3), 10);

  const isDstObserved = (today: Date): boolean => {
    return today.getTimezoneOffset() < stdTimezoneOffset();
  };

  if (isDstObserved(today)) {
    return selectedTimeZone + 1;
  }

  return selectedTimeZone;
};

export const getWeekStartDateAndIgnoreTimeZone = (dateStr: string): Date => {
  const date = new Date(dateStr);

  // convert to msec since Jan 1 1970
  const localTime = date.getTime();

  // obtain local UTC offset and convert to msec
  const offset = date.getTimezoneOffset() * 60 * 1000;

  // obtain UTC time in msec
  const utcTime = localTime + offset;

  // obtain and add destination's UTC time offset
  const localOffset = getLocalOffset(dateStr);

  const offsetCalculation = 60 * 60 * 1000 * localOffset;

  // convert msec value to date string
  return new Date(utcTime + offsetCalculation);
};

export const isDateStringDiff = (start: Date, currentTime: Date): boolean => {
  return start.toString() !== currentTime.toString();
};
