import { FC, useEffect, useState } from 'react';
import { RecordIcon } from '@groove/ui/Components/BoogieIcon';
import useDialerStore from '@groove/dialer-components/hooks/useStore';
import {
  startRecording,
  stopRecording,
} from '@groove/api/gateway/v1/commonDialer';
import {
  useGetCurrentUser,
  useGetOrgSettings,
} from '@groove/api/hooks/useUsers';
import { useCheckNumber } from '@groove/api/hooks/useCommonDialer';

import useStore from '../../store/useStore';

import DialerBarButton from './DialerBarButton';

const CALL_RECORDING_MESSAGE_ID = 'callRecordingMessageId';
const TWO_PARTY_TOOLTIP =
  'The phone number you are calling is in a state that requires 2 party consent.';
const TWO_PARTY_LOAD_TOOLTIP =
  'Checking if phone number requires 2 party consent...';

const RecordButton: FC = () => {
  const [toolTip, setToolTip] = useState('');
  const [isRecordingDisabled, setIsRecordingDisabled] = useState(true);

  const isRecording = useStore(store => store.dialerState.isRecording);
  const hasCalled = useStore(store => store.action.dynamicData.hasCalled);
  const toRecipients = useStore(store => store.action.toRecipients);
  const recordFromStart = useDialerStore(store => store.recordFromStart);
  const twilioSid = useDialerStore(store => store.getActiveConnectionSid)();
  const callState = useDialerStore(store => store.callState);

  const { data: userData } = useGetCurrentUser();
  const { data: orgData } = useGetOrgSettings();
  const { isFetching: isLoading, data: checkNumberData } = useCheckNumber(
    toRecipients?.[0]?.phone || '',
    !!toRecipients?.[0]?.phone,
  );

  const shouldConsent = checkNumberData?.requires_consent;
  const recordingSetting = userData?.call_recording_setting;
  const orgOverride = orgData?.call_recording_override_setting;

  // Set up recording button, tooltips and message banners
  // Please see this diagram for all the behaviors:
  // https://clarius.atlassian.net/wiki/spaces/GRCS/pages/3722838929/Settings+Combinations
  useEffect(() => {
    setToolTip('');
    if (hasCalled && callState === 'not-connected') {
      setIsRecordingDisabled(true);
      useStore.getState().updateDialerState({ isRecording: false });
      useStore.getState().removeMessage(CALL_RECORDING_MESSAGE_ID);
      setToolTip('You have already made a called.');
    } else if (
      orgOverride?.toLowerCase() === 'force' ||
      recordingSetting?.toLowerCase() === 'required'
    ) {
      const isRecording =
        orgOverride?.toLowerCase() !== 'off' || !(shouldConsent && isLoading);
      useStore.getState().updateDialerState({ isRecording });

      if (isRecording) {
        setToolTip(
          'Call Recording is required by your Admin and cannot be disabled.',
        );
      } else if (isLoading || shouldConsent) {
        setToolTip(isLoading ? TWO_PARTY_LOAD_TOOLTIP : TWO_PARTY_TOOLTIP);
      }
    } else if (recordingSetting?.toLowerCase() === 'disabled') {
      useStore.getState().updateDialerState({ isRecording: false });
      setToolTip('Call Recording has been disabled by your Admin.');
    } else if (recordingSetting?.toLowerCase() === 'optional') {
      if (
        orgOverride?.toLowerCase() === 'off' &&
        (isLoading || shouldConsent)
      ) {
        setToolTip(isLoading ? TWO_PARTY_LOAD_TOOLTIP : TWO_PARTY_TOOLTIP);
      } else {
        setIsRecordingDisabled(false);
        setToolTip('Record your call to the cloud.');
        useStore.getState().updateDialerState({ isRecording: recordFromStart });
        if (shouldConsent && orgOverride === 'optional')
          useStore.getState().addMessage({
            level: 'warning',
            message: TWO_PARTY_TOOLTIP,
            id: CALL_RECORDING_MESSAGE_ID,
            noCloseButton: true,
          });
      }
    } else {
      setToolTip('Contact your admin to setup call recording.');
    }
    return () => {
      useStore.getState().removeMessage(CALL_RECORDING_MESSAGE_ID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    recordingSetting,
    orgOverride,
    isLoading,
    shouldConsent,
    hasCalled,
    callState,
  ]);

  // Auto start recording if we click record before start otherwise update the isRecording state with
  // recordFromStart state
  useEffect(() => {
    if (isRecording && twilioSid) {
      useStore.getState().updateDialerState({ isRecording: true });
      startRecording(twilioSid, toRecipients?.[0]?.phone || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording, twilioSid]);

  const onRecordClick = (): void => {
    useStore.getState().updateDialerState({ isRecording: !isRecording });
    if (isRecording && twilioSid) {
      stopRecording(twilioSid);
    } else if (twilioSid) {
      startRecording(twilioSid, toRecipients?.[0]?.phone || '');
    } else {
      useDialerStore.getState().setRecordFromStart(!recordFromStart);
    }
  };

  return (
    <DialerBarButton
      label="Record"
      onClick={onRecordClick}
      selected={isRecording}
      tooltip={toolTip}
      disabled={isRecordingDisabled}
    >
      <RecordIcon className="w-[20px]" />
    </DialerBarButton>
  );
};

export default RecordButton;
