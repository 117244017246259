import { IPersonaProps, List, Spinner, Text } from '@fluentui/react';
import { grooveWhoSearch } from '@groove/api/visualforce/grooveSearch';
import GrooveViewObject from '@groove/api/visualforce/types/GrooveContext/GrooveViewObject';
import RecordMenuItem from '@groove/ui/Components/RecordMenuItem';
import { FC } from 'react';
import { useQuery } from 'react-query';

import transformGrooveEngineResult from './transformGrooveEngineResults';
import transformGrooveViewObject from './transformGrooveViewObject';
import { DisplayableSearchResult } from './transformSearchResult';

type WhatListProps = {
  whoId: string;
  whoType: string;
  selectedWhatId?: string;
  onWhatSelected: (what: DisplayableSearchResult) => void;
};

export const getDisplayAttributeValue = (
  grooveViewObject: GrooveViewObject,
  fieldName: string,
): string | undefined => {
  return grooveViewObject.displayAttributes.find(
    displayAttribute =>
      displayAttribute.api?.split('.')[1] === fieldName.toLowerCase(),
  )?.displayValue;
};

const WhatList: FC<WhatListProps> = ({
  whoId,
  whoType,
  selectedWhatId,
  onWhatSelected,
}) => {
  const { data, isLoading } = useQuery(['search', whoId], async () => {
    const data = grooveWhoSearch(whoId, whoType);
    return transformGrooveEngineResult(data);
  });

  const allObjects = data?.views?.map(view => view.objects).flat() || [];

  const allUniqueObjectsById: GrooveViewObject[] = Object.values(
    allObjects.reduce(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (acc, object) => ({ ...acc, [object.attributes!.id]: object }),
      {},
    ),
  );

  const transformedData = allUniqueObjectsById.map(
    transformGrooveViewObject,
  ) as IPersonaProps[];

  return (
    <>
      {isLoading && <Spinner />}
      {transformedData && transformedData.length > 0 && (
        <Text block className="my-1">
          Related To
        </Text>
      )}
      <List
        items={transformedData}
        onRenderCell={(item?: IPersonaProps) =>
          item && (
            <div key={item.key}>
              <div className="flex items-center">
                <input
                  aria-label={`Select-${item.id}`}
                  type="radio"
                  className="checked:bg-gray-200"
                  checked={selectedWhatId === item.id}
                  onChange={() =>
                    item.id && onWhatSelected(item as DisplayableSearchResult)
                  }
                />
                <RecordMenuItem
                  fullWidth
                  showLinkToSalesforce
                  id={item.id as string}
                  text={item.text || ''}
                  secondaryText={item.secondaryText}
                  secondaryTextLabel={
                    (item as DisplayableSearchResult).secondaryTextLabel
                  }
                  tertiaryText={(item as DisplayableSearchResult).tertiaryText}
                  tertiaryTextLabel={
                    (item as DisplayableSearchResult).tertiaryTextLabel
                  }
                  quarteraryText={
                    (item as DisplayableSearchResult).quarteraryText
                  }
                  quarteraryTextLabel={
                    (item as DisplayableSearchResult).quarteraryTextLabel
                  }
                  quinaryText={(item as DisplayableSearchResult).quinaryText}
                  quinaryTextLabel={
                    (item as DisplayableSearchResult).quinaryTextLabel
                  }
                />
              </div>
            </div>
          )
        }
      />
    </>
  );
};

export default WhatList;
