import { FC, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Text from '@groove/ui/Components/Text';
import { format } from 'date-fns';
import CalendarTimePicker from '@groove/ui/Components/CalendarTimePicker';
import useHandleOutsideClicks from '@groove/ui//helpers/useHandleOutsideClicks';
import InputFrame from '@groove/ui/Components/InputFrame';

import useStore from '../store/useStore';

const DueAt: FC = () => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const dueAt = useStore(store => store.action.dueAt);
  const personStepId = useStore(store => store.action.personStepId);
  const parsedDueAt = dueAt ? new Date(dueAt) : new Date();

  const togglerPicker = (): void => setIsOpen(!isOpen);

  const onDateChange = (date: Date): void => {
    useStore.getState().updateAction({ dueAt: date.toISOString() });
  };

  useHandleOutsideClicks(ref, () => setIsOpen(false));

  return (
    <div ref={ref}>
      <Text variant="body-sm" className="mb-[4px] mt-[4px] font-semibold">
        Due
      </Text>
      <div className="relative">
        <InputFrame
          placeholder="Name"
          className="w-full mb-[4px] h-[28px]"
          onClick={togglerPicker}
          value={format(parsedDueAt, 'Pp')}
          disabled={!!personStepId}
        />
        <div className="h-0 absolute w-full top-0">
          <motion.div
            className="absolute z-[1000] overflow-y-auto bg-white border-0.5 border-gray-300 drop-shadow-md w-full px-[12px] py-[12px] bottom-0 !box-content"
            animate={{
              opacity: isOpen ? 1 : 0,
              width: isOpen ? 460 - 24 : 0,
              height: isOpen ? 280 - 24 : 0,
            }}
            initial={{ opacity: 0, width: 0, height: 0 }}
            transition={{ duration: 0.15 }}
          >
            <CalendarTimePicker
              date={parsedDueAt}
              onDateChange={onDateChange}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default DueAt;
