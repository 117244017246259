import { stringify } from 'querystring';

import ky, { Options } from 'ky';

import getClient from '../getClient';

// eslint-disable-next-line import/no-mutable-exports
export let strategy: VisualforceStrategy | null = null;

export type VisualforceStrategy = TunnelStrategy | DirectCallStrategy;

export type TunnelStrategy = {
  type: 'TUNNEL';
  prefixUrl?: string;
  path?: string;
  options?: never;
};

export type DirectCallStrategy = {
  type: 'DIRECT_CALL';
  includePrefix?: boolean;
  options: Options;
};

export const configureClient = (
  strategyToUse: VisualforceStrategy | null,
): void => {
  strategy = strategyToUse;
};

export const isClientConfigured = (): boolean => !!strategy;

export class VisualForceError extends Error {
  response: string;

  constructor(message: string, response: string) {
    super(message);
    this.response = response;
  }
}

export default (): typeof ky => {
  if (strategy === null) {
    throw new Error(
      'The Visualforce client is not configured. Please call `configureClient` before using it.',
    );
  }
  if (strategy.type === 'DIRECT_CALL') {
    const client = ky.create({
      ...strategy.options,
      timeout: 25000,
    });
    return {
      get(_, options = {}) {
        const { searchParams, ...otherOptions } = options;
        const { page, ...otherSearchParams } = searchParams as {
          [key: string]: string | number | boolean;
        };
        const hasBody = !!options.body;

        const includeCredentals = strategy?.options?.credentials
          ? strategy?.options?.credentials
          : 'omit';

        if (hasBody) {
          // https://github.com/sindresorhus/ky/issues/450
          // the above issue is fixed in a newer version of ky
          // but we cannot upgrade because of several issues that
          // the upgrade introduces to outlook-add-in
          // so instead we have to use the fetch API directly
          return {
            json: async () => {
              const response = await fetch(
                `${strategy?.options?.prefixUrl}${
                  (strategy as DirectCallStrategy)?.includePrefix
                    ? `DaScoopComposer__${page}`
                    : `${page}`
                }?${stringify(otherSearchParams as { [key: string]: string })}`,
                {
                  method: 'POST',
                  headers: strategy?.options?.headers as HeadersInit,
                  body: options.body,
                  redirect: 'follow',
                  credentials: includeCredentals,
                },
              );

              // This makes it so we use the same afterResponse that the get requests uses:
              // https://github.com/GrooveLabs/monorepo/blob/master/apps/chrome-extension-mv3/src/serviceWorker/configureClients.ts#L49
              if (strategy?.options?.hooks?.afterResponse) {
                strategy?.options?.hooks?.afterResponse.forEach(callback => {
                  callback(
                    new Request(
                      `${strategy?.options?.prefixUrl}${
                        (strategy as DirectCallStrategy)?.includePrefix
                          ? `DaScoopComposer__${page}`
                          : `${page}`
                      }?${stringify(
                        otherSearchParams as { [key: string]: string },
                      )}`,
                    ),
                    {
                      method: undefined,
                      credentials: undefined,
                      retry: undefined,
                      prefixUrl: undefined,
                      onDownloadProgress: undefined,
                    },
                    response,
                  );
                });
              }

              if (response.headers.get('Content-Type')?.includes('text/html')) {
                throw new VisualForceError(
                  `Visual Force Error: ${page}`,
                  await response.text(),
                );
              }

              return response.json();
            },
          };
        }

        return client.get(
          (strategy as DirectCallStrategy)?.includePrefix
            ? `DaScoopComposer__${page}`
            : `${page}`,
          {
            ...otherOptions,
            ...(Object.keys(otherSearchParams).length > 0 && {
              searchParams: otherSearchParams,
            }),
          },
        );
      },
      post(_, options = {}) {
        const { searchParams, ...otherOptions } = options;
        const { page, ...otherSearchParams } = searchParams as {
          [key: string]: string | number | boolean;
        };
        const hasBody = !!options.body;
        if (hasBody) {
          // https://github.com/sindresorhus/ky/issues/450
          // the above issue is fixed in a newer version of ky
          // but we cannot upgrade because of several issues that
          // the upgrade introduces to outlook-add-in
          // so instead we have to use the fetch API directly
          return {
            json: async () => {
              const response = await fetch(
                `${strategy?.options?.prefixUrl}${
                  (strategy as DirectCallStrategy)?.includePrefix
                    ? `DaScoopComposer__${page}`
                    : `${page}`
                }?${stringify(otherSearchParams as { [key: string]: string })}`,
                {
                  method: 'POST',
                  headers: strategy?.options?.headers as HeadersInit,
                  body: options.body,
                  redirect: 'follow',
                  credentials: 'omit',
                },
              );

              const textResponse = await response.text();

              if (textResponse.startsWith('\r\n<!DOCTYPE HTML')) {
                throw new VisualForceError('Visual Force Error', textResponse);
              }

              return JSON.parse(textResponse);
            },
          };
        }

        return client.get(
          (strategy as DirectCallStrategy)?.includePrefix
            ? `DaScoopComposer__${page}`
            : `${page}`,
          {
            ...otherOptions,
            ...(Object.keys(otherSearchParams).length > 0 && {
              searchParams: otherSearchParams,
            }),
          },
        );
      },
    } as typeof ky;
  }
  return getClient({
    ...(strategy.prefixUrl && { prefixUrl: strategy.prefixUrl }),
  });
};
