import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const MinimizeIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <rect x="3" y="14" width="14" height="1.5" rx="0.75" />
  </svg>
);
