/**
 * Handles the state change of table sorting to return the new orderBy column and order direction.
 *
 * @param {string} nextOrderBy The new orderBy column.
 * @param {string} previousOrderBy The previous orderBy column.
 * @param {string} previousOrderDirection The previous order direction.
 *
 * @return {string} The next order direction.
 * */
export default function nextOrderDirection(
  nextOrderBy,
  previousOrderBy,
  previousOrderDirection,
  defaultOrderDirection = 'desc'
) {
  if (nextOrderBy === previousOrderBy) {
    return previousOrderDirection === 'desc' ? 'asc' : 'desc';
  }

  return defaultOrderDirection;
}
