import { useQuery, UseQueryResult } from 'react-query';

import {
  ApiFlowsFetch,
  flowsFetch,
  FlowsFetchParams,
} from '../services/v1/flows';
import paramsToQueryKeyArray from '../utils/paramsToQueryKeyArray';

export const useAllActiveFlowsKey = 'allActiveFlows';

const useAllActiveFlows = (
  params: FlowsFetchParams,
  enabled: boolean,
): UseQueryResult<ApiFlowsFetch, unknown> => {
  return useQuery(
    [useAllActiveFlowsKey, ...paramsToQueryKeyArray(params)],
    () =>
      flowsFetch({
        ...params,
        isFlowActive: true,
        notCompleted: true,
      }),
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 1000 * 60 * 10,
      enabled,
    },
  );
};

export default useAllActiveFlows;
