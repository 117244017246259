import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const MoneyIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, 'fill-current')}
  >
    <path d="M9 7.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm-1 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zM1 4.75c0-.69.56-1.25 1.25-1.25h9.5c.69 0 1.25.56 1.25 1.25v5.5c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-5.5zm1.25-.25a.25.25 0 0 0-.25.25v.75h.5A.5.5 0 0 0 3 5v-.5h-.75zM2 10.25c0 .138.112.25.25.25H3V10a.5.5 0 0 0-.5-.5H2v.75zM4 10v.5h6V10a1.5 1.5 0 0 1 1.5-1.5h.5v-2h-.5A1.5 1.5 0 0 1 10 5v-.5H4V5a1.5 1.5 0 0 1-1.5 1.5H2v2h.5A1.5 1.5 0 0 1 4 10zm7 .5h.75a.25.25 0 0 0 .25-.25V9.5h-.5a.5.5 0 0 0-.5.5v.5zm1-5v-.75a.25.25 0 0 0-.25-.25H11V5a.5.5 0 0 0 .5.5h.5zm-7.5 8a1.5 1.5 0 0 1-1.427-1.036c.139.024.281.036.427.036h8.25A2.25 2.25 0 0 0 14 10.25V5.585A1.5 1.5 0 0 1 15 7v3.25a3.25 3.25 0 0 1-3.25 3.25H4.5z" />
  </svg>
);
