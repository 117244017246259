import { Record } from 'immutable';

const defaults = {
  id: null,
  deviceType: null,
  location: null,
  triggeredAt: null,
  type: null,
  readByUser: null,
};

export default class EmailEvent extends Record(defaults) {
  static TYPES = Object.freeze({
    EmailOpen: 'EmailOpen',
    LinkClick: 'LinkClick',
    EmailReply: 'EmailReply',
    AttachmentDownload: 'AttachmentDownload',
  });

  isOpen() {
    return this.type === this.constructor.TYPES.EmailOpen;
  }
}
