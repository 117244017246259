export const SMART_PRIORITY_CUTOFFS = {
  HIGH: 80,
  MEDIUM: 60,
  LOW: 0,
};

export const SMART_PRIORITY_LABELS = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  DEFAULT: '--',
};

// Hovercard Header
export const sharedLabelStyle = {
  paddingLeft: 12,
  paddingRight: 12,
  paddingTop: 1.5,
  paddingBottom: 1.5,
  borderRadius: 4,
  letterSpacing: 0.11,
};

// Factors
export const sharedFactorRowSpacingStyle = {
  minimumHeight: 16,
  marginLeft: 12,
  marginTop: 8,
  marginBottom: 8,
};

export const FACTOR_EFFECTS = {
  POSITIVE: /positive/i,
  NEGATIVE: /negative/i,
};

// FEATURE FLAG. NB: This constant is duplicated from Actions
// Duplicated so we can eventually delete the entire Actions folder later
export const SMART_ACTION_FORRESTER_FEATURE_FLAG = 'smart-priorities';
