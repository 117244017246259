import { useQuery } from 'react-query';
import {
  postActionCounts,
  ActionsFilter,
  processDueAtFilter,
  DueAtOperator,
} from '@groove/api/gateway/v1/actions';

export default function usePostActionCounts(
  filter = {} as Partial<ActionsFilter>
) {
  return useQuery(['postActionTabCounts', filter], () =>
    postActionCounts({
      ...filter,
      due_at: processDueAtFilter(filter.due_at as unknown as DueAtOperator[]),
    })
  );
}
