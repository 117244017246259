import React, { FC } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { GrooveTheme } from '../type';
import { ErrorType } from '../constants';

import { LoginErrorProps } from './Seismic';

const useStyles = makeStyles((theme: GrooveTheme) => ({
  container: {
    textAlign: 'center',
    padding: 50,
    width: 400,
    paddingBottom: 40,
  },
  textContainer: {
    paddingBottom: 30,
  },
  containedPrimary: {
    backgroundColor: theme.palette.primary[500],
    '&:hover': {
      background: theme.palette.primary[700],
    },
  },
  changeTenantLink: {
    marginTop: 15,
    fontSize: '0.875rem',
    color: theme.palette.primary[500],
    cursor: 'pointer',
  },
}));

const LoginError: FC<LoginErrorProps> = ({
  clearTenant,
  errorType,
  reloader,
}) => {
  const { changeTenantLink, container, containedPrimary, textContainer } =
    useStyles();

  let errorMessage = 'The sign in screen was closed too early.';
  if (errorType === ErrorType.other) {
    errorMessage = 'An unknown error has occurred.';
  } else if (errorType === ErrorType.sessionEnded) {
    errorMessage = 'Your Seismic session has ended.';
  }
  return (
    <div className={container}>
      <div className={textContainer}>
        <Typography>{errorMessage}</Typography>
        <Typography>Please sign in again to continue.</Typography>
      </div>
      <div>
        <div>
          <Button
            className={containedPrimary}
            variant="contained"
            color="primary"
            onClick={reloader}
          >
            Sign in
          </Button>
        </div>
        <div>
          <Typography
            classes={{ root: changeTenantLink }}
            onClick={clearTenant}
          >
            Change Tenant
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default LoginError;
