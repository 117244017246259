import * as GrooveLocalStorage from 'GrooveLocalStorage';

// Temporary measure to fix incorrectly encoded csrf tokens in localStorage
export default function fixCsrfToken(): void {
  let csrfToken = GrooveLocalStorage.fetch('csrfToken');
  if (csrfToken && csrfToken.includes(' ')) {
    csrfToken = csrfToken.replace(/ /g, '+');
    GrooveLocalStorage.save('csrfToken', csrfToken);
  }
}
