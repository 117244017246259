import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const ChevronIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <g clipPath="url(#lz0gr9ro0a)">
      <path
        d="M11.853 7.646a.5.5 0 0 1 .001.707l-5.465 5.485a.55.55 0 0 1-.78 0L.146 8.352a.5.5 0 1 1 .708-.706L6 12.812l5.147-5.165a.5.5 0 0 1 .707-.001z"
        fill="#9E9E9E"
      />
    </g>
    <defs>
      <clipPath id="lz0gr9ro0a">
        <path fill="#fff" d="M0 0h12v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
