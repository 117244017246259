import { decamelizeKeys } from 'humps';
import { POST } from 'GrooveHTTPClient/HTTPMethod';
import makeHTTPRequest from '../../makeHTTPRequest';

const BASE_PATH = '/api/v2/groove_user/users';

export const index = ({ searchQuery, order, limit, offset }) =>
  makeHTTPRequest({
    path: BASE_PATH,
    queryParameters: decamelizeKeys({
      searchQuery,
      order,
      limit,
      offset,
    }),
  });

export const loginAs = ({ userId }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${userId}/login_as`,
    method: POST,
  });
