import { selectors } from '@groove-labs/groove-ui';
import { STEP_TEMPLATE_NAME_FIELD } from 'Modules/Shared/constants';
import {
  getWysiwygEditorValue,
  getWysiwygEditorInitialValue,
} from 'Modules/Shared/selectors/templateAddEdit';
import { createSelector } from 'reselect';

const { makeGetFieldValue, makeGetSelectedData } = selectors.form;

const SDDMfieldId = STEP_TEMPLATE_NAME_FIELD;

export const getTemplateNameFieldValue = makeGetFieldValue(SDDMfieldId);

export const getSelectedTemplate = makeGetSelectedData(SDDMfieldId);

export const getStepType = makeGetFieldValue('stepType');

export const getDayNumber = makeGetFieldValue('dayNumber');

export const getIncludeInThread = makeGetFieldValue('keepGmailThreadId');

export const isTemplateEditorDirty = createSelector(
  getWysiwygEditorValue,
  getWysiwygEditorInitialValue,
  (value, initialValue) => {
    if (value && initialValue) {
      return value !== initialValue;
    }

    return false;
  }
);
