import React from 'react';
import PropTypes from 'prop-types';
import {
  PRIORITY_COLORS,
  PRIORITY_OPTIONS,
  PRIORITIES,
} from 'Modules/Shared/constants/index';
import { READABLE_NAME_LOOK_UP } from 'Modules/Actions/constants/index';
import StatusChip from 'Modules/Shared/components/StatusChip';
import ClariTheme from 'Utils/theme/ClariTheme';

const style = {
  backgroundColor: ClariTheme.palette.text.neutralColor,
  color: ClariTheme.palette.text.secondary,
  borderWidth: 1,
  borderStyle: 'solid',
};

const PriorityChip = ({ priority }) => {
  const label =
    PRIORITY_OPTIONS.getIn([priority, 'label']) ||
    READABLE_NAME_LOOK_UP[PRIORITIES.NONE];
  return (
    <StatusChip
      label={label}
      color={PRIORITY_COLORS[priority]}
      style={priority ? {} : style}
    />
  );
};

PriorityChip.displayName = 'PriorityChip';
PriorityChip.propTypes = {
  priority: PropTypes.string.isRequired,
};

export default PriorityChip;
