import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ClariTheme from 'Utils/theme/ClariTheme';

const styles = theme => ({
  root: {
    color: theme.palette.text.neutralColor,
    borderRadius: 2,
    height: 16,
    fontSize: 11,
    fontWeight: 500,
    justifyContent: 'center',
    textTransform: 'uppercase',
    padding: '1px 4px',
    display: 'flex',
    alignItems: 'center',
  },
});

const LabeledChip = ({ classes, label, color, style }) => {
  const styles = {
    backgroundColor: color,
    ...style,
  };
  return (
    <div className={classes.root} style={styles}>
      {label}
    </div>
  );
};

LabeledChip.displayName = 'LabeledChip';
LabeledChip.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

LabeledChip.defaultProps = {
  color: ClariTheme.palette.text.primary,
  style: {},
};

export default withStyles(styles)(LabeledChip);
