import cx from 'classnames';
import { FC, HTMLProps } from 'react';
import { motion } from 'framer-motion';

import Text from './Text';

export interface HeaderItem {
  label: string | JSX.Element;
  value: string;
}

export type PivotHeaderProps = {
  headerItems: HeaderItem[];
  fontSize?: 'text-metadata-xs' | 'text-metadata';
  onSelect: (value: string) => void;
  selectedValue: string;
  className?: HTMLProps<HTMLElement>['className'];
};

const PivotHeader: FC<PivotHeaderProps> = ({
  headerItems,
  fontSize = 'text-metadata',
  onSelect,
  selectedValue,
  className,
}) => {
  return (
    <div className={cx('flex flex-row w-full h-[36px]', className)}>
      {headerItems.map(({ label, value }) => (
        <div
          className="pt-[9px] pb-[11px] flex-1 relative cursor-pointer flex justify-center items-center hover:bg-neutral/50"
          key={`pivot-item-${value}`}
          onClick={() => onSelect(value)}
          role="button"
          tabIndex={0}
          onKeyDown={event => event.key === 'Enter' && onSelect(value)}
        >
          <Text className={cx('font-semibold', fontSize)}>{label}</Text>
          <motion.div
            initial={{ opacity: 0, width: '50%' }}
            animate={{
              opacity: value === selectedValue ? 1 : 0,
              width: value === selectedValue ? '100%' : 0,
            }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="absolute bottom-0 w-full h-[2px] bg-clari-blue/600"
          />
        </div>
      ))}
    </div>
  );
};

export default PivotHeader;
