import { call } from 'redux-saga/effects';
import sendPostMessage from 'Modules/Shared/utilities/sendPostMessage';
import { baseActionTypes } from 'Modules/ActionCompose/actions/incoming';
import { INCOMING_POST_MESSAGE_TARGET as ACTION_COMPOSE_INCOMING_POST_MESSAGE_TARGET } from 'Modules/ActionCompose/constants';
import { OUTGOING_POST_SOURCE_SOURCE } from 'Modules/Actions/constants';

function* handleLoginFromComposePane() {
  yield call(sendPostMessage, {
    windowContext: window.opener,
    type: baseActionTypes.INCOMING_ACTION_COMPOSE.SET_GROOVE_TOKENS_AS_RESOLVED,
    payload: {},
    target: ACTION_COMPOSE_INCOMING_POST_MESSAGE_TARGET,
    source: OUTGOING_POST_SOURCE_SOURCE,
  });

  window.open('', '_self');
  window.close();
}

export default handleLoginFromComposePane;
