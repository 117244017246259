import { sagas } from '@groove-labs/groove-ui';
import { actionTypes as locationActionTypes } from 'Modules/Shared/actions/location';
import {
  getScroll,
  location as locationSelector,
} from 'Modules/Shared/selectors/location';
import { all, fork, select, takeLatest } from 'redux-saga/effects';

import alerts from './firestoreInit';
import automatedActions from './automatedActions';
import analytics from './analytics';
import activitySettings from './activitySettings';
import batchJobs from './batchJobs';
import createRunRootSaga from './createRunRootSaga';
import dialer from './dialer';
import errors from './errors';
import flows from './flows';
import location from './location';
import omnibar from './omnibar';
import people from './people';
import steps from './steps';
import templates from './templates';
import wysiwygSagaManager from './wysiwygSagaManager';
import labels from './labels';
import users from './users';
import health from './health';
import remainingEmailSendQuota from './remainingEmailSendQuota';
import autoCreationOfRecords from './autoCreationOfRecords';
import campaignAssociation from './campaignAssociation';

const { form } = sagas;

export { createRunRootSaga };

function* updateScroll() {
  const currentLocation = yield select(locationSelector);
  const oldScroll = yield select(getScroll(currentLocation.get('routeName')));
  const interval = setInterval(() => {
    const appRoot = document.getElementById('app-root');
    if (appRoot) {
      appRoot.scrollTop = oldScroll;
      clearInterval(interval);
    }
  }, 100);
}

function* watchLocationChangeFinish() {
  yield takeLatest(locationActionTypes.FINISH_CHANGE, updateScroll);
}

export default function* root() {
  yield all([
    fork(alerts),
    fork(analytics),
    fork(activitySettings),
    fork(automatedActions),
    fork(batchJobs),
    fork(dialer),
    fork(errors),
    fork(flows),
    fork(form),
    fork(labels),
    fork(location),
    fork(omnibar),
    fork(people),
    fork(steps),
    fork(templates),
    fork(watchLocationChangeFinish),
    fork(wysiwygSagaManager),
    fork(remainingEmailSendQuota),
    fork(users),
    fork(health),
    fork(autoCreationOfRecords),
    fork(campaignAssociation),
  ]);
}
