import HttpStatusCodes from 'http-status-codes';
import { logError } from 'Modules/Shared/actions/errors';
import { call, put } from 'redux-saga/effects';
import sagaErrorHandler from 'ErrorHandling/sagaErrorHandler';
import HTTPError from 'GrooveHTTPClient/HTTPError';
import { ERROR_TYPES } from 'Modules/Shared/constants';
import { SERVER_ERROR_TYPES } from 'ErrorHandling/constants';

import logout from './logout';

function* defaultSagaErrorHandler({ error }) {
  // TODO: @vijaypemmaraju port this logic to make-http-request
  // once fully ported
  if (error.name === 'UnauthorizedError') {
    // Reset app/content loading, just in case it was turned on
    yield call(logout, error);
  } else if (error instanceof HTTPError) {
    switch (error.response.status) {
      case HttpStatusCodes.UNAUTHORIZED: {
        if (error.response.data.type === SERVER_ERROR_TYPES.NOT_AUTHENTICATED) {
          yield call(logout, error);
        } else {
          yield put(logError({ error, errorType: ERROR_TYPES.GENERIC }));
        }
        break;
      }
      case HttpStatusCodes.GATEWAY_TIMEOUT: {
        yield put(logError({ error, errorType: ERROR_TYPES.REQUEST_TIMEOUT }));
        break;
      }
      case HttpStatusCodes.TOO_MANY_REQUESTS: {
        if (
          error.response.data.type ===
          SERVER_ERROR_TYPES.SFDC_REQUEST_LIMIT_EXCEEDED
        ) {
          yield put(
            logError({
              error,
              errorType: ERROR_TYPES.SFDC_REQUEST_LIMIT_EXCEEDED,
            })
          );
        } else {
          yield put(logError({ error, errorType: ERROR_TYPES.GENERIC }));
        }
        break;
      }
      case null: {
        yield put(logError({ error, errorType: ERROR_TYPES.NETWORK_ERROR }));
        break;
      }
      default: {
        yield put(
          logError({ error, errorType: ERROR_TYPES.UNHANDLED_HTTP_ERROR })
        );
      }
    }
  } else {
    yield put(logError({ error, errorType: ERROR_TYPES.GENERIC }));
  }
}

function* rootSagaErrorHandler(error) {
  yield* sagaErrorHandler({
    error,
    routeSagaErrorHandlerName: 'rootSagaErrorHandler',
    defaultSagaErrorHandler,
  });
}

export default rootSagaErrorHandler;
