import { Template } from '@groove/api/gateway/v1/template';
import { TemplateFolder } from '@groove/api/services/v1/templateFolders';

export default function findTemplatesByNameOrSubject(
  folder: TemplateFolder,
  searchTerm: string,
): Template[] {
  return folder.templates.filter(
    template =>
      template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      template.subject.toLowerCase().includes(searchTerm.toLowerCase()),
  );
}
