import { TemplateType, Template } from './template';
import getClient from './getClient';

export type ApiRecentTemplates = {
  data: Template[];
};

export const recentTemplatesGet = (
  templateType: TemplateType,
  templateSubType = '',
): Promise<ApiRecentTemplates> => {
  return getClient()
    .post('recent_templates/get', {
      json: {
        variables: {
          template_type: templateType,
          template_subtype: templateSubType,
        },
      },
    })
    .json();
};

export const recentTemplatesSet = (templateId: number): Promise<void> => {
  return getClient()
    .post('recent_templates/set', {
      json: {
        variables: { template_id: templateId },
      },
    })
    .json();
};
