import {
  all,
  call,
  delay,
  fork,
  takeEvery,
  put,
  take,
} from 'redux-saga/effects';
import * as batchJobHTTPClient from 'GrooveHTTPClient/batchJob';
import {
  actionTypes,
  updateJobProgress,
  deleteJob,
  jobFinished,
} from 'Modules/Shared/actions/batchJobs';

// -------------- Exports --------------

export function* watchJob(watchedJobId) {
  while (true) {
    const {
      payload: { id, batchStatus },
    } = yield take(actionTypes.JOB_FINISHED);

    if (watchedJobId === id) {
      return batchStatus;
    }
  }
}

// -------------- Handlers --------------

function* processJob({ payload: { id } }) {
  while (true) {
    const batchStatusResponse = yield call(batchJobHTTPClient.status, id);
    const batchStatus = batchStatusResponse.data;
    const { total, pending } = batchStatus;
    const completed = total - pending;
    const newProgress = Math.floor((completed / total) * 100);
    yield put(updateJobProgress({ id, progress: newProgress }));

    if (batchStatus.isComplete) {
      // Yield delay to let animations finish
      yield delay(1000); // TODO pull from transition constant?

      yield put(jobFinished({ id, batchStatus }));
      yield put(deleteJob({ id }));

      return;
    }

    // yield delay for poll
    yield delay(250);
  }
}

// -------------- Watchers --------------

function* watchAddJob() {
  yield takeEvery(actionTypes.ADD_JOB, processJob);
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([fork(watchAddJob)]);
}
