/* eslint-disable prefer-destructuring */
import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { UserManager } from 'oidc-client';

import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { logErrorToSentry } from 'Modules/Shared/sagas/errors';
import { history } from 'Utils/history';

const emptySettings = {};
const manager = new UserManager(emptySettings);

export function* redirectHandler(): SagaIterator {
  try {
    let passedData = window.location.href.split('?')[1];

    if (!passedData) {
      passedData = window.location.href.split('#')[1];
    }
    manager.signinPopupCallback(passedData);
    yield delay(2000);
    yield call(history.push, '');
  } catch (e) {
    yield call(history.push, '');
    logErrorToSentry(e);
    yield put(
      pushSnackbarMessage({
        message:
          'The was some trouble redirecting you to your intended page.  Please try again.',
      })
    );
  }
}
