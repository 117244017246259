import { fromJS } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/productUsage';

const initialState = fromJS({
  flow: {
    actions: {
      quota: 0,
      used: 0,
      resetData: '',
      resetInterval: '',
    },
  },
  dialer: {
    minutes: {
      quota: 0,
      used: 0,
    },
  },
});

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SET_PRODUCT_USAGE: {
      return state.merge(fromJS(action.payload));
    }

    default:
      return state;
  }
}
