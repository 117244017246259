import { List, Record } from 'immutable';

const defaults = {
  id: null,
  subject: null,
  primaryRecipient: null,
  lastEventAt: null,
  completedAt: null,
  repliedAt: null,
  mutedAt: null,
  events: List(),
  recipients: null,
};

export default class Email extends Record(defaults) {
  getOpensCount() {
    return this.events.filter(event => event.isOpen()).size;
  }

  isUnread() {
    return this.events.some(event => !event.readByUser);
  }
}
