import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SHARED: {
    USERS: {
      HEALTH: {
        FIX_HEALTH_ITEM: standardActions(),
        ...keyMirror([
          'SET_ITEM_HEALTH',
          'SET_LAST_CHECKED_AT',
          'REFRESH_SFDC_META',
        ]),
      },
    },
  },
}).SHARED.USERS.HEALTH;

export const beginFixingHealthItem = ({ key }) => ({
  type: actionTypes.FIX_HEALTH_ITEM.BEGIN,
  payload: { key },
});

export const failedFixingHealthItem = ({ key }) => ({
  type: actionTypes.FIX_HEALTH_ITEM.FAILURE,
  payload: { key },
});

export const successfullyRanHealthFix = ({ key, isFixed }) => ({
  type: actionTypes.FIX_HEALTH_ITEM.SUCCESS,
  payload: { key, isFixed },
});

export const refreshSfdcMeta = () => ({
  type: actionTypes.REFRESH_SFDC_META,
});

export function setItemHealth({ status, key }) {
  return {
    type: actionTypes.SET_ITEM_HEALTH,
    payload: { status, key },
  };
}

export function setLastCheckedAt({ dateTime }) {
  return {
    type: actionTypes.SET_LAST_CHECKED_AT,
    payload: { dateTime },
  };
}
