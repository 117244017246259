import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { SOURCES } from 'Modules/Actions/constants';
import { Action } from '@groove/api/gateway/v1/actions';
import { TableText } from '@clari/iris-table';

const formatTime = (timezone: string) =>
  moment.tz(timezone).format('hh:mm A, z');

const Timezone = ({ action }: { action: Action }) => {
  const { tzid: timezone } = action?.who || {};
  const { source } = action;
  const time = timezone ? formatTime(timezone) : 'Unknown';
  const [localTime, setLocalTime] = useState(time);
  const displayTime = source === SOURCES.FLOW ? localTime : 'N/A';

  useEffect(() => {
    setLocalTime(time);
    let interval = null;
    if (timezone) {
      interval = setInterval(() => {
        setLocalTime(formatTime(timezone));
      }, 30 * 1000);
    }
    return () => clearInterval(interval);
  }, [time, timezone]);

  return <TableText>{displayTime}</TableText>;
};

export default Timezone;
