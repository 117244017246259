import React, { FC } from 'react';

type SearchZeroStateIconProps = {
  className: string;
};

/* eslint max-len: ["error", { "code": 1000 }] */
const SearchZeroStateIcon: FC<SearchZeroStateIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="233"
      height="145"
      fill="none"
      viewBox="0 0 233 145"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipRule="evenodd">
        <path
          fillRule="evenodd"
          d="M208.281 36.647H7.718c-3.159 0-5.718-2.58-5.718-5.76V8.76C2 5.58 4.56 3 7.718 3H208.28C211.439 3 214 5.58 214 8.76v22.127c0 3.18-2.561 5.76-5.719 5.76z"
          fill="#b8eced"
        />
        <path
          d="M206.291 133.578H9.709c-4.24 0-7.71-3.494-7.71-7.765V10.637c0-4.27 3.47-7.765 7.71-7.765h196.582c4.24 0 7.709 3.494 7.709 7.765v115.176c0 4.271-3.469 7.765-7.709 7.765z"
          stroke="#04294c"
          strokeWidth="4"
        />
        <path fillRule="evenodd" d="M21 119h60V52H21v67z" fill="#b8eced" />
        <path
          fillRule="evenodd"
          d="M65.638 77.93c0 7.862-6.328 14.236-14.133 14.236-7.806 0-14.134-6.374-14.134-14.235 0-7.862 6.328-14.236 14.134-14.236 7.805 0 14.133 6.374 14.133 14.236z"
          fill="#04294c"
        />
      </g>
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x="29"
        y="97"
        width="45"
        height="22"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29 97h45v22H29V97z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.4 130.225c-12.35 0-22.4-1.949-22.4-10.882s10.05-21.47 22.4-21.47c12.349 0 22.36 12.537 22.36 21.47 0 8.933-10.011 10.882-22.36 10.882z"
          fill="#04294c"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.29 66.702h-79.821c-3.894 0-7.08-3.21-7.08-7.133s3.186-7.133 7.08-7.133h79.821c3.894 0 7.081 3.21 7.081 7.133s-3.187 7.133-7.081 7.133zM168.271 94.942h-61.415c-1.908 0-3.467-1.572-3.467-3.493v-.71c0-1.921 1.559-3.493 3.467-3.493h61.415c1.907 0 3.467 1.572 3.467 3.494v.71c0 1.92-1.56 3.492-3.467 3.492zM194.234 94.21h-16.51c-1.725 0-3.136-1.423-3.136-3.162v-.642c0-1.736 1.411-3.159 3.136-3.159h16.51c1.725 0 3.137 1.423 3.137 3.16v.641c0 1.74-1.412 3.162-3.137 3.162zM123.035 118.577h-16.51c-1.725 0-3.136-1.423-3.136-3.161v-.642c0-1.736 1.411-3.159 3.136-3.159h16.51c1.725 0 3.137 1.423 3.137 3.159v.642c0 1.738-1.412 3.161-3.137 3.161zM150.09 118.577h-16.508c-1.727 0-3.138-1.423-3.138-3.161v-.642c0-1.736 1.411-3.159 3.138-3.159h16.508c1.726 0 3.138 1.423 3.138 3.159v.642c0 1.738-1.412 3.161-3.138 3.161zM168.271 108.134h-61.415c-1.908 0-3.467-1.572-3.467-3.493v-.709c0-1.922 1.559-3.494 3.467-3.494h61.415c1.907 0 3.467 1.572 3.467 3.494v.709c0 1.921-1.56 3.493-3.467 3.493z"
        fill="#b8eced"
      />
      <path d="M1.644 36.874h213.712" stroke="#04294c" strokeWidth="4" />
      <circle cx="185" cy="98" r="21" fill="#b8eced" />
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.203 10.626c1.848 0 3.46 1.051 4.319 2.613a5.871 5.871 0 012.442-.53c3.333 0 6.036 2.782 6.036 6.214 0 3.432-2.703 6.214-6.036 6.214-.408 0-.805-.041-1.189-.12-.757 1.376-2.198 2.306-3.852 2.306a4.314 4.314 0 01-1.93-.454c-.767 1.84-2.553 3.131-4.635 3.131-2.167 0-4.015-1.4-4.724-3.363-.31.067-.631.102-.96.102-2.582 0-4.674-2.157-4.674-4.819 0-1.783.94-3.34 2.337-4.174a5.614 5.614 0 01-.448-2.204c0-3.06 2.435-5.542 5.439-5.542 1.763 0 3.33.855 4.324 2.181.903-.96 2.16-1.555 3.551-1.555z"
          fill="#04294c"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M179.176 73.879A24.45 24.45 0 01185.75 73c2.273 0 4.465.293 6.574.879a24.438 24.438 0 015.906 2.496 25.021 25.021 0 015.01 3.885 25.055 25.055 0 013.885 5.01 24.461 24.461 0 012.496 5.906c.586 2.11.879 4.3.879 6.574 0 2.93-.492 5.736-1.477 8.42a25.267 25.267 0 01-4.253 7.4l27.562 27.598c.445.445.668.973.668 1.582 0 .609-.223 1.137-.668 1.582-.445.445-.973.668-1.582.668-.609 0-1.137-.223-1.582-.668L201.57 116.77a25.267 25.267 0 01-7.4 4.253 24.243 24.243 0 01-8.42 1.477c-2.273 0-4.465-.293-6.574-.879a24.446 24.446 0 01-5.906-2.496 25.011 25.011 0 01-5.01-3.885 25.011 25.011 0 01-3.885-5.01 24.446 24.446 0 01-2.496-5.906A24.446 24.446 0 01161 97.75c0-2.273.293-4.465.879-6.574a24.461 24.461 0 012.496-5.907 25.055 25.055 0 013.885-5.01 25.021 25.021 0 015.01-3.884 24.438 24.438 0 015.906-2.496zm-.954 42.541c2.431 1.053 5.023 1.58 7.778 1.58s5.347-.527 7.778-1.58c2.43-1.053 4.548-2.482 6.354-4.288s3.235-3.924 4.288-6.354c1.053-2.431 1.58-5.023 1.58-7.778s-.527-5.347-1.58-7.778c-1.053-2.43-2.482-4.548-4.288-6.354-1.806-1.805-3.924-3.235-6.354-4.288C191.347 78.527 188.755 78 186 78s-5.347.527-7.778 1.58c-2.43 1.053-4.548 2.483-6.354 4.288-1.806 1.806-3.235 3.924-4.288 6.354-1.053 2.43-1.58 5.023-1.58 7.778s.527 5.347 1.58 7.778c1.053 2.43 2.482 4.548 4.288 6.354s3.924 3.235 6.354 4.288z"
        fill="#04294c"
      />
    </svg>
  );
};

export default SearchZeroStateIcon;
