import React, { FC, useState } from 'react';
import { Input, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SeismicLogo from '../config/images/seismicLogo';

import { TenantInputProps } from './Seismic';
import SeismicButton from './SeismicButton';

const useStyles = makeStyles({
  container: {
    width: 602,
    height: 460,
  },
  innerContainer: {
    textAlign: 'center',
    padding: 50,
  },
  titleContainer: {
    fontSize: '2rem',
    paddingTop: 10,
    paddingBottom: 55,
  },
  subTitleContainer: {
    paddingBottom: 30,
  },
  subTitle: {
    fontSize: '1.375rem',
    lineHeight: '1.375rem',
  },
  inputLabel: {
    textAlign: 'left',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
  },
  inputContainer: {
    borderBottom: '1px solid black',
    display: 'flex',
    fontSize: '0.875rem',
  },
  inputRoot: {
    flex: 1,
  },
  input: {
    fontSize: '0.875rem',
    height: '1.25rem',
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: '20px',
  },
  inputSuffix: {
    fontSize: '0.875rem',
    height: '1.25rem',
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  buttonContainer: {
    paddingTop: 45,
    margin: 'auto',
  },
});

const TenantInput: FC<TenantInputProps> = ({ onNext }) => {
  const {
    container,
    innerContainer,
    subTitleContainer,
    subTitle,
    titleContainer,
    inputLabel,
    inputContainer,
    inputRoot,
    input,
    inputSuffix,
    buttonContainer,
  } = useStyles();
  const [inputValue, setInputValue] = useState('');
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputValue(e.target.value);
  const onNextHandler = () => onNext(inputValue);
  const returnHandler = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
      onNextHandler();
    }
  };
  return (
    <div className={container}>
      <div className={innerContainer}>
        <div className={titleContainer}>
          <SeismicLogo />
        </div>
        <div className={subTitleContainer}>
          <Typography classes={{ root: subTitle }}>Welcome</Typography>
        </div>
        <Typography classes={{ root: inputLabel }}>
          Enter the tenant to continue
        </Typography>
        <div className={inputContainer}>
          <Input
            classes={{ root: inputRoot, input }}
            disableUnderline
            onChange={onChangeHandler}
            value={inputValue}
            onKeyDown={returnHandler}
          />
          <Typography classes={{ root: inputSuffix }}>.seismic.com</Typography>
        </div>
        <div className={buttonContainer}>
          <SeismicButton onClick={onNextHandler} disabled={!inputValue}>
            Next
          </SeismicButton>
        </div>
      </div>
    </div>
  );
};

export default TenantInput;
