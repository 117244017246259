import { Dictionary } from '@fullcalendar/react';
import { Event } from '@groove/api/services/v1/scheduler';
import { fluentTheme } from '@groove/ui/theme';
import {
  addMinutes,
  areIntervalsOverlapping,
  Interval,
  parseISO,
} from 'date-fns';
import create from 'zustand';

import { ParsedEvent } from '../Calendar/Calendar';

export type TimeSlot = {
  start: Date;
  end: Date;
  color?: string;
  extendedProps?: Dictionary;
};

export type Store = {
  timeSlots: Map<string, TimeSlot>;
  validTimeSlots: Map<string, TimeSlot>;
  insertTimeSlots: (
    start: Date,
    end: Date,
    timeSlotDuration: number,
    events: ParsedEvent[],
  ) => void;
  removeTimeSlot: (start: Date) => void;
  clearTimeSlots: () => void;
  recalculateValidTimeSlots: (events: Event[]) => void;
};

const useStore = create<Store>((set, get) => ({
  timeSlots: new Map(),
  validTimeSlots: new Map(),
  insertTimeSlots: (start, end, timeSlotDuration, events) => {
    const timeSlots = new Map<string, TimeSlot>();
    const validTimeSlots = new Map<string, TimeSlot>();

    let timeSlotStartTime = start;
    let minimumEndTime = end;
    let timeSlotEndTime = addMinutes(start, timeSlotDuration);
    if (timeSlotEndTime > end) {
      minimumEndTime = timeSlotEndTime;
    }

    while (
      timeSlotStartTime < minimumEndTime &&
      timeSlotEndTime <= minimumEndTime
    ) {
      const timeSlot: TimeSlot = {
        start: timeSlotStartTime,
        end: timeSlotEndTime,
        color: fluentTheme.palette?.themePrimary,
        extendedProps: {
          isTimeSlot: true,
        },
      };
      timeSlots.set(timeSlot.start.toString(), timeSlot);
      if (
        !events.some(
          event =>
            areIntervalsOverlapping(timeSlot, event as Interval) &&
            event.transparency !== 'transparent',
        )
      ) {
        validTimeSlots.set(timeSlot.start.toString(), timeSlot);
      }

      timeSlotStartTime = timeSlotEndTime;
      timeSlotEndTime = addMinutes(timeSlotStartTime, timeSlotDuration);
    }

    get().timeSlots.forEach((slot, key) => timeSlots.set(key, slot));

    get().validTimeSlots.forEach((slot, key) => validTimeSlots.set(key, slot));

    set({ timeSlots, validTimeSlots });
  },
  removeTimeSlot: start => {
    const { timeSlots, validTimeSlots } = get();
    timeSlots.delete(start.toString());
    validTimeSlots.delete(start.toString());
    set({
      timeSlots: new Map([...timeSlots]),
      validTimeSlots: new Map([...validTimeSlots]),
    });
  },

  recalculateValidTimeSlots: events => {
    const { timeSlots } = get();

    const userEvents = events?.map(
      event =>
        ({
          ...event,
          start: parseISO(event.start),
          end: parseISO(event.end),
        } as ParsedEvent),
    );

    get().clearTimeSlots();

    Array.from(timeSlots.values()).forEach(timeSlot => {
      const duration =
        (timeSlot.end.getTime() - timeSlot.start.getTime()) / 60000;
      get().insertTimeSlots(timeSlot.start, timeSlot.end, duration, userEvents);
    });
  },

  clearTimeSlots: () => {
    set({ timeSlots: new Map(), validTimeSlots: new Map() });
  },
}));

export default useStore;
