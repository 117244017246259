import { createActions, keyMirror } from '@groove-labs/action-utils';

export const actionTypes = createActions({
  APP: {
    ...keyMirror(['ADD', 'DISMISS', 'SET']),
  },
}).APP;

export const add = payload => ({
  type: actionTypes.ADD,
  payload,
});

export const dismiss = payload => ({
  type: actionTypes.DISMISS,
  payload,
});

export const set = payload => ({
  type: actionTypes.SET,
  payload,
});
