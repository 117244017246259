import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import ClariTheme from 'Utils/theme/ClariTheme';

export default class FlowCount extends Component {
  static propTypes = {
    used: PropTypes.number.isRequired,
    quota: PropTypes.number.isRequired,
  };

  getStyle() {
    const { used, quota } = this.props;
    const percentageUsed = used / quota;
    let color = '';

    if (percentageUsed > 0.67) {
      color = ClariTheme.palette.error.main;
    } else if (percentageUsed > 0.34) {
      color = ClariTheme.palette.warning.main;
    } else {
      color = ClariTheme.palette.text.primary;
    }

    return {
      color,
      fontWeight: 'bold',
    };
  }

  render() {
    const { used, quota } = this.props;
    return quota !== -1 ? (
      <span>
        <span style={this.getStyle()}>{numeral(used).format('0,0')}</span>
        {' / '}
        {numeral(quota).format('0,0')}
      </span>
    ) : (
      '-'
    );
  }
}
