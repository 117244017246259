import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const SettingsIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, ' fill-current')}
  >
    <path d="M0.910747 6.38266C1.28004 5.24053 1.88839 4.19213 2.69109 3.30364C2.82683 3.15339 3.03978 3.09984 3.23044 3.16802L5.14873 3.85392C5.6688 4.03977 6.24107 3.76883 6.42692 3.24875C6.4452 3.19762 6.45927 3.14507 6.469 3.09173L6.83446 1.08573C6.8708 0.886273 7.02398 0.728505 7.22227 0.686297C7.80246 0.562802 8.39734 0.5 9.00001 0.5C9.60232 0.5 10.1968 0.562725 10.7767 0.686072C10.9749 0.728241 11.1281 0.88591 11.1645 1.08529L11.531 3.09165C11.6301 3.63497 12.1509 3.9951 12.6942 3.89601C12.7476 3.88627 12.8002 3.87219 12.8512 3.85395L14.7696 3.16802C14.9602 3.09984 15.1732 3.15339 15.3089 3.30364C16.1116 4.19213 16.72 5.24053 17.0893 6.38266C17.1516 6.57534 17.0915 6.78658 16.9371 6.91764L15.3823 8.23773C14.9613 8.5952 14.9098 9.22627 15.2673 9.64726C15.3024 9.68865 15.3409 9.72712 15.3823 9.76225L16.9371 11.0824C17.0915 11.2134 17.1516 11.4247 17.0893 11.6173C16.72 12.7595 16.1116 13.8079 15.3089 14.6964C15.1732 14.8466 14.9602 14.9002 14.7696 14.832L12.8513 14.1461C12.3312 13.9602 11.759 14.2312 11.5731 14.7512C11.5548 14.8024 11.5408 14.8549 11.531 14.9085L11.1645 16.9147C11.1281 17.1141 10.9749 17.2718 10.7767 17.3139C10.1968 17.4373 9.60232 17.5 9.00001 17.5C8.39734 17.5 7.80246 17.4372 7.22227 17.3137C7.02398 17.2715 6.8708 17.1137 6.83446 16.9143L6.46902 14.9084C6.36993 14.365 5.84916 14.0049 5.30583 14.104C5.25241 14.1137 5.19987 14.1278 5.14881 14.1461L3.23044 14.832C3.03978 14.9002 2.82683 14.8466 2.69109 14.6964C1.88839 13.8079 1.28004 12.7595 0.910747 11.6173C0.848447 11.4247 0.908519 11.2134 1.06289 11.0824L2.61773 9.76227C3.03872 9.4048 3.09021 8.77373 2.73274 8.35274C2.69759 8.31135 2.65913 8.27288 2.61775 8.23775L1.06289 6.91764C0.908519 6.78658 0.848447 6.57534 0.910747 6.38266ZM7.00001 9C7.00001 10.1046 7.89544 11 9.00001 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9.00001 7C7.89544 7 7.00001 7.89543 7.00001 9Z" />
  </svg>
);
