import { keyMirror } from '@groove-labs/action-utils';

export const ACTIVITY_LOGS_DATA_KEY_PATH = ['activityLog'];

export const STATUS = {
  ...keyMirror(['failed', 'complete']),
};

export const ANALYTICS_FIELDS = {
  FAILED: STATUS.failed,
  COMPLETE: STATUS.complete,
  TOTAL: 'total',
  TYPE_COUNTS: 'event_type_counts',
};

export const EVENT_TYPES = {
  auto_import: { label: 'Auto Import' },
  automated_action: { label: 'Automated Action' },
  email_log_gmail: {
    label: 'Email Logged from Gmail',
    condition: 'isGoogleOrg',
  },
  email_log_microsoft: {
    label: 'Email Logged from Outlook',
    condition: 'isMicrosoftOrg',
  },
  calendar_event_log_microsoft: {
    label: 'Event Logged from Outlook',
    condition: 'isMicrosoftOrg',
  },
  email_send_microsoft: {
    label: 'Email Sent from Outlook',
    condition: 'isMicrosoftOrg',
  },
  email_log_flow: { label: 'Email Logged from Flow' },
  call_log: { label: 'Call Logged' },
  transfer_flows: { label: 'Flow Transfers' },
};

export const EVENT_LOGS_DATA_KEY_PATH = [
  'settings',
  'manageMyOrg',
  'eventLogs',
];

export const IS_DETAILS_DIALOG_OPEN_KEY_PATH = [
  'eventLogs',
  'isDetailsDialogOpen',
];

export const DRILLDOWN_UI_KEY_PATH = ['manageMyOrg', 'drilldown'];

export const LOG_LIMIT = 15;

export const FILTER_SEARCH_GROUP_ID = 'filterSearch';

export const FILTER_SEARCH_FIELD_ID = 'search';
export const TABLE_PAGE_NUMBER_UI_KEY_PATH = ['eventLogs', 'page'];
export const SEARCH_INPUT_DEBOUNCE_TIME = 500;

export const METADATA_DATATYPE_KEY = 'datatype';
export const USER_DATATYPE = 'user';
export const SFDC_USER_ID = 'sfdcUserId';
export const USERS_TABLE_COLUMNS = [
  { key: 'name', label: 'User Name' },
  { key: SFDC_USER_ID, label: 'SFDC ID' },
];
