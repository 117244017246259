import * as Sentry from '@sentry/browser';
import querystring from 'querystring';
import { isUndefined, omitBy } from 'lodash-es';
import {
  setPageScroll,
  startChange,
  update,
} from 'Modules/Shared/actions/location';
import { location as locationSelector } from 'Modules/Shared/selectors/location';
import { shouldReloadFromVersionUpdate } from 'Modules/App/selectors';
import { router } from '@groove-labs/redux-saga-router';
import { call, fork, put, select, all } from 'redux-saga/effects';
import { history, getPathName } from 'Utils/history';
import { actions } from '@groove-labs/groove-ui';
import appStore from 'Modules/App/Store/useStore';
import { routeConfiguration } from './routeConfiguration';
import Route from './Route';
import RouteMatcher from './RouteMatcher';

const { reset: resetUi } = actions.ui;

// Checks if the application version has changed since the
// last route change and does a hard reload if necessary.
// the system should not reload while a moboUser is set - otherwise the user
// will lose their current work on behalf of the moboUser
function* checkForAndHandleVersionUpdate() {
  const versionUpdate = yield select(shouldReloadFromVersionUpdate);
  const moboUser = appStore.getState().moboUser;
  const shouldReload = !!versionUpdate && !moboUser;

  if (shouldReload) {
    window.location.reload(true);
  }
}

// Build the route structure and instantiate the RouteMatcher with it
export const ROUTES = Route.assembleRoutes(routeConfiguration);
export const routeMatcher = new RouteMatcher(ROUTES);

const options = {
  beforeRouteChange: function* beforeRouteChange(routeParams) {
    // Select the previous Route
    const previousLocation = yield select(locationSelector);
    const previousFullPath = previousLocation.get('fullPath');

    // Notify that resolution has begun. The route will not be resolved until the upstarts have all
    // run, and the root saga has been forked.`
    yield put(startChange());

    // Checks if the application version has changed since the
    // last route change and does a hard reload if necessary.
    yield* checkForAndHandleVersionUpdate();

    yield put(resetUi());
    // Select the next Route
    const fullPath = getPathName();

    if (process.env.NODE_ENV === 'development') {
      document.title = `Groove - ${fullPath}`;
    }

    const nextRoute = routeMatcher.find(fullPath);
    const appRoot = document.getElementById('app-root');

    let previousRoute = null;
    if (previousFullPath) {
      previousRoute = routeMatcher.find(previousFullPath);
      if (appRoot) {
        yield put(
          setPageScroll({
            routeName: previousRoute.routeName,
            scroll: appRoot.scrollTop,
          })
        );
      }
    }

    const rootPath = fullPath.split('/')[1].toLowerCase();
    const searchParams = querystring.parse(window.location.search.slice(1));
    const { activeTab, next } = searchParams;

    if (nextRoute.routeName !== 'loginCallback' && next) {
      yield call(history.push, next);
      return;
    }

    // Dispatch location change
    const nextLocationData = omitBy(
      {
        routeName: nextRoute.routeName,
        previousRouteNames: previousLocation
          .get('previousRouteNames')
          .push(previousLocation.get('routeName'))
          .slice(-3),
        previousPaths: previousLocation
          .get('previousPaths')
          .push(previousLocation.get('fullPath'))
          .slice(-3),
        fullPath,
        rootPath,
        searchParams,
        activeTab,
        routeParams,
      },
      isUndefined
    );
    yield put(update(nextLocationData));

    Sentry.configureScope(scope => {
      scope.setTag('routeName', nextRoute.routeName);
    });

    yield call(
      nextRoute.buildBeforeRouteChangeHandler(previousRoute, nextRoute)
    );

    // reset the previous route's reducer
    if (previousRoute && Route.moduleChanged(previousRoute, nextRoute)) {
      const actionPrefixesToReset = [];
      if (previousRoute.parent) {
        actionPrefixesToReset.push(previousRoute.parent.actionPrefix);
        previousRoute.parent.children.forEach(child =>
          actionPrefixesToReset.push(child.actionPrefix)
        );
      } else {
        actionPrefixesToReset.push(previousRoute.actionPrefix);
      }

      yield all(
        // eslint-disable-next-line consistent-return
        actionPrefixesToReset.map(function* resetDispatch(prefix) {
          if (prefix) {
            const resetActionType = `${previousRoute.actionPrefix}/RESET_REDUCER`;
            return yield put({ type: resetActionType });
          }
        })
      );
    }
  },
};

export default function* root() {
  yield fork(
    router,
    history,
    ROUTES.reduce((acc, route) => {
      acc[route.pattern] = route.buildRouteHandler();
      return acc;
    }, {}),
    options
  );
}
