import { FC, useRef, useState } from 'react';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { debounce } from 'lodash-es';
import { Spinner } from '@fluentui/react';

import calcTextWidth from '../helpers/calcTextWidth';

import {
  CloseIcon,
  DoubleChevronDown,
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from './BoogieIcon';
import Text from './Text';

export type MessageLevel = 'info' | 'warning' | 'error' | 'success' | 'loading';

export type Message = {
  level: MessageLevel;
  id: string;
  message: string | JSX.Element;

  /**
   * @deprecated use buttonComponent
   */
  buttonClick?: () => void;

  /**
   * @deprecated use buttonComponent
   */
  buttonLabel?: string;

  hide?: boolean;

  noCloseButton?: boolean;

  /**
   * The built in logic to always truncate the message is needed for Action Compose.
   */
  alwaysExpanded?: boolean;

  buttonComponent?: JSX.Element;
};

export type MessageParams = {
  messageObject: Message;
};

export const SingleMessage: FC<MessageParams> = ({ messageObject }) => {
  const { message, level, noCloseButton, alwaysExpanded, buttonComponent } =
    messageObject;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  let colorClassName = 'bg-info/50 text-info/900';
  let icon = <InfoIcon className="h-[20px]" />;
  if (level === 'error') {
    colorClassName = 'bg-error/50 text-error/900';
    icon = <ErrorIcon className="h-[20px]" />;
  }
  if (level === 'warning') {
    colorClassName = 'bg-warning/50 text-warning/900';
    icon = <WarningIcon className="h-[20px]" />;
  }
  if (level === 'success') {
    colorClassName = 'bg-success/50 text-success/900';
    icon = <SuccessIcon className="h-[20px]" />;
  }
  if (level === 'loading') {
    icon = <Spinner />;
  }
  const textWidth = calcTextWidth(message, ref, 'groove', 16);

  const isSingleLine = textWidth < 656;

  return (
    <div
      className={cx(
        'w-full flex self-center relative flex-row items-center px-[8px] font-groove text-metadata min-w-0 box-border',
        colorClassName,
      )}
      ref={ref}
    >
      {icon}
      <Text
        className={cx(
          'flex-1 text-metadata p-[8px]',
          !alwaysExpanded && !isOpen && 'truncate',
        )}
      >
        {message}
      </Text>
      {!alwaysExpanded && !isSingleLine && (
        <div
          className="cursor-pointer flex items-center w-[20px] h-[20px] hover:opacity-60"
          onClick={() => debounce(setIsOpen)(!isOpen)}
          role="button"
          tabIndex={0}
          onKeyDown={event =>
            event.key === 'Enter' && debounce(setIsOpen)(!isOpen)
          }
        >
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: isOpen ? -180 : 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            className="h-[16px]"
          >
            <DoubleChevronDown className="h-[16px]" />
          </motion.div>
        </div>
      )}
      {!noCloseButton && (
        <div
          className="cursor-pointer flex items-center w-[20px] h-[20px] hover:opacity-60 ml-[8px]"
          onClick={() => debounce(setIsOpen)(!isOpen)}
          role="button"
          tabIndex={0}
          onKeyDown={event =>
            event.key === 'Enter' && debounce(setIsOpen)(!isOpen)
          }
        >
          <CloseIcon className="h-[16px]" />
        </div>
      )}
      {buttonComponent && buttonComponent}
    </div>
  );
};

export type MessageBarParams = {
  messages: Message[];
};

const MessageBar: FC<MessageBarParams> = ({ messages }) => {
  const [isHover, setIsHover] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const filteredMessages = messages?.filter(message => !message.hide) || [];
  if (filteredMessages.length === 0) return null;

  return (
    <div
      className="relative flex flex-col w-full bg-white"
      onMouseOver={() => {
        setIsHover(true);
        setIsAnimating(true);
      }}
      onFocus={() => {
        setIsHover(true);
        setIsAnimating(true);
      }}
      onMouseLeave={() => setIsHover(false)}
      onBlur={() => setIsHover(false)}
    >
      <SingleMessage
        messageObject={filteredMessages[0]}
        key={filteredMessages[0].id}
      />
      {isAnimating && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isHover ? 1 : 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className="absolute w-full flex flex-col top-0 z-[1000]"
          onAnimationComplete={() => !isHover && setIsAnimating(false)}
        >
          {filteredMessages.map((message, index) => (
            <SingleMessage messageObject={message} key={message.id} />
          ))}
        </motion.div>
      )}
    </div>
  );
};

export default MessageBar;
