import keyMirror from 'Utils/key-mirror';

export const actionTypes = keyMirror(
  {
    CLEAR_CALL_ACTIVITY_CACHE: null,
    FETCH_SETTINGS: null,
    LOAD_SETTINGS: null,
    SET_CALL_ACTIVITY_CACHE: null,
    NON_FLOW_CLICK_TO_CALL: null,
    SET_CALL_IN_PROGRESS: null,
    DIALER_SETTING_UPDATED: null,
    SET_VISIBILITY_STATUS: null,
    SET_DIALER_OPENED: null,
  },
  'SHARED',
  'DIALER'
);

/**
 * @return {object}
 */
export function clearCallActivityCache() {
  return {
    type: actionTypes.CLEAR_CALL_ACTIVITY_CACHE,
  };
}

/**
 * Accepts an object which will be loaded into the existing UI state
 *
 * @param {object} payload
 *
 * @return {object}
 */
export function loadSettings(payload) {
  return {
    type: actionTypes.LOAD_SETTINGS,
    payload,
  };
}

export function fetchSettings() {
  return {
    type: actionTypes.FETCH_SETTINGS,
  };
}

/**
 * Accepts an object which will be loaded into the existing UI state
 *
 * @param {number} personId
 * @param {object} value
 *
 * @return {object}
 */
export function setCallActivityCache(personId, value) {
  return {
    type: actionTypes.SET_CALL_ACTIVITY_CACHE,
    payload: { key: personId, value },
  };
}

export function setCallInProgress(payload) {
  return {
    type: actionTypes.SET_CALL_IN_PROGRESS,
    payload,
  };
}

/**
 *
 * @param {string} sfdcId
 * @param {string} phoneNumber
 * @return {object}
 */
export function nonFlowClickToCall({ sfdcId, phoneNumber }) {
  return {
    type: actionTypes.NON_FLOW_CLICK_TO_CALL,
    payload: { sfdcId, phoneNumber },
  };
}

/**
 *
 * @param {string} settingType
 * @return {object}
 */
export function notifyDialerOfSettingUpdate({ message, settingType, value }) {
  return {
    type: actionTypes.DIALER_SETTING_UPDATED,
    payload: {
      message,
      settingType,
      value,
    },
  };
}

export function setDialerOpened(isOpen) {
  return {
    type: actionTypes.SET_DIALER_OPENED,
    payload: isOpen,
  };
}
