import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Device, Call } from '@twilio/voice-sdk';

import getToken from '../api/twilio/token';
import outgoing from '../assets/outgoing.mp3';
import incoming from '../assets/incoming.mp3';
import disconnect from '../assets/disconnect.mp3';
import { FETCHED_TWILIO_TOKEN_TIME } from '../constants';

import useStore from './useStore';

const useTwilioDevice = ({ isDialerInAction = false } = {}): {
  device: Device | null;
} => {
  const { data: tokenData } = useQuery('token', getToken);
  const device = useStore(store => store.device);
  const queryClient = useQueryClient();

  useEffect(() => {
    const { device, setDevice, setIncomingConnection } = useStore.getState();

    const newDeviceParams: Device.Options = {
      logLevel: 1,
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
      enableImprovedSignalingErrorPrecision: true,
    };

    // For some reason dialer in action is crashing when these sounds are being injected for new device
    if (!isDialerInAction)
      newDeviceParams.sounds = {
        outgoing,
        incoming,
        disconnect,
      };

    if (tokenData?.token) {
      const newDevice = device || new Device(tokenData.token, newDeviceParams);
      const tokenDate = new Date();
      localStorage.setItem(FETCHED_TWILIO_TOKEN_TIME, tokenDate.toISOString());
      setDevice(newDevice);
      if (newDevice.listeners('registered')?.length < 1) {
        newDevice.on('registered', async () => {
          const inputDeviceId = localStorage.getItem('inputDeviceId');
          if (inputDeviceId) {
            try {
              await device?.audio?.setInputDevice(inputDeviceId);
            } catch {
              localStorage.removeItem('inputDeviceId');
              await device?.audio?.setInputDevice('default');
            }
          }
          try {
            await device?.audio?.speakerDevices.set(
              localStorage.getItem('speakerDeviceId') || 'default',
            );
          } catch {
            localStorage.removeItem('speakerDeviceId');
            await device?.audio?.speakerDevices.set('default');
          }

          try {
            await device?.audio?.ringtoneDevices.set(
              localStorage.getItem('ringtoneDeviceId') || 'default',
            );
          } catch {
            localStorage.removeItem('ringtoneDeviceId');
            await device?.audio?.ringtoneDevices.set('default');
          }
        });
      }

      if (newDevice.listeners('incoming')?.length < 1) {
        newDevice.on('incoming', connection => {
          setIncomingConnection(connection);
          useStore
            .getState()
            .ipcRenderer?.send('asynchronous-message', 'focus');

          connection.on('cancel', () => setIncomingConnection(null));
        });
      }

      if (newDevice.listeners('offline')?.length < 1) {
        newDevice.on('offline', () => {
          queryClient.invalidateQueries('token');
        });
      }

      if (newDevice.listeners('unregistered')?.length < 1) {
        newDevice.on('unregistered', () => {
          newDevice.register();
        });
      }

      if (newDevice.listeners('error')?.length < 1) {
        newDevice.on('error', () => {
          if (newDevice.state === 'unregistered') {
            newDevice.register();
          }
        });
      }

      if (newDevice.listeners('tokenWillExpire')?.length < 1) {
        newDevice.on('tokenWillExpire', async () => {
          queryClient.invalidateQueries('token', {
            refetchActive: false,
            refetchInactive: false,
          });
          // refetch the token
          const { token: refetchedToken } = await getToken();
          if (refetchedToken) {
            newDevice.updateToken(refetchedToken);
            const refetchedTokenDate = new Date();
            localStorage.setItem(
              FETCHED_TWILIO_TOKEN_TIME,
              refetchedTokenDate.toISOString(),
            );
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData?.token]);

  return { device };
};

export default useTwilioDevice;
