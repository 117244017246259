import { List } from 'immutable';

export const CALENDER_AVAILABILITY_GROUP_ID = 'CalendarAvailability';

export const NEW_FOOTER_ID = -1;
export const NAV_BAR_HEIGHT = 50;
export const TOP_MARGIN = -32;
export const TIME_ZONES_FIELD_ID = 'timeZones';
export const TIME_ZONES = [
  { name: '(GMT-11:00) Niue', label: 'Pacific/Niue' },
  { name: '(GMT-11:00) Pago Pago', label: 'Pacific/Pago_Pago' },
  { name: '(GMT-10:00) Hawaii Time', label: 'Pacific/Honolulu' },
  { name: '(GMT-10:00) Rarotonga', label: 'Pacific/Rarotonga' },
  { name: '(GMT-10:00) Tahiti', label: 'Pacific/Tahiti' },
  { name: '(GMT-09:30) Marquesas', label: 'Pacific/Marquesas' },
  { name: '(GMT-09:00) Alaska Time', label: 'America/Anchorage' },
  { name: '(GMT-09:00) Gambier', label: 'Pacific/Gambier' },
  { name: '(GMT-08:00) Pacific Time', label: 'America/Los_Angeles' },
  { name: '(GMT-08:00) Pacific Time - Tijuana', label: 'America/Tijuana' },
  { name: '(GMT-08:00) Pacific Time - Vancouver', label: 'America/Vancouver' },
  {
    name: '(GMT-08:00) Pacific Time - Whitehorse',
    label: 'America/Whitehorse',
  },
  { name: '(GMT-08:00) Pitcairn', label: 'Pacific/Pitcairn' },
  {
    name: '(GMT-07:00) Mountain Time - Dawson Creek',
    label: 'America/Dawson_Creek',
  },
  { name: '(GMT-07:00) Mountain Time', label: 'America/Denver' },
  { name: '(GMT-07:00) Mountain Time - Edmonton', label: 'America/Edmonton' },
  {
    name: '(GMT-07:00) Mountain Time - Hermosillo',
    label: 'America/Hermosillo',
  },
  {
    name: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
    label: 'America/Mazatlan',
  },
  { name: '(GMT-07:00) Mountain Time - Arizona', label: 'America/Phoenix' },
  {
    name: '(GMT-07:00) Mountain Time - Yellowknife',
    label: 'America/Yellowknife',
  },
  { name: '(GMT-06:00) Belize', label: 'America/Belize' },
  { name: '(GMT-06:00) Central Time', label: 'America/Chicago' },
  { name: '(GMT-06:00) Costa Rica', label: 'America/Costa_Rica' },
  { name: '(GMT-06:00) El Salvador', label: 'America/El_Salvador' },
  { name: '(GMT-06:00) Guatemala', label: 'America/Guatemala' },
  { name: '(GMT-06:00) Managua', label: 'America/Managua' },
  {
    name: '(GMT-06:00) Central Time - Mexico City',
    label: 'America/Mexico_City',
  },
  { name: '(GMT-06:00) Central Time - Regina', label: 'America/Regina' },
  {
    name: '(GMT-06:00) Central Time - Tegucigalpa',
    label: 'America/Tegucigalpa',
  },
  { name: '(GMT-06:00) Central Time - Winnipeg', label: 'America/Winnipeg' },
  { name: '(GMT-06:00) Galapagos', label: 'Pacific/Galapagos' },
  { name: '(GMT-05:00) Bogota', label: 'America/Bogota' },
  { name: '(GMT-05:00) America Cancun', label: 'America/Cancun' },
  { name: '(GMT-05:00) Cayman', label: 'America/Cayman' },
  { name: '(GMT-05:00) Guayaquil', label: 'America/Guayaquil' },
  { name: '(GMT-05:00) Havana', label: 'America/Havana' },
  { name: '(GMT-05:00) Eastern Time - Iqaluit', label: 'America/Iqaluit' },
  { name: '(GMT-05:00) Jamaica', label: 'America/Jamaica' },
  { name: '(GMT-05:00) Lima', label: 'America/Lima' },
  { name: '(GMT-05:00) Nassau', label: 'America/Nassau' },
  { name: '(GMT-05:00) Eastern Time', label: 'America/New_York' },
  { name: '(GMT-05:00) Panama', label: 'America/Panama' },
  { name: '(GMT-05:00) Port-au-Prince', label: 'America/Port-au-Prince' },
  { name: '(GMT-05:00) Rio Branco', label: 'America/Rio_Branco' },
  { name: '(GMT-05:00) Eastern Time - Toronto', label: 'America/Toronto' },
  { name: '(GMT-05:00) Easter Island', label: 'Pacific/Easter' },
  { name: '(GMT-04:30) Caracas', label: 'America/Caracas' },
  { name: '(GMT-03:00) Asuncion', label: 'America/Asuncion' },
  { name: '(GMT-04:00) Barbados', label: 'America/Barbados' },
  { name: '(GMT-04:00) Boa Vista', label: 'America/Boa_Vista' },
  { name: '(GMT-03:00) Campo Grande', label: 'America/Campo_Grande' },
  { name: '(GMT-03:00) Cuiaba', label: 'America/Cuiaba' },
  { name: '(GMT-04:00) Curacao', label: 'America/Curacao' },
  { name: '(GMT-04:00) Grand Turk', label: 'America/Grand_Turk' },
  { name: '(GMT-04:00) Guyana', label: 'America/Guyana' },
  { name: '(GMT-04:00) Atlantic Time - Halifax', label: 'America/Halifax' },
  { name: '(GMT-04:00) La Paz', label: 'America/La_Paz' },
  { name: '(GMT-04:00) Manaus', label: 'America/Manaus' },
  { name: '(GMT-04:00) Martinique', label: 'America/Martinique' },
  { name: '(GMT-04:00) Port of Spain', label: 'America/Port_of_Spain' },
  { name: '(GMT-04:00) Porto Velho', label: 'America/Porto_Velho' },
  { name: '(GMT-04:00) Puerto Rico', label: 'America/Puerto_Rico' },
  { name: '(GMT-04:00) Santo Domingo', label: 'America/Santo_Domingo' },
  { name: '(GMT-04:00) Thule', label: 'America/Thule' },
  { name: '(GMT-04:00) Bermuda', label: 'Atlantic/Bermuda' },
  {
    name: '(GMT-03:30) Newfoundland Time - St. Johns',
    label: 'America/St_Johns',
  },
  { name: '(GMT-03:00) Araguaina', label: 'America/Araguaina' },
  { name: '(GMT-03:00) Buenos Aires', label: 'America/Argentina/Buenos_Aires' },
  { name: '(GMT-03:00) Salvador', label: 'America/Bahia' },
  { name: '(GMT-03:00) Belem', label: 'America/Belem' },
  { name: '(GMT-03:00) Cayenne', label: 'America/Cayenne' },
  { name: '(GMT-03:00) Fortaleza', label: 'America/Fortaleza' },
  { name: '(GMT-03:00) Godthab', label: 'America/Godthab' },
  { name: '(GMT-03:00) Maceio', label: 'America/Maceio' },
  { name: '(GMT-03:00) Miquelon', label: 'America/Miquelon' },
  { name: '(GMT-03:00) Montevideo', label: 'America/Montevideo' },
  { name: '(GMT-03:00) Paramaribo', label: 'America/Paramaribo' },
  { name: '(GMT-03:00) Recife', label: 'America/Recife' },
  { name: '(GMT-03:00) Santiago', label: 'America/Santiago' },
  { name: '(GMT-02:00) Sao Paulo', label: 'America/Sao_Paulo' },
  { name: '(GMT-03:00) Palmer', label: 'Antarctica/Palmer' },
  { name: '(GMT-03:00) Rothera', label: 'Antarctica/Rothera' },
  { name: '(GMT-03:00) Stanley', label: 'Atlantic/Stanley' },
  { name: '(GMT-02:00) Noronha', label: 'America/Noronha' },
  { name: '(GMT-02:00) South Georgia', label: 'Atlantic/South_Georgia' },
  { name: '(GMT-01:00) Scoresbysund', label: 'America/Scoresbysund' },
  { name: '(GMT-01:00) Azores', label: 'Atlantic/Azores' },
  { name: '(GMT-01:00) Cape Verde', label: 'Atlantic/Cape_Verde' },
  { name: '(GMT+00:00) Abidjan', label: 'Africa/Abidjan' },
  { name: '(GMT+00:00) Accra', label: 'Africa/Accra' },
  { name: '(GMT+00:00) Bissau', label: 'Africa/Bissau' },
  { name: '(GMT+00:00) Casablanca', label: 'Africa/Casablanca' },
  { name: '(GMT+00:00) El Aaiun', label: 'Africa/El_Aaiun' },
  { name: '(GMT+00:00) Monrovia', label: 'Africa/Monrovia' },
  { name: '(GMT+00:00) Danmarkshavn', label: 'America/Danmarkshavn' },
  { name: '(GMT+00:00) Canary Islands', label: 'Atlantic/Canary' },
  { name: '(GMT+00:00) Faeroe', label: 'Atlantic/Faroe' },
  { name: '(GMT+00:00) Reykjavik', label: 'Atlantic/Reykjavik' },
  { name: '(GMT+00:00) GMT (no daylight saving)', label: 'Etc/GMT' },
  { name: '(GMT+00:00) Dublin', label: 'Europe/Dublin' },
  { name: '(GMT+00:00) Lisbon', label: 'Europe/Lisbon' },
  { name: '(GMT+00:00) London', label: 'Europe/London' },
  { name: '(GMT+01:00) Algiers', label: 'Africa/Algiers' },
  { name: '(GMT+01:00) Ceuta', label: 'Africa/Ceuta' },
  { name: '(GMT+01:00) Lagos', label: 'Africa/Lagos' },
  { name: '(GMT+01:00) Ndjamena', label: 'Africa/Ndjamena' },
  { name: '(GMT+01:00) Tunis', label: 'Africa/Tunis' },
  { name: '(GMT+02:00) Windhoek', label: 'Africa/Windhoek' },
  { name: '(GMT+01:00) Amsterdam', label: 'Europe/Amsterdam' },
  { name: '(GMT+01:00) Andorra', label: 'Europe/Andorra' },
  {
    name: '(GMT+01:00) Central European Time - Belgrade',
    label: 'Europe/Belgrade',
  },
  { name: '(GMT+01:00) Berlin', label: 'Europe/Berlin' },
  { name: '(GMT+01:00) Brussels', label: 'Europe/Brussels' },
  { name: '(GMT+01:00) Budapest', label: 'Europe/Budapest' },
  { name: '(GMT+01:00) Copenhagen', label: 'Europe/Copenhagen' },
  { name: '(GMT+01:00) Gibraltar', label: 'Europe/Gibraltar' },
  { name: '(GMT+01:00) Luxembourg', label: 'Europe/Luxembourg' },
  { name: '(GMT+01:00) Madrid', label: 'Europe/Madrid' },
  { name: '(GMT+01:00) Malta', label: 'Europe/Malta' },
  { name: '(GMT+01:00) Monaco', label: 'Europe/Monaco' },
  { name: '(GMT+01:00) Oslo', label: 'Europe/Oslo' },
  { name: '(GMT+01:00) Paris', label: 'Europe/Paris' },
  {
    name: '(GMT+01:00) Central European Time - Prague',
    label: 'Europe/Prague',
  },
  { name: '(GMT+01:00) Rome', label: 'Europe/Rome' },
  { name: '(GMT+01:00) Stockholm', label: 'Europe/Stockholm' },
  { name: '(GMT+01:00) Tirane', label: 'Europe/Tirane' },
  { name: '(GMT+01:00) Vienna', label: 'Europe/Vienna' },
  { name: '(GMT+01:00) Warsaw', label: 'Europe/Warsaw' },
  { name: '(GMT+01:00) Zurich', label: 'Europe/Zurich' },
  { name: '(GMT+02:00) Cairo', label: 'Africa/Cairo' },
  { name: '(GMT+02:00) Johannesburg', label: 'Africa/Johannesburg' },
  { name: '(GMT+02:00) Maputo', label: 'Africa/Maputo' },
  { name: '(GMT+02:00) Tripoli', label: 'Africa/Tripoli' },
  { name: '(GMT+02:00) Amman', label: 'Asia/Amman' },
  { name: '(GMT+02:00) Beirut', label: 'Asia/Beirut' },
  { name: '(GMT+02:00) Damascus', label: 'Asia/Damascus' },
  { name: '(GMT+02:00) Gaza', label: 'Asia/Gaza' },
  { name: '(GMT+02:00) Jerusalem', label: 'Asia/Jerusalem' },
  { name: '(GMT+02:00) Nicosia', label: 'Asia/Nicosia' },
  { name: '(GMT+02:00) Athens', label: 'Europe/Athens' },
  { name: '(GMT+02:00) Bucharest', label: 'Europe/Bucharest' },
  { name: '(GMT+02:00) Chisinau', label: 'Europe/Chisinau' },
  { name: '(GMT+02:00) Helsinki', label: 'Europe/Helsinki' },
  { name: '(GMT+02:00) Istanbul', label: 'Europe/Istanbul' },
  { name: '(GMT+02:00) Moscow-01 - Kaliningrad', label: 'Europe/Kaliningrad' },
  { name: '(GMT+02:00) Kiev', label: 'Europe/Kiev' },
  { name: '(GMT+02:00) Riga', label: 'Europe/Riga' },
  { name: '(GMT+02:00) Sofia', label: 'Europe/Sofia' },
  { name: '(GMT+02:00) Tallinn', label: 'Europe/Tallinn' },
  { name: '(GMT+02:00) Vilnius', label: 'Europe/Vilnius' },
  { name: '(GMT+03:00) Khartoum', label: 'Africa/Khartoum' },
  { name: '(GMT+03:00) Nairobi', label: 'Africa/Nairobi' },
  { name: '(GMT+03:00) Syowa', label: 'Antarctica/Syowa' },
  { name: '(GMT+03:00) Baghdad', label: 'Asia/Baghdad' },
  { name: '(GMT+03:00) Qatar', label: 'Asia/Qatar' },
  { name: '(GMT+03:00) Riyadh', label: 'Asia/Riyadh' },
  { name: '(GMT+03:00) Minsk', label: 'Europe/Minsk' },
  { name: '(GMT+03:00) Moscow+00 - Moscow', label: 'Europe/Moscow' },
  { name: '(GMT+03:30) Tehran', label: 'Asia/Tehran' },
  { name: '(GMT+04:00) Baku', label: 'Asia/Baku' },
  { name: '(GMT+04:00) Dubai', label: 'Asia/Dubai' },
  { name: '(GMT+04:00) Tbilisi', label: 'Asia/Tbilisi' },
  { name: '(GMT+04:00) Yerevan', label: 'Asia/Yerevan' },
  { name: '(GMT+04:00) Moscow+01 - Samara', label: 'Europe/Samara' },
  { name: '(GMT+04:00) Mahe', label: 'Indian/Mahe' },
  { name: '(GMT+04:00) Mauritius', label: 'Indian/Mauritius' },
  { name: '(GMT+04:00) Reunion', label: 'Indian/Reunion' },
  { name: '(GMT+04:30) Kabul', label: 'Asia/Kabul' },
  { name: '(GMT+05:00) Mawson', label: 'Antarctica/Mawson' },
  { name: '(GMT+05:00) Aqtau', label: 'Asia/Aqtau' },
  { name: '(GMT+05:00) Aqtobe', label: 'Asia/Aqtobe' },
  { name: '(GMT+05:00) Ashgabat', label: 'Asia/Ashgabat' },
  { name: '(GMT+05:00) Dushanbe', label: 'Asia/Dushanbe' },
  { name: '(GMT+05:00) Karachi', label: 'Asia/Karachi' },
  { name: '(GMT+05:00) Tashkent', label: 'Asia/Tashkent' },
  {
    name: '(GMT+05:00) Moscow+02 - Yekaterinburg',
    label: 'Asia/Yekaterinburg',
  },
  { name: '(GMT+05:00) Kerguelen', label: 'Indian/Kerguelen' },
  { name: '(GMT+05:00) Maldives', label: 'Indian/Maldives' },
  { name: '(GMT+05:30) India Standard Time', label: 'Asia/Calcutta' },
  { name: '(GMT+05:30) Colombo', label: 'Asia/Colombo' },
  { name: '(GMT+05:45) Katmandu', label: 'Asia/Katmandu' },
  { name: '(GMT+06:00) Vostok', label: 'Antarctica/Vostok' },
  { name: '(GMT+06:00) Almaty', label: 'Asia/Almaty' },
  { name: '(GMT+06:00) Bishkek', label: 'Asia/Bishkek' },
  { name: '(GMT+06:00) Dhaka', label: 'Asia/Dhaka' },
  { name: '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk', label: 'Asia/Omsk' },
  { name: '(GMT+06:00) Thimphu', label: 'Asia/Thimphu' },
  { name: '(GMT+06:00) Chagos', label: 'Indian/Chagos' },
  { name: '(GMT+06:30) Rangoon', label: 'Asia/Rangoon' },
  { name: '(GMT+06:30) Cocos', label: 'Indian/Cocos' },
  { name: '(GMT+07:00) Davis', label: 'Antarctica/Davis' },
  { name: '(GMT+07:00) Bangkok', label: 'Asia/Bangkok' },
  { name: '(GMT+07:00) Hovd', label: 'Asia/Hovd' },
  { name: '(GMT+07:00) Jakarta', label: 'Asia/Jakarta' },
  { name: '(GMT+07:00) Moscow+04 - Krasnoyarsk', label: 'Asia/Krasnoyarsk' },
  { name: '(GMT+07:00) Hanoi', label: 'Asia/Saigon' },
  { name: '(GMT+07:00) Christmas', label: 'Indian/Christmas' },
  { name: '(GMT+08:00) Casey', label: 'Antarctica/Casey' },
  { name: '(GMT+08:00) Brunei', label: 'Asia/Brunei' },
  { name: '(GMT+08:00) Choibalsan', label: 'Asia/Choibalsan' },
  { name: '(GMT+08:00) Hong Kong', label: 'Asia/Hong_Kong' },
  { name: '(GMT+08:00) Moscow+05 - Irkutsk', label: 'Asia/Irkutsk' },
  { name: '(GMT+08:00) Kuala Lumpur', label: 'Asia/Kuala_Lumpur' },
  { name: '(GMT+08:00) Macau', label: 'Asia/Macau' },
  { name: '(GMT+08:00) Makassar', label: 'Asia/Makassar' },
  { name: '(GMT+08:00) Manila', label: 'Asia/Manila' },
  { name: '(GMT+08:00) China Time - Beijing', label: 'Asia/Shanghai' },
  { name: '(GMT+08:00) Singapore', label: 'Asia/Singapore' },
  { name: '(GMT+08:00) Taipei', label: 'Asia/Taipei' },
  { name: '(GMT+08:00) Ulaanbaatar', label: 'Asia/Ulaanbaatar' },
  { name: '(GMT+08:00) Western Time - Perth', label: 'Australia/Perth' },
  { name: '(GMT+08:30) Pyongyang', label: 'Asia/Pyongyang' },
  { name: '(GMT+09:00) Dili', label: 'Asia/Dili' },
  { name: '(GMT+09:00) Jayapura', label: 'Asia/Jayapura' },
  { name: '(GMT+09:00) Seoul', label: 'Asia/Seoul' },
  { name: '(GMT+09:00) Tokyo', label: 'Asia/Tokyo' },
  { name: '(GMT+09:00) Moscow+06 - Yakutsk', label: 'Asia/Yakutsk' },
  { name: '(GMT+09:00) Palau', label: 'Pacific/Palau' },
  { name: '(GMT+10:30) Central Time - Adelaide', label: 'Australia/Adelaide' },
  { name: '(GMT+09:30) Central Time - Darwin', label: 'Australia/Darwin' },
  { name: "(GMT+10:00) Dumont D'Urville", label: 'Antarctica/DumontDUrville' },
  { name: '(GMT+10:00) Moscow+07 - Magadan', label: 'Asia/Magadan' },
  {
    name: '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk',
    label: 'Asia/Vladivostok',
  },
  { name: '(GMT+10:00) Eastern Time - Brisbane', label: 'Australia/Brisbane' },
  { name: '(GMT+11:00) Eastern Time - Hobart', label: 'Australia/Hobart' },
  {
    name: '(GMT+11:00) Eastern Time - Melbourne, Sydney',
    label: 'Australia/Sydney',
  },
  { name: '(GMT+10:00) Truk', label: 'Pacific/Chuuk' },
  { name: '(GMT+10:00) Guam', label: 'Pacific/Guam' },
  { name: '(GMT+10:00) Port Moresby', label: 'Pacific/Port_Moresby' },
  { name: '(GMT+11:00) Efate', label: 'Pacific/Efate' },
  { name: '(GMT+11:00) Guadalcanal', label: 'Pacific/Guadalcanal' },
  { name: '(GMT+11:00) Kosrae', label: 'Pacific/Kosrae' },
  { name: '(GMT+11:00) Norfolk', label: 'Pacific/Norfolk' },
  { name: '(GMT+11:00) Noumea', label: 'Pacific/Noumea' },
  { name: '(GMT+11:00) Ponape', label: 'Pacific/Pohnpei' },
  {
    name: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
    label: 'Asia/Kamchatka',
  },
  { name: '(GMT+13:00) Auckland', label: 'Pacific/Auckland' },
  { name: '(GMT+13:00) Fiji', label: 'Pacific/Fiji' },
  { name: '(GMT+12:00) Funafuti', label: 'Pacific/Funafuti' },
  { name: '(GMT+12:00) Kwajalein', label: 'Pacific/Kwajalein' },
  { name: '(GMT+12:00) Majuro', label: 'Pacific/Majuro' },
  { name: '(GMT+12:00) Nauru', label: 'Pacific/Nauru' },
  { name: '(GMT+12:00) Tarawa', label: 'Pacific/Tarawa' },
  { name: '(GMT+12:00) Wake', label: 'Pacific/Wake' },
  { name: '(GMT+12:00) Wallis', label: 'Pacific/Wallis' },
  { name: '(GMT+14:00) Apia', label: 'Pacific/Apia' },
  { name: '(GMT+13:00) Enderbury', label: 'Pacific/Enderbury' },
  { name: '(GMT+13:00) Fakaofo', label: 'Pacific/Fakaofo' },
  { name: '(GMT+13:00) Tongatapu', label: 'Pacific/Tongatapu' },
  { name: '(GMT+14:00) Kiritimati', label: 'Pacific/Kiritimati' },
];

export const DELETE_MEETING_DIALOG_OPEN_KEY_PATH = [
  'settings',
  'mySettings',
  'scheduler',
  'deleteDialogOpen',
];
export const DELETE_MEETING_ID_IMPORT_KEY_PATH = [
  'settings',
  'mySettings',
  'scheduler',
  'meetingId',
];
export const BLANK_EMAIL_HOLDER = { blankEmail: 'No signature found' };
export const FETCH_SIGNATURE_ERROR = 'FETCH_SIGNATURE_ERROR';

export const SCHEDULER_LINK_EDIT_UI_KEY_PATH = ['scheduler', 'linkEditable'];

export const TABS = {
  MY_SETTINGS: 'my_settings',
  MANAGE_MY_ORG: 'manage_my_org',
  MANAGE_MY_TEAMS: 'manage_my_teams',
};

interface OnClickParam {
  setProperty: ({
    uiKeyPath,
    data,
  }: {
    uiKeyPath: string[];
    data: boolean;
  }) => void;
}

export const MY_SETTINGS_NAV_OPTIONS = List([
  {
    label: 'General Settings',
    isGroupHeader: true,
    scrollId: 'generalSettings',
  },
  { label: 'Timezone', isGroupHeader: false, scrollId: 'generalSettings' },
  {
    label: 'Vacation Pausing',
    isGroupHeader: false,
    scrollId: 'vacationPausing',
  },
  { label: 'Flow Options', isGroupHeader: true, scrollId: 'flowOptions' },
  { label: 'Sync Settings', isGroupHeader: true, scrollId: 'emailAndCal' },
  {
    label: 'Email Settings',
    scrollId: 'emailSettings',
    onClick: ({ setProperty }: OnClickParam) => {
      setProperty({
        uiKeyPath: ['emailAndCalendar', 'emailSettingsCollapsed'],
        data: false,
      });
    },
  },
  {
    label: 'Calendar Settings',
    scrollId: 'calendarSettings',
    onClick: ({ setProperty }: OnClickParam) => {
      setProperty({
        uiKeyPath: ['emailAndCalendar', 'calendarSettingsCollapsed'],
        data: false,
      });
    },
  },
  { label: 'Scheduler', isGroupHeader: true, scrollId: 'scheduler' },
  { label: 'Scheduler Link', scrollId: 'schedulerLink' },
  {
    label: 'Calendar Availability',
    scrollId: 'calendarAvailability',
    onClick: ({ setProperty }: OnClickParam) => {
      setProperty({
        uiKeyPath: ['scheduler', 'calendarAvailabilityCollapsed'],
        data: false,
      });
    },
  },
  {
    label: 'Meeting Types',
    scrollId: 'meetingTypes',
    onClick: ({ setProperty }: OnClickParam) => {
      setProperty({
        uiKeyPath: ['scheduler', 'calendarMeetingTypesCollapsed'],
        data: false,
      });
    },
  },
  { label: 'Dialer', isGroupHeader: true, scrollId: 'dialer' },
  {
    label: 'Voicemail Drop',
    scrollId: 'voicemailRecording',
    onClick: ({ setProperty }: OnClickParam) => {
      setProperty({
        uiKeyPath: ['dialer', 'voicemailRecording', 'collapsed'],
        data: false,
      });
    },
  },
  {
    label: 'Connected Accounts',
    isGroupHeader: true,
    scrollId: 'connectedAccounts',
  },
  { label: 'Google Settings', scrollId: 'googleSettings' },
  {
    label: 'Authorized Applications',
    scrollId: 'authorizedApplications',
    onClick: ({ setProperty }: OnClickParam) => {
      setProperty({
        uiKeyPath: ['account', 'authorizedApplicationsCollapsed'],
        data: false,
      });
    },
  },
  { label: 'Labels', isGroupHeader: true, scrollId: 'labels' },
]);

export const MMO_USERS_BACKEND_PAGINATION = 'mmo-users-backend-pagination';

export const FLOW_APPROVAL_FEATURE_FLAG = 'flow-approval-process';
export const MANAGE_MY_ORG_CALL_SMS_CUSTOM_FIELDS_FEATURE_FLAG =
  'custom-call-and-sms-opt-out';
export const MANAGE_MY_ORG_CUSTOM_LOGGING_FIELDS_FEATURE_FLAG =
  'additional-fields-dialer';
export const MANAGE_MY_ORG_MOBO_FEATURE_FLAG = 'mobo';
export const INTERNATIONAL_PROVISIONING_REFACTOR = 'international-local-dial';
export const MANAGE_MY_ORG_CONVERSATION_INTELLIGENCE_FEATURE_FLAG =
  'Conversation_Intelligence';
export const CLARI_GROOVE_LICENSE_MANAGEMENT_SHORT_TERM_FEATURE_FLAG =
  'clari-groove-license-management-short-term';
export const CUSTOM_FORM_FIELDS_FEATURE_FLAG = 'customFormFields';
export const INTEGRATIONS_FEATURE_FLAG = 'integrations';
export const INTEGRATIONS_GROUP_ID = 'integrations';
export const INTEGRATIONS_FIELD_ID = {
  SENDOSO_ID: 'sendosoEnabled',
  SEISMIC_ID: 'seismicEnabled',
  VIDYARD_ID: 'vidyardEnabled',
  SHOW_VIDYARD_ID: 'showVidyardLink',
  WINGMAN_ID: 'wingmanEnabled',
};
export const INTEGRATIONS_NAME: { [key: string]: string } = {
  sendosoEnabled: 'Sendoso',
  seismicEnabled: 'Seismic',
  wingmanEnabled: 'Wingman',
};

export const SECTION_ERRORS_UI_KEY_PATH = ['manageMyOrg', 'sectionErrors'];
export const USERS_SECTION_ID = 'users';
export const TEAMS_SECTION_ID = 'teams';
export const PROFILE_SETTINGS_SECTION_ID = 'profileSettings';
export const ABS_SETTINGS_SECTION_ID = 'absSettings';
export const MY_ORG_SECTION_ID = 'myOrg';
export const MEETING_SCHEDULER_SECTION_ID = 'meetingScheduler';
export const CALL_SETTINGS_SECTION_ID = 'callSettings';
export const DIALER_SECTION_ID = 'dialer';
export const AUTOMATED_ACTIONS_SECTION_ID = 'automatedActions';
export const FLOW_SECTION_ID = 'Flow-Settings-Id';
export const ACTIVITY_LOGS_SECTION_ID = 'activityLog';
export const CONVERSATION_INTELLIGENCE_SECTION_ID = 'conversationIntelligence';
export const CLARI_COPILOT_SECTION_ID = 'clariCopilot';
export const GROOVE_STATUS_SECTION_ID = 'grooveStatus';
export const RIO_SECTION_ID = 'rioSettings';
export const MEETING_SCHEDULER_ERROR_MSG =
  'Failed to fetch Meeting Scheduler Settings';

export const MANAGE_MY_ORG_NAV_OPTIONS = [
  {
    label: 'Users',
    isGroupHeader: true,
    scrollId: USERS_SECTION_ID,
  },
  {
    label: 'Teams',
    isGroupHeader: true,
    scrollId: TEAMS_SECTION_ID,
  },
  {
    label: 'Profile Settings',
    isGroupHeader: true,
    scrollId: PROFILE_SETTINGS_SECTION_ID,
    condition: 'isSfdcAdmin',
  },
  {
    label: 'Account Based Sales',
    isGroupHeader: true,
    scrollId: ABS_SETTINGS_SECTION_ID,
  },
  {
    label: 'My Org',
    isGroupHeader: true,
    scrollId: MY_ORG_SECTION_ID,
  },
  {
    label: 'Licenses',
    scrollId: 'grooveLicenses',
  },
  {
    label: 'Salesforce Service Account',
    scrollId: 'salesforceServiceAccount',
  },
  {
    label: 'Domain-Wide Enablement',
    scrollId: 'domainWideEnablement',
    condition: 'isGoogleOrg',
  },
  {
    label: 'Admin Notification Email',
    scrollId: 'grooveOwnerEmail',
  },
  {
    label: 'Analytics',
    scrollId: 'analytics',
  },
  {
    label: 'Manage on Behalf of',
    scrollId: 'mobo',
    featureFlag: MANAGE_MY_ORG_MOBO_FEATURE_FLAG,
    condition: 'launchDarkly',
  },
  {
    label: 'Integrations',
    scrollId: 'integrations',
    featureFlag: INTEGRATIONS_FEATURE_FLAG,
    condition: 'launchDarkly',
  },
  // temporarily removing this until this feature flag is removed because of launch darkly FF
  // {
  //   label: 'Templates in Actions',
  //   scrollId: 'templatesInActions',
  // },
  {
    label: 'Meeting Scheduler',
    isGroupHeader: true,
    scrollId: MEETING_SCHEDULER_SECTION_ID,
    // Is this really any less ridiculous than this system?
    condition: 'superSpecialCheck_isMicrosoftOrgWithSchedulerEnabled',
  },
  {
    label: 'Custom Branding',
    scrollId: 'customMeetingScheduler',
    condition: 'isGoogleOrg',
  },
  {
    label: 'Round Robin Groups',
    scrollId: 'roundRobinGroups',
    condition: 'isGoogleOrg',
  },
  {
    label: 'Customize your form',
    scrollId: 'meetingFormCustomization',
    condition: 'isGoogleOrg',
    featureFlag: CUSTOM_FORM_FIELDS_FEATURE_FLAG,
  },
  {
    label: 'Embed your Scheduler',
    scrollId: 'embedScheduler',
    condition: 'isGoogleOrg',
    featureFlag: CUSTOM_FORM_FIELDS_FEATURE_FLAG,
  },
  {
    label: 'Dialer',
    isGroupHeader: true,
    scrollId: DIALER_SECTION_ID,
    condition: ['allowedDialerLicenses'],
  },
  {
    label: 'Local Dial',
    scrollId: 'localDial',
    condition: ['allowedDialerLicenses'],
  },
  {
    label: 'Provision Phone Numbers',
    scrollId: 'internationalDial',
    condition: ['allowedDialerLicenses'],
  },
  {
    label: 'Call Settings',
    isGroupHeader: true,
    scrollId: CALL_SETTINGS_SECTION_ID,
  },
  {
    label: 'Call Status',
    scrollId: 'callStatus',
  },
  {
    label: 'Conversation Outcome',
    scrollId: 'conversationOutcome',
  },
  {
    label: 'Salesforce Custom Opt Out fields',
    scrollId: 'salesforceCustomOptOutFields',
    featureFlag: MANAGE_MY_ORG_CALL_SMS_CUSTOM_FIELDS_FEATURE_FLAG,
  },
  {
    label: 'Additional Call Log Fields',
    scrollId: 'additionalCallLogFields',
    featureFlag: MANAGE_MY_ORG_CUSTOM_LOGGING_FIELDS_FEATURE_FLAG,
  },
  {
    label: 'Global Pausing',
    isGroupHeader: true,
    scrollId: 'Global-Pausing-Id',
  },
  {
    label: 'Holiday Pausing',
    scrollId: 'Holiday-Pause-Id',
  },
  {
    label: 'Out of Office Detection',
    scrollId: 'Out-Of-Office-Id',
  },
  {
    label: 'Flows and Actions',
    isGroupHeader: true,
    scrollId: FLOW_SECTION_ID,
  },
  {
    label: 'Automated Actions',
    isGroupHeader: true,
    scrollId: AUTOMATED_ACTIONS_SECTION_ID,
  },
  {
    label: 'Groove Conversations',
    isGroupHeader: true,
    scrollId: CONVERSATION_INTELLIGENCE_SECTION_ID,
    featureFlag: MANAGE_MY_ORG_CONVERSATION_INTELLIGENCE_FEATURE_FLAG,
  },
  {
    label: 'Clari Copilot',
    isGroupHeader: true,
    scrollId: CLARI_COPILOT_SECTION_ID,
  },
  {
    label: 'Activity Log',
    isGroupHeader: true,
    scrollId: ACTIVITY_LOGS_SECTION_ID,
  },
  {
    label: 'Groove Status',
    isGroupHeader: true,
    scrollId: GROOVE_STATUS_SECTION_ID,
  },
];

export const MANAGE_MY_TEAMS_NAV_OPTIONS = [
  { label: 'Teams', isGroupHeader: true, scrollId: 'teams' },
];

export const ALLOWED_LICENSES = 'allowedLicenses';
export const ALLOWED_FLOW_LICENSES = 'allowedFlowLicenses';
export const ALLOWED_DIALER_LICENSES = 'allowedDialerLicenses';
export const ALLOWED_SMS_LICENSES = 'allowedSmsLicenses';
export const ALLOWED_CONVERSATION_INTELLIGENCE_LICENSES =
  'allowedConversationIntelligenceLicenses';
export const ALLOWED_LICENSE_LABELS = {
  [ALLOWED_LICENSES]: 'Core',
  [ALLOWED_FLOW_LICENSES]: 'Flow',
  [ALLOWED_DIALER_LICENSES]: 'Dialer',
  [ALLOWED_SMS_LICENSES]: 'SMS',
  [ALLOWED_CONVERSATION_INTELLIGENCE_LICENSES]: 'CI',
};
export const USED_LICENSES = 'usedLicenses';
export const USED_FLOW_LICENSES = 'usedFlowLicenses';
export const USED_DIALER_LICENSES = 'usedDialerLicenses';
export const USED_SMS_LICENSES = 'usedSmsLicenses';
export const USED_CONVERSATION_INTELLIGENCE_LICENSES =
  'usedConversationIntelligenceLicenses';

export const ADD_LICENSES_AUTO_PURCHASE_UI_KEY_PATH = [
  'manageMyOrg',
  'addLicensesAutoOpen',
];
export const ADD_LICENSES_DIALOG_OPEN_UI_KEY_PATH = [
  'manageMyOrg',
  'addLicensesDialogOpen',
];
export const ADD_LICENSES_SUCCESS_CALLBACK_UI_KEY_PATH = [
  'manageMyOrg',
  'addLicensesSuccessCallback',
];
export const ADD_LICENSES_GROUP_ID = 'ADD_LICENSES';
export const ADD_LICENSES_CORE_LICENSES_FIELD = 'newCoreLicenses';
export const ADD_LICENSES_FLOW_UNLIMITED_LICENSES_FIELD =
  'newFlowUnlimitedLicenses';
export const ADD_LICENSES_DIALER_LICENSES_FIELD = 'newDialerLicenses';
export const ADD_LICENSES_CONVERSATION_INTELLIGENCE_LICENSES_FIELD =
  'newConversationIntelligenceLicenses';
export const LICENSE_UNLIMITED_VALUE = -1;

export const INACTIVE_USERS_DIALOG_OPEN_UI_KEY_PATH = [
  'manageMyOrg',
  'inactiveUsersDialogOpen',
];

export const DEACTIVATED_USERS_DIALOG_OPEN_UI_KEY_PATH = [
  'manageMyOrg',
  'deactivatedUsersDialogOpen',
];
export const GROOVE_OWNER_EMAIL_UI_KEY_PATH = [
  'manageMyOrg',
  'grooveOwnerEmail',
];

export const TEAMS_KEY_PATH = ['settings', 'manageMyOrg', 'teams', 'teams'];
export const PREVENT_AGENT_AND_ANALYST_FLOW_CREATION_FIELD_ID =
  'preventAgentAndAnalystFlowCreation';

export const DRILLDOWN_UI_KEY_PATH = ['manageMyOrg', 'drilldown'];
export const DRILLDOWN_OPEN_UI_KEY_PATH = [...DRILLDOWN_UI_KEY_PATH, 'open'];
export const DRILLDOWN_COMPONENT_UI_KEY_PATH = [
  ...DRILLDOWN_UI_KEY_PATH,
  'component',
];
export const DRILLDOWN_HIDE_TITLE_PAGE_UI_KEY_PATH = [
  ...DRILLDOWN_UI_KEY_PATH,
  'hideTitle',
];
export const DRILLDOWN_IS_FULL_PAGE_UI_KEY_PATH = [
  ...DRILLDOWN_UI_KEY_PATH,
  'isFullPage',
];
export const DRILLDOWN_KEY_UI_KEY_PATH = [...DRILLDOWN_UI_KEY_PATH, 'key'];
export const DRILLDOWN_TITLE_UI_KEY_PATH = [...DRILLDOWN_UI_KEY_PATH, 'title'];
export const DRILLDOWN_BUTTON_UI_KEY_PATH = [
  ...DRILLDOWN_UI_KEY_PATH,
  'button',
];
export const DRILLDOWN_CONTEXT_UI_KEY_PATH = [
  ...DRILLDOWN_UI_KEY_PATH,
  'context',
];
export const DRILLDOWN_BACK_TO_TRIGGER_ID_UI_KEY_PATH = [
  ...DRILLDOWN_UI_KEY_PATH,
  'backToTriggerId',
];
export const DRILLDOWN_DEFAULT_TITLE = 'Manage My Org';

export const NEW_TEAM_UI_KEY_PATH = ['teams', 'newTeam'];
export const CREATING_NEW_TEAM_UI_KEY_PATH = ['teams', 'creatingNewTeam'];
export const DELETING_TEAM_UI_KEY_PATH = ['teams', 'deletingTeam'];

export const ROLES = {
  admin: 'Groove Admin',
  analyst: 'Analyst',
  team_lead: 'Team Lead',
  agent: 'Standard User',
};

// the following will be used to map suborg ids to names for AirBnB Suborgs
// hardcoded in backend unfortunately
const AIRBNB_4_WORK = 1476;
const AIRBNB_PROHOST = 1508;
const AIRBNB_DEFAULT = 1509;
const AIRBNB_EXPERIENCES = 1613;
const AIRBNB_MOBX = 1998;
const AIRBNB_PLUS = 2313;

export const AIRBNB_ORG_LABEL_MAP = {
  [AIRBNB_4_WORK]: 'Airbnb for Work',
  [AIRBNB_PROHOST]: 'Prohost',
  [AIRBNB_DEFAULT]: 'Airbnb',
  [AIRBNB_EXPERIENCES]: 'Experiences',
  [AIRBNB_MOBX]: 'Mobilization',
  [AIRBNB_PLUS]: 'Airbnb Plus',
};

export const CALENDAR_SETTINGS_COLLAPSED_UI_KEY_PATH = [
  'emailAndCalendar',
  'calendarSettingsCollapsed',
];
export const CALENDAR_SYNC_ENABLED_UI_KEY_PATH = [
  'emailAndCalendar',
  'calendarSyncEnabled',
];
export const CALENDAR_SYNC_ENABLE_IN_PROGRESS_UI_KEY_PATH = [
  'emailAndCalendar',
  'calendarSyncEnableInProgress',
];
export const CALENDAR_SYNC_VARIANT = 'CALENDAR';
export const EMAIL_SETTINGS_COLLAPSED_UI_KEY_PATH = [
  'emailAndCalendar',
  'emailSettingsCollapsed',
];
export const EMAIL_SYNC_ENABLED_UI_KEY_PATH = [
  'emailAndCalendar',
  'emailSyncEnabled',
];
export const EMAIL_SYNC_ENABLE_IN_PROGRESS_UI_KEY_PATH = [
  'emailAndCalendar',
  'emailSyncEnableInProgress',
];
export const EMAIL_SYNC_VARIANT = 'EMAIL';
const SYNC_CONNECTION_INVALID_MSG =
  'sync has been disabled for your account, please contact your admin or Groove support to learn more.';
export const CALENDAR_SYNC_CONNECTION_INVALID_MSG = `Calendar ${SYNC_CONNECTION_INVALID_MSG}`;
export const EMAIL_SYNC_CONNECTION_INVALID_MSG = `Email ${SYNC_CONNECTION_INVALID_MSG}`;

// Dialer settings
export const NUMBER_VERIFICATION_GROUP_ID = 'phoneNumberVerification';
export const DIALER_NUMBER_SETTINGS_GROUP_ID = 'dialerNumberSettings';
export const VERIFY_INPUT_EDITING_KEYPATH = [
  'dialer',
  'numberVerification',
  'editing',
];
export const VERIFY_NUMBER_OPEN_KEY_PATH = [
  'dialer',
  'numberVerification',
  'dialogOpen',
];
export const VERIFICATION_CODE_KEY_PATH = [
  'dialer',
  'numberVerification',
  'verificationCode',
];
export const DEPROVISION_NUMBER_DIALOG_OPEN_KEY_PATH = [
  'settings',
  'mySettings',
  'dialer',
  'deprovisionDialogOpen',
];

export const SEARCH_AND_SELECT_OBJECTS_FIELD_ID = 'searchAndSelectObjects';
export const SEARCH_AND_SELECT_COLLAPSIBLE_UI_KEY_PATH = [
  'profileSettings',
  'searchAndSelectCollapsed',
];
export const ADDITIONAL_FIELDS_FOR_EMAIL_LOGGING_FIELD_ID =
  'additionalFieldsForEmailLogging';
export const ADDITIONAL_FIELDS_FOR_EMAIL_LOGGING_COLLAPSIBLE_UI_KEY_PATH = [
  'profileSettings',
  'additionalFieldsForEmailLoggingCollapsed',
];
export const ADDITIONAL_FIELDS_FOR_CALENDAR_EVENTS_FIELD_ID =
  'additionalFieldsForCalendarEvents';
export const ADDITIONAL_FIELDS_FOR_CALENDAR_EVENTS_COLLAPSIBLE_UI_KEY_PATH = [
  'profileSettings',
  'additionalFieldsForCalendarEventsCollapsed',
];

export const PROFILE_SETTINGS_NEW_FORM_OPENED_UI_KEY_PATH = [
  'profileSettings',
  'newFormOpened',
];
export const PROFILE_SETTINGS_NEW_PROFILE_FIELD_ID = 'profileId';
export const PROFILE_SETTINGS_ORG_DEFAULT_PROFILE_NAME =
  'Organization Wide Default';
export const PROFILE_SETTINGS_OWNER_KEY = 'SetupOwnerId';
export const ORG_WIDE_DEFAULT_SFDC_PROFILE_ID_PREFIX = '00D';

export const ONE_MEGABYTE = 1048576;

export const PROFILE_SETTINGS_FIELDS_MAPPING = {
  additionalFieldsForCalendarEvents:
    'DaScoopComposer__Additional_Fields_for_Calendar_Events__c',
  additionalFieldsForEmailLogging:
    'DaScoopComposer__Additional_Fields_for_Email_Logging__c',
  allowAutomaticCalendarSync:
    'DaScoopComposer__Allow_Automatic_Calendar_Sync__c',
  allowAutomaticEmailSync: 'DaScoopComposer__Allow_Automatic_Email_Sync__c',
  alternativeEmailMatching: 'DaScoopComposer__Alternative_Email_Matching__c',
  alwaysMirrorEventsInGoogleCalendar:
    'DaScoopComposer__Always_Mirror_Events_in_Google_Calendar__c',
  bestMatchEmailLogging: 'DaScoopComposer__Best_Match_Email_Logging__c',
  blockTeammateEmailOpens: 'DaScoopComposer__Block_Teammate_Email_Opens__c',
  createUnresolvedItems: 'DaScoopComposer__Create_Unresolved_Items__c',
  domainBlacklistAppliesToTracking:
    'DaScoopComposer__Domain_Blacklist_Applies_to_Tracking__c',
  domainBlacklist: 'DaScoopComposer__Domain_Blacklist__c',
  emailToSaleforceAssociationPolicy:
    'DaScoopComposer__Email_to_Saleforce_Association_Policy__c',
  enableCalendar: 'DaScoopComposer__Enable_Calendar__c',
  enableEasyCreateCase: 'DaScoopComposer__Enable_Easy_Create_Case__c',
  enableEasyCreateContact: 'DaScoopComposer__Enable_Easy_Create_Contact__c',
  enableEasyCreateLead: 'DaScoopComposer__Enable_Easy_Create_Lead__c',
  enableEmailTracking: 'DaScoopComposer__Enable_Email_Tracking__c',
  enableSearchSalesforceFromGmail:
    'DaScoopComposer__Enable_Search_Salesforce_from_Gmail__c',
  ignoredAttachmentTypes: 'DaScoopComposer__Ignored_Attachment_Types__c',
  maximumAttachmentSize: 'DaScoopComposer__Maximum_Attachment_Size__c',
  onlySyncCrmRelatedGoogleEvents:
    'DaScoopComposer__Only_sync_CRM_related_Google_events__c',
  reparentEmailAttachments: 'DaScoopComposer__Reparent_Email_Attachments__c',
  searchAndSelectObjects: 'DaScoopComposer__Search_and_Select_Objects__c',
  useInlineGrooveViewsLegacy:
    'DaScoopComposer__Use_Inline_Groove_Views_Legacy__c',
  useGrooveTemplatesOnly: 'DaScoopComposer__Use_Groove_Templates_Only__c',
  enableContactAutoCapture: 'DaScoopComposer__Enable_Contact_Auto_Capture__c',
};

export const CUSTOM_OBJECT_LOGGING_FEATURE_FLAG = 'custom_object_logging';
export const CUSTOM_OBJECT_LOGGING_SALESFORCE_VALUE = '30';

export const PROFILE_SETTINGS_FIELDS_OPTIONS = {
  emailToSaleforceAssociationPolicy: [
    { value: '0', label: 'No Related-to search' },
    { value: '11', label: 'My open Opportunities' },
    { value: '110', label: 'My open Opportunities*' },
    { value: '12', label: 'Any of my Opportunities' },
    { value: '120', label: 'Any of my Opportunities*' },
    { value: '13', label: 'Any open Opportunity' },
    { value: '130', label: 'Any open Opportunity*' },
    { value: '14', label: 'Any Opportunity' },
    { value: '140', label: 'Any Opportunity*' },
    { value: '21', label: 'My open Cases' },
    { value: '22', label: 'My Cases' },
    { value: '23', label: 'Any open Cases' },
    { value: '24', label: 'Any Cases' },
    {
      value: CUSTOM_OBJECT_LOGGING_SALESFORCE_VALUE,
      label: 'Custom Object Logging',
    },
  ],
  maximumAttachmentSize: [
    { value: '1048576', label: '1' },
    { value: '2097152', label: '2' },
    { value: '3145728', label: '3' },
    { value: '4194304', label: '4' },
    { value: '5242880', label: '5' },
  ],
};

export const CUSTOM_FIELDS_LIMIT = 20;
export const CUSTOM_FIELDS_LIMIT_MINUS_1 = CUSTOM_FIELDS_LIMIT - 1;
export const ABS_ENABLED_FIELD_ID = 'absEnabled';
export const ABS_SFDC_STATUS_COLUMN_NAME_ID = 'absSfdcStatusColumnName';
export const ABS_STATUS_COLUMN_SELECT_VISIBLE_UI_KEY_PATH = [
  'admin',
  'abs',
  'statusColumnSelectOpen',
];

export const ADD_UNSUBSCRIBE_LINK_ON_SEND_WITH_GROOVE_FIELD_ID =
  'addUnsubscribeSWGEnabled';

export const UNSUBSCRIBE_ENFORCED_FIELD_ID = 'unsubscribeEnabled';

export const OUT_OF_OFFICE_ENABLED_FIELD_ID = 'oooEnabled';

export const ONE_FLOW_RESTRICTION_ENABLED_FIELD_ID = 'oneFlowRestriction';

export const DIALER_ADD_PHONE_NUMBERS_DIALOG_OPEN_KEY_PATH = [
  'admin',
  'dialer',
  'addPhoneNumbersOpen',
];
export const DIALER_ADD_PHONE_NUMBERS_CONFIRM_DIALOG_OPEN_KEY_PATH = [
  'admin',
  'dialer',
  'addPhoneNumbersConfirmOpen',
];

export const DIALER_REVERSE_PHONE_LOOKUP_UI_KEY_PATH = [
  'admin',
  'dialer',
  'reversePhoneLookup',
];

export const DIALER_PHONE_NUMBERS_COLUMNS = [
  {
    id: 'friendlyName', // friendly formatted phone number
    label: 'Phone Number',
    noWrap: true,
  },
  {
    id: 'isoCountry',
    label: 'Country',
  },
  {
    id: 'city',
    label: 'City',
  },
  {
    id: 'region',
    label: 'Region',
  },
  {
    id: 'postalCode',
    label: 'ZIP',
  },
];

export const DIALER_PHONE_CALL_MONTH_KEY_FORMAT = 'MMMM YYYY';

export const DIALER_PHONE_CALLS_COLUMNS = [
  {
    id: 'createdAt',
    label: 'Date',
    sortable: false,
  },
  {
    id: 'userName',
    label: 'User',
    sortable: false,
  },
  {
    id: 'from',
    label: 'From',
    sortable: false,
  },
  {
    id: 'direction',
    label: 'Direction',
    sortable: false,
  },
  {
    id: 'to',
    label: 'To',
    sortable: false,
  },
  {
    id: 'duration',
    label: 'Duration',
    sortable: false,
  },
  {
    id: 'status',
    label: 'Status',
    sortable: false,
  },
];

export const DIALER_PHONE_CALLS_PER_PAGE = 22;
export const DIALER_PHONE_CALLS_DOWNLOAD_CSV_PER_PAGE = 300;

export const SENTIMENT_LABELS = {
  positive: 'Positive signal',
  neutral: 'Neutral signal',
  negative: 'Negative signal',
};

export const SENTIMENT_OPTIONS = [
  { label: SENTIMENT_LABELS.positive, value: 'positive' },
  { label: SENTIMENT_LABELS.neutral, value: 'neutral' },
  { label: SENTIMENT_LABELS.negative, value: 'negative' },
];

export const ACTIVITY_OUTCOME_MODES_UI_KEY_PATH = [
  'manageMyOrg',
  'activityOptcome',
  'modes',
];
export const ACTIVITY_OUTCOME_SAVING_UI_KEY_PATH = [
  'manageMyOrg',
  'activityOptcome',
  'saving',
];

export const EDIT_MODE_FIELD_ID = 'editMode';

export const NEW_RECORD_ID = -1;

export const DIALER_NUMBER_SETTINGS = {
  callerIdPhoneNumber: {
    id: 'callerIdPhoneNumber',
    requiresVerification: true,
  },
  callForwardingPhoneNumber: {
    id: 'callForwardingPhoneNumber',
    requiresVerification: false,
  },
  bridgeCallingPhoneNumber: {
    id: 'bridgeCallingPhoneNumber',
    requiresVerification: false,
  },
};

export const DIALER_PHONE_NUMBERS = {
  userVerifiedPhoneNumber: 'userVerifiedPhoneNumber',
  groovePhoneNumber: 'groovePhoneNumber',
  other: 'other',
};

// Label Settings
export const ADD_CUSTOM_LABEL_ID = 'addCustomLabel';
export const ADD_PERSONA_ID = 'addPersona';

export const CUSTOM_LABEL_GROUP_ID = 'customLabel';
export const CUSTOM_LABEL_DESCRIPTION_FIELD_ID = 'customLabelDescription';

export const PERSONA_GROUP_ID = 'persona';
export const PERSONA_BIO_FIELD_ID = 'bio';
export const PERSONA_MOTIVATION_FIELD_ID = 'motivation';
export const PERSONA_DETAILS_FIELD_ID = 'details';
export const PERSONA_NAME_FIELD_ID = 'name';
export const PERSONA_COLOR_FIELD_ID = 'color';
export const PERSONA_DETAIL_FIELDS = [
  PERSONA_BIO_FIELD_ID,
  PERSONA_MOTIVATION_FIELD_ID,
  PERSONA_DETAILS_FIELD_ID,
];
export const PERSONA_FORM_FIELDS = [
  PERSONA_NAME_FIELD_ID,
  ...PERSONA_DETAIL_FIELDS,
];

export const REMOVE_LABEL_DIALOG_OPEN = ['removeLabelDialogOpen'];

export const BRAND_CUSTOM_MEETING_ENABLED = 'brandCustomMeetingEnabled';
export const BRAND_LOGO_URL = 'brandLogoUrl';
export const BRAND_DECO_COLOR = 'brandDecoColor';
export const GROOVE_OWNER_EMAIL = 'grooveOwnerEmail';

export const DWA_ENABLED_UI_KEY_PATH = ['manageMyOrg', 'dwaEnabled'];
export const MANAGE_MY_ORG_ROOT_ID = 'manage-my-org-root';
export const MANAGE_MY_TEAMS_ROOT_ID = 'manage-my-teams-root';

export const EMAIL_ALIAS_EDITABLE_UI_KEY_PATH = [
  'account',
  'emailAliasEditable',
];

export const CSV_LIMIT_EDITABLE_UI_KEY_PATH = ['flows', 'csvLimit'];

export const SEARCH_INPUT_DEBOUNCE_TIME = 500;
export const SEARCH_INPUT_MENU_DEBOUNCE_TIME = 1000;

export const MEETING_TYPE_DETAILS_STYLE = {
  outerContainer: {
    padding: '12px 0px 12px',
  },
  title: {
    fontWeight: 500,
    paddingBottom: 2,
    fontSize: 16,
  },
  subTitle: {
    paddingBottom: 24,
    fontSize: 14,
  },
  settingContainer: {
    fontWeight: 500,
    display: 'flex',
  },
  labelColumn: {
    width: '32%',
    paddingBottom: 22,
  },
  label: {
    fontWeight: 500,
    paddingRight: 30,
  },
  linkDetails: {
    display: 'flex',
  },
  linkItem: {
    alignSelf: 'flex-start',
    padding: '6px 4px 7px',
  },
  inputTextStyle: {
    fontSize: 14,
    fontWeight: 'normal',
    height: 20,
  },
  textareaStyle: {
    fontSize: 14,
    fontWeight: 'normal',
    resize: 'auto',
  },
};

export const CUSTOM_FORM_FIELD_ID = 'customFormField';

export const SCHEDULER_GROUP_LINK_EDIT_UI_KEY_PATH = [
  'schedulerGroups',
  'linkEditable',
];

export const ROUND_ROBIN_GROUPS_DELETE_MEETING_DIALOG_OPEN_KEY_PATH = [
  'settings',
  'manageMyOrg',
  'schedulerGroups',
  'deleteDialogOpen',
];
export const ROUND_ROBIN_GROUPS_DELETE_MEETING_ID_IMPORT_KEY_PATH = [
  'settings',
  'manageMyOrg',
  'schedulerGroups',
  'meetingId',
];

export const PAUSE_FLOWS_UNTIL_UI_KEY_PATH = ['flows', 'pauseFlowsUntil'];
export const US_DATE_FORMAT = 'M/D/YYYY';

export const SITE_LICENSE_LABEL = 'Unlimited';

export const ZUORA_CUSTOMER_PORTAL = 'https://1700259.ecommerce.zuora.com/';

export const CHILD_CAMPAIGN_FIELD_ID = 'childCampaignOption';

export const SUGGESTED_ACTIONS_FIELD_ID = 'suggestedActionsEnabled';

export const SMART_EMAIL_SCHEDULING_FIELD_ID = 'smartEmailSchedulingEnabled';

export const EMAIL_EFFECTIVENESS_FIELD_ID = 'emailEffectivenessEnabled';

export const ADVANCED_SHARING_SETTING_FIELD_ID = 'MasterFlowCreation';

export const FLOW_APPROVAL_SETTINGS_FIELD_ID = 'flowApprovalSettings';

export const CLARI_DXP_CONTACT_SUPPORT_URL =
  'https://clari.my.site.com/customer/s/get-support';
export const CLARI_DXP_KNOWLEDGE_BASE_URL =
  'https://clari.my.site.com/customer/s/topiccatalog';
export const CLARI_DXP_MANAGEMENT_LICENSE_URL =
  'https://clari.my.site.com/customer/s/license-management';
export const CLARI_DXP_CUSTOMER_PORTAL_URL =
  'https://clari.my.site.com/customer/s/';
export const CLARI_UNIVERSITY_URL = 'https://university.clari.com/learn';

export const SHOW_SF_TASK_TYPE_PICKLIST = 'showSfTaskTypePicklist';
export const EDIT_SF_TASK_TYPE_PICKLIST = 'editSfTaskTypePicklist';
export const SF_TASK_TYPE_MAPPINGS = 'sfTaskTypeMappings';
export const SF_TASK_MAPPING = 'sfTaskMapping';
export const SF_EMAIL_MAPPING = 'sfEmailMapping';
export const SF_CALL_MAPPING = 'sfCallMapping';
export const SF_SMS_MAPPING = 'sfSmsMapping';
export const SF_LINKEDIN_CONNECT_MAPPING = 'sfLinkedinConnectMapping';
export const SF_LINKEDIN_INMAIL_MAPPING = 'sfLinkedinInmailMapping';
export const SF_SENDOSO_MAPPING = 'sfSendosoMapping';
export const SF_IN_PERSON_VISIT_MAPPING = 'sfInPersonVisitMapping';
