import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  ACCOUNTS: {
    FETCH_ACCOUNTS: standardActions(),
    FETCH_ACTIVITY_SNAPSHOT: standardActions(),
    TOGGLE_STAR: standardActions(),
    ...keyMirror([
      'UPDATE_QUERY',
      'UPDATE_PREVIEW_QUERY',
      'UPDATE_QUERY_AND_REFETCH',
      'UPDATE_PREVIEW_QUERY_AND_REFETCH',
      'SELECT_ACCOUNTS',
      'UNSELECT_ACCOUNTS',
      'SELECT_PEOPLE',
      'UNSELECT_PEOPLE',
      'MOVE_TO_PAGE',
      'SET_STATUS_FILTER',
      'UPDATE_SELECTED_STATUSES',
      'SET_ACTIVE_PERSON_ID',
      'UNSET_ACTIVE_PERSON_ID',
      'MOVE_TO_PREVIEW_PAGE',
      'RESET_REDUCER',
      'SET_ACTIVE_ACCOUNT_ID',
    ]),
  },
}).ACCOUNTS;

export function fetchAccountsBegin() {
  return {
    type: actionTypes.FETCH_ACCOUNTS.BEGIN,
  };
}

export function selectAccounts(accounts) {
  return {
    type: actionTypes.SELECT_ACCOUNTS,
    payload: accounts,
  };
}

export function unselectAccounts(accounts) {
  return {
    type: actionTypes.UNSELECT_ACCOUNTS,
    payload: accounts,
  };
}

export function setActiveAccountId(accountId) {
  return {
    type: actionTypes.SET_ACTIVE_ACCOUNT_ID,
    payload: accountId,
  };
}

export function updateQuery(payload) {
  return {
    type: actionTypes.UPDATE_QUERY,
    payload,
  };
}

export function updatePreviewQuery(payload) {
  return {
    type: actionTypes.UPDATE_PREVIEW_QUERY,
    payload,
  };
}

export function updateQueryAndRefetch(payload) {
  return {
    type: actionTypes.UPDATE_QUERY_AND_REFETCH,
    payload,
  };
}

export function updatePreviewQueryAndRefetch(payload) {
  return {
    type: actionTypes.UPDATE_PREVIEW_QUERY_AND_REFETCH,
    payload,
  };
}

export function moveToPage(payload) {
  return {
    type: actionTypes.MOVE_TO_PAGE,
    payload,
  };
}

export function toggleStar(payload) {
  return {
    type: actionTypes.TOGGLE_STAR.BEGIN,
    payload,
  };
}

export function setStatusFilter(payload) {
  return {
    type: actionTypes.SET_STATUS_FILTER,
    payload,
  };
}

export function selectPeople(people) {
  return {
    type: actionTypes.SELECT_PEOPLE,
    payload: { people },
  };
}

export function unselectPeople(people) {
  return {
    type: actionTypes.UNSELECT_PEOPLE,
    payload: { people },
  };
}

export function setActivePersonId(personId) {
  return {
    type: actionTypes.SET_ACTIVE_PERSON_ID,
    payload: personId,
  };
}

export function unsetActivePersonId() {
  return {
    type: actionTypes.UNSET_ACTIVE_PERSON_ID,
  };
}

export function moveToPreviewPage(payload) {
  return {
    type: actionTypes.MOVE_TO_PREVIEW_PAGE,
    payload,
  };
}
