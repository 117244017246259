import client from './client';

export type Voicemail = {
  id: number;
  name: string;
  file_url: string;
  user_id: number;
  created_at: string;
};

export type IncomingVoicemail = {
  id: number;
  file_url: string;
  name: string;
  user_id: number;
  created_at: string;
  phone_number: string;
  archived: boolean;
  clicked: boolean;
  location: string;
  sfdc_activity_id: string;
  transcription_text: string;
  who?: string;
};

type DropVoicemailOptions = {
  conferenceFriendlyName: string;
  voicemailId: number;
  phoneNumber: string;
  shouldEndUserCall: boolean;
  participantCallSid?: string;
};
type VoicemailDropResult = {
  data?: { message?: string };
  meta: {
    status: number;
    success: boolean;
  };
};

export type VoicemailTabType = 'Voicemail' | 'Archived';

export type VoicemailProps = {
  onSwitchTab: (tab: VoicemailTabType) => void;
  onVoicemailClicked: (voicemail: IncomingVoicemail | undefined) => void;
};

type VoicemailGreetingSetting = {
  voicemailGreetingSetting: boolean;
  uploadedToS3: boolean;
};

export type VoicemailGreetingSettingResponse = {
  data: {
    custom_voicemail_greeting: boolean;
    s3_presigned_url: string;
    voicemail_uploaded: boolean;
  };
};

export type VoicemailFetchResult = {
  data: Voicemail[];
};

export type IncomingVoicemailFetchResult = {
  data: IncomingVoicemail[];
  meta: {
    current_page: number;
    next_page: number | null;
    prev_page: number | null;
    total_pages: number;
    voicemail_dot: boolean;
  };
};

export type UploadVoicemailGreeting = {
  presigned_url: string;
  data: string;
};

export type voicemailGreetingParams = {
  voicemailGreetingSetting: boolean;
  uploadedToS3: boolean;
};

export type ArchiveVoicemail = {
  id: number;
  status: number;
};

export const getVoicemails = (): Promise<VoicemailFetchResult> => {
  return client.get('api/v2/voicemails').json();
};

export const getIncomingVoicemails = (
  pageParam: number | undefined,
): Promise<IncomingVoicemailFetchResult> | null => {
  if (pageParam === undefined) {
    return null;
  }
  return client.get(`api/v2/voicemails/incoming?per_page=${pageParam}`).json();
};

export const getArchivedIncomingVoicemails = (
  pageParam: number | undefined,
): Promise<IncomingVoicemailFetchResult> | null => {
  if (pageParam === undefined) {
    return null;
  }
  return client
    .get(`api/v2/voicemails/incoming_archived?per_page=${pageParam}`)
    .json();
};

export const postVMRecordingToS3 = (): Promise<UploadVoicemailGreeting> => {
  return client
    .post('api/dialer/v1/services/setting/upload_voicemail_greeting')
    .json();
};

export const voicemailGreeting = async (
  voicemailGreetingSettingOptions: VoicemailGreetingSetting,
): Promise<VoicemailGreetingSettingResponse> => {
  return client
    .post('api/dialer/v1/services/setting/voicemail_greeting', {
      json: {
        variables: {
          custom_voicemail_greeting:
            voicemailGreetingSettingOptions.voicemailGreetingSetting,
          uploaded_to_s3: voicemailGreetingSettingOptions.uploadedToS3,
        },
      },
    })
    .json();
};

export const getVoicemailGreeting =
  async (): Promise<VoicemailGreetingSettingResponse> => {
    return client
      .post('api/dialer/v1/services/setting/fetch_voicemail_greeting', {
        json: {},
      })
      .json();
  };

export const dropVoicemail = ({
  conferenceFriendlyName,
  voicemailId,
  phoneNumber,
  shouldEndUserCall,
  participantCallSid,
}: DropVoicemailOptions): Promise<VoicemailDropResult> => {
  return client
    .post('api/v2/voicemails/drop', {
      json: {
        variables: {
          conference_name: conferenceFriendlyName,
          voicemail_id: voicemailId,
          to_phone_number: phoneNumber,
          should_end_user_call: shouldEndUserCall,
          participant_call_sid: participantCallSid,
        },
      },
    })
    .json();
};
