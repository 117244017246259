import useGatewayLaunchDarkly from '@groove/api/hooks/launchdarkly/useGatewayLaunchDarkly';
import {
  useGetCurrentUser,
  useGetOrgSettings,
} from '@groove/api/hooks/useUsers';
import useGrooveMeta from '@groove/action-compose/hooks/useGrooveMeta';
import { FC } from 'react';

/**
 * A functional component that pre-fetches shared data via hooks to make loading of subsequent components much quicker
 *
 * This FC that returns no elements was written to re-use hooks within class components.
 */
const WebAppPreLoader: FC = () => {
  useGetOrgSettings();
  useGetCurrentUser();
  useGatewayLaunchDarkly();
  useGrooveMeta();
  return null;
};

export default WebAppPreLoader;
