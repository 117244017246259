import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';

const styles = {
  overlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
};

const lightGrey = 'rgba(0,0,0,0.1)';

const DisablingOverlay = ({ classes, active, color }) => {
  const style = {
    zIndex: active ? 7 : -1,
    backgroundColor: color,
  };

  return (
    <Fade in={active}>
      <div className={classes.overlay} style={style} />
    </Fade>
  );
};

DisablingOverlay.displayName = 'DisablingOverlay';
DisablingOverlay.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  active: PropTypes.bool,
  color: PropTypes.string,
};

DisablingOverlay.defaultProps = {
  active: false,
  color: lightGrey,
};

export default withStyles(styles)(DisablingOverlay);
