import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import isNil from 'lodash-es/isNil';
import { Button } from '@material-ui/core';

import ConfusedMan from 'Modules/Shared/components/Images/ConfusedMan';
import { triggerShowReportDialog } from 'Modules/Shared/actions/errors';
import { getPreviousPath } from 'Modules/App/selectors';
import { getPathName } from 'Utils/history';

const useStyles = makeStyles({
  singleButtonContainer: {
    paddingTop: 30,
    width: 322,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  multiButtonContainer: {
    paddingTop: 30,
    width: 322,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageContainer: {
    paddingTop: 35,
  },
  title: {
    paddingTop: 15,
    size: 16,
    fontWeight: 700,
  },
  message: {
    padding: '12px 20px 0px 20px',
    size: 16,
    fontWeight: 400,
    textAlign: 'center',
  },
  buttonRoot: {
    textTransform: 'none',
  },
});

const Error: React.FC<ErrorPropTypes> = ({
  showRecoveryButton,
  showSubmitFeedbackButton,
  onRecovery,
  recoveryButtonText,
  primaryText,
  secondaryText,
  icon,
}) => {
  const {
    multiButtonContainer,
    singleButtonContainer,
    buttonRoot,
    imageContainer,
    title,
    message,
  } = useStyles();
  const dispatch = useDispatch();

  const previousPath = useSelector(getPreviousPath);
  const renderSubmitFeedbackButton =
    showSubmitFeedbackButton && !isNil(Sentry.lastEventId());
  const showMultiButtons = showRecoveryButton && renderSubmitFeedbackButton;
  const buttonContainerStyle = showMultiButtons
    ? multiButtonContainer
    : singleButtonContainer;

  const handleRecovery = () => {
    if (onRecovery) {
      onRecovery();
    } else if (previousPath) {
      window.location.assign(`${process.env.PUBLIC_URL}${previousPath}`);
    } else if (getPathName() === '/error') {
      window.location.assign(`${process.env.PUBLIC_URL}`);
    } else {
      window.location.reload();
    }
  };

  const handleSubmitFeedback = () => {
    dispatch(triggerShowReportDialog());
  };

  const getReloadPageButton = () => {
    if (showMultiButtons) {
      return (
        <Button className={buttonRoot} onClick={handleRecovery}>
          {recoveryButtonText}
        </Button>
      );
    }
    return (
      <Button
        className={buttonRoot}
        variant="contained"
        color="primary"
        onClick={handleRecovery}
      >
        {recoveryButtonText}
      </Button>
    );
  };

  return (
    <>
      <div className={imageContainer}>{icon}</div>
      <div className={title}>{primaryText}</div>
      <div className={message}>{secondaryText}</div>
      <div className={buttonContainerStyle}>
        {showRecoveryButton && getReloadPageButton()}
        {renderSubmitFeedbackButton && (
          <Button
            className={buttonRoot}
            variant="contained"
            color="primary"
            onClick={handleSubmitFeedback}
          >
            Submit Feedback
          </Button>
        )}
      </div>
    </>
  );
};

type ErrorPropTypes = {
  showRecoveryButton?: boolean;
  showSubmitFeedbackButton?: boolean;
  primaryText?: string;
  onRecovery?: () => void;
  secondaryText?: string;
  recoveryButtonText?: string;
  previousPath?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
};

Error.defaultProps = {
  showRecoveryButton: false,
  showSubmitFeedbackButton: false,
  primaryText: 'Something went wrong',
  onRecovery: null,
  secondaryText: 'We logged the error and are looking into it',
  recoveryButtonText: 'Reload Page',
  previousPath: '',
  icon: <ConfusedMan />,
};

export default Error;
