import client from './client';
import { ApiToken } from './types';

const refresh = async (): Promise<ApiToken> =>
  client
    .post('token', {
      searchParams: { grant_type: 'refresh_token' },
      body: new URLSearchParams({
        client_id: process.env.GROOVE_ENGINE_OAUTH_CLIENT_ID!,
        client_secret: process.env.GROOVE_ENGINE_OAUTH_CLIENT_SECRET!,
        refresh_token: localStorage.getItem('refreshToken')!,
        redirect_uri: 'groove-dialer://auth',
      }),
    })
    .json();

export default refresh;
