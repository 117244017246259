/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useRef } from 'react';
import { motion } from 'framer-motion';
import { PrimaryButton } from '@fluentui/react';
import cx from 'classnames';

type PicklistDropDownProps = {
  showDropDown: boolean;
  showShadow?: boolean;
  width?: string | number;
  maxHeight?: number;
  xDirection: string;
  yDirectionOuter: string;
  yDirectionInner: string;
  onClose?: () => void;
  onSave?: () => void;
};

// This functional overload is to make sure that users can only use one typing in the Union
const PicklistDropdown: FC<PicklistDropDownProps> = ({
  showDropDown,
  width = '100%',
  maxHeight: mHeight = 600,
  xDirection = 'right-0',
  yDirectionOuter = 'bottom-0',
  yDirectionInner = 'top-0',
  showShadow = false,
  onClose,
  onSave,
  children,
}) => {
  const childrenRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={cx('h-0 relative w-full', xDirection, yDirectionOuter)}>
        <motion.div
          className={cx(
            'relative z-50 bg-white border-0.5 border-gray-300 rounded shadow-md w-full',
            xDirection,
            yDirectionInner,
            !showDropDown && 'overflow-hidden',
            showShadow && 'drop-shadow-md',
          )}
          animate={{
            opacity: showDropDown ? 1 : 0,
            y: showDropDown ? 10 : 0,
            x: showDropDown ? -width : 0,
            width: showDropDown ? width : 0,
            height: 'auto',
            maxHeight: mHeight,
            minHeight: 400,
            transformOrigin: 'right top',
          }}
          initial={{ opacity: 0, y: 10, width: 0, height: 0 }}
          transition={{ duration: 0.15 }}
        >
          <div ref={childrenRef}>{children}</div>
          <div className="flex flex-row justify-end p-1 gap-x-1">
            <PrimaryButton
              className="p-1 border-solid border-[1px] hover:border-solid hover:border-[1px] hover:border-neutral/900 bg-white hover:bg-white text-clari-blue/600 hover:text-clari-blue/700"
              onClick={onClose}
            >
              Close
            </PrimaryButton>
            <PrimaryButton className="p-1" onClick={onSave}>
              Apply
            </PrimaryButton>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default PicklistDropdown;
