import React from 'react';
import PropTypes from 'prop-types';
import UserActionDialog from 'Modules/Shared/components/UserActionDialog';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ClariTheme from 'Utils/theme/ClariTheme';

const LeavePageConfirmation = props => {
  const handleConfirmation = () => props.handleOk();

  const userAcProps = {
    title: 'Are you sure you want to leave? Any unsaved progress will be lost',
    buttons: [
      { text: 'ok', onClick: handleConfirmation },
      {
        text: 'cancel',
        onClick: props.handleCancel,
        color: ClariTheme.palette.text.secondary,
      },
    ],
  };

  return <UserActionDialog {...userAcProps} />;
};

LeavePageConfirmation.displayName = 'LeavePageConfirmation';
LeavePageConfirmation.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  confirmationStatus: ImmutablePropTypes.mapContains({
    show: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    nextRoute: PropTypes.string,
  }).isRequired,
};

export default LeavePageConfirmation;
