import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { IDropdownOption } from '@fluentui/react';
import { getActionsAssignees } from '@groove/api/gateway/v1/actions';

export default function useGetAssigneesOptions(): IDropdownOption[] {
  const [assignees, setAssignees] = useState<IDropdownOption[]>([]);
  const { data: rawAssignees } = useQuery('assignees', getActionsAssignees);

  useEffect(() => {
    setAssignees(
      rawAssignees?.data.map(user => ({
        key: user.assignee_id.toString(),
        ariaLabel: user.name,
        text: user.name,
      })) || [],
    );
  }, [rawAssignees]);

  return assignees;
}
