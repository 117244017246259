import { GetState, SetState } from 'zustand';
import { Message, MessageLevel } from '@groove/ui/Components/MessageBar';
import { isEqual } from 'lodash-es';
import produce from 'immer';

import { Store } from './useStore';

export const messagePriorityHash: Record<MessageLevel, number> = {
  loading: 5,
  success: 4,
  error: 3,
  warning: 2,
  info: 1,
};

export type ErrorType = {
  id: string;
  readableLocation?: string;
};

export type AddMessageOptions = {
  isMessageAtTop?: boolean; // Defaults to false
  messageTimer?: number; // In number of milliseconds it takes to either remove or move the message to normal location.  Defaults to 0/infinity.
  shouldKeepAfterTimer?: boolean; // If true, we keep the message in the array of messages after the timer runs out, otherwise we remove it from the list. Defaults to false
};

export type MessageBarStore = {
  messages: Message[];
  saveErrors: ErrorType[];
  executionErrors: ErrorType[];
  clearMessages: () => void;
  clearErrorByPrefix: (idPrefix: string) => void;
  setRequestErrorMessage: (id: string, message: string) => void;
  addMessage: (message: Message, options?: AddMessageOptions) => void;
  removeMessage: (messageId: string) => void;
  addExecutionError: (location: ErrorType) => void;
  removeExecutionError: (location: string) => void;
  addSaveError: (location: ErrorType) => void;
  removeSaveError: (location: string) => void;
};

export const messageBarStore = (
  set: SetState<Store>,
  get: GetState<Store>,
): MessageBarStore => ({
  messages: [],
  saveErrors: [],
  executionErrors: [],

  /**
   * Clears the messages stored in the store and gets it ready for a new Action
   * @returns
   */
  clearMessages: () =>
    set({
      messages: [],
      saveErrors: [],
      executionErrors: [],
    }),
  /**
   * Clears all errors / messages that have a specific id prefix
   */
  clearErrorByPrefix: (idPrefix: string) => {
    const filteredExecutionErrors = get().executionErrors.filter(
      executionError => !executionError.id.startsWith(idPrefix),
    );
    const filteredMessages = get().messages.filter(
      message => !message.id.startsWith(idPrefix),
    );
    const filteredSaveErrors = get().saveErrors.filter(
      saveError => !saveError.id.startsWith(idPrefix),
    );
    set({
      executionErrors: filteredExecutionErrors,
      messages: filteredMessages,
      saveErrors: filteredSaveErrors,
    });
  },
  setRequestErrorMessage: (id, message) =>
    get().addMessage({
      level: 'error',
      message,
      id,
    }),
  /**
   * Add a message to the message array
   * @param newMessage Of type Message, ids are unique, if they are the same, they will replace the previous message
   */
  addMessage: (newMessage, options) => {
    let messages = [...get().messages];

    const priority = messagePriorityHash[newMessage.level];
    let index = 0;

    if (!options?.isMessageAtTop) {
      index = messages.findIndex(message => message.id === newMessage.id);

      if (index > -1) {
        messages[index] = newMessage;
      } else {
        index = messages.findIndex(
          message => priority >= messagePriorityHash[message.level],
        );
        messages.splice(index > -1 ? index : messages.length, 0, newMessage);
      }
    } else {
      messages = [newMessage, ...messages];
    }
    set({ messages });

    if (options?.messageTimer) {
      setTimeout(() => {
        if (!options.shouldKeepAfterTimer) get().removeMessage(newMessage.id);
        else get().addMessage(newMessage);
      }, options?.messageTimer);
    }
  },
  removeMessage: messageId => {
    const { messages } = get();
    set({ messages: messages.filter(message => message.id !== messageId) });
  },
  /**
   * Adds a execution error
   * @param newError An object with a Human readable location of where the error is occurring, which is joined together to make a tooltip and an unique id
   * @returns void
   */
  addExecutionError: newError =>
    set(
      produce((draft: Store) =>
        addErrorHelper(draft.executionErrors, newError),
      ),
    ),
  /**
   * Removes an execution error
   * @param id the id of the execution error
   * @returns void
   */
  removeExecutionError: id =>
    set({
      executionErrors: get().executionErrors.filter(error => error.id !== id),
    }),
  /**
   * Adds a save error * @param newError An object with a Human readable location of where the error is occurring, which is joined together to make a tooltip and an unique id
   * @returns void
   */
  addSaveError: newError =>
    set(produce((draft: Store) => addErrorHelper(draft.saveErrors, newError))),
  /**
   * Removes a save error
   * @param id the id of the save error
   * @returns void
   */
  removeSaveError: id =>
    set({ saveErrors: get().saveErrors.filter(error => error.id !== id) }),
});

/**
 * This helper adds a new error to the Error array
 * @param errors ErrorType[] - the immer array of Errors that you want to add the new error to
 * @param newError ErrorType - the new error that needs to be added
 */
const addErrorHelper = (errors: ErrorType[], newError: ErrorType): void => {
  const index = errors.findIndex(error => newError.id === error.id);

  if (index > 0 && !isEqual(newError, errors[index])) {
    errors.splice(index, 1, newError);
  } else if (index === -1) {
    errors.push(newError);
  }
};

export const messagesHashed = (messages: ErrorType[]): string => {
  return messages.map(message => message.id).join(':');
};
