import cx from 'classnames';
import { selectors, actions, RIT } from '@groove-labs/groove-ui';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import {
  Business as BusinessIcon,
  InsertChart as InsertChartIcon,
  Mail as MailIcon,
  People as PeopleIcon,
  Home as HomeIcon,
  Phone as PhoneIcon,
  PlayCircleFilled as ActionsIcon,
  Explore as ExploreIcon,
  Subscriptions as SubscriptionsIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DisablingOverlay from 'Modules/Shared/components/DisablingOverlay';
import SideBarNavMenuItem from 'Modules/App/containers/SideBarNavMenuItem';
import { createFeatureFlagSelector } from 'Modules/Shared/selectors/featureFlags';
import { hasFlowsEnabled, getAppUpstartSucceeded } from 'Modules/App/selectors';
import { LEAVE_PAGE_CONFIRMATION_KEY_PATH } from 'Modules/Shared/constants';
import { isUserHealthy } from 'Modules/Shared/selectors/users/health';
import { shouldEnableCallCoaching } from 'Modules/Coaching/selectors';
import { getDialerEnabled } from 'Modules/Shared/selectors/dialer';
import { getEditorHasBeenModified } from 'Modules/Shared/selectors/ui';
import LeavePageConfirmation from 'Modules/Shared/containers/LeavePageConfirmation';
import { history } from 'Utils/history';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import RoundedEdgesSpacesIcon from 'Modules/Shared/icons/RoundedEdgesSpacesIcon';
import OutcomesIcon from 'Modules/Shared/icons/OutcomesIcon';
import { getLdClient } from 'Utils/ldClient';

const { getProperty } = selectors.ui;
const { setProperty } = actions.ui;

const coachingFeatureFlag = createFeatureFlagSelector('coaching');

const SIDEBAR_NAV_LEAVE_CONFIRMATION = 'sidebarNavLeaveConfirmation';
export const SIDE_BAR_NAV_WIDTH = 80;

const styles = theme => ({
  root: {
    position: 'fixed',
    height: '100vh',
    width: SIDE_BAR_NAV_WIDTH,
    backgroundColor: theme.palette.text.neutralColor,
    padding: 0,
    zIndex: 5,
    'border-right': `1px solid ${theme.palette.text.borders}`,
  },
  innerRoot: {
    flex: '1 1 auto',
    overflow: 'auto',
  },
  grooveIcon: {
    transform: 'scale(0.55)',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: 0,
    transition: 'opacity 0.3s',
    pointerEvents: 'none',
  },
  listVisible: {
    opacity: 1,
    pointerEvents: 'auto',
    paddingTop: 8,
  },
});

class SidebarNav extends PureComponent {
  static SIDEBAR_NAV_LEAVE_CONFIRMATION = 'sidebarNavLeaveConfirmation';

  constructor(props) {
    super(props);

    this.homeIcon = HomeIcon;
    this.flowsIcon = ExploreIcon;
    this.mailIcon = MailIcon;
    this.insertChartIcon = InsertChartIcon;
    this.peopleIcon = PeopleIcon;
    this.accountsIcon = BusinessIcon;
    this.spacesIcon = RoundedEdgesSpacesIcon;
    this.phoneIcon = PhoneIcon;
    this.actionsIcon = ActionsIcon;
    this.SubscriptionsIcon = SubscriptionsIcon;
    this.outcomesIcon = OutcomesIcon;
  }

  handleRouteChange = (route, isMetaKey) => {
    const { editorHasBeenModified } = this.props;
    if (!editorHasBeenModified) {
      this.handleNewRoute(route, isMetaKey);
    } else {
      this.openLeaveConfirmationDialog(route);
    }
  };

  handleNewRoute(route, isMetaKey) {
    const { leavePageConfirmationData } = this.props;
    if (isMetaKey) {
      this.handleMetaRoute(route);
    } else if (leavePageConfirmationData.get('show')) {
      this.handleNormalRoute(route);
    } else {
      history.push(route);
    }
  }

  buildNewRoute(route) {
    const { leavePageConfirmationData } = this.props;
    if (leavePageConfirmationData.get('show')) {
      return leavePageConfirmationData.get('nextRoute');
    }
    return route;
  }

  handleMetaRoute(route) {
    const { leavePageConfirmationData } = this.props;
    if (leavePageConfirmationData) {
      let nextRoute;
      if (leavePageConfirmationData.get('show')) {
        nextRoute = leavePageConfirmationData.get('nextRoute');
      } else {
        nextRoute = route;
      }
      window.open(`${window.location.origin}${nextRoute}`);
    }
  }

  handleNormalRoute(route) {
    const { leavePageConfirmationData } = this.props;
    let nextRoute;
    if (leavePageConfirmationData.get('show')) {
      nextRoute = leavePageConfirmationData.get('nextRoute');
    } else {
      nextRoute = route;
    }
    history.push(nextRoute);
  }

  shouldShowLeaveConfirmationDialog() {
    const { leavePageConfirmationData } = this.props;
    return (
      leavePageConfirmationData.get('show') &&
      leavePageConfirmationData.get('type') === SIDEBAR_NAV_LEAVE_CONFIRMATION
    );
  }

  openLeaveConfirmationDialog = route => {
    this.props.setProperty({
      uiKeyPath: LEAVE_PAGE_CONFIRMATION_KEY_PATH,
      data: {
        show: true,
        nextRoute: route,
        type: SIDEBAR_NAV_LEAVE_CONFIRMATION,
      },
    });
  };

  closeDialog = () => this.handleNewRoute();

  render() {
    const {
      classes,
      flowsEnabled,
      isCoachingActive,
      appUpstartSucceeded,
      disabled,
      isDialerEnabled,
    } = this.props;

    const isCIEnabled = getLdClient()?.variation('Conversation_Intelligence');

    const playBuilderEnabled =
      getLdClient()?.variation('play-builder') &&
      getLdClient()?.variation('plays');

    return (
      <React.Fragment>
        {RIT(
          this.shouldShowLeaveConfirmationDialog(),
          <LeavePageConfirmation onConfirm={this.closeDialog} />
        )}
        <div className={classes.root}>
          <DisablingOverlay active={disabled} />
          <div className={classes.innerRoot}>
            <List
              className={cx({
                [classes.list]: true,
                [classes.listVisible]: appUpstartSucceeded,
              })}
            >
              <SideBarNavMenuItem
                handleRouteChange={this.handleRouteChange}
                key="home"
                primaryText="Home"
                route="/"
                routeName="home"
                icon={this.homeIcon}
              />
              <SideBarNavMenuItem
                handleRouteChange={this.handleRouteChange}
                key="actions"
                primaryText="Actions"
                route="/actions"
                routeName="actions"
                icon={this.actionsIcon}
              />
              {RIT(
                flowsEnabled,
                <SideBarNavMenuItem
                  handleRouteChange={this.handleRouteChange}
                  key="flows"
                  primaryText="Flows"
                  route="/flows"
                  routeName="flows"
                  icon={this.flowsIcon}
                />
              )}
              <SideBarNavMenuItem
                handleRouteChange={this.handleRouteChange}
                key="templates"
                primaryText="Templates"
                route="/templates"
                routeName="templates"
                icon={this.mailIcon}
              />
              <SideBarNavMenuItem
                handleRouteChange={this.handleRouteChange}
                key="accounts"
                primaryText="Accounts"
                route="/accounts"
                routeName="accounts"
                icon={this.accountsIcon}
              />
              <SideBarNavMenuItem
                handleRouteChange={this.handleRouteChange}
                key="people"
                primaryText="People"
                route="/people"
                routeName="people"
                icon={this.peopleIcon}
              />
              <SideBarNavMenuItem
                handleRouteChange={this.handleRouteChange}
                key="spaces"
                primaryText="Spaces"
                route="/spaces"
                routeName="spaces"
                icon={this.spacesIcon}
              />
              {isCIEnabled && (
                <SideBarNavMenuItem
                  handleRouteChange={this.handleRouteChange}
                  key="conversations"
                  primaryText="Conversations"
                  route="/conversations"
                  routeName="conversations"
                  icon={this.SubscriptionsIcon}
                />
              )}
              {isCoachingActive && (
                <SideBarNavMenuItem
                  handleRouteChange={this.handleRouteChange}
                  key="coaching"
                  primaryText="Coaching"
                  route="/coaching"
                  routeName="coaching"
                  icon={this.phoneIcon}
                  disabled={!isDialerEnabled}
                  tooltip={{
                    show: !isDialerEnabled,
                    text: 'You do not have an OmniDialer license which is required to view this page. Please contact your Groove Admin to assign you a license.',
                  }}
                />
              )}
              <SideBarNavMenuItem
                handleRouteChange={this.handleRouteChange}
                key="analytics"
                primaryText="Analytics"
                route="/analytics"
                routeName="analytics"
                icon={this.insertChartIcon}
              />
              <SideBarNavMenuItem
                handleRouteChange={this.handleRouteChange}
                key="outcomes"
                primaryText="Outcomes"
                route="/outcomes"
                routeName="outcomes"
                icon={this.outcomesIcon}
              />
              {playBuilderEnabled && (
                <SideBarNavMenuItem
                  handleRouteChange={this.handleRouteChange}
                  key="plays"
                  primaryText="Plays"
                  route="/playbooks"
                  routeName="playbooks"
                  icon={({ style }) => (
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      style={style}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.998 6.266A3.752 3.752 0 1 1 12.5 9.943v2.073h3.25a2.25 2.25 0 0 1 2.25 2.25v.824a3.753 3.753 0 1 1-1.5.001v-.825a.75.75 0 0 0-.75-.75h-8a.75.75 0 0 0-.75.75v.824a3.753 3.753 0 1 1-1.5.001v-.825a2.25 2.25 0 0 1 2.25-2.25H11V9.943a3.754 3.754 0 0 1-3.002-3.677z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                />
              )}
            </List>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SidebarNav.displayName = 'SidebarNav';
SidebarNav.propTypes = {
  flowsEnabled: PropTypes.bool.isRequired,
  isCoachingActive: PropTypes.bool.isRequired,
  appUpstartSucceeded: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    grooveIcon: PropTypes.string.isRequired,
    innerRoot: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
    listVisible: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
  }).isRequired,
  leavePageConfirmationData: ImmutablePropTypes.mapContains({
    show: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    nextRoute: PropTypes.string.isRequired,
  }).isRequired,
  editorHasBeenModified: PropTypes.bool.isRequired,
  setProperty: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(
  connect(
    state => ({
      isCoachingActive:
        coachingFeatureFlag(state) && shouldEnableCallCoaching(state),
      flowsEnabled: hasFlowsEnabled(state),
      appUpstartSucceeded: getAppUpstartSucceeded(state),
      disabled: !isUserHealthy(state),
      editorHasBeenModified: getEditorHasBeenModified(state),
      leavePageConfirmationData: getProperty(
        state,
        LEAVE_PAGE_CONFIRMATION_KEY_PATH,
        Map({
          show: false,
          type: SIDEBAR_NAV_LEAVE_CONFIRMATION,
          nextRoute: '/',
        })
      ),
      isDialerEnabled: getDialerEnabled(state),
    }),
    { setProperty }
  )(SidebarNav)
);
