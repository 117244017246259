import { createActions, keyMirror } from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SHARED: {
    LOCATION: keyMirror([
      'UPDATE',
      'START_CHANGE',
      'FINISH_CHANGE',
      'SET_PAGE_SCROLL',
      'MERGE_SEARCH_PARAMS',
      'SET_SEARCH_PARAMS',
      'SET_ACTIVE_TAB',
      'ADD_SEARCH_PARAM_TO_URL',
    ]),
  },
}).SHARED.LOCATION;

export const update = payload => {
  return {
    type: actionTypes.UPDATE,
    payload,
  };
};

export const startChange = () => {
  return {
    type: actionTypes.START_CHANGE,
  };
};

export const finishChange = () => {
  return {
    type: actionTypes.FINISH_CHANGE,
  };
};

export const setPageScroll = ({ routeName, scroll }) => {
  return {
    type: actionTypes.SET_PAGE_SCROLL,
    payload: { routeName, scroll },
  };
};

export function mergeSearchParams({ searchParams }) {
  return {
    type: actionTypes.MERGE_SEARCH_PARAMS,
    payload: { searchParams },
  };
}

export function setSearchParams({ searchParams }) {
  return {
    type: actionTypes.SET_SEARCH_PARAMS,
    payload: { searchParams },
  };
}

// extraQueryParameters - Optional - a Map of additional query parameters
export function setActiveTab({
  activeTab,
  clearAllOtherParameters,
  skipSettingQueryParameter,
  extraQueryParameters,
}) {
  return {
    type: actionTypes.SET_ACTIVE_TAB,
    payload: {
      activeTab,
      clearAllOtherParameters,
      skipSettingQueryParameter,
      extraQueryParameters,
    },
  };
}

export const handleSettingQueryParameter = ({ key, value }) => ({
  type: actionTypes.ADD_SEARCH_PARAM_TO_URL,
  payload: { key, value },
});
