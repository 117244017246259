import GrooveViewObject from '@groove/api/visualforce/types/GrooveContext/GrooveViewObject';

import { DisplayableSearchResult } from './transformSearchResult';
import { getDisplayAttributeValue } from './WhatList';

const transformGrooveViewObject = (
  result: GrooveViewObject,
): DisplayableSearchResult => {
  const displayableSearchResult = {
    id: result.attributes?.id,
    type: result.attributes?.type,
  } as DisplayableSearchResult;

  if (!result.attributes) {
    return displayableSearchResult;
  }

  switch (result.attributes?.type) {
    case 'account':
      return {
        ...displayableSearchResult,
        text: result.attributes.name || '<No Name>',
        secondaryText: getDisplayAttributeValue(result, 'type'),
        secondaryTextLabel: 'Type',
        tertiaryText: getDisplayAttributeValue(result, 'industry'),
        tertiaryTextLabel: 'Industry',
      };
    case 'opportunity':
      return {
        ...displayableSearchResult,
        text: result.attributes.name || '<No Name>',
        secondaryText: getDisplayAttributeValue(result, 'accountid'),
        secondaryTextLabel: 'Account',
        tertiaryText: getDisplayAttributeValue(result, 'stagename'),
        tertiaryTextLabel: 'Stage',
        quinaryText: getDisplayAttributeValue(result, 'type'),
        quinaryTextLabel: 'Type',
        quarteraryText: getDisplayAttributeValue(result, 'closedate'),
        quarteraryTextLabel: 'Close Date',
      };
    default:
      return {
        ...displayableSearchResult,
        text: result.attributes.name || '<No Name>',
      };
  }
};

export default transformGrooveViewObject;
