import { actions } from '@groove-labs/groove-ui';
import {
  show as getEntity,
  setAccessControl,
} from 'GrooveHTTPClient/accessControls/entities';
import { update as updateFlow } from 'GrooveHTTPClient/flows';
import { List } from 'immutable';
import { concat, difference, isNull, isUndefined } from 'lodash-es';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import {
  actionTypes,
  loadData,
  setLoading,
  updateSuccessful,
} from 'Modules/SharingDialog/actions';
import {
  AVAILABLE_ACTORS_KEY_PATH,
  CURRENT_ACTORS_KEY_PATH,
  IS_MASTER_FLOW_KEY_PATH,
  READ_SCOPE,
} from 'Modules/SharingDialog/constants';
import {
  canonicalCurrentActors,
  canonicalIsMasterFlow,
  currentActors,
  isMasterFlow,
  makeGetCanonicalActor,
} from 'Modules/SharingDialog/selectors';
import { actorUniqueId, testActorEquality } from 'Modules/SharingDialog/utils';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import listToOrderedMap from 'Utils/list-to-ordered-map';

const { batchSetProperty, deleteProperty, setProperty } = actions.ui;

// -------------- Handlers --------------
function* upstart({
  payload: { isOpenUiKeyPath, entityType, entityId, isMasterFlow },
}) {
  const response = yield call(getEntity, {
    entityType,
    entityId,
  });

  const buildActorUniqueId = actor => {
    const { type, id } = actor;
    return actorUniqueId(type, id);
  };
  const scopes = new List(response.data.scopes);
  const currentActors = listToOrderedMap(
    response.data.currentActors,
    buildActorUniqueId
  );
  const availableActors = listToOrderedMap(
    response.data.availableActors,
    buildActorUniqueId
  );

  yield put(
    batchSetProperty([
      {
        uiKeyPath: AVAILABLE_ACTORS_KEY_PATH,
        data: availableActors,
      },
      {
        uiKeyPath: CURRENT_ACTORS_KEY_PATH,
        data: currentActors,
      },
    ])
  );

  if (!isNull(isMasterFlow)) {
    yield put(
      setProperty({
        uiKeyPath: IS_MASTER_FLOW_KEY_PATH,
        data: isMasterFlow,
      })
    );
  }

  yield put(
    loadData({
      isOpenUiKeyPath,
      entityType,
      entityId,
      scopes,
      currentActors,
      availableActors,
      isMasterFlow,
    })
  );

  // Toggle loading to false, we're ready to rock!
  yield put(setLoading(false));
}

function* teardown() {
  yield put(deleteProperty({ uiKeyPath: ['SharingDialog'] }));
}

function* addCurrentActor({ payload: actor }) {
  const actorType = actor.get('type');
  const actorId = actor.get('id');
  const actorKeyPathId = actorUniqueId(actorType, actorId);

  // Remove from ephemeral available actors
  yield put(
    deleteProperty({
      uiKeyPath: [...AVAILABLE_ACTORS_KEY_PATH, actorKeyPathId],
    })
  );

  // Add to current actors using canonical data. This discards any changes that were made to the
  // ephemeral data, making the remove button act as a "revert" to any changes that were made.
  const selectCanonicalActor = makeGetCanonicalActor(actorType, actorId);
  let canonicalActor = yield select(selectCanonicalActor);

  // If the actor had no scopes, add the READ scope to start
  if (canonicalActor.get('scopes').isEmpty()) {
    canonicalActor = canonicalActor.set('scopes', [READ_SCOPE]);
  }

  yield put(
    setProperty({
      uiKeyPath: [...CURRENT_ACTORS_KEY_PATH, actorKeyPathId],
      data: canonicalActor,
    })
  );
}

function* removeCurrentActor({ payload: actor }) {
  const actorType = actor.get('type');
  const actorId = actor.get('id');
  const actorKeyPathId = actorUniqueId(actorType, actorId);

  // Remove from current actors
  yield put(
    deleteProperty({
      uiKeyPath: [...CURRENT_ACTORS_KEY_PATH, actorKeyPathId],
    })
  );

  // Add to available actors
  yield put(
    setProperty({
      uiKeyPath: [...AVAILABLE_ACTORS_KEY_PATH, actorKeyPathId],
      data: actor,
    })
  );
}

function* updateCurrentActor({ payload: { actorType, actorId, scopes } }) {
  yield put(
    setProperty({
      uiKeyPath: [
        ...CURRENT_ACTORS_KEY_PATH,
        actorUniqueId(actorType, actorId),
        'scopes',
      ],
      data: scopes,
    })
  );
}

function* saveChanges() {
  const entityType = yield select(state =>
    state.getIn(['SharingDialog', 'entityType'])
  );
  const entityId = yield select(state =>
    state.getIn(['SharingDialog', 'entityId'])
  );

  const source = yield select(currentActors);
  const canonical = yield select(canonicalCurrentActors);
  const sourceKeys = source.keySeq().toArray();
  const canonicalKeys = canonical.keySeq().toArray();

  // Calculate keys for added and removed Actors
  const addedActorKeys = difference(sourceKeys, canonicalKeys);
  const removedActorKeys = difference(canonicalKeys, sourceKeys);

  // Calculate keys for source actors that have changed from canonical
  const changedActorKeys = source
    .entrySeq()
    .reduce((accumulator, [key, actor]) => {
      // Ignore known added/removed actors
      if (addedActorKeys.includes(key)) return accumulator;
      if (removedActorKeys.includes(key)) return accumulator;

      if (!testActorEquality(actor, canonical.get(key))) {
        accumulator.push(key);
      }

      return accumulator;
    }, []);

  const calls = [];

  concat(addedActorKeys, changedActorKeys).forEach(key => {
    const actor = source.get(key);
    const actorType = actor.get('type');
    const actorId = actor.get('id');
    const scopes = actor.get('scopes');

    calls.push(
      setAccessControl({
        entityType,
        entityId,
        actorType,
        actorId,
        scopes,
      })
    );
  });

  removedActorKeys.forEach(key => {
    const actor = canonical.get(key);
    const actorType = actor.get('type');
    const actorId = actor.get('id');

    // Allow scopes to reset to empty array by excluding it from parameters.
    calls.push(
      setAccessControl({
        entityType,
        entityId,
        actorType,
        actorId,
      })
    );
  });

  // Check if sharing settings for flow changed, if the entity type is a flow.
  const ephemeralIsMasterFlow = yield select(isMasterFlow);
  if (!isUndefined(ephemeralIsMasterFlow)) {
    const originalIsMasterFlow = yield select(canonicalIsMasterFlow);
    if (ephemeralIsMasterFlow !== originalIsMasterFlow) {
      calls.push(
        updateFlow(entityId, {
          is_master_flow: ephemeralIsMasterFlow,
        })
      );
    }
  }

  // Set the SharingDialog UI state back to loading
  yield put(setLoading(true));

  // Wait for all API calls to resolve
  yield all(calls);

  // Send out a snackbar to inform the user that the sharing settings have been updated
  yield put(
    pushSnackbarMessage({
      message: 'Sharing settings saved',
    })
  );

  // Dispatch an action to signify an entity has been changed.
  // Any saga can subscribe to this action to update it's canonical data
  yield put(
    updateSuccessful({
      entityId,
      entityType,
    })
  );

  // Finally, close the modal by updated isOpenUiKeyPath in UI state
  const isOpenUiKeyPath = yield select(state =>
    state.getIn(['SharingDialog', 'isOpenUiKeyPath'])
  );
  yield put(
    setProperty({
      uiKeyPath: isOpenUiKeyPath,
      data: false,
    })
  );
}

// -------------- Watchers --------------
function* watchUpstart() {
  yield takeEvery(actionTypes.UPSTART, upstart);
}

function* watchTeardown() {
  yield takeEvery(actionTypes.TEARDOWN, teardown);
}

function* watchAddCurrentActor() {
  yield takeEvery(actionTypes.ADD_CURRENT_ACTOR, addCurrentActor);
}

function* watchRemoveCurrentActor() {
  yield takeEvery(actionTypes.REMOVE_CURRENT_ACTOR, removeCurrentActor);
}

function* watchUpdateCurrentActor() {
  yield takeEvery(actionTypes.UPDATE_CURRENT_ACTOR, updateCurrentActor);
}

function* watchSaveChanges() {
  yield takeEvery(actionTypes.SAVE_CHANGES, saveChanges);
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([
    fork(watchUpstart),
    fork(watchTeardown),
    fork(watchAddCurrentActor),
    fork(watchRemoveCurrentActor),
    fork(watchUpdateCurrentActor),
    fork(watchSaveChanges),
  ]);
}
