import getClient from './getClient';

export type ApiFetchEmailSignature = {
  email_alias_meta: {
    email_alias?: '';
    email_alias_name?: '';
  };
  email_signature: string;
};

export type FetchEmailSignatureParams = {
  alias: string;
};

export const fetchEmailSignature = ({
  alias,
}: FetchEmailSignatureParams): Promise<ApiFetchEmailSignature> => {
  return getClient()
    .get('settings/fetch_email_signature', { searchParams: { alias } })
    .json();
};
