import client from '../client';

export type ApiSession = {
  csrf_token: string;
};

const session = async (): Promise<ApiSession> =>
  client
    .post('api/dialer/session', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
    .json();

export default session;
