import { put, select } from 'redux-saga/effects';
import {
  addLabelInstances as addFlowLabelInstances,
  removeLabelInstances as removeFlowLabelInstances,
} from 'Modules/FlowsShow/actions';
import {
  addLabelInstances as addTemplateLabelInstances,
  removeLabelInstances as removeTemplateLabelInstances,
} from 'Modules/Templates/actions';
import { LABELABLE_TYPES } from 'Modules/Shared/constants/labels';
import listToOrderedMap from 'Utils/list-to-ordered-map';
import { getActiveTemplate } from 'Modules/Templates/selectors';
import LabelInstance from 'Modules/Shared/records/LabelInstance';

function* updateLabelable({
  labelableType,
  labelsToAdd,
  labelInstanceIdsToRemove,
}) {
  const labelInstances = labelsToAdd.map(instance =>
    LabelInstance.from({ labelInstance: instance })
  );
  const formattedLabelInstances = listToOrderedMap(
    labelInstances,
    instance => instance.id
  );
  if (labelableType === LABELABLE_TYPES.FLOW) {
    yield put(
      addFlowLabelInstances({ labelInstances: formattedLabelInstances })
    );
    yield put(removeFlowLabelInstances({ ids: labelInstanceIdsToRemove }));
  }

  if (labelableType === LABELABLE_TYPES.TEMPLATE) {
    const activeTemplate = yield select(getActiveTemplate());
    const activeTemplateId = activeTemplate.get('id');
    const activeFolderId = activeTemplate.get('templateFolderId');

    yield put(
      addTemplateLabelInstances({
        activeFolderId,
        activeTemplateId,
        labelInstances: formattedLabelInstances,
      })
    );
    yield put(
      removeTemplateLabelInstances({
        activeFolderId,
        activeTemplateId,
        instanceIds: labelInstanceIdsToRemove,
      })
    );
  }
}

export default updateLabelable;
