import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

export const isUsingGEReviewApp = (): boolean =>
  new URL(grooveEngineRootUrl).hostname.startsWith(
    process.env.GE_REVIEW_APP_PREFIX || ''
  );
export const isUsingGEStaging = (): boolean =>
  grooveEngineRootUrl === process.env.GE_STAGING_URL;
export const isUsingGEQA = (): boolean =>
  [
    process.env.GE_QA1_URL,
    process.env.GE_QA2_URL,
    process.env.GE_QA_CLEAN_URL,
    process.env.GE_QA_DIRTY_URL,
  ].includes(grooveEngineRootUrl);
export const isUsingGEDemo = (): boolean =>
  grooveEngineRootUrl === process.env.GE_DEMO_URL;
export const isUsingGEProduction = (): boolean =>
  grooveEngineRootUrl === process.env.GE_PRODUCTION_URL;
export const isFEBESDemo = (): boolean =>
  window.location.pathname.startsWith('/review/demo');
export const isFEBESReviewApp = (): boolean =>
  window.location.pathname.match(/\/review\/\d+/) !== null ||
  window.location.pathname.startsWith('/commits/');
export const getGEPRNumber = (): string =>
  // eslint-disable-next-line no-useless-escape
  (new RegExp(`${process.env.GE_REVIEW_APP_PREFIX}(\\d+)`).exec(
    grooveEngineRootUrl
  ) || [])[1];
