import { selectors } from '@groove-labs/groove-ui';
import moment from 'moment';
import { Map, List } from 'immutable';
import {
  TEMPLATE_DROPDOWN_OPTIONS,
  PRIORITY_FIELD_ID,
} from 'Modules/Shared/constants';
import {
  getWysiwygEditorValue,
  makeGetTemplateAddEditData,
} from 'Modules/Shared/selectors/templateAddEdit';
import { createSelector } from 'reselect';

import { getDayNumber } from 'Modules/Shared/selectors/addOrEditTemplate';

import { getSteps as getFlowsShowSteps } from 'Modules/FlowsShow/selectors';

import { getSteps as getFlowWizardSteps } from 'Modules/FlowsWizard/selectors';

import { getFullPath as getLocationFullPath } from 'Modules/Shared/selectors/location';

const { getGroupFields } = selectors.form;

export const makeGetStepAddEditData = groupId => {
  return createSelector(
    [
      getGroupFields,
      makeGetTemplateAddEditData(groupId),
      getWysiwygEditorValue,
    ],
    (groupData, templateDataValues, wysiwygEditorValue) => {
      const baseMap = groupData.entrySeq().reduce((map, keyValuePair) => {
        const fieldId = keyValuePair[0];

        if (fieldId === 'stepTemplateName') {
          let templateId =
            keyValuePair[1].getIn(['selectedData']) &&
            keyValuePair[1].getIn(['selectedData', 'id']);

          const dropdownValue = keyValuePair[1].get('value');

          if (
            !templateId &&
            dropdownValue === TEMPLATE_DROPDOWN_OPTIONS.NO_TEMPLATE
          ) {
            templateId = 'none';
          }
          return map.set('templateId', templateId);
        }

        if (fieldId === PRIORITY_FIELD_ID) {
          const priorityTypeValue = keyValuePair[1].get('value');
          return map.set(fieldId, priorityTypeValue);
        }

        if (fieldId === 'templateFolder') {
          const templateFolderId =
            keyValuePair[1].getIn(['selectedData']) &&
            keyValuePair[1].getIn(['selectedData', 'id']);
          return map.set(fieldId, templateFolderId);
        }

        if (fieldId === 'stepType') {
          // Map dropdown options to values the server will understand
          const stepTypeValue = keyValuePair[1].get('value');
          return map.set(fieldId, stepTypeValue);
        }

        const fieldValue = keyValuePair[1].get('value');
        return map.set(fieldId, fieldValue);
      }, new Map());

      return baseMap
        .set('htmlBody', wysiwygEditorValue)
        .merge(templateDataValues);
    }
  );
};

const getStepsByDayNumber = createSelector(
  getLocationFullPath,
  getFlowsShowSteps,
  getFlowWizardSteps,
  (locationPath, flowsShowSteps, flowWizardSteps) => {
    const steps =
      locationPath === '/flows/wizard' ? flowWizardSteps : flowsShowSteps;
    return Map(
      steps.reduce((stepsByDayNumbers, step) => {
        const dayNumber = step.get('dayNumber');
        if (stepsByDayNumbers[dayNumber]) {
          stepsByDayNumbers[dayNumber].push(step);
        } else {
          stepsByDayNumbers[dayNumber] = [step];
        }
        return stepsByDayNumbers;
      }, {})
    );
  }
);

export const getAutoSendHourOptions = (step, groupId) =>
  createSelector(
    state => getDayNumber(state, { groupId }),
    getStepsByDayNumber,
    (dayNumber, stepsByDayNumber) => {
      // options are 12:00 AM, 1:00 AM .. 11:00 PM
      const options = [];
      const formDayNumber = String(dayNumber || 1);

      // Show immediately option when:
      //   - the step is not the first one in given day
      //   - or it's a day 1 step
      if (
        formDayNumber === '1' ||
        (stepsByDayNumber.has(formDayNumber) &&
          stepsByDayNumber.get(formDayNumber)[0].id !== step.id)
      ) {
        options.push({
          label: 'Immediately',
          value: '31',
        });
      }

      const m = moment('00:00', 'HH:mm');

      let i = 0;
      for (i = 0; i < 24; i += 1) {
        let label;
        if (i === 0) {
          label = '12:00 AM';
        } else {
          label = m.format('hh:mm A');
        }
        m.add(1, 'hours');

        options.push(
          { value: String(i), label } // really dumb but value 0  doesn't pick the time of 12:00am hence must use string version which isn't falsy
        );
      }

      return List(options);
    }
  );
