import { ColumnDefinitionKey } from 'Modules/ActionsRewrite/components/columnDefs/keysToColumnDefinitions';

// FINDME: TODO: Remove post Forrester. Created due to WFA-81 / PLAYB-155
import { getLdClient } from 'Utils/ldClient';
import { SMART_ACTION_FORRESTER_FEATURE_FLAG } from 'Modules/ActionsRewrite/components/customCells/SmartPriority/constants';

const defaultColumnOrderWithoutSmartPriorities = [
  'type',
  // 'priority',
  'title',
  'who',
  'company',
  'due',
  'timezone',
  'source',
  'managedBy',
] as ColumnDefinitionKey[];
const defaultColumnOrderWithSmartPriorities = [
  'type',
  // 'priority',
  'title',
  'who',
  'company',
  'smartPriority',
  'due',
  'timezone',
  'source',
  'managedBy',
] as ColumnDefinitionKey[];

/**
 * A custom hook that returns an array of column definition keys, and includes the smart priorities
 * if the smart-priorities feature flag is enabled. These keys determine which columns (and in which order)
 * show up on the actions table.
 *
 * Encapsulated within a hook to ensure the LdClient is properly set up
 */
export default function useBuildDefaultColumnOrder() {
  const shouldUseSmartPriorities = getLdClient()?.variation(
    SMART_ACTION_FORRESTER_FEATURE_FLAG
  );

  return shouldUseSmartPriorities
    ? defaultColumnOrderWithSmartPriorities
    : defaultColumnOrderWithoutSmartPriorities;
}
