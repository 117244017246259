import { FC, useEffect, useState } from 'react';
import { StandardDropDownItem } from '@groove/ui/Components/DropDownList';
import parsePhoneNumber from 'libphonenumber-js';
import { CopyIcon, PhoneIcon } from '@groove/ui/Components/BoogieIcon';
import cx from 'classnames';
import { Callout } from '@fluentui/react';
import Tooltip from '@groove/ui/Components/Tooltip';
import Text from '@groove/ui/Components/Text';
import { motion } from 'framer-motion';
import LinkButton from '@groove/ui/Components/LinkButton';
import useUserGet from '@groove/api/hooks/useUserGet';
import EditIcon from '@groove/templates/icons/EditIcon';
import MultiSelect from '@groove/ui/Components/MultiSelect';
import { CONTACT_PREFIX, LEAD_PREFIX } from '@groove/api/gateway/v1/salesforce';
import { SimpleFieldType } from '@groove/api/gateway/v1/actionCompose';
import { reduce } from 'lodash-es';
import { CheckMarkIcon } from '@fluentui/react-icons-mdl2';
import { useGetOrgSettings } from '@groove/api/hooks/useUsers';
import useVariation from '@groove/api/hooks/launchdarkly/useVariation';

import useStore from '../store/useStore';
import { addSFDCObjectToOmnibar } from '../utils/loggingToMethods';
import useGrooveUpdate from '../hooks/useGrooveUpdate';

import RecipientSelector from './RecipientSelector';
import EditPhoneDialog, { PHONE_UPDATE_MESSAGE_ID } from './EditPhoneDialog';

const PhoneRecipient: FC = () => {
  const [phoneItems, setPhoneItems] = useState<StandardDropDownItem[]>([]);
  const [selectedPhoneItem, setSelectedPhoneItem] = useState<
    StandardDropDownItem[]
  >([]);
  const [showCopiedTip, setShowCopiedTip] = useState(false);

  const who = useStore(store => store.action.who);
  const id = useStore(store => store.action.id);
  const isTaskPaneOpenRaw = useStore(store => store.otherValues.isTaskPaneOpen);
  const taskPaneInitialClose = useStore(
    store => store.otherValues.taskPaneInitialClose,
  );
  const dialerInActionEnabled = useVariation('dialer-in-action');
  const isTaskPaneOpen = isTaskPaneOpenRaw && !taskPaneInitialClose;

  const { data: user } = useUserGet();
  const { data: orgSettings } = useGetOrgSettings();

  const disableEditPhone = !who?.sfdcId;
  const disabledClassNames = cx({
    '!bg-neutral/50 !cursor-not-allowed !text-neutral/400': disableEditPhone,
  });

  useEffect(() => {
    let copyAnimation: NodeJS.Timeout;
    if (showCopiedTip) {
      copyAnimation = setTimeout(() => setShowCopiedTip(false), 4000);
    }
    return () => {
      if (copyAnimation) clearTimeout(copyAnimation);
    };
  }, [showCopiedTip]);

  useEffect(() => {
    if (who && who.phone) {
      const recipient = useStore.getState().action.toRecipients?.[0];
      const selectedPhoneNumber = recipient?.phone;

      const tempPhoneItems: StandardDropDownItem[] = [];
      if (who?.phones?.length) {
        who?.phones?.forEach(item => {
          const formattedValue =
            parsePhoneNumber(item.value as string)?.formatInternational() ||
            item.value;
          const displayText = `${formattedValue}${
            item.label ? ` (${item.label})` : ''
          }`;
          tempPhoneItems.push({
            key: item.field,
            text: displayText,
            value: item.value,
          });
        });
      } else {
        tempPhoneItems.push({
          key: 'phone',
          text: `${
            parsePhoneNumber(who.phone || '')?.formatInternational() ||
            who.phone
          } (Phone)`,
          value: who.phone,
        });

        if (who.mobilePhone) {
          tempPhoneItems.push({
            key: 'mobile_phone',
            text: `${
              parsePhoneNumber(who.mobilePhone)?.formatInternational() ||
              who.mobilePhone
            } (Mobile Phone)`,
            value: who.mobilePhone,
          });
        }
      }

      setPhoneItems(tempPhoneItems);

      if (selectedPhoneNumber) {
        const formattedPhoneNumber =
          parsePhoneNumber(selectedPhoneNumber)?.formatInternational() ||
          selectedPhoneNumber;

        let phoneLabel = recipient.phoneField || 'phone';

        const matchingPhone = who.phones?.find(
          item => item.value === selectedPhoneNumber,
        );
        if (matchingPhone) {
          phoneLabel = matchingPhone.label;
        }

        setSelectedPhoneItem([
          {
            key: recipient.phoneField || 'phone',
            text: selectedPhoneNumber
              ? `${formattedPhoneNumber} (${phoneLabel})`
              : '',
            secondaryText: ['Phone'],
            value: selectedPhoneNumber || '',
          },
        ]);
      } else {
        setSelectedPhoneItem([tempPhoneItems[0]]);
        const toRecipients = [
          {
            id: who.id.toString(),
            email: who.email,
            name: who.name,
            phone: tempPhoneItems[0].value as string,
            phoneField: tempPhoneItems[0].key as string,
            attributes: {
              type: who.sfdcType,
            },
            sfdcId: who.sfdcId,
          },
        ];
        useStore.getState().updateAction({
          toRecipients,
        });
        useStore.getState().updateOriginalAction({
          toRecipients,
        });
      }
    } else {
      setPhoneItems([]);
      setSelectedPhoneItem([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [who?.id, id, who?.phone, who?.mobilePhone, who?.name, who?.phones]);

  const onNumberChange = (item: StandardDropDownItem | null): void => {
    if (item && who) {
      const { toRecipients } = useStore.getState().action;
      let tempToRecipients = [...(toRecipients || [])];
      const index = tempToRecipients.findIndex(
        record => record.id === who.sfdcId || record.sfdcId === who.sfdcId,
      );
      const recipient = {
        ...tempToRecipients[index],
        id: who.id.toString(),
        sfdcId: who.sfdcId,
        phone: item.value as string,
        phoneField: item.key as string,
      };

      tempToRecipients = [
        ...tempToRecipients.slice(0, index),
        { ...recipient },
        ...tempToRecipients.slice(index + 1),
      ];

      useStore.getState().updateAction({
        toRecipients: tempToRecipients,
      });
      setSelectedPhoneItem([item]);
    }
  };

  const onCopyClick = (): void => {
    if (selectedPhoneItem?.[0]?.value) {
      navigator.clipboard.writeText(selectedPhoneItem[0].value.toString());
      setShowCopiedTip(true);
    }
  };

  const renderTo = (
    <RecipientSelector className="flex-1 mr-[4px] min-w-0" placeholder="To" />
  );

  let phonePlaceholder = 'Phone';

  if (selectedPhoneItem.length === 0) {
    if (who) phonePlaceholder = 'No phone number';
    else if (phoneItems.length < 1) phonePlaceholder = 'No phone numbers';
    else phonePlaceholder = 'Please add a recipient';
  }
  let objectType = '';
  if (who?.sfdcId?.startsWith(CONTACT_PREFIX)) objectType = 'contact';
  if (who?.sfdcId?.startsWith(LEAD_PREFIX)) objectType = 'lead';
  const { mutateAsync: grooveUpdate } = useGrooveUpdate(
    who?.sfdcId || '',
    objectType,
  );

  const showSalesforceUpdateMessage = (
    level: 'loading' | 'success' | 'error',
    message: string,
  ): void => {
    useStore.getState().addMessage(
      {
        level,
        message,
        id: PHONE_UPDATE_MESSAGE_ID,
        noCloseButton: true,
      },
      {
        isMessageAtTop: true,
        messageTimer: 5000,
        shouldKeepAfterTimer: false,
      },
    );
  };

  const showSalesforceUpdateInProgressMessage = (): void => {
    showSalesforceUpdateMessage(
      'loading',
      'Updating phone numbers in Salesforce.',
    );
  };

  const showSalesforceUpdateSuccessMessage = (): void => {
    showSalesforceUpdateMessage(
      'success',
      'Phone numbers updated successfully in Salesforce.',
    );
  };

  const showSalesforceUpdateErrorMessage = (): void => {
    showSalesforceUpdateMessage(
      'error',
      'An issue occurred while updating phone numbers in Salesforce. Please try again later or contact support for assistance.',
    );
  };

  const validateUpdateSalesforceAndSync = async (): Promise<void> => {
    const selectedPhoneType = useStore.getState().currentPhoneItem?.type;
    const selectedPhoneValue = useStore.getState().currentPhoneItem?.value;

    const { phoneTypeKeyToPhoneLabelMap } = useStore.getState();
    // get the values that have been updated in the edit phone numbers dialog and build the params object that
    // will be sent to the grooveUpdate(apex)
    const updatedParams: Record<string, string> = useStore
      .getState()
      .getChangedPhoneItems()
      .reduce((obj: Record<string, string>, item) => {
        if (item.key && item.value) {
          obj[item.key] = item.value as string;
        }
        return obj;
      }, {});

    showSalesforceUpdateInProgressMessage();

    // catch the error if the update fails
    try {
      await grooveUpdate({
        id: who?.sfdcId || '',
        sobject: objectType,
        additionalParams: updatedParams,
      });
    } catch (error) {
      // display the error message and call it a day
      showSalesforceUpdateErrorMessage();
      return;
    }

    // show the success message
    showSalesforceUpdateSuccessMessage();

    // build the object to update the who.phones object with. This will be used to render the phones dropdown up top
    const updatedPhones = who?.phones?.map(phone => {
      if (updatedParams[phone.field]) {
        return {
          ...phone,
          value: updatedParams[phone.field],
        };
      }
      return phone;
    });

    // In some scenarios, the user might have added a phone value to a type that currently doesn't exist in the who.phones
    // for example, the user might have had a business phone but say the user added a mobile phone number. In this case,
    // we need to add the new phone number to the who.phones array
    const existingFields = new Set(who?.phones?.map(phone => phone.field));
    const newPhones: SimpleFieldType[] = reduce(
      updatedParams,
      (acc: SimpleFieldType[], value: number | string, key: string) => {
        if (!existingFields.has(key)) {
          acc.push({
            field: key,
            value: value.toString(),
            label: phoneTypeKeyToPhoneLabelMap[key] || 'Phone',
          });
        }
        return acc;
      },
      [] as SimpleFieldType[],
    );
    updatedPhones?.push(...newPhones);

    // update the store with the updated phone numbers
    useStore.getState().updateAction({
      who: {
        ...who,
        company: who?.company || '',
        phones: updatedPhones || [],
      },
    });

    // refresh omnibar with the updated phone number
    refreshOmnibar(useStore.getState().currentPhoneItem?.value || '');
    // update the selection in the action compose window so that the last updated phone number is selected
    const updatedPhoneItem = phoneItems.find(item => {
      return item.key === selectedPhoneType;
    }) as StandardDropDownItem | undefined;
    const formattedPhoneNumber =
      parsePhoneNumber(selectedPhoneValue)?.formatInternational() ||
      selectedPhoneValue;

    const phoneTypeLabel =
      phoneTypeKeyToPhoneLabelMap[updatedPhoneItem?.key || ''];
    const formattedText = phoneTypeLabel
      ? `${formattedPhoneNumber} (${phoneTypeLabel})`
      : formattedPhoneNumber;

    const newUpdatedPhoneItem = {
      ...updatedPhoneItem,
      text: formattedText,
      value: selectedPhoneValue,
      key: selectedPhoneType,
    };

    onNumberChange(newUpdatedPhoneItem);

    // if who.phone is not present, update the who object with the new phone number
    // this is useful in scenarios when the contact/lead doesn't have a phone number and the user adds one
    if (!who?.phone) {
      useStore.getState().updateAction({
        who: {
          ...who,
          phone: selectedPhoneValue,
          phones: updatedPhones || [],
        },
      });
    }
  };

  const refreshOmnibar = (phone: string): void => {
    addSFDCObjectToOmnibar(who?.sfdcId, who?.email, phone);
  };

  const openEditPhoneModal = (): void => {
    if (disableEditPhone) return;
    useStore.getState().setNewModal({
      isOpen: true,
      onConfirm: validateUpdateSalesforceAndSync,
      content: (
        <EditPhoneDialog
          phoneItems={phoneItems}
          selectedPhoneItem={selectedPhoneItem}
        />
      ),
      title: who?.name || '',
      cancelText: 'Cancel',
      confirmText: 'Save',
      boldTitle: true,
    });
  };

  const editPhoneFooter: JSX.Element | undefined = (
    <div
      className={cx(
        'bg-white text-left w-full  p-0 cursor-pointer',
        disabledClassNames,
      )}
    >
      <hr className="m-0 h-px bg-neutral/75 border-0 " />
      <div
        className="items-center display: flex pb-2"
        onClick={openEditPhoneModal}
        onKeyDown={event => {
          // Trigger function on Enter or Space key press
          if (event.key === 'Enter' || event.key === 'Space') {
            openEditPhoneModal();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className="pl-2 pt-[5px]">
          <EditIcon />
        </div>
        <Text className="my-auto text-metadata whitespace-nowrap pl-[6px] text-black ">
          Edit phone numbers
        </Text>
      </div>
    </div>
  );

  const renderPhone = (
    <>
      <MultiSelect
        className="flex-1 mx-[4px] min-w-0"
        onSelect={onNumberChange}
        selectedValues={selectedPhoneItem}
        placeholder={selectedPhoneItem?.[0]?.text || phonePlaceholder}
        items={phoneItems}
        listFooter={editPhoneFooter}
        showChevron
        closeAfterSelect
        showSelectedIcon
        truncate
        selectedIcon={<CheckMarkIcon />}
        inputHeightClassName="h-[30px]"
      />
      <div
        className={cx(
          'px-[8px] items-center flex h-full mr-[4px]',
          selectedPhoneItem?.[0]?.value
            ? 'text-wavyNavy/900 cursor-pointer'
            : 'text-neutral/400 cursor-not-allowed',
        )}
        onClick={onCopyClick}
        tabIndex={0}
        aria-label="Copy Button"
        role="button"
        onKeyDown={onCopyClick}
        id="copy-button"
      >
        <CopyIcon className="w-[12px]" />
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: showCopiedTip ? 1 : 0 }}
        transition={{ duration: 0.1 }}
      >
        {showCopiedTip && (
          <Callout
            role="alert"
            gapSpace={-5}
            target="#copy-button"
            className="p-[8px]"
          >
            <Text className="text-body-sm">Copied to clipboard!</Text>
          </Callout>
        )}
      </motion.div>
      {(!dialerInActionEnabled ||
        !user?.data.dialer_enabled ||
        orgSettings?.third_party_dialer_enabled) && (
        <LinkButton
          href={
            user?.data.settings?.is_3c_logic_dialer_enabled
              ? undefined
              : `tel:${selectedPhoneItem?.[0]?.value}`
          }
          onClick={
            user?.data.settings?.is_3c_logic_dialer_enabled
              ? () => {
                  (
                    document.querySelector(
                      'iframe.openctiSoftPhone',
                    ) as HTMLIFrameElement
                  )?.contentWindow?.postMessage(
                    {
                      apiVersion: 50,
                      apiType: 'opencti',
                      methodName: 'onClickToDial',
                      callId: 1,
                      response: {
                        number: selectedPhoneItem?.[0]?.value,
                        recordId: who?.id.toString(),
                        objectType: who?.id.toString()?.startsWith('003')
                          ? 'Contact'
                          : 'Lead',
                      },
                      shouldExecuteCallback: true,
                      shouldExecuteListener: true,
                    },
                    '*',
                  );
                }
              : undefined
          }
          className={cx(
            'pl-[4px] mr-[4px]',
            selectedPhoneItem?.[0]?.value
              ? 'text-wavyNavy/900 cursor-pointer'
              : 'text-neutral/400 cursor-not-allowed',
          )}
        >
          <Tooltip
            content={
              selectedPhoneItem?.[0]?.value
                ? `Call ${selectedPhoneItem?.[0]?.value}`
                : 'Please have a valid phone number selected'
            }
          >
            <PhoneIcon
              className={cx(
                'w-[16px]',
                selectedPhoneItem?.[0]?.value
                  ? 'text-wavyNavy/900 cursor-pointer'
                  : 'text-neutral/400 cursor-not-allowed',
              )}
            />
          </Tooltip>
        </LinkButton>
      )}
    </>
  );

  let renderBoth = (
    <>
      {renderTo}
      {renderPhone}
    </>
  );

  if (!isTaskPaneOpen)
    renderBoth = (
      <>
        <div className="flex flex-row flex-1 items-center min-w-0">
          {renderTo}
        </div>
        <div className="flex flex-row flex-1 items-center min-w-0">
          {renderPhone}
        </div>
      </>
    );

  return <div className="flex flex-row items-center">{renderBoth}</div>;
};

export default PhoneRecipient;
