import { all, fork, select, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { FSA } from 'flux-standard-action';
import { Map } from 'immutable';

import { safelyLogEvent } from 'Modules/Shared/sagas/analytics';
import { ROUTE_NAMES } from 'Routing/constants';
import { location as locationSelector } from 'Modules/Shared/selectors/location';
import { getFlow } from 'Modules/FlowsShow/selectors';
import {
  actionTypes,
  SavePayload,
} from 'Modules/Shared/actions/automatedActions';
import AutomatedAction from 'Modules/Shared/records/AutomatedAction';
import { currentUser as getCurrentUser } from 'Modules/Shared/selectors/users';

const getDefaultTrackingAttributes = (
  aa: AutomatedAction
): {
  triggerType: string;
  hasSalesforceConditions: boolean;
  actionType: string;
} => ({
  triggerType: aa.triggerType,
  hasSalesforceConditions: !aa.salesforceTriggerConditions.isEmpty(),
  actionType: aa.type,
});

const getFlowTrackingAttributes = (
  flow: Map<string, unknown>
): {
  scope: string;
  featureCategory: string;
  flowType: string;
} => ({
  scope: 'flow',
  featureCategory: ROUTE_NAMES.FLOWS_SHOW,
  flowType: flow.get('isMasterFlow') ? 'master' : 'individual',
});

const getMMOTrackingAttributes = (): {
  scope: string;
  featureCategory: string;
} => ({
  scope: 'organization',
  featureCategory: ROUTE_NAMES.SETTINGS,
});

function* handleSave({
  payload: {
    automatedActionsToCreate,
    automatedActionsToUpdate,
    automatedActionIdsToDestroy,
  },
}: FSA<string, SavePayload>): SagaIterator {
  const location = yield select(locationSelector);
  const currentUser = yield select(getCurrentUser);

  if (location.get('routeName') === 'flowsShow') {
    const flow: Map<string, unknown> = yield select(getFlow);

    automatedActionsToCreate.forEach(aa =>
      safelyLogEvent({
        currentUser,
        eventName: 'Automated Action Created',
        properties: {
          ...getDefaultTrackingAttributes(aa),
          ...getFlowTrackingAttributes(flow),
        },
      })
    );

    automatedActionsToUpdate.forEach(aa =>
      safelyLogEvent({
        currentUser,
        eventName: 'Automated Action Updated',
        properties: {
          ...getDefaultTrackingAttributes(aa),
          ...getFlowTrackingAttributes(flow),
        },
      })
    );

    automatedActionIdsToDestroy.forEach(() =>
      safelyLogEvent({
        currentUser,
        eventName: 'Automated Action Deleted',
        properties: getFlowTrackingAttributes(flow),
      })
    );
  } else {
    automatedActionsToCreate.forEach(aa =>
      safelyLogEvent({
        currentUser,
        eventName: 'Automated Action Created',
        properties: {
          ...getDefaultTrackingAttributes(aa),
          ...getMMOTrackingAttributes(),
        },
      })
    );

    automatedActionsToUpdate.forEach(aa =>
      safelyLogEvent({
        currentUser,
        eventName: 'Automated Action Updated',
        properties: {
          ...getDefaultTrackingAttributes(aa),
          ...getMMOTrackingAttributes(),
        },
      })
    );

    automatedActionIdsToDestroy.forEach(() =>
      safelyLogEvent({
        currentUser,
        eventName: 'Automated Action Deleted',
        properties: getMMOTrackingAttributes(),
      })
    );
  }
}

function* watchSave(): SagaIterator {
  yield takeEvery<FSA<string, SavePayload>>(actionTypes.SAVE.BEGIN, handleSave);
}

export default function* root(): SagaIterator {
  yield all([fork(watchSave)]);
}
