import gql from 'graphql-tag';

import graphql from '../graphql';
import { ApiPerson, ApiGraphQl } from '../types';

const updatePhoneNumber = (
  personId: string,
  type: string,
  number: string,
): Promise<ApiGraphQl<ApiPerson>> =>
  graphql<ApiPerson>(
    gql`
      mutation UpdatePhoneNumber(
        $personId: ID!
        $type: String!
        $number: String!
      ) {
        updatePhoneNumber(personId: $personId, type: $type, number: $number) {
          person {
            id
          }
        }
      }
    `,
    {
      personId,
      type,
      number,
    },
    false,
  );

export default updatePhoneNumber;
