import { Template, TemplateType } from '@groove/api/gateway/v1/template';
import {
  ApiTemplateFolders,
  TemplateFolder,
} from '@groove/api/services/v1/templateFolders';
import create from 'zustand';

import { TEMPLATES_TAB } from '../analytics/templatesEvents';

export type Store = {
  selectedTemplatePanelTab: TEMPLATES_TAB;
  setSelectedTemplatePanelTab: (tab: TEMPLATES_TAB) => void;
  selectedFolder: TemplateFolder | undefined;
  setSelectedFolder: (folder: TemplateFolder | undefined) => void;
  selectedFolderId: number | undefined;
  setSelectedFolderId: (templateFolderId: number | undefined) => void;
  selectedTemplate: Template | undefined;
  setSelectedTemplate: (template: Template | undefined) => void;
  insertingTemplate: boolean;
  setInsertingTemplate: (insertingTemplate: boolean) => void;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  isNewTemplate: boolean;
  setIsNewTemplate: (isNewTemplate: boolean) => void;
  templateFolders: ApiTemplateFolders;
  setTemplateFolders: (templateFolders: ApiTemplateFolders) => void;
  favoriteTemplates: Template[];
  setFavoriteTemplates: (favoriteTemplates: Template[]) => void;
  favoriteTemplateFolders: TemplateFolder[];
  setFavoriteTemplateFolders: (
    favoriteTemplateFolders: TemplateFolder[],
  ) => void;
  isOutlook: boolean;
  setIsOutlook: (isOutlook: boolean) => void;
  resetTemplatesStore: () => void;
  templateType: TemplateType;
  setTemplateType: (type: TemplateType) => void;

  templatesApp: {
    initialSearchTerm?: string;
    setInitialSearchTerm: (value: string) => void;
    customActionText?: string;
    setCustomActionText: (text?: string) => void;
  };
};

const useStore = create<Store>((set, get) => ({
  selectedTemplatePanelTab: TEMPLATES_TAB.TEMPLATES,
  setSelectedTemplatePanelTab: tab => set({ selectedTemplatePanelTab: tab }),
  selectedFolder: undefined,
  setSelectedFolder: folder => set({ selectedFolder: folder }),
  selectedFolderId: undefined,
  setSelectedFolderId: id => set({ selectedFolderId: id }),
  selectedTemplate: undefined,
  setSelectedTemplate: selectedTemplate => set({ selectedTemplate }),
  insertingTemplate: false,
  setInsertingTemplate: insertingTemplate => set({ insertingTemplate }),
  isNewTemplate: false,
  setIsNewTemplate: isNewTemplate => set({ isNewTemplate }),
  searchTerm: '',
  setSearchTerm: searchTerm => set({ searchTerm }),
  templateFolders: { data: [] },
  setTemplateFolders: templateFolders => set({ templateFolders }),
  favoriteTemplates: [],
  setFavoriteTemplates: favoriteTemplates => set({ favoriteTemplates }),
  favoriteTemplateFolders: [],
  setFavoriteTemplateFolders: favoriteTemplateFolders =>
    set({ favoriteTemplateFolders }),
  isOutlook: false,
  setIsOutlook: isOutlook => set({ isOutlook }),
  resetTemplatesStore: () => {
    set({
      selectedTemplate: undefined,
      selectedFolder: undefined,
      selectedFolderId: undefined,
      searchTerm: '',
    });
  },
  templateType: 'email',
  setTemplateType: templateType => set({ templateType }),

  templatesApp: {
    initialSearchTerm: undefined,
    setInitialSearchTerm: initialSearchTerm =>
      set(state => ({
        templatesApp: { ...state.templatesApp, initialSearchTerm },
      })),
    customActionText: undefined,
    setCustomActionText: customActionText =>
      set(state => ({
        templatesApp: { ...state.templatesApp, customActionText },
      })),
  },
}));

export default useStore;
