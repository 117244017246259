import React from 'react';
import PropTypes from 'prop-types';
import ClariTheme from 'Utils/theme/ClariTheme';

const defaultColor = ClariTheme.palette.text.neutralColor;

const RoundedEdgesSpacesIcon = ({ className, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={className}
      style={style}
    >
      <path
        fill={style?.color || defaultColor}
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M31 331v6h-7a1 1 0 01-.993-.883L23 336v-5h8zm10-4v9a1 1 0 01-.883.993L40 337h-7v-10h8zm-10-8v10h-8v-9a1 1 0 01.883-.993L24 319h7zm9 0a1 1 0 011 1v5h-8v-6z"
        opacity="0.8"
        transform="translate(-23 -319)"
      />
    </svg>
  );
};

RoundedEdgesSpacesIcon.displayName = 'RoundedEdgesSpacesIcon';
RoundedEdgesSpacesIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RoundedEdgesSpacesIcon.defaultProps = {
  className: '',
  style: {
    color: defaultColor,
  },
};

export default RoundedEdgesSpacesIcon;
