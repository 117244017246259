import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Text from '@groove/ui/Components/Text';

const keyframes = [
  {
    transform: 'scale(0.95)',
    boxShadow: '0 0 0 0 rgba(37, 99, 235, 0.7)',
  },
  {
    transform: 'scale(1)',
    boxShadow: '0 0 0 20px rgba(0, 0, 0, 0)',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    offSet: 0.7,
    color: '#303030',
  },
  {
    transform: 'scale(0.95)',
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    backgroundColor: '#FFFFFF',
    color: '#303030',
  },
];
const animationOptions = {
  duration: 400,
  iterations: 1,
};

export type SingleKeyPadButtonRef = {
  handleClick: (input: string) => void;
};

type SingleKeyPadButtonProps = {
  onClick: (value: string) => void;
  value: string;
  secondaryValue?: string;
};

const SingleKeyPadButton = forwardRef<
  SingleKeyPadButtonRef,
  SingleKeyPadButtonProps
>(({ onClick, value, secondaryValue }, forwardedRef) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isHover, setIsHover] = useState(false);

  useImperativeHandle(forwardedRef, () => ({
    handleClick: input => {
      if ((value + (secondaryValue || '')).includes(input.toLowerCase()))
        handleClick();
    },
  }));

  const handleClick = (): void => {
    ref.current?.animate(keyframes, animationOptions);
    onClick(value);
  };

  let className =
    'flex flex-col justify-center items-center rounded cursor-pointer h-[46px] w-[46px] m-[2px] relative';

  if (isHover) className = `${className} bg-clari-blue/600 text-white`;

  // Need to use onMouseEnter and onMouseLeave to simulate hovering effect due to how the animation works
  // Otherwise the text is white while the animation is playing
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={className}
      onClick={handleClick}
      role="button"
      tabIndex={0}
      ref={ref}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Text className="text-body">{value}</Text>
      <Text className="text-metadata-xs h-[14px]">{secondaryValue}</Text>
    </div>
  );
});

export default SingleKeyPadButton;
