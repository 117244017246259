import React, { FC, InputHTMLAttributes, LegacyRef } from 'react';
import cx from 'classnames';
import { useId } from '@fluentui/react-hooks';

export interface InputProps
  extends React.DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement & HTMLTextAreaElement>,
    HTMLInputElement & HTMLTextAreaElement
  > {
  /**
   * A label to be displayed for the input field.
   */
  label?: string;
  variant?: 'outlined' | 'no-outline' | 'underlined';
  multiple?: boolean;
  rows?: number;
  suffix?: React.ReactNode;
  innerRef?: LegacyRef<HTMLInputElement & HTMLTextAreaElement>;
}

/**
 * Primary UI component for user interaction
 */
const Input: FC<InputProps> = ({
  variant = 'outlined',
  multiple,
  suffix,
  innerRef,
  ...rest
}) => {
  const id = useId();
  const Element = multiple ? 'textarea' : 'input';

  const { className, disabled, ...restProps } = rest;
  let additionalClassName =
    'border-[1px] hover:border-neutral/900 active:border-clari-blue/600 active:border-[1px] focus:border-clari-blue/600 focus:border-[1px] active:py-[3px] active:px-[5px] focus:py-[3px] focus:px-[5px]';
  if (variant === 'no-outline') additionalClassName = 'border-0';
  if (variant === 'underlined')
    additionalClassName = 'border-0 !border-b !rounded-none';

  if (disabled)
    additionalClassName =
      '!bg-neutral/50 !cursor-not-allowed  !text-neutral/400 border';

  return (
    <>
      {rest.label && (
        <label className="font-semibold text-body font-groove" htmlFor={id}>
          {rest.label}
        </label>
      )}
      <div className={cx('relative', className)}>
        <Element
          id={id}
          disabled={disabled}
          className={cx(
            'font-groove text-left text-body-sm bg-white background flex items-center px-[6px] py-[4px] border-solid rounded focus:outline-none focus:shadow-none w-full !outline-none focus:ring-0 focus:bg-white resize-none border-neutral/200 h-full box-border',
            additionalClassName,
            className,
          )}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...restProps}
          ref={innerRef}
        />
        {suffix && (
          <div className="absolute right-0 h-[30px] top-0 flex items-center pr-4">
            {suffix}
          </div>
        )}
      </div>
    </>
  );
};

export default Input;
