import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SETTINGS: {
    MANAGE_MY_ORG: {
      MY_ORG: {
        FETCH_BRANDING: standardActions(),
        FETCH_DWA: standardActions(),
        FETCH_GROOVE_OWNER_EMAIL: standardActions(),
        FETCH_GROOVE_LICENSES: standardActions(),
        FETCH_GROOVE_SUBORG_LICENSES: standardActions(),
        FETCH_SCHEDULER_SETTINGS: standardActions(),
        FETCH_CONVERSATION_INTELLIGENCE_ZOOM_CONNECTED: standardActions(),
        FETCH_CONVERSATION_INTELLIGENCE_SHARING: standardActions(),
        TEST_DWA: standardActions(),
        UPDATE_BRANDING: standardActions(),
        UPDATE_DWA: standardActions(),
        UPDATE_LICENSES: standardActions(),
        UPDATE_MASTER_FLOW_TEMPLATE_PERMISSION: standardActions(),
        UPDATE_DIRECT_ATTACHMENT_ENABLED: standardActions(),
        UPDATE_GROOVE_OWNER_EMAIL: standardActions(),
        UPDATE_TEAM_ANALYTICS_VISIBLE: standardActions(),
        UPDATE_MOBO_ENABLED: standardActions(),
        UPDATE_FILTER_TEAM_LEADS_ANALYTICS: standardActions(),
        UPDATE_TEAM_LEAD_CAN_MANAGE_TEAM: standardActions(),
        ...keyMirror([
          'SET_TEAM_ANALYTICS_VISIBLE',
          'SET_FILTER_TEAM_LEADS_ANALYTICS',
          'SET_TEAM_LEAD_CAN_MANAGE_TEAM',
          'OPEN_ADD_LICENSES_DIALOG',
          'UPDATE_MMO_SETTING',
          'UPDATE_MMO_SETTING_NO_POPUP',
          'SET_USER_ORG_SETTING',
          'UPDATE_CONVERSATION_INTELLIGENCE_ZOOM_CONNECTED',
          'UPDATE_CONVERSATION_INTELLIGENCE_SHARING',
        ]),
      },
    },
  },
}).SETTINGS.MANAGE_MY_ORG.MY_ORG;

export const beginUpdatingMasterFlowTemplatePermission = ({
  canAdjustTemplates,
}) => ({
  type: actionTypes.UPDATE_MASTER_FLOW_TEMPLATE_PERMISSION.BEGIN,
  payload: { canAdjustTemplates },
});

export const updatedMasterFlowTemplatePermission = () => ({
  type: actionTypes.UPDATE_MASTER_FLOW_TEMPLATE_PERMISSION.SUCCESS,
});

export const failedUpdatingMasterFlowTemplatePermission = () => ({
  type: actionTypes.UPDATE_MASTER_FLOW_TEMPLATE_PERMISSION.FAILURE,
});

export const beginUpdatingDirectAttachmentEnabled = ({
  IsDirectAttachmentEnabled,
}) => ({
  type: actionTypes.UPDATE_DIRECT_ATTACHMENT_ENABLED.BEGIN,
  payload: { IsDirectAttachmentEnabled },
});

export const updatedDirectAttachmentEnabled = () => ({
  type: actionTypes.UPDATE_DIRECT_ATTACHMENT_ENABLED.SUCCESS,
});

export const failedUpdatingDirectAttachmentEnabled = () => ({
  type: actionTypes.UPDATE_DIRECT_ATTACHMENT_ENABLED.FAILURE,
});

export const fetchGrooveLicensesBegin = () => ({
  type: actionTypes.FETCH_GROOVE_LICENSES.BEGIN,
});

export const fetchGrooveLicensesSuccess = payload => ({
  type: actionTypes.FETCH_GROOVE_LICENSES.SUCCESS,
  payload,
});

export const fetchGrooveSuborgLicensesBegin = () => ({
  type: actionTypes.FETCH_GROOVE_SUBORG_LICENSES.BEGIN,
});

export const fetchGrooveSuborgLicensesSuccess = payload => ({
  type: actionTypes.FETCH_GROOVE_SUBORG_LICENSES.SUCCESS,
  payload,
});

export const fetchBrandingSuccess = ({
  brandCustomMeetingEnabled,
  brandDecoColor,
  brandLogoUrl,
}) => ({
  type: actionTypes.FETCH_BRANDING.SUCCESS,
  payload: {
    brandCustomMeetingEnabled,
    brandDecoColor: brandDecoColor || '',
    brandLogoUrl: brandLogoUrl || '',
  },
});

export const fetchDwaSuccess = payload => ({
  type: actionTypes.FETCH_DWA.SUCCESS,
  payload,
});

export const updateBrandingBegin = payload => ({
  type: actionTypes.UPDATE_BRANDING.BEGIN,
  payload,
});

export const updateBrandingSuccess = payload => ({
  type: actionTypes.UPDATE_BRANDING.SUCCESS,
  payload,
});

export const testDwaBegin = () => ({
  type: actionTypes.TEST_DWA.BEGIN,
});

export const testDwaSuccess = payload => ({
  type: actionTypes.TEST_DWA.SUCCESS,
  payload,
});

export const fetchSchedulerSettingsBegin = () => ({
  type: actionTypes.FETCH_SCHEDULER_SETTINGS.BEGIN,
});

export const fetchSchedulerSettingsSuccess = payload => ({
  type: actionTypes.FETCH_SCHEDULER_SETTINGS.SUCCESS,
  payload,
});

export const updateDwaBegin = payload => ({
  type: actionTypes.UPDATE_DWA.BEGIN,
  payload,
});

export const updateDwaFailure = payload => ({
  type: actionTypes.UPDATE_DWA.FAILURE,
  payload,
});

export const updateDwaSuccess = payload => ({
  type: actionTypes.UPDATE_DWA.SUCCESS,
  payload,
});

export const updateLicensesBegin = payload => ({
  type: actionTypes.UPDATE_LICENSES.BEGIN,
  payload,
});

export const updateLicensesSuccess = payload => ({
  type: actionTypes.UPDATE_LICENSES.SUCCESS,
  payload,
});

export const updateLicensesFailure = payload => ({
  type: actionTypes.UPDATE_LICENSES.FAILURE,
  payload,
});

export const openAddLicensesDialog = payload => ({
  type: actionTypes.OPEN_ADD_LICENSES_DIALOG,
  payload,
});

export const fetchConversationIntelligenceZoomConnectedSuccess = ({
  zoomConversationIntelligenceConnected,
}) => ({
  type: actionTypes.FETCH_CONVERSATION_INTELLIGENCE_ZOOM_CONNECTED.SUCCESS,
  payload: {
    zoomConversationIntelligenceConnected,
  },
});
export const updateConversationIntelligenceZoomConnectedState = ({
  zoomConversationIntelligenceConnected,
}) => ({
  type: actionTypes.UPDATE_CONVERSATION_INTELLIGENCE_ZOOM_CONNECTED,
  payload: {
    zoomConversationIntelligenceConnected,
  },
});

export const fetchConversationIntelligenceSharingSuccess = ({
  conversationIntelligenceSharing,
}) => ({
  type: actionTypes.FETCH_CONVERSATION_INTELLIGENCE_SHARING.SUCCESS,
  payload: {
    conversationIntelligenceSharing,
  },
});

export const updateConversationIntelligenceSharingState = ({
  conversationIntelligenceSharing,
}) => ({
  type: actionTypes.UPDATE_CONVERSATION_INTELLIGENCE_SHARING,
  payload: {
    conversationIntelligenceSharing,
  },
});

export const fetchGrooveOwnerEmailSuccess = ({ grooveOwnerEmail }) => ({
  type: actionTypes.FETCH_GROOVE_OWNER_EMAIL.SUCCESS,
  payload: {
    grooveOwnerEmail,
  },
});

export const updateGrooveOwnerEmailBegin = payload => {
  return {
    type: actionTypes.UPDATE_GROOVE_OWNER_EMAIL.BEGIN,
    payload,
  };
};

export const updateGrooveOwnerEmailSuccess = payload => ({
  type: actionTypes.UPDATE_GROOVE_OWNER_EMAIL.SUCCESS,
  payload,
});

export const updateMmoSetting = ({ key, value }, noSnackBar = false) => ({
  type: actionTypes.UPDATE_MMO_SETTING,
  payload: {
    key,
    noSnackBar,
    value,
  },
});

export const setUserOrgSetting = ({ key, value }) => ({
  type: actionTypes.SET_USER_ORG_SETTING,
  payload: {
    key,
    value,
  },
});

export const setTeamAnalyticsVisible = payload => ({
  type: actionTypes.SET_TEAM_ANALYTICS_VISIBLE,
  payload,
});

export const beginUpdatingTeamAnalyticsVisible = ({ isEnabled }) => ({
  type: actionTypes.UPDATE_TEAM_ANALYTICS_VISIBLE.BEGIN,
  payload: { isEnabled },
});

export const successfullyUpdatedTeamAnalyticsVisible = ({ isEnabled }) => ({
  type: actionTypes.UPDATE_TEAM_ANALYTICS_VISIBLE.SUCCESS,
  payload: { isEnabled },
});

export const failedToUpdateTeamAnalyticsVisible = () => ({
  type: actionTypes.UPDATE_TEAM_ANALYTICS_VISIBLE.FAILURE,
});

export const beginUpdatingMoboEnabled = ({ isEnabled }) => ({
  type: actionTypes.UPDATE_MOBO_ENABLED.BEGIN,
  payload: { isEnabled },
});

export const successfullyUpdatedMoboEnabled = ({ isEnabled }) => ({
  type: actionTypes.UPDATE_MOBO_ENABLED.SUCCESS,
  payload: { isEnabled },
});

export const failedToUpdateMoboEnabled = () => ({
  type: actionTypes.UPDATE_MOBO_ENABLED.FAILURE,
});

export const setFilterTeamLeadAnalytics = ({ value }) => ({
  type: actionTypes.SET_FILTER_TEAM_LEADS_ANALYTICS,
  payload: { value },
});

export const beginUpdatingFilterTeamLeadAnalytics = ({ isEnabled }) => ({
  type: actionTypes.UPDATE_FILTER_TEAM_LEADS_ANALYTICS.BEGIN,
  payload: { isEnabled },
});

export const successfullyUpdatedFilterTeamLeadAnalytics = ({ isEnabled }) => ({
  type: actionTypes.UPDATE_FILTER_TEAM_LEADS_ANALYTICS.SUCCESS,
  payload: { isEnabled },
});

export const failedToUpdatedFilterTeamLeadAnalytics = () => ({
  type: actionTypes.UPDATE_FILTER_TEAM_LEADS_ANALYTICS.FAILURE,
});

export const setTeamLeadCanManageTeam = ({ value }) => ({
  type: actionTypes.SET_TEAM_LEAD_CAN_MANAGE_TEAM,
  payload: { value },
});

export const beginUpdatingTeamLeadCanManageTeam = ({ isEnabled }) => ({
  type: actionTypes.UPDATE_TEAM_LEAD_CAN_MANAGE_TEAM.BEGIN,
  payload: { isEnabled },
});

export const successfullyUpdatedTeamLeadCanManageTeam = ({ isEnabled }) => ({
  type: actionTypes.UPDATE_TEAM_LEAD_CAN_MANAGE_TEAM.SUCCESS,
  payload: { isEnabled },
});

export const failedToUpdatedTeamLeadCanManageTeam = () => ({
  type: actionTypes.UPDATE_TEAM_LEAD_CAN_MANAGE_TEAM.FAILURE,
});
