import * as SentryReact from '@sentry/react';
import parsePhoneNumber, {
  PhoneNumber,
  CountryCallingCode,
  NationalNumber,
} from 'libphonenumber-js';

import useStore from '../hooks/useStore';

// this function parses a number and returns the parsed number and if anything goes wrong,
// returns undefined
export function safePhoneNumberParser(
  valueToParse: string,
): PhoneNumber | undefined {
  let parsedPhoneNumber;
  try {
    parsedPhoneNumber = parsePhoneNumber(valueToParse);
  } catch (e) {
    SentryReact.addBreadcrumb({
      category: 'number',
      message: 'Error parsing number',
      data: { number: valueToParse },
    });
    SentryReact.captureException(e);
  }
  return parsedPhoneNumber;
}

export function preparePhoneNumberForParsing(value: string): string {
  const { defaultCountryCode } = useStore.getState();

  let valueToParse = '';
  if (value.startsWith('00')) {
    valueToParse = value.replace('00', '+');
  } else if (value.startsWith('+')) {
    valueToParse = value;
  } else {
    valueToParse = `+${defaultCountryCode}${value}`;
  }
  return valueToParse;
}

// Only returns true for valid international numbers.
export function validatePhoneNumber(value: string): boolean {
  const valueToParse = preparePhoneNumberForParsing(value);
  const phoneNumber = safePhoneNumberParser(valueToParse);
  if (phoneNumber) {
    return phoneNumber.isValid();
  }

  return false;
}

export function formatPhoneNumber(value: string): string | null {
  if (!value) return null;
  const phoneNumber = safePhoneNumberParser(value);
  if (!phoneNumber) {
    const { defaultCountryCode } = useStore.getState();
    if (value.startsWith('+')) {
      return null;
    }
    return formatPhoneNumber(`+${defaultCountryCode}${value}`);
  }
  let formatted;
  if (value.startsWith('+')) {
    formatted = phoneNumber.formatInternational();
  } else {
    formatted = phoneNumber.formatNational();
  }
  if (formatted.includes('ext.')) {
    formatted = formatted.slice(0, formatted.indexOf('ext.')).trim();
  }
  return formatted;
}

/**
 * Extracts the digits of an international phone number without the country code
 * e.g. +1 213 373 4253 => 2133734253
 */
export function deriveDigitsFromPhoneNumber(
  value: string,
): NationalNumber | null {
  const { defaultCountryCode } = useStore.getState();
  const valueToParse = value.startsWith('+')
    ? value
    : `+${defaultCountryCode}${value}`;
  if (validatePhoneNumber(valueToParse)) {
    const phoneNumber = safePhoneNumberParser(valueToParse);
    return (phoneNumber && phoneNumber.nationalNumber) || null;
  }

  return null;
}

export function deriveCountryCodeFromPhoneNumber(
  value: string,
): CountryCallingCode | null {
  const { defaultCountryCode } = useStore.getState();
  const valueToParse = value.startsWith('+')
    ? value
    : `+${defaultCountryCode}${value}`;
  if (validatePhoneNumber(valueToParse)) {
    const phoneNumber = safePhoneNumberParser(valueToParse);
    return (phoneNumber && phoneNumber.countryCallingCode) || null;
  }

  return null;
}

export function isInternationalNumber(
  countryCode: string | undefined,
): boolean {
  const localCountryCodes = ['US', 'CA', 'VI'];
  return localCountryCodes.includes(countryCode || '') !== true;
}

export function fetchAreaCodeFromPhoneNumber(nationalNumber: string): string {
  const areaCode = nationalNumber.toString().substring(0, 3);
  return areaCode;
}
