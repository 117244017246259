import { actions } from '@groove-labs/groove-ui';
import { addableFlows } from 'GrooveHTTPClient/flows';
import { actionTypes } from 'Modules/Shared/actions/flows';
import { location as locationSelector } from 'Modules/Shared/selectors/location';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import listToOrderedMap from 'Utils/list-to-ordered-map';
import { decamelize } from 'humps';
import { ADDABLE_FLOWS_UI_KEY_PATH } from 'Modules/Shared/constants';

const { setProperty } = actions.ui;

function* loadFlows() {
  try {
    yield put({ type: actionTypes.LOAD_ADDABLE_FLOWS.PROGRESS });

    const location = yield select(locationSelector);
    const tab = location.get('activeTab');
    const sortingDirection = yield select(state =>
      state.getIn(['flows', 'sorting', 'direction'], 'desc')
    );
    const sortingValue = yield select(state =>
      state.getIn(['flows', 'sorting', 'value'], 'name')
    );

    // this value should map to backend attrs on the Flow model, i.e `created_at` and not `createdAt`
    const decamlizedSortingValue = decamelize(sortingValue);

    const flowsResponse = yield call(addableFlows, {
      filter: tab || 'active',
      order_direction: sortingDirection,
      order_attribute: decamlizedSortingValue,
    });

    const flows = listToOrderedMap(flowsResponse.data, flow => flow.id);
    yield put(
      setProperty({ uiKeyPath: ADDABLE_FLOWS_UI_KEY_PATH, data: flows })
    );
    yield put({ type: actionTypes.LOAD_ADDABLE_FLOWS.SUCCESS, payload: flows });
  } catch (e) {
    yield put({ type: actionTypes.LOAD_ADDABLE_FLOWS.FAILURE, error: e });
    throw e;
  }
}

function* watchLoadFlows() {
  yield takeEvery(actionTypes.LOAD_ADDABLE_FLOWS.BEGIN, loadFlows);
}

export default function* root() {
  yield all([fork(watchLoadFlows)]);
}
