import { all, fork, takeLatest } from 'redux-saga/effects';
import { actionTypes } from 'Modules/Shared/actions/users/health';
import handleRefreshSfdcMeta from './handleRefreshSfdcMeta';

function* watchRefreshSfdcMeta() {
  yield takeLatest(actionTypes.REFRESH_SFDC_META, handleRefreshSfdcMeta);
}

export default function* root() {
  yield all([fork(watchRefreshSfdcMeta)]);
}
