import {
  call,
  delay,
  fork,
  put,
  race,
  select,
  all,
  take,
  takeLatest,
  cancel,
} from 'redux-saga/effects';
import { actionTypes } from 'Modules/PeopleImportDialog/submodules/basicSearch/actions';
import { loadData } from 'Modules/PeopleImportDialog/submodules/peopleTable/actions';
import {
  actionTypes as peopleImportActionTypes,
  setSearching,
  requestProcessSearchResults,
  setNoResultsFound,
} from 'Modules/PeopleImportDialog/actions';
import { query } from 'Modules/PeopleImportDialog/submodules/basicSearch/selectors';
import { List, OrderedMap } from 'immutable';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import * as peopleImportHTTPClient from 'GrooveHTTPClient/peopleImport';
import { handleInvalidSalesforceConnectionHTTPRequest } from 'GrooveHTTPClient/sagas';
import { getFlowId } from 'Modules/FlowsShow/selectors';

// -------------- Handlers --------------

function* performBasicSearch() {
  // Debounce 500 ms
  yield delay(500);

  const searchQuery = yield select(query);

  // Cancel if query not valid
  // TODO
  //  this is cheap, there is a way to do it and report to client. Use our Input
  //  component + validators
  if (searchQuery.length < 2) {
    yield put(setSearching(false));
    yield cancel();
  } else {
    // Clear existing rows
    yield put(
      loadData({
        rows: new List(),
        columns: new List(),
        selectedRows: new OrderedMap(),
      })
    );

    // Before searching ensure the no results indicator is set to false.
    yield put(setNoResultsFound(false));

    // Show spinning progress indicator
    yield put(setSearching(true));
  }

  try {
    // Perform search and parse results
    // and cancel the action when a tab is switched
    const flowId = yield select(getFlowId);
    const { response, cancel } = yield race({
      response: call(
        handleInvalidSalesforceConnectionHTTPRequest,
        peopleImportHTTPClient.quickSearch,
        {
          flowId,
          phrase: searchQuery,
        }
      ),
      cancel: take(peopleImportActionTypes.REQUEST_SET_ACTIVE_TAB),
    });

    if (cancel) {
      yield put(setSearching(false));
      return;
    }

    // Does the heavy lifting of parsing the shitty response and loading row/column data into the
    // peopleTable reducer.
    yield put(requestProcessSearchResults(response));
  } catch (e) {
    // TODO better error handling
    yield put(
      pushSnackbarMessage({
        message: 'Something went wrong searching for people',
      })
    );
    yield put(setSearching(false));
    yield cancel();
  }
}

// -------------- Watchers --------------

function* watchQueryChange() {
  yield takeLatest(actionTypes.UPDATE_SEARCH_QUERY, performBasicSearch);
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([fork(watchQueryChange)]);
}
