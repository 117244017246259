import cx from 'classnames';
import { FC, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Button from '@groove/ui/Components/Button';
import Text from '@groove/ui/Components/Text';

import useStore from '../store/useStore';

type AnimationState = 'closed' | 'opening' | 'opened' | 'closing';

const ModalPortal: FC = () => {
  const [animationState, setAnimationState] =
    useState<AnimationState>('closed');

  const {
    isOpen,
    onConfirm,
    onCancel,
    content,
    title,
    confirmText,
    contentText,
    cancelText,
    boldTitle = false,
  } = useStore(store => store.modalValues);

  const onDismiss = (): void => setAnimationState('closing');

  useEffect(() => {
    if (isOpen) setAnimationState('opening');
    if (!isOpen) setAnimationState('closing');
  }, [isOpen]);

  return animationState === 'closed' ? null : (
    <motion.div
      className="z-[100000] h-full w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      animate={{
        opacity:
          animationState === 'opened' || animationState === 'opening' ? 1 : 0,
      }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
      onAnimationComplete={() => {
        if (animationState === 'opening') setAnimationState('opened');
      }}
    >
      <div className="h-full w-full bg-black opacity-50" />
      <motion.div
        className={cx(
          'bg-white p-[24px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-md rounded-[3px]',
        )}
        animate={{
          height:
            animationState === 'opened' || animationState === 'opening'
              ? 'auto'
              : 0,
        }}
        initial={{ height: 0 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        onAnimationComplete={() => {
          if (animationState === 'closing') {
            setAnimationState('closed');
            useStore.getState().setNewModal({ isOpen: false });
          }
        }}
      >
        <motion.div
          animate={{
            opacity: animationState === 'opened' ? 1 : 0,
          }}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          {title && (
            <Text
              className="text-pane-header"
              weight={boldTitle ? 'semibold' : 'normal'}
            >
              {title}
            </Text>
          )}
          {!content && !!contentText && (
            <Text className="text-body-sm pt-[12px] pb-[16px]">
              {contentText}
            </Text>
          )}
          {!!content && content}
          <div className="flex flex-row-reverse">
            <Button
              variant="primary"
              onClick={() => {
                onConfirm?.();
                onDismiss();
              }}
              className="ml-[8px]"
            >
              {confirmText || 'Confirm'}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                onCancel?.();
                onDismiss();
              }}
            >
              {cancelText || 'Cancel'}
            </Button>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default ModalPortal;
