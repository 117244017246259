import { isUndefined, omitBy } from 'lodash-es';
import { camelizeKeys, decamelize, decamelizeKeys } from 'humps';
import { DELETE, PATCH, POST, PUT } from '../HTTPMethod';
import makeHTTPRequest from '../makeHTTPRequest';
import { getLdClient } from 'Utils/ldClient';

const transformResponse = data => {
  try {
    const parsedData = JSON.parse(data);
    const camelizeData = camelizeKeys(parsedData);
    camelizeData.data.taskFieldValues = parsedData.data.task_field_values;
    return camelizeData;
  } catch (e) {
    return data;
  }
};

export const getAdminContentVisibilityEnabled = () =>
  !!getLdClient()?.variation('admin-content-visibility');

/**
 * Get the Flow.
 *
 * @param {number} flowId
 *
 * @return {Promise}
 * */
export const show = (
  flowId,
  {
    includeSteps = false,
    includeTemplates = false,
    includePeople = false,
    includeFlowDataSummary = true,
  } = {}
) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}`,
    queryParameters: {
      include_steps: includeSteps,
      include_templates: includeTemplates,
      include_people: includePeople,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
      include_flow_data_summary: includeFlowDataSummary,
    },
    transformResponse,
  });

export const index = ({
  filter,
  order_direction,
  order_attribute,
  offset,
  limit,
  query,
  shared_with,
  shared_by,
  label_ids,
  user_id,
}) =>
  makeHTTPRequest({
    path: '/api/v2/flows',
    queryParameters: {
      filter,
      order_direction,
      order_attribute,
      offset,
      limit,
      query,
      shared_with,
      shared_by,
      label_ids,
      user_id,
    },
  });

export const addableFlows = () =>
  makeHTTPRequest({ path: '/api/v2/flows/addable_flows' });

/**
 * Update the flow.
 *
 * @param {number} flowId
 * @param {FlowsUpdateHttpParam} param The typing for FlowsUpdateHttpParam exists in the type.d.ts file
 *
 * @return {Promise}
 * */
export const update = (
  flowId,
  {
    id,
    name,
    description,
    user_id,
    created_at,
    updated_at,
    remove_person_after_reply,
    sfdc_campaign_id,
    archived_at,
    sharing_mode,
    is_org_shared,
    is_master_flow,
    original_flow_id,
    auto_import_id,
    remove_person_after_bounce,
    campaign_link,
    create_sfdc_campaign,
    enforce_child_campaign_creation_on_copy,
    task_field_values,
    set_default_step_templates,
    email_footer,
    default_lead_email_field_name,
    default_lead_phone_field_name,
    default_contact_email_field_name,
    default_contact_phone_field_name,
  } = {}
) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}`,
    method: PUT,
    body: omitBy(
      {
        id,
        name,
        description,
        user_id,
        created_at,
        updated_at,
        remove_person_after_reply,
        sfdc_campaign_id,
        archived_at,
        sharing_mode,
        is_org_shared,
        is_master_flow,
        original_flow_id,
        auto_import_id,
        remove_person_after_bounce,
        campaign_link,
        create_sfdc_campaign,
        email_footer_id: email_footer?.id,
        enforce_child_campaign_creation_on_copy,
        task_field_values,
        set_default_step_templates,
        default_lead_email_field_name,
        default_lead_phone_field_name,
        default_contact_email_field_name,
        default_contact_phone_field_name,
        admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
      },
      isUndefined
    ),
    transformResponse,
  });

/**
 * Copy the flow.
 *
 * @param {number} flowId
 *
 * @return {Promise}
 * */
export const copy = (flowId, { copyAllTemplates, copyAutomatedActions }) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/copy`,
    method: POST,
    body: {
      copyAllTemplates,
      copyAutomatedActions,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
    transformResponse,
  });

/**
 * Archive the flow.
 *
 * @param {number} flowId
 *
 * @return {Promise}
 * */
export const archive = flowId =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/archive`,
    queryParameters: {
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
    method: PATCH,
  });

/**
 * Delete the flow.
 *
 * @param {number} flowId
 *
 * @return {Promise}
 * */
export const destroy = flowId =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}`,
    queryParameters: {
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
    method: DELETE,
  });

/**
 * Activate the flow.
 *
 * @param {number} flowId
 *
 * @return {Promise}
 * */
export const activate = flowId =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/activate`,
    queryParameters: {
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
    method: PATCH,
  });

/**
 * Activate campaign for flow.
 *
 * @param {number} flowId
 *
 * @return {Promise}
 * */
export const activateCampaign = flowId =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/activate_campaign`,
    method: POST,
    body: {
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

/**
 * Make an XHR request to create a new `Flow`
 * @param  {Map} attrs - Attributes for the Flow
 * @return {Promise}
 */
export const create = ({
  create_sfdc_campaign,
  sfdc_campaign_id,
  description,
  name,
  is_master_flow,
  remove_person_after_reply,
  remove_person_after_bounce,
  enforce_child_campaign_creation_on_copy,
  task_field_values,
  email_footer,
  flow_collection_id,
}) =>
  makeHTTPRequest({
    path: '/api/v2/flows',
    method: POST,
    body: {
      create_sfdc_campaign,
      task_field_values,
      flow: {
        name,
        description,
        sfdc_campaign_id,
        is_master_flow,
        email_footer_id: email_footer?.id,
        remove_person_after_reply,
        remove_person_after_bounce,
        enforce_child_campaign_creation_on_copy,
        flow_collection_id,
      },
    },
    transformResponse,
  });

export const people = flowId =>
  makeHTTPRequest({ path: `/api/v2/flows/${flowId}/people` });

/**
 * Update the flow auto-import setting
 *
 * @param {number} flowId
 * @param {number|string} autoImportId
 * @param {boolean} autoImportAssignOwner
 * @param {object} autoImportMeta
 *
 * @return {Promise}
 * */
export const updateAutoImport = (
  flowId,
  autoImportId,
  autoImportAssignOwner,
  autoImportMeta
) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/auto_import`,
    method: PUT,
    body: decamelizeKeys({
      autoImportAssignOwner,
      autoImportId,
      autoImportMeta,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    }),
  });

export const removePeopleBulkFromAllActiveFlows = peopleIds =>
  makeHTTPRequest({
    path: '/api/v2/flows/remove_people_bulk_from_all_active_flows',
    method: POST,
    body: {
      people_ids: peopleIds,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const removePeopleFromFlow = (flowId, peopleIds) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/remove_person`,
    method: POST,
    body: {
      person_id: peopleIds,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const removePeopleFromFlowBulk = (flowId, peopleIds) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/remove_people_bulk`,
    method: POST,
    body: {
      people_ids: peopleIds,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const movePeopleToFlowCheck = (fromFlowId, toFlowId, people) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${fromFlowId}/move_people_to_flow_check`,
    method: POST,
    body: {
      people,
      to_flow_id: toFlowId,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const resumePerson = ({ flowId, personId }) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/unpause_person`,
    method: PUT,
    body: decamelizeKeys({
      personId,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    }),
  });

export const flowsWithActionDueSummary = () =>
  makeHTTPRequest({ path: '/api/v2/flows/flows_with_action_due_summary' });

export const movePeopleToStep = ({ flowId, peopleIds, dayNumber, stepId }) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/move_people`,
    method: POST,
    body: {
      people_ids: peopleIds,
      day_number: dayNumber,
      current_step_id: stepId,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const stepsStats = flowId =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/steps_stats`,
    queryParameters: {
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const allStepsPeople = ({ flowId, ...queryParameters }) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/all_steps_people_v2`,
    queryParameters: decamelizeKeys({
      ...queryParameters,
      filter: decamelize(queryParameters.filter),
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    }),
  });

export const flowStatsSummary = (flowId, queryParams) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/flow_stats_summary`,
    queryParameters: {
      ...decamelizeKeys(queryParams),
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const flowFilters = () =>
  makeHTTPRequest({ path: '/api/v2/flows/flow_filters' });

export const searchEngagedWithByEmailOrSfdcId = ({ email, sfdcId }) =>
  makeHTTPRequest({
    path: '/api/v2/flows/search_engaged_with_by_email_or_sfdc_id',
    queryParameters: decamelizeKeys({ email, sfdcId }),
  });

export const searchByName = ({ phrase }) =>
  makeHTTPRequest({
    path: '/api/v2/flows/search_by_name',
    queryParameters: {
      phrase,
    },
  });

export const adminFlows = ({
  filter,
  user_id,
  order_direction,
  order_attribute,
}) =>
  makeHTTPRequest({
    path: '/api/v2/admin/flows',
    queryParameters: {
      filter,
      user_id,
      order_direction,
      order_attribute,
    },
  });

export const assignableUsers = ({ flowId }) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/assignable_users`,
    queryParameters: {
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });
