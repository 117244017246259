import { createSelector } from 'reselect';
import { Set } from 'immutable';
import { LABEL_CATEGORIES } from 'Modules/Shared/constants/labels';

export const getLabels = state => state.getIn(['labels', 'customLabels']);
export const getUserFavoriteLabel = state =>
  state.getIn(['labels', 'userFavoriteLabel']);

export const getCheckedLabelIds = (state, uiKeyPath) =>
  state.getIn(['ui', ...uiKeyPath], new Set());

export const makeGetLabelsByCategory = category =>
  createSelector(getLabels, labels =>
    labels.filter(labelData => labelData.get('category') === category)
  );

export const getCustomLabels = state =>
  getLabels(state).filter(
    labelData => labelData.get('category') === LABEL_CATEGORIES.ORG_CUSTOM
  );

// Combined selector to merge custom labels and user favorite label
export const getCombinedLabels = createSelector(
  [getCustomLabels, getUserFavoriteLabel],
  (customLabels, userFavoriteLabel) => {
    if (userFavoriteLabel && Object.keys(userFavoriteLabel.toJS()).length > 0) {
      return customLabels.set(userFavoriteLabel.get('id'), userFavoriteLabel);
    }
    return customLabels;
  }
);
