import { FC, useEffect, useState } from 'react';
import {
  templateGet,
  templateDelete,
  Template,
} from '@groove/api/gateway/v1/template';
import {
  QueryObserverResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  DefaultButton,
  IconButton,
  Modal,
  PrimaryButton,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import sanitizeHtml from 'sanitize-html';

import useStore from './store/useStore';
import { SingleTemplateShimmer } from './TemplateLoadingShimmers';
import TemplateEditor from './TemplateEditor';
import TemplateStarrer from './TemplateStarrer';
import {
  CHROME_EXTENSION_WIDTH,
  REACT_QUERY_CACHE_STALE_TIME,
} from './constants';
import DeleteIcon from './icons/DeleteIcon';
import EditIcon from './icons/EditIcon';
import TemplateDeleteDialog from './TemplateDeleteDialog';
import {
  TEMPLATES_ACTION_TYPE,
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  TEMPLATES_VIEW,
  TEMPLATE_EDITOR_CLOSED_VIA,
  trackTemplatesEvent,
} from './analytics/templatesEvents';

type TemplateViewerProps = {
  onSubmit: (data: Template | undefined) => void;
  onReplace?: (data: Template | undefined) => void;
  refetchTemplateFolders: () => Promise<QueryObserverResult>;
};

const modalStyles = { scrollableContent: 'p-6 max-w-[350px]' };
const templateEditorStyles = {
  scrollableContent: `w-[${CHROME_EXTENSION_WIDTH}]`,
  layer: { zIndex: 1000002 },
};
const modalLayerProps = {
  styles: {
    content: 'groove',
  },
};
const btnStyles = { root: 'h-5 w-5 p-0 text-clari-blue/600' };

const TemplateViewer: FC<TemplateViewerProps> = ({
  onSubmit,
  onReplace,
  refetchTemplateFolders,
}) => {
  const queryClient = useQueryClient();
  const selectedTemplate = useStore(store => store.selectedTemplate);
  const selectedFolder = useStore(store => store.selectedFolder);
  const isOutlook = useStore(store => store.isOutlook);
  const selectedTemplateId = selectedTemplate?.id;
  const {
    data: template,
    isFetching: isFetchingTemplate,
    refetch: refetchTemplate,
  } = useQuery(
    ['templateGet', selectedTemplateId],
    () => templateGet(selectedTemplateId || 0),
    REACT_QUERY_CACHE_STALE_TIME,
  );
  const { isSuccess, mutate: deleteTemplate } = useMutation(templateDelete);

  const [isTemplatesEditorOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [
    isDeleteTemplateConfirmationOpen,
    setIsDeleteTemplateConfirmationOpen,
  ] = useState<boolean>(false);

  const favoriteTemplates = useStore(store => store.favoriteTemplates);
  const setSelectedTemplate = useStore(store => store.setSelectedTemplate);
  const templateFolders = useStore(store => store.templateFolders);

  useEffect(() => {
    if (isSuccess) {
      refetchTemplateFolders();
    }
  }, [isSuccess, refetchTemplateFolders, setSelectedTemplate]);

  useEffect(() => {
    if (!template?.data?.id && !isFetchingTemplate) {
      setSelectedTemplate(undefined);
    }
  }, [setSelectedTemplate, template, isFetchingTemplate]);

  let isTemplateEditable = false;
  if (selectedFolder) {
    isTemplateEditable = selectedFolder.is_editable;
  } else if (template) {
    const templateFolderData = templateFolders?.data?.find(
      folder => folder.id === template.data.folder_id,
    );
    if (templateFolderData) {
      isTemplateEditable = templateFolderData.is_editable;
    }
  }

  if (isFetchingTemplate) return <SingleTemplateShimmer />;

  const commonStyles =
    'p-4 border-0 border-b-[1px] border-solid border-neutral/100';

  const refetchOnSuccess = (): void => {
    refetchTemplateFolders();
    refetchTemplate();
    queryClient.invalidateQueries('favoriteTemplatesGet');
    queryClient.invalidateQueries('recentTemplatesGet');
  };
  const bodyContainer = document.createElement('div');
  bodyContainer.innerHTML = template?.data.body || '';
  const sanitizeHtmlBody = sanitizeHtml(bodyContainer.innerHTML);

  // Width of app - templates menu width - width for scrollbar
  const viewerMaxWidth = isOutlook
    ? `max-w-[calc(100vw-397px)]` // 100vw - 400px - 3px
    : ``;
  return (
    <>
      <div className="flex flex-col w-full">
        {selectedTemplateId && template?.data && (
          <div className={`flex flex-col h-full ${viewerMaxWidth}`}>
            <div className={`flex justify-between ${commonStyles}`}>
              <Text className="overflow-x-hidden text-base font-semibold font-groove">
                {template.data.name}
              </Text>
              {isTemplateEditable && (
                <div className="flex items-center">
                  {!isOutlook && (
                    <TemplateStarrer
                      isFavorite={favoriteTemplates?.some(
                        favorite => favorite.id === template?.data.id,
                      )}
                      templateId={template.data.id}
                      isPreviewView
                      isInModalView
                    />
                  )}
                  <IconButton
                    className="ml-1"
                    styles={btnStyles}
                    aria-label="template-edit-button"
                    onClick={() => {
                      showModal();
                      trackTemplatesEvent({
                        eventName: TEMPLATES_EVENT_TYPE.EDIT_MODAL_OPENED,
                        templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
                      });
                    }}
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    aria-label="template-delete-button"
                    styles={btnStyles}
                    onClick={() => setIsDeleteTemplateConfirmationOpen(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
            </div>
            <div className={commonStyles}>
              <Text className="text-sm font-semibold font-groove">
                {template?.data.subject}
              </Text>
            </div>
            <div
              className={`flex-1 ${commonStyles} overflow-y-auto overflow-wrap-anywhere`}
            >
              <div
                // Re-use code from templatePreview
                id="templates-preview-body"
                className="flex flex-col flex-grow p-3 overflow-auto font-groove text-body-sm"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: sanitizeHtmlBody || '' }}
              />
            </div>
            <div className={`flex justify-end border-b-0 ${commonStyles}`}>
              {onReplace && (
                <DefaultButton
                  aria-label="template-preview-replace"
                  className="font-groove mr-2 rounded"
                  onClick={() => onReplace(template.data)}
                >
                  Replace
                </DefaultButton>
              )}
              <PrimaryButton
                aria-label="template-preview-insert"
                className="font-groove text-white bg-clari-blue/600 hover:bg-clari-blue/700 active:bg-clari-blue/800 rounded"
                onClick={() => {
                  onSubmit(template.data);
                  trackTemplatesEvent({
                    eventName: TEMPLATES_EVENT_TYPE.INSERTED,
                    templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
                    templateView: TEMPLATES_VIEW.PREVIEW,
                  });
                }}
              >
                Insert
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={isTemplatesEditorOpen}
        forceFocusInsideTrap={false}
        closeButtonAriaLabel="Dismiss Templates Editor"
        onDismiss={() => {
          trackTemplatesEvent({
            eventName: TEMPLATES_EVENT_TYPE.EDITOR_CLOSED,
            templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
            templateActionType: TEMPLATES_ACTION_TYPE.EDIT,
            closedVia: TEMPLATE_EDITOR_CLOSED_VIA.X_BUTTON,
          });
        }}
        layerProps={modalLayerProps}
        styles={templateEditorStyles}
      >
        <div className="flex px-3 pt-4">
          <Text className="text-lg font-semibold font-groove">
            Edit Template
          </Text>
          <IconButton
            className="ml-auto text-neutral/900 hover:text-neutral/900"
            iconProps={{ iconName: 'cancel' }}
            onClick={hideModal}
          />
        </div>

        <TemplateEditor
          action="EDIT"
          initialFolderId={template?.data?.folder_id || 0}
          initialName={template?.data?.name || ''}
          initialSubject={template?.data?.subject || ''}
          initialBody={template?.data?.html_body || ''}
          dismissEditor={hideModal}
          refetchOnSuccess={refetchOnSuccess}
        />
      </Modal>

      {/* Delete Confirmation dialog */}
      <Modal
        isOpen={isDeleteTemplateConfirmationOpen}
        isBlocking={false}
        layerProps={modalLayerProps}
        styles={modalStyles}
      >
        <TemplateDeleteDialog
          template={selectedTemplate}
          onDeleteClick={() => {
            if (selectedTemplateId) deleteTemplate(selectedTemplateId);
            setIsDeleteTemplateConfirmationOpen(false);
            trackTemplatesEvent({
              eventName: TEMPLATES_EVENT_TYPE.DELETED,
              deletedConfirmed: true,
            });
          }}
          onCancelClick={() => {
            setIsDeleteTemplateConfirmationOpen(false);
            trackTemplatesEvent({
              eventName: TEMPLATES_EVENT_TYPE.DELETED,
              deletedConfirmed: false,
            });
          }}
        />
      </Modal>
    </>
  );
};

export default TemplateViewer;
