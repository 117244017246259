import firebaseApp from 'firebase/compat/app';

import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';

import {
  isUsingGEReviewApp,
  isUsingGEStaging,
  isUsingGEDemo,
  isUsingGEProduction,
  isUsingGEQA,
} from 'Utils/reviewAppUtils';

let FIREBASE_CONFIGURATION;

if (isUsingGEReviewApp() || isUsingGEStaging() || isUsingGEDemo()) {
  FIREBASE_CONFIGURATION = Object.freeze({
    apiKey: process.env.REACT_APP_FIRESTORE_API_KEY_STAGING,
    authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN_STAGING,
    databaseURL: process.env.REACT_APP_FIRESTORE_DATABASE_URL_STAGING,
    projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID_STAGING,
    messagingSenderId: process.env.FIREBASE_CLOUD_MESSAGING_SENDER_ID_STAGING,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_STAGING,
  });
} else if (isUsingGEQA()) {
  FIREBASE_CONFIGURATION = Object.freeze({
    apiKey: process.env.REACT_APP_FIRESTORE_API_KEY_QA,
    authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN_QA,
    databaseURL: process.env.REACT_APP_FIRESTORE_DATABASE_URL_QA,
    projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID_QA,
    messagingSenderId: process.env.FIREBASE_CLOUD_MESSAGING_SENDER_ID_QA,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_QA,
  });
} else if (isUsingGEProduction()) {
  FIREBASE_CONFIGURATION = Object.freeze({
    apiKey: process.env.REACT_APP_FIRESTORE_API_KEY_PRODUCTION,
    authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN_PRODUCTION,
    databaseURL: process.env.REACT_APP_FIRESTORE_DATABASE_URL_PRODUCTION,
    projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID_PRODUCTION,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_PRODUCTION,
    messagingSenderId:
      process.env.FIREBASE_CLOUD_MESSAGING_SENDER_ID_PRODUCTION,
  });
} else {
  FIREBASE_CONFIGURATION = Object.freeze({
    apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
    authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIRESTORE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID,
    messagingSenderId: process.env.FIREBASE_CLOUD_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  });
}

const firebase = firebaseApp.initializeApp(FIREBASE_CONFIGURATION);

export default firebase;
