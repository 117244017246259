import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {
  SMART_PRIORITY_CUTOFFS,
  SMART_PRIORITY_LABELS,
} from 'Modules/ActionsRewrite/components/customCells/SmartPriority/constants';

const useStyles = makeStyles(theme => ({
  scoreBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: theme.palette['neutral/600'],
    border: 'solid 1px',
    width: 38,
    height: 35,
  },
  high: {
    color: theme.palette['bittersweet-red'],
  },
  medium: {
    color: theme.palette['naranja/50'],
  },
  default: {
    color: theme.palette['neutral/100'],
  },
}));

type DisplayScoreParams = {
  smartPriority: number;
};

const DisplayScore = ({ smartPriority }: DisplayScoreParams) => {
  const styles = useStyles();
  let scoreClassName = styles.default;
  if (typeof smartPriority !== 'number') scoreClassName = styles.default;
  else if (smartPriority >= SMART_PRIORITY_CUTOFFS.HIGH)
    scoreClassName = styles.high;
  else if (smartPriority >= SMART_PRIORITY_CUTOFFS.MEDIUM)
    scoreClassName = styles.medium;
  else if (smartPriority >= SMART_PRIORITY_CUTOFFS.LOW)
    scoreClassName = styles.default;

  const displayedSmartPriority =
    typeof smartPriority === 'number' &&
    smartPriority >= SMART_PRIORITY_CUTOFFS.LOW
      ? `${smartPriority}`
      : SMART_PRIORITY_LABELS.DEFAULT;

  return (
    <Box className={styles.scoreBox}>
      <Typography className={scoreClassName}>
        {displayedSmartPriority}
      </Typography>
    </Box>
  );
};

export default DisplayScore;
