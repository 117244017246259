import { createActions, keyMirror } from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SHARED: {
    LABEL_INSTANCES: {
      ...keyMirror(['REMOVE', 'UPDATE']),
    },
  },
}).SHARED.LABEL_INSTANCES;

export function update({ labelable, labelInstances, checkedLabelIds }) {
  return {
    type: actionTypes.UPDATE,
    payload: { labelable, labelInstances, checkedLabelIds },
  };
}

export function remove({ id, labelInstances, labelable }) {
  return {
    type: actionTypes.REMOVE,
    payload: { id, labelInstances, labelable },
  };
}
