import { fromJS } from 'immutable';
import { actionTypes as peopleImportActionTypes } from 'Modules/PeopleImportDialog/actions';

import { actionTypes } from './actions';

const initialState = fromJS({
  query: '',
  fetching: false,
  listviews: [],
});

export default function peopleImportDialog(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.FETCH_LIST_VIEWS.BEGIN: {
      return state.set('fetching', true).set('listviews', null);
    }
    case actionTypes.FETCH_LIST_VIEWS.SUCCESS: {
      return state.set('fetching', false).mergeDeep(action.payload);
    }
    case actionTypes.FETCH_LIST_VIEWS.FAILURE: {
      return state.set('fetching', false);
    }
    case peopleImportActionTypes.RESET: {
      return initialState;
    }

    case actionTypes.RESET_FILTERS: {
      return initialState;
    }
    case peopleImportActionTypes.SET_ACTIVE_TAB: {
      const { payload } = action;

      if (payload !== 'salesforceListView') {
        return initialState;
      }

      return state;
    }

    default:
      return state;
  }
}
