/**
 * This will remove punctuation characters from a string before it is sorted.
 * @param {string} inputString
 *
 * @return {string}
 */
export function ignoreSpecialCharacters(inputString) {
  const punctuationExp = /[~`!@#$%^&*()_+\-={}[\];:"'\\|<,>.?–’/]/g;
  return inputString?.replace(punctuationExp, ' ').replace(/\s+/g, ' ').trim();
}
