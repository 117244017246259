import shortid from 'shortid';
import makeHTTPRequest from '../makeHTTPRequest';
import * as GrooveLocalStorage from 'GrooveLocalStorage';

/**
 * this shortid gets passed to the `has_valid_salesforce_connection` endpoint
 * as a way to guarantee that the first invocation on page load does _not_ use
 * the browser cache.
 */
const checkSalesforceConnectionCacheBuster = shortid.generate();
const checkGoogleConnectionCacheBuster = shortid.generate();
const checkOAuthConnectionCacheBuster = shortid.generate();

export const getCurrentUser = () => makeHTTPRequest({ path: '/api/v2/user' });
export const getUserOrgSettings = () =>
  makeHTTPRequest({ path: '/api/v2/user/org_settings' });
export const getLdUser = () => {
  const loginAsTime = GrooveLocalStorage.fetch('loginAsTime') || null;
  return makeHTTPRequest({
    path: 'users/ld_user',
    queryParameters: { cacheBuster: loginAsTime },
  });
};
export const checkSalesforceConnection = () =>
  makeHTTPRequest({
    path: '/api/v2/user/has_valid_salesforce_connection',
    queryParameters: {
      cacheBuster: checkSalesforceConnectionCacheBuster,
    },
  });
export const checkGoogleConnection = () =>
  makeHTTPRequest({
    path: '/api/v2/user/has_valid_google_connection',
    queryParameters: {
      cacheBuster: checkGoogleConnectionCacheBuster,
    },
  });
export const checkOAuthConnection = () =>
  makeHTTPRequest({
    path: '/api/v2/user/has_valid_oauth_tokens',
    queryParameters: {
      cacheBuster: checkOAuthConnectionCacheBuster,
    },
  });
export const getActivityOptions = () =>
  makeHTTPRequest({ path: '/api/v2/activity_options' });

export const index = () => makeHTTPRequest({ path: '/api/v2/users' });

export const optimizedIndex = () =>
  makeHTTPRequest({
    path: '/api/v2/users',
    queryParameters: { optimized: true },
  });

export const basicIndex = () =>
  makeHTTPRequest({ path: '/api/v2/users/basic_index' });

export const searchUsers = ({ phrase }) =>
  makeHTTPRequest({
    path: 'api/v2/users/search',
    queryParameters: { phrase },
  });

export const todaysProjectedEmailUsage = () =>
  makeHTTPRequest({
    path: '/api/v2/user/todays_projected_email_usage',
  });

export const getManagedPackageVersion = () =>
  makeHTTPRequest({
    path: '/api/v2/user/groove_managed_package_version',
  });
