import { createActions } from '@groove-labs/action-utils';
import { FSA } from 'flux-standard-action';

const types = {
  SHARED: {
    FEATURE_FLAGS: {
      SET_FEATURE_FLAGS: 'SET_FEATURE_FLAGS',
    },
  },
};

export const actionTypes =
  createActions<typeof types>(types).SHARED.FEATURE_FLAGS;

type FeatureFlagPayload = {
  [key: string]: boolean;
};

export function setFeatureFlags(
  payload: FeatureFlagPayload
): FSA<string, FeatureFlagPayload> {
  return {
    type: actionTypes.SET_FEATURE_FLAGS,
    payload,
  };
}
