import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {
  sharedLabelStyle,
  SMART_PRIORITY_LABELS,
} from 'Modules/ActionsRewrite/components/customCells/SmartPriority/constants';

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette['neutral/90'],
    backgroundColor: theme.palette['neutral/75'],
    ...sharedLabelStyle,
  },
}));

const DefaultChip = () => {
  const styles = useStyles();

  return (
    <Typography className={styles.label}>
      {SMART_PRIORITY_LABELS.DEFAULT}
    </Typography>
  );
};

export default DefaultChip;
