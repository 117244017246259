import { List, Map } from 'immutable';

import { getSnackbarWarnings } from 'Modules/App/selectors';
import {
  actionTypes,
  set as setWarnings,
} from 'Modules/App/actions/snackbarWarnings';

import { all, fork, put, select, takeEvery } from 'redux-saga/effects';

// --------------  Helpers --------------

function determineDismissedSetting(existingWarning, key, message) {
  // keep dismissed setting when it's key and message are the same
  if (!existingWarning) return false;

  if (
    existingWarning.get('key') === key &&
    existingWarning.get('message') === message
  ) {
    return existingWarning.get('dismissed');
  }
  return false;
}

// -------------- Handlers --------------
function* handleAdd({ payload }) {
  const { key, message, submessage, variant } = payload;

  let warnings = (yield select(getSnackbarWarnings)) || List();

  const existingWarningIndex =
    warnings && warnings.findIndex(warning => warning.get('key') === key);
  const existingWarning =
    existingWarningIndex !== null ? warnings.get(existingWarningIndex) : null;

  // keep dismissed setting when it's key and message are the same
  const dismissed = determineDismissedSetting(existingWarning, key, message);

  const newWarning = Map({
    key,
    dismissed,
    message,
    submessage,
    variant,
  });

  if (existingWarning) {
    if (existingWarning.equals(newWarning)) return;
    warnings = warnings.remove(existingWarningIndex);
  }

  warnings = warnings.push(newWarning);

  yield put(setWarnings(warnings));
}

function* handleDismiss({ payload: { key } }) {
  let warnings = yield select(getSnackbarWarnings);

  if (!warnings) return;

  warnings = warnings.toArray();

  const dismissedWarningIndex = warnings.findIndex(
    warning => warning.get('key') === key
  );

  const dismissedWarning = warnings[dismissedWarningIndex];

  warnings[dismissedWarningIndex] = dismissedWarning.set('dismissed', true);

  yield put(setWarnings(List(warnings)));
}

// -------------- Watchers --------------
function* watchAdd() {
  yield takeEvery(actionTypes.ADD, handleAdd);
}

function* watchDismiss() {
  yield takeEvery(actionTypes.DISMISS, handleDismiss);
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([fork(watchAdd), fork(watchDismiss)]);
}
