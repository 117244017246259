import { DELETE, POST, PUT } from '../../HTTPMethod';
import makeHTTPRequest from '../../makeHTTPRequest';

import { getAdminContentVisibilityEnabled } from 'GrooveHTTPClient/flows';

/**
 * @param {number} flowId
 *
 * @return {Promise}
 * */
export const index = flowId =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/automated_actions`,
    queryParameters: {
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const create = ({ flowId, automatedAction }) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/automated_actions`,
    method: POST,
    body: {
      automated_action: { ...automatedAction, id: null },
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const update = ({ flowId, automatedAction }) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/automated_actions/${automatedAction.id}`,
    method: PUT,
    body: {
      automated_action: automatedAction,
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
  });

export const destroy = ({ flowId, automatedAction }) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/automated_actions/${automatedAction.id}`,
    queryParameters: {
      admin_content_visibility_enabled: getAdminContentVisibilityEnabled(),
    },
    method: DELETE,
  });
