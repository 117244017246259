import { FC, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';

import DropDownFrame from './DropDownFrame';
import Item, { DropDownItem, StandardDropDownItem } from './DropDownItem';
import Input from './Input';

// export all the types from DropDown Item
export * from './DropDownItem';

export type MergeFieldDropDownProps = {
  items: DropDownItem[];
  onChange: (value: StandardDropDownItem | null) => void;
  onOpenChange: (value: boolean) => void;
  maxHeight?: number;
  isOpen: boolean;
};

const MergeFieldDropDown: FC<MergeFieldDropDownProps> = ({
  items,
  onChange,
  onOpenChange,
  maxHeight = 200,
  isOpen,
}) => {
  const listRef = useRef<VariableSizeList | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropDownRef = useRef<VariableSizeList<any>>(null);
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState<DropDownItem[]>([]);

  useEffect(() => {
    const newItems: DropDownItem[] = [];
    if (items.length > 0) {
      items.forEach(item => {
        if (!item) return;
        if (
          !searchValue ||
          (!item.type && (item.value as string).includes(searchValue))
        )
          newItems.push(item);

        if (item.type === 'header') {
          while (
            newItems.length > 0 ||
            newItems[newItems.length - 1]?.type === ('divider' || 'header')
          ) {
            newItems.pop();
          }
        }
      });
      setFilteredItems(newItems);
    } else {
      setFilteredItems(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, items.length]);

  useEffect(() => {
    if (!isOpen) setSearchValue('');
    if (isOpen) inputRef.current?.focus();
  }, [isOpen]);

  // These two helper functions are needed because dividers and headers are part of a DropDown
  const getNextSelectableIndex = (index: number): number => {
    if (filteredItems.length < 1) return -1;
    if (!filteredItems[index].type) return index;

    for (let i = index; i < filteredItems.length; i += 1) {
      if (!filteredItems[i].type) return i;
    }

    return -1;
  };

  const getPrevSelectableIndex = (index: number): number => {
    if (!filteredItems[index].type) return index;

    for (let i = index; i >= 0; i -= 1) {
      if (!filteredItems[i].type) return i;
    }

    return filteredItems.length - 1;
  };

  const [highlightedIndex, setHighlightedIndex] = useState<number>(() =>
    getNextSelectableIndex(0),
  );

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [filteredItems]);

  useEffect(() => {
    if (!isOpen) {
      dropDownRef.current?.scrollToItem(-1);
    }
  }, [isOpen]);

  const setSearch = (value: string): void => {
    setSearchValue(value);
  };

  const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    let newIndex;
    if (event.key === 'Enter') {
      if (searchValue.length > 0 || !filteredItems[highlightedIndex].type)
        handleItemClick(
          filteredItems[highlightedIndex] as StandardDropDownItem,
        );
    } else if (event.key === 'ArrowDown') {
      if (highlightedIndex + 1 >= filteredItems.length)
        newIndex = getNextSelectableIndex(0);
      else newIndex = getNextSelectableIndex(highlightedIndex + 1);
      setHighlightedIndex(newIndex);
      dropDownRef.current?.scrollToItem(newIndex);
    } else if (event.key === 'ArrowUp') {
      if (highlightedIndex <= 0)
        newIndex = getPrevSelectableIndex(filteredItems.length - 1);
      else newIndex = getPrevSelectableIndex(highlightedIndex - 1);
      setHighlightedIndex(newIndex);
      dropDownRef.current?.scrollToItem(newIndex);
    } else {
      onOpenChange?.(true);
    }
  };

  const handleItemClick = (value: StandardDropDownItem): void => {
    onChange?.(value);
    setSearch('');
    onOpenChange?.(false);
  };

  return (
    <div className="relative h-0 w-0">
      <DropDownFrame
        itemList={filteredItems}
        itemSize={index => {
          const item = filteredItems[index];
          if (item?.type === 'header') return 26;
          if (item?.type === 'divider') return 4;
          return item?.secondaryText ? 48 : 32;
        }}
        isVariable
        showDropDown={isOpen}
        renderedItem={Item}
        width="180px"
        maxHeight={maxHeight}
        itemData={{
          hasSearch: true,
          highlightedIndex,
          items: filteredItems,
          onClick: handleItemClick,
          searchTerm: searchValue,
        }}
        innerRef={dropDownRef}
      >
        <div className="px-[8px] pt-[8px] pb-[4px]">
          <Input
            className="text-metadata"
            placeholder="Search"
            onKeyDown={onInputKeyDown}
            value={searchValue}
            onChange={e => setSearch(e.target.value || '')}
            innerRef={inputRef}
          />
        </div>
      </DropDownFrame>
    </div>
  );
};

export default MergeFieldDropDown;
