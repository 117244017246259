import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';
import { OrderedMap } from 'immutable';

export const actionTypes = createActions({
  PEOPLE: {
    FETCH_PEOPLE: standardActions(),
    TOGGLE_HOTLIST: standardActions(),
    ADD_PEOPLE_TO_FLOW: standardActions(),
    REMOVE_PEOPLE_FROM_ALL_FLOWS: standardActions(),
    PERMANENTLY_ERASE: standardActions(),
    FETCH_ONE_FLOW_RESTRICTION: standardActions(),
    ...keyMirror([
      'UPDATE_QUERY',
      'UPDATE_QUERY_AND_REFETCH',
      'SELECT_PEOPLE',
      'UNSELECT_PEOPLE',
      'SET_ACTIVE_PERSON_ID',
      'UNSET_ACTIVE_PERSON_ID',
      'MOVE_TO_PAGE',
      'RESET_REDUCER',
      'OPEN_ACCOUNT_PREVIEW',

      'ADD_SEARCH_RULE',
      'APPLY_ADVANCED_SEARCH',
      'CONNECT_GROUP_ID_TO_SEARCH_RULE',
      'SET_EDITED_SEARCH_RULES',
      'REMOVE_ACTIVE_SEARCH_RULE',
      'REMOVE_SEARCH_RULE',
      'UPDATE_ACTIVE_SEARCH_RULES_AND_QUERY',
      'UPDATE_EDITED_SEARCH_RULES',

      'SELECT_SAVED_SEARCH',
      'SET_SAVED_SEARCH_CONFIRM',
      'SET_SELECTED_SAVED_SEARCH_ID',
      'FETCH_SAVED_SEARCHES',
      'SET_SAVED_SEARCHES',
      'REMOVE_SAVED_SEARCH',
      'SAVE_SAVED_SEARCH',
      'ADVANCED_SEARCH_BUTTON_CLICKED',
    ]),
  },
}).PEOPLE;

export function fetchPeopleBegin() {
  return {
    type: actionTypes.FETCH_PEOPLE.BEGIN,
  };
}

export function fetchPeopleSuccess(person) {
  return {
    type: actionTypes.FETCH_PEOPLE.SUCCESS,
    payload: {
      people: new OrderedMap([[person.id, person]]), // Use array syntax to preserve keys as integers
      total: 1,
    },
  };
}

export function selectPeople(people) {
  return {
    type: actionTypes.SELECT_PEOPLE,
    payload: people,
  };
}

export function unselectPeople(people) {
  return {
    type: actionTypes.UNSELECT_PEOPLE,
    payload: people,
  };
}

export function setActivePersonId(personId) {
  return {
    type: actionTypes.SET_ACTIVE_PERSON_ID,
    payload: personId,
  };
}

export function unsetActivePersonId() {
  return {
    type: actionTypes.UNSET_ACTIVE_PERSON_ID,
  };
}

export function updateQuery(payload) {
  return {
    type: actionTypes.UPDATE_QUERY,
    payload,
  };
}

export function updateQueryAndRefetch(payload) {
  return {
    type: actionTypes.UPDATE_QUERY_AND_REFETCH,
    payload,
  };
}

export function moveToPage(payload) {
  return {
    type: actionTypes.MOVE_TO_PAGE,
    payload,
  };
}

export function toggleHotlist(payload) {
  return {
    type: actionTypes.TOGGLE_HOTLIST.BEGIN,
    payload,
  };
}

export function addPeopleToFlowBegin(payload) {
  return {
    type: actionTypes.ADD_PEOPLE_TO_FLOW.BEGIN,
    payload,
  };
}

export function removePeopleFromAllFlowsBegin(payload) {
  return {
    type: actionTypes.REMOVE_PEOPLE_FROM_ALL_FLOWS.BEGIN,
    payload,
  };
}

export function openPeopleAccountPreview(accountId) {
  return {
    type: actionTypes.OPEN_ACCOUNT_PREVIEW,
    payload: accountId,
  };
}

export function setAdvancedPeopleSearch() {
  return {
    type: actionTypes.SET_ADVANCED_PEOPLE_SEARCH,
  };
}

export function addSearchRule() {
  return {
    type: actionTypes.ADD_SEARCH_RULE,
  };
}

export function removeSearchRule(payload) {
  return {
    type: actionTypes.REMOVE_SEARCH_RULE,
    payload,
  };
}

export function applyAdvancedSearch() {
  return {
    type: actionTypes.APPLY_ADVANCED_SEARCH,
  };
}

export function setEditedSearchRules() {
  return {
    type: actionTypes.SET_EDITED_SEARCH_RULES,
  };
}

export function updateEditedSearchRules(payload) {
  return {
    type: actionTypes.UPDATE_EDITED_SEARCH_RULES,
    payload,
  };
}

export function updateActiveSearchRulesAndQuery(payload) {
  return {
    type: actionTypes.UPDATE_ACTIVE_SEARCH_RULES_AND_QUERY,
    payload,
  };
}

export function removeActiveSearchRule(payload) {
  return {
    type: actionTypes.REMOVE_ACTIVE_SEARCH_RULE,
    payload,
  };
}

export function connectGroupIdToSearchRule(payload) {
  return {
    type: actionTypes.CONNECT_GROUP_ID_TO_SEARCH_RULE,
    payload,
  };
}

export function fetchSavedSearches() {
  return {
    type: actionTypes.FETCH_SAVED_SEARCHES,
  };
}

export function setSavedSearches(payload) {
  return {
    type: actionTypes.SET_SAVED_SEARCHES,
    payload,
  };
}

export function setSelectedSavedSearchId(payload) {
  return {
    type: actionTypes.SET_SELECTED_SAVED_SEARCH_ID,
    payload,
  };
}

export function selectSavedSearch(payload) {
  return {
    type: actionTypes.SELECT_SAVED_SEARCH,
    payload,
  };
}

export function saveSavedSearch(payload) {
  return {
    type: actionTypes.SAVE_SAVED_SEARCH,
    payload,
  };
}

export function removeSavedSearch(payload) {
  return {
    type: actionTypes.REMOVE_SAVED_SEARCH,
    payload,
  };
}

export function permanentlyEraseBegin(payload) {
  return {
    type: actionTypes.PERMANENTLY_ERASE.BEGIN,
    payload,
  };
}

export function advancedSearchButtonClicked() {
  return {
    type: actionTypes.ADVANCED_SEARCH_BUTTON_CLICKED,
  };
}

export function fetchOneFlowRestrictionData() {
  return {
    type: actionTypes.FETCH_ONE_FLOW_RESTRICTION.BEGIN,
  };
}

export function setOneFlowRestrictionData(payload) {
  return {
    type: actionTypes.FETCH_ONE_FLOW_RESTRICTION.SUCCESS,
    payload,
  };
}

export function setOneFlowRestrictionFailed() {
  return {
    type: actionTypes.FETCH_ONE_FLOW_RESTRICTION.FAILURE,
  };
}
