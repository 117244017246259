/* eslint-disable @typescript-eslint/no-non-null-assertion */
import './fullcalendar-material.css';

import React, { forwardRef, useEffect, useState } from 'react';
import { IconButton, Text, TooltipHost } from '@fluentui/react';
import FullCalendar, { EventInput } from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { useQuery, useQueryClient } from 'react-query';
import {
  Event,
  schedulerEvents as events,
} from '@groove/api/services/v1/scheduler';
import {
  differenceInMinutes,
  getISOWeek,
  getISOWeekYear,
  parseISO,
  areIntervalsOverlapping,
} from 'date-fns';
import moment from 'moment-timezone';
import { theme } from '@groove/ui/theme';

import {
  SCHEDULER_BLOCK_TYPES,
  SCHEDULER_UI,
  trackSchedulerEvent,
} from '../analytics/event';
import useStore from '../store/useStore';
import {
  isDateStringDiff,
  getWeekStartDateAndIgnoreTimeZone,
} from '../utils/time';

const iconBtnStyles = {
  icon: 'text-[8px]',
};

type CalendarProps = {
  duration: number | null;
  timeZone: string | null;
  userIds: number[];
  userColors: { [key: string]: string };
  allowDoubleBooking: boolean;
  onIsEventsLoading: (isLoading: boolean) => void;
};

// This is the existing EventInput type used by fullcalendar, but with some additional parameters passed from
// the groove backend as well as reification of the start and end types to work with interval checks
export type ParsedEvent = EventInput &
  Event & {
    start: Date;
    end: Date;
  };

const isBusy = (event: Event | ParsedEvent): boolean => {
  return event.transparency !== 'transparent';
};

const Calendar = forwardRef<FullCalendar, CalendarProps>(
  (
    {
      duration,
      timeZone,
      userIds,
      userColors,
      allowDoubleBooking,
      onIsEventsLoading,
    },
    ref,
  ) => {
    const timeSlots = useStore(store => store.timeSlots);
    const insertTimeSlots = useStore(store => store.insertTimeSlots);
    const removeTimeSlot = useStore(store => store.removeTimeSlot);
    const clearTimeSlots = useStore(store => store.clearTimeSlots);
    const recalculateValidTimeSlots = useStore(
      store => store.recalculateValidTimeSlots,
    );
    const queryClient = useQueryClient();

    const [currentTime, setCurrentTime] = useState(new Date());

    const {
      data: eventsData,
      isLoading,
      isStale,
    } = useQuery(
      ['events', currentTime.toString(), userIds],
      () =>
        events(getISOWeek(currentTime), getISOWeekYear(currentTime), userIds),
      {
        enabled: userIds.length > 0,
        onError: error => {
          console.log('error getting user events on calendar', error);
          queryClient.invalidateQueries('mail_and_calendar_provider');
        },
      },
    );

    /* For all-day events, the date should be set irrespective of time zones since parseISO was not giving the correct date.
    we have to manipulate the startDate and endDate based on the timezone getTimezoneOffset.
    Get the timezone offset for the selected timezone, UTCOffset() function returns the offset in minutes.
    compare the desired offset and the getTimezoneOffset() of the startDate.
    if the current offset is behind the desired offset, so we add 24 hours in milliseconds.
    then calculate the start date in UTC by adding up the timestamp of startDate in milliseconds
    and he current offset in milliseconds and desired offset
     */
    const userEvents = eventsData?.data?.map(event => {
      const offset = moment.tz(timeZone || 'UTC').utcOffset() * -1;

      const startDate = new Date(event.start);
      const startDateOffset =
        offset > startDate.getTimezoneOffset() ||
        (timeZone === 'America/Anchorage' &&
          offset === startDate.getTimezoneOffset())
          ? 24 * 60 * 60 * 1000
          : 0;
      const startDateUTC = new Date(
        startDate.getTime() +
          startDate.getTimezoneOffset() * 60000 +
          startDateOffset,
      );

      const endDate = new Date(event.end);
      const endDateOffset =
        offset > endDate.getTimezoneOffset() ||
        (timeZone === 'America/Anchorage' &&
          offset === endDate.getTimezoneOffset())
          ? 24 * 60 * 60 * 1000
          : 0;
      const endDateUTC = new Date(
        endDate.getTime() + endDate.getTimezoneOffset() * 60000 + endDateOffset,
      );

      return {
        ...event,
        title: event.title || (isBusy(event) ? 'Busy' : 'Free'),
        start: event.is_all_day ? startDateUTC : parseISO(event.start),
        end: event.is_all_day ? endDateUTC : parseISO(event.end),
        color: !isBusy(event) ? theme.gray4 : userColors[event.user_id],
        className: 'existing',
        display: 'block',
      } as ParsedEvent;
    });

    let combinedEvents = Array.from(timeSlots.values());

    if (userEvents) {
      combinedEvents = combinedEvents.concat(userEvents);
    }

    useEffect(() => {
      if (eventsData) {
        recalculateValidTimeSlots(eventsData.data);
      }
    }, [recalculateValidTimeSlots, eventsData]);

    useEffect(() => {
      if (duration) {
        clearTimeSlots();
      }
    }, [clearTimeSlots, duration]);

    useEffect(() => {
      onIsEventsLoading(isLoading && isStale);
    }, [isLoading, isStale, onIsEventsLoading]);

    return (
      <div className="flex-grow max-h-screen">
        <FullCalendar
          height="100%"
          ref={ref}
          plugins={[timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
          initialView="timeGridWeek"
          allDaySlot={eventsData?.data.some(
            e => e.is_all_day || e.is_greater_than_24_hours,
          )}
          nowIndicator
          eventOrder="-title"
          firstDay={1}
          datesSet={({ start, startStr }) => {
            if (isDateStringDiff(start, currentTime)) {
              setCurrentTime(getWeekStartDateAndIgnoreTimeZone(startStr));
            }
          }}
          timeZone={timeZone || 'local'}
          eventColor={theme['primary-dark-alt']}
          eventClick={eventObj => {
            const target = (eventObj.jsEvent.target as Element) || null;
            if (
              target?.classList.contains('ms-Button') ||
              target?.classList.contains('ms-Button-icon')
            ) {
              removeTimeSlot(eventObj.event.start!);
              trackSchedulerEvent({
                eventName: 'Time Blocks Deleted',
                schedulerUi: SCHEDULER_UI.ADD_SPECIFIC_TIMES,
              });
            }
          }}
          selectable
          scrollTime="08:00:00"
          snapDuration={{ minutes: duration || 30 }}
          events={combinedEvents}
          eventContent={content => {
            const duration = differenceInMinutes(
              content.event.end!,
              content.event.start!,
            );
            return (
              <TooltipHost
                content={
                  content.event.title
                    ? `${content.timeText}\n${content.event.title}`
                    : content.timeText
                }
              >
                <div className="flex" style={{ height: 'inherit' }}>
                  <Text
                    variant="medium"
                    block
                    nowrap
                    className={
                      content.event.extendedProps.isTimeSlot
                        ? 'overflow-hidden text-white'
                        : 'overflow-hidden text-black text-[10px] font-semibold'
                    }
                  >
                    {duration <= 45
                      ? content.event.title || content.timeText
                      : `${content.timeText}\n${content.event.title}`}
                  </Text>
                  {content.event.extendedProps.isTimeSlot && (
                    <IconButton
                      ariaLabel="Remove Time Slot"
                      className="w-4 h-4 ml-auto text-white overflow-visible"
                      styles={iconBtnStyles}
                      iconProps={{ iconName: 'ChromeClose' }}
                    />
                  )}
                </div>
              </TooltipHost>
            );
          }}
          eventDataTransform={event => {
            if (!event) return {};
            return {
              ...event,
              allDay: event.is_all_day || event.is_greater_than_24_hours,
              color:
                event.extendedProps?.isTimeSlot &&
                !allowDoubleBooking &&
                userEvents?.some(
                  userEvent =>
                    areIntervalsOverlapping(userEvent, event as Interval) &&
                    isBusy(userEvent),
                )
                  ? 'red'
                  : event.color,
            };
          }}
          selectMirror
          select={arg => {
            // After selecting, the placeholder sticks around, so
            // we need to programmatically remove it.
            arg.view.calendar.unselect();
            const timeSlotDuration = duration || 30;
            insertTimeSlots(
              arg.start,
              arg.end,
              timeSlotDuration,
              userEvents || [],
            );
            const diff =
              (arg.end.getTime() - arg.start.getTime()) / (60 * 1000);
            trackSchedulerEvent({
              eventName: 'Time Blocks Created',
              schedulerUi: SCHEDULER_UI.ADD_SPECIFIC_TIMES,
              blockTypes:
                diff > timeSlotDuration
                  ? SCHEDULER_BLOCK_TYPES.MULTIPLE
                  : SCHEDULER_BLOCK_TYPES.INDIVIDUAL,
            });
          }}
          selectAllow={date => date.start > new Date()}
        />
      </div>
    );
  },
);

export default Calendar;
