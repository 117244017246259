import React from 'react';
import { GrooveLogo } from '@groove-labs/groove-ui';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import notificationPrompt from 'Modules/Emails/assets/notificationPrompt.svg';
import sampleNotification from 'Modules/Emails/assets/sampleNotification.svg';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    width: 316,
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing.unit * 4,
  },
  title: {
    fontWeight: 400,
  },
  content: {
    width: 256,
    paddingBottom: theme.spacing.unit * 2,
  },
  contentTypography: {
    lineHeight: '25px',
    paddingBottom: theme.spacing.unit * 2,
  },
  notificationPrompt: {
    position: 'relative',
    top: -30,
    left: -5,
  },
  sampleNotification: {
    paddingLeft: theme.spacing.unit * 2,
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    textTransform: 'none',
    boxShadow: 'none',
  },
});

const Onboarding = ({ onComplete, classes }) => {
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <GrooveLogo />
        <Typography className={classes.title} variant="h6">
          Email Activity Notifications
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography className={classes.contentTypography}>
          Click “Allow” to get notified when someone opens your email
        </Typography>
        <img
          className={classes.notificationPrompt}
          src={notificationPrompt}
          alt="Notification Prompt"
        />
        <img
          className={classes.sampleNotification}
          src={sampleNotification}
          alt="Sample Notification"
        />
      </div>
      <div className={classes.footer}>
        <Button
          className={classes.button}
          color="primary"
          onClick={onComplete}
          variant="contained"
          size="small"
        >
          Got it
        </Button>
      </div>
    </div>
  );
};

Onboarding.displayName = 'Onboarding';
Onboarding.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    contentTypography: PropTypes.string.isRequired,
    footer: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default withStyles(styles)(Onboarding);
