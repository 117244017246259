import { MutableRefObject } from 'react';

/**
 * This function calculates the width of an element with text as if it is one line on screen
 * Please make sure to set both the font size and font family of the ref object to the object
 * you are calculating
 *
 * @param text What is the text label
 * @param ref The ref to either the element or the containing element that has the correct font
 * @param canvasId String Id of what canvas to use
 * @param paddingMargin The total horizontal padding and margin
 * @returns The width of that element
 */
const calcTextWidth = (
  text: string | JSX.Element,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: MutableRefObject<any>,
  canvasId: string,
  paddingMargin = 0,
): number => {
  let textWidth = 0;

  if (typeof text === 'string') {
    // Let's do some crazy canvas stuff to check how wide our text is going to be
    let canvas = document.getElementById(canvasId) as HTMLCanvasElement;

    if (!canvas) {
      canvas = document.createElement('canvas');
      canvas.setAttribute('id', canvasId);
    }
    const context = canvas.getContext('2d');

    if (context && ref.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const element = ref.current as any as Element;
      context.font = getComputedStyle(element).getPropertyValue('font');
    }

    textWidth = paddingMargin;
    textWidth += context?.measureText(text).width || 0;
  }

  return Math.ceil(textWidth);
};

export default calcTextWidth;
