import { trackEvent } from 'Utils/segment';
import { takeEvery, select } from 'redux-saga/effects';
import { actionTypes as flowShowActionTypes } from 'Modules/FlowsShow/actions';
import { actionTypes as templateActionTypes } from 'Modules/Templates/actions';
import {
  createSafeActionHandler,
  ANALYTICS_BASE_PROPERTIES,
} from 'Modules/Shared/sagas/analytics';
import { actionTypes as labelActionTypes } from 'Modules/Shared/actions/labels';
import { getCurrentUserRole } from 'Modules/Shared/selectors/users';
import { getLabels } from 'Modules/Shared/selectors/labels';

const featureCategory = 'label';

function* labelsApplied() {
  yield takeEvery(
    action =>
      action.type === flowShowActionTypes.ADD_LABEL_INSTANCES ||
      action.type === templateActionTypes.ADD_LABEL_INSTANCES,
    createSafeActionHandler(({ payload }) => {
      const { labelInstances } = payload;
      labelInstances.forEach(labelInstance => {
        trackEvent('Label Applied', {
          ...ANALYTICS_BASE_PROPERTIES,
          featureCategory,
          labelableType: labelInstance.labelable.type.toLowerCase(),
          labelScope: labelInstance.label.labelOwner.type.toLowerCase(),
          labelCategory: labelInstance.label.category.toLowerCase(),
        });
      });
    })
  );
}

function* labelCreated() {
  yield takeEvery(
    labelActionTypes.ADD_LABEL,
    createSafeActionHandler(function* track({ payload }) {
      const { label } = payload;
      const currentUserRole = yield select(getCurrentUserRole);

      trackEvent('Label Created', {
        ...ANALYTICS_BASE_PROPERTIES,
        featureCategory,
        labelCategory: label.category.toLowerCase(),
        labelScope: label.labelOwner.type.toLowerCase(),
        creatorRole: currentUserRole.toLowerCase(),
      });
    })
  );
}

function trackLabelFilter({ labelCategory, labelScope }) {
  trackEvent('Filtered by Label', {
    ...ANALYTICS_BASE_PROPERTIES,
    featureCategory,
    labelCategory,
    labelScope,
  });
}

function* filteredByLabels() {
  yield takeEvery(
    labelActionTypes.REQUEST_LABELS_FILTER,
    createSafeActionHandler(function* track({ payload }) {
      const { checkedLabelIds } = payload;
      const labels = yield select(getLabels);

      checkedLabelIds.forEach(id => {
        const labelCategory = labels.get(id).category.toLowerCase();
        const labelScope = labels.get(id).labelOwner.type.toLowerCase();
        trackLabelFilter({ labelCategory, labelScope });
      });
    })
  );
}

function* filterBySingleLabel() {
  yield takeEvery(
    labelActionTypes.SET_SINGLE_LABEL_FILTER,
    createSafeActionHandler(function* track({ payload }) {
      const { id } = payload;
      const labels = yield select(getLabels);
      const labelCategory = labels.get(id).category.toLowerCase();
      const labelScope = labels.get(id).labelOwner.type.toLowerCase();
      trackLabelFilter({ labelCategory, labelScope });
    })
  );
}

export { labelsApplied, labelCreated, filteredByLabels, filterBySingleLabel };
