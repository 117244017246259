import { FC, useEffect, useLayoutEffect } from 'react';
import { useGetCurrentUser } from '@groove/api/hooks/useUsers';
import useDialerStore from '@groove/dialer-components/hooks/useStore';
import Tooltip from '@groove/ui/Components/Tooltip';

import {
  DIALER_SETTINGS_URL,
  INVALID_BRIDGE_DIAL,
  NO_GROOVE_NUMBER,
} from '../../constants';
import useStore, { defaultDialerState } from '../../store/useStore';

import VMDropButton from './VMDropButton';
import KeyPadButton from './KeyPad';
import LocalDialButton from './LocalDialButton';
import DialerBarMessageArea from './DialerBarMessageArea';
import RecordButton from './RecordButton';
import MuteButton from './MuteButton';
import ConstantBridgeButton from './ConstantBridgeButton';

const DialerBar: FC = () => {
  const { data: userData } = useGetCurrentUser();
  const grooveNumber = userData?.phone_number || '';
  const isBridgeDialInvalid =
    userData?.bridge_calling_enabled && !userData.bridge_calling_number;

  /**
   * Check if there is a groove phone number or bridge dial number.
   * Using useLayoutEffect for smooth transition between different messageBar messages
   */
  useLayoutEffect(() => {
    if (!grooveNumber)
      useStore.getState().addMessage({
        id: NO_GROOVE_NUMBER,
        level: 'warning',
        message: (
          <span>
            Please make sure you have provisioned a Groove phone number in{' '}
            <a href={DIALER_SETTINGS_URL} target="_blank" rel="noreferrer">
              settings
            </a>
            .
          </span>
        ),
        noCloseButton: true,
      });

    if (isBridgeDialInvalid)
      useStore.getState().addMessage({
        id: INVALID_BRIDGE_DIAL,
        level: 'warning',
        message: (
          <span>
            Please make sure you have provisioned a Bridge Dial number in{' '}
            <a href={DIALER_SETTINGS_URL} target="_blank" rel="noreferrer">
              settings
            </a>
            .
          </span>
        ),
        noCloseButton: true,
      });
  }, [grooveNumber, isBridgeDialInvalid]);

  /**
   * Set Bridge dialing state
   */
  useEffect(() => {
    useDialerStore
      .getState()
      .setBridgeCallingEnabled(userData?.bridge_calling_enabled || false);
  }, [userData?.bridge_calling_enabled]);

  /**
   * Clean the initial state
   */
  useEffect(() => {
    useStore.getState().updateDialerState(defaultDialerState);
    return () => {
      useStore.getState().updateDialerState(defaultDialerState);
    };
  }, []);

  /**
   * Warn users if they don't have a Groove phone number
   */
  let callerIdTooltip;

  if (!grooveNumber)
    callerIdTooltip = (
      <span>
        Please make sure you have provisioned a Groove phone number in{' '}
        <a href={DIALER_SETTINGS_URL} target="_blank" rel="noreferrer">
          settings
        </a>
        .
      </span>
    );

  return (
    <div className="flex flex-row bg-spot-on-cotton border-0 border-t border-solid border-neutral/100 h-[54px] items-center relative">
      {!grooveNumber && (
        <Tooltip
          content={callerIdTooltip}
          className="h-full w-full !absolute z-[1000]"
        >
          <div className="bg-none w-full h-full absolute cursor-not-allowed" />
        </Tooltip>
      )}
      <DialerBarMessageArea />
      <div className="flex-1 flex pr-[12px]">
        <div className="flex-1 flex" />

        <div className="border-0 !border-r border-solid border-neutral/100 flex pr-[4px]">
          <ConstantBridgeButton />
          <LocalDialButton />
        </div>

        <div className="flex pl-[4px]">
          <KeyPadButton />
          <RecordButton />
          <MuteButton />
          <VMDropButton />
        </div>
      </div>
    </div>
  );
};

export default DialerBar;
