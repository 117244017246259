export function alphabetically(ascending) {
  return function comparator(a, b) {
    if (a === null) {
      return 1;
    } else if (b === null) {
      return -1;
    } else if (ascending) {
      return `${a}`.toLowerCase().localeCompare(`${b}`.toLowerCase());
    }

    return `${b}`.toLowerCase().localeCompare(`${a}`.toLowerCase());
  };
}

/**
 * This will sort the lists of steps by day number
 * @param  {List} unsortedSteps
 *
 * @return {List} sortedList
 *
 */
export function sortStepsByDay(unsortedSteps) {
  const daySortedSteps = unsortedSteps.sort((original, next) => {
    if (original.get('dayNumber') < next.get('dayNumber')) {
      return -1;
    }
    if (original.get('dayNumber') > next.get('dayNumber')) {
      return 1;
    }
    return 0;
  });

  return daySortedSteps;
}
