export const SAFE_EXTENSIONS = [
  'csv',
  'doc',
  'docx',
  'gif',
  'ics',
  'indd',
  'jpeg',
  'jpg',
  'json',
  'mp3',
  'mp4',
  'numbers',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'txt',
  'xls',
  'xlsx',
];

export const MAX_FILE_SIZE = 20 * 1024 * 1024;
