import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import moment from 'moment';

import { LINKED_IN_SAVE_KEY } from 'Modules/Shared/constants';
import { actionTypes } from 'Modules/Shared/actions/users';
import { fetch, save } from 'GrooveLocalStorage';
import { getSalesAccessToken } from 'GrooveHTTPClient/linkedin';

export default function* handleFetchLinkedInSat(): SagaIterator {
  yield put({ type: actionTypes.FETCH_LINKEDIN_SAT.PROGRESS });

  // fetch 30min cached token from localStorage
  const storedToken = yield call(fetch, LINKED_IN_SAVE_KEY);
  if (storedToken && moment().diff(storedToken.last_set, 'minutes') < 30) {
    yield put({
      type: actionTypes.FETCH_LINKEDIN_SAT.SUCCESS,
      payload: storedToken.token,
    });
    return;
  }

  try {
    const response = yield call(getSalesAccessToken);
    if (response.status === 200) {
      yield put({
        type: actionTypes.FETCH_LINKEDIN_SAT.SUCCESS,
        payload: response.data.token,
      });
      // cache the token in local storage client side
      yield call(save, LINKED_IN_SAVE_KEY, {
        token: response.data.token,
        last_set: new Date(),
      });
    } else {
      yield put({ type: actionTypes.FETCH_LINKEDIN_SAT.FAILURE });
    }
  } catch (e) {
    yield put({ type: actionTypes.FETCH_LINKEDIN_SAT.FAILURE });
  }
}
