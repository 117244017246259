type StringInsertParams = {
  newString: string;
  originalString: string;
  selectionStart: number;
  selectionEnd: number;
};
type StringInserterReturn = {
  newCursorPosition: number;
  combinedString: string;
};
type StringInserterType = (params: StringInsertParams) => StringInserterReturn;

export const stringInserter: StringInserterType = ({
  newString,
  selectionStart,
  selectionEnd,
  originalString,
}) => {
  let resultString = '';
  if (selectionStart > 0) {
    resultString += originalString.substring(0, selectionStart);
    if (!/[\n\r\s]$/.test(resultString)) resultString += ' ';
  }
  resultString += newString;
  if (selectionEnd + 1 <= originalString.length) {
    const endString = originalString.substring(
      selectionEnd,
      originalString.length,
    );
    if (!/^[\n\r\s]/.test(endString)) resultString += ' ';
    resultString += endString;
  }

  const newPosition = selectionStart + newString.length + 1;

  return {
    newCursorPosition: newPosition,
    combinedString: resultString,
  };
};
