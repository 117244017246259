import React, { FC, useEffect } from 'react';
import { ActionButton, Text } from '@fluentui/react';
import TruncatedTextWithTooltip from '@groove/ui/Components/TruncatedTextWithTooltip';

import useStore from './store/useStore';
import { TemplateLoadingShimmers } from './TemplateLoadingShimmers';
import TemplatesListItem from './TemplatesListItem';
import {
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  trackTemplatesEvent,
} from './analytics/templatesEvents';

type TemplatesListProps = {
  isFetching?: boolean;
};

const TemplatesList: FC<TemplatesListProps> = ({
  isFetching: isFetchingTemplateFolder,
}) => {
  const templateFolder = useStore(store => store.selectedFolder);
  const setSelectedTemplate = useStore(store => store.setSelectedTemplate);
  const setSelectedFolderId = useStore(store => store.setSelectedFolderId);
  const templatesList = templateFolder?.templates.sort((a, b) => {
    return b.updated_at.localeCompare(a.updated_at);
  });
  const setSelectedFolder = useStore(store => store.setSelectedFolder);
  const searchTerm = useStore(store => store.searchTerm);

  useEffect(() => {
    if (templateFolder) {
      setSelectedTemplate(
        templateFolder?.templates.length > 0
          ? templateFolder.templates[0]
          : undefined,
      );
    }
  }, [setSelectedTemplate, templateFolder]);

  return (
    <div className="flex flex-col w-full h-auto">
      <div className="flex items-center sticky top-0 z-10 px-2 bg-white border-solid border-0 border-b border-neutral/100">
        <ActionButton
          className="min-w-max"
          onClick={() => {
            setSelectedFolder(undefined);
            setSelectedTemplate(undefined);
            setSelectedFolderId(undefined);
            if (!searchTerm) {
              trackTemplatesEvent({
                eventName: TEMPLATES_EVENT_TYPE.ALL_FOLDERS_LIST_OPENED,
                templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.MODAL,
              });
            }
          }}
        >
          <Text className="text-sm font-semibold text-clari-blue/600 font-groove">
            {searchTerm ? 'Back to search results' : 'All Folders'}
          </Text>
        </ActionButton>
        {!searchTerm && (
          <div className="flex flex-grow">
            <Text className="text-sm">/&nbsp;</Text>
            <TruncatedTextWithTooltip
              text={templateFolder?.name || ''}
              textClasses="text-sm"
            />
          </div>
        )}
      </div>
      <div className="w-full h-full overscroll-none">
        {isFetchingTemplateFolder && <TemplateLoadingShimmers />}
        {!isFetchingTemplateFolder &&
          templatesList &&
          templatesList.map(template => (
            <TemplatesListItem
              template={template}
              key={`template-${template.id}`}
            />
          ))}
      </div>
    </div>
  );
};

export default TemplatesList;
