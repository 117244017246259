import { FC, useEffect, useState, useRef } from 'react';
import {
  Checkbox,
  Dropdown,
  IconButton,
  Pivot,
  PivotItem,
  Spinner,
  TextField,
} from '@fluentui/react';
import Expand from '@groove/ui/Components/Expand';
import { useDebouncedCallback } from 'use-debounce';
import { Transaction } from '@sentry/types';
import * as Sentry from '@sentry/browser';

import Actions from './Actions';
import Review from './Review';
import useSearchResults, {
  useMyRecordsOnly,
  useOpenOpportunitiesOnly,
  useOrderBy,
  useOrderDir,
  useSearchResultObjectTypes,
} from './useSearchResults';
import useStore, { SelectedItems } from './useStore';
import Results from './Results';
import SearchZeroState from './SearchZeroState';
import SearchNoResults from './SearchNoResults';
import useSentry from './sentry/initializeSentry';

export type SelectedItem = {
  id: string;
  name: string;
  type: string;
  text?: string | null;
  secondaryText?: string | null;
  secondaryTextLabel?: string | null;
  tertiaryText?: string | null;
  tertiaryTextLabel?: string | null;
  quarteraryText?: string | null;
  quarteraryTextLabel?: string | null;
  quinaryText?: string | null;
  quinaryTextLabel?: string | null;
  subItems?: {
    [key: string]: SelectedItem;
  };
};

export type SearchAndSelectSortBy = 'lastactivitydate' | 'lastmodifieddate';
export type SearchAndSelectSortDir = 'asc' | 'desc';
export type SearchAndSelectFilter =
  | 'myRecordsOnly'
  | 'openOpportunitiesOnly'
  | 'both'
  | 'none';

type SearchAndSelectProps = {
  onDismiss?: (selectedItems?: SelectedItems) => void;
  onSubmit: (
    selectedItems: SelectedItems,
    additionalFields: { [key: string]: string | number | boolean | null },
  ) => void;
  additionalFieldsInitialValues?: {
    [key: string]: string | number | boolean | null;
  };
  additionalFieldsType: 'email' | 'calendar';
  actionBtnText?: string;
  handleOmnibarContextChange?: (id: string) => void;
  onFilterChange?: (
    sortBy: SearchAndSelectSortBy,
    sortDir: SearchAndSelectSortDir,
    filter: SearchAndSelectFilter,
  ) => void;
};

const SearchAndSelect: FC<SearchAndSelectProps> = ({
  onDismiss,
  onSubmit,
  additionalFieldsInitialValues,
  additionalFieldsType,
  actionBtnText = 'Log to Salesforce',
  handleOmnibarContextChange,
  onFilterChange,
}) => {
  useSentry();
  const isReviewing = useStore(store => store.isReviewing);
  const setIsReviewing = useStore(store => store.setIsReviewing);
  const query = useStore(store => store.searchQuery);
  const setQuery = useStore(store => store.setSearchQuery);

  const [search, setSearch] = useState<string>(query);

  const [debouncedSearch] = useDebouncedCallback(() => setQuery(search), 500);

  const selectedItems = useStore(store => store.selectedItems);

  const additionalFields = useStore(store => store.additionalFields);
  const setAdditionalFields = useStore(store => store.setAdditionalFields);
  const setAdditionalFieldsType = useStore(
    store => store.setAdditionalFieldsType,
  );

  const objectFilter = useStore(store => store.objectFilter);
  const setObjectFilter = useStore(store => store.setObjectFilter);

  const transactionRef = useRef<Transaction | null>(null);

  const { data: transformedData, isLoading } = useSearchResults();
  const objectTypes = useSearchResultObjectTypes();

  useEffect(() => {
    Sentry.setTag('component', 'search-and-select-full');
  }, []);

  useEffect(() => {
    if (isLoading) {
      transactionRef.current = Sentry.startTransaction({
        name: 'Search and Select Data Fetch',
      });
    } else {
      transactionRef.current?.finish();
    }
  }, [isLoading]);

  useEffect(() => {
    if (additionalFieldsInitialValues) {
      setAdditionalFields(additionalFieldsInitialValues);
    }
  }, [additionalFieldsInitialValues, setAdditionalFields]);

  useEffect(() => {
    setAdditionalFieldsType(additionalFieldsType);
  }, [additionalFieldsType, setAdditionalFieldsType]);

  useEffect(() => {
    setSearch(query);
  }, [query, setSearch]);

  useEffect(() => {
    if (Object.values(selectedItems).length === 0) {
      setIsReviewing(false);
    }
  }, [selectedItems, setIsReviewing]);

  const [orderBy, setOrderBy] = useOrderBy();
  const [orderDir, setOrderDir] = useOrderDir();
  const [myRecordsOnly, setMyRecordsOnly] = useMyRecordsOnly();
  const [openOpportunitiesOnly, setOpenOpportunitiesOnly] =
    useOpenOpportunitiesOnly();

  const handleFilterChange = (
    newOrderBy: string,
    newDir: string,
    newMyRecordsOnly: boolean,
    newOpportunitiesOnly: boolean,
  ): void => {
    if (onFilterChange) {
      let filter;
      if (newMyRecordsOnly && !newOpportunitiesOnly) {
        filter = 'myRecordsOnly';
      } else if (newOpportunitiesOnly && !newMyRecordsOnly) {
        filter = 'openOpportunitiesOnly';
      } else if (newMyRecordsOnly && newOpportunitiesOnly) {
        filter = 'both';
      } else {
        filter = 'none';
      }
      onFilterChange(
        newOrderBy as SearchAndSelectSortBy,
        newDir as SearchAndSelectSortDir,
        filter as SearchAndSelectFilter,
      );
    }
  };

  return (
    <div className="relative flex flex-col h-full">
      <Expand open={!isReviewing}>
        <TextField
          label="Search"
          placeholder="Type at least 3 characters to search…"
          onChange={(e, value) => {
            setSearch(value || '');
            debouncedSearch();
          }}
          value={search}
        />
        <div className="flex items-center gap-4 my-4">
          <Dropdown
            className="relative top-[-10px]"
            label="Order By"
            selectedKey={orderBy}
            onChange={(_e, option) => {
              setOrderBy(option?.key as string);
              handleFilterChange(
                option?.key as string,
                orderDir,
                myRecordsOnly,
                openOpportunitiesOnly,
              );
            }}
            options={[
              {
                key: 'lastmodifieddate',
                text: 'Last Modified Date',
                ariaLabel: 'Last Modified Date',
              },
              {
                key: 'lastactivitydate',
                text: 'Last Activity Date',
                ariaLabel: 'Last Activity Date',
              },
            ]}
          />
          <IconButton
            ariaLabel="Toggle Sort Order"
            iconProps={{ iconName: orderDir === 'desc' ? 'Up' : 'Down' }}
            onClick={() => {
              const newDir = orderDir === 'desc' ? 'asc' : 'desc';
              handleFilterChange(
                orderBy,
                newDir,
                myRecordsOnly,
                openOpportunitiesOnly,
              );
              setOrderDir(newDir);
            }}
          />
          <Checkbox
            label="My Records Only"
            ariaLabel="My Records Only"
            checked={myRecordsOnly}
            onChange={(e, checked) => {
              handleFilterChange(
                orderBy,
                orderDir,
                !!checked,
                openOpportunitiesOnly,
              );
              setMyRecordsOnly(!!checked);
            }}
          />
          <Checkbox
            label="Open Opportunities Only"
            ariaLabel="Open Opportunities Only"
            checked={openOpportunitiesOnly}
            onChange={(e, checked) => {
              handleFilterChange(orderBy, orderDir, myRecordsOnly, !!checked);
              setOpenOpportunitiesOnly(!!checked);
            }}
          />
        </div>
        {isLoading && (
          <div className="p-4">
            <Spinner />
          </div>
        )}
        <Expand open={!isLoading} className="flex flex-col">
          {transformedData && transformedData?.length > 0 && (
            <Pivot
              headersOnly
              selectedKey={objectFilter}
              onLinkClick={item =>
                item?.props.itemKey && setObjectFilter(item.props.itemKey)
              }
            >
              <PivotItem
                key="All"
                headerText="All"
                itemKey="All"
                itemCount={transformedData?.length}
              />
              {objectTypes?.map(([objectType, objectCount]) => {
                return (
                  <PivotItem
                    key={objectType}
                    itemKey={objectType || undefined}
                    headerText={objectType || ''}
                    itemCount={objectCount}
                  />
                );
              })}
            </Pivot>
          )}
          <div className="pb-20">
            {query.length === 0 && <SearchZeroState />}
            {query.length !== 0 && transformedData?.length === 0 && (
              <SearchNoResults />
            )}
            <Results handleOmnibarContextChange={handleOmnibarContextChange} />
          </div>
        </Expand>
      </Expand>
      <Expand open={isReviewing} className="flex-grow">
        <Review selectedItems={selectedItems} />
      </Expand>
      {!isLoading && (
        <Actions
          isReviewing={isReviewing}
          disabled={Object.values(selectedItems).length === 0}
          onToggleReview={() => setIsReviewing(!isReviewing)}
          onDismiss={() => {
            if (onDismiss) onDismiss(selectedItems);
          }}
          reviewItems={
            Object.values(selectedItems).length +
            Object.values(selectedItems).reduce(
              (acc, item) => acc + Object.values(item.subItems || {}).length,
              0,
            )
          }
          onSubmit={() => onSubmit(selectedItems, additionalFields)}
          actionBtnText={actionBtnText}
        />
      )}
    </div>
  );
};

export default SearchAndSelect;
