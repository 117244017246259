import { StickyFilters } from '@groove/api/user';
import { ApiUser, userUpdate } from '@groove/api/services/v1/user';
import { useMutation, UseMutationResult } from 'react-query';

const useUpdateFilters = (): UseMutationResult<
  ApiUser,
  unknown,
  StickyFilters,
  unknown
> =>
  useMutation((stickyFilters: StickyFilters) => {
    return userUpdate({ variables: { sticky_filters_omnibar: stickyFilters } });
  });

export default useUpdateFilters;
