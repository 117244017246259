import React from 'react';

import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

import { styles as sharedStyles } from 'Modules/Shared/components/SearchInput';

const styles = {
  root: sharedStyles.root,
  icon: sharedStyles.icon,
};

const LoaderSearchInput = ({ classes }) => {
  return (
    <div className={classes.root}>
      <SearchIcon className={classes.icon} />
    </div>
  );
};

LoaderSearchInput.displayName = 'LoaderSearchInput';
LoaderSearchInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(LoaderSearchInput);
