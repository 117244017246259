import { toNumber, isNaN, groupBy, extend, isNil, isString } from 'lodash-es';
import moment from 'moment-timezone';
import { List } from 'immutable';
import { COLUMN_TYPES } from 'Modules/Shared/components/Table/constants';
import sanitizer from 'Modules/Analytics/utils/sanitizer';

const aggregateGroupedStatsForUser = usersActivity => {
  const stats = {
    all: 0,
    emails: 0,
    trackedEmails: 0,
    calls: 0,
    actions: 0,
    openedEmails: 0,
    clickedEmails: 0,
    repliedEmails: 0,
  };

  usersActivity.forEach(activity => {
    stats.all += activity.activitiesCount;
    switch (activity.type) {
      case 'Email':
        stats.emails += activity.activitiesCount;
        stats.trackedEmails += activity.sumTracked;
        stats.openedEmails += activity.sumTimesOpened;
        stats.clickedEmails += activity.sumTimesLinkClicked;
        stats.repliedEmails += activity.sumReplyRecorded;
        break;
      case 'Call':
        stats.calls += activity.activitiesCount;
        break;
      default:
        stats.actions += activity.activitiesCount;
        break;
    }
  });

  return stats;
};

/**
 * Transform users data for activity tab.
 * Includes the totals at the end.
 *
 * @param {array} usersData
 * @param {array} activities
 * */
export const buildActivityReportingUsersData = (usersData, activities) => {
  const users = [usersData.length]; // create Array object with given length

  const activitiesByUserId = groupBy(activities, activity => activity.userId);

  // process calculation for each user
  return usersData.reduce((accumulator, user) => {
    // aggregate activities
    if (activitiesByUserId[user.id]) {
      // join user's data with activity statistics
      accumulator[user.index] = extend(
        user,
        aggregateGroupedStatsForUser(activitiesByUserId[user.id])
      );
    } else {
      // no activities for give user, we just returns user's data without statistics
      accumulator[user.index] = user;
    }

    return accumulator;
  }, users);
};

/**
 * Transform calls data for calls tab.
 *
 * @param {array} activities
 * @param {array} activityResultsById
 * @param {array} activityOutcomesById
 * @param {number} resultFilterId
 *
 * @return {array} filteredActivities
 */
export const buildCallsData = (
  activities,
  activityResultsById,
  activityOutcomesById,
  resultFilterId
) => {
  const filteredActivities = [];
  const browserTimezone = moment.tz.guess();

  activities.forEach(record => {
    // skip when activityResultById is undefined; this properties are already set
    if (activityResultsById) {
      record.result = activityResultsById[record.activityResultId]
        ? activityResultsById[record.activityResultId].name
        : '';
      record.completedAt = moment
        .tz(record.completedAt, 'UTC')
        .tz(browserTimezone);
      record.completedAtTime = record.completedAt.format('HH:mm');
    }

    // If the activity outcome id is not defined or invalid, default to neutral sentiment.
    if (activityOutcomesById) {
      record.outcomeSentiment =
        record.activityOutcomeId &&
        activityOutcomesById[record.activityOutcomeId]
          ? activityOutcomesById[record.activityOutcomeId].sentiment
          : 'neutral';
    }

    // If the result filter id is null or undefined, then "All" is selected so don't filter.
    if (isNil(resultFilterId) || record.activityResultId === resultFilterId) {
      filteredActivities.push(record);
    }
  });

  return filteredActivities;
};

/**
 * Converts strings with valid numbers to numbers otherwise
 * returns the string passed in.
 *
 * @param {string} stringArg
 *
 * @return {string/number}
 */
export const convertScopeParam = stringArg => {
  const convertedString = toNumber(stringArg);

  return isNaN(convertedString) ? stringArg : convertedString;
};

/**
 * Formats row data. Converts dateTime strings to a readable format
 * and appends '%' to data which has 'percentage' set to true.
 *
 * @param {rowData} Map
 * @param {dataFieldsMeta} Array
 *
 * @return Map
 */
export const formatRowData = (rowData, dataFieldsMeta) => {
  return dataFieldsMeta.reduce((accumulator, column) => {
    const data = accumulator.get(column.id);

    if (isNil(data)) {
      return accumulator;
    }

    if (column.percentage) {
      return accumulator.set(column.id, `${data}%`);
    }
    if (column.type === COLUMN_TYPES.dateTime) {
      return accumulator.set(column.id, moment(data).fromNow());
    }

    return accumulator;
  }, rowData);
};

/**
 * Converts seconds to "hh:mm:ss"
 *
 * @param {seconds} Integer
 *
 * @return String
 */
export const formattedPeriod = seconds => {
  if (!seconds) return '00:00:00';

  const date = new Date(null);
  date.setSeconds(seconds);
  return date.toISOString().substr(11, 8);
};

/**
 * Sorts data.... Specifically for use with the <SummaryTable />
 *
 * @param {data} Immutable.Map
 * @param {orderBy} String
 * @param {orderDirection} String
 * @param {attributeParentKeyPath} Array - Allows you to specify if the data to be sorted has a nested key path, i.e. if the data
 *        is at ['rowValues', 'numOfEmails'] and not ['numOfEmails'], you can pass ['rowValues'] as the `attributeParentKeyPath`

 *
 * @return Immutable.Set
 */
export const sortData = ({
  data,
  orderBy,
  orderDirection,
  attributeParentKeyPath = [],
}) => {
  const returnComparator = orderDirection === 'asc' ? 1 : -1;

  const attributeKeyPath = [...attributeParentKeyPath, orderBy];

  return data.sort((el1, el2) => {
    const [a, b] = [el1, el2].map(el => {
      const currentVal = el.getIn(attributeKeyPath);
      return isString(currentVal) ? currentVal.toLowerCase() : currentVal;
    });
    if (a > b) {
      return returnComparator;
    } else if (a < b) {
      return -returnComparator;
    }

    return 0;
  });
};

/**
 * Determines which rows should be selected. Specifically for use with the <SummaryTable />
 *
 * @param {currentlySelected} Immutable.Set
 * @param {rowId} Integer
 *
 * @return Immutable.Set
 */
export const determineSelectedRowIds = ({ currentlySelected, rowId }) => {
  return currentlySelected.has(rowId)
    ? currentlySelected.delete(rowId)
    : currentlySelected.add(rowId);
};

/**
 * Builds a csv string from table rows and columns
 * columns: List [ Map({ label: "Some Label", id: "someId" })...]
 * rows: [ Map({ someId: "Some Value", ... })...]
 * The header values are derived from labels while the body values are the
 *
 * @param {Immutable.List} rows
 * @param {Immutable.List} columns
 *
 * @return {String}
 */
export const buildCsvFromRowsColumns = (rows, columns) => {
  const headerColumn = columns
    .map(column => column.get('label'))
    .toArray()
    .join(', ');
  return rows
    .reduce(
      (rowsArray, row) => {
        const tempRow = [];
        columns.forEach(column => {
          let rowValueForColumn = row.get(column.get('id'));
          if (column.get('composite')) {
            rowValueForColumn = column
              .get('columns')
              .map(key => row.get(key))
              .join(column.get('delimiter') || ' ');
          } else if (isNil(rowValueForColumn)) {
            rowValueForColumn = column.get('emptyValue') || ' ';
          } else if (column.get('boolean')) {
            rowValueForColumn = rowValueForColumn ? 'Yes' : 'No';
          } else if (column.get('percentage')) {
            rowValueForColumn = `${rowValueForColumn || 0}%`;
          } else if (column.get('numeric')) {
            rowValueForColumn = rowValueForColumn || 0;
          } else if (
            isString(rowValueForColumn) &&
            rowValueForColumn.includes(',')
          ) {
            rowValueForColumn = `"${rowValueForColumn}"`;
          }
          tempRow.push(rowValueForColumn);
        });
        rowsArray.push(tempRow.join(','));
        return rowsArray;
      },
      [headerColumn]
    )
    .join('\n');
};

/**
 * Builds a csv URL for the passed in string.
 *
 * @param {String} csv
 *
 * @return {String}
 */

export const buildCsvURL = csv => {
  const blob = new Blob([csv], { type: 'text/csv' });
  return window.URL.createObjectURL(blob);
};

const isNumber = value =>
  !Number.isNaN(Number(value)) && !Number.isNaN(parseFloat(value));

export function sanitizeObjectValues(object) {
  return Object.entries(object).reduce((acc, [key, value]) => {
    // Attempting to do math on anything that can't be coerced into a number returns NaN
    acc[key] = isNumber(value) ? sanitizer(parseFloat(value)) : value;
    return acc;
  }, {});
}

export const buildBlobFileName = ({ url, fileExtension }) => {
  const splitUrl = url.split('/');
  const fileName = splitUrl[splitUrl.length - 1];
  return `${fileName}${fileExtension}`;
};

export const downloadFileFromUrl = ({ url, fileName }) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
 * The teamScope key in URL can be a single string or an immutable List
 * @description takes the teamScope and converts it to an immutable list if it's a string
 * return the immutable list or return an empty immutable list if incorrect values given
 *
 * @param {object} urlTeamScope
 */
export function formatUrlTeamScope({ urlTeamScope }) {
  if (List.isList(urlTeamScope)) {
    return urlTeamScope;
  } else if (isString(urlTeamScope)) {
    return new List([parseInt(urlTeamScope, 10)]);
  }
  return new List();
}
