import grooveEmails, {
  AttachmentResult,
} from '@groove/api/visualforce/grooveEmails';
import {
  crmObject,
  FullAction,
  RecipientType,
} from '@groove/api/gateway/v1/actionCompose';
import {
  CONTACT_PREFIX,
  ACCOUNT_PREFIX,
  LEAD_PREFIX,
  OPPORTUNITY_PREFIX,
  salesforceFind,
  SalesforceMeta,
} from '@groove/api/gateway/v1/salesforce';
import { StandardDropDownItem } from '@groove/ui/Components/DropDownItem';
import { findAPerson, findOrCreatePerson } from '@groove/api/gateway/v1/people';
import useSearchAndSelectStore, {
  SelectedItems,
} from '@groove/search-and-select/useStore';

import useStore from '../store/useStore';
import { SALESFORCE_RECORD_TEXT } from '../constants';

import { getOptOutValues, syncWhoData } from './syncWhoData';
import { getSfdcTypeIcon } from './transformGrooveSearch';

/**
 * This function sets the related to when the system still has control over the related to
 * field.  Fetches max 15 related to.  Will stop working when a user adds something to the
 * related to field or touches it when there is something in there resets when the field is
 * empty
 *
 * @param emails - an array of email strings
 * @param id - sfdc id of the last who object added (Name field). Makes sure that it's a contact
 * @param autoCreated - should we set the related to as autocompleted, meaning that it is something the system can touch.  Will only touch the related to field when it's empty or when all the items in related to are autocomplete
 * @returns void
 */
export const setSalesforceRelatedTo = async (
  emails: string[],
  id?: string,
  autoCreated = true,
  initialCall = false,
): Promise<void> => {
  const selectedWhat: crmObject[] = [];

  // If there is non autocompleted item, we just leave
  const { loggingTo } = useStore.getState().action;
  if (
    loggingTo.what?.some(item => !item.autoCreated) ||
    (loggingTo.removedWhat && Object.keys(loggingTo.removedWhat).length > 0)
  )
    return;

  const { grooveMessageEvent: eventData } = useStore.getState().otherValues;
  const grooveEmailsData = await grooveEmails(emails);
  const topRelatedTos = Object.values(
    grooveEmailsData?.attachmentResults || {},
  )?.sort((a, b) => b.score - a.score);

  // set the top relatedTo for both the LoggingTo and the relatedTo fields
  const { loggingToDropdownItems, relatedToDropdownItems } =
    getLoggingTo(topRelatedTos);

  useStore.getState().setOtherValues({
    loggingToRelatedResults: loggingToDropdownItems,
    relatedToRelatedResults:
      relatedToDropdownItems.length > 0
        ? relatedToDropdownItems
        : loggingToDropdownItems,
  });

  // Always clear out the selectedWhats when it's a Lead
  if (
    loggingTo.who?.[0]?.id.toString().startsWith(CONTACT_PREFIX) &&
    Array.isArray(topRelatedTos) &&
    topRelatedTos.length > 0
  ) {
    for (let i = 0; i < topRelatedTos.length; i += 1) {
      const topRelatedTo = topRelatedTos[i];
      if (
        topRelatedTo &&
        topRelatedTo.what &&
        !loggingTo?.removedWhat?.[topRelatedTo.what?.Id || '']
      ) {
        selectedWhat.push({
          id: topRelatedTo.what?.Id,
          text: topRelatedTo.what.Name || SALESFORCE_RECORD_TEXT.NO_NAME,
          type: topRelatedTo.what.ApiName,
          autoCreated,
        });
      }
      // We want either the top 15 results or just the top one result depending on bestMatchEmailLogging setting
      if (eventData?.bestMatchEmailLogging || i === 14) break;
    }
  }
  useStore.getState().updateCurrentLoggingTo({ what: selectedWhat });
  if (initialCall)
    useStore.getState().updateOriginalLoggingTo({ what: selectedWhat });
};

const getLoggingTo = (
  topRelatedTo: AttachmentResult[],
): {
  loggingToDropdownItems: StandardDropDownItem[];
  relatedToDropdownItems: StandardDropDownItem[];
} => {
  const loggingToResults: StandardDropDownItem[] = [];
  const relatedToResults: StandardDropDownItem[] = [];

  topRelatedTo.forEach(relatedTo => {
    const { what, who } = relatedTo;
    const whoType = relatedTo.who?.Label;
    const whoItem: StandardDropDownItem = {
      key: who?.Id || '',
      text: '',
      tooltip: whoType,
      value: who?.Id || '',
      icon: getSfdcTypeIcon(whoType || ''),
    };

    const joinTextArray = [];
    whoItem.secondaryText = [] as string[];
    whoItem.metaData = { type: whoType || '' };

    switch (whoType) {
      case SALESFORCE_RECORD_TEXT.CONTACT:
      case SALESFORCE_RECORD_TEXT.LEAD:
        whoItem.text = who?.Name || SALESFORCE_RECORD_TEXT.NO_NAME;
        if (who?.Email) {
          joinTextArray.push(who?.Email);
          whoItem.metaData.email = who?.Email;
        }
        if (joinTextArray.length > 0)
          whoItem.secondaryText.push(joinTextArray.join(' - '));

        if (who?.Title) whoItem.secondaryText.push(who?.Title);

        loggingToResults.push(whoItem);
        break;
      default:
        whoItem.text = who?.Name || SALESFORCE_RECORD_TEXT.NO_NAME;
        whoItem.secondaryText.push(`Type: ${whoType}`);
        loggingToResults.push(whoItem);
    }

    if (what) {
      const whatType = what.Label;
      const result: StandardDropDownItem = {
        key: what.Id,
        text: '',
        tooltip: whatType,
        value: what.Id,
        icon: getSfdcTypeIcon(whatType),
      };
      result.secondaryText = [] as string[];
      result.metaData = { whatType };

      switch (whatType) {
        case SALESFORCE_RECORD_TEXT.OPPORTUNITY:
          result.text = what.Name || SALESFORCE_RECORD_TEXT.NO_NAME;
          result.secondaryText.push(`Type: ${whatType}`);
          relatedToResults.push(result);
          break;
        default:
          result.text = what.Name || SALESFORCE_RECORD_TEXT.NO_NAME;
          result.secondaryText.push(`Type: ${whatType}`);
          relatedToResults.push(result);
      }
    }
  });
  return {
    loggingToDropdownItems: loggingToResults,
    relatedToDropdownItems: relatedToResults,
  };
};

/**
 * This function sets the selectedItems in Advance search and select
 */
export const setAdvanceSearchAndSelect = async (): Promise<void> => {
  const selectedItems: SelectedItems = {};
  const accountIds: string[] = [];
  const contactIds: string[] = [];
  const leadIds: string[] = [];
  const opportunityIds: string[] = [];
  const { loggingTo } = useStore.getState().action;

  loggingTo.what?.forEach(what => {
    if (what.id.startsWith(OPPORTUNITY_PREFIX)) {
      opportunityIds.push(what.id);
    } else if (what.id.startsWith(ACCOUNT_PREFIX)) {
      accountIds.push(what.id);
    }
  });

  loggingTo.who?.forEach(who => {
    if (who.id.startsWith(CONTACT_PREFIX)) {
      contactIds.push(who.id);
    } else if (who.id.startsWith(LEAD_PREFIX)) {
      leadIds.push(who.id);
    }
  });

  const data = await salesforceFind({
    accountIds,
    contactIds,
    leadIds,
    opportunityIds,
  });

  data?.leads?.forEach(lead => {
    selectedItems[lead.Id] = {
      id: lead.Id,
      name: lead.Name,
      text: lead.Name || SALESFORCE_RECORD_TEXT.NO_NAME,
      type: SALESFORCE_RECORD_TEXT.LEAD,
      secondaryText: lead.Company,
      secondaryTextLabel: SALESFORCE_RECORD_TEXT.COMPANY,
      tertiaryText: lead.Email,
      tertiaryTextLabel: SALESFORCE_RECORD_TEXT.EMAIL,
    };
  });
  data?.contacts?.forEach(contact => {
    selectedItems[contact.Id] = {
      id: contact.Id,
      name: contact.Name,
      text: contact.Name || SALESFORCE_RECORD_TEXT.NO_NAME,
      type: SALESFORCE_RECORD_TEXT.CONTACT,
      secondaryText: contact?.Account?.Name,
      secondaryTextLabel: SALESFORCE_RECORD_TEXT.ACCOUNT,
      tertiaryText: contact.Email,
      tertiaryTextLabel: SALESFORCE_RECORD_TEXT.EMAIL,
      quarteraryText: contact.Title,
      quarteraryTextLabel: SALESFORCE_RECORD_TEXT.TITLE,
    };
  });
  data?.opportunities?.forEach(opportunity => {
    selectedItems[opportunity.Id] = {
      id: opportunity.Id,
      name: opportunity.Name,
      text: opportunity.Name || SALESFORCE_RECORD_TEXT.NO_NAME,
      type: SALESFORCE_RECORD_TEXT.OPPORTUNITY,
      secondaryText: opportunity?.Account?.Name,
      secondaryTextLabel: SALESFORCE_RECORD_TEXT.ACCOUNT,
      tertiaryText: opportunity.StageName,
      tertiaryTextLabel: SALESFORCE_RECORD_TEXT.STAGE,
      quarteraryText: opportunity.CloseDate,
      quarteraryTextLabel: SALESFORCE_RECORD_TEXT.CLOSE_DATE,
    };
  });

  data?.accounts?.forEach(account => {
    selectedItems[account.Id] = {
      id: account.Id,
      name: account.Name,
      text: account.Name || SALESFORCE_RECORD_TEXT.NO_NAME,
      type: SALESFORCE_RECORD_TEXT.ACCOUNT,
    };
  });

  useSearchAndSelectStore.setState({ selectedItems });
};

/**
 * This function first adds a who object to the who section of LoggingTo.  If it's a Lead or a
 * Contact not inside Email Type, it will replace everything else with just that lead, else it
 * will add the contact to the end of the array.
 * If there is no who or there is nothing in the ToRecipients, replace the who data with a new who.
 * Lastly call setSalesforceRelatedTo if the conditions are met.
 *
 * @param item - the drop down item from the Multiselect
 * @returns
 */
export const handleWhoSelect = async (
  item: StandardDropDownItem | null,
  grooveMeta: SalesforceMeta | undefined,
): Promise<void> => {
  if (!item) return;

  const { loggingTo, toRecipients, who, type } = useStore.getState().action;
  let whoArray: crmObject[] = [];

  if (
    item.value.toString().startsWith(CONTACT_PREFIX) &&
    loggingTo.who &&
    !loggingTo.who?.[0]?.id?.startsWith(LEAD_PREFIX) &&
    type === 'TEMPLATE'
  )
    whoArray = [...loggingTo.who];

  whoArray.push({
    id: item.value as string,
    text: item.text,
    type: item.metaData?.type || '',
    email: item.metaData?.email || '',
    autoCreated: true,
  });

  useStore.getState().updateCurrentLoggingTo({ who: whoArray });

  const data = await findOrCreatePerson(item.value.toString() || '');

  if (!data) return;

  const tempAction: Partial<Omit<FullAction, 'id'>> = {};

  let filteredRecipients = type === 'TEMPLATE' ? toRecipients : [];

  if (whoArray.length < 2 && type === 'TEMPLATE')
    filteredRecipients =
      toRecipients?.filter(recipient => !recipient.autoCreated) || [];

  tempAction.toRecipients = [
    ...(filteredRecipients || []),
    {
      id: data.id?.toString() || '',
      phone: data.phone,
      email: data.email,
      name: data.name,
      sfdcId: data.sfdcId,
      autoCreated: true,
    },
  ];

  syncWhoData([data.sfdcId], grooveMeta);
  addSFDCObjectToOmnibar(data.sfdcId, data.email);

  // Add a who if there isn't a who already or replace it if it's the first person
  if (!who || !toRecipients || toRecipients.length < 1 || type !== 'TEMPLATE') {
    tempAction.personId = data.id;
    tempAction.who = {
      doNotCall:
        getOptOutValues(data.sfdcId, data.sfdcData)?.doNotCall ||
        data.sfdcData.doNotCall,
      doNotEmail:
        getOptOutValues(data.sfdcId, data.sfdcData)?.doNotEmail ||
        data.sfdcData.hasOptedOutOfEmail,
      doNotSms:
        getOptOutValues(data.sfdcId, data.sfdcData)?.doNotSms ||
        data.sfdcData.hasOptedOutOfSms,
      company: data.companyName,
      email: data.email,
      firstName: data.sfdcData.firstName,
      lastName: data.sfdcData.lastName,
      name: data.name,
      id: data.id,
      sfdcId: data.sfdcId,
      mobilePhone: data.mobilePhone,
      phone: data.phone,
      sfdcType: data.sfdcData.attributes.type,
      title: data.sfdcData.title,
    };
  }

  if (
    !loggingTo.what ||
    !loggingTo.what.some(whatItem => !whatItem.autoCreated)
  )
    setSalesforceRelatedTo(
      whoArray.map(item => item.email || ''),
      item.value as string,
    );

  useStore.getState().updateAction(tempAction);
};

/**
 * This function handles the removal of a who object inside LoggingTo via an index
 *
 * @param index - index of the who object inside LoggingTo
 * @returns void
 */
export const handleWhoRemove = (index: number): void => {
  const { loggingTo, type, toRecipients } = useStore.getState().action;
  if (!loggingTo.who) return;
  const who = [...loggingTo.who];
  const tempWho = who.splice(index, 1)[0];
  const tempRecipients: RecipientType[] = [];
  const actionUpdate: Partial<Omit<FullAction, 'id' | 'loggingTo'>> = {};

  if (type === 'TEMPLATE') {
    toRecipients?.forEach(recipient => {
      if (recipient.autoCreated && recipient.sfdcId === tempWho.id) return;
      tempRecipients.push(recipient);
    });
  } else {
    actionUpdate.toRecipients = null;
  }

  if (tempRecipients.length < 1) {
    actionUpdate.personId = null;
    actionUpdate.who = null;
  }

  setSalesforceRelatedTo(who.map(item => item.email || ''));
  useStore.getState().updateAction(actionUpdate);
  useStore.getState().updateCurrentLoggingTo({ who });
};

export type SetAutoSalesforceWhosParams = {
  addedPerson?: {
    sfdcId: string;
    name: string;
    email: string;
    sfdcType?: string;
    sfdcData?: {
      attributes?: {
        type?: string;
      };
    };
  };
  removedSfdcId?: string;
};

/**
 * This function sets the whos in the Logging To by looking at what is removed and/or added
 * from the recipient fields
 */
export const setAutoSalesforceWhos = async ({
  addedPerson,
  removedSfdcId,
}: SetAutoSalesforceWhosParams): Promise<void> => {
  const { loggingTo, toRecipients, type } = useStore.getState().action;
  let who: crmObject[] = [];
  if (addedPerson) {
    addSFDCObjectToOmnibar(addedPerson.sfdcId, addedPerson.email);
    if (
      ((addedPerson.sfdcId.toString().startsWith(CONTACT_PREFIX) &&
        !loggingTo.who?.[0]?.id?.startsWith(LEAD_PREFIX)) ||
        addedPerson.sfdcId.toString().startsWith(LEAD_PREFIX)) &&
      loggingTo.who &&
      type === 'TEMPLATE'
    )
      who = [...loggingTo.who];

    if (
      who.length < 1 ||
      addedPerson.sfdcId.toString().startsWith(CONTACT_PREFIX)
    )
      who.push({
        id: addedPerson.sfdcId as string,
        text: addedPerson.name,
        type:
          addedPerson.sfdcData?.attributes?.type || addedPerson.sfdcType || '',
        autoCreated: true,
        email: addedPerson.email || '',
      });
  }

  if (removedSfdcId) {
    loggingTo.who?.forEach(item => {
      if (!item.autoCreated || item.id !== removedSfdcId) who.push(item);
    });
    // If there is no one left in the who field but there are recipients, then that means
    // that there is a lead to add to the who and the email is still valid to send
    if (who.length < 1 && toRecipients && toRecipients?.length > 0) {
      const removedPerson = await findAPerson(toRecipients[0]?.id);
      who.push({
        id: removedPerson.sfdcId,
        text: removedPerson.name,
        type: removedPerson.sfdcData?.attributes?.type,
        autoCreated: true,
        email: removedPerson.email,
      });
    }
  }

  useStore.getState().updateCurrentLoggingTo({ who });

  setSalesforceRelatedTo(
    who.map(item => item.email || ''),
    addedPerson?.sfdcId,
  );
};

/**
 * This function sends the current person to the omnibar to display also the reason why
 * we are not just pushing this value as a call back is due to the fact that action
 * compose can be in FEBES
 *
 * @param id The sfdc id
 * @param email The email of the who object
 */
export const addSFDCObjectToOmnibar = (
  id?: string,
  email?: string,
  phone?: string,
): void => {
  window.postMessage(
    {
      type: window.location.pathname.endsWith('/actions')
        ? 'SET_ACTIVE_RECORD_IN_GE'
        : 'OUTGOING_ACTION_COMPOSE/UPDATE_OMNIBAR_RECORD',
      payload: {
        id,
        email,
        phone,
      },
      meta: { target: 'omnibar' },
    },
    '*',
  );
};
