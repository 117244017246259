import getClient, { TunnelStrategy, strategy } from './getClient';
import GrooveMessageEvent from './types/GrooveMessageEvent';

export type ApiGrooveMessageEvent = GrooveMessageEvent;

const grooveMessageEvent = async (
  raw?: boolean,
): Promise<ApiGrooveMessageEvent> => {
  try {
    const response = await (await grooveMessageEventResponse()).text();
    return raw
      ? (response as unknown as GrooveMessageEvent)
      : parseGrooveMessageEvent(response);
  } catch {
    return {} as GrooveMessageEvent;
  }
};

export const parseGrooveMessageEvent = (
  response: string,
): GrooveMessageEvent => {
  // Make sure the response is valid and includes 'xsrfToken'
  if (response && response.includes('xsrfToken')) {
    const parsed = JSON.parse(response.substr(5))[0];
    return parsed;
  }
  return {} as GrooveMessageEvent;
};

export const grooveMessageEventResponse = async (
  raw?: boolean,
): Promise<Response> => {
  return getClient().get(
    (strategy as TunnelStrategy)?.path || 'api/v2/visualforce/tunnel',
    {
      searchParams: {
        page: 'GrooveMessageEvent',
      },
    },
  );
};

export default grooveMessageEvent;
