import {
  createActions,
  standardActions,
  keyMirror,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  PEOPLE_IMPORT_DIALOG: {
    LIST_VIEW: {
      FETCH_LIST_VIEWS: standardActions(),
      FETCH_LIST_VIEW: standardActions(),
      ...keyMirror(['RESET_FILTERS']),
    },
  },
}).PEOPLE_IMPORT_DIALOG.LIST_VIEW;

export function clearFilters() {
  return {
    type: actionTypes.RESET_FILTERS,
  };
}
