import { call, put, select } from 'redux-saga/effects';
import { ready } from 'Modules/App/actions';
import {
  logout as logoutAction,
  setContentLoading,
} from 'Modules/Shared/actions/app';
import * as Sentry from '@sentry/browser';
import { ROUTE_NAMES } from 'Routing/constants';
import { location as getLocation } from 'Modules/Shared/selectors/location';
import { setIsLoggedIn } from 'Modules/Shared/actions/users';
import * as GrooveLocalStorage from 'GrooveLocalStorage';

const LOGOUT_EXEMPT_ROUTES = Object.freeze([ROUTE_NAMES.ACTION_COMPOSE]);

export default function* logout(error) {
  const location = yield select(getLocation);
  const routeName = location.get('routeName');

  if (LOGOUT_EXEMPT_ROUTES.includes(routeName)) {
    Sentry.withScope(scope => {
      scope.setTag('type', `Exempt logout error - Route: ${routeName}`);
      scope.setLevel('debug');
      Sentry.captureException(error);
    });
    throw error;
  }

  yield put(setContentLoading(false));
  yield put(ready());

  yield put(setIsLoggedIn(false));
  yield call(GrooveLocalStorage.remove, 'csrfToken');
  yield put(logoutAction());
}
