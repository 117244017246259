import { select, call } from 'redux-saga/effects';
import { batchCreate } from 'GrooveHTTPClient/labelInstances';
import { currentUser as getCurrentUser } from 'Modules/Shared/selectors/users';
import { LABEL_OWNER_TYPES } from 'Modules/Shared/constants/labels';
import LabelOwner from 'Modules/Shared/records/LabelOwner';

export function* applyLabels({ labelIds, labelable }) {
  const currentUser = yield select(getCurrentUser);
  const labelOwnerId = currentUser.get('orgId');
  const labelOwnerType = LABEL_OWNER_TYPES.ORGANIZATION;

  const createResponse = yield call(batchCreate, {
    labelOwner: new LabelOwner({ id: labelOwnerId, type: labelOwnerType }),
    labelable,
    labelIds,
  });
  const appliedLabelInstances = createResponse.getIn(['message', 'data']);
  return appliedLabelInstances;
}

export default applyLabels;
