import React, { FC } from 'react';

import {
  Warning,
  IconWarning,
  FixButton,
  CloseButton,
  FixButtonText,
  WarningMessage,
  Left,
  Right,
  SuccessIcon,
  SuccessMessage,
} from './InconsistentFontWarningStyle';

interface InconsistentFontWarningProps {
  onClose: () => void;
  onFix: () => void;
  undoInconsistentWarning?: () => void;
}

const InconsistentFontWarning: FC<InconsistentFontWarningProps> = ({
  onClose,
  onFix,
  undoInconsistentWarning,
}) => {
  return (
    <WarningMessage>
      {undoInconsistentWarning ? (
        <Left>
          <SuccessIcon />
          <SuccessMessage>The formatting has been updated.</SuccessMessage>
        </Left>
      ) : (
        <Left>
          <IconWarning />
          <Warning>
            This email contains inconsistent formatting. Click the fix button to
            reset the browser default.
          </Warning>
        </Left>
      )}

      <Right>
        {undoInconsistentWarning ? (
          <FixButton onClick={undoInconsistentWarning}>
            <FixButtonText>Undo</FixButtonText>
          </FixButton>
        ) : (
          <FixButton onClick={onFix}>
            <FixButtonText>Fix</FixButtonText>
          </FixButton>
        )}

        <CloseButton onClick={onClose} />
      </Right>
    </WarningMessage>
  );
};
export default InconsistentFontWarning;
