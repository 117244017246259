import React, { Component } from 'react';
import { Menu } from '@material-ui/core';
import UserNameAndAvatar from './UserNameAndAvatar';
import UserMenuOptions from './UserMenuOptions';

export default class UserDisplayAndMenu extends Component {
  state = {
    userMenuOpen: false,
    userMenuAnchorEl: null,
  };

  shouldComponentUpdate(_, nextState) {
    return this.state.userMenuOpen || nextState.userMenuOpen;
  }

  closeUserMenu = () => {
    this.setState({
      userMenuOpen: false,
      userMenuAnchorEl: null,
    });
  };

  showUserMenu = event => {
    this.setState({
      userMenuOpen: true,
      userMenuAnchorEl: event.currentTarget,
    });
  };

  render() {
    const { userMenuOpen, userMenuAnchorEl } = this.state;
    return (
      <div>
        <UserNameAndAvatar handleClick={this.showUserMenu} />
        <Menu
          open={userMenuOpen}
          anchorEl={userMenuAnchorEl}
          onClose={this.closeUserMenu}
        >
          <UserMenuOptions closeUserMenu={this.closeUserMenu} />
        </Menu>
      </div>
    );
  }
}
