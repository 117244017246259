import { FC } from 'react';
import cx from 'classnames';

import { IconProps } from './type';

export const LocalDialIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className, 'fill-current')}
  >
    <path d="M3.55101 2.44275C4.40494 1.96498 5.49406 1.85275 6.53544 2.11656C7.04218 2.24493 7.47512 2.57348 7.73513 3.02697L8.6329 4.59284C9.29786 5.75262 8.9327 7.2303 7.80407 7.94687L7.01425 8.44833C6.68733 8.65589 6.56745 8.97243 6.64244 9.22405C6.90993 10.1215 7.50308 11.1182 8.15518 11.8002C8.34925 12.0032 8.7045 12.0519 9.05289 11.8487L9.4899 11.5938C10.6974 10.8894 12.2478 11.312 12.9308 12.5317L13.7345 13.9669C13.9975 14.4366 14.0415 14.9979 13.8548 15.5027C13.4838 16.5057 12.7694 17.3143 11.8903 17.7193C10.9973 18.1309 9.95159 18.1142 9.0056 17.4831C7.50831 16.4843 5.70298 14.8046 4.06815 12.002C2.41207 9.16306 1.99456 6.74553 2.04156 4.94418C2.07166 3.79026 2.68184 2.92905 3.55101 2.44275Z" />
    <path d="M14.25 2C16.3211 2 18 3.7269 18 5.85714C18 7.45368 16.8168 9.12702 14.5 10.9143C14.3519 11.0286 14.1481 11.0286 14 10.9143C11.6832 9.12702 10.5 7.45368 10.5 5.85714C10.5 3.7269 12.1789 2 14.25 2ZM14.25 4.57143C13.5596 4.57143 13 5.14706 13 5.85714C13 6.56722 13.5596 7.14286 14.25 7.14286C14.9404 7.14286 15.5 6.56722 15.5 5.85714C15.5 5.14706 14.9404 4.57143 14.25 4.57143Z" />
  </svg>
);
