import { FC, HTMLProps, useState } from 'react';
import DropDownList, {
  StandardDropDownItem,
} from '@groove/ui/Components/DropDownList';
import { useQuery } from 'react-query';
import {
  getPeople,
  ApiPeople,
  SearchPerson,
} from '@groove/api/gateway/v1/people';
import { debounce } from 'lodash-es';
import { RecipientType } from '@groove/api/gateway/v1/actionCompose';

import useStore from '../store/useStore';
import { setAutoSalesforceWhos } from '../utils/loggingToMethods';
import { syncWhoData } from '../utils/syncWhoData';
import useGrooveMeta from '../hooks/useGrooveMeta';

type PeopleHash = {
  [email: string]: SearchPerson;
};

type RecipientSelectorParams = {
  placeholder?: string;
  className?: HTMLProps<HTMLElement>['className'];
};

const RecipientSelector: FC<RecipientSelectorParams> = ({
  placeholder = '',
  className,
}) => {
  const [personSearch, setPersonSearch] = useState('');
  const [peopleHash, setPeopleHash] = useState<PeopleHash>({});
  const [personSearchItems, setpersonSearchItems] = useState<
    StandardDropDownItem[]
  >([]);

  const who = useStore(store => store.action.who);
  const personStepId = useStore(store => store.action.personStepId);

  const { data: grooveMeta } = useGrooveMeta();

  const filters = [
    {
      field: 'name_or_account_or_email',
      value: personSearch.trim(),
    },
  ];
  const searchParams = {
    orderBy: 'engagement',
    orderDir: 'desc',
    offset: 0,
    limit: 15,
    scope: 'all_people_in_organization',
    omitTotal: true,
  };

  const onPeopleSuccess = (data: ApiPeople): void => {
    const hash: PeopleHash = {};
    const personSearchItems: StandardDropDownItem[] = (data?.data || []).map(
      person => {
        hash[person.email] = person;
        return {
          value: person.email,
          key: person.email,
          text: person.name || person.email,
          secondaryText: [person.phone || person.mobilePhone],
        };
      },
    );
    setPeopleHash(hash);
    setpersonSearchItems(personSearchItems);
  };

  useQuery(['call-people'], () => getPeople(searchParams), {
    onSuccess: onPeopleSuccess,
    enabled: personSearch.length < 2,
    cacheTime: 1 * 60 * 1000, // 1 minute because the list of people and their names aren't going to change often
    staleTime: 1 * 60 * 1000,
  });

  useQuery(
    ['call-people', personSearch],
    () => getPeople({ ...searchParams, filters }),
    {
      onSuccess: onPeopleSuccess,
      enabled: personSearch.length >= 2,
      cacheTime: 1 * 60 * 1000, // 1 minute because the list of people and their names aren't going to change often for the same search
      staleTime: 1 * 60 * 1000,
    },
  );

  const onPersonChange = (item: StandardDropDownItem | null): void => {
    if (item) {
      const newWho: SearchPerson = peopleHash[item.value];
      const newToRecipient: RecipientType[] = [
        {
          id: newWho.id.toString(),
          phone: newWho.phone,
          email: newWho.email,
          sfdcId: newWho.sfdcId,
        },
      ];
      useStore.getState().updateAction({
        personId: newWho.id,
        who: newWho,
        toRecipients: newToRecipient,
      });
      setAutoSalesforceWhos({
        addedPerson: newWho,
      });
      syncWhoData([newWho.sfdcId], grooveMeta);
    }
  };

  let renderedText = placeholder;

  if (who?.id) {
    renderedText = 'Unknown Person';

    if (who.name) {
      renderedText = `${who.name}`;
    } else if (who.email) {
      renderedText = who.email;
    }
  }
  return (
    <DropDownList
      disabled={!!personStepId}
      className={className}
      hasSearch
      onSearchChange={debounce(setPersonSearch, 200)}
      onChange={onPersonChange}
      items={personSearchItems}
      renderedText={renderedText}
    />
  );
};

export default RecipientSelector;
