import cx from 'classnames';
import { FC, HTMLProps } from 'react';

import Text from './Text';

export type InputFrameProps = {
  value: string | JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
  isFocused?: boolean;
  placeholder?: string;
  className?: HTMLProps<HTMLElement>['className'];
};

/**
 * This component is a fake frame made to look like an input
 * @param param0
 * @returns
 */

const InputFrame: FC<InputFrameProps> = ({
  value,
  disabled,
  onClick,
  isFocused,
  placeholder,
  className,
}) => (
  <div
    role="button"
    className={cx(
      'text-left bg-white background w-full flex items-center border-solid rounded focus:border-primaryDark focus:outline-none flex-row box-border relative cursor-pointer border-neutral/200 border',
      disabled && 'bg-neutral/50 cursor-not-allowed text-neutral/400',
      !disabled && isFocused && '!border-clari-blue/600 border-[2px]',
      className,
      !onClick && '!cursor-default',
    )}
    onClick={() => !disabled && onClick?.()}
    onKeyDown={event => !disabled && event.key === 'Enter' && onClick?.()}
    tabIndex={0}
  >
    {!value && (
      <Text className="truncate text-body-sm px-[6px] text-neutral/400">
        {placeholder}
      </Text>
    )}
    {value && <Text className="truncate text-body-sm px-[6px]">{value}</Text>}
  </div>
);

export default InputFrame;
