import '@groove/ui';
import { initializeIcons } from '@fluentui/react';
import 'font-awesome/css/font-awesome.css';
import './font-awesome.css';
import '@clari/iris-styles';
import '@clari/iris-react/style.css';
import '@clari/iris-table/style.css';

/* POLYFILLS */
import 'core-js/stable';
import 'reflect-metadata';
import 'regenerator-runtime/runtime';
import 'element-scroll-polyfill';
import grooveEngineRootUrl from './js/utils/grooveEngineRootUrl';

import { QueryClient, QueryClientProvider } from 'react-query';
import { configureClient } from '@groove/api/getClient';
import { configureClient as configureServicesClient } from '@groove/api/services/v1/getClient';
import { configureClient as configureVisualforceClient } from '@groove/api/visualforce/getClient';
import { configureClient as configureGatewayClient } from '@groove/api/gateway/v1/getClient';

import Immutable from 'immutable';
import App from 'Modules/App/containers/ModuleRoot';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { initializeSentry } from 'ErrorHandling/sentry';
import isString from 'lodash-es/isString';
import appRender from './appRender';
import { fetch as getLocalStorageValue } from 'GrooveLocalStorage';

import {
  SagaManager,
  getOrConfigureStore,
  createRootReducer,
} from './js/redux';

initializeIcons();

configureClient({
  credentials: 'include',
  prefixUrl: `${grooveEngineRootUrl || process.env.NX_GROOVE_ENGINE_ROOT_URL}/`,
  headers: {
    'X-CSRF-TOKEN': getLocalStorageValue('csrfToken'),
  },
});

configureGatewayClient({
  prefixUrl: `${
    grooveEngineRootUrl || process.env.NX_GROOVE_ENGINE_ROOT_URL
  }/api/gateway/v1/`,
});

configureServicesClient({
  prefixUrl: `${
    grooveEngineRootUrl || process.env.NX_GROOVE_ENGINE_ROOT_URL
  }/api/chrome_extension/v1/services/`,
});

configureVisualforceClient({
  type: 'TUNNEL',
});

let counter = 0;
const generateClassName = (rule, styleSheet) => {
  let className = styleSheet.options.classNamePrefix;
  if (className.includes('Connect(')) {
    className = className.replace('Connect(', '').replace(')', '');
  }
  counter += 1;
  return `webapp-${className}-${rule.key}-${counter}`;
};

if (process.env.NODE_ENV === 'development') {
  const CONSOLE_LOG_CODES = Object.freeze({
    UNMOUNTED_COMPONENT_STATE_UPDATED: 'unmounted-component-state-update',
    RENDER_EMPTY_IMPORT: 'render-empty-import',
    FUNCTION_COMPONENT_REFS: 'function-component-refs',
    UNRECOGNIZED_DOM_PROP: 'unrecognized-dom-prop',
    MUI_WARNING: 'mui-warning',
    INVALID_DOM_NESTING: 'validate-dom-nesting',
    REACT_PROP_TYPES: 'prop-types',
    REACT_MISSING_KEY: 'react-missing-key',
    UNKNOWN: 'unknown',
    WEBPACK_DEV_SERVER: 'webpack-dev-server',
    FEBES_VERSION: 'febes-version',
    NOOP_FIRED: 'noop-fired',
    UNKNOWN_EVENT_HANDLER: 'unknown-event-handler',
    IGNORED_ACTION_FROM_DIALER: 'ignored-action-from-dialer',
    TINY_MCE_DEPRECATION: 'tiny-mce-deprecation',
    CANNOT_READ_PROPERTY: 'cannot-read-property',
    INVALID_SPREAD_OPERATION: 'invalid-spread-operation',
    FIRESTORE_ERROR: 'firestore-error',
    FIRESTORE_MESSAGE: 'firestore-message',
  });
  const messageToCodeMap = {
    "Warning: Can't perform a React state update on an unmounted component":
      CONSOLE_LOG_CODES.UNMOUNTED_COMPONENT_STATE_UPDATED,
    'Warning: React.createElement: type is invalid':
      CONSOLE_LOG_CODES.RENDER_EMPTY_IMPORT,
    'Warning: Function components cannot be given refs':
      CONSOLE_LOG_CODES.FUNCTION_COMPONENT_REFS,
    'Warning: React does not recognize the `%s` prop':
      CONSOLE_LOG_CODES.UNRECOGNIZED_DOM_PROP,
    'Warning: Material-UI:': CONSOLE_LOG_CODES.MUI_WARNING,
    'Warning: validateDOMNesting\\(\\.\\.\\.\\)':
      CONSOLE_LOG_CODES.INVALID_DOM_NESTING,
    'Warning: Failed prop type': CONSOLE_LOG_CODES.REACT_PROP_TYPES,
    'Warning: Each child in a list should have a unique "key" prop':
      CONSOLE_LOG_CODES.REACT_MISSING_KEY,
    '\\[WDS\\]': CONSOLE_LOG_CODES.WEBPACK_DEV_SERVER,
    'FEBES Version': CONSOLE_LOG_CODES.FEBES_VERSION,
    'Noop fired': CONSOLE_LOG_CODES.NOOP_FIRED,
    'Warning: Unknown event handler property':
      CONSOLE_LOG_CODES.UNKNOWN_EVENT_HANDLER,
    'Ignored action from Chrome extension dialer':
      CONSOLE_LOG_CODES.IGNORED_ACTION_FROM_DIALER,
    'Text color plugin is now built in to the core editor':
      CONSOLE_LOG_CODES.TINY_MCE_DEPRECATION,
    'Color picker plugin is now built in to the core editor':
      CONSOLE_LOG_CODES.TINY_MCE_DEPRECATION,
    'Cannot read property': CONSOLE_LOG_CODES.CANNOT_READ_PROPERTY,
    'Warning: %s: `.*?` is not a prop': CONSOLE_LOG_CODES.REACT_PROP_TYPES,
    'Invalid attempt to spread non-iterable instance':
      CONSOLE_LOG_CODES.INVALID_SPREAD_OPERATION,
    'Warning: `value` prop on `%s` should not be null. Consider using an empty string':
      CONSOLE_LOG_CODES.MUI_WARNING,
    'Firestore document path \\[.*?\\] does not exist':
      CONSOLE_LOG_CODES.FIRESTORE_ERROR,
    '\\[.*?\\] @firebase/firestore:': CONSOLE_LOG_CODES.FIRESTORE_MESSAGE,
  };
  const getComponentNameFromPropTypeMessage = message => {
    let matches = message.match(/render method of `(.*?)`/m);
    if (matches) {
      return matches[1];
    }
    matches = message.match(/supplied to `(.*?)`/m);
    if (matches) {
      return matches[1];
    }
    return null;
  };
  const logToNewRelic = (level, message) => {
    if (!window.newrelic) {
      return;
    }
    if (!isString(message)) {
      return;
    }
    let code = null;
    let reactComponentName = null;
    for (const test in messageToCodeMap) {
      if (new RegExp(test, 'im').test(message)) {
        code = messageToCodeMap[test];
        if (code === CONSOLE_LOG_CODES.REACT_PROP_TYPES) {
          reactComponentName = getComponentNameFromPropTypeMessage(message);
        }
        break;
      }
    }
    window.newrelic.addPageAction('LocalDevelopmentConsoleLog', {
      'LocalDevelopmentConsoleLog.level': level,
      'LocalDevelopmentConsoleLog.message': message,
      'LocalDevelopmentConsoleLog.code': code || CONSOLE_LOG_CODES.UNKNOWN,
      'LocalDevelopmentConsoleLog.reactComponentName': reactComponentName,
    });
  };
  const logDebug = console.debug; // eslint-disable-line no-console
  console.debug = (message, ...rest) => {
    // eslint-disable-line no-console
    logToNewRelic('debug', message);
    logDebug(message, ...rest);
  };
  const log = console.log; // eslint-disable-line no-console
  console.log = (message, ...rest) => {
    // eslint-disable-line no-console
    logToNewRelic('log', message);
    log(message, ...rest);
  };
  const logWarning = console.warn; // eslint-disable-line no-console
  console.warn = (message, ...rest) => {
    // eslint-disable-line no-console
    logToNewRelic('warning', message);
    logWarning(message, ...rest);
  };
  const logError = console.error; // eslint-disable-line no-console
  console.error = (message, ...rest) => {
    // eslint-disable-line no-console
    logToNewRelic('error', message);
    logError(message, ...rest);
  };

  // eslint-disable-next-line import/no-extraneous-dependencies,global-require
  const ImmutableDevTools = require('immutable-devtools');
  ImmutableDevTools(Immutable);
}

initializeSentry();

export const store = getOrConfigureStore({
  createRootReducer,
  SagaManager,
  Sentry,
});

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const AppModuleRoot = (
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);

if (process.env.REACT_APP_VERSION !== undefined) {
  console.log('FEBES Version: ', process.env.REACT_APP_VERSION); // eslint-disable-line no-console
  window.FEBES_VERSION = process.env.REACT_APP_VERSION;
}

if (process.env.NODE_ENV !== 'test') {
  appRender(AppModuleRoot, store, generateClassName);
}

if (module.hot) {
  module.hot.accept();
}
