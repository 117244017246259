import { FC } from 'react';

import { IconProps } from './type';

export const InfoIcon: FC<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3C4.34315 3 3 4.34315 3 6L3 14C3 15.6569 4.34315 17 6 17L14 17C15.6569 17 17 15.6569 17 14L17 6C17 4.34315 15.6569 3 14 3L6 3ZM10 8.49997C9.75454 8.49997 9.55039 8.67685 9.50806 8.91009L9.5 8.99997L9.5 13.5021L9.50806 13.592C9.55039 13.8253 9.75454 14.0021 10 14.0021C10.2455 14.0021 10.4496 13.8253 10.4919 13.592L10.5 13.5021L10.5 8.99997L10.4919 8.91009C10.4496 8.67685 10.2455 8.49997 10 8.49997ZM10 5.99997C9.58579 5.99997 9.25 6.33576 9.25 6.74997C9.25 7.16418 9.58579 7.49997 10 7.49997C10.4142 7.49997 10.75 7.16418 10.75 6.74997C10.75 6.33576 10.4142 5.99997 10 5.99997Z"
      fill="#0960B2"
    />
  </svg>
);
