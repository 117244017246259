import { createActions } from '@groove-labs/action-utils';
import { FSA } from 'flux-standard-action';

const types = {
  SHARED: {
    SALESFORCE_META: {
      SET_SALESFORCE_META: 'SET_SALESFORCE_META',
      UPDATE_SALESFORCE_META: 'UPDATE_SALESFORCE_META',
    },
  },
};

export const actionTypes =
  createActions<typeof types>(types).SHARED.SALESFORCE_META;

/**
 * Accepts an object which will be loaded into the existing UI state
 *
 * @param {object} payload
 * @return {object}
 */
// eslint-disable-next-line
export function setSalesforceMeta(payload: any): FSA {
  return {
    type: actionTypes.SET_SALESFORCE_META,
    payload,
  };
}

/**
 * Accepts an object which will be used to update the current fields in salesforceMeta
 *
 * @param {object} payload
 * @return {object}
 */

type UpdateSalesforceMetaPayload = {
  salesforceObject: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  fields: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

type UpdateSalesforceMetaReturnType = {
  object: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  fields: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export function updateSalesforceMeta(
  payload: UpdateSalesforceMetaPayload
): FSA<string, UpdateSalesforceMetaReturnType> {
  const { salesforceObject, fields } = payload;

  return {
    type: actionTypes.UPDATE_SALESFORCE_META,
    payload: {
      object: salesforceObject,
      fields,
    },
  };
}
