import cx from 'classnames';
import { connect } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import Fade from '@material-ui/core/Fade';
import { values } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import StatusChip from 'Modules/Shared/components/LabeledChip';
import Tooltip from '@material-ui/core/Tooltip';

import { MENU_ITEM_STATES } from 'Modules/App/constants';
import { location } from 'Modules/Shared/selectors/location';
import ClariTheme from 'Utils/theme/ClariTheme';

const textBaseStyle = {
  fontSize: 10,
  fontWeight: 600,
  letterSpacing: 'normal',
};

const styles = theme => ({
  active: {
    color: theme.palette.primary.main,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cursorUnset: {
    cursor: 'unset',
  },
  listItem: {
    width: '100%',
    justifyContent: 'center',
    padding: 8,
    height: 50,
    position: 'relative',
  },
  tooltip: {
    fontSize: 12,
  },
  text: {
    ...textBaseStyle,
    color: theme.palette['neutral/600'],
  },
  hoveredText: {
    ...textBaseStyle,
    color: theme.palette.primary.main,
  },
  activeText: {
    ...textBaseStyle,
    color: theme.palette.primary.main,
  },
});

const betaChipStyles = {
  backgroundColor: ClariTheme.palette['grapeshine/600'],
  position: 'absolute',
  zIndex: 4,
  height: 12,
  right: 10,
  top: 6,
  width: 20,
  fontSize: 8,
  letterSpacing: '0.5px',
};

@withStyles(styles, { withTheme: true })
@connect((state, props) => {
  const { routeName, route } = props;
  const locationData = location(state) || new Map();
  const isRouteNameActive = locationData.get('routeName') === routeName;
  const isRootPathActive = route.slice(1) === locationData.get('rootPath');

  const status = do {
    if (isRouteNameActive && isRootPathActive) {
      MENU_ITEM_STATES.ACTIVE_MODULE; // eslint-disable-line no-unused-expressions
    } else if (!isRouteNameActive && isRootPathActive) {
      MENU_ITEM_STATES.ACTIVE_SUBMODULE; // eslint-disable-line no-unused-expressions
    } else {
      MENU_ITEM_STATES.INACTIVE_MODULE; // eslint-disable-line no-unused-expressions
    }
  };

  return {
    status,
  };
}, {})
export default class SideBarNavMenuItem extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    status: PropTypes.oneOf(values(MENU_ITEM_STATES)).isRequired,
    // passable props
    primaryText: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    handleRouteChange: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
    isBeta: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltip: PropTypes.object,
  };

  static defaultProps = {
    isBeta: false,
    tooltip: {
      show: false,
      text: null,
    },
  };

  constructor(props) {
    super(props);

    this.onClick = this.onClickHandler;
    this.state = {
      inHoveredState: false,
    };
  }

  onClickHandler = e => {
    const { route, status } = this.props;
    e.preventDefault();
    e.stopPropagation();
    if (status === MENU_ITEM_STATES.ACTIVE_MODULE) {
      return;
    }
    this.props.handleRouteChange(route, !!e.metaKey);
  };

  handleOnMouseEnter = () => {
    this.setState({
      inHoveredState: true,
    });
  };

  handleOnMouseLeave = () => {
    this.setState({
      inHoveredState: false,
    });
  };

  renderListItem = () => {
    const {
      primaryText,
      classes,
      route,
      status,
      icon,
      isBeta,
      theme,
      disabled,
    } = this.props;

    const { inHoveredState } = this.state;

    const Icon = icon;

    const isActiveMenuItem =
      status === MENU_ITEM_STATES.ACTIVE_MODULE ||
      status !== MENU_ITEM_STATES.INACTIVE_MODULE;
    const iconStyle = {
      color:
        isActiveMenuItem || inHoveredState
          ? theme.palette.primary.main
          : theme.palette['neutral/600'],
      width: 'auto',
    };

    return (
      <ListItem
        className={cx(
          status !== MENU_ITEM_STATES.INACTIVE_MODULE ? classes.active : null,
          classes.listItem,
          status === MENU_ITEM_STATES.ACTIVE_MODULE ? classes.cursorUnset : null
        )}
        button
        disableGutters
        onClick={this.onClick}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
        component="a"
        href={`${window.location.origin}${route}`}
        disabled={disabled}
      >
        {isBeta && <StatusChip label="beta" style={betaChipStyles} />}
        <div className={classes.content}>
          <Icon style={iconStyle} />
          <div
            className={cx({
              [classes.text]: true,
              [classes.activeText]: isActiveMenuItem,
              [classes.hoveredText]: inHoveredState,
            })}
          >
            <span>{primaryText}</span>
          </div>
        </div>
      </ListItem>
    );
  };

  render() {
    const { classes, tooltip } = this.props;

    return tooltip?.show && tooltip?.text ? (
      <Tooltip
        title={tooltip?.text}
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <span>{this.renderListItem()}</span>
      </Tooltip>
    ) : (
      this.renderListItem()
    );
  }
}
