import { useIsMutating } from 'react-query';

/**
 *
 * @returns boolean of whether an action is either mutating
 */
export const useActionMutating = (): boolean => {
  const loadingCountExecuteAction = useIsMutating({
    mutationKey: ['executeAction'],
  });
  const loadingCountAddPeopleToFlow = useIsMutating({
    mutationKey: ['addPeopleToFlow'],
  });
  const loadingCountFlowsPauseUntil = useIsMutating({
    mutationKey: ['flowsPauseUntil'],
  });
  const loadingCountRemoveFromFlow = useIsMutating({
    mutationKey: ['removeFromFlow'],
  });
  const loadingCountSkipPersonStep = useIsMutating({
    mutationKey: ['skipPersonStep'],
  });
  const loadingCountCreateAction = useIsMutating({
    mutationKey: ['createAction'],
  });
  const loadingCountUpdateAction = useIsMutating({
    mutationKey: ['updateAction'],
  });
  const loadingCountDeleteAction = useIsMutating({
    mutationKey: ['deleteAction'],
  });

  return (
    loadingCountExecuteAction +
      loadingCountAddPeopleToFlow +
      loadingCountFlowsPauseUntil +
      loadingCountRemoveFromFlow +
      loadingCountSkipPersonStep +
      loadingCountCreateAction +
      loadingCountUpdateAction +
      loadingCountDeleteAction >
    0
  );
};

export default useActionMutating;
