import { Separator, Shimmer, ShimmerElementType, Stack } from '@fluentui/react';
import { FC } from 'react';

import NoSelectedTemplate from './NoSelectedTemplate';
import { TEMPLATES_MENU_WIDTH } from './constants';

const styles = { root: 'p-4 h-1' };
const TemplateRowLoader: FC = key => (
  <>
    <Shimmer
      shimmerElements={[
        { type: ShimmerElementType.gap, width: '5%', height: 36 },
        { type: ShimmerElementType.line, width: '8%', height: 36 },
        { type: ShimmerElementType.gap, width: '5%', height: 36 },
        { type: ShimmerElementType.line, width: '70%', height: 36 },
        { type: ShimmerElementType.gap, width: '12%', height: 36 },
      ]}
    />
    <Separator styles={styles} />
  </>
);

export const TemplateLoadingShimmers: FC = () => {
  return (
    <Stack
      horizontal
      className="flex h-full border-solid border-[1px] border-neutral/200"
    >
      <Stack.Item
        className={`py-4 w-[${TEMPLATES_MENU_WIDTH}] h-full border-r-[1px] border-0 border-solid border-black`}
      >
        {Array.from({ length: 8 }, (_v, templateNumber) => (
          <TemplateRowLoader key={templateNumber} />
        ))}
      </Stack.Item>
      <Stack.Item className="flex flex-grow w-0 h-full">
        <NoSelectedTemplate />
      </Stack.Item>
    </Stack>
  );
};

export const SingleTemplateShimmer: FC = () => {
  return (
    <div aria-label="Loading" className="p-2 w-60">
      <Shimmer
        className="mb-4"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 40, width: '70%' },
          { type: ShimmerElementType.gap, width: '30%' },
        ]}
      />
      <Shimmer
        className="mb-4"
        shimmerElements={[
          { type: ShimmerElementType.line, height: 25, width: '50%' },
          { type: ShimmerElementType.gap, width: '50%' },
        ]}
      />
      <Shimmer
        shimmerElements={[
          { type: ShimmerElementType.gap, height: 100, width: '0%' },
        ]}
      />
    </div>
  );
};
