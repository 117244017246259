import { FilterActionsInput } from '@groove/api/services/v1/actions';
import { StickyFilters } from '@groove/api/user';
import { formatISO } from 'date-fns';

export const formatActionFilters = (
  filters: StickyFilters | undefined,
): FilterActionsInput => {
  if (!filters) {
    return {};
  }
  const dateTime = formatISO(new Date());
  return {
    ...filters,
    due_at: filters?.due_at?.map(operator => ({
      date_time: dateTime,
      operator,
    })),
  } as FilterActionsInput;
};
