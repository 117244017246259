import { FC } from 'react';
import { Text } from '@fluentui/react';
import { useQuery } from 'react-query';
import { recentTemplatesGet } from '@groove/api/services/v1/recentTemplates';
import StyledSeparator from '@groove/ui/Components/StyledSeparator';
import { Template, TemplateType } from '@groove/api/gateway/v1/template';

import EmptyRecentTemplates from '../icons/empty_recent_templates.svg';

import TemplatesList from './TemplatesList';
import TemplateListLoader from './TemplateListLoader';

type Props = {
  onInsert: (template: Template) => void;
  templateType: TemplateType;
  templateSubType?: string;
};

const RecentlyUsedTemplatesList: FC<Props> = ({
  onInsert,
  templateType,
  templateSubType,
}) => {
  const { data: recentTemplatesData, isFetching: isFetchingRecent } = useQuery(
    ['recentTemplatesGet', templateType],
    () => recentTemplatesGet(templateType, templateSubType),
  );

  if (isFetchingRecent) {
    return <TemplateListLoader />;
  }

  return recentTemplatesData?.data.length &&
    recentTemplatesData.data.length > 0 ? (
    <>
      <div className="px-3 pb-0.5">
        <Text className="text-xs text-neutral/400">
          Recently used templates
        </Text>
      </div>
      <StyledSeparator xMargin={12} />
      <TemplatesList
        templates={recentTemplatesData?.data}
        onInsert={onInsert}
      />
    </>
  ) : (
    <div className="flex flex-col w-full h-full p-10 items-center justify-center box-border">
      <img src={EmptyRecentTemplates} alt="empty-recent-templates-img" />
      <Text className="pt-2 text-xs text-gray2 text-center pb-4">
        Start using templates to boost your productivity.
      </Text>
    </div>
  );
};

export default RecentlyUsedTemplatesList;
