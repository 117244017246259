import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NoResultsSvg from 'Modules/Emails/components/NoResultsSvg';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 7,
  },
  svg: {
    paddingRight: theme.spacing.unit * 3,
  },
  text: {
    paddingTop: theme.spacing.unit * 2,
    fontSize: 18,
    fontWeight: 500,
  },
});

const NoResults = ({ classes }) => {
  return (
    <div className={classes.root}>
      <NoResultsSvg className={classes.svg} />
      <Typography className={classes.text}>No Matching Results</Typography>
    </div>
  );
};

NoResults.displayName = 'NoResults';
NoResults.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    svg: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(NoResults);
