import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  EMAILS: {
    FETCH: standardActions(),
    FETCH_SINGLE_EMAIL: standardActions(),
    SET_IS_EMAIL_MUTED: standardActions(),
    COMPLETE_EMAILS_ONBOARDING: standardActions(),
    ...keyMirror([
      'SET_EVENT_FILTER',
      'SET_SOURCE',
      'SET_IS_SEARCH_ACTIVE',
      'SET_SEARCH',
      'CLEAR_EMAILS',
      'MARK_EMAIL_AS_READ',
      'MARK_ALL_EMAIL_EVENTS_AS_READ',
      'SET_SHOULD_ONBOARD',
    ]),
  },
}).EMAILS;

export const fetchEmails = payload => ({
  type: actionTypes.FETCH.BEGIN,
  payload,
});

export const fetchSucceeded = ({ emails, pageInfo }) => ({
  type: actionTypes.FETCH.SUCCESS,
  payload: { emails, pageInfo },
});

export const fetchSingleEmailSucceeded = email => ({
  type: actionTypes.FETCH_SINGLE_EMAIL.SUCCESS,
  payload: email,
});

export const fetchSingleEmailFailed = () => ({
  type: actionTypes.FETCH_SINGLE_EMAIL.FAILURE,
});

export const fetchCancelled = () => ({
  type: actionTypes.FETCH.CANCEL,
});

export const fetchFailed = () => ({
  type: actionTypes.FETCH.FAILURE,
});

export const setEventFilter = eventFilter => ({
  type: actionTypes.SET_EVENT_FILTER,
  payload: eventFilter,
});

export const setSource = source => ({
  type: actionTypes.SET_SOURCE,
  payload: source,
});

export const setIsInSearchMode = payload => ({
  type: actionTypes.SET_IS_SEARCH_ACTIVE,
  payload,
});

export const setSearch = search => ({
  type: actionTypes.SET_SEARCH,
  payload: search,
});

export const clearEmails = () => ({
  type: actionTypes.CLEAR_EMAILS,
});

export const markEmailAsRead = id => ({
  type: actionTypes.MARK_EMAIL_AS_READ,
  payload: id,
});

export const markAllEmailEventsAsRead = () => ({
  type: actionTypes.MARK_ALL_EMAIL_EVENTS_AS_READ,
});

export const setIsEmailMuted = ({ id, value }) => ({
  type: actionTypes.SET_IS_EMAIL_MUTED.BEGIN,
  payload: { id, value },
});

export const setIsEmailMutedSucceeded = ({ id, mutedAt }) => ({
  type: actionTypes.SET_IS_EMAIL_MUTED.SUCCESS,
  payload: { id, mutedAt },
});

export const completeEmailsOnboarding = () => ({
  type: actionTypes.COMPLETE_EMAILS_ONBOARDING.BEGIN,
});

export const completeEmailsOnboardingSucceeded = () => ({
  type: actionTypes.COMPLETE_EMAILS_ONBOARDING.SUCCESS,
});

export const setShouldOnboard = value => ({
  type: actionTypes.SET_SHOULD_ONBOARD,
  payload: value,
});
