export const FLOW_PREVIEW_DIALOG_OPEN_KEY_PATH = ['showFlowPreviewDialog'];
export const FLOW_ID_IN_PREVIEW_DIALOG_OPEN_KEY_PATH = ['currentPerviewFlowId'];

export const FLOW_PER_PAGE_LIMIT = 10;
export const SEARCH_VISIBLE_KEY_PATH = ['flows', 'searchIsVisible'];
export const FLOWS_FILTERS_GROUP_ID = 'flowsFilterGroup';

export const ANYONE = 'Anyone';

export const TAB_VALUES = Object.freeze({
  ACTIVE: 'active',
  DUE: 'due',
  SHARED: 'shared',
  ARCHIVED: 'archived',
  ALL: 'all',
});

export const SEARCH_FILTER_FIELDS = Object.freeze({
  NAME: 'NAME',
  DESCRIPTION: 'DESCRIPTION',
});

export const SEARCH_FIELD_FORM_ID = 'search';
export const DISABLED_FLOW_ACTIONS_MESSAGE =
  'Please contact flow owner for access.';

export const FLOW_ACTIONS_LIMIT_REACHED_MESSAGE =
  'Flow Action Limit has been reached.';

export const UNKNOWN_TAG_MESSAGE = 'Unknown Field, Removed from Salesforce';
export const UNKNOWN_PICKLIST_OPTION_MESSAGE = 'Removed Picklist Option for';

export const SHARED_WITH_FIELD_ID = 'sharedWith';
export const SHARED_BY_FIELD_ID = 'sharedBy';
export const FLOW_COLLECTIONS_FEATURE_FLAG = 'flow-collections';
export const FLOW_UI_FEATURE_FLAG = 'pi5-flow-ui';
export const FLOW_ANALYTICS_FLAG = 'flow-metrics';
export const FLOW_APPROVAL_FEATURE_FLAG = 'flow-approval-process';
export const FLOW_METRICS_FLAG = 'flow-metrics';
export const FLOW_LAST_ACTIVITY_AT_FLAG = 'pi-5-last-activity';
export const FLOW_ACTIVE_VIEWS = {
  FLOWS: 'flows',
  COLLECTIONS: 'collections',
  COLLECTION_SHOW: 'collection_show',
};
export const FLOW_USAGE_METRICS = [
  { key: 'HIGH_USAGE', value: 'HIGH_USAGE', text: 'High usage' },
  { key: 'MEDIUM_USAGE', value: 'MEDIUM_USAGE', text: 'Medium usage' },
  { key: 'LOW_USAGE', value: 'LOW_USAGE', text: 'Low usage' },
];

export const FLOW_ENGAGEMENT_METRICS = [
  { key: 'HIGH_ENGAGEMENT', value: 'HIGH_ENGAGEMENT', text: 'High engagement' },
  {
    key: 'MEDIUM_ENGAGEMENT',
    value: 'MEDIUM_ENGAGEMENT',
    text: 'Medium engagement',
  },
  { key: 'LOW_ENGAGEMENT', value: 'LOW_ENGAGEMENT', text: 'Low engagement' },
];

export const FLOW_CONVERSION_METRICS = [
  { key: 'HIGH_CONVERSION', value: 'HIGH_CONVERSION', text: 'High conversion' },
  {
    key: 'MEDIUM_CONVERSION',
    value: 'MEDIUM_CONVERSION',
    text: 'Medium conversion',
  },
  { key: 'LOW_CONVERSION', value: 'LOW_CONVERSION', text: 'Low conversion' },
];

export const FLOW_STATUS = [
  { key: 'NEW_ACTIVE', value: 'NEW_ACTIVE', text: 'Active' },
  { key: 'ARCHIVED', value: 'ARCHIVED', text: 'Archived' },
  { key: 'ALL', value: null, text: 'All' },
];
export const FLOW_APPROVAL_STATUS = [
  { key: 'DRAFT', value: 'DRAFT', text: 'Draft' },
  { key: 'PENDING', value: 'PENDING', text: 'Pending Review' },
];
export const FLOW_SORT_BY = {
  name: 'Name',
  created_at: 'Created date',
  updated_at: 'Last updated date',
  starred: 'Favorites',
};
export const FLOW_ANALYTICS_SORT_BY = {
  last_activity_at: 'Last activity date',
  flow_usage_rating: 'Usage',
  flow_engagement_rating: 'Engagement',
  flow_conversion_rating: 'Conversion',
};
export const COLLECTION_CREATED_MESSAGE = 'Collection created successfully';
export const COLLECTION_UPDATED_MESSAGE = 'Collection updated successfully';
export const FLOW_COLLECTION_SORT_BY = {
  name: 'Name',
  created_at: 'Created date',
  updated_at: 'Last updated date',
  starred: 'Favorites',
};
export const COLLECTION_STATUS = [
  { key: 'all', value: null, text: 'All' },
  { key: 'active', value: 'active', text: 'Active' },
  { key: 'archived', value: 'archived', text: 'Archived' },
];

export const COLLECTION_ERROR_MESSAGES = {
  archive:
    'Something went wrong while archiving the collection. Please try again.',
  move: 'Something went wrong while moving the flow. Please try again.',
  remove: 'Something went wrong while removing the flow. Please try again.',
  removeAll:
    'Something went wrong while removing all flows from the collection. Please try again.',
  share: 'Something went wrong while sharing the collection. Please try again.',
  activate:
    'Something went wrong while activating the collection. Please try again.',
};
