import { createSelector } from 'reselect';
import { Set } from 'immutable';
import { currentUser } from 'Modules/Shared/selectors/users';

export const accessControls = state => state.get('accessControls');

export const makeAccessControlsForEntity = (entityType, entityId) => {
  return createSelector(accessControls, accessControls => {
    return accessControls.filter(accessControl => {
      const thisEntityType = accessControl.get('entityType');
      const thisEntityId = accessControl.get('entityId');
      return thisEntityType === entityType && thisEntityId === entityId;
    });
  });
};

export const makeCurrentUserScopesForEntity = (entityType, entityId) => {
  return createSelector(
    currentUser,
    makeAccessControlsForEntity(entityType, entityId),
    (currentUser, accessControls) => {
      return accessControls.reduce((accumulator, accessControl) => {
        return accumulator.concat(accessControl.get('scopes'));
      }, new Set());
    }
  );
};

export const currentUserOwnsEntity = (entityType, entityId) => {
  return createSelector(
    makeCurrentUserScopesForEntity(entityType, entityId),
    scopes => scopes.includes('owner')
  );
};

export const currentUserCanViewEntity = (entityType, entityId) => {
  return createSelector(
    makeCurrentUserScopesForEntity(entityType, entityId),
    scopes => scopes.includes('write') || scopes.includes('read')
  );
};

export const currentUserCanEditEntity = (entityType, entityId) => {
  return createSelector(
    makeCurrentUserScopesForEntity(entityType, entityId),
    scopes => scopes.includes('write')
  );
};

export const currentUserSharedEntity = (entityType, entityId) => {
  return createSelector(
    [
      currentUserOwnsEntity(entityType, entityId),
      currentUserCanViewEntity(entityType, entityId),
    ],
    (ownsEntity, canViewEntity) => {
      return !ownsEntity && canViewEntity;
    }
  );
};

export const getUserHasNoAccessControls = (entityType, entityId) => {
  return createSelector(
    makeCurrentUserScopesForEntity(entityType, entityId),
    scopes => {
      return scopes.isEmpty();
    }
  );
};
