import { FC, useEffect, useState } from 'react';
import { PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { Template } from '@groove/api/gateway/v1/template';
import TruncatedTextWithTooltip from '@groove/ui/Components/TruncatedTextWithTooltip';
import cx from 'classnames';
import StyledSeparator from '@groove/ui/Components/StyledSeparator';

import useStore from '../store/useStore';
import TemplateStarrer from '../TemplateStarrer';
import {
  TEMPLATES_EVENT_TYPE,
  TEMPLATES_EVENT_UI_SOURCE,
  trackTemplatesEvent,
} from '../analytics/templatesEvents';
import TemplateFolderIcon from '../icons/cleared_folder_icon.svg';

type TemplateItemProps = {
  template: Template;
  onInsert: (template: Template) => void;
};

const btnStyles = { flexContainer: 'flex flex-row-reverse' };

const TemplateItem: FC<TemplateItemProps> = ({ template, onInsert }) => {
  const favorites = useStore(store => store.favoriteTemplates);
  const isFavorite = favorites?.some(favorite => favorite.id === template.id);

  // Need a local state to reduce lag when starring/unstarring
  const [isStarred, setIsStarred] = useState(isFavorite);

  // Unused for now, until we set up body previews
  // const templateBodyText = extractTextFromHtml(template.body);

  const [itemHovered, setItemHovered] = useState(false);
  const setSelectedTemplate = useStore(store => store.setSelectedTemplate);
  const selectedTemplate = useStore(store => store.selectedTemplate);
  const customActionText = useStore(
    store => store.templatesApp.customActionText,
  );

  useEffect(() => {
    setIsStarred(isFavorite);
  }, [favorites, isFavorite]);

  const isSelected = template.id === selectedTemplate?.id;
  const insertingTemplate = useStore(store => store.insertingTemplate);
  const [insertInvokedForThisTemplate, setInsertInvokedForThisTemplate] =
    useState(false);

  const selectedTemplatePanelTab = useStore(
    store => store.selectedTemplatePanelTab,
  );

  return (
    <>
      <Stack
        className={cx(
          `block w-auto text-left text-neutral/900 cursor-pointer hover:bg-neutral/50`,
          isSelected ? 'bg-neutral/50' : '',
        )}
        aria-label={`template-item-${template.id}`}
        onClick={() => {
          setSelectedTemplate(template);
          trackTemplatesEvent({
            eventName: TEMPLATES_EVENT_TYPE.PREVIEW_OPENED,
            templatesSourceUi: TEMPLATES_EVENT_UI_SOURCE.PANEL,
            templateTab: selectedTemplatePanelTab,
          });
        }}
      >
        <div
          className="flex"
          onMouseOver={() => setItemHovered(true)}
          onMouseLeave={() => setItemHovered(false)}
          onFocus={() => setItemHovered(true)}
        >
          <span
            id="template-mini-details"
            className="flex flex-col flex-grow w-4/5 p-3"
          >
            {template.template_folder_name && (
              <div className="flex align-middle">
                <img
                  src={TemplateFolderIcon}
                  alt="template-folder-icon"
                  width={14}
                  className="pr-1"
                />
                <TruncatedTextWithTooltip
                  text={template.template_folder_name}
                  textClasses="text-xs text-gray1"
                />
              </div>
            )}
            <TruncatedTextWithTooltip
              text={template.name}
              textClasses="text-xs font-semibold text-primary"
            />
            <TruncatedTextWithTooltip
              text={template.subject}
              textClasses="text-xs text-gray1"
            />
            {/* Unused for now, until we set up body previews
            <Text className="text-xs text-gray1 truncate">
              {templateBodyText}
            </Text> */}
          </span>
          <div className="z-50 flex flex-col-reverse justify-between items-end pr-3 py-2">
            <TemplateStarrer
              isFavorite={isStarred}
              templateId={template.id}
              isActive={isSelected || itemHovered}
            />
            <PrimaryButton
              text={
                insertInvokedForThisTemplate
                  ? 'Inserting...'
                  : customActionText || 'Insert'
              }
              aria-label="quick-insert-template"
              className={`${
                itemHovered || insertInvokedForThisTemplate
                  ? 'visible'
                  : 'hidden'
              } flex-none p-0 min-w-[48px] h-[18px] bg-clari-blue/600 hover:bg-clari-blue/700 text-[11px] text-white disabled:bg-neutral/75 disabled:text-neutral/400`}
              onClick={e => {
                e.stopPropagation();
                setInsertInvokedForThisTemplate(true);
                onInsert(template);
              }}
              disabled={insertingTemplate}
              styles={btnStyles}
            >
              {insertInvokedForThisTemplate && (
                <Spinner className="p-1 pr-0" size={SpinnerSize.xSmall} />
              )}
            </PrimaryButton>
          </div>
        </div>
      </Stack>
      <StyledSeparator xMargin={12} />
    </>
  );
};

export default TemplateItem;
