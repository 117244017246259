import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';

import { getIsGrooveCurrentUser } from 'Modules/Shared/selectors/users';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

const styles = theme => ({
  headerRow: {
    height: 'auto',
  },
  headerCell: {
    border: 'none',
    color: theme.palette.text.primary,
    paddingLeft: 0,
    '&:first-child': {
      paddingLeft: theme.groove.unit * 3,
    },
  },
});

@withStyles(styles)
@connect(
  state => ({
    isGrooveCurrentUser: getIsGrooveCurrentUser(state),
  }),
  {}
)
export default class UserTableHead extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      headerCell: PropTypes.string.isRequired,
    }).isRequired,
    isGrooveCurrentUser: PropTypes.bool.isRequired,
  };

  render() {
    const { classes, isGrooveCurrentUser } = this.props;

    return (
      <TableHead>
        <TableRow classes={{ head: classes.headerRow }}>
          {isGrooveCurrentUser && (
            <TableCell classes={{ head: classes.headerCell }}>Id</TableCell>
          )}
          <TableCell classes={{ head: classes.headerCell }}>Name</TableCell>
          <TableCell classes={{ head: classes.headerCell }}>Email</TableCell>
          {isGrooveCurrentUser && (
            <TableCell classes={{ head: classes.headerCell }}>
              Org SFDC ID
            </TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  }
}
