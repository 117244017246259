/**
 * https://www.salesforceben.com/salesforce-object-key-prefix-list/
 */
export const SFDC_PREFIXES = {
  LEAD: '00Q',
  CONTACT: '003',
  ACCOUNT: '001',
  OPPORTUNITY: '006',
};

export const isContactOrLead = (id: string): boolean => {
  return [SFDC_PREFIXES.CONTACT, SFDC_PREFIXES.LEAD].includes(
    id.substring(0, 3),
  );
};

export const isContact = (id: string): boolean => {
  return [SFDC_PREFIXES.CONTACT].includes(id.substring(0, 3));
};

export const isAccountOrOpportunity = (id: string): boolean => {
  return [SFDC_PREFIXES.ACCOUNT, SFDC_PREFIXES.OPPORTUNITY].includes(
    id.substring(0, 3),
  );
};

export const isLead = (id: string): boolean => {
  return [SFDC_PREFIXES.LEAD].includes(id.substring(0, 3));
};

export const isAccount = (id: string): boolean => {
  return [SFDC_PREFIXES.ACCOUNT].includes(id.substring(0, 3));
};

export const isOpportunity = (id: string): boolean => {
  return [SFDC_PREFIXES.OPPORTUNITY].includes(id.substring(0, 3));
};
