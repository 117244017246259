import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ModifiedTheme } from 'Utils/theme/ClariTheme';
import Error from 'Modules/Shared/components/ErrorStates/Error';
import ConfusedMan from 'Modules/Shared/components/Images/ConfusedMan';

const useStyles = makeStyles<ModifiedTheme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  displayInCenter: {
    paddingTop: 0,
  },
  paper: {
    width: 400,
    paddingBottom: 42,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: `0px 4px 20px ${theme.palette.text.shadows}`,
    background: theme.palette.text.neutralColor,
    borderRadius: 6,
  },
}));

const ErrorPage: React.FC<ErrorPagePropTypes> = ({
  showRecoveryButton,
  showSubmitFeedbackButton,
  onRecovery,
  recoveryButtonText,
  primaryText,
  secondaryText,
  icon,
  center,
}) => {
  const { root, paper } = useStyles();

  return (
    <div
      className={root}
      style={center ? { paddingTop: '0px' } : { padding: '71px' }}
    >
      <div className={paper}>
        <Error
          icon={icon}
          showRecoveryButton={showRecoveryButton}
          showSubmitFeedbackButton={showSubmitFeedbackButton}
          onRecovery={onRecovery}
          primaryText={primaryText}
          secondaryText={secondaryText}
          recoveryButtonText={recoveryButtonText}
        />
      </div>
    </div>
  );
};

type ErrorPagePropTypes = {
  showRecoveryButton?: boolean;
  showSubmitFeedbackButton?: boolean;
  primaryText?: string;
  onRecovery?: () => void;
  secondaryText?: string;
  recoveryButtonText?: string;
  previousPath?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  center?: boolean;
};

ErrorPage.defaultProps = {
  showRecoveryButton: false,
  showSubmitFeedbackButton: false,
  primaryText: 'Something went wrong',
  onRecovery: null,
  secondaryText: 'We logged the error and are looking into it',
  recoveryButtonText: 'Reload Page',
  previousPath: '',
  icon: <ConfusedMan />,
  center: false,
};

export default ErrorPage;
