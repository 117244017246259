import { eventChannel } from 'redux-saga';
import { select, call } from 'redux-saga/effects';
import { currentUser } from 'Modules/Shared/selectors/users';
import { getFirestore } from 'Utils/firestore';
import {
  FirebaseAuthenticationError,
  DocumentOrCollectionMissingError,
} from 'Utils/firestore/FirestoreErrors';
import getOrganizationBasePath from 'Utils/firestore/getOrganizationBasePath';
import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
} from 'Modules/Spaces/show/constants';

// -------------- Helpers --------------
export function* waitUntilDocumentOrCollectionExists(path) {
  const firestore = yield getFirestore();
  if (firestore == null) throw new FirebaseAuthenticationError();

  const type =
    path.split('/').length % 2 === 0
      ? FIRESTORE_DOCUMENT
      : FIRESTORE_COLLECTION;

  const firestoreMethod =
    type === FIRESTORE_COLLECTION ? firestore.collection : firestore.doc;

  let numberOfAttempts = 0;

  yield call(
    () =>
      new Promise((resolve, reject) => {
        const handler = () => {
          firestoreMethod.call(firestore, path).onSnapshot(snapshot => {
            if (
              (type === FIRESTORE_COLLECTION && !snapshot.empty) ||
              (type === FIRESTORE_DOCUMENT && snapshot.exists)
            ) {
              resolve();
            } else {
              console.error(`Firestore ${type} path [${path}] does not exist.`);
              if (numberOfAttempts > 30) {
                reject(new DocumentOrCollectionMissingError(type, path));
                return;
              }
              numberOfAttempts += 1;
              setTimeout(handler, 1000);
            }
          });
        };
        handler();
      })
  );
}

export function* createFirestoreChannel(path, firestoreReference) {
  const firestore = do {
    if (firestoreReference) {
      firestoreReference; // eslint-disable-line no-unused-expressions
    } else {
      // handing this call to redux-saga middleware so it can properly cancel getFirestore generator
      // and properly cancel createFirestoreChannel
      // using yield* will result in this method have to handle properly effect cancelled and cancel itself in the process.
      // this is a side effect of redirecting to another page while the root saga still working
      yield getFirestore();
    }
  };

  if (firestore == null) return false;

  let docPathExists = true;

  const channel = eventChannel(emit => {
    const type =
      path.split('/').length % 2 === 0
        ? FIRESTORE_DOCUMENT
        : FIRESTORE_COLLECTION;

    const firestoreMethod =
      type === FIRESTORE_COLLECTION ? firestore.collection : firestore.doc;
    const unsubscribe = firestoreMethod
      .call(firestore, path)
      .onSnapshot(snapshot => {
        if (type === FIRESTORE_DOCUMENT && snapshot.exists) {
          emit(snapshot);
        } else if (type === FIRESTORE_COLLECTION && !snapshot.empty) {
          emit(snapshot);
        } else {
          docPathExists = false;
          emit(snapshot);
          console.error(`Firestore ${type} path [${path}] does not exist.`);
        }
      });

    // Returns unsubscribe function
    return () => unsubscribe();
  });

  if (docPathExists) {
    return channel;
  }

  return false;
}

export function* assembleFirestoreUserPath() {
  const userData = yield select(state => currentUser(state));
  const { id, orgId } = userData.toJS();
  return `${getOrganizationBasePath(orgId)}/users/${id}`;
}

export default function* root() {}
