import { fromJS, Map } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/dialer';

const buildInitialCallActivityCache = () => new Map();

const initialState = fromJS({
  isCallInProgress: false,
  settings: {},
  callActivityCache: buildInitialCallActivityCache(),
  isOpen: false,
});

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SET_CALL_ACTIVITY_CACHE: {
      return state.mergeIn(
        ['callActivityCache', action.payload.key],
        action.payload.value
      );
    }

    case actionTypes.SET_CALL_IN_PROGRESS: {
      return state.set('isCallInProgress', action.payload);
    }

    case actionTypes.CLEAR_CALL_ACTIVITY_CACHE: {
      return state.set('callActivityCache', buildInitialCallActivityCache());
    }

    case actionTypes.LOAD_SETTINGS: {
      return state.set('settings', action.payload);
    }

    case actionTypes.SET_DIALER_OPENED: {
      return state.set('isOpen', action.payload);
    }

    default: {
      return state;
    }
  }
}
