import { createSelector } from 'reselect';
import {
  FILTER_TYPES,
  ORDER_FIELDS,
  MINIMUM_SEARCH_LENGTH,
} from 'Modules/Emails/constants';
import { inLoadingState } from 'Modules/App/selectors';

export const getIsFetchingTheNextPageOfEmails = state =>
  state.getIn(['emails', 'isFetchingTheNextPageOfEmails']);
export const getPageInfo = state => state.getIn(['emails', 'pageInfo']);
export const getHasNextPage = createSelector(getPageInfo, pageInfo =>
  pageInfo.get('hasNextPage')
);
export const getEventFilter = state => state.getIn(['emails', 'eventFilter']);
export const getSource = state => state.getIn(['emails', 'source']);
export const getIsInSearchMode = state =>
  state.getIn(['emails', 'isInSearchMode']);
export const getSearch = state => state.getIn(['emails', 'search']);
export const getOrder = createSelector(
  getEventFilter,
  getSearch,
  (eventFilter, search) => {
    if (search.length > 0) {
      return ORDER_FIELDS.COMPLETED_AT;
    }
    switch (eventFilter) {
      case FILTER_TYPES.OPENED:
      case FILTER_TYPES.CLICKED:
        return ORDER_FIELDS.LAST_EVENT_AT;
      case FILTER_TYPES.UNOPENED:
        return ORDER_FIELDS.COMPLETED_AT;
      case FILTER_TYPES.REPLIED:
        return ORDER_FIELDS.REPLIED_AT;
      default:
        return ORDER_FIELDS.COMPLETED_AT;
    }
  }
);

export const getEmailData = state => state.getIn(['emails', 'data']);

const getAreResultsEmpty = createSelector(
  getEmailData,
  getIsFetchingTheNextPageOfEmails,
  (emailData, isFetchingTheNextPageOfEmails) =>
    emailData.isEmpty() && !isFetchingTheNextPageOfEmails
);

const getIsFetchingTheFirstPage = createSelector(
  getEmailData,
  getIsFetchingTheNextPageOfEmails,
  (emailData, isFetchingTheNextPageOfEmails) =>
    emailData.isEmpty() && isFetchingTheNextPageOfEmails
);

export const shouldShowLoader = createSelector(
  inLoadingState,
  getIsFetchingTheFirstPage,
  (inLoadingState, isFetchingTheFirstPage) =>
    inLoadingState || isFetchingTheFirstPage
);

export const getNoTrackingActivity = createSelector(
  getAreResultsEmpty,
  getIsInSearchMode,
  (areResultsEmpty, isInSearchMode) => areResultsEmpty && !isInSearchMode
);

export const getIsSearchTooShort = createSelector(
  getIsInSearchMode,
  getSearch,
  (isInSearchMode, search) =>
    isInSearchMode && search.length < MINIMUM_SEARCH_LENGTH
);

export const getNoMatchingResults = createSelector(
  getAreResultsEmpty,
  getIsInSearchMode,
  getIsSearchTooShort,
  (areResultsEmpty, isInSearchMode, isSearchTooShort) =>
    areResultsEmpty && isInSearchMode && !isSearchTooShort
);

export const getEmails = createSelector(
  getEmailData,
  getOrder,
  (emailData, order) => {
    return emailData
      .valueSeq()
      .toList()
      .sortBy(
        email => email.get(order),
        (date1, date2) => (date2?.valueOf() || 0) - (date1?.valueOf() || 0)
      );
  }
);

export const getEmailsOnboardingCompleted = state =>
  state.getIn(['emails', 'emailsOnboardingCompleted']);
