import { fromJS, List, Map } from 'immutable';
import { actionTypes as peopleImportActionTypes } from 'Modules/PeopleImportDialog/actions';
import { actionTypes as validateAutoImportReportActionTypes } from 'Modules/PeopleImportDialog/submodules/reports/actions/validateAutoImportReport';

import { actionTypes } from './actions';

const initialState = fromJS({
  reportFolders: [],
  reports: [],
  query: '',
  fetching: false,
  fetchingReport: false,
  reportSelected: false,
  contactIds: [],
  leadIds: [],
  validationReport: {},
});

export default function peopleImportDialog(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.FETCH_REPORT_FOLDERS.PROGRESS: {
      return state.set('fetchingFolders', true);
    }
    case actionTypes.FETCH_REPORT_FOLDERS.SUCCESS: {
      return state.set('fetchingFolders', false).set(
        'reportFolders',
        fromJS(action.payload)
          .filter(folder => folder.get('name'))
          .map(folder =>
            fromJS({
              value: folder.get('value') || folder.get('name'),
              label: folder.get('name'),
              sfdcId: folder.get('id'),
            })
          )
      );
    }
    case actionTypes.FETCH_REPORTS.BEGIN: {
      return state
        .set('fetching', true)
        .set('reports', new List())
        .set('contactIds', new List())
        .set('leadIds', new List())
        .set('reportSelected', false)
        .set('flowOwnerOptions', new List());
    }
    case actionTypes.FETCH_REPORTS.SUCCESS: {
      return state.set('fetching', false).set(
        'reports',
        fromJS(action.payload).map(report =>
          fromJS({ value: report.get('id'), label: report.get('name') })
        )
      );
    }
    case actionTypes.FETCH_REPORTS.FAILURE: {
      return state.set('fetching', false);
    }
    case actionTypes.FETCH_REPORT.BEGIN: {
      return state
        .set('contactIds', new List())
        .set('leadIds', new List())
        .set('sfdcWhatIdMap', null)
        .set('reportSelected', false);
    }
    case actionTypes.FETCH_REPORT.PROGRESS: {
      return state.set('fetchingReport', true);
    }
    case actionTypes.FETCH_REPORT.SUCCESS: {
      const { contactIds, leadIds, sfdcWhatIdMap } = action.payload;

      return state
        .set('contactIds', fromJS(contactIds))
        .set('leadIds', fromJS(leadIds))
        .set('sfdcWhatIdMap', fromJS(sfdcWhatIdMap))
        .set('fetchingReport', false)
        .set('reportSelected', true);
    }
    case actionTypes.FETCH_REPORT.FAILURE: {
      return state.set('fetchingReport', false);
    }
    case peopleImportActionTypes.RESET: {
      return initialState;
    }
    case peopleImportActionTypes.SET_ACTIVE_TAB: {
      const { payload } = action;

      if (payload !== 'salesforceReport') {
        return initialState;
      }

      return state;
    }

    case validateAutoImportReportActionTypes.VALIDATE_AUTO_IMPORT_REPORT
      .FAILURE: {
      const { message, reportSfdcId } = action.payload;

      return state.setIn(
        ['validationReport', reportSfdcId],
        Map({ inProgress: false, isDone: true, isValid: false, message })
      );
    }

    case validateAutoImportReportActionTypes.VALIDATE_AUTO_IMPORT_REPORT
      .PROGRESS: {
      const { reportSfdcId } = action.payload;

      return state.setIn(
        ['validationReport', reportSfdcId],
        Map({ inProgress: true })
      );
    }

    case validateAutoImportReportActionTypes.VALIDATE_AUTO_IMPORT_REPORT
      .SUCCESS: {
      const { reportSfdcId } = action.payload;

      return state.setIn(
        ['validationReport', reportSfdcId],
        Map({ inProgress: false, isDone: true, isValid: true })
      );
    }

    default:
      return state;
  }
}
