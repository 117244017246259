import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as shortId from 'shortid';

import LoaderRow from 'Modules/Shared/components/WorkStepRouteLoader/LoaderRow';
import sharedStyles from 'Modules/Actions/containers/Table/styles';
import pulse from 'Modules/Shared/components/WorkStepRouteLoader/pulse';
import { ModifiedTheme } from 'Utils/theme/ClariTheme';

const useStyles = makeStyles((theme: ModifiedTheme) => ({
  root: {
    padding: 24,
  },
  title: {
    ...pulse,
    height: 20,
    width: 250,
  },
  subtitle: {
    ...pulse,
    height: 20,
    width: 50,
  },
  headerSection: {
    minHeight: 65,
    maxHeight: 65,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 8,
  },
  filtersRow: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: 45,
    borderBottom: `1px solid ${theme.palette.text.borders}`,
    backgroundColor: theme.palette.text.neutralColor,
  },
  rowHeader: {
    ...pulse,
    width: 50,
    height: 14,
  },
  headerRow: {
    height: 56,
    borderBottom: `2px solid ${theme.palette.text.borders}`,
    backgroundColor: theme.palette.text.neutralColor,
    display: 'flex',
    alignItems: 'center',
  },
  '@keyframes pulse': {
    '0%': {
      backgroundColor: theme.palette.text.accentGray,
    },
    '50%': {
      backgroundColor: theme.palette.text.lightGray,
    },
    '100%': {
      backgroundColor: theme.palette.text.accentGray,
    },
  },
  table: {
    ...sharedStyles.tableRoot,
    overflow: 'hidden',
    scroll: 'none',
    borderRadius: 2,
    boxShadow: `0 2px 2px 0 ${theme.palette.text.shadows}, 0 0 2px 0 ${theme.palette.text.shadows}`,
  },
}));

const headerRows = [...Array(8)].fill(null).map(shortId.generate);
const rows = [...Array(15)].fill(null).map(shortId.generate);

const WorkStepRouteLoader: React.FC = () => {
  const classes = useStyles({});
  return (
    <div className={classes.root}>
      <div className={classes.headerSection}>
        <div className={classes.title} />
      </div>
      <div className={classes.subtitle} />
      <div className={classes.filtersRow}>
        {headerRows.map(value => (
          <div className={classes.rowHeader} key={value} />
        ))}
      </div>
      <div className={classes.table}>
        {rows.map(value => (
          <LoaderRow key={value} />
        ))}
      </div>
    </div>
  );
};

WorkStepRouteLoader.displayName = 'WorkStepRouteLoader';

export default WorkStepRouteLoader;
