import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import useStore, { TabName } from 'Modules/ActionsRewrite/store/useStore';
import ClariTheme from 'Utils/theme/ClariTheme';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px 4px 0px 0px',
    height: 32,
    width: 104,
    marginRight: 4,
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  inactiveTab: {
    background: ClariTheme.palette.text.backgroundColor,
    boxShadow: `inset 0px -1px 2px 0px ${ClariTheme.palette.text.shadows}`,
    color: ClariTheme.palette.text.disabled,
  },
  activeTab: {
    background: ClariTheme.palette.text.neutralColor,
    boxShadow: `0px 0px 2px 0px ${ClariTheme.palette.text.shadows}`,
  },
  disabled: {
    pointerEvents: 'none',
  },
  longerTab: {
    width: 180,
  },
}));

export type TabParams = {
  name: TabName;
  isActive: boolean;
  isFetchingCounts: boolean;
  totalCount: number;
  isFetchingAdditionalData: boolean;
};

// See apps/chrome-extension-mv3/src/pages/Notifications/CustomTabs.tsx

export default function Tab({
  name,
  isActive,
  isFetchingCounts,
  totalCount,
  isFetchingAdditionalData,
}: TabParams) {
  const classes = useStyles();
  const { setActiveTab } = useStore();
  const isHistoryTab = name === 'Executed Actions';
  const isAssignedToOthersTab = name === 'Assigned to Others';
  const count = isFetchingCounts ? '--' : totalCount;
  const tabDisplayName = `${isHistoryTab ? '' : `${count} `}${name}`;

  // terrible: using a div
  return (
    <div
      className={cx({
        [classes.root]: true,
        [classes.inactiveTab]: !isActive,
        [classes.activeTab]: isActive,
        [classes.disabled]: isFetchingAdditionalData,
        [classes.longerTab]: isHistoryTab || isAssignedToOthersTab,
      })}
      role="button"
      // Why do these all have the same tabIndex?
      tabIndex={-1}
      onClick={() => setActiveTab(name)}
    >
      {tabDisplayName}
    </div>
  );
}
