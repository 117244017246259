import { FC } from 'react';
import { ApiTemplateFolders } from '@groove/api/services/v1/templateFolders';

import useStore from './store/useStore';
import TemplatesList from './TemplatesList';
import TemplateFolderListItem from './TemplateFoldersListItem';
import SearchResults from './SearchResults';
import { TEMPLATES_MENU_WIDTH } from './constants';

type TemplatesMenuProps = {
  templateFolders: ApiTemplateFolders | undefined;
};

const TemplatesMenu: FC<TemplatesMenuProps> = ({ templateFolders }) => {
  const searchTerm = useStore(store => store.searchTerm);
  const selectedFolder = useStore(store => store.selectedFolder);

  if (searchTerm && searchTerm.length > 2) {
    return <SearchResults results={templateFolders} />;
  }

  return (
    <div
      className={`flex flex-col h-full overflow-y-auto w-[${TEMPLATES_MENU_WIDTH}]`}
    >
      {selectedFolder ? (
        <TemplatesList />
      ) : (
        templateFolders?.data.map(folder => (
          <TemplateFolderListItem key={folder.id} folder={folder} />
        ))
      )}
    </div>
  );
};

export default TemplatesMenu;
